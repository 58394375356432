import React from "react";
import { connect } from "react-redux";
import ProgressBar from "./Progressbar";
import { putAPI } from "../../../utils/API";
// import $ from 'jquery';
import "jquery-ui/ui/widgets/slider";
import FixedCostImage from "../../../Assets/upgrade-images/select_project_type/fixed_cost.svg";
import RetainerImage from "../../../Assets/upgrade-images/select_project_type/retainer.svg";

import InputRange from "react-input-range";
// budget_subtext
class ProjectType extends React.Component {
  state = {
    subTypeError: false,
    isLoading: false,
  }
  componentDidMount() {
    // to show budget at left panel
  }

  handleContinue = () => {
    const { isHireflow, subType } = this.props;
    // const { value } = this.state;
    this.setState({ isLoading: true });
    const projectType = { _id: this.props.projectId, sub_type: subType };
    if (!subType) {
      this.setState({ subTypeError: true, isLoading: false });
      return;
    }
    if (this.props.userName) {
      putAPI("/project/post-project-sub-type", projectType)
        .then((res) => {
          if (res.data.status === "1") {
            // this.props.PostProjectSteps(post_steps, total_steps);

            this.props.history.push(
              `${isHireflow ? "/hire/" : "/self-service-hire/"}budget`
            );

            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      localStorage.setItem("ProjectType", JSON.stringify(projectType));
      // this.props.PostProjectSteps(post_steps, total_steps);

      this.props.history.push(
        `${isHireflow ? "/hire/" : "/self-service-hire/"}budget`
      );

      this.setState({ isLoading: false });
    }


    this.props.history.push(
      `${isHireflow ? "/hire/" : "/self-service-hire/"}budget`
    );

    this.setState({ isLoading: false });

  };


  handleChange(subType) {
    this.setState({ subTypeError: false })
    this.props.subTypeEvent(subType);
  }

  render() {
    const Background_1 = require("../../../Assets/scope-images/Fixedcost.svg");
    const Background_2 = require("../../../Assets/scope-images/Retainer.svg");
    const { subType } = this.props;
    const { subTypeError } = this.state
    return (
      <>
        <section>
          <div className="card-wrapper mob-mb-70">
            <div className="card mob-border-radius-0">
              <div className="card-body">
                <div className="row mx-0 mt-1 overflow-content project-height">
                  {/* "budget-height" -> previous class name that we used before "project-height" above */}
                  <div className="col-sm-12 mob-col-100 post_a_project">
                    <h2 className="pt-5 px-4 mob-pt-py font-22 mob-font-16 font-weight-600">Choose a one</h2>
                    <div className="custom_form px-2 pb-4 pt-2 mob-p-0 mob-mb-45">
                      {/* <div className="row mx-0">
								<div className="col-sm-1 form-group radio_custom">
								
										<input 
										type="radio" 
										id="fixed" 
										name="projecttype" 
										checked={subType==='fixed'?true:false}
										onChange={()=>this.handleChange('fixed')}
										value="fixed"
										/>
								
									
									<label className="label font-weight-500 text-dark" htmlFor="fixed">
										Fixed
									</label>
								</div>
								<div className="col-sm-1 form-group radio_custom">
							
										<input 
										type="radio" 
										id="retainer" 
										name="projecttype"   
										value="retainer" 
										checked={subType==='retainer'?true:false}

										onChange={()=>this.handleChange('retainer')}
										/>
								
									
									<label className="label font-weight-500 text-dark" htmlFor="retainer">
										Retainer
									</label>
								</div>
							</div>   */}
                      <div className="row mx-0 serviece_page_cards">
                        <div className="col-lg-3 col-sm-4 mb-4 cardbox">
                          <div class="card text-center cursor-pointer h-100">
                            <div
                              class="card-body p-4"
                              onClick={(e) => {
                                { this.handleChange('fixed') }
                              }}
                            >
                              <div className="icon">
                                <img src={FixedCostImage} className="icon_img"></img>
                              </div>
                              <h5 class="card-title">Fixed</h5>
                              <div class="card-text">
                                <p className="mb-2">
                                  Hire an expert for a defined project scope, deliverable, or milestone
                                  {" "}
                                </p>
                              </div>
                              <div className="cstm_checkbox">
                                <input
                                  type="checkbox"
                                  checked={subType === 'fixed' ? true : false}
                                  id="chk2"
                                />
                                <label htmlFor="chk2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-4 cardbox">
                          <div class="card text-center cursor-pointer h-100">
                            <div
                              class="card-body p-4"
                              onClick={(e) => {
                                { this.handleChange('retainer') }
                              }}
                            >
                              <div className="icon">
                                <img src={RetainerImage} className="icon_img"></img>
                              </div>
                              <h5 class="card-title">Retainer</h5>
                              <div class="card-text">
                                <p className="mb-2">
                                  Hire an expert for a set number of hours or days each month to work on one or more projects / tasks
                                  {" "}
                                </p>
                              </div>
                              <div className="cstm_checkbox">
                                <input
                                  type="checkbox"
                                  checked={subType === 'retainer' ? true : false}
                                  id="chk2"
                                />
                                <label htmlFor="chk2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                          <div className="col-sm-12">
                          {(subTypeError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your project type.</p> : ``}
                          </div>
                        </div>
                      {/* <div  className="row m-0">
										<div  className="col-sm-11">
											<div  className="deadline-project">
												<ul  className="content">
													<li  className="mb-4">
														<div  className={`deadline position-relative ${(subType === 'fixed') ? "active" : ""}`} style={{backgroundImage: `url("${Background_1}")`}} onClick={(e) => {this.handleChange('fixed')}}>
                              <span className="d-block font-weight-600 mb-2">Fixed</span>
                              <span className="d-block font-11 font-weight-600 text-sub-2">Hire an expert for a defined project scope, deliverable, or milestone </span>
														</div> 
													</li>
													<li  className="mb-4">
														<div  className={`deadline position-relative ${(subType === 'retainer') ? "active" : ""}`} style={{backgroundImage: `url("${Background_2}")`}} onClick={(e) => {this.handleChange('retainer')}}> 
                              <span className="d-block font-weight-600 mb-2">Retainer</span>
                              <span className="mobile-ipad-none font-11 font-weight-600 text-sub-2">Hire an expert for a set number of hours or days each month to work on one or more projects/tasks</span>
                              <span className="mobile-ipad-show font-11 font-weight-600 text-sub-2">Hire an expert for a set number of hours or days each month</span>
														</div> 
													</li> 	
												</ul>  
											</div>
											{(subTypeError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your project type.</p> : `` }	
										</div> 
									</div>  */}
                    </div>
                  </div>

                  {/* <label className="h5 p-2">fixed </label>
                  {subType == "fixed_scope" ? (
                    <input
                      type="radio"
                      class="form-check-input"
                      onChange={this.handleChange.bind(this)}
                      name="project"
                      checked
                      value="fixed_scope"
                    ></input>
                  ) : (
                    <input
                      type="radio"
                      onChange={this.handleChange.bind(this)}
                      name="project"
                      value="fixed_scope"
                    ></input>
                  )}

                  <label className="h5 p-2">retainer</label>
                  {subType === "retainer" ? (
                    <input
                      type="radio"
                      onChange={this.handleChange.bind(this)}
                      name="project"
                      checked
                      value="retainer"
                    ></input>
                  ) : (
                    <input
                      type="radio"
                      onChange={this.handleChange.bind(this)}
                      name="project"
                      value="retainer"
                    ></input>
                  )}  */}

                </div>
                <div className="row m-0">
                  <ProgressBar
                    {...this.state}
                    handleContinue={this.handleContinue}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapState = (store) => {
  let postProjectData = store.postProject;
  let globalReducer = store.globalReducer;
  return {
    subType: postProjectData.subType,
    projectId: postProjectData.projectId,
    post_steps: postProjectData.post_steps,
    total_steps: postProjectData.total_steps,
    alreadyCreatedProject: postProjectData.alreadyCreatedProject,
    userName: globalReducer.Me.Username,
  };
};

const mapDispatch = (dispatchEvent) => ({
  OnFieldChange: (name, value) =>
    dispatchEvent({
      type: "ON_FIELD_CHANGE_POST_PROJECT",
      payload: { name: name, value: value },
    }),
  PostProjectSteps: (step, total_step) =>
    dispatchEvent({
      type: "POST_PROJECT_STEPS",
      payload: { step: step, total_steps: total_step },
    }),
  subTypeEvent: (val) => dispatchEvent({ type: "SELECT_SUBTYPE", values: val }),
});
export default connect(mapState, mapDispatch)(ProjectType);
