import React, { useEffect, useState } from "react";
import {
  releventProjects,
  projectbyCollateral,
  relevent_projects,
} from "../../../../../store/action/proposal.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../../../authorized/Common/Common/Loader';
import ExclamationIcon from '../../../../../Assets/upgrade-images/select_revlent_project/exclamation_mark.png';
import SearchIcon from '../../../../../Assets/upgrade-images/submit_proposal/search-normal.svg';
import { getThumbnailImage } from "../../../../../utils/Helperfunctions";
import { isViewableFile, renderDifferentFilesThumbnail } from "../../../Common/Common/FileRenderMessage";

const ReleventProjects = (props) => {
    const _ProjectsIs = useSelector((state) => state.releventProjects)
    const creatorFavProIs = _ProjectsIs?.releventProjectIs;
    const [ dataIs , setData ] = useState([])
    const [ from , setFrom ] = useState(0)
    const [ to, setTo ] = useState(4)
    const [ project_ids, setProject_ids] = useState([])
    const [ releventProject , setReleventProject ] = useState([])
    const [ isChecked , setChecked ] = useState(false)
    const [ activeCollateral, setActiveCollateral ] = useState(null)
    const projectIs = props?.projects?.data;
    const dispatch = useDispatch()
    const responseIs =  props?.projects;
    const ids = _ProjectsIs?.project_ids;
    

    useEffect(() => {
      setProject_ids(ids)
    },[ids])

    useEffect(() => {
      setReleventProject(creatorFavProIs)
    },[creatorFavProIs])
    

    useEffect(() => {
      setData(projectIs)
    },[projectIs])

  const getData = (collateral_id) => {
    const data = {
      userName: props?.userName,
      collateral_id,
      search: props?.search,
    };
    dispatch(projectbyCollateral(data));
  };

    useEffect(() => {
      setTimeout(() => {
        const collateral_id = dataIs?.length && dataIs[0]?.collateral_id
        getData(collateral_id)  
        setActiveCollateral(collateral_id) 
      }, 500);
    },[dataIs])

  const getProjects = (obj) => {
    setActiveCollateral(obj?.collateral_id);
    getData(obj?.collateral_id);
  };

  const handleLeft = () => {
    if (from > 0) {
      setFrom(from - 1);
      setTo(to - 1);
    }
  };

  const handleRight = () => {
    const totalData = dataIs?.length;
    if (from < totalData - 4) {
      setTo(to + 1);
      setFrom(from + 1);
    }
  };

    const getReleventProjects = (checkedIs, obj) => {
      if(checkedIs){
        console.log("project_ids...", project_ids, obj)
        const previousData = project_ids
        const projectChoosed = { _id : obj?._id }        
        const currentData = previousData.concat(projectChoosed)
        setProject_ids(currentData)
      }else{
        const ids_now = project_ids?.filter(el => el._id !== obj?._id)
        setProject_ids(ids_now)
      }
    }

    const getFavProject = (checkedIs, obj) => {
      if(checkedIs){
        const previousData = releventProject ? releventProject : []
        const currentData = previousData.concat(obj)
        setReleventProject(currentData)
      }else{
        const projects_now = releventProject?.filter(el => el._id !== obj?._id)
        setReleventProject(projects_now)
      }
    }
 
  const chooseProjects = (e, obj) => {
    const checkedIs = e.target.checked;
    if (project_ids?.length < 3 || !checkedIs) {
      getReleventProjects(checkedIs, obj);
      getFavProject(checkedIs, obj);
      setChecked(true);
    } else {
      alert("You can choose max 3 projects");
      setChecked(false);
    }
  };

  const handleClose = () => {
    setProject_ids([]);
    setProjectIds();
  };

  const setProjectIds = () => {
    const data = { project_ids, releventProject };
    dispatch(relevent_projects(data));
  };

  const handleSubmit = () => {
    if (project_ids?.length) {
      setProjectIds();
    } else {
      alert("Please choose atleast 1 project");
    }
  }
    
    return(
    <>
      <section className="select_project_type">
        <div
          className="modal fade modal_fade_bg"
          id="releventproject"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered submit_proposal_modal_dialog m-auto">
            <div className="modal-content">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
                <h5
                  className="main_modal_title"
                  id="exampleModalLabel"
                >
                  Select Relevent Projects
                </h5>
                <button
                  type="button"
                  className="close close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  //  onClick={handleClose}
                >
                  <span
                    aria-hidden="true"
                    className="close_icon modalclose-btnicon"
                  >
                    ×
                  </span>
                </button>
              </div>

              <div className="modal-body px-4 pb-0 select_revelant_modal_body">
                <div className="body_scroll px-4">
                  {/* tabs */}
                  <div className="row mb-5">
                  <div className="col-lg-3 px-1 search_box mb-4 d-block d-lg-none">
                      <input
                        class="form-control input_search mr-sm-2"
                        type="search"
                        placeholder="Search by project name"
                        aria-label="Search"
                        onChange={props.handleSearch}
                      />
                      <img src={SearchIcon} className="img-fluid search_icon" />
                    </div>
                    <div className="col-lg-9 px-2 d-flex position-relative">
                      <span className="relevent_left_icon" onClick={handleLeft}>
                        <a
                            class="nav-link nav_link_btn"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                          >
                            <i class="fa fa-caret-left"></i>
                          </a>
                      </span>
                      <ul
                        class="nav nav-pills tab_pills mb-0 d-flex flex-nowrap relevent_ul_wrapper"
                        id="pills-tab"
                        role="tablist"
                      >
                        {/* tab_btn */}
                        {/* <li class="nav-item" onClick={handleLeft}>
                          <a
                            class="nav-link nav_link_btn"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                          >
                            <img src={PreIcon} className="img-fluid" />
                          </a>
                        </li> */}
                        {/* <li class="nav-item ">
                      <a class="nav-link nav_pills_link text_black_sm  active" id="pills-recommended-tab" data-toggle="pill" href="#pills-recommended" role="tab" aria-controls="pills-recommended" aria-selected="true">Recommended</a>
                    </li> */}
                        {dataIs?.length > 0
                          ? dataIs?.slice(from, to)?.map((obj, index) => {
                              return (
                                <li
                                  class="nav-item"
                                  key={index}
                                  onClick={() => getProjects(obj)}
                                >
                                  <a
                                    class={`nav-link nav_pills_link text_black_xs_w_600 ${
                                      obj?.collateral_id === activeCollateral
                                        ? "active"
                                        : ""
                                    }`}
                                    id="pills-design-tab"
                                    data-toggle="pill"
                                    href="#pills-design"
                                    role="tab"
                                    aria-controls="pills-design"
                                    aria-selected="false"
                                  >
                                    {obj?.collateral_name}
                                  </a>
                                </li>
                              );
                            })
                          : ""}
                        {/*tab_btn */}
                        {/* <li class="nav-item" onClick={handleRight}>
                          <a
                            class="nav-link nav_link_btn"
                            id="pills-contact-tab"
                            data-toggle="pill"
                            href="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            <img src={NextIcon} className="img-fluid" />
                          </a>
                        </li> */}
                      </ul>
                      <span className="relevent_right_icon" onClick={handleRight}>
                          <a
                            class="nav-link nav_link_btn"
                            id="pills-contact-tab"
                            data-toggle="pill"
                            href="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            <i class="fa fa-caret-right"></i>
                          </a>
                      </span>
                    </div>
                    <div className="col-lg-3 px-1 search_box d-none d-lg-block">
                      <input
                        class="form-control input_search mr-sm-2"
                        type="search"
                        placeholder="Search by project name"
                        aria-label="Search"
                        onChange={props.handleSearch}
                      />
                      <img src={SearchIcon} className="img-fluid search_icon" />
                    </div>
                  </div>

                  <div
                    class="tab-content tabs_content px-2"
                    id="pills-tabContent"
                  >
                    {/* <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  </div> */}
                    {/* for recammond */}
                    {/* <div class="tab-pane fade show active" id="pills-recommended" role="tabpanel" aria-labelledby="pills-recommended-tab">
                    <div className='row mb-4 pt-4 justify-content-between align-items-center'>
                      <div className='col-lg-10 px-0 d-flex align-items-center'>
                        <div class="card w-15 mr-4 border-0">
                          <img src={CardImage1} className='img-fluid card-img-top'/>
                        </div>
                        <div>
                          <p className='text_black_md'>Social Media Campaign app</p>
                          <p className='text_light_gray_xs'>Last Updated : 12th May 2022</p>
                        </div>
                      </div>
                      <div className='col-lg-2 px-0 icon_box'>
                        <div className='checkbox_icon mr-4'>
                          <input type="checkbox" id="chk1" name="chkdemo" checked={true} />
                          <label for="chk1 mb-0"></label> 
                        </div> 
                      </div>

                    </div>
                  </div> */}

                  <div class="tab-pane fade show active" id="pills-design" role="tabpanel" aria-labelledby="pills-design-tab">
                    {/* content */}
                    {/* row1 */}
                    {responseIs?.projectLoading ? 
                    <Loader />:
                    responseIs?.projects?.length > 0  ?
                    responseIs?.projects.map((obj, index) => {
                      return<div className='row mb-4 pb-4 justify-content-between align-items-center br_btm_border-gray-500' key={index}>
                        <div className='col-lg-10 col-10 px-0 d-flex align-items-center'>
                          {/* <div class="card w-15 mr-4 border-0"> */}
                            {/* <img src={getThumbnail(obj?.project_data?.images_or_links)} className='img-fluid card-img-top'/> */}
                            {/* <ProjectItem
                             images={obj.project_data.images_or_links}
                            /> */}
                            {/* <RenderImage
                              images={obj.images_or_links}
                            /> */}
                          {/* </div> */}
                          <div className="preview_image_wrapper">
                          <RenderImage
                              images={obj.images_or_links}
                            />
                          </div>
                          <div className="pl-4">
                            <p className='text_black_md'>{obj?.title}</p>
                            <p className='text_light_gray_xs'>Last Updated : {obj?.updatedAt?.split("T")[0]}</p>
                          </div>
                        </div>
                        {/* <div className='col-lg-2 px-0 icon_box'>
                          <div className='checkbox_icon mr-4'>
                            <input type="checkbox" id={`chk`+ index} name={`chkdemo${index}`} 
                            checked={project_ids?.some(el => el._id === obj?._id)} 
                            onChange={ (e) => chooseProjects(e, obj) }/>
                            <label className="mb-0" htmlFor={`chk${index}`}></label> 
                          </div>  
                        </div> */}
                        <div className="col-lg-2 col-2 px-0">
                          <div className='cstm_checkbox'>
                            <input type="checkbox" id={`chk`+ index} name={`chkdemo${index}`} 
                            checked={project_ids?.some(el => el._id === obj?._id)} 
                            onChange={ (e) => chooseProjects(e, obj) }/>
                            <label className="mb-0" htmlFor={`chk${index}`}></label> 
                          </div> 
                        </div>
                      </div>
                    }):
                    "No data found"}
                  </div>
                </div>
                </div>
              </div>
              <div className="modal-footer py-3 border-0 justify-content-between align-items-center">
                <div className="max_projects">
                  <p className="text_black_700_w_600 fs_14 mb-0">
                    {" "}
                    <img src={ExclamationIcon} className="img-fluid mr-2" />
                    Max <span className="text_black_700_w_600 fs_14">3 Projects</span>
                  </p>
                </div>
                <div className="add_project">
                  <button
                    type="button"
                    class="btn btn-common btn_common_sm"
                    disabled={project_ids?.length ? false : true}
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleSubmit}
                  >
                    Add Projects
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


const RenderImage = ({ images }) => {
  let thumbnailObj;
  const thumbnailIs = images?.find((el) => el?.isThumbnail)
  if(thumbnailIs){
    thumbnailObj = thumbnailIs
  }else{
    thumbnailObj = images[0]
  }
  // const {type}=image;
  // const {value}=image;
  const type = thumbnailObj?.type
  const value = thumbnailObj?.value
  const [thumbnail, setThumbnail] = useState(null);
  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url);
    };
    if (type === "url") {
      getThumbnailImageFunc(value);
    } else {
      setThumbnail(value);
    }
  }, [type,value]);

  return (
    <div
      className="preview_image_card"
      style={{
        backgroundImage: `url("${
          isViewableFile(thumbnailObj.value)
            ? renderDifferentFilesThumbnail(thumbnailObj.value)
            : thumbnail
        }")`,
      }}
    ></div>
  );
};

export default ReleventProjects;
