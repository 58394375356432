import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function HeaderTabs(props) {
  const proposalTabList = [
    {
      name: "Overview",
      to: "overview",
    },
    {
      name: "Project Room",
      to: "project_room",
    },
    {
      name: props?.CurrentRole == 1 ? "Brief Details" : "Proposal Details",
      to: "proposal_details",
    },
    // {
    //   name : "Compliances",
    //   to : "compliance"
    // }
    // {
    //   name: "Files",
    //   to: "files",
    // },
  ];

  const [headItem, setHeadItem] = useState([0]);

  // const [headItem, setHeadItem] = useState([0, 1]);

  useEffect(() => {
    let check = props?.projectDataStates?.data?.creator_status_brief == 1 || props?.CurrentRole == 1 || props?.CurrentRole == 3;
    if (check) {
      proposalTabList.forEach((ele, i) => {
        if (props?.path1 == "projects" && props?.subroute == ele?.to && i > 1) {
          setHeadItem([i - 1, i]);
        }
      });
    }
  }, [props?.projectDataStates?.data?.creator_status_brief]);

  // const consditionSetHeadItem = (type) => {
  //   let length = proposalTabList.length - 1;
  //   let index = 0;
  //   if (type === "left") {
  //     index = headItem[0];
  //     if (index != 0) {
  //       setHeadItem([index - 1, index]);
  //     }
  //   } else {
  //     index = headItem[1];
  //     let check = props?.projectDataStates?.data?.creator_status_brief == 1 || props?.CurrentRole == 1 || props?.CurrentRole == 3;
  //     if (index != length && check) {
  //       setHeadItem([index, index + 1]);
  //     }
  //   }
  // };

  return (
    <>
      <div className="d-none d-md-block">
        <ul className="nav nav-pills pills_tab_nav  d-flex flex-nowrap header_ul_wrapper mb-4" id="pills-tab" role="tablist">
          {props.authIs ? (
            proposalTabList?.map((obj, index) => {
              return (
                <>
                  {(obj?.to === "overview" ||
                    obj?.to === "project_room" ||
                    ((props?.projectDataStates?.data?.creator_status_brief == 1 ||
                      props?.CurrentRole == 1 ||
                      props?.CurrentRole == 3) &&
                      (obj?.to === "proposal_details" || obj?.to === "compliance"))) && (
                    <li className="nav-item" role="presentation" key={index}>
                      <Link
                        className={`nav-link ${props?.path1 == "projects" && props?.subroute == obj?.to ? "active" : ""}`}
                        style={{ display: "flex", alignItems: "center" }}
                        to={`/projects/${props?.id}/${obj?.to}`}
                      >
                        {obj?.name}
                        {obj?.name === "Project Room" && props?.chatUnreadMes ? (
                          <span className="msg_tag ml-2" style={{ "font-size": "11px", padding: "2px 6px" }}>
                            {props?.chatUnreadMes}
                          </span>
                        ) : null}
                      </Link>
                    </li>
                  )}
                </>
              );
            })
          ) : (
            <li className="nav-item" role="presentation">
              <Link className={`nav-link active`} to={`/projects/${props?.id}/overview`}>
                Overview
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="d-flex position-relative d-block d-md-none">
        {/* {props.authIs ? (
          <span className="header_left_icon" onClick={() => consditionSetHeadItem("left")}>
            <a
              class="nav-link nav_link_btn"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="false"
            >
              <i class="fa fa-caret-left"></i>
            </a>
          </span>
        ) : null} */}
        <ul className="nav nav-pills pills_tab_nav  d-flex flex-nowrap header_ul_wrapper mb-4" id="pills-tab" role="tablist">
          {props.authIs ? (
            proposalTabList?.length &&
            proposalTabList?.map((obj, index) => {
              return (
                <>
                  {headItem.includes(index) ? (
                    <li className="nav-item" role="presentation" key={index}>
                      <Link
                        className={`nav-link ${props?.path1 == "projects" && props?.subroute == obj?.to ? "active" : ""}`}
                        to={`/projects/${props?.id}/${obj?.to}`}
                      >
                        {obj?.name}
                      </Link>
                    </li>
                  ) : null}
                </>
              );
            })
          ) : (
            <li className="nav-item" role="presentation">
              <Link className={`nav-link active`} to={`/projects/${props?.id}/overview`}>
                Overview
              </Link>
            </li>
          )}
        </ul>

        {/* {props.authIs ? (
          <span className="header_right_icon" onClick={() => consditionSetHeadItem("right")}>
            <a
              class="nav-link nav_link_btn"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              <i class="fa fa-caret-right"></i>
            </a>
          </span>
        ) : null} */}
      </div>
    </>
  );
}

/* <li className="nav-item" role="presentation">
<Link
  className={`nav-link ${props?.path1 == "projects" && props?.subroute == "overview" ? "active" : ""}`}
  to={`/projects/${props?.id}/overview`}
>
  Overview
</Link>
</li>
<li className="nav-item" role="presentation">
<Link
  className={`nav-link ${props?.path1 == "projects" && props?.subroute == "project_room" ? "active" : ""}`}
  to={`/projects/${props?.id}/project_room`}
>
  Project Room
</Link>
</li>
{(props?.projectDataStates?.data?.creator_status_brief == 1 || props?.CurrentRole == 1 || props?.CurrentRole == 3) && (
<>
  <li className="nav-item" role="presentation">
    <Link
      className={`nav-link ${props?.path1 == "projects" && props?.subroute == "proposal_details" ? "active" : ""}`}
      to={`/projects/${props?.id}/proposal_details`}
    >
      Project Details
    </Link>
  </li>
  <li className="nav-item" role="presentation">
    <Link
      className={`nav-link ${props?.path1 == "projects" && props?.subroute == "compliance" ? "active" : ""}`}
      to={`/projects/${props?.id}/compliance`}
    >
      Compliances
    </Link>
  </li>
  <li className="nav-item" role="presentation">
    <Link
      className={`nav-link ${props?.path1 == "projects" && props?.subroute == "files" ? "active" : ""}`}
      to={`/projects/${props?.id}/files`}
    >
      Files
    </Link>
  </li>
</>
)} */
