import React, { Component } from 'react'
import { connect } from 'react-redux'
import { postAPI } from '../../../../utils/API'
import ModalBody from '../../Common/FeedbackModal/ModalBody'

class ClientRejectsCreator extends Component {

    
  state = {
    checkboxListToRejectCreator: [
      {text: "I am not satisfied with the creator's proposal"},
      {text: "I am not satisfied with the creator's responses"},
      {text: "I have found another creator on your platform"},
      {text: "I have found another creator outside IndieFolio"},
      {text: "The creator is not responsive"},
      {text: "I have decided to delay the project"},
      {text: "I have decided to cancel the project"},
      {text: "Other"},
    ]
  }

  handleSubmit = async (data) => {
    const { Me, chat,ProjectStatus,ProjectType,Projects } = this.props;
    const {FirstName, LastName, CurrentRole}  = Me;
    const {chat_connection_id, user_id} = chat.CurrentChat
    const { ProjectId  } = chat
    const currentProject = Projects?.filter(el=>el.post_project_id === ProjectId)[0]
    const obj = {
        post_project_id: ProjectId,
        user_type: CurrentRole === 1 ? 'client' : 'creator',
        reason: data.reason,
        description: data.description,
        user_id: user_id
      }
 
    const response1 = await postAPI('/project/client-reject-postproject', obj);
    const response2 = await postAPI('/project/reject-creator-client', {connection_id: chat_connection_id});

    if(parseInt(response1.data.status) === 1 && parseInt(response2.data.status) === 1 ){ 
        if(CurrentRole === 1) {
          const obj = {
            projectId:ProjectId,
            projectName:currentProject?.project_name,
            projectStatus: ProjectStatus,
            projectType:ProjectType,
          }
          this.props.loadChat(obj)
            alert(`Thank you, ${FirstName} ${LastName}. We have rejected the creator on your behalf and recorded your feedback.`)
            this.props.closePopup()
        }
    }
        
  } 


  handleSubmitToRejectCreatorOnTalentConnectProfile = async (data) => { 
    const { Me,ProjectIdd, selectedCreator, postProjectClientBreif } = this.props;
    const {FirstName, LastName, CurrentRole}  = Me;
    const creatorData = postProjectClientBreif.creator.filter(el=>el.creator_id._id === selectedCreator)[0]
    const obj = {
        post_project_id: ProjectIdd,
        user_type: CurrentRole === 1 ? 'client' : 'creator',
        reason: data.reason,
        description: data.description,
        user_id: selectedCreator
      }
 
    const response1 = await postAPI('/project/client-reject-postproject', obj);
    const response2 = await postAPI('/project/reject-creator-client', {connection_id: creatorData.chat_connection_id});

    if(parseInt(response1.data.status) === 1 && parseInt(response2.data.status) === 1 ){
     
        if(CurrentRole === 1) {
            this.props.getPostProjectClientBrief(ProjectIdd)
            alert(`Thank you, ${FirstName} ${LastName}. We have rejected the creator on your behalf and recorded your feedback.`)
            this.props.closePopup()
        }
    }        
  } 


  handleSubmitHandler=(data)=>{
    const path = window.location.pathname.split('/')[1].toLowerCase();
    if(path === 'talentconnect'){
      this.handleSubmitToRejectCreatorOnTalentConnectProfile(data)      
    }else{
      this.handleSubmit(data)
    }
  }
    render() {
        const { Me: {CurrentRole} } = this.props
        return (
            <>
                <div className="modal fade custom_form verify_modal edit-basic-profile show" style={{
                paddingRight: "0px !important",
                display:'block'
                }} id="ClientRejectsCreator" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <span className="close_icon" data-dismiss="modal" aria-label="Close">
                    <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
                </span>
                <ModalBody 
                    headerText="Reasons for rejecting the creator"
                    buttonText='Confirm'
                    checkboxList={CurrentRole === 1 ? this.state.checkboxListToRejectCreator : []} 
                    submitHandler={this.handleSubmitHandler} 
                />
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}>
                </div>
             </>
        )
    }
}

const mapState = (store) => {
    let creatorProfile = store.creatorProfile;
    let { Me } = store.globalReducer;
    let chat = store.chat
    return {
      closeModal: creatorProfile.closeModal,
      Me: Me,
      chat: chat,
    };
  };
  const mapDispatch = dispatch => ({
    loadChat: (obj) => dispatch({ type: 'UPDATE_LOAD_CHAT', payload: obj }),
    getPostProjectClientBrief: (data) => dispatch({ type: 'GET_POST_PROJECT_CLIENT_BRIEF', payload: data }),
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    rejectIncomingBrief: (obj) => dispatch({ type: 'REJECT_INCOMING_BRIEF', payload: obj }),
  });

export default connect(mapState, mapDispatch)(ClientRejectsCreator)

