import React, { useEffect } from "react";
import PercentIcon from "../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import moment from "moment";
import { separateNumber } from "../../../ProfileCreator/TimeRetainer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import { handleSelectedNames, onkeyCode, retainerTableValues } from "../CommonFunctionsNew/CommonFunctionsNew";
import { sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";

export default function ReviewProposal({
  showReviewProp,
  setShowReviewProp,
  handleSubmitApi,
  states,
  propType,
  gst_total_price,
  total_price,
  totalEarning,
  loader,
}) {
  const _ProjectsIs = useSelector((state) => state.releventProjects);

  const handleTerms = (action) => {
    let name = "";
    let result = "";
    if (action === 1) {
      name = "Proposed Duration";
      result =
        states?.propDuration?.prop_dur_num +
        ` ${states?.propDuration?.prop_dur_num > 1 ? states?.propDuration?.prop_duration?.label : "Month"}`;
    } else if (action === 2) {
      name = "Billing Cycle";
      result = states?.prefBillingCycle?.label;
    }

    return (
      <div className="row mx-0 justify-content-between align-items-center">
        <div className="review_name">
          <p className="text_gray_xs_w_600">{name}</p>
        </div>
        <div className="review_date">
          <p className="text_light_gray_xs">{result}</p>
        </div>
      </div>
    );
  };

  const handleTimeCommitment = (action) => {
    let obj = retainerTableValues(action, states, propType);
    let name = obj?.name;
    let result = obj?.result;

    return (
      <div className="row mx-0 justify-content-between align-items-center">
        <div className="review_name">
          <p className="text_gray_xs_w_600">{name}</p>
        </div>
        <div className="review_date">
          <p className="text_light_gray_xs">{result}</p>
        </div>
      </div>
    );
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      setShowReviewProp(false);
    }
  };

  const noteText = states?.note ? sanitiseSummernoteText(states?.note.replace(/\n/g,'<br/>')) : "";

  return (
    <section className="review_confirm">
      <div
        className={`modal fade modal_fade_bg ${showReviewProp ? "show" : ""}`}
        id="reviewConfirm"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showReviewProp ? "block" : "" }}
      >
        <div className="modal-dialog modal-dialog-centered review_confirm_modal_dialog m-auto">
          <div className="modal-content">
            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
              <h5 className=" main_modal_title" id="exampleModalLabel">
                Review Proposal
              </h5>
              {propType === "fixed" ? (
                <div className="tag_btn_common">
                  <p className="mb-0">
                    <img src={PercentIcon} className="icon_img mr-1" />
                    Fixed Scope
                  </p>
                </div>
              ) : propType === "retainer" ? (
                <div className="tag_btn_green_common">
                  <p className="mb-0">
                    <img src={PercentIconGreen} className="icon_img mr-1" />
                    Retainer
                  </p>
                </div>
              ) : (
                <div className="tag_btn_dark_green_common">
                  <p className="mb-0">
                    <img src={PercentIconDarkGreen} className="icon_img mr-1" />
                    On-Actuals
                  </p>
                </div>
              )}
            </div>

            <div className="modal-body py-0 px-0 select_revelant_modal_body">
              <div className="body_scroll">
                <div className="top_section px-4">
                  {/* Proposed start date*/}
                  <div className="row mx-0 py-4  justify-content-between align-items-center">
                    <div className="review_title">
                      <p className="mb-0 font_semi_bold">Proposed start date</p>
                    </div>
                    <div className="review_sub_title">
                      <p className="mb-0 text_light_gray_xs">{moment(states?.proposed_start_date).format("MMM D, YYYY")}</p>
                    </div>
                  </div>

                  {/* Time Commitment */}

                  {propType === "retainer" ? (
                    <>
                      <div className="row mx-0 py-4 br_tp">
                        <div className="col-lg-12 px-0">
                          <p className="mb-0 font_semi_bold">Time Commitment</p>
                        </div>
                      </div>
                      {/* list */}
                      {handleTimeCommitment(1)}
                      {handleTimeCommitment(2)}
                      {handleTimeCommitment(3)}
                    </>
                  ) : (
                    ""
                  )}

                  {/* Payment Terms */}

                  {propType === "retainer" ? (
                    <>
                      <div className="row mx-0 py-4 br_tp">
                        <div className="col-lg-12 px-0">
                          <p className="mb-0 font_semi_bold">Payment Terms</p>
                        </div>
                      </div>
                      {/* list */}
                      {handleTimeCommitment(4)}
                      {handleTimeCommitment(5)}
                    </>
                  ) : (
                    ""
                  )}

                  {/* Total Deliverables */}
                  {propType === "fixed" ? (
                    states?.deliverable.length > 0 && states?.deliverable?.[0]?.name?.length > 0 ? (
                      <>
                        <div className="row mx-0 py-4 br_tp">
                          <div className="col-lg-12 px-0">
                            <p className="mb-0 font_semi_bold">Total Deliverables</p>
                          </div>
                        </div>
                        {/* list */}
                        {states.deliverable.map((item, i) => {
                          return (
                            <div className="row mx-0 justify-content-between align-items-center">
                              <div className="review_name">
                                <p className="text_gray_xs_w_600">
                                  <span className="mr-3">{onkeyCode(i + 65)}</span>
                                  {item.name} {`${item.iteration > 1 ? `(${item.iteration} Iterations)` : ""}`}
                                </p>
                              </div>
                              <div className="review_date">
                                <p className="text_light_gray_xs">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {/* payment_milestone */}

                  {propType === "fixed" ? (
                    states?.payments.length > 0 && states?.payments?.[0]?.names?.length > 0 ? (
                      <>
                        <div className="row mx-0 py-4 br_tp">
                          <div className="col-lg-12 px-0">
                            <p className="mb-0 font_semi_bold">Payment Milestones</p>
                          </div>
                        </div>
                        {/* list */}
                        {states?.advanceFees?.breakup ? (
                          <div className="row mx-0 justify-content-between align-items-center">
                            <div className="review_name">
                              <p className="text_gray_xs_w_600">
                                <span className="mr-3">1.</span>Advance Fees
                              </p>
                            </div>
                            <div className="review_date">
                              <p className="text_light_gray_xs">₹ {separateNumber(states.advanceFees.breakup, "")}</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {states.payments.map((item, i) => {
                          return (
                            <div className="row mx-0 justify-content-between align-items-center">
                              <div className="review_name">
                                <p className="text_gray_xs_w_600">
                                  <span className="mr-3">{states?.advanceFees?.breakup ? i + 2 : i + 1}</span>
                                  {handleSelectedNames(item.names)}
                                </p>
                              </div>
                              <div className="review_date">
                                <p className="text_light_gray_xs">₹ {separateNumber(item?.breakup, "")}</p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {/* On Actual */}
                  {propType === "actual" ? (
                    <>
                      <div className="row mx-0 py-4 br_tp">
                        <div className="col-lg-12 px-0">
                          <p className="mb-0 font_semi_bold">Terms</p>
                        </div>
                      </div>
                      {handleTerms(1)}
                      {handleTerms(2)}
                    </>
                  ) : (
                    ""
                  )}

                  {propType === "actual" ? (
                    states?.deliverable.length > 0 && states?.deliverable?.[0]?.name?.length > 0 ? (
                      <>
                        <div className="row mx-0 py-4 br_tp">
                          <div className="col-lg-12 px-0">
                            <p className="mb-0 font_semi_bold">Deliverables</p>
                          </div>
                        </div>
                        {/* list */}
                        {states.deliverable.map((item, i) => {
                          return (
                            <div className="row mx-0 justify-content-between align-items-center">
                              <div className="review_name">
                                <p className="text_gray_xs_w_600">
                                  <span className="mr-3">{i + 1}.</span>
                                  {item.name} {`${item.iteration > 1 ? `(${item.iteration} Iterations)` : ""}`}
                                </p>
                              </div>
                              <div className="review_date">
                                <p className="text_light_gray_xs">₹ {separateNumber(item.breakup, "")} {` ( Your Earnings: ₹ ${separateNumber(item.breakup * .85, "")} )`}</p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {/* On Actual End */}

                  {/* References_Attached */}
                  <div className="row mx-0 py-4 br_tp justify-content-between align-items-center">
                    <div className="review_title">
                      <p className="mb-0 font_semi_bold">References</p>
                    </div>
                    <div className="review_sub_title">
                      <p className="mb-0 text_light_gray_xs">
                        {_ProjectsIs?.project_ids && _ProjectsIs?.project_ids?.length > 0 ? _ProjectsIs.project_ids.length : 0}
                        {_ProjectsIs?.project_ids?.length > 1 ? " Projects" : " Project"},{" "}
                        {_ProjectsIs?.link_image && _ProjectsIs?.link_image?.length > 0 ? _ProjectsIs.link_image.length : 0}
                        {_ProjectsIs?.link_image?.length > 1 ? " Attachments" : " Attachment"},{" "}
                        {_ProjectsIs?.urls && _ProjectsIs?.urls?.length > 0 ? _ProjectsIs.urls.length : 0}
                        {_ProjectsIs?.urls?.length > 1 ? " URLs" : " URL"}
                      </p>
                    </div>
                  </div>

                  {/*notes */}
                  {states?.note?.trim()?.length > 0 ? (
                    <div className="row mx-0 py-4 br_tp">
                      <div className="col-lg-12 px-0">
                        <p className="mb-0 font_semi_bold mb-3">Additional Notes</p>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div className="text_gray_xs_w_600" dangerouslySetInnerHTML={{ __html: noteText }} />
                        {/* <p className="text_gray_xs_w_600 break-word">{states?.note}</p> */}
                      </div>
                    </div>
                  ) : null}
                </div>

                {propType === "actual" ? (
                  <div className="btm_section px-4">
                    <div className="row mx-0 py-4 align-items-center">
                      <div className="review_title">
                        <p className="mb-0 text_black_xs">
                          The amount you’ll receive depends on items approved by client.
                          {/* <img src={ExclamationIcon} className="img-fluid ml-1" /> */}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="btm_section px-4">
                    <div className="row mx-0 py-4  justify-content-between align-items-center">
                      <div className="review_title">
                        <p className="mb-0 font_semi_bold">{propType === "retainer" ? "Retainer Fee" : "Total Cost To Client"}</p>
                      </div>
                      <div className="review_sub_title">
                        <p className="mb-0 text_gray_md_w_700 text-right">
                          ₹{" "}
                          {propType === "retainer"
                            ? separateNumber(states?.fees?.retainerFees, "")
                            : separateNumber(total_price, "")}
                          {propType === "retainer" ? `/${states?.retainer_fees_type?.label === "Hourly" ? "Hour" : "Month"}` : ""}
                        </p>
                        <span class="d-block text-right text_light_gray_xxs_w_600">+ Applicable Taxes</span>
                      </div>
                    </div>
                    {/* receive */}
                    <div className="row mx-0  br_tp py-4  justify-content-between align-items-center">
                      <div className="review_title">
                        <p className="mb-0 font_semi_bold">
                          {/* <div
                            class="position-relative tool"
                            data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable 
                      taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                          ></div> */}
                          {propType === "retainer" ? "Your Earnings" : `You'll Receive`}
                          {/* <img src={ExclamationIcon} className="img-fluid ml-1" /> */}
                        </p>
                      </div>
                      <div className="review_sub_title">
                        <p className="mb-0 text_highlight_md">
                          ₹{" "}
                          {propType === "retainer"
                            ? separateNumber(states?.fees?.retainerFees * 0.85, "")
                            : separateNumber(totalEarning, "")}
                          {propType === "retainer" ? `/${states?.retainer_fees_type?.label === "Hourly" ? "Hour" : "Month"}` : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer px-4 py-3 border-0 justify-content-between align-items-center">
              <div className="max_projects m-0">
                <p className="mb-0 text_highlight cursor-pointer">
                  <a onClick={() => setShowReviewProp(false)}>Back to Editing</a>
                </p>
              </div>
              <div className="add_project m-0">
                <button type="button" class="btn btn-common btn_common_lg" onClick={() => handleSubmitApi()}>
                  {loader ? <Loader isCreateModal={true} /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
