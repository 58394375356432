import  axios from './axios';
// import store from '../store/index.store'
import {Baseurl} from './BaseUrl';

// console.log(store.getState())

export const postAPI = (path, data) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method: 'post',
        url: Baseurl + path,
        headers: { 'content-type': 'application/json', 'Authorization': 'Bearer ' + token},
        data:data
    })
}

export const putAPI = (path, data) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method: 'put',
        url: Baseurl + path,
        headers: { 'content-type': 'application/json', 'Authorization': 'Bearer ' + token},
        data:data
    })
}

export const deleteAPI = (path, data) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method: 'delete',
        url: Baseurl + path,
        headers: { 'content-type': 'application/json', 'Authorization': 'Bearer ' + token},
        data:data
    })
}

export const postAPIM = (path, data) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method: 'post',
        url: Baseurl + path,
        headers: { 'content-type': 'multipart/form-data', 'Authorization': 'Bearer ' + token},
        data:data
    })
}

export const getAPI = (path) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method : 'get',
        url : Baseurl + path,
        headers: { 'content-type': 'application/json' , 'Authorization': 'Bearer ' + token},
    })
}

export const getAPIPath = (path) => {
    const token = localStorage.getItem('SignUpidToken' );
    return axios({
        method : 'get',
        url : path,
        headers: { 'content-type': 'application/json' , 'Authorization': 'Bearer ' + token},
    })
}
export const getPublicAPI = (path) => {
    return axios({
        method : 'get',
        url : Baseurl + path,
        headers: { 'content-type': 'application/json'},
    })
}

export const postPublicAPI = (path ,data) => {
    return axios({
        method : 'post',
        url : Baseurl + path,
        headers: { 'content-type': 'application/json'},
        data:data
    })
}