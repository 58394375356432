import React, { useEffect, useState } from "react";
import { getAPI, putAPI } from "../../../../../utils/API";
import { workDays, handleProposalData } from "../CommonFunctionsNew/CommonFunctionsNew";
import { useDispatch, useSelector } from "react-redux";
import { relevent_projects, projectAttachments, projectUrls } from "../../../../../store/action/proposal.action";
import ViewPropShow from "./ViewPropShow";
import Loader from "../../../Common/Common/Loader";
import MilestonePayCreator from "./MilestonePayCreator";
import SuccessAdvancePay from "../PopupAll/SuccessAdvancePay";
import { NotResponsivePopUp } from "../PopupAll/NotResponsivePopUp";

export default function ViewProp(props) {
  const [states, setStates] = useState({
    deliverable: [],
    payments: [],
    advanceFees: {},
    note: "",
    proposed_start_date: "",
    type: "",
    propDuration: {
      prop_duration: "",
      prop_dur_num: 1,
    },
    numOfHours: {
      day_week: "",
      num_hour: 1,
    },
    fees: {
      retainerFees: "",
      advanceFees: "",
    },
    workingDaysInWeek: workDays,
    propDuration: {
      prop_duration: { label: "Week", value: "Week" },
      prop_dur_num: 1,
    },
    prefBillingCycle: { label: "Monthly", value: "Monthly" },
    sub_total_cost: 0,
    total_earning: 0,
    proposalData: [],
    retainer_fees_type: "",
    number: "",
  });
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("");
  const [checkStatus, setCheckStatus] = useState("");
  const [proposalId, setProposalId] = useState("");
  const [loader, setLoader] = useState(true);
  const [showSuccessAdvancePay, setShowSuccessAdvancePay] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // const { id, userId } = props.match.params;
    if (props?.projectDataStates?.id || props?.userId) {
      setId(props?.projectDataStates?.id);
      setUserId(props?.userId);
      if (!props?.projectDataStates?.projectDataloader) handleGetProposal(props?.projectDataStates?.id, props?.userId);
    }
  }, [props?.projectDataStates]);

  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const handleCallApiAgain = () => {
    handleGetProposal(props?.projectDataStates?.id, props?.userId);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: current_ids ? current_ids : [],
      releventProject: data?.allProjectData ? data.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.link_image ? data.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.urls ? data.urls : []));
  };

  const handleGetProposal = async (id, user_id) => {
    setLoader(true);
    let res = "";
    let isVisibleActProj = props?.projectDataStates?.data?.project_data?.status;
    try {
      if (isVisibleActProj == 6 || isVisibleActProj == 7) {
        if (props?.CurrentRole == 1 || props?.CurrentRole == 3) {
          res = await getAPI(`/proposal/get-latest-approved-proposal-data?user_id=${user_id}&id=${id}`);
        } else {
          res = await getAPI(`/proposal/proposal-latest-approved-data?id=${id}`);
        }
      } else {
        if (props?.CurrentRole == 1 || props?.CurrentRole == 3) {
          res = await getAPI(`/proposal/get-latest-proposal-data?user_id=${user_id}&id=${id}`);
        } else {
          res = await getAPI(`/proposal/proposal-payment?id=${id}`);
        }
      }
    } catch (err) {
      console.log("error");
    }
    if (res) {
      let data = res?.data?.AllProposal;
      // console.log("data == ", data);
      setCheckStatus(data?.status);
      getReferenceData(data);
      setProposalId(data?._id);
      let getProp = handleProposalData(states, data);
      setStates({ ...states, getProp });
      setLoader(false);
      props?.setPropsNum && props.setPropsNum(data?.number);
    } else {
      props.setUserError(true);
    }
  };

  const ApiCall = async (value) => {
    try {
      let obj = {
        status: value,
        post_project_id: props?.projectDataStates?.id,
      };
      let res = await putAPI(`/proposal/change-visible-status`, obj);
      if (res) {
        props.callApiAgain();
      }
    } catch (err) {
      alert("Something went wrong, please try again");
    }
  };

  let proj = props?.projectDataStates?.data?.project_data;
  const projectObj = proj?.visible_creator;

  return (
    <>
      {loader ? (
        <div className={`col-lg-${props?.col} px-0`}>
          <Loader isCreateModal={true} style={{ paddingTop: "20rem", paddingBottom: "20rem" }} />
        </div>
      ) : (
        <>
          {props?.isOverview && (checkStatus == 1 || proj?.status == 7) ? (
            (projectObj == 2 && checkStatus == 1) || props?.CurrentRole == 1 || proj?.status == 7 ? (
              <>
                <MilestonePayCreator
                  handleCallApiAgain={handleCallApiAgain}
                  role={props?.CurrentRole}
                  {...props}
                  state={states}
                  proposalId={proposalId}
                  projStatus={proj?.status}
                  projectObj={projectObj}
                  checkStatus={checkStatus}
                />
                <NotResponsivePopUp {...props} />
              </>
            ) : (
              <>
                <ViewPropShow
                  state={states}
                  ApiCall={ApiCall}
                  setState={setStates}
                  loader={loader}
                  {...props}
                  proposalId={proposalId}
                  approveProposal={true}
                  handleGetProposal={handleGetProposal}
                />
                {projectObj == 0 && <SuccessAdvancePay projectObj={projectObj} ApiCall={ApiCall} {...props} />}
              </>
            )
          ) : (
            <ViewPropShow
              state={states}
              ApiCall={ApiCall}
              setState={setStates}
              loader={loader}
              {...props}
              showReject={proj?.status < 6 ? true : false}
              proposalId={proposalId}
              handleGetProposal={handleGetProposal}
            />
          )}
        </>
      )}
    </>
  );
}
