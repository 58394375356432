import React from 'react';
// import ProgressBar from './Progressbar';
// import { connect } from 'react-redux';
// import { putAPI } from '../../../utils/API';
import { CollateralBaseUrl } from '../../../../utils/BaseUrl';

class Style extends React.Component {
  state = {
    showDropDownList: false,
    checkBoxImages: [],
    answer: [],
  };
  componentDidMount = () => {
    // const {
    //   styleAnswers: { answer },
    // } = this.props;
    const answer = this.props.styleAnswers ? this.props.styleAnswers : [];

    // console.log('STYLE ANSWER IN Style.js ', answer);
    // console.log('styleQuestions ', this.props.StyleQuestions);
    this.setState({
      checkBoxImages: answer,
      show_ans: answer?.[0]?.item,
      answer: answer,
    });
  };

  handleDropDownList = () => {
    const { showDropDownList } = this.state;
    this.setState({
      showDropDownList: !showDropDownList,
    });
  };

  ChooseCheckboxImage = (options) => {
    let checkBoxImages = [...this.state.checkBoxImages];
    const alreadySelected = checkBoxImages.some(
      (el) => el.item === options.item
    );
    let hasOther = checkBoxImages.some((el) => el.item === 'other');
    if (!alreadySelected) {
      if (options.item !== 'other' && hasOther === false) {
        checkBoxImages.push({
          item: options.item,
          image: options.image,
        });
      } else if (hasOther === true && options.item !== 'other') {
        checkBoxImages.splice(0, checkBoxImages.length, {
          item: options.item,
          image: options.image,
        });
      } else {
        checkBoxImages.splice(0, checkBoxImages.length, {
          item: options.item,
          image: options.image,
        });
      }
      this.setState(
        {
          checkBoxImages: checkBoxImages,
        },
        () => this.props.answer(this.state.checkBoxImages)
      );
    } else {
      let remove_selected = checkBoxImages.filter(function (value, index, arr) {
        return value.item !== options.item;
      });
      this.setState(
        {
          checkBoxImages: remove_selected,
        },
        () => this.props.answer(this.state.checkBoxImages)
      );
    }
  };

  SelectAnswer = (item) => {
    let answer = [...this.state.answer];
    const { showDropDownList } = this.state;
    answer.splice(0, 1, {
      item: item.item,
      image: item.image,
    });
    // console.log('SelectAnswer ', answer);
    this.setState(
      {
        answer: answer,
        show_ans: item.item,
        showDropDownList: !showDropDownList,
      },
      //   () => this.props.ChooseAnswer(this.state.answer)
      () => this.props.answer(this.state.answer)
    );
  };

  render() {
    const { StyleQuestions, error } = this.props;
    const { showDropDownList, checkBoxImages, answer, show_ans } = this.state;
    // console.log(StyleQuestions);
    // console.log('ERROR in styleQuestion.js', this.props.error);

    // const Background_1 = require('../../../Assets/images/s-1.jpg');
    // const Background_2 = require('../../../Assets/images/s-2.jpg');
    return (
      <>
        <section style={{ width: '100%' }} className="">
          <div className="card-wrapper mob-mb-70 mb-0">
            <div className="card mob-border-radius-0 box-shadow-none  border-0 bg-tranparent">
              <div className="card-body">
                {/* <div className="pt-py mob-pt-py">
                  <h2 className="font-22 mob-font-16 font-weight-600 mb-1">
                    Pick your desired style
                  </h2>
                  <p className="label font-weight-500 mob-font-12 text-third">
                    This will help us recommend relevant creators and also help
                    us understand your taste.
                  </p>
                  
                </div> */}

                {StyleQuestions.map((obj, index) => {
                  // {
                  //   /* console.log('StyleQuestions', obj); */
                  // }
                  if (obj.type === 'checkbox_with_images_with_text') {
                    return (
                      <div
                        key={index}
                        className="row m-0 mob-p-0 ipad-only-p-10 ipad-pro-only-p-10 py-1 px-0"
                      > 
                        <div className="col-12 col-sm-12 mob-p-0 p-0">
                          <div className="row m-0">
                            <div className="col-sm-12 p-0">
                              <div className="onboarding_style_questions">
                                {/* <h2 className="font-22 mob-font-16 font-weight-600 mb-2 text-capitalize">
                                    {obj.question}
                                  </h2> */}
                                <ul
                                  className="content"
                                  // style={{
                                  //   gridTemplateColumns:
                                  //     'repeat(auto-fill, minmax(135px, 1fr))',
                                  // }}
                                >
                                  {obj.option.map((options, index) => (
                                    <li key={index} className="position-relative">
                                      <div
                                        className={`deadline position-relative ${
                                          checkBoxImages.some(
                                            (el) => el.item === options.item
                                          )
                                            ? 'active'
                                            : ''
                                        }`}
                                        value={options.item}
                                        style={{
                                          backgroundImage: `url('${
                                            CollateralBaseUrl + options.image
                                          }')`,
                                          backgroundSize: 'cover', 
                                        }}
                                        onClick={(e) =>
                                          this.ChooseCheckboxImage(options)
                                        }
                                      ></div>
                                      <p className="style_name">{options.item.length > 15 ? options.item.substring(0,15) + '...' : options.item}</p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <p className="mb-0 text-danger font-weight-600 font-12 py-1">
                            Choose at least one.
                          </p>
                        ) : null}
                      </div>
                    );
                  }
                  // if (obj.type === "Checkbox") {
                  // 	return <>
                  // 		<h2 className="mb-2 font-22 mob-font-16 font-weight-600" style={{textTransform:"capitalize"}}>{obj.question}</h2>
                  // 		<div className="row align-items-center flex-wrap mb-4">
                  // 			{
                  // 				obj.option.map((options,index) => (
                  // 					<div key={index} className="col-sm-2 mob-mb-15" onClick={(e) => this.ChooseCheckboxImage(options.item)}>
                  // 						<span className={`select-btn  ${checkBoxImages.some(el => el.item === options.item) ? "active" : "" }`}>{options.item}</span>
                  // 					</div>
                  // 			))
                  // 			}
                  // 		</div>
                  // </>
                  // }
                  if (obj.type === 'radio_button_with_images_with_text') {
                    return (
                      <div
                        key={index}
                        className="row m-0 mob-p-0 ipad-only-p-10 ipad-pro-only-p-10 py-1 px-0"
                      > 
                        <div className="col-12 col-sm-12 mob-p-0 p-0">
                          <div className="row m-0">
                            <div className="col-sm-12 p-0">
                              <div className="onboarding_style_questions">
                                {/* <h2 className="font-22 mob-font-16 font-weight-600 mb-2 text-capitalize">
                                    {obj.question}
                                  </h2> */}
                                <ul
                                  className="content"
                                  // style={{
                                  //   gridTemplateColumns:
                                  //     'repeat(auto-fill, minmax(109px, 1fr))',
                                  // }}
                                >
                                  {obj.option.map((options, index) => (
                                    <li key={index} className="">
                                      <div
                                        className={`deadline position-relative ${
                                          answer.some(
                                            (el) => el.item === options.item
                                          )
                                            ? 'active'
                                            : ''
                                        }`}
                                        onClick={(e) =>
                                          this.SelectAnswer(options)
                                        }
                                        value={options.item}
                                        style={{
                                          backgroundImage: `url('${
                                            CollateralBaseUrl + options.image
                                          }')`,
                                          backgroundSize: 'cover', 
                                        }}
                                      ></div>
                                      <p className="style_name">{options.item.length > 15 ? options.item.substring(0,15) + '...' : options.item}</p>
                                    </li>
                                    // <div key={index} className="col-sm-2 mob-mb-15" onClick={(e) => this.SelectAnswer(options)}>
                                    //     <span className={`select-btn ${answer.some(el => el.item === options.item) ? "active" : ""}`}>{options.item}</span>
                                    // </div>
                                  ))}
                                </ul>
                                {/* <ul
                                    className="content"
                                    style={{
                                      gridTemplateColumns:
                                        'repeat(auto-fill, minmax(109px, 1fr))',
                                    }}
                                  >
                                    {obj.option.map((options, index) => (
                                      <li key={index} className="">
                                        <div
                                          className={`deadline position-relative ${
                                            checkBoxImages.some(
                                              (el) => el.item === options.item
                                            )
                                              ? 'active'
                                              : ''
                                          }`}
                                          value={options.item}
                                          style={{
                                            backgroundImage: `url('${
                                              CollateralBaseUrl + options.image
                                            }')`,
                                            backgroundSize: 'cover',
                                            height: '109px',
                                          }}
                                          onClick={(e) =>
                                            this.SelectAnswer(options)
                                          }
                                        ></div>
                                      </li>
                                    ))}
                                  </ul> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <p className="mb-0 text-danger font-weight-600 font-12 py-1">
                            Choose at least one.
                          </p>
                        ) : null}
                      </div>
                    );
                  }

                  if (obj.type.toLowerCase() === 'dropdown') {
                    return (
                      <div
                        key={index}
                        className="filter-dropdown wrapper-dropdown position-relative"
                      >
                        <div className="select">
                          <div className="select">
                            <div className={`select-styled text-black ${error ? 'border-error' : ''}`} 
                            onClick={this.handleDropDownList}
                            > {show_ans ? show_ans : 'Choose here'} </div>
                            {obj.option.length > 0 &&
                            showDropDownList === true ? (
                              <ul
                                className="select-options hideList"
                                style={{ display: 'block' }}
                              >
                                <li rel="hide">2020</li>
                                {obj.option.map((options, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) => this.SelectAnswer(options)}
                                  >
                                    {options.item}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <i
                          className="fas fa-chevron-down select-arrow"
                          style={{ top: '20px', right: '20px' }}
                        ></i>
                      </div>
                    );
                  } else return null
                  // if(obj.type === "Text") {
                  // 	return <>
                  // 		<div className="form-group mb-4 col-sm-12 pl-0 mob-p-0">
                  // 			<h2 className="pt mob-pt font-22 mob-font-16 font-weight-600 " style={{textTransform:"capitalize"}}>{obj.question}</h2>
                  // 			<textarea className="form-control email min-h-100 font-weight-600" onChange={(e) => this.SelectAnswer(e.target.value)}></textarea>
                  // 		</div>
                  // 	</>
                  // }
                })}
              </div>
              {/* <ProgressBar   handleContinue={this.handleContinue} /> */}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Style;
