import {
  CLOSE_POPUP,
  OPEN_POPUP,
  UPDATE_FIRSTNAME,
  UPDATE_LASTNAME,
  UPDATE_ABOUT,
  UPDATE_EDUCATION,
  UPDATE_SOFTWARE,
  UPDATE_SPECIALIZATION,
  UPDATE_COLLATERAL,
  UPDATE_LOCATION,
  UPDATE_OCCUPATION,
  UPDATE_AVATAR,
  UPDATE_BASIC_FIRSTNAME,
  UPDATE_BASIC_LASTNAME,
  UPDATE_BASIC_ABOUT,
  UPDATE_BASIC_LOCATION,
  UPDATE_BASIC_AVATAR,
  LOAD_SPECIALIZATION,
  OPEN_SPECIALIZATION,
  LOAD_LOCATIONS,
  UPDATE_BASIC_PRIMARYFIELDWORK,
  LOAD_CURRENTWORKSTATUS,
  UPDATE_CREATORBASIC,
  UPDATE_CREATOR,
  UPDATE_BASIC_ERROR,
  UPDATE_SOFTWARE_UPDATE_ERROR,
  UPDATE_CURRENTWORKSTATUS,
  DELETE_CREATOR_WORK,
  DELETE_USEREDUCATION,
  ADD_USEREDUCATION,
  UPDATE_SOFTWARE_UPDATE,
  UPDATE_USEREDUCATION_EDITUPDATE,
  UPDATE_USERWORK_EDITWORK,
  UPDATE_SPECIALIZATION_UPDATE,
  UPDATE_SPECIALIZATION_UPDATE_ERROR,
  UPDATE_CREATOR_PROJECT,
  UPDATE_CREATOR_REVIEWS,
  UPDATE_CREATOR_WORK,
  LOAD_CREATOR_ALLTOOLS,
  UPDATE_BASIC_FIRSTNAME_ERROR,
  UPDATE_BASIC_LASTNAME_ERROR,
  UPDATE_BASIC_ABOUT_ERROR,
  UPDATE_BASIC_LOCATION_ERROR,
  UPDATE_BASIC_AVATAR_ERROR,
  UPDATE_BASIC_PRIMARYFIELDWORK_ERROR,
  LOAD_SOCIALPROFILE,
  UPDATE_CREATOR_PUBLIC,
  UPDATE_LANGUAGE_UPDATE,
  UPDATE_LANGUAGE,
  UPDATE_REVIEW_PROJECT_ID,
  UPDATE_AWARD_UPDATE,
  UPDATE_AWARD_DELETE,
  // UPDATE_ADD_AWARD,
  UPDATE_AWARD,
  ADD_AWARD,
  LOAD_AWARDS,
  UPDATE_CREATOR_CLIENTS,
  UPDATE_EXPERIENCE_YEAR,
  UPDATE_CREATOR_DATA_FETCHING,
  UPDATE_CREATOR_INDUSTRIES,
  UPDATE_PRIMARYSPECIALIZATION_EXP,
} from '../type.action';

 
  import {UPDATE_ADDCOLLATERAL, UPDATE_DELETECOLLATERAL, UPDATE_UPDATECOLLATERAL, } from '../action/userAddCollateral.action'

const initState = {
  public: true,
  closeModal: false,
  Component: '',
  EnableSpecialization: false,
  Specialization: [
    {
      name: '3D',
    },
    {
      name: '6D',
    },
  ],
  UpdateSpecialization: [],
  UpdateSpecializationError: false,
  Locations: [
    {
      name: '',
    },
  ],
  PrimaryFieldWork: '',
  FirstName: '',
  LastName: '',
  Avatar: '',
  Location: '',
  Country: '',
  About:
    'Product Designer from belgrade. Working with small to medium startups. Passion projects and raising a kid in spare time. Say hello@designeat.com',
  CurrentWorkStatus: '',
  BasicDetails: {
    FirstName: '',
    LastName: '',
    Avatar: '',
    About:
      'Product Designer from belgrade. Working with small to medium startups. Passion projects and raising a kid in spare time. Say hello@designeat.com',
    Location: '',
    PrimaryFieldWork: '',
    FirstNameError: false,
    FirstNameErrorMessage: '',
    LastNameError: false,
    LastNameErrorMessage: '',
    AvatarError: false,
    AvatarErrorMessage: '',
    AboutError: false,
    AboutErrorMessage: '',
    LocationError: false,
    LocationErrorMessage: '',
    PrimarySpecializationError: false,
    PrimarySpecializationErrorMessage: '',
  },
  UserReview: {
    Rating: '1.2',
    Reviews: '48',
  },
  RepeatHire: '2',
  ProjectCompleted: '3',
  AvgResponseTime: '4',
  ExperienceYear: '2',
  Reviews: [],
  SocialProfile: {
    behance_url: '',
    createdAt: '',
    custom_url: '',
    dribbble_url: '',
    indiefolio_url: '',
    instagram_url: '',
    personal_url: '',
    vimeo_url: '',
    resume: '',
  },
  AddCollateral: {
    Name: '',
    EndPrice: '',
    StartPrice: '',
    Experience: '',
    Time: '',
    Type: '',
  },
  CollateralListing: [],
  Specilizations: [],
  Tools: [],
  UpdateTools: [],
  UpdateLanguages: [],
  UpdateToolsError: false,
  AllTools: [],
  Industries: [],
  Experience: [],
  Education: [],
  Languages: [],
  Awards: [],
  UpdateAwards: [],
  Projects: {
    UIUX: [],
    Logo: [],
    Brochure: [],
  },
  creatorClients: [],
  AllProjects: [],
  isFetching: false,
  UserIndustries:[],
    PrimaryFieldWorkExperience:"",
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};
  let obj = {};
  let BasicDetails = {};
  // let SocialProfile = {};
  let Experience = {};
  let i = -1;

  switch (type) {
    case UPDATE_CREATOR_PUBLIC:
      newState = Object.assign({}, state, {
        public: values,
      });
      return newState

    case UPDATE_CREATOR_DATA_FETCHING:
      newState = Object.assign({}, state, {
        isFetching: values,
      });
      return newState;

    case CLOSE_POPUP:
      newState = Object.assign({}, state, {
        closeModal: false,
        Component: '',
      });
      return newState;
    case UPDATE_CREATOR:
      newState = Object.assign({}, state, values);
      return newState;
    case UPDATE_LOCATION:
      newState = Object.assign({}, state, {
        Location: values,
      });
      return newState;
    case UPDATE_OCCUPATION:
      newState = Object.assign({}, state, {
        Occupation: values,
      });
      return newState;
    case UPDATE_AVATAR:
      newState = Object.assign({}, state, {
        Avatar: values,
      });
      return newState;
    case UPDATE_FIRSTNAME:
      newState = Object.assign({}, state, {
        FirstName: values,
      });
      return newState;

    case UPDATE_LASTNAME:
      newState = Object.assign({}, state, {
        LastName: values,
      });
      return newState;
    case UPDATE_ABOUT:
      newState = Object.assign({}, state, {
        About: values,
      });
      return newState;
    case OPEN_POPUP:
      // console.log("test")
      newState = Object.assign({}, state, {
        closeModal: true,
        Component: values,
      });
      return newState;
    case OPEN_SPECIALIZATION:
      newState = Object.assign({}, state, {
        EnableSpecialization: true,
      });
      return newState;
    case UPDATE_EDUCATION:
      newState = Object.assign({}, state, {
        Education: values,
      });
      return newState;
    case UPDATE_SOFTWARE:
      newState = Object.assign({}, state, {
        Tools: values,
      });
      return newState;
    case UPDATE_SPECIALIZATION:
      newState = Object.assign({}, state, {
        Specilizations: values,
      });
      return newState;

      case UPDATE_PRIMARYSPECIALIZATION_EXP:
        newState = Object.assign({},state,{
          PrimaryFieldWorkExperience:values,
        })
        return newState;
        
    case UPDATE_COLLATERAL:
      newState = Object.assign({}, state, {
        CollateralListing: values,
      });
      return newState;

    case UPDATE_CREATOR_WORK:
      Experience = Object.assign([], state.Experience);
      Experience.push(values);

      newState = Object.assign({}, state, {
        Experience: Experience,
      });
      return newState;

    case DELETE_CREATOR_WORK:
      Experience = Object.assign([], state.Experience);
      i = -1;
      Experience.forEach((e, k) => {
        if (e._id === values) i = k;
      });

      if (i !== -1) Experience.splice(i, 1);

      newState = Object.assign({}, state, {
        Experience: Experience,
      });
      return newState;

    case UPDATE_USERWORK_EDITWORK:
      Experience = Object.assign([], state.Experience);
      Experience.forEach((e, k) => {
        if (e._id === values._id) Experience[k] = values;
      });

      newState = Object.assign({}, state, {
        Experience: Experience,
      });
      return newState;

    case UPDATE_USEREDUCATION_EDITUPDATE:
      Experience = Object.assign([], state.Education);
      Experience.forEach((e, k) => {
        if (e._id === values._id) Experience[k] = values;
      });

      newState = Object.assign({}, state, {
        Education: Experience,
      });
      return newState;

    case ADD_USEREDUCATION:
      // console.log('reduces creator profile', values);
      Experience = Object.assign([], state.Education);
      Experience.push(values);

      newState = Object.assign({}, state, {
        Education: Experience,
      });
      return newState;

    case DELETE_USEREDUCATION:
      Experience = Object.assign([], state.Education);
      i = -1;
      Experience.forEach((e, k) => {
        if (e._id === values) i = k;
      });

      if (i !== -1) Experience.splice(i, 1);

      newState = Object.assign({}, state, {
        Education: Experience,
      });
      return newState;

    case LOAD_SPECIALIZATION:
      newState = Object.assign({}, state, {
        Specialization: values,
      });
      return newState;

    case LOAD_LOCATIONS:
      newState = Object.assign({}, state, {
        Locations: values,
      });
      return newState;

    case LOAD_CURRENTWORKSTATUS:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_CURRENTWORKSTATUS:
      newState = Object.assign({}, state, {
        CurrentWorkStatus: values,
      });
      return newState;

    case LOAD_SOCIALPROFILE:
      // console.log('creator reducer ', values);
      newState = Object.assign({}, state, {
        SocialProfile: Object.assign({}, state.SocialProfile, values),
      });
      return newState;

    case LOAD_AWARDS:
      // console.log(values);
      newState = Object.assign({}, state, {
        Awards: values,
      });
      return newState;

    case LOAD_CREATOR_ALLTOOLS:
      newState = Object.assign({}, state, {
        AllTools: values,
      });
      return newState;

    case UPDATE_SOFTWARE_UPDATE:
      newState = Object.assign({}, state, {
        UpdateTools: values,
      });
      return newState;

    case UPDATE_LANGUAGE_UPDATE:
      newState = Object.assign({}, state, {
        UpdateLanguages: values,
      });
      return newState;

    case UPDATE_LANGUAGE:
      newState = Object.assign({}, state, {
        Languages: values,
      });
      return newState;

    case UPDATE_AWARD_UPDATE:
      let Awards = [...state.Awards];
      // // console.log('reduces update_awards awards', Awards);
      // console.log('reduces update_awards', values);
      Awards.forEach((e, k) => {
        if (e._id === values._id) Awards[k] = values;
      });

      newState = Object.assign({}, state, {
        Awards: Awards,
      });
      return newState;

    case UPDATE_AWARD_DELETE:
      // let Awards = state.Awards;
      // // // console.log('reduces update_awards awards', Awards);
      // // console.log('reduces update_awards', values);
      // Awards.forEach((e, k) => {
      //   if (e._id === values._id) Awards[k] = values;
      // });

      newState = Object.assign({}, state, {
        Awards: state.Awards.filter(el => el._id !== values),
      });
      return newState;

    // case UPDATE_ADD_AWARD:
    //   // const awards = state.Awards;

    //   newState = Object.assign({}, state, {
    //     Awards: [...state.Awards, values],
    //   });
    //   return newState;

    case UPDATE_AWARD:
      newState = Object.assign({}, state, {
        Awards: values,
      });
      return newState;

    case ADD_AWARD:
      const newAwards = state.Awards;
      newAwards.push(values);
      newState = Object.assign({}, state, {
        Awards: [...newAwards],
      });
      return newState;

    case UPDATE_UPDATECOLLATERAL:
      // console.log('%c UPDATE COLLATERAL', 'font-size: 22px; color:orange');
      // console.log(values);
      
      Experience = Object.assign([], state.CollateralListing);
      Experience.forEach((e, k) => {
        if (e._id === values._id) Experience[k] = values;
      });

      newState = Object.assign({}, state, {
        CollateralListing: Experience,
      });

      return newState;

    case UPDATE_ADDCOLLATERAL:
      Experience = Object.assign([], state.CollateralListing);
      Experience.push(values);
      //  forEach((e, k) => {
      //   if (e._id === values._id)
      //     Experience[k] = values;
      // });

      newState = Object.assign({}, state, {
        CollateralListing: Experience,
      });

      return newState;

    case UPDATE_DELETECOLLATERAL:
      newState = Object.assign({}, state, {
        CollateralListing: state.CollateralListing.map(el => {
          if(el._id === values){
             el.status = 4
             return el
          } else {
            return el
          }
        }),
      });
      return newState;

    case UPDATE_SPECIALIZATION_UPDATE:
      newState = Object.assign({}, state, {
        UpdateSpecialization: values,
      });
      return newState;

    case UPDATE_SPECIALIZATION_UPDATE_ERROR:
      newState = Object.assign({}, state, {
        UpdateSpecializationError: values.error,
      });
      return newState;

    case UPDATE_CREATOR_PROJECT:
      obj = { ...state.Projects };
      obj.UIUX = values;
      newState = Object.assign({}, state, {
        Projects: obj,
      });
      return newState;

    case UPDATE_CREATOR_REVIEWS:
      newState = Object.assign({}, state, {
        Reviews: values.newData
      });
      return newState;

    // Change in BasicDetails.
    case UPDATE_CREATORBASIC:
      BasicDetails = Object.assign({}, state.BasicDetails, values);
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, values);
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_FIRSTNAME:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        FirstName: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_PRIMARYFIELDWORK:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        PrimaryFieldWork: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_LASTNAME:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        LastName: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_ABOUT:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        About: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_LOCATION:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        Location: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_AVATAR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        Avatar: values,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_FIRSTNAME_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        FirstNameError: values.error,
        FirstNameErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_LASTNAME_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        LastNameError: values.error,
        LastNameErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_ABOUT_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        AboutError: values.error,
        AboutErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_LOCATION_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        LocationError: values.error,
        LocationErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_AVATAR_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        AvatarError: values.error,
        AvatarErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_BASIC_PRIMARYFIELDWORK_ERROR:
      BasicDetails = Object.assign({}, state.BasicDetails, {
        PrimarySpecializationError: values.error,
        PrimarySpecializationErrorMessage: values.message,
      });
      newState = Object.assign({}, state, { BasicDetails });
      return newState;

    case UPDATE_SOFTWARE_UPDATE_ERROR:
      newState = Object.assign({}, state, {
        UpdateToolsError: values.error,
      });
      return newState;
    case UPDATE_REVIEW_PROJECT_ID:
      newState = Object.assign({}, state, {
        UpdateReviewProjectId: values,
      });
      return newState;

    case UPDATE_CREATOR_CLIENTS:
      // console.log('creator profile reducer user_clients', values);
      newState = Object.assign({}, state, {
        creatorClients: values,
      });
      return newState;

    case UPDATE_EXPERIENCE_YEAR:
      newState = Object.assign({}, state, {
        ExperienceYear: values,
      });
      return newState;

      case UPDATE_CREATOR_INDUSTRIES:
        newState = Object.assign({},state,{
          UserIndustries:values,
        });
      return newState;
    default:
      return state;
  }
};

export default reducers;
