import React from 'react';
import { connect } from 'react-redux';
// import { getAPI } from '../../../../utils/API';

class MobilePlusButtonDropdown extends React.Component {
  closePopup = () => {
    this.props.closePopup()
  }

  handleSubmitMilestone = () => {
    this.props.history.push('/submitMilestone')
    this.closePopup()
  }

  render() {
    return (
      <>
        <div className="modal fade custom_form verify_modal custom_Modal_Box show" id="subHeaderDropdown" style={{ paddingRight: "0px !important", "display": "block"}} tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div type="button" className="close_icon" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
            </span>
          </div>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content border-0 position-bottom-modal">
                    <div className="modal-body">
                        <div className="card-wrapper mb-0">
                            <div className="card">
                                <div className="card-body">
                                    <li onClick={this.props.openAttachment} className="border-bottom-1 d-flex align-items-center px-15 py-12" data-toggle="modal" data-target="#mobileSearchMessage">
                                        <span className="w-8"><i className="fas fa-link text-secondary font-16"></i></span>
                                        <span className="font-15 text-dark-black font-weight-600">Add attachment</span> 
                                    </li> 
                                    {this.props.proposalStatus === 2 ? null :
                                      (this.props.ProjectStatus === 5 && this.props.CurrentRole === 2) ? 
                                        <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                                            <span className="w-8"><i className="fas fa-plus text-primary font-16"></i></span>
                                            <span onClick={() => this.props.OpenPopup('ReviewMilestone')} className="font-15 text-primary font-weight-600">Submit Proposal</span> 
                                        </li> : 
                                        (this.props.ProjectStatus === 6 && this.props.CurrentRole === 2) ? 
                                          <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                                              <span className="w-8"><i className="fas fa-plus text-primary font-16"></i></span>
                                              <span onClick={this.handleSubmitMilestone} className="font-15 text-primary font-weight-600">Submit Milestone</span> 
                                          </li> : null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  const creatorProfile = store.creatorProfile;
  const chat = store.chat;
  const postProject = store.postProject
  const globalReducer = store.globalReducer
  return {
    closeModal: creatorProfile.closeModal,
    ProjectStatus: chat.ProjectStatus,
    proposalStatus: postProject.proposalStatus,
    CurrentRole:globalReducer.Me.CurrentRole,
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  openAttachment: () => dispatch({ type: 'ATTACHMENT_BUTTON_TOGGLE', payload: true }),

});
export default connect(mapState, mapDispatch)(MobilePlusButtonDropdown);
