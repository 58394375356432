import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import StarRatings from "react-star-ratings";
import DummyProfile from "../../../../../../Assets/upgrade-images/client_active_brief_chat/dummy.png";
import Location from "../../../../../../Assets/upgrade-images/view_proposal_list/location.svg";
import MessageWhiteIcon from "../../../../../../Assets/upgrade-images/view_proposal_list/message.svg";
import Loader from "../../../../../authorized/Common/Common/Loader";
import NotFound from "../../../../Common/NotFound/NotFound";
import { BaseImageUrl, WebsiteUrl } from "../../../../../../utils/BaseUrl";
import defaultIcon from "../../../../../../Assets/images/c.jpg";
import ProjectSlider from "./ProjectSlider";
import {
  separateNumber,
  getProjectQuote,
  capitalizeWordFirstLetter,
} from "../../../../../../utils/Helperfunctions";
import moment from "moment";
import { Link } from "react-router-dom";
import PercentIcon from "../../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import ClientCreatorProposalPopup from "../../CommonpPopup/ClientCreatorProposalPopup";
import Danger from "../../../../../../Assets/upgrade-images/account/danger.svg";
import Popup from "../../../../Chat/Popup";
import ViewModalPopup from "../../../../ProfileCreator/Popup.js"
import { getDateFormated } from "../../CommonFunctionsNew/CommonFunctionsNew";

const ActiveOverview = (props) => {
  const data = useSelector((state) => state.releventProjects);
  const creatorIs = data?.allProposal?.creator;
  const DataIs = creatorIs?.filter((el) => el?.status !== 2);
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedCreator,setSelectedCreator] = useState('')
  const [selectedCreatorType,setSelectedCreatorType] = useState('')
  const [selectedCreatorData,setSelectedCreatorData] = useState({})
  const [userPropChat,setUserPropChat] = useState('')
  const [openedFrom,setOpenedFrom] = useState('')
  const dispatch = useDispatch()
  // console.log('**************************', DataIs)

  // useEffect(() => {
  //   if (userId) setShowVersionHistory(true)
  // }, [userId])

  const onClickViewProjects = (ele) => {
    if (!ele.project_data) {
      alert("No Projects for the Selected Collateral");
      return;
    }
    if (ele?.review_status) {
      setOpenedFrom("slidermodalbrief");
    } else {
      setOpenedFrom("overview");
    }
    // this.props.LoadProjectData(ele.project_data._id);
    // this.props.closePopup();
    //   this.props.OpenPopupPoject('ViewModal');
    dispatch({ type: "UPDATE_CREATOR_PUBLIC", values: true });
    // dispatch({ type: "LOAD_ALL_PROJECTS_SAGA", values: ele.username })
    dispatch({
      type: "LOAD_PROJECT_DATA",
      payload: { id: ele.project_data._id },
    });
    dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} });
    dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "ViewModal" } });
  };

  const handleViewProfile = (data) => {
    // console.log(index, data);
    const proposalid = data.creator_id._id;
    const username = data.creator_id.username;
    const userType = data.user_type;
    // const {authIs} = this.props;
    setSelectedCreatorType(userType);
    setSelectedCreator(proposalid);
    setSelectedCreatorData(data);
    // props.OpenPopup('SliderModalBrief')
    // props.loadPublicCreatorData(username)
    dispatch({
      type: "OPEN_POPUP_SAGA",
      payload: { Component: "SliderModalBrief" },
    });
    dispatch({ type: "LOAD_CREATOR_PUBLIC", payload: { user_name: username } });
  };

  return (
    <>
      {data?.loadingIs ? (
        <div className="row rejected_body  body_scroll_bar d-flex justify-content-center align-items-center">
          <div className="col-lg-3 text-center">
            <Loader />
          </div>
        </div>
      ) : DataIs?.length ? (
        DataIs.map((obj, index) => {
          return obj?.status !== 2 ? (
            <div
              className={`row mb-2 px-4 mx-0 py-5 ${
                DataIs.length === index + 1 ? "" : "br_btm_border-gray-500"
              }`}
              key={index}
            >
              <div className="col-lg-8">
                <div className="row profile_content mb-4 cursor-pointer"
                  onClick={() => handleViewProfile(obj)}
                >
                  <div className="col-lg-2 col-sm-3">
                    {/* <a href={`${WebsiteUrl}creator/${obj.creator_id?.username}`} target="_blank"> */}
                    <div
                      className="profile_image mr-3 cursor-pointer"
                      // onClick={() => handleViewProfile(obj)}
                    >
                      <img
                        src={
                          obj?.creator_id?.profile_image
                            ? BaseImageUrl + obj.creator_id.profile_image
                            : DummyProfile
                        }
                        className="img-fluid"
                      />
                    </div>
                    {/* </a> */}
                  </div>
                  <div className="col-lg-10 col-sm-9">
                    <div className="profile_name">
                      <p className="mb-1">
                        {/* {obj?.creator_id?.first_name + " " + obj?.creator_id?.last_name}{" "}
                      <span className="text_gray_1100_w_700 ml-4">
                        {obj?.experience?.year + " year " + obj?.experience?.month} month of experience
                      </span> */}
                        <div className="client_details d-flex align-items-center justify-content-between">
                          <div>
                            <span className="text-dark">
                              {obj?.creator_id?.first_name +
                                " " +
                                obj?.creator_id?.last_name}{" "}
                            </span>
                            <span className="ml-2">
                              <span className="text_gray_1100_w_700 ml-1 mr-2">
                                {/* {obj?.experience?.year + " year " + obj?.experience?.month} month of experience */}
                                {(obj?.experience?.year < 1
                                  ? 1
                                  : obj?.experience?.year) +
                                  (obj?.experience?.year < 1
                                    ? " year "
                                    : obj?.experience?.month
                                    ? "+ years "
                                    : obj?.experience?.year == 1
                                    ? " year "
                                    : " years ")}
                                of experience
                              </span>
                              {obj?.unread_msg ? (
                                <span
                                  // to={`/projects/${props?.match?.params?.projectId}/project_room/${obj?.creator_id?.username}`}
                                >
                                  <span className="msg_tag">
                                    <img
                                      src={MessageWhiteIcon}
                                      className="img-fluid"
                                    />{" "}
                                    {obj.unread_msg}
                                  </span>
                                </span>
                              ) : null}
                            </span>
                          </div>
                          {obj?.Proposal && obj?.Proposal?.proposal_type ?
                            <div className="d-flex p-0">
                              {obj?.Proposal?.proposal_type?.toLowerCase() &&
                                (obj?.Proposal?.proposal_type?.toLowerCase() == "fixed" ? (
                                  <div className="tag_btn_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img src={PercentIcon} className="icon_img mr-1" />
                                      Fixed Scope
                                    </p>
                                  </div>
                                ) : obj?.Proposal?.proposal_type?.toLowerCase() == "retainer" ? (
                                  <div className="tag_btn_green_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img
                                        src={PercentIconGreen}
                                        className="icon_img mr-1"
                                      />
                                      Retainer
                                    </p>
                                  </div>
                                ) : (
                                  <div className="tag_btn_dark_green_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img
                                        src={PercentIconDarkGreen}
                                        className="icon_img mr-1"
                                      />
                                      On-Actuals
                                    </p>
                                  </div>
                                ))}
                            </div>
                           : null}
                        </div>
                      </p>
                      {obj.creator_rating ? (
                        <p className="d-flex align-items-end">
                          <StarRatings
                            rating={Number(obj?.creator_rating)}
                            starRatedColor="#90c028"
                            starDimension="15px"
                            starSpacing="2px"
                            numberOfStars={5}
                            name="rating"
                          />
                          <span className="text_gray_1100_w_600_sm ml-3">
                            {obj.creator_rating}({obj.total_client_rating_count}
                            )
                          </span>
                        </p>
                      ) : null}

                      {/* <div className="client_details d-flex align-items-center">
                      <div className="icon d-flex">
                        <img src={Location} className="img-fluid" />
                      </div>
                      <span className="ml-2 text_gray_1100_w_500_sm">{obj?.creator_id?.location}</span>
                      <span className="ml-4 text_gray_xs_w_700 mr-2">Clients</span>
                      {obj?.creatorClient?.length
                        ? obj?.creatorClient.map((el, index) => {
                            return (
                              <div className="client_logo_image d-flex align-items-center" key={index}>
                                <img
                                  src={el?.client_image ? BaseImageUrl + el?.client_image : defaultIcon}
                                  className="img-fluid"
                                  // height={30}
                                  // width={30}
                                />
                              </div>
                            );
                          })
                        : ""}
                    </div> */}

                      <ul className="client_details d-block align-items-center">
                        <li className="mr-3">
                          <div className="location d-flex">
                            <div className="icon d-flex">
                              <img src={Location} className="img-fluid" />
                            </div>
                            <span className="ml-2 text_gray_1100_w_500_sm">
                              {obj?.creator_id?.location}
                            </span>
                          </div>
                        </li>
                        {obj?.creatorClient.filter(
                          (cl) => cl?.client_name?.length
                        ).length ? (
                          <li className="ml-2">
                            <div className="rating d-flex">
                              <span className=" text_gray_xs_w_700 mr-2">
                                Previously worked with
                              </span>
                              {obj?.creatorClient?.length
                                ? obj?.creatorClient.map((el, index) => {
                                    return el?.client_name ? (
                                      <div
                                        className="client_logo_image d-flex align-items-center"
                                        key={index}
                                      >
                                        <img
                                          src={
                                            el?.client_image
                                              ? BaseImageUrl + el?.client_image
                                              : defaultIcon
                                          }
                                          className="img-fluid"
                                          // height={30}
                                          // width={30}
                                        />
                                      </div>
                                    ) : null;
                                  })
                                : ""}
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="info_box title_commn row mb-3 align-items-center">
                  {obj?.Proposal && obj?.Proposal?.proposal_type ? (
                    <>
                      <div className="col-sm-10 col-xl-8 info_box_item_outer d-flex col cursor-pointer" 
                        onClick={() => handleViewProfile(obj)}
                      >
                        <div className="info_box_item">
                          <p className="text_gray_1100_w_700 mb-1">Quote</p>
                          <p className="font_semi_bold mb-0">
                            {getProjectQuote(
                              obj?.Proposal,
                              obj?.Proposal?.sub_total_cost,
                              obj?.Proposal?.proposal_type,
                              obj?.Proposal?.retainer_fees_type
                            )}
                            {/* ₹ {separateNumber(obj?.Proposal?.total_cost)} */}
                          </p>
                          <p>{obj?.Proposal ? <span className="gst pl-0">{(obj?.Proposal?.proposal_type == 'fixed' || obj?.Proposal?.proposal_type == 'retainer') ? '+ Applicable taxes' : ' Pricing provided per item'}</span> : null}</p>
                        </div>
                        <div className="info_box_item">
                          <p className="text_gray_1100_w_700 mb-1">
                            Start Date
                          </p>
                          <p className="font_semi_bold mb-0">
                            {getDateFormated(obj?.Proposal?.proposed_start_date)}
                          </p>
                        </div>
                      </div>

                      <div className="col-sm-2 col-xl-4 text-center text-lg-right">
                        {/* <div className="card_icon mr-3">
                          <img src={MessageIcon} className="img-fluid" />
                        </div> */}
                        {obj?.Proposal ? (
                          <>
                            <button
                              type="button"
                              class="btn btn-common btn_common float-right"
                              onClick={() => {
                                // if (props.authIs) {
                                //   props.history.push(
                                //     props.CurrentRole == 2
                                //       ? `/projects/${props?.projectDataStates?.id}/proposal_details`
                                //       : `/projects/${props?.projectDataStates?.id}/project_room/${obj?.creator_id?.username}/proposal`
                                //   )
                                // }
                                // else {
                                  setUserId(obj?.Proposal?.user_id)
                                  setUserPropChat(obj?.chat)
                                  setShowVersionHistory(true)
                                // }
                              }}
                            >
                              View Proposal
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="empty_div"></div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="info_box_item_outer d-flex col">
                        <p className="text_gray_1100_w_700 mb-1">
                          The Creator has shared some questions
                        </p>
                      </div>
                      {props.authIs ? (
                        <div className="info_box_item_outer d-flex align-items-center col-auto">
                          <button
                            type="button"
                            class="btn float-right btn-common-white fw_700"
                            onClick={() =>
                              props?.history?.push(
                                `/projects/${props?.match?.params?.projectId}/project_room/${obj?.creator_id?.username}`
                              )
                            }
                          >
                            View Questions
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                {(obj?.Proposal && obj?.status !== 6)&&(obj?.Proposal?.proposal_type?.toLowerCase() != props?.projectDataStates.data?.project_data?.sub_type )? 
                <div className="row text-center text-lg-left dflex cursor-pointer"
                  onClick={() => handleViewProfile(obj)}
                >
                  <span className="col-sm-12 text_gray_1100_w_500_sm d-flex align-items-start"><img src={Danger} className='mr-2' width={18}/> Creator has submitted {`${obj?.Proposal?.proposal_type?.toLowerCase()=='actual'?"On-Actuals":'a '+ capitalizeWordFirstLetter(obj?.Proposal?.proposal_type?.toLowerCase())}`} proposal</span>
                </div> : null }
              </div>
              {/* image/slider section */}

              <div className="col-lg-4">
                <div
                  className="profile_imagecar slider_card cursor-pointer"
                  // onClick={()=>{dispatch({ type: "LOAD_ALL_PROJECTS_SAGA", values: obj.creator_id.username });onClickViewProjects({project_data:obj.project?.[0],username:obj.creator_id.username})}}
                >
                  <ProjectSlider
                    SelectedArtistData={obj}
                    onClickViewProjects={onClickViewProjects}
                    location={props.location}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        })
      ) : (
        <NotFound />
      )}
      {selectedCreator && (
        <Popup
          {...props}
          selectedCreator={selectedCreator}
          hideActions={true}
          projectId={props?.match.params.projectId}
          // openProposal={this.openProposal}
          // openBrief={this.openBrief}
          // creatorUnderReview={creatorUnderReview}
          handleCreatorProfileByIndex={handleViewProfile}
          onClickViewProjects={onClickViewProjects}
          selectedCreatorType={selectedCreatorType}
          selectedCreatorData={selectedCreatorData}
          // Below props needed to showcase common slider
          // creatorRejected={artistArrayForSlider}
          // allCreators={artistArrayForSlider}
        />
      )}
      <ViewModalPopup
        {...props}
        hideActions={true}
        selectedCreator={selectedCreator}
        projectId={props?.match.params.projectId}
        // openProposal={this.openProposal}
        // openBrief={this.openBrief}
        // creatorUnderReview={creatorUnderReview}
        handleCreatorProfileByIndex={handleViewProfile}
        onClickViewProjects={onClickViewProjects}
        selectedCreatorType={selectedCreatorType}
        selectedCreatorData={selectedCreatorData}
        openedFrom={openedFrom}
        // creatorRejected={artistArrayForSlider}
      />

      {
        showVersionHistory && (
          <ClientCreatorProposalPopup
            id={props.match.params.projectId}
            setShowVersionHistory={setShowVersionHistory}
            showVersionHistory={showVersionHistory}
            user_id={userId}
            unAuthorized={props?.authIs ? false : true}
            userPropChat={userPropChat}
            clientOverview={true}
            {...props}
          />
        )
      }
    </>
  );
};

export default ActiveOverview;
