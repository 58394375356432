import React, { useEffect, useState } from "react";
import LinkIcon from '../../../../../../Assets/upgrade-images/submit_proposal/url_link.svg'
import TrashImage from '../../../../../../Assets/upgrade-images/submit_proposal/trash.svg';
import { projectUrls } from "../../../../../../store/action/proposal.action";
import { useDispatch, useSelector } from "react-redux";

const Urls = () => {
    const [currentUrl, setCurrentUrl] = useState("")
    const [urls, setUrls] = useState([])
    const [addUrl, setAddUrl] = useState(true)
    const [errorIs, setError] = useState("")
    const dispatch = useDispatch()

    const _relevent_projects = useSelector((state) => state.releventProjects)
    const urlsIs = _relevent_projects?.urls ? _relevent_projects.urls : []

    useEffect(() => {
        setUrls(_relevent_projects?.urls ? _relevent_projects.urls : [])
    },[_relevent_projects?.urls])

    const handleChange = (e) => {
        setCurrentUrl(e.target.value)
    }

    const setAllUrls = (data) => {
        dispatch(projectUrls(data))
    }

    // useEffect(()=>{
    //     setAllUrls()
    //     console.log("chla")
    // },[urls])

    const handleAppend = () => {
        // e.preventDefault();
        const validUrl = (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(currentUrl)
        if (validUrl) {
            const currentUrlIs = currentUrl?.split("/").slice(0)[0];
            let currentData = null
            currentData = { name: currentUrl }
            // if (currentUrlIs === "http:" || currentUrlIs === "https:" || currentUrlIs === "www:") {
            //     currentData = { name: currentUrl }
            // } else {
            //     currentData = { name: "http://" + currentUrl }
            // }
            const data = urlsIs.concat(currentData)
            setError("")
            setUrls(data)
            setAddUrl(false)
            setAllUrls(data)
        } else {
            setError("Invalid url")
        }
    }

    const handleAnotherUrl = () => {
        setCurrentUrl("")
        setAddUrl(true)
    }

    const handleRemove = (index) => {
        const data = urls.filter((_, i) => i !== index)
        setUrls(data)
        setAllUrls(data)
    }

    return (
        <>
            <div className='col-lg-12 px-0 py-3'>
                <div className='upload_sub_title d-flex align-items-center'><p className='mb-0 text_light_gray_xs'>Add URL references here </p></div>
            </div>
            <div className='row px-0 mx-0'>
                {urlsIs?.length ?
                    urlsIs.map((obj, index) => {
                        return (
                            <>
                                <div className='col-lg-11 col-10 px-0 mb-2' key={index}>
                                    <input class="form-control input_md url_input" disabled type="text" value={obj?.name} />

                                    <div className='input_content d-flex justify-content-between align-items-center px-3'>
                                        <img className='img-fluid' src={LinkIcon} />
                                        {/* <p className='mb-0 blue_text_sm'>Add URL</p> */}
                                    </div>
                                </div>
                                <div className='col-lg-1 col-2 d-flex align-items-center justify-content-end pr-0'>
                                    <img className='img-fluid' src={TrashImage} onClick={() => handleRemove(index)} />
                                </div>
                            </>
                        )
                    })
                    : ""}
                {!addUrl ? <div className='col-lg-12 py-4 px-0'>
                    <p className='add_on_milestone' ><span className="cursor-pointer" onClick={handleAnotherUrl}><span className="add_on_milstone_icon ">+</span>Add Another URL</span></p>
                </div> : ""}

            </div>
            {addUrl ? <div className='row px-0 mx-0 mb-4'>
                <div className='col-lg-12 px-0'>
                    <div className="row mx-0" 
                    // onSubmit={currentUrl ? handleAppend : ""}
                    >
                        <input class={`form-control input_md url_input ${errorIs ? "border border-danger" : ""}`} type="text"
                            onKeyDown={(e) => { if (e.key === "Enter" && currentUrl ) handleAppend(e) }}
                            placeholder="Enter URL Here" onChange={handleChange} />

                        {/* {(currentUrl) ?<div className="col-sm-2">
                    <button type="submit"
                        className="main-btn font-14 cursor-pointer url_save w-100" 
                    >
                        Save
                    </button>
                </div> : ""} */}

                        {(currentUrl) ? <>
                            <div className={`add_url`}><button onClick={handleAppend} type="submit" className={`${errorIs ? "error_button" : ""}`}>Add URL</button></div>
                        </> : ""}

                        {errorIs ? <span className="col-sm-12 error_message">{errorIs}</span> : ""}

                        <div className={`input_content ${errorIs ? "error_input_image" : ""} d-flex justify-content-between align-items-center px-3`}>
                            <img className='img-fluid' src={LinkIcon} />
                            {/* <p className='mb-0 blue_text_sm'>Add URL</p> */}
                        </div>
                    </div>
                </div>
            </div> : ""}
       
        </>
    )
}

export default Urls