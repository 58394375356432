import React, { useState, useEffect } from "react";
import { ChatImageBaseUrl } from "../../../../../utils/BaseUrl";
import ImportIcon from "../../../../../Assets/upgrade-images/upload_milestone/import.svg";
import { downloadFilesFun, getDateTimeFormated, getRealFileName, handleFileName } from "../CommonFunctionsNew/CommonFunctionsNew";
import CommonViewFile from "./CommonViewFile";
import CustomSummernote from "../../../Chat/CustomSummernote";
import RenderHtml from "../../../Common/RenderHtml/RenderHtml";
import { limitString, sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";
import { getAPI, postAPI } from "../../../../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { BaseImageUrl, ChatBaseUrl } from "../../../../../utils/BaseUrl";
import SendArrow from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/send.svg";
import SendArrowDisable from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/sendDisabled.svg";
import Loader from "../../../Common/Common/Loader";

const ViewDocument = ({ documentDetail, ...props }) => {
  const [chatStates, setChatStates] = useState({
    // completed_Milestone: "",
    // completed_Milestone_comment: [],
    // milestone_id: "",
    // socket: "",
    deliverable_feedback: "",
    user_chat: [],
    isLoading: false,
  });

  // const dispatch = useDispatch();

  const chat = useSelector((state) => state.chat);

  useEffect(() => {
    getMilestoneData();
  }, []);

  useEffect(() => {
    if (chat.UserToken) {
      const socket = socketIOClient(ChatBaseUrl);
      setChatStates({ ...chatStates, socket: socket });
      socket.on(chat.UserToken, (resp) => {
        getMilestoneData();
        // this.feedbackRef.current.scrollTop = 0;
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [chat?.UserToken]);

  const handleState = (data) => {
    setChatStates({
      deliverable_feedback: "",
      user_chat: data.user_chat,
      isLoading: false,
    });
  };

  const getMilestoneData = () => {
    setChatStates({ ...chatStates, isLoading: true });
    getAPI("/chat/get-chat-file-message?file_id=" + documentDetail?._id)
      .then((res) => {
        handleState(res.data);
      })
      .catch((error) => {
        setChatStates({ ...chatStates, isLoading: false });
      });
  };

  const handleFeedbackNew = (value) => {
    setChatStates({ ...chatStates, deliverable_feedback: value });
  };

  const sendFeedback = () => {
    const { deliverable_feedback } = chatStates;

    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = deliverable_feedback;

    if (!deliverable_feedback || htmlObject?.innerText?.trim().length <= 0) return;
    const milestone_details = {
      file_id: documentDetail?._id,
      text: sanitiseSummernoteText(deliverable_feedback),
    };
    postAPI("/chat/get-chat-file-message", milestone_details)
      .then((res) => {
        if (res.data.status === 1) {
          getMilestoneData();
          document.querySelector(".chat-modal-section .chatting-box-overflow").style.height = "calc(100vh - 200px)";
        }
        setChatStates({ ...chatStates, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        setChatStates({ ...chatStates, isLoading: false });
      });
  };
  const userId = useSelector(state => state?.globalReducer?.Me.Id)

  let htmlObject = document.createElement('div');
  htmlObject.innerHTML = chatStates.deliverable_feedback;
  let isInput = (htmlObject.innerText.trim().length === 0) ? false : true

  return (
    <div className="modal-body p-0">
      <div className="row mx-0">
        <div className="col-12 bg-white p-4">
          <div className="d-flex align-items-center justify-content-between bg-white">
            <div
              className="d-flex align-items-center cursor_pointer"
              onClick={() => {
                props.setViewDocument(false);
              }}
            >
              <i className="fas fa-chevron-left mr-2 font-14" />
              <p className="mb-0 fw_800">
                {documentDetail.type == "file"
                  ? handleFileName(getRealFileName(documentDetail?.name), 40)
                  : limitString(documentDetail?.name, 60)}
              </p>
              {props.fixedLastSubm || props.isFixed || props?.state?.retainer_fees_type === 'Monthly' ? null : (
                <>
                  {documentDetail.status == 1 && <span className="pills_tag_btn_green ml-3">Approved</span>}
                  {documentDetail.status == 0 && <span className="pills_tag_btn_yellow ml-3">In Review</span>}
                  {documentDetail.status == 2 && <span className="pills_tag_btn_danger ml-3">Rejected</span>}
                </>
              )}
            </div>
            <div className="d-flex align-items-end">
              {documentDetail?.type === "file" ? (
                <p
                  className="cursor_pointer font-weight-bold mb-0 mr-3"
                  style={{ color: "#1464DC" }}
                  onClick={() => downloadFilesFun(ChatImageBaseUrl, documentDetail?.name)}
                >
                  <img src={ImportIcon} className="icon_img mr-3" />
                  Download
                </p>
              ) : (
                ""
              )}
              {props?.role!==2 && props?.isActual && props.paymentStatus!==2 ? (
                <>
                  {documentDetail.status == 0 || documentDetail.status == 1 ? (
                    <p
                      className="mb-0 cursor_pointer pl-3"
                      onClick={() => {
                        props.setSingleUploadDocId(documentDetail._id);
                        props.setAppRejType("reject");
                        props.setShowApproveRejectDeliverable(true);
                      }}
                      style={{ color: "#9393AB", borderLeft: "1px solid #E1E5EB" }}
                    >
                      Reject
                    </p>
                  ) : null}
                  {documentDetail.status == 0 || documentDetail.status == 2 ? (
                    <p
                      className="mb-0 cursor_pointer ml-4"
                      onClick={() => {
                        props.setSingleUploadDocId(documentDetail._id);
                        props.setAppRejType("approve");
                        props.setShowApproveRejectDeliverable(true);
                      }}
                      style={{ color: "#10C89B" }}
                    >
                      Approve
                    </p>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>

        {/* <div
          className={`col-${
            props?.showChatMess && !props.fixedLastSubm ? "8" : "12"
          }  view_document_wrapper common_overflow_height`}
        >
          <div className="d-flex uploading-image justify-content-center align-items-center h-100">
            <CommonViewFile documentDetail={documentDetail} />
          </div>
        </div> */}

        <div
          className={`col-${props?.showChatMess && !props.fixedLastSubm ? "8" : "12"
            } view_document_wrapper common_overflow_height`}
        >
          <div className="uploading-image ">
            <CommonViewFile documentDetail={documentDetail} />
          </div>
        </div>

        {props?.showChatMess && !props.fixedLastSubm && (
          <div className="col-lg-4 p-4">
            <div className="row mb-4">
              <div className="col-lg-12">
                <p className="mb-0 text_black_700_w_600">Comments</p>
              </div>
            </div>
            <div className="commet_section">
              {chatStates.isLoading
                ? <Loader isCreateModal={true} style={{ paddingTop: "15rem" }} />
                : chatStates.user_chat.map((comments, index) => (
                  <div className="row mb-3 mx-0" key={index}>
                    <div className="col-lg-auto">
                      <div className="profile_image_40_outline">
                        <img src={BaseImageUrl + comments?.sender_id?.profile_image} />
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <p className="mb-1">
                        <ul className="d-flex">
                          <li className="pr_2 font_semi_bold_w_600 mr-2">
                            {comments?.sender_id?._id == userId
                              ? "You"
                              :comments?.sender_id?.first_name?.length > 10
                                ? limitString(comments.sender_id.first_name, 10)
                                : comments?.sender_id?.first_name}
                          </li>
                          <li className="li_disc text_light_gray_xs_w_400 ml-3">{getDateTimeFormated(comments?.time)}</li>
                        </ul>
                      </p>
                      <p className="font_text-black-700_md_w_400 mb-0">
                        <RenderHtml data={comments?.text} />
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            {/* textarea */}
            <div className="row mx-0">
              <div className="col-lg-12 px-0">
                <div className="border py-2 rounded-lg">
                  <div className="custom_form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="chat-feedback">
                        <div className="position-relative">
                          <div className={`submit_btn mobile-ipad-none ${isInput? "cursor-pointer" : ""}`}>
                            <span
                              onClick={() => {
                                if (
                                  !chatStates.isLoading &&
                                  chatStates.deliverable_feedback.replace(/<\/?[^>]+(>|$)/g, "").length
                                ) {
                                  // setChatStates({ ...chatStates, isLoading: true });
                                  sendFeedback();
                                }
                              }}
                            >
                              <img src={isInput ? SendArrow:SendArrowDisable} className="img-fluid mr-4" />
                            </span>
                          </div>
                          <CustomSummernote
                            placeholder="Write a message..."
                            value={chatStates.deliverable_feedback}
                            onChange={handleFeedbackNew}
                            sendMsg={() => {
                              if (!chatStates.isLoading) {
                                // setChatStates({ ...chatStates, isLoading: true });
                                sendFeedback();
                              }
                            }}
                            inMilestone
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className="zoom_icons">
        <div className="zoom_in">
          <img src={ZoomInIcon} className="img-fluid" />
        </div>
        <span>100%</span>
        <div className="zoom_out">
          <img src={ZoomOutIcon} className="img-fluid" />
        </div>
        {props?.showChatMess && (
          <div className={`col-4 d-flex uploading-image justify-content-center`}>
            <div className="card-wrapper mb-0">
              <div
                // ref={this.feedbackRef}
                className="overflow-content chat-milestone-modal-height chatting-box-overflow full-height pt-4"
              >
                <div className="version-history">
                  {chatStates.completed_Milestone_comment.map((comments, index) => (
                    <div key={index} className="card-wrapper mb-3">
                      <div className="card-body pl-4 pr-2 position-relative">
                        <div className="custom_form">
                          <div className="card-wrapper mb-10">
                            <div className="row m-0 d-flex">
                              <div className="col-sm-12 p-0">
                                <div className="d-flex">
                                  <div className="">
                                    <p className="mb-0 creator-profile">
                                      <img src={BaseImageUrl + comments.user_id.profile_image} alt="profile" width={20} />
                                    </p>
                                  </div>
                                  <div className="w-100 pl-2">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                      <div className="d-flex align-items-center">
                                        <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">
                                          {comments.user_id.first_name
                                            ? limitString(comments.user_id.first_name, 10)
                                            : comments.user_id.first_name}
                                        </h2>
                                      </div>
                                      <div className="pl-2">
                                        <span className="font-12 text-sub font-weight-600">
                                          {getMsgTime(comments.createdAt, "time")}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <p className="mb-0 text-black font-13 letter-spacing-02 line-height-17 font-weight-500">
                                        <RenderHtml data={comments.comment} />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="input-chat py-20 px-20 mob-bg-white mobile-hide">
              <div className="custom_form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="chat-feedback">
                    <div className="position-relative">
                      <div className="submit_btn mobile-ipad-none">
                        <span
                          onClick={() => {
                            if (!chatStates.isLoading) {
                              setChatStates({ ...chatStates, isLoading: true });
                              sendFeedback();
                            }
                          }}
                        >
                          <i
                            className={`cursor-pointer fas fa-location-arrow ${
                              chatStates.deliverable_feedback.replace(/<\/?[^>]+(>|$)/g, "").length
                                ? "text-success"
                                : "text-light-gray"
                            }`}
                          />
                        </span>
                      </div>
                      <CustomSummernote
                        placeholder="Type your message here..."
                        value={chatStates.deliverable_feedback}
                        onChange={handleFeedbackNew}
                        sendMsg={() => {
                          if (!chatStates.isLoading) {
                            setChatStates({ ...chatStates, isLoading: true });
                            sendFeedback();
                          }
                        }}
                        inMilestone
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ViewDocument;

// const handleState = (data) => {
//   // console.log("data = ", data);
//   dispatch({ type: "SET_CURRENT_MILESTONE_ID", payload: data.completed_Milestone?._id });
//   setChatStates({
//     ...chatStates,
//     milestone_id: data.milestone._id,
//     completed_Milestone: data.completed_Milestone,
//     completed_Milestone_comment: data.completed_Milestone_comment,
//     deliverable_feedback: "",
//     isLoading: false,
//   });
// };

// const sendFeedback = () => {
//   const { deliverable_feedback } = chatStates;
//   // const isAdminProject = chat.ProjectType === "post_project_admin";
//   if (!deliverable_feedback) return;
//   // console.log("documentDetail == ", documentDetail);
//   const milestone_details = {
//     file_id: documentDetail?._id,
//     comment: sanitiseSummernoteText(deliverable_feedback),
//   };
//   postAPI("/chat/get-chat-file-message", milestone_details)
//     .then((res) => {
//       if (res.data.status === 1) {
//         getMilestoneData();
//         // dispatch({ type: "UPDATE_LOAD_CHATMESSAGES", payload: chat.CurrentChat });
//         // if (isAdminProject) {
//         //   dispatch({ type: "LOAD_ADMIN_PROJECT_PROPOSAL", payload: null });
//         // } else {
//         //   dispatch({ type: "LOAD_LATEST_PROPOSAL", payload: chat.projectId });
//         // }
//         // Reset milestone feedback height
//         document.querySelector(".chat-modal-section .chatting-box-overflow").style.height = "calc(100vh - 200px)";
//       }
//       setChatStates({ ...chatStates, isLoading: false });
//     })
//     .catch((error) => {
//       console.log(error);
//       setChatStates({ ...chatStates, isLoading: false });
//     });
// };
