import {
  UPDATE_ALLCOLLATERAL,
  UPDATE_ALLCOLLATERAL_RESET,
  UPDATE_ALLCOLLATERAL_NAME,
  UPDATE_ALLCOLLATERAL_DURATION,
  UPDATE_ALLCOLLATERAL_ENDPRICE,
  UPDATE_ALLCOLLATERAL_STARTPRICE,
  UPDATE_ALLCOLLATERAL_AVGTIME,
  UPDATE_ALLCOLLATERAL_EXPERIENCE,
  UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH,
  UPDATE_ALLCOLLATERAL_ENABLEEDIT,
  UPDATE_ALLCOLLATERAL_ID,
  UPDATE_ALLCOLLATERAL_TYPEINDEX,
  LOAD_ADDCOLLATERALS,
  LOAD_COLLATERALSDATA,
  UPDATE_COLLATERAL_ERROR,
  UPDATE_ALLCOLLATERAL_NAME_ERROR,
  UPDATE_ALLCOLLATERAL_DURATION_ERROR,
  UPDATE_ALLCOLLATERAL_ENDPRICE_ERROR,
  UPDATE_ALLCOLLATERAL_STARTPRICE_ERROR,
  UPDATE_ALLCOLLATERAL_AVGTIME_ERROR,
  UPDATE_ALLCOLLATERAL_EXPERIENCE_ERROR,
  UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH_ERROR,
  GET_QNA_IN_USER_COLLATERAL,
  RESET_QNA_IN_USER_COLLATERAL,
  UPDATE_STYLE_ANSWER,
  UPDATE_TYPE_ANSWER,
  UPDATE_RESET_COLLATERAL_QNA,
  GET_COLLATERAL_INFO_TEXT,
} from '../action/userAddCollateral.action';

const initState = {
  AllCollaterals: [],
  CollateralQuestions: null,
  edit: -1,
  Name: '',
  EndPrice: '',
  StartPrice: '',
  Experience: 0,
  ExperienceMonth: 0,
  Time: 0,
  Type: 'Hours',
  CollateralError: false,
  EndPriceError: false,
  StartPriceError: false,
  ExperienceError: false,
  TimeError: false,
  TypeError: false,
  ExperienceMonthError: false,
  ExperienceMonthErrorMessage: '',
  CollateralErrorMessage: '',
  EndPriceErrorMessage: '',
  StartPriceErrorMessage: '',
  ExperienceErrorMessage: '',
  TimeErrorMessage: '',
  TypeErrorMessage: '',
  ID: '',
  TypeIndex: 0,
  avgTimeType: ['Hours', 'Days', 'Weeks', 'Months'],
  StyleAnswer: [],
  TypeAnswer: [],
  collateralPricing: '',
  collateralDesc: '',
  collateralUnit: '',
  CollateralDetail:[]
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};

  switch (type) {
    case LOAD_ADDCOLLATERALS:
      newState = Object.assign({}, state, {
        AllCollaterals: values,
      });
      return newState;
    case LOAD_COLLATERALSDATA:
      newState = Object.assign({},state,{
        CollateralDetail:values
      })
      return newState;
    case UPDATE_ALLCOLLATERAL_TYPEINDEX:
      newState = Object.assign({}, state, {
        TypeIndex: values,
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_ENABLEEDIT:
      newState = Object.assign({}, state, {
        edit: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_ALLCOLLATERAL_NAME:
      // console.log(values);
      newState = Object.assign({}, state, {
        Name: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_DURATION:
      newState = Object.assign({}, state, {
        Type: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_ENDPRICE:
      newState = Object.assign({}, state, {
        EndPrice: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_STARTPRICE:
      newState = Object.assign({}, state, {
        StartPrice: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_AVGTIME:
      newState = Object.assign({}, state, {
        Time: parseInt(values,10),
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_EXPERIENCE:
      newState = Object.assign({}, state, {
        Experience: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH:
      newState = Object.assign({}, state, {
        ExperienceMonth: values,
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_ID:
      newState = Object.assign({}, state, {
        ID: values,
      });
      return newState;

    case UPDATE_COLLATERAL_ERROR:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_ALLCOLLATERAL_NAME_ERROR:
      newState = Object.assign({}, state, {
        CollateralError: false,
        CollateralErrorMessage: '',
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_DURATION_ERROR:
      newState = Object.assign({}, state, {
        TypeError: false,
        TypeErrorMessage: '',
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_ENDPRICE_ERROR:
      newState = Object.assign({}, state, {
        EndPriceError: false,
        EndPriceErrorMessage: '',
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_STARTPRICE_ERROR:
      newState = Object.assign({}, state, {
        StartPriceError: false,
        StartPriceErrorMessage: '',
      });
      return newState;

    case UPDATE_ALLCOLLATERAL_AVGTIME_ERROR:
      newState = Object.assign({}, state, {
        TimeError: false,
        TimeErrorMessage: '',
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_EXPERIENCE_ERROR:
      newState = Object.assign({}, state, {
        ExperienceError: false,
        ExperienceErrorMessage: '',
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH_ERROR:
      newState = Object.assign({}, state, {
        ExperienceMonthError: false,
        ExperienceMonthErrorMessage: '',
      });
      return newState;
    case UPDATE_ALLCOLLATERAL_RESET:
      newState = Object.assign({}, state, {
        edit: -1,
        CollateralId: '',
        Name: '',
        EndPrice: '',
        StartPrice: '',
        Experience: 0,
        ExperienceMonth: 0,
        Time: 0,
        Type: 'Days',
        CollateralError: false,
        EndPriceError: false,
        StartPriceError: false,
        ExperienceError: false,
        TimeError: false,
        TypeError: false,
        CollateralErrorMessage: '',
        EndPriceErrorMessage: '',
        StartPriceErrorMessage: '',
        ExperienceErrorMessage: '',
        TimeErrorMessage: '',
        TypeErrorMessage: '',
        ID: '',
        TypeIndex: 0,
        avgTimeType: ['Hours', 'Days', 'Weeks', 'Months'],
        StyleAnswer: [],
        TypeAnswer: [],
        CollateralQuestions: null,
      });
      return newState;
      
    case GET_QNA_IN_USER_COLLATERAL:
      // console.log('reducer userCollateral ', values);
      newState = Object.assign({}, state, {
        StyleAnswer: values.QnA.StyleAnswer,
        TypeAnswer: values.QnA.TypeAnswer,
        CollateralQuestions: values.Questions,
        CollateralId: values.CollateralId,
      });
      return newState;

    case GET_COLLATERAL_INFO_TEXT:
      // console.log('reducer userCollateral ', values);
      newState = Object.assign({}, state, {
        collateralPricing: values.pricing_unit,
        collateralDesc: values.standard_project_unit_description,
        collateralUnit: values.unit,
      });
      return newState;

    case RESET_QNA_IN_USER_COLLATERAL:
      // console.log('reducer userCollateral ', values);
      newState = Object.assign({}, state, {
        StyleAnswer: [],
        TypeAnswer: [],
        CollateralQuestions: null,
        CollateralId: '',
      });
      return newState;

    case UPDATE_STYLE_ANSWER:
      newState = Object.assign({}, state, {
        StyleAnswer: values,
      });
      return newState;
    case UPDATE_TYPE_ANSWER:
      newState = Object.assign({}, state, {
        TypeAnswer: values,
      });
      return newState;
    case UPDATE_RESET_COLLATERAL_QNA:
      newState = Object.assign({}, state, {
        TypeAnswer: [],
        StyleAnswer: [],
        CollateralQuestions: null,
      });
      return newState;
    default:
      return state;
  }
};

export default reducers;
