import { put, call } from 'redux-saga/effects';
import { fireApi } from '../../../utils/fireApiPrecheck';

export function* UserAddCollateral(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Resp = yield fireApi('UserAddCollateral', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Resp.error && Resp.error === true) {
      yield put({ type: 'UPDATE_COLLATERAL_ERROR', values: Resp });
    } else {
      if (parseInt(Resp.status) === 1) {
        // console.log('RESP ', Resp);
        yield put({
          type: 'UPDATE_ADDCOLLATERAL',
          values: {
            collateral_id: data.payload.collateral_id,
            collateral_name: data.payload.name,
            year: data.payload.year,
            month: data.payload.month,
            time: data.payload.time,
            type: data.payload.type,
            start_price: data.payload.start_price,
            end_price: data.payload.end_price,
            _id: Resp.user_collateral._id,
            image: Resp.collateral.image,
            status: data.payload.status
          },
        });

        yield put({ type: 'UPDATE_FORMOPEN', values: false });
        yield put({ type: 'UPDATE_ALLCOLLATERAL_RESET', values: { edit: -1 } });
        yield call(alert('Collateral has been sent for review.'));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* UpdateUserCollateral(data = '') {
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let action = 'UpdateUserCollateral';
    let Work = yield fireApi(action, data.payload);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Work.error && Work.error === true) {
      yield put({ type: 'UPDATE_COLLATERAL_ERROR', values: Work });
    } else {
      if (parseInt(Work.status) === 1) {
        yield put({
          type: 'UPDATE_UPDATECOLLATERAL',
          values: {
            collateral_id: data.payload.collateral_id,
            collateral_name: data.payload.name,
            year: data.payload.year,
            month: data.payload.month,
            time: data.payload.time,
            type: data.payload.type,
            start_price: data.payload.start_price,
            end_price: data.payload.end_price,
            _id: data.payload._id,
            image: Work.collateral.image,
            status: data.payload.status
          },
        });
        yield put({ type: 'UPDATE_FORMOPEN', values: false });
        yield put({ type: 'UPDATE_ALLCOLLATERAL_RESET', values: { edit: -1 } });
        yield call(alert('Collateral has been sent for review.'));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* DeleteUserCollateral(data = '') {
  try {
    let Work = yield fireApi('DeleteUserCollateralFromProfile', { id: data.payload });
    if (parseInt(Work.status) === 1) {
      yield put({
        type: 'UPDATE_DELETECOLLATERAL',
        values: data.payload,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* loadCollateralQnAInUserCollateral(value) {
  // ques_id, ans_id;
  try {
    const ids = value.payload;
    // console.log('Collateral IDs IN CREATOR PROFILE', ids);
    let Questions = yield fireApi('GetQuestionsInCreatorProfile', {
      id: ids.ques_id,
    });
    let QnA = {
      StyleAnswer: [],
      TypeAnswer: [],
    };
    if (ids.ans_id !== '') {
      let { styleQuestionData, typeQuestionData } = yield fireApi(
        'GetCollaterAnswersInCreatorProfile',
        {
          id: ids.ans_id,
        }
      );
      QnA = {
        StyleAnswer: styleQuestionData[0].answer,
        TypeAnswer: typeQuestionData[0].answer,
      };
    }

    // console.log('Collateral Questions IN CREATOR PROFILE', Questions, QnA);
    yield put({
      type: 'GET_QNA_IN_USER_COLLATERAL',
      values: { Questions, QnA, CollateralId: ids.ques_id },
    });
    yield put({type: 'GET_COLLATERAL_INFO_TEXT', values: Questions.collateral})
  } catch (error) {
    console.log(error);
  }
}

export function* loadCollateralAnswersInUserCollateral(value) {
  try {
    value = value.payload;
    // console.log('Collateral ID IN CREATOR PROFILE for ANSWERs', value);
    let { styleQuestionData, typeQuestionData } = yield fireApi(
      'GetCollaterAnswersInCreatorProfile',
      {
        id: value,
      }
    );
    const QnA = {
      // questions form another api
      // Collateral_style_question: styleQuestionData[0],
      // Collateral_type_question: typeQuestionData[0],
      StyleAnswer: styleQuestionData[0].answer,
      TypeAnswer: typeQuestionData[0].answer,
    };
    // console.log('Collateral ANSWERS IN CREATOR PROFILE ', QnA);
    yield put({
      type: 'GET_COLLATERAL_ANSWERS_IN_USER_COLLATERAL',
      values: QnA,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* setStyleAnswer(value) {
  try {
    // console.log('styleAnswer ', value);
    yield put({ type: 'UPDATE_STYLE_ANSWER', values: value.payload });
  } catch (error) {
    console.log(error);
  }
}
export function* setTypeAnswer(value) {
  try {
    // console.log('styleAnswer ', value);
    yield put({ type: 'UPDATE_TYPE_ANSWER', values: value.payload });
  } catch (error) {
    console.log(error);
  }
}
export function* resetCollateralQnA() {
  try {
    // console.log('styleAnswer ', value);
    yield put({ type: 'UPDATE_RESET_COLLATERAL_QNA' });
  } catch (error) {
    console.log(error);
  }
}
