import React from 'react';

export default class MonthYearDP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: false,
    }
  }

  enableHideDropdown = () => {
    this.setState({ enable: !this.state.enable });
  }

  onSelect = (e) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e);
    }
    this.enableHideDropdown();
  }

  render() {
    const { Title, List, Key, error, disabled } = this.props;

    return (
      <>
        <div 
          className={`select-styled text-black ${error === true ? "border-error" : ""}`} 
          onClick={() => {
            if (!disabled) {
              this.enableHideDropdown()
            }
          }}
        >{Title}</div>
        {/* <ErrorSpan
          error={error}
          message={message}
        /> */}
        {this.state.enable ?
          <ul className="hideList select-options" style={{ display: "block" }}>
            {/* <li rel="hide">Month</li> */}
            {List && List.map((obj, index) => (
              <li key={index} rel={
                Key && Key !== '' ? obj[Key]: obj
              } onClick={() => this.onSelect(obj)}>
                {Key && Key !== '' ? obj[Key]: obj}
              </li>
            ))
            }
          </ul> : <></>
        }
      </>
    )
  }
}