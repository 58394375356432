import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
// import { getAPI } from '../../../utils/API';
// import { getMsgTime } from '../Common/Common/FileRenderMessage';
// import socketIOClient from 'socket.io-client';
// import { ChatBaseUrl } from '../../../utils/BaseUrl';
// import { getDateTime } from '../../../utils/DateMethod';
// import CurrentProgress from './../../../Assets/images/current_progress.png';
// import { separateNumber } from '../../../utils/Helperfunctions';
import CurrentProgressCard from "./CurrentProgressCard";
// import BootstrapLoader from '../Common/BootstrapLoader/BootstrapLoader';
import Milestones from "./Milestones";
import Proposals from "./Proposals";
import ArtistList from "./ArtistList";
import { capitalizeWordFirstLetter, limitString } from "../../../utils/Helperfunctions";
import ClientCardInLeftPanel from "./AdminChatComponents/ClientCardInLeftPanel";
import { BaseImageUrl } from "../../../utils/BaseUrl";
import { postAPI } from "../../../utils/API";
// import { mobScheduleCall } from '../../../store/action/addProject.action';

class ChatLeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      milestones: [],
      delivered_status: "",
      user_data: [],
      CurrentMilestone: [],
      scheduling: false,
    };
  }

  getArtistList = async (obj) => {
    // pl.post_project_id, pl.project_name, pl.project_status
    const inAdminChat = this.props.location.pathname.includes("/adminChat");
    // console.log('get artist list....', this.props.ProjectId === obj.post_project_id)
    if (this.props.ProjectId === obj.post_project_id) {
      return;
    }
    this.props.resetChatData();
    await this.setState({ showDropdown: false });
    // this.props.history.push(`/${inAdminChat ? 'adminChat' : 'chat'}/${obj.post_project_id}/${obj.project_slug}`)
    this.props.history.push(`/projects/${obj.post_project_id}/project_room`);
  };

  handleShow = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  inviteMoreArtist = () => {
    this.props.resetData();
    this.props.updateInviteMoreStatus(true, true);
    this.props.history.push({
      pathname: "/profile_selection",
      state: {
        project_id: this.props.ProjectId,
      },
    });
  };

  scheduleCallWithManager = (e, managerId) => {
    e.preventDefault();

    const { ProjectId, Me } = this.props;
    if (window.confirm("Are you sure you want to schedule call with manager?")) {
      this.setState({ scheduling: true });
      const apiData = {
        post_project_id: ProjectId,
        date: null,
        meeting_with_id: managerId,
        meeting_type: "user_to_managar",
        user_type: Me.CurrentRole === 1 ? "Client" : "Creator",
      };

      postAPI("/project/set-meeting", apiData)
        .then((res) => {
          this.setState({ scheduling: false });
          alert(`Your request to schedule a call with manager has been received. We'll be in touch with you shortly.`);
        })
        .catch((error) => {
          alert("Something went wrong, please try again later.");
          console.log(error);
        });
    }
  };

  getUserId = (CurrentChat, Artist) => {
    let data = {
      user_id: "",
      username: "",
      proposal_id:""
    }
    Artist.forEach((obj) => {
      if (obj?.chat_connection_id === CurrentChat?.chat_connection_id) {
        data.user_id = obj?.user_id
        data.username = obj?.username
        data.proposal_id = obj?.proposal_id
      }
    });
    this.props.handleSetUserId(data);
  };

  componentDidMount() {
    this.props.takeFunction(this.scheduleCallWithManager);
    if (this.props?.CurrentChat && this.props.Artist) {
      this.getUserId(this.props?.CurrentChat, this.props.Artist);
    }
  }

  componentDidUpdate = (nextProps) => {
    if (this.props?.CurrentChat != nextProps?.CurrentChat) {
      this.getUserId(this.props?.CurrentChat, this.props.Artist);
    }
  };

  render() {
    const {
      Artist,
      Projects,
      ProjectName,
      ProjectStatus,
      ProjectType,
      isAdmin,
      CurrentChat,
      CurrentRole,
      milestones,
      proposal,
      delivered_status,
      CurrentMilestone,
      Client,
      location,
      history,
      clientBriefDetails,
    } = this.props;
    const isAdminProject = ProjectType === "post_project_admin";
    const inAdminChat = location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && inAdminChat;
    const { project_data } = clientBriefDetails ? clientBriefDetails : {};

    const titleName = capitalizeWordFirstLetter(ProjectName);
    return (
      <React.Fragment>
        <Helmet>
          <title>{titleName ? `${titleName} Messages || IndieFolio` : ""}</title>
        </Helmet>

        {/* mobile-view */}
        {/* <div className="col-12 mobile-only-show">
          <div className="row">
            {Artist?.length ? (
              <ArtistList socket={this.props.socket} history={history} handleSetUserId={this.props?.handleSetUserId} />
            ) : null}
          </div>
        </div> */}
        {/* mobile-view-end */}


        <div className="col-lg-3 mb-2">
          <div className="row">
            {isAdminAndInAdminChat && Client ? <ClientCardInLeftPanel Client={Client} /> : null}

            {Artist?.length ? (
              <>
                <ArtistList project_data={project_data} socket={this.props.socket} history={history} handleSetUserId={this.props?.handleSetUserId} {...this.props} />
              </>
            ) : null}

            {/* {project_data?.manager_id ? (
                <div className="col-12 px-0">
                  <div className="card-wrapper mb-10 chat-box-status active">
                    <div className="card">
                      <div className="card-body px-2 py-3">
                        <h5 className="pl-2 text-dark-black font-weight-600 font-18">Talent Manager</h5>
                        <div className="row m-0 d-flex pt-2 align-items-center">
                          <div className="col-2 pl-2 mob-pl-6 mob-pr-0">
                            <div className="">
                              <p className="mb-0 basic_profile chat_user">
                                <img src={BaseImageUrl + project_data?.manager_image} alt="profile" title="" width="35" />
                              </p>
                            </div>
                          </div>
                          <div className="col-5 pl-2">
                            <div className="dflex mb-1">
                              <div className="w-100">
                                <div className="dflex">
                                  <h2 className="text-dark-black text-capitalize m-0 font-16 font-weight-600 mob-font-14">
                                    {project_data?.manager_name}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="chat_left_user_html d-flex align-items-center justify-content-between">
                              <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
                                {" "}
                                {project_data?.manager_email}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-5 pl-2">
                              <button
                                className="nav-link main-btn pt-2 pb-2 text-white font-12 ipad-font-10 ml-auto"
                                disabled={this.state.scheduling}
                                onClick={(e) => this.scheduleCallWithManager(e, project_data?.manager_id)}
                              >
                                Schedule Call
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null} */}

            {/* {ProjectStatus !== 5 && CurrentRole === 1 && !isAdminProject ? (
                <CurrentProgressCard
                  CurrentChat={CurrentChat}
                  delivered_status={delivered_status}
                  proposal={proposal}
                  CurrentMilestone={CurrentMilestone}
                />
              ) : null} */}

            {/* {(ProjectStatus === 6 || ProjectStatus === 7) && milestones && milestones.length > 0 ? (
                  <Milestones />
                ) : ProjectStatus === 5 && milestones && milestones?.length > 0 ? (
                  <Proposals />
                ) : null} */}

                {/* ============manager ================ */}

            {/* {project_data?.manager_id ? (
              <div className="col-12 px-0 d-none">
                <div className="card-wrapper mb-10 chat-box-status active">
                  <div className="card">
                    <div className="card-body px-2 py-3">
                      <h5 className="pl-2 text-dark-black font-weight-600 font-18">Talent Manager</h5>
                      <div className="row m-0 d-flex pt-2 align-items-center">
                        <div className="col-2 pl-2 mob-pl-6 mob-pr-0">
                          <div className="">
                            <p className="mb-0 basic_profile chat_user">
                              <img src={BaseImageUrl + project_data?.manager_image} alt="profile" title="" width="35" />
                            </p>
                          </div>
                        </div>
                        <div className="col-5 pl-2">
                          <div className="dflex mb-1">
                            <div className="w-100">
                              <div className="dflex">
                                <h2 className="text-dark-black text-capitalize m-0 font-16 font-weight-600 mob-font-14">
                                  {project_data?.manager_name}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="chat_left_user_html d-flex align-items-center justify-content-between">
                            <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
                              {" "}
                              {project_data?.manager_email}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-5 pl-2">
                              <button
                                className="nav-link main-btn pt-2 pb-2 text-white font-12 ipad-font-10 ml-auto"
                                disabled={this.state.scheduling}
                                onClick={(e) => this.scheduleCallWithManager(e, project_data?.manager_id)}
                              >
                                Schedule Call
                              </button>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  const postProject = store.postProject;
  return {
    Artist: chat.Artist,
    Projects: chat.Projects,
    ProjectId: chat.ProjectId,
    ProjectName: chat.ProjectName,
    ProjectStatus: chat.ProjectStatus,
    Messages: chat.Messages,
    CurrentChat: chat.CurrentChat,
    ProjectType: chat.ProjectType,
    Client: chat.Client,
    isLastMilestonePaymentDone: chat.isLastMilestonePaymentDone,
    isMultipleProposal: chat.isMultipleProposal,

    CurrentRole: globalReducer.Me.CurrentRole,
    isAdmin: globalReducer.Me.isAdmin,

    milestones: postProject.milestones,
    proposal: postProject.proposal,
    review_proposal_data: postProject.review_proposal_data?.proposal,
    active_milestones: postProject.active_milestones,
    delivered_status: postProject.delivered_status,
    user_data: postProject.user,
    CurrentMilestone: postProject.CurrentMilestone,
    proposalStatus: postProject.proposalStatus,
    loading: globalReducer.loading,
    postProjectClientBreif: store.postProject.postProjectClientBreif,
    Me: globalReducer.Me,
  };
};
const mapDispatch = (dispatch) => ({
  OpenPopup: (text) => dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  SetCurrentProposal: (proposal_id, project_id, reviewStatus) =>
    dispatch({ type: "SET_CURRENT_PROPOSAL", payload: { proposal_id, project_id, reviewStatus } }),
  SetCurrentMileStoneID: (milestone_id) => dispatch({ type: "SET_CURRENT_MILESTONE_ID", payload: { milestone_id } }),
  LoadReviewProposalData: (values) => dispatch({ type: "LOAD_REVIEW_PROPOSAL_DATA", payload: values }),
  loadChat: (projectId, projectName, projectStatus) =>
    dispatch({ type: "UPDATE_LOAD_CHAT", payload: { id: projectId, name: projectName, project_status: projectStatus } }),
  loadChatMessages: (connection_id) => dispatch({ type: "UPDATE_LOAD_CHATMESSAGES", payload: connection_id }),
  OnChange: (text) => dispatch({ type: "ON_FIELDCHANGE_SAGA", payload: text }),
  loadLatestProposals: (projectId) => dispatch({ type: "LOAD_LATEST_PROPOSAL", payload: projectId }),
  resetChatData: () => dispatch({ type: "RESET_CHAT_DATA", payload: null }),
  loadProjectStatus: (projectId) => dispatch({ type: "LOAD_PROJECT_STATUS", payload: { projectId } }),
  resetUnreadMsgInCreatorObj: (artistObj) => dispatch({ type: "RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ", payload: artistObj }),
  resetData: (id) => dispatch({ type: "RESET_ALL_DATA", payload: id }),
  updateInviteMoreStatus: (value, isFormChat) =>
    dispatch({ type: "UPDATE_INVITE_MORE_STATUS_SAGA", payload: { value, isFormChat } }),
  removeNotificationInProjectList: (projectId) => dispatch({ type: "REMOVE_NOTIFICATION_FORM_PROJECT_LIST", payload: projectId }),

  // resetNotification: (chat_connection_id) => dispatch({type: 'RESET_NOTIFICATION_SAGA', payload:chat_connection_id })
});
export default withRouter(connect(mapState, mapDispatch)(ChatLeftPanel));
