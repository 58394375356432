import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChatImageBaseUrl } from "../../../../../utils/BaseUrl";
import Loader from "../../../../authorized/Common/Common/Loader";
import { handleFileType } from "../CommonFunctionsNew/CommonFunctionsNew";
import CarosalCommon from "../CommonComp/CarosalCommon";

const AttachmentForClient = () => {
  const [attachments, setAttachments] = useState([]);
  const [filesUploading, setfilesUploading] = useState(false);
  const [showCarosalCommon, setShowCarosalCommon] = useState(false);
  const [particularAttachment, setParticularAttachment] = useState("");

  const _relevent_projects = useSelector((state) => state.releventProjects);
  const attachmentsIs = _relevent_projects?.link_image;

  useEffect(() => {
    setfilesUploading(false);
    if (attachmentsIs) {
      let array = attachmentsIs.map((item, i) => {
        return { ...item, index: i+1 };
      });
      setAttachments(array)
    }
  }, [attachmentsIs]);

  return (
    <>
      {/* uploaded image */}
      <div className="col-lg-12 px-0 mb-4">
        <div className="row mx-0 upload_image_box d-flex flex-wrap ">
          {filesUploading ? (
            <Loader style={{ margin: "0 auto" }} />
          ) : attachments?.length ? (
            attachments?.map((obj, index) => {
              const data = ["gif", "jpg", "jpeg", "png"];
              return (
                <div className="col-lg-3 mb-4">
                  <div className="upload_image upload_image_delete border" key={index}>
                    <img
                      className="rounded image cursor-pointer"
                      src={
                        handleFileType(obj?.name, ChatImageBaseUrl)
                        // data?.includes(obj?.name?.split('.')[obj?.name?.split('.')?.length -1]) ?
                        // ChatImageBaseUrl + obj.name :
                        // obj?.name?.includes(".pdf") ?
                        // PdfImage :
                        // obj?.name?.includes(".zip")?
                        // ZipImage :
                        // obj?.name?.includes(".ppt")?
                        // PPtImage:
                        // DocImage
                      }
                      // onClick={() => window.open(`${ChatImageBaseUrl + obj.name}`, '_blank', 'noopener,noreferrer')}
                      onClick={() => {
                        setParticularAttachment(obj);
                        setShowCarosalCommon(true);
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-sm-12 text-center">
              <span className="discription_common pl-4">No Attachments Found...</span>
            </div>
          )}
          {/* <div className='upload_image upload_image_blank mb-4'>
                    <p className='mb-0 plus_icon'>+</p>
                </div> */}
        </div>
      </div>

      {showCarosalCommon ? (
        <CarosalCommon
          particularAttachment={particularAttachment}
          showCarosalCommon={showCarosalCommon}
          setShowCarosalCommon={setShowCarosalCommon}
          attachments={attachments}
        />
      ) : null}
    </>
  );
};

export default AttachmentForClient;
