import React, { useEffect, useState } from "react";
import { releventProjects, relevent_projects } from "../../../../../store/action/proposal.action";
import { useDispatch, useSelector } from "react-redux";
import UploadPlusIcon from "../../../../../Assets/upgrade-images/submit_proposal/upload_media_plus.png";
import FeaturedImage1 from "../../../../../Assets/upgrade-images/submit_proposal_fixed_cost/rectangle_image1.png";
import FeaturedImage2 from "../../../../../Assets/upgrade-images/submit_proposal_fixed_cost/rectangle_image2.png";
import EditIcon from "../../../../../Assets/upgrade-images/submit_proposal/edit.png";
// import ReleventProjectsModal from "../../PopopCreator/ReleventProjects";
import { getAPI } from "../../../../../utils/API";
import { getThumbnailImage } from "../../../../../utils/Helperfunctions";
import { isViewableFile, renderDifferentFilesThumbnail } from "../../../Common/Common/FileRenderMessage";
import TrashImage from "../../../../../Assets/upgrade-images/submit_proposal_fixed_cost/trash.png";
import { Baseurl } from "../../../../../utils/BaseUrl";

const ReleventProjectForclient = (props) => {
  const [userName, setUserName] = useState("");
  const [search, setSearch] = useState("");
  const _ProjectsIs = useSelector((state) => state.releventProjects);
  const userData = useSelector((state) => state.globalReducer.Me);
  const creatorFavProIs = _ProjectsIs?.releventProjectIs;
  const project_ids = _ProjectsIs?.project_ids;
  const dispatch = useDispatch();

  const getcollateralList = async () => {
    const res = await getAPI("/users/user-profile");
    const userNameIs = res?.data?.data?.username;
    const data = { userNameIs, search };
    setUserName(userNameIs);
    dispatch(releventProjects(data));
  };

  useEffect(() => {
    if (!props?.unAuthorized) {
      getcollateralList();
    }
  }, []);

  useEffect(() => {
    if (!props?.unAuthorized) {
      getcollateralList();
    }
  }, [search]);

  const onClickViewProjects = (ele) => {
    let obj = {
      project_data: ele,
      username:props?.CurrentRole == 2 ? userData?.Username : props?.creatorUsername
    }

    if (!obj.project_data) {
      alert("No Projects for the Selected Collateral");
      return;
    }
    dispatch({ type: "UPDATE_CREATOR_PUBLIC", values: true });
    dispatch({
      type: "LOAD_PROJECT_DATA",
      payload: { id: obj.project_data._id },
    });
    dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} });
    dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "ViewModal" } });
  };

  return (
    <>
      {/* description */}
      {/* <div className="col-lg-12 px-0 py-3">
            <p className="discription_common">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
            laoreet est augue faucibus ac. Est morbi cras nisl, non quis massa
            mattis sit arcu. Facilisis purus vitae volutpat tincidunt consectetur
            pulvinar convallis sed. Nulla risus sit in enim nam aliquam sapien
            phasellus rhoncus.
            </p>
        </div> */}

      {/* cards */}
      <div className="col-lg-12 px-0">
        <div className="row">
          {/* cards */}
          {creatorFavProIs?.length ? (
            creatorFavProIs?.map((obj, index) => {
              return (
                <div className="col-lg-4 mb-4 mb-lg-0 featured_project_card" key={index}>
                  <div
                    class="card featured_card cursor-pointer"
                    onClick={() => {
                      // if (!props?.unAuthorized) {
                      //   window.open(`/creator/${props?.CurrentRole == 2 ? userData?.Username : props?.creatorUsername}/${obj?._id}/${obj?.title}`, '_blank', 'noopener,noreferrer')
                      // }
                      onClickViewProjects(obj);
                    }}
                  >
                    <RenderImage images={obj.images_or_links} />
                    <div class="card-body featured_card_body">
                      <div className="row m-auto featured_card_body_text p-3">
                        <p class="card_font mb-0">{obj?.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="discription_common pl-3">No Relevant Projects Found...</span>
          )}
        </div>
      </div>
      {/* end cards */}
      <div className="edit_proposal d-flex align-items-center col-lg-12 px-0 py-4">
        {/* <img src={EditIcon} className="icon_img mr-1"/> */}
        {/* <p className='mb-0 mr-3 edit_proposal_text anchor_text'>Edit Proposal Type</p> */}
      </div>

      {/* Modal */}
      {/* <ReleventProjectsModal
        {...props}
        projects={_ProjectsIs}
        userName={userName}
        handleSearch={handleSearch}
        search={search}
      /> */}
    </>
  );
};

const RenderImage = ({ images }) => {
  let thumbnailObj;
  const thumbnailIs = images?.find((el) => el?.isThumbnail);
  if (thumbnailIs) {
    thumbnailObj = thumbnailIs;
  } else {
    thumbnailObj = images[0];
  }
  // const {type}=image;
  // const {value}=image;
  const type = thumbnailObj?.type;
  const value = thumbnailObj?.value;
  const [thumbnail, setThumbnail] = useState(null);
  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url);
    };
    if (type === "url") {
      getThumbnailImageFunc(value);
    } else {
      setThumbnail(value);
    }
  }, [type, value]);

  return (
    <div
      className="project-view"
      style={{
        backgroundImage: `url("${
          isViewableFile(thumbnailObj.value) ? renderDifferentFilesThumbnail(thumbnailObj.value) : thumbnail
        }")`,
      }}
    ></div>
  );
};

export default ReleventProjectForclient;
