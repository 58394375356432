import React, { useEffect, useState } from "react";
import { postAPI, putAPI } from "../../../../../utils/API";
import { fireApi } from "../../../../../utils/fireApiPrecheck";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import { sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";

const RetHourlyRequest = (props) => {
  const [request, setRequest] = useState("");
  const [loader, setLoader] = useState(false);
  const [requestValid, setRequestValid] = useState(false);

  const formValidation = () => {
    let isValid = true;
    if (!request?.trim().length) {
      setRequestValid(true);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (props.retHourlyReqTYpe === "approve" || formValidation()) {
      handleSubmitRequestChange();
    }
  };

  const handleSubmitRequestChange = async () => {
    setLoader(true);

    try {
      let res = "";
      if (props.retHourlyReqTYpe === "approve") {
        let obj = {
          work_log_id: props?.work_log_id,
        };
        res = await putAPI("/proposal/approve-worklog-hour", obj);
      } else {
        let obj = {
          note: sanitiseSummernoteText(request),
        };
        if (props.retHourlyReqTYpe === "rework") {
          // console.log("333 = ",props?.reworkId)
          obj.milestone_complete_id = props?.reworkId;
        } else {
          obj.work_log_id = props?.work_log_id;
        }
        res = await putAPI("/proposal/add-contested-workLog", obj);
      }
      // console.log("res =", res);
      if (res) {
        setLoader(false);
        props.setRetHourlyReq(false);
        alert(
          `${
            props.retHourlyReqTYpe === "approve"
              ? "Hours approved successfully"
              : props.retHourlyReqTYpe === "rework"
              ? "Rework request submitted successfully"
              : "Contest hours submitted successfully"
          }`
        );
        props.setViewUploadDel(false);
        props.handleCallApiAgain();
      } else {
        setLoader(false);
        alert("Something went wrong please try again later");
      }
    } catch (err) {
      setLoader(false);
      alert("Something went wrong please try again later");
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setRetHourlyReq(false);
    }
  };

  const user = props?.userData;
  const name = user?.first_name + " " + user?.last_name;
  return (
    <React.Fragment>
      <section className="ask_question">
        <div
          className={`modal fade modal_fade_bg ${props?.retHourlyReq ? "show" : ""}`}
          id="reviewConfirm"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.retHourlyReq ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm">
            <div className="modal-content">
              <div className="modal-header  d-block">
                <div className="row header_title">
                  <div className="col-lg-12">
                    <h5 className="modal-title modal_title mb-3" id="exampleModalLabel">
                      {props.retHourlyReqTYpe === "approve"
                        ? "Approve hours?"
                        : props.retHourlyReqTYpe === "rework"
                        ? "Request Rework"
                        : "Contest Hours"}
                    </h5>
                    {props.retHourlyReqTYpe === "approve" ? null : (
                      <p className="text_gray_1100_w_600 mb-0">
                        To: <span className="font_semi_bold_w_600">{name}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-body py-4">
                <div className="row">
                  {props.retHourlyReqTYpe === "approve" ? (
                    <div className="form-group col-sm-12">
                      <p className="text_gray_1100_w_600">Are you sure you want to approve hours of this worklog.</p>
                    </div>
                  ) : (
                    <div className="form-group col-sm-12">
                      <p className="text_gray_1100_w_600 mb-2">
                        {props.retHourlyReqTYpe === "rework" ? "Your Request" : "Your Comment"}
                      </p>
                      <textarea
                        className="form-control  request_textarea modal_scroll mb-1 mt-1"
                        value={request}
                        onChange={(e) => {
                          setRequest(e.target.value.substring(0, 300));
                          setRequestValid(e.target.value?.length > 0 ? false : true);
                        }}
                        placeholder="comment ..."
                        id="comment"
                      ></textarea>
                      <span className="font-weight-500 text-secondary">{request?.length}/300</span>
                      {requestValid ? (
                        <p className="text-center" style={{ color: "red" }}>
                          Field Required
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer br_tp">
                <button type="button" className="btn btn_outline_sm" onClick={() => props.setRetHourlyReq(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn_common_sm" onClick={handleSubmit}>
                  {loader ? <Loader isCreateModal={true} /> : props.retHourlyReqTYpe === "approve" ? "Approve" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RetHourlyRequest;
