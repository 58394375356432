import React from 'react';
import { connect } from 'react-redux';
// import LocationDP from './../LocationDP';
import SingleSelectDP from '../../Common/Common/SingleSelectDP';
// import BtnLoader from '../../Common/Common/BtnLoader';
import Loader from './../../../unauthorized/Common/Loader/Loader';

class Specialization extends React.Component {
  state = {
    enable: false,
    secondarySection: '',
    primaryExp:"",
    primarySpecExpError:false,
    primarySpecExpErrorMsg:''
  };

  handlevalidate = () =>{
    let {primaryExp} = this.state;
    // if(primaryExp<0 || !primaryExp){
    //   this.setState({primarySpecExpError:true,primarySpecExpErrorMsg:'Add Experience to continue'})
    //   return false
    // }
     if(primaryExp>60 || primaryExp<0 || primaryExp==null || primaryExp==undefined ){
      this.setState({primarySpecExpError:true,primarySpecExpErrorMsg:"Experience value should be between 0 - 60"})
    }
    else{
      return true
    }
  }

  componentDidMount(){
    this.setState({
      primaryExp:this.props.PrimaryFieldWorkExperience
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    if(this.handlevalidate()){
      this.props.UpdateUserSpecialization({
        primary_specialization_experience:this.state.primaryExp,
        specialization: this.props.UpdateSpecialization,
        primary_specialization: this.props.PrimaryFieldWork,
      });
      this.props.UpdateUserSpecializationEXP(this.state.primaryExp)
      this.props.closePopup();
    }
    
  };
  ShowList = () => {
    this.setState({ enable: !this.state.enable });
  };

  // onBlur = () => {
  //   this.setState({ enable: false });
  // }

  onSelect = (obj) => {
    let Specilizations = [...this.props.UpdateSpecialization];

    if (Specilizations.length < 4) {
      let i = -1;
      Specilizations.forEach((s, k) => {
        if (s.name === obj.name) {
          i = k;
        }
      });

      if (i === -1) Specilizations.push(obj);
      else {
        alert(`${obj.name} is already selected`)
        return
      }

      this.props.onFieldChange({
        name: 'SPECIALIZATION_UPDATE',
        value: Specilizations,
      });
    }

    this.setState({
      enable: false,
      secondarySection: '',
    });
    this.reloadSpecilization('');
  };

  deleteItems = (obj) => {
    let Specilizations = [...this.props.UpdateSpecialization];
    let i = -1;

    Specilizations.forEach((s, k) => {
      if (s.name === obj.name) {
        i = k;
      }
    });

    if (1 !== -1) Specilizations.splice(i, 1);

    this.props.onFieldChange({
      name: 'SPECIALIZATION_UPDATE',
      value: Specilizations,
    });

    this.setState({
      enable: false,
      secondarySection: '',
    });
    this.reloadSpecilization('');
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, enable: true });
    this.reloadSpecilization(e.target.value);
  };

  reloadSpecilization = (value) => {
    this.props.loadSpecialization(value);
  };

  closePopup = () => {
    this.props.onFieldChange({
      name: 'SPECIALIZATION_UPDATE',
      value: [...this.props.Specilizations],
    });
    this.props.onFieldChange({
      name: 'BASIC_PRIMARYFIELDWORK',
      value: this.props.defaultPrimaryFieldWork,
    });
    this.props.closePopup();
  };

  render() {
    let {
      PrimaryFieldWork,
      saving,
      PrimarySpecializationError,
      UpdateSpecializationError,
    } = this.props;

    let {primarySpecExpError} = this.state;

    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditSpecializationsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">
                          Specializations
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <form onSubmit={(e) => this.onSubmit(e)}>
                        <div className="row m-0">
                          <div className="form-group mb-4 col-sm-12 p-0 mob-p-0 mob-w-100">
                            <label
                              className="label font-weight-600 font-14 mob-font-13"
                              htmlFor="last"
                            >
                              Choose your primary specialization
                            </label>{' '}
                            <br />
                            <SingleSelectDP
                              Selected={PrimaryFieldWork}
                              List={this.props.Specialization}
                              // hideList={2}
                              onChange={(e) => {
                                this.reloadSpecilization(e);
                              }}
                              onRemove={(e) => {
                                this.props.onDPChange({
                                  name: 'BASIC_PRIMARYFIELDWORK',
                                  value: '',
                                });
                              }}
                              infoText="Eg: Graphic Design, Illustration"
                              onSelect={(e) => {
                                this.props.onDPChange({
                                  name: 'BASIC_PRIMARYFIELDWORK',
                                  value: e,
                                });
                              }}
                              notListed={this.props.UpdateSpecialization}
                              error={PrimarySpecializationError}
                            />
                            {PrimarySpecializationError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add primary field to continue
                              </span>
                            )}
                          </div>

                          <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                          <label
                            className="mb-0 label font-weight-600 font-14 mob-font-13 position-relative"
                            htmlFor="perChaarge"
                          >
                            Enter number of Experience in Primary Specialization
                          </label>
                          <div className="row work-experience onboarding_dropdown_wrapper my-3">
                            <div className="col-sm-12 position-relative d-flex align-items-center position-relative">
                              <input
                                type="number"
                                className={`form-control email price_input  mob-w-100 ${
                                  primarySpecExpError ? "border-error" : ""
                                } `}
                                name="perCharge"
                                value={this.state.primaryExp}
                                onChange={e =>this.setState({primaryExp:e.target.value,primarySpecExpError:false})}
                                autoComplete="off"
                                placeholder="Experience (in Years)"
                              />
                              {/* Dropdown */}
                              <div className="position-relative onboarding_dropdown_option">
                                <div className="">
                                  <button
                                    className="btn btn-secondary remove-arrow dropdown-toggle cursor-default"
                                    type="button"
                                    id="dropdownMenuButton"
                                  >
                                    Years
                                  </button>
                
                                </div>
                              </div>
                            </div>
                          </div>
                          {primarySpecExpError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                {this.state.primarySpecExpErrorMsg}
                              </span>
                            )}
                        </div>

                          <div className="form-group mb-4 col-sm-12 p-0 mob-p-0 mob-w-100">
                            <label
                              className="label font-weight-600 font-14 mob-font-13"
                              htmlFor="last"
                            >
                              Choose your secondary specializations
                            </label>
                            <div
                              className={`select-tags d-flex align-items-center flex-wrap ${
                                UpdateSpecializationError &&
                                UpdateSpecializationError === true
                                  ? 'border-error'
                                  : ''
                              }`}
                            >
                              <div>
                                {this.props.UpdateSpecialization.map(
                                  (obj, index) => (
                                    <span
                                      className="selected-tags common-tags py-10 px-15"
                                      key={index}
                                    >
                                      {obj.name}{' '}
                                      <i
                                        className="fas fa-times ml-1 font-13"
                                        onClick={() => this.deleteItems(obj)}
                                      ></i>
                                    </span>
                                  )
                                )}
                                {this.props.UpdateSpecialization.length < 4 && 
                                  <span style={{ display: 'inline-block' }}>
                                    <input
                                      type="text"
                                      className="w-230 form-control email pl-3 tags-input py-3"
                                      placeholder="Eg: Photoshop, Illustrator"
                                      autoComplete="off"
                                      name="secondarySection"
                                      value={this.state.secondarySection}
                                      onChange={this.handleOnChange}
                                      onClick={this.ShowList}
                                      // onBlur={this.onBlur}
                                    />
                                  </span>
                                 }
                              </div>
                              {this.props.Specialization.length > 0 &&
                              this.state.enable === true ? (
                                <ul
                                  className={`select-options select-top100 hideList1`}
                                  style={{ display: 'block' }}
                                >
                                  <li rel="hide">Choose here</li>
                                  {this.props.Specialization &&
                                    this.props.Specialization.map(
                                      (obj, index) => {
                                        return PrimaryFieldWork !== '' &&
                                          PrimaryFieldWork !== obj.name ? (
                                          <li
                                            key={index}
                                            onClick={() => this.onSelect(obj)}
                                          >
                                            {obj.name}
                                          </li>
                                        ) : (
                                          <></>
                                        );
                                      }
                                    )}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </div>
                            {UpdateSpecializationError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add specialization to continue
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="form-group text-right pt-3 mob-pr-0 mob-mb-30 mob-pt-0">
                          <button type="submit" className="main-btn px-4" disabled={saving}>
                            {saving === true
                              ?
                              "Saving..."
                              :
                              "Save"
                            }
                          </button>
                        </div> */}
                      </form>
                    </div> 
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top">
                    <div className="form-group text-right pr-3 pt-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-danger col-sm-2 col-5 mx-2 mb-2 font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className={`main-btn col-sm-3 col-6 mx-2 mb-2 ${saving ? 'p-0' : ''}`}
                        disabled={saving}
                        // data-dismiss="modal"
                        onClick={this.onSubmit}
                      >
                        {saving === true ? <Loader profileCreator="true"/> : 'Save Changes'}
                      </button>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;

  return {
    saving: globalReducer.saving,
    closeModal: creatorProfile.closeModal,
    PrimaryFieldWork: creatorProfile.BasicDetails.PrimaryFieldWork,
    PrimarySpecializationError:
      creatorProfile.BasicDetails.PrimarySpecializationError,
    UpdateSpecialization: creatorProfile.UpdateSpecialization,
    UpdateSpecializationError: creatorProfile.UpdateSpecializationError,
    Specialization: creatorProfile.Specialization,
    Specilizations: creatorProfile.Specilizations,
    defaultPrimaryFieldWork: creatorProfile.PrimaryFieldWork,
  };
};


const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onDPChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UpdateUserSpecialization: (e) =>
    dispatch({ type: 'UPDATE_SPECIALIZATION_SAGA', payload: e }),
  loadSpecialization: (e) =>
    dispatch({ type: 'LOAD_CREATOR_SPECIALIZATION_SAGA', payload: e }),
  UpdateUserSpecializationEXP : (e) => dispatch({type:'UPDATE_PRIMARYSPECIALIZATION_EXP',values:e})
});

export default connect(mapState, mapDispatch)(Specialization);
