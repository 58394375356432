import {GET_INCOMPLETE_BRIEFS,GET_DRAFT_PROJECTS,GET_ONGOING_PROJECTS,GET_CLIENT_COMPLETED_PROJECT,GET_CLIENT_CLOSED_PROJECT,GET_INCOMING_BRIEFS,DASHBOARD_DATA_LOADING,GET_BRIEF_DATA, UPDATE_VIEW_INCOMING_BRIEF,
  GET_COLLATERAL_BASED_PROJECTS, GET_DEFAULT_COLLATERAL_BASED_PROJECTS, UPDATE_INCOMING_BRIEF,GET_JOBS_DONE,
  UPDATE_DELETE_INCOMPLETE_BRIEF, GET_PROPOSAL_PROJECT,GET_CREATOR_ONGOING_PROJECT,GET_CREATOR_COMPLETED_PROJECT,GET_CREATOR_CLOSED_PROJECT,UNREAD_MSG_DASHBOARD,GET_USER_MILESTONES, CHANGE_PROJECT_TYPE,LOAD_ALL_PROJECTS, RESET_COLLATERAL_PROJECTS_DATA, UPDATE_DELETE_CREATOR_PROJECT,UPDATE_DELETE_CREATOR_PROJECT_FROM_ALL_PROJECTS, CLEAR_DASHBOARD_DATA  } from '../action/dashboard.action';

const initState = {
  CurrentRole: 0,
  UserReview: 4.2,
  UserReviewCount: 48,
  ProjectCompleted: 4,
  AvgResponseTime: 3.1,
  RepeatHire: 2,
  UpcomingMilestone: 1,
  Milestones: [],
  JobsData:[],
  PopularServices: [
    {title: 'Web Design',
    price: '12,000'},
    {title: 'Illustration',
    price: '15,000'},
    {title: 'Packaging Design',
    price: '26,000'},
  ],
  CompletedProjects: 1,
  ClosedProjects: 1,
  ClientCompletedProjects: [],
  ClientClosedProjects: [],
  Projects: [],
  IncompleteBrief: [],
  ProjectDrafts: [],
  // Client
  OngoingProjects: [],

  // Creator
  InComingBrief: [],
  CreatorOngoingProjects: [],
  CreatorCompletedProjects: [],
  CreatorClosedProjects: [],
  ProjectProposal: [],
  ProjectType: 'Active Projects',
  defaultCollateral: '',
  post_project_id: '',
  project_name: ' ',
  client_role: '',
  company_name: '',
  about: '',
  industry: '',
  website: '',
  work_type: '',
  minBudget: '',
  maxBudget: '',
  deadline: '',
  project_description: '',
  client: null,
  collateral_name: '',
  style: [],
  type: [],
  reference_files: [],
  reference_link: [],
  company_question: [],
  project_question: [],
  AllProjects: [],
  isLoading: false,
  number_hour:'',
  number_month:'',
  unread_msg_dashboard:{
    active_unread_msg:0,
    brief_unread_msg:0
  }
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};

  switch (type) {
    case GET_INCOMPLETE_BRIEFS: 
      return {
        ...state,
        IncompleteBrief:values,
      };

    case UPDATE_DELETE_INCOMPLETE_BRIEF: 
      return {
        ...state,
        IncompleteBrief: state.IncompleteBrief.filter(obj => obj.post_project_id !== values),
      };

      case GET_DRAFT_PROJECTS: 
      return {
        ...state,
        ProjectDrafts:values,
      };

      case GET_ONGOING_PROJECTS:
      return {
        ...state,
        OngoingProjects:values,
      };

      case GET_CLIENT_COMPLETED_PROJECT:
        return {
          ...state,
          ClientCompletedProjects:values,
          CompletedProjects:values.length,
        };
      case GET_CLIENT_CLOSED_PROJECT:
        return {
          ...state,
          ClientClosedProjects:values,
          ClosedProjects:values.length,
        }
        
      case GET_INCOMING_BRIEFS: 
      return {
        ...state,
        InComingBrief:values,
      };
        
      case DASHBOARD_DATA_LOADING: 
      return {
        ...state,
        isLoading:values,
      };

      case GET_PROPOSAL_PROJECT: 
      return {
        ...state,
        ProjectProposal:values,
      };

      case GET_CREATOR_ONGOING_PROJECT:
        return {
          ...state,
          CreatorOngoingProjects:values,
        };
      case GET_CREATOR_COMPLETED_PROJECT:
        return {
          ...state,
          CreatorCompletedProjects:values,
          CompletedProjects:values.length,
        };
      case GET_CREATOR_CLOSED_PROJECT :
        return {
          ...state,
          CreatorClosedProjects:values,
          ClosedProjects:values.length
        }
        case UNREAD_MSG_DASHBOARD :
          return {
            ...state,
            unread_msg_dashboard:{
              active_unread_msg:values.active_unread_msg,
              brief_unread_msg:values.brief_unread_msg
            }
          }
        
      case GET_USER_MILESTONES:
        return {
          ...state,
          Milestones:values.data,
        };
      case GET_JOBS_DONE:
        return {
          ...state,
          JobsData: values
        };

      case UPDATE_INCOMING_BRIEF:
        const updatedIncomingBrief = state.InComingBrief.filter(obj => obj.post_project_id !== values)
      return {
        ...state,
        InComingBrief: [...updatedIncomingBrief],
      };

      case GET_BRIEF_DATA:
        const valueIs = values.project_data;
        return {
          ...state,
          sub_type:values.project_data.sub_type,
          number_month:values.project_data.number_month,
          number_hour:values.project_data.number_hour,
          post_project_id: values.project_data._id,
          project_name: values.project_data.project_name,
          project_slug: values.project_data.project_slug,
          client_role:values.project_data.company_job_role,
          company_name:values.project_data.company_name,
          about:values.project_data.company_describes,
          industry:values.project_data.company_industry,
          website:values.project_data.company_add_link,
          work_type:values.project_data.company_work_type,
          minBudget: values.project_data.min_budget,
          maxBudget: values.project_data.max_budget,
          deadline: values.project_data.dealline,
          deadline_date:values.project_data.project_deadline_date,
          project_description: values.project_data.project_description,
          project_indust:values.project_data.company_industry,
          client: values.user_list[0],
          collateral_name: values.collateral.name,
          collateral_id: values.collateral._id,
          style: values.project_style_question,
          type: values.project_type_question,
          reference_files: values.project_data.project_add_reference_files,
          reference_link: values.project_data.project_add_reference_link,
          company_question: values.project_company_question,
          project_question: values.project_project_question,
          manager : { manager_id : valueIs.manager_id, manager_available : valueIs.manager_available, manager_email : valueIs.manager_email,
                    manager_image : valueIs.manager_image, manager_name : valueIs.manager_name, manager_phone_number : valueIs.manager_phone_number }
        }

      case UPDATE_VIEW_INCOMING_BRIEF:
        return {
          ...state,
          InComingBrief: state.InComingBrief.map(obj => {
            if(obj.post_project_id === values.id){
              obj.view_status = 1
              return obj
            } else {
              return obj
            }
          })
        }

      case GET_COLLATERAL_BASED_PROJECTS :
        return {
          ...state,
          Projects: values.project,
        }

      case GET_DEFAULT_COLLATERAL_BASED_PROJECTS : 
        return {
          ...state,
          Projects: values.Projects.project,
          defaultCollateral: values.collateralId,
        }
      
      
      case LOAD_ALL_PROJECTS:
        newState = Object.assign({}, state, {
          AllProjects: values,
        });
        return newState;

      case RESET_COLLATERAL_PROJECTS_DATA:
        newState = Object.assign({}, state, {
          Projects: [],
        });
        return newState;

      case CHANGE_PROJECT_TYPE : 
        return {
          ...state,
          ProjectType: values
        }

      case UPDATE_DELETE_CREATOR_PROJECT : 
        return {
          ...state,
          Projects: state.Projects.filter(el => el.project_data._id !== values.id),
        }

      case UPDATE_DELETE_CREATOR_PROJECT_FROM_ALL_PROJECTS : 
        return {
          ...state,
          AllProjects: state.AllProjects.filter(el => el.project_data._id !== values.id),
        }

        // test
      case CLEAR_DASHBOARD_DATA : 
        return {
          ...state,
          InComingBrief: [],
          ProjectProposal: [],
          CreatorOngoingProjects: [],
          CreatorClosedProjects: [],
          CreatorCompletedProjects: [],

          IncompleteBrief: [],
          ProjectDrafts: [],
          OngoingProjects: [],
          ClientCompletedProjects: [],
          ClientClosedProjects: [],
        }

    default:
      return state;
  }
};

export default reducers;