var pastYear = new Date().getFullYear();

export const jobType = [
    {name: "Fulltime"},
    {name: "Freelance"},
    {name: "Internship"},
    ]

var getCalYear = [];
var getCalYearNew = [];

for (let Years = 0; Years <= 50; Years++) {
    getCalYear.push(pastYear);
    getCalYearNew.push({name: pastYear});
    pastYear = pastYear - 1;
}

export const plus6years = (year) => {
    let plus6yearArr = []
    for (let i = (year + 6); i >= year; i--){
        plus6yearArr.push(i);
    }
    return plus6yearArr;
}

export const allPreviousYear = getCalYear;
export const allPreviousYearNew = getCalYearNew;

export const ExperienceMonth = [
    {
        number: 1,
        name: "January"
    },
    {
        number: 2,
        name: "February"
    },
    {
        number: 3,
        name: "March"
    },
    {
        number: 4,
        name: "April"
    },
    {
        number: 5,
        name: "May"
    },
    {
        number: 6,
        name: "June"
    },
    {
        number: 7,
        name: "July"
    },
    {
        number: 8,
        name: "August"
    },
    {
        number: 9,
        name: "September"
    },
    {
        number: 10,
        name: "October"
    },
    {
        number: 11,
        name: "November"
    },
    {
        number: 12,
        name: "December"
    }
]