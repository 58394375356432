import React from "react";
import ProjectDetail from "./ProjectDetail";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import ClientDetails from "./ClientDetails";
import DarkBG from "../../../../../Assets/upgrade-images/client_active_brief_chat/Vector.svg";
import DummyProfile from "../../../../../Assets/upgrade-images/client_active_brief_chat/dummy.png";
import EmptyNoti from "../../../../../Assets/upgrade-images/submit_proposal/empty-notification.svg";
import NotificationCenter from "./NotificationCenter";
import { Link } from "react-router-dom";


const DetailSideBar = (props) => {
  const data = props?.isLeftClient
    ? props?.clientLeftStates?.data
    : props?.projectDataStates?.data;
  return (
    <div
      className={`col-lg-3 d-none d-lg-block ${
        props.showProjDetail == "no" ? "detail-sidebar" : ""
      }`}
    >
      <div className="">
        {props?.showProjDetail ? (
          props?.showProjDetail === "yes" ? (
            <ProjectDetail
              data={data}
              id={props?.id}
              isClient={true}
              {...props}
            />
          ) : (
            <ClientDetails {...props} />
          )
        ) : (
          ""
        )}

        {/* ===================== notification center ========================= */}

        {/* {props?.match?.params?.subroute === "overview" ? (
          <div className="page_card pb-2 d-none d-lg-block">
            <div className="proposal_header br_btm d-flex justify-content-between">
              <div className="prposal_heading d-flex align-items-center">
                <p className="mb-0">Notification Center</p>
               --------- already comment start-------

                <div className='tag_btn_tab_active ml-3'>
              <p className='mb-0'>0</p>
            </div>
              --------- already comment end-------

              </div>
            </div>
            <div className="message_outer body_scroll_bar mx-4">
              <div className="row mx-0">
                <div className="d-flex align-items-center mx-auto py-5 px-0 flex-column">
                  <img src={EmptyNoti} />
                  <p className="text_light_gray_xs_w_400 font-14">
                    {" "}
                    No Notifications
                  </p>
                </div>
              </div>
              --------- already comment start-------
              <div className='row mx-0'>
            <div className='col-lg-12 d-flex align-items-center py-3 px-0'>
              <div className='notify profile_image_50'>
                <img src={User2} className="img-fluid rounded-circle" />
                <div className='active_blue_sm_circle active_cricle'>
                </div>
              </div>
              <div className='massage_box ml-4'>
                <p className='text-capitalize mb-0 text_gray'>new message from <span className='font_semi_bold'>Sunnet</span></p>
                <p className='mb-0 text_light_gray_xs'>14 jan, 12:00</p>
              </div>

            </div>
          </div> 
              <div className='row mx-0'>
            <div className='col-lg-12 d-flex br_tp  align-items-center py-3 px-0'>
              <div className='notify profile_image_50'>
                <img src={User2} className="img-fluid rounded-circle" />
              </div>
              <div className='massage_box ml-4'>
                <p className='text-capitalize mb-0 text_gray'><span className='font_semi_bold'>3 new </span> proposal added</p>
                <p className='mb-0 text_light_gray_xs'>14 jan, 12:00</p>
              </div>
            </div>
          </div> 
              <div className='row mx-0'>
            <div className='col-lg-12 d-flex br_tp  align-items-center py-3 px-0'>
              <div className='notify profile_image_50'>
                <img src={DummyProfile} className="img-fluid rounded-circle" />
              </div>

              <div className='massage_box ml-4'>
                <p className='text-capitalize mb-0 text_gray'> <span className='font_semi_bold'>completed</span> your profile</p>
                <p className='mb-0 text_light_gray_xs'>14 jan, 12:00</p>
              </div>
            </div>
          </div> 

--------- already comment end-------
            </div>
          </div>
        ) : null} */}
        {/* ===================== notification center end ========================= */}

        <div
          className="dark_page_card d-none d-lg-block"
          style={{
            backgroundImage: `url(${DarkBG})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            backgroundSize: "auto 75%",
          }}
        >
          <div className="row py-4 px-1 mx-0">
            <div className="col-4 col-xl-3 col-md-5">
              <div className="rounded-circle detail_sidebar_profile_image mx-auto">
                <img
                  src={
                    data?.project_data?.manager_image
                      ? BaseImageUrl + data?.project_data?.manager_image
                      : DummyProfile
                  }
                  className=" rounded-circle"
                />
              </div>
            </div>
            <div className="col-xl-9 col-sm-7  d-flex flex-column align-items-start">
              <p className="text_light_gray_xs_w_400 mb-1">Account Manager</p>
              <p className="font_lg_white mb-2">{data?.project_data?.manager_name ? data.project_data.manager_name : "AM assign shortly"}</p>
             {props.authIs ? <Link to={`/projects/${data?.project_data?._id}/project_room/${data?.project_data?.manager_username}`}><div className="tag_btn_primary text_highlight_xs">Message</div></Link> : null }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSideBar;
