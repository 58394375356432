import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { getAPI, postAPI, putAPI } from '../../../../utils/API';
// import { getDateTime } from '../../../../utils/DateMethod';
import { sanitiseSummernoteText, separateNumber } from '../../../../utils/Helperfunctions';
import MobileSubmitMilestone from './MobileSubmitMilestone';
import CustomSummernote from '../CustomSummernote';
import NumberFormat from 'react-number-format';
import { fireApi } from '../../../../utils/fireApiPrecheck';
import { getFileName, renderDifferentFiles } from '../../Common/Common/FileRenderMessage';
import BootstrapLoader from '../../Common/BootstrapLoader/BootstrapLoader';
import MultipleCardLoader from '../../Common/MiltipleCardLoader.js/MultipleCardLoader';
import MobileSubmitMilestoneJob from './MobileSubmitMilestoneJob';
import Select from 'react-select';
import { ChatImageBaseUrl } from "../../../../utils/BaseUrl"
import LinkImage from '../../../../Assets/images/link-file.jpg';
import PdfImage from "../../../../Assets/images/big-pdf.jpg";
import DocImage from "../../../../Assets/images/big-doc.jpg";
import ZipImage from "../../../../Assets/images/big-zip.jpg";
import PPtImage from "../../../../Assets/images/big-ppt.jpg";
import { handleFileType } from '../../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';

class ReviewMilestoneJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ItemMilestone: [
        {
          price: "",
          type: "token",
          isOpen: true,
        },
        {
          name: "",
          price: "",
          due_date: "",
          iteration: 1,
          type: "milestone",
          nameError: false,
          priceError: false,
          dateError: false,
          isOpen: true,
        }],
      changedData: 0,
      subTotal: 0,
      tax: 0,
      totalCost: 0,
      totalEarning: 0,

      advancePrice: '',
      advanceDate: '',
      notes: '',
      price: [],
      taxes: 0,
      AmountPayable: 0,
      tempPrice: 0,
      advancePriceError: '',
      hideVersionHistoryButton: true,
      submitting: false,
      files: [],
      filesCount: 0,
      filesUploading: false,
      check: false,
      selectedType : {},
      images_links : [],
      urlData : "",
      imagesLinksFlag: false,
      ItemMilestoneCopy: []
    }
    this.hiddenFileInput = React.createRef();
  }

  loadAllProposal = (proposal) => {
    if (proposal) {
      let api_path = '/milestone/proposals-creator?post_project_id=' + proposal.post_project_id + '&user_id=' + proposal.user_id
      getAPI(api_path).then((res) => {

        if (res.data.status === "1") {
          this.setState({
            hideVersionHistoryButton: res.data.data.length > 1 ? false : true
          })
        }
      }).catch((error) => {
        console.log(error)
        this.setState({ isLoading: false })
      });
    }
  }

  componentDidMount = async () => {
    if (this.props?.paths) {
      this.props.loadProjectStatus(this.props?.paths)
      this.loadLatestProposals(this.props?.paths);
    }
  }

  loadLatestProposals = (id) => {
    getAPI(`/postProject/get-jobs-proposal?id=${id}`).then((res) => {
      // console.log("res ========== ",res.data)
      this.loadAllProposal(res.data.proposal)
      this.handleSetState(res)
    }).catch((error) => {
      console.log(error)
    });
  }

  handleSetState = (res) => {

    let active_milestones;
    if (this.props.ProjectStatus === 5) {
      active_milestones = res.data.milestone.find((element) => { return element.status === 0 });
    } else {
      active_milestones = res.data.milestone.find((element) => { return element.status === 1 });
    }
    let milestone_id = active_milestones?._id;

    let milestones;
    if (res.data.proposal.update_status === 0) {
      milestones = res.data.milestone.map((obj) => {
        if (obj.type !== 'token' && obj.update_status === 0) {
          obj.price = obj.price_new
          obj.name = obj.name_new
          obj.due_date = obj.due_date_new
          obj.iteration = obj.iteration_new
          return obj
        } else {
          return obj
        }
      })
    } else {
      milestones = res.data.milestone
    }

    const mile = JSON.stringify(milestones)
    const mileData = JSON.parse(mile) 

    this.setState({
      ItemMilestone: milestones,
      ItemMilestoneCopy: mileData,
      proposal_status: res.data.proposal.status,
      proposal_id: res.data.proposal._id,
      projectName: res.data.project_name,
      active_milestones: milestone_id,
      images_links: res.data.proposal?.link_image
    })

    // update_status === 1 means client did not approve
    if (res.data.proposal.update_status === 1) {
      const { files, note } = res.data.proposal;
      this.setState({
        subTotal: res.data.proposal.sub_total_cost,
        tax: res.data.proposal.tax,
        totalCost: res.data.proposal.total_cost,
        totalEarning: res.data.proposal.total_earning,
        files: files ? files : [],
        notes: note
      })
    } else {
      const { files_new, note_new } = res.data.proposal;

      this.setState({

        subTotal: res.data.proposal.sub_total_cost_new,
        tax: res.data.proposal.tax_new,
        totalCost: res.data.proposal.total_cost_new,
        totalEarning: res.data.proposal.total_earning_new,
        files: files_new ? files_new : [],
        notes: note_new
      })
    }
    // console.log(res.data)
  }

  chargesAndTaxes = () => {
    const subTotal = parseInt(this.state.subTotal);
    // const subTotal = parseInt((10 / 100) * subTotal);
    const taxes = parseInt(0.18 * subTotal);
    const AmountPayable = parseInt(subTotal + taxes);
    const totalEarning = subTotal * 0.85
    this.setState({
      taxes,
      AmountPayable,
      totalEarning,
    })
  }

  handleDateOnChange = (date, k) => {

    let ItemMilestone = [...this.state.ItemMilestone];

    ItemMilestone[k]['due_date'] = date.toDateString();
    ItemMilestone[k][`dateError`] = false
    ItemMilestone[k]['isChanged'] = true

    this.setState({ ItemMilestone: ItemMilestone });
  }

  handleOnChange = (event, k) => {
    let ItemMilestone = [...this.state.ItemMilestone]
    let { value, name } = event.target
    ItemMilestone[k][name] = value
    if (name === "iteration") {
      ItemMilestone[k][name] = parseInt(value)
    }

    if (name === 'name') {
      ItemMilestone[k][`milestoneNameError`] = ""
    }

    ItemMilestone[k][`${name}Error`] = "";
    ItemMilestone[k]['isChanged'] = true;
    this.setState({ ItemMilestone: ItemMilestone })
    if (name === "price") {
      this.handleCalculation()
    }
  }

  handleCalculation = () => {
    let total = 0
    let tempPrice = 0
    this.state.ItemMilestone.forEach((obj) => {
      if (obj.price !== '') {
        total = total + parseInt(obj.price);
        tempPrice = tempPrice + parseInt(obj.price);
      }
    })
    if (this.state.advancePrice !== '') {
      total = parseInt(total) + parseInt(this.state.advancePrice)
    }
    this.setState({ tempPrice: tempPrice })
    this.setState({
      subTotal: total
    }, (e) => { this.chargesAndTaxes() });
  }

  onBlur = (event, k) => {
    let ItemMilestone = [...this.state.ItemMilestone]
    let { value, name } = event.target
    ItemMilestone[k][name] = value
    if (Number(event.target.value) < event.target.min || Number(event.target.value) > event.target.max) {
      ItemMilestone[k][name] = event.target.min
      this.setState({ ItemMilestone: ItemMilestone })
    }
  }

  handleChange = (event) => {
    let { value, name } = event.target

    this.setState({ [name]: value, [`${name}Error`]: "" })

    if (name === "advancePrice" && value !== '') {
      if (this.state.tempPrice !== '') {
        let total = parseInt(this.state.tempPrice) + parseInt(value)
        this.setState({ subTotal: total }, (e) => { this.chargesAndTaxes() })
      }
    }
    else if (name === "advancePrice" && value === '') {
      this.setState({
        subTotal: parseInt(this.state.tempPrice)
      }, (e) => { this.chargesAndTaxes() })
    }
  }

  newHandleOnChange = (event, index) => {
    const { name, value } = event.target;
    const { ItemMilestone } = this.state;
    ItemMilestone[index][name] = value.substring(0, 100)
    ItemMilestone[index][`${name}Error`] = false
    ItemMilestone[index].isChanged = true
    this.newHandleCalculation(ItemMilestone)
    this.setState({ ItemMilestone })
  }

  newHandleOnChangeWithSeprator = (event, index, initialPrice) => {
    const { value, floatValue } = event;
    const { ItemMilestone } = this.state;

    if (floatValue !== initialPrice) {
      ItemMilestone[index]['price'] = value.substring(0, 100)
      ItemMilestone[index][`${'price'}Error`] = false
      ItemMilestone[index].isChanged = true
      this.newHandleCalculation(ItemMilestone)
      this.setState({ ItemMilestone })
    }
  }
  newHandleCalculation = (milestones) => {
    const subTotal = milestones.reduce((acc, obj) => acc + (obj.price === "" ? 0 : parseInt(obj.price)), 0);
    const tax = subTotal * 0.18;
    const totalCost = subTotal + tax
    const totalEarning = subTotal * 0.85
    this.setState({ subTotal, tax, totalCost, totalEarning })
  }

  AddMilestone = () => {
    let ItemMilestone = [...this.state.ItemMilestone, {
      name: '',
      due_date: '',
      price: '',
      type: "milestone",
      iteration: 1,
      nameError: false,
      dateError: false,
      priceError: false,
      isOpen: true,
    }]
    this.setState({ ItemMilestone })
  }

  removeMilestone = (event, k) => {
    let ItemMilestone = [...this.state.ItemMilestone]
    ItemMilestone.splice(k, 1);
    this.setState({
      ItemMilestone,
    }, () => this.newHandleCalculation(ItemMilestone))
  }

  IncrementItem = (event, k) => {
    let ItemMilestone = [...this.state.ItemMilestone]
    if (ItemMilestone[k]['iteration'] < 100) {
      ItemMilestone[k].iteration = parseInt(ItemMilestone[k].iteration) + 1;
      ItemMilestone[k].isChanged = true
      this.setState({ ItemMilestone })
    }
  }

  DecreaseItem = (event, k) => {
    let ItemMilestone = [...this.state.ItemMilestone]
    if (ItemMilestone[k].iteration > 1) {
      ItemMilestone[k].iteration = parseInt(ItemMilestone[k].iteration) - 1;
      ItemMilestone[k].isChanged = true
      this.setState({ ItemMilestone })
    }
  }

  newHandleValidation = () => {
    const { ItemMilestone } = this.state;
    let isFormValid = true;
    const updatedItemMilestone = ItemMilestone.map((obj, i) => {
      if (obj.type === 'token') {
        if (obj.price === "") {
          ItemMilestone[i].priceError = true;
          isFormValid = false
        }
        return obj;
      } else {
        if (!obj.name.trim().length) {
          ItemMilestone[i].nameError = true;
          isFormValid = false;
        }
        if (obj.price === "") {
          ItemMilestone[i].priceError = true;
          isFormValid = false;
        }
        if (!obj.due_date.length) {
          ItemMilestone[i].dateError = true;
          isFormValid = false;
        }
        return obj
      }
    })

    this.setState({ ItemMilestone: updatedItemMilestone })
    return isFormValid
  }

  handleValidation() {
    let formError = []
    let formIsnotValid = false
    let ItemMilestone = [...this.state.ItemMilestone]
    if (ItemMilestone.length === 0) {
      alert("Please enter atleast one milestone")
      return;
    }
    ItemMilestone.forEach((e, i) => {
      if ((e.due_date === "" || e.due_date === undefined) && e.type !== "token") {
        e.dateError = "Please enter a date"
        formError.push(true)
      }
      if (e.name === "" && e.type !== "token") {
        e.milestoneNameError = "Please enter a name"
        formError.push(true)
      }
      if (e.price === "" || e.price === "0") {
        e.priceError = "Please enter Price"
        formError.push(true)
      }
      if (e.iteration === "" || isNaN(e.iteration)) {
        e.iterationError = "Please select one iteration"
        formError.push(true)
      }
    })
    if (this.state.advancePrice === '') {
      this.setState({ advancePriceError: "Please enter price" })
      formError.push(true)
    }
    this.setState({ ItemMilestone })
    formIsnotValid = formError.includes(true)
    return formIsnotValid
  }

  removeErrorKeys = (milestones) => {
    return milestones.map((obj) => {
      delete obj.nameError
      delete obj.priceError
      delete obj.dateError
      return obj
    })
  }
    
  handleRealSubmit = () => {
      const { advancePrice, ItemMilestone, } = this.state;
      let milestoneData = [];
      milestoneData = [{
        name: "",
        price: advancePrice,
        due_date: "",
        iteration: 0,
        type: "token"
      }];
      ItemMilestone.forEach((data, key) => {
        if (!data.type) {
          milestoneData.push({
            name: data.name,
            price: data.price,
            due_date: data.due_date,
            iteration: data.iteration,
            type: "milestone",
          })
        } else if (data.type !== 'token') {
          milestoneData.push({
            name: data.name,
            price: data.price,
            due_date: data.due_date,
            iteration: data.iteration,
            type: "milestone",
          })
        }
      })
      this.handleSubmit();
  }
    
  handleSubmit = () => {
    const { totalCost, totalEarning, tax, subTotal, notes, ItemMilestone,
    proposal_status, files,images_links } = this.state;
    const isFormValid = this.newHandleValidation()
    if (isFormValid) {
      if (window.confirm('Are you sure you want to proceed?')) {
        this.setState({ submitting: true })
          let submitProposalData = {
            post_project_id: this.props?.paths,
            tax: tax,
            sub_total_cost: subTotal,
            total_cost: totalCost,
            total_earning: totalEarning,
            note: sanitiseSummernoteText(notes),
            milestone: this.removeErrorKeys(ItemMilestone),
            files,
            link_image:images_links
          }
          postAPI('/milestone/add-non-collateral-proposals', submitProposalData).then((res) => {
            if (res.data.status == "1") {
              this.setState({ submitting: false });
              alert("submitted successfully");
              this.props.closePopup();
              this.props.loadJobData()
              if (res.data.hasProject) {
                 this.props.history.push(`/dashboard/jobs`);
              } else {
                //  this.props.history.push(`/uploadproject/choose_collateral`);
                this.props.OpenPopup('CollateralJob')
              }
            }
          }).catch((error) => {
            alert('Something went wrong, Please try again later.');
            this.setState({ submitting: false });
            console.log("user-company api error------->", error)
          })
      }
    }
    else {
      alert("Please fill all the required fields")
    }
  }

  closeInputBoxMobile = (index) => {
    const { ItemMilestone } = this.state;

    let isFormValid = true;
    if (ItemMilestone[index].type === 'token') {
      if (!ItemMilestone[index].price || ItemMilestone[index].price === "" || ItemMilestone[index].price === "0") {
        ItemMilestone[index].priceError = true;
        isFormValid = false
      }
    } else {
      if (!ItemMilestone[index].name.trim().length) {
        ItemMilestone[index].nameError = true;
        isFormValid = false;
      }
      if (!ItemMilestone[index].price || ItemMilestone[index].price === "" || ItemMilestone[index].price === "0") {
        ItemMilestone[index].priceError = true;
        isFormValid = false;
      }
      if (!ItemMilestone[index].due_date.length) {
        ItemMilestone[index].dateError = true;
        isFormValid = false;
      }
    }

    if (isFormValid) {
      ItemMilestone[index].isOpen = false
    }

    this.setState({ ItemMilestone: ItemMilestone })

  }

  openInputBoxMobile = (index) => {
    const { ItemMilestone } = this.state
    ItemMilestone[index].isOpen = true
    this.setState({ ItemMilestone: ItemMilestone })
  }

  viewPreviousVersions = () => {
    const { proposal, SetCurrentProposal, OpenPopup } = this.props;
    SetCurrentProposal(proposal._id, proposal.post_project_id);
    OpenPopup('ProposalVersionModal')
  }

  summernoteHandler = (value) => {
    this.setState({ notes: value, [`notesError`]: "" })
  }

  handleImageOnChange = (e) => {
    const { files } = this.state;
    let filesNew = e.target.files;
    if (!filesNew.length) return
    let totalFiles = filesNew.length + files.length;
    let someFilesExceedSizeLimits = false
    for (var i = 0; i < filesNew.length; i++) {
      if (filesNew[i].size > 5042880) {
        someFilesExceedSizeLimits = true
      }
    }

    if (totalFiles > 10) {
      alert('More than 10 files are not allowed');
    } else if (someFilesExceedSizeLimits) {
      alert('A file size cannot be more than 5MB.')
    } else {
      this.setState({ filesUploading: true, filesCount: files.length })
      const formData = new FormData();
      for (var p = 0; p < filesNew.length; p++) {
        formData.append('chatFiles', filesNew[p]);
      }
      fireApi('UploadChatFiles', formData)
        .then((r) => {
          if (r.status && r.status === "1") {
            let fil = [];
            r.chat_files.forEach((ff) => {
              fil.push({ name: ff.name });
            })
            this.setState({ files: [...this.state.files, ...fil], filesUploading: false, filesCount: 0 });
          }
        });
    }
  }

  handleAppend = (status, value) => {
    const { images_links, urlData } = this.state;
    var data = [...images_links]
    if(status === "Add files"){
      if(data?.length <= 9){
        data.push({name : value, type : "files" })
        this.setState({ images_links : data })
      }
    }else{
      // const validUrl = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(urlData)
      const validUrl = (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(urlData)
      if(validUrl){
        const currentUrl = urlData.split("/").slice(0)[0];
        let currentData = null
        if (currentUrl === "http:" || currentUrl === "https:" || currentUrl === "www:") {
          currentData = { name : urlData, type : "url" }
        } else {
          currentData = { name : "http://"+urlData, type : "url" }
        }        
        data.push(currentData)
        this.setState({ images_links : data, urlData : "", imagesLinksFlag : false })
      }else{
        alert("Invalid url")
      }
    }
  }

  handleImageChange = (selectedType) => {
    this.setState({selectedType})
  }

  setImageRes = (status, value) => {
    const formData = new FormData();
    let validSize = false
    let invalid_images = []; 
    let otherFormat = [];
    let valid_images = []   
    const allValidTypes = [ 'jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'ppt' ]
    
    for (var i = 0; i < value?.length; i++) {
        if(allValidTypes.includes(value[i]?.name?.split(".")[1])){
          if(value[i]?.size > 10 * 1024 *1024) {
            invalid_images.push(value[i]);
          } else {
            valid_images.push(value[i])
            validSize = true
            formData.append('chatFiles', value[i]);            
          }
        }else{
          otherFormat.push(value[i])
        }           
    }
    if(otherFormat.length > 0){
      let alertMsg = (otherFormat.length > 1) ? otherFormat?.[0].name + "and " + (otherFormat.length-1) + " other files are not uploaded, Please upload files of valid formats." : otherFormat?.[0].name + " is not uploaded, Please upload file of valid format";
      alert(alertMsg);
      this.setState({ filesUploading : false })
      return
    }
    if(invalid_images.length > 0) {
      let alertMsg = (invalid_images.length > 1) ? invalid_images?.[0].name + "and " + (invalid_images.length-1) + " other files are not uploaded, Please upload files of size less than 10 Mb." : invalid_images?.[0].name + " is not uploaded, Please upload file of size less than 10 Mb";
      alert(alertMsg);
      this.setState({ filesUploading : false })
    }
    if(valid_images?.length > 0){
      fireApi('UploadChatFiles', formData)
      .then((res) => {      
        this.setState({filesUploading: false})
        const valueIs = res.chat_files
        let allImages = [];
        if(valueIs?.length > 0){
          const data = valueIs?.forEach((obj) => {
            allImages.push({ name: obj?.name, type : "files" })
            this.handleAppend("Add files", obj?.name)
          })
        }else{
          this.handleAppend("Add files", [])
        }        
      }).catch(err=>{
        this.setState({filesUploading: false})
      })
    }else{
      this.setState({ filesUploading : false })
    }
  }

  handleOnImageLinks = (e, status) => {   
    if(status === "files"){      
      const valueIs = e.target.files
        if(valueIs?.length > 0){
          this.setState({ filesUploading : true })
          this.setImageRes(status , valueIs)
        }else{
          alert("Please choose file ..")
        }         
    }else{
      const valueIs = e.target.value
      this.setState({urlData: valueIs, imagesLinksFlag : true, filesUploading : false })
    }

  }

  handleRemove = (index) => {
    const { images_links } = this.state;
    const data = images_links.filter((_, i) => i !== index)
    this.setState({ images_links : data })
  }

  removeFile = (idx) => {
    let files = this.state.files;
    if (files.length < 1) return '';
    files.splice(idx, 1);
    this.setState({ files });
  }

  render() {
    const { ItemMilestone, notes, subTotal, totalCost, tax, totalEarning, hideVersionHistoryButton, submitting,
      files, filesUploading, filesCount, projectName,selectedType, images_links, imagesLinksFlag, urlData } = this.state
    const allFunc = {
      newHandleOnChange: this.newHandleOnChange,
      handleDateOnChange: this.handleDateOnChange,
      closeInputBoxMobile: this.closeInputBoxMobile,
      removeMilestone: this.removeMilestone,
      openInputBoxMobile: this.openInputBoxMobile,
      AddMilestone: this.AddMilestone,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      handleOnImageLinks : this.handleOnImageLinks,
      handleImageChange : this.handleImageChange,
      handleAppend : this.handleAppend,
      handleRemove : this.handleRemove
    }
    const LoadingCard = (
      <span className="upload-files mob-w-100 mob-ml-0 mx-1 py-2 pl-2 pr-3 mt-2 border-1 border-radius-8">
        <div className="d-flex align-items-center">
          <BootstrapLoader
            smallSize
            height={'0vh'}
          />
        </div>
      </span>
    )

    const options = [
      {  label: 'Select' },
      { value: 'Add files', label: 'Add files' },
      { value: 'Add url', label: 'Add url' }
    ]

    let imgData = [...images_links]
    let imgFile = imgData.filter(item => item?.type === "file").pop()

    return (
      <>
        <div className="modal fade custom_form review_milestone_modal show" style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon recommendation_modal_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => {
              this.props.closePopup()
              this.props.history.push(`/dashboard/jobs`);
            }
            } alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator dflex p-4 mob-p-15">
                      <div>
                        <div className="d-flex w-100">
                          <div className="pt-15">
                            <i className="far fa-calendar-alt font-20 text-white"></i>
                          </div>
                          <div className="pl-3">
                            <span className="text-white font-13 mob-font-12 font-weight-500">{projectName}</span>
                            <h2 className="font-weight-500 m-0 font-18 mob-font-16 mob-only-text-left line-height-14">Project Proposal</h2>
                          </div>
                        </div>
                      </div>
                      {/* {!hideVersionHistoryButton ? <button onClick={this.viewPreviousVersions} type="button" className="mobile-hide main-btn bg-light-gray font-12 mob-w-100 cursor-pointer "> View previous version</button> : null}
                      <div className="mobile-hide hidden">
                        <button type="button" className="main-btn font-12 mob-w-100 cursor-pointer">Approve and pay</button>
                        <button type="button" className="main-btn bg-light-gray font-12 mob-w-100 cursor-pointer hidden"> View previous version</button>
                      </div> */}
                    </div>
                  </div>
                  {/* <!-- proposal version history --> */}
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 p-0 dont-like overflow-content chat-modal-height mobile-hide">
                    {/* <!-- Creator Section --> */}
                    <div className="card-wrapper mb-0 mobile-hide">
                      <div className="custom_form p-3">
                        <div className="custom_table">
                          <div className="row-border pb-3">
                            <div className="row m-0">
                              <div className="col-sm-1 text-center">
                                <span className="text-sub font-weight-500">#</span>
                              </div>
                              <div className="col-sm-5">
                                <span className="text-sub font-weight-500">Milestone</span>
                              </div>
                              <div className="col-sm-2">
                                <span className="text-sub font-weight-500">Due Date</span>
                              </div>
                              <div className="col-sm-2">
                                <span className="text-sub font-weight-500">Pricing</span>
                              </div>
                              <div className="col-sm-2">
                                <span className="text-sub font-weight-500">No. of Iterations</span>
                              </div>
                            </div>
                          </div>



                          {ItemMilestone.map((data, k) => {
                            // {/* make minDate 1 more than prev milestone date */}
                            let previousMilestoneDate = k > 0 && ItemMilestone[k - 1].due_date
                            let minDate = k > 1
                              ? previousMilestoneDate
                                ? new Date(previousMilestoneDate)
                                : new Date()
                              : new Date()
                            minDate.setDate(minDate.getDate() + 1);


                            // {/* make maxDate 1 less than next milestone date */}
                            let maxDate = (k < ItemMilestone.length - 1 && data.type !== 'token') ? new Date(ItemMilestone[k + 1].due_date) : null
                            if (maxDate) maxDate.setDate(maxDate.getDate() - 1)

                            const lastMilestone = ItemMilestone[ItemMilestone.length - 1]
                            const secondLastMilestone = ItemMilestone[ItemMilestone.length - 2]
                            const isLastAndActiveMilestone = (!lastMilestone?.status || lastMilestone?.status === 1) && secondLastMilestone?.status === 2


                            if (data.type === 'token') {
                              return (
                                <div key={k} className={`${'row-border'} py-3 creator-proposal a-test`}>
                                  <div className={`row m-0 ${data.type === 'token' && data.status === 2 ? 'disabled' : ''}`}>
                                    <div className="col-sm-1 text-center">
                                      <span><i className="fas fa-info advance_info"></i></span>
                                    </div>
                                    <div className="col-sm-5">
                                      <div>
                                        <h3 className="text-dark-black mb-0 font-14 font-weight-600">Project - Advance Fees</h3>
                                        <p className="mb-0 text-third">
                                          <span className="font-10 font-weight-600">Recommendation: 10-20% of total project value</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                      <div className="custom_form">
                                        <input type="text" className="form-control email input-chat-field" disabled id="exampleInputImmediately" placeholder="Immediately" />
                                      </div>
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                      <div className="custom_form dflex position-relative">
                                        <i className="fas fa-rupee-sign reviewMilestone_rupee"></i>
                                          <NumberFormat onValueChange={(e) => this.newHandleOnChangeWithSeprator(e, k, data.price)}
                                            className={`form-control w-100 email input-chat-field pl-4 ${data.priceError ? 'border-error' : ''}`}
                                            id="exampleInputPrice"
                                            placeholder="Price"
                                            thousandSeparator={true}
                                            thousandsGroupStyle="lakh"
                                            value={data.price}
                                            disabled={data.type === 'token' && data.status === 2}
                                            allowNegative={false}
                                          />
                                      </div>
                                      {data.priceError && <span className="text-danger font-weight-600 font-12 py-1">Please enter price </span>}
                                    </div>
                                    <div className="col-sm-2 position-relative iteration_div">
                                      <div className="input-group active">
                                        <div className="input-group-prepend" disabled >
                                          <span className="input-group-text input-text-left font-12">
                                            <i className="fas fa-minus"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="number" name='iteration' min="1" max="100"
                                          disabled
                                          className={`form-control email pl-2 text-center`}
                                        />
                                        <div className="input-group-append" disabled >
                                          <span className="input-group-text input-text-right font-12">
                                            <i className="fas fa-plus"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            } else {
                              const isDisabled = false
                              return (
                                <div key={k} className={`${k === ItemMilestone.length - 1 ? '' : 'row-border'} py-3 creator-proposal`} >
                                  <div className={`row m-0 align-items-center ${(data.status === 2 || isDisabled) ? 'disabled' : ''}`}>
                                    <div className="col-sm-1 text-center">
                                      <span className="text-sub-7 font-weight-500">{k + 1}</span>
                                    </div>
                                    <div className="col-sm-5">
                                      <div className="custom_form">
                                        <input type="text" name="name" value={data.name} onChange={(e) => this.newHandleOnChange(e, k)} className={`form-control email input-chat-field ${data.nameError ? 'border-error' : ''}`} id="exampleInputEmailk" placeholder="Milestone Name Eg: Rough Sketches" disabled={(data.status === 2 || isDisabled) ? true : false} />
                                        {data.nameError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a name</span>}
                                      </div>
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                      <div className="custom_form position-relative">
                                        <DatePicker
                                          onChange={
                                            (e) => { this.handleDateOnChange(e, k) }
                                          }
                                          value={(data.due_date) ? new Date(data.due_date) : ''}
                                          className={`form-control email ${data.dateError ? 'border-error' : ''}`}
                                          clearIcon={null}
                                          format="dd-MM-yyyy"
                                          calendarClassName="apniClass"
                                          showLeadingZeros={true}
                                          disabled={data.status === 2 || isDisabled ? true : false}
                                          // minDate={k > 1 ? new Date(ItemMilestone[k-1].due_date) : new Date()}
                                          defaultActiveStartDate={minDate}
                                          minDate={minDate}
                                          maxDate={maxDate}
                                        />
                                       {data.dateError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a date</span>}
                                      </div>
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                      <div className="custom_form position-relative">
                                        <i className="fas fa-rupee-sign reviewMilestone_rupee"></i>
                                        {/* <input type="number" name="price" value={data.price} onChange={(e) => this.newHandleOnChange(e, k)} className={`form-control w-100 email input-chat-field pl-4 ${data.priceError ? 'border-error' : ''}`} id="exampleInputEmail1" placeholder="Price" disabled={(data.status === 2 ||  isDisabled ) ? true : false}/> */}
                                        <NumberFormat onValueChange={(e) => this.newHandleOnChangeWithSeprator(e, k, data.price)}
                                          className={`form-control w-100 email input-chat-field pl-4 ${data.priceError ? 'border-error' : ''}`}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          value={data.price}
                                          allowNegative={false}
                                        />
                                        {data.priceError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a price</span>}
                                      </div>
                                    </div>

                                    <div className="col-sm-2 position-relative iteration_div">
                                      <div className={`input-group ${data.status === 2 ? "active" : ""}`}>
                                        <div className="input-group-prepend" onClick={(e) => this.DecreaseItem(e, k)} disabled={data.status === 2 || isDisabled ? true : false}>
                                          <span className="input-group-text input-text-left font-12">
                                            <i className="fas fa-minus"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="number" name='iteration' min="1" max="100"
                                          placeholder="3"
                                          disabled={(data.status === 2 || isDisabled) ? true : false}
                                          className={`form-control email pl-2 text-center ${data.iterationError ? 'border-error' : ''}`}
                                          value={data.iteration}
                                          onChange={(e) => this.newHandleOnChange(e, k)} onBlur={(e) => this.onBlur(e, k)}
                                        />
                                        <div className="input-group-append" onClick={(e) => this.IncrementItem(e, k)} disabled={(data.status === 2 || isDisabled) ? true : false}>
                                          <span className="input-group-text input-text-right font-12">
                                            <i className="fas fa-plus"></i>
                                          </span>
                                        </div>
                                        <span className={`remove-icon ${(isDisabled || data.status === 2 || k === 1 || isLastAndActiveMilestone || data.submitted_iteration > 0) ? 'hidden' : ''}`} onClick={(e) => this.removeMilestone(e, k)}><i className="fas fa-times font-16 text-danger"></i></span>
                                        {data.iterationError && <span className="text-danger font-weight-600 font-12 py-1">{data.iterationError}</span>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }

                          })}

                          <div className="w-100 dflex add_milestone">
                            <a href="/" className="font-13 mob-font-12 font-weight-bold letter-spacing-02 text-center" onClick={e => { e.preventDefault(); this.AddMilestone(); }}><i className="fas fa-plus mr-1"></i> Add milestone</a>
                          </div>
                          <div className="row-border row m-0 py-3">
                            <div className="col-sm-8 offset-sm-4">
                              <div className="card-wrapper mb-0">
                                <div className="d-flex align-items-center">
                                  <div className="w-60 text-right">
                                    <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                      Sub Total
                                    </h2>
                                  </div>
                                  <div className="w-40 pl-5">
                                    <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(subTotal)}</h2>
                                  </div>
                                </div>
                              </div>
                              <div className="card-wrapper mb-0">
                                <div className="d-flex align-items-center">
                                  <div className="w-60 text-right">
                                    <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                      G.S.T @ 18%
                                    </h2>
                                  </div>
                                  <div className="w-40 pl-5">
                                    <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(tax)}</h2>
                                  </div>
                                </div>
                              </div>
                              <div className="card-wrapper mb-0">
                                <div className="d-flex align-items-center">
                                  <div className="w-60 text-right">
                                    <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                      Total Project Cost for Client
                                    </h2>
                                  </div>
                                  <div className="w-40 pl-5">
                                    <h2 className="font-16 font-weight-700 text-dark-black"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(totalCost)}</h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 add_image_url_wrapper">

                             {/* Add image or url */}
                            <div className="col-sm-12 pt-4">
                              <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Add Project References to Your Proposal</span>
                            </div>
                            {(images_links?.length < 10) && 
                            <div className="col-sm-12 mb-4"> 
                              <div className='row'>
                                <div className='col-12 col-sm-4 mb-4'>
                                  <div className="w-100">
                                    <Select options={options} onChange={this.handleImageChange}/>
                                  </div>
                                </div> 
                                {
                                  images_links?.length <=9  &&  selectedType?.label === "Add url" ?
                                  <div className="col-12 col-sm-6">
                                      <input className='form-control' value={urlData} type='text' placeholder="Enter url" 
                                        onChange={(e)=>this.handleOnImageLinks(e, "url")}
                                      />
                                  </div>
                                  : ""
                                }
                                {(images_links?.length <= 9 && imagesLinksFlag) ?
                                  <div className="col-12 col-sm-2">
                                    <button type="button"
                                      className="main-btn font-14 mob-w-100 cursor-pointer w-100"
                                      onClick={ () =>  this.handleAppend("Add url")}
                                      >
                                      Save
                                    </button>
                                  </div> : "" } 
                              </div>
                                <div className='row'>
                                  { (images_links?.length <= 9 && selectedType?.label === "Add files") ?
                                      <div className="col-12">                                    
                                        <div className="form-group mb-3 col-sm-12 pl-0 mob-p-0 ipad-pr-0">
                                          <label className="label font-weight-600 font-14 mob-font-13 w-100 pt-3 pb-2" htmlFor="first">Upload your files</label>
                                          <div className="upload-btn-wrapper w-100" >
                                            <button className="btn upload-file w-100 font-13 font-weight-500 text-secondary">
                                              <span className="font-18 mob-font-13 d-block text-success"> 
                                                {
                                                  (imgFile && imgFile?.name.split(".")[0]) && !filesUploading ? 
                                                  <span className="complete-check text-center d-block mb-1">
                                                    <i className="fas fa-check bg-success text-white"></i>
                                                  </span> : 
                                                  filesUploading ? 
                                                  <div className="btn-loader-wrapper">
                                                    <div className="btn-loader resume_upload"></div>
                                                  </div> : 
                                                  <img src={require("../../../../Assets/images/upload-icon.png")} 
                                                  className="d-block m-auto"width="60" alt="Indiefolio"
                                                  />
                                                } 
                                                {
                                                filesUploading ? 'Uploading image ...'
                                                  : 
                                                  imgFile ? imgFile?.name.split(".")[0] : 'Browse folders here'
                                                  }
                                              </span>
                                              {"Supports the following file formats: .jpg, jpeg, png, gif, pdf, .doc, .docx, .ppt"}
                                            </button>

                                            <input type="file" name="myfile" accept='image/*'  multiple  onChange={(e) => this.handleOnImageLinks(e,"files")}
                                              />
                                          </div>
                                        </div>
                                      </div>:
                                       ""
                                  }

                                  </div> 
                            </div> }

                            {
                              images_links?.length > 0 && 
                              <div className="col-sm-12 uploading-image review_milestone_uploading">
                                <div className="upload-thumbnail mb-2">
                                {
                                    images_links?.length > 0 && images_links?.map((obj, index) => {
                                        const data = ['gif', 'jpg', 'jpeg', 'png']
                                        if(obj.type === "files"){
                                          return(                                
                                            <span
                                              className="upload-thumb"
                                              draggable="true"
                                              style={{ opacity: 1 }}
                                            >
                                              <div className="upload-delete">
                                                <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                              </div>
                                              <span
                                                className="upload_image_thumbnail"
                                                style={{backgroundImage:`url("${
                                                  handleFileType(obj?.name, ChatImageBaseUrl)
                                                  // data?.includes(obj?.name?.split('.')[1]) ? 
                                                  // ChatImageBaseUrl + obj.name :
                                                  // obj?.name?.includes(".pdf") ?
                                                  // PdfImage : 
                                                  // obj?.name?.includes(".zip")?
                                                  // ZipImage :
                                                  // obj?.name?.includes(".ppt")?
                                                  // PPtImage:
                                                  // DocImage
                                                }")`}}
                                              />
                                            </span>                                 
                                          )
                                        }  else{
                                            return(                                
                                              <span
                                                className="upload-thumb"
                                                draggable="true"
                                                style={{ opacity: 1 }}
                                              >
                                                <div className="upload-delete">
                                                  <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                                </div>
                                                <a href={obj?.name} target="_blank" key={index} style={{display:"contents"}}>
                                                  <span
                                                    className="upload_image_thumbnail"
                                                    style={{backgroundImage:`url(${LinkImage})`}}
                                                  />
                                                </a>
                                                
                                              </span>                      
                                            )
                                        }                              
                                    })
                                  }
                                </div>
                              </div> 
                            }

                            {/* Add image or url Exit */}

                            <div className="col-sm-12 pt-4">
                              <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Additional Notes for Client</span>
                            </div>
                            <div className="col-sm-12">
                              <div className="submit-milestone-summernote attachment_open">
                                <div className="position-relative">
                                  <CustomSummernote
                                    placeholder="Please add any additional notes, points or disclaimers here"
                                    value={notes}
                                    onChange={(val) => this.summernoteHandler(val)}
                                    sendMsg={this.handleSubmit}
                                  />
                                  <span className="review_attachment_icon"
                                    onClick={() => this.hiddenFileInput.current.click()}>
                                    <i className="pointer fas fa-link mr-3 text-light-gray"></i>
                                  </span>
                                  <input
                                    type="file"
                                    name="images"
                                    // className="user-uploading-input"
                                    accept="*"
                                    multiple
                                    // disabled={isDisabledChat}
                                    onChange={this.handleImageOnChange}
                                    ref={this.hiddenFileInput}
                                    style={{ display: 'none' }}
                                  />
                                </div>
                                {(files.length > 0 || filesUploading) &&
                                  <div className="preview-fileupload" style={{ display: 'block' }}>
                                    <div className="d-flex align-content-center flex-wrap inner-preview-fileupload">
                                      {files.map((file, idx) => {
                                        return (
                                          <span key={idx} className="upload-files mob-w-100 mob-ml-0 mx-1 py-2 pl-2 pr-3 mb-2 border-1 border-radius-8">
                                            <div className="d-flex align-items-center">
                                              <div className="pl-2">
                                                {renderDifferentFiles(file.name)}
                                              </div>
                                              <div className="pl-3">
                                                <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-12 line-height-normal">
                                                  {getFileName(file.name)}
                                                </h2>
                                              </div>
                                            </div>
                                            <div className="remove-file" onClick={() => {
                                              this.removeFile(idx);
                                            }}>
                                              <i className="fas fa-times" />
                                            </div>
                                          </span>
                                        )
                                      })}
                                      {filesUploading ?
                                        <MultipleCardLoader loadingCount={filesCount}>
                                          {LoadingCard}
                                        </MultipleCardLoader>
                                        : null
                                      }
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  {/* <!--desktop Section --> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer mobile-hide">
                    <p className="mb-0 text-sub-3 font-11 hidden">
                      <i className="fas fa-info-circle"></i>
                      <span className="text-secondary font-weight-500">IndieFolio charges an additional non-refundable one time payment of 10% of the total project cost.<sup><i className="fas fa-star-of-life font-5"></i></sup>Project quote is inclusive of all taxes and charges. Milestone payment will only be payable when you approve the milestone deliverables.</span>
                    </p>
                    <p className="mb-0 text-right d-flex align-items-center justify-content-end">
                      <span className="earning_info position-relative pr-3 line-height-normal font-16 font-weight-700 text-dark-black letter-spacing-023">Total earnings for you
                        <span className="tool" data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."><i className="fas fa-info-circle font-13 ml-1"></i></span>
                        <span className="d-block line-height-normal text-capitalize font-24 font-weight-700 text-dark-black letter-spacing-05"><i className="fas fa-rupee-sign font-20"></i>&nbsp;{separateNumber(totalEarning)}</span>
                      </span>
                      <button type="button"
                        className="main-btn font-14 py-3 mob-w-100 cursor-pointer"
                        onClick={this.handleRealSubmit} disabled={submitting}>
                        {submitting ? 'Submitting...' : this.props?.checkHasApplied ? 'Make Changes' : 'Submit proposal'} <i className="fas fa-arrow-right pl-5"></i>
                      </button>
                    </p>
                  </div>
                </div>
                {/*-- Mobile Submit Milestone --*/}
                <div className="row m-0">
                  <div className="mobile-show">
                    <MobileSubmitMilestoneJob {...this.props} {...this.state} {...allFunc} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store, props) => {
  let creatorProfile = store.creatorProfile;
  let dashboard = store.dashboard;
  let postProject = store.postProject;
  let chat = store.chat;

  const ProjectId = props.location.pathname === '/dashboard/jobs' ? dashboard.post_project_id : chat.ProjectId
  const ProjectName = props.location.pathname === '/dashboard/jobs' ? dashboard.project_name : chat.ProjectName
  return {
    closeModal: creatorProfile.closeModal,
    project_name: dashboard.project_name,
    ProjectId: ProjectId,
    ProjectName: ProjectName,
    ProjectType: chat.ProjectType,
    ProjectSlug: chat.ProjectSlug,
    ProjectStatus: chat.ProjectStatus,
    proposal: postProject.proposal,
    CurrentChat: chat.CurrentChat,
    collateral_id: dashboard.collateral_id
  };
};
const mapDispatch = dispatch => ({
  loadChatMessages: (connection_id) => dispatch({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  loadProjectStatus: (projectId) => dispatch({ type: 'LOAD_PROJECT_STATUS', payload: { projectId } }),
  loadChatAndLatestProposals: (projectId, projectName, projectStatus) => dispatch({ type: 'LOAD_CHAT_AND_LATEST_PROPOSAL', payload: { id: projectId, name: projectName, project_status: projectStatus } }),
  loadChat: (obj) => dispatch({ type: 'UPDATE_LOAD_CHAT', payload: obj }),
  SetCurrentProposal: (proposal_id, project_id, reviewStatus) => dispatch({ type: 'SET_CURRENT_PROPOSAL', payload: { proposal_id, project_id, reviewStatus } }),

});

export default connect(mapState, mapDispatch)(ReviewMilestoneJob);

