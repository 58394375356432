import React from 'react';
import { connect } from 'react-redux';

class MobileDropDown extends React.Component {
  closePopup = () => {
    this.props.closePopup()
  }

  openProposal = () => {
    const {proposal} = this.props
    
    this.props.OpenPopup('ReviewProposal')
    this.props.SetCurrentProposal(proposal._id,proposal.post_project_id);

    // creator side
    // this.props.SetCurrentProposal(proposal._id,proposal.post_project_id);
    // this.props.OpenPopup('ReviewProposal');
  }

  callhandler = () => {
    alert("feature coming soon.")
  }

  openBrief = () => {
    this.props.OpenPopup('IncomingBriefReview')
    this.props.LoadBriefData(this.props.ProjectId)
  }
 

  render() {
    
    const {Me, CurrentChat, proposalStatus, ProjectStatus, getFunc,clientBriefDetails} = this.props
    const {project_data} = clientBriefDetails ? clientBriefDetails : {};
    // project_data.manager_id=false;
   
    return (
      <>
        <div className="modal fade custom_form verify_modal custom_Modal_Box show" id="subHeaderDropdown" style={{ paddingRight: "0px !important", "display": "block"}} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div type="button" className="close_icon" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
            </span>
          </div>
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content border-0 position-bottom-modal">
              <div className="modal-body">
                <div className="card-wrapper mb-0">
                  <div className="card">
                    <div className="card-body">
                    {Me.CurrentRole === 1 ? 
                      <a  href={`/creator/${CurrentChat.username}`} target="_blank" rel="noopener noreferrer">
                        <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                          <span className="w-8"><i className="far fa-eye text-secondary font-16"></i></span>
                          <span className="font-15 text-dark-black font-weight-600">View {CurrentChat.first_name}'s profile</span>
                        </li>
                      </a> 
                    : null}

                        <li onClick={this.openBrief} className="border-bottom-1 d-flex align-items-center px-15 py-12">
                          <span className="w-8"><i className="far fa-eye text-secondary font-16"></i></span>
                          <span className="font-15 text-dark-black font-weight-600">View brief</span>
                        </li>

                    {proposalStatus === 2 ? null :
                      <>
                        {ProjectStatus === 5 || ProjectStatus === 6 ? 
                          <li onClick={this.openProposal} className="border-bottom-1 d-flex align-items-center px-15 py-12">
                          <span className="w-8"><i className="far fa-eye text-secondary font-16"></i></span>
                          <span className="font-15 text-dark-black font-weight-600">View Proposal</span>
                        </li> : null}
                        
                        {ProjectStatus === 5 && Me.CurrentRole === 1 ? 
                          <li onClick={(e) => { this.props.OpenPopup('ClientRejectsCreator')}} 
                            className="border-bottom-1 d-flex align-items-center px-15 py-12">
                            <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                            <span className="font-15 text-dark-black font-weight-600">Reject creator</span>
                          </li> : null}

                        {ProjectStatus === 5 && Me.CurrentRole === 2 ? 
                          <li onClick={(e) => {this.props.OpenPopup('CreatorRejectsBrief')}} 
                            className="border-bottom-1 d-flex align-items-center px-15 py-12">
                            <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                            <span className="font-15 text-dark-black font-weight-600">Reject brief</span>
                          </li>
                         : null}

                        {(ProjectStatus === 6) || (ProjectStatus === 5 && Me.CurrentRole === 1) ? 
                          <li onClick={(e) => {this.props.OpenPopup('EndProject')}} 
                            className="border-bottom-1 d-flex align-items-center px-15 py-12">
                            <span className="w-8"><i className="fas fa-genderless text-secondary font-24"></i></span>
                            <span className="font-15 text-dark-black font-weight-600">End project</span>
                          </li>
                         : null}

                         {/* <li onClick={this.callhandler} className="border-bottom-1 d-flex align-items-center px-15 py-12">
                          <span className="w-8"><i className="fas fa-phone text-secondary font-16"></i></span>
                          <span className="font-15 text-dark-black font-weight-600">Call {CurrentChat.first_name}</span>
                        </li> */}

                        <li onClick={(e)=>getFunc(e,project_data?.manager_id)} className={project_data.manager_id?`border-bottom-1 d-flex align-items-center px-15 py-12`:`d-none`}>
                          <span className="w-8"><i className="fa fa-phone text-secondary font-16" aria-hidden="true"></i></span>
                          <span className="font-15 text-dark-black font-weight-600">Schedule call with talent manager</span>
                        </li>
                         
                      </>
                    }
                      
                      {/* <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Ongoing projects</span>
                      </li>
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-phone text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Call Karan</span>
                      </li>
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Project brief</span>
                      </li>
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Starred messages</span>
                      </li>
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Files</span>
                      </li>
                      
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">Reject creator</span>
                      </li>
                      <li className="border-bottom-1 d-flex align-items-center px-15 py-12">
                        <span className="w-8"><i className="fas fa-search text-secondary font-16"></i></span>
                        <span className="font-15 text-dark-black font-weight-600">End project</span>
                      </li> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  const postProject = store.postProject
  let creatorProfile = store.creatorProfile;
  // console.log(creatorProfile);
  return {
    closeModal: creatorProfile.closeModal,
    Me: globalReducer.Me,
    CurrentChat: chat.CurrentChat,
    UserToken: chat.UserToken,
    UserId: chat.UserId,
    ProjectStatus: chat.ProjectStatus,
    proposalStatus: postProject.proposalStatus,
    proposal: postProject.proposal,
    loading: globalReducer.loading,
    milestones: postProject.milestones,
    // scheduleCall:creatorProfile.extra
    scheduleCall : creatorProfile.data
  };
};


const mapDispatch = dispatch => ({
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  LoadBriefData: (id) => dispatch({ type: 'LOAD_BRIEF_DATA', payload: { project_id: id } }),
  SetCurrentProposal: (proposal_id,project_id,reviewStatus) => dispatch({ type: 'SET_CURRENT_PROPOSAL', payload: {proposal_id,project_id,reviewStatus}}),

});
export default connect(mapState, mapDispatch)(MobileDropDown);
