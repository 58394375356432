import React, { Component } from 'react'
// import { calendarClientID, calendarApiKey } from '../../../utils/BaseUrl'
import ClickOutHandler from 'react-onclickout'
// import uuid from 'react-uuid'
import { connect } from 'react-redux'; 
import callingIcon from '../../../Assets/images/google-calendar.svg';
import { allMonths, allWeekdays } from '../../../utils/Constants';

import CalendarForm from './GoogleCalendarForm';
import {postAPI} from '../../../utils/API';


class ScheduleCall extends Component {

    state = {
        isOpenCalendar: false,
        isSignedIn: false,
        eventList: [],
        isLoading: false,
        isEdit: false,
        eventObj: null,
        slot1: {date: '', startTime: '', endTime: ''},
        slot2: {date: '', startTime: '', endTime: ''}
    }
    componentDidMount() {

    }
      
    onChange = (slot, data) => {
      // console.log(slot, data)
      this.setState({[slot]: data});
    }

    openCalendarHandler = () => {
        // console.log('clicked on calendar')
        this.props.onClick();
    }   

    validateForm = () => {
      const {slot1, slot2} = this.state;
      const {date, startTime, endTime} = slot1;
      const date2 = slot2.date;
      const startTime2 = slot2.startTime;
      const endTime2 = slot2.endTime;
      return (date && startTime && endTime) && (date2 && startTime2 && endTime2);
    }

    arrangeDateInArr = () => {
      const {slot1, slot2} = this.state;
      const {date, startTime, endTime} = slot1;

      const date2 = slot2.date;
      const startTime2 = slot2.startTime;
      const endTime2 = slot2.endTime;
      const slotArr = []

      if(date && startTime && endTime){ 
        const slot1FullDay = allWeekdays[new Date(date).getDay()].full;
        const slot1FullMonth = allMonths[new Date(date).getMonth()].full;
        const [,, dateNumber, fullYear] = date.split(' ');
        const newDate = `${slot1FullDay} ${slot1FullMonth} ${dateNumber}, ${fullYear}`
        slotArr.push({"slot":"1", "meeting_date": newDate, "meeting_time": `${startTime} to ${endTime}`})
      }
      if(date2 && startTime2 && endTime2){
        const slot1FullDay = allWeekdays[new Date(date2).getDay()].full;
        const slot1FullMonth = allMonths[new Date(date2).getMonth()].full;
        const [,, dateNumber, fullYear] = date2.split(' ');
        const newDate = `${slot1FullDay} ${slot1FullMonth} ${dateNumber}, ${fullYear}`
        slotArr.push({"slot":"2", "meeting_date": newDate, "meeting_time": `${startTime2} to ${endTime2}`})
      }
      
      return slotArr;
    }
    
    onSubmit = (e) => {
      const {ProjectId, CurrentChat, Me, postProjectId, creatorData, fromTalentConnect } = this.props
      const creatorFirstName = fromTalentConnect ? creatorData.creator_id.first_name : CurrentChat.first_name;
      // passing different props from talentConnect and Chat
      const meeting_with_id = fromTalentConnect ? creatorData.creator_id._id : CurrentChat.user_id;
      const post_project_id = fromTalentConnect ? postProjectId : ProjectId;

      if(this.validateForm()){
        const arrangedDate = this.arrangeDateInArr();
        const apiData =  {
          post_project_id, 
          date: arrangedDate, 
          meeting_with_id, 
          meeting_type: 'user_to_user',
          user_type: Me.CurrentRole === 1 ? 'Client' : 'Creator'
        }
        // console.log(apiData)
        const confirmMessage = `Are you sure you want to schedule call on these slots: \n 
          ${arrangedDate[0].meeting_date} from ${arrangedDate[0].meeting_time} \n 
          ${arrangedDate[1] ? arrangedDate[1].meeting_date + ' from ' + arrangedDate[1].meeting_time : ''}`

        if(window.confirm(confirmMessage)){
            this.setState({isLoading: true})
            postAPI('/project/set-meeting', apiData).then((res) => {
              this.setState({isLoading: false})
              // console.log("payment Details===>",res);
              // console.log(slot1, slot2, this.props, apiData);
              alert(`Your request to schedule a call with ${creatorFirstName} has been received. We'll be in touch with you shortly.`)
              this.openCalendarHandler()
            
            }).catch((error) => {
                alert('Something went wrong, please try again later.')
                console.log(error);
            })
        }
      } else {
        alert('Both slots are mandatory to schedule call.')
      }
    }

    render() {
      const {CurrentChat, fromTalentConnect, creatorData} = this.props;
      const creatorFirstName = fromTalentConnect ? creatorData.creator_id.first_name : CurrentChat.first_name;
      // console.log(this.props)
      const {isLoading } = this.state
        return (
            <>
                    <ClickOutHandler onClickOut={() => this.setState({isOpenCalendar: false})}>
                      
                      <span className={`chat-search-box google-calling-box dropdown ${fromTalentConnect ? 'active_brief_dropdown' : ''}`}>
                        {/* <i onClick={this.openCalendarHandler} className="fas fa-calendar"></i> */}
                        {fromTalentConnect ? 
                          <button onClick={this.openCalendarHandler} className="main-btn text-white px-4" style={{width: 140}}>
                            Schedule Call
                          </button>
                          :
                          <a href="/" onClick={e=>{e.preventDefault();this.openCalendarHandler();}}  className="dropdown-toggle d-block">
                            <img src={callingIcon} alt="Indiefolio" className="img-fluid chat-google-calendar"/>
                            <span className="d-block field-name">Schedule</span>
                          </a>
                        }
                        {this.props.showCalendar && 
                        <div className="dropdown-menu outer-nav-wrap google-calendar-dropdown" style={{display:"inline"}}  aria-labelledby="navbarDropdown">
                          <span className="dropdown-menu-arrow nav-arrow"></span>
                          <div className="row m-0 pt-3">
                            <div className="col-sm-12 px-0">
                              <div className="custom_form">
                                <h2 className="mb-0 text-dark-black font-weight-600 font-18 py-3 px-4">Schedule Call</h2> 
                                <div className="form-group px-4 mb-0">
                                  <label className="font-12 text-sub-2 font-weight-500">Choose 2 slots when you'd like to speak with {creatorFirstName}</label> 
                                </div> 
                                <div className="form-group mb-3">
                                    <label className="font-12 text-sub-2 font-weight-500 px-4">Slot #1</label>
                                    <CalendarForm
                                        onChange={data => this.onChange('slot1', data)}
                                        // isLoading={isLoading}
                                        // isEdit={isEdit}
                                        // eventObj={eventObj}
                                        // eventList={eventList}
                                        // onResetEdit={() => this.setState({isEdit: false, eventObj: null})}
                                    />
                                  </div> 
                                  <div className="form-group mb-5">
                                    <label className="font-12 text-sub-2 font-weight-500 px-4">Slot #2</label>
                                    <CalendarForm
                                        onChange={data => this.onChange('slot2', data)}
                                        // isLoading={isLoading}
                                        // isEdit={isEdit}
                                        // eventObj={eventObj}
                                        // eventList={eventList}
                                        // onResetEdit={() => this.setState({isEdit: false, eventObj: null})}
                                    />
                                  </div>
                                  <div className="google-calendar-footer dflex">
                                      <button disabled={isLoading} 
                                              onClick={this.onSubmit} 
                                              className="main-btn font-13">Schedule</button>
                                      <div className="mobile-show">
                                        <button onClick={this.openCalendarHandler}
                                                className="main-btn font-13">Cancel</button>
                                      </div>
                                  </div>
                              </div>  
                            </div>
                          </div>
                          <div className="overflow-content folder-modal-height">
                          </div>
                        </div>
                        }
                      </span>
                    </ClickOutHandler>

            </>
        )
    }
}

const mapState = state => {
  const {ProjectId, CurrentChat} = state.chat;
  const {Me} = state.globalReducer
  return {
    ProjectId,
    CurrentChat,
    Me,
  }
}

export default connect(mapState, null)(ScheduleCall)


