import React from "react";
import { connect } from "react-redux";
import { postAPI } from "../../../../../utils/API";
import { BaseImageUrl, RazorpayKey, prefill } from "../../../../../utils/BaseUrl";
import PercentIcon from "../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import DummyProfile from "../../../../../Assets/upgrade-images/client_active_brief_chat/dummy.png";
import Location from "../../../../../Assets/upgrade-images/client_approve_pay/location.svg";
import Exclamation from "../../../../../Assets/upgrade-images/client_approve_pay/exclamation.svg";
import Select from "react-select";
import moment from "moment";
import { separateNumber } from "../../../../../utils/Helperfunctions";
import StarRatings from "react-star-ratings";
import defaultIcon from "../../../../../Assets/images/c.jpg";
import DatePicker from "react-date-picker";
import { addHrMinDate } from "../CommonFunctionsNew/CommonFunctionsNew";
class ApproveAndPay extends React.Component {
  state = {
    isLoading: false,
    method: "Razorpay",
    prefBillingCycle: { label: "Monthly", value: "Monthly" },
    propDuration: {
      prop_duration: { label: "Months", value: "Months" },
      prop_dur_num: 1,
    },
    reject_creators: { label: "Yes", value: true },
    logo_usage_rights: { label: "Approve", value: true },
    work_usage_rights: { label: "Approve", value: true },
    validation: { prop_dur_num: false },
    proposed_start_date: new Date(),
    copy_proposed_start_date: new Date(),
  };

  componentDidMount = () => {
    let proposalData = this.props.stateData?.proposalData;
    this.setState({
      ...this.state,
      method: parseInt(proposalData?.advance_amount) == 0 ? "Bank" : "Razorpay",
      prefBillingCycle:
        proposalData.proposal_type == "fixed"
          ? { label: "Monthly", value: "Monthly" }
          : {
              label: proposalData?.preferred_billing_cycle == "Monthly" ? "Cycle End" : proposalData?.preferred_billing_cycle,
              value: proposalData?.preferred_billing_cycle,
            },
      proposed_start_date: proposalData.proposal_type == "fixed" ? new Date() : new Date(proposalData?.proposed_start_date),
      copy_proposed_start_date: proposalData.proposal_type == "fixed" ? new Date() : new Date(proposalData?.proposed_start_date),
      propDuration:
        proposalData.proposal_type == "fixed"
          ? {
              prop_duration: { label: "Months", value: "Months" },
              prop_dur_num: 1,
            }
          : {
              prop_duration: { label: "Months", value: "Months" },
              prop_dur_num: proposalData?.proposed_duration,
            },
    });
  };

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps) {
  //     this.setState({
  //       ...this.state,
  //       method: parseInt(this.props.stateData?.proposalData?.advance_amount) == 0 ? "Bank" : "Razorpay",
  //     });
  //   }
  // }

  approveWithoutPayment = async () => {
    try {
      let values = this.props.stateData?.proposalData;
      const obj = {
        milestone_id: values?._id,
        proposal_id: values?._id,
        type: "proposal",
        reject_creators: this.state.reject_creators.value,
        logo_usage_rights: this.state.logo_usage_rights.value,
        work_usage_rights: this.state.work_usage_rights.value,
      };

      if (this.props.stateData?.proposalData.proposal_type != "fixed") {
        obj.preferred_billing_cycle = this.state.prefBillingCycle.value;
        obj.proposed_start_date = addHrMinDate(this.state.proposed_start_date, 5, 30);
        obj.proposed_duration = this.state.propDuration.prop_dur_num;
      }

      const res = await postAPI("/payment/direct-payment", obj);
      if (parseInt(res.data.status) === 1) {
        const obj = {
          projectId: this.props?.isClientViewBrief ? this.props.userData?._id : this.props.ProjectId,
          projectName: this.props?.isClientViewBrief ? this.props.userData?.project_name : this.props.ProjectName,
          projectStatus: 6,
          projectType: this.props?.isClientViewBrief ? this.props.userData?.sub_type : this.props.ProjectType,
        };

        // const slug = this.props?.isClientViewBrief
        //   ? this.props.userData?.project_slug
        //   : this.props?.projectDataStates?.data?.project_data?.project_slug;

        // this.props.loadChat(obj);
        this.props.changeShowApproveAndPay(false);
        // this.props.callApiAgain();
        this.props.history.push(`/projects/${this.props?.projectDataStates?.data?.project_data?._id}/project_room`);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  handleContinue = () => {
    this.setState({ isLoading: true });
    let proposalData = this.props.stateData?.proposalData;
    let advance_amount = proposalData?.advance_amount;
    // if (parseInt(advance_amount) === 0 || this.props?.projectDataStates?.data?.project_data.offline_payment === 1 || (this.props?.isClientViewBrief && this.props?.userData?.offline_payment === 1)) {
    //   this.approveWithoutPayment();
    // } else {
    //   this.approvePay();
    // }
    if ((proposalData.proposal_type != "fixed" && this.handleValidation()) || proposalData.proposal_type == "fixed") {
      if (this.state.method === "Bank" || parseInt(advance_amount) == 0) {
        this.approveWithoutPayment();
      } else {
        this.approvePay();
      }
    }
  };

  handleValidation = () => {
    let valid = true;
    if (this.state.propDuration.prop_dur_num < 1) valid = false;
    this.state.validation.prop_dur_num = this.state.propDuration.prop_dur_num < 1 ? true : false;
    this.setState({ ...this.state });
    return valid;
  };

  approvePay = () => {
    let advance_amount = this.props.stateData?.proposalData?.advance_amount;
    let amount = Math.ceil(advance_amount * 1.18);
    const payment_details = {
      amount: amount * 100,
      currency: "INR",
      receipt: "#",
      proposal_id: this.props.stateData?.proposalData?._id,
      type: "proposal",
      reject_creators: this.state.reject_creators.value,
      logo_usage_rights: this.state.logo_usage_rights.value,
      work_usage_rights: this.state.work_usage_rights.value,
    };

    if (this.props.stateData?.proposalData.proposal_type != "fixed") {
      payment_details.preferred_billing_cycle = this.state.prefBillingCycle.value;
      payment_details.proposed_start_date = addHrMinDate(this.state.proposed_start_date, 5, 30);
      payment_details.proposed_duration = this.state.propDuration.prop_dur_num;
    }

    postAPI("/payment/create-order", payment_details)
      .then((res) => {
        if (res.data.status === "1") {
          // console.log("payment Details===>", res.data);
          this.setState(
            {
              order_id: res.data.data.order_id,
              proposal_id: res.data.data.proposal_id,
              payment_type: res.data.data.type,
            },
            (e) => this.paymentHandler()
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  paymentHandler = async () => {
    const options = {
      key: RazorpayKey,
      name: "IndieFolio",
      description: "Kickstart Project payment",
      order_id: this.state.order_id,
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;
          const proposal_id = this.state.proposal_id;
          const type = this.state.payment_type;

          let payment_details = {
            payment_id: paymentId,
            order_id: orderId,
            signature: signature,
            proposal_id: proposal_id,
            type: type,
            reject_creators: this.state.reject_creators.value,
            logo_usage_rights: this.state.logo_usage_rights.value,
            work_usage_rights: this.state.work_usage_rights.value,
          };

          if (this.props.stateData?.proposalData.proposal_type != "fixed") {
            payment_details.preferred_billing_cycle = this.state.prefBillingCycle.value;
            payment_details.proposed_start_date = addHrMinDate(this.state.proposed_start_date, 5, 30);
            payment_details.proposed_duration = this.state.propDuration.prop_dur_num;
          }

          postAPI("/payment/verify-signeture", payment_details)
            .then((res) => {
              if (parseInt(res.data.status) === 1) {
                const obj = {
                  projectId: this.props?.isClientViewBrief ? this.props.userData?._id : this.props.ProjectId,
                  projectName: this.props?.isClientViewBrief ? this.props.userData?.project_name : this.props.ProjectName,
                  projectStatus: 6,
                  projectType: this.props?.isClientViewBrief ? this.props.userData?.sub_type : this.props.ProjectType,
                  callAllProposalApi: true,
                };

                // const slug = this.props?.isClientViewBrief
                //   ? this.props.userData?.project_slug
                //   : this.props?.projectDataStates?.data?.project_data?.project_slug;

                // this.props.loadChat(obj);
                this.props.changeShowApproveAndPay(false);
                // this.props.callApiAgain();
                this.props.history.push(`/projects/${this.props?.projectDataStates?.data?.project_data?._id}/project_room`);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      options.prefill = prefill;
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    this.setState({ isLoading: false });
  };

  handleSelect = (selected, which) => {
    if (which === "duration")
      this.setState({
        ...this.state,
        propDuration: { ...this.state.propDuration, prop_duration: selected },
      });
    else if (which === "logo")
      this.setState({
        ...this.state,
        logo_usage_rights: selected,
      });
    else if (which === "work")
      this.setState({
        ...this.state,
        work_usage_rights: selected,
      });
    else if (which === "reject_creators")
      this.setState({
        ...this.state,
        reject_creators: selected,
      });
    else
      this.setState({
        ...this.state,
        prefBillingCycle: selected,
      });
  };

  handleNumbers = (action, e) => {
    let numbers = this.state.propDuration.prop_dur_num;
    if (action === "plus") {
      numbers == 0 ? (this.state.propDuration.prop_dur_num = 1) : (this.state.propDuration.prop_dur_num += 1);
    } else if (action === "change") {
      this.state.propDuration.prop_dur_num = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (this.state.propDuration.prop_dur_num > 1) this.state.propDuration.prop_dur_num -= 1;
    }
    this.state.validation.prop_dur_num = this.state.propDuration.prop_dur_num < 1 ? true : false;
    this.setState({ ...this.state, propDuration: { ...this.state.propDuration } });
  };

  handleChangeDate = (e) => {
    this.state.proposed_start_date = new Date(e);
    this.setState({ ...this.state });
  };

  deliveryDate = (array) => {
    let result = "";
    let length = array?.length;
    let value = length ? array[length - 1]?.due_date : "";
    result = value ? moment(value).format("MMM D, YYYY") : "";

    return result;
  };

  render() {
    const { stateData } = this.props;
    const { method, isLoading } = this.state;

    // console.log("stateData = ", stateData.proposalData);

    const proposalData = stateData?.proposalData;
    const userDate = proposalData?.userData;

    // let experience = userDate.user_collateral_experience.year
    let expYear = userDate?.user_collateral_experience?.year;
    let expMonth = userDate?.user_collateral_experience?.month;
    return (
      <>
        <section className="client_appprovepay_wrapper">
          <div
            className={`modal fade modal_fade_bg ${this.props?.showApproveAndPay ? "show" : ""}`}
            id="reviewConfirm"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: this.props?.showApproveAndPay ? "block" : "" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content br_6">
                <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                  <div className="header">
                    <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                      Review & Confirm
                    </h5>
                  </div>

                  {proposalData?.proposal_type &&
                    (proposalData.proposal_type == "fixed" ? (
                      <div className="tag_btn_common">
                        <p className="mb-0">
                          <img src={PercentIcon} alt="" className="icon_img mr-1" />
                          Fixed Scope
                        </p>
                      </div>
                    ) : proposalData.proposal_type == "retainer" ? (
                      <div className="tag_btn_green_common">
                        <p className="mb-0">
                          <img src={PercentIconGreen} alt="" className="icon_img mr-1" />
                          Retainer
                        </p>
                      </div>
                    ) : (
                      <div className="tag_btn_dark_green_common">
                        <p className="mb-0">
                          <img src={PercentIconDarkGreen} alt="" className="icon_img mr-1" />
                          On-Actuals
                        </p>
                      </div>
                    ))}
                </div>

                <div
                  className="modal-body py-4 ps_2 pe_2 common_overflow_sm_height">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <p className="mb-0 font_semi_bold">Creator Selected</p>
                    </div>
                  </div>
                  <div className="payment_profile_card row mx-0 mb-0 py-4 br_6">
                    <div className="col-lg-auto">
                      <div className="profile_image_100 ">
                        <img src={userDate?.profile_image ? BaseImageUrl + userDate.profile_image : DummyProfile} alt="" />
                      </div>
                    </div>
                    <div className="profile_details col-lg-9">
                      <ul className="d-flex line_height_18 align-items-center mb-3">
                        <li className="pr-4 mr-2 text_black_500_xl_w_700"> {userDate?.first_name + " " + userDate?.last_name}</li>
                        <li className="li_disc text_gray_1100_w_700">
                          {(expYear < 1 ? 1 : expYear) +
                            (expYear < 1 ? " year " : expMonth ? "+ years " : expYear == 1 ? " year " : " years ")}
                          of experience
                        </li>
                      </ul>
                      {userDate?.averageRating === 0 ? null : (
                        <p className="d-flex align-items-end mb-3">
                          <StarRatings
                            rating={Number(userDate?.averageRating)}
                            starRatedColor="#90c028"
                            starDimension="15px"
                            starSpacing="2px"
                            numberOfStars={5}
                            name="rating"
                          />
                          <span className="text_gray_1100_w_600_sm ml-3">
                            {userDate?.averageRating}({userDate?.averageRatingCount})
                          </span>
                        </p>
                      )}
                      <ul className="client_details d-flex align-items-center">
                        <li className="mr-4">
                          <div className="location d-flex">
                            <img src={Location} alt="" className="img-fluid" />
                            {/* </div> */}
                            <span className="ml-2 text_gray_1100_w_500_sm">{userDate?.location}</span>
                          </div>
                        </li>
                        {userDate?.creatorClient.length ? (
                          <li className="ml-2">
                            <div className="rating d-flex">
                              <span className=" text_gray_xs_w_700 mr-2">Previously worked with</span>
                              {userDate?.creatorClient?.length
                                ? userDate?.creatorClient.map((el, index) => {
                                    return el?.client_name ? (
                                      <div className="client_logo_image d-flex align-items-center" key={index}>
                                        <img
                                          alt=""
                                          src={el?.client_image ? BaseImageUrl + el?.client_image : defaultIcon}
                                          className="img-fluid"
                                        />
                                      </div>
                                    ) : null;
                                  })
                                : ""}
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>

                  <div className="row mb-0 py-3 justify-content-between align-items-center br_btm mx-0">
                    <div className="col-lg-8 pl-0">
                      <p className="mb-1 font_semi_bold_w_600">Would you like to reject the remaining Creators?</p>
                      <p className="mb-0 text_light_gray_xs">You can hire the remaining Creators as well by selecting “No”</p>
                    </div>

                    <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0">
                      <div className="dropedown_field select_dropdown">
                        <Select
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                          className=""
                          value={this.state.reject_creators}
                          onChange={(e) => this.handleSelect(e, "reject_creators")}
                        />
                      </div>
                    </div>
                  </div>

                  {/* {parseInt(this.props.stateData?.proposalData?.advance_amount) == 0 ? null : ( */}
                  <div className="row br_btm mx-0 mb-3 py-4">
                    <div className="col-lg-12 px-0 mb-3">
                      <p className="mb-0 font_semi_bold">Payment Mode</p>
                    </div>
                    <>
                      <div className="col-lg-6 pl-lg-0 pl-0">
                        <div
                          className={`payment_card ${method === "Razorpay" ? "active" : ""} d-flex cursor-pointer`}
                          onClick={() => this.setState({ ...this.state, method: "Razorpay" })}
                        >
                          <div className="radio_btn pr-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios1"
                                checked={method === "Razorpay" ? true : false}
                              />
                            </div>
                          </div>
                          <div className="payment_message">
                            <p className="mb-2 font_semi_bold_w_600">Razorpay</p>
                            <p className="mb-2 text_light_gray_md">
                              Use this option to make the payment immediately using debit card/credit card/UPI/net banking.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-lg-0 pr-0">
                        <div
                          className={`payment_card ${method === "Bank" ? "active" : ""} d-flex cursor-pointer`}
                          onClick={() => this.setState({ ...this.state, method: "Bank" })}
                        >
                          <div className="radio_btn pr-3">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios2"
                                checked={method === "Bank" ? true : false}
                              />
                            </div>
                          </div>
                          <div className="payment_message">
                            <p className="mb-2 font_semi_bold_w_600">Bank Transfer</p>
                            <p className="mb-2 text_light_gray_md">
                              Use this option to facilitate payments outside of the platform using a bank transfer
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  {/* )} */}

                  {/* --------------------------------------------------------------------proposal start------------------------------------------------------------------ */}

                  {proposalData.proposal_type == "fixed" ? null : (
                    <div className="project_details mb-3">
                      <div className="row mx-0">
                        <div className="col-lg-12 px-0">
                          <p className="mb-0 font_semi_bold mb-0">Project Details</p>
                        </div>
                      </div>
                      <div className="row justify-content-between mx-0 br_btm py-4">
                        <div className="col-lg-8 pl-0">
                          <p className="mb-0 text_gray_w_600 mb-1">Start Date</p>
                          <p className="mb-0 text_light_gray_xs">
                            Kindly indicate by when you will be able to start working on this project.
                          </p>
                        </div>
                        <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0 pr-0">
                          <div className="date_input">
                            <DatePicker
                              value={this.state.proposed_start_date}
                              className="form-control text-center input_sm"
                              clearIcon={null}
                              name="startDate"
                              format="dd/MM/yyyy"
                              calendarClassName="apniClass"
                              showLeadingZeros={true}
                              onChange={this.handleChangeDate}
                              minDate={
                                new Date().getTime() > this.state.copy_proposed_start_date.getTime()
                                  ? this.state.copy_proposed_start_date
                                  : new Date()
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between py-4 mx-0 br_btm">
                        <div className="col-lg-8 pl-0">
                          <p className="mb-0 text_gray_w_600 mb-1">Billing cycle</p>
                          <p className="mb-0 text_light_gray_xs">
                            {" "}
                            If you'd like for the payment to be cleared at the end of a calendar month, kindly choose "Month End".
                            For example, your project starts off on June 15 and you'd like to be paid for June and then start a
                            new billing cycle, choose "Month End". If you'd like to be paid after 1 month gets over, i.e., June 15
                            to July 14, choose "Cycle End".
                          </p>
                        </div>
                        <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0 pr-0">
                          <div className="dropedown_field select_dropdown">
                            <Select
                              options={[
                                { label: "Cycle End", value: "Monthly" },
                                { label: "Month End", value: "Month End" },
                              ]}
                              className=""
                              value={this.state.prefBillingCycle}
                              onChange={(e) => this.handleSelect(e, "Billing")}
                              // isDisabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between py-4 mx-0 br_btm">
                        <div className="col-lg-5 pl-0">
                          <p className="mb-0 text_gray_w_600 mb-1">Project Duration</p>
                          <p className="mb-0 text_light_gray_xs">Indicate how long you will be able to work on this project.</p>
                        </div>
                        <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0 pr-0">
                          <div className="btn_field number_btn mr-2">
                            <div class="btn-group btn-group-h-3 btn-group-lg" role="group" aria-label="Basic example">
                              <button type="button border-0" class="btn" onClick={() => this.handleNumbers("minus")}>
                                -
                              </button>
                              <input
                                type="text"
                                class="form-control text-center input_sm"
                                value={this.state.propDuration.prop_dur_num}
                                onKeyDown={(evt) =>
                                  (evt.key === "e" ||
                                    evt.key === "=" ||
                                    evt.key === "-" ||
                                    evt.key === "+" ||
                                    evt.key === "_" ||
                                    evt.key === ".") &&
                                  evt.preventDefault()
                                }
                                onChange={(e) => this.handleNumbers("change", e)}
                                // disabled={true}
                              />
                              <button type="button border-0" class="btn" onClick={() => this.handleNumbers("plus")}>
                                +
                              </button>
                            </div>
                            {this.state.validation.prop_dur_num ? <p className="error_message">required</p> : ""}
                          </div>
                          <div
                            className="dropedown_field select_dropdown d-flex align-items-center ml-5"
                            style={{ minWidth: "3rem" }}
                          >
                            <p className="text_light_gray_md_w_700 mb-0">{this.state.propDuration.prop_duration.value}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* --------------------------------------------------------------------proposal end------------------------------------------------------------------ */}
                  <div className="legal_rights py-3">
                    <div className="row mx-0">
                      <div className="col-lg-12 px-0">
                        <p className="mb-0 font_semi_bold mb-0">Legal Rights</p>
                      </div>
                    </div>
                    <div className="row justify-content-between mx-0 br_btm py-4">
                      <div className="col-lg-8 pl-0">
                        <p className="mb-0 text_gray_w_600 mb-3">Logo Usage Rights</p>
                        <p className="mb-0 text_light_gray_xs">
                          Does IndieFolio & the selected expert have the right to use your name & logo for marketing and
                          promotional purposes after completion of the project?
                        </p>
                      </div>
                      <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0 pr-0">
                        <div className="dropedown_field select_dropdown">
                          <Select
                            options={[
                              { label: "Approve", value: true },
                              { label: "Reject", value: false },
                            ]}
                            className=""
                            value={this.state.logo_usage_rights}
                            onChange={(e) => this.handleSelect(e, "logo")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between py-4 mx-0">
                      <div className="col-lg-8 pl-0">
                        <p className="mb-0 text_gray_w_600 mb-3">Work Usage Rights</p>
                        <p className="mb-0 text_light_gray_xs">
                          Does IndieFolio & the selected expert have the right to use your name & logo for marketing and
                          promotional purposes after completion of the project?
                        </p>
                      </div>
                      <div className="duration_fields d-flex col-lg-auto justify-content-end px-lg-0 pr-0">
                        <div className="dropedown_field select_dropdown">
                          <Select
                            options={[
                              { label: "Approve", value: true },
                              { label: "Reject", value: false },
                            ]}
                            className=""
                            value={this.state.work_usage_rights}
                            onChange={(e) => this.handleSelect(e, "work")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="total_details_card p-3 ps_2 pe_2">
                  {proposalData.proposal_type == "fixed" ? (
                    <>
                      <div className="row d-flex align-items-center justify-content-between br_btm mx-0 px-0 py-3 amount_tag ">
                        <div className="title">
                          <p className="mb-0 font_semi_bold">Total Cost</p>
                        </div>
                        <div className="total_amount">
                          <p className="mb-0 text_gray_md_w_700">
                            ₹ {separateNumber(proposalData?.sub_total_cost, "")}{" "}
                            <span className="d-block text_light_gray_xs text-right">+18% GST </span>
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center justify-content-between  mx-0  px-0 py-3 amount_tag ">
                        <div className="title">
                          <p className="mb-0 font_semi_bold">Delivery Date</p>
                        </div>
                        <div className="total_amount">
                          <p className="mb-0 text_gray_md_w_700">{this.deliveryDate(proposalData?.deliverables)}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {proposalData.proposal_type == "retainer" ? (
                    <div className="row d-flex align-items-center justify-content-between mx-0 px-0 py-3 amount_tag ">
                      <div className="title">
                        <p className="mb-0 font_semi_bold">Retainer Cost</p>
                      </div>
                      <div className="total_amount">
                        <p className="mb-0 text_gray_md_w_700">
                          ₹ {separateNumber(proposalData?.retainer_fees, "")}/{" "}
                          {proposalData?.retainer_fees_type == "Hourly" ? "Hour" : "Month"}{" "}
                          <span className="d-block text_light_gray_xs text-right">+18% GST </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {proposalData.proposal_type == "actual" ? (
                    <div className="row mx-0 px-0 py-3 amount_tag ">
                      <div className="title">
                        <p className="mb-0 font_semi_bold">
                          The amount you’ll pay depends on items approved by you.
                          <span>
                            <img src={Exclamation} alt="" className="img-fluid ml-2" />
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                </div>

                
                <div className="modal-footer d-flex  justify-content-between align-items-center py-4  ps_2 pe_2 border-0">
                  <button
                    type="button"
                    class="btn text_gray_1100 fw_700 font-14 m-0"
                    onClick={() => this.props.changeShowApproveAndPay(false)}
                  >
                    Cancel
                  </button>
                  <button type="button" class="btn btn_common_md fw_700 m-0" disabled={isLoading} onClick={this.handleContinue}>
                    {isLoading
                      ? "Processing..."
                      : parseInt(this.props.stateData?.proposalData?.advance_amount) == 0
                      ? "Proceed"
                      : "Approve and pay"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let creatorProfile = store.creatorProfile;
  let postProjectData = store.postProject;

  return {
    closeModal: creatorProfile.closeModal,
    current_proposal_id: postProjectData.current_proposal_id,
    proposal_data: postProjectData.review_proposal_data,
    CurrentChat: chat.CurrentChat,
    ProjectName: chat.ProjectName,
    ProjectStatus: chat.ProjectStatus,
    ProjectType: chat.ProjectType,
    ProjectId: chat.ProjectId,
  };
};
const mapDispatch = (dispatch) => ({
  loadChat: (obj) => dispatch({ type: "UPDATE_LOAD_CHAT", payload: obj }),
  loadChatMessages: (connection_id) => dispatch({ type: "UPDATE_LOAD_CHATMESSAGES", payload: connection_id }),
  loadChatAndLatestProposals: (projectId, projectName, projectStatus) =>
    dispatch({
      type: "LOAD_CHAT_AND_LATEST_PROPOSAL",
      payload: { id: projectId, name: projectName, project_status: projectStatus },
    }),
});
export default connect(mapState, mapDispatch)(ApproveAndPay);
