import React, { useState } from 'react'
import Check from '../../../../../Assets/upgrade-images/account/Group 38204.svg'
import Success from '../../../../../Assets/upgrade-images/account/Bigsuccess.svg'
import { postAPI } from '../../../../../utils/API';
import {emailVerification} from '../../../../../utils/BaseUrl'

export const NotResponsivePopUp = (props) => {
   const [show, setShow] = useState(false);

   const sendMail =async () =>{
    try{
        let data = {url:emailVerification+props.location.pathname,project_name:props.projectDataStates.data.project_data.project_name,mail_id:props.userProfile.email}
        const api=await postAPI('/project/non-responsive-template-email',data)
        console.log(api.data)
    }catch(err){
        alert('something went wrong')
    }
   }

   function toggleShow() {
    if(props.authIs){
        setShow(!show);
    }else{
        setShow(!show);
        props.history.push({
            pathname: '/login',
            state: { text: "Log in to your account to view the brief" },
        });
    }

  }
    return (
        <section className="screen-not-responsive position-relative mobile-show">
            <div
                className={`modal fade modal_fade_bg show`}
                style={{ display:"block"}}
                id="responsive_check"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm m-auto">
                    <div className="modal-content br_6 border-0 mx-4">
                        <div className="modal-body p-4 text-center">
                            <div className={`col-10 py-4 mx-auto ${show ? 'hidden' : 'show-div'}`}>
                                <img src={Check} className='mb-4'/>
                                <p className='mb-4 font-18 fw_700 text-gray-1300'>This page is only accessible on a desktop screen for now</p>
                                <p><button className='main-btn font-12 fw_700' onClick={toggleShow}>Email me the link</button></p>
                                {/* <p><a href="" className='font-12 fw_700'>Show Desktop View</a></p> */}
                            </div>

                            { show && 
                            <div className={'col-10 py-4 mx-auto'}>
                                <img src={Success} className='mb-4'/>
                                <p className='mb-2 font-18 fw_700 text-gray-1300'>Link emailed to</p>
                                <p className='fw_700 font-16 mb-4'>{props.userProfile.email}</p>
                                <p><button className='main-btn font-12 fw_700 col-7'>Okay!</button></p>
                                {/* <p><a href="" className='font-12 fw_700'>Show Desktop View</a></p> */}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}