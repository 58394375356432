import React, { Component } from 'react';
import { connect } from 'react-redux'
// import Loader from './../../../unauthorized/Common/Loader/Loader';

class UserSurvey extends Component {
    handleClose = () => {
        this.props.closePopup()
     }

    render() { 
        return (
             <React.Fragment>
                 <div className="modal custom_form userSurveyModal" id="usersurvey" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                    <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("./../../../../Assets/images/close.png")} className="img-fliud" width="35" alt='' /></span> 
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content zoom-out">  
                        <div className="modal-body">
                            <div className="row m-0">
                                <div className="col-sm-12 overflow-content p-0"> 
                                    <div class="user-survey">
                                        <div className="row mx-0 mb-4">
                                            <div className="col-sm-12">
                                                <label className="font-16 font-weight-600 text-dark-black mb-4"><span className="text-danger">*</span>1.On a scale of 0 to 10, how likely are you to recommend our business to a friend or colleague?</label>
                                            </div>
                                            <div className="col-sm-12 d-flex align-items-center justify-content-between">
                                                <label className="font-12 font-weight-500 text-dark-black text-uppercase">Not at all likely</label>
                                                <label className="font-12 font-weight-500 text-dark-black text-uppercase">Extremely likely</label>
                                            </div>
                                            <div className="col-sm-12">
                                                <button class="btn btn-scale btn-scale-asc-0">0</button>
                                                <button class="btn btn-scale btn-scale-asc-1">1</button>
                                                <button class="btn btn-scale btn-scale-asc-2">2</button>
                                                <button class="btn btn-scale btn-scale-asc-3">3</button>
                                                <button class="btn btn-scale btn-scale-asc-4">4</button>
                                                <button class="btn btn-scale btn-scale-asc-5">5</button>
                                                <button class="btn btn-scale btn-scale-asc-6">6</button>
                                                <button class="btn btn-scale btn-scale-asc-7">7</button>
                                                <button class="btn btn-scale btn-scale-asc-8">8</button>
                                                <button class="btn btn-scale btn-scale-asc-9">9</button>
                                                <button class="btn btn-scale btn-scale-asc-10">10</button>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-sm-12">
                                                <label className="font-16 font-weight-600 text-dark-black mb-4"><span className="text-danger">*</span>2.How would you feel if you could no longer use IndieFolio?</label>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="row survey-radio">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                                        <label className="form-check-label" for="exampleRadios1">
                                                            Very disappointed
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                                        <label className="form-check-label" for="exampleRadios2">
                                                            Somewhat disappointed
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" />
                                                        <label className="form-check-label" for="exampleRadios3">
                                                            Not disappointed
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-backdrop show" style={{display: 'block'}}></div>
             </React.Fragment>
        )
    }
}

const mapDispatch =  dispatch => ({
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
}) 

export default connect(null, mapDispatch)(UserSurvey)