import React from 'react';
import { connect } from 'react-redux';
// import Empty from '../../../../../Assets/images/svg/empty.png';

class CollateralJob extends React.Component {

    render() {
        const { collName } = this.props;
        
        return (
              <>
                <div className="modal fade custom_form show" id="modal-subscribe" tabIndex={-1} style={{ paddingRight: "0px", display: "block" }} role="dialog" aria-labelledby="myModalLabel">
                    <div type="button" className="close_icon" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <img onClick={(e) => {
                                this.props.closePopup()
                                this.props.history.push(`/dashboard/jobs`);
                            }
                            } alt="" src={require("../../../../../Assets/images/close.png")} className="img-fliud" width="35" />
                    </span>
                    </div>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content border-0 height-auto">
                            <div className="modal-body">
                                <div className="sucess-brief">
                                    <div className="row m-0">
                                        <div className="col-sm-12 p-0">
                                            <div className="sucess-bkg no_collateral text-center pt-30 pb-20 mob-px-20 mob-pb-40">
                                                <h2 className="font-18 text-white font-weight-600"> Improve Your Chances of Bagging the Project </h2>
                                                
                                            </div>
                                            <div className="sucess-content text-center pt-50 px-15 overflow-content mob-overflow-content">
                                                <p className="font-20 mob-font-18 font-weight-600 ">
                                                    Since you just added the collateral to your profile, there are no associated projects
                                                    to it. Having related projects improves your profile & proposal view. And hence,
                                                    it increases your chances of landing the project manifold. We recommend that you
                                                    take a few more minutes and add relevant projects to your profile. Or you could
                                                    edit one of your existing projects and add <span style={{ color: "#10c89b" }}>{collName}</span> to it.

                                                </p>
                                            </div>
                                            <div className="sucess-footer text-center py-30 pb-50">
                                                <button type="button" className="main-btn mr-4" onClick={(e) => {
                                                    this.props.closePopup()
                                                    this.props.history.push(`/uploadproject/choose_collateral`);
                                                }
                                                } >
                                                    Upload Project
                                                    <i className="fas fa-arrow-right ml-1 mob-m-0" />
                                                </button>
                                                <button type="button" className="main-btn bg-light-gray font-weight-600 cursor-pointer" onClick={(e) => {
                                                    this.props.closePopup() 
                                                    this.props.history.push(`/dashboard/jobs`);
                                                }} >
                                                    Ignore 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="back-modal" />
            </>
        )
    }
}

const mapDispatch = dispatch => ({
  OpenPopup: (text) => dispatch({ type: 'OPEN_POST_PROJECT_POPUP', payload: { Component: text } }),
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
});

export default connect(null,mapDispatch)(CollateralJob);


