import React, { useState } from "react";
import Microphone from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/microphone.png";
import Attachment from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/attachment.png";
import Media from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/upload_media.png";
import Message from "../../../../../Assets/upgrade-images/creator_active_brief_chat_room_empty/messages.svg";
import Danger from "../../../../../Assets/upgrade-images/creator_active_brief_chat_room_empty/danger.png";
import FadeSendArrow from "../../../../../Assets/upgrade-images/creator_active_brief_chat_room_empty/send.svg";
import AskQuestion from "../PopopCreator/AskQuestion";

export default function EmptyChat(props) {
  const [showAskQuestion, setShowAskQuestion] = useState(false);
  const [showAskButton, setShowAskButton] = useState(true);

  const changeShowAskQuestion = (value) => {
    setShowAskQuestion(value);
  };

  return (
    <>
      <div className="col-lg-12">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-chat" role="tabpanel" aria-labelledby="nav-chat-tab">
            <div className="chat_body body_scroll_bar empty_chat_box">
              <div className="empty_box_massage text-center">
                <img src={Message} className="img-fluid mr-4" />
                <p className="text_light_gray_lg_w_600">{props?.emptyChatStatus != 5 && showAskButton ? "Chat will be initiated by the Client" : "Questions have been sent to the client."}</p>
                <p className="text_text-gray-1500_md mb-1">{props?.emptyChatStatus != 5 && showAskButton ? "Got questions on the brief?" : "Chat will be initiated by the client"}</p>
                {props?.emptyChatStatus != 5 && showAskButton ? (
                  <p className="font-14 anchor_text_sm_w_700" onClick={() => changeShowAskQuestion(true)}>
                    Ask Questions
                  </p>
                ) : (
                  <p className="text_light_gray_lg_w_600">Questions has been asked, wait for admin approval.</p>
                )}
              </div>
            </div>

            <div className="row mx-0 outer_textarea px-2">
              <div className="col-lg-12 px-0">
                <div className="empty_chat_upload_notes">
                  <textarea
                    class="body_scroll_bar form-control upload_sub_title border-0"
                    id="exampleFormControlTextarea1"
                    placeholder="You cannot send messages here at the moment"
                    disabled={true}
                  ></textarea>
                  <div className="dander_icon">
                    <img src={Danger} className="img-fluid mr-4" />
                  </div>

                  <div className="send_icon">
                  <img src={Attachment} className="img-fluid mr-4" />
                    <img src={FadeSendArrow} className="img-fluid mr-4" />
                  </div>
                  {/* <div className="upload_media_icons d-flex">
                    <img src={Attachment} className="img-fluid mr-4" />
                    <img src={Media} className="img-fluid  mr-4" />
                    <img src={Microphone} className="img-fluid  mr-4" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAskQuestion ? (
        <AskQuestion
          changeShowAskQuestion={changeShowAskQuestion}
          showAskQuestion={showAskQuestion}
          id={props?.match?.params?.projectId}
          {...props}
          setShowAskButton={setShowAskButton}
        />
      ) : (
        ""
      )}
    </>
  );
}
