export const clickLogin = ()=>{
    return {
        type:"CLICK_LOGIN",
        
    }
}

export const closeLoginPopUp = ()=>{
    return {
        type:"CLOSE_LOGIN_POP_UP",
        
    }
}

export const clickSignUp= ()=>{
    return {
        type:"CLICK_SIGN_UP",
        
    }
}

export const closeSignUp= ()=>{
    return {
        type:"CLOSE_SIGN_UP",
        
    }
}