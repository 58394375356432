
import { put } from "redux-saga/effects";
import { fireApi } from '../../../utils/fireApiPrecheck';


export function* loadAdminProjectChat() {
    try {
    //   let currentUserRole = yield select(globalReducer);
    //   currentUserRole       = currentUserRole.Me.CurrentRole;
      // console.log('%c CurrentUserRole', 'font-size: 22px; color:orange');
      // console.log(currentUserRole);
      
    //   let data = {}
    //   // let data =   yield fireApi('GetProjectChatClient');
    //   if(currentUserRole === 1) {
    //       data = yield fireApi('GetProjectChatClient');
    //   } else if(currentUserRole === 2) {
    //     data = yield fireApi('GetProjectChatCreator');
    //   }
    // console.log('%c 2', 'font-size: 22px; color:orange');
    let data = yield fireApi('GetAdminProjectChatClient')
    // console.log(data);
      if (data.status === "1") {
        let Project = data.project;
        yield put({ type: 'GET_ADMIN_PROJECT_CHAT', values: Project });
      }
    } catch (error) {
      throw error;
    }
  }

export function* loadArtistAndClient(values) {
    let value = values.payload
    try {
    // console.log('%c LOAD ARTISTS AND CLIENT', 'font-size: 22px; color:orange');
    // console.log(value);
    
    let data = yield fireApi('GetArtistAndClient', {id: value.id})
    // console.log(data);
    
      if (data.status === "1") {
        // let Project = data.project;
        yield put({ type: 'UPDATE_ARTIST_AND_CLIENT', values: {Artist: data.creator_data, Client: data.client_data, ProjectName: value.name} });
      }
    } catch (error) {
      throw error;
    }
  }

  export function* LoadAdminChatMessages(values) {
    // console.log('%c LoadAdminChatMessages', 'font-size: 22px; color:orange');
    try {
      let a = values.payload; 
      const data = yield fireApi('GetAdminChatMessages',{id:a.chat_connection_id});
      // console.log("data555",a)
      // console.log('%c ADMIN CHAT', 'font-size: 22px; color:orange');
      // console.log(data);
      
      yield put({ type: 'UPDATE_CHANGE_ADMIN_CHAT',  values: {
        UserToken: a.admin_token,
        ProjectId: a.post_project_id,
        CurrentChat: a
      } });
      if (data.status === "1") {
        let List = data.user_chat;
        yield put({ type: 'UPDATE_CHANGE_ADMIN_CHATMESSAGE', values: List });
      // console.log('%c 1', 'font-size: 22px; color:orange');
        
      }
    } catch (error) {
      throw error;
    }
  }