import React, { Component } from 'react';
import { connect } from 'react-redux';
import MessagesTemplate from './MessageTemplate';

class Messages extends Component {
  scrollToBottom = () => {
    if (this.el)
      this.el.scrollIntoView(false,{
        behavior: 'smooth',
      });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentDidMount(){
    this.scrollToBottom();
  }

  defaultMsg = () => {
    const { CurrentChat, Me, proposal, ProjectName } = this.props;
    // console.log(proposal);
    return {
      _id: '60c8c3030a60d11', 
      admin_id: null,
      chat_connection_id: CurrentChat.chat_connection_id,
      client_text: "",
      createdAt: proposal ? proposal.createdAt : '',
      creator_text: "",
      data: [],
      event: "data",
      final_link: "",
      islast: 0,
      last_seen: null,
      links: "",
      milestone_complete_id: null,
      milestone_id: null,
      milestone_serial_number: null,
      milestone_serial_number_text: "",
      note: "<p>Your proposal is submitted and is under review.</p>",
      post_project_id: proposal ? proposal.post_project_id : '',
      project_name: ProjectName,
      proposal_id: proposal ? proposal._id : '',
      receiver_id: CurrentChat.user_id,
      receiver_id1: null,
      receiver_name: "",
      reminder_status: 0,
      sender_id: Me.Id,
      sender_name: "",
      status: 3,
      sub_text: "Project Proposal Under Review",
      sub_type: "",
      text: "submitted a proposal",
      time: proposal ? proposal.createdAt : '',
      type: "milestones_review",
      unread_msg_email: 1,
      unread_msg_email1: 1,
      updatedAt: proposal ? proposal.updatedAt : '',
      user_type: "user",
    }
  }

  render() {
    const { Messages, UserId, CurrentChat, Me,UserToken, ProjectStatus, milestones } = this.props;
    const messageList = Messages.length ? Messages : Me.CurrentRole === 2 ? [this.defaultMsg()] : []
    const addTabNoneTag = (ProjectStatus === 6 || ProjectStatus === 7 || ProjectStatus === 5) && milestones && milestones.length > 0 ? '' : 'tab-none';
    const clientData = this.props.Artist.find(m=>m.role==1)
    return (
      <div className="card-wrapper bg-white">
        <div className={` ${addTabNoneTag}`}>
          {messageList && messageList.length > 0
            &&
            messageList.map((m, k) => {
              if(m.type === 'reminder_milestone' && (Me.CurrentRole === 1 || Me.CurrentRole == 3)){
                return null
              }
              return (
                <div className="card-wrapper" key={k}>
                  <div className="card-body px-2 position-relative mob-px-15">
                    <div className="custom_form">
                      <div className="card-wrapper mb-10 mt-3">
                        <div className="row m-0 d-flex">
                          <MessagesTemplate clientData={clientData} Data={m} UserId={UserId} CurrentChat={CurrentChat} Me={Me} UserToken = {UserToken} msgId={this.props.msgId} {...this.props}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            
          }
          <div ref={el => { this.el = el; }}>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  const postProject = store.postProject
  return {
    Me: globalReducer.Me,
    Messages: chat.Messages,
    UserId: chat.UserId,
    CurrentChat: chat.CurrentChat,
    UserToken: chat.UserToken,
    ProjectName: chat.ProjectName,
    proposal: postProject.proposal,
    
    // ProjectType: chat.ProjectType,
    // Artist: chat.Artist,    
    // ProjectStatus: chat.ProjectStatus,
    // proposalStatus: postProject.proposalStatus,    
    // milestones: postProject.milestones,
  };
};
const mapDispatch = dispatchEvent => ({});
export default connect(mapState, mapDispatch)(Messages);
