import React, { useEffect, useState } from "react";
import { putAPI } from "../../../../../utils/API";
import { separateNumber } from "../../../../../utils/Helperfunctions";
import { addHrMinDate } from "../CommonFunctionsNew/CommonFunctionsNew";
import { CommonRejectionPopup } from "./CommonRejectionPopup";
import TerminateProject from "./TerminateProject";

const creatorCheckboxList = [
  { text: "Personal reasons." },
  { text: "I am too busy to complete this project." },
  { text: "Client is unresponsive." },
  { text: "Client is refusing to cooperate or make my payments." },
  { text: "Client wants me to end the project." },
  { text: "Other" },
];
const clientCheckboxList = [
  { text: "I am not satisfied with the creator's work." },
  { text: "The creator is not responsive." },
  { text: "The creator missed a deadline." },
  { text: "I have decided to cancel the project altogether." },
  { text: "Other" },
];
export const TerminateProjProcess = ({ terminalData, ...props }) => {
  const [rejectStates, setRejectStates] = useState({
    reason: [],
    description: "",
  });
  const [terminateMileCompPop, setTerminateMileCompPop] = useState("");
  const [actualRetainerData, setActualRetainerData] = useState({
    copyAmount: "",
    amount: "",
    num_hour: 0,
    validAmount: false,
    validHours: false,
  });
  const [rejectLoader, setRejectLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [mileData, setMileData] = useState([]);
  const [mileDataRetainer, setMileDataRetainer] = useState([]);
  const [project_end_date, setProjectEndDate] = useState("");
  const [ref_project_end_date, setRefProjectEndDate] = useState(new Date());
  const [editTotalPay, setEditTotalPay] = useState(false);
  const [checkDate, setDate] = useState({
    minDate: "",
    maxDate: "",
  });
  const [note, setNote] = useState("");

  const type = props?.isEdit
    ? terminalData?.project_proposal_type && terminalData.project_proposal_type.toLowerCase()
    : terminalData?.type && terminalData.type.toLowerCase();

  useEffect(() => {
    setTerminateMileCompPop(props?.isEdit ? 1 : 2);
    if (terminalData) {
      if (props?.isEdit) {
        handleAllData(terminalData);
      } else {
        if (type == "fixed") {
          handleDataFunction(terminalData);
        } else {
          handleDataFunActualRet(
            type == "actual" ? terminalData?.proposalData?.milestoneActual : terminalData?.proposalData?.milestoneRetainer,
            type
          );
        }
      }
    }
  }, []);

  const handleAllData = (terminalData) => {
    if (type == "fixed") {
      let filterArr = terminalData?.milestone_payment.map((item, i) => {
        return {
          ...item,
          names: item?.names,
          show: true,
          payable: item?.payable,
          payable_tax: item?.payable_tax,
          payable_amount: item?.original_price,
          completion_status: item?.completion_status,
          validPayable: false,
          index: item?.index,
        };
      });
      setMileData(filterArr ? filterArr : []);
      setNote(terminalData?.note);
    } else {
      setProjectEndDate(terminalData?.project_end_date ? new Date(terminalData?.project_end_date) : "");
      setDate({
        minDate: terminalData?.min_date,
        maxDate: terminalData?.max_date,
      });
      let amount = terminalData?.total_payable;
      let num_hour = type == "retainer" && props?.retainer_fees_type === "Hourly" ? terminalData?.number_of_hours : 0;
      setActualRetainerData({ ...actualRetainerData, copyAmount: amount, amount, num_hour });
      setNote(terminalData?.note);
    }
  };

  const handleDataFunction = (terminalData) => {
    const delivArr = terminalData?.payments.map((item, i) => {
      return {
        ...item,
        names: item?.names.filter((obj) => obj.check && !obj.isDisable),
        show: true,
        payable: item?.breakup,
        payable_tax: item?.breakup * 0.18,
        payable_amount: item?.breakup,
        completion_status: { label: "Fully Done", value: "Fully Done" },
        validPayable: false,
        index: i,
      };
    });
    let filterArr = delivArr.filter((obj) => obj.status != 2);
    setMileData(filterArr ? filterArr : []);
  };

  const handleDataFunActualRet = (data, type) => {
    // console.log("data == 4444", data);
    let filterArr = [];
    if (type == "actual") filterArr = data?.filter((obj) => obj?.status != 2);
    else filterArr = data.filter((obj) => obj?.type !== "token" && obj?.status != 2);
    let date = filterArr.length > 0 ? new Date(filterArr[0]?.start_date) : new Date();
    let max_date = filterArr.length > 0 ? new Date(filterArr[filterArr.length - 1]?.end_date) : "";
    setProjectEndDate(date);
    setDate({
      minDate: date,
      maxDate: max_date,
    });
    let amount = 0;
    if (type == "actual") {
      filterArr.forEach((item) => {
        item.deliverable.forEach((element) => {
          amount += element.price;
        });
      });
    } else {
      amount = terminalData?.proposalData?.retainer_fees;
    }

    setActualRetainerData({ ...actualRetainerData, copyAmount: amount, amount });
  };

  const handleRejectSubmit = () => {
    handleSubmitReject();
  };

  const validationPayable = () => {
    let valid = true;
    mileData.forEach((item) => {
      if (item.payable < 1) valid = false;
      item.validPayable = item.payable < 1 ? true : false;
    });
    setMileData([...mileData]);
    return valid;
  };

  const validationActualRetainer = () => {
    let valid = true;
    if (actualRetainerData.num_hour < 1 && type == "retainer" && props?.retainer_fees_type === "Hourly") valid = false;
    if (editTotalPay && actualRetainerData.amount < 1) valid = false;
    if (type == "retainer" && props?.retainer_fees_type === "Hourly")
      actualRetainerData.validHours = actualRetainerData.num_hour < 1 ? true : false;
    actualRetainerData.validAmount = editTotalPay && actualRetainerData.amount < 1 ? true : false;
    setActualRetainerData({
      ...actualRetainerData,
    });
    return valid;
  };

  const handleTerminalSubmit = async () => {
    setLoad(true);
    let payable_all = 0,
      payable_amount_all = 0,
      payable_tax_all = 0,
      number_of_hours_all = "";
    if (type == "fixed") {
      mileData.map((m) => {
        payable_tax_all += ~~parseInt(m.payable_tax);
        payable_all += ~~parseInt(m.payable);
        payable_amount_all += ~~parseInt(m.payable) + ~~parseInt(m.payable_tax);
      });
    } else {
      if (type == "retainer" && props?.retainer_fees_type === "Hourly") number_of_hours_all = actualRetainerData.num_hour;
      payable_tax_all = ~~parseInt(actualRetainerData.amount) * 0.18;
      payable_all = ~~parseInt(actualRetainerData.amount);
      payable_amount_all = parseInt(payable_all) + parseInt(payable_tax_all);
    }
    const dataIs = {
      reasons: rejectStates.reason,
      reasons_note: rejectStates.description,
      note: note,
      post_project_id: props.projectDataStates.id,
      proposal_id: props.proposalId,
      total_payable: payable_all,
      total_payable_tax: payable_tax_all,
      total_payable_with_tax: payable_amount_all,
      milestone_payment: mileData,
      project_end_date: type == "fixed" ? "" : addHrMinDate(project_end_date,5,30),
      number_of_hours: type == "fixed" ? "" : number_of_hours_all,
      min_date: type == "fixed" ? "" : checkDate.minDate,
      max_date: type == "fixed" ? "" : checkDate.maxDate,
    };
    // console.log("dataIs === ", dataIs);
    try {
      let res = await putAPI(`/proposal/project-termination`, dataIs);
      if (res) {
        setLoad(false);
        props.changeTerminatePopup(false);
        if (props?.isEdit) {
          props.handleTerminationData();
        } else {
          props.history.push(`/projects/${props.projectDataStates.id}/overview`);
        }
      } else {
        setLoad(false);
        alert("Something went wrong please try again later");
      }
    } catch (err) {
      setLoad(false);
      alert("Something went wrong please try again later");
    }
  };

  const handleSubmitReject = () => {
    setTerminateMileCompPop(1);
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeTerminatePopup(false);
    }
  };

  let checkboxData = props.CurrentRole === 2 ? creatorCheckboxList : clientCheckboxList;

  return (
    <>
      {!terminateMileCompPop ? null : terminateMileCompPop === 1 ? (
        <TerminateProject
          changeTerminatePopup={props.changeTerminatePopup}
          showTerminatePopup={props.showTerminatePopup}
          handleTerminalSubmit={handleTerminalSubmit}
          proposalData={props.proposalData}
          mileDataRetainer={mileDataRetainer}
          mileData={mileData}
          setMileDataRetainer={setMileDataRetainer}
          setMileData={setMileData}
          validationPayable={validationPayable}
          type={type}
          setNote={setNote}
          note={note}
          load={load}
          project_end_date={project_end_date}
          ref_project_end_date={ref_project_end_date}
          setProjectEndDate={setProjectEndDate}
          actualRetainerData={actualRetainerData}
          checkDate={checkDate}
          setActualRetainerData={setActualRetainerData}
          editTotalPay={editTotalPay}
          setEditTotalPay={setEditTotalPay}
          validationActualRetainer={validationActualRetainer}
          {...props}
        />
      ) : (
        <CommonRejectionPopup
          heading="Select Reasons For Termination"
          checkboxData={checkboxData}
          rejectStates={rejectStates}
          setRejectStates={setRejectStates}
          changeCommonReject={props.changeTerminatePopup}
          showCommonReject={props.showTerminatePopup}
          handleRejectSubmit={handleRejectSubmit}
          submitButtonName="Proceed Termination"
          rejectLoader={rejectLoader}
        />
      )}
    </>
  );
};
