import React from 'react'
import { removeHttps } from './../../../../utils/Helperfunctions'

export default function RandomUrlPreview({url, className=''}) { 
    return (
        <div className={`upload_url ${className}`}>
            <div className="font-weight-800 font-18 text-dark-black d-block my-3" >
                External <span className="text-uppercase">Url</span> 
                <a className="ml-2" href={`http://${removeHttps(url)}`} target="_blank" rel="noopener noreferrer">
                 <i className="fas fa-external-link-alt font-16 text-success"></i> 
                </a>
            </div>
            <div className="font-weight-600">
                <a onClick={e => e.stopPropagation()} href={`http://${removeHttps(url)}`} target="_blank" rel="noopener noreferrer">
                    <span className="ext-url text-primary">{url}</span>
                </a>
            </div>
            {/* <div>
                <a onClick={e => e.stopPropagation()} href={`http://${url}`} target="_blank" className="main-btn text-white mob-font-13 font-12" value>Visit website</a>
            </div>  */}
        </div>
    )
}
