import React from 'react';
import { connect } from 'react-redux';

class EndProject extends React.Component {
  closePopup = () => {
    this.props.closePopup()
  }

  // <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />

  render() {
    return (
      <>
        <div className="modal fade custom_form verify_modal edit-basic-profile show" style={{
          paddingRight: "0px !important",
          display:"block",
          opacity:"1"
        }} id="closeProjectConfirmation" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0 mobile-hide">
                    <div className="profile-creator ml-05 mob-m-0">
                      <div className="py-20 px-40 text-center">
                        <h2 className="mt-0 font-weight-500 mb-0 pt-2 font-18 line-height-14 mob-font-14">Are you sure want to close the project?</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mobile-show bg-shadow">
                    <div className="mob-m-0 text-left">
                      <div className="mob-p-15">
                        <h2 className="mt-0 font-weight-500 mb-1 pt-1 font-16 mob-font-18 line-height-14">Are you sure you want to close the project?</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project">
                    <div className="custom_form px-2 mob-p-0">
                      <form method="post" action="">
                        <div className="form-group text-left pl-3 pt-4 mob-pr-0 mob-mb-30">
                          <p className="font-16 font-weight-500 line-height-14">
                            We're sad to see you go away. You'll have to clear any pending dues in order to close the project.
								          </p>
                          <button onClick={() => this.props.OpenPopup('EndProjectReason')} type="button" className="main-btn my-2 font-14 py-12 mob-w-100 bg-light-gray font-weight-600">Confirm and close</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  return {
    closeModal: creatorProfile.closeModal,
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
});

export default connect(mapState, mapDispatch)(EndProject);
