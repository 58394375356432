import React, { useEffect, useState, useRef } from "react";
import DotImage from "../../../../../../Assets/images/vdot.svg";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import HeaderIcon from "../../../../../../Assets/upgrade-images/submit_proposal/header_icon.svg";
import PercentIcon from "../../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import EditIcon from "../../../../../../Assets/upgrade-images/submit_proposal/edit.svg";
import ExclamationIcon from "../../../../../../Assets/upgrade-images/submit_proposal/info-circle.svg";
import DateIcon from "../../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import ReviewProposal from "../ReviewProposal";
import Reference from "../Reference/Reference";
import Deliverables from "./Deliverables";
import PaymentTerms from "./PaymentTerms";
import { postAPI, getAPI } from "../../../../../../utils/API";
import DatePicker from "react-date-picker";
import SuccessProp from "../../SuccessProp";
import { separateNumber } from "../../../../ProfileCreator/TimeRetainer";
import ProjectDetail from "../../CommonComp/ProjectDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  releventProjects,
  relevent_projects,
  projectAttachments,
  projectUrls,
} from "../../../../../../store/action/proposal.action";
import SubmitProp from "../../PopopCreator/SubmitProp";
import { addHrMinDate, convertToPlain, GetpaymenTermsData, onkeyCode, setTimeToZero } from "../../CommonFunctionsNew/CommonFunctionsNew";
import Loader from "../../../../../authorized/Common/Common/Loader";

const FixedProposal = (props) => {
  const [states, setStates] = useState({
    deliverable: [
      {
        name: "",
        dueDate: "",
        iterations: 1,
        toggle: false,
        check: false,
        isDisable: false,
        iteration_charge: "",
        validation: { name: false, dueDate: false, iteration_charge: false },
        status: 0,
        paid_status: 0,
        milestonecomplete: [],
        id: "",
        restrict: false,
      },
    ],
    payments: [
      {
        names: [],
        breakup: "",
        toggle: false,
        validation: { name: false, price: false },
        _id: "",
        restrict: false,
      },
    ],
    copyDeliverable: [],
    advanceFees: { name: "Advance Fees", breakup: "", show: false, status: 0 },
    note: "",
    validate: { note: false, proposed_start_date: false, advanceFees: false },
    proposed_start_date: setTimeToZero(new Date()),
    checkPayError: false,
  });
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showReviewProp, setShowReviewProp] = useState(false);
  const [showSuccessProp, setShowSuccessProp] = useState(false);
  const [id, setId] = useState("");
  const _ProjectsIs = useSelector((state) => state.releventProjects);
  const dispatch = useDispatch();
  const rt1 = useRef(null);

  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: data?.proposal_type === "fixed" ? current_ids : [],
      releventProject: data?.proposal_type === "fixed" ? data?.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.proposal_type === "fixed" ? data?.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.proposal_type === "fixed" ? data?.urls : []));
  };

  useEffect(() => {
    setId(props?.id);
    if (props?.data?.project_data) handleGetProposal(props?.id);
  }, [props?.data]);

  const handleGetProposal = async (id) => {
    try {
      let res = "";
      let projectStatus = props?.data?.project_data?.status;
      if (projectStatus == 6 || projectStatus == 7) {
        res = await getAPI(`/proposal/proposal-latest-approved-data?id=${id}`);
      } else {
        res = await getAPI(`/proposal/proposal-payment?id=${id}`);
      }
      if (res && res?.data && res?.data?.AllProposal?.proposal_type === "fixed") {
        let data = res?.data?.AllProposal;
        getReferenceData(data);
        let arrDel = [];
        data?.deliverables &&
          data.deliverables.forEach((item) => {
            if ((item?.name != "advance_payment" || item?.type != "token") && data?.proposal_type === "fixed") {
              arrDel.push({
                name: item?.name,
                dueDate: new Date(item?.due_date),
                iterations: item?.iteration,
                toggle: item?.iteration_charge ? true : false,
                check: false,
                isDisable: false,
                iteration_charge: item?.iteration_charge ? item.iteration_charge : "",
                validation: {
                  name: false,
                  dueDate: false,
                  iteration_charge: false,
                },
                status: item?.status,
                milestonecomplete: item?.milestonecomplete?.length > 0 ? item.milestonecomplete : [],
                paid_status: item?.paid_status ? item.paid_status : 0,
                id: item?._id ? item._id : "",
                restrict: item?.restrict,
              });
            }
          });

        let obj = GetpaymenTermsData(data?.payment_terms, arrDel);
        let arrPay = obj?.arrPay;
        let advanceFees = obj?.advanceFees;

        // console.log("arrDel,arrPay ",arrPay)

        var tempNote = data?.note ? convertToPlain(data.note)  : "" 

        setStates({
          ...states,
          deliverable:
            arrDel?.length > 0
              ? arrDel
              : [
                  {
                    name: "",
                    dueDate: new Date(new Date().setDate(new Date().getDate() + 1)),
                    iterations: 1,
                    toggle: false,
                    check: false,
                    isDisable: false,
                    iteration_charge: "",
                    validation: {
                      name: false,
                      dueDate: false,
                      iteration_charge: false,
                    },
                    restrict: false,
                  },
                ],
          payments: arrPay,
          copyDeliverable: JSON.parse(JSON.stringify(arrDel)),
          proposed_start_date: data?.proposed_start_date ? setTimeToZero(data?.proposed_start_date) : setTimeToZero(new Date()),
          note: tempNote,
          advanceFees,
        });
        setLoading(false);
      } else {
        setLoading(false);
        getReferenceData([]);
      }
    } catch (err) {
      console.log("error");
      setLoading(false);
      getReferenceData([]);
    }
  };

  const handleTypeChange = () => {
    props.changeShowSubmitProposal(true);
  };

  const validationDeliverable = () => {
    let valid = true;
    states.deliverable.forEach((item) => {
      if (!item.name.length > 0 || !item.dueDate || (!item.iteration_charge && item.toggle)) valid = false;
      item.validation.name = item.name.length > 0 ? false : true;
      item.validation.dueDate = item.dueDate ? false : true;
      if (item.toggle) {
        item.validation.iteration_charge = item.iteration_charge < 1 ? true : false;
      }
    });
    setStates({ ...states });
    return valid;
  };

  const scroll = (action) => {
    return rt1?.current?.scrollIntoView({
      behavior: "smooth",
      block: action,
      inline: "start",
    });
  };

  const validationPayment = () => {
    let valid = true;
    // console.log("states.payments == ", states.payments);
    states.payments.forEach((item) => {
      let filter = item.names.filter((obj) => obj.check && !obj.isDisable);
      if (!filter.length > 0 || item.breakup < 1) valid = false;
      item.validation.name = filter.length > 0 ? false : true;
      item.validation.price = item.breakup < 1 ? true : false;
    });
    setStates({ ...states });
    return valid;
  };

  const addDays = (date, day) => {
    var result = new Date(date);
    result.setDate(result.getDate() + day);
    return result;
  };

  const handleChangeNote = (e, action) => {
    if (action === "note") {
      const { value } = e.target;
      if (value.length <= 1000) {
        states.note = value;
      }
    } else if (action === "startDate") {
      states.proposed_start_date = new Date(e);
      states.validate.proposed_start_date = e ? false : true;
    }
    setStates({ ...states });
  };

  const validationExtra = () => {
    let valid = true;
    if (!states.proposed_start_date || (states.advanceFees.breakup < 1 && states.advanceFees.show)) valid = false;
    states.validate.advanceFees = states.advanceFees.breakup < 1 && states.advanceFees.show ? true : false;
    states.validate.proposed_start_date = states.proposed_start_date ? false : true;
    setStates({ ...states });
    return valid;
  };

  const handleSubmitApi = async () => {
    setLoader(true);
    let arrDel = [
      {
        due_date: "",
        iteration: 0,
        iteration_charge: 0,
        name: "advance_payment",
        type: "token",
        status: states?.advanceFees?.status ? states.advanceFees.status : 0,
      },
    ];
    states.deliverable.forEach((item) => {
      arrDel.push({
        due_date: addHrMinDate(item.dueDate, 5, 30),
        iteration: item.toggle ? item.iterations : 0,
        iteration_charge: item.toggle ? item.iteration_charge : 0,
        name: item.name,
        type: "milestone",
        status: item.status,
        id: item?.id ? item.id : "",
        restrict: item?.restrict ? item.restrict : false,
      });
    });

    let arrPay = [
      {
        price: states.advanceFees.breakup,
        milestone_reference: [{ index: 0, label: states.advanceFees.name }],
        status: states?.advanceFees?.status ? states.advanceFees.status : 0,
        type: "token",
      },
    ];
    states.payments.forEach((obj) => {
      let refArr = [];
      obj.names.forEach((object, i) => {
        if (object.check && !object.isDisable) refArr.push({ index: i + 1, label: onkeyCode(i + 65) });
      });
      arrPay.push({
        price: obj.breakup,
        milestone_reference: refArr,
        status: obj?.status ? obj?.status : 0,
        type: "milestone",
        _id: obj?._id,
        restrict: obj?.restrict ? obj.restrict : false,
      });
    });

    let data = {
      post_project_id: id,
      proposal_type: "fixed",
      payment_terms: arrPay,
      deliverables: arrDel,
      project_ids: _ProjectsIs?.project_ids,
      link_image: _ProjectsIs?.link_image,
      urls: _ProjectsIs?.urls,
      note: states.note,
      proposed_start_date: addHrMinDate(states.proposed_start_date, 5, 30),
      total_cost: gst_total_price,
      sub_total_cost: total_price,
      total_earning: totalEarning,
      advance_amount: states.advanceFees?.breakup ? states.advanceFees?.breakup : 0,
    };

    // console.log("data = ", data)

    try {
      let path = props.location.pathname.includes("jobs") ? "/milestone/add-outside-proposals" : "/proposal/proposal-submit";
      const res = await postAPI(path, data);
      if (res?.data) {
        setLoader(false);
        setShowReviewProp(false);
        setShowSuccessProp(true);
        dispatch({ type: "GET_JOBS", payload: "" });
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };

  const handleReviewCheck = () => {
    let payError = false;
    let valid1 = validationExtra();
    let valid2 = validationPayment();
    let valid3 = validationDeliverable();
    if (!valid2 && valid3) scroll("center");
    if (valid2 && !valid3) scroll("end");
    if (!(states.payments?.[0]?.names?.filter((item) => !item.check).length == 0)) payError = true;
    setStates({ ...states, checkPayError: payError });
    return valid1 && valid2 && valid3 && !payError ? true : false;
  };

  const onClickBack = () => {
    const { location, history, id, data } = props;
    if (history && data.project_data.project_slug) {
      if (location.pathname.includes("jobs")) {
        dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "IncomingBriefReviewJob" } });
        history.push(`/dashboard/jobs/:${id}/${data.project_data.project_slug}`);
      } else {
        history.push(`/projects/${props?.match?.params?.id}/overview`);
      }
    }
  };

  let total_price = 0;
  states.payments.forEach((item) => {
    total_price += Number(item.breakup);
  });
  total_price += states?.advanceFees?.breakup && states?.advanceFees?.show ? Number(states?.advanceFees?.breakup) : 0;
  let gst_total_price = 0;
  gst_total_price = Number(total_price + total_price * 0.18);

  let totalEarning = 0;
  totalEarning = Number(total_price * 0.85);
  const dateimg = <img src={DateIcon} />;
  return (
    <>
      <section className="submit_proposal_fixed_cost main_page_container">
        <div className="row submit_proposal_container mx-0">
          {/* top_heading */}
          <div className="col-lg-12 pb-4">
            <div className="top_header d-flex align-items-center mob-justify-content-between">
              <div className="heading_icon">
                <img
                  src={HeaderIcon}
                  className="img-fluid cursor-pointer pr-4"
                  // onClick={() => (props?.history?.goBack() ? props.history.goBack() : "")}
                  //     onClick={() => props.location.pathname.includes('jobs')?
                  //     props?.history?.push(`/dashboard/jobs`)
                  //  :  props?.history?.push(`/projects/${props?.match?.params?.id}/overview`)
                  //  }
                  onClick={() => (props.data?.project_data?.project_slug ? onClickBack() : "")}
                />
                <span className="submit_proposal_heading mb-0">Submit Proposal</span>
              </div>
              <div class="dropdown dropleft d-block d-lg-none">
                <img src={DotImage} className="dropdown-toggle" data-toggle="dropdown" />
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    View Brief
                  </a>
                  <a class="dropdown-item" href="#">
                    Learn Best Practices
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* left_side */}
          <div className="col-lg-9">
            {/* page_card */}
            <div className="page_card">
              <div className="proposal_header br_btm d-flex justify-content-between">
                <div className="prposal_heading d-flex align-items-center">
                  <p className="mb-0 mr-3 text_black_lg_600">Your Proposal</p>
                  <div className="tag_btn_common">
                    <p className="mb-0 blue_text_sm_w-600">
                      <img src={PercentIcon} className="icon_img mr-1" />
                      Fixed Scope
                    </p>
                  </div>
                </div>

                {loading || states?.advanceFees?.status == 2 || props?.data?.project_data?.status == 6 ? null : (
                  <div className="edit_proposal d-flex align-items-center" onClick={handleTypeChange}>
                    <p className="mb-0 mr-3 edit_proposal_text anchor_text_sm_w_700 d-none d-lg-block">Edit Proposal Type</p>
                    <img src={EditIcon} className="icon_img mr-1" />
                  </div>
                )}
              </div>

              {loading ? (
                <Loader isCreateModal={true} style={{ paddingTop: "15rem", paddingBottom: "15rem" }} />
              ) : (
                <>
                  <div className="proposal_date px-4 pt-4 d-block d-lg-flex justify-content-between align-items-center">
                    <div className="proposal_date_title pb-2">
                      <p className="mb-0 font_semi_bold_w_600">Proposed start date</p>
                      <p className="proposal_date_sub_title">Estimated Beginning Date</p>
                    </div>
                    <div className="date_input pb-2">
                      <DatePicker
                        value={states.proposed_start_date}
                        className="form-control text-center input_sm"
                        placeholder="dd/mm/yy"
                        clearIcon={null}
                        name="startDate"
                        format="dd/MM/yyyy"
                        calendarIcon={dateimg}
                        calendarClassName="apniClass"
                        showLeadingZeros={true}
                        onChange={(e) => handleChangeNote(e, "startDate")}
                        minDate={setTimeToZero(new Date())}
                        maxDate={
                          states?.deliverable?.length && states?.deliverable[0].dueDate ? states?.deliverable[0].dueDate : ""
                        }
                        // disabled={props?.data?.project_data?.status == 6 || (states?.deliverable?.length && states?.deliverable[0].dueDate) ? true : false}
                      />
                      {states.validate.proposed_start_date ? <p className="error_message">field required</p> : ""}
                    </div>
                  </div>
                  <Deliverables
                    states={states}
                    setStates={setStates}
                    validationDeliverable={validationDeliverable}
                    addDays={addDays}
                    ref={rt1}
                  />
                  <PaymentTerms
                    states={states}
                    setStates={setStates}
                    validationPayment={validationPayment}
                    ref={rt1}
                    scroll={scroll}
                  />
                  <div ref={rt1}></div>

                  {/* Reference *************************************** */}

                  <Reference />

                  {/* Reference *************************************** */}

                  {/* add notes */}
                  <div className="row p-4 add_notes mx-0 mb-3">
                    <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                      <p className="mb-0 text_black_md">Additional Notes</p>
                      <div className="d-block d-lg-none">
                        <img
                          src={FeaturedToggleImage}
                          className="icon_img mr-1 cursor-pointer"
                          data-toggle="collapse"
                          href={`#collapseNotes`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`collapseNotes`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 px-0">
                      <div className="row mx-0 collapse show " id={`collapseNotes`}>
                        <div className="col-lg-12 px-0 py-4">
                          <textarea
                            className={`form-control upload_notes`}
                            rows="5"
                            placeholder="Please add any additional notes, comments, or explanations here. Please note that any proposals with email IDs or phone numbers will be rejected."
                            name="note"
                            value={states?.note}
                            onChange={(e) => handleChangeNote(e, "note")}
                          />
                          {/* {states.validate.note ? <p className="error_message">field required</p> : ""} */}
                          <span className="font-weight-500 text-secondary">{states?.note?.length}/1000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* right_side */}
          <div className="col-lg-3">
            {/* card1 */}
            <div className="sticky-top">
              <div className="page_card mb-4">
                <div className="proposal_header  br_btm d-none d-lg-block">
                  <div className="prposal_heading d-flex align-items-center justify-content-between">
                    <p className="mb-0 text_black_700_w_600">Payment Summary</p>
                  </div>
                </div>
                <a className="edit_proposal d-block p-3 d-lg-none text-center" data-toggle="collapse" href={`#collapsePayment`} role="button" aria-expanded="false" aria-controls={`collapsePayment`}>
                  <span className=" mb-0 sub_title_commn mr-2">Payment Summary</span>
                  <img
                    src={FeaturedToggleImage}
                    className="icon_img mr-1 cursor-pointer"
                  />
                </a>
                <div className="row mx-0 mb-4 collapse show " id={`collapsePayment`}>
                  <div className="total_project_price col-lg-12">
                    <div className="col-lg-12 px-0 br_btm mb-4">
                      <div className="project_price_title d-flex justify-content-between py-3">
                        <p className="mb-0 font_semi_bold_w_600">Total price of project</p>
                        <p className="mb-0 text_gray text-right">
                          {total_price ? `₹ ${separateNumber(total_price, "")}` : "-"}
                          <span className="d-block text_light_gray_xxs_w_600">+ Applicable Taxes</span>
                        </p>
                      </div>
                      {/* <p className="description w-75">
                    This includes all milestones + taxes, and is the amount your client will see.
                  </p> */}
                    </div>
                    <div className="col-lg-12 mb-4 px-0">
                      <div className="project_price_title d-flex justify-content-between align-items-center line_height_12 mb-3">
                        <p className="mb-0 font_semi_bold_w_600 d-flex align-items-center">
                          You’ll Receive
                          <div
                            class="position-relative tool"
                            data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable 
                      taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                          >
                            <img src={ExclamationIcon} className="img-fluid tool ml-3" />
                          </div>{" "}
                        </p>
                        <p className="mb-0 text_highlight"> {totalEarning ? `₹ ${separateNumber(totalEarning, "")}` : "-"}</p>
                      </div>
                      {/* <p className="description w-75">Your estimated payment, after service fees</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pb-4">
                  <button
                    type="button"
                    className="btn btn-common w-100"
                    onClick={() => {
                      let result = handleReviewCheck() ? setShowReviewProp(true) : "";
                    }}
                  >
                    Review & Confirm
                  </button>
                </div>
              </div>
              {/* card2 */}

              <ProjectDetail data={props?.data} id={props?.id} {...props} proposalSubmit={true} />

              {/* bottom link */}
              {/* <div className="col-lg-12 d-none d-md-block">
              <p className=" mb-0">
                <a className="anchor_text">Learn Best Practices for Proposals </a>
                <img src={ArrowIcon} className="icon_img ml-1" />
              </p>

            </div> */}
            </div>
          </div>
        </div>

        <SubmitProp
          changeShowSubmitProposal={props?.changeShowSubmitProposal}
          id={props?.id}
          {...props}
          showSubmitProposal={props?.showSubmitProposal}
          data={props?.data}
        />
        <ReviewProposal
          showReviewProp={showReviewProp}
          setShowReviewProp={setShowReviewProp}
          handleSubmitApi={handleSubmitApi}
          states={states}
          propType="fixed"
          gst_total_price={gst_total_price}
          totalEarning={totalEarning}
          total_price={total_price}
          loader={loader}
        />
        <SuccessProp showSuccessProp={showSuccessProp} setShowSuccessProp={setShowSuccessProp} propType="fixed" {...props} />
      </section>
    </>
  );
};

export default FixedProposal;
