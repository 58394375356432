import React, { useState } from "react";
import AskQuestion from "../PopopCreator/AskQuestion";
import RejectBrief from "../PopopCreator/RejectBrief";
import CloseProject from "../CommonpPopup/CloseProject";
import SubmitProp from "../PopopCreator/SubmitProp";
import VisionIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/vision_historyicon.png";
import RequestChange from "../PopupClient/RequestChange";
import ApproveAndPay from "../CommonpPopup/ApproveAndPay";
import Loader from "../../../Common/Common/Loader";
import { TerminateProjProcess } from "../CommonpPopup/TerminateProjProcess";
import Warn from "../../../../../Assets/upgrade-images/view_proposal_list/warn.png";

export default function FooterPopup(props) {
  const [showSubmitProposal, setShowSubmitProposal] = useState(false);
  const [showAskQuestion, setShowAskQuestion] = useState(false);
  const [showRejectBrief, setShowRejectBrief] = useState(false);
  const [showCloseProj, setShowCloseProj] = useState(false);
  const [showRequestChange, setShowRequestChange] = useState(false);
  const [showAskButton, setShowAskButton] = useState(true);
  const [showApproveAndPay, setShowApproveAndPay] = useState(false);
  const [showTerminatePopup, setShowTerminatePopup] = useState(false);

  const changeShowSubmitProposal = (value) => {
    setShowSubmitProposal(value);
  };

  const changeTerminatePopup = (value) => {
    setShowTerminatePopup(value);
  };

  const changeShowAskQuestion = (value) => {
    setShowAskQuestion(value);
  };

  const changeShowRejectBrief = (value) => {
    setShowRejectBrief(value);
  };

  const changeShowCloseProj = (value) => {
    setShowCloseProj(value);
  };

  const changeShowRequestChange = (value) => {
    setShowRequestChange(value);
  };

  const changeShowApproveAndPay = (value) => {
    setShowApproveAndPay(value);
  };

  const projectStatus = props?.projectDataStates?.data?.project_data?.status

  return (
    <>
      {props?.projectDataStates?.data?.creator_status_brief != 2 ? (
        <div className="col-12">
          {props?.CurrentRole!==3 ?<div className="d-block d-md-flex align-items-center flex-row justify-content-between px-2">
            <div className="py-3">
              {props?.approveProposal && (
                <div>
                  <img src={Warn} className="mr-2 mb-1" />
                  <span style={{ fontSize: "1rem", color: "#9393AB", weight: 400 }}>Please ensure you check the</span>{" "}
                  <span style={{ fontSize: "1rem", weight: 400 }}>Due Dates</span>
                </div>
              )}
              {props?.showHistory && props?.stateData?.number > 1 && !props?.approveProposal && (
                <>
                  <img src={VisionIcon} className="icon_img mr-1" />
                  <span
                    className="mb-0 mr-3 edit_proposal_text anchor_text"
                    onClick={() =>
                      props.historyTab
                        ? props.handleSetShowHistoryTab(true)
                        : props?.isClient
                          ? props?.history?.push(`/version-history/${props?.id}/${props?.userId}`)
                          : props?.history?.push(`/version-history/${props?.id}`)
                    }
                  >
                    Show version history
                  </span>
                </>
              )}
              {!props?.isClient && props?.showReject && projectStatus < 6 && props?.projectDataStates?.data?.creator_status_brief == 0 && (
                <a class="anchor_text_dander_md_w_700 mr-4" onClick={() => changeShowRejectBrief(true)}>
                  Reject Project
                </a>
              )}

              {props?.showCloseProj && projectStatus < 6 && (
              <a class="anchor_text_dander_md_w_700 mr-4" onClick={() => changeShowCloseProj(true)}>
                Close Project
              </a>
            )}
              {props?.projectDataStates?.data?.project_data?.visible_creator >= 2 && !props?.projectDataStates?.data?.project_data?.were_admin_project &&
                projectStatus < 7 &&
                (props?.projectDataStates?.data?.project_data?.terminate_request == 3 ||
                  props?.projectDataStates?.data?.project_data?.terminate_request == 0)
                ? props?.showTerminateProj && (
                  <a class="anchor_text_dander_md_w_700 mr-4" onClick={() => changeTerminatePopup(true)}>
                    Terminate Project
                  </a>
                )
                : null}
            </div>
            <div className="d-lg-flex align-items-center column_gap_10 mob-pt-3">
              {props?.isClient && props?.showReject && projectStatus < 6 && (
                <a class="anchor_text_dander_md_w_700 d-flex align-items-center mob-w-100 mr-4 py-3" onClick={() => changeShowRejectBrief(true)}>
                  Reject
                </a>
              )}
              {props?.showQuestion && showAskButton && projectStatus < 7 && (
                <button type="button" class="btn btn-common btn_outline_md mob-w-100 mb-2" onClick={() => changeShowAskQuestion(true)}>
                  Ask Questions
                </button>
              )}
              {props?.questionStatus === 5 && projectStatus < 7 && (
                <p class="mob-w-100 text-primary mt-3">
                  Questions sent to client
                </p>
              )}
              {props?.showSubmit && projectStatus < 7 && (
                <button type="button" class="btn btn-common btn_common_md mob-w-100 mb-2" onClick={() => changeShowSubmitProposal(true)}>
                  Submit Proposal
                </button>
              )}
              {props?.isAppRejProp && props?.stateData?.proposalData?.is_incoming_proposal && projectStatus < 7 && (
                <>
                  <a
                    class="anchor_text_dander_md_w_700 mr-4"
                    // onClick={() => changeShowRejectBrief(true)}
                    onClick={() => props.handleApproveApi("reject")}
                  >
                    {props?.loader && props?.loadType == "reject" ? <Loader isCreateModal={true} /> : " Reject Proposal"}
                  </a>
                  <button className="btn btn_common_md mr-4 ml-3" onClick={() => props.handleApproveApi("approve")}>
                    {props?.loader && props?.loadType == "approve" ? <Loader isCreateModal={true} /> : " Approve Proposal"}
                  </button>
                </>
              )}
              {!props?.projectDataStates?.data?.project_data?.were_admin_project && props?.showChanges && projectStatus < 7 && (
                <button
                  type="button"
                  class="btn btn-common btn_common_md mob-w-100 mb-2"
                  onClick={() => props.history.push(`/proposal/${props?.id}/${props?.type ? props?.type : "actual"}/dashboard`)}
                >
                  {props?.approveProposal ? "Edit Proposal" : "Make Changes"}
                </button>
              )}
              {props?.projectDataStates?.data?.project_data?.terminate_request === 2 &&
                projectStatus === 6 ? null : props.CurrentRole == 1 && projectStatus < 7 && props?.showRequest ? (
                  <button className="btn btn_outline_md mob-w-100 mr-4 mb-2" onClick={() => changeShowRequestChange(true)}>
                    Request Changes
                  </button>
                ) : null}
              {props?.showAppPay && projectStatus < 7 && (
                <button className="btn btn_common_md mr-4 mob-w-100 mb-2" onClick={() => changeShowApproveAndPay(true)}>
                  {props?.isOffLine ? "Approve" : "Approve and Pay"}
                </button>
              )}
              {props?.approveProposal && projectStatus < 7 && (
                <button className="btn btn_common_md mr-4 ml-3 mob-ml-0 mob-w-100 mb-2" onClick={() => props.ApiCall(2)}>
                  Approve Proposal
                </button>
              )}
              {/* {props?.approveProposal && projectStatus < 7 && props?.stateData?.proposalData?.is_incoming_proposal && (
                <div>
                  <img src={YellowClock} className="mr-2 mb-1" />
                  <span style={{ fontSize: "1rem", color: "#9393AB", weight: 400 }}>Please wait your edit proposal is in review</span>
                </div>
              )} */}
            </div>
          </div>
          :
            <div
              className="d-block d-md-flex align-items-center flex-row  px-2 py-2 mt-2" >
                <button className="btn btn_common_md mr-4 ml-3 mob-ml-0 mob-w-100 mb-2" onClick={(e) => props.handleApproveProposalAM(e)}>
                  Approve Proposal
                </button>
              </div>}
        </div>
      ) : null}
      {showSubmitProposal ? (
        <SubmitProp
          changeShowSubmitProposal={changeShowSubmitProposal}
          id={props?.id}
          {...props}
          showSubmitProposal={showSubmitProposal}
          data={props?.projectDataStates?.data}
        />
      ) : (
        ""
      )}
      {showAskQuestion ? (
        <AskQuestion
          changeShowAskQuestion={changeShowAskQuestion}
          setShowAskButton={setShowAskButton}
          showAskQuestion={showAskQuestion}
          id={props?.id}
          {...props}
        />
      ) : (
        ""
      )}
      {showRejectBrief ? (
        <RejectBrief
          changeShowRejectBrief={changeShowRejectBrief}
          showRejectBrief={showRejectBrief}
          id={props?.id}
          {...props}
          isClient={props?.isClient}
        />
      ) : (
        ""
      )}

      {showCloseProj ? (
        <CloseProject
          changeShowCloseProj={changeShowCloseProj}
          showCloseProj={showCloseProj}
          id={props?.id}
          {...props}
          isClient={props?.isClient}
        />
      ) : (
        ""
      )}

      {showRequestChange ? (
        <RequestChange
          changeShowRequestChange={changeShowRequestChange}
          showRequestChange={showRequestChange}
          id={props?.id}
          {...props}
          proposalId={props?.proposalId}
        />
      ) : (
        ""
      )}

      {showApproveAndPay ? (
        <ApproveAndPay
          showApproveAndPay={showApproveAndPay}
          changeShowApproveAndPay={changeShowApproveAndPay}
          id={props?.id}
          {...props}
        />
      ) : (
        ""
      )}

      {showTerminatePopup ? (
        <TerminateProjProcess
          showTerminatePopup={showTerminatePopup}
          changeTerminatePopup={changeTerminatePopup}
          isEdit={false}
          id={props?.id}
          terminalData={props?.stateData}
          retainer_fees_type={props?.stateData?.proposalData?.retainer_fees_type}
          {...props}
        />
      ) : (
        ""
      )}
    </>
  );
}
