import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../../utils/API";
import ViewProposalModal from "../../Dashboard/VersionHistory/ViewProposalModal";
import { useDispatch } from "react-redux";
import { relevent_projects, projectAttachments, projectUrls } from "../../../../../store/action/proposal.action";

export default function ChatViewProposal(props) {
    const [data, setData] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.chatPropId) {
            handleApi(props.chatPropId);
        }
    }, []);

    const getReferenceData = (data) => {
        setAllUrls(data);
        setProjectAttachments(data);
        setProjectIds(data);
    };

    const setProjectIds = (data) => {
        const current_ids = [];
        const ids = data?.allProjectIds?.forEach((obj) => {
            return current_ids.push({ _id: obj });
        });

        const dataIs = {
            project_ids: current_ids ? current_ids : [],
            releventProject: data?.allProjectData ? data.allProjectData : [],
        };
        dispatch(relevent_projects(dataIs));
    };

    const setProjectAttachments = (data) => {
        dispatch(projectAttachments(data?.link_image ? data.link_image : []));
    };

    const setAllUrls = (data) => {
        dispatch(projectUrls(data?.urls ? data.urls : []));
    };

    const handleApi = async (id) => {
        setIsLoading(true);
        let res = "";
        try {
            res = await getAPI(`/proposal/get-proposal_data?_id=${id}`);
            if (res) {
                let data = res?.data?.AllProposal;
                setData(data)
                getReferenceData(data);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("error");
        }
    };


    return (
        <ViewProposalModal {...props} isChat={true} isLoading={isLoading} data={data} userId={props?.user_id ? props?.user_id: props?.userId}/>
    );
}
