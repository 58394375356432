import {
  UPDATE_USEREDUCATION,
  UPDATE_USEREDUCATION_RESET,
  UPDATE_USEREDUCATION_SCHOOL,
  UPDATE_USEREDUCATION_COURSE,
  UPDATE_USEREDUCATION_STARTMONTH,
  UPDATE_USEREDUCATION_ENDMONTH,
  UPDATE_USEREDUCATION_STARTYEAR,
  UPDATE_USEREDUCATION_ENDYEAR,
  UPDATE_USEREDUCATION_EDIT,
  UPDATE_USEREDUCATION_ERROR,
  UPDATE_USEREDUCATION_SCHOOL_ERROR,
  UPDATE_USEREDUCATION_COURSE_ERROR,
  UPDATE_USEREDUCATION_STARTMONTH_ERROR,
  UPDATE_USEREDUCATION_ENDMONTH_ERROR,
  UPDATE_USEREDUCATION_STARTYEAR_ERROR,
  UPDATE_USEREDUCATION_ENDYEAR_ERROR,
} from '../type.action';

const initState = {
  edit: -1,
  School: '',
  InstituteImage: '',
  Course: '',
  StartMonth: '',
  EndMonth: '',
  StartYear: '',
  EndYear: '',
  SchoolError: false,
  CourseError: false,
  StartMonthError: false,
  EndMonthError: false,
  StartYearError: false,
  EndYearError: false,
  SchoolErrorMessage: '',
  CourseErrorMessage: '',
  StartMonthErrorMessage: '',
  EndMonthErrorMessage: '',
  StartYearErrorMessage: '',
  EndYearErrorMessage: '',
  ID: '',
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};

  switch (type) {
    case UPDATE_USEREDUCATION:
      newState = Object.assign({}, state, values);
      return newState;
    case UPDATE_USEREDUCATION_EDIT:
      newState = Object.assign({}, state, {
        edit: values,
      });
      return newState;

    case UPDATE_USEREDUCATION_STARTMONTH:
      newState = Object.assign({}, state, {
        StartMonth: values,
      });
      return newState;
    case UPDATE_USEREDUCATION_SCHOOL:
      newState = Object.assign({}, state, {
        School: values,
      });
      return newState;
    case UPDATE_USEREDUCATION_COURSE:
      newState = Object.assign({}, state, {
        Course: values,
      });
      return newState;

    case UPDATE_USEREDUCATION_ENDMONTH:
      newState = Object.assign({}, state, {
        EndMonth: values,
      });
      return newState;
    case UPDATE_USEREDUCATION_STARTYEAR:
      newState = Object.assign({}, state, {
        StartYear: values,
      });
      return newState;
    case UPDATE_USEREDUCATION_ENDYEAR:
      newState = Object.assign({}, state, {
        EndYear: values,
      });
      return newState;

    case UPDATE_USEREDUCATION_ERROR:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_USEREDUCATION_SCHOOL_ERROR:
      newState = Object.assign({}, state, {
        SchoolError: values.error,
        SchoolErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_COURSE_ERROR:
      newState = Object.assign({}, state, {
        CourseError: values.error,
        CourseErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_STARTMONTH_ERROR:
      newState = Object.assign({}, state, {
        StartMonthError: values.error,
        StartMonthErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_ENDMONTH_ERROR:
      newState = Object.assign({}, state, {
        EndMonthError: values.error,
        EndMonthErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_STARTYEAR_ERROR:
      newState = Object.assign({}, state, {
        StartYearError: values.error,
        StartYearErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_ENDYEAR_ERROR:
      newState = Object.assign({}, state, {
        EndYearError: values.error,
        EndYearErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USEREDUCATION_RESET:
      return initState;
    default:
      return state;
  }
};

export default reducers;
