import React, { useState } from "react";
import PercentIcon from "../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import { separateNumber } from "../../ProfileCreator/TimeRetainer";
import CompanyAndProjectQuestions from "../Dashboard/Popup/CompanyAndProjectQuestions";
import StyleAndTypeQuestions from "../Dashboard/Popup/StyleAndTypeQuestions";
import { CollateralBaseUrl } from "../../../../utils/BaseUrl";
import LinkIcon from "../../../../Assets/upgrade-images/submit_proposal/url_link.svg";
import ImportIcon from "../../../../Assets/upgrade-images/submit_proposal/import.svg";
import MoneyIcon from "../../../../Assets/upgrade-images/view_brief/money.svg";
import EmptyIcon from "../../../../Assets/upgrade-images/sidebar_icons/empty_gray.png";
import Loader from "../../Common/Common/Loader";
import ProjectsFooter from "./CommonComp/ProjectsFooter";
import WalletIcon from "../../../../Assets/upgrade-images/view_proposal_fc/wallet.svg";
import MenuBoardIcon from "../../../../Assets/upgrade-images/view_proposal_fc/menu-board.svg";
import {
  convertToPlain,
  downloadFilesFun,
  getDateFormated,
  handleFileName,
} from "./CommonFunctionsNew/CommonFunctionsNew";
import { useSelector } from "react-redux";
import { removeHttps, sanitiseSummernoteText } from "../../../../utils/Helperfunctions";

const ViewBrief = (props) => {
  const data = props?.projectDataStates?.data;
  const loader = props?.projectDataStates?.projectDataloader;
  const CurrentRole = useSelector((state) => state?.globalReducer?.Me?.CurrentRole);
  const isIndividual =
    data?.project_data?.company_work_type?.toLowerCase() === "company" ||
    data?.project_data?.company_work_type?.toLowerCase() === "agency"
      ? false
      : true;

  const refFilesArr = data?.project_data?.project_add_reference_files;
  const refLinksArr = data?.project_data?.project_add_reference_link;
  const [toggle, setToggle] = useState(refFilesArr?.length ? 1 : 2);

  const project_description = data?.project_data?.project_description?.length
    ? sanitiseSummernoteText(data.project_data.project_description.replace(/\n/g, "<br/>"))
    : "";

  const viewCardShow = (imgValue,text,value) => {
    return (
      <div className="card view_proposal_card">
        <div className="card-body px-4 py-4">
          <div className="card_body_inner d-flex">
            <div className="inner_item mr-4">
              <div className="card_icon d-flex">
                <img src={imgValue} alt="" className="icon_img" />
              </div>
            </div>
            <div className="inner_item">
              <p className="mb-0 text_gray">{text}</p>
              <p className="mb-2 text_black_md">
                {value}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`col-lg-${props?.col} px-0`}>
        {loader ? (
          <Loader isCreateModal={true} style={{ paddingTop: "20rem", paddingBottom: "20rem" }} />
        ) : (
          <div className="page_card ">
            <div
              className={`${props?.match?.params?.subroute === "overview" ? "" : "content_scrollbar scroll_bar_body"}`}
            >
              <div className={`proposal_header ${props?.isTab ? "" : "br_btm mb-4"} d-flex justify-content-between`}>
                <div className="prposal_heading d-flex align-items-center">
                  <p className="mb-0 mr-3">
                    {props?.match?.params?.subroute === "proposal_details" ? "Brief Type" : "Project Brief"}
                  </p>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="d-none d-lg-block">
                    {data?.collateral.name ? (
                      <div className="tag_btn_gray_common mr-4" style={{ padding: "10px" }}>
                        <p className="mb-0">{data.collateral.name}</p>
                      </div>
                    ) : null}
                  </div>

                  {data?.project_data?.sub_type &&
                    (data?.project_data?.sub_type == "fixed" ? (
                      <div className="tag_btn_common">
                        <p className="mb-0">
                          <img src={PercentIcon} className="icon_img mr-1" />
                          Fixed Scope
                        </p>
                      </div>
                    ) : data?.project_data?.sub_type == "retainer" ? (
                      // <div>
                      <div className="tag_btn_green_common">
                        <p className="mb-0">
                          <img src={PercentIconGreen} className="icon_img mr-1" />
                          Retainer
                        </p>
                        {/* </div>                
                    <p className="mb-0 text_highlight text_gray_1100_w_600_sm">Duration</p>
                    <p className="font_semi_bold">{data?.project_data?.number_month} {data?.project_data?.number_month > 1  ? ' Months' : ' Month'}</p>
                    <p className="mb-0 text_highlight text_gray_1100_w_600_sm">Weekly Commitment</p>
                    <p className="font_semi_bold">{data?.project_data?.number_hour} {data?.project_data?.number_hour > 1  ? ' Hours/Week' : ' Hour/Week'}</p> */}
                      </div>
                    ) : (
                      <div className="tag_btn_dark_green_common">
                        <p className="mb-0">
                          <img src={PercentIconDarkGreen} alt="" className="icon_img mr-1" />
                          On-Actuals
                        </p>
                      </div>
                    ))}
                </div>
              </div>

              <div
                className={`${
                  props?.match?.params?.subroute === "overview" ? "content_scrollbar scroll_bar_body" : ""
                }`}
              >
                 <div className="row mx-0 px-2 mb-4">
                  <div className="col-sm-12 mb-3 d-block d-lg-none">
                    {data?.collateral.name ? (
                      <div className="tag_btn_gray_common" style={{ padding: "10px" }}>
                        <p className="mb-0">{data.collateral.name}</p>
                      </div>
                    ) : null}
                  </div>
                  {data?.project_data?.were_admin_project && CurrentRole == 2 ? null : (
                    <div
                      className={`col-lg-${
                        (data?.project_data?.sub_type == "retainer" && data?.project_data?.number_hour > 0) || data?.project_data?.unit > 0 ? "4" : "6"
                      } mb-3`}
                    >
                      {viewCardShow(
                        MoneyIcon,
                        "Budget",
                        `₹ ${separateNumber(data?.project_data?.min_budget, "")} - ₹ ${separateNumber(
                          data?.project_data?.max_budget,
                          ""
                        )}`
                      )}
                      {/* <div className="card view_proposal_card">
                        <div className="card-body px-4 py-4">
                          <div className="card_body_inner d-flex">
                            <div className="inner_item mr-4">
                              <div className="card_icon d-flex">
                                <img src={MoneyIcon} alt="" className="icon_img" />
                              </div>
                            </div>
                            <div className="inner_item">
                              <p className="mb-0 text_gray">Budget</p>
                              <p className="mb-2 text_black_md">
                                ₹ {separateNumber(data?.project_data?.min_budget, "")} - ₹{" "}
                                {separateNumber(data?.project_data?.max_budget, "")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )}
                  {data?.project_data?.sub_type == "fixed" || data?.project_data?.sub_type == "retainer" ? (
                    <div
                      className={`col-lg-${
                        (data?.project_data?.sub_type == "retainer" && data?.project_data?.number_hour > 0) || data?.project_data?.unit > 0 ? "4" : "6"
                      }`}
                    >
                      {viewCardShow(
                        MenuBoardIcon,
                        `${data?.project_data?.sub_type == "fixed" ? "Delivery Date" : "Duration (in Months)"}`,
                        ` ${
                          data?.project_data?.sub_type == "fixed"
                            ? getDateFormated(data?.project_data?.project_deadline_date)
                            : `${data?.project_data?.number_month} ${
                                data?.project_data?.number_month > 1 ? "Months" : "Month"
                              }`
                        }`
                      )}
                    </div>
                  ) : null}
                  {data?.project_data?.sub_type == "retainer" && data?.project_data?.number_hour > 0 ? (
                    <div
                      className={`col-lg-${
                        (data?.project_data?.sub_type == "retainer" && data?.project_data?.number_hour > 0) || data?.project_data?.unit > 0 ? "4" : "6"
                      }`}
                    >
                      {viewCardShow(
                        MenuBoardIcon,
                        "Working Hours per Week",
                        `${`${data?.project_data?.number_hour} ${
                          data?.project_data?.number_hour > 1 ? "Hours" : "Hour"
                        }`}`
                      )}
                    </div>
                  ) : null}
                  {data?.project_data?.sub_type == "fixed" && data?.project_data?.unit > 0  ? (
                    <div
                      className={`col-lg-${
                        data?.project_data?.sub_type == "fixed" && data?.project_data?.unit > 0 ? "4" : "6"
                      }`}
                    >
                      {viewCardShow(WalletIcon, "Unit", `${data?.project_data?.unit}`)}
                    </div>
                  ) : null}
                </div>

                {/* Project Description */}
                {data?.project_data?.project_description.length > 0 ? (
                  <div className="row px-4 pb-4  mx-0 ">
                    <div className="col-lg-12 title_commn">
                      <p className="mb-0">Project Description</p>
                    </div>
                    <div
                      className={`col-lg-12 project_description px-2 py-4 ${
                        data.project_data.project_description.length > 0 ? "" : "text-center"
                      }`}
                    >
                      {loader ? (
                        <Loader isCreateModal={true} style={{ padding: "3rem" }} />
                      ) : (
                        <div className="">
                          {data.project_data.project_description.length > 0 ? (
                            <div
                              className="text_gray_xs_w_600"
                              dangerouslySetInnerHTML={{ __html: project_description }}
                            />
                          ) : (
                            <div className="py-4">
                              <img src={EmptyIcon} alt="" className="img-fluid" width="60" />
                              <div className="font-14 font-weight-500 pt-2">No description found ... </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {/*Add References */}
                {refFilesArr?.length > 0 || refLinksArr?.length > 0 ? (
                  <div className="row px-4 pb-4 add_reference  mx-0">
                    <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                      <p className="mb-0">References</p>
                    </div>
                    <div className="col-lg-12 pt-2 px-0">
                      <nav className="mb-3">
                        <div className="nav nav-tabs cstm_tab" id="nav-tab" role="tablist">
                          {refFilesArr?.length > 0 ? (
                            <a
                              className={`nav-item nav-link cstm_tab_item ${
                                refFilesArr?.length ? "refrence_tab_active  active" : ""
                              } d-flex align-items-center`}
                              id="nav-attachment-tab"
                              data-toggle="tab"
                              href="#nav-attachment"
                              role="tab"
                              aria-controls="nav-attachment"
                              aria-selected="true"
                              onClick={() => setToggle(1)}
                            >
                              <p className="mb-0">{refFilesArr?.length > 1 ? "Attachments" : "Attachment"}</p>
                              <div className={`${toggle !== 2 ? "tag_btn_tab_active" : "tag_btn_tab"} ml-3`}>
                                <p className="mb-0"> {refFilesArr?.length > 0 ? refFilesArr.length : 0}</p>
                              </div>
                            </a>
                          ) : null}

                          {refLinksArr?.length > 0 ? (
                            <a
                              className={`nav-item nav-link cstm_tab_item ${
                                !refFilesArr?.length && refLinksArr?.length ? "active" : ""
                              } d-flex align-items-center`}
                              id="nav-contact-tab"
                              data-toggle="tab"
                              href="#nav-contact"
                              role="tab"
                              aria-controls="nav-contact"
                              aria-selected="false"
                              onClick={() => setToggle(2)}
                            >
                              <p className="mb-0"> {refLinksArr?.length > 1 ? "URLs" : "URL"}</p>
                              <div className={`${toggle !== 1 ? "tag_btn_tab_active" : "tag_btn_tab"} ml-3`}>
                                <p className="mb-0"> {refLinksArr?.length > 0 ? refLinksArr.length : 0}</p>
                              </div>
                            </a>
                          ) : null}
                        </div>
                      </nav>

                      {refFilesArr?.length > 0 || refLinksArr?.length > 0 ? (
                        <div className="tab-content" id="nav-tabContent">
                          {/* tab1 */}
                          {refFilesArr?.length > 0 ? (
                            <div
                              className={`tab-pane fade ${refFilesArr?.length > 0 ? "show active" : ""}`}
                              id="nav-attachment"
                              role="tabpanel"
                              aria-labelledby="nav-attachment-tab"
                            >
                              <div className="row">
                                {loader ? (
                                  <Loader isCreateModal={true} style={{ padding: "3rem" }} />
                                ) : refFilesArr?.length > 0 ? (
                                  refFilesArr?.map((item) => {
                                    return (
                                      <div className="col-lg-3 text-center mb-3">
                                        <div className="import">
                                          <p className="mb-0 text_black_xs_w_500">
                                            <span style={{ overflowWrap: "break-word" }}>
                                              {handleFileName(item?.name, 12)}
                                            </span>
                                            <a
                                              href={CollateralBaseUrl + item?.name}
                                              target="_blank"
                                              onClick={() => {
                                                downloadFilesFun(CollateralBaseUrl, item?.name);
                                              }}
                                            >
                                              <img src={ImportIcon} className="icon_img ml-3" />
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="col-sm-12">
                                    <div style={{ padding: "1rem", textAlign: "center" }}>
                                      <span style={{ color: "#6f7d91" }}>No Attachments found ...</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* end cards */}
                            </div>
                          ) : null}
                          {/* tab3 */}
                          {refLinksArr?.length > 0 ? (
                            <div
                              className={`tab-pane fade ${
                                !refFilesArr?.length && refLinksArr?.length ? "show active" : ""
                              }`}
                              id="nav-contact"
                              role="tabpanel"
                              aria-labelledby="nav-contact-tab"
                            >
                              <div className="row px-0 mx-0">
                                {loader ? (
                                  <Loader isCreateModal={true} style={{ padding: "3rem" }} />
                                ) : refLinksArr?.length > 0 ? (
                                  refLinksArr?.map((item, index) => {
                                    return (
                                      <>
                                        {/* <div className="col-lg-12 px-0 py-3">
                                      <p className="url_input mb-0">
                                        <a className="url_input mb-0" href={`http://${removeHttps(item)}`} target="_blank">
                                          <img className="img-fluid mr-3" src={LinkIcon} />
                                          {item}
                                        </a>
                                      </p>
                                    </div> */}
                                        <>
                                          <div
                                            className="col-lg-11 col-10 px-0 mb-2 cursor-pointer"
                                            key={index}
                                            onClick={() =>
                                              window.open(
                                                `http://${removeHttps(item)}`,
                                                "_blank",
                                                "noopener,noreferrer"
                                              )
                                            }
                                          >
                                            <input
                                              className="form-control input_md url_input cursor-pointer"
                                              type="text"
                                              value={item}
                                            />
                                            <div className="input_content d-flex justify-content-between align-items-center cursor-pointer px-3">
                                              <img className="img-fluid" src={LinkIcon} />
                                            </div>
                                          </div>
                                        </>
                                      </>
                                    );
                                  })
                                ) : (
                                  <div className="col-sm-12">
                                    <div style={{ padding: "1rem", textAlign: "center" }}>
                                      <span style={{ color: "#6f7d91" }}>No urls found ...</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* end Add References */}

                {data?.project_project_question?.length ||
                data?.project_style_question?.length ||
                data?.project_type_question?.length ? (
                  <div className="row px-4 pb-4 deliverables mx-0 ">
                    <div className="col-lg-12 title_commn">
                      <p className="mb-0">Project Details</p>
                    </div>

                    <div className="col-lg-12 px-2 py-4">
                      {loader ? (
                        <Loader isCreateModal={true} style={{ padding: "3rem" }} />
                      ) : data?.project_project_question?.length ||
                        data?.project_style_question?.length ||
                        data?.project_type_question?.length ? (
                        <>
                          {data?.project_project_question?.length ? (
                            <CompanyAndProjectQuestions questions={data?.project_project_question} />
                          ) : null}
                          {data?.project_style_question?.length ? (
                            <StyleAndTypeQuestions ques={data?.project_style_question} quesType={"style"} />
                          ) : null}
                          {data?.project_type_question?.length ? (
                            <StyleAndTypeQuestions ques={data?.project_type_question} quesType={"type"} />
                          ) : null}
                        </>
                      ) : (
                        <div className="col-lg-12 mb-3">
                          <div style={{ padding: "1rem", textAlign: "center" }}>
                            <span style={{ color: "#6f7d91" }}>No project summary found ...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* page_bottom_btn */}
            {CurrentRole == 3 ||
            data?.project_data?.status == 7 ||
            data?.project_data?.terminate_request == 1 ||
            data?.project_data?.terminate_request == 2 ? null : (
              <div className="row mx-0 br_tp">
                <ProjectsFooter id={props?.id} {...props} type={data?.project_data?.sub_type} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ViewBrief;
