import React from 'react'
import { connect } from 'react-redux';
import { separateNumber } from '../../../utils/Helperfunctions';
import { getDateTime } from '../../../utils/DateMethod'

function Proposals(props) {
    return (
        <div className="card-wrapper mb-10">
            <div className="card">
                <div className="card-body px-2 py-3">
                <div className="row m-0">
                    <div className="col-sm-12 border-bottom-1 pb-3 dflex">
                    <h2 className="text-dark-black font-14 font-weight-600 m-0">Latest Proposal</h2>
                    {props.ProjectStatus === 5 && props.CurrentRole === 2 && props.isMultipleProposal?
                        <div className="">
                            <a href="/" className="text-primary font-12 font-weight-600 pt-3"
                                onClick={(e)=>{
                                e.preventDefault();
                                // props.SetCurrent.Proposal(props.proposal._id,props.proposal.post_project_id);
                                // props.OpenPopup('ReviewUpdatedProposal');
                                props.OpenPopup('ReviewProposal')
                                props.SetCurrentProposal(props.proposal._id,props.proposal.post_project_id);
                                }}
                            >View Version History <i className="fas fa-arrow-right"></i></a>
                        </div> 
                        : null}
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-sm-12">
                    <div className="timeline-due-date page">
                        <div className="timeline">
                        <div className="timeline__group">
                            {props.milestones && props.milestones.map((items, k) => (
                                (items.type === "token") ?
                                <div className="timeline__box mb-30" key={k}>
                                    <div className="timeline__date bg-tranparent">
                                        <span><i className="fas fa-info-circle text-secondary font-30 pt-15 mob-p-0"></i></span>
                                    </div>
                                    <div className="timeline__post">
                                        <div className="timeline__content active">
                                        <p className="mb-0 font-12 font-weight-600">Project - Advance Fees</p>
                                        <div className="dflex">
                                            <h2 className="milestone-headline mb-1 font-weight-600 font-12">non-refundable fee</h2>
                                            <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12"></i>{items.price?separateNumber(items.price):'-'}</span>
                                        </div>
                                        </div>
                                    </div>
                                    <span className="vertical-line advance-payment"></span>
                                </div>
                                :
                                <div className="timeline__box mb-36" key={k}>
                                    <div className={`timeline__date  active `}>
                                        <span className={`due-date active`}>DUE DATE</span>
                                        <span className="timeline__day">{getDateTime(items.due_date,"day")}</span>
                                        <span className="timeline__month">{getDateTime(items.due_date,"month")}</span>
                                    </div>
                                    <div className="timeline__post">
                                        <div className="timeline__content active">
                                        <div className="dflex">
                                            <h2 className="milestone-headline mb-1 font-weight-600 font-13 w-70">{items.name.length > 25 ? items.name.substring(0,25) + '...' : items.name}</h2>
                                            <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12"></i>{items?.price ?separateNumber(items.price):'-'}</span>
                                        </div>
                                        <div className="dflex">
                                            <p className="font-11 font-weight-600">Milestone {items.serial_no}</p>
                                            <p className="font-11 font-weight-600">Iterations {items.iteration}</p>
                                            {/* <p className="font-12 font-weight-600 text-yellow"><i className="fas fa-exclamation-triangle"></i> Delayed </p> */}

                                        </div>
                                        </div>
                                    </div>
                                    <span className={`vertical-line ${k+1 === props.milestones.length ? 'hidden' : ''}`}></span>
                                </div>
                            ))}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row m-0 bor-bottom bor-top py-3">
                    <div className="col-sm-12 dflex creator-cost">
                    <div className="w-50">
                        <span className="d-block font-12 text-sub font-weight-500">Project Quote</span>
                        <span className="font-16 text-dark-black font-weight-600 position-relative"><i className="fas fa-rupee-sig  n font-14"></i>{separateNumber(props.proposal?.sub_total_cost)} <span className="tool pre-line" data-tip={`Sub Total: Rs ${props.proposal?.sub_total_cost}
                                GST @ 18%: Rs ${props.proposal?.tax}
                                Total Project Cost for Client: Rs ${props.proposal?.total_cost}`}><i className="fas fa-info-circle text-secondary font-12"></i></span></span>
                        <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span>
                    </div>
                    <div className="w-50 project-delivery-date position-relative pl-3">
                        <span className="d-block font-12 text-sub font-weight-500">Your earnings</span>
                        <span className="font-16 text-dark-black font-weight-600"><i className="fas fa-rupee-sign font-14"></i>{separateNumber(props.proposal?.total_earning)} </span>
                    </div>
                    </div>
                </div>
                {props.proposalStatus === 2 ? null : 
                <>
                    {props.CurrentRole === 2 && props.proposal.update_status === 1 ?
                    <div className="row m-0">
                        <div className="col-sm-12 text-right">
                        <a href="/" className="text-primary font-12 font-weight-600 d-block pt-3"
                            onClick={(e)=>{
                            e.preventDefault();
                            props.OpenPopup('ReviewMilestone');
                            }}
                        >Make changes <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    : <></>}
                </>}
                </div>
            </div>
            </div>
    )
}



const mapState = (store) => {
    let chat = store.chat;
    let globalReducer = store.globalReducer;
    const postProject = store.postProject
    return {
      Artist: chat.Artist,
      Projects: chat.Projects,
      ProjectId: chat.ProjectId,
      ProjectName: chat.ProjectName,
      ProjectStatus: chat.ProjectStatus,
      Messages: chat.Messages,
      CurrentChat: chat.CurrentChat,
      isLastMilestonePaymentDone: chat.isLastMilestonePaymentDone,
      isMultipleProposal: chat.isMultipleProposal,
  
      CurrentRole:globalReducer.Me.CurrentRole,
  
      milestones: postProject.milestones,
      proposal: postProject.proposal?.length ? postProject.proposal : postProject.review_proposal_data?.proposal,
      review_proposal_data:postProject.review_proposal_data?.proposal,
      active_milestones: postProject.active_milestones,
    //   delivered_status: postProject.delivered_status,
      user_data: postProject.user,
      CurrentMilestone: postProject.CurrentMilestone,
      proposalStatus: postProject.proposalStatus,
      loading: globalReducer.loading,
  
    };
};

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    SetCurrentProposal: (proposal_id,project_id,reviewStatus) => dispatch({ type: 'SET_CURRENT_PROPOSAL', payload: {proposal_id,project_id,reviewStatus}}),
  });

  export default connect(mapState, mapDispatch)(Proposals)