import React, { Component } from 'react'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import $ from 'jquery'

export default class CustomSummernote extends Component {
    state = {
        value: ''
    }
    summernoteRef = React.createRef()
    
    sendButton = (context) => {
        var ui = window.jQuery.summernote.ui;
        
        // create button
        var button = ui.button({
            contents: 'send',
            // tooltip: 'send msg',
            click: () => {
                this.props.sendMsg()
                this.props.onChange('')
            }
        });
        
        return button.render();   // return button as jquery object
    }
    
    EnterPressed = (e) => {
        // check if Enter + Shift is pressed
        const isBrTag = e.shiftKey === true && e.keyCode === 13
        if(!isBrTag && this.props.sendMsgOnEnter) {
            e.preventDefault()
            // var plainText = $($("#summernote").summernote("code")).text()
            //  console.log(plainText)
            // console.log(ReactSummernote.code().text())
            this.props.sendMsg()
            // ReactSummernote.reset()
        }        
    }

    summernoteOnChange = (content, editable) => {
        this.setState({value: content})
        if(content.replace(/<\/?[^>]+(>|$)/g, "").length){
            this.props.onChange(content)
        } else {
            this.props.onChange('')
        }
        

        if(editable[0].scrollHeight < 200){
            // if(this.props.inChat) {
            //     // $('div.note-editable').css({'min-height': increasedHeight});
            //     $('.overflow-content.chat-height.chatting-box-overflow').css( { height: `calc(100vh - (184px + ${editable[0].scrollHeight - 50 }px))` } );
            // } 
            // else
             if(this.props.inMilestone){
                $('.chat-modal-section .chatting-box-overflow').css({ height: `calc(100vh - (200px + ${editable[0].scrollHeight - 50 }px))` })
            }
        }

    }

    summernoteInit = () => {
        
    }
    render() {
        const {minHeight, placeholder,disabled} = this.props
        return (
        <>  
            <ReactSummernote
                ref={this.summernoteRef}
                value={this.props.value}
                disabled={disabled}
                className={"font-weight-400"}
                onInit={() => this.summernoteInit}
                onPaste={(e) => {
                  var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                  e.preventDefault();
                  document.execCommand('insertText', false, bufferText);
                }}
                onEnter={(e)=>this.EnterPressed(e)}
                // onFocus={() => console.log('focus')}
                options={{
                placeholder: placeholder,
                minHeight: minHeight ? minHeight : 50,
                // maxHeight: 80,
                disableResizeEditor: true,
                toolbar: [
                    // ['style', ['style']],
                    // ['insert', ['emoji']],
                    // ['font', ['bold', 'underline']],
                    // ['fontname', ['fontname']],
                    // ['para', ['ul', 'ol']],
                    // ['table', ['table']],
                    // ['insert', ['link', 'picture', 'video']],
                    // ['view', ['fullscreen', 'codeview']]
                    // ['mybutton', ['send']],
                ],
                // buttons: {
                //     hello: this.HelloButton,
                //     send: this.sendButton,
                // }
                }}
                onChange={this.summernoteOnChange}
            />

        </>
        )
    }
}
