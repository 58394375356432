import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import RedBull from "./../../../../../Assets/images/redbull_logo_720.png";
import Pratilili from "./../../../../../Assets/images/pratilili_logo_720.png";
import { handleFileType } from "../CommonFunctionsNew/CommonFunctionsNew";
import { ChatImageBaseUrl } from "../../../../../utils/BaseUrl";
import CommonViewFile from "../PopupAll/CommonViewFile";

const CarosalCommon = ({ showCarosalCommon, setShowCarosalCommon, attachments, particularAttachment }) => {
  const [showImage, setShowImage] = useState({});

  useEffect(() => {
    setShowImage({ ...particularAttachment, type: "file" });
  }, [attachments]);

  const imageLeftRight = (action) => {
    let length = attachments.length;
    let index = showImage?.index;
    if (action === "left") {
      if (index != 1) {
        setShowImage({ ...attachments[index - 2], type: "file" });
      }
    } else {
      if (index != length) {
        setShowImage({ ...attachments[index], type: "file" });
      }
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "ArrowRight") {
      imageLeftRight("right");
    } else if (e.key === "ArrowLeft") {
      imageLeftRight("left");
    } else if (e.key === "Escape") {
      setShowCarosalCommon(false);
    }
  };

  return (
    <section className="finalreviewrapper ">
      <div
        className={`modal fade modal_fade_bg ${showCarosalCommon ? "show" : ""}`}
        style={{ display: showCarosalCommon ? "block" : "" }}
        id="finalreviewpopup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered popup_modal_dialog_md m-auto">
          <div className="modal-content">
            <div className="modal-header br_btm d-block">
              <div className="row header_title mb-3">
                <div className="col-lg-12 d-flex justify-content-center align-items-center">
                  <h5 className="modal-title modal_title" id="exampleModalLabel">
                    Attachments
                  </h5>
                  <button type="button" className="close close_btn" onClick={() => setShowCarosalCommon(false)}>
                    <span aria-hidden="true" className="close_icon modalclose-btnicon">
                      ×
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body py-5">
              <div className="container d-flex justify-content-center">
                {attachments.length > 1 ? (
                  <>
                    <div
                      className="project_prev"
                      onClick={() => imageLeftRight("left")}
                      style={{ left: "60px", zIndex: "999", top: "45%" }}
                    >
                      <i className="fas fa-arrow-left" style={{ color: "#10c89b", border: "1px solid #10c89b" }}></i>
                    </div>
                    <div
                      className="project_next"
                      onClick={() => imageLeftRight("right")}
                      style={{ right: "10px", zIndex: "999", top: "45%" }}
                    >
                      <i className="fas fa-arrow-right" style={{ color: "#10c89b", border: "1px solid #10c89b" }}></i>
                    </div>
                  </>
                ) : null}
                {/* <img
                  className="rounded image cursor-pointer"
                  style={{ width: "34rem", height: "auto" }}
                  src={handleFileType(showImage?.name, ChatImageBaseUrl)}
                  onClick={() => window.open(`${ChatImageBaseUrl + showImage.name}`, "_blank", "noopener,noreferrer")}
                /> */}
                {showImage && showImage?.name ? (
                  <div className={`view_document_wrapper common_overflow_height w-100`}>
                    <div
                      className="uploading-image "
                      onClick={() =>
                        window.open(`${ChatImageBaseUrl + showImage.name}`, "_blank", "noopener,noreferrer")
                      }
                    >
                      <CommonViewFile documentDetail={showImage} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarosalCommon;
