import React from "react";
import YellowClock from "../../../../../Assets/upgrade-images/submit_proposal/yellow_clock.svg";
import { separateNumber } from "../../../../../utils/Helperfunctions";
import ArrowGray from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/arrow-right-gray.svg";
import { getDateFormated } from "../CommonFunctionsNew/CommonFunctionsNew";
import Exclamation from "../../../../../Assets/upgrade-images/submit_proposal/info-circle.svg";

const TerminateOverviewComp = ({
  type,
  terminationData,
  userName,
  CurrentRole,
  changeTerminatePopup,
  retainer_fees_type,
  setShowApproveRejectPop,
  setPopupType,
  terminate_requested_by,
  setShowTerminationPay,
  rowFiles,
}) => {
  const handleTotalPayable = () => {
    return (
      <div className="inner_wrapper br_btm">
        <div className="inner_item_wrapper d-flex justify-content-between mx-0 mb-4 mt-4">
          <div className="deliverable_title">
            <p className="mb-0 font_semi_bold">Total Payable</p>
          </div>
          <div className="extension_date">
            <p className="mb-0">
              {terminationData?.total_payable_old == terminationData?.total_payable ? (
                ""
              ) : terminationData?.total_payable_old ? (
                <>
                  <span className="strike text_light_gray_xs mr-1">
                    ₹ {separateNumber(terminationData.total_payable_old)}/{" "}
                    {retainer_fees_type && retainer_fees_type == "Hourly" ? "Hourly" : "Monthly"}
                  </span>{" "}
                  <img src={ArrowGray} className="mr-1" />{" "}
                </>
              ) : null}
              ₹ {separateNumber(terminationData.total_payable)}/{" "}
              {retainer_fees_type && retainer_fees_type == "Hourly" ? "Hourly" : "Monthly"}
            </p>
            <p className="mb-0 text_light_gray_xs d-flex justify-content-end">+18% GST</p>
          </div>
        </div>
      </div>
    );
  };

  const handleButtons = () => {
    return (
      <>
        <button
          type="button"
          class="btn btn_outline_md fw_700 mr-2"
          onClick={() => {
            changeTerminatePopup(true);
          }}
        >
          Make Changes
        </button>
        <button
          type="button"
          class="btn btn_common_md fw_700 "
          onClick={() => {
            setPopupType(1);
            setShowApproveRejectPop(true);
          }}
        >
          Approve
        </button>
      </>
    );
  };

  const handleActualRetDiv = (terminationData, text, isType) => {
    return (
      <div className="inner_wrapper br_btm">
        <div className="inner_item_wrapper d-flex justify-content-between mx-0 mb-4 mt-4">
          <div className="deliverable_title">
            <p className="mb-0 font_semi_bold">{text}</p>
          </div>
          <div className="extension_date">
            {isType === 1 ? (
              <p className="mb-0 text_gray_xs_w_600">
                {terminationData?.project_end_date_old == terminationData?.project_end_date ? (
                  ""
                ) : terminationData?.project_end_date_old ? (
                  <>
                    <span className="strike mr-1">{getDateFormated(terminationData?.project_end_date_old)}</span>{" "}
                    <img src={ArrowGray} className="mr-1" />{" "}
                  </>
                ) : null}
                {getDateFormated(terminationData?.project_end_date)}
              </p>
            ) : (
              <p className="mb-0 text_gray_xs_w_600">
                {terminationData?.number_of_hours_old == terminationData?.number_of_hours ? (
                  ""
                ) : terminationData?.number_of_hours_old + " Hrs" ? (
                  <>
                    <span className="strike mr-1">{terminationData?.number_of_hours_old + " Hrs"}</span>{" "}
                    <img src={ArrowGray} className="mr-1" />{" "}
                  </>
                ) : null}
                {terminationData?.number_of_hours + " Hrs"}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleEditButton = () => {
    return (
      <button
        type="button"
        class="btn btn_common_md fw_700 "
        onClick={() => {
          changeTerminatePopup(true);
        }}
      >
        Edit Request
      </button>
    );
  };

  const userBio = CurrentRole == 2 ? "creator" : CurrentRole == 1 ? "client" : "";

  return (
    <div className="page_card mb-3">
      <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
        <div className="prposal_heading d-flex align-items-center">
          <div className="heading_logo">
            <img src={YellowClock} className="mr-3" />
          </div>
          <div className="heading_info">
            <p className="mb-0">
              {CurrentRole == 3
                ? ""
                : CurrentRole == 2 && terminationData.updated_by === "creator"
                ? "Termination being reviewed by client"
                : CurrentRole == 2 && terminationData.updated_by === "client"
                ? "Client has requested revisions to termination"
                : CurrentRole == 1 && terminationData.updated_by === "client"
                ? `Termination being reviewed by ${userName}`
                : CurrentRole == 1 && terminationData.updated_by === "creator"
                ? `Termination being reviewed by ${userName}`
                : ""}
            </p>
          </div>
        </div>
      </div>
      <div className="row  br_btm mx-0">
        <div className="col-lg-12 px-4 common_overflow_xsm_height">
          {type === "fixed" ? (
            terminationData.milestone_payment?.map((item, i) => {
              return (
                <div className="inner_wrapper br_btm">
                  <div className="inner_item_wrapper d-flex justify-content-between mx-0 mb-4 mt-4">
                    <div className="deliverable_title">
                      <p className="mb-0 font_semi_bold">Milestone {item?.index + 1}</p>
                    </div>
                    <div className="extension_date">
                      <p className="mb-0 text_gray_xs_w_600">
                        {item?.payable_old == item?.payable ? (
                          ""
                        ) : item?.payable_old ? (
                          <>
                            <span className="strike mr-1">₹ {separateNumber(item?.payable_old)}</span>{" "}
                            <img src={ArrowGray} className="mr-1" />{" "}
                          </>
                        ) : null}
                        ₹ {separateNumber(item?.payable)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {handleActualRetDiv(terminationData, "End Date", 1)}
              {retainer_fees_type && retainer_fees_type == "Hourly" && type === "retainer"
                ? handleActualRetDiv(terminationData, "Number of hours", 2)
                : null}
            </>
          )}
          {handleTotalPayable()}
        </div>
        {terminationData?.note?.length ? (
          <div className="col-lg-12 p-4">
            <p className="font_semi_bold_w_600 mb-0">Notes & Comments</p>
            <p className="text_light_gray_md mb-0">{terminationData.note}</p>
          </div>
        ) : null}
      </div>

      {/* ============== footer ======================= */}

      {terminationData?.status == 1 ? (
        <div className="proposal_footer ">
          <div className="row  br_btm mx-0">
            {type === "fixed"  ? 
              <div className="col-lg-12 pl-0 pb-3 pl-0">
                <div className="title_commn row text_gray_1100_w_600_sm mb-2 mx-0">
                  <div className="col-lg-4 pl-lg-0">
                    <p className="text_gray_1100_w_600_sm mb-0">Deliverable</p>
                    <p className="font_semi_bold mb-0">Final Submission</p>
                  </div>
                  <div className="col-lg-8 my-auto">
                    <div className="column_gap_10 d-flex align-items-center justify-content-end">
                      <span className="text_light_gray_xs">
                        <img src={Exclamation} className="mr-2" />
                        {rowFiles.length
                          ? "Please complete pending payment to view final files"
                          : "Final submission not yet done by " + userName}
                      </span>
                    </div>
                  </div>
                </div>
              </div> : null
            }
            <div className="col-lg-12 pb-4 pl-0">
              <button
                type="button"
                class="btn btn-common fw_700 w-100"
                onClick={() => {
                  setShowTerminationPay(true);
                }}
              >
                Complete Payment
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="proposal_footer d-flex align-items-center justify-content-between">
          <div className="left_btn">
            {terminate_requested_by === userBio ? (
              <button
                type="button"
                class="btn btn-link-text-red-500 fw_700"
                onClick={() => {
                  setPopupType(2);
                  setShowApproveRejectPop(true);
                }}
              >
                Undo Termination
              </button>
            ) : null}
          </div>

          <div className="right_btn">
            {CurrentRole == 3
              ? ""
              : (CurrentRole == 2 && terminationData.updated_by === "creator") ||
                (CurrentRole == 1 && terminationData.updated_by === "client")
              ? handleEditButton()
              : (CurrentRole == 2 && terminationData.updated_by === "client") ||
                (CurrentRole == 1 && terminationData.updated_by === "creator")
              ? handleButtons()
              : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default TerminateOverviewComp;
