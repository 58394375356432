import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { getThumbnailImage } from '../../../../utils/Helperfunctions'
import pdfThumbnail from '../../../../Assets/images/big-pdf.jpg'

class SubmitProject extends React.Component {

    state = {
        projectImage: "",
    }

    componentDidMount = () => {
        const images_or_links = this.props.images_or_links;
        const projectImage    = images_or_links && images_or_links.some(el => el.isThumbnail === 1) ? images_or_links.filter(item => item.isThumbnail === 1) : images_or_links && images_or_links[0]
        this.setState({
            projectImage : projectImage,
        })
    }

    closePOP = () => {
        this.props.closePopup()
    }

    requestReview = (projectId) => {
        this.props.onFieldChange({
            name: 'REVIEW_PROJECT_ID', value: projectId
        });
    }


    componentWillUnmount() {
        this.props.resetProjectData();
    }

    render() {
        const {projectImage} = this.state;
        const {projectId} = this.props;
        return (
            <>
                <div className="modal fade custom_form verify_modal edit-basic-profile show" data-backdrop="static" style={{ paddingRight: "0px", display: "block" }} id="projectSubmittedModal"
                    tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content small-mt-60">
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="custom_form py-3 text-center">
                                            <div className="upload-project-submit mb-4">
                                                {/* <img src={projectImage} className="img-fluid" /> */}
                                                <RenderImage image={projectImage} />
                                            </div>
                                            <h2 className="text-dark-black mob-p-15 font-20 font-weight-800">Your project has been submitted for approval</h2>
                                            <p className="font-12 px-5 mob-p-15 font-weight-500 text-sub">Our curation team is reviewing your project. We will notify you shortly via email whether your project has been approved or if we need any further details. Meanwhile, you can add credibility to your project and profile by requesting your former client/employer to verify & review this project.
                                                    <a 
                                                    href="/" 
                                                    onClick={(e) => {e.preventDefault();
                                                        this.requestReview(projectId);
                                                        this.props.closePopup();
                                                        this.props.OpenPopup('ReviewModal')
                                                      }} 
                                                    className="font-12 font-weight-800 text-primary d-block py-2"  data-toggle="modal" data-target="#RequestReviewModal">
                                                    Request testimonial from client <i className="fas fa-arrow-right ml-1"></i>
                                                </a>
                                            </p>
                                            <div className="w-100 dflex bg-light-sky p-3 mb-3 have-more-work">
                                                <p className="text-dark-black font-weight-600 mb-0 small-font-11">Have more work?</p>
                                                <Link onClick={(e) => {
                                                    this.closePOP()
                                                }} to='/uploadproject/choose_collateral' className="main-btn font-12 cursor-pointer small-font-11 text-white ">Upload project <i className="fas fa-arrow-right ml-1"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}>
                </div>
            </>
        )
    }
}

const mapState = (store) => {
    let project = store.project;
    let creatorProfile = store.creatorProfile;
    // console.log("Project=====>",project);
    return {
        projectImage: project.project_image,
        images_or_links: project.images_or_links,
        projectId:project.project_submitted_id,
        closeModal: creatorProfile.closeModal,
    };
};

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    resetProjectData: () => dispatch({type: 'RESET_USER_PROJECT_DATA'}),
});

export default connect(mapState, mapDispatch)(SubmitProject);


const RenderImage = ({ image }) => {
    const [thumbnail, setThumbnail]  = useState(null);

    useEffect(() => {
        const getThumbnailImageFunc = async (u) => {
            const url = await getThumbnailImage(u, true);
            setThumbnail(url)
          }
          if(image.type === 'url'){
            getThumbnailImageFunc(image.value)
          } else {
            setThumbnail(image.value)
          }
    }, [image.value,image.type])
    const isImageCheck=(dataurl)=>{
        const condition = dataurl && dataurl.split(".")
        if(condition?.length && condition[condition.length - 1] === "pdf"){
            return pdfThumbnail
        }else{
            return dataurl
        }
    }
    return (
    <div className="submit_project_img" style={{backgroundImage: `url("${isImageCheck(thumbnail)}")`}}></div>
    )
} 