import { createStore, combineReducers, applyMiddleware } from "redux";
import { createBrowserHistory } from 'history'
import createSagaMiddleware from "redux-saga";
import reducers from "./reducer/index.reducer";
import sagas from "./saga/index.saga";
import { connectRouter } from 'connected-react-router'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory()


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const REDUCERS = (history) => combineReducers({
    router: connectRouter(history),
    ...reducers
});

const SAGA = createSagaMiddleware();

export default function configureStore(initialState = {}) {
    const STORE = createStore(
        REDUCERS(history),
        initialState,
        composeWithDevTools(applyMiddleware(SAGA,routerMiddleware(history),))
    );

    // Running Sagas
    SAGA.run(sagas);

    if (module.hot && process.env.NODE_ENV === "development") {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("./reducer/index.reducer", () => {
            const nextRootReducer = require("./reducer/index.reducer");
            STORE.replaceReducer(nextRootReducer);
        });
    }

    return STORE;
}
