import React from "react";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

export const CommonRejectionPopup = ({
  heading,
  checkboxData,
  rejectStates,
  setRejectStates,
  changeCommonReject,
  showCommonReject,
  handleRejectSubmit,
  submitButtonName,
  rejectLoader,
}) => {
  const onChangeHandler = (e, obj) => {
    const isAlreadyChecked = rejectStates.reason.find((el) => el.text === obj.text);
    if (isAlreadyChecked) {
      setRejectStates({
        ...rejectStates,
        reason: [...rejectStates.reason.filter((el) => el.text !== obj.text)],
      });
    } else {
      setRejectStates({ ...rejectStates, reason: [...rejectStates.reason, { text: obj.text }] });
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (!rejectStates.reason.length) {
      alert("Please select at least one reason.");
      isValid = false;
    }
    return isValid;
  };

  return (
    <section className="worklog_extension_rejection_wrapper">
      <div
        className={`modal fade modal_fade_bg ${showCommonReject ? "show" : ""}`}
        style={{ display: showCommonReject ? "block" : "" }}
        id="worklogextensionrejection"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm m-auto">
          <div className="modal-content br_6">
            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
              <div className="header">
                <h5 className="text-black-700 fw_700 fs_20 main_modal_title mb-0" id="exampleModalLabel">
                  {heading}
                </h5>
              </div>
              <button
                type="button"
                className="close close_btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => changeCommonReject(false)}
              >
                {/* <span aria-hidden="true" className="close_icon modalclose-btnicon">
                  ×
                </span> */}
              </button>
            </div>

            <div className="modal-body">
              {checkboxData?.length
                ? checkboxData.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="row mx-0 mb-3 align-items-center">
                      <div className="col-lg-auto">
                        <div className="cstm_checkbox">
                          <input
                            type="checkbox"
                            id={`checkbox${index}`}
                            checked={rejectStates.reason.find((data) => data.text === obj.text) ? true : false}
                            onChange={(e) => onChangeHandler(e, obj)}
                            name={`checkbox${index}`}
                          />
                          <label htmlFor={`checkbox${index}`}></label>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        {/* <p
                            className="mb-0 font_semi_bold_w_600 "
                            style={{
                              color: rejectStates.reason.find((data) => data.text === obj.text) ? "#000000" : "#9b9b9b",
                              marginLeft: "7px",
                            }}
                          >
                            {obj.text}
                          </p> */}
                        <p
                          className={`mb-0 fs_146 fw_500  ${rejectStates.reason.find((data) => data.text === obj.text) ? "text-black-700" : "text-gray-500"}`}

                        >
                          {obj.text}
                        </p>

                      </div>
                    </div>
                  </React.Fragment>
                ))
                : null}
              <div className="row mx-0 mb-3 align-items-center">
                <div className="col-lg-12 ">
                  <textarea
                    className="form-control upload_notes p-3"
                    name="description"
                    value={rejectStates.description}
                    onChange={(e) =>
                      setRejectStates({
                        ...rejectStates,
                        description: e.target.value.substring(0, 300),
                      })
                    }
                    placeholder="Describe further (optional)"
                    id="comment"
                    rows="5"
                  ></textarea>
                  <span className="font-weight-500 text-secondary">{rejectStates.description?.length}/300</span>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-end align-items-center py-4">
              <div className="col-lg-12 text-right">
                <button type="button" class="btn btn_outline_sm fw_700 mr-4" onClick={() => changeCommonReject(false)}>
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn_common_danger_sm fw_700"
                  onClick={() => (formValidation() ? handleRejectSubmit() : "")}
                >
                  {rejectLoader ? <Loader isCreateModal={true} /> : submitButtonName}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
