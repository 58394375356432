import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import logo from "../../../Assets/images/logo-icon.png";
import { BaseImageUrl, ChatBaseUrl } from "../../../utils/BaseUrl";
import socketIOClient from "socket.io-client";
import { separateNumber, limitString, getCompanyUrl } from "../../../utils/Helperfunctions";
import CompanyProfile2 from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/user-octagon.svg";
import DummyImage from "../../../Assets/upgrade-images/dashboard/dummy_user2.svg";
import RenderImageCommonComp from "../Home/DashboardNew/CommonComp/RenderImageCommonComp";
import { getImageSize } from "react-image-size";
import DummyCompany from "../../../Assets/upgrade-images/dashboard/dummy_company.svg";
import MobileResArtistList from "../Home/DashboardNew/CommonComp/MobileResArtistList";

function ArtistList({ Artist, ProjectType, CurrentChat, Messages, ...props }) {
  // const [showDropdown, setShowDropdown] = useState(false);
  const [firstMessFinal, setFirstMessFinal] = useState(props?.firstMess);

  useEffect(() => {
    const socket = socketIOClient(
      ChatBaseUrl
      // { transports: ['websocket'] }
    );
    Artist.forEach((item) => {
      socket.on(item.user_token, function (resp) {
        props.updateArtistMsgCount({ user_token: item.user_token, response: resp });
      });
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let chatUnreadMes = 0
    Artist.forEach((item) => {
      chatUnreadMes += item?.unread_msg ? item.unread_msg : 0
    });
    props.setChatUnreadMes(chatUnreadMes)
  },[Artist])

  // useEffect(() => {
  //   const username = window.location.pathname.split('/').pop();
  //   const preSelectedPerson = Artist.find(val => val.username && val.username.toLowerCase() === username.toLowerCase());
  //   // console.log(Artist, preSelectedPerson)
  //   if(props.socket){
  //     if(preSelectedPerson && preSelectedPerson.chat_connection_id !== CurrentChat.chat_connection_id){
  //       // console.log(username, preSelectedPerson, props)
  //       selectPerson(preSelectedPerson);
  //     }
  //   }
  // })

  const selectPerson = (a) => {
    // const isRepresentative = CurrentChat.user_role === 'representative'
    const isAdminProject = ProjectType === "post_project_admin";
    if (!a) return;
    let userType;
    if (isAdminProject) {
      userType = CurrentChat.user_role;
      props.loadAdminProjectProposal();
    } else {
      props.loadLatestProposals(a.post_project_id);
      userType = props.CurrentRole === 2 ? "creator" :props.CurrentRole==3? "account_manager":"client"
    }
    props.loadChatMessages(a);
    props.socket.emit("chat_reset", { chat_connection_id: a.chat_connection_id, user_type: userType });
    if (a?.unread_msg > 0) {
      props.resetUnreadMsgInCreatorObj(a);
    }
  };

  const loadPerson = (obj) => {
    const { push, location } = props.history;
    // const {pathname} = location;
    // const [,, projectId, projectName] = pathname.split('/');
    // push(`/chat/${projectId}/${projectName}/${obj.username}`);

    let { projectId } = props?.match?.params;
    let redirect =
      props?.CurrentRole == 2 && obj?.role != 3
        ? `/projects/${projectId}/project_room`
        : `/projects/${projectId}/project_room/${obj?.username}`;
    push(redirect);
    props.handleSetShowHistoryTab(false);
    selectPerson(obj);
  };

  const getArtistDetail = (obj,k) => {
    const isAdminProject = ProjectType === "post_project_admin";
    let name, profileImage, isActive, role, unread_msg;
    if (isAdminProject) {
      console.log(obj,'--')
      name = obj.chat_type === "group" ? "Group" : `${obj.member1.first_name} ${obj.member1.last_name}`;
      profileImage = obj.chat_type === "group" ? logo : BaseImageUrl + obj.member1.profile_image;
      isActive = obj.chat_connection_id === CurrentChat.chat_connection_id;
      unread_msg = obj?.chat_connection_id == firstMessFinal?.ID ? firstMessFinal?.mes : obj.unread_msg;
    } else {
      if (props?.CurrentRole == 2 && (props?.emptyChatStatus == 5 || props?.emptyChatStatus == 0) && obj?.role != 3) {
        name = "client";
      } else {
        name = `${obj.first_name} ${obj.last_name} ${
          props.CurrentRole == 3
            ? obj.connection_type == "am_creator" || obj.connection_type == "am_client"
              ? obj.connection_type == "am_creator"
                ? " (creator)"
                : " (client)"
              : "(group)"
            : ""
        }`;
      }

      profileImage = BaseImageUrl + obj.profile_image;
      isActive = obj.chat_connection_id === CurrentChat.chat_connection_id;
      unread_msg = obj?.chat_connection_id == firstMessFinal?.ID ? firstMessFinal?.mes : obj.unread_msg;
      role = obj.role;
    }

    return { name, profileImage, isActive, role, unread_msg,index:k };
  };

  // const getLastMsg = (artistObj) => {
  //   return  artistObj.type.toLowerCase() === 'file' ? 'Attachment' : removePandBrTag(artistObj.last_msg)
  // }
  // const isAdminProject = ProjectType === 'post_project_admin'
  // const activeChatObj = Artist.find(obj => obj.chat_connection_id === CurrentChat.chat_connection_id)
  // let activeUserName;
  // if(isAdminProject){
  //   activeUserName = activeChatObj.chat_type === 'group' ? 'Group' : `${activeChatObj.member1.first_name} ${activeChatObj.member1.last_name}`
  // }else{
  //   activeUserName = `${activeChatObj?.first_name} ${activeChatObj?.last_name}`
  // }

  const projectData = props?.projectDataStates?.data?.project_data;
  const isIndividual =
    projectData?.company_work_type?.toLowerCase() === "company" || projectData?.company_work_type?.toLowerCase() === "agency"
      ? false
      : true;

  setTimeout(() => {
    setFirstMessFinal({...firstMessFinal,mes:0})
  }, 2000);

  return (
    <>
      <div className="col-lg-12">
        <div className="page_card">
          <div className="row justify-content-center mx-0">
            <div className="d-none d-lg-block w-100">
              <div className="proposal_header col-lg-12 br_btm d-flex justify-content-between px-3">
                <div className="prposal_heading d-flex align-items-center mx-3">
                  <p className="mb-0 text_black_700_w_600">Active Conversations</p>
                </div>
              </div>
            </div>

            <div className="d-none d-lg-block conversation_body body_scroll_bar mx-0 w-100">
              {Artist?.length > 0
                ? Artist.map((obj, k) => {
                  // console.log(obj,'--3')
                    let { isActive } = getArtistDetail(obj,k);
                    let index = k
                    return (
                      <div
                        onClick={(e) => loadPerson(obj)}
                        key={k}
                        className={`col-lg-12 ipadPro-px-5 d-flex Conversation_card ${isActive ? "active" : ""}`}
                      >
                        {singleArtistItem(getArtistDetail(obj,k), projectData, isIndividual, obj,firstMessFinal, props)}
                      </div>
                    );
                  })
                : "No List Found ..."}
              {/* {props?.project_data?.manager_id&&props.CurrentRole!=3 ? (
                <div className="col-lg-12 d-flex px-3 Conversation_card">
                  <div className="conversation_card_inner d-flex mx-3 py-3 w-100">
                    <div className=" user_profile mr-3">
                      <div className="user_profile_image rounded-circle">
                        <img
                          src={
                            props?.project_data?.manager_image ? BaseImageUrl + props?.project_data?.manager_image : DummyImage
                          }
                          className="img-fluid rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="user_info">
                      <div className="profile_title_inner d-flex justify-content-between mb-2">
                        <p className="mb-0 text_black_500_xl_w_700"> {props?.project_data?.manager_name}</p>
                        <div className="tag_btn_tab_active ml-3">
                        <p className="mb-0">2</p>
                      </div>
                      </div>

                      <div className="user_company_info d-flex align-items-center">
                        <div className="user_company_profile_name rounded-circle mr-2">
                          <img src={CompanyProfile2} className="img-fluid" />
                        </div>
                        <p className="mb-0 text_dark_green_500_sm_w_500" >
                          Account Manager
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>

            <MobileResArtistList
              Artist={Artist}
              singleArtistItem={singleArtistItem}
              getArtistDetail={getArtistDetail}
              loadPerson={loadPerson}
              firstMessFinal={firstMessFinal}
              CurrentChat={CurrentChat}
              projectData={projectData}
              isIndividual={isIndividual}
              {...props}
            />

            {/* <div className="col-lg-12 conversation_footer py-3">
              <div className=" footer_1  d-flex justify-content-between mx-3 mb-2">
                <p className="mb-0 text_gray_1100_w_600_sm">Participants</p>
                <p className="mb-0 blue_text_sm">
                  Manage <img src={Arrow2} className="img-fluid ml-2" />
                </p>
              </div>
              <div className="footer_2 d-flex justify-content-between mx-3">
                <div className="more_users d-flex">
                  <div className="more_users_profile rounded-circle ml-0">
                    <img src={UserProfile2} className="img-fluid" />
                  </div>
                  <div className="more_users_profile rounded-circle">
                    <img src={User4} className="img-fluid" />
                  </div>
                  <div className="more_users_profile rounded-circle">
                    <img src={User3} className="img-fluid" />
                  </div>
                  <div className="more_users_profile rounded-circle">
                    <p className="mb-0 font_xs_white_w_600">+2</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapState = (state) => {
  const { chat, globalReducer } = state;
  return {
    Artist: chat.Artist,
    ProjectType: chat.ProjectType,
    CurrentChat: chat.CurrentChat,
    Messages: chat.Messages,
    CurrentRole: globalReducer.Me.CurrentRole,
  };
};

const mapDispatch = (dispatch) => ({
  loadChatMessages: (connection_id) => dispatch({ type: "UPDATE_LOAD_CHATMESSAGES", payload: connection_id }),
  loadAdminProjectProposal: () => dispatch({ type: "LOAD_ADMIN_PROJECT_PROPOSAL", payload: null }),
  loadLatestProposals: (projectId) => dispatch({ type: "LOAD_LATEST_PROPOSAL", payload: projectId }),
  resetUnreadMsgInCreatorObj: (artistObj) => dispatch({ type: "RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ", payload: artistObj }),
  updateArtistMsgCount: (obj) => dispatch({ type: "UPDATE_ARTIST_MSG_COUNT", values: obj }),
});

export default connect(mapState, mapDispatch)(ArtistList);

const singleArtistItem = (object, projectData, isIndividual, obj,firstMessFinal, ...props) => {
  let { name, profileImage, isActive, role, unread_msg } = object;
  const AdminProject = projectData?.type==='post_project_admin' || projectData?.were_admin_project
  return (
    <div className="conversation_card_inner d-flex py-3 w-100">
      <div className=" user_profile mr-3">
        <div className="user_profile_image rounded-circle">
          {/* <img src={role==1 && props.CurrentRole==2 && props.review_proposal_data?.status==4  && props?.CurrentRole==2   ? DummyImage:profileImage} className="img-fluid rounded-circle" /> */}
          <img src={obj?.profile_image ? profileImage : DummyImage} className="img-fluid rounded-circle" />
        </div>
      </div>
      <div className="user_info">
        <div className="profile_title_inner d-flex justify-content-between mb-2">
          <p
            className="mb-0 text_black_500_xl_w_700"
            // className={` ${
            //   isActive ? "chat-username" : ""
            // }  text-dark-black text-capitalize m-0 font-16 font-weight-600 mob-font-14`}
          >
            {/* {props?.emptyChatStatus == 5 || props?.emptyChatStatus == 0 ? "client" : name ? limitString(name, 35) : name} */}
            {role == 1 && props.CurrentRole == 2 && props.review_proposal_data?.status == 4 && props?.CurrentRole == 2
              ? "Client"
              : name
              ? limitString(name, 35)
              : name}{" "}
          </p>
          {unread_msg && (!isActive || obj?.chat_connection_id == firstMessFinal?.ID) ? (
            // <div className="main-btn bg-primary font-11 mr-1 py-1 px-2 text-capitalize">{unread_msg}</div>
            <div className="msg_tag" style={{padding:"0.4rem 0.5rem 0rem",fontSize:".7rem"}}>
                <span className="msg_tag p-0">{unread_msg}</span>
            </div>
          ) : null}
        </div>
        {/* {isAdminProject ? (
          <p className="font-weight-500 text-dark-black mb-0 text-capitalize font-13">
            {" "}
            {obj.chat_type === "group" ? null : obj.member1_role}{" "}
          </p>
        ) : null} */}
        {obj?.role === 3 ? (
          <div className="user_company_info d-flex align-items-center">
            <div className="user_company_profile_name rounded-circle mr-2">
              <img src={CompanyProfile2} className="img-fluid" />
            </div>
            <p className="mb-0 text_dark_green_500_sm_w_500">Account Manager</p>
          </div>
        ) : obj?.role === 2 ? (
          !AdminProject && <div className="chat_left_user_html d-flex align-items-center justify-content-between">
            {obj?.proposal_data?.proposal_type == "fixed" ? (
              <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
                <span className="text_gray_xs_w_400">Proposal:</span>
                {` ₹ ${separateNumber(obj?.proposal_data?.sub_total_cost, "")}`}
              </p>
            ) : obj?.proposal_data?.proposal_type == "retainer" ? (
              <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
                <span className="text_gray_xs_w_400">Proposal:</span>
                {` ₹ ${separateNumber(obj?.proposal_data?.retainer_fees, "")}/ ${
                  `${obj?.proposal_data?.retainer_fees_type}` === `Monthly` ? `Month` : `Hour`
                }`}
              </p>
            ) : obj?.proposal_data?.proposal_type == "actual" ? (
              <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
                <span className="text_gray_xs_w_400">Proposal:</span>
                {` On-Actuals`}
              </p>
            ) : (
              ""
            )}
          </div>
        ) : isIndividual ? (
          <div className="user_company_info d-flex align-items-center">
            <p className="mb-0 text_gray_xs_w_400">Individual</p>
          </div>
        ) : (
          <div className="user_company_info d-flex align-items-center">
            <div className="user_company_profile_image rounded-circle mr-2">
              <RenderImageCommonComp companyLogo={projectData?.company_logo} companyUrl={projectData?.company_add_link} />
            </div>
            <p className="mb-0 text_gray_xs_w_400">{limitString(projectData?.company_name, 40)}</p>
          </div>
        )}
        {/* <div className="chat_left_user_html d-flex align-items-center justify-content-between">
      {isActive && Messages.length ? (
        <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">
          {getMsgText(Messages[Messages.length - 1])}
        </p>
      ) : null}
      {!isActive ? (
        <p className="mb-0 font-12 text-dark-black font-weight-500 artist-card">{getLastMsg(obj)}</p>
      ) : null}
    </div> */}
      </div>
    </div>
  );
};

// obj?.user_token?.includes(Messages[Messages.length-1]?.sender_id)) ?
// (Messages[Messages.length-1]?.type === "File") ?
// "Attachement":
//  (Messages[Messages.length-1]?.type === "Plain")
//   ? removePandBrTag(Messages[Messages.length-1]?.text) : ""
//    : removePandBrTag(obj?.text)

{
  /* <img
  src={
    projectData?.company_logo
      ? BaseImageUrl + projectData?.company_logo
      : `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${getCompanyUrl(
          projectData?.company_add_link
        )}&size=64`
  }
  className="img-fluid"
/> */
}
