import React, { Component } from 'react';
import {getPublicAPI} from '../../../utils/API'
import { validateEmail } from '../../../utils/formValidator';
import Loader from './../Common/Loader/Loader';

export default class ForgetPwd extends Component {

    state = {
        isLoading: false,
        forget_pwd_Email: '',
    }


    send_Link = (e) => {
        e.preventDefault();
        this.setState({isLoading: true})
        const { forget_pwd_Email } = this.state;

        if(validateEmail(forget_pwd_Email)){
            getPublicAPI('/users/forgot-password-link?email='+forget_pwd_Email).then((res)=>{
                // console.log('forgot password link api review--->',res)
                if(res.data.status === "1"){
                    alert("A link to reset password has been sent to your email.")
                    this.props.setHomeState({forgetPasswordModal: false})
                }
            }).catch((error)=>{
                console.log("forgot password link api error----->",error.response);
                this.setState({isLoading: false})
            })
        } else { 
            this.setState({emailError: true, isLoading: false}) 
        }
    }

    handleOnChange = (event) => {
        this.setState({
            forget_pwd_Email: event.target.value, emailError: false
        })
    }


    handleClose = () => {
       this.props.setHomeState({forgetPasswordModal: false})
    }

    render() {
        return (
            <React.Fragment>
                <>
                        <div className="modal custom_form forget_Modal_Box" id="forgetModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block" }}>
                            <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("../../../Assets/images/close.png")} className="img-fliud" width="35" alt=""/></span>
                            <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="row m-0">
                                            <div className="col-sm-7 mob_background_image">
                                                <div className="mob-login-background"></div>
                                            </div>
                                            <div className="col-sm-7 overflow-content mob-overflow-content">
                                                {/* <!-- Forget Email --> */}
                                                <div className="login forget-padding forget_password zoomin">
                                                    <h2 className="pb-2 w-100 line-height-17 letter-spacing-normal">Forgot your password?</h2>
                                                    <form onSubmit={(e) => this.send_Link(e)}>
                                                        <div className="form-group user_details line-height-17 font-16 mob-font-14">
                                                            <p>Enter your email address below and we'll send you a link to reset your password.</p>
                                                        </div>
                                                        <div className="form-group mb-30">
                                                            <input type="email" className={`form-control email ${this.state.emailError ? 'border-error' : ''}`} 
                                                                id="exampleInputEmail1" placeholder="Email Address"
                                                                onChange={this.handleOnChange}
                                                            />
                                                            {this.state.emailError && <span className="text-danger font-weight-600 font-12 py-1">Please enter your email to continue</span>}
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="main-btn mob-w-100"
                                                                onClick={this.send_Link} disabled={this.state.isLoading}
                                                                >{this.state.isLoading ? <Loader /> : 'Get reset link'} {!this.state.isLoading && <i className="fas fa-arrow-right"></i>}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-sm-5 remove-pd overflow-hidden">
                                                <div className="forget-background forget-single-input slideinleft"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-modal"></div>
                    </>
            </React.Fragment>
        )
    }
}