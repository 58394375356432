import React,{useState} from "react"
import { useDispatch, useSelector } from "react-redux";
import ReleventProjectForclient from "./ReleventProjectForclient";
import AttachmentForClient from "./AttachmentForClient";
import UrlsForClient from "./UrlsForClient";

const ReferenceForClient = (props) => {
    const _relevent_projects = useSelector((state) => state.releventProjects)
    const creatorFavProIs = _relevent_projects?.releventProjectIs;
    const attachments = _relevent_projects?.link_image
    const urlsIs = _relevent_projects?.urls
    const [toggle, setToggle] = useState(creatorFavProIs?.length ? 1 : !creatorFavProIs?.length && attachments?.length ? 2 : 3)


    return(
        creatorFavProIs?.length || attachments?.length || urlsIs?.length ?
        <div className='row px-4 pb-4 add_reference  mx-0'>
            <div className='col-lg-12 title_commn d-flex justify-content-between align-items-center'>
                <p className='mb-0'>{props.CurrentRole === 2 ? 'Add References' : "View References"}</p>
            </div>

            <div className='col-lg-12 pt-2'>
                <nav className='mb-3'>
                    <div class="nav nav-tabs cstm_tab" id="nav-tab" role="tablist">
                    {creatorFavProIs?.length ? 
                    <a class={`nav-item nav-link cstm_tab_item ${creatorFavProIs?.length ? "active" : ""}  d-flex align-items-center`} id="nav-proj-tab" data-toggle="tab" href="#nav-proj" role="tab" aria-controls="nav-proj" onClick={() => setToggle(1)} aria-selected="true">
                        <p className='mb-0'>{creatorFavProIs?.length > 1 ? "Relevant Projects" : "Relevant Project"} </p>
                        <div className={`${toggle ==1 ? "tag_btn_tab_active" : "tag_btn_tab"} ml-3`}>
                        <p className='mb-0'>{creatorFavProIs.length}</p>
                        </div>
                    </a> : null }
                    {attachments?.length ? 
                    <a class={`nav-item nav-link cstm_tab_item ${!creatorFavProIs?.length && attachments?.length ? "active" : ""} d-flex align-items-center`} id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" onClick={() => setToggle(2)} aria-selected="false">
                    {/* <p className='mb-0'>Attachments</p> */}
                    <p className='mb-0'>{attachments?.length > 1 ? "Attachments" : "Attachment"} </p>
                    <div className={`${toggle == 2 ? "tag_btn_tab_active" : "tag_btn_tab"} ml-3`}>
                        <p className='mb-0'>{attachments?.length}</p>
                    </div>
                    </a>: null }
                    {urlsIs?.length ? 
                    <a class={`nav-item nav-link cstm_tab_item ${!creatorFavProIs?.length && !attachments?.length && urlsIs?.length ? "active" : ""} d-flex align-items-center`} id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" onClick={() => setToggle(3)} aria-selected="false">
                    <p className='mb-0'> {urlsIs?.length > 1 ? "URLs" : "URL"} </p>
                    <div className={`${toggle == 3 ? "tag_btn_tab_active" : "tag_btn_tab"} ml-3`}>
                        <p className='mb-0'>{urlsIs?.length}</p>
                    </div>
                    </a> : null }
                    </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">
                    {creatorFavProIs?.length ? 
                    <div class={`tab-pane fade ${creatorFavProIs?.length ? "show active" : ""}`} id="nav-proj" role="tabpanel" aria-labelledby="nav-proj-tab">
                       <ReleventProjectForclient {...props}/>
                    </div> : null }
                    {attachments?.length ? 
                    <div class={`tab-pane fade ${!creatorFavProIs?.length && attachments?.length ? "show active" : ""}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                       <AttachmentForClient {...props}/>
                    </div> : null }
                    {urlsIs?.length ? 
                    <div class={`tab-pane fade ${!creatorFavProIs?.length && !attachments?.length && urlsIs?.length ? "show active" : ""}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                       <UrlsForClient {...props}/>
                    </div> : null }
                </div>
            </div>
        </div> : null 
        
    )
}

export default ReferenceForClient