import React, { useEffect, useState } from "react";
import FixedCostImage from "../../../../../Assets/upgrade-images/select_project_type/fixed_cost.svg";
import OnActualsImage from "../../../../../Assets/upgrade-images/select_project_type/on_actuals.svg";
import RetainerImage from "../../../../../Assets/upgrade-images/select_project_type/retainer.svg";
import InfoIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/InfoIcon2.png";

const SubmitProp = (props) => {
  const [proposalType, setProposalType] = useState("");
  const { jobs } = props?.match?.params;

  useEffect(() => {
    let type =
      props?.data?.project_data?.sub_type === "fixed"
        ? "fixed"
        : props?.data?.project_data?.sub_type === "retainer"
        ? "retainer"
        : "actual";
    setProposalType(type);
  }, [props]);

  const handleChange = (type) => {
    setProposalType(type);
  };

  const handleSubmit = () => {
    if (props.location.pathname.includes("jobs")) {
      props.history.push(`/proposal/${props?.id}/${proposalType}/jobs`);
      if (!jobs) {
        props.closePopup("IncomingBriefReviewJob");
      }
    } else {
      props.history.push(`/proposal/${props?.id}/${proposalType}/dashboard`);
    }
    console.log("-------- ", props);
    props.changeShowSubmitProposal(false);
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeShowSubmitProposal(false);
    }
  };

  return (
    <React.Fragment>
      <section className="select_project_type">
        <div
          className={`modal fade modal_fade_bg ${props?.showSubmitProposal ? "show" : ""}`}
          id="praposalType"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.showSubmitProposal ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header br_btm">
                <h5 className="modal-title text_black_lg" id="exampleModalLabel">
                  Choose Proposal Type
                </h5>
                <button type="button" className="close close_btn" onClick={() => props.changeShowSubmitProposal(false)}>
                  <span aria-hidden="true" className="close_icon">
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row project_type_cards">
                  <div className="col-lg-4 col-sm-6  mb-4 mb-lg-0 cardbox">
                    <div className="card text-center cursor-pointer h-100">
                      <div
                        className="card-body"
                        onClick={(e) => {
                          handleChange("fixed");
                        }}
                      >
                        <div className="icon">
                          <img src={FixedCostImage} alt="" className="icon_img"></img>
                        </div>
                        <h5 className="card-title ">Fixed Scope</h5>
                        <div className="card-text">
                          <p className="mb-2">
                            Engagement involving a defined scope where payments are made on completion of milestones.{" "}
                          </p>
                          {/* <p>Eg: Branding for a brand, 2 minute video</p> */}
                        </div>

                        {/* <div className="checkbox_icon">
                          <input
                            type="checkbox"
                            checked={proposalType === "fixed" ? true : false}
                          />
                          <label for="chk1"></label>
                        </div> */}

                        <div className="cstm_checkbox">
                          <input type="checkbox" checked={proposalType === "fixed" ? true : false} id="chk1" />
                          <label htmlFor="chk1"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0 cardbox">
                    <div className="card text-center cursor-pointer h-100">
                      <div
                        className="card-body"
                        onClick={(e) => {
                          handleChange("retainer");
                        }}
                      >
                        <div className="icon">
                          <img src={RetainerImage} alt="" className="icon_img"></img>
                        </div>
                        <h5 className="card-title">Retainer</h5>
                        <div className="card-text">
                          <p className="mb-2">
                            Engagement involving indefinite scope where payment is made on completion of time (hours/months).{" "}
                          </p>
                          {/* <p>Eg: UX Designer on a 3 month retainer</p> */}
                        </div>
                        {/* <div className="checkbox_icon">
                          <input
                            type="checkbox"
                            checked={proposalType === "retainer" ? true : false}
                          />
                          <label for="chk1"></label>
                        </div> */}

                        <div className="cstm_checkbox">
                          <input type="checkbox" checked={proposalType === "retainer" ? true : false} id="chk2" />
                          <label htmlFor="chk2"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 mb-lg-0 cardbox">
                    <div className="card text-center cursor-pointer h-100">
                      <div
                        className="card-body"
                        onClick={(e) => {
                          handleChange("actual");
                        }}
                      >
                        <div className="icon">
                          <img src={OnActualsImage} className="icon_img"></img>
                        </div>
                        <h5 className="card-title">On Actuals</h5>
                        <div className="card-text">
                          <p className="mb-2">
                            Engagement involving indefinite scope where payment is made on approval of deliverables in every
                            cycle.{" "}
                          </p>
                          {/* <p> Eg: Social media retainer billed basis posts designed every month</p> */}
                        </div>
                        {/* <div className="checkbox_icon">
                          <input
                            type="checkbox"
                            checked={proposalType === "actual" ? true : false}
                          />
                          <label for="chk1"></label>
                        </div> */}
                        <div className="cstm_checkbox">
                          <input type="checkbox" checked={proposalType === "actual" ? true : false} id="chk3" />
                          <label htmlFor="chk3"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex flex-row justify-content-between modal-footer br_tp">
                <div className="review_title">
                  <p className="mb-0 d-flex flex-row">
                    <img src={InfoIcon} alt="" className=" mr-2 mt-1" style={{ width: "1.2rem", height: "1.2rem" }} />
                    <span className="d-flex flex-column">
                      <span>
                        <span className="text_gray_w_600 font-14">Client has selected </span>
                        <span className="font_semi_bold_w_600 font-14">
                          {props?.data?.project_data?.sub_type === "fixed"
                            ? "Fixed Scope"
                            : props?.data?.project_data?.sub_type === "retainer"
                            ? "Retainer"
                            : "On Actuals"}
                        </span>
                      </span>
                      <span className="text_light_gray_xs">
                        You can propose a different project type if it serves the brief better.
                      </span>
                    </span>
                  </p>
                </div>
                <button type="button" onClick={handleSubmit} className="btn btn_continue btn-common mt-4 mt-sm-0">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SubmitProp;
