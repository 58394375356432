import React, { useEffect, useState } from "react";
import { postAPI, putAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

const checkboxList = [
  { text: "Project budget doesn't match my fee rate" },
  { text: "Work not suitable for my portfolio objectives" },
  { text: "I am busy with other projects currently" },
  { text: "I am taking a break from work" },
  { text: "Other" },
];

const checkboxListClient = [
  { text: "I am not satisfied with the creator's proposal" },
  { text: "I am not satisfied with the creator's responses" },
  { text: "I have found another creator on your platform" },
  { text: "I have found another creator outside IndieFolio" },
  { text: "The creator is not responsive" },
  { text: "I have decided to delay the project" },
  { text: "I have decided to cancel the project" },
  { text: "Other" },
];

const RejectBrief = (props) => {
  const [states, setStates] = useState({
    reason: [],
    checkboxList: checkboxList,
    description: "",
    checkboxListClient: checkboxListClient,
  });

  const [loader, setLoader] = useState(false);

  const onChangeHandler = (e, obj) => {
    const isAlreadyChecked = states.reason.find((el) => el.text === obj.text);

    if (isAlreadyChecked) {
      setStates({
        ...states,
        reason: [...states.reason.filter((el) => el.text !== obj.text)],
      });
    } else {
      setStates({ ...states, reason: [...states.reason, { text: obj.text }] });
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (!states.reason.length) {
      alert("Please select at least one reason.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    const { reason, description } = states;
    if (formValidation()) {
      handleSubmitReject({ reason, description });
    }
  };

  const handleSubmitReject = async (data) => {
    setLoader(true);
    let obj = {
      post_project_id: props?.id,
      user_type: props?.CurrentRole === 1 ? "client" : "creator",
      reason: data.reason,
      description: data.description,
      history: props?.history,
    };

    try {
      let response1 = "";
      let response2 = "";
      if (props?.CurrentRole === 1) {
        obj.user_id = props?.userId;
        response1 = await postAPI("/project/client-reject-postproject", obj);
        response2 = await postAPI("/project/reject-creator-client", {
          connection_id: props?.clientOverview ? props?.userPropChat?.chat_connection_id : props?.CurrentChat?.chat_connection_id,
        });
      } else {
        response1 = await postAPI("/project/creator-reject-postproject", obj);
        let obj2 = {
          post_project_id: props?.id,
          status: 2,
        };
        response2 = await putAPI("/project/post-project-artist-approved", obj2);
      }
      if (parseInt(response1.data.status) === 1 && parseInt(response2.data.status) === 1) {
        setLoader(false);
        props.changeShowRejectBrief(false);
        setTimeout(() => {
          alert("Brief Rejected");
          props.history.push(`/dashboard`);
        }, 300);
        // props.callApiAgain()
      } else {
        setLoader(false);
        alert("Something went wrong please try again later");
      }
    } catch (err) {
      setLoader(false);
      alert("Something went wrong please try again later");
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeShowRejectBrief(false);
    }
  };

  let checkboxData = props?.isClient ? checkboxListClient : checkboxList;

  return (
    <React.Fragment>
      <section className="worklog_extension_rejection_wrapper">
        <div
          className={`modal fade modal_fade_bg ${props?.showRejectBrief ? "show" : ""}`}
          style={{ display: props?.showRejectBrief ? "block" : "" }}
          id="worklogextensionrejection"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm m-auto">
            <div className="modal-content br_6">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h5 className="fw_700 fs_20 text-black-700 main_modal_title mb-0" id="exampleModalLabel">
                    Select Reasons For Rejection
                  </h5>
                </div>
                <button
                  type="button"
                  className="close close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.changeShowRejectBrief(false)}
                >
                  {/* <span aria-hidden="true" className="close_icon modalclose-btnicon">
                    ×
                  </span> */}
                </button>
              </div>

              <div className="modal-body pt-4">
                {checkboxData?.length
                  ? checkboxData.map((obj, index) => (
                      <>
                        <div className="row mx-0 mb-3 align-items-center">
                          <div className="col-lg-auto">
                            <div className="cstm_checkbox">
                              <input
                                type="checkbox"
                                id={`checkbox${index}`}
                                checked={states.reason.find((data) => data.text === obj.text) ? true : false}
                                onChange={(e) => onChangeHandler(e, obj)}
                                name={`checkbox${index}`}
                              />
                              <label htmlFor={`checkbox${index}`}></label>
                            </div>
                          </div>
                          <div className="col-lg-10">
                            <p
                              className="mb-0 font_semi_bold_w_600"
                              style={{
                                color: states.reason.find((data) => data.text === obj.text) ? "#000000" : "#9b9b9b",
                                marginLeft: "7px",
                              }}
                            >
                              {obj.text}
                            </p>
                          </div>
                        </div>
                      </>
                    ))
                  : null}
                <div className="row mx-0 mb-3 align-items-center">
                  <div className="col-lg-12 ">
                    <textarea
                      className="form-control upload_notes p-3"
                      name="description"
                      value={states.description}
                      onChange={(e) =>
                        setStates({
                          ...states,
                          description: e.target.value.substring(0, 300),
                        })
                      }
                      placeholder="Describe further (optional)"
                      id="comment"
                      rows="5"
                    ></textarea>
                    <span className="font-weight-500 text-secondary">{states.description?.length}/300</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-end align-items-center py-4">
                <div className="col-lg-12 text-right">
                  <button type="button" class="btn btn_outline_sm fw_700 mr-4" onClick={() => props.changeShowRejectBrief(false)}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn_common_danger_sm fw_700"
                    disabled={!states.reason.length ? true : false}
                    onClick={handleSubmit}
                  >
                    {loader ? <Loader isCreateModal={true} /> : "Reject Brief"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RejectBrief;
