import { fireApi } from "../../../utils/fireApiPrecheck";
import { put, call, all } from "redux-saga/effects";
import { getAPI } from '../../../utils/API';


export function* onFieldChange(data) {
  let value = data.payload;
  yield put({ type: 'UPDATE_' + value.type, values: value.payload });
}

// export function* loadUploadProjectApi(d) {
//   try {
//     const [collateral, repos] = yield all([
//       call(getAPI, '/onboarding/get-user-collateral'),
//       // call(getAPI, '/onboarding/get-user-collateral'),
//     ])
//     // yield put({ type: "UPDATE_LISTCOLLATERAL", values: collateral.data.data })
//   } catch (error) {
//     throw error;
//   }
// }

export function* onloadCollateral() {
  try {
    const list = yield all([
      call(getAPI, '/project/get-user-collateral-approved'),
    ])
    yield put({type:"GET_LOAD_COLLATERAL",values:list[0].data.data});
  } catch (error) {
    throw error;
  }
}

export function* loadSelectedCollateral(data) {
    let value = data.payload;
    yield put({type:'GET_SELECTED_COLLATERAL',values:value});
}

export function* openProjectPopup(data) {
  let values = data.payload;
  try {
    yield put({ type: "OPEN_POPUP", values: values.Component })
  } catch (error) {
    throw error;
  }
}

export function* submitProject(data) {
  let values = data.payload;
  try {
      yield put({type: "SUBMIT_PROJECT",values: values})
  } catch (error) {
    throw error;
  }
}

export function* selectCollateralAnswer(value) {
  try {
      let values = value.payload;
      yield put({ type: "SELECT_COLLATERAL_ANSWER", values: values});
  } catch (error) {
      console.log(error);
  }
}

export function* styleTypeQuestions(value) {
  try {
      let values = value.payload;
    
      yield put({ type: "SET_STYLE_TYPE_QUESTIONS", values: values});
  } catch (error) {
      console.log(error);
  }
}

export function* setCropImages(value) {
  try {
      let values = value.payload;
      // console.log("Preview Src ====>", values.image);
      yield put({ type: "SET_CROP_IMAGE", values: values });
  } catch (error) {
      console.log(error);
  }
}

// export function* updateCropImagesList(value) {
//   try {
//       let values = value.payload;
//       yield put({ type: "SET_CROP_IMAGE_LIST", values: values});
//   } catch (error) {
//       console.log(error);
//   }
// }

export function* loadUserProjectData(value) {
  try {
      let projectData = yield fireApi('GetProjectData', { id: value.payload });
     yield put({type: 'UPDATE_USER_PROJECT_DATA', values: projectData})
  } 
  catch (error) {
      console.log(error)
  }
}