import React from "react";
import { ChatImageBaseUrl } from "../../../../../utils/BaseUrl";
import AllFileViewer from "../../../Common/AllFileViewer/AllFileViewer";
import RandomUrlPreview from "../../../Common/RandomUrlPreview/RandomUrlPreview";
import { isImage, isRandomUrl, modifyURLByPlatform, sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";
import { isViewableFile, renderDifferentFilesThumbnail } from "../../../../authorized/Common/Common/FileRenderMessage";

const CommonViewFile = ({ documentDetail }) => {
  return (
    <div className="card-wrapper mb-0">
      <div className="card upload-image-border pdf-height">
        <div className={`upload-project-big-thumbnail ${isViewableFile(documentDetail?.name) ? "min-h-100-percentage" : ""}`}>
          <>
            {documentDetail?.type === "file" ? (
              isViewableFile(documentDetail?.name) ? (
                <AllFileViewer link={ChatImageBaseUrl + documentDetail?.name} />
              ) : (
                <div
                  className="preview_image_upload"
                  style={{
                    backgroundImage: `url("${isImage(documentDetail?.name) ? ChatImageBaseUrl + documentDetail?.name :renderDifferentFilesThumbnail(documentDetail?.name)}")`,
                    backgroundSize:'contain',
                  }}
                ></div>
              )
            ) : isRandomUrl(documentDetail?.name) ? (
              <RandomUrlPreview url={modifyURLByPlatform(documentDetail?.name)} />
            ) : (
              <iframe
                className="submit-milestone-iframe"
                title="custom-url"
                src={modifyURLByPlatform(documentDetail?.name)}
              ></iframe>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default CommonViewFile;
