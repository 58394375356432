import React from "react";
// import RightIcon from "../../../../Assets/upgrade-images/Sucess/Right_Icon.png";
import SucessIcon from "../../../../../Assets/upgrade-images/Sucess/Sucess.svg";

export default function SuccessAdvancePay({ projectObj, ApiCall, ...props }) {
  let projectData = props?.projectDataStates?.data?.project_data
  return (
    <section className="sucess">
      <div
        className={`modal fade modal_fade_bg show`}
        id="sucess"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered sucess_modal_dialog m-auto">
          <div className="modal-content modal_content_box">
            <div className="modal_top_header d-flex align-items-center  justify-content-between px-4"></div>

            <div className="modal-body sucess_modal_body select_revelant_modal_body">
              <div className="row justify-content-center align-items-center">
                <div className="sucess_content col-lg-8 text-center">
                  <div className="sucess_img mb-4">
                    <img src={SucessIcon} className="img-fluid w-50" />
                    {/* <img src={RightIcon} className="img-fluid"/> */}
                  </div>
                  <h4 className="text_gray_lg_w_800 sucess_title">Woohoo! Proposal Approved!</h4>
                  <p className="mx-auto text_light_gray_md w-60 mb-0">
                    Your proposal has been approved by the Client.
                  </p>
                  <p className="sucess_msg btn-link-text-red-500">Please approved the Final Proposal from your end as well</p>
                  <div className="add_project">
                    <button type="button" class="btn btn-common btn_common_sm btn_sucess" onClick={() => ApiCall(projectData && projectData?.were_admin_project ? 2 : 1)}>
                      Review Final Proposal
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer py-3 border-0 justify-content-between align-items-center"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
