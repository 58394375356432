import axios from 'axios'
import defaultThumbnail from '../Assets/images/video-thumbnail-default.png'
import linkThumbnail from '../Assets/images/link-file.jpg'
import { ChatImageBaseUrl } from './BaseUrl'
import {getImageSize} from 'react-image-size'
import companyLogo from '../Assets/upgrade-images/dashboard/company_logo.svg';

export const slugify = (text) => {
    if (!text || !text.trim().length) return

    return text.trim().replace(/\s+|[,/]/g, "-");
}

export const checkTruthy = (value) => {
    if (value === null || value === undefined || value.length === 0) {
        return false;
    } else {
        return true;
    }
}

export function detectMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

// export const getHeight = (selector) => {
//     console.log('%c getHeight', 'font-size: 22px; color:orange');
//     // console.log(selector);

//     const el = document.querySelector(selector.toString());
//     if(!el) return

//     // console.log(el);
//     const width = el.offsetWidth;
//     const height = width / 1.33;
//     // console.log(width, height);
//     return height;
//   }

export const isURL = (string) => {
     // eslint-disable-next-line
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

export const isTouchDevice = () => {
    if ("ontouchstart" in window) {
        return true;
    }
    return false;
};

export const removeKeys = (obj, keys) => {

}

export const separateNumber = (number, obj) => {

    if (obj && obj.isWords && number >= 1000) {
        let val = Math.abs(number)

        if (val >= 10000000) {
            val = (val % 10000000 > 0 ? (val / 10000000).toFixed(2): val / 10000000) + 'Cr';
        } else if (val >= 100000) {
            val = (val % 100000 > 0 ? (val / 100000).toFixed(2) : val / 100000) + 'L';
        } else if (val >= 1000) {
            val = (val % 1000 > 0 ? (val / 1000).toFixed(2) : val / 1000) + 'K';
        }
        return val;
    }

    const num = parseInt(number, 10)
    let output = num.toLocaleString('en-IN');
    return output;
}

const getVimeoThumbnail = async (videoCode) => {
    const res = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoCode}`);
    const thumbnailImg = await res.data.thumbnail_url
    return thumbnailImg;
}
const getSketchfabThumbnail = async (videoCode) => {
    // const res = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoCode}`);

    const res = await axios.get(`https://api.sketchfab.com/v3/models/${videoCode}`);
    const thumbnailImg = await res.data.thumbnails.images[0].url
    return thumbnailImg;
}

export const getThumbnailImage = async (url, UrlOnly) => {
    if (!url) return

    let videoCode;

    if (url.includes('www.youtube.com') || url.includes('youtu.be')) {

        if (url.includes('youtu.be')) {
            videoCode = url.split('youtu.be/')[1]
            return `https://img.youtube.com/vi/${videoCode}/0.jpg`
        } else {
            videoCode = url.replace('https://www.youtube.com/embed/', '')
            return `https://img.youtube.com/vi/${videoCode}/0.jpg`
        }


    } else if (url.includes('www.dailymotion.com')) {

        videoCode = url.replace('https://www.dailymotion.com/embed/video/', '');
        return `https://www.dailymotion.com/thumbnail/video/${videoCode}`
    }
    else if (url.includes('vimeo.com')) {
        // https://vimeo.com/api/oembed.json?url=https://vimeo.com/76979871
        // "https://player.vimeo.com/video/76979871"
        // videoCode = url.replace('https://player.vimeo.com/video/', '');
        videoCode = url.replace('https://player.vimeo.com/video/', '');
        const thumbnailImg = await getVimeoThumbnail(videoCode)
        return thumbnailImg
    }
    else if (url.includes('sketchfab.com')) {
        videoCode = url.split('.com/models/')[1]
        videoCode = videoCode.split('/embed?')[0]
        const thumbnailImg = await getSketchfabThumbnail(videoCode)
        return thumbnailImg
    }
    else if (url.includes('https://drive.google.com/')) {
        videoCode = url.split('com/file/d/')[1]
        videoCode = videoCode ? videoCode.split('/preview')[0] : '';
        return `https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${videoCode}`
    } else {
        return UrlOnly ? url : isRandomUrl(url) ? linkThumbnail : defaultThumbnail
    }
}

const urlPlatform = (url) => {
    if (!url) return
    if (url.includes('https://drive.google.com/')) {
        return 'google drive'
    } else if (url.includes('www.youtube.com') || url.includes('youtu.be')) {
        return 'youtube'
    } else if (url.includes('sketchfab.com')) {
        return 'sketchfab'
    } else if (url.includes('vimeo.com')) {
        return 'vimeo'
    } else if (url.includes('www.dailymotion.com') || url.includes('https://dai.ly')) {
        return 'dailymotion'
    } else if (url.includes('xd.adobe.com')) {
        return 'adobe'
    } else {
        return 'random'
    }
}

export const isRandomUrl = (url) => {
    return urlPlatform(url) === 'random'
}

// ------------------------------------------------------------+++++------------------------------------------------

export const modifyURLToOriginal = (url) => {
    let videoCode;
    // console.log("modifyURLToOriginal ;;;;;;; ", url)


    if (urlPlatform(url) === 'youtube') {
        if (url.includes('www.youtube.com')) {
            videoCode = url.replace('https://www.youtube.com/embed/', '').split('&')[0]
            return `https://www.youtube.com/watch?v=${videoCode}`
        }

    } else if (urlPlatform(url) === 'vimeo') {

        videoCode = url.replace('https://player.vimeo.com/video/', '');
        return `https://vimeo.com/${videoCode}`

    } else if (urlPlatform(url) === 'dailymotion') {

        if (url.includes('www.dailymotion.com')) {
            videoCode = url.replace('https://www.dailymotion.com/embed/video/', '').split('&')[0];
        }
        return `https://www.dailymotion.com/video/${videoCode}`

    } else if (urlPlatform(url) === 'adobe') {

        videoCode = url.replace('https://xd.adobe.com/embed/', '');
        return `https://xd.adobe.com/view/${videoCode}`

    } else if (urlPlatform(url) === 'google drive') {
        // https://drive.google.com/file/d/15fN6Q-Qp96gZwHeiSI184xcB4J_BV7qX/view?usp=sharing
        return url.replace('preview ', 'view')
    }
    else {
        return url
    }
}


// ------------------------------------------------------------+++++------------------------------------------------


export const modifyURLByPlatform = (url, obj) => {
    let videoCode;
    // console.log("modifyURLByPlatform ;;;;;;; ", url)
    // it will run when there is second parameter
    if (obj && obj.isForPreview) {
        if (url.includes('youtu.be')) {
            videoCode = url.split('youtu.be/')[1]
            return `https://www.youtube.com/embed/${videoCode}`
        } else {
            return url
        }
    }



    if (urlPlatform(url) === 'youtube') {
        if (url.includes('https://www.youtube.com/embed/')) {
            return url
        }
        else if (url.includes('www.youtube.com')) {
            videoCode = url.replace('https://www.youtube.com/watch?v=', '').split('&')[0]
            return `https://www.youtube.com/embed/${videoCode}`
        } else {
            videoCode = url.split('youtu.be/')[1]
            return `https://www.youtube.com/embed/${videoCode}`
        }

    } else if (urlPlatform(url) === 'sketchfab') {

        videoCode = url.substr(url.length - 32, url.length);
        return `https://sketchfab.com/models/${videoCode}/embed?autostart=0&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=1&amp;ui_watermark_link=1`

    } else if (urlPlatform(url) === 'vimeo') {
        let path = ''
        videoCode = url.replace('https://vimeo.com/', '');
        if(videoCode.includes('https://player.vimeo.com/video')){
            path = videoCode
        }else{
            path = `https://player.vimeo.com/video/${videoCode}`
        }
        return path

    } else if (urlPlatform(url) === 'dailymotion') {

        if (url.includes('www.dailymotion.com')) {
            videoCode = url.replace('https://www.dailymotion.com/video/', '').split('&')[0];
        } else {
            videoCode = url.replace('https://dai.ly/', '');
        }
        return `https://www.dailymotion.com/embed/video/${videoCode}`

    } else if (urlPlatform(url) === 'adobe') {

        videoCode = url.replace('https://xd.adobe.com/view/', '');
        return `https://xd.adobe.com/embed/${videoCode}`

    } else if (urlPlatform(url) === 'google drive') {
        // https://drive.google.com/file/d/15fN6Q-Qp96gZwHeiSI184xcB4J_BV7qX/view?usp=sharing
        return url.replace('view', 'preview')
    }
    else {
        return url
    }
}


export const checkAuthentication = (obj) => {
    const emailStatus = obj.email_status;
    const verificationPhone = obj.phone_status;
    const user_role = obj.current_set_role;
    const dashboard_status = obj.review_status;

    let isAuth = false;

    if (emailStatus === 1) {
        if (user_role === 2) {
            if (verificationPhone === 0) {
                isAuth = false;
                // this.setState({
                //   authIs: false,
                //   unVerifiedPhone: true,
                //   userProfile: res.data.data,
                //   unverifiedEmail: false,
                // });
            } else if (
                dashboard_status === 0 ||
                dashboard_status === 1 ||
                dashboard_status === 2 ||
                dashboard_status === 3
            ) {
                isAuth = true;
                // this.setState({
                //   authIs: true,
                //   unVerifiedPhone: false,
                //   completeApplication: dashboard_status,
                //   userProfile: res.data.data,
                // });
            }
        } else if (user_role === 1) {
            isAuth = true
            //   this.setState({ authIs: true, userProfile: res.data.data, unverifiedEmail: false, });
        }
    } else {
        isAuth = false
        // this.setState({
        //   authIs: false,
        //   unverifiedEmail: true,
        //   userProfile: res.data.data,
        // });
    }
    return isAuth;

}

export const removePandBrTag = (txt) => {
    if (!txt) return
    const text = txt.replace(/<[^>]+>/g, " ").replace(/&nbsp;/g, "").replace(/  +/g, ' ')
    // let text = txt.replace(/(?:^<p[^>]*>)|(?:<\/p>$)/g, '')
    // text = text.replace(/<br>/g,'')
    // text = text.replace(/&nbsp;/g, '')
    // text = text.match(/(?:ht|f)tps?:\/\/[-a-zA-Z0-9.]+\.[a-zA-Z]{2,3}(?:\/(?:[^"<=]|=)*)?/g).join(' ')
    return text
}

export const sanitiseSummernoteText = (txt) => {
    if (!txt) return
    // remove br tag from begin and end
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = txt;
    let temp = htmlObject?.innerText
    // let text = temp
    let text = txt.replace(/(?:^<p[^>]*>)|(?:<\/p>$)/g, '')
    text = text.replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, '')
    text = text.replace(/<span[^>]*>|<\/span>/gm, '')
    text = `<p>${text}</p>`
    if (text.includes('target="_blank"')) return text;

    // if there is url but no anchor tag then make it
     // eslint-disable-next-line
    // var urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9+&@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9+&@:%_\+.~#?&//=]*)/;

    var urlRegex = /(?:(?:https?|ftp|file):\/\/|(www)?([-A-Z0-9+&@#\/])*?\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.;]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.;])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.;]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

    const finalText = text.replace(urlRegex, function (url) {
        // console.log(url,'-------------url---------------')
        const urlWithoutHTTP = removeHttps(url)
        return '<a target="_blank" href="http://' + urlWithoutHTTP + '">' + url + '</a>';
    })

    // console.log(finalText)
    return finalText
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export const isImage = (name) => {
    return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name)
}

// get msg text form msg/chat object
export const getMsgText = (msgObj) => {
    switch (msgObj.type.toLowerCase()) {
        case 'plain':
            return removePandBrTag(msgObj.text)

        case 'file':
            return 'Attatchment'

        default:
            return removePandBrTag(msgObj.text)
    }
}

export const limitString = (text, limit) => {
    if (!text || !limit) return '';

    if (text.length < limit) return text;

    const txt = text.substring(0, limit - 3) + '...'
    return txt
}

export const removeHttps = (url) => {
    if (!url && !url.trim()) return
    return url.replace(/^https?:\/\//i, "") 
}

// regex to get href links
// /(?:ht|f)tps?:\/\/[-a-zA-Z0-9.]+\.[a-zA-Z]{2,3}(?:\/(?:[^"<=]|=)*)?/g

// remove http
// .replace(/(^\w+:|^)\/\//, '')

export const removeChatImageBaseUrl = (text) => {
    if (!text) return null

    const array = text.split(ChatImageBaseUrl)
    return array[array.length - 1]
}

export const getListOfTimeIntervals = (startTime, endTime, interval) => {
    // var startTime = "2016-08-10 02:30:00"
    // var endTime = "2016-08-10 04:45:00"
    // debugger

    const round15 = (n) => {
        return Math.ceil(n / 15) * 15
    }

    var parseIn = function (date_time) {
        var d = new Date();
        let hrs = new Date(date_time).toString().substring(16, 18)
        let mins = new Date(date_time).toString().substring(19, 21)

        mins = round15(mins)
        if (mins === 60) {
            mins = 0
            hrs = parseInt(hrs) + 1
        }

        d.setHours(hrs);
        d.setMinutes(mins);
        // console.log(d.getMinutes())
        // debugger
        return d;
    }

    // 24 hours to 12 hours (AM/PM converter)
    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    //make list
    var getTimeIntervals = function (time1, time2) {
        var arr = [];
        while (time1 < time2) {
            arr.push(tConvert(time1.toTimeString().substring(0, 5)));
            time1.setMinutes(time1.getMinutes() + interval);
        }
        return arr;
    }

    startTime = parseIn(startTime);
    endTime = parseIn(endTime);

    return getTimeIntervals(startTime, endTime);

}


export const handleLongString = (string, length)=>{
    if(string.length > 10){
        return `${string.slice(0,length)}...`
    }else{
        return string
    }
}

export const capitalizeWordFirstLetter = (text)=>{
    return text && text.split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
}

export const getCompanyUrl = (url)=>{
    if(url.includes('http')){
      return url
    }else{
      return 'http://'+url
    }
  }

const handleActualQuote = (arr) => {
    let data = arr.map((item) => {
      return { amount: item?.price, name: item?.name };
    });
    return data;
  };

export const getProjectQuote = (proposal,quote, type, retainerFeesType) => {
    let arr = proposal?.milestones ? proposal.milestones : []
    if (type === "retainer") {
      let billing = retainerFeesType?.split("l")?.[0];
      return "₹ " + separateNumber(quote) + "/" + billing;
    } else if (type === "actual") {
      let value =
        handleActualQuote(arr).length > 1
          ? `${separateNumber(handleActualQuote(arr)[0]?.amount)}/${limitString(
              handleActualQuote(arr)[0]?.name,
              15
            )} +${handleActualQuote(arr).length - 1}`
          : `${separateNumber(handleActualQuote(arr)[0]?.amount)}/${limitString(handleActualQuote(arr)[0]?.name, 15)}`;
      return value;
    } else {
      return "₹ " + separateNumber(quote);
    }
  };

export const getImageSizeFromUrl = async(url) =>{
    // console.log(url,'9999999999')
    let newUrl=url
    if(!url?.includes('http')){
        newUrl='http://'+url
    }
    
    try {
        
        const {width, height} = await getImageSize(`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${newUrl}&size=256`);     
        if(width > 16){
            return `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${newUrl}&size=64`
        }
        return false
        } catch(err) {
        console.error(err)
        return false
        }
}

export const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
};