import React from "react";
import ChatFromRoute from "../../../../Chat/ChatFromRoute";

export default function ProjectRoom(props) {
  return (
    <div>
      <ChatFromRoute {...props}/>
    </div>
  );
}
