import React from "react";
import { postAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import { handleStatusDel } from "../CommonFunctionsNew/CommonFunctionsNew";
import { connect } from "react-redux";
import { RazorpayKey, prefill } from "../../../../../utils/BaseUrl";
import moment from "moment";
import { separateNumber } from "../../../../../utils/Helperfunctions";

class ClientPayNow extends React.Component {
  state = {
    isLoading: false,
    method: "",
  };

  componentDidMount = () => {
    const proposal_data = this.props.state?.proposalData;
    this.setState({ method: proposal_data?.payment_method == 1 ? "Razorpay" : "Bank" });
    this.props.loadProjectChat(this.props?.projectDataStates?.data?.project_data);
  };

  handleSubmit = async () => {
    // let isActual = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "actual";
    // let isRetainer = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "retainer";
    let breakup = this.props.mileObj.price;
    // if (isActual) {
    //   breakup = this.handleActualPayAmount();
    // } else if (isRetainer) {
    //   breakup = this.props.mileObj.price;
    // } else {
    //   breakup = this.props.mileObj.breakup;
    // }
    if (this.state.method === "Bank" || breakup == 0) {
      this.handleSubmitBank();
    } else {
      this.handleSubmitRazorpay();
    }
  };

  handleSubmitRazorpay = async () => {
    let isActual = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "actual";
    let isRetainer = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "retainer";
    let breakup = this.props.mileObj.price;
    // if (isActual) {
    //   breakup = this.handleActualPayAmount();
    // } else if (isRetainer) {
    //   breakup = this.props.mileObj.price;
    // } else {
    //   breakup = this.props.mileObj.price;
    // }
    const payment_details = {
      amount: breakup * 100,
      currency: "INR",
      receipt: "#",
      proposal_id: this.props.proposalId,
      milestone_id: this.props?.milestone_id,
      paymentDeliveries_id: isActual || isRetainer ? this.props?.milestone_id : this.props?.mileObj?._id,
      proposal_type: this.props?.proposal_type,
      type: "payment",
    };
    console.log("payment_details == ", breakup, payment_details);
    postAPI("/payment/create-order", payment_details)
      .then((res) => {
        if (res.data.status === "1") {
          // console.log("payment Details===>", res.data);
          this.setState(
            {
              order_id: res.data.data.order_id,
              // proposal_id: res.data.data.proposal_id,
              payment_type: res.data.data.type,
            },
            (e) => this.paymentHandler()
          );
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, isLoading: false });
        alert("Something went wrong, please try again");
      });
  };

  handleActualPayAmount = () => {
    let amount = 0;
    this.props.mileObj.deliverable.forEach((ele) => {
      if (ele.name !== "advance_payment" && ele.type !== "token") {
        amount += ele.price;
      }
    });
    return separateNumber(amount);
  };

  handleStatusDelRet = (status) => {
    return status == 0 ? (
      <p className="mb-0 text_yellow_md_500">In Review</p>
    ) : status == 1 ? (
      <p className="mb-0 text_primary_100_w_600">Approved</p>
    ) : (
      <p className="mb-0 text-red-500_md_w_600">Rejected</p>
    );
  };

  paymentHandler = async () => {
    let isActual = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "actual";
    let isRetainer = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "retainer";
    const options = {
      key: RazorpayKey,
      name: "IndieFolio",
      description: "Kickstart Project payment",
      order_id: this.state.order_id,
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;
          const proposal_id = this.props.proposalId;
          const type = this.state.payment_type;

          let payment_details = {
            payment_id: paymentId,
            order_id: orderId,
            signature: signature,
            proposal_id: proposal_id,
            type: type,
            paymentDeliveries_id: isActual || isRetainer ? this.props?.milestone_id : "",
            milestone_id: this.props?.milestone_id,
          };

          postAPI("/payment/verify-signeture", payment_details)
            .then((res) => {
              if (parseInt(res.data.status) === 1) {
                const obj = {
                  projectId: this.props.ProjectId,
                  projectName: this.props.ProjectName,
                  projectStatus: 6,
                  projectType: this.props.ProjectType,
                  callAllProposalApi: true,
                };
                if (this.props?.history?.location?.pathname?.startsWith("/talentconnect")) {
                  const pdata = this.props?.projectDataStates?.data?.project_data;
                  const objj = {
                    projectId: pdata?._id,
                    projectName: pdata?.project_name,
                    projectStatus: pdata?.status,
                    projectType: pdata?.type,
                    callAllProposalApi: true,
                  };
                  this.props.loadChat(objj);
                  const slug = pdata?.project_slug;
                  this.props.history.push(`/projects/${objj.projectId}/project_room/${slug}`);
                } else {
                  this.props.loadChat(obj);
                }
                this.props.setShowClientPayNow(false);
                this.props.handleCallApiAgain();
              }
            })
            .catch((error) => {
              alert("Something went wrong, please try again");
            });
        } catch (err) {
          alert("Something went wrong, please try again");
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      options.prefill = prefill;
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    this.setState({ ...this.state, isLoading: false });
  };

  handleSubmitBank = async () => {
    let isActual = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "actual";
    let isRetainer = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "retainer";
    let payment_id = "";
    if (isActual || isRetainer) {
      payment_id = this.props?.milestone_id;
    } else {
      payment_id = this.props?.mileObj?._id;
    }
    try {
      const obj = {
        milestone_id: this.props?.milestone_id,
        proposal_id: this.props?.proposalId,
        payment_id,
        proposal_type: this.props?.proposal_type,
        type: "payment",
      };
      const res = await postAPI("/payment/direct-payment", obj);
      //   console.log("res === ", res);
      if (res) {
        this.setState({ ...this.state, isLoading: false });
        this.props.setShowClientPayNow(false);
        alert("Payment Proceed successfully.");
        this.props.handleCallApiAgain();
      }
    } catch (error) {
      this.setState({ ...this.state, isLoading: false });
      alert("Something went wrong, please try again");
    }
  };

  actualItems = (data) => {
    let sum = 0;
    data.forEach((item) => {
      sum += item.AllCount;
    });
    return sum;
  };

  retainerHours = (data) => {
    let sum = 0;
    data.forEach((item) => {
      sum += item?.latestWorkLog?.number_of_hour_worked_on;
    });
    return sum;
  };

  render() {
    const { method, isLoading } = this.state;
    let isActual = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "actual";
    const isRetainer = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "retainer";

    return (
      <>
        <section className="retainer_worklog_payment_wrapper">
          <div
            className={`modal fade modal_fade_bg ${this.props?.showClientPayNow ? "show" : ""}`}
            style={{ display: this.props?.showClientPayNow ? "block" : "" }}
            id="worklogviewfile_2"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg m-auto">
              <div className="modal-content br_6">
                <div className="br_btm modal_top_header px-4 d-flex align-items-center  justify-content-between">
                  <div className="header">
                    <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                      Confirm Payment
                    </h5>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.props.setShowClientPayNow(false)}
                    className="close close_btn cursor-pointer"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="close_icon modalclose-btnicon">
                      ×
                    </span>
                  </button>
                </div>
                <div className="modal-body p-4">
                  <div className="row px-3 mb-3">
                    <div className="col-lg-12 br_btm px-0">
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 font_semi_bold mb-3">
                          Paying for
                          {isActual || isRetainer ? " Cycle" : " milestone"} {this.props.index}
                          {isActual || isRetainer
                            ? ` (${moment(this.props?.mileObj.start_date).format("D MMM")} - ${moment(
                                this.props?.mileObj.end_date
                              ).format("D MMM")})`
                            : null}
                        </p>
                        {isActual || (isRetainer && this.props.state?.proposalData?.retainer_fees_type == "Hourly") ? (
                          <p className="mb-0 text_gray_xs_w_700">
                            Total:{" "}
                            {isActual
                              ? `${this.actualItems(this.props?.mileObj.deliverable)} ${
                                  this.actualItems(this.props?.mileObj.deliverable) == 1 ? "Item" : "Items"
                                }`
                              : `${this.retainerHours(this.props?.mileObj.deliverable)} ${
                                  this.retainerHours(this.props?.mileObj.deliverable) == 1 ? "Hour" : "Hours"
                                }`}
                          </p>
                        ) : null}
                      </div>

                      <div className="extend_overflow_height">
                        <ul className="paying_cycle_list text_gray_xs_w_600 ">
                          {isActual
                            ? this.props?.mileObj.deliverable.map((item, i) => {
                                return (
                                  item.name !== "advance_payment" &&
                                  item.type !== "token" && (
                                    <li className="mb-2">
                                      <div className="d-flex justify-content-between">
                                        <p className="mb-0">{item.name}</p>
                                        <p className="mb-0 text_highlight_sm_w_600">{item.ApprovedCount} Approved</p>
                                      </div>
                                    </li>
                                  )
                                );
                              })
                            : isRetainer
                            ? this.props?.mileObj.deliverable.map((item, i) => {
                                return (
                                  <li className="mb-2">
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-0">
                                        {moment(item.latestWorkLog.start_duration).format("D MMM")} -{" "}
                                        {moment(item.latestWorkLog.end_duration).format("D MMM")}
                                      </p>
                                      {this.props.state?.proposalData?.retainer_fees_type == "Hourly" ? (
                                        <p className="mb-0 text_highlight_sm_w_600">
                                          {this.handleStatusDelRet(item.latestWorkLog.status)}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </li>
                                );
                              })
                            : this.props?.mileObj.names.map((item, i) => {
                                return (
                                  <li className="mb-2">
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-0">
                                        {item.name} {item.iterations > 1 ? `(${item.iterations} Iterations)` : ""}
                                      </p>
                                      <p className="mb-0 text_highlight_sm_w_600">{handleStatusDel(item)}</p>
                                    </div>
                                  </li>
                                );
                              })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row px-3">
                    <div className="col-lg-12 px-0 mb-3">
                      <p className="mb-0 font_semi_bold">Payment Mode</p>
                    </div>
                    <div className="col-lg-6 pl-lg-0 mb-3">
                      <div
                        className={`payment_card  ${method === "Razorpay" ? "active" : ""} d-flex cursor-pointer`}
                        onClick={() => this.setState({ ...this.state, method: "Razorpay" })}
                      >
                        <div className="radio_btn pr-3">
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              checked={method === "Razorpay" ? true : false}
                            />
                          </div>
                        </div>
                        <div className="payment_message">
                          <p className="mb-2 font_semi_bold_w_600">Razorpay</p>
                          <p className="mb-2 text_light_gray_md">Use this option to make the payment immediately using debit card/credit card/UPI/net banking.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 pr-lg-0 mb-3">
                      <div
                        className={`payment_card ${method === "Bank" ? "active" : ""} d-flex cursor-pointer`}
                        onClick={() => this.setState({ ...this.state, method: "Bank" })}
                      >
                        <div className="radio_btn pr-3">
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              checked={method === "Bank" ? true : false}
                            />
                          </div>
                        </div>
                        <div className="payment_message">
                          <p className="mb-2 font_semi_bold_w_600">Bank Transfer</p>
                          <p className="mb-2 text_light_gray_md">Use this option to facilitate payments outside of the platform using a bank transfer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex total_details_card align-items-center justify-content-between mx-0 mb-2  px-4 py-3 amount_tag ">
                  <div className="title">
                    <p className="mb-0 font_semi_bold">Amount Payable</p>
                  </div>
                  <div className="total_amount">
                    <p className="mb-0 text_gray_md_w_700">
                      {`₹ ${
                        // isActual
                        //   ? this.handleActualPayAmount()
                        //   : isRetainer
                        //   ? separateNumber(this.props.mileObj.price)
                        //   : 
                          
                          separateNumber(this.props.mileObj.price_without_tax)
                      }`}
                      {this.props.mileObj?.tax > 0 ? <span className="d-block text_light_gray_xs text-right">+18% GST </span> : null }
                    </p>
                  </div>
                </div>
                {/* <div className="row px-3 br_tp mx-0 d-flex justify-content-center">
                  <div className="col-lg-12 px-0 email_grouped">
                    <div class="input-group br_border-gray-1000 br_6 mr-sm-2">
                      <input
                        type="text "
                        class="form-control border-0 font_semi_bold_w_600 py-4"
                        id="inlineFormInputGroupUsername2"
                        value="joedoe@gmail.com"
                      />
                      <div class="input-group-prepend email_group_prepend mx-0">
                        <div class="input-group-text px-4 border-0">
                          <p className="mb-0 blue_text_md_w_700">
                            <span className="align-bottom">
                              <img src={Export} className="mr-3 " />
                            </span>
                            Email POC
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="modal-footer justify-content-end align-items-center py-4 border-0">
                  <div className="col-lg-12 text-right">
                    <button type="button" class="btn btn_common_md fw_700" onClick={this.handleSubmit}>
                      {isLoading ? <Loader isCreateModal={true} /> : "Proceed"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let creatorProfile = store.creatorProfile;
  let postProjectData = store.postProject;
  return {
    closeModal: creatorProfile.closeModal,
    current_proposal_id: postProjectData.current_proposal_id,
    proposal_data: postProjectData.review_proposal_data,
    CurrentChat: chat.CurrentChat,
    ProjectName: chat.ProjectName,
    ProjectStatus: chat.ProjectStatus,
    ProjectType: chat.ProjectType,
    ProjectId: chat.ProjectId,
  };
};
const mapDispatch = (dispatch) => ({
  loadChat: (obj) => dispatch({ type: "UPDATE_LOAD_CHAT", payload: obj }),
  loadProjectChat: (obj) =>
    dispatch({
      type: "LOAD_PROJECT_CHAT",
      payload: { projectId: obj?._id, projectName: obj?.project_name, projectStatus: obj?.status },
    }),
});

export default connect(mapState, mapDispatch)(ClientPayNow);
