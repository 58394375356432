import React, { Component } from 'react';
import Clients from './Clients';

export default class AboutMe extends Component {
    countWidth=(width)=>{
        // console.log(width);
        if(width === 0){ 
            return 12
        }else if(width === 1){
            return 6
        }else if(width === 2){
            return 4
        }else{
            return width
        }
    }
    render() {
        const PrimaryFieldWork = this.props?.PrimaryFieldWork;
        const skills = this.props.Specilizations;
        const tools = this.props.Tools;
        const languages = this.props.Languages;
        // const bio = this.props.bio;
        const selectedCreatorData = this.props;
        const viewWidth = (skills?.length > 0 ? 1 : 0) + (tools?.length > 0 ? 1 : 0 ) +( languages?.length > 0 ? 1 : 0) ;
        //console.log(skills.length, tools.length, languages.length,'ttt')
        
        
        return (
            <React.Fragment>
                <section className="bg-white p-4 border-radius-8">
                    {/* <h2 className="font-16 font-weight-800 pb-15 mb-0">About me</h2> */}
                    <div className="row">
                        {/* <div className="col-sm-12 col-lg-7 pl-0 ipad-p-0">
                            <p className="profile-subTitle line-height-14 font-14 font-weight-500">{bio}</p>
                          
                        </div> */}
                        <div className={`col-sm-6 col-lg-${this.countWidth(viewWidth)}`}> 
                            <Clients data={selectedCreatorData} />
                        </div> 
                        <div className={`col-sm-6 col-lg-${this.countWidth(viewWidth)}`}> 
                            {skills && <div className="mb-2">
                                <h3 className="font-16 font-weight-800 pb-15 mb-0">Specializations</h3>
                                <div className="d-flex align-items-center flex-wrap">
                                    {PrimaryFieldWork &&  <span className="tags">{PrimaryFieldWork}</span>}
                                    {skills.map(el => {
                                        return (
                                            <span className="tags" key={el._id}>{el.name}</span>
                                        )
                                    })}
                                </div>
                            </div>}  
                        </div>
                        <div className={`col-sm-6 col-lg-${this.countWidth(viewWidth)}`}> 
                            {tools && <div className="mb-2">
                                <h3 className="font-16 font-weight-800 pb-15 mb-0">Tools</h3>
                                <div className="d-flex align-items-center flex-wrap">
                                    {tools.map(el => {
                                        return (
                                            <span className="tags" key={el._id}>{el.name}</span>
                                        )
                                    })}
                                </div>
                            </div>}
                        </div>
                        <div className={`col-sm-6 col-lg-${this.countWidth(viewWidth)}`}> 
                            {languages && <div className="mb-2">
                                <h3 className="font-16 font-weight-800 pb-15 mb-0">Languages</h3>
                                <div className="d-flex align-items-center flex-wrap">
                                    {languages.map(el => {
                                        return (
                                            <span className="tags" key={el}>{el}</span>
                                        )
                                    })}
                                </div>
                            </div>}
                        </div> 
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
