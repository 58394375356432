import React, {Component} from 'react';

class Loader extends Component {
    render(){
        const {isPasswordModal, isCreateModal, profileCreator, isAccountSettings} = this.props
        return(
            <>
            <div className={`common-wrapper ${isPasswordModal ? 'common-password' : ''} ${isCreateModal ? 'common-create' : ''} ${profileCreator ? 'common-profile' : ''} ${isAccountSettings ? 'account-settings' : ''}`}>
                <div className="common-loader"></div>
            </div>
            </>
        )
    }
}

export default Loader;