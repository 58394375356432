import React, { Component, useState, useEffect } from "react";
import { getThumbnailImage } from "../../../../../utils/Helperfunctions";
import {
  isViewableFile,
  renderDifferentFilesThumbnail,
} from "../../../Common/Common/FileRenderMessage";

export class ServicesOffered extends Component {
  state = {
    activeTab: 'all',
    showMore: false,
    mobileDropdownShow:false,
    counter: 0
  };
  
  handleActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };
  
  getSelectedTabName = (aT,collateral) =>{
    if(aT === 'all'){
      return 'All'
    } else {
      return collateral.find(el => el.collateral_id === aT).collateral_name
    }
  }

  handleMobileDropdown=(mobileDropdownShow)=>{ 
    this.setState({ mobileDropdownShow });
  }

  onPrevAndNext = (action) => {
    const {counter} = this.state;
    const {Collateral} = this.props;
    action === 'prev' && counter > 0 && this.setState({counter: counter - 1});
    action === 'next' && counter < Collateral.length - 5 && this.setState({counter: counter + 1});
  }

  componentDidMount(){
    this.props.ResetCollateralBasedProjects();
    this.setState({ showMore: false, activeTab: 'all',mobileDropdownShow:false });
    // this.props.loadAllProjects();
  }
  
  render() {
    const { activeTab, showMore,mobileDropdownShow, counter } = this.state;

    const { AllProjectsData, ProjectsData, Collateral } = this.props;
    const newCollaterList = counter === 0 ? Collateral : [...Collateral].splice(counter);
    const disableLeft = counter === 0;
    const disableRight = counter >= Collateral.length - 5;
    // console.log("AllProjectsData = ",AllProjectsData,ProjectsData)
    return (
      <React.Fragment>
        <section className="briefServices bg-white border-radius-8">
          <h2 className="font-16 font-weight-800 px-4 py-15 mb-0">
            Services Offered
          </h2>
          <div className="main-content mob-p-15 ipad-p-15  position-relative border-radius-8">
            <div className="mobile-ipad-none">
              <div className={`prev_tabs`} onClick={() => this.onPrevAndNext('prev')}>
                <i className={`fas fa-chevron-left  ${disableLeft ? 'text-sub-9' : ''}`} />
              </div>
              <ul
                className="nav nav-tabs bor-bottom project_nav_tabs"
                id="myTab"
                role="tablist"
              >
                {AllProjectsData && (
                  <li className="nav-item">
                    <a
                      className={`nav-link text-capitalize ${
                        activeTab === 'all' ? `active` : ``
                      }`}
                      id="allProjects"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.ResetCollateralBasedProjects();
                        this.setState({ showMore: false, activeTab: 'all' });
                        // this.props.loadAllProjects();
                      }}
                    >
                      All
                    </a>
                  </li>
                )}
                {newCollaterList &&
                  newCollaterList.map((el, i) => {
                    return (
                      <li className="nav-item" key={el.collateral_id}>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.ResetCollateralBasedProjects();
                            this.setState({
                              showMore: false,
                              activeTab: el.collateral_id,
                            });
                            this.props.LoadCollateralBasedProjects(
                              el.collateral_id, 'ServicesOffered'
                            );
                          }}
                          className={`nav-link text-capitalize ${
                            activeTab === el.collateral_id ? `active` : ``
                          }`}
                        >
                          {el.collateral_name}
                        </a>
                      </li>
                    );
                  })}
              </ul>
              <div className="next_tabs" onClick={() => this.onPrevAndNext('next')}>
                <i className={`fas fa-chevron-right ${disableRight ?  'text-sub-9' : ''}`} />
              </div>
            </div>
            <div className="ipad-only-show message-folder custom_form">
              <div className="row m-0">
                <div className="filter-dropdown col-12 p-0">
                  <div className="select">
                    <div className="select">
                      <div
                        type="text"
                        className="form-control email select-styled"
                        onClick={()=>this.handleMobileDropdown(!mobileDropdownShow)}
                      >
                      {this.getSelectedTabName(activeTab,Collateral)}
                      </div>
                      <ul
                        className="select-options"
                        style={{ display: mobileDropdownShow ? "block" :"none"}}
                      >
                        <li rel="hide">Choose here</li>
                        {AllProjectsData.length ? (
                          <li
                            className={`${activeTab === 'all' ? `active` : ``}`}
                            onClick={() => {
                              this.props.ResetCollateralBasedProjects();
                              this.setState({ showMore: false, activeTab: 'all',mobileDropdownShow:false });
                              this.props.loadAllProjects();
                            }}
                          >
                            All
                          </li>
                        ) : null}
                        {Collateral &&
                          Collateral.map((el, i) => {
                            return (
                              <li
                                key={el.collateral_id}
                                className={`${
                                  activeTab === el.collateral_id ? `active` : ``
                                }`}
                                onClick={() => {
                                  this.props.ResetCollateralBasedProjects();
                                  this.setState({
                                    showMore: false,
                                    activeTab: el.collateral_id,
                                    mobileDropdownShow:false
                                  });
                                  this.props.LoadCollateralBasedProjects(
                                    el.collateral_id, 'ServicesOffered'
                                  );
                                }}
                              >
                                {el.collateral_name}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  <i className="fas fa-chevron-down select-arrow without-label" />
                </div>
              </div>
            </div>
            <ol className="content custom_form">
              {activeTab === 'all' ? (
                <React.Fragment>
                  {AllProjectsData.map((el, i) => {
                    return (
                      <React.Fragment key={i}>
                        {showMore ? (
                          <ProjectItem
                            title={el.project_data.title}
                            images={el.project_data.images_or_links}
                            onClickViewProjects={this.props.onClickViewProjects}
                            selectedCreatorData={this.props.selectedCreatorData}
                            eldata={el}
                          />
                        ) : i < 3 ? (
                          <ProjectItem
                            title={el.project_data.title}
                            images={el.project_data.images_or_links}
                            onClickViewProjects={this.props.onClickViewProjects}
                            selectedCreatorData={this.props.selectedCreatorData}
                            eldata={el}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {ProjectsData.map((el, i) => {
                    return (
                      <React.Fragment key={i}>
                        {showMore ? (
                          <ProjectItem
                            title={el.project_data.title}
                            images={el.project_data.images_or_links}
                            onClickViewProjects={this.props.onClickViewProjects}
                            eldata={el}
                          />
                        ) : i < 3 ? (
                          <ProjectItem
                            title={el.project_data.title}
                            images={el.project_data.images_or_links}
                            onClickViewProjects={this.props.onClickViewProjects}
                            eldata={el}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
            </ol>
            <div className="text-center pb-3">
              {showMore ? (
                <a
                  href="/"
                  onClick={(e) => {e.preventDefault(); this.setState({ showMore: false })}}
                  className="text-uppercase text-success font-weight-500 font-12"
                >
                  VIEW SOME PROJECTS{" "}
                  <i className="fa fa-angle-up ml-2" aria-hidden="true"></i>
                </a>
              ) : (
                <a
                  href="/"
                  onClick={(e) => {e.preventDefault(); this.setState({ showMore: true })}}
                  className="text-uppercase text-success font-weight-500 font-12"
                >
                  VIEW ALL PROJECTS{" "}
                  <i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                </a>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default ServicesOffered;

const RenderImage = ({ image }) => {
  const [thumbnail, setThumbnail] = useState(null);
  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url);
    };
    if (image.type === "url") {
      getThumbnailImageFunc(image.value);
    } else {
      setThumbnail(image.value);
    }
  }, [image]);

  return (
    <div
      className="project-view"
      style={{
        backgroundImage: `url("${
          isViewableFile(image.value)
            ? renderDifferentFilesThumbnail(image.value)
            : thumbnail
        }")`,
      }}
    />
  );
};

const ProjectItem = ({ title, images, onClickViewProjects, eldata,selectedCreatorData }) => {
  // console.log("fffewfvklvkvm", )
  const getThumbnailObj = (images_or_links) => {
    const thumbnailObj = images_or_links.find((el) => el.isThumbnail);
    const secondsteptogetimage = images_or_links.filter(
      (el) => el.image_original
    );
    const thirdsteptogetimage = images_or_links.filter((el) => el.value);
      return thumbnailObj
      ? thumbnailObj
      :  thirdsteptogetimage[0];
    // return thumbnailObj
    //   ? thumbnailObj
    //   : secondsteptogetimage.length
    //   ? secondsteptogetimage[0]
    //   : thirdsteptogetimage[0];
  };
  return (
    <li
      className="team shot-thumbnail cursor-pointer"
      onClick={() => onClickViewProjects({...eldata,username:selectedCreatorData?.creator_id?.username})}
    >
      <div className="multi-shot">
        <div className="content-shot">
          <div className="content-img">
            <a className="content-link" href="#/">
              <RenderImage image={getThumbnailObj(images)} />
            </a>
          </div>
        </div>
      </div>
      <p className="d-line w-100 font-16 mob-font-14 font-weight-600 py-2 pl-2 mb-0 text-capitalize" style={{wordBreak: 'break-word'}}>
        {title}
      </p>
    </li>
  );
};
