import { getAPI, postAPIM, postAPI, putAPI, deleteAPI } from './API';
import _ from 'lodash';
import ValidateAction from './validation';

let Actions = {
  GetUserData: {
    Url: '/users/creator-profile-public',
    Method: 'GET',
  },
  GetUserDataPublic: {
    Url: '/users/creator-profile-public-global?username=$username',
    Method: 'GET',
  },
  GetUserEducation: {
    Url: '/Onboarding/get-user-education',
    Method: 'GET',
  },
  GetUserEducationPublic: {
    Url: '/Onboarding/get-user-education-global?username=$username',
    Method: 'GET',
  },
  GetUserSoftwareSpecialization: {
    Url: '/users/user-software-specialization',
    Method: 'GET',
  },
  GetUserAllProjects: {
    Url: '/project/all-user-project-list',
    Method: 'GET',
  },
  GetUserAllProjectsPublic: {
    Url: '/project/all-user-project-list-global?username=$username',
    Method: 'GET',
  },
  GetUserSoftwareSpecializationPublic: {
    Url: '/users/user-software-specialization-global?username=$username',
    Method: 'GET',
  },

  GetUserCollateral2: {
    Url: '/project/get-user-collateral-approved-done',
    Method: 'GET',
  },

  GetUserCollateral2Public: {
    Url: '/project/get-user-collateral-approved-done-global?username=$username',
    Method: 'GET',
  },
  GetUserProjects: {
    Url: '/project/get-user-project-list',
    Method: 'GET',
  },
  GetUserProjectsPublic: {
    Url: '/project/get-user-project-list-global?username=$username',
    Method: 'GET',
  },
  DeleteUserProject: {
    Url: '/project/delete-user-project',
    Method: 'DELETE',
  },
  GetUserCollateral: {
    Url: '/project/get-user-collateral-approved',
    Method: 'GET',
  },
  UploadUserImage: {
    Url: '/users/profile-image',
    Method: 'POSTM',
  },
  UploadUserResume: {
    Url: '/onboarding/user-resume',
    Method: 'POSTM',
  },
  UpdateUserBasic: {
    Url: '/users/creator-basic-details',
    Method: 'PUT',
  },
  GetSpecialization: {
    Url: '/specialization?name=$name',
    Method: 'GET',
  },
  GetSoftware: {
    Url: '/software?name=$name',
    Method: 'GET',
  },
  GetIndustry: {
    Url: '/industry?name=$name',
    Method: 'GET',
  },
  GetCompany: {
    Url: '/company?name=$name',
    Method: 'GET',
  },
  GetCreatorClients: {
    Url: '/users/get-creator-client',
    Method: 'GET',
  },
  GetCreatorClientsPublic: {
    Url: '/users/get-creator-client-global?username=$username',
    Method: 'GET',
  },
  GetAwards: {
    Url: '/users/get-user-award-list',
    Method: 'GET',
  },
  GetAwardsPublic: {
    Url: '/users/get-user-award-list-global?username=$username',
    Method: 'GET',
  },
  GetCollaterals: {
    Url: '/collateral?name=$name',
    Method: 'GET',
  },
  GetCreatorCollaterals: {
    Url: '/project/get-user-collateral-creator?_id=$id',
    Method: 'GET',
  },
  GetCollateralsVisible: {
    Url: '/collateral-visible?name=',
    Method: 'GET',
  },
  CollateralDetail: {
    Url: '/collateral/get-collateral?_id=$_id',
    Method: 'GET',
  },
  GetQuestions: {
    Url: '/project/get-question?_id=$id',
    Method: 'GET',
  },
  GetQuestionsInCreatorProfile: {
    Url: '/project/get-question?_id=$id',
    Method: 'GET',
  },
  GetCollaterAnswersInCreatorProfile: {
    Url: '/onboarding/get-user-collateral-single?_id=$id',
    Method: 'GET',
  },
  GetArtists: {
    Url: '/project/get-artist-post-project?_id=$id',
    Method: 'GET',
  },
  GetUserWork: {
    Url: '/Onboarding/get-user-work',
    Method: 'GET',
  },
  GetUserWorkPublic: {
    Url: '/Onboarding/get-user-work-global?username=$username',
    Method: 'GET',
  },
  GetLocation: {
    Url: '/location?name=$name',
    Method: 'GET',
  },
  UpdateCurrentWorkStatus: {
    Url: '/onboarding/current-work-status',
    Method: 'POST',
  },
  GetWebLinks: {
    Url: '/onboarding/get-web-links',
    Method: 'GET',
  },
  GetWebLinksPublic: {
    Url: '/onboarding/get-web-links-global?username=$username',
    Method: 'GET',
  },
  UpdateWebLinks: {
    Url: '/onboarding/add-web-links',
    Method: 'POST',
  },
  AddSoftwareSpecialization: {
    Url: '/users/add-software-specialization',
    Method: 'POST',
  },
  AddUserWork: {
    Url: '/onboarding/add-user-work',
    Method: 'POST',
  },
  AddUserWorkSecond: {
    Url: '/onboarding/add-user-work',
    Method: 'POST',
  },
  UpdateUserWork: {
    Url: '/onboarding/update-user-work',
    Method: 'POST',
  },
  UpdateUserWorkSecond: {
    Url: '/onboarding/update-user-work',
    Method: 'POST',
  },
  UserAddCollateral: {
    Url: '/onboarding/add-user-collateral',
    Method: 'POST',
  },
  UpdateUserCollateral: {
    Url: '/onboarding/update-user-collateral',
    Method: 'POST',
  },
  DeleteUserCollateral: {
    Url: '/onboarding/delete-user-collateral?_id=$id',
    Method: 'GET',
  },
  DeleteUserCollateralFromProfile: {
    Url: '/onboarding/delete-user-collateral-profile?_id=$id',
    Method: 'GET',
  },
  AddUserEducation: {
    Url: '/onboarding/add-user-education',
    Method: 'POST',
  },
  UpdateUserEducation: {
    Url: '/onboarding/update-user-education',
    Method: 'POST',
  },
  ProjectRequestReview: {
    Url: '/project/project-request-review',
    Method: 'POST',
  },
  UpdateUserSoftware: {
    Url: '/users/update-software',
    Method: 'PUT',
  },
  UpdateUserSpecialization: {
    Url: '/users/update-specialization',
    Method: 'PUT',
  },
  UpdateUserLanguage: {
    Url: '/users/user-language',
    Method: 'PUT',
  },
  AddUserAwards: {
    Url: '/users/add-user-award',
    Method: 'POST',
  },
  UpdateUserAwards: {
    Url: '/users/update-user-award',
    Method: 'PUT',
  },
  DeleteUserAwards: {
    Url: '/users/delete-user-awards ',
    Method: 'DELETE',
  },
  DeleteUserWork: {
    Url: '/onboarding/delete-user-work?_id=$id',
    Method: 'GET',
  },
  DeleteUserEducation: {
    Url: '/onboarding/delete-user-education?_id=$id',
    Method: 'GET',
  },
  GetChatUserList: {
    Url: '/chat/get-chat-user-list?post_project_id=$id',
    Method: 'GET',
  },
  GetChatUserListAdminProject: {
    Url: '/chat/get-chat-user-list-admin-project?post_project_id=$id',
    Method: 'GET',
  },
  GetChatMessages: {
    Url: '/chat/get-chat-message-list?chat_connection_id=$id',
    Method: 'GET',
  },
  GetAdminChatMessages: {
    Url: '/chat/get-dadmin-chat-message-list?chat_connection_id=$id',
    Method: 'GET',
  },
  UploadChatFiles: {
    Url: '/chat/upload-chat-files',
    Method: 'POSTM',
  },
  GetIncompleteBriefs: {
    // Url: '/project/get-client-post-project-list?search=',
    Url: '/project/get-client-post-project-list-new?search=',
    Method: 'GET',
  },
  // ======admin======
  GetIncompleteBriefsAdmin: {
    // Url: '/project/get-admin-post-project-list-chat-admin',
    Url: '/project/get-admin-post-project-list-new',
    Method: 'GET',
  },
  GetIncomingBriefs: {
    Url: '/project/get-creator-post-project-list-new?search=',
    Method: 'GET',
  },
  GetPostProjectData: {
    Url: '/project/get-post-project?_id=$id',
    Method: 'GET',
  },
  GetUserCompanyDetail: {
    Url: '/users/get-company-details?userId=$id',
    Method: 'GET',
  },
  GetUserMilestones : {
    Url: '/milestone/users-milestones',
    Method: 'GET',
  },
  GetJobs : {
    Url: '/project/get-creator-project-job-list?collateral_id=$id',
    Method: 'GET',
  },
  GetProjectData: {
    Url: '/project/get-user-project?_id=$id',
    Method: 'GET',
  },
  GetProjectDataPublic: {
    Url: '/project/get-user-project-global?_id=$collateral_id',
    Method: 'GET',
  },
  GetBriefData: {
    Url: '/project/get-post-project-details-brief?post_project_id=$id',
    Method: 'GET',
  },
  ViewIncomingBrief: {
    Url: '/project/post-project-view-status?post_project_id=$id',
    Method: 'GET',
  },
  AcceptAndRejectIncomingBrief: {
    Url: '/project/post-project-artist-approved',
    Method: 'PUT',
  },
  RejectIncomingBriefResason: {
    Url: '/project/creator-reject-postproject',
    Method: 'POST',
  },
  DeleteIncompleteBrief: {
    Url: '/project/delete-postproject/$id',
    Method: 'DELETE',
  },
  GetCollateralBasedProjects: {
    Url: '/project/get-collateral-base-project-all?collateral_id=$id',
    Method: 'GET',
  },
  GetPostProjectClientBriefRequest: {
    Url: '/project/post-project-client-breif?post_project_id=$post_project_id',
    Method: 'GET',
  },
  GetPostProjectClientBriefPublicRequest: {
    Url: '/project/post-project-client-breif-public?post_project_id=$post_project_id',
    Method: 'GET',
  },
  GetCreatorProposalProfile: {
    Url: '/project/creator-proposal-profile?post_project_id=$post_project_id&creator_id=$creator_id',
    Method: 'GET',
  },
  GetCollateralBasedProjectsPublic: {
    Url: '/project/get-collateral-base-project-all-global?collateral_id=$collateral_id&username=$username',
    Method: 'GET',
  },
  GetApprovedCollateralBasedProjects: {
    Url: '/project/get-collateral-base-project?collateral_id=$id',
    Method: 'GET',
  },
  GetProjectChatClient: {
    Url: '/project/get-client-post-project-list-chat',
    Method: 'GET',
  },
  GetArtistAndClient: {
    Url: '/chat/get-admin-chat-user?post_project_id=$id',
    Method: 'GET',
  },
  GetAdminProjectChatClient: {
    Url: '/project/get-admin-user-project-list',
    Method: 'GET',
  },
  GetProjectChatCreator: {
    Url: '/project/get-artist-post-project-list-chat',
    Method: 'GET',
  },
  GetCreatorList: {
    Url: '/project/get-project-send-brief?post_project_id=$id',
    Method: 'GET',
  },
  getProposalDataCreator: {
    Url: '/milestone/latest-proposals?post_project_id=$id',
    Method: 'GET',
  },
  getProposalDataClient: {
    Url: '/milestone/ongoing-proposals?post_project_id=$id',
    Method: 'GET',
  },
  GetInviteMoreCreatorList: {
    Url: '/project/inivit-new-recommended-artist',
    Method: 'POST',
  },
  GetUserReviews: {
    Url: '/project/project-review-list',
    Method: 'GET',
  },
  GetUserReviewsPublic: {
    Url: '/project/project-review-list-global?username=$username',
    Method: 'GET',
  },
  ProjectReview: {
    Url: '/project/add-post-project-rating',
    Method: 'POST',
  },
  PlateformReviewToCompany: {
    Url: '/project/end-question-postproject',
    Method: 'POST',
  },
  PostProjectDetailsForReview: {
    Url: '/project/closed-project-details?post_project_id=$id',
    Method: 'GET',
  },
  ClearMessageNotification: {
    Url: '/users/user-msg-clear?type=$type',
    Method: 'GET',
  },
  ResetNotification: {
    Url: '/chat/live-chat?chat_connection_id=$id&user_type=$user_type',
    Method: 'GET',
  },
  ClearDashboardNotification: {
    Url: '/users/user-dashboard-clear?type=$user_type',
    Method: 'GET',
  },
  ClearCompletedProjectNotification: {
    Url: '/users/user-dashboard-complete-clear?type=$user_type',
    Method: 'GET',
  },
  ToggleCreatorAvailability: {
    Url: '/users/creator-available',
    Method: 'PUT'
  },
  GetUserIndustries:{
    Url:'/project/user-industry',
    Method:'GET'
  }
};

let Validations = {
  UpdateUserBasic: {
    first_name: [1, 'FirstName', '', 'Please provide FirstName', 'FirstName'],
    last_name: [1, 'LastName', '', 'Please provide LastName', 'LastName'],
    profile_image: [1, 'String', '', 'Please provide AvatarImage', 'Avatar'],
    primary_specialization: [
      1,
      'String',
      '',
      'Please provide PrimaryFieldWork',
      'PrimarySpecialization',
    ],
    location: [1, 'String', '', 'Please provide Location', 'Location'],
    bio: [1, 'String', '', 'Please provide About', 'About'],
  },
  UserAddCollateral: {
    name: [1, 'String', '', 'Please provide Collateral', 'Collateral'],
    // year: [1, 'Number', '', 'Please provide Experience Year', 'Experience'],
    // month: [
    //   0,
    //   'Number',
    //   '',
    //   'Please provide Experience Month',
    //   'ExperienceMonth',
    // ],
    time: [1, 'Number', '', 'Please provide Time', 'Time'],
    type: [1, 'String', '', 'Please provide Experience Type', 'Type'],
    start_price: [1, 'Number', '', 'Please provide StartPrice', 'StartPrice'],
    end_price: [1, 'Number', '', 'Please provide EndPrice', 'EndPrice'],
  },
  UpdateUserCollateral: {
    name: [1, 'String', '', 'Please provide Collateral', 'Collateral'],
    // year: [1, 'Number', '', 'Please provide Experience Year', 'Experience'],
    // month: [
    //   0,
    //   'Number',
    //   '',
    //   'Please provide Experience Month',
    //   'ExperienceMonth',
    // ],
    time: [1, 'Number', '', 'Please provide Time', 'Time'],
    type: [1, 'String', '', 'Please provide Experience Type', 'Type'],
    start_price: [1, 'Number', '', 'Please provide StartPrice', 'StartPrice'],
    end_price: [1, 'Number', '', 'Please provide EndPrice', 'EndPrice'],
    _id: [1, 'String', '', 'Please Select Collateral', 'ID'],
  },
  AddUserEducation: {
    name: [1, 'String', '', 'Please provide School', 'School'],
    course: [1, 'String', '', 'Please provide Course', 'Course'],
    start_month: [1, 'String', '', 'Please provide StartMonth', 'StartMonth'],
    start_year: [1, 'Number', '', 'Please provide StartYear', 'StartYear'],
    end_month: [1, 'String', '', 'Please provide EndMonth', 'EndMonth'],
    end_year: [1, 'Number', '', 'Please provide EndYear', 'EndYear'],
  },
  AddUserWork: {
    name: [1, 'String', '', 'Please provide Company', 'Company'], // Company,
    designation: [1, 'String', '', 'Please provide Designation', 'Designation'], // Designation,
    start_month: [1, 'String', '', 'Please provide StartMonth', 'StartMonth'], // StartMonth,
    start_year: [1, 'Number', '', 'Please provide StartYear', 'StartYear'], // StartYear,
    end_month: [0, 'String', '', 'Please provide EndMonth', 'EndMonth'], // EndMonth,
    end_year: [0, 'Number', '', 'Please provide EndYear', 'EndYear'], // EndYear,
    location: [1, 'String', '', 'Please provide Location', 'Location'], // Location,
    industry: [1, 'String', '', 'Please provide Industry', 'Industry'], // Industry,
    website_url: [0, 'Url', '', 'Please provide Website', 'WebSite'], // WebSite,
    is_current: [1, 'Bool', '', 'Please provide CurrentRole', 'CurrentRole'], // CurrentRole,
    job_type: [1, 'String', '', 'Please provide Type', 'Type'], // CurrentRole,
  },
  AddUserWorkSecond: {
    name: [1, 'String', '', 'Please provide Company', 'Company'], // Company,
    designation: [1, 'String', '', 'Please provide Designation', 'Designation'], // Designation,
    start_month: [1, 'String', '', 'Please provide StartMonth', 'StartMonth'], // StartMonth,
    start_year: [1, 'Number', '', 'Please provide StartYear', 'StartYear'], // StartYear,
    end_month: [1, 'String', '', 'Please provide EndMonth', 'EndMonth'], // EndMonth,
    end_year: [1, 'Number', '', 'Please provide EndYear', 'EndYear'], // EndYear,
    location: [1, 'String', '', 'Please provide Location', 'Location'], // Location,
    industry: [1, 'String', '', 'Please provide Industry', 'Industry'], // Industry,
    website_url: [0, 'Url', '', 'Please provide Website', 'WebSite'], // WebSite,
    is_current: [0, 'Bool', '', 'Please provide CurrentRole', 'CurrentRole'], // CurrentRole,
    job_type: [1, 'String', '', 'Please provide Type', 'Type'], // CurrentRole,
  },
  UpdateUserWork: {
    name: [1, 'String', '', 'Please provide Company', 'Company'], // Company,
    designation: [1, 'String', '', 'Please provide Designation', 'Designation'], // Designation,
    start_month: [1, 'String', '', 'Please provide StartMonth', 'StartMonth'], // StartMonth,
    start_year: [1, 'Number', '', 'Please provide StartYear', 'StartYear'], // StartYear,
    end_month: [0, 'String', '', 'Please provide EndMonth', 'EndMonth'], // EndMonth,
    end_year: [0, 'Number', '', 'Please provide EndYear', 'EndYear'], // EndYear,
    location: [1, 'String', '', 'Please provide Location', 'Location'], // Location,
    industry: [1, 'String', '', 'Please provide Industry', 'Industry'], // Industry,
    website_url: [0, 'Url', '', 'Please provide Website', 'WebSite'], // WebSite,
    is_current: [1, 'Bool', '', 'Please provide CurrentRole', 'CurrentRole'], // CurrentRole,
    job_type: [1, 'String', '', 'Please provide Type', 'Type'], // CurrentRole,
  },
  UpdateUserWorkSecond: {
    name: [1, 'String', '', 'Please provide Company', 'Company'], // Company,
    designation: [1, 'String', '', 'Please provide Designation', 'Designation'], // Designation,
    start_month: [1, 'String', '', 'Please provide StartMonth', 'StartMonth'], // StartMonth,
    start_year: [1, 'Number', '', 'Please provide StartYear', 'StartYear'], // StartYear,
    end_month: [1, 'String', '', 'Please provide EndMonth', 'EndMonth'], // EndMonth,
    end_year: [1, 'Number', '', 'Please provide EndYear', 'EndYear'], // EndYear,
    location: [1, 'String', '', 'Please provide Location', 'Location'], // Location,
    industry: [1, 'String', '', 'Please provide Industry', 'Industry'], // Industry,
    website_url: [0, 'Url', '', 'Please provide Website', 'WebSite'], // WebSite,
    is_current: [0, 'Bool', '', 'Please provide CurrentRole', 'CurrentRole'], // CurrentRole,
    job_type: [1, 'String', '', 'Please provide Type', 'Type'], // CurrentRole,
  },
  UpdateWebLinks: {
    behance_url: [0, 'Url', '', 'Please provide behance_url', 'behance_url'],
    custom_url: [0, 'Url', '', 'Please provide custom_url', 'custom_url'],
    custom_url1: [0, 'Url', '', 'Please provide custom_url', 'custom_url1'],
    custom_url2: [0, 'Url', '', 'Please provide custom_url', 'custom_url2'],
    dribbble_url: [0, 'Url', '', 'Please provide dribbble_url', 'dribbble_url'],
    indiefolio_url: [
      0,
      'Url',
      '',
      'Please provide indiefolio_url',
      'indiefolio_url',
    ],
    instagram_url: [
      0,
      'Url',
      '',
      'Please provide instagram_url',
      'instagram_url',
    ],
    personal_url: [0, 'Url', '', 'Please provide personal_url', 'personal_url'],
    vimeo_url: [0, 'Url', '', 'Please provide vimeo_url', 'vimeo_url'],
  },
  UpdateUserSpecialization: {
    specialization: [
      1,
      'Array',
      '',
      'Please provide atlease 1 Specialization',
      'UpdateSpecialization',
    ],
    primary_specialization: [
      1,
      'String',
      '',
      'Please provide PrimaryFieldWork',
      'PrimarySpecialization',
    ],
  },
  UpdateUserSoftware: {
    software: [1, 'Array', '', 'Please provide atlease 1 Tool', 'UpdateTools'],
  },
  ProjectRequestReview: {
    name: [1, 'String', '', 'Please provide Name', 'Name'],
    email: [1, 'Email', '', 'Please provide Email', 'Email'],
  },
};

let AllMethod = {
  GET: getAPI,
  POST: postAPI,
  POSTM: postAPIM,
  PUT: putAPI,
  DELETE: deleteAPI,
};

/**
 * fetch Definition of Request based on Action.
 *
 * Validatie.
 * Encode if needed.
 *
 * FireApi.
 */

export function validateActionData(Action, Data) {
  let r = ValidateAction(Validations[Action], Data);
  if (r.error === true) {
    return r;
  }

  r = Actions[Action];
  return r;
}

export async function fireApi(Action, Data) {
  let r = validateActionData(Action, Data);
  if (r.error === true) {
    return r;
  }
  // if (_.size(r) < 1) {
  //   return new Error('Action Not Found');
  // }
  /**
   *
   * if Method is Get then Add Params.
   * in url in What.
   *
   */
  let Url = r.Url;
  if (Url.indexOf('$') !== -1) {
    _.forOwn(Data, (v, k) => {
      Url = Url.replace('$' + k, v);
    });
  }

  if (r.Method === 'Get' && Url.indexOf('$Zip') !== -1) {
    Url = Url.replace('$Zip', Data.Zip);
  }

  // return await AllFunction[r.Method](Url, Data);
  let resp = await AllMethod[r.Method](Url, Data);
  return resp.data;
}
