const initialState ={
    login:false,
    signup:false
}

const loginReducer = (state=initialState,{type,payload})=>{
    switch (type) {
        case "CLICK_LOGIN":
            
             return {...state,login:true}
        case "CLOSE_LOGIN_POP_UP":
            
             return {...state,login:false}
        case "CLICK_SIGN_UP":
            
                return {...state,signup:true}
        case "CLOSE_SIGN_UP":
            
                return {...state,signup:false}
              
        default:
            return state 
    }

} 
export default loginReducer;