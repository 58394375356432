import { call, put, select } from "redux-saga/effects";
import { fireApi } from "../../../utils/fireApiPrecheck";
import { chat, globalReducer } from "./selectors/index.select";
import socketIOClient from "socket.io-client";
import { ChatBaseUrl } from "../../../utils/BaseUrl";
import {
  loadLatestProposal,
  loadAdminProjectProposal,
} from "./postProject.saga";
import { getAPI } from "../../../utils/API";
import { push } from "connected-react-router";
import { createArtistNecessaryFields } from "../../../utils/reduxUtils/chatUtils";
import { slugify } from "../../../utils/Helperfunctions";
// import { isInaccessible } from "@testing-library/react";

export function* ChatLoad(values) {
  try {
    // yield put({type: 'UPDATE_LOADING', values: true})

    // remove old user data
    yield put({ type: "UPDATE_RESET_CHAT_DATA", values: null });

    const globalReducerData = yield select(globalReducer);
    // const chatData = yield select(chat);

    const CurrentRole = globalReducerData.Me.CurrentRole;
    const isAdmin = globalReducerData.Me.isAdmin;
    const isInAdminChat = window.location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && isInAdminChat;
    let value = values.payload;

    const isAdminProject = value.projectType === "post_project_admin";
    let data;
    if (isAdminAndInAdminChat)
      data = yield fireApi("GetArtistAndClient", { id: value.projectId });
    else if (isAdminProject)
      data = yield fireApi("GetChatUserListAdminProject", {
        id: value.projectId,
      });
    else data = yield fireApi("GetChatUserList", { id: value.projectId });

    if (data.status === "1") {
      let List, userId;
      if (isAdminAndInAdminChat) {
        List = data.creator_data;
      } else if (isAdminProject) {
        List = data.chatUser.map((el) =>
          createArtistNecessaryFields(el, data.proposal)
        );
        userId = data.chatUser[0].user._id;
      } else {
        List = data.user_list;
        userId = data.user_id;
      }
      // if person which is not associated with project in ChatFromRoute then artist array will be empty and redirect user to dashboard
     
      if (!List.length) {
        // yield put(push("/dashboard"));
      }

      let unreadMsgUser = List.find(
        (a) => a.status !== 4 && a.status !== 0 && a.unread_msg > 0
      );

      let artist;

      if (isAdminProject) {

        artist = List[0];
      } else {
        if (value.connectionId) {

          artist = List.find(
            (a) =>
              ((a.status !== 4 &&
              a.status !== 0 ) || a.role == 3)&&
              a.username.toLowerCase() === value.connectionId.toLowerCase()
          );
        }
        if (!artist) {

          artist = unreadMsgUser
            ? unreadMsgUser
            : List.find(
                (a) =>
                  (a.status !== 4 && a.status !== 0) ||
                  (a.status === 4 && value.projectStatus === 8)||(a.status===4&&value.projectStatus===7)
              );
        }
      }

        // ================ NEW CODE ===========

      let pathArr = window.location.pathname.split("/")
      let setUser = ""
      if(pathArr?.[3] === "project_room" && pathArr?.[4]?.length){
        let filter = List.length ? List.find(el => el.username == pathArr?.[4]) : ""
        setUser = filter
      }else{
        setUser = List[0]
      }
        // ================END NEW  CODE ===========


      yield put({
        type: "UPDATE_CHAT_USERS",
        values: {
          Artist: List,
          Client: isAdminAndInAdminChat ? data.client_data : null,
          UserId: userId,
          acceptedIndividual:data.acceptedIndividual,
          ProjectId: value.projectId,
          ProjectName: value.projectName,
          ProjectStatus: value.projectStatus,
          ProjectType: value.projectType,
          ProjectSlug: value.projectSlug,
          // CurrentChat: artist ? artist : { chat_connection_id: "" },
          CurrentChat: artist ? artist : setUser,
        },
      });
      // console.log({artist})
      if (!artist) {
        // ================ OLD CODE ===========
        
        // yield call(alert,'Artist not accepted your project')
        // console.log('no active artist found for the project')
        // yield put(push('/dashboard'))

        // ================END OLD CODE ===========

        // ================ NEW CODE ===========
        yield call(LoadChatMessages, { payload: setUser });

        // ================END NEW  CODE ===========

      } else {
        // it will reset dashboard and chat message count of particular project
        const socket = socketIOClient(ChatBaseUrl, {
          // transports: ["websocket"],
        });
        socket.emit("chat_reset", {
          chat_connection_id: artist.chat_connection_id,
          user_type: isAdminProject
            ? artist.user_role
            : CurrentRole === 2
            ? "creator":CurrentRole==3?"account_manager": "client",
        });
        if (artist.unread_msg > 0) {
          yield call(resetUnreadMsgInCreatorObj, { payload: artist });
        }

        yield call(LoadChatMessages, { payload: artist });

        // load proposal and project
        if (isAdminProject) {
          yield call(loadAdminProjectProposal, { payload: data });
          // const active_milestones = data.milestone.find((element) => { return element.status === 1});
          // let milestone_id = active_milestones ? active_milestones._id : null;
          // values = {
          //     milestones: data.milestone,
          //     proposal:data.proposal,
          //     proposalStatus: data.proposal.status,
          //     active_milestones:milestone_id,
          //     delivered_status: null, // we are not showing current progress card
          //     user_data:data.user,
          //     CurrentMilestone: active_milestones,
          // }
          // yield put({ type: "GET_REVIEW_PROPOSAL_DATA", values: {proposal: data.proposal, milestone: data.milestone}});
          // yield put({type: 'UPDATE_LOAD_PROPOSAL_DATA', values: values})
        } else {
          let projectId = artist.post_project_id;
          yield call(loadLatestProposal, { payload: projectId });
          if (value.callAllProposalApi && value.project_status === 5) {
            const allProposal = yield getAPI(
              `/milestone/proposals-creator?post_project_id=${value.projectId}&user_id=${data.user_id}`
            );
            yield put({
              type: "UPDATE_MULTIPLE_PROPOSAL_STATUS",
              values: {
                isMultipleProposal:
                  allProposal.data.data.length > 1 ? true : false,
              },
            });
          }
        }
      }

      // yield put({type: 'UPDATE_LOADING', values: false})

      // yield call(LoadChatMessages, {payload: List[0]});
      // yield call(loadLatestProposal, {payload: List[0].post_project_id})
    }
  } catch (error) {
    // console.log({ error });
    if (error?.response?.status === 404) {
      yield put(push("/notFound"));
    }
  }
}

export function* LoadNormalMessages(values) {
  try {
    let a = values.payload;
    const data = yield fireApi("GetChatMessages", { id: a.chat_connection_id });
    yield put({
      type: "UPDATE_CHANGE_CHAT",
      values: {
        UserToken: a.user_token,
        ProjectId: a.post_project_id,
        CurrentChat: a,
      },
    });
    if (data.status === "1") {
      let List = data.user_chat;

      yield put({ type: "UPDATE_CHANGE_CHATMESSAGE", values: List });
    }
  } catch (error) {
    throw error;
  }
}

export function* LoadChatMessages(values) {
  try {
    const globalReducerData = yield select(globalReducer);
    const isAdmin = globalReducerData.Me.isAdmin;
    const isInAdminChat = window.location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && isInAdminChat;

    if (isAdminAndInAdminChat) {
      yield call(LoadAdminChatMessages, { payload: values.payload });
    } else {
      yield call(LoadNormalMessages, { payload: values.payload });
    }
  } catch (error) {
    throw error;
  }
}

export function* LoadAdminChatMessages(values) {
  // console.log('%c LoadAdminChatMessages', 'font-size: 22px; color:orange');
  try {
    let a = values.payload;
    const data = yield fireApi("GetAdminChatMessages", {
      id: a.chat_connection_id,
    });
    // console.log("data555",a)
    // console.log('%c ADMIN CHAT', 'font-size: 22px; color:orange');
    // console.log(data);

    yield put({
      type: "UPDATE_CHANGE_CHAT",
      values: {
        UserToken: a.admin_token,
        ProjectId: a.post_project_id,
        CurrentChat: a,
      },
    });
    if (data.status === "1") {
      let List = data.user_chat;
      yield put({ type: "UPDATE_CHANGE_CHATMESSAGE", values: List });
      // console.log('%c 1', 'font-size: 22px; color:orange');
    }
  } catch (error) {
    throw error;
  }
}

export function* loadProjectChat({ payload }) {
  try {
    yield put({ type: "UPDATE_LOADING", values: true });
    const globalReducerData = yield select(globalReducer);
    // const chatData = yield select(chat);

    const currentUserRole = globalReducerData.Me.CurrentRole;
    const isAdmin = globalReducerData.Me.isAdmin;
    const isInAdminChat = window.location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && isInAdminChat;

    let data = {};
    // let data =   yield fireApi('GetProjectChatClient');
    if (isAdminAndInAdminChat) {
      data = yield fireApi("GetAdminProjectChatClient");
    } else if (currentUserRole === 1 || currentUserRole === 3) {
      data = yield fireApi("GetProjectChatClient");
    } else if (currentUserRole === 2) {
      data = yield fireApi("GetProjectChatCreator");
    }
    if (data.status === "1") {
      let Project = data.project;

      if (!Project.length) {
        // yield call(alert, 'You have no projects.')
        // yield put(push('/dashboard'))
      }
      // yield put({ type: 'GET_PROJECT_CHAT', values: Project });
      let projectId, projectName, projectStatus, projectType, projectSlug;
      if (
        payload &&
        payload.projectId &&
        payload.projectName &&
        payload.projectStatus
      ) {
        projectId = payload.projectId;
        projectName = payload.projectName;
        projectStatus = payload.projectStatus;
        projectType = payload.projectType;
        projectSlug = payload.projectSlug;
        let connectionId = payload.connectionId;
        // projectSlug   = slugify(Project[0].project_name);

        const payload1 = {
          connectionId,
          projectId,
          projectName,
          projectStatus,
          projectType,
          projectSlug,
          callAllProposalApi: currentUserRole === 2 ? true : false,
        };
        yield call(ChatLoad, { payload: payload1 });
      } else if (Project.length) {
        const unreadMsgProject = Project.find((el) => el.unread_msg > 0);

        // if there is a project having unread msg then open it otherwise load first project chat
        if (unreadMsgProject) {
          projectId = unreadMsgProject.post_project_id;
          projectName = unreadMsgProject.project_name;
          projectSlug = unreadMsgProject.project_slug;
          // projectStatus = unreadMsgProject.project_status;
          // projectType = unreadMsgProject.type;
        } else {
          projectId = Project[0].post_project_id;
          projectName = Project[0].project_name;
          projectSlug = Project[0].project_slug;
          projectSlug = slugify(Project[0].project_name);
          // projectStatus = Project[0].project_status;
          // projectType = Project[0].type;
        }
      }

      // remove notification from the project
      const projectList = Project.map((obj) => {
        if (obj.post_project_id === projectId) {
          obj.unread_msg = 0;
          return obj;
        } else {
          return obj;
        }
      });

      if (projectList.length)
        yield put({ type: "GET_PROJECT_CHAT", values: projectList });

      // if (projectId) {
      //   if (isAdminAndInAdminChat) {
      //     yield put(push(`/adminChat/${projectId}/${projectSlug}`));
      //   } else {
      //     let connectionIdExt =
      //       payload && payload.connectionId ? `/${payload.connectionId}` : "";
      //     yield put(
      //       push(`/chat/${projectId}/${projectSlug + connectionIdExt}`)
      //     );
      //   }
      // }

      yield put({ type: "UPDATE_LOADING", values: false });

      // payload: { id: projectId, name: projectName, project_status:projectStatus, callAllProposalApi: callAllProposalApi }

      // yield call(loadLatestProposal, {payload: payload2} )
    }
  } catch (error) {
    throw error;
  }
}

export function* activateAdminChat() {
  try {
    yield put({ type: "ACTIVATE_ADMIN_CHAT", values: true });
  } catch (error) {
    console.log(error);
  }
}

export function* setCurrentMilestoneId(value) {
  try {
    value = value.payload;
    yield put({ type: "GET_CURRENT_MILESTONE_ID", values: value });
  } catch (error) {
    console.log(error);
  }
}

export function* initializeSocket() {
  const socket = yield socketIOClient(ChatBaseUrl, {
    // transports: ["websocket"],
  });
  // const chatReducer =  yield select(chat)
  // yield socket.emit('join_user', {
  //   user_token: chatReducer.UserToken
  // })
  yield put({ type: "LOAD_SOCKET", values: socket });
  // console.log("socket initialized");
}

export function* resetSocket() {
  yield put({ type: "UPDATE_RESET_SOCKET", values: null });
}

export function* loadChatAndLatestProposals(values) {
  try {
    let value = values.payload;
    const data = yield fireApi("GetChatUserList", { id: value.id });
    if (data.status === "1") {
      let List = data.user_list;
      yield put({
        type: "UPDATE_CHAT_USERS",
        values: {
          Artist: List,
          UserId: data.user_id,
          ProjectName: value.name,
          ProjectStatus: value.project_status,
        },
      });
    }

    const projectId = value.id;
    const ProjectStatus = value.project_status;
    // let api_path = (this.props.CurrentRole === 2) ? '/milestone/latest-proposals?post_project_id=' : '/milestone/ongoing-proposals?post_project_id='
    const globalReducerData = yield select(globalReducer);
    const CurrentRole = globalReducerData.Me.CurrentRole;
    let proposalData;
    if (CurrentRole === 2) {
      proposalData = yield fireApi("getProposalDataCreator", { id: projectId });
    } else {
      proposalData = yield fireApi("getProposalDataClient", { id: projectId });
    }
    yield put({ type: "GET_REVIEW_PROPOSAL_DATA", values: proposalData });

    let active_milestones, dataObj;
    // console.log(proposalData);
    if (proposalData.milestone.length) {
      if (ProjectStatus === 5) {
        active_milestones = proposalData.milestone.find((element) => {
          return element.status === 0;
        });
      } else {
        active_milestones = proposalData.milestone.find((element) => {
          return element.status === 1;
        });
      }
      let milestone_id = active_milestones ? active_milestones._id : null;
      // console.log(proposalData);
      dataObj = {
        milestones: proposalData.milestone,
        proposal: proposalData.proposal,
        proposalStatus: proposalData.proposal.status,
        active_milestones: milestone_id,
        delivered_status: proposalData.delivered_status,
        user_data: proposalData.user,
      };
      dataObj.CurrentMilestone = {
        ...proposalData.CurrentMilestone,
        completed_milestone_id: proposalData.mlstnCompt._id,
      };
    } else {
      dataObj = {
        milestones: [],
        proposal: [],
        active_milestones: "",
        delivered_status: null,
        user_data: null,
        CurrentMilestone: null,
        proposalStatus: null,
      };
    }

    yield put({ type: "UPDATE_LOAD_PROPOSAL_DATA", values: dataObj });

    // yield call(loadLatestProposal, {payload: value.payload.id})
  } catch (err) {
    console.log(err);
  }
}

export function* loadProjectStatus({ payload: { projectId } }) {
  try {
    const projectData = yield fireApi("GetPostProjectData", { id: projectId });
    yield put({
      type: "UPDATE_PROJECT_STATUS",
      values: projectData.project_data.status,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* resetUnreadMsgInCreatorObj({ payload }) {
  try {
    yield put({
      type: "UPDATE_UNREAD_MESSAGE_IN_CREATOR_OBJ",
      values: payload,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* loadLastMilestonePaymentDone(value) {
  try {
    yield put({
      type: "UPDATE_LAST_MILESTONE_PAYMENT_DONE",
      values: value.payload,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* updateCurrentChatUserData({ payload }) {
  try {
    const data = yield fireApi("GetChatUserList", { id: payload });
    const chatData = yield select(chat);
    if (data.status === "1") {
      let List = data.user_list;
      // CurrentChat
      const userData = List.find(
        (el) =>
          el.chat_connection_id === chatData.CurrentChat.chat_connection_id
      );
      yield put({ type: "UPDATE_CURRENT_CHAT_USER_DATA", values: userData });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* resetChatData() {
  try {
    yield put({ type: "UPDATE_RESET_CHAT_DATA", values: null });
  } catch (error) {
    console.log(error);
  }
}

export function* toggleAttachmentButton({ payload }) {
  try {
    yield put({
      type: "UPDATE_ATTACHMENT_BUTTON_CLICKED_STATUS",
      values: payload,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* removeNotificationFromProjectList({ payload }) {
  try {
    yield put({
      type: "UPDATE_PROJECT_LIST_NOTIFICATION",
      values: { id: payload },
    });
  } catch (error) {
    console.log(error);
  }
}
