import React, {Component} from 'react';
import { Baseurl } from '../../../utils/BaseUrl';
import firebaseIs from '../../../utils/fire';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from './../Common/Loader/Loader';
import { withRouter } from 'react-router-dom'
import { logoutUserRedirect } from '../../authorized/Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';



class CreateNewForm extends Component {

    state = {
        isLoading: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        showCountryCodes: false,
        selectedCountryCode: '+',
        post_Project: false,
        ifnTermsIs: false,
    }

    componentDidMount() {
        if(this.props.isLoginOrSignupAsClient){
            this.setState({post_Project: true})
        }
    }

    handleOnChange = (event) => {
        const {name, value} = event.target;

        name === 'firstName' && this.setState({firstNameError: false});
        name === 'lastName' && this.setState({lastNameError: false});
        name === 'phone' && this.setState({phoneError: false});
        name === 'password' && this.setState({passError: false})
        if(name === 'phone'){
            if(value.length <= 10){
                this.setState({[name]: value})
            }
        } else if(name === 'password') {
            // const {password} = this.state;
            const is8andAbove = value.length > 7;
            const hasUpperCase = /^((?=.*[A-Z]{1,}.*$).+)$/.test(value);
            const hasLowerCase = /^((?=.*[a-z]{1,}.*$).+)$/.test(value);
            // console.log(is8andAbove, hasUpperCase, hasLowerCase)
            this.setState({[name]: value, passLength8: is8andAbove, passHasUpperCase: hasUpperCase, passHasLowerCase: hasLowerCase})
        } else if(name === 'firstName' || name === 'lastName') {
            value.length < 21 && this.setState({[name]: value})
        } else {
            this.setState({[name]: value})
        }
    }

    handleCountryCode = () => {
        this.setState({ showCountryCodes: true })
    }

    handleTakeCodeOnclick = (obj) => {
        this.setState({
        selectedCountryCode: obj.code,
        showCountryCodes: false
        })
    }

    handleFullModalOnclick = () => {
        this.setState({
            showCountryCodes: false
        })
    }

    handleIfnTermsOnChange = () => {
        const { ifnTermsIs } = this.state;
        this.setState({
            ifnTermsIs: !ifnTermsIs, termsAndConditionError: false
        })
    }

    handleCheckbox = (e) => {
        this.setState({[e.target.name]: !this.state[e.target.name]})
    }

    handleClose = () => {
        // this.props.setHomeState({createNewAccountModal: false, firebaseOnlyUser: false});
        logoutUserRedirect()    }

    validateForm = () => {
        const {firstName, lastName, phone, password, phoneNumLength} = this.state;
        const hasUpperAndLowerCase = /^((?=.*[A-Z]{1,}.*$)(?=.*[a-z]{1,}.*$).+)$/.test(password);
        // console.log(phone.length)
        firstName.length < 1 && this.setState({firstNameError: true});
        lastName.length < 1 && this.setState({lastNameError: true});
        (!phoneNumLength || phone.length < phoneNumLength) && this.setState({phoneError: true});
        !this.props.firebaseOnlyUser && (password.length < 8 || !hasUpperAndLowerCase) && this.setState({passError: true})

        return firstName.length > 0 && lastName.length > 0 && phone.length === phoneNumLength
            && (this.props.firebaseOnlyUser || (password.length > 7 && hasUpperAndLowerCase))
    }

    createAccountAPI = (createdUserIs) => {

        /******** signup DB api call ******/    
        axios({            
            method: 'post',
            url: Baseurl + '/users/signup',
            headers: { 'content-type': 'application/json' },
            data: createdUserIs,
          }).then((res => {
            let signUpApiResponseIs = res.data.status;
            let UserToken = res.data.token;
            // console.log("UserToken signup", UserToken)
            if (signUpApiResponseIs === "0") {
            //   console.log("User Account not Created..")
              this.setState({isLoading: false})
              alert('Account could not be created. Please try again')
            }
            else if (signUpApiResponseIs === "1") {
                localStorage.setItem("SignUpidToken", UserToken)
                this.props.setHomeState({createNewAccountModal: false});
                this.props.reload();
            } else {
                this.setState({isLoading: false})
                alert('Something went wrong. Please try again.')
            }

          })).catch((error) => {
            console.log("signup-with-api-error------>", error);
            this.setState({isLoading: false})
            alert('Server error. Please try again.')
          })

          /******** signup DB api call ******/
    }

    signup = (e) => {
        e.preventDefault();
        const {password, firstName, lastName, phone, post_Project, selectedCountryCode, ifnTermsIs } = this.state;
        const that = this;
        const { userEmail, loginTypeIs, firebaseOnlyUser } = this.props
        let userType;
        // if (post_Project && freelancer) {
        //   console.log("in signup 11------>", "0")
        //   userType = "0";
        // }
        // else 
        if (post_Project) {
            userType = "1";
        } else if (!post_Project) {
            userType = "2";
        }
        
        if(this.validateForm()){
            if(userType){
                if(ifnTermsIs){
                    // console.log('gone through...')
                    this.setState({isLoading: true})

                    if(firebaseOnlyUser){
                        firebaseIs.auth().currentUser.getIdToken().then(function (idToken) {
                            const createdUserIs = { email: userEmail, phone_no: selectedCountryCode + phone, first_name: firstName, last_name: lastName,
                            password, login_type: loginTypeIs, user_type: userType, token: idToken }
                            that.createAccountAPI(createdUserIs);
                        }).catch(function (error) {
                            console.log("token error", error)
                            that.setState({isLoading: false})
                        });
                    } else {
                        /*********firebase sign up *********/
        
                        firebaseIs.auth().createUserWithEmailAndPassword(userEmail, password)
                        .then(result => {
                
                            /********* fetch firebase token *********/
                    
                            firebaseIs.auth().currentUser.getIdToken().then(function (idToken) {
                                const createdUserIs = { email: userEmail, phone_no: selectedCountryCode + phone, first_name: firstName, last_name: lastName,
                                password, login_type: loginTypeIs, user_type: userType, token: idToken }
                                that.createAccountAPI(createdUserIs)
                            }).catch(function (error) {
                                console.log("token error", error)
                                that.setState({isLoading: false})
                            });
                    
                            /********* fetch firebase token *********/
                        })
                        .catch(function (error) {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log("errorCode", errorCode, errorMessage)
                            that.setState({isLoading: false})
                            alert(error.message);
                        });
                
                    /*********firebase sign up *********/
                    }
                } else {
                    this.setState({termsAndConditionError: true})
                }
                
            } else {
                alert('Please select what you are here for.')
            }
        } else {
            // alert('Please fill all details')
        }
      }

      handlePhoneChange = (phone1, country) => {
        let phoneNumLength = country.format.match(/\./g).length - country.dialCode.length

        if(phoneNumLength < 12){
            phoneNumLength = phoneNumLength + country.dialCode.length
        } else {
            phoneNumLength = 12  + country.dialCode.length
        }

        this.setState({ phone: phone1, country, phoneError: false, phoneNumLength })
        // console.log(country.format, phone1); 
        // this.setState({ phone: phone1.slice(country.dialCode.length), country, phoneError: false, phoneNumLength: country.format.match(/\./g).length })
      }
    render(){ 
        const {isLoading, firstName, lastName, phone, password,
            post_Project, firstNameError, lastNameError, phoneError, passError, termsAndConditionError,
            passLength8, passHasUpperCase, passHasLowerCase
        } = this.state;
        const {userEmail, firebaseOnlyUser, isLoginOrSignupAsClient} = this.props;
        
        const passValidate = (key) => {
            return key !== undefined ? key ? 'text-success' : `text-danger ${passError ? 'font-weight-bold' : ''}` : ''
        }
        return(
            <>
            <div className="modal custom_form custom_Modal_Box show" id="1LoginModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }} >
                <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("../../../Assets/images/close.png")} className="img-fliud" width="35" alt=""/></span>
                <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                    <div className="modal-content user_bg">
                    <div className="modal-body">
                        <div className="row m-0">
                        <div className="col-sm-7 mob_background_image">
                            <div className="mob-login-background"></div>
                        </div>
                        <div className="col-sm-5 overflow-content mob-overflow-content user_modal">
                            <div className="login create_login zoomin">
                            <h2>Create an account</h2>
                            <form onSubmit={(e) => this.signup(e)}>
                                <div className="dflex">
                                    <div className="form-group w-48">
                                        <input type="text" className={`form-control email ${firstNameError ? 'border-error' : ''}`} placeholder="First Name"
                                            name="firstName"
                                            value={firstName}
                                            onChange={this.handleOnChange}
                                        />
                                        {
                                            firstNameError && <span className="text-danger font-weight-600 font-12 py-1">Add first name</span>
                                        }
                                    </div>
                                    <div className="form-group w-48">
                                        <input type="text" className={`form-control email ${lastNameError ? 'border-error' : ''}`} placeholder="Last Name"
                                            name="lastName"
                                            value={lastName}
                                            onChange={this.handleOnChange}
                                        />
                                        {
                                            lastNameError && <span className="text-danger font-weight-600 font-12 py-1">Add last name</span>
                                        }
                                    </div> 
                                </div>
                                
                                <div className="form-group">
                                    <input type="email" className="form-control email" placeholder="Email Address"
                                        readOnly
                                        name="email"
                                        value={userEmail}
                                    />
                                </div>
                                <div className="form-group">
                                    <PhoneInput
                                        country={'in'}
                                        placeholder={"Mobile Number"}
                                        value={phone}
                                        onChange={(phone1, country) => this.handlePhoneChange(phone1, country)}
                                        // onChange={(value, country) => console.log(value, country)}
                                        inputClass={`email pl-extra ${phoneError ? 'border-error' : ''}`}
                                        containerClass="position-relative"
                                        // buttonClass="select-class getSelect"
                                        buttonStyle={{border: 'none', backgroundColor: 'inherit'}}
                                        inputStyle={{paddingLeft: 45, width: '100%'}}
                                        
                                    />
                                    {
                                        phoneError && <span className="text-danger font-weight-600 font-12 py-1">Add phone to continue </span>
                                    }
                                </div>
                                

                                <div className="form-group">
                                <p className="here">I'm here to</p>
                                </div>
                                 
                                <div className="dflex">
                                    <div className="form-group radio_custom">
                                        <input type="radio" id="post" name="post_Project"
                                            checked={post_Project}
                                            onChange={(e) => !isLoginOrSignupAsClient && this.handleCheckbox(e)}
                                            // onClick={this.PostProject}
                                        />
                                        <label htmlFor="post">Hire A Creator</label>
                                    </div>
                                    {!isLoginOrSignupAsClient &&
                                    <div className="form-group radio_custom">
                                        <input type="radio" id="freelancer" name='post_Project'
                                            checked={!post_Project}
                                            onChange={e => this.handleCheckbox(e)}
                                        />
                                        <label htmlFor="freelancer">Join As A Creator</label>
                                    </div>}
                                </div>
                                 
                                
                                
                                    {!firebaseOnlyUser ?
                                        <React.Fragment>
                                            <div className={`form-group`}>
                                                <input type="password" className={`email form-control ${passError ? 'border-error' : ''}`} placeholder="Password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleOnChange}
                                                />
                                            </div>
                                            <div className="form-group sub_dflex">
                                                <span className={`sub_title ${passValidate(passLength8)}`}>8 characters</span>
                                                <span className={`sub_title px-4 ${passValidate(passHasUpperCase)}`}>1 uppercase</span>
                                                <span className={`sub_title ${passValidate(passHasLowerCase)}`}>1 lowercase</span>
                                            </div>
                                        </React.Fragment>
                                    : <div className="mb-15 d-block"></div> }
                                
                                <div className="form-group custom_checkbox">
                                    <input type="checkbox" id="terms" 
                                        onChange={this.handleIfnTermsOnChange}
                                    />
                                    <label htmlFor="terms" style={{color: termsAndConditionError ? '#d63447' : '    '}}>I agree to the website's <a href="/terms" target="_blank">Terms of Use</a> and have read and acknowledge the <a href="/privacypolicy"  target="_blank" className="text-primary cursor-pointer"> Privacy Policy</a></label>
                                </div>
                                <div className="form-group">
                                
                                    <button type="submit" className="main-btn mob-w-100" disabled={isLoading}
                                        onClick={this.signup}
                                >{isLoading ? <Loader isCreateModal={true}/> : 'Create'} {!isLoading && <i className="fas fa-arrow-right"></i>}</button>
                                    
                                </div>
                            </form>
                            </div>
                        </div>
                        <div className="col-sm-7 remove-pd overflow-hidden">
                            <div className="login-background slideinleft"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="back-modal"></div>
            </>
        )
    }
}

export default withRouter(CreateNewForm)