import React from 'react';
import { connect } from 'react-redux'
import MobileDropDown from '../Chat/Popup/MobileDropDown';
import KickStartProject from '../Chat/Popup/KickStartProject';
import ReviewMilestone from '../Chat/Popup/ReviewMilestone'; 
import ReviewMilestoneJob from '../Chat/Popup/ReviewMilestoneJob';
import ReviewUpdatedProposal from '../Chat/Popup/ReviewUpdatedProposal';
import ProposalVersionModal from '../Chat/Popup/ProposalVersionModal';
import ReviewProposal from '../Chat/Popup/ReviewProposal';
import ReviewDeliverable from '../Chat/Popup/ReviewDeliverables';
// import RejectCreator from '../Chat/Popup/RejectCreator';
import EndProject from '../Chat/Popup/EndProject';
import InComingCall from '../Chat/Popup/InComingCall';
import KickOffProject from '../Chat/Popup/KickOffProject';
import MilestoneComplete from '../Chat/Popup/MilestoneComplete';
import ProjectComplete from '../Chat/Popup/ProjectComplete';
import EndProjectReason from '../Chat/Popup/EndProjectReason';
import ClientRejectsCreator from '../Chat/Popup/ClientRejectsCreator';
import CreatorRejectsBrief from '../Chat/Popup/CreatorRejectsBrief'
import MobilePlusButtonDropdown from '../Chat/Popup/MobilePlusButtonDropdown'
import MobileVersionHistory from '../Chat/Popup/MobileVersionHistory'
import IncomingBriefReview from '../Home/Dashboard/Popup/IncomingBriefReview'
import CollateralJobPop from '../ProfileCreator/Popup/CollateralJobPop';
import CollateralFormJobPop from '../ProfileCreator/Popup/CollateralFormJobPop';
import IncomingBriefReviewJob from '../Home/Dashboard/Popup/IncomingBriefReviewJob'
import SliderModalBrief from '../Home/Dashboard/Popup/SliderModalBrief'
import ViewModalFullScreen from '../ProfileCreator/Popup/ViewModalFullScreen'
import ChatFiles from '../Chat/Popup/ChatFiles'

// import { creatorProfile } from '../../../store/saga/workingSagas/selectors/index.select';


let AllPopup = {
//   BasicDetails,
//   Collateral,
//   ViewModal,
//   ViewModalFullScreen,
//   ReviewModal,
//   Specialization,
//   Clients,
//   WorkExperience,
//   Education,
//   WebLinks,
//   CurrentWorkStatus,
//   SubmitProject,
//   CropImages,
//   Tools,
//   Languages,
//   Awards,
  MobileDropDown,
  KickStartProject,
  ReviewMilestone,
  ReviewMilestoneJob,
  ReviewUpdatedProposal,
  ReviewProposal,
  ReviewDeliverable,
  ProposalVersionModal,
  // RejectCreator,
  EndProject,
  // CreatorAndCompanyFeedback,
  InComingCall,
  KickOffProject,
  MilestoneComplete,
  ProjectComplete,
  EndProjectReason,
  ClientRejectsCreator,
  CreatorRejectsBrief,
  MobilePlusButtonDropdown,
  MobileVersionHistory,
  IncomingBriefReview,
  IncomingBriefReviewJob,
  ViewModalFullScreen,
  ChatFiles,
  SliderModalBrief,
  CollateralJobPop,
  CollateralFormJobPop
}

class Popup extends React.Component {
  render() {
    // console.log(this.props);
    // console.log("coponent",this.props.Component)
    let Component = AllPopup[this.props.Component];
    return (
      this.props.Component !== '' && Component ? 
        <Component {...this.props}  />
        :
        <></>
    )
  }
}

const mapState = (store) => {

  let creatorProfile = store.creatorProfile;
  return {
    Component: creatorProfile.Component,
     scheduleCallFunction :creatorProfile.scheduleFunction
  };
};

const mapDispatch = dispatchEvent => ({
});

export default connect(mapState, mapDispatch)(Popup);
