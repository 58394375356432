import React, { useEffect, useState } from "react";
import { postAPI, putAPI } from "../../../../../utils/API";
import { fireApi } from "../../../../../utils/fireApiPrecheck";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

const checkboxList = [
  { text: "Personal reasons" },
  { text: "I am too busy to complete this project" },
  { text: "Client is unresponsive" },
  { text: "Client is refusing to cooperate or make my payments" },
  { text: "Client wants me end this project" },
  { text: "Other" },
];

const checkboxListClient = [{ text: "I have decided to cancel the project" }, { text: "Other" }];

const CloseProject = (props) => {
  const [states, setStates] = useState({
    reason: [],
    checkboxList: checkboxList,
    description: "",
    checkboxListClient: checkboxListClient,
  });

  const [mainCloseProj, setMainCloseProj] = useState(false);

  const [loader, setLoader] = useState(false);

  const onChangeHandler = (e, obj) => {
    const isAlreadyChecked = states.reason.find((el) => el.text === obj.text);

    if (isAlreadyChecked) {
      setStates({
        ...states,
        reason: [...states.reason.filter((el) => el.text !== obj.text)],
      });
    } else {
      setStates({ ...states, reason: [...states.reason, { text: obj.text }] });
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (!states.reason.length) {
      alert("Please select at least one reason.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    const { reason, description } = states;
    if (formValidation()) {
      handleSubmitReject({ reason, description });
    }
  };

  const handleSubmitReject = async (data) => {
    setLoader(true);
    let obj = {
      post_project_id: props?.id,
      user_type: props?.CurrentRole === 1 ? "client" : "creator",
      feedback: data.reason,
      description: data.description,
      history: props?.history,
    };
    try {
      let response = await postAPI("/project/add-post-project-close-reviews", obj);
      if (parseInt(response.data.status) === 1) {
        setLoader(false);
        props.changeShowCloseProj(false);
        alert("Project close successfully");
        // props.history.push(`/projects/${props?.id}/overview`);
        props.history.push(`/dashboard`);
      } else {
        setLoader(false);
        alert("Something went wrong please try again later");
      }
    } catch (err) {
      setLoader(false);
      alert("Something went wrong please try again later");
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeShowCloseProj(false);
    }
  };

  let checkboxData = props?.isClient ? checkboxListClient : checkboxList;
  return (
    <React.Fragment>
      <section className="worklog_extension_rejection_wrapper">
        <div
          className={`modal fade modal_fade_bg ${props?.showCloseProj ? "show" : ""}`}
          id="worklogextensionrejection"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.showCloseProj ? "block" : "" }}
        >
          {mainCloseProj ? (
            <>
              <div className="modal-dialog modal-dialog-centered popup_modal_dialog_xsm m-auto">
                <div className="modal-content br_6">
                  <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                    <div className="header">
                      <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                        Select Reasons For Rejection
                      </h5>
                    </div>
                    <button
                      type="button"
                      className="close close_btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => props.changeShowCloseProj(false)}
                    >
                      <span aria-hidden="true" className="close_icon modalclose-btnicon">
                        ×
                      </span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row mx-0 mb-3 align-items-center">
                      {checkboxData?.length
                        ? checkboxData.map((obj, index) => (
                            <>
                              <div className="col-lg-auto">
                                <div className="cstm_checkbox">
                                  <input
                                    type="checkbox"
                                    id={`checkbox${index}`}
                                    checked={states.reason.find((data) => data.text === obj.text) ? true : false}
                                    onChange={(e) => onChangeHandler(e, obj)}
                                    name={`checkbox${index}`}
                                  />
                                  <label htmlFor={`checkbox${index}`}></label>
                                </div>
                              </div>
                              <div className="col-lg-10">
                                <p
                                  className="mb-0 font_semi_bold_w_600"
                                  style={{
                                    color: states.reason.find((data) => data.text === obj.text) ? "#000000" : "#9b9b9b",
                                    marginLeft: "7px",
                                  }}
                                >
                                  {obj.text}
                                </p>
                              </div>
                            </>
                          ))
                        : null}
                    </div>
                    <div className="row mx-0 mb-3 align-items-center">
                      <div className="col-lg-12 ">
                        <textarea
                          className="form-control upload_notes p-3"
                          name="description"
                          value={states.description}
                          onChange={(e) =>
                            setStates({
                              ...states,
                              description: e.target.value.substring(0, 300),
                            })
                          }
                          placeholder="Describe further (optional)"
                          id="comment"
                          rows="5"
                        ></textarea>
                        <span className="font-weight-500 text-secondary">{states.description?.length}/300</span>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-end align-items-center py-4">
                    <div className="col-lg-12 text-right">
                      <button
                        type="button"
                        class="btn btn_outline_sm fw_700 mr-4"
                        onClick={() => props.changeShowCloseProj(false)}
                      >
                        Cancel
                      </button>
                      <button type="button" class="btn btn_common_danger_sm fw_700" onClick={handleSubmit}>
                        {loader ? <Loader isCreateModal={true} /> : "Submit Request"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="modal-dialog modal-dialog-centered modal-lg m-auto ">
              <div className="modal-content mt-5 mb-5 br_6 ">
                <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
                  <h5 className="modal-title main_modal_title" id="exampleModalLabel">
                    Are you sure?
                  </h5>
                </div>
                <div className="modal-body br_6 px-2">
                  <div className="row mx-0">
                    <div className="col-sm-12 pb-5">
                      <p>We're sad to see you go away. You'll have to clear any pending dues in order to close the project.</p>
                    </div>
                  </div>
                </div>
                <div className=" modal-footer px-4 py-3 d-flex justify-content-end align-items-center">
                  <div className="">
                    <button
                      type="button"
                      className="btn btn_outline_sm"
                      onClick={() => {
                        props.changeShowCloseProj(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-common-danger ml-3"
                      onClick={() => {
                        setMainCloseProj(true);
                      }}
                    >
                      Confirm and Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default CloseProject;
