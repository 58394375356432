// global popup
import React from 'react';
import { connect } from 'react-redux';
import CreatorAndCompanyFeedback from './Chat/Popup/CreatorAndCompanyFeedback'
import ClientAndCompanyFeedback from './Chat/Popup/ClientAndCompanyFeedback'
import TimeBasedPricing from '../../Components/authorized/ProfileCreator/Popup/TimeBasedPricing'

let AllPopup = {
    CreatorAndCompanyFeedback,
    ClientAndCompanyFeedback,
    TimeBasedPricing
}

class Popup extends React.Component {
    render() {
        let Component = AllPopup[this.props.Component];
        return (
            this.props.Component !== '' && Component ?
                <Component {...this.props} />
                :
                <></>
        )
    }
}

const mapState = (state) => {  
    let postProjectData = state.postProject;
    return {
        Component: postProjectData.Component,
    };
};
const mapDispatch = dispatchEvent => ({
});
export default connect(mapState, mapDispatch)(Popup);
