  
  import { GET_CURRENT_MILESTONE_ID, LOAD_SOCKET,  UPDATE_RESET_SOCKET } from "../action/postProject.action";
import {
  UPDATE_CHAT_USERS,
  UPDATE_MULTIPLE_PROPOSAL_STATUS,
  UPDATE_CHANGE_CHAT,
  UPDATE_CHANGE_CHATMESSAGE,
  UPDATE_SEND_MESSAGE,
  GET_PROJECT_CHAT,
  UPDATE_PROJECT_STATUS,
  UPDATE_UNREAD_MESSAGE_IN_CREATOR_OBJ,
  UPDATE_LAST_MILESTONE_PAYMENT_DONE,
  UPDATE_RESET_CHAT_DATA,
  UPDATE_ATTACHMENT_BUTTON_CLICKED_STATUS,
  UPDATE_PROJECT_LIST_NOTIFICATION,
  UPDATE_CURRENT_CHAT_USER_DATA,
  ACTIVATE_ADMIN_CHAT,
  UPDATE_ARTIST_MSG_COUNT,
  SET_CHAT_FILES,
} from "../action/chat.action";
import { getMsgText } from "../../utils/Helperfunctions";

const initState = {
  isInAdminChat: false,
  socket: null,
  Projects: [],
  About: '',
  Artist: [],
  Project: '',
  UserToken: '',
  ProjectToken: '',
  UserId: '',
  acceptedIndividual:'',
  ProjectId: '',
  CurrentChat: {},
  CurrentMilestoneId: '',
  Messages: [
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'Plain',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'Files',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'Milestone',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'Reply',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'ReviewDeliverables',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'DownloadInvoice',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'ProjectComplete',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'StartProject',
      body: {}
    },
    {
      text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
      type: 'ProjectStarted',
      body: {}
    },
    {
      text: 'Uploaded files',
      type: 'File',
      files: [
        { name: '1593113513295download.png' },
        { name: '1593113513295download.png' },
        { name: 'hello.pdf' },
        { name: 'hello.doc' },
        { name: 'hello.docx' },
      ]
    }
  ],
  isLastMilestonePaymentDone: false,
  isMultipleProposal: false,
  isAttachmentButtonClicked: false,
  chatFilesData: null,
  currentFileIndex: 0,
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};
  let obj = [];

  switch (type) {
    case UPDATE_CHAT_USERS:
      newState = Object.assign({}, state, values);
      return newState

    case UPDATE_MULTIPLE_PROPOSAL_STATUS:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_CHANGE_CHAT:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_CHANGE_CHATMESSAGE:
      newState = Object.assign({}, state, {
        Messages: values
      });
      return newState;

    case UPDATE_SEND_MESSAGE:
      obj = Object.assign([], state.Messages);
      obj.push(values)
      newState = Object.assign({}, state, {
        Messages: obj,
        Artist: updateArtistLatestMsg(state.Artist, state.CurrentChat, values)
      });
      return newState;
      
    case GET_PROJECT_CHAT:
      return {
        ...state,
        Projects:values,
      }
    case GET_CURRENT_MILESTONE_ID:
      return {
        ...state,
        CurrentMilestoneId:values.milestone_id,
        CurrentMilestoneStatus: values.milestone_status,
      }

      case LOAD_SOCKET:
      return {
        ...state,
        socket:values,
      }

      case UPDATE_RESET_SOCKET:
      return {
        ...state,
        socket: values,
      }

      case UPDATE_PROJECT_STATUS: 
        return {
          ...state,
          ProjectStatus: values
        }

      case UPDATE_UNREAD_MESSAGE_IN_CREATOR_OBJ: 
      const newArr = state.Artist.map(el => {
        if(el.chat_connection_id === values.chat_connection_id){
          el.unread_msg = 0;
          return el
        } else {
          return el
        }
      })
        return {
          ...state,
          Artist: [...newArr]
        }

      case UPDATE_LAST_MILESTONE_PAYMENT_DONE:
        return{
          ...state,
          isLastMilestonePaymentDone: values
        }

      case UPDATE_RESET_CHAT_DATA:
        return{
          ...state,
          isLastMilestonePaymentDone: false,
          Projects: [],
          Artist: [],
          UserId: '',
          ProjectId: '',
          ProjectName: '',
          ProjectStatus: '',
          Messages:[],
          UserToken: '',
          CurrentChat: null
        }

      case UPDATE_ATTACHMENT_BUTTON_CLICKED_STATUS:
        return{
          ...state,
          isAttachmentButtonClicked: values
        }

      case UPDATE_CURRENT_CHAT_USER_DATA:
        return{
          ...state,
          CurrentChat: values
        }

      case UPDATE_PROJECT_LIST_NOTIFICATION:
        return{
          ...state,
          Projects: state.Projects.map(el => {
            if(el.post_project_id === values.id){
              el.unread_msg = 0;
              return el;
            } else {
              return el;
            }
          })
        }

      case ACTIVATE_ADMIN_CHAT:
        return{
          ...state,
          isInAdminChat: values
        }
        case UPDATE_ARTIST_MSG_COUNT:
          // debugger
          if(values.user_token !== state.CurrentChat.user_token) {
            const newArtistList = state.Artist.map(artist => {
              if(artist.user_token === values.user_token){
                return {...artist, unread_msg: artist.unread_msg+1, last_msg: getMsgText(values.response)};
              } 
              else return artist
            })
            return {
              ...state,
              Artist: newArtistList
            }
          } else {
            return {
              ...state
            }
          }
        
        case SET_CHAT_FILES:
          return {
            ...state,
            chatFilesData: values.filesData,
            currentFileIndex: values.currentFileIndex
          }

    default:
      return state;
  }
};


export default reducers;

const updateArtistLatestMsg = (ArtistList, CurrentChatObj, msgObj) => {
  ArtistList.map(artist => {
    if(artist.chat_connection_id === CurrentChatObj.chat_connection_id){
      artist.last_msg =  getMsgText(msgObj)
      artist.type = msgObj.type
      return artist
    }
    else {
      return artist
    }
  })
  return ArtistList
}