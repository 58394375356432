import React, { useEffect, useState } from "react";
import moment from "moment";
import Exclamation from "../../../../../../Assets/upgrade-images/submit_proposal/info-circle.svg";
import ArrowRight2 from "../../../../../../Assets/upgrade-images/submit_proposal/green-arrow-right.svg";
import Eye from "../../../../../../Assets/upgrade-images/version_history/eye.svg";
import Plus from "../../../../../../Assets/upgrade-images/submit_proposal/add.svg";
import { ActualProjectForm } from "../../PopupAll/ActualProjectForm";
import Clock from "../../../../../../Assets/upgrade-images/submit_proposal/clock.svg";
import Check from "../../../../../../Assets/upgrade-images/submit_proposal/check.svg";
import { getAPI, putAPI } from "../../../../../../utils/API";
import {
  getDateFormated,
  getQueryParams,
  initialActualExtApi,
  initialActualExtState,
} from "../../CommonFunctionsNew/CommonFunctionsNew";
import YellowClock from "../../../../../../Assets/upgrade-images/submit_proposal/yellow_clock.svg";
import ArrowGray from "../../../../../../Assets/upgrade-images/submit_proposal/arrow_gray.svg";
import CloseExtPopup from "../../CommonpPopup/CloseExtPopup";
import Alert from "../../../../../../Assets/upgrade-images/submit_proposal/alert.svg";
import ClientCreatorProposalPopup from "../../CommonpPopup/ClientCreatorProposalPopup";
import UpArrow from "../../../../../../Assets/upgrade-images/submit_proposal/arrow-up.svg";
import DownArrow from "../../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/arrow-down.svg";
import { sanitiseSummernoteText, separateNumber } from "../../../../../../utils/Helperfunctions";
import TerminateOverviewComp from "../TerminateOverviewComp";
import PopupClose from "../../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/popup_close.svg";
import Loader from "../../../../../unauthorized/Common/Loader/Loader";

const ActualOverviewProj = ({
  state,
  mileDataActual,
  setMileDataActual,
  set_milestone_id,
  setMileObj,
  setIndex,
  setShowClientPayNow,
  setViewUploadDel,
  setViewAttachments,
  setShowUploadMilestone,
  setPaymentStatus,
  ...props
}) => {
  const [actualPopupForm, setActualPopupForm] = useState(false);
  const [addExtApiCall, setAddExtApiCall] = useState(false);
  const [extClose, setExtClose] = useState(false);
  const [isaddExt, setIsaddExt] = useState(false);
  const [addExtData, setAddExtData] = useState(initialActualExtState());
  const [load, setLoad] = useState(false);
  const [loadExt, setLoadExt] = useState(false);
  const [extCloseId, setExtCloseId] = useState("");
  const [approveExtTag, setApproveExtTag] = useState(false);
  const [showVersionHistory, setShowVersionHistory] = useState(false);

  const array = mileDataActual;
  // console.log(array,'this is array ------------------------------->')
  const filter = array.filter((obj) => obj.status != 2);
  const showEndProjPop = filter.length ? false : true;
  const projectData = props?.projectDataStates?.data?.project_data;
  const proposal_data = state?.proposalData;

  useEffect(() => {
    handleGetAddExtData();
  }, []);

  useEffect(() => {
    let id1 = getQueryParams("id1");
    let id2 = getQueryParams("id2");
    let id3 = getQueryParams("id3");
    if (id1?.length && id2?.length && id3?.length) {
      let arr = JSON.parse(JSON.stringify(mileDataActual));
      let arr1 = arr.filter((item) => item._id === id1);
      let arr2 = arr1.length && arr1[0].deliverable.filter((obj) => obj._id === id2);
      let arr3 = arr2.length && arr2[0].milestonecomplete.filter((obj) => obj._id === id3);
      if (arr2.length) {
        arr2[0].milestonecomplete = arr3;
      }
      if (arr1.length) {
        arr1[0].deliverable = arr2;
      }
      if (arr1.length) {
        setViewUploadDel(true);
        set_milestone_id(id2);
        setViewAttachments(arr1[0].deliverable[0]);
        setMileObj(arr1[0]);
        setPaymentStatus(arr1[0].status);
      }
    }
  }, [mileDataActual]);

  // const showPayments = (data, type) => {
  //   let result = 0;
  //   data.forEach((element) => {
  //     result += element.price;
  //   });
  //   let price = type === "gst" ? result + 0.18 * result : type === "real" ? result : result - 0.15 * result;
  //   return separateNumber(price);
  // };

  const handleRightMark = (data) => {
    let check = data.filter((ele) => ele?.AllCount >= 1)?.length > 0;
    return check;
  };

  const uploadViewButtons = (obj, item) => {
    return (
      <div className="eye_image cursor-pointer">
        {props.projStatus == 7 || item.status == 2 || props.role == 1 || props.role == 3 ? null : (
          <button
            className={`btn btn-common-white fw_700 mr-4 ${uploadDateCheck(item?.start_date) ? "position-relative tool" : ""}`}
            onClick={() => {
              setShowUploadMilestone(true);
              set_milestone_id(obj?._id);
              setViewAttachments(obj);
            }}
            disabled={uploadDateCheck(item?.start_date)}
            data-tip={uploadDateCheck(item?.start_date) ? "You can only Upload after cycle has started." : null}
          >
            Upload
          </button>
        )}
        <img
          src={Eye}
          onClick={() => {
            setViewUploadDel(true);
            set_milestone_id(obj?._id);
            setViewAttachments(obj);
            setMileObj(item);
            setPaymentStatus(item?.status);
          }}
        />
      </div>
    );
  };

  const handleShowPay = (arr,end_date) => {
    let result = true;
    let dateCheck = new Date(end_date) <= new Date()
    arr.forEach((ele,i) => {
      let count = !ele?.PendingCount || ele?.PendingCount < 1;
      if (ele.name !== "advance_payment" && ele.type !== "token" && !count) result = false;
    });
    return result && dateCheck;
  };

  const uploadDateCheck = (start_date) => {
    let dateCheck = new Date(start_date) <= new Date()
    return !dateCheck
  }

  const handleEndProj = () => {
    return (
      <div className="page_card mb-3">
        <div className="end_cycle_topheadres d-flex align-items-center justify-content-between">
          <div className="prposal_heading d-flex align-items-center column_gap_15">
            <div className="alert_outer">
              <img src={Clock} />
            </div>
            <div className="alert_massage">
              <p className="mb-0">Would you like to end the project?</p>
              <p className="text_light_gray_xs mb-0">
                On confirmation, we will update the creator that the retainer ends as per schedule and there will be no further
                retainers.
              </p>
            </div>
          </div>

          <div className="review_btn">
            <button
                type="button"
                class={`btn ${showEndProjPop ? "btn-link-text-red-500" : "btn-link-text-gray-1500 position-relative tool"} mr-3`}
                onClick={() => (showEndProjPop ? props.setShowProjEndPop(true) : false)}
                data-tip={showEndProjPop ? null : "Kindly approve work logged in the final cycle to end project" }
              >
                End Project Cycle
              </button>
           {!projectData?.were_admin_project&& <button
              type="button"
              class="btn btn-common fw_700"
              onClick={() => {
                setIsaddExt(true);
                setActualPopupForm(true);
              }}
            >
              Extend Project
            </button>}
          </div>
        </div>
      </div>
    );
  };

  const handleGetAddExtData = async () => {
    setLoad(true);
    try {
      let res = await initialActualExtApi(state?.proposalData?._id);
      // console.log("res === ", res);
      setAddExtData(res);
      setLoad(false);
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoad(false);
    }
  };

  const writeReviewPop = () => {
    return (
      <div className="page_card mb-3">
        <div
          className={`proposal_header br_btm d-flex align-items-center justify-content-${projectData?.terminate_request == 1 || projectData?.terminate_request == 2 ? "between" : "start"
            }`}
        >
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Check} className="mr-3" />
            </div>
            <div className="heading_info">
              <p className="mb-0">
                {projectData?.terminate_request == 2 || projectData?.terminate_request == 1
                  ? "Project Terminated on"
                  : "Congratulations, the project is completed!"}{" "}
                {projectData?.terminate_request == 2 || projectData?.terminate_request == 1
                  ? getDateFormated(
                    projectData?.terminate_request == 2 || projectData?.terminate_request == 1
                      ? props.terminationData?.project_end_date
                      : projectData?.project_completed_date
                  )
                  : ""}
              </p>
              {projectData?.status == 7 ? (
                projectData?.terminate_request == 2 || projectData?.terminate_request == 1 ? null : (
                  <p className="text_light_gray_xs mb-0">
                    The project with {proposal_data.userData.first_name + " " + proposal_data.userData.last_name} was wrapped up
                    on {getDateFormated(projectData?.project_completed_date)}
                  </p>
                )
              ) : null}
            </div>
          </div>
          {projectData?.terminate_request == 2 ||
            (projectData?.terminate_request == 1 &&
              ((props?.CurrentRole == 1 && projectData.rating_by_client < 1) ||
                (props?.CurrentRole == 2 && projectData.rating_by_creator < 1))) ? (
            <div className="proposal_header d-flex align-items-center justify-content-end">
              <div className="prposal_heading w-100">
                <button type="button" class="btn btn-common fw_700 w-100" onClick={() => props.setShowFinalReview(true)}>
                  Write a Review
                </button>
              </div>
            </div>
          ) : null}
        </div>
        {projectData?.terminate_request == 2 || projectData?.terminate_request == 1 ? null : (props?.CurrentRole == 1 &&
          projectData.rating_by_client < 1) ||
          (props?.CurrentRole == 2 && projectData.rating_by_creator < 1) ? (
          <div className="proposal_header d-flex align-items-center justify-content-start">
            <div className="prposal_heading w-100">
              <button type="button" class="btn btn-common fw_700 w-100" onClick={() => props.setShowFinalReview(true)}>
                Write a Review
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleCloseAppExt = async (type, id, obj) => {
    setLoadExt(true);
    try {
      let data = {
        extend_proposal_id: id,
        approved_proposal_id: state?.proposalData?._id,
        status: type,
      };
      if (type === 2) {
        data.rejected_reason = obj.rejected_reason;
        data.rejected_note = obj.rejected_note;
      }
      let res = await putAPI(`/proposal/approved-reject-extend-proposal`, data);
      // console.log("res === ", res);
      if (res) {
        if (type === 1) {
          setExtClose(false);
          alert("Extension add successfully.");
          setApproveExtTag(true);
        } else {
          setExtClose(false);
          setExtClose(false);
          setTimeout(() => {
            alert("Extension has been closed.");
          }, 500);
        }
        props.handleCallApiAgain();
      }
    } catch (err) {
      setExtClose(false);
      alert("Something went wrong please try again later");
    }
  };

  const showAddExtensionDiv = (obj) => {
    let data = obj?.deliverables;
    return (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={YellowClock} className="mr-3" />
            </div>
            <div className="heading_info">
              {/* <p className="mb-0">{props.CurrentRole == 1 ? "Creator" : "Client"} has requested revisions to extension</p> */}
              <p className="mb-0">
                {props.CurrentRole == obj?.access_key
                  ? "You have"
                  : props?.CurrentRole == 2 && obj?.access_key == 1
                    ? "Client has"
                    : "Creator has"}{" "}
                requested revisions to extension
              </p>
            </div>
          </div>
        </div>
        <div className="row  br_btm mx-0">
          <div className="col-lg-12 px-4">
            <div className="row justify-content-between mx-0 br_btm py-3 mb-3">
              <div className="deliverable_title">
                <p className="mb-0 font_semi_bold">Extension Till</p>
              </div>
              <div className="extension_date">
                <p className="mb-0 text_light_gray_xs">
                  {obj?.proposed_start_date_new == obj?.proposed_start_date ? (
                    ""
                  ) : obj?.proposed_start_date_new ? (
                    <>
                      <span className="strike mr-1">{getDateFormated(obj?.proposed_start_date_new)}</span>{" "}
                      <img src={ArrowGray} className="mr-1" />{" "}
                    </>
                  ) : null}
                  {getDateFormated(obj?.proposed_start_date)}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 px-4 mb-3">
            <p className="font_semi_bold_w_600 mb-0">Deliverable Items</p>
          </div>
          <div className="col-lg-12 px-4 common_overflow_xsm_height">
            <div className="inner_wrapper br_btm">
              {data?.map((item, i) => {
                return item?.name ? (
                  <div className="inner_item_wrapper d-flex justify-content-between mx-0 mb-4">
                    <div className="deliverable_title">
                      <p className="mb-0 text_gray_xs_w_600">
                        <span className="mr-2">{i + 1}.</span>
                        {item?.name_new == item?.name ? (
                          ""
                        ) : item?.name_new ? (
                          <>
                            <span className="strike mr-1">{item?.name_new}</span> <img src={ArrowGray} className="mr-1" />{" "}
                          </>
                        ) : null}
                        {item?.name}
                      </p>
                    </div>
                    <div className="extension_date">
                      <p className="mb-0 text_gray_xs_w_600">
                        {item?.price_new == item?.price ? (
                          ""
                        ) : item?.price_new ? (
                          <>
                            <span className="strike mr-1">₹ {separateNumber(item?.price_new)}</span>{" "}
                            <img src={ArrowGray} className="mr-1" />{" "}
                          </>
                        ) : null}
                        ₹ {separateNumber(item?.price)}
                      </p>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="col-lg-12 p-4">
            <p className="font_semi_bold_w_600 mb-0">Notes & Comments</p>
            <p className="text_light_gray_md mb-0">{obj.note}</p>
          </div>
        </div>
        <div className="proposal_footer d-flex align-items-center justify-content-between">
          <div className="left_btn">
            <button
              type="button"
              class="btn btn-link-text-red-500 fw_700"
              onClick={() => {
                setExtCloseId(obj._id);
                setExtClose(true);
              }}
            >
              {props.CurrentRole == obj?.access_key ? "Close Extension" : "Reject Extension"}
            </button>
          </div>
          <div className="right_btn">
            <button
              type="button"
              class="btn btn_outline_md fw_700 mr-2"
              onClick={() => {
                setIsaddExt(false);
                setActualPopupForm(true);
              }}
            >
              Make Changes
            </button>
            {obj.access_key == props.CurrentRole ? null : (
              <button type="button" class="btn btn_common_md fw_700 " onClick={() => handleCloseAppExt(1, obj._id, "")}>
                {loadExt ? <Loader isCreateModal={true} /> : "Approve"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleReviewAndConfirm = () => {
    return (
      <>
        {props.projStatus == 7 ||
          projectData?.terminate_request == 1 ||
          projectData?.terminate_request == 2 ||
          !proposal_data?.is_incoming_proposal ? (
          ""
        ) : (
          <div className="page_card mb-3">
            <div className="proposal_header d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={Alert} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">Creator has updated the proposal</p>
                  <p className="text_light_gray_xs mb-0">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
                    You can accept the latest proposal shared by{" "}
                    {proposal_data.userData.first_name && proposal_data.userData.first_name}{" "}
                    {proposal_data.userData.last_name && proposal_data.userData.last_name} or request changes to the same.
                  </p>
                </div>
              </div>

              <div className="review_btn">
                <button type="button" class="btn btn-common" onClick={() => setShowVersionHistory(true)}>
                  Review Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleChangeProposalYetApp = (text, which, check) => {
    return (
      <>
        {props.projStatus == 7 ||
          projectData?.terminate_request == 1 ||
          projectData?.terminate_request == 2 ||
          (!check && !proposal_data?.is_incoming_proposal) ? (
          ""
        ) : (
          <div className="page_card mb-3">
            <div className="end_cycle_topheadres d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={Alert} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">{text}</p>
                  <p className="text_light_gray_xs mb-0">
                    The {which} will approve the deadlines and payment terms mentioned in the latest proposal
                  </p>
                </div>
              </div>
              {check ? null : (
                <div>
                  <button type="button" onClick={() => setShowVersionHistory(true)} class="btn btn-common">
                    View Updated Proposal
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const handleExtAppTag = () => {
    return (
      <div className="page_card mb-3">
        <div className="end_cycle_topheadres end_cycle_topheadres d-flex align-items-center justify-content-between">
          <div className="prposal_heading d-flex align-items-center column_gap_15">
            <div className="alert_outer">
              <img src={Check} />
            </div>
            <div className="alert_massage">
              <p className="mb-0">Extension approved!</p>
              <p className="text_light_gray_xs mb-0">
                The Client will approve the deadlines and payment terms mentioned in the latest proposal
              </p>
            </div>
          </div>
          <div className="close_btn cursor-pointer" onClick={() => setApproveExtTag(false)}>
            <img src={PopupClose} />
          </div>
        </div>
      </div>
    );
  };

  const showRequestChange = () => {
    return (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Alert} className="mr-3" />
            </div>
            <div className="heading_info">
              <p className="mb-0">{props?.CurrentRole == 1 ? "You" : "Client"} has requested changes in the proposal</p>
              {/* <p className="text_light_gray_xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </div>
          </div>
        </div>
        <div className="row  br_btm mx-0">
          <div className="col-lg-12 p-4">
            {/* <p className="text_light_gray_md mb-0">{proposal_data?.request_change_text}</p> */}
            {proposal_data?.request_change_text && (
              <div className="text_light_gray_md mb-0" dangerouslySetInnerHTML={{ __html: sanitiseSummernoteText(proposal_data?.request_change_text?.replace(/\n/g,'<br/>')) }} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const showMutualAcceptDiv = () => {
    return (projectData?.mutuallyAcceptByClient && props?.CurrentRole == 1 && props.dummyMutualAccState) ||
      (projectData?.mutuallyAcceptByCreator && props?.CurrentRole == 2 && props.dummyMutualAccState) ? (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-between">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Check} className="mr-3" />
            </div>
            <div className="alert_massage">
              <p className="mb-0">Proposal & terms have been mutually accepted!</p>
              {/* <p className="text_light_gray_xs mb-0">
                The creator will approve the deadlines and payment terms mentioned in the latest proposal
              </p> */}
            </div>
          </div>
          <button type="button" className="close close_btn">
            <span aria-hidden="true" className="close_icon modalclose-btnicon" onClick={props.changeMutualAcceptStatus}>
              ×
            </span>
          </button>
        </div>
      </div>
    ) : null;
  };

  let is_add_ext = addExtData?.allData;
  let add_ext_data = [];
  if (is_add_ext) {
    add_ext_data = addExtData?.allData;
  }

  let cyclePaids = array.filter((ele) => ele.status != 2);

  return (
    <div className={`col-lg-${props?.col}`}>
      {proposal_data?.isRequestChange && !projectData?.were_admin_project ? showRequestChange() : null}
      {!projectData?.were_admin_project ? showMutualAcceptDiv() : null}

      {projectData?.terminate_request == 1 ? (
        props?.termLoad ? null : props?.terminationData ? (
          props.terminationData?.status == 1 && props?.CurrentRole == 2 ? null : (
            <TerminateOverviewComp
              terminationData={props?.terminationData}
              type={"actual"}
              CurrentRole={props?.CurrentRole}
              userName={props.CurrentRole == 1 ? proposal_data.userData.first_name + " " + proposal_data.userData.last_name : ""}
              changeTerminatePopup={props.changeTerminatePopup}
              retainer_fees_type={proposal_data?.retainer_fees_type}
              setPopupType={props?.setPopupType}
              terminate_requested_by={projectData?.terminate_requested_by}
              setShowTerminationPay={props.setShowTerminationPay}
              setShowApproveRejectPop={props.setShowApproveRejectPop}
              rowFiles=""
            />
          )
        ) : null
      ) : null}
      {props?.CurrentRole == 1 && !projectData?.were_admin_project ? handleReviewAndConfirm() : ""}
      {props?.CurrentRole == 2 && !projectData?.were_admin_project ? handleChangeProposalYetApp("Client hasn’t yet approved your proposal changes", "client") : ""}
      {props?.CurrentRole == 1 && !projectData?.were_admin_project
        ? props?.projectObj != 2
          ? handleChangeProposalYetApp("Creator approval pending", "creator", true)
          : null
        : null}

      {projectData?.terminate_request == 1 || projectData?.terminate_request == 2
        ? null
        : props?.CurrentRole == 1
          ? !props.justStaticEndProjClient && props.projStatus != 7 && filter.length <= 1 && !is_add_ext
            ? handleEndProj()
            : null
          : null}
      {props.justStaticEndProjClient ||
        props.projStatus == 7 ||
        (projectData?.terminate_request == 1 && props?.CurrentRole == 2 && props.terminationData?.status == 1)
        ? writeReviewPop()
        : null}

      {is_add_ext && !projectData?.were_admin_project ? showAddExtensionDiv(add_ext_data) : null}
      {approveExtTag && !projectData?.were_admin_project ? handleExtAppTag() : null}

      <div className="page_card">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-between">
          <div className="prposal_heading">
            <p className="mb-0">Work Log</p>
          </div>

          <div className="view_profile d-flex justify-content-between">
            <p
              className="blue_text_sm d-flex align-items-center mb-0 cursor-pointer"
              onClick={() => props.history.push(`/projects/${props?.projectDataStates?.id}/proposal_details`)}
            >
              <img src={Eye} className="mr-2" />
              View Proposal
            </p>
          </div>
        </div>

        <div class="timelines row mx-0">
          <div class="timeline education col-lg-12">
            <div class="timeline-items">
              {array?.length
                ? array.map((item, i) => {
                  return (
                    <div class="timeline-item">
                      <div className="timeline_header">
                        <div className="icons">
                          <div
                            className={`circle ${item.status == 2 ? "approved" : handleRightMark(item?.deliverable) ? "process" : ""
                              } `}
                          >
                            {item.status == 2 ? (
                              <span>
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </span>
                            ) : (
                              <span>{i + 1}</span>
                            )}
                          </div>
                        </div>
                        <div className="title_outer">
                          <div className="title text_black_700_w_600">
                            <p className="mb-1 d-flex align-items-center">
                              Cycle {i + 1} ({moment(item.start_date).format("D MMM")} - {moment(item.end_date).format("D MMM")}
                              ){" "}
                              {item.status == 2 ? (
                                <span className="pills_tag_btn_green ml-3">
                                  {props?.CurrentRole == 2 || (props?.CurrentRole == 1 && item?.payment_type == 0)
                                    ? (props?.CurrentRole == 2 ? item?.admin_approved : item?.admin_approved_client)
                                      ? "Paid"
                                      : "Approved"
                                    : "Paid"}
                                </span>
                              ) : projectData?.terminate_request != 1 && projectData?.terminate_request != 2 ? (
                                handleRightMark(item?.deliverable) ? (
                                  <span className="pills_tag_btn_yellow ml-3">
                                    {props?.CurrentRole == 1 ? "Payment Pending" : "In Progress"}
                                  </span>
                                ) : (
                                  <span className="pills_tag_btn_gray ml-3">Upcoming</span>
                                )
                              ) : (
                                <span className="pills_tag_btn_danger ml-3">
                                  {projectData?.terminate_request == 1 ? "Termination In Process" : "Terminated"}
                                </span>
                              )}
                            </p>
                          </div>
                          {props?.CurrentRole == 1 ? (
                            <div className="amount_details d-flex justify-content-between text_light_gray_md">
                              <p className="mb-0">
                                Amount:
                                <span className="text_highlight_w_600">
                                  {item?.price_without_tax ? ` ₹ ${separateNumber(item.price_without_tax)} ` : " N/A "}
                                </span>
                                {projectData?.were_admin_project ? null : item?.price_without_tax ? `+ GST` : ""}
                                {item?.price_without_tax ? (
                                  <span className="font_semi_bold_w_600"> for {item.allApprovedDeliverable} Approved Items</span>
                                ) : (
                                  ""
                                )}
                              </p>
                              {item.status == 2 ? (
                                <p className="mb-0">
                                  {item?.payment_type == 1
                                    ? "Paid On"
                                    : item?.admin_approved_client
                                      ? "Paid On"
                                      : "Payment Pending"}
                                  :
                                  <span className="text_highlight_w_600">
                                    {" "}
                                    {item.payment_date ? getDateFormated(item.payment_date) : "-"}
                                    {/* {item?.payment_type == 1
                                    ? item?.payment_date ? getDateFormated(item.payment_date) : "-"
                                    : item?.admin_approved_client
                                      ? item?.payment_date ? getDateFormated(item.payment_date) : "-"
                                      :` ₹ ${separateNumber(item?.price_without_tax)} `} */}
                                  </span>
                                </p>
                              ) : props.projStatus == 7 ||
                                projectData?.terminate_request == 1 ||
                                projectData?.terminate_request == 2 ? null : (
                                <p className="mb-0">
                                  <p
                                    className={`text_highlight ${item.deliverable.filter((el) => el.milestonecomplete.length > 0).length > 0
                                      ? "cursor-pointer"
                                      : "cursor-default"
                                      } mb-0 ${handleShowPay(item.deliverable,item?.end_date) ? "" : "position-relative tool"}`}
                                    onClick={() => {
                                      if (
                                        // item.deliverable.filter((el) => el.milestonecomplete.length > 0).length > 0 &&
                                        handleShowPay(item.deliverable,item?.end_date)
                                      ) {
                                        set_milestone_id(item._id);
                                        setMileObj(item);
                                        setIndex(i + 1);
                                        setShowClientPayNow(true);
                                      }
                                    }}
                                    style={{
                                      color:
                                        // item.deliverable.filter((el) => el.milestonecomplete.length > 0).length > 0 &&
                                          handleShowPay(item.deliverable,item?.end_date)
                                          ? "#10C89B"
                                          : "#C9CED7",
                                    }}
                                    data-tip={handleShowPay(item.deliverable,item?.end_date) ? null : "Approve or reject all deliverables. You can only approve the cycle after it has ended." }
                                  >
                                    {proposal_data?.payment_method == 1 ? "Pay Now" : "Approve"}
                                    <img src={ArrowRight2} className="ml-1" />
                                  </p>
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className="amount_details d-flex justify-content-between text_light_gray_md">
                              {projectData?.were_admin_project ? null : (
                                <p className="mb-0">
                                  Client Pays:
                                  <span className="text_highlight_w_600">
                                    {item?.price_without_tax ? ` ₹ ${separateNumber(item.price_without_tax)}` : " N/A"}
                                  </span>
                                  {projectData?.were_admin_project ? null : item?.price_without_tax ? ` + GST` : ""}
                                  {item?.price_without_tax ? (
                                    <span className="font_semi_bold_w_600"> for {item.allApprovedDeliverable} Approved Items</span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              )}

                              {/* <p className="mb-0">
                                  {item?.admin_approved ? "Paid On" : "Payment Pending"}:
                                  <span className="text_highlight_w_600">
                                    {" "}
                                    {item?.admin_approved
                                      ? item.payment_date
                                        ? getDateFormated(item.payment_date)
                                        : ""
                                      : item?.total_earning
                                      ? ` ₹ ${separateNumber(item.total_earning)}`
                                      : " N/A"}
                                  </span>
                                  <img src={Exclamation} className="ml-2" />
                                </p> */}

                              {projectData?.were_admin_project && props?.CurrentRole != 2 ? null : (
                                <p className="mb-0">
                                  {item?.admin_approved ? "Paid On" : "Payment Pending"}:
                                  <span className="text_highlight_w_600">
                                    {" "}
                                    {item?.admin_approved
                                      ? item.payment_date
                                        ? getDateFormated(item.payment_date_creator?item.payment_date_creator:item.payment_date)
                                        : ""
                                      : projectData?.were_admin_project
                                        ? ` ₹ ${separateNumber(item.total_earning)}`
                                        : ` ₹ ${separateNumber(item?.price_without_tax * 0.85)}`}
                                  </span>
                                  <img src={Exclamation} className="ml-2" />
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {item.deliverable.map((obj) => {
                        return (
                          obj.name !== "advance_payment" &&
                          obj.type !== "token" && (
                            <div
                              className={`timeline_inner_item collapse ${item.status == 2 ? "" : "show"}  ${(obj.AllCount == obj.ApprovedCount + obj.RejectCount && obj.AllCount != 0) || item.status == 2
                                ? "inner_item_active"
                                : ""
                                }`}
                              id={`collapseExample${i}`}
                            >
                              <div className="title_commn row text_gray_1100_w_600_sm mb-2 mx-0 px-0">
                                <div className="col-lg-3">
                                  <p className="text_gray_1100_w_600_sm mb-0">Item Name</p>
                                  <p className="font_semi_bold mb-0">{obj?.name}</p>
                                </div>
                                <div className="col-lg-2">
                                  <p className="text_gray_1100_w_600_sm mb-0">Item Price</p>
                                  <p className="font_semi_bold_w_600 mb-0">{!projectData?.were_admin_project && props?.CurrentRole == 2  ? obj?.price * .85 : obj?.price}</p>
                                </div>
                                <div className="col-lg-2">
                                  <p className="text_gray_1100_w_600_sm mb-0"> Pending</p>
                                  <p className="font_semi_bold_w_600 mb-0">
                                    {obj?.PendingCount
                                      ? `${obj.PendingCount} ${obj.PendingCount == 1 ? "Item" : "Items"}`
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-lg-1">
                                  <p className="text_gray_1100_w_600_sm mb-0">Approved</p>
                                  <span style={{ color: "green" }}>{obj?.ApprovedCount ? obj.ApprovedCount : "-"}</span>
                                </div>
                                <div className="col-lg-1">
                                  <p className="text_gray_1100_w_600_sm mb-0">Rejected</p>
                                  <span style={{ color: "red" }}>{obj?.RejectCount ? obj.RejectCount : "-"}</span>
                                </div>
                                <div className="col-lg-3 m-auto">
                                  <div className="column_gap_10 d-flex align-items-center justify-content-end">
                                    {obj?.milestonecomplete?.length ? (
                                      // obj.milestonecomplete[0].status == 0 ? (
                                      item.status != 2 ? (
                                        <>
                                          {props.role == 1 && obj.PendingCount > 0 ? (
                                            <>
                                              {/* <span class="badge_blue_800 d-flex">
                                                    <img src={Massage} className="mr-1" />3
                                                  </span> */}
                                              <button
                                                className="btn btn-common-white fw_700"
                                                onClick={() => {
                                                  setViewUploadDel(true);
                                                  set_milestone_id(obj?._id);
                                                  setViewAttachments(obj);
                                                  setMileObj(item);
                                                  setPaymentStatus(item.status);
                                                }}
                                              >
                                                Review
                                              </button>
                                            </>
                                          ) : props?.CurrentRole == 3 ? null : (
                                            uploadViewButtons(obj, item)
                                          )}
                                        </>
                                      ) : (
                                        uploadViewButtons(obj, item)
                                      )
                                    ) : props.role == 1 ? (
                                      <span className="text_light_gray_xs">
                                        <img src={Exclamation} className="mr-2" />
                                        No items added yet
                                      </span>
                                    ) : props?.CurrentRole == 3 ? (
                                      ""
                                    ) : props.projStatus == 7 || item.status == 2 ? null : (
                                      <button
                                        className={`btn btn-common-white fw_700 ${uploadDateCheck(item?.start_date) ? "position-relative tool" : ""}`}
                                        onClick={() => {
                                          setShowUploadMilestone(true);
                                          set_milestone_id(obj?._id);
                                          setViewAttachments(obj);
                                        }}
                                        data-tip={uploadDateCheck(item?.start_date) ? "You can only Upload after cycle has started." : null}
                                        disabled={uploadDateCheck(item?.start_date)}
                                      >
                                        Upload
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}

                      {item.status == 2 ? (
                        <div className="timeline_inner_item inner_item_active">
                          <a
                            className="blue_text_sm"
                            data-toggle="collapse"
                            href={`#collapseExample${i}`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseExample${i}`}
                            onClick={() => {
                              mileDataActual[i].show = !item?.show;
                              setMileDataActual([...mileDataActual]);
                            }}
                          >
                            {item?.show ? "Hide" : "Show"} Deliverables <img src={item?.show ? UpArrow : DownArrow} />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  );
                })
                : "No data found..."}

              {/* ================================== */}

              {props?.CurrentRole == 1 &&
                props.projStatus != 7 &&
                !is_add_ext &&
                cyclePaids?.length == 1 &&
                projectData?.terminate_request < 1&&!projectData?.were_admin_project ? (
                <div class="timeline-item">
                  <div className="timeline_header">
                    <div className="icons">
                      <div className="circle dashed">
                        <span>
                          <img src={Plus} />
                        </span>
                      </div>
                    </div>
                    <div className="title_outer d-flex justify-content-between align-items-center">
                      <div
                        className="title blue_text_heighlight cursor-pointer"
                        onClick={() => {
                          setIsaddExt(true);
                          setActualPopupForm(true);
                        }}
                      >
                        <p className="mb-1 mt-2  d-flex align-items-center ">Add Extension</p>
                      </div>
                      {/* <div className="end_btn">
                        <button
                          type="button"
                          class={`btn ${
                            showEndProjPop ? "btn-link-text-red-500" : "btn-link-text-gray-1500"
                          } fw_700`}
                          onClick={() => (showEndProjPop ? props.setShowProjEndPop(true) : false)}
                        >
                          End Project Cycle
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* ============ acitve project form modal ======================== */}

      {actualPopupForm && (
        <ActualProjectForm
          actualPopupForm={actualPopupForm}
          setActualPopupForm={setActualPopupForm}
          addExtApiCall={addExtApiCall}
          state={state}
          array={array}
          handleGetAddExtData={handleGetAddExtData}
          addExtData={isaddExt ? initialActualExtState() : addExtData}
          isaddExt={isaddExt}
          {...props}
        />
      )}

      {extClose && (
        <CloseExtPopup
          role={props.CurrentRole}
          setExtClose={setExtClose}
          extCloseId={extCloseId}
          handleCloseAppExt={handleCloseAppExt}
        />
      )}

      {showVersionHistory && (
        <ClientCreatorProposalPopup
          id={props.match.params.projectId}
          setShowVersionHistory={setShowVersionHistory}
          showVersionHistory={showVersionHistory}
          data={proposal_data}
          user_id={props?.CurrentRole === 1 ? proposal_data?.user_id : ""}
          {...props}
        />
      )}
    </div>
  );
};

export default ActualOverviewProj;
