import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Rating from 'react-rating'
// import { BaseImageUrl } from './../../../../utils/BaseUrl'
// import closeImg from './../../../../Assets/images/close.png';
import logo from './../../../../Assets/images/logo-icon.png';
// import Loader from './../../../unauthorized/Common/Loader/Loader'
import ReviewMoalBody from '../../Common/UserAndPlatformModal/ReviewMoalBody';
import { handleLongString } from '../../../../utils/Helperfunctions';

class ClientAndCompanyFeedback extends Component {

    state = {
        
        form1Completed: false,
        // creatorRating: 0,
        // companyRating: 0,

        // creatorCheckbox1: false, 
        // creatorCheckbox2: false, 
        // creatorCheckbox3: false, 

        // companyCheckbox1: false, 
        // companyCheckbox2: false, 
        // companyCheckbox3: false, 

        // creatorDescription: '',
        // companyDescription: '',

        // isCreatorFormCompleted: false,
    }

    // creatorFeedbackCheckboxes = {
    //     checkbox1: "Portfolio work didn't match my requirements",
    //     checkbox2: "Want more experienced creators",
    //     checkbox3: "Do you have any additional feedback?",
    // }
    // companyFeedbackCheckboxes = {
    //     checkbox1: "Portfolio work didn't match my requirements (company)",
    //     checkbox2: "Want more experienced creators (company)",
    //     checkbox3: "Do you have any additional feedback? (company)",
    // }

    // onChangeHandler = (e) => {
    //     const target = e.target;
    //     const name = target.name;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;

    //     // validate length of the descriptions
    //     if((name === 'creatorDescription' || name === 'companyDescription') && value.length >= 300){
    //         alert('cannot exceed 300 characters')
    //         return;
    //     }

    //     this.setState({[name]: value})
    // }

    // validateCreatorForm = () => {
    //     const { creatorRating, creatorCheckbox1, creatorCheckbox2, creatorCheckbox3 } = this.state;
    //     let error = false;

    //     if(creatorRating <= 0){
    //         error = true;
    //         alert('select rating')
    //     }

    //     if(!creatorCheckbox1 && !creatorCheckbox2 && !creatorCheckbox3){
    //         error = true;
    //         alert('choose atleast one reason.')
    //     }

    //     return !error
    // }

    // validateCompanyForm = () => {
    //     const { companyRating, companyCheckbox1, companyCheckbox2, companyCheckbox3 } = this.state;
    //     let error = false;

    //     if(companyRating <= 0){
    //         error = true;
    //         alert('select rating')
    //     }

    //     if(!companyCheckbox1 && !companyCheckbox2 && !companyCheckbox3){
    //         error = true;
    //         alert('choose atleast one reason.')
    //     }

    //     return !error
    // }

    // finalSubmit = () => {
    //     const { companyRating, companyCheckbox1, companyCheckbox2, companyCheckbox3, companyDescription, creatorRating,
    //      creatorDescription, creatorCheckbox1, creatorCheckbox2, creatorCheckbox3} = this.state
    //     const companyObj = {
    //         post_project_id: this.props.PostProjectId, rate: companyRating, type: "platform_feedback", description: companyDescription
    //     }
    //     // company feedback array
    //     const companyFeedbackArray = [];
    //     if(companyCheckbox1) companyFeedbackArray.push({feedback: this.companyFeedbackCheckboxes['checkbox1']})
    //     if(companyCheckbox2) companyFeedbackArray.push({feedback: this.companyFeedbackCheckboxes['checkbox2']})
    //     if(companyCheckbox3) companyFeedbackArray.push({feedback: this.companyFeedbackCheckboxes['checkbox3']})
    //     companyObj.feedback = companyFeedbackArray


    //     const creatorObj = {
    //         post_project_id: this.props.PostProjectId, rate: creatorRating, type: "creator_feedback", description: creatorDescription
    //     }
    //     const creatorFeedbackArray = [];
    //     if(creatorCheckbox1) creatorFeedbackArray.push({feedback: this.creatorFeedbackCheckboxes['checkbox1']})
    //     if(creatorCheckbox2) creatorFeedbackArray.push({feedback: this.creatorFeedbackCheckboxes['checkbox2']})
    //     if(creatorCheckbox3) creatorFeedbackArray.push({feedback: this.creatorFeedbackCheckboxes['checkbox3']})
    //     creatorObj.feedback = creatorFeedbackArray

    //     console.log(companyObj, creatorObj)
    //     this.props.reviewProject({creator:creatorObj, company:companyObj})
    // }

    // handleContinue = (event) => {
    //     event.preventDefault()
    //     // console.log(person)
    //     if(!this.state.isCreatorFormCompleted ){
    //         if(this.validateCreatorForm()){
    //             this.setState({isCreatorFormCompleted: true})
    //         }
    //     }else {
    //         if(this.validateCompanyForm()){
    //             // go for api
    //             alert('comleted')
    //             this.finalSubmit()
    //         }
    //     }

    // }

    submit = () => {
        const { data1, data2 } = this.state
        const clientObj = {
            post_project_id: this.props.PostProjectId,
            rate: data1.rating, 
            feedback: '',
            type: "client_feedback",
            description: data1.description, 
            user_type: this.props.CurrentRole === 1 ? 'client' : 'creator'
        }

        const companyObj  = {
            post_project_id: this.props.PostProjectId, 
            qus_ans: data2,
            // rate: data2.rating, 
            // feedback: '',
            // type: "platform_feedback", 
            // description: data2.description, 
            user_type: this.props.CurrentRole === 1 ? 'client' : 'creator'
        }
        this.props.reviewProject({user:clientObj, company:companyObj})
    }

    handleSubmit1 = (data) => {
        // console.log(data)
        this.setState({form1Completed : true, data1: data})
    }

    handleSubmit2 = (data) => {
        // console.log(data)
        this.setState({data2: data}, () => this.submit())

    }


    render() {
        const { form1Completed } = this.state;
        const { projectDetails } = this.props;
        // const { submitting, projectDetails } = this.props;
        // console.log("=--=-=-=-=-=-=-=-=-=",this.props.projectDetails)
        return (
            <>
            <div
                className="modal fade custom_form show"
                style={{ paddingRight: "0px !important", display: "block" }}
                id="creatorFeedback"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                >
                {!form1Completed ? 
                    <ReviewMoalBody 
                        // noRating
                        descriptionRequired
                        text={`Tell us more about the experience`}
                        projectName={projectDetails?.completed_project?.project_name}
                        propsSubtext={`Write a review about ${handleLongString(projectDetails?.client?.first_name,20)} here`}
                        // image={BaseImageUrl + projectDetails.user.profile_image}
                        checkboxListObj={null} 
                        handleSubmit={this.handleSubmit1} 
                    /> : 
                    <div>
                    <ReviewMoalBody
                        form1Completed
                        projectName={projectDetails?.completed_project?.project_name}
                        // noRating
                        // descriptionRequired
                        image={logo}
                        text="What did you think of the platform?"
                        propsSubtext="Do let us know if you would like us to add or modify any features"
                        checkboxListObj={null}
                        form1Complete 
                        handleSubmit={this.handleSubmit2}  
                        // text={`How was your experience working with ${'Vaibhav'}?`}
                        // image={'https://picsum.photos/200/300'}
                        // checkboxListObj={checkboxListObj} 
                        // handleSubmit={this.handleSubmit1} 
                    />
                    </div>

                }
                {/* <span className="close_icon" data-dismiss="modal" aria-label="Close">
                    <img  alt="" src={closeImg} className="img-fliud" width={35} />
                </span> */}
                {/* {/* <div
                    className="modal-dialog modal-dialog-centered mob-modal-xs ipad-screen-650"
                    role="document"
                >
                    <div className="modal-content">
                    <div className="modal-body">
                        <div className="row m-0">
                        <div className="col-sm-12 p-0">
                            <div className="profile-creator ml-05 mob-m-0">
                                <div className="py-20 px-40 mob-px-15 text-center mob-text-left">
                                    <h2 className="mt-0 font-weight-500 mb-0 pt-2 font-18 mob-font-16 line-height-14">
                                    We'd love to hear your feedback
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 p-3 bg-gray-morelight">
                            <div className="d-flex align-items-center justify-content-center mob-justify-content-unset">
                            <div className="publish-project active">
                                <p className="mb-0 font-weight-bold d-flex align-items-center">
                                { !isCreatorFormCompleted ? 
                                    <span className="sticky-note">
                                        <i className="fas fa-user-check font-18 mob-font-14 small-font-12 mr-2 text-dark-black" />
                                    </span> :
                                    <span class="complete-check text-dark  ">
                                        <i class="fas fa-check mr-2 mob-m-0"></i>
                                    </span>
                                }
                                <span className="mob-font-12 small-font-10">
                                    Creator feedback
                                </span>
                                </p>
                            </div>
                            <div className={`choose-underline ${isCreatorFormCompleted ? 'active' : ''} `} />
                            <div className={`publish-project ${isCreatorFormCompleted ? 'active' : ''}`}>
                                <p className="mb-0 font-weight-bold d-flex align-items-center">
                                <span>
                                    <i className="far fa-file-alt font-18 mob-font-14 small-font-12 mr-2" />
                                </span>
                                <span className="mob-font-12 small-font-10">
                                    Platform feedback
                                </span>
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                        {!isCreatorFormCompleted ?  
                            <CommonForm 
                                {...this.state} 
                                {...this.props} 
                                checkboxesText={this.creatorFeedbackCheckboxes} 
                                onChangeHandler={this.onChangeHandler}
                                creatorRatingHandler={(value) => this.setState({creatorRating: value})}
                                handleContinue={this.handleContinue}
                                isCreatorFeedback
                            /> : 
                            <CommonForm
                                {...this.state}
                                {...this.props} 
                                checkboxesText={this.companyFeedbackCheckboxes} 
                                onChangeHandler={this.onChangeHandler}
                                companyRatingHandler={(value) => this.setState({companyRating: value})}
                                handleContinue={this.handleContinue}


                             />
                        }
                    </div>
                    </div>
                </div> */}
            </div>  
            <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
        </>
        )
    }
}

const mapState = (store) => {
    let { closeModal } = store.creatorProfile;
    let { Me, submitting } = store.globalReducer;
    let clientInfo = store;
    return {
        closeModal,
        submitting,
        clientInfo,
        ProjectRatingStatus: Me.ProjectRatingStatus,
        PostProjectId: Me.PostProjectId,
        projectDetails: Me.PostProjectDetailsForReview,
        CurrentRole: Me.CurrentRole,

    };
  };
  const mapDispatch = dispatch => ({
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    reviewProject: (obj) => dispatch({ type: 'PROJECT_REVIEW_SAGA', payload: obj }),
  });
  

export default connect(mapState, mapDispatch)(ClientAndCompanyFeedback);

