import {
  LOAD_ALL_SERVICES,
  UPDATE_MINIMUM_BUDGET,
  UPDATE_MAXIMUM_BUDGET,
  BUDGET_CHANGED,
  UPDATE_UNIT,
  // UPDATE_MAXIMUM_UNIT,
  CHOOSE_SERVICE,
  GET_QUESTIONS,
  SELECT_DEADLINE,
  // SELECT_ANSWER,
  GET_PROJECT_STEPS,
  SET_SELECTED_COLLATERAL,
  GET_ARTISTS,
  SELECT_ARTIST,
  ADD_org_name,
  ADD_role_in_org,
  ADD_org_website,
  ADD_org_location,
  ADD_is_org_location_from_list,
  ADD_company_individual_location,
  ADD_is_company_individual_location_from_list,
  ADD_org_industry,
  ADD_is_org_industry_from_list,
  ADD_is_org_name_from_list,
  ADD_org_employees,
  ADD_org_description,
  ADD_org_type,
  ADD_project_name,
  ADD_project_brief,
  ADD_project_reference,
  ADD_project_reference_text,
  SELECT_PROJECT_DETAIL_ANSWER,
  SELECT_PROJECT_DETAIL_PROJECT_ANSWER,
  GET_POST_PROJECT_DATA,
  CLOSE_POPUP,
  UPDATE_POST_PROJECT_DATA,
  UPDATE_INVITE_MORE_STATUS,
  LOAD_CREATOR_LIST,
  UPDATE_RESET_PROJECT_DATA,
  UPDATE_RESET_SERVICE,
  ADD_project_reference_files,
  SELECT_TYPE_ANSWER,
  SELECT_STYLE_ANSWER,
  SCOPE_OF_WORK_COMPLETED,
  UPDATE_RESET_ALL_DATA,
  SET_PORTFOLIO_PROJECTS, 
  GET_REVIEW_PROPOSAL_DATA,
  GET_CURRENT_PROPOSAL_ID,
  UPDATE_LOAD_LATEST_MILESTONE_STATUS,
  SET_PROJECT_QUESTION_WITH_ANSWER,
  SET_COMPANY_QUESTION_WITH_ANSWER,
  SET_PROJECT_NAME_ERROR,
  UPDATE_HIRE_AGAIN_STATUS,
  UPDATE_LOAD_PROPOSAL_DATA,
  UPDATE_VERSION_HISTORY,
  UPDATE_COMPLETED_MILESTONE,
  GET_POST_PROJECT_CLIENT_BRIEF,
  GET_CREATOR_PROPOSAL_PROFILE,
  SELECT_SUBTYPE,
  SET_PROJECT_NAME,
  SET_SKIP_TYPE,
  SET_SKIP_STYLE,
  ADD_BUDGET_SCOPE,
  ADD_PROJECT_MONTH,
  ADD_PROJECT_HOUR,
  UPDATE_COMPANY_DETAIL,
  ADD_FIXED_PROJECT_DEADLINE,
} from '../action/postProject.action';

import { OPEN_POPUP } from '../type.action';

import {UPDATE_RESET_CHAT_DATA} from '../action/chat.action'

const initState = {
  project_name: '',
  project_name_error: false,
  minBudget: 8000,
  maxBudget: 12000,
  minUnit: 8000,
  maxUnit: 12000,
  unit:'',
  minDefault: 5000,
  maxDefault: 70000,
  subType:'',
  subTypeChanged:false,
  budgetChanged: false,
  selected_collateral: '',
  selected_collateral_name: 'Choose Service',
  Services: [],
  CollateralQuestions: [],
  SelectedArtists: [],
  companyDetailQuestions: [],
  projectDetailQuestions: [],
  //post_steps: 0,
  deadline: '',
  org_type: 'company',
  org_name: '',
  role_in_org: '',
  org_location: '',
  org_industry: '',
  org_website: '',
  org_description: '',
  org_employees: '',
  company_individual_location: '',
  project_add_reference_files: [],
  project_reference: [],
  project_reference_text: '',
  is_org_location_form_list: false,
  is_company_individual_location_from_list: false,
  is_org_industry_from_list: false,
  is_org_name_from_list: false,
  isHireAgain: false,
  hireAgainCreatorId: '',
  Artists: [],
  isArtistLoaded: false,
  milestones: [],
  proposal:[],
  active_milestones:'',
  delivered_status: null,
  user_data: null,
  CurrentMilestone: null,
  proposalStatus: null,
  isInviteMore: false,
  isFromChat: false,
  isLoadLatestMilestone: false,
  latestMilestoneId: '',
  versionHistory: [],
  completedMilestone: null,
  postProjectClientBreif:null,
  creatorProposalProfile: null,
  skip_type_question:'',
  skip_style_question:'',
  budget_scope:'',
  projectStatus:'',
  number_month:"",
  number_hour:"",
  fixedProjectDeadline: new Date(new Date().getTime() + 86400000)
};

const postProject = (state = initState, { type, values, error }) => {
  switch (type) {
    case UPDATE_POST_PROJECT_DATA:
      // console.log(values);
      const obj = {
        ...state,

        //Budget Question Data
        minBudget:
          values.project_data.min_budget <= 0
            ? 8000
            : values.project_data.min_budget,
        maxBudget:
          values.project_data.max_budget <= 0
            ? 12000
            : values.project_data.max_budget,
        budget_heading: values.project_data.budget_heading,
        fixedProjectDeadline: values.project_data.project_deadline_date ? values.project_data.project_deadline_date : new Date(new Date().getTime() + 86400000),
        budget_subtext: values.project_data.budget_subtext,
        budget_type: values.project_data.budget_type,
        budget_unit: values.project_data.budget_unit,

        //Unit Question Data
        pricingUnit: values.collateral.pricing_unit,
        unit: values.project_data.unit,
        unit_question: values.project_data.unit_question,
        unit_question_subtext: values.project_data.unit_question_subtext,

        //Deadline Questions
        deadline: values.project_data.dealline,
        deadline_subtext: values.project_data.deadline_subtext,
        deadline_heading: values.project_data.deadline_heading,

        //Company Detail Questions
        company_work_type_heading:
          values.project_data.company_work_type_heading,
        company_work_type_subtext:
          values.project_data.company_work_type_subtext,
        org_type: values.project_data.company_work_type ? values.project_data.company_work_type : 'company' ,

        company_name_heading: values.project_data.company_name_heading,
        company_name_placeholder: values.project_data.company_name_placeholder,
        company_name_subtext: values.project_data.company_name_subtext,
        org_name: values.project_data.company_name,
        is_org_name_from_list: values.project_data.company_name ? true : false,

        company_job_role_heading: values.project_data.company_job_role_heading,
        company_job_role_placeholder:
          values.project_data.company_job_role_placeholder,
        company_job_role_subtext: values.project_data.company_job_role_subtext,
        role_in_org: values?.project_data?.company_job_role,

        company_add_link_heading: values.project_data.company_add_link_heading,
        company_add_link_subtext: values.project_data.company_add_link_subtext,
        org_website: values?.project_data?.company_add_link,

        company_location_heading: values.project_data.company_location_heading,
        company_location_subtext: values.project_data.company_location_subtext,
        org_location: values?.project_data?.company_location,
        is_org_location_from_list: values?.project_data?.company_location ? true : false,

        company_individual_location_heading:
          values.project_data.company_individual_location_heading,
        company_individual_location_subtext:
          values.project_data.company_individual_location_subtext,
        company_individual_location:
          values.project_data.company_individual_location,
          is_company_individual_location_from_list: values.project_data.company_individual_location ? true : false,

        company_industry_heading: values.project_data.company_industry_heading,
        company_industry_subtext: values.project_data.company_industry_subtext,
        org_industry: values?.project_data?.company_industry,
        is_org_industry_from_list: values.project_data.company_industry ? true : false,
        company_employees_heading:
          values.project_data.company_employees_heading,
        company_employees_subtext:
          values.project_data.company_employees_subtext,
        org_employees: values.project_data.company_employees,

        company_describes_heading:
          values.project_data.company_describes_heading,
        company_describes_placeholder:
          values.project_data.company_describes_placeholder,
        company_describes_subtext:
          values.project_data.company_describes_subtext,
        org_description: values.project_data.company_describes,

        //Project Detail Questions
        project_name_heading: values.project_data.project_name_heading,
        project_name_subtext: values.project_data.project_name_subtext,
        project_name_placeholder: values.project_data.project_name_placeholder,
        project_name: values.project_data.project_name,
        projectStatus:values.project_data.status,
        number_month:values.project_data.number_month,
        number_hour:values.project_data.number_hour,
        

        project_description_heading:
          values.project_data.project_description_heading,
          project_description_placeholder:
          values.project_data.project_description_placeholder,
          project_description_subtext:
          values.project_data.project_description_subtext,
          project_brief: values.project_data.project_description,
          subType:values.project_data.sub_type,

        project_add_reference_link_heading:
          values.project_data.project_add_reference_link_heading,
        project_add_reference_link_subtext:
          values.project_data.project_add_reference_link_subtext,
        project_reference: values.project_data.project_add_reference_link ? values.project_data.project_add_reference_link : [],

        project_add_reference_files_heading:
          values.project_data.project_add_reference_files_heading,
        project_add_reference_files_subtext:
          values.project_data.project_add_reference_files_subtext,
        project_add_reference_files:
          values.project_data.project_add_reference_files ? values.project_data.project_add_reference_files : [] ,

        projectId: values.project_data._id,
        selected_collateral: values.project_data.collateral_id,
        status: values?.project_data?.status,

        typeQuestions: values.project_type_question,
        styleQuestions: values.project_style_question,
        subTypeChanged:values.subTypeChanged,
        subType:values.project_data.sub_type,//projectdata.subStypey
        // companyDetailQuestions: values.project_company_question,
        skip_type_question:values.project_data.skip_type_question,
        skip_style_question:values.project_data.skip_style_question,
        // projectDetailQuestions: values.project_project_question,

        selected_collateral_name: values.collateral.name,

        alreadyCreatedProject: true,
        // commented because default budget is always there
        // budgetChanged:
        //   values.project_data.min_budget > 0 ||
        //   values.project_data.max_budget > 0
        //     ? true
        //     : false,
        budgetChanged: true,
        
        unitChanged: values.project_data.unit !== ''&&values.project_data.unit!=0 ? true : false,
        budget_scope:values.project_data.budget_scope,
        // posting_from :values.project_data.posting_from
 
      };

      //  if payload contains project_project_question and project_company_question then add it
      const projectQuestions = values.project_project_question
      const companyQuestions = values.project_company_question
      if(projectQuestions.length) obj.projectDetailQuestions = [...projectQuestions]
      if(companyQuestions.length) obj.companyDetailQuestions = [...companyQuestions]
      return {...obj}

    case UPDATE_COMPANY_DETAIL:
      return {
        ...state,
      org_type: values.company_work_type ? values.company_work_type.toLowerCase() : 'company' ,
      org_name: values.company_name?values.company_name:'',
      is_org_name_from_list: values.company_name ? true : false,
      role_in_org: values?.company_job_role,
      org_website: values?.company_add_link,
      org_location: values?.company_location,
      is_org_location_from_list: values?.company_location ? true : false,
      company_individual_location:
        values.company_individual_location,
        is_company_individual_location_from_list: values.company_individual_location ? true : false,
      org_industry: values?.company_industry,
      is_org_industry_from_list: values.company_industry ? true : false,
      org_employees: values.company_employees,
      org_description: values.company_describes,


      }
    case UPDATE_MINIMUM_BUDGET:
      return {
        ...state,
        minBudget: values.budget,
        handler: values.handle,
        budgetChanged: true,
      };

    case UPDATE_MAXIMUM_BUDGET:
      return {
        ...state,
        maxBudget: values.budget,
        handler: values.handle,
        budgetChanged: true,
      };

    case BUDGET_CHANGED:
      return {
        ...state,
        budgetChanged: values,
      };

    case UPDATE_UNIT:
      return {
        ...state,
        unit: values.budget,
        unitChanged: true,
      };
      case SET_SKIP_TYPE:
        return {
          ...state,
          skip_type_question:values
        }
      case SET_SKIP_STYLE:
        return {
          ...state,
          skip_style_question:values
        }

    // case UPDATE_MAXIMUM_UNIT:
    //   return {
    //     ...state,
    //     maxUnit: values.budget,
    //     handler: values.handle,
    //     unitChanged: true,
    //   };

    case LOAD_ALL_SERVICES:
      return {
        ...state,
        Services: values,
      };

    case CHOOSE_SERVICE:
      return {
        ...state,
        selected_collateral: values.id,
        selected_collateral_name: values.name,
        selected: values.selected,
      };

    case SET_SELECTED_COLLATERAL:
      return {
        ...state,
        projectId: values.id,
        //this is post projectId
      };
      case SET_PROJECT_NAME:
        console.log("setprojctname",values)
        return {
          ...state,
          project_name:values
        }

    case GET_QUESTIONS:
      const collateralQuesObj = {CollateralQuestions: values}
      // if there is no projectDetailQuestions and companyDetailQuestions then add it from collateralQuestions
      if(!state.projectDetailQuestions.length) collateralQuesObj.projectDetailQuestions = [...values.collateral_project_Detail_question];
      if(!state.companyDetailQuestions.length) collateralQuesObj.companyDetailQuestions = [...values.collateral_company_detail_questionata]
      
      return {
        ...state,
        ...collateralQuesObj,
        pricingUnit: values.collateral.pricing_unit,
        unit_question: values.collateral.unit_question,
        unit_question_subtext: values.collateral.unit_question_subtext,
      };

    case SELECT_TYPE_ANSWER:
      return {
        ...state,
        typeQuestions: values,
      };

    
    case SELECT_STYLE_ANSWER:
      return {
        ...state,
        styleQuestions: values,
      };
    case ADD_BUDGET_SCOPE:
      return {
        ...state,
        budget_scope: values,
      };
    case ADD_PROJECT_MONTH:
      return {
        ...state,
        number_month: values,
      };
    case ADD_PROJECT_HOUR:
      return {
        ...state,
        number_hour: values,
      };

    case SELECT_DEADLINE:
      return {
        ...state,
        deadline: values,
      };
    case SELECT_SUBTYPE:
      return {
        ...state,
        subType:values
      }

    case GET_PROJECT_STEPS:
      return {
        ...state,
        post_steps: values.step + 1,
        total_steps: values.total_steps,
      };

    case GET_ARTISTS:
      return {
        ...state,
        Artists: values.data,
        ArtistsCount: values.selectCount,
        isArtistLoaded: true,
      };

    case SELECT_ARTIST:
      return {
        ...state,
        SelectedArtists: [...values],
      };
    case SET_PORTFOLIO_PROJECTS:
      return {
        ...state,
        userPortfolioProjects:values,
      };
    case OPEN_POPUP:
      return {
        ...state,
        closeModal: true,
        Component: values,
      };
    case ADD_org_type:
      return {
        ...state,
        org_type: values,
      };
    case ADD_org_name:
      return {
        ...state,
        org_name: values,
      };

    case ADD_role_in_org:
      return {
        ...state,
        role_in_org: values,
      };
    case ADD_org_website:
      return {
        ...state,
        org_website: values,
      };
    case ADD_org_location:
      return {
        ...state,
        org_location: values,
      };
    case ADD_is_org_location_from_list:
      return {
        ...state,
        is_org_location_from_list: values,
      };
    case ADD_company_individual_location:
      return {
        ...state,
        company_individual_location: values,
      };
    case ADD_is_company_individual_location_from_list:
      return {
        ...state,
        is_company_individual_location_from_list: values,
      };
    case ADD_org_industry:
      return {
        ...state,
        org_industry: values,
      };
    case ADD_is_org_industry_from_list:
      return {
        ...state,
        is_org_industry_from_list: values,
      };
    case ADD_is_org_name_from_list:
      return {
        ...state,
        is_org_name_from_list: values,
      };
    case ADD_org_employees:
      return {
        ...state,
        org_employees: values,
      };
    case ADD_org_description:
      return {
        ...state,
        org_description: values,
      };
    case ADD_project_name:
      return {
        ...state,
        project_name: values,
        project_name_error: !values.length
      };
      
    case SET_PROJECT_NAME_ERROR:
      return {
        ...state,
        project_name_error: values
      };
    case ADD_project_brief:
      return {
        ...state,
        project_brief: values,
      };
    case ADD_project_reference:
      return {
        ...state,
        project_reference: values,
      };
    case ADD_project_reference_text:
      return {
        ...state,
        project_reference_text: values,
      };
    case ADD_project_reference_files:
      return {
        ...state,
        project_add_reference_files: values,
      };
    case SELECT_PROJECT_DETAIL_ANSWER:
      return {
        ...state,
        company_answers: values,
      };
    case SELECT_PROJECT_DETAIL_PROJECT_ANSWER:
      return {
        ...state,
        project_answers: values,
      };
    case GET_POST_PROJECT_DATA:
      return {
        ...state,
        post_project_data: values,
      };
    case CLOSE_POPUP:
      return {
        ...state,
        closeModal: false,
        Component: '',
      };
    case LOAD_CREATOR_LIST:
      return {
        ...state,
        creatorList: values.user_list,
      };
    case UPDATE_RESET_PROJECT_DATA:
      return {
        ...state,
        projectId: '',
        minBudget: 8000,
        maxBudget: 12000,
        budgetChanged: false,
        unit: '',
        unitChanged: false,
        deadline: 0,
        post_steps: 0,
        typeQuestions: [],
        styleQuestions: [],
        CollateralQuestions: [],
        projectDetailQuestions: [],
        companyDetailQuestions: [],
        selected_collateral: '',
        alreadyCreatedProject: false,
      }
    case UPDATE_RESET_ALL_DATA:
        return initState;
    case UPDATE_RESET_SERVICE:
      return {
        ...state,
        selected: values,
      };
    case SCOPE_OF_WORK_COMPLETED:
      return {
        ...state,
        isScopeOfWorkCompleted: values,
      };
    case GET_REVIEW_PROPOSAL_DATA:
      return {
        ...state,
        review_proposal_data: values,
      }

    case UPDATE_LOAD_PROPOSAL_DATA:
      return {
        ...state,
        ...values
      }

    case GET_CURRENT_PROPOSAL_ID: 
      return {
        ...state,
        current_proposal_id:values.proposal_id,
        current_project_id:values.project_id,
        current_project_status:values.reviewStatus,
      }

    case UPDATE_LOAD_LATEST_MILESTONE_STATUS: 
      return {
        ...state,
        isLoadLatestMilestone: values.id ? true : false,
        latestMilestoneId: values.id
      }

    case SET_PROJECT_QUESTION_WITH_ANSWER: 
      return {
        ...state,
        projectDetailQuestions: [...values],
      }

    case SET_COMPANY_QUESTION_WITH_ANSWER: 
      return {
        ...state,
        companyDetailQuestions: [...values],
      }

    case UPDATE_HIRE_AGAIN_STATUS: 
      return {
        ...state,
        projectId: '',
        minBudget: 8000,
        maxBudget: 12000,
        budgetChanged: false,
        unit: '',
        unitChanged: false,
        deadline: 0,
        post_steps: 0,
        typeQuestions: [],
        styleQuestions: [],
        CollateralQuestions: [],
        selected_collateral: '',
        projectDetailQuestions: [],
        companyDetailQuestions: [],
        alreadyCreatedProject: false,
        isHireAgain: values.isHireAgain,
        hireAgainCreatorId: values.creatorId,
      }

    case UPDATE_INVITE_MORE_STATUS:
      return {
        ...state,
        isInviteMore: values.value,
        isFromChat: values.isFromChat,
      }

    case UPDATE_VERSION_HISTORY:
      return {
        ...state,
        versionHistory: values,
      }

    case UPDATE_COMPLETED_MILESTONE:
      return {
        ...state,
        completedMilestone: values,
      }

    case UPDATE_RESET_CHAT_DATA:
      return {
        ...state,
        milestones: [],
        proposal: null,
        proposalStatus: null,
        active_milestones: '',
        delivered_status: null,
        user_data: null,
      }

    case GET_POST_PROJECT_CLIENT_BRIEF:
      return{
        ...state,
        postProjectClientBreif:values,
      }

    case GET_CREATOR_PROPOSAL_PROFILE: 
      return{
        ...state,
        creatorProposalProfile:values,
      }
    case ADD_FIXED_PROJECT_DEADLINE: 
      return{
        ...state,
        fixedProjectDeadline: values,
      }
      
    default:
      return state;
  }
};

export default postProject;
