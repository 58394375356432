import { put } from 'redux-saga/effects';
import { fireApi } from '../../../utils/fireApiPrecheck';

export function* AddUserEducation(data = {}) {
  // console.log('AddUserEducation ', data.payload);
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Action = 'AddUserEducation';
    if (data.payload._id !== '') {
      Action = 'UpdateUserEducation';
    }

    let Work = yield fireApi(Action, data.payload);
    // console.log('%c education', 'font-size: 22px; color:orange');
    // console.log(Work);
    
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Work.error && Work.error === true) {
      yield put({ type: 'UPDATE_USEREDUCATION_ERROR', values: Work });
    } else {
      if (parseInt(Work.status) === 1) {
        if (Action === 'AddUserEducation') {
          yield put({
            type: 'ADD_USEREDUCATION',
            values: {
              Institute_name: data.payload.name,
              course: data.payload.course,
              start_month: data.payload.start_month,
              start_year: data.payload.start_year,
              end_month: data.payload.end_month,
              end_year: data.payload.end_year,
              _id: Work.user_education._id,
              Institute_image: Work.Institute_image,
            },
          });
        } else {
          yield put({
            type: 'UPDATE_USEREDUCATION_EDITUPDATE',
            values: {
              Institute_name: data.payload.name,
              course: data.payload.course,
              start_month: data.payload.start_month,
              start_year: data.payload.start_year,
              end_month: data.payload.end_month,
              end_year: data.payload.end_year,
              _id: data.payload._id,
              Institute_image: Work.Institute_image,
            },
          });
        }
        yield put({ type: 'UPDATE_USEREDUCATION_RESET', values: { edit: -1 } });
        yield put({ type: 'UPDATE_FORMOPEN', values: false });
        
      }
    }
  } catch (error) {
    throw error;
  }
}

export function* DeleteUserEducation(data = {}) {
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Work = yield fireApi('DeleteUserEducation', { id: data.payload });
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Work.error && Work.error === true) {
      yield put({ type: 'UPDATE_USEREDUCATION_ERROR', values: Work });
    } else {
      if (parseInt(Work.status) === 1) {
        yield put({
          type: 'DELETE_USEREDUCATION',
          values: data.payload,
        });
        yield put({ type: 'UPDATE_USEREDUCATION_RESET', values: { edit: -1 } });
      }

    }
  } catch (error) {
    throw error;
  }
}
