
import React from 'react';
import { connect } from 'react-redux';
import { getAPI } from '../../../../utils/API';
import { BaseImageUrl } from '../../../../utils/BaseUrl';
import { getDateTime } from '../../../../utils/DateMethod';
import { sanitiseSummernoteText, separateNumber } from '../../../../utils/Helperfunctions';
class ProposalVersionModal extends React.Component {
  state = {
    allProposals: [],
    isLoading: false,
  }

  loadProposalVersions = (projectId, userId) => {
    // let userId = this.props.UserId;
    // let api_path = (this.props.CurrentRole === 2) ? '/milestone/proposals-creator?post_project_id='+projectId+'&user_id='+userId : '/milestone/all-proposals?post_project_id=' + projectId
    let api_path = '/milestone/proposals-creator?post_project_id='+projectId+'&user_id='+userId
    getAPI(api_path).then((res) => { 
      if (res.data.status === "1") {
        this.setState({
          allProposals: res.data.data,
          // showProposalId: res.data.data?.[0].proposal._id,
          // showTable: true,
          isLoading: false
        })
      }
    }).catch((error) => {
      console.log(error)
      this.setState({isLoading: false})
    });
  }

  getPercent = (amount, value) => {
    const sub_total_cost = value.proposal.update_status === 0 ? value.proposal.sub_total_cost_new : value.proposal.sub_total_cost
    let percent = parseInt((amount * 100) / sub_total_cost); 
    return percent;
  }
  componentDidMount =() => {
    this.setState({isLoading: true})
    let proposal_id = this.props.current_proposal_id;
        getAPI('/milestone/get-proposals?proposal_id=' + proposal_id).then((res) => {
          this.props.LoadReviewProposalData(res.data); 
          this.loadProposalVersions(this.props.current_project_id, res.data.proposal.user_id);
            }).catch((error) => {
                console.log(error)
                this.setState({isLoading: false})
            })
  }

  showTable = (proposalId) => {
      const {showTable,showProposalId} = this.state;
      this.setState({
        showProposalId:proposalId,
        showTable: (proposalId === showProposalId) ? !showTable : true,
      })
  }

  render() {
    const { allProposals,showProposalId,showTable } = this.state;
    const {CurrentRole} = this.props;
    const image_user = require("../../../../Assets/images/user.png");
    // console.log(allProposals, CurrentRole);
    const filterProposalByUser = CurrentRole === 1 ? allProposals.filter(val => val.proposal.admin_request_changes !== 1) : allProposals;
    return (
      <>
        <div className="modal fade custom_form show" style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon recommendation_modal_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650 mob-modal-xs" role="document">
            <div className="modal-content border-0 height-auto">
              <div className="modal-body">
                <div className="col-sm-12 p-0">
                  <div className="profile-creator dflex p-4">
                    <div>
                      <div className="d-flex w-100">
                        <div className="pt-15" onClick={(e)=>{this.props.OpenPopup('ReviewProposal')}}>
                          <i className="fas fa-arrow-left font-18 text-white cursor-pointer"></i>
                        </div>
                        <div className="pl-3">
                          <span className="text-white font-13 mob-font-12 font-weight-500">Version history</span>
                          <h2 className="font-weight-500 m-0 font-18 mob-font-14 mob-only-text-left line-height-14">Project Proposal</h2>
                          <span className="font-13 text-sub">shared by {allProposals && allProposals.length ? allProposals[0].user.first_name + " " + allProposals[0].user.last_name : null}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button type="button" className="main-btn font-12 mob-w-100 cursor-pointer mobile-hide"
                      onClick={(e)=>{this.props.OpenPopup('ReviewProposal')}}
                      >View latest version</button>
                    </div>
                  </div>
                </div>
                <div className={"col-sm-12 p-0 overflow-content review_proposal mobile_creator_proposal"}>
                  {
                    filterProposalByUser && filterProposalByUser.length > 0 && filterProposalByUser.map((value, index) => (
                      <div key={index} className="mobile-hide mb-2">
                        <div className="card-wrapper mt-2"> 
                          <div className="custom_form px-3">
                            <div className="border-radius-4 border-1 box-shadow-box">
                              <div className="row m-0" onClick={(e)=>{this.showTable(value.proposal._id)}}>
                                <div className="col-sm-12 py-3">
                                  <div className="dflex">
                                    <div className="">
                                      <h3 className="text-dark-black mb-0 font-14 font-weight-500">Edited on <span className="text-capitalize">{getDateTime(value.proposal.updatedAt, "dateMonth")}</span></h3>
                                    </div>
                                    <div className="cursor-pointer">
                                      <i className={`fas ${(showProposalId === value.proposal._id && showTable) ? "fa-chevron-up" : "fa-chevron-down" } text-dark-black font-14 ml-1`}></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`custom_table  ${(showProposalId === value.proposal._id && showTable) ? "" : "hidden" }`}>
                                <div className="row-border py-3">
                                  <div className="row m-0">
                                    <div className="col-sm-1 pr-0">
                                      <span className="text-sub font-weight-500">#</span>
                                    </div>
                                    <div className="col-sm-5">
                                      <span className="text-sub font-weight-500">Deliverable</span>
                                    </div>
                                    <div className="col-sm-4">
                                      <span className="text-sub font-weight-500">Due Amount</span>
                                    </div>
                                    <div className="col-sm-2">
                                      <span className="text-sub font-weight-500">Due Date</span>
                                    </div>
                                  </div>
                                </div>
                                {
                                  value.milestone && value.milestone.map((milestones, k) => (
                                    (milestones.type === "token") ?
                                        <div key={k} className="wrapper-form row-border py-3 rounded-0">
                                          <div className="row m-0">
                                            <div className="col-sm-1 text-left pl-10">
                                              <span className="text-sub-8"><i className="fas fa-info advance_info"></i></span>
                                            </div>
                                            <div className="col-sm-5">
                                              <div>
                                                <h3 className="text-third mb-0 font-14 font-weight-500">Project - Advance Fees</h3>
                                                {/* <p className="mb-0 text-third">
                                                  <sup><i className="fas fa-star-of-life font-5"></i></sup>
                                                  <span className="font-11 font-weight-500">Recommendation: 10-20% of total project value.</span>
                                                </p> */}
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div>
                                                <span className="text-dark-black mb-0 font-15 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{separateNumber(milestones.price)}</span>
                                                <button type="button" className="main-btn non-btn bg-tranparent text-third font-10 cursor-default font-weight-500 ml-1">{this.getPercent(milestones.price, value)} % of total payment</button>
                                              </div>
                                            </div>
                                            <div className="col-sm-2">
                                              <span className="font-14 mob-font-13 text-dark-black font-weight-600 text-capitalize">{milestones.due_date ? getDateTime(milestones.due_date, "dateMonth") : "-"}</span>
                                            </div>
                                          </div>
                                        </div>
                                       :
                                      <div key={k} className="row-border py-3">
                                        <div className="row m-0">
                                          <div className="col-sm-1 pr-0">
                                            <span className="text-sub font-weight-500">{k}</span>
                                          </div>
                                          <div className="col-sm-5">
                                            <div>
                                              <h3 className="text-dark-black mb-0 font-14 font-weight-600">{milestones.name}</h3>
                                            </div>
                                          </div>
                                          <div className="col-sm-4">
                                            <div>
                                              <span className="text-dark-black mb-0 font-15 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{separateNumber(milestones.price)}</span>
                                              <button type="button" className="main-btn bg-light-gray font-10 cursor-default py-1 px-2 font-weight-500 ml-1">{this.getPercent(milestones.price, value)} % of total payment</button>
                                            </div>
                                          </div>
                                          <div className="col-sm-2">
                                            <span className="font-14 text-dark-black font-weight-600 text-capitalize">{getDateTime(milestones.due_date, "dateMonth")}</span>
                                          </div>
                                        </div>
                                      </div>
                                  ))}

                                  {value.proposal ? 
                                    <div className="row-border row m-0 py-3">
                                        <div className="col-sm-8 offset-sm-4">
                                        <div className="card-wrapper mb-0">
                                            <div className="d-flex align-items-center">
                                            <div className="w-60 text-right">
                                                <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                Sub Total
                                                </h2>
                                            </div>
                                            <div className="w-40 pl-5">
                                                <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(value.proposal.update_status === 0 ? value.proposal.sub_total_cost_new : value.proposal.sub_total_cost)}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="card-wrapper mb-0">
                                            <div className="d-flex align-items-center">
                                            <div className="w-60 text-right">
                                                <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                G.S.T @ 18%
                                                </h2>
                                            </div>
                                            <div className="w-40 pl-5">
                                                <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(value.proposal.update_status === 0 ? value.proposal.tax_new : value.proposal.tax)}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="card-wrapper mb-0">
                                            <div className="d-flex align-items-center">
                                            <div className="w-60 text-right">
                                                <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                                Total Project Cost for Client
                                                </h2>
                                            </div>
                                            <div className="w-40 pl-5">
                                                <h2 className="font-16 font-weight-700 text-dark-black"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(value.proposal.update_status === 0 ? value.proposal.total_cost_new : value.proposal.total_cost)}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> : null}

                                <div className="py-3">
                                  <div className="row m-0 align-items-center pb-2">
                                    <div className="col-5 col-sm-5 dflex py-3">
                                      <div className="w-50">
                                        <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">Project quote</span>
                                        <span className="font-18 text-dark-black font-weight-600"><span className="project-date-star position-relative"><i className="fas fa-rupee-sign font-18"></i>{separateNumber(value.proposal.update_status === 0 ? value.proposal.sub_total_cost_new : value.proposal.sub_total_cost  )}</span></span>
                                        <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span>
                                      </div>
                                      <div className="w-50 project-delivery-date position-relative">
                                        <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">Project delivery date</span>
                                        <span className="font-18 text-dark-black font-weight-600 text-capitalize">{getDateTime(value.milestone[value.milestone.length-1].due_date,"dateMonth")}</span>
                                      </div>
                                    </div>
                                    <div className="col-sm-7">
                                      <div className="wrapper-form d-flex align-items-center p-2">
                                        <div className="pl-1">
                                          <p className="mb-0 creator-profile"><img src={(value.user.profile_image) ? BaseImageUrl + value.user.profile_image : image_user} alt="profile" title="" width="35" /></p>
                                        </div>
                                        <div className="pl-3">
                                          <span className="font-weight-500 text-third font-11 mob-font-11">Notes from {value.user.first_name} </span>
                                          <h2 className="chat-username m-0 text-dark-black font-12 font-weight-600 mob-font-12" dangerouslySetInnerHTML={{__html: sanitiseSummernoteText(value?.proposal?.note?.replace(/\n/g,'<br/>'))}}></h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  {this.state.isLoading && 
                    <div className="spinner-wrapper">
                      <div className="spinner-border"></div>
                    </div>
                  }
                  <div className="mobile-show py-3">
                    <div className="mobile-chat-header hidden">
                      <div className="row m-0 align-items-center py-2">
                        <div className="col-9 mob_get_verify d-flex align-items-center border-0">
                          <div>
                            <h2><i className="fas fa-arrow-left mr-2 font-16"></i></h2>
                          </div>
                          <div className="pl-2">
                            <h2 className="d-block font-weight-600 text-dark-black line-height-normal font-18">Version history</h2>
                          </div>
                        </div>
                      </div>
                    </div>

                  {filterProposalByUser && filterProposalByUser.length > 0 && filterProposalByUser.map((value, index) => (
                    <div key={index} className="card-wrapper mb-3">
                      <div className="custom_form px-3">
                        <div className="border-radius-8 border-1">
                          <div className="row m-0 bg-light-sky bor-bottom"  onClick={(e)=>{this.showTable(value.proposal._id)}}>
                            <div className="col-sm-12 py-3">
                              <div className="dflex">
                                <div className="">
                                  <h3 className="text-third mb-0 font-14 font-weight-500">Edited on {getDateTime(value.proposal.updatedAt, "dateMonth")}</h3>
                                </div>
                                <div className="">
                                  <i className={`fas ${(showProposalId === value.proposal._id && showTable) ? "fa-chevron-up" : "fa-chevron-down" } text-third font-14 ml-1`}></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`custom_table  ${(showProposalId === value.proposal._id && showTable) ? "" : "hidden" }`}>
                            <div className="row m-0 bor-bottom pb-4">
                              <div className="col-12 col-sm-12 d-flex align-items-center pt-3">
                                <div className="w-40">
                                  <span className="d-block font-12 text-sub font-weight-500">Project quote</span>
                                  <span className="font-18 text-dark-black font-weight-600"><span className="project-date-star position-relative"><i className="fas fa-rupee-sign font-16"></i>{value.proposal.total_cost}</span></span>
                                  {/* <sapn>Exc</sapn> */}
                                </div>
                                <div className="w-50 project-delivery-date position-relative">
                                  <span className="d-block font-12 text-sub font-weight-500">Project delivery date</span>
                                  <span className="font-18 text-dark-black font-weight-600 text-upparcase">{getDateTime(value.milestone[value.milestone.length-1].due_date,"dateMonth")}</span>
                                </div>
                              </div>
                            </div>
                            {
                              value.milestone && value.milestone.map((milestones, k) => (
                                (milestones.type === "token") ?
                                  <div key={k} className="row-border py-3">
                                    <div className="row m-0">
                                      <div className="col-9">
                                        <div>
                                          <h3 className="text-third mb-2 font-15 font-weight-500 line-height-16">Advance one-time non-<span className="d-block">refundable fee <span className="text-sub-8"><i className="fas fa-info-circle"></i></span></span></h3>
                                          <button type="button" className="main-btn non-btn bg-tranparent text-third font-12 cursor-pointer font-weight-500">{this.getPercent(milestones.price, value)} % of total payment</button>
                                        </div>
                                      </div>
                                      <div className="col-3 small-p-0 small-mid-p-0">
                                        <div>
                                          <span className="text-dark-black mb-0 font-18 small-font-16 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{separateNumber(milestones.price)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div> :
                                    <div key={k} className="row-border py-3">
                                      <div className="row m-0">
                                        <div className="col-9">
                                          <div>
                                            <span className="text-sub mb-1 font-14 d-block font-weight-500">Milestones {k}</span>
                                            <h3 className="text-dark-black mb-1 font-15 font-weight-600">{milestones.name}</h3>
                                            <span className="font-13 text-dark-black d-block font-weight-600 text-upparcase">Due date : {getDateTime(milestones.due_date, "dateMonth")}</span>
                                          </div>
                                        </div>
                                        <div className="col-3 small-p-0 small-mid-p-0">
                                          <div>
                                            <span className="text-sub font-10 mb-1 d-block font-weight-500">Due Amount</span>
                                            <span className="text-dark-black mb-0 font-18 small-font-14 font-weight-600"><i className="fas fa-rupee-sign font-16"></i>{separateNumber(milestones.price)}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    ))}
                                    <div className="row m-0">
                                      <div className="col-sm-12">
                                        <div className="d-flex pt-3 pb-4">
                                          <div className="">
                                            <p className="mb-0 creator-profile"><img src={(value.user.profile_image) ? BaseImageUrl + value.user.profile_image : image_user} alt="profile" title="" width="35" /></p>
                                          </div>
                                          <div className="pl-3">
                                            <span className="font-weight-500 text-third font-13 mob-font-12">{value.user.name} also mentioned</span>
                                            <h2 className="chat-username text-capitalize m-0 text-black font-13 font-weight-600 line-height-16">{value.proposal.note}</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                  ))}

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
      </>
    )
  }
}

const mapState = (store) => {
  let postProjectData = store.postProject;
  let chat = store.chat;
  let globalReducer = store.globalReducer
  return {
    collateral_name: postProjectData.selected_collateral_name,
    project_name: postProjectData?.project_name,
    projectId: postProjectData?.projectId,
    closeModal: postProjectData.closeModal,
    review_proposal_data: postProjectData.review_proposal_data,
    current_proposal_id: postProjectData.current_proposal_id,
    current_project_id: postProjectData.current_project_id,
    UserId: chat.UserId,
    CurrentRole:globalReducer.Me.CurrentRole,
  };
}

const mapDispatch = dispatchEvent => ({
  LoadReviewProposalData: (values) => dispatchEvent({ type: 'LOAD_REVIEW_PROPOSAL_DATA', payload: values}),
  OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  closePopup: () => dispatchEvent({ type: 'CLOSE_POST_PROJECT_POPUP', payload: {} }),
});

export default connect(mapState, mapDispatch)(ProposalVersionModal);



