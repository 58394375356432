
export default function getUserName(data) {
  // console.log(data,data.Data.sender_id,'0000000000000000000000000000 this is user name')
    let name;
  // for admin when he is in adminChat
  if(data.Me.isAdmin && data.location.pathname.includes('/adminChat')){
    name = data.Data.sender_id && data.Data.user_type === "user"
    ?  data.Data.sender_id.first_name + " " + data.Data.sender_id.last_name
    : "IndieFolio"
    return name
  }
   
  if(data.Data.user_type === "admin"){
    name = 'IndieFolio'
  } else if(data.Data.user_type === "Indiefolio"){
    name = 'IndieFolio'
  }
  else if(data.UserId === data.Data.sender_id){
    name = 'you'
  }else if(data.Data.user_type=="admin_group"){
    // name="Account Manager"
    name= data?.projectDataStates?.data?.project_data?.manager_name 
  }  else if(data.ProjectType === 'post_project_admin'){
    if (data.CurrentChat.member1._id === data.Data.sender_id) {
      name = data.CurrentChat.member1.first_name + " " + data.CurrentChat.member1.last_name
    } else if (data.CurrentChat.chat_type === 'group' && data.CurrentChat.member2._id === data.Data.sender_id){
      name = data.CurrentChat.member2.first_name + " " + data.CurrentChat.member2.last_name
    }
  } else if (data.user_id==data.Data.sender_id){
    name = data.CurrentChat?.first_name + " " + data.CurrentChat?.last_name
  }  else if (data.clientData?.user_id==data.Data.sender_id){
    name = data.clientData?.first_name + " " + data.clientData?.last_name
  } else{
    name = data.CurrentChat?.first_name + " " + data.CurrentChat?.last_name
  }

  return name
}
