import React, { useEffect, useState } from "react";
import FeaturedGalleryIconImage from "../../../../../Assets/upgrade-images/submit_proposal/gallery-blank.svg";
import GalleryBackgroundIcon from "../../../../../Assets/upgrade-images/submit_proposal/gallery.svg";
import LinkHorizontalIcon from "../../../../../Assets/upgrade-images/submit_proposal/url_link.svg";
import Import from "../../../../../Assets/upgrade-images/submit_proposal/import.svg";
import LinkSquar from "../../../../../Assets/upgrade-images/submit_proposal/link-square.svg";
import LinkBackgroundIcon from "../../../../../Assets/upgrade-images/submit_proposal/link.svg";
import TrashImage from "../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import EyeBlueIcon from "../../../../../Assets/upgrade-images/version_history/eye.svg";
import DateIcon from "../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import { fireApi } from "../../../../../utils/fireApiPrecheck";
import { ChatImageBaseUrl } from "../../../../../utils/BaseUrl";
import Loader from "../../../../authorized/Common/Common/Loader";
import { isImage, limitString, sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";
import { postAPI } from "../../../../../utils/API";
import ViewDocument from "../PopupAll/ViewDocument";
import CloseAndDelete from "./CloseAndDelete";
import DatePicker from "react-date-picker";
import ActualIteartionSelect from "../CommonComp/ActualIteartionSelect";
import { getRealFileName, handleFileName } from "../CommonFunctionsNew/CommonFunctionsNew";
import { renderDifferentFilesThumbnail } from "../../../Common/Common/FileRenderMessage";

const UploadMilestone = (props) => {
  const [attachments, setAttachments] = useState([]);
  const [uploadTimesheet, setUploadTimesheet] = useState([]);
  const [filesUploading, setfilesUploading] = useState(false);
  const [timesheetloading, setTimesheetloading] = useState(false);
  const [closeUploadMile, setCloseUploadMile] = useState(false);
  const [load, setLoad] = useState(false);
  const [description, setDescription] = useState("");
  const [linkName, setLinkName] = useState("");
  const [errorIs, setError] = useState("");
  const [documentDetail, setDocumentDetail] = useState("");
  const [numOfHours, setNumOfHours] = useState("");
  const [iteration, setIteration] = useState(false);
  const [retainerRightData, setRetainerRightData] = useState({
    task_list: [{ name: "" }],
    number_of_hour_worked_on: 1,
    start_duration: "",
    end_duration: "",
  });
  const [iterationFiles, setIterationFiles] = useState([]);
  const [viewDocument, setViewDocument] = useState(false);
  const isActual = props?.proposal_type && props.proposal_type.toLowerCase() == "actual";
  const isFixed = props?.proposal_type && props.proposal_type.toLowerCase() == "fixed";

  const [validRetainer, setValidRetainer] = useState({
    start_duration: false,
    end_duration: false,
    number_of_hour_worked_on: false,
  });

  useEffect(() => {
    setfilesUploading(false);
  }, [attachments]);

  useEffect(() => {
    if (props?.viewLatestAttachments && isActual) {
      let data = props.viewLatestAttachments;
      // return { _id: item._id, name: item?.name, type: item?.type, isCheckedBy: "" };
      let files = data?.allFiles.length
        ? data.allFiles.map((item) => {
          return { ...item,isCheckedBy: "" };
        })
        : [];
      setIterationFiles([...files]);
    }
  }, [props?.viewLatestAttachments]);

  useEffect(() => {
    if (props?.viewLatestAttachments && props.uploadMileEdit) {
      let data = props.viewLatestAttachments;
      let files = data?.allFiles.length
        ? data.allFiles.map((item) => {
          return { name: item?.name, type: item?.type };
        })
        : [];
      setAttachments([...files]);
      setRetainerRightData({
        task_list: [...data?.task_list],
        number_of_hour_worked_on: data?.number_of_hour_worked_on,
        start_duration: data?.start_duration,
        end_duration: data?.end_duration,
      });
      setUploadTimesheet([...data?.timesheet]);
    }
  }, [props?.viewLatestAttachments]);

  const isButtonActive = attachments.find((image) => image.name.length && image.type.length);
  const isRetainer = props?.proposal_type && props.proposal_type.toLowerCase() == "retainer";

  const setImageRes = (value, num) => {
    const formData = new FormData();
    let validSize = false;
    let invalid_images = [];
    let valid_images = [];

    for (var i = 0; i < value?.length && i < num; i++) {
      if (value[i]?.size > 10 * 1024 * 1024) {
        invalid_images.push(value[i]);
      } else {
        valid_images.push(value[i]);
        validSize = true;
        formData.append("chatFiles", value[i]);
      }
    }
    if (invalid_images?.length > 0) {
      let alertMsg =
        invalid_images.length > 1
          ? invalid_images?.[0].name +
          "and " +
          (invalid_images.length - 1) +
          " other files are not uploaded, Please upload files of size less than 10 Mb."
          : invalid_images?.[0].name + " is not uploaded, Please upload file of size less than 10 Mb";
      alert(alertMsg);
      setfilesUploading(false);
    }
    if (valid_images?.length > 0) {
      fireApi("UploadChatFiles", formData)
        .then((res) => {
          // console.log("res == ", res);
          const valueIs = res.chat_files;
          if (valueIs?.length > 0) {
            let allImages = attachments;
            const data = valueIs?.forEach((obj) => {
              allImages.push({ name: obj?.name, type: "file", array: isActual ? iterationFiles : [] });
            });
            // console.log("allImages == ", allImages);
            setAttachments(allImages);
            setfilesUploading(false);
          } else {
            setAttachments(attachments);
          }
        })
        .catch((err) => {
          setfilesUploading(false);
        });
    } else {
      setfilesUploading(false);
    }
  };
  const onChangeHandler = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setIteration(value);
  };
  const handleSetTimesheet = (value, num) => {
    const formData = new FormData();
    let validSize = false;
    let invalid_images = [];
    let valid_images = [];

    for (var i = 0; i < value?.length && i < num; i++) {
      if (value[i]?.size > 10 * 1024 * 1024) {
        invalid_images.push(value[i]);
      } else {
        valid_images.push(value[i]);
        validSize = true;
        formData.append("chatFiles", value[i]);
      }
    }
    if (invalid_images?.length > 0) {
      let alertMsg =
        invalid_images.length > 1
          ? invalid_images?.[0].name +
          "and " +
          (invalid_images.length - 1) +
          " other files are not uploaded, Please upload files of size less than 10 Mb."
          : invalid_images?.[0].name + " is not uploaded, Please upload file of size less than 10 Mb";
      alert(alertMsg);
      setTimesheetloading(false);
    }
    if (valid_images?.length > 0) {
      fireApi("UploadChatFiles", formData)
        .then((res) => {
          const valueIs = res.chat_files;
          if (valueIs?.length > 0) {
            let allImages = uploadTimesheet;
            const data = valueIs?.forEach((obj) => {
              allImages.push({ name: obj?.name, type: "file" });
            });
            setUploadTimesheet(allImages);
            setTimesheetloading(false);
          } else {
            setUploadTimesheet(uploadTimesheet);
          }
        })
        .catch((err) => {
          setTimesheetloading(false);
        });
    } else {
      setTimesheetloading(false);
    }
  };

  const handleOnImage = (e) => {
    const valueIs = e.target?.files;
    if (valueIs?.length > 0) {
      let num = 15 - attachments.length;
      if (valueIs?.length > num) {
        alert("Maximum upload files limit is 15");
      }
      setfilesUploading(true);
      setImageRes(valueIs, num);
    } else {
      alert("Please choose attachments");
    }
  };

  const setIterChangeFun = (name, i) => {
    let arr = [];
    iterationFiles.forEach((item, ind) => {
      if (ind === i) {
        if (item.isCheckedBy.length && name == item.isCheckedBy) {
          arr.push({ ...item, isCheckedBy: "" });
        } else {
          arr.push({ ...item, isCheckedBy: name });
        }
      } else {
        if (item.isCheckedBy.length && name != item.isCheckedBy) {
          arr.push({ ...item, isCheckedBy: item.isCheckedBy });
        } else {
          arr.push({ ...item, isCheckedBy: "" });
        }
      }
    });
    setIterationFiles([...arr]);
  };

  const handleRemoveActual = (name, index) => {
    let arr = [];
    iterationFiles.forEach((item, ind) => {
      if (item.isCheckedBy && item.isCheckedBy == name) {
        arr.push({ ...item, isCheckedBy: "" });
      } else {
        arr.push({ ...item });
      }
    });
    setIterationFiles([...arr]);
    const data = attachments?.filter((_, i) => i !== index);
    setAttachments(data);
  };

  const handleUploadTimesheet = (e) => {
    const valueIs = e.target?.files;
    if (valueIs?.length > 0) {
      let num = 10 - uploadTimesheet.length;
      if (valueIs?.length > num) {
        alert("Maximum upload files limit is 10");
      }
      setTimesheetloading(true);
      handleSetTimesheet(valueIs, num);
    } else {
      alert("Please choose upload timesheet");
    }
  };

  const handleRemove = (index) => {
    const data = attachments?.filter((_, i) => i !== index);
    setAttachments(data);
  };

  const handleRemoveTasks = (index) => {
    const data = retainerRightData.task_list.filter((_, i) => i !== index);
    setRetainerRightData({ ...retainerRightData, task_list: [...data] });
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChange = (e) => {
    setLinkName(e.target.value);
  };

  const handleKeyDown = (event, i) => {
    if (event.key === "Enter") {
      handleAppend(i);
    }
  };

  const handleAppend = (index) => {
    const validUrl = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
      linkName
    );
    if (validUrl) {
      const currentUrlIs = linkName?.split("/").slice(0)[0];
      let currentData = null;
      currentData = { name: linkName, type: "url" };
      // if (currentUrlIs === "http:" || currentUrlIs === "https:" || currentUrlIs === "www:") {
      //   currentData = { name: linkName, type: "url" };
      // } else {
      //   currentData = { name: "http://" + linkName, type: "url" };
      // }
      attachments[index].name = currentData.name;
      attachments[index].array = isActual ? iterationFiles : [];
      setAttachments([...attachments]);
    } else {
      setError("Invalid url");
    }
  };

  const addUrlToAttach = () => {
    let arr = attachments;
    arr.push({ name: "", type: "url" });
    setError("");
    setAttachments([...arr]);
    setLinkName("")
  };

  const submitMilestone = () => {
    const atleastOneImage = attachments.find((image) => image.name.length && image.type.length);
    if (!atleastOneImage && !isRetainer) {
      alert("Please upload image to continue.");
      return;
    }
    if (
      isRetainer &&
      (!retainerRightData.start_duration || !retainerRightData.end_duration || retainerRightData.number_of_hour_worked_on < 1)
    ) {
      setValidRetainer({
        start_duration: !retainerRightData.start_duration ? true : false,
        end_duration: !retainerRightData.end_duration ? true : false,
        number_of_hour_worked_on: retainerRightData.number_of_hour_worked_on < 1 ? true : false,
      });
      return;
    }
    if (isRetainer && !retainerRightData.task_list.filter((item) => (item.name.trim()).length).length) {
      alert("Please add at least one task.");
      return;
    }
    let filterAttachments = attachments.filter((item) => item.name.length && item.type.length);
    let actualAttahments = [];
    if (isActual) {
      let arr = iterationFiles.length ? iterationFiles.filter((obj) => !obj.isCheckedBy) : [];
      const filea = filterAttachments.map(m => {
        const json = JSON.parse(JSON.stringify(m));
        delete json.array
        const isvalue = iterationFiles.find(a => a.isCheckedBy == m.name)
        json._id = isvalue ? isvalue._id : "";
        json.is_iteration = isvalue ? true : false;
        return json
      })
      actualAttahments = filea
    }
    setLoad(true);
    let mileStoneData = "";
    let api = "";
    if (props?.fixedLastSubm) {
      api = "/proposal/add-raw-file";
      let arr = [...filterAttachments];
      arr.push({ type: "rowDescription", name: description ? sanitiseSummernoteText(description) : "" });
      mileStoneData = {
        proposal_id: props?.proposalId,
        allFiles: arr,
        isIteration: iteration,
      };
    } else if (isRetainer) {
      api = "/proposal/add-worklog";
      mileStoneData = {
        payment_reference_id: props?.mileObj?._id,
        allFiles: filterAttachments,
        task_list: retainerRightData.task_list.filter((item) => item.name.length),
        number_of_hour_worked_on: retainerRightData.number_of_hour_worked_on,
        start_duration: retainerRightData.start_duration,
        end_duration: retainerRightData.end_duration,
        timesheet: uploadTimesheet,
        work_log_complete_id: props?.viewAttachments?._id,
      };
    } else {
      api = "/milestone/milestone-submit";
      mileStoneData = {
        milestone_id: props?.milestone_id,
        allFiles: isActual ? actualAttahments : filterAttachments,
        description: description ? sanitiseSummernoteText(description) : "",
        raw_files_link: "",
        isIteration: iteration,
      };
    }

    // console.log("mileStoneData == ", mileStoneData);
    postAPI(api, mileStoneData)
      .then((res) => {
        if (res) {
          setLoad(false);
          props.setFixedLastSubm(false);
          props.setShowUploadMilestone(false);
          setTimeout(() => { alert("Document uploaded successfully.") }, 300)
          props.handleCallApiAgain();
        }
      })
      .catch((error) => {
        alert("There is some error please try again.");
        setLoad(false);
      });
  };

  const alertMSG = () => {
    alert("Please add at least one task.")
  }

  const fixedRightSideDiv = () => {
    return (
      <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 p-3 ipad-w-100 common_overflow_height white_bg">
        <div className="card-wrapper">
          <div className="custom_form  mob-p-0">
            <div>
              <div className="form-group mb-4 col-sm-12 mob-p-0">
                <label className="text_light_gray_md" htmlFor="last">
                  Description
                </label>
                <div className="submit-milestone-summernote">
                  <textarea
                    className={`form-control textarea_gray_500 `}
                    rows="5"
                    placeholder="Explain more details related to this milestone submission"
                    name="note"
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                  />
                </div>
              </div>
              <div className="px-3 mob-mb-70">
                <p className="font_semi_bold position-relative upload_files_checkbox">
                  <div className="cstm_checkbox">
                    <input type="checkbox" id="chk2" checked={iteration} onChange={onChangeHandler} />
                    <label htmlFor="chk2" />
                  </div>
                  Mark as an iteration
                </p>
                <p className="text_light_gray_md">Notes for milestone submission</p>
                <ul className="milestone_noted_checked text_light_gray_xs">
                  <li className="mb-2 text_light_gray_xs">
                    Milestones are defined steps & deliverables that you and the client have agreed upon in order to break the
                    project down into smaller chunks and distribute payments.
                  </li>
                  <li className="mb-2 text_light_gray_xs">
                    Please attach all files in the section to the left to help the client review & approve the milestone. Once the
                    milestone is approved, the client will make the agreed-upon payment tranche and you can start working on the
                    next milestone.
                  </li>
                  <li className="mb-2 text_light_gray_xs">In the description section, you can add any notes for the client.</li>
                  <li className="mb-2 text_light_gray_xs">
                    Kindly do not share deliverables of any future milestones before the client approves the current milestone.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDate = (e, action) => {
    if (action === "start") {
      setRetainerRightData({ ...retainerRightData, start_duration: new Date(e) });
      setValidRetainer({ ...validRetainer, start_duration: e ? false : true });
    } else {
      setRetainerRightData({ ...retainerRightData, end_duration: new Date(e) });
      setValidRetainer({ ...validRetainer, end_duration: e ? false : true });
    }
  };

  const handleNumbers = (action, which, e) => {
    let numbers = retainerRightData.number_of_hour_worked_on;
    if (action === "plus") {
      numbers == 0 ? (retainerRightData.number_of_hour_worked_on = 1) : (retainerRightData.number_of_hour_worked_on += 1);
    } else if (action === "change") {
      retainerRightData.number_of_hour_worked_on = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (retainerRightData.number_of_hour_worked_on > 1) retainerRightData.number_of_hour_worked_on -= 1;
    }
    validRetainer.number_of_hour_worked_on = retainerRightData.number_of_hour_worked_on < 1 ? true : false;
    setValidRetainer({ ...validRetainer });
    setRetainerRightData({ ...retainerRightData });
  };

  const handleChangeTaskList = (e, i) => {
    e.preventDefault();
    retainerRightData.task_list[i].name = e.target.value;
    setRetainerRightData({ ...retainerRightData });
  };

  const handleRemoveTimeSheet = (i) => {
    uploadTimesheet.splice(i, 1);
    setUploadTimesheet([...uploadTimesheet]);
  };
  const dateimg = <img src={DateIcon}/>;
  const RetainerRightSideDiv = () => {
    return (
      <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 p-3 ipad-w-100 common_overflow_height white_bg">
        <div className="row mx-0 mb-4 pb-2">
          <div className="col-lg-12">
            <p className="text_light_gray_md mb-2">Duration</p>
          </div>
          <div class="col-lg-12 col-12 pr-0">
            <div className="row mx-0 align-items-center justify-content-center">
              <div className="col-lg-5 px-0">
                <DatePicker
                  value={retainerRightData?.start_duration ? new Date(retainerRightData?.start_duration) : ''}
                  className="form-control text-center input_md"
                  clearIcon={null}
                  name="date"
                  dayPlaceholder={'dd'}
                  monthPlaceholder={'mm'}
                  yearPlaceholder={'yyyy'}
                  format="dd/MM/yyyy"
                  calendarIcon={dateimg}
                  calendarClassName="apniClass"
                  showLeadingZeros={true}
                  minDate={new Date(props?.mileObj?.start_date)}
                  maxDate={retainerRightData.end_duration ? new Date(retainerRightData.end_duration) : new Date() > new Date(props?.mileObj?.end_date) ? new Date(props?.mileObj?.end_date) : new Date()}
                  onChange={(e) => handleDate(e, "start")}
                />
                {validRetainer.start_duration ? <p className="error_message">field required</p> : ""}
              </div>
              <div className="col-lg-2 text-center">
                <p className="mb-0 font_semi_bold_w_600">to</p>
              </div>
              <div className="col-lg-5 px-0">
                <DatePicker
                  value={retainerRightData.end_duration ? new Date(retainerRightData.end_duration ): ''}
                  className="form-control text-center input_md"
                  clearIcon={null}
                  name="date"
                  dayPlaceholder={'dd'}
                  monthPlaceholder={'mm'}
                  yearPlaceholder={'yyyy'}
                  format="dd/MM/yyyy"
                  calendarIcon={dateimg}
                  calendarClassName="apniClass"
                  showLeadingZeros={true}
                  minDate={
                    retainerRightData.start_duration
                      ? new Date(retainerRightData.start_duration)
                      : new Date(props?.mileObj?.start_date)
                  }
                  maxDate={new Date() > new Date(props?.mileObj?.end_date) ? new Date(props?.mileObj?.end_date) : new Date()}
                  onChange={(e) => handleDate(e, "end")}
                />
                {validRetainer.end_duration ? <p className="error_message">field required</p> : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0 mb-4 pb-2">
          <div className="col-lg-12">
            <p className="text_light_gray_md mb-2">Number of hours worked on</p>
          </div>
          <div className="btn_field number_btn col-lg-12">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "numHours")}>
                -
              </button>
              <input
                type="text"
                class="form-control text-center input_sm"
                onKeyDown={(evt) =>
                  (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                  evt.preventDefault()
                }
                onChange={(e) => handleNumbers("change", "numHours", e)}
                value={retainerRightData.number_of_hour_worked_on}
              />
              <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "numHours")}>
                +
              </button>
            </div>
            {validRetainer.number_of_hour_worked_on ? <p className="error_message">required</p> : ""}
          </div>
        </div>
        <div className="row mx-0 mb-4 pb-2 d-flex align-items-center justify-content-between">
          <div className="col-lg-12">
            <p className="text_light_gray_md mb-2">Time Sheet</p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex ">
              <div className="tag_btn_common">
                <input
                  className="file_input_upload"
                  style={{ maxHeight: "30px" }}
                  type="file"
                  name="myfile"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx, image/*"
                  multiple
                  onChange={(e) => handleUploadTimesheet(e)}
                />
                <p className="mb-0">Upload Timesheet</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 text-right">
            <p className="mb-0 blue_text_sm">
              <img src={Import} className="img-fluid mr-2" />
              Download Template
            </p>
          </div> */}
          {uploadTimesheet.length > 0
            ? uploadTimesheet.map((item, i) => {
              return (
                <div className="col-lg-12 mt-3">
                  <div
                    className="d-flex justify-content-between title_commn "
                    style={{ lineHeight: "9px", fontSize: "13px", padding: "12px" }}
                  >
                    <p className="text_light_gray_md">{handleFileName(getRealFileName(item?.name), 40)}</p>
                    <img className="img-fluid cursor_pointer" src={TrashImage} onClick={() => handleRemoveTimeSheet(i)} />
                  </div>
                </div>
              );
            })
            : null}
        </div>
        <div className="row mx-0 mb-3 pb-2 d-flex align-items-center justify-content-between">
          <div className="col-lg-12">
            <p className="text_light_gray_md mb-2">Task List</p>
          </div>
          {retainerRightData.task_list.map((item, i) => {
            let size = i === 0 ? "10" : "10";

            return (
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="serial_number align-items-center justify-content-center d-flex col-1 col-sm-1 col-md-1 col-lg-auto col-xl-auto pr-0">
                    <p className="mb-0 font_semi_bold_w_600">{i + 1}.</p>
                  </div>
                  <div className={`input_text col-${size} col-sm-${size} col-md-${size} col-lg-${size} col-xl-${size} pr-0`}>
                    <input
                      class="form-control input_md "
                      type="text"
                      placeholder="Enter Task here"
                      onChange={(e) => handleChangeTaskList(e, i)}
                      value={item.name}
                    />
                  </div>
                  {retainerRightData?.task_list?.length === 1 ? null : (
                    <div className="serial_number align-items-center justify-content-center d-flex col-1 col-sm-1 col-md-1 col-lg-auto col-xl-auto pr-0 pl-1">
                      <img className="img-fluid cursor_pointer" src={TrashImage} onClick={() => handleRemoveTasks(i)} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row mx-0">
          <div className="col-lg-12 col-xl-12">
            <p
              className="blue_text_sm cursor_pointer"
              onClick={() => {
                let arr = retainerRightData.task_list;
                arr.push({ name: "" });
                setRetainerRightData({ ...retainerRightData, task_list: [...arr] });
              }}
            >
              <span className="mr-1">+</span> Add Task
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="worklog_upload_deliverable_wrappper worklog_actual_upload_filled_wrappper upload_milestone">
      <div
        className={`modal fade modal_fade_bg ${props?.showUploadMilestone ? "show" : ""}`}
        id="ViewingProposalFC"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: props?.showUploadMilestone ? "block" : "" }}
      >
        <div className={`modal-dialog modal-dialog-centered ${isActual ? "popup_modal_dialog_lg" : "popup_modal_dialog_lg"} `}>
          <div className="modal-content br_6 ">
            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
              <h5 className="modal-title main_modal_title" id="exampleModalLabel">
                Upload Files
              </h5>
              <button
                type="button"
                className="close close_btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  if (viewDocument) {
                    setViewDocument(false);
                  } else {
                    setCloseUploadMile(true);
                  }
                }}
              >
                <span aria-hidden="true" className="close_icon modalclose-btnicon">
                  ×
                </span>
              </button>
            </div>
            {viewDocument ? (
              <ViewDocument
                documentDetail={documentDetail}
                isFixed={isFixed}
                isActual={isActual}
                isRetainer={isRetainer}
                viewDocument={viewDocument} setViewDocument={setViewDocument}
                {...props}
              />
            ) : (
              <>
                <div className="modal-body br_6 py-0 px-0 upload_milestone_bg">
                  <div className="row mx-0">
                    {/* upload_media */}
                    <div
                      className={`col-12 col-sm-${isActual ? "12" : "7"} col-md-${isActual ? "12" : "7"} col-lg-${isActual ? "12" : "7"
                        } col-xl-${isActual ? "12" : "7"} mt-3 body_scroll py-3`}
                    >
                      {attachments?.length > 0 ? (
                        filesUploading ? (
                          <Loader style={{ margin: "0 auto" }} />
                        ) : attachments?.length ? (
                          <>
                            {attachments?.map((obj, index) => {
                              return (
                                <>
                                  {obj?.type === "file" ? (
                                    <div className="mb-3">
                                      {/* ===== upload media============ */}

                                      <div className="page_card" key={index}>
                                        <div className="p-3">
                                          <div className="row align-items-center px-0 mx-0">
                                            <div className="col-9 col-sm-10 ipad-w-100 px-0 d-flex align-items-center">
                                              <div className={` pr-3`}>
                                                <img
                                                  className="small_image_preview"
                                                  src={
                                                    isImage(obj?.name)
                                                      ? ChatImageBaseUrl + obj?.name
                                                      : renderDifferentFilesThumbnail(obj?.name)
                                                  }
                                                />
                                              </div>
                                              <p className="mb-0 text_black_xs_w_600">
                                                {handleFileName(getRealFileName(obj?.name), 40)}
                                              </p>
                                            </div>
                                            <div className="col-3 col-sm-2 ipad-w-100 d-flex align-items-center justify-content-end">
                                              {isActual && iterationFiles?.length ? (
                                                <div className="d-flex pr-3">
                                                  <div className="toggle_btn d-flex align-items-center justify-content-between">
                                                    <label className="switch mb-0">
                                                      <input
                                                        type="checkbox"
                                                        data-toggle="collapse"
                                                        href={"#multiCollapseExample" + index}
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls={"multiCollapseExample" + index}
                                                      />
                                                      <span className="slider round"></span>
                                                    </label>
                                                  </div>
                                                  <span className="mb-0 text_light_gray_md ml-3">Iteration</span>
                                                </div>
                                              ) : null}
                                              <div className={` pr-3`}>
                                                <img
                                                  className="img-fluid cursor_pointer"
                                                  onClick={() => {
                                                    setDocumentDetail(obj);
                                                    setViewDocument(true);
                                                  }}
                                                  src={EyeBlueIcon}
                                                />
                                              </div>

                                              <div>
                                                <img
                                                  className="img-fluid cursor_pointer"
                                                  src={TrashImage}
                                                  onClick={() =>
                                                    isActual ? handleRemoveActual(obj.name, index) : handleRemove(index)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* ========end upload media============= */}

                                      {isActual ? (
                                        <ActualIteartionSelect
                                          iterationFiles={iterationFiles}
                                          setIterChangeFun={setIterChangeFun}
                                          index={index}
                                          object={obj}
                                        />
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="mb-3">
                                      {/* new upload link */}
                                      <div className="page_card mb-3" key={index}>
                                        <div className="p-3">
                                          <div className="row align-items-center px-0 mx-0">
                                            {obj?.name?.length > 0 ? (
                                              <>
                                                <div className="col-9 col-sm-10 ipad-w-100 px-0 d-flex align-items-center">
                                                  <div className="pr-3">
                                                    <img className="small_image_preview" src={LinkBackgroundIcon} />
                                                  </div>
                                                  <p className="mb-0 text_black_xs_w_600" style={{ color: "#1464DC" }}>
                                                    {limitString(obj?.name, 60)}
                                                  </p>
                                                </div>
                                                <div className="col-3 col-sm-2 ipad-w-100 d-flex align-items-center justify-content-end">
                                                  {isActual && iterationFiles?.length ? (
                                                    <div className={`d-flex align-items-center pr-3`}>
                                                      <div className="toggle_btn d-flex align-items-center justify-content-between">
                                                        <label className="switch mb-0">
                                                          <input
                                                            type="checkbox"
                                                            data-toggle="collapse"
                                                            href={"#multiCollapseExample" + index}
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-controls={"multiCollapseExample" + index}
                                                          />
                                                          <span className="slider round"></span>
                                                        </label>
                                                      </div>
                                                      <span className="mb-0 text_light_gray_md ml-3">Iteration</span>
                                                    </div>
                                                  ) : null}
                                                  <div className={` pr-3`}>
                                                    <img
                                                      className="img-fluid cursor_pointer"
                                                      onClick={() => {
                                                        setDocumentDetail(obj);
                                                        setViewDocument(true);
                                                      }}
                                                      src={EyeBlueIcon}
                                                    />
                                                  </div>
                                                  <div>
                                                    <img
                                                      className="img-fluid cursor_pointer"
                                                      src={TrashImage}
                                                      onClick={() =>
                                                        isActual ? handleRemoveActual(obj.name, index) : handleRemove(index)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  className={`col-${isActual ? "11" : "11"} col-sm-${isActual ? "11" : "11"
                                                    } ipad-w-100 px-0 d-flex align-items-center`}
                                                >
                                                  <div className="pr-3">
                                                    <img className="small_image_preview" src={LinkBackgroundIcon} />
                                                  </div>
                                                  <input
                                                    class={`form-control input_md url_input ${errorIs ? "border border-danger" : ""
                                                      }`}
                                                    type="text"
                                                    placeholder="Enter URL Here"
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                  />

                                                  {linkName ? (
                                                    <>
                                                      <div className={`add_url`}>
                                                        <button
                                                          onClick={() => handleAppend(index)}
                                                          className={`${errorIs ? "error_button" : ""}`}
                                                        >
                                                          Add URL
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>

                                                <div
                                                  className={`col-${isActual ? "1" : "auto"} col-sm-${isActual ? "1" : "auto"
                                                    } ipad-w-100 d-flex align-items-center justify-content-end`}
                                                >
                                                  <div className={`pr-0`}>
                                                    <img
                                                      className="img-fluid cursor_pointer"
                                                      src={TrashImage}
                                                      onClick={() =>
                                                        isActual ? handleRemoveActual(obj.name, index) : handleRemove(index)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {/* new end upload link */}

                                      {/* ===================================================================================== */}

                                      {isActual ? (
                                        <ActualIteartionSelect
                                          iterationFiles={iterationFiles}
                                          setIterChangeFun={setIterChangeFun}
                                          index={index}
                                          object={obj}
                                        />
                                      ) : null}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                            {attachments.length < 15 ? (
                              <div className="d-flex justify-content-start mt-4">
                                <div className="d-flex cursor_pointer" style={{ width: "130px" }}>
                                  <img src={FeaturedGalleryIconImage} className="gallery_icon img-fluid mb-2 cursor_pointer" />
                                  <input
                                    className="file_input_upload cursor_pointer"
                                    style={{ maxHeight: "28px" }}
                                    type="file"
                                    name="myfile"
                                    multiple
                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx,.xlsx,.ppt,.zip, image/*"
                                    onChange={(e) => handleOnImage(e)}
                                  />
                                  <p className="mb-0 ml-2 text_black_xs_w_600 cursor_pointer" style={{ color: "#1464DC" }}>
                                    Upload Media
                                  </p>
                                </div>
                                {attachments &&
                                  attachments.filter((item) => item.type == "url" && item.name.length < 1).length > 0 ? (
                                  ""
                                ) : (
                                  <div className="d-flex cursor_pointer ml-3" style={{ width: "130px" }} onClick={addUrlToAttach}>
                                    <img src={LinkHorizontalIcon} className="gallery_icon img-fluid mb-2" />
                                    <p className="mb-0 ml-2 text_black_xs_w_600 " style={{ color: "#1464DC" }}>
                                     Upload Link
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className={`upload_meliston_box col-sm-${isActual ? "6" : "12"} mx-auto`}>
                          <div className="upload_milstone_title d-flex justify-content-center align-items-center mb-4 pb-2">
                            <p className="text_light_gray_md mb-0">Add Content </p>
                          </div>

                          <div className="upload_milstone_inner d-flex align-items-center justify-content-center">
                            <div className="upload_icon_bg_blue_200 d-flex cursor_pointer">
                              <img src={GalleryBackgroundIcon} className="gallery_icon img-fluid mb-2" />
                              <input
                                className="file_input_upload"
                                type="file"
                                name="myfile"
                                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx,.xlsx,.ppt,.zip, image/*"
                                multiple
                                onChange={(e) => handleOnImage(e)}
                              />
                              <p className="mb-0 text_black_xs_w_600">Upload Media</p>
                            </div>
                            <p className="text_light_gray_xs m-5">or</p>
                            <div className="upload_icon_bg_blue_200 d-flex cursor_pointer" onClick={addUrlToAttach}>
                              <img src={LinkSquar} className="gallery_icon img-fluid mb-2" />
                              <p className="mb-0 text_black_xs_w_600">Upload Link</p>
                            </div>
                          </div>

                          <div className="upload_milestone_sub_title d-flex justify-content-center align-items-center">
                            <p className="text_light_gray_xs mt-5">Supports JPG, JPEG, PNG, GIF, DOC, PDF, XLSX, DOCX, PPT, ZIP </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {isActual ? null : isRetainer ? RetainerRightSideDiv() : fixedRightSideDiv()}
                  </div>
                </div>
                <div className="modal-footer px-4 py-3 d-flex justify-content-between align-items-center">
                  <div className="col-sm-7 col-xl-7 content_outer">
                    <div className="d-flex align-items-center">
                      <div className="img_outer">
                        <img src={require("../../../../../Assets/images/cc.png")} className="img-fluid" alt="uploading" />
                      </div>
                      <div className="description_inner ml-3">
                        <p className="mb-0 text_gray_1100_w_700_sm">This work is licensed under a </p>
                        <a
                          className="blue_text_sm"
                          href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License.
                        </a>
                      </div>
                    </div>
                  </div>
                  {props.projStatus == 7 ? null : (
                    <div className="col-sm-4 col-xl-4 btn_outer text-right">
                      {isButtonActive || isRetainer ? (
                        <button
                          type="button"
                          className={isRetainer ?isRetainer  && retainerRightData.task_list.filter((item) => (item.name.trim()).length).length? `btn btn_common_md`:"btn btn_common_gray_md ":`btn btn_common_md`}
                          onClick={isRetainer ? isRetainer && retainerRightData.task_list.filter((item) => (item.name.trim()).length).length? submitMilestone:alertMSG:submitMilestone}
                          disabled={load ? true : false}
                        >
                          {load ? "Submitting ..." : "Submit"}
                        </button>
                      ) : (
                        <button type="button" disabled={true} className="btn btn_common_gray_md " onClick={submitMilestone}>
                          Submit
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {closeUploadMile ? <CloseAndDelete setCloseUploadMile={setCloseUploadMile} {...props} /> : null}
    </section>
  );
};

export default UploadMilestone;

// const setImageRes = (value, num) => {
//   const formData = new FormData();
//   let validSize = false;
//   let invalid_images = [];
//   let otherFormat = [];
//   let valid_images = [];
//   const allValidTypes = ["jpg", "jpeg", "png", "gif", "pdf", "doc"];

//   for (var i = 0; i < value?.length && i < num; i++) {
//     const validFormatIs = allValidTypes.includes(value[i]?.name?.split(".")[value[i]?.name?.split(".")?.length - 1]);
//     if (validFormatIs) {
//       if (value[i]?.size > 10 * 1024 * 1024) {
//         invalid_images.push(value[i]);
//       } else {
//         valid_images.push(value[i]);
//         validSize = true;
//         formData.append("chatFiles", value[i]);
//       }
//     } else {
//       otherFormat.push(value[i]);
//     }
//   }
//   if (otherFormat?.length > 0) {
//     let alertMsg =
//       otherFormat.length > 1
//         ? otherFormat?.[0]?.name +
//           "and " +
//           (otherFormat.length - 1) +
//           " other files are not uploaded, Please upload files of valid formats."
//         : otherFormat?.[0].name + " is not uploaded, Please upload file of valid format";
//     alert(alertMsg);
//     setfilesUploading(false);
//     return;
//   }
//   if (invalid_images?.length > 0) {
//     let alertMsg =
//       invalid_images.length > 1
//         ? invalid_images?.[0].name +
//           "and " +
//           (invalid_images.length - 1) +
//           " other files are not uploaded, Please upload files of size less than 10 Mb."
//         : invalid_images?.[0].name + " is not uploaded, Please upload file of size less than 10 Mb";
//     alert(alertMsg);
//     setfilesUploading(false);
//   }
//   if (valid_images?.length > 0) {
//     fireApi("UploadChatFiles", formData)
//       .then((res) => {
//         // console.log("res == ", res);
//         const valueIs = res.chat_files;
//         if (valueIs?.length > 0) {
//           let allImages = attachments;
//           // console.log("valueIs== ", valueIs);
//           const data = valueIs?.forEach((obj) => {
//             allImages.push({ name: obj?.name, type: "file" });
//           });
//           // console.log("allImages == ", allImages);

//           setAttachments(allImages);
//           setfilesUploading(false);
//         } else {
//           setAttachments(attachments);
//         }
//       })
//       .catch((err) => {
//         setfilesUploading(false);
//       });
//   } else {
//     setfilesUploading(false);
//   }
// };

// ================== file view=====================

// =======file

{
  /* <div className="page_card" key={index}>
    <div className="p-3">
      <div className="row align-items-center px-0 mx-0">
        <div className={` col-sm-${isActual ? "auto" : "auto"} ipad-w-100 pl-0`}>
          <img
            className="small_image_preview" 
            src={
              data?.includes(obj?.name?.split(".")[obj?.name?.split(".")?.length - 1])
                ? ChatImageBaseUrl + obj.name
                : obj?.name?.includes(".pdf")
                ? PdfImage
                : obj?.name?.includes(".zip")
                ? ZipImage
                : obj?.name?.includes(".ppt")
                ? PPtImage
                : DocImage
            }
          />
        </div>
        <div className={`col-sm-${isActual ? "8" : "9"} ipad-w-100 px-0`}>
          <p className="mb-0 text_black_xs_w_600">{getRealFileName(obj?.name)}</p>
        </div>

            {isActual && iterationFiles.length ? (
          <div className={`col-sm-auto ipad-w-100 d-flex align-items-center justify-content-end`} >
            <div className="toggle_btn d-flex align-items-center justify-content-between">
              <label className="switch mb-0">
                <input
                  type="checkbox"
                  data-toggle="collapse"
                  href={"#multiCollapseExample" + index}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"multiCollapseExample" + index}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <span className="mb-0 text_light_gray_md ml-3">Iteration</span>
          </div>
        ) : null}

        <div
          className={` col-sm-${
            isActual ? "auto" : "auto"
          } ipad-w-100 d-flex align-items-center justify-content-center`}
        >
          <img
            className="img-fluid cursor_pointer"
            onClick={() => {
              setDocumentDetail(obj);
              setViewDocument(true);
            }}
            src={EyeBlueIcon}
          />
        </div>

        <div
          className={` col-sm-${
            isActual ? "auto" : "auto"
          } ipad-w-100 d-flex align-items-center justify-content-center`}
        >
          <img
            className="img-fluid cursor_pointer"
            src={TrashImage}
            onClick={() =>
              isActual ? handleRemoveActual(obj.name, index) : handleRemove(index)
            }
          />
        </div>
      </div>
    </div>
  </div> */
}

// =============url===

{
  /* <div className="page_card" key={index}>
      <div className="p-3">
        <div className="row align-items-center px-0 mx-0">
          <div className={` col-sm-${isActual ? "auto" : "auto"} ipad-w-100 pl-0`}>
            <img
              className="small_image_preview" 
              src={LinkBackgroundIcon}
            />
          </div>

          {obj?.name?.length > 0 ? (
            <>
              <div className={`col-sm-${isActual ? "8" : "9"} ipad-w-100 px-0`}>
                <p className="mb-0 text_black_xs_w_600" style={{ color: "#1464DC" }}>
                  {obj?.name}
                </p>
              </div>
              {isActual ? (
                <div
                  className={`col-sm-auto  ipad-w-100 d-flex align-items-center justify-content-end`}
                >
                  <div className="toggle_btn d-flex align-items-center justify-content-between">
                    <label className="switch mb-0">
                      <input
                        type="checkbox"
                        data-toggle="collapse"
                        href={"#multiCollapseExample" + index}
                        role="button"
                        aria-expanded="false"
                        aria-controls={"multiCollapseExample" + index}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <span className="mb-0 text_light_gray_md ml-3">Iteration</span>
                </div>
              ) : null}
              <div
                className={` col-sm-${
                  isActual ? "auto" : "auto"
                } ipad-w-100 d-flex align-items-center justify-content-center`}
              >
                <img
                  className="img-fluid cursor_pointer"
                  onClick={() => {
                    setDocumentDetail(obj);
                    setViewDocument(true);
                  }}
                  src={EyeBlueIcon}
                />
              </div>
            </>
          ) : (
            <>
              <div className={`col-sm-${isActual ? "10" : "9"} ipad-w-100 px-0`} key={index}>
                <input
                  class={`form-control input_md url_input ${
                    errorIs ? "border border-danger" : ""
                  }`}
                  type="text"
                  placeholder="Enter URL Here"
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />

                {linkName ? (
                  <>
                    <div className={`add_url`}>
                      <button
                        onClick={() => handleAppend(index)}
                        className={`${errorIs ? "error_button" : ""}`}
                      >
                        Add URL
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}

          <div
            className={` col-sm-${
              isActual ? "auto" : "auto"
            } ipad-w-100 d-flex align-items-center justify-content-center`}
          >
            <img
              className="img-fluid cursor_pointer"
              src={TrashImage}
              onClick={() =>
                isActual ? handleRemoveActual(obj.name, index) : handleRemove(index)
              }
            />
          </div>
        </div>
      </div>
    </div> */
}
