import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocationDP from '../LocationDP';
import ErrorSpan from '../../Common/Common/ErrorSpan';
import StyleQuestion from './Style';
import TypeQuestion from './Type';
import { getAPI } from '../../../../utils/API';

class CollateralFormJobPop extends Component {
  state = {
    collName: "",
    collId: ""
  };

  componentDidMount() {
    this.props.loadData();
    if (this.props?.checkMyColl) {
      if (this.props?.paths) { 
        this.handleGetCollDetails(this.props?.paths)
      }
    }
    
  }

  handleGetCollDetails = (paths) => {
    getAPI(`/collateral/get-collateral-detail?id=${paths}`).then((res) => {
      // console.log("res = ", res?.data?.name)
      let name = res?.data ? res?.data?.name : ""
      let id = res?.data ? res?.data?._id : ""
      this.setState({ collName: name,collId:id }, () => {
        // console.log("name , id = ",name,id)
        let filter = this.props?.AllCollaterals && this.props.AllCollaterals.filter(item => item?._id === id)?.[0]
        // console.log("filter = ",filter)
        if(filter)
        this.props.onDPChange({
          name: 'ALLCOLLATERAL_NAME',
          value: filter?.name,
        });
        this.props.LoadCollateralQuestions(filter?._id);
      })
    }).catch((error) => {
        console.log(error);
    })  
  }

  state = {
    styleQuestionError: false,
    typeQuestionError: false,
  };

  // style and type question validation
  validation = () => {
    if (
      this.props.styleQuestion &&
      this.props.styleQuestion.length &&
      this.props.styleAnswer.length === 0
    ) {
      this.setState({ styleQuestionError: true });
      return false;
    }
    if (
      this.props.typeQuestion &&
      this.props.typeQuestion.length &&
      this.props.typeAnswer.length === 0
    ) {
      this.setState({ typeQuestionError: true });
      return false;
    }
    return true;
  };

  onSubmit = () => {
    const {
      styleQuestion,
      typeQuestion,
      styleAnswer,
      typeAnswer,
      // ExperienceMonthError,
      // TypeError,
      // TimeError,
      // ExperienceError,
      // StartPriceError,
      // EndPriceError,
      // CollateralError,
    } = this.props;
    // e.preventDefault();

    // style and type question validation
    if (!this.validation()) {
      return;
    }

    // send style and type answer if questions exists
    let fullStyleAnswer = [],
      fullTypeAnswer = [];
    if (styleQuestion) {
      fullStyleAnswer = [{ ...styleQuestion[0], answer: styleAnswer }];
    }
    if (typeQuestion) {
      fullTypeAnswer = [{ ...typeQuestion[0], answer: typeAnswer }];
    }

    if (this.onBlur({ target: {} })) return '';
    if (this.props.edit === -1) {
      this.props.UserAddCollateral({
        collateral_id: this.props.CollateralId,
        name: this.props.Name,
        year: this.props.Experience,
        month: this.props.ExperienceMonth,
        time: this.props.Time,
        type: this.props.Type,
        start_price: this.props.StartPrice,
        end_price: this.props.EndPrice,
        style_question_answer: fullStyleAnswer,
        type_question_answer: fullTypeAnswer,
        status: 0, // status = 0 means it is going under review
      });
    } else {
      this.props.UpdateUserCollateral({
        collateral_id: this.props.CollateralId,
        name: this.props.Name,
        year: this.props.Experience,
        month: this.props.ExperienceMonth,
        time: this.props.Time,
        type: this.props.Type,
        start_price: this.props.StartPrice,
        end_price: this.props.EndPrice,
        _id: this.props.ID,
        style_question_answer: fullStyleAnswer,
        type_question_answer: fullTypeAnswer,
        status: 0, // status = 0 means it is going under review
      });
    }
  };

  handleExperChange = (obj) => {
    const { Experience } = this.props;
    let experienceState;
    if (obj === 'Desc') {
      experienceState = Experience > 0 ? parseInt(Experience, 10) - 1 : 0;
    } else if (obj === 'Asc') {
      experienceState = Experience >= 0 ? parseInt(Experience, 10) + 1 : 0;
    }

    this.props.onDPChange({
      name: 'ALLCOLLATERAL_EXPERIENCE',
      value: experienceState,
    });
  };

  handleTimeChange = (obj) => {
    const { Time } = this.props;
    let currentTime;
    if (obj === 'Asc') {
      currentTime = Time >= 0 ? parseInt(Time, 10) + 1 : 0;
    } else if (obj === 'Desc') {
      currentTime = Time > 0 ? parseInt(Time, 10) - 1 : 0;
    }

    this.props.onDPChange({
      name: 'ALLCOLLATERAL_AVGTIME',
      value: currentTime,
    });
  };

  handleMonthChange = (obj) => {
    const { ExperienceMonth } = this.props;
    let currentTime;
    if (obj === 'Asc') {
      currentTime =
        ExperienceMonth >= 0 ? parseInt(ExperienceMonth, 10) + 1 : 0;
    } else if (obj === 'Desc') {
      currentTime = ExperienceMonth > 0 ? parseInt(ExperienceMonth, 10) - 1 : 0;
    }

    this.props.onDPChange({
      name: 'ALLCOLLATERAL_EXPERIENCEMONTH',
      value: currentTime,
    });
  };

  handleAvrageTime = (obj) => {
    const { avgTimeType, TypeIndex } = this.props;
    let currentState = TypeIndex;
    if (obj === 'Asc') {
      currentState =
        TypeIndex === avgTimeType.length-1 ? avgTimeType.length-1 : TypeIndex + 1;
    } else if (obj === 'Desc') {
      currentState = TypeIndex === 0 ? 0 : TypeIndex - 1;
    }

    this.props.onDPChange({
      name: 'ALLCOLLATERAL_TYPEINDEX',
      value: currentState,
    });
    this.props.onDPChange({
      name: 'ALLCOLLATERAL_DURATION',
      value: avgTimeType[currentState],
    });
  };

  onBlur = (e) => {
    let StartPrice =
      e.target.name === 'ALLCOLLATERAL_STARTPRICE'
        ? e.target.value
        : this.props.StartPrice;
    let EndPrice =
      e.target.name === 'ALLCOLLATERAL_ENDPRICE'
        ? e.target.value
        : this.props.EndPrice;
    let r = false;
    this.props.onDPChange({
      name: 'COLLATERAL_ERROR',
      value: {
        StartPriceError: false,
        EndPriceError: false,
      },
    });
    if (EndPrice && StartPrice) {
      if (parseInt(StartPrice, 10) > parseInt(EndPrice, 10)) {
        r = true;
        this.props.onDPChange({
          name: 'COLLATERAL_ERROR',
          value: {
            StartPriceError: false,
            StartPriceErrorMessage: '',
            EndPriceError: true,
            EndPriceErrorMessage: 'Max charge cannot be less than Min charge',
          },
        });
      }
    }
    return r;
  };

  setStyleAnswerHandler = (ans) => {
    this.setState({ styleQuestionError: false });
    this.props.setStyleAnswer(ans);
  };
  setTypeAnswerHandler = (ans) => {
    this.setState({ typeQuestionError: false });
    this.props.setTypeAnswer(ans);
  }; 

  componentDidUpdate = (prevProps) => {
    if (prevProps?.AllCollateralsList.length != this.props?.AllCollateralsList.length) {
      let res = [...this.props?.AllCollateralsList]
      let data = res.pop()
      if (data?.collateral_id === this.state.collId) {
        this.props.closePop()
        this.props.redirect.push(`/dashboard/jobs/:${this.props?.paths}/${this.props?.pathSlug}`);
        this.props.closePop()
        setTimeout(() => {
          this.handleApiPopup()
        },500)
      }
    }
  }

  handleApiPopup = () => {
    if (this.props?.paths) {
      this.props.OpenPopup('IncomingBriefReviewJob')
      this.props.LoadBriefData(this.props?.paths)
      this.props.ViewIncomingBrief(this.props?.paths)
    }
  }

  render() {
    const {
      Name,
      Type,
      EndPrice,
      StartPrice,
      Time,
      Experience,
      ExperienceMonth,
      edit,
      AllCollaterals,
      CollateralError,
      EndPriceError,
      StartPriceError,
      ExperienceError,
      TimeError,
      TypeError,
      TypeErrorMessage,
      CollateralErrorMessage,
      EndPriceErrorMessage,
      StartPriceErrorMessage,
      ExperienceErrorMessage,
      TimeErrorMessage,
      // saving,
      ExperienceMonthError,
      ExperienceMonthErrorMessage,
      styleQuestion,
      typeQuestion,
      styleAnswer,
      typeAnswer,
      collateralDesc,
      collateralPricing,
      collateralUnit,
    } = this.props;

    let ExperienceShow = Experience + ' years';
    let Experience2Show = ExperienceMonth + ' months';


    // console.log('styleQuestion in form ', styleQuestion);
    // console.log('styleAnswer in form ', styleAnswer);
    // console.log('CollateralQuestions in form ', CollateralQuestions);
    // console.log('%c TYPE', 'font-size: 22px; color:orange');
    // console.log("AllCollaterals = ",AllCollaterals);
    //  console.log("this.props?.pathSlug === ",this.props?.pathSlug)
    

    return (
      <React.Fragment>
        <div
          className={`custom_form wrapper-form p-4 mb-4 mob-p-15 ${
            edit !== undefined && edit !== -1 ? 'active border-top-0-10 border-top-none' : ''
          }`}
        >
          <form>
            <div className="row m-0">
              <div className="form-group mt-2 mb-4 col-sm-12 pl-0 position-relative mob-p-0">
                <label
                  className="label font-weight-600 font-14 mob-font-13"
                  htmlFor="first"
                >
                  Choose collateral
                </label>
                {Name !== '' ? (
                  <div className="select-tags background-white d-flex align-items-center flex-wrap email">
                    <span className="selected-tags p-2 mb-2" key={0}>
                      {Name}
                      {edit === -1 ? <i
                        className="fas fa-times ml-1 font-13"
                        onClick={() => {
                          this.props.onDPChange({
                            name: 'ALLCOLLATERAL_NAME',
                            value: '',
                          });
                          this.props.ResetCollateralQuestions();
                        }}
                      ></i> : null}
                    </span>
                  </div>
                ) : (
                  <LocationDP
                    placeholder={'Eg: Branding, Video Editing, Web UX/UI'}
                    isCollateral={true}
                    Selected={Name}
                    List={AllCollaterals}
                    error={CollateralError}
                    message={CollateralErrorMessage}
                    onChange={(e) => {
                      // console.log('IN e ', e);
                      this.props.loadCollaterals(e);
                    }}
                    className="w-98"
                    infoText="Collaterals are services offered by you. Please choose at least 1 collateral that you offer to proceed."
                    onSelect={(e) => {
                      console.log('IN colletral form ', e);
                      this.props.onDPChange({
                        name: 'ALLCOLLATERAL_NAME',
                        value: e.name,
                      });
                      this.props.LoadCollateralQuestions(e._id);
                    }}
                  />
                )}
                {/* AllCollaterals */}
                <span className="font-14 input-icon sub_title font-weight-500 pt-2 d-block">
                  <i className="fas fa-search"></i>
                </span>
              </div>
              {styleQuestion && styleQuestion.length ? (
                <div className="form-group mb-4 col-sm-12 pl-0 position-relative mob-p-0">
                  <label
                    className="label font-weight-600 font-14 mob-font-13"
                    // style={{ color: collateralError ? '#d63447' : '' }}
                  >
                    Which style do your offer in {Name}
                    {/* {collateralIs} */}
                  </label>
                  <StyleQuestion
                    StyleQuestions={styleQuestion}
                    answer={(ans) => this.setStyleAnswerHandler(ans)}
                    styleAnswers={styleAnswer}
                    error={this.state.styleQuestionError}
                  />
                </div>
              ) : null}
              {typeQuestion && typeQuestion.length ? (
                <div className="form-group mb-4 col-sm-12 pl-0 position-relative mob-p-0">
                  <label
                    className="label font-weight-600 font-14 mob-font-13"
                    // style={{ color: collateralError ? '#d63447' : '' }}
                  >
                    Which type do your offer in {Name}
                    {/* {collateralIs} */}
                  </label>
                  <TypeQuestion
                    TypeQuestions={typeQuestion}
                    answer={(ans) => this.setTypeAnswerHandler(ans)}
                    typeAnswers={typeAnswer}
                    error={this.state.typeQuestionError}
                  />
                </div>
              ) : null}
              <div className="form-group row m-0">
                <div className="form-group mb-4 col-sm-6 pl-0 experience-section mob-p-0">
                  <label
                    className="label font-weight-600 font-14 mob-font-13"
                    htmlFor="first"
                  >
                    Experience in collateral
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="input-group w-45 mob-w-100">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-text-left font-12"
                          onClick={(e) => this.handleExperChange('Desc')}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        name="ALLCOLLATERAL_EXPERIENCE"
                        value={ExperienceShow}
                        onChange={(e) => this.props.onDPChange(e.target)}
                        className={`form-control email pl-3`}
                        placeholder="5 years"
                        disabled
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text input-text-right font-12"
                          onClick={(e) => this.handleExperChange('Asc')}
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div>

                      {ExperienceError ? (
                        <span className="text-danger font-weight-600 font-12 py-1">
                          {ExperienceErrorMessage}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-group w-45 mob-w-100 ml-4">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-text-left font-12"
                          onClick={(e) => this.handleMonthChange('Desc')}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        name="ALLCOLLATERAL_EXPERIENCEMONTH"
                        value={Experience2Show}
                        onChange={(e) => this.props.onDPChange(e.target)}
                        className={`form-control email pl-3`}
                        placeholder="5 months"
                        disabled
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text input-text-right font-12"
                          onClick={(e) => this.handleMonthChange('Asc')}
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {ExperienceError ? (
                    <ErrorSpan
                      error={ExperienceError}
                      message={ExperienceErrorMessage}
                    />
                  ) : (
                    <ErrorSpan
                      error={ExperienceMonthError}
                      message={ExperienceMonthErrorMessage}
                    />
                  )}
                </div>
                <div className="form-group mb-4 col-sm-6 experience-section mob-p-0">
                  {/* <label
                    className="label font-weight-600 font-14 mob-font-13"
                    htmlFor="first"
                  >
                    Average time taken
                  
                  </label> */}
                    <label className="label font-weight-600 font-14 mob-font-13 position-relative" htmlFor="first">Average time taken {collateralDesc && collateralDesc.length &&  <span className="tool" data-tip={collateralDesc}><i className="fas fa-info-circle ml-1"></i></span>}</label>
                  <div className="d-flex align-items-center">
                    <div className="input-group w-45 mob-w-100">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-text-left font-12"
                          onClick={(e) => this.handleTimeChange('Desc')}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        name="ALLCOLLATERAL_AVGTIME"
                        value={Time}
                        onChange={(e) => this.props.onDPChange(e.target)}
                        className={`form-control email pl-3 text-center ${
                          TimeError === true ? 'border-error' : ''
                        }`}
                        placeholder="3"
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text input-text-right font-12"
                          onClick={(e) => this.handleTimeChange('Asc')}
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div>
                    </div>
                    <div className="input-group w-45 mob-w-100 ml-4">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text input-text-left font-14"
                          onClick={(e) => this.handleAvrageTime('Desc')}
                        >
                          <i className="fas fa-angle-left"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        name="ALLCOLLATERAL_DURATION"
                        value={Type}
                        onChange={(e) => this.props.onDPChange(e.target)}
                        className={`form-control email pl-3 text-center ${
                          TypeError === true ? 'border-error' : ''
                        }`}
                        placeholder="Months"
                        disabled
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text input-text-right font-14"
                          onClick={(e) => this.handleAvrageTime('Asc')}
                        >
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </div>
                      {/* <ErrorSpan error={TypeError} message={TypeErrorMessage} /> */}
                    </div>
                  </div>
                  {TimeError ? (
                    <ErrorSpan error={TimeError} message={TimeErrorMessage} />
                  ) : (
                    <ErrorSpan error={TypeError} message={TypeErrorMessage} />
                  )}
                </div>
              </div>
              <div className="form-group mb-4 col-sm-12 pl-0 mob-p-0">
                <label
                  className="mb-0 label font-weight-600 font-14 mob-font-13 position-relative"
                  htmlFor="last"
                >
                  {`How much do you charge ${collateralPricing ? collateralPricing === 'per_unit' ? `per ${collateralUnit}` : `for a ${Name} project` : ''} ?`}
                  {collateralDesc && collateralDesc.length && collateralPricing !== 'per_unit' &&  <span className="tool" data-tip={collateralDesc}><i className="fas fa-info-circle ml-1"></i></span>}
                  {/* <span className="info_icon"><i className="fas fa-info-circle ml-1"></i><span className="tooltip_text">orem Ipsum is simply dummy text</span></span> */}
                </label>
                <span class="font-12 text-sub font-weight-500 mb-2 d-block">The pricing range will just be used for indicative purposes & to generate leads for you.</span>
                <div className="w-100 d-flex work-experience">
                  <div className="form-group w-48 mob-w-45 position-relative">
                    <input
                      type="text"
                      className={`form-control email mob-w-100 ${
                        EndPriceError === true ? 'border-error' : ''
                      }`}
                      placeholder="Minimum Price"
                      name="ALLCOLLATERAL_STARTPRICE"
                      value={StartPrice}
                      onChange={(e) => this.props.onDPChange(e.target)}
                      onBlur={(e) => {
                        this.onBlur(e);
                      }}
                      onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
                      autoComplete="off"
                    />
                    <span className="font-14 text-light-gray font-weight-500 pt-2 d-block">
                      <i className="fas fa-rupee-sign post-search rupees"></i>
                    </span>
                    <ErrorSpan
                      error={StartPriceError}
                      message={StartPriceErrorMessage}
                    />
                  </div>
                  <span className="w-5 mob-w-10 text-center font-20 pt-2">
                    -
                  </span>
                  <div className="form-group w-48 mob-w-45 position-relative">
                    <input
                      type="text"
                      className={`form-control email mob-w-100 ${
                        EndPriceError === true ? 'border-error' : ''
                      }`}
                      name="ALLCOLLATERAL_ENDPRICE"
                      value={EndPrice}
                      onChange={(e) => {
                        this.props.onDPChange(e.target);
                      }}
                      onBlur={(e) => {
                        this.onBlur(e);
                      }}
                      onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
                      autoComplete="off"
                      placeholder="Maximum Price"
                    />
                    <span className="font-14 text-light-gray font-weight-500 pt-2 d-block">
                      <i className="fas fa-rupee-sign post-search right_icon rupees"></i>
                    </span>
                    <ErrorSpan
                      error={EndPriceError}
                      message={EndPriceErrorMessage}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="form-group mb-4 col-sm-12 pl-0 mob-p-0">
                <label
                  className="label font-weight-600 font-14 mob-font-13"
                  htmlFor="last"
                >
                  How much do you charge for a logo design project?
                </label>
                <div className="w-100 dflex">
                  <input
                    type="number"
                    name="ALLCOLLATERAL_STARTPRICE"
                    value={StartPrice}
                    onChange={(e) => this.props.onDPChange(e.target)}
                    onBlur={(e) => {
                      this.onBlur(e);
                    }}
                    className={`form-control text-center email mob-w-100 ${
                      StartPriceError === true ? 'border-error' : ''
                    }`}
                    placeholder="Enter details here"
                  />
                  <span className="font-14 input-icon text-light-gray font-weight-500 pt-2 d-block">
                    <i className="fas fa-rupee-sign"></i>
                  </span>
                  <span className="w-8 text-center font-20">-</span>
                  <input
                    type="number"
                    name="ALLCOLLATERAL_ENDPRICE"
                    value={EndPrice}
                    onChange={(e) => {
                      this.props.onDPChange(e.target);
                    }}
                    onBlur={(e) => {
                      this.onBlur(e);
                    }}
                    className={`form-control text-center email mob-w-100 ${
                      EndPriceError === true ? 'border-error' : ''
                    }`}
                    placeholder="Enter details here"
                  />
                  <span className="font-14 input-icon icon-right text-light-gray font-weight-500 pt-2 d-block">
                    <i className="fas fa-rupee-sign"></i>
                  </span>
                </div>
                <ErrorSpan
                  error={StartPriceError}
                  message={StartPriceErrorMessage}
                />
                <ErrorSpan
                  error={EndPriceError}
                  message={EndPriceErrorMessage}
                />
              </div> */}
            </div>
            {/* <div className="form-group text-right pr-3 pt-1 pb-2 mb-0 mob-pr-0">
              {edit !== -1 ? (
                <button
                  type="submit"
                  className="main-btn px-4"
                  disabled={saving}
                >
                  {saving ? 'Updating...' : 'Update'}
                </button>
              ) : (
                <button
                  type="submit"
                  className="main-btn px-4"
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save'}
                </button>
              )}
            </div> */}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let addCollateral = store.addCollateral;
  let { CollateralListing } = store.creatorProfile;

  const remainingCollaterals = addCollateral.AllCollaterals.filter(
    (colletral) =>
      !CollateralListing.some((item) => item.collateral_id === colletral._id)
  );

    // console.log('%c REMAINING COLLATERALS', 'font-size: 22px; color:orange');
    // console.log("remainingCollaterals = ",remainingCollaterals);
    // console.log("addCollateral = ",addCollateral);
    
  return {
    saving: globalReducer.saving,
    CollateralId: addCollateral.CollateralId,
    Name: addCollateral.Name,
    Type: addCollateral.Type,
    EndPrice: addCollateral.EndPrice,
    StartPrice: addCollateral.StartPrice,
    Time: addCollateral.Time,
    Experience: addCollateral.Experience,
    ExperienceMonth: addCollateral.ExperienceMonth,
    collateralPricing: addCollateral.collateralPricing,
    collateralDesc: addCollateral.collateralDesc,
    collateralUnit: addCollateral.collateralUnit,
    
    CollateralError: addCollateral.CollateralError,
    EndPriceError: addCollateral.EndPriceError,
    StartPriceError: addCollateral.StartPriceError,
    ExperienceError: addCollateral.ExperienceError,
    TimeError: addCollateral.TimeError,
    TypeError: addCollateral.TypeError,
    ExperienceMonthError: addCollateral.ExperienceMonthError,
    CollateralErrorMessage: addCollateral.CollateralErrorMessage,
    EndPriceErrorMessage: addCollateral.EndPriceErrorMessage,
    StartPriceErrorMessage: addCollateral.StartPriceErrorMessage,
    ExperienceErrorMessage: addCollateral.ExperienceErrorMessage,
    TimeErrorMessage: addCollateral.TimeErrorMessage,
    TypeErrorMessage: addCollateral.TypeErrorMessage,
    ExperienceMonthErrorMessage: addCollateral.ExperienceMonthErrorMessage,

    ID: addCollateral.ID,
    edit: addCollateral.edit,
    TypeIndex: addCollateral.TypeIndex,
    avgTimeType: addCollateral.avgTimeType,
    AllCollaterals: remainingCollaterals,
    CollateralQuestions: addCollateral.CollateralQuestions,
    styleQuestion: addCollateral.CollateralQuestions?.Collateral_style_question,
    typeQuestion: addCollateral.CollateralQuestions?.Collateral_type_question,
    styleAnswer: addCollateral.StyleAnswer,
    typeAnswer: addCollateral.TypeAnswer,

    AllCollateralsList : CollateralListing
  };
};
const mapDispatch = (dispatchEvent) => ({
  loadData: () => dispatchEvent({ type: 'LOAD_USERWORK', payload: {} }),
  onDPChange: (e) =>
    dispatchEvent({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UserAddCollateral: (e) =>
    dispatchEvent({ type: 'USERADD_COLLATEROL', payload: e }),
  UpdateUserCollateral: (e) =>
    dispatchEvent({ type: 'UPDATE_UPDATECOLLATERAL_SAGA', payload: e }),
  loadCollaterals: (e) =>
    dispatchEvent({ type: 'LOAD_ADDCOLLATERALS_SAGA', payload: e }),
  LoadCollateralQuestions: (id) =>
    dispatchEvent({
      type: 'LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL',
      payload: { ques_id: id, ans_id: '' },
    }),
  setStyleAnswer: (ans) =>
    dispatchEvent({ type: 'UPDATE_STYLE_ANSWER_SAGA', payload: ans }),
  setTypeAnswer: (ans) =>
    dispatchEvent({ type: 'UPDATE_TYPE_ANSWER_SAGA', payload: ans }),
  ResetCollateralQuestions: () =>
    dispatchEvent({ type: 'RESET_QNA_IN_USER_COLLATERAL' }),
});
export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  CollateralFormJobPop
);
