import { fireApi } from "../../../utils/fireApiPrecheck";
import { put, select } from "redux-saga/effects";
import {creatorProfile} from '../workingSagas/selectors/index.select';

export function* loadProjectData(value) {
    try {
        value = (value && value.payload) ? value.payload.id : "";
        let creatorProfileData = yield select(creatorProfile);
        let publicView     = creatorProfileData.public;
        if(publicView === true) {
            let projectData = yield fireApi('GetProjectDataPublic',{collateral_id:value});
            yield put({ type: "GET_PROJECT_DATA", values: projectData})
        } else {
            let projectData = yield fireApi('GetProjectData', { id: value });
            yield put({ type: "GET_PROJECT_DATA", values: projectData})
        }
    } catch (error) {
        yield put({type:"PROJECT_DATA_ERROR",values:true})
        console.log(error);
    }
}

export function* editUserProject(value) {
    try {
        const {id,history} = value.payload;
        // yield put({ type: "EDIT_USER_PROJECT_1", values: id})
        value = (value && value.payload) ? value.payload.id : "";
        let creatorProfileData = yield select(creatorProfile);
        let publicView     = creatorProfileData.public;
        if(publicView === true) {
            let projectData = yield fireApi('GetProjectDataPublic',{collateral_id:id});
            yield put({ type: "SET_USER_PROJECT_DATA", values: projectData})
        } else {
            let projectData = yield fireApi('GetProjectData', { id: id });
            projectData.project.images_or_links.forEach((image, index) => {
                if(!image.id){
                    image.id = `image-${index}`
                } 
            })
            yield put({ type: "SET_USER_PROJECT_DATA", values: projectData})

        }


        history.push('/uploadproject/choose_collateral')
    } catch (error) {
        console.log(error);
    }
}

