import React, { Component } from 'react'
import Rating from 'react-rating'
import { limitString } from '../../../../utils/Helperfunctions';

export default class ReviewMoalBody extends Component {

    state = {
        rating: 0,
        reason: [],
        description: '',
        plateFormFeedbackQuestions: [{
            id: 'q1',
            question: "On a scale of 0 to 10, how likely are you to recommend our business to a friend or colleague?",
            answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            selectedAnswer: null,
            errorMessage:"Please rate our plateform on scale of 0 to 10."
        }, {
            id: 'q2',
            question: "How would you feel if you could no longer use IndieFolio?",
            answers: ["Very disappointed", "Somewhat disappointed", "Not disappointed"],
            selectedAnswer: null,
            errorMessage:"Please answer `How would you feel if you could no longer use IndieFolio`"
        }]
    }

    handleChangePlateformAnswer = (questionId, answer) => {
  
        let newData = [];
        this.state.plateFormFeedbackQuestions.forEach(el => {
            if (el.id === questionId) {
                newData.push({
                    ...el,
                    selectedAnswer: answer
                })
            } else {
                newData.push(el)
            }
        })
      
        this.setState({ plateFormFeedbackQuestions: [...newData] },()=>{
            // console.log('answer', this.state.plateFormFeedbackQuestions)
        })

    }
    handleOnChange = (e, obj) => {
        // const { name, checked } = e.target;
        // console.log({ name, checked, obj })
        const isAlreadyChecked = this.state.reason.find(el => el.text === obj.text);

        if (isAlreadyChecked) {
            this.setState({ reason: [...this.state.reason.filter(el => el.text !== obj.text)] })
        } else {
            this.setState({ reason: [...this.state.reason, { text: obj.text }] })
        }
    }


    componentDidMount() {
    }

    formValidation = () => {
        const { rating, description, reason, plateFormFeedbackQuestions } = this.state
        const { checkboxListObj, noRating, descriptionRequired, form1Completed } = this.props
        let isValid = true;
        if (!form1Completed && !noRating && rating === 0) {
            alert('Please give rating')
            isValid = false
        }
        if (!form1Completed && checkboxListObj && reason.length === 0) {
            alert('Please select at least one reason')
            isValid = false
        }
        if (!form1Completed && descriptionRequired && description.trim().length === 0) {
            alert('Please write a comment')
            isValid = false
        }
        if(form1Completed){
            plateFormFeedbackQuestions.forEach(el=>{
                if(!el.selectedAnswer){
                    alert(el.errorMessage)
                    isValid = false
                }
            })
        }

        return isValid
    }

    handleRatingOnChange = (value) => {
        // clear reaseon array if rating changes if necessary
        // console.log('handleRatingOnChange', value)
        this.setState((prevState) => {
            if (prevState.rating === 0) {
                // handle initial state
                return ({ rating: value })
            }
            if ((prevState.rating <= 3 && value > 3) || (prevState.rating >= 4 && value <= 3)) {
                return ({ reason: [] })
            }
        })

        this.setState({ rating: value })
    }

    handleSubmit = () => {
        if (this.formValidation()) {
            const { checkboxListObj, noRating,form1Completed } = this.props
            const { rating, description, reason, plateFormFeedbackQuestions } = this.state
            const obj = {}
            obj.description = description
            // console.log('data is ready to send  ', this.state)
            if (checkboxListObj) {
                obj.reason = reason;
            }
            if (!noRating) {
                obj.rating = rating;
            }
           if(!form1Completed){
            this.props.handleSubmit(obj)
           }else{
               const ques_ans = plateFormFeedbackQuestions.map(el=>{
                   return {question:el.question, answer:el.selectedAnswer}
               })
            this.props.handleSubmit([...ques_ans])
           }
        }
    }

    render() {
        const { checkboxListObj, propsSubtext, text, noRating, image, form1Completed,projectName } = this.props
        const { rating, description, reason, plateFormFeedbackQuestions } = this.state
        const checkboxListType = (rating !== 0 && rating <= 3) ? 'negative' : 'positive' // rating === 0 check is for handle initial state otherwise it will show negative reviews first
        const subtext = checkboxListObj ? checkboxListObj[`${checkboxListType}Subtext`] : propsSubtext
        return (
            <>
                <div className="modal-dialog modal-dialog-centered mob-modal-xs screen-2k-900 ipad-screen-650" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row m-0">
                                <div className="col-sm-12 p-0">
                                    <div className="profile-creator ml-05 mob-m-0">
                                        <div className="py-20 px-40 mob-px-15 text-center mob-text-left">
                                            <h2 className="mt-0 font-weight-500 mb-0 pt-2 font-18 mob-font-16 line-height-14">We'd love to hear your feedback about working on {limitString(projectName,25)}</h2> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 p-3 bg-gray-morelight">
                                    <div className="d-flex align-items-center justify-content-center mob-justify-content-unset">
                                        <div className="publish-project active">
                                            <p className="mb-0 font-weight-bold d-flex align-items-center">
                                                <span className="sticky-note"><i className="fas fa-user-check font-18 mob-font-14 small-font-12 mr-2 text-dark-black" /></span>
                                                <span className="mob-font-12 small-font-10">Creator feedback</span>
                                            </p>
                                        </div>
                                        <div className={`choose-underline ${form1Completed ? 'active' : ''}`} />
                                        <div className={`publish-project ${form1Completed ? 'active' : ''}`}>
                                            <p className="mb-0 font-weight-bold d-flex align-items-center">
                                                <span><i className="far fa-file-alt font-18 mob-font-14 small-font-12 mr-2" /></span>
                                                <span className="mob-font-12 small-font-10">Platform feedback</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-sm-12 mob-pt-25 mob-p-0 mob-col-100 overflow-content chat-modal-height creator-feedback">
                                    <div className="custom_form p-2 mob-p-0 feedback-creator">
                                        <div className="row m-0">
                                            <div className={`col-sm-12 ${!form1Completed ? 'text-center' : ''}`}>
                                                <p className="pt-1 mb-2 feedback-profile text-center">
                                                    {image ? <img src={image} alt="profile" width={35} /> : null}
                                                </p>
                                                {!form1Completed ?
                                                    <React.Fragment>
                                                        <h2 className="font-weight-bold line-height-normal letter-spacing-01 font-18 text-dark-block">{text}</h2>
                                                        {!noRating ?
                                                            <div className="pt-1">
                                                                <div className="rating">
                                                                    <span className="font-10 font-weight-500 text-third">Very bad</span>
                                                                    <Rating
                                                                        onChange={(value) => this.handleRatingOnChange(value)}
                                                                        emptySymbol="fas fa-star font-18"
                                                                        fullSymbol="fas fa-star font-18 text-success"
                                                                        initialRating={rating}
                                                                    />
                                                                    <span className="font-10 font-weight-500 text-third">Very good</span>
                                                                </div>
                                                            </div> : null}
                                                        <div className="py-4 text-center position-relative">
                                                            <span className={`text-secondary-light font-weight-500 font-16 mob-font-14`}>{subtext}</span>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>

                                                        <div className="row m-0">
                                                            <div className="col-sm-12 p-0">
                                                                <div className="user-survey">
                                                                    {plateFormFeedbackQuestions.map((el, i) => {
                                                                        return (
                                                                            <React.Fragment key={i}>
                                                                                {i === 0 && <div className="row mx-0 mb-5" key={el.questionId}>
                                                                                    <div className="col-sm-12">
                                                                                        <label className="font-16 font-weight-600 text-dark-black mb-4"><span className="text-danger">*</span>{i + 1}.{el.question}</label>
                                                                                    </div>
                                                                                    <div className="col-sm-12 d-flex align-items-center justify-content-between">
                                                                                        <label className="font-12 font-weight-500 text-dark-black text-uppercase">Not at all likely</label>
                                                                                        <label className="font-12 font-weight-500 text-dark-black text-uppercase">Extremely likely</label>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        {el.answers.map(x => (<button key={`rate${x}`} onClick={() => this.handleChangePlateformAnswer(el.id, x)} className={`btn btn-scale btn-scale-asc-0 ${el.selectedAnswer === x ? `active` : ``}`}>{x}</button>))}

                                                                                    </div>
                                                                                </div>}
                                                                                {i === 1 &&
                                                                                    <div className="row mx-0 mb-4">
                                                                                        <div className="col-sm-12">
                                                                                            <label className="font-16 font-weight-600 text-dark-black mb-4"><span className="text-danger">*</span>{i + 1}.{el.question}</label>
                                                                                        </div>
                                                                                        <div className="col-sm-12">
                                                                                            <div className="row survey-radio">
                                                                                                {el.answers.map((ell, ii) => {
                                                                                                    return (
                                                                                                        <div className="form-check" key={ii}>
                                                                                                            <input
                                                                                                            onClick={() => this.handleChangePlateformAnswer(el.id, ell)} className="form-check-input" type="radio" name={el.question} id={ell} value={ell} />
                                                                                                            <label className="form-check-label" htmlFor={ell}>
                                                                                                                {ell}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    )
                                                                                                })}

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <h2 className="font-weight-bold line-height-normal letter-spacing-01 font-18 text-dark-block">{text}</h2>
{!noRating ?  
<div className="pt-1">
<div className="rating">
<span className="font-10 font-weight-500 text-third">Very bad</span>
<Rating
onChange={(value) => this.handleRatingOnChange(value)}
emptySymbol="fas fa-star font-18"
fullSymbol="fas fa-star font-18 text-success"
initialRating={rating}
/>
<span className="font-10 font-weight-500 text-third">Very good</span>
</div>
</div> : null}
<div className="py-4 text-center position-relative">
<span className={`text-secondary-light font-weight-500 font-16 mob-font-14`}>{subtext}</span>
</div> */}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="custom_form">
                                            <form onSubmit={() => { }}>
                                                <div className="row m-0">
                                                    {checkboxListObj && checkboxListObj[checkboxListType].length && checkboxListObj[checkboxListType].map((obj, i) =>
                                                        <div key={i} className="form-group col-sm-12 custom_checkbox">
                                                            <input
                                                                onChange={(e) => this.handleOnChange(e, obj)}
                                                                checked={reason.find(data => data.text === obj.text) ? true : false}
                                                                type="checkbox"
                                                                id={i}
                                                            />
                                                            <label htmlFor={i} className="text-secondary-light font-weight-500 letter-spacing-053">{obj.text}</label>
                                                        </div>
                                                    )}
                                                    {!form1Completed && <div className="form-group col-sm-12">
                                                        <label className="text-secondary-light font-weight-500 letter-spacing-053 mobile-show">Do you have any additional feedback?</label>
                                                        <textarea
                                                            onChange={(event) => this.setState({ description: event.target.value.substring(0, 300) })}
                                                            value={description}
                                                            className="form-control email mb-1" id="comment" placeholder={"Comment here"} />
                                                    </div>}
                                                </div>
                                                <div className="form-group text-center pl-3 pt-2 mob-px-15 mob-mb-30">
                                                    <button onClick={this.handleSubmit} type="button" className="main-btn font-16 mob-w-100 font-weight-500 px-4">Continue</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
