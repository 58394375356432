import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allPreviousYear } from '../../../../utils/PreviousYear';
import { ExperienceMonth } from '../../../../utils/PreviousYear';
import LocationDP from '../LocationDP';
import MonthYearDP from '../../Common/Common/MonthYearDP';
import TextInputBox from '../../Common/Common/TextInputBox';
import ErrorSpan from '../../Common/Common/ErrorSpan';
import SingleSelectDP from '../../Common/Common/SingleSelectDP';

class WorkExperienceForm extends Component {
  componentDidMount() {
    this.props.loadData();
  }

  onSubmit = (e) => {
    // e.preventDefault();

    if (this.onStartEndDate({})) {
      return '';
    }

    if (this.props.ID === '') {
      this.props.AddWorkExperience({
        name: this.props.Company,
        designation: this.props.Designation,
        company_image: this.props.CompanyImage,
        start_month: this.props.StartMonth,
        start_year: this.props.StartYear,
        end_month: this.props.EndMonth,
        end_year: this.props.EndYear,
        location: this.props.Location,
        industry: this.props.Industry,
        website_url: this.props.WebSite,
        is_current: this.props.CurrentRole,
        job_type: this.props.Type,
        _id: this.props.ID,
      });
    } else {
      this.props.UpdateWorkExperience({
        name: this.props.Company,
        company_image: this.props.CompanyImage,
        designation: this.props.Designation,
        start_month: this.props.StartMonth,
        start_year: this.props.StartYear,
        end_month: this.props.EndMonth,
        end_year: this.props.EndYear,
        location: this.props.Location,
        industry: this.props.Industry,
        website_url: this.props.WebSite,
        is_current: this.props.CurrentRole,
        job_type: this.props.Type,
        _id: this.props.ID,
      });
    }
  };

  onStartEndDate = (e) => {
    let Months = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    this.props.onDPChange({ name: e.name, value: e.value });

    let StartMonth =
      e.name === 'USERWORK_STARTMONTH' ? e.value : this.props.StartMonth;
    let StartYear =
      e.name === 'USERWORK_STARTYEAR' ? e.value : this.props.StartYear;
    let EndMonth =
      e.name === 'USERWORK_ENDMONTH' ? e.value : this.props.EndMonth;
    let EndYear = e.name === 'USERWORK_ENDYEAR' ? e.value : this.props.EndYear;
    let r = false;

    if (
      EndYear !== '' &&
      StartYear !== '' &&
      StartMonth !== '' &&
      EndMonth !== '' &&
      this.props.CurrentRole !== true
    ) {
      let StartDate = StartYear + Months[StartMonth];
      let EndDate = EndYear + Months[EndMonth];
      if (StartDate > EndDate) {
        r = true;
        this.dispatchAction(true);
      }
    }

    if (!r) {
      this.dispatchAction(false);
    }
    return r;
  };

  dispatchAction = (e) => {
    this.props.onDPChange({
      name: 'USERWORK_ENDMONTH_ERROR',
      value: { error: e },
    });
    this.props.onDPChange({
      name: 'USERWORK_ENDYEAR_ERROR',
      value: { error: e },
    });
  };

  render() {
    const {
      CurrentRole,
      AllCompany,
      AllLocations,
      AllIndustry,
      Company,
      Location,
      Designation,
      StartMonth,
      EndMonth,
      StartYear,
      EndYear,
      WebSite,
      Industry,
      IndustryError,
      CompanyError,
      LocationError,
      DesignationError,
      StartMonthError,
      EndMonthError,
      StartYearError,
      EndYearError,
      WebSiteError,
      // IndustryErrorMessage,
      CompanyErrorMessage,
      // LocationErrorMessage,
      DesignationErrorMessage,
      StartMonthErrorMessage,
      EndMonthErrorMessage,
      StartYearErrorMessage,
      EndYearErrorMessage,
      WebSiteErrorMessage,
      // ID,
      Type,
      TypeError,
      TypeErrorMessage,
      // saving,
      edit,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={`custom_form wrapper-form remove p-4 mb-4 mob-p-15 ${
            edit !== -1 ? 'border-top-0-10 border-top-none' : ''
          }`}
        >
          <form onSubmit={this.onSubmit}>
            <div className="row m-0">
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label" htmlFor="first">
                  Company
                </label>
                <LocationDP
                  placeholder={'Eg: IndieFolio Network'}
                  paddingEnable={true}
                  Selected={Company}
                  List={AllCompany}
                  onChange={(e) => {
            
                    this.props.loadCompany(e);
                    this.props.onDPChange({
                      name: 'USERWORK_COMPANY',
                      value: e,
                    });
                  }}
                  // infoText="Eg: IndieFolio Network"
                  onSelect={(e) => {
                    this.props.onDPChange({
                      name: 'USERWORK_COMPANY',
                      value: e.name,
                    });
                  }}
                  error={CompanyError}
                  message={CompanyErrorMessage}
                />
              </div>
              <div className="form-group position-relative mb-4 pr-0 col-sm-6 mob-p-0">
                <label className="label" htmlFor="primary">
                  Designation
                </label>
                <TextInputBox
                  placeholder="Eg: Creative Director"
                  name="USERWORK_DESIGNATION"
                  value={Designation}
                  error={DesignationError}
                  message={DesignationErrorMessage}
                />
              </div>
              <div className="form-group col-sm-12 p-0 mb-4 mob-p-0">
                <div className="form-group custom_checkbox m-0">
                  <input
                    type="checkbox"
                    id="role"
                    name="USERWORK_"
                    checked={CurrentRole}
                    onChange={(e) => {
                      this.props.onDPChange({
                        name: 'USERWORK_CURRENTROLE',
                        value: !CurrentRole,
                      });
                      this.props.onDPChange({
                        name: 'USERWORK_ENDMONTH',
                        value: '',
                      });
                      this.props.onDPChange({
                        name: 'USERWORK_ENDYEAR',
                        value: '',
                      });
                    }}
                  />
                  <label
                    htmlFor="role"
                    className="m-0 font-weight-500 text-secondary active"
                  >
                    I am currently working in this role
                  </label>
                </div>
              </div>
              <div className="form-group position-relative mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label" htmlFor="primary">
                  Start date
                </label>
                <div className="d-flex align-items-center">
                  <div className="filter-dropdown wrapper-dropdown w-48 position-relative">
                    <div className="select">
                      <div className="select">
                        <MonthYearDP
                          Title={StartMonth}
                          List={ExperienceMonth}
                          Key={'name'}
                          onChange={(e) => {
                            this.onStartEndDate({
                              name: 'USERWORK_STARTMONTH',
                              value: e.name,
                            });
                          }}
                          error={StartMonthError}
                        />
                      </div>
                    </div>
                    <ErrorSpan
                      error={StartMonthError}
                      message={StartMonthErrorMessage}
                    />
                    <i
                      className="fas fa-chevron-down select-arrow"
                      style={{ top: '20px', right: '20px' }}
                    ></i>
                  </div>
                  <div className="filter-dropdown wrapper-dropdown w-48 ml-3 position-relative">
                    <div className="select">
                      <div className="select">
                        <MonthYearDP
                          Title={StartYear}
                          List={allPreviousYear}
                          Key={''}
                          onChange={(e) => {
                            this.onStartEndDate({
                              name: 'USERWORK_STARTYEAR',
                              value: e,
                            });
                          }}
                          error={StartYearError}
                        />
                      </div>
                    </div>
                    <ErrorSpan
                      error={StartYearError}
                      message={StartYearErrorMessage}
                    />
                    <i
                      className="fas fa-chevron-down select-arrow"
                      style={{ top: '20px', right: '20px' }}
                    ></i>
                  </div>
                </div>
              </div>
              {
                <div className="form-group position-relative mb-4 pr-0 col-sm-6 mob-p-0">
                  <label className="label" htmlFor="primary">
                    End date
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="filter-dropdown wrapper-dropdown w-48 position-relative">
                      <div className="select">
                        <div className="select">
                          <MonthYearDP
                            Title={EndMonth}
                            List={ExperienceMonth}
                            Key={'name'}
                            onChange={(e) => {
                              this.onStartEndDate({
                                name: 'USERWORK_ENDMONTH',
                                value: e.name,
                              });
                            }}
                            disabled={CurrentRole}
                            error={EndMonthError}
                          />
                        </div>
                      </div>
                      <ErrorSpan
                        error={EndMonthError}
                        message={EndMonthErrorMessage}
                      />
                      <i
                        className="fas fa-chevron-down select-arrow"
                        style={{ top: '20px', right: '20px' }}
                      ></i>
                    </div>
                    <div className="filter-dropdown wrapper-dropdown w-48 ml-3 position-relative">
                      <div className="select">
                        <div className="select">
                          <MonthYearDP
                            Title={EndYear}
                            List={allPreviousYear}
                            Key={''}
                            onChange={(e) => {
                              this.onStartEndDate({
                                name: 'USERWORK_ENDYEAR',
                                value: e,
                              });
                            }}
                            disabled={CurrentRole}
                            error={EndYearError}
                          />
                        </div>
                      </div>
                      <ErrorSpan
                        error={EndYearError}
                        message={EndYearErrorMessage}
                      />
                      <i
                        className="fas fa-chevron-down select-arrow"
                        style={{ top: '20px', right: '20px' }}
                      ></i>
                    </div>
                  </div>
                </div>
              }
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label" htmlFor="first">
                  Location
                </label>
                <SingleSelectDP
                  width="w-95"
                  Selected={Location}
                  List={AllLocations}
                  onChange={(e) => {
                    this.props.loadLocation(e);
                  }}
                  onRemove={(e) => {
                    this.props.onDPChange({
                      name: 'USERWORK_LOCATION',
                      value: '',
                    });
                  }}
                  infoText="Eg: Mumbai"
                  onSelect={(e) => {
                    this.props.onDPChange({
                      name: 'USERWORK_LOCATION',
                      value: e,
                    });
                  }}
                  className="mt-15px"
                  notListed={this.props.Location}
                  error={LocationError}
                />
                {LocationError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add location to continue
                  </span>
                )}
              </div>
              <div className="form-group position-relative mb-4 pl-0 col-sm-6 mob-p-0">
                <label className="label" htmlFor="primary">
                  Industry
                </label>
                <SingleSelectDP
                  width="w-95"
                  hideList={2}
                  Selected={Industry}
                  List={AllIndustry}
                  onChange={(e) => {
                    this.props.loadIndustry(e);
                  }}
                  onRemove={(e) => {
                    this.props.onDPChange({
                      name: 'USERWORK_INDUSTRY',
                      value: '',
                    });
                  }}
                  infoText="Eg: Internet"
                  onSelect={(e) => {
                    this.props.onDPChange({
                      name: 'USERWORK_INDUSTRY',
                      value: e,
                    });
                  }}
                  notListed={this.props.Industry}
                  className="mt-left-15px mt-15px"
                  error={IndustryError}
                />
                {IndustryError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add industry to continue
                  </span>
                )}
              </div>
              <div className="form-group position-relative mb-4 pl-0 col-sm-6 mob-p-0">
                <label className="label" htmlFor="primary">
                  Type
                </label>
                <div className="d-flex align-items-center">
                  <div className="filter-dropdown w-100 wrapper-dropdown position-relative">
                    <div className="select">
                      <div className="select">
                        <MonthYearDP
                          Title={Type}
                          List={[
                            {
                              name: 'Fulltime',
                            },
                            {
                              name: 'Freelance',
                            },
                            {
                              name: 'Internship',
                            },
                          ]}
                          Key={'name'}
                          onChange={(e) => {
                            this.props.onDPChange({
                              name: 'USERWORK_TYPE',
                              value: e.name,
                            });
                          }}
                          error={TypeError}
                        />
                      </div>
                    </div>
                    <ErrorSpan error={TypeError} message={TypeErrorMessage} />
                    <i
                      className="fas fa-chevron-down select-arrow"
                      style={{ top: '20px', right: '20px' }}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label" htmlFor="first">
                  Website URL
                </label>
                <TextInputBox
                  placeholder="Eg: www.indiefolio.com"
                  name="USERWORK_WEBSITE"
                  value={WebSite}
                  error={WebSiteError}
                  message={WebSiteErrorMessage}
                  onblur={true}
                />
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let userWork = store.userWork;

  return {
    saving: globalReducer.saving,
    edit: userWork.edit,
    CurrentRole: userWork.CurrentRole,
    AllCompany: userWork.AllCompany,
    CompanyImage: userWork.CompanyImage,
    AllLocations: creatorProfile.Locations,
    AllIndustry: userWork.AllIndustry,
    Industry: userWork.Industry,
    Company: userWork.Company,
    Location: userWork.Location,
    Insuration: userWork.Insuration,
    Designation: userWork.Designation,
    StartMonth: userWork.StartMonth,
    EndMonth: userWork.EndMonth,
    StartYear: userWork.StartYear,
    EndYear: userWork.EndYear,
    WebSite: userWork.WebSite,
    Type: userWork.Type,

    IndustryError: userWork.IndustryError,
    CompanyError: userWork.CompanyError,
    LocationError: userWork.LocationError,
    InsurationError: userWork.InsurationError,
    DesignationError: userWork.DesignationError,
    StartMonthError: userWork.StartMonthError,
    EndMonthError: userWork.EndMonthError,
    StartYearError: userWork.StartYearError,
    EndYearError: userWork.EndYearError,
    WebSiteError: userWork.WebSiteError,
    IndustryErrorMessage: userWork.IndustryErrorMessage,
    CompanyErrorMessage: userWork.CompanyErrorMessage,
    LocationErrorMessage: userWork.LocationErrorMessage,
    InsurationErrorMessage: userWork.InsurationErrorMessage,
    DesignationErrorMessage: userWork.DesignationErrorMessage,
    StartMonthErrorMessage: userWork.StartMonthErrorMessage,
    EndMonthErrorMessage: userWork.EndMonthErrorMessage,
    StartYearErrorMessage: userWork.StartYearErrorMessage,
    EndYearErrorMessage: userWork.EndYearErrorMessage,
    WebSiteErrorMessage: userWork.WebSiteErrorMessage,
    TypeErrorMessage: userWork.TypeErrorMessage,
    TypeError: userWork.TypeError,

    ID: userWork.ID,
  };
};
const mapDispatch = (dispatchEvent) => ({
  loadData: () => dispatchEvent({ type: 'LOAD_USERWORK', payload: {} }),
  onDPChange: (e) =>
    dispatchEvent({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  AddWorkExperience: (e) =>
    dispatchEvent({ type: 'ADD_USERWORK_SAGA', payload: e }),
  UpdateWorkExperience: (e) =>
    dispatchEvent({ type: 'UPDATE_USERWORK_EDIT_SAGA', payload: e }),
  loadLocation: (e) =>
    dispatchEvent({ type: 'LOAD_CREATOR_LOCATION_SAGA', payload: e }),
  loadIndustry: (e) =>
    dispatchEvent({ type: 'LOAD_CREATOR_INDUSTORY_SAGA', payload: e }),
  loadCompany: (e) =>
    dispatchEvent({ type: 'LOAD_CREATOR_COMPANY_SAGA', payload: e }),
});
export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  WorkExperienceForm
);
