import React, { Component } from 'react';

class LocationDP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enable: false,
      List: props.List,
      Selected: this.props.Selected,
    };
  }

  showListOnClick = () => {
    this.setState({ Enable: !this.state.Enable });
  };

  handleOnclick = (obj) => {
    console.log('locationDP', obj);
    this.callDispatch(obj);
    this.showListOnClick();
  };

  onSelect = (e) => {
    console.log('onSelect in locationDP', e);
  };

  onChange = (e) => {
    // console.log('%c onChange', 'font-size: 22px; color:orange');
    this.setState({ Selected: e.target.value, Enable: true });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e.target.value);
    }
  };

  callDispatch = (obj) => {
    this.setState({ Selected: obj.name });
    if (typeof this.props.onChange === 'function') {
      this.props.onSelect(obj);
    }
  };

  render() {
    let { Enable } = this.state;
    let { List, error, className, infoText, message, paddingEnable, isCollateral, placeholder } = this.props;
    const collateralHelp = <a className='font-12 d-block py-1' style={{color: '#0056b3'}} target='_blank' rel="noopener noreferrer" href="https://indiefolio.typeform.com/to/exwLz9qo">Did not find the collateral that you provide? Let us know</a>

    return (
      <>
        <input
          type="text"
          className={`form-control email ${
            error && error === true ? 'border-error' : ''
          } ${paddingEnable ? 'pl-3' : ''}`}
          placeholder={placeholder ? placeholder : 'Enter text'}
          name="PRIMARYFIELDWORK"
          value={this.state.Selected}
          onChange={this.onChange}
          onClick={this.showListOnClick}
          autoComplete="off"
        />
        {error === true ? (
          <span className="font-11 font-weight-600 text-danger">{message} {isCollateral && collateralHelp } </span>
        ) : (
          <span className="font-12 sub_title font-weight-500 pt-2 d-block">
            {infoText}
          </span>
        )}

        {List && List.length > 0 && Enable === true ? (
          <ul
            className={`select-options select-position w-95 ${className} hideList`}
            style={{ display: 'block' }}
          >
            <li rel="hide">Choose here</li>
            {List &&
              List.map((obj, index) => (
                <li key={index} onClick={() => this.handleOnclick(obj)}>
                  {obj.name}
                </li>
              ))}
          </ul>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default LocationDP;
