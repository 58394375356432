import React, { Component } from "react";
import { connect } from "react-redux";
import ChatLeftPanel from "./ChatLeftPanel";
import MessageWindow from "./MessageWindow";
// import socketIOClient from 'socket.io-client';
import socketIOClient from "socket.io-client";
import { WebsiteUrl, ChatBaseUrl } from "../../../utils/BaseUrl";
import { getAPI } from "../../../utils/API";
import Popup from "./Popup";
import { withRouter } from "react-router";
import ViewProp from "../Home/DashboardNew/CommonComp/ViewProp";
import EmptyChat from "../Home/DashboardNew/CommonComp/EmptyChat";
import Message from "../../../Assets/upgrade-images/creator_active_brief_chat_room_empty/messages.svg";
import ClientVersionHistory from "../Home/Dashboard/VersionHistory/ClientVersionHistory";
import Loader from "../Common/Common/Loader";
import EyeIcon from "../../../Assets/upgrade-images/version_history/eye.svg";

class ChatComponent extends Component {
  state = {
    intViewportWidth: 1000,
    redirect: false,
    socket: null,
    getFunc: null,
    user_id: "",
    showHistoryTab: false,
  };

  componentDidMount() {
    // const projectId = this.props.location.state ? this.props.location.state.post_project_id : this.props.ProjectId
    // this.props.loadProjectStatus(projectId)

    // this will run when user click on message
    // this.props.clearMessageNotification()

    //  this.loadChatInitially()
    if (!this.state.socket) {
      this.initializeSocket();
      // this.props.initializeSocket()
    }

    this.setState({ intViewportWidth: window.innerWidth });
    window.addEventListener("resize", this.updateSize);
    this.updateSize();
    return () => window.removeEventListener("resize", this.updateSize);
  }

  handleSetShowHistoryTab = (value) => {
    this.setState({ ...this.state, showHistoryTab: value });
  };

  initializeSocket = () => {
    const socket = socketIOClient(
      ChatBaseUrl
      // { transports: ['websocket'] }
    );
    // console.log(socket.connected)
    this.setState({ socket: socket }, () => {
      // console.log(socket)
      this.joinUser(this.props.UserToken);
      this.listenAnotherUser(this.props.UserToken);
    });
  };

  updateSize = () => {
    this.setState({ intViewportWidth: window.innerWidth });
  };

  listenAnotherUser = (e) => {
    const { socket } = this.state;
    const { CurrentChat, CurrentRole, ProjectType } = this.props;
    // console.log("CurrentChat === ", CurrentChat)
    const isAdminProject = ProjectType === "post_project_admin";
    // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
    socket.on(e, (resp) => {
      this.setState({ connection: 1 });
      this.addMessagetoBoard(resp);
      socket.emit("chat_reset", {
        chat_connection_id: CurrentChat?.chat_connection_id,
        user_type: isAdminProject ? CurrentChat?.user_role : CurrentRole === 1 ? "client" :CurrentRole==3?"account_manager": "creator",
      });
    });
  };

  joinUser = (e) => {
    // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
    this.state.socket.emit("join_user", {
      user_token: e,
    });
  };
  scheduleCall = (data) => {
    this.setState({ getFunc: data });
  };
  addMessagetoBoard = (values) => {
    // const isAdminProject = this.props.ProjectType === 'post_project_admin'
    // update chat users list in left panel
    if (values.type === "milestones_review") {
      this.props.updateCurrentChatUserData(values.post_project_id);
    }

    if (
      values.type === "proposal_accepted" ||
      values.type === "milestones_review" ||
      values.type === "milestone_payment" ||
      values.type === "proposal_review" ||
      values.type === "proposal_review" ||
      values.type === "approved_updated_proposal_view" ||
      values.type === "milestone_delivered" ||
      values.type === "cycle_delivered" ||
      values.type === "final_project" ||
      values.type === "admin_updated_proposal"
    ) {
      let projectId = values.post_project_id;
      let projectName = values.project_name;
      // console.log('valuesss',values)
      let projectStatus = 6;
      let projectType = this.props.ProjectType;
      if (values.type === "milestone_payment" && values.islast) {
        this.props.loadLastMilestonePaymentDone(true);
      }
      if (values.type === "final_project") {
        this.props.loadProjectStatus(values.post_project_id);
        this.getUserProfile();
      }
      // this.props.loadChat(projectId,projectName,projectStatus);
      // if(isAdminProject){
      const obj = {
        projectId,
        projectName,
        projectStatus: this.props.ProjectStatus || projectStatus,
        projectType,
      };
      this.props.loadChat(obj);
      // } else {
      //   this.props.loadChatAndLatestProposals(projectId,projectName,projectStatus)
      // }
      // this.props.loadLatestProposals(projectId)
    }
    this.props.onChange({
      name: "SEND_MESSAGE",
      value: values,
    });
  };

  getUserProfile = async () => {
    const res = await getAPI("/users/user-profile");
    this.props.loadMe({
      name: "UPDATE_LOAD_LOGGEDIN_USER",
      value: res.data.data,
    });
  };

  handleSetUserId = (data) => {
    // console.log(data,'this is the ---------------------------------------------------------------------data and the data')
    this.setState({ ...this.state, ...data });
  };

  componentDidUpdate(prevProps) {
    if (this.props.UserToken !== prevProps.UserToken) {
      if (this.state.socket) {
        this.state.socket.disconnect();
      }
      this.initializeSocket();
    }
    // if(this.props.CurrentRole !== prevProps.CurrentRole){
    //   this.loadChatInitially()
    // }
    // if (this.props.Projects !== prevProps.Projects) {
    //   return
    //   if (this.props.Projects.length === 0) {
    //     if (this.props.history) {
    //       this.props.history.push('/dashboard');
    //        alert("You do not have any Project to start chat")
    //     }
    //   }
    // }
  }

  componentWillUnmount() {
    const { socket } = this.state;
    // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
    if (socket) {
      socket.disconnect();
      const unlisten = this.props.history.listen((location, action) => {
        if (location.pathname !== "/submitMilestone") {
          this.props.resetChatData();
        }
      });
      unlisten();
    }
  }

  handlePathChange = (connectionId, type) => {
    let redirect = "";
    if (connectionId) {
      if (connectionId != "proposal" && connectionId != "meetings") redirect = `${connectionId}/${type}`;
      else {
        redirect = `${type}`;
      }
    } else {
      redirect = `${type}`;
    }
    return redirect;
  };

  render() {
    const { clientBriefDetails } = this.props;
    const { socket } = this.state;
    const emptyChatStatus = this.props?.CurrentChat?.status
    const { projectId, connectionId, tabs } = this.props?.match?.params;
    return (
      // loading ?
      //   <BootstrapLoader /> :
      <section>
        <div className="container-fluid p-0 mob-p-0">
          <div className="row">
            {this.props?.ArtistCheck && this.props?.ArtistCheck?.length > 0 ? (
              <>
                <ChatLeftPanel
                  socket={socket}
                  clientBriefDetails={clientBriefDetails}
                  takeFunction={this.scheduleCall.bind(this)}
                  {...this.props}
                  handleSetUserId={this.handleSetUserId}
                  emptyChatStatus={emptyChatStatus}
                  handleSetShowHistoryTab={this.handleSetShowHistoryTab}
                />
                {this.state.showHistoryTab ? (
                  <ClientVersionHistory
                    id={projectId}
                    userId={this.state?.user_id}
                    historyTab={true}
                    handleSetShowHistoryTab={this.handleSetShowHistoryTab}
                    {...this.props}
                  />
                ) : (
                  <div className="col-lg-9">
                    <div className="page_card">
                      <div className="row mx-0">
                        <div className="col-lg-12 px-0">
                          <nav className="mb-0">
                            <div class="nav nav-tabs cstm_tab proposal_header pt-0 pb-0 pl-0 px-2 d-flex justify-content-between" id="nav-tab" role="tablist">
                              <div className="d-flex">
                                <p
                                  className={`mb-0 ml-3 pl-0 pr-0 nav-item nav-link cstm_tab_item refrence_tab_active d-flex align-items-center cursor-pointer ${
                                    // !connectionId ||
                                    // (connectionId != "proposal" && connectionId != "meetings") ||
                                    // !tabs ||
                                    // (tabs != "proposal" && tabs != "meetings")

                                    !connectionId ||
                                      (connectionId && connectionId != "proposal" && connectionId != "meetings" && !tabs)
                                      ? "active"
                                      : ""
                                    }`}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/projects/${projectId}/project_room/${this.handlePathChange(connectionId, "")}`
                                    )
                                  }
                                >
                                  <p className="mb-0">Chat </p>
                                  {/* {console.log(this.props?.CurrentRole , this.props?.CurrentRole == 1 , this.props?.CurrentRole == 3 , this.state?.user_id , this.state?.proposal_id,'======================== this is the data ================')} */}
                                </p>
                                {clientBriefDetails?.project_data?.status >= 6 ? null : this.props?.CurrentChat?.proposal_data && this.props?.CurrentRole && (this.props?.CurrentRole == 1 || this.props?.CurrentRole == 3) && this.state?.user_id && this.state?.proposal_id 
                                ? (
                                  <p
                                    className={`mb-0 ml-3 pl-0 pr-0 nav-item nav-link cstm_tab_item refrence_tab_active d-flex align-items-center cursor-pointer ${connectionId == "proposal" || tabs == "proposal" ? "active" : ""
                                      }`}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/projects/${projectId}/project_room/${this.handlePathChange(connectionId, "proposal")}`
                                      )
                                    }
                                  >
                                    <p className="mb-0">Proposal </p>
                                  </p>
                                ) : null}

                                {/* <p
                                className={`mb-0 ml-4 pl-0 pr-0 nav-item nav-link cstm_tab_item d-flex align-items-center cursor-pointer ${
                                  connectionId == "meetings" || tabs == "meetings" ? "active" : ""
                                }`}
                                onClick={() =>
                                  this.props.history.push(
                                    `/projects/${projectId}/project_room/${this.handlePathChange(connectionId, "meetings")}`
                                  )
                                }
                              >
                                <p className="mb-0">Meetings</p>
                              </p> */}

                              </div>
                              {this.props?.CurrentRole == 1 && this.props?.CurrentChat?.role !== 3 && this.state?.username ?
                                <a href={`${WebsiteUrl}creator/${this.state.username}`} target='_blank' className="d-flex align-items-center mb-0 mob-m-0 mr-5"><span className="blue_text_md_w_600  cursor-pointer"
                                // onClick={(e) => {
                                //   this.props.history.push(`/creator/${this.state.username}`)
                                // }}
                                ><img src={EyeIcon} className="img-fluid mr-1" /> </span><span className="d-none d-lg-block">View</span>&nbsp;<span>Profile </span></a>
                                : null}
                            </div>
                          </nav>

                        </div>
                      </div>

                      <div className="row mx-0 pb-2">
                        <div className="col-lg-12 px-0">
                          <div class="tab-content">
                            {(connectionId == "proposal" || tabs == "proposal") &&
                            clientBriefDetails?.project_data?.status < 6 &&
                              this.props?.CurrentRole &&
                              (this.props?.CurrentRole == 1 || this.props?.CurrentRole == 3) ? (
                              <div className=""> {/*meeting_body body_scroll_bar pt-1*/}
                                {this.state?.user_id && this.state?.proposal_id && (
                                  <ViewProp
                                    id={projectId}
                                    userId={this.state.user_id}
                                    role={1}
                                    {...this.props}
                                    // dataState={this.state}
                                    col={12}
                                    isClient={true}
                                    showReject={true}
                                    showRequest={true}
                                    isTab={true}
                                    historyTab={true}
                                    handleSetShowHistoryTab={this.handleSetShowHistoryTab}
                                  />
                                )}
                              </div>

                            ) : connectionId == "meetings" || tabs == "meetings" ? (
                              null
                              // <div className="meeting_body  body_scroll_bar">
                              //   <div className="upcoming_meeting mb-5 px-2">
                              //     <div className="row meeting mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex justify-content-between">
                              //         <div className="meeting_status">
                              //           <p className="mb-0 text_gray_1100_lg_w_700">Upcoming Meetings</p>
                              //         </div>
                              //         <div className="meeting_shedule">
                              //           <p className="mb-0 text_highlight">
                              //             Schedule Meeting <img src={MeetingCalender} className="img-fluid ml-2" />
                              //           </p>
                              //         </div>
                              //       </div>
                              //     </div>

                              //     <div className="row mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex">
                              //         <div className=" company_profile mr-3">
                              //           <div className="icon_profile_image  rounded-circle">
                              //             <img src={Calendar} className="img-fluid" />
                              //           </div>
                              //         </div>

                              //         <div className="px-0  user_massage">
                              //           <p className="user_name font_semi_bold">Visual Design Discussion</p>
                              //           <div className="small_title_commn d-flex justify-content-between">
                              //             <div className="meeting_link">
                              //               <p className="mb-0 text_gray_1100_w_600_xsm">Google Meet</p>
                              //               <p className="mb-0 anchor_blue_md_w_600 meeting_link_tag">
                              //                 <img src={Link} className="img-fluid mr-2" />
                              //                 <a href="#">meet.google/Rq56Qxd</a>
                              //               </p>
                              //             </div>

                              //             <div className="invited_users">
                              //               <p className="mb-0 text_gray_1100_w_600_sm text-right">Invited</p>
                              //               <div className="more_users d-flex">
                              //                 <div className="more_users_profile rounded-circle ml-0">
                              //                   <img src={UserProfile2} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User4} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User3} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <p className="mb-0 font_xs_white_w_600">+2</p>
                              //                 </div>
                              //                 <p className="mb-0 font_semi_bold_w_600">Sarah, Daniel and 2 more</p>
                              //               </div>
                              //             </div>
                              //           </div>
                              //         </div>
                              //       </div>
                              //     </div>
                              //   </div>

                              //   <div className="recent_meeting px-2">
                              //     <div className="row meeting mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex justify-content-between">
                              //         <div className="meeting_status">
                              //           <p className="mb-0 text_gray_1100_lg_w_700">Recent Meetings</p>
                              //         </div>
                              //       </div>
                              //     </div>

                              //     <div className="row mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex">
                              //         <div className=" company_profile mr-3">
                              //           <div className="icon_profile_image  rounded-circle">
                              //             <img src={Calendar} className="img-fluid" />
                              //           </div>
                              //         </div>

                              //         <div className="px-0  user_massage">
                              //           <p className="user_name font_semi_bold">UI/UX Brief</p>
                              //           <div className="small_title_commn d-flex justify-content-between">
                              //             <div className="meeting_link">
                              //               <p className="mb-0 text_gray_1100_w_600_xsm">When</p>
                              //               <p className="mb-0 anchor_blue_md_w_600 meeting_link_tag">
                              //                 <img src={Link} className="img-fluid mr-2" />
                              //                 <a href="#">meet.google/Rq56Qxd</a>
                              //               </p>
                              //             </div>

                              //             <div className="invited_users">
                              //               <p className="mb-0 text_gray_1100_w_600_sm text-right">Invited</p>
                              //               <div className="more_users d-flex">
                              //                 <div className="more_users_profile rounded-circle ml-0">
                              //                   <img src={UserProfile2} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User4} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User3} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <p className="mb-0 font_xs_white_w_600">+2</p>
                              //                 </div>
                              //                 <p className="mb-0 font_semi_bold_w_600">Sarah, Daniel and 2 more</p>
                              //               </div>
                              //             </div>
                              //           </div>
                              //         </div>
                              //       </div>
                              //     </div>

                              //     <div className="row mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex">
                              //         <div className=" company_profile mr-3">
                              //           <div className="icon_profile_image  rounded-circle">
                              //             <img src={Calendar} className="img-fluid" />
                              //           </div>
                              //         </div>

                              //         <div className="px-0  user_massage">
                              //           <p className="user_name font_semi_bold">Low-Fi Wireframe Discussion</p>
                              //           <div className="small_title_commn d-flex justify-content-between">
                              //             <div className="meeting_link">
                              //               <p className="mb-0 text_gray_1100_w_600_xsm">When</p>{" "}
                              //               <p className="mb-0 font_semi_bold_w_600">Mon 23rd April, 2:30PM</p>
                              //             </div>

                              //             <div className="invited_users">
                              //               <p className="mb-0 text_gray_1100_w_600_sm text-right">Participants</p>
                              //               <div className="more_users d-flex">
                              //                 <div className="more_users_profile rounded-circle ml-0">
                              //                   <img src={UserProfile2} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User4} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User3} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <p className="mb-0 font_xs_white_w_600">+2</p>
                              //                 </div>
                              //                 <p className="mb-0 font_semi_bold_w_600">Sarah, Daniel and 2 more</p>
                              //               </div>
                              //             </div>
                              //           </div>
                              //         </div>
                              //       </div>
                              //     </div>

                              //     <div className="row mx-0 mb-4">
                              //       <div className="col-lg-12 d-flex">
                              //         <div className=" company_profile mr-3">
                              //           <div className="icon_profile_image  rounded-circle">
                              //             <img src={Calendar} className="img-fluid" />
                              //           </div>
                              //         </div>

                              //         <div className="px-0  user_massage">
                              //           <p className="user_name font_semi_bold">Hi-Fi Wireframe Discussion</p>
                              //           <div className="small_title_commn d-flex justify-content-between">
                              //             <div className="meeting_link">
                              //               <p className="mb-0 text_gray_1100_w_600_xsm">When</p>{" "}
                              //               <p className="mb-0 font_semi_bold_w_600">Mon 23rd April, 2:30PM</p>
                              //             </div>

                              //             <div className="invited_users">
                              //               <p className="mb-0 text_gray_1100_w_600_sm text-right">Participants</p>
                              //               <div className="more_users d-flex">
                              //                 <div className="more_users_profile rounded-circle ml-0">
                              //                   <img src={UserProfile2} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User4} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <img src={User3} className="img-fluid" />
                              //                 </div>
                              //                 <div className="more_users_profile rounded-circle">
                              //                   <p className="mb-0 font_xs_white_w_600">+3</p>
                              //                 </div>
                              //                 <p className="mb-0 font_semi_bold_w_600">Sarah, Daniel and 3 more</p>
                              //               </div>
                              //             </div>
                              //           </div>
                              //         </div>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </div>
                            ) : this.props?.projectDataStates?.data ? (
                              (emptyChatStatus == 5 || emptyChatStatus == 0) && this.props?.CurrentChat?.role != 3 && this.props?.CurrentRole == 2 ? (
                                <EmptyChat {...this.props} {...this.state} emptyChatStatus={emptyChatStatus} />
                              ) : (
                                <MessageWindow {...this.props} {...this.state} />
                              )
                            ) : (
                              <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="col-lg-12">
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-chat" role="tabpanel" aria-labelledby="nav-chat-tab">
                    <div className="chat_body body_scroll_bar empty_chat_box">
                      <div className="empty_box_massage text-center">
                        <img src={Message} className="img-fluid mr-4" />
                        <p className="text_light_gray_lg_w_600">Chat is not activated yet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Popup {...this.state} {...this.props} scheduleCallFunc={this.state.getFunc} clientBriefDetails={clientBriefDetails} />
      </section>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  return {
    UserToken: chat.UserToken,
    Projects: chat.Projects,
    ProjectId: chat.ProjectId,
    ProjectType: chat.ProjectType,
    CurrentRole: globalReducer.Me.CurrentRole,
    CurrentChat: chat.CurrentChat,
    loading: globalReducer.loading,
    ProjectStatus: chat.ProjectStatus,
    ArtistCheck: chat.Artist,
  };
};
const mapDispatch = (dispatchEvent) => ({
  loadChat: (obj) => dispatchEvent({ type: "UPDATE_LOAD_CHAT", payload: obj }),
  loadProjectChat: (projectId, projectName, projectStatus) =>
    dispatchEvent({ type: "LOAD_PROJECT_CHAT", payload: { projectId, projectName, projectStatus } }),
  onChange: (e) => dispatchEvent({ type: "ON_FIELDCHANGE_SAGA", payload: { name: e.name, value: e.value } }),
  loadLastMilestonePaymentDone: (value) => dispatchEvent({ type: "LOAD_LAST_MILESTONE_PAYMENT_DONE", payload: value }),
  loadChatAndLatestProposals: (projectId, projectName, projectStatus) =>
    dispatchEvent({
      type: "LOAD_CHAT_AND_LATEST_PROPOSAL",
      payload: { id: projectId, name: projectName, project_status: projectStatus },
    }),
  resetChatData: () => dispatchEvent({ type: "RESET_CHAT_DATA", payload: null }),
  loadProjectStatus: (projectId) => dispatchEvent({ type: "LOAD_PROJECT_STATUS", payload: { projectId } }),
  loadLatestProposals: (projectId) => dispatchEvent({ type: "LOAD_LATEST_PROPOSAL", payload: projectId }),
  loadMe: (text) => dispatchEvent({ type: "ON_STATEUPDATE_SAGA", payload: text }),
  clearMessageNotification: () => dispatchEvent({ type: "CLEAR_MESSAGE_NOTIFICATION_SAGA", payload: null }),
  updateCurrentChatUserData: (post_project_id) =>
    dispatchEvent({ type: "UPDATE_CURRENT_CHAT_USER_DATA_SAGA", payload: post_project_id }),
});

export default withRouter(connect(mapState, mapDispatch)(ChatComponent));
