// import _ from 'lodash';
import validator from 'validator';

let FieldActions = {
  BASIC_FIRSTNAME: ["FirstName", "20"],
  BASIC_LASTNAME: ["LastName", "20"],
  BASIC_ABOUT: ["String", "500"],
  SOCIALPROFILE_indiefolio_url: ["Url", ""],
  SOCIALPROFILE_behance_url: ["Url", ""],
  SOCIALPROFILE_dribbble_url: ["Url", ""],
  SOCIALPROFILE_instagram_url: ["Url", ""],
  SOCIALPROFILE_vimeo_url: ["Url", ""],
  SOCIALPROFILE_personal_url: ["Url", ""],
  SOCIALPROFILE_custom_url: ["Url", ""],
  SOCIALPROFILE_custom_url1: ["Url", ""],
  SOCIALPROFILE_custom_url2: ["Url", ""],
  ALLCOLLATERAL_STARTPRICE: ["Number", ""],
  ALLCOLLATERAL_ENDPRICE: ["Number", ""],
  USERWORK_WEBSITE: ["Url", ""],
  USERWORK_COMPANY: ["String", "50"],
  USERWORK_DESIGNATION: ["String", "100"],
  USEREDUCATION_SCHOOL: ["String", "60"],
  USEREDUCATION_COURSE: ["String", "60"],

  COMPANY_NAME: ["Name", "50"],
  ORG_ROLE: ["String","100"],
  ORG_WEBSITE: ["Url",""],
  ORG_LOCATION: ["String","60"],
  ORG_INDUSTRY: ["String","60"],
  ORG_EMPLOYEES: ["Number", ""],
  ORG_DESCRIPTION: ["String","300"],
}

export default function ValidateAction(act, Data) {
  let resp = false;
  let Action = FieldActions[act];
  if (!Action || Object.keys(Action).keys < 1)
    return false;

  // check datatype
  // check length
  // console.log('%c Action', 'font-size: 22px; color:orange');
  // console.log(act)

  switch (Action[0]) {
    case 'Number':
      if (Data === null || isNaN(Data)) {
        resp = true;
      }

      if (Data < 0) {
        resp = true;
      }
      break;
    case 'Bool':
      if (Data && typeof Data !== 'boolean') {
        resp = true;
      }
      break;
    case 'Date':
      if (Data && Data !== '') {
        let newDd = new Date(Data);
        if (newDd instanceof Date === false) {
          resp = true;
        }
      }
      break;
    case 'Email':
      if (Data && Data !== '') {
        if (!validator.isEmail(Data)) {
          resp = true;
        }
      }
      break;
    case 'File':
      if (Data && Data !== '') {
        if (!Data.name || typeof Data.name !== 'string') {
          resp = true;
        }
      }
      break;
    case 'DOB':
      let newD = new Date(Data);
      if (!newD instanceof Date) {
        resp = true;
      }
      let currDate = new Date()
      if (newD > currDate) {
        resp = true;
      }
      // Check DOB for Age and DateType
      break;
    case 'FirstName':
      if (Data && Data !== '') {
        if (!validator.isAlpha(Data, 'en-US')) {
          resp = true;
        }
      }
      break;
    case 'Name':
      if (Data && Data !== '') {
        if (!validator.isAlpha(Data, 'en-US')) {
          resp = true;
        }
      }
      break;
    case 'LastName':
      if (Data && Data !== '') {
        let reg = /[^A-Z a-z]/;
        if (reg.test(Data)) {
          resp = " is Not Valid. Given:" + Data;
        }
      }
      break;
    case 'Mobile':
      
      if (Data && Data !== '') {
         // eslint-disable-next-line
        let reg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        if (reg.test(Data) !== true || Data.toString().length !== 10) {
          resp = Data + " is Not a Valid Number. Given:" + Data;
        }
      }
      break;
    case 'Obj':
      if (Data && Data !== '') {
      } else {
        if (typeof Data !== 'object' || Data.length < 1) {
          resp = true;
        }
      }
      break;
    case 'PinCode':
      if (Data && Data !== '') {
        if (isNaN(Data)) {
          resp = true;
        }
        // eslint-disable-next-line
        if (Data.length != 5) {
          resp = true;
        }
      }
      break;
    case 'GST':
      // eslint-disable-next-line
      if (Data && Data !== '') {
        if (Data == null) {
          resp = true;
        }
        if (!verifyGST(Data)) {
          resp = Data + " is not a Valid GST. Given:" + Data;
        }

        // eslint-disable-next-line
        if (Data.length != 15) {
          resp = true;
        }
      }
      break;
    case 'String':
      if (Data && Data !== '') {
        if (typeof Data !== 'string') {
          resp = true;
        }
      }
      break;
    case 'Url':
      if (Data && Data !== '') {
         // eslint-disable-next-line
        let reg = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
        if (!reg.test(Data)) {
          resp = true;
        }
      }
      break;
    default:
      resp = false;
  }

  let lengthCheck = Action[1];
  if (lengthCheck) {
    let dType = typeof Data;
    switch (dType) {
      case "string":
        if (Data.length > lengthCheck) {
          resp = true;
        }
        break;
      case "number":
        resp = false;
        break;
      default:
        break;
    }
  }

  return resp;
}

function verifyGST(g) {
  let reg = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
  let r = reg.test(g);

  if (g.length !== 15) {
    return {
      Status: 'Not Valid',
      State: '00'
    }
  } else {
    return {
      Status: (r) ? 'Valid' : 'Not Valid',
      State: g.substr(0, 2)
    }
  }
}
