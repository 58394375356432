import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import  { connect } from 'react-redux'

class Header extends Component {
    render() {
        // const { title } = this.props
        return (
            <section className="main-header bg-custom upload-project-wrapper">
                <div className="container-fluid main-header py-2 mob-p-0 max-w-1440">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/"><img src={require("../../../Assets/images/landing/IndieFolio_logo_white.png")} className="img-fluid" alt="Indiefolio" title="Indiefolio" /></Link>
                    {/* <div className="ipad-flex-grow-1 text-center">
                      <h2 className="text-white font-weight-500 font-16 mb-0 ipad-p-15 mob-font-14">{title}</h2>
                    </div>  */}
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                      <ul className="navbar-nav d-flex align-items-center">  
                        <li className="nav-item">
                      <a className="text-white outline-btn py-10 cursor-pointer" href='/' onClick={(e) => { e.preventDefault(); localStorage.setItem('prevPath', window.location.pathname); this.props.history.push('/hire',{ prevPath : window.location.pathname })}}>Hire An Expert</a>
                        </li>
                        <li className="nav-item profile-header">
                          <button className="font-weight-800 text-white main-btn py-10"
                                onClick={
                                    (e) => 
                                    {
                                        this.props.OpenSignupLogin(true);
                                        this.props.history.push('/login');
                                        e.preventDefault();
                                    }
                                    }> 
                             Log In
                          </button>
                        </li>  
                      </ul>
                    </div>
                  </nav>
                </div> 
              </section>
        )
    }
}


const mapDispatch = dispatchEvent => ({
    OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state}),
})

export default withRouter(connect(null, mapDispatch)(Header))