import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import Loader from "../../../../../authorized/Common/Common/Loader";
import NotFound from "../../../../Common/NotFound/NotFound";
import defaultIcon from "../../../../../../Assets/images/c.jpg";
import ProjectSlider from "./ProjectSlider";
import DummyProfile from "../../../../../../Assets/upgrade-images/client_active_brief_chat/dummy.png";
import Location from "../../../../../../Assets/upgrade-images/view_proposal_list/location.svg";
import MessageWhiteIcon from "../../../../../../Assets/upgrade-images/view_proposal_list/message.svg";
import { BaseImageUrl, WebsiteUrl } from "../../../../../../utils/BaseUrl";
import { Link } from "react-router-dom";
import PercentIcon from "../../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import Danger from "../../../../../../Assets/upgrade-images/account/danger.svg";
import moment from "moment";
import { getProjectQuote } from "../../../../../../utils/Helperfunctions";

const RejectedOverview = (props) => {
  const data = useSelector((state) => state.releventProjects);
  const creatorIs = data?.allProposal?.creator;

  const rejectedDataIs = creatorIs?.filter((el) => el?.status === 2);

  return (
    <>
      {data?.loadingIs ? (
        <div className="row rejected_body  body_scroll_bar d-flex justify-content-center align-items-center">
          <div className="col-lg-3 text-center">
            <Loader />
          </div>
        </div>
      ) : rejectedDataIs?.length ? (
        rejectedDataIs.map((obj, index) => {
          return (
            <div className="row mb-2 px-4 py-5" key={index}>
              {/* -----------------------new ------------------------- */}
              {console.log("rrrrrr = ", obj)}
              <div className="col-lg-8">
                <div className="row profile_content mb-4">
                  <div className="col-lg-2 col-sm-3">
                    <a href={`${WebsiteUrl}creator/${obj.creator_id?.username}`} target="_blank">
                      <div className="profile_image mr-3">
                        <img
                          src={obj?.creator_id?.profile_image ? BaseImageUrl + obj.creator_id.profile_image : DummyProfile}
                          className="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-10 col-sm-9">
                    <div className="profile_name">
                      <p className="mb-1">
                        <div className="client_details d-flex align-items-center justify-content-between">
                          <div>
                            <span className="text-dark">{obj?.creator_id?.first_name + " " + obj?.creator_id?.last_name} </span>
                            <span className="ml-2">
                              <span className="text_gray_1100_w_700 ml-1 mr-2">
                                {(obj?.experience?.year < 1 ? 1 : obj?.experience?.year) +
                                  (obj?.experience?.year < 1 ? " year " : obj?.experience?.month ? "+ years " : obj?.experience?.year == 1 ? " year " : " years ")}
                                of experience
                              </span>
                              {/* {obj?.unread_msg ? (
                                <Link
                                  to={`/projects/${props?.match?.params?.projectId}/project_room/${obj?.creator_id?.username}`}
                                >
                                  <span className="msg_tag">
                                    <img src={MessageWhiteIcon} className="img-fluid" /> {obj.unread_msg}
                                  </span>
                                </Link>
                              ) : null} */}
                            </span>
                          </div>
                          {obj?.Proposal && obj?.Proposal.proposal_type ? (
                            <div className="d-flex p-0">
                              {obj?.Proposal.proposal_type?.toLowerCase() &&
                                (obj?.Proposal.proposal_type?.toLowerCase() == "fixed" ? (
                                  <div className="tag_btn_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img src={PercentIcon} className="icon_img mr-1" />
                                      Fixed Scope
                                    </p>
                                  </div>
                                ) : obj?.Proposal.proposal_type?.toLowerCase() == "retainer" ? (
                                  <div className="tag_btn_green_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img src={PercentIconGreen} className="icon_img mr-1" />
                                      Retainer
                                    </p>
                                  </div>
                                ) : (
                                  <div className="tag_btn_dark_green_common">
                                    <p className="mb-0 fw_600 fs_12">
                                      <img src={PercentIconDarkGreen} className="icon_img mr-1" />
                                      On-Actuals
                                    </p>
                                  </div>
                                ))}
                            </div>
                          ) : null}
                        </div>
                      </p>
                      {obj.creator_rating ? (
                        <p className="d-flex align-items-end">
                          <StarRatings
                            rating={Number(obj?.creator_rating)}
                            starRatedColor="#90c028"
                            starDimension="15px"
                            starSpacing="2px"
                            numberOfStars={5}
                            name="rating"
                          />
                          <span className="text_gray_1100_w_600_sm ml-3">
                            {obj.creator_rating}({obj.total_client_rating_count})
                          </span>
                        </p>
                      ) : null}

                      <ul className="client_details d-block align-items-center">
                        <li className="mr-3">
                          <div className="location d-flex">
                            <div className="icon d-flex">
                              <img src={Location} className="img-fluid" />
                            </div>
                            <span className="ml-2 text_gray_1100_w_500_sm">{obj?.creator_id?.location}</span>
                          </div>
                        </li>
                        {obj?.creatorClient.filter((cl) => cl?.client_name?.length).length ? (
                          <li className="ml-2">
                            <div className="rating d-flex">
                              <span className=" text_gray_xs_w_700 mr-2">Previously worked with</span>
                              {obj?.creatorClient?.length
                                ? obj?.creatorClient.map((el, index) => {
                                    return el?.client_name ? (
                                      <div className="client_logo_image d-flex align-items-center" key={index}>
                                        <img
                                          src={el?.client_image ? BaseImageUrl + el?.client_image : defaultIcon}
                                          className="img-fluid"
                                        />
                                      </div>
                                    ) : null;
                                  })
                                : ""}
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="info_box title_commn row mb-3">
                  {obj?.Proposal && obj?.Proposal?.proposal_type ? (
                    <>
                      <div className="col-sm-10 col-xl-8 info_box_item_outer d-flex col">
                        <div className="info_box_item">
                          <p className="text_gray_1100_w_700 mb-1">Quote</p>
                          <p className="font_semi_bold mb-0">
                            {getProjectQuote(
                              obj?.Proposal,
                              obj?.Proposal?.total_cost,
                              obj?.Proposal?.proposal_type,
                              obj?.Proposal?.retainer_fees_type
                            )}
                            {obj?.Proposal ? (
                              <span className="gst">
                                {obj?.Proposal?.proposal_type == "fixed" || obj?.Proposal?.proposal_type == "retainer"
                                  ? "+ Applicable taxes"
                                  : " Pricing provided per item"}
                              </span>
                            ) : null}
                          </p>
                        </div>
                        <div className="info_box_item">
                          <p className="text_gray_1100_w_700 mb-1">Start Date</p>
                          <p className="font_semi_bold mb-0">
                            {moment(obj?.Proposal?.proposed_start_date).format("MMM D, YYYY")}
                          </p>
                        </div>
                      </div>

                      {/* <div className="col-sm-2 col-xl-4 text-center text-lg-right dflex">
                        <span className="text-blue d-flex align-items-center">Reinstate Proposal</span>
                      </div> */}
                    </>
                  ) : (
                    <div className="col-sm-10 col-xl-8 info_box_item_outer dflex">
                      {/* <span className="text-blue d-flex align-items-center">Reinstate Proposal</span> */}
                    </div>
                  )}
                </div>
                <div className="row text-center text-lg-left dflex">
                  <span className="col-sm-12 text-red-500_md_w_600 d-flex align-items-center"><img src={Danger} className='mr-2' width={18}/> Rejected on {moment(obj?.rejected_date).format("MMM D, YYYY")}</span>
                </div>
              </div>

              {/* image/slider section */}
              <div className="col-lg-4">
                <div className="profile_imagecar slider_card">
                  <ProjectSlider SelectedArtistData={obj} location={props.location}/>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <NotFound isReject={true} />
      )}
    </>
  );
};

export default RejectedOverview;
