import React, { useEffect, useState } from "react";
import { getDateFormated, getQueryParams, handleStatusDel } from "../../CommonFunctionsNew/CommonFunctionsNew";
import Exclamation from "../../../../../../Assets/upgrade-images/submit_proposal/info-circle.svg";
import ArrowRight2 from "../../../../../../Assets/upgrade-images/submit_proposal/green-arrow-right.svg";
import Eye from "../../../../../../Assets/upgrade-images/version_history/eye.svg";
import Plus from "../../../../../../Assets/upgrade-images/submit_proposal/add.svg";
import Alert from "../../../../../../Assets/upgrade-images/submit_proposal/alert.svg";
import UploadDoc from "../../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/upload_doc.svg";
import Clock from "../../../../../../Assets/upgrade-images/submit_proposal/clock.svg";
import Check from "../../../../../../Assets/upgrade-images/submit_proposal/check.svg";
import ClientCreatorProposalPopup from "../../CommonpPopup/ClientCreatorProposalPopup";
import UpArrow from "../../../../../../Assets/upgrade-images/submit_proposal/arrow-up.svg";
import DownArrow from "../../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/arrow-down.svg";
import { separateNumber } from "../../../../ProfileCreator/TimeRetainer";
import TerminateOverviewComp from "../TerminateOverviewComp";
import YellowClock from "../../../../../../Assets/upgrade-images/submit_proposal/yellow_clock.svg";
import Danger from "../../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/danger.png";
import moment from "moment";
import { sanitiseSummernoteText } from "../../../../../../utils/Helperfunctions";

const FixedOverviewProj = ({
  state,
  mileData,
  setMileData,
  set_milestone_id,
  setMileObj,
  setIndex,
  setShowClientPayNow,
  setViewUploadDel,
  setViewAttachments,
  setFixedLastSubm,
  setShowUploadMilestone,
  ...props
}) => {
  // const handleRightMark = (data) => {
  //   let check = data.filter((ele) => ele?.milestonecomplete?.length < 1 || ele?.milestonecomplete?.[0]?.status != 1)?.length > 0;
  //   return check;
  // };

  const handleRightMark = (data) => {
    let check = data.filter((ele) => ele?.milestonecomplete?.length && ele?.milestonecomplete?.[0]?.status == 1)?.length > 0;
    return check;
  };

  const [showVersionHistory, setShowVersionHistory] = useState(false);

  const projectData = props?.projectDataStates?.data?.project_data;
  const proposal_data = state?.proposalData;

  useEffect(() => {
    let id1 = getQueryParams("id1");
    let id2 = getQueryParams("id2");
    let id3 = getQueryParams("id3");
    if (id1?.length && id2?.length && id3?.length) {
      let arr = JSON.parse(JSON.stringify(mileData));
      let arr1 = arr.filter((item) => item._id === id1);
      let arr2 = arr1.length && arr1[0].names.filter((obj) => obj._id === id2);
      let arr3 = arr2.length && arr2[0].milestonecomplete.filter((obj) => obj._id === id3);
      if (arr2.length) {
        arr2[0].milestonecomplete = arr3;
      }
      if (arr1.length) {
        arr1[0].names = arr2;
      }
      if (arr1.length) {
        setViewUploadDel(true);
        set_milestone_id(id2);
        setViewAttachments(arr1[0].names[0]);
        setMileObj(arr1[0]);
      }
    }
  }, [mileData]);

  const handleDivFinalSub = (data, isbottom) => {
    return (
      <>
        {props.projStatus == 7 ? (
          ""
        ) : (
          <div className="page_card mb-3">
            {isbottom ? null : (
              <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
                <div className="prposal_heading d-flex align-items-center">
                  <div className="heading_logo">
                    <img src={Clock} className="mr-3" />
                  </div>
                  <div className="heading_info">
                    <p className="mb-0">{`Final Submission has been shared by ${proposal_data.userData.first_name + " " + proposal_data.userData.last_name
                      }`}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="row  br_btm mx-0">
              <div className="col-lg-12 p-4">
                <div className="title_commn row text_gray_1100_w_600_sm mb-2 mx-0">
                  <div className="col-lg-4 pl-lg-0">
                    <p className="text_gray_1100_w_600_sm mb-0">Deliverable</p>
                    <p className="font_semi_bold mb-0">Final Submission</p>
                  </div>
                  <div className="col-lg-8 my-auto">
                    <div className="column_gap_10 d-flex align-items-center justify-content-end">
                      <span className="text_light_gray_xs">
                        <img src={Exclamation} className="mr-2" />
                        {data.length
                          ? "Please approve all milestones and complete pending payment to view final files"
                          : "Creator has to yet add final files"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleSubmitFinalFiles = () => {
    return (
      <>
        {props.projStatus == 7 || projectData?.terminate_request == 2 || projectData?.terminate_request == 1 ? (
          ""
        ) : (
          <div className="page_card mb-3">
            <div className="proposal_header d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={UploadDoc} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">Project on last milestone</p>
                  <p className="text_light_gray_xs mb-0">Please submit final files for futher process.</p>
                </div>
              </div>

              <div className="review_btn">
                <button
                  type="button"
                  onClick={() => {
                    setShowUploadMilestone(true);
                    set_milestone_id("");
                    setFixedLastSubm(true);
                  }}
                  class="btn btn-common"
                >
                  Submit Final Files
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleCallFinalTermButton = (type) => {
    if (type === 1) {
      setShowUploadMilestone(true);
      set_milestone_id("");
      setFixedLastSubm(true);
    }
  };

  const handleFinalTerrmination = (type) => {
    return (
      <>
        {props.projStatus == 7 ? null : (
          <div className="page_card mb-3">
            <div className="proposal_header d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={type === 1 ? UploadDoc : ""} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">{type === 1 ? "Termination approved. upload final files" : ""}</p>
                  <p className="text_light_gray_xs mb-0">{type === 1 ? "Please submit final files for futher process." : ""}</p>
                </div>
              </div>

              <div className="review_btn">
                <button type="button" onClick={() => handleCallFinalTermButton(type)} class="btn btn_common_md">
                  {type === 1 ? "Upload" : ""}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleReviewAndConfirm = () => {
    return (
      <>
        {props.projStatus == 7 ||
          projectData?.terminate_request == 1 ||
          projectData?.terminate_request == 2 ||
          !proposal_data?.is_incoming_proposal ? (
          null
        ) : (
          <div className="page_card mb-3">
            <div className="proposal_header d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={Alert} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">Creator has updated the proposal</p>
                  <p className="text_light_gray_xs mb-0">
                    {/* Please review and confirm the proposal. */}
                    You can accept the latest proposal shared by{" "}
                    {proposal_data.userData.first_name && proposal_data.userData.first_name}{" "}
                    {proposal_data.userData.last_name && proposal_data.userData.last_name} or request changes to the same.
                  </p>
                </div>
              </div>

              <div className="review_btn">
                <button type="button" class="btn btn-common" onClick={() => setShowVersionHistory(true)}>
                  Review Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleEndProjectReview = (rowFiles, isTop) => {
    let checkPay = mileData.filter((item) => item.status != 2).length ? false : true;
    return (
      <div className="page_card mb-3">
        {isTop ? (
          <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
            <div className="prposal_heading d-flex align-items-center">
              <div className="heading_logo">
                <img
                  src={
                    checkPay || projectData?.status == 7 || projectData?.terminate_request == 2
                      ? Check
                      : rowFiles.length
                        ? YellowClock
                        : Clock
                  }
                  className="mr-3"
                />
              </div>
              <div className="heading_info">
                <p className="mb-0">
                  {projectData?.status == 7
                    ? `${projectData?.terminate_request == 2
                      ? "Project Terminated on"
                      : "Congratulations, the project is completed!"
                    } ${projectData?.terminate_request == 2 || projectData?.terminate_request == 1
                      ? getDateFormated(
                        projectData?.terminate_request == 2
                          ? props.terminationData?.project_end_date
                          : projectData?.project_completed_date
                      )
                      : ""
                    }`
                    : props?.CurrentRole == 2
                      ? checkPay
                        ? "Client has completed payment and can access files"
                        : "Client can only access projects once payment is completed"
                      : `Final Submission has been shared by ${proposal_data.userData.first_name + " " + proposal_data.userData.last_name
                      }`}
                </p>
                {projectData?.status == 7 ? (
                  projectData?.terminate_request == 2 || projectData?.terminate_request == 1 ? null : (
                    <p className="text_light_gray_xs mb-0">
                      The project with {proposal_data.userData.first_name + " " + proposal_data.userData.last_name} was wrapped up
                      on {getDateFormated(projectData?.project_completed_date)}
                    </p>
                  )
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {projectData?.terminate_request == 2 && props?.CurrentRole == 1 && projectData.rating_by_client >= 1 ? null : (
          <div className="row  br_btm mx-0">
            <div className="col-lg-12 p-4">
              <div className="title_commn row text_gray_1100_w_600_sm mb-2 mx-0">
                <div className="col-lg-3 pl-lg-0">
                  <p className="text_gray_1100_w_600_sm mb-0">Deliverable</p>
                  <p className="font_semi_bold mb-0">Final Submission</p>
                </div>
                <div className="col-lg-3">
                  <p className="text_gray_1100_w_600_sm mb-0">Last Submitted</p>
                  <p className="font_semi_bold_w_600 mb-0">{rowFiles?.length ? getDateFormated(rowFiles[0].createdAt) : null}</p>
                </div>
                <div className="col-lg-6 px-lg-0">
                  <div className="column_gap_15 d-flex align-items-center justify-content-end">
                    {/* {props.justStaticEndProjClient ? (
                      ""
                    ) : props?.CurrentRole == 2 ? (
                      projectData?.terminate_request != 1 && projectData?.terminate_request != 2 ? (
                        <p
                          className="blue_text_sm mb-0 cursor_pointer"
                          onClick={() => {
                            setShowUploadMilestone(true);
                            set_milestone_id("");
                            setFixedLastSubm(true);
                          }}
                        >
                          Resubmit
                        </p>
                      ) : null
                    ) : (
                      ""
                    )} */}
                    <button
                      type="button"
                      class="btn btn-common-white fw_700 "
                      onClick={() => {
                        setFixedLastSubm(true);
                        setViewUploadDel(true);
                        set_milestone_id("");
                        setViewAttachments(state.proposalData);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTop &&
          (checkPay || projectData?.status == 7 || projectData?.terminate_request == 2) &&
          ((props?.CurrentRole == 1 && projectData.rating_by_client < 1) ||
            (props?.CurrentRole == 2 && projectData.rating_by_creator < 1)) ? (
          <div className="proposal_header d-flex align-items-center justify-content-start">
            <div className="prposal_heading w-100">
              {props?.CurrentRole == 1 && props?.projStatus != 7 && !props.justStaticEndProjClient ? (
                <button type="button" class="btn btn-common fw_700 w-100" onClick={() => props.setShowProjEndPop(true)}>
                  End & Review Project
                </button>
              ) : (
                <button type="button" class="btn btn-common fw_700 w-100" onClick={() => props.setShowFinalReview(true)}>
                  {projectData?.terminate_request == 2 && props?.CurrentRole == 1 ? "End & Review Project" : "Write a Review"}
                </button>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const handleChangeProposalYetApp = (text, which, check) => {
    return (
      <>
        {props.projStatus == 7 ||
          projectData?.terminate_request == 1 ||
          projectData?.terminate_request == 2 ||
          (!check && !proposal_data?.is_incoming_proposal) ? (
          ""
        ) : (
          <div className="page_card mb-3">
            <div className="end_cycle_topheadres d-flex align-items-center justify-content-between">
              <div className="prposal_heading d-flex align-items-center column_gap_15">
                <div className="alert_outer">
                  <img src={Alert} />
                </div>
                <div className="alert_massage">
                  <p className="mb-0">{text}</p>
                  <p className="text_light_gray_xs mb-0">
                    The {which} will approve the deadlines and payment terms mentioned in the latest proposal
                  </p>
                </div>
              </div>
              {check ? null : (
                <div>
                  <button type="button" onClick={() => setShowVersionHistory(true)} class="btn btn-common">
                    View Updated Proposal
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const getStatus = (object) => {
    let obj = object?.milestonecomplete?.length ? object.milestonecomplete[0] : "";
    return obj ? false : true;
  };

  const showRequestChange = () => {
    return (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Alert} className="mr-3" />
            </div>
            <div className="heading_info">
              <p className="mb-0">{props?.CurrentRole == 1 ? "You" : "Client"} has requested changes in the proposal</p>
              {/* <p className="text_light_gray_xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </div>
          </div>
        </div>
        <div className="row  br_btm mx-0">
          <div className="col-lg-12 p-4">
            {/* <p className="text_light_gray_md mb-0">{getHTMLInnerText(proposal_data?.request_change_text).length>3?getHTMLInnerText(proposal_data?.request_change_text)+'...show more':getHTMLInnerText(proposal_data?.request_change_text)}</p */}
            {proposal_data?.request_change_text && (
              <div className="text_light_gray_md mb-0" dangerouslySetInnerHTML={{ __html:sanitiseSummernoteText(proposal_data?.request_change_text?.replace(/\n/g,'<br/>')) }} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const showMutualAcceptDiv = () => {
    return (projectData?.mutuallyAcceptByClient && props?.CurrentRole == 1 && props.dummyMutualAccState) ||
      (projectData?.mutuallyAcceptByCreator && props?.CurrentRole == 2 && props.dummyMutualAccState) ? (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-between">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Check} className="mr-3" />
            </div>
            <div className="alert_massage">
              <p className="mb-0">Proposal & terms have been mutually accepted!</p>
              {/* <p className="text_light_gray_xs mb-0">
                The creator will approve the deadlines and payment terms mentioned in the latest proposal
              </p> */}
            </div>
          </div>
          <button type="button" className="close close_btn">
            <span aria-hidden="true" className="close_icon modalclose-btnicon" onClick={props.changeMutualAcceptStatus}>
              ×
            </span>
          </button>
        </div>
      </div>
    ) : null;
  };

  const handleDelayFun = (date) => {
    let start = getDateFormated(new Date());
    let end = getDateFormated(new Date(date));
    let check = moment(start).isAfter(end);
    return check;
  };

  const rowFiles = proposal_data?.RawFile;
  let mileDataPaid = mileData.filter((ele) => ele.status != 2);
  const clientRowFilesAccess = mileDataPaid.length > 0 ? false : true;
  // console.log("ttt === ", props.CurrentRole == 2, proposal_data.userData, proposal_data.userData.first_name);
  return (
    <div className={`col-lg-${props?.col}`}>
      {proposal_data?.isRequestChange && !projectData?.were_admin_project ? showRequestChange() : null}
      {!projectData?.were_admin_project ? showMutualAcceptDiv() : null}

      {projectData?.terminate_request == 1 ? (
        props?.termLoad ? null : props?.terminationData ? (
          props.terminationData?.status == 1 && props?.CurrentRole == 2 ? (
            rowFiles?.length ? null : (
              handleFinalTerrmination(1)
            )
          ) : (
            <TerminateOverviewComp
              terminationData={props?.terminationData}
              type={"fixed"}
              CurrentRole={props?.CurrentRole}
              userName={props.CurrentRole == 1 ? proposal_data.userData.first_name + " " + proposal_data.userData.last_name : ""}
              changeTerminatePopup={props.changeTerminatePopup}
              retainer_fees_type={proposal_data?.retainer_fees_type}
              setShowApproveRejectPop={props.setShowApproveRejectPop}
              setPopupType={props?.setPopupType}
              terminate_requested_by={projectData?.terminate_requested_by}
              setShowTerminationPay={props.setShowTerminationPay}
              rowFiles={rowFiles}
            />
          )
        ) : null
      ) : null}

      {props?.CurrentRole == 1 && !projectData?.were_admin_project ? handleReviewAndConfirm() : ""}
      {props?.CurrentRole == 2 && !projectData?.were_admin_project ? handleChangeProposalYetApp("Client hasn’t yet approved your proposal changes", "client") : ""}
      {props?.CurrentRole == 1 && !projectData?.were_admin_project
        ? props?.projectObj != 2
          ? handleChangeProposalYetApp("Creator approval pending", "creator", true)
          : null
        : null}

      {rowFiles?.length
        ? props?.CurrentRole == 2 || clientRowFilesAccess || projectData?.terminate_request == 2
          ? handleEndProjectReview(rowFiles, true)
          : projectData?.terminate_request != 1 && projectData?.terminate_request != 2
            ? handleDivFinalSub(rowFiles)
            : null
        : props?.CurrentRole == 2 && mileDataPaid.length <= 1
          ? handleSubmitFinalFiles()
          : props?.CurrentRole == 1
            ? //  handleDivFinalSub(rowFiles)
            null
            : ""}

      <div className="page_card">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-between">
          <div className="prposal_heading">
            <p className="mb-0">Milestones</p>
          </div>

          <div className="view_profile d-flex justify-content-between">
            <p
              className="blue_text_sm d-flex align-items-center mb-0 cursor-pointer"
              onClick={() =>
                // setShowVersionHistory(true)
                props.history.push(`/projects/${props?.projectDataStates?.id}/proposal_details`)
              }
            >
              <img src={Eye} className="mr-2" />
              View Proposal
            </p>
          </div>
        </div>
        <div class="timelines row mx-0">
          <div class="timeline education col-lg-12">
            <div class="timeline-items">
              {state?.advanceFees?.breakup ? (
                <div class="timeline-item">
                  <div className="timeline_header">
                    <div className="icons">
                      <div className="circle approved">
                        <span>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                    <div className="title_outer">
                      <div className="title text_black_700_w_600">
                        <p className="mb-1 d-flex align-items-center">
                          Project Advance<span className="pills_tag_btn_green ml-3">Paid</span>
                        </p>
                      </div>
                      {props?.CurrentRole == 1 ? (
                        <div className="amount_details d-flex justify-content-between text_light_gray_md">
                          <p className="mb-0">
                            Amount:
                            <span className="font_semi_bold_w_600">
                              {` ₹ ${separateNumber(state?.advanceFees?.price_without_tax)} `}{" "}
                            </span>
                            {projectData?.were_admin_project ? null : `+ GST`}
                          </p>
                          <p className="mb-0">
                            Paid On: <span className="text_highlight_w_600">{getDateFormated(proposal_data?.approval_date)}</span>
                          </p>
                        </div>
                      ) : (
                        <div className="amount_details d-flex justify-content-between text_light_gray_md">
                          {projectData?.were_admin_project ? null : (
                            <p className="mb-0">
                              Client Pays:
                              <span className="font_semi_bold_w_600">
                                {` ₹ ${separateNumber(state?.advanceFees?.price_without_tax)} `}+GST
                              </span>
                            </p>
                          )}
                          <p className="mb-0">
                            {/* Your Earnings:
                            <span className="text_highlight_w_600">
                              {` ₹ ${separateNumber(state?.advanceFees?.total_earning)}`}
                            </span>
                            <img src={Exclamation} className="ml-2" /> */}
                            Paid On: <span className="text_highlight_w_600">{getDateFormated(proposal_data?.approval_date)}</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="timeline_inner_item inner_item_active"></div>
                </div>
              ) : null}

              {mileData.map((item, i) => {
                return (
                  <div class="timeline-item">
                    <div className="timeline_header">
                      <div className="icons">
                        <div
                          className={`circle ${item.status == 2 ? "approved" : handleRightMark(item?.names) ? "process" : ""} `}
                        >
                          {item.status == 2 ? (
                            <span>
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                          ) : (
                            <span>{i + 1}</span>
                          )}
                        </div>
                      </div>
                      <div className="title_outer">
                        <div className="title text_black_700_w_600">
                          <p className="mb-1 d-flex align-items-center">
                            Milestone {i + 1}{" "}
                            {item.status == 2 ? (
                              <span className="pills_tag_btn_green ml-3">
                                {props?.CurrentRole == 2 || (props?.CurrentRole == 1 && item?.payment_type == 0)
                                  ? (props?.CurrentRole == 2 ? item?.admin_approved : item?.admin_approved_client)
                                    ? "Paid"
                                    : "Approved"
                                  : "Paid"}
                              </span>
                            ) : projectData?.terminate_request != 1 && projectData?.terminate_request != 2 ? (
                              handleRightMark(item?.names) ? (
                                <span className="pills_tag_btn_yellow ml-3">
                                  {props?.CurrentRole == 1 ? "Payment Pending" : "In Progress"}
                                </span>
                              ) : (
                                <span className="pills_tag_btn_gray ml-3">Upcoming</span>
                              )
                            ) : (
                              <span className="pills_tag_btn_danger ml-3">
                                {projectData?.terminate_request == 1 ? "Termination In Process" : "Terminated"}
                              </span>
                            )}
                          </p>
                        </div>
                        {props?.CurrentRole == 1 ? (
                          <div className="amount_details d-flex justify-content-between text_light_gray_md">
                            <p className="mb-0">
                              Amount:
                              <span className="font_semi_bold_w_600"> {` ₹ ${separateNumber(item?.price_without_tax)} `}</span>
                              {projectData?.were_admin_project ? null : `+ GST`}
                            </p>
                            {item.status == 2 ? (
                              <p className="mb-0">
                                {item?.payment_type == 1
                                  ? "Paid On"
                                  : item?.admin_approved_client
                                    ? "Paid On"
                                    : "Payment Pending"}
                                :
                                <span className="text_highlight_w_600">
                                  {" "}
                                  {item.payment_date ? getDateFormated(item.payment_date) : "-"}
                                  {/* {item?.payment_type == 1
                                    ? item?.payment_date ? getDateFormated(item.payment_date) : "-"
                                    : item?.admin_approved_client
                                      ? item?.payment_date ? getDateFormated(item.payment_date) : "-"
                                      :` ₹ ${separateNumber(item?.price_without_tax)} `} */}
                                </span>
                              </p>
                            ) : projectData?.status == 7 ||
                              projectData?.terminate_request == 1 ||
                              projectData?.terminate_request == 2 ? null : (
                              <p className="mb-0">
                                {(i == 0 || (i > 0 && mileData[i - 1].status == 2)) && (
                                  <p
                                    className="text_highlight cursor-pointer mb-0"
                                    onClick={() => {
                                      set_milestone_id(item.names[0]._id);
                                      setMileObj(item);
                                      setIndex(i + 1);
                                      setShowClientPayNow(true);
                                    }}
                                  >
                                    {proposal_data?.payment_method == 1 ? "Pay Now" : "Approve"}
                                    <img src={ArrowRight2} className="ml-1" />
                                  </p>
                                )}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`amount_details d-flex justify-content-${projectData?.were_admin_project ? "end" : "between"
                              } text_light_gray_md`}
                          >
                            {projectData?.were_admin_project ? null : (
                              <p className="mb-0">
                                Client Pays:
                                <span className="font_semi_bold_w_600">{` ₹ ${separateNumber(item?.price_without_tax)} `}</span>+
                                GST
                              </p>
                            )}
                            {projectData?.were_admin_project && props?.CurrentRole != 2 ? null : (
                              <p className="mb-0">
                                {item?.admin_approved || projectData?.were_admin_project ? "Your Earnings" : "Payment Pending"}:
                                <span className="text_highlight_w_600">
                                  {" "}
                                  {` ₹ ${projectData?.were_admin_project
                                      ? separateNumber(item?.total_earning, "")
                                      : separateNumber(item?.price_without_tax * 0.85)
                                    }`}
                                </span>
                                <img src={Exclamation} className="ml-2" />
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {item?.names.map((obj) => {
                      return (
                        <>
                          <div
                            className={`timeline_inner_item collapse ${item.status == 2 ? "" : "show"} ${(obj?.milestonecomplete?.length && obj.milestonecomplete[0]?.status == 1) || item.status == 2
                                ? "inner_item_active"
                                : ""
                              }`}
                            id={`collapseExample${i}`}
                          >
                            <div className="title_commn row text_gray_1100_w_600_sm mb-2 mx-0">
                              <div className="col-lg-4">
                                <p className="text_gray_1100_w_600_sm mb-0">Deliverable</p>
                                <p className="font_semi_bold mb-0">{obj?.name}</p>
                              </div>
                              <div className="col-lg-2">
                                <p className="text_gray_1100_w_600_sm mb-0">Last Updated</p>
                                <p className="font_semi_bold_w_600 mb-0">{getDateFormated(obj?.updatedAt)}</p>
                              </div>
                              <div className="col-lg-2">
                                <p className="text_gray_1100_w_600_sm mb-0">
                                  {" "}
                                  Due By
                                  {handleDelayFun(obj?.due_date) && getStatus(obj) ? (
                                    <img src={Danger} className="ml-1 mb-1" />
                                  ) : null}
                                </p>
                                <p
                                  className={`font_semi_bold_w_600 ${handleDelayFun(obj?.due_date) && getStatus(obj) ? "text-red-500_md_w_600" : ""
                                    } mb-0`}
                                >
                                  {getDateFormated(obj?.due_date)}
                                </p>
                              </div>
                              <div className="col-lg-2">
                                <p className="text_gray_1100_w_600_sm mb-0">Status</p>
                                {handleStatusDel(obj)}
                              </div>
                              <div className="col-lg-2 m-auto">
                                <div className="column_gap_10 d-flex align-items-center justify-content-end">
                                  {obj?.milestonecomplete?.length ? (
                                    obj.milestonecomplete[0].status == 0 ? (
                                      <>
                                        {props.role == 1 ? (
                                          props?.CurrentRole == 3 ? (
                                            ""
                                          ) : (
                                            <>
                                              {/* <span class="badge_blue_800 d-flex">
                                              <img src={Massage} className="mr-1" />3
                                            </span> */}
                                              <button
                                                className="btn btn-common-white fw_700"
                                                onClick={() => {
                                                  setViewUploadDel(true);
                                                  set_milestone_id(obj?._id);
                                                  setViewAttachments(obj);
                                                  setMileObj(item);
                                                }}
                                              >
                                                {item.status == 2 ? "View" : "Review" }
                                              </button>
                                            </>
                                          )
                                        ) : (
                                          <span className="eye_image cursor-pointer">
                                            <img
                                              src={Eye}
                                              onClick={() => {
                                                setViewUploadDel(true);
                                                set_milestone_id(obj?._id);
                                                setViewAttachments(obj);
                                                setMileObj(item);
                                              }}
                                            />
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span className="eye_image cursor-pointer">
                                        <img
                                          src={Eye}
                                          onClick={() => {
                                            setViewUploadDel(true);
                                            set_milestone_id(obj?._id);
                                            setViewAttachments(obj);
                                            setMileObj(item);
                                          }}
                                        />
                                      </span>
                                    )
                                  ) : props.role == 1 ? (
                                    <span className="text_light_gray_xs">
                                      <img src={Exclamation} className="mr-2" />
                                      No items added yet
                                    </span>
                                  ) : props?.CurrentRole == 3 ? (
                                    ""
                                  ) : projectData?.status == 7 ||
                                    projectData?.terminate_request == 1 ||
                                    projectData?.terminate_request == 2 ? null : (
                                    <button
                                      className="btn btn-common-white fw_700"
                                      onClick={() => {
                                        setShowUploadMilestone(true);
                                        set_milestone_id(obj?._id);
                                      }}
                                    >
                                      Upload
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ----------------------------------------- */}
                        </>
                      );
                    })}

                    {item.status == 2 ? (
                      <div className="timeline_inner_item inner_item_active">
                        <a
                          className="blue_text_sm"
                          data-toggle="collapse"
                          href={`#collapseExample${i}`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`collapseExample${i}`}
                          onClick={() => {
                            mileData[i].show = !item?.show;
                            setMileData([...mileData]);
                          }}
                        >
                          {item?.show ? "Hide" : "Show"} Deliverable <img src={item?.show ? UpArrow : DownArrow} />
                        </a>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div class="timeline-item  ">
                <div className="timeline_header">
                  <div className="icons">
                    <div className={`circle ${rowFiles?.length ? "approved" : ""} `}>
                      {rowFiles?.length ? (
                        <span>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                      ) : (
                        <span>{mileData?.length + 1}</span>
                      )}
                    </div>
                  </div>
                  <div className="title_outer">
                    <div className="title text_black_700_w_600">
                      <p className="mb-1 mt-2 d-flex align-items-center">
                        Final Submission{" "}
                        {projectData?.terminate_request == 1 || projectData?.terminate_request == 2 ? (
                          <span className="pills_tag_btn_danger ml-3">
                            {projectData?.terminate_request == 1 ? "Termination In Process" : "Terminated"}
                          </span>
                        ) : rowFiles?.length ? null : (
                          <span className="pills_tag_btn_gray ml-3">Upcoming</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {rowFiles?.length ? (
                  props?.CurrentRole == 2 || clientRowFilesAccess ? (
                    handleEndProjectReview(rowFiles, false)
                  ) : (
                    handleDivFinalSub(rowFiles, true)
                  )
                ) : props?.CurrentRole == 2 && mileDataPaid.length <= 1 ? (
                  projectData?.status == 7 ||
                    projectData?.terminate_request == 1 ||
                    projectData?.terminate_request == 2 ? null : (
                    <div className="timeline_inner_item ">
                      <div className="title_commn br_dotted row mb-2 mx-0">
                        <div className="col-lg-12 py-2">
                          <p
                            className="blue_text_heighlight line_height_20 mb-0 cursor_pointer"
                            onClick={() => {
                              setShowUploadMilestone(true);
                              set_milestone_id("");
                              setFixedLastSubm(true);
                            }}
                          >
                            <img src={Plus} />
                            Submit Final Files
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                ) : props?.CurrentRole == 1 ? (
                  handleDivFinalSub(rowFiles, true)
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVersionHistory && (
        <ClientCreatorProposalPopup
          id={props.match.params.projectId}
          setShowVersionHistory={setShowVersionHistory}
          showVersionHistory={showVersionHistory}
          data={proposal_data}
          user_id={props?.CurrentRole === 1 ? proposal_data?.user_id : ""}
          {...props}
        />
      )}
    </div>
  );
};

export default FixedOverviewProj;
