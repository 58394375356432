import React from "react";
import { postAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import { handleStatusDel, onkeyCode } from "../CommonFunctionsNew/CommonFunctionsNew";
import { connect } from "react-redux";
import { RazorpayKey, prefill } from "../../../../../utils/BaseUrl";
import moment from "moment";
import { separateNumber } from "../../../../../utils/Helperfunctions";

class TerminationPay extends React.Component {
  state = {
    isLoading: false,
    method: "Razorpay",
  };

  componentDidMount = () => {
    this.props.loadProjectChat(this.props?.projectDataStates?.data?.project_data);
  };

  handleSubmit = async () => {
    let breakup = this.props.terminationData?.total_payable;
    if (this.state.method === "Bank" || breakup == 0) {
      this.handleSubmitBank();
    } else {
      this.handleSubmitRazorpay();
    }
  };

  handleSubmitRazorpay = async () => {
    let breakup = this.props.terminationData?.total_payable;
    const payment_details = {
      amount: (breakup + breakup * 0.18) * 100,
      currency: "INR",
      receipt: "#",
      proposal_id: this.props.proposalId,
      type: "termination",
      termination_id: this.props.terminationData?._id,
    };
    // console.log(" payment_details 2 == ",payment_details)
    postAPI("/payment/create-order", payment_details)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState(
            {
              order_id: res.data.data.order_id,
              payment_type: res.data.data.type,
            },
            (e) => this.paymentHandler()
          );
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, isLoading: false });
        alert("Something went wrong, please try again");
      });
  };

  paymentHandler = async () => {
    const options = {
      key: RazorpayKey,
      name: "IndieFolio",
      description: "Kickstart Project payment",
      order_id: this.state.order_id,
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;
          const proposal_id = this.props.proposalId;

          let payment_details = {
            payment_id: paymentId,
            order_id: orderId,
            signature: signature,
            proposal_id: proposal_id,
            type: "termination",
            termination_id: this.props.terminationData?._id,
          };

          postAPI("/payment/verify-signeture", payment_details)
            .then((res) => {
              if (parseInt(res.data.status) === 1) {
                const obj = {
                  projectId: this.props.ProjectId,
                  projectName: this.props.ProjectName,
                  projectStatus: 6,
                  projectType: this.props.ProjectType,
                  callAllProposalApi: true,
                };
                if (this.props?.history?.location?.pathname?.startsWith("/talentconnect")) {
                  const pdata = this.props?.projectDataStates?.data?.project_data;
                  const objj = {
                    projectId: pdata?._id,
                    projectName: pdata?.project_name,
                    projectStatus: pdata?.status,
                    projectType: pdata?.type,
                    callAllProposalApi: true,
                  };
                  this.props.loadChat(objj);
                  const slug = pdata?.project_slug;
                  this.props.history.push(`/projects/${objj.projectId}/project_room/${slug}`);
                } else {
                  this.props.loadChat(obj);
                }
                this.props.setShowTerminationPay(false);
                this.props.callApiAgain();
              }
            })
            .catch((error) => {
              alert("Something went wrong, please try again");
            });
        } catch (err) {
          alert("Something went wrong, please try again");
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      options.prefill = prefill;
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    this.setState({ ...this.state, isLoading: false });
  };

  handleSubmitBank = async () => {
    try {
      const obj = {
        proposal_id: this.props?.proposalId,
        type: "termination",
        termination_id: this.props.terminationData?._id,
      };
      // console.log("obj 1 == ",obj)
      const res = await postAPI("/payment/direct-payment", obj);
      //   console.log("res === ", res);
      if (res) {
        this.setState({ ...this.state, isLoading: false });
        this.props.setShowTerminationPay(false);
        this.props.callApiAgain();
      }
    } catch (error) {
      this.setState({ ...this.state, isLoading: false });
      alert("Something went wrong, please try again");
    }
  };

  render() {
    const { method, isLoading } = this.state;
    const isFixed = this.props?.proposal_type && this.props.proposal_type.toLowerCase() == "fixed";
    return (
      <>
        <section className="retainer_worklog_payment_wrapper">
          <div
            className={`modal fade modal_fade_bg ${this.props?.showTerminationPay ? "show" : ""}`}
            style={{ display: this.props?.showTerminationPay ? "block" : "" }}
            id="worklogviewfile_2"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg m-auto">
              <div className="modal-content br_6">
                <div className="br_btm modal_top_header px-4 d-flex align-items-center  justify-content-between">
                  <div className="header">
                    <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                      Complete Payment
                    </h5>
                  </div>
                  <button
                    type="button"
                    onClick={() => this.props.setShowTerminationPay(false)}
                    className="close close_btn cursor-pointer"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="close_icon modalclose-btnicon">
                      ×
                    </span>
                  </button>
                </div>
                <div className="modal-body p-4">
                  {isFixed ? (
                    <div className="row px-3 mb-3 common_overflow_xsm_height">
                      {this.props?.terminationData.milestone_payment.map((item, i) => {
                        return (
                          <div className="col-lg-12 br_btm px-0 mb-4">
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 font_semi_bold mb-3">Milestone {item.index + 1}</p>
                            </div>
                            {/* <div className="extend_overflow_height"> */}
                              <ul className="paying_cycle_list text_gray_xs_w_600 ">
                                {item.names.map((obj) => {
                                  return (
                                    <li className="mb-2">
                                      <div className="d-flex justify-content-between">
                                        <p className="mb-0">
                                          {obj.name} {obj.iterations > 1 ? `(${obj.iterations} Iterations)` : ""}
                                        </p>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            {/* </div> */}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="row px-3">
                    <div className="col-lg-12 px-0 mb-3">
                      <p className="mb-0 font_semi_bold">Payment Mode</p>
                    </div>
                    <div className="col-lg-6 pl-lg-0 mb-3">
                      <div
                        className={`payment_card  ${method === "Razorpay" ? "active" : ""} d-flex cursor-pointer`}
                        onClick={() => this.setState({ ...this.state, method: "Razorpay" })}
                      >
                        <div className="radio_btn pr-3">
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              checked={method === "Razorpay" ? true : false}
                              onChange={() => ""}
                            />
                          </div>
                        </div>
                        <div className="payment_message">
                          <p className="mb-2 font_semi_bold_w_600">Razorpay</p>
                          <p className="mb-2 text_light_gray_md"> Use this option to make the payment immediately using debit card/credit card/UPI/net banking.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 pr-lg-0 mb-3">
                      <div
                        className={`payment_card ${method === "Bank" ? "active" : ""} d-flex cursor-pointer`}
                        onClick={() => this.setState({ ...this.state, method: "Bank" })}
                      >
                        <div className="radio_btn pr-3">
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              checked={method === "Bank" ? true : false}
                              onChange={() => ""}
                            />
                          </div>
                        </div>
                        <div className="payment_message">
                          <p className="mb-2 font_semi_bold_w_600">Bank Transfer</p>
                          <p className="mb-2 text_light_gray_md">Use this option to facilitate payments outside of the platform using a bank transfer.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row total_details_card d-flex align-items-center justify-content-between mx-0 mb-5 px-4 py-3 amount_tag ">
                  <div className="title">
                    <p className="mb-0 font_semi_bold">Amount Payable</p>
                  </div>
                  <div className="total_amount">
                    <p className="mb-0 text_gray_md_w_700">
                      {`₹ ${separateNumber(this.props.terminationData?.total_payable)}`}
                      <span className="d-block text_light_gray_xs text-right">+18% GST </span>
                    </p>
                  </div>
                </div>
                <div className="modal-footer justify-content-end align-items-center py-4 border-0">
                  <div className="col-lg-12 text-right">
                    <button type="button" class="btn btn_common_md fw_700" onClick={this.handleSubmit}>
                      {isLoading ? <Loader isCreateModal={true} /> : "Proceed"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let creatorProfile = store.creatorProfile;
  let postProjectData = store.postProject;
  return {
    closeModal: creatorProfile.closeModal,
    current_proposal_id: postProjectData.current_proposal_id,
    proposal_data: postProjectData.review_proposal_data,
    CurrentChat: chat.CurrentChat,
    ProjectName: chat.ProjectName,
    ProjectStatus: chat.ProjectStatus,
    ProjectType: chat.ProjectType,
    ProjectId: chat.ProjectId,
  };
};
const mapDispatch = (dispatch) => ({
  loadChat: (obj) => dispatch({ type: "UPDATE_LOAD_CHAT", payload: obj }),
  loadProjectChat: (obj) =>
    dispatch({
      type: "LOAD_PROJECT_CHAT",
      payload: { projectId: obj?._id, projectName: obj?.project_name, projectStatus: obj?.status },
    }),
});

export default connect(mapState, mapDispatch)(TerminationPay);
