import React, { Component } from "react";
import { connect } from "react-redux";
import ChatInterface from "./ChatInterface";
import { getAPI } from "../../../utils/API";
import Messages from "./Messages";
import { getUserProfileForAdminProject } from "./utils";
import ChatLeftPanelMobileView from "./MobileScreens/ChatLeftPanel/ChatleftPanelMobieView";
class MessageWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterMsg: [],
      fileMsg: [],
      linkMsg: [],
      showFolder: false,
      showFiles: true,
      showLinks: false,
      showSearch: false,
      currentScreen: "messages",
    };

    // rtc.client = AgoraRTC.createClient({mode: "rtc", codec: "h264"});
    // rtc.client.init("83d11252c75940fea38395969f1efd7a")
    // this.openCall=this.openCall.bind(this);
  }

  openProposal = () => {
    const { CurrentChat } = this.props;
    this.props.OpenPopup("ReviewProposal");
    this.props.SetCurrentProposal(CurrentChat.proposal_id, CurrentChat.post_project_id);

    // creator side
    // this.props.SetCurrentProposal(proposal._id,proposal.post_project_id);
    // this.props.OpenPopup('ReviewProposal');
  };

  componentDidMount() {
    // rtc.client.on("userJoined", (data)=>{
    //   console.log("datauserjoine",data)
    // })
  }
  componentDidUpdate(prevProps) {
    if (this.props.CurrentChat !== prevProps.CurrentChat) {
      this.setState({ filterMsg: [], searchValue: "" });
      this.getfile();
      this.getlinks();
    }
  }

  searchMsg = (event) => {
    if (!this.props.CurrentChat) return;

    this.setState({ searchValue: event.target.value });
    getAPI(`/chat/serch-chat-messages?chat_connection_id=${this.props.CurrentChat.chat_connection_id}&text=${event.target.value}`)
      .then((res) => {
        //  console.log("serch-chat-messages data=======>",res)

        this.setState({
          filterMsg: res.data.user_chat,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getfile = () => {
    if (!this.props.CurrentChat?.chat_connection_id) return;
    getAPI(`/chat/get-chatfiles?chat_connection_id=${this.props.CurrentChat?.chat_connection_id}`)
      .then((res) => {
        // console.log("schatfiles-messages data-->",res.data.data,res.data.status)
        if (res.data && res.data.data) {
          this.setState({
            fileMsg: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getlinks = () => {
    const { CurrentChat } = this.props;
    if (!CurrentChat?.chat_connection_id) return;

    getAPI(`/chat/get-links?chat_connection_id=${this.props.CurrentChat.chat_connection_id}`)
      .then((res) => {
        // console.log("schatlink-messages data-->",res.data.data)
        if (res.data && res.data.data) {
          this.setState({
            linkMsg: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  clear = () => {
    this.setState({ searchValue: "" });
  };

  jumpToMsg = (msgId) => {
    this.setState({ msgId });
  };

  openFiles = () => {
    if (!this.state.showFolder) {
      this.getfile();
    }
    this.setState({ showFolder: !this.state.showFolder });
    this.setState({ showSearch: false, showCalendar: false });
  };

  showFile = () => {
    this.setState({ showFiles: true, showLinks: false });
    this.getfile();
  };
  showLink = () => {
    this.setState({ showFiles: false, showCalendar: false, showLinks: true });
    this.getlinks();
  };
  hideBlackScreen = () => {
    this.setState({ showFolder: false, showSearch: false, showCalendar: false, showFiles: false });
  };

  openSearch = () => {
    this.setState({ showSearch: !this.state.showSearch });
    this.setState({ showFolder: false, showCalendar: false });
  };
  openCalendar = () => {
    this.setState({ showCalendar: !this.state.showCalendar });
    this.setState({ showFolder: false, showSearch: false });
  };

  toggleMessageScreen = (value) => {
    this.setState({
      currentScreen: value,
    });
  };

  openBrief = () => {
    this.props.OpenPopup("IncomingBriefReview");
    this.props.LoadBriefData(this.props.ProjectId);
  };

  getUserProfileImage = (senderId) => {
    const { UserId, Me, CurrentChat, ProjectType, Artist } = this.props;
    const isAdminProject = ProjectType === "post_project_admin";
    if (isAdminProject) {
      return getUserProfileForAdminProject(Artist[0], senderId).profile_image;
    } else {
      return UserId === senderId ? Me.ProfileImage : CurrentChat.profile_image;
    }
  };

  render() {
    const { CurrentChat, UserToken, Me, ProjectStatus, proposalStatus, ProjectType, Artist, chatMessages } = this.props;
    const { currentScreen } = this.state;
    const isAdminProject = ProjectType === "post_project_admin";
    const chatUsername = CurrentChat && CurrentChat.first_name + " " + CurrentChat.last_name;
    const isDisabledChat = ProjectType === "post_project_client" && !chatMessages?.length;
    return (
      <>
        <div className="col-12 mob-p-0">
          {UserToken !== "" ? (
            <>
              {/* <div
                className={`chat-search-overlay ${
                  this.state.showFolder || this.state.showSearch || this.state.showCalendar ? "d-block" : "d-none"
                }`}
                onClick={this.hideBlackScreen}
              ></div>
              <div className="mobile-chat-header desktop_ipadPro_display_none">
                <div className="row m-0 d-flex align-items-center mob_get_verify remove_border">
                  {(ProjectStatus === 6 || ProjectStatus === 7 || ProjectStatus === 5) &&
                    this.props.milestones &&
                    this.props.milestones.length > 0 && (
                      <div className="col-sm-12 message-folder mobile-tabs-milestone pb-3">
                        <div className="wrapper-form p-1">
                          <div className="row m-0 align-items-center">
                            <div className="col-6 col-sm-6 p-0">
                              <a
                                href="/"
                                className={`tabs-folder py-8 font-weight-500 ${currentScreen === "messages" ? "active" : ""}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.toggleMessageScreen("messages");
                                }}
                              >
                                Messages
                              </a>
                            </div>
                            {(ProjectStatus === 6 || ProjectStatus === 7) &&
                            this.props.milestones &&
                            this.props.milestones.length > 0 ? (
                              <div className="col-6 col-sm-6 p-0">
                                <a
                                  href="/"
                                  className={`tabs-folder py-8 font-weight-500 ${
                                    currentScreen === "milestones" ? "active" : ""
                                  } `}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleMessageScreen("milestones");
                                  }}
                                >
                                  Milestones
                                </a>
                              </div>
                            ) : ProjectStatus === 5 && this.props.milestones && this.props.milestones.length > 0 ? (
                              <div className="col-6 col-sm-6 p-0">
                                <a
                                  href="/"
                                  className={`tabs-folder py-8 font-weight-500 ${currentScreen === "proposal" ? "active" : ""} `}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleMessageScreen("proposal");
                                  }}
                                >
                                  Latest Proposal
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div> */}
              {currentScreen === "messages" ? (
                <>
                  <div className="chat_body  body_scroll_bar">
                    <Messages {...this.props} />
                  </div>
                  <ChatInterface {...this.state} {...this.props} />
                </>
              ) : (
                <ChatLeftPanelMobileView />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  const postProject = store.postProject;
  return {
    Me: globalReducer.Me,
    CurrentChat: chat.CurrentChat,
    ProjectType: chat.ProjectType,
    Artist: chat.Artist,
    UserToken: chat.UserToken,
    UserId: chat.UserId,
    ProjectId: chat.ProjectId,
    ProjectStatus: chat.ProjectStatus,
    proposalStatus: postProject.proposalStatus,
    proposal: postProject.proposal,
    loading: globalReducer.loading,
    milestones: postProject.milestones,
    chatMessages: chat.Messages,
  };
};
const mapDispatch = (dispatch) => ({
  OpenPopup: (text) => dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  onFieldChange: (e) => dispatch({ type: "ON_FIELDCHANGE_SAGA", payload: { name: e.name, value: e.value } }),
  LoadBriefData: (id) => dispatch({ type: "LOAD_BRIEF_DATA", payload: { project_id: id } }),
  SetCurrentProposal: (proposal_id, project_id, reviewStatus) =>
    dispatch({ type: "SET_CURRENT_PROPOSAL", payload: { proposal_id, project_id, reviewStatus } }),
});
export default connect(mapState, mapDispatch)(MessageWindow);
