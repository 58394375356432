import React from 'react';
import ProgressBar from './Progressbar';
import { connect } from 'react-redux';
import { putAPI, postAPI } from '../../../utils/API';
import { CollateralBaseUrl } from '../../../utils/BaseUrl';
import { Fragment } from 'react';

class Type extends React.Component {

	state = {
		showDropDownList: false,
		typeQuestions: [],
		answer: [],
		typeError: false,
		isLoading: false,

	}
	componentDidMount = () => {
		const { typeQuestions } = this.props;
		this.setState({
			typeQuestions: typeQuestions,
		})
	}

	handleContinue = (e, skip) => {
		this.setState({ isLoading: true })
		const { selected_collateral, projectId, typeQuestions, total_steps, post_steps, isHireflow, StyleQuestions } = this.props;
		if (skip) {
			this.setState({ isLoading: false })

			if(this.props.userName){

				putAPI('/project/post-project-skip-style-type', { _id: projectId, type: 'type' }).then(res => {
					if (StyleQuestions && StyleQuestions.length > 0) {
						
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
					} else {
						this.props.history.push(`${isHireflow ? '/hire/projectName' : '/self-service-hire/style'}`);
						
					}
					})
			}else{
				localStorage.setItem("TypeSkip",'yes');
				if (StyleQuestions && StyleQuestions.length > 0) {
						
					this.props.history.push(`/hire/style`);
				} else {
					this.props.history.push(`/hire/projectName`);
					
				}
						

			}
			
			return
		}
		if (typeQuestions.length <= 0) {
			this.setState({
				typeError: true,
				isLoading: false,
			})
			//alert("Please select your type");
			return;
		}
		const type_data = {
			collateral_id: selected_collateral,
			_id: projectId,
			question_answer: typeQuestions,
		}

		if (this.props.userName) {
			putAPI('/project/post-project-type-question-answer', type_data).then((res) => {
				if (res.data.status === "1") {
					if (StyleQuestions && StyleQuestions.length > 0) {
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
						return
					}
					const status_data = {
						post_project_id: this.props.projectId,
						status: 1,
					}
					if (isHireflow) {
						this.props.PostProjectSteps(post_steps, total_steps);
						if (StyleQuestions && StyleQuestions.length > 0) {
							this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
						} else {
							this.props.history.push('/hire/projectName');
						}
						return

					}
					putAPI('/project/post-project-status', status_data).then((res) => {
						if (res.data.status === "1") {
							const { hireAgainCreatorId, isHireAgain } = this.props;
								this.props.PostProjectSteps(post_steps, total_steps);
								this.props.history.push('/hire/projectName');
						}
					}).catch((error) => {
						console.log(error);
					})
					this.props.PostProjectSteps(post_steps, total_steps);
					if (StyleQuestions && StyleQuestions.length > 0) {
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
					} else {
						this.props.history.push('/hire/projectName');
					}
					// console.log("Type Response", res.data);
					this.setState({ isLoading: false })
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("TypeQuestionData", JSON.stringify(type_data));
			this.props.PostProjectSteps(post_steps, total_steps);
			if (StyleQuestions && StyleQuestions.length > 0) {
				this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
			} else {
		
				this.props.history.push('/hire/projectName');
			}
			this.setState({ isLoading: false })
		}
	}

	handleDropDownList = () => {
		const { showDropDownList } = this.state;
		this.setState({
			showDropDownList: !showDropDownList,
		})
	}

	SelectAnswer = (item) => {
		let typeQuestions = (this.state.typeQuestions) ? [...this.state.typeQuestions] : [];
		const questionAlreadySelected = typeQuestions.some(el => el._id === item.id);
		// console.log("Question Already Selected====>",questionAlreadySelected);
		if (!questionAlreadySelected) {
			let answers = [item.answer];
			typeQuestions.push({
				_id: item.id,
				type: item.type,
				question: item.question,
				option: item.option,
				subtext: item.subtext,
				answer: answers,
			})
			this.setState({
				typeQuestions: typeQuestions,
				typeAnswers: answers,
			}, () => this.props.ChooseAnswer(this.state.typeQuestions));
		} else {
			const elIndex = typeQuestions.findIndex(element => element._id === item.id)
			let answers = typeQuestions[elIndex].answer;
			if (item.type === "radio_button_with_images_with_text" || item.type === "dropdown") {
				answers.splice(0, 1, item.answer);
			}
			else {
				let hasOther = answers.some(el => el.item === "other");
				if (item.answer.item !== "other" && hasOther === false) {
					answers.indexOf(item.answer) === -1 ? answers.push(item.answer) : (answers = answers.filter(el => el !== item.answer));
				} else if (hasOther === true && item.answer.item !== "other") {
					answers.splice(0, answers.length, item.answer)
				} else {
					answers.splice(0, answers.length, item.answer)
				}
			}
			const elementsIndex = typeQuestions.findIndex(element => element._id === item.id)
			let newArray = [...typeQuestions];
			newArray[elementsIndex] = { ...newArray[elementsIndex], answer: answers }
			this.setState({
				typeQuestions: newArray,
				typeAnswers: answers,
			}, () => this.props.ChooseAnswer(this.state.typeQuestions));
		}
		this.setState({
			typeError: false,
		})
	}



	render() {

		const { TypeQuestions } = this.props;
		const { showDropDownList, typeQuestions, typeError } = this.state;

		return (
			<>
				<section>
					<div className="card-wrapper">
						<div className="card mob-border-radius-0">
							<div className="card-body">
								<div className="row m-0 pb-4 pt-2 mob-p-10 ipad-only-p-10 ipad-pro-only-p-10 overflow-content project-height">
									<div className="col-12 col-sm-12 mob-px-0 px-4 ">
										{/* "type-height" classname change  */}
										<div className="row m-0">
											<div className="col-sm-12 mob-px-5 p-0">
												<div className="onboarding_style_questions xl_size">
													{
														TypeQuestions.map((obj, index) => {
															if (obj.type === "checkbox_with_images_with_text") {
																return <Fragment key={index}>
																	<div className="pt-5 mob-pt-20">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "") ?
																			<p className="label font-weight-500 mob-font-13 text-third">{obj.subtext}</p>
																			: <></>}
																	</div>
																	<ul className="content">
																		{
																			obj.option.map((options, index) => (
																				<li key={index} className="position-relative">
																					<div className={`deadline position-relative 
																					${typeQuestions && typeQuestions.some(el => (el._id === obj._id && el?.answer?.some(item => item.item === options.item))) ? "active" : ""}`
																					}
																						value={options.item}
																						style={{ backgroundImage: `url("${CollateralBaseUrl + options.image}")`, backgroundSize: "cover" }}
																						onClick={(e) => this.SelectAnswer({
																							id: obj._id,
																							type: obj.type,
																							question: obj.question,
																							subtext: obj.subtext,
																							option: obj.option,
																							answer: options
																						})} >
																					</div>
																					<p className="style_name">{options.item.length > 35 ? options.item.substring(0, 35) + '...' : options.item}</p>
																				</li>
																			))
																		}
																	</ul>
																	{(typeError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your type.</p> : ""}
																</Fragment>
															}
															if (obj.type === "radio_button_with_images_with_text") {
																return <>
																	<div className="pt-5 mob-pt-py">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "") ?
																			<p className="label font-weight-500 mob-font-13 text-third">{obj.subtext}</p>
																			: <></>}
																	</div>
																	<ul className="content">
																		{obj.option.map((options, index) => (
																			<li key={index} className="position-relative">
																				<div className={`deadline position-relative 
																		${typeQuestions && typeQuestions.some(el => (el._id === obj._id && el?.answer?.some(item => item.item === options.item))) ? "active" : ""}`
																				}
																					onClick={(e) => this.SelectAnswer({
																						id: obj._id,
																						type: obj.type,
																						question: obj.question,
																						subtext: obj.subtext,
																						option: obj.option,
																						answer: options
																					})}
																					value={options.item}
																					style={{ backgroundImage: `url('${CollateralBaseUrl + options.image}')`, backgroundSize: "cover" }} >
																				</div>
																				<p className="style_name">{options.item.length > 35 ? options.item.substring(0, 35) + '...' : options.item}</p>
																			</li>
																		))
																		}
																	</ul>
																	{(typeError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your type.</p> : ""}
																</>
															}

															if (obj.type === "dropdown") {
																return <>
																	<div className="pt-5 mob-pt-py">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "") ?
																			<p className="label font-weight-500 mob-font-13 text-third">{obj.subtext}</p>
																			: <></>}
																		{(typeError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your type.</p> : ""}
																	</div>
																	<div className="filter-dropdown wrapper-dropdown w-48 mob-w-100 position-relative">
																		<div className="select">
																			<div className="select">
																				<div className={`select-styled text-dark-black`}
																					onClick={this.handleDropDownList}>
																					{typeQuestions && typeQuestions.some(el => el._id === obj._id) ? typeQuestions.filter(el => el._id === obj._id).map(filteredObj => filteredObj.answer?.[0].item) : <span className="text-secondary font-14">Choose your answer</span>}
																				</div>
																				{obj.option.length > 0 && showDropDownList === true ?

																					<ul className="select-options hideList" style={{ display: "block" }} >
																						<li rel="hide">2020</li>
																						{
																							obj.option.map((options, index) => (
																								<li key={index} onClick={(e) => this.SelectAnswer({
																									id: obj._id,
																									type: obj.type,
																									question: obj.question,
																									subtext: obj.subtext,
																									option: obj.option,
																									answer: options
																								})}>{options.item}</li>
																							))
																						}
																					</ul> : ""
																				}
																			</div>
																		</div>
																		<i className="fas fa-chevron-down select-arrow" style={{ top: "20px", right: "20px" }}></i>
																	</div>
																</>
															} else return null
														})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<ProgressBar skip={this.props.isHireflow ? true : false} {...this.state} handleContinue={this.handleContinue} />
						</div>
					</div>
				</section>
			</>
		)
	}
}

const mapState = (store) => {
	let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
	// console.log(postProjectData);
	return {
		budget_scope:postProjectData.budget_scope,
		selected_collateral: postProjectData.selected_collateral,
		style_answers: postProjectData.style_answers,
		projectId: postProjectData.projectId,
		post_steps: postProjectData.post_steps,
		total_steps: postProjectData.total_steps,
		hireAgainCreatorId: postProjectData.hireAgainCreatorId,
		isHireAgain: postProjectData.isHireAgain,

		TypeQuestions: (postProjectData?.typeQuestions?.length > 0) ? (postProjectData.typeQuestions) : postProjectData?.CollateralQuestions?.Collateral_type_question,
		typeQuestions: (postProjectData?.typeQuestions?.length > 0) ? (postProjectData.typeQuestions) : [],

		StyleQuestions: postProjectData.CollateralQuestions.Collateral_style_question,

		alreadyCreatedProject: postProjectData.alreadyCreatedProject,
		userName: globalReducer.Me.Username,


	};
}

const mapDispatch = dispatchEvent => ({
	OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state }),
	ChooseAnswer: (answer) => dispatchEvent({ type: "CHOOSE_TYPE_ANSWER", payload: answer }),
	PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
	changeHireAgainStatus: (val) => dispatchEvent({ type: 'UPDATE_HIRE_AGAIN_STATUS', values: { creatorId: '', isHireAgain: val } }),

});

export default connect(mapState, mapDispatch)(Type);