import React, { useEffect, useRef, useState } from "react";
import ArrowImage from "../../../../../Assets/upgrade-images/submit_proposal/caret-down.svg";

const MobileResArtistList = ({
  Artist,
  singleArtistItem,
  CurrentChat,
  getArtistDetail,
  loadPerson,
  projectData,
  isIndividual,
  firstMessFinal,
  ...props
}) => {
  const reff = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState("");
  const handleDropDownClose = (ee) => {
    if (!reff.current?.contains(ee.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleDropDownClose);
    return () => {
      window.removeEventListener("click", handleDropDownClose);
    };
  }, []);

  useEffect(() => {
    // console.log("eeeeeeeee",Artist)
    let obj = {};
    Artist?.length &&
      Artist.forEach((ele) => {
        if (ele.chat_connection_id === CurrentChat.chat_connection_id) {
          obj = ele;
        }
        // console.log(ele.chat_connection_id,'----',CurrentChat.chat_connection_id)
      });
    setSelectedArtist(obj);
  }, [Artist]);

  return (
    <div class="dropedown_input w-100 d-block d-md-none" ref={reff}>
      <div onClick={() => setToggle(!toggle)}>
      {/* {console.log(selectedArtist,'--33344')} */}
        <div className={`col-lg-12 d-flex Conversation_card ${selectedArtist&&getArtistDetail(selectedArtist)?.isActive ? "active" : ""}`}>
          {selectedArtist?singleArtistItem(getArtistDetail(selectedArtist), projectData, isIndividual, selectedArtist,firstMessFinal, props):""}
        </div>
        <img src={ArrowImage} className={`img-fluid dropedown_icon cursor-pointer`} />
      </div>
      {toggle ? (
        <div className="dropdown_menu ml-1">
          <div className="dropdown_inner">
            {Artist?.length > 0
              ? Artist.map((obj, k) => {
                // console.log(obj.member1,'54')
                  return (
                    <div
                      onClick={(e) => {
                        setSelectedArtist(obj,k);
                        loadPerson(obj);
                        setToggle(!toggle)
                      }}
                      key={k}
                      className={`col-lg-12 d-flex Conversation_card ${getArtistDetail(obj)?.isActive ? "active" : ""}`}
                    >
                      {singleArtistItem(getArtistDetail(obj), projectData, isIndividual, obj, props)}
                    </div>
                  );
                })
              : "No List Found ..."}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default MobileResArtistList;
