import React, { Component } from 'react'
import { Fragment } from 'react';
import DatePicker from 'react-date-picker';
import { getDateTime } from '../../../../utils/DateMethod';
import { separateNumber } from '../../../../utils/Helperfunctions';
import Select from 'react-select';
import { ChatImageBaseUrl } from "../../../../utils/BaseUrl"
import LinkImage from '../../../../Assets/images/link-file.jpg';
import PdfImage from "../../../../Assets/images/big-pdf.jpg";
import DocImage from "../../../../Assets/images/big-doc.jpg";
import ZipImage from "../../../../Assets/images/big-zip.jpg";
import PPtImage from "../../../../Assets/images/big-ppt.jpg";
import { handleFileType } from '../../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';

export default class MobileSubmitMilestone extends Component {
    openProposal = () => {
      this.props.OpenPopup('ReviewProposal')
    }
    render() {

        const {ItemMilestone, active_milestones, notes, totalCost, totalEarning, submitting, images_links, selectedType, urlData, imagesLinksFlag, filesUploading } = this.props
        const options = [
          {  label: 'Select' },
          { value: 'Add file', label: 'Add files' },
          { value: 'Add url', label: 'Add url' }
        ]

        let count = 0;
        let imgData = [...images_links]
        let imgFile = imgData.filter(item => item?.type === "files").pop()

        return (
            <div className="row m-0">
            {/* New proposal and old version mobile */}
            {/* <div className="col-sm-12 message-folder mobile-tabs-milestone py-3">
              <div className="wrapper-form p-1">
                <div className="row m-0 align-items-center">
                  <div className="col-6 col-sm-6 p-0"><a href="#/" className="tabs-folder py-8 font-weight-500 active">New Proposal</a></div>
                  <div onClick={this.openProposal} className="col-6 col-sm-6 p-0"><a href="#/" className="tabs-folder py-8 font-weight-500">Older versions</a></div>
                </div>
              </div>
            </div> */}
            <div className="col-sm-12 col-md-12 col-lg-12 dont-like overflow-content chat-modal-height new-proposal-height">
              {/* <p className="mb-0 py-3 font-16 small-font-14 font-weight-600">
                <i className="fas fa-info-circle text-dark-black font-16 small-font-14" /> Advance one-time non-refundable fee
              </p>
              <div className="wrapper-form p-4 mb-4 mob-p-15">
                <form>
                  <div className="row m-0">
                    <div className="form-group mb-3 col-12 p-0">
                      <label className="label font-weight-600" htmlFor="first">Due date</label>
                      <input type="text" disabled className="form-control email pl-3 bg-tranparent" placeholder="Immediately" />
                      <span className="pick-a-date set-quote"><i className="far fa-calendar-alt font-20 text-third" /></span>
                    </div>
                    <div className="form-group mb-3 col-12 p-0">
                      <label className="label font-weight-600" htmlFor="first">Cost</label>
                      <input  type="text" className="form-control email pl-3" placeholder="Enter cost here" value={advancePrice} name="advancePrice" onChange={this.props.handleChange} />
                      <span className="text-third font-13 pt-2 d-block line-height-14 font-weight-500">Recommended to maintain a minimum of 10% of total project size.</span>
                    </div>
                  </div>
                  <button type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer">Save</button>
                </form>
              </div> */}
              <div className="border-bottom-2 my-30 mob-my-20" />
              {ItemMilestone.map((data, k) => {
                let prevDate = k > 1 ? new Date(ItemMilestone[k-1].due_date) : new Date()
                prevDate.setDate(prevDate.getDate() + 1)
                const isDisabled = (active_milestones && data._id) ? (active_milestones === data._id) : false
                if(data.type === "token"){
                  return (
                    data.isOpen ? 
                      <Fragment key={k}>
                        <p className="mb-0 py-3 font-16 small-font-14 font-weight-600">
                          <i className="fas fa-info-circle text-dark-black font-16 small-font-14" /> Advance one-time non-refundable fee
                        </p>
                        <div className="wrapper-form p-4 mb-4 mob-p-15">
                          <form>
                            <div className="row m-0">
                              <div className="form-group mb-3 col-12 p-0">
                                <label className="label font-weight-600" htmlFor="first">Due date</label>
                                <input type="text" disabled className="form-control email pl-3 bg-tranparent" placeholder="Immediately" />
                                <span className="pick-a-date set-quote"><i className="far fa-calendar-alt font-20 text-third" /></span>
                              </div>
                              <div className="form-group mb-3 col-12 p-0">
                                <label className="label font-weight-600" htmlFor="first">Cost</label>
                                <input  type="number" value={data.price ? data.price : ''} disabled={data.type === 'token' && data.status === 2} name="price" onChange={(e) => this.props.newHandleOnChange(e, k)} className={`form-control email pl-3 ${data.priceError ? 'border-error' : ''}`} placeholder="Enter cost here" />
                                {data.priceError && <span className="text-danger font-weight-600 font-12 py-1">Please enter price</span>}
                                <span className="text-third font-13 pt-2 d-block line-height-14 font-weight-500">Recommended to maintain a minimum of 10% of total project size.</span>
                              </div>
                            </div>
                            <button onClick={() => this.props.closeInputBoxMobile(k)} type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer">Save</button>
                          </form>
                        </div>
                      </Fragment> :
                      <div key={k} className="form-group mb-4 col-12 p-0">
                        <label className="label font-weight-600 font-14 text-dark-black" htmlFor="first">Advance one-time non-refundable fee</label>
                        <div className="card wrapper-form">
                          <div className="card-body">
                            <div className="row m-0 py-3 px-2 mob-pr-0 mob-pl-0">
                              <div className="col-9">
                                {/* <h2 className=" text-capitalize text-dark-black font-16 mb-1 font-weight-600">Logo design iterations</h2> */}
                                <div className="form-group mb-0 pt-1">
                                  <span className="d-block mb-1 text-third font-weight-500 font-14"><i className="far fa-calendar-alt font-16 text-third mr-1" /> Immediately</span>
                                  <span className="d-block text-third font-weight-500 font-14"><i className="far fa-closed-captioning font-16 mr-1" /> <i className="fas fa-rupee-sign font-12" />{data.price}</span>
                                </div>
                              </div> 
                              {data.status === 2 || isDisabled ?
                              null :
                              <div className="col-3 tabs-icon milestone-icon first_icon">
                                <div className="text-right">
                                  <span className="d-block hidden"><i className="fas fa-times text-light-gray font-20 mob-font-14 pl-3 pb-30" /></span>
                                  <span onClick={() => this.props.openInputBoxMobile(k)} className={`d-block ${(data.status === 2 ||  isDisabled  ) ? 'hide' : ''}`} ><i className="fas fa-chevron-down text-light-gray font-20 mob-font-14 pl-3 collacteral position-relative" /></span>
                                </div>
                              </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                } else {
                  return(
                    data.isOpen ? 
                      <div key={k} className="wrapper-form p-4 mb-4 mob-p-15">
                        <form method="post">
                          <div className="row m-0">
                            <div className="form-group mb-3 col-12 p-0">
                              <label className="label font-weight-600" htmlFor="first">Name milestones</label>
                              <input type="text" name="name" value={data.name} onChange={(e) => this.props.newHandleOnChange(e, k)} className={`form-control email pl-3  ${data.nameError ? 'border-error' : ''}`} 
                              disabled={(data.status === 2 || isDisabled ) ? true : false}
                              placeholder="Enter details here" />
                            {data.nameError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a name</span>}
                            </div>
                            <div className="form-group mb-3 col-12 p-0">
                              <label className="label font-weight-600" htmlFor="first">Due date</label>
                              {/* <input type="text"  className="form-control email pl-3" placeholder="Pick a date" />
                              <span className="pick-a-date set-quote"><i className="far fa-calendar-alt font-20 text-third" /></span> */}
                              <DatePicker
                              onChange={
                                (e) => { this.props.handleDateOnChange(e, k) }
                              }
                              value={(data.due_date) ? new Date(data.due_date) : ''}
                              className={`form-control email pl-3 ${data.dateError ? 'border-error' : ''}`}
                              clearIcon={null}
                              format="dd-MM-yyyy"
                              calendarClassName="apniClass"
                              showLeadingZeros={true}
                              disabled={data.status === 2 || isDisabled  ? true : false}
                              // minDate={k > 1 ? new Date(ItemMilestone[k-1].due_date) : new Date()}
                              defaultActiveStartDate={prevDate}
                              minDate={prevDate}
                            />
                            {data.dateError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a date</span>}

                            </div>
                            <div className="form-group mb-3 col-12 p-0">
                              <label className="label font-weight-600" htmlFor="first">Cost</label>
                              <input type="number" name="price" value={data.price === 0 ? '' : data.price} onChange={(e) => this.props.newHandleOnChange(e, k)} className={`form-control email pl-3  ${data.priceError ? 'border-error' : ''}`} disabled={(data.status === 2 ||  isDisabled ) ? true : false} placeholder="Enter cost here" />
                            {data.priceError && <span className="text-danger font-weight-600 font-12 py-1">Please enter a price</span>}
                            </div>
                            <button onClick={() => this.props.closeInputBoxMobile(k)} type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer">Save</button>
                          </div>
                        </form>
                      </div> :
                    <div key={k} className="form-group mb-4 col-12 p-0">
                      <label className="label font-weight-600 font-14 text-dark-black" htmlFor="first">Milestone {data.serial_no}</label>
                      <div className="card wrapper-form">
                        <div className="card-body">
                          <div className="row m-0 py-3 px-2 mob-pr-0 mob-pl-0">
                            <div className="col-9">
                              <h2 className=" text-capitalize text-dark-black font-16 mb-1 font-weight-600">{data.name}</h2>
                              <div className="form-group mb-0 pt-1">
                                <span className="d-block mb-1 text-third font-weight-500 font-14"><i className="far fa-calendar-alt font-16 text-third mr-1" /> {getDateTime(data.due_date,"dateMonth")}</span>
                                <span className="d-block text-third font-weight-500 font-14"><i className="far fa-closed-captioning font-16 mr-1" /> <i className="fas fa-rupee-sign font-12" />{data.price}</span>
                              </div>
                            </div>
                            {data.status === 2 || isDisabled ? 
                              null
                            : <div className="col-3 tabs-icon milestone-icon ">
                                <div className="text-right">
                                  <span className={`d-block ${(isDisabled  || data.status === 2 || k === 1 ) ? 'hidden' : ''}`}
                                  onClick={(e) => this.props.removeMilestone(e, k)}><i className="fas fa-times text-light-gray font-20 mob-font-14 pl-3 pb-30" /></span>
                                  <span onClick={() => this.props.openInputBoxMobile(k)} className={`d-block ${(data.status === 2 ||  isDisabled  ) ? 'hide' : ''}`}><i className="fas fa-chevron-down text-light-gray font-20 mob-font-14 pl-3 collacteral position-relative" /></span>
                                </div> 
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                
                
              })}
              {/* <div className="border-bottom-2 my-30 mob-my-20" /> */}
              
              <div className="w-100 dflex add_milestone pl-0 mb-4">
                <a href="#/" onClick={this.props.AddMilestone} className="mob-font-13 small-font-11 font-weight-bold letter-spacing-02 text-center"><i className="fas fa-plus mr-1" /> Add milestone</a>
                <span className="add-collateral-underline" />
              </div>
              <div className="row m-0">

                {/* Add image or url */}
                <div className="col-sm-12 pt-4 px-0 pb-1">
                  <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Add Project References to Your Proposal</span>
                </div>
                {(images_links?.length < 10) && 
                <div className={`col-sm-12 ${(images_links?.length <= 9 && imagesLinksFlag) ? "mb-4" : ""}`}> 
                  <div className='row'>
                    <div className={`col-12 col-sm-4 px-0 ${(images_links?.length > 0 || selectedType?.label === "Add files") ? "mb-4" : ""} `}>
                      <div className="w-100">
                        <Select options={options} onChange={this.props.handleImageChange}/>
                      </div>
                    </div> 
                    {
                      images_links?.length <=9  &&  selectedType?.label === "Add url" ?
                      <div className="col-12 col-sm-6 px-0 mb-4">
                          <input className='form-control' value={urlData} type='text' placeholder="Enter url" 
                          onChange={(e)=>this.props.handleOnImageLinks(e, "url")}
                          />
                      </div>
                      : ""
                    }
                    {(images_links?.length <= 9 && imagesLinksFlag) ?
                      <div className="col-12 col-sm-2 px-0">
                        <button type="button"
                          className="main-btn font-14 mob-w-100 cursor-pointer w-100"
                          onClick={ () =>  this.props.handleAppend("Add url")}
                          >
                          Save
                        </button>
                      </div> : "" } 
                  </div>
                    <div className='row'>
                      { (images_links?.length <= 9 && selectedType?.label === "Add files") ?
                          <div className="col-12 px-0">                                    
                            <div className="form-group mb-3 col-sm-12 pl-0 mob-p-0 ipad-pr-0">
                              <label className="label font-weight-600 font-14 mob-font-13 w-100 pt-3 pb-2" htmlFor="first">Upload your files</label>
                              <div className="upload-btn-wrapper w-100" >
                                <button className="btn upload-file w-100 font-13 font-weight-500 text-secondary">
                                  <span className="font-18 mob-font-13 d-block text-success"> 
                                    {
                                      (imgFile && imgFile?.name.split(".")[0]) && !filesUploading ? 
                                      <span className="complete-check text-center d-block mb-1">
                                        <i className="fas fa-check bg-success text-white"></i>
                                      </span> : 
                                      filesUploading ? 
                                      <div className="btn-loader-wrapper">
                                        <div className="btn-loader resume_upload"></div>
                                      </div> : 
                                      <img src={require("../../../../Assets/images/upload-icon.png")} 
                                      className="d-block m-auto"width="60" alt="Indiefolio"
                                      />
                                    } 
                                    {
                                    filesUploading ? 'Uploading image ...'
                                      : 
                                      imgFile ? imgFile?.name.split(".")[0] : 'Browse folders here'
                                      }
                                  </span>
                                  {"Supports the following file formats: jpg, jpeg, png, gif, pdf, .doc, .docx, .ppt"}
                                </button>

                                <input type="file" name="myfile" accept='image/*'  multiple  onChange={(e) => this.props.handleOnImageLinks(e,"files")}
                                  />
                              </div>
                            </div>
                          </div>:
                            ""
                      }

                    </div> 
                </div> }
                {
                  images_links?.length > 0 && 
                  <div className="col-sm-12 uploading-image review_milestone_uploading px-0">
                    <div className="upload-thumbnail mb-2">
                    {
                        images_links?.length > 0 && images_links?.map((obj, index) => {
                            const data = ['gif', 'jpg', 'jpeg', 'png']
                            if(obj.type === "files"){
                              return(                                
                                <span
                                  className="upload-thumb"
                                  draggable="true"
                                  style={{ opacity: 1 }}
                                >
                                  <div className="upload-delete">
                                    <i className="far fa fa-times" onClick={()=>this.props.handleRemove(index)}/>
                                  </div>
                                  <span
                                    className="upload_image_thumbnail"
                                    style={{backgroundImage:`url("${
                                      handleFileType(obj?.name, ChatImageBaseUrl)
                                      // data?.includes(obj?.name?.split('.')[1]) ? 
                                      // ChatImageBaseUrl + obj.name :
                                      // obj?.name?.includes(".pdf") ?
                                      // PdfImage : 
                                      // obj?.name?.includes(".zip")?
                                      // ZipImage :
                                      // obj?.name?.includes(".ppt")?
                                      // PPtImage:
                                      // DocImage
                                    }")`}}
                                  />
                                </span>                                 
                              )
                            }  else{
                              count++
                                return(                                
                                  <span
                                    className="upload-thumb"
                                    draggable="true"
                                    style={{ opacity: 1 }}
                                  >
                                    <div className="upload-delete">
                                      <i className="far fa fa-times" onClick={()=>this.props.handleRemove(index)}/>
                                    </div>
                                    <a href={obj?.name} target="_blank" key={index} style={{display:"contents"}}>
                                      <span
                                        className="upload_image_thumbnail"
                                        style={{backgroundImage:`url(${LinkImage})`}}
                                      />
                                    </a>
                                    
                                  </span>                      
                                )
                            }                              
                        })
                      }
                    </div>
                  </div> 
                }

                {/* Add image or url Exit */}

                <div className="col-sm-12 p-0">
                  <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block">Add notes for the client</span>
                </div>
                <div className="col-sm-12 p-0 mb-3">
                  <div className="d-flex align-items-center">
                    <textarea className="form-control w-100 email" id="comment"   value={notes} name="notes" onChange={this.props.handleChange} placeholder="Please add any additional notes, points or disclaimers here"/>
                  </div>
                </div>
              </div>
            </div>
            {/* mobile Section */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer client-cost mobile-ipad-show mob-p-20">
              <div className="row m-0 mb-0 align-items-center">
                <div className="col-8 d-flex align-items-center p-0">
                  <div className="row w-100">
                    <div className="col-6 pr-0">
                      <span className="d-block font-12 text-sub font-weight-500">Client's cost</span>
                      {/* <span className="font-18 mob-font-14 text-dark-black font-weight-600">
                      {totalCost ?<span className="position-relative">
                          <i className="fas fa-rupee-sign font-16 mob-font-13" /> {separateNumber(totalCost)}
                          {" "}<i className="fas fa-info-circle text-sub-8 font-16 mob-font-12 small-font-10" data-toggle="modal" data-target="#milestonesBreakdown" />
                        </span> : null}
                      </span> */}
                      <span className="font-18 mob-font-14 text-dark-black font-weight-600 position-relative mobile_proposal_cost">
                        <i className="fas fa-rupee-sign font-16 mob-font-13" /> {separateNumber(totalCost)}
                        <span className="tool" data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."><i className="fas fa-info-circle font-13 ml-1"></i></span>
                      </span> 
                    </div>
                    <div className="col-6 project-delivery-date position-relative pr-0">
                      <span className="d-block font-12 text-sub font-weight-500">Your earnings</span>
                      {totalEarning ? <span className="font-18 mob-font-14 text-dark-black font-weight-600 text-upparcase"><i className="fas fa-rupee-sign font-16 mob-font-13" /> {separateNumber(totalEarning)}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="col-4 text-right p-0">
                  <button type="button" 
                          className="main-btn font-14 py-12 cursor-pointer small-font-12" 
                          disabled={submitting}
                          onClick={this.props.handleSubmit}>{submitting ? 'Submitting...' : this.props?.checkHasApplied ? 'Make Changes' : 'Submit'} <i className="fas fa-arrow-right" /></button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
