import { checkAuthentication } from "../../utils/Helperfunctions";
import {
  UPDATE_UPLOADING,
  UPDATE_SAVING,
  UPDATE_LOADING,
  UPDATE_FORMOPEN,
  UPDATE_LOAD_LOGGEDIN_USER,
  UPDATE_PROFILE_IMAGE,
  APPROVE_USER_STATUS,
  UPDATE_PROJECT_RATING_STATUS,
  UPDATE_SUBMITTING,
  UPDATE_POST_PROJECT_DETAILS_FOR_REVIEW,
  UPDATE_PROFILE_LOADED,
  UPDATE_MESSAGE_NOTIFICATION_COUNT,
  UPDATE_DASHBOARD_NOTIFICATION_COUNT,
  UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT,
  RESET_LOGGED_IN_STATE,
  CHANGE_USERNAME,
  UPDATE_REVIEW_STATUS,
} from "../type.action";

const initState = {
  saving: false,
  submitting: false,
  uploading: false,
  formOpen: false,
  loading: true,
  Me: {
    isAuth: false,
    CurrentRole: 0,
    Email: '',
    EmailStatus: '',
    FirstName: '',
    LastName: '',
    ManualLogin: '',
    OnboardingStatus: '',
    PhoneStatus: '',
    PricingVisibility: '',
    ProfileImage: '',
    ProfileVisibility: '',
    ReviewStatus: '',
    ApprovedStatus: '',
    Role: '',
    Uid: '',
    Username: '',
    ProjectRatingStatus: null,
    PostProjectId: '',
    PostProjectDetailsForReview: null,
    isProfileLoaded: false,
    messageNotificationCount: 0,
    dashboardNotificationCount: 0,
    completedProjectNotificationCount: 0,
  }
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};
  switch (type) {
    case UPDATE_UPLOADING:
      newState = Object.assign({}, state, {
        uploading: values,
      });
      return newState;

    case UPDATE_SAVING:
      newState = Object.assign({}, state, {
        saving: values,
      });
      return newState;

    case UPDATE_LOADING:
      newState = Object.assign({}, state, {
        loading: values,
      });
      return newState;

    case UPDATE_SUBMITTING:
      newState = Object.assign({}, state, {
        submitting: values,
      });
      return newState;

    case UPDATE_FORMOPEN:
      newState = Object.assign({}, state, {
        formOpen: values,
      });
      return newState;
    case UPDATE_REVIEW_STATUS:
      console.log(values)
      const Me6 = Object.assign({}, state.Me, {
        ReviewStatus:values  
      });
      newState = Object.assign({}, state, {Me:Me6})
      console.log(newState)
      return newState;

    case UPDATE_LOAD_LOGGEDIN_USER:
      newState = Object.assign({}, state, {
        Me: {
          time_base_alert_date:values.time_base_alert_date,
          isAdmin: values.is_admin,
          isAuth: checkAuthentication(values),
          CurrentRole: values.current_set_role,
          Email: values.email,
          EmailStatus: values.email_status,
          FirstName: values.first_name,
          LastName: values.last_name,
          ManualLogin: values.manual_login,
          OnboardingStatus: values.onboarding_status,
          PhoneStatus: values.phone_status,
          PricingVisibility: values.pricing_visibility,
          ProfileImage: values.profile_image,
          ProfileVisibility: values.profile_visibility,
          ReviewStatus: values.review_status,
          ApprovedStatus: values.approved_status,
          Role: values.role,
          Uid: values.uid,
          Id: values._id,
          Username: values.username,
          ProjectRatingStatus: values.project_rating_status,
          PostProjectId: values.post_project_id,
          PostProjectDetailsForReview: null,
          isProfileLoaded: true,
          messageNotificationCount: values.current_set_role === 1 ? values.client_message_count : values.creator_message_count,
          dashboardNotificationCount: values.current_set_role === 1 ? values.client_dashboard : values.creator_dashboard,
          update_status:values.update_status
        },
      });
      // add extra key:value when it is creator
      if(values.current_set_role === 2) {
        newState.Me.isAvailable = values.available
        newState.Me.availableDate = values.available_date
      }

      return newState;
      case UPDATE_PROFILE_IMAGE:
        const Me = Object.assign({}, state.Me, {
          ProfileImage: values  
        });
        newState = Object.assign({}, state, {Me})
        return newState;

      case APPROVE_USER_STATUS:
        // console.log(values, 'approved')
        const newMe = Object.assign({}, state.Me, {
          ApprovedStatus: values
        });
        newState = Object.assign({}, state, {Me:newMe})
        return newState;

      case UPDATE_PROJECT_RATING_STATUS:
        // console.log(values, 'approved')
        const newMe1 = Object.assign({}, state.Me, {
          ProjectRatingStatus: values,
          PostProjectId: null,
          PostProjectDetailsForReview: null,
        });
        newState = Object.assign({}, state, {Me:newMe1})
        return newState;

      case UPDATE_POST_PROJECT_DETAILS_FOR_REVIEW:
        // console.log(values, 'approved')
        const newMe2 = Object.assign({}, state.Me, {
          PostProjectDetailsForReview: values,
        });
        newState = Object.assign({}, state, {Me:newMe2})
        return newState;

      case UPDATE_PROFILE_LOADED:
        return {
          ...state,
          Me: {...state.Me, isProfileLoaded: values}
        }

      case UPDATE_MESSAGE_NOTIFICATION_COUNT:
        return {
          ...state,
          Me: {
            ...state.Me,
            messageNotificationCount: values
          },
        }
      case UPDATE_DASHBOARD_NOTIFICATION_COUNT:
        return {
          ...state,
          Me: {
            ...state.Me,
            dashboardNotificationCount: values
          },
        }
      case UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT:
        return {
          ...state,
          Me: {
            ...state.Me,
            completedProjectNotificationCount: values
          },
        }

      case RESET_LOGGED_IN_STATE:
          return {
            ...state,
            ...initState,
          }

      case CHANGE_USERNAME:
          return {
            ...state,
            Me: {
              ...state.Me,
              Username: values
            }
          }


    default:
      return state;
  }
};

export default reducers;