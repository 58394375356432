import React from 'react';
import { connect } from 'react-redux';


// import $ from 'jquery';

import { putAPI } from '../../../../../utils/API';
// budget_subtext
class CallConfirmed extends React.Component {
	state = {
		project_id: ''
	}
//invitee_full_name=testnischay%20rawat&invitee_email=testnischay.rawat%40cliffex.com	

	componentDidMount() {
		const url_string = window.location.href;
		const url = new URL(url_string);

		const event_start_time = url.searchParams.get('event_start_time');
		const event_end_time=url.searchParams.get('event_end_time')
		const invitee_full_name=url.searchParams.get('invitee_full_name')
		const invitee_email=url.searchParams.get('invitee_email')
		const projectid = localStorage.getItem('postProjectId');
		if (projectid) {

			putAPI('/project/post-project-status', {
				post_project_id: projectid,
				status: 11
			}).then(res => {
				this.setState({ project_id: projectid })
				putAPI('/project/post-project-meeting-schedule', {
					_id: projectid,
					dateTime: event_start_time,
					endTime:event_end_time,
					full_name:invitee_full_name,
					email:invitee_email
				}).then(res => {

					localStorage.removeItem('postProjectId')
				}).catch(ex => alert('Something went wrong'))

			}).catch(ex => {
				localStorage.removeItem('postProjectId')

				// alert('page expired')
			})
		} else {
			alert('page expired')
			this.props.history.push('/dashboard')
		}


	}
	render() {
		const url_string = window.location.href;
		const url = new URL(url_string);

		const event_start_time = url.searchParams.get('event_start_time');
		const date=new Date(event_start_time)

		return (
			<>
				<div className="call_confirmed">
					<div className="call_wrapper">
						<div className="text-center"><i className="far fa-check-circle"></i></div>
						<h1 className="text-center">“Congratulations, <br />your call has been confirmed”</h1>
						<p className="text-center text-dark font-weight-600">Date : <span className="text-sub font-weight-500">{date.toLocaleDateString()}</span>  Time : <span className="text-sub font-weight-500">{date.toLocaleTimeString()}</span></p>
						<div className="notes my-4">

							<p>You can now:</p>
							<p>1. View some of the recommended talent</p>
							<p>2. Add further project details</p>
							<p>3. Do nothing and let our Account Managers work their magic</p>
						</div>
						<div className="text-center">
							<button onClick={e => this.props.history.push('/dashboard')} className="main-btn">Go to dashboard </button>
							<button onClick={e => this.props.history.push({ pathname: '/profile_selection', state: { project_id: this.state.project_id, } })} className="main-btn ml-3">Browse Talent </button>
						</div>
					</div>
				</div>
			</>
		)

	}
}

const mapState = (store) => {
	// let postProjectData = store.postProject;
	// let globalReducer = store.globalReducer;
	// return {
	// 	projectStatus:postProjectData.projectStatus,
	// 	subType:postProjectData.subType,
	// 	projectId: postProjectData.projectId,
	// 	minBudget: postProjectData.minBudget,
	// 	maxBudget: postProjectData.maxBudget,
	// 	budgetChanged: postProjectData.budgetChanged,
	// 	budget_heading: (postProjectData?.budget_heading !== undefined ) ? postProjectData.budget_heading :postProjectData?.CollateralQuestions?.collateral?.budget_heading,
	// 	budget_subtext: (postProjectData?.budget_subtext !== undefined ) ? postProjectData.budget_subtext :postProjectData?.CollateralQuestions?.collateral?.budget_subtext,
	// 	pricingUnit: postProjectData?.CollateralQuestions?.collateral?.pricing_unit,
	//     typeQuestions: (postProjectData?.typeQuestions?.length > 0) ?  (postProjectData.typeQuestions) : postProjectData?.CollateralQuestions?.Collateral_type_question,
	// 	post_steps: postProjectData.post_steps,
	// 	total_steps: postProjectData.total_steps,
	// 	alreadyCreatedProject: postProjectData.alreadyCreatedProject,
	// 	userName: globalReducer.Me.Username,
	// };
}

const mapDispatch = dispatchEvent => ({
	// OnFieldChange: (name, value) => dispatchEvent({ type: 'ON_FIELD_CHANGE_POST_PROJECT', payload: { name: name, value: value } }),
	// PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
	// budgetChangedEvent: (val) => dispatchEvent({ type: "BUDGET_CHANGED", values: val }),
});
export default connect(mapState, mapDispatch)(CallConfirmed);