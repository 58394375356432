import React from 'react';

const BootstrapLoader = ({height, hideDesktop, smallSize}) => {
    return (
        <div className={`col-12 text-center ${ hideDesktop ? 'mobile-ipad-show' : ''}`} style={{ top: height ? height : '40vh' }}>
            <div className={`${smallSize ? 'spinner-border spinner-border-sm' : 'spinner-border'} `} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default BootstrapLoader;