import React from 'react'
import { connect } from 'react-redux';
import { separateNumber } from '../../../utils/Helperfunctions';
import { getDateTime } from '../../../utils/DateMethod'

function Milestones(props) {

    const openLatestMilestone = (id) => {
        // console.log(id)
        props.LoadLatestMilestone(id)
        props.OpenPopup('ReviewDeliverable')
    }

    const isAdminProject = props.ProjectType === 'post_project_admin' 
    const isRepresentative = props.CurrentChat.user_role === 'representative'

    return (
        <>
        <div className="card-wrapper mb-10">
            <div className="card">
                <div className="card-body px-2 py-3">
                <div className="row m-0 d-flex">
                    <div className="col-sm-12 border-bottom-1 pb-3 dflex">
                    <h2 className="text-dark-black font-14 font-weight-600 m-0">Milestones</h2>
                    {/* for client */}
                    { props.proposalStatus === 2  ? null :
                        props.ProjectStatus === 6  && props.CurrentRole === 1 ? 
                            <div>
                                <a href="#/" className="text-primary font-12 font-weight-600 d-block"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    props.OpenPopup('ReviewProposal')
                                    props.SetCurrentProposal(props.proposal._id,props.proposal.post_project_id);
                                }}
                                >Request Changes <i className="fas fa-arrow-right"></i></a>
                            </div> : null
                    }
                    { props.proposalStatus === 2 || isAdminProject  ? null :
                    <>
                        {props.ProjectStatus === 6 && props.CurrentRole === 2 ?
                        props.proposal.update_status === 0  ?
                        <div className="">
                            <a href="#/" className="text-primary font-12 font-weight-600 pt-3"
                                onClick={(e)=>{
                                e.preventDefault();
                                props.SetCurrentProposal(props.proposal._id,props.proposal.post_project_id);
                                // props.OpenPopup('ReviewUpdatedProposal');
                                props.OpenPopup('ReviewMilestone');
                                }}
                            >View recently sent proposal <i className="fas fa-arrow-right"></i></a>
                        </div> :
                        <div>
                            <a href="#/" className="text-primary font-12 font-weight-600 d-block"
                            onClick={(e)=>{
                                e.preventDefault();
                                props.SetCurrentProposal(props.proposal._id,props.proposal.post_project_id);
                                props.OpenPopup('ReviewProposal');
                            }}
                            >View Proposal <i className="fas fa-arrow-right"></i></a>
                        </div>
                    : null}
                    </>
                    }
                    </div>
                    
                </div>
                <div className="row m-0">
                    <div className="col-sm-12 pt-3">
                    <div className="timeline-due-date page">
                        <div className="timeline">
                        <div className="timeline__group">
                            {props.milestones && props.milestones.map((items, k) => {
                             //is milestone delay more than 48 hrs 
                            const dueDate = new Date(items.due_date)
                            dueDate.setDate(dueDate.getDate() + 2)
                            const isMilestoneDelay = dueDate < new Date() && !items.submitted_iteration
                            const marginClass = items.submitted_iteration ? 'mb-0' : '' 
                            return (
                                (items.type === "token") ?
                                <div key={k} className="timeline__box mb-30">
                                    <div className="timeline__date bg-tranparent">
                                    <span><i className="fas fa-info-circle text-secondary font-30 pt-15 mob-p-0"></i></span>
                                    </div>
                                    <div className="timeline__post">
                                    <div className="timeline__content active">
                                        <p className="mb-0 font-12 font-weight-600">Project - Advance Fees</p>
                                        <div className="dflex">
                                        <h2 className="milestone-headline mb-1 font-weight-600 font-12">non-refundable fee</h2>
                                        <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12"></i>{separateNumber(items.price)}</span>
                                        </div>
                                    </div>
                                    </div>
                                    <span className="vertical-line advance-payment"></span>
                                </div>
                                :
                                <div key={k} className={`timeline__box mb-36 ${items.status === 2 ? 'disabled' : ''}`}>
                                <div className={`timeline__date   ${items._id === props.active_milestones || items.status === 2 ? 'active' : ''}`}>
                                    <span className={`due-date ${items._id === props.active_milestones || items.status === 2 ? 'active' : ''}`}>DUE DATE</span>
                                    <span className={`timeline__day`}>{getDateTime(items.due_date,"day")}</span>
                                    <span className="timeline__month">{getDateTime(items.due_date,"month")}</span>
                                </div>
                                <div className="timeline__post">
                                    <div className="timeline__content active">
                                    <div className="dflex">
                                        <h2 className={`milestone-headline mb-1 font-weight-600 font-13`}>{items.name.length > 25 ? items.name.substring(0,25) + '...' : items.name}</h2>
                                        {!isAdminProject ? <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12"></i>{separateNumber(items.price)}</span> : null}
                                    </div>
                                    <div className="dflex">
                                        <p className={`${marginClass} milestone-headline font-11 font-weight-600 ${items.status === 2 ? 'active' : ''}`}>Milestone {items.serial_no}</p>
                                        { items.status === 1 && isMilestoneDelay ? 
                                        <p className={`${marginClass} font-11 font-weight-600 text-yellow`}><i className="fas fa-exclamation-triangle"></i> Delayed </p>
                                        : <p className={` ${marginClass} font-11 font-weight-600`}>Iterations: {items.iteration}</p>
                                        }
                                        
                                    </div>
                                    {items.submitted_iteration ? 
                                        <div onClick={() => openLatestMilestone(items._id)} className="mb-2 text-primary milestone-headline font-11 font-weight-600 cursor-pointer">View {items.status === 2 ? 'Confirmed' : 'Latest'} Deliverable</div>
                                     : null
                                    }
                                    </div>
                                </div>
                                <span className={`vertical-line ${k+1 === props.milestones.length ? 'hidden' : ''}`}></span>
                                </div>
                                )}
                            )}
                            {props.milestones && props.milestones.length ? 
                            <div className="row m-0 bor-bottom bor-top py-3">
                                <div className={`col-sm-12 d-flex justify-content-between creator-cost mob-pr-0 ${(isAdminProject && props.CurrentRole === 2 && !isRepresentative) ? 'admin-project' : '' }`}>
                                {(isAdminProject && props.CurrentRole === 2 && !isRepresentative) ?  
                                    null :
                                    <div className="w-50">
                                        <span className="d-block font-12 text-sub font-weight-500">{props.CurrentRole === 2 ? "Project Quote" : "Project Quote"}</span>
                                        <span className="font-16 text-dark-black font-weight-600 position-relative"><i className="fas fa-rupee-sign font-14"></i>{separateNumber(props.proposal.sub_total_cost)} <span className="tool pre-line" data-tip={`Sub Total: Rs ${props.proposal.sub_total_cost} 
                                        GST @ 18%: Rs ${props.proposal.tax}
                                        Total Project Cost for Client: Rs ${props.proposal.total_cost}`}><i className="fas fa-info-circle text-secondary font-12"></i></span></span>
                                        <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span>
                                    </div>
                                }
                                {props.CurrentRole === 2 || isRepresentative ? <div className="w-50 project-delivery-date position-relative pl-3">
                                    <span className="d-block font-12 text-sub font-weight-500">{isRepresentative ? 'Creator' : 'Your'} earnings</span>
                                    <span className="font-16 text-dark-black font-weight-600"><i className="fas fa-rupee-sign font-14"></i>{separateNumber(props.proposal?.total_earning)} </span>
                                </div> : 
                                <div className="w-50 project-delivery-date position-relative pl-3">
                                    <span className="d-block font-12 text-sub font-weight-500">Project Completion Date</span>
                                    <span className="font-16 text-dark-black font-weight-600 text-capitalize">{getDateTime(props.milestones[props.milestones.length -1].due_date, 'dateMonth') } </span>
                                </div>
                                }
                                </div>
                            </div>
                            : null}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* props.proposal.update_status === 0 */}
                {props.proposalStatus === 2 ? null : 
                    <>
                    {/* {props.ProjectStatus === 6 && props.CurrentRole == 2 && props.proposal.update_status === 1 && !isAdminProject ? */}
                    {props.ProjectStatus === 6 && props.CurrentRole === 2 && !isAdminProject ?
                        <div className="row m-0">
                        <div className="col-sm-12 text-right">
                            <a href="#/" className="text-primary font-12 font-weight-600 d-block pt-3"
                            onClick={(e)=>{
                                e.preventDefault();
                                props.OpenPopup("ReviewMilestone");
                            }}
                            >Make changes <i className="fas fa-arrow-right"></i></a>
                        </div>
                        </div>
                    : <></>}
                    </>
                }
                </div>
            </div>
            </div>
        </>
    )
}


const mapState = (store) => {
    let chat = store.chat;
    let globalReducer = store.globalReducer;
    const postProject = store.postProject
    return {
      Artist: chat.Artist,
      Projects: chat.Projects,
      ProjectId: chat.ProjectId,
      ProjectName: chat.ProjectName,
      ProjectStatus: chat.ProjectStatus,
      Messages: chat.Messages,
      CurrentChat: chat.CurrentChat,
      ProjectType: chat.ProjectType,
      isLastMilestonePaymentDone: chat.isLastMilestonePaymentDone,
      isMultipleProposal: chat.isMultipleProposal,
  
      CurrentRole:globalReducer.Me.CurrentRole,
  
      milestones: postProject.milestones,
      proposal: postProject.proposal,
      active_milestones: postProject.active_milestones,
      delivered_status: postProject.delivered_status,
      user_data: postProject.user,
      CurrentMilestone: postProject.CurrentMilestone,
      proposalStatus: postProject.proposalStatus,
      loading: globalReducer.loading,
  
    };
};

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    SetCurrentProposal: (proposal_id,project_id,reviewStatus) => dispatch({ type: 'SET_CURRENT_PROPOSAL', payload: {proposal_id,project_id,reviewStatus}}),
    LoadLatestMilestone: (id) => dispatch({type: 'LOAD_LATEST_MILESTONE', payload: id})
  });

  export default connect(mapState, mapDispatch)(Milestones)
