import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getAPI, postAPI, putAPI } from "../../../../../utils/API";
import PriceImage from "../../../../../Assets/upgrade-images/submit_proposal/price.svg";
import Loader from "../../../Common/Common/Loader";
import DatePicker from "react-date-picker";
import { addHrMinDate, setTimeToZero } from "../CommonFunctionsNew/CommonFunctionsNew";

export const RetainerProjectFormPop = (props) => {
  const [states, setStates] = useState(props.addExtData);
  const [loading, setLoading] = useState(true);
  const [minExtStartDate, setMinExtStartDate] = useState(setTimeToZero(new Date()));
  const [loader, setLoader] = useState(false);
  const { projectId } = props?.match?.params;

  useEffect(() => {
    let endDate = props.array[props.array.length - 1].end_date;
    let minimumDate = new Date(endDate);
    minimumDate.setDate(minimumDate.getDate() + 1);
    if(states.noAlreadyData){
      states.proposed_start_date = setTimeToZero(minimumDate);
    }
    setMinExtStartDate(setTimeToZero(minimumDate))
    setStates({ ...states });
  },[])


  const handleChangeNote = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length < 300) {
      setStates({
        ...states,
        note: value,
      });
    }
  };

  const handleNumbers = (action, which, e) => {
    let numbers = "";
    if (which === "duration") numbers = states.propDuration.prop_dur_num;
    else numbers = states.numOfHours.num_hour;
    if (action === "plus") {
      if (which === "duration") numbers == 0 ? (states.propDuration.prop_dur_num = 1) : (states.propDuration.prop_dur_num += 1);
      else numbers == 0 ? (states.numOfHours.num_hour = 1) : (states.numOfHours.num_hour += 1);
    } else if (action === "change") {
      if (which === "duration") states.propDuration.prop_dur_num = e.target.value > 0 ? Number(e.target.value) : e.target.value;
      else states.numOfHours.num_hour = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (which === "duration") {
        if (states.propDuration.prop_dur_num > 1) states.propDuration.prop_dur_num -= 1;
      } else {
        if (states.numOfHours.num_hour > 1) states.numOfHours.num_hour -= 1;
      }
    }
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    states.validation.num_hour = states.numOfHours.num_hour < 1 ? true : false;
    if (which === "duration") setStates({ ...states, propDuration: { ...states.propDuration } });
    else setStates({ ...states, numOfHours: { ...states.numOfHours } });
  };

  const handleChangeFees = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    states.fees.retainerFees = value;
    states.validation.retainerFees = states.fees.retainerFees < 1 ? true : false;
    setStates({
      ...states,
      fees: { ...states.fees, [name]: value },
    });
  };

  const handleSelect = (selected, which) => {
    if (which === "duration")
      setStates({
        ...states,
        propDuration: { ...states.propDuration, prop_duration: selected },
      });
    else
      setStates({
        ...states,
        numOfHours: { ...states.numOfHours, day_week: selected },
      });
  };

  const handleValidation = () => {
    let valid = true;
    if (states?.fees?.retainerFees < 1 || states?.propDuration?.prop_dur_num < 1 || states?.numOfHours?.num_hour < 1)
      valid = false;
    states.validation.retainerFees = states?.fees?.retainerFees < 1 ? true : false;
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    states.validation.num_hour = states?.numOfHours?.num_hour < 1 ? true : false;
    setStates({ ...states });
    return valid;
  };

  const handleSubmitApi = async () => {
    setLoader(true);
    let data = {
      post_project_id: projectId,
      proposal_type: "retainer",
      note: states.note,
      proposed_duration_type: states.propDuration.prop_duration.value,
      proposed_duration: states.propDuration.prop_dur_num,
      proposal_id: props.state?.proposalData?._id,
      tax: parseInt((total_price * 0.18).toFixed(2)),
      number_of_hours_type: states.numOfHours.day_week.value,
      number_of_hours: states.numOfHours.num_hour,
      retainer_fees: total_price,
      total_cost: gst_total_price,
      sub_total_cost: total_price,
      total_earning: totalEarning,
      access_key: props.CurrentRole,
      proposed_start_date: addHrMinDate(states.proposed_start_date,5,30)
    };

    // console.log("data = ", data);
    try {
      let res = "";
      if (props.isaddExt) {
        res = await postAPI("/proposal/extend-proposal-retainer", data);
      } else {
        data.approvedproposal_id = props.state?.proposalData?._id;
        data.proposal_id = states?.allData?._id;
        res = await putAPI("/proposal/put-extend-proposal-retainer", data);
      }
      // console.log("res = ", res);
      if (res?.data) {
        setLoader(false);
        props.setRetainerPopupForm(false);
        props.handleGetAddExtData();
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };

  const handleChangeDate = (e) => {
    states.proposed_start_date = new Date(e);
    setStates({ ...states });
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setRetainerPopupForm(false);
    }
  };

  let total_price = Number(states.fees.retainerFees);
  let gst_total_price = 0;
  gst_total_price = Number(total_price + total_price * 0.18);

  let totalEarning = 0;
  totalEarning = Number(total_price * 0.85);

  return (
    <>
      <section className="worklogextensionrequestwrapper">
        <div
          className={`modal fade modal_fade_bg ${props.retainerPopupForm ? "show" : ""}`}
          id="worklogextensionrequestpopup"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props.retainerPopupForm ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_md m-auto">
            <div className="modal-content">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h5 className="modal-title main_modal_title mb-3" id="exampleModalLabel">
                    Request Extension
                  </h5>
                </div>
              </div>
              <div className="modal-body common_overflow_height">
                <div className="br_btm duration px-0 mx-4 mb-3">
                  <div className="row proposal_date pb-4 mx-0">
                    <div className="proposal_date_title col-lg-12 mb-3 px-1">
                      <p className="mb-0 font_semi_bold_w_600">Duration</p>
                      <span className="proposal_date_sub_title">
                        Enter the start till which you would like to extend the project
                      </span>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center px-1 column_gap_20 ">
                      <div className="date_input">
                        <DatePicker
                          value={states?.proposed_start_date ? new Date(states?.proposed_start_date):'' }
                          clearIcon={null}
                          name="startDate"
                          format="dd/MM/yyyy"
                          calendarClassName="apniClass"
                          showLeadingZeros={true}
                          onChange={handleChangeDate}
                          minDate={minExtStartDate}
                          className={`form-control text-center input_sm`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="br_btm duration px-0 mx-4 mb-3">
                  <div className="row proposal_date pb-4 mx-0">
                    <div className="proposal_date_title col-lg-12 mb-3 px-1">
                      <p className="mb-0 font_semi_bold_w_600">Monthly commitment</p>
                      <span className="proposal_date_sub_title">
                        Enter the amount of months would be expecting per month
                      </span>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center px-1 column_gap_20 ">
                      <div className="btn_field number_btn">
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "duration")}>
                            -
                          </button>
                          <input
                            type="number"
                            class="form-control text-center input_sm"
                            value={states.propDuration.prop_dur_num}
                            onKeyDown={(evt) =>
                              (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                              evt.preventDefault()
                            }
                            onChange={(e) => handleNumbers("change", "duration", e)}
                          />
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "duration")}>
                            +
                          </button>
                        </div>
                        {states.validation.prop_dur_num ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div className="dropedown_field select_dropdown mr-4">
                        <Select
                          options={[{ label: "/Month", value: "Month" }]}
                          value={states.propDuration.prop_duration}
                          //  onChange={(e) => handleSelect(e, "duration")}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="br_btm duration px-0 mx-4 mb-3">
                  <div className="row proposal_date pb-4 mx-0">
                    <div className="proposal_date_title col-lg-12 mb-3 px-1">
                      <p className="mb-0 font_semi_bold_w_600">Hourly commitment</p>
                      <span className="proposal_date_sub_title">Enter the amount of hours would be expecting per week/day</span>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center px-1 column_gap_20 ">
                      <div className="btn_field number_btn">
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "numHours")}>
                            -
                          </button>
                          <input
                            type="number"
                            class="form-control text-center input_sm"
                            onChange={(e) => handleNumbers("change", "numHours", e)}
                            onKeyDown={(evt) =>
                              (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                              evt.preventDefault()
                            }
                            value={states.numOfHours.num_hour}
                          />
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "numHours")}>
                            +
                          </button>
                        </div>
                        {states.validation.num_hour ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div className="dropedown_field select_dropdown mr-4">
                        <Select
                          options={[
                            { label: "/Day", value: "Day" },
                            { label: "/Week", value: "Week" },
                          ]}
                          value={states.numOfHours.day_week}
                          onChange={(e) => handleSelect(e, "numHours")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="br_btm duration px-0 mx-4 mb-3">
                  <div className="row proposal_date pb-4 mx-0">
                    <div className="proposal_date_title col-lg-12 mb-3 px-1">
                      <p className="mb-0 font_semi_bold_w_600">Retainer Fees</p>
                      <span className="proposal_date_sub_title">Enter the amount you will be charging per week/per month</span>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center px-1 column_gap_20 ">
                      <div className="price_input">
                        <input
                          type="number"
                          class={`form-control input_sm input_sm_height ${states.validation.retainerFees ? "border-danger" : ""}`}
                          placeholder="Price"
                          value={states.fees.retainerFees}
                          name="retainerFees"
                          onChange={handleChangeFees}
                        />
                        <img src={PriceImage} className={`price_icon img-fluid`}></img>
                      </div>
                      {states.validation.retainerFees ? <p className="error_message">field required</p> : ""}

                      <div className="dropedown_field select_dropdown mr-4">
                        <Select
                          options={[
                            // { label: "Week", value: "Week" },
                            { label: "Month", value: "Month" },
                          ]}
                          // className="mob-min-w-170 mob-mb-10"
                          value={states.propDuration.prop_duration}
                          //  onChange={(e) => handleSelect(e, "duration")}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* add notes */}
                <div className="px-0 mx-4 mb-3">
                  <div className="row add_notes mx-0">
                    <div className="col-lg-12 px-1">
                      <p className="mb-0 font_semi_bold_w_600">
                        Additional Notes <span className="text_light_gray_md">(Optional)</span>
                      </p>
                    </div>
                    <div className="col-lg-12 px-1 py-4">
                      <textarea
                        class={`form-control upload_notes`}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="Please add any additional notes, points or disclaimers here"
                        value={states.note}
                        onChange={handleChangeNote}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-end align-items-center py-4">
                <div className="col-lg-12 text-right">
                  <button type="button" class="btn btn_outline_md fw_700 mr-4" onClick={() => props.setRetainerPopupForm(false)}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn_common_md fw_700"
                    onClick={() => {
                      let result = handleValidation() ? handleSubmitApi() : "";
                    }}
                  >
                    {loader ? <Loader isCreateModal={true} /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
