import React from "react";
import { connect } from "react-redux";
import { putAPI } from "../../../../utils/API";

class Availability extends React.Component {
  state = {
    freelance_hour: 0,
    freelance_current_status: 0,
    errorMsg: "",
  };

  handleAvailabilityHours = async () => {
    let { freelance_hour, freelance_current_status } = this.state;
    try {
      const response = await putAPI("/users/user-freelance", {
        freelance_hour,
        freelance_current_status,
      });
      console.log(response, "this is the response");
      if (response.status === 200) {
        alert('Changes submitted successfully')
        this.onClose()

      } else {
        alert("Something went wrong!!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleValidate = () => {
    let { freelance_hour } = this.state;
    if (freelance_hour < 0) {
      this.setState({ errorMsg: "Invalid Input" });
    } else if (freelance_hour > 168) {
      this.setState({ errorMsg: "Maximum 168 hours allowed" });
    }
    // else if(freelance_hour==0){
    //   this.setState({errorMsg:'Required'})
    // }
    else {
      this.setState({ errorMsg: "" });
      return true;
    }
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    // this.props.UserAddCollateral({});
    if (this.handleValidate()){ 
    this.handleAvailabilityHours()}
  };

  componentDidMount() {
    this.setState({ freelance_hour: this.props.freelance_hour });
  }

  OnChange = (e) => {
    this.setState({ freelance_hour: e.target.value });
  };

  onClose = () =>{
    this.props.closePopup()
    this.props.openedFrom=='dashboard' && this.props.showPopupForDetails('TimeBasedPricing')
  }

  render() {
    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: "0px", display: "block" }}
          id="EditCurrentWorkStatus"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.onClose()}
              alt=""
              src={require("../../../../Assets/images/close.png")}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Availability</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      {/* <form method="post" action="">
                        <div className="form-group mb-4 w-100">
                          <label className="label font-weight-600 font-14 mob-font-13" htmlFor="first">Availability</label>
                          <div className="wrapper-form py-3 px-1 d-flex ">
                            <div className="form-group m-0 col-sm-4 small-pl-5">
                              <input type="number" id="post"
                                className='form-control'
                                value={this.state.freelance_hour}
                                onChange={this.OnChange}
                              />
                              <label htmlFor="post" className={`m-0 font-weight-500 mob-font-10 small-font-8 mob-font-weight-700 text-secondary active `}>Hours / Week</label>
                            </div>
                          </div>
                        </div>
                   
                      </form> */}

                      <form>
                        <div className="form-group mb-4 col-sm-12 pl-0 mob-p-0">
                          <label
                            className="mb-0 label font-weight-600 font-14 mob-font-13 position-relative"
                            htmlFor="perChaarge"
                          >
                            Number of hours you are available to freelance in
                            any given week
                          </label>
                          <div className="row work-experience onboarding_dropdown_wrapper my-3">
                            <div className="col-sm-12 position-relative d-flex align-items-center position-relative">
                              <input
                                type="number"
                                className={`form-control email price_input  mob-w-100 ${
                                  this.state.errorMsg ? "border-error" : ""
                                }
                `}
                                name="perCharge"
                                value={this.state.freelance_hour}
                                onChange={this.OnChange}
                                autoComplete="off"
                                placeholder="Number of Hours"
                              />
                              {/* Dropdown */}
                              <div className="position-relative onboarding_dropdown_option">
                                <div className="">
                                  <button
                                    className="btn btn-secondary remove-arrow dropdown-toggle cursor-default"
                                    type="button"
                                    id="dropdownMenuButton"
                                    // data-toggle="dropdown" aria-expanded="false"
                                  >
                                    {/* {time.name} */}
                                    Per Week
                                  </button>
                                  {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                   {
                    options.map((dropdownValue,index) => {
                      return <a className="dropdown-item" href="/" key={index} onClick={e => handleFunc(e, dropdownValue) }>
                        {dropdownValue.name}
                      </a>
                    })
                  }  
                </div> */}
                                </div>
                              </div>
                            </div>
                            {/* {perChargeError && (
              <span className="col-12 text-danger font-weight-600 font-12 py-1">
                Add charge to continue
              </span>
            )}   */}
                            {/* <div className="col-sm-6 creator_profile_time_price">
            {load && <div className="filter-dropdown wrapper-dropdown position-relative"> 
            <SimpleReactSelect
              options={options} 
              value={{ name: time.name }}
              selectHandler={e => handleFunc(e, 'time') }
              placeholder="Time Period"
            />
            </div>} 
          </div> */}
                          </div>
                        </div>
                      </form>
                      {this.state.errorMsg && (
                        <span className="text-danger font-weight-600 font-12 py-1">
                          {this.state.errorMsg}
                        </span>
                      )}
                    </div>
                    <div className="row mt-2 bg-shadow-top mobile-edit-collateral">
                      <div className="col-sm-12 p-0 text-right pr-3 py-3">
                        <button
                          type="button"
                          onClick={(e) => this.onClose()}
                          className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3">
                      <button type="button" onClick={(e) => this.props.closePopup()} className="main-btn bg-light-gray font-weight-600 cursor-pointer" data-dismiss="modal">Close</button> */}
                  {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                  {/* </div>
                  </div> */}

                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top d-flex justify-content-end desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3">
                      <button
                        type="button"
                        onClick={(e) => this.onSubmit(e)}
                        className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                    <div className="form-group text-right pr-3 pt-3">
                      <button
                        type="button"
                        onClick={(e) => this.onClose()}
                        className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div> */}

                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top d-flex justify-content-end desktop-ipad-show'>
                    <div className='form-group text-right pr-3 pt-3'>
                      <button
                        type='button'
                        onClick={(e) => this.onSubmit(e)}
                        className='main-btn font-weight-600 cursor-pointer'
                        data-dismiss='modal'
                      >
                        Save Changes
                      </button>
                      {/* <button type='button' className='main-btn px-4 ml-2'>Save</button> */}
                    </div>
                    <div className='form-group text-right pr-3 pt-3'>
                      <button
                        type='button'
                        onClick={() => this.onClose()}
                        className='main-btn bg-light-gray font-weight-600 cursor-pointer'
                        data-dismiss='modal'
                      >
                        Close
                      </button>
                      {/* <button type='button' className='main-btn px-4 ml-2'>Save</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? "back-modal" : ""}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;

  return {
    closeModal: creatorProfile.closeModal,
    CurrentWorkStatus: creatorProfile.CurrentWorkStatus,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: "UPDATE_SAGA_CURRENTWORKSTATUS",
      payload: { name: e.name, value: e.value },
    }),
});

export default connect(mapState, mapDispatch)(Availability);
