import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// import CreatorCard from '../../../../../unauthorized/Home/CreatorCard'
import ClientCard from './ClientCard'
import CreatorDetailsCard from './CreatorDetailsCard'

class ShowUsersCardInAdminProject extends React.Component{
    
    render(){
        const {CurrentChat, work_type, about, website, industry, company_name, client_role} = this.props

        // const isInChat = match.path === "/chat/:projectId/:projectName"
        // const isAdminProject = isInChat && ProjectType === "post_project_admin"
        // const isRepresentative = isInChat && CurrentChat.user_role === "representative"

        const clientObj = CurrentChat.member1_role === 'client' ? CurrentChat.member1 : CurrentChat.member2
        const creatorObj = CurrentChat.member1_role === 'creator' ? CurrentChat.member1 : CurrentChat.member2

        const isIndividual = work_type?.toLowerCase() === 'company' || work_type?.toLowerCase() === 'agency' ? false : true

        // const clientData = {...client, website, about, industry, isIndividual, company_name, role: client_role, }

        // const {description, company_url, company_name, role } = CurrentChat.clientCompany || {}
        const clientData = {
            first_name: clientObj.first_name, last_name: clientObj.last_name,
            profile_image: clientObj.profile_image, website, about, industry, isIndividual, company_name, role: client_role,
            }
        const creatorData = {
            first_name: creatorObj.first_name, last_name: creatorObj.last_name,
            profile_image: creatorObj.profile_image,
        }
        return (
            <>
                {CurrentChat.user_role === 'representative'? 
                    <>
                        <ClientCard data={clientData} />
                        <CreatorDetailsCard data={creatorData} />
                    </> : null}
                {CurrentChat.user_role === 'client' ? 
                        <CreatorDetailsCard data={creatorData} />
                    : null}
                {CurrentChat.user_role === 'creator'? 
                    <ClientCard data={clientData} />
                : null}
            </>
        )
    }
}

const mapState = state => {
    const dashboard = state.dashboard;
    const chat = state.chat
    return {
        ProjectType: chat.ProjectType,
        CurrentChat: chat.CurrentChat,
        client_role:dashboard.client_role,
        company_name:dashboard.company_name,
        about:dashboard.about,
        industry:dashboard.industry,
        website:dashboard.website,
        work_type:dashboard.work_type,
    }
}

export default withRouter(connect(mapState)(ShowUsersCardInAdminProject))