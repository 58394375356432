import React from 'react';
import { connect } from 'react-redux';
import { BaseImageUrl } from '../../../../../utils/BaseUrl';
// import Popup from '../../../ProfileCreator/Popup';
import { CollateralBaseUrl } from '../../../../../utils/BaseUrl';
import StyleAndTypeQuestions from './StyleAndTypeQuestions';
import CompanyAndProjectQuestions from './CompanyAndProjectQuestions';
import { withRouter } from 'react-router';
import { separateNumber, isImage, sanitiseSummernoteText } from '../../../../../utils/Helperfunctions'
// import { renderDifferentFilesThumbnail } from '../../../Common/Common/FileRenderMessage'
// import { Fragment } from 'react';
import { removeHttps, limitString } from '../../../../../utils/Helperfunctions';
import { getAPI } from '../../../../../utils/API';
import SubmitProp from '../../DashboardNew/PopopCreator/SubmitProp';
import BootstrapLoader from '../../../Common/BootstrapLoader/BootstrapLoader';
// import {getDateFormated} from '../../DashboardNew/CommonFunctionsNew/CommonFunctionsNew';
import PercentIcon from "../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import LinkIcon from "../../../../../Assets/upgrade-images/submit_proposal/url_link.svg";
import ImportIcon from "../../../../../Assets/upgrade-images/submit_proposal/import.svg";
import { downloadFilesFun, getDateFormated, handleFileName } from '../../DashboardNew/CommonFunctionsNew/CommonFunctionsNew';
class IncomingBriefReviewJob extends React.Component {

    state = {
        user_list: [],
        viewMore: false,
        redirectPop: "",
        clientData: "",
        checkFavIcon: "",
        company_add_link: "",
        company_logo:"",
        isIndividual:"",
        showSubmitProposal: '',
        toggle:1 

    }

    isImage = (name) => {
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name)
    }

    componentDidMount = () => {
        if (this.props?.paths) {
            // console.log("this.props?.paths = ",this.props?.paths)
            this.submitProposal(this.props?.paths)
        }
    }

    componentWillUnmount = () => {
        this.props.closePopup()
    }

    changeShowSubmitProposal = (value) => {
        this.setState({ showSubmitProposal: value });
    };

    submitProposal = (id) => {
        getAPI(`/collateral/has-user-in-collateral?_id=${id}`).then((res) => {
            // console.log("res = ", res.data.hasCollateral)
            let data = res?.data
            let clientData = data?.clientData
            let checkHasApplied = data?.hasApplied
            let collName = data?.collateralData?.name
            let checkFavIcon = data?.checkFavIcon
            let company_add_link = data?.company_add_link
            let company_logo = data?.company_logo
            let isIndividual = data?.company_work_type === 'company' || data?.company_work_type === 'Company' || data?.company_work_type === 'agency' || data?.company_work_type === 'Agency' ? false : true
            this.props.handleCollName(collName)
            if (data?.hasCollateral) {
                this.setState({ redirectPop: "mile", clientData,checkFavIcon,company_add_link,isIndividual,company_logo }, () => {
                    this.handlSet(checkHasApplied)
                })
            } else {
                this.setState({ redirectPop: "coll", clientData ,checkFavIcon,company_add_link,isIndividual,company_logo}, () => {
                    this.handlSet(checkHasApplied)
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({ redirectPop: "" })
        })
    }

    handlSet = (checkHasApplied) => {
        // console.log("1 === ",this.props)
        this.props.handleCheckHasApplied(false)
        if (!checkHasApplied) {
            this.props.handleCheckHasApplied(false)
        } else {
            this.props.handleCheckHasApplied(true)
        }
    }

    handleRedirect = (name) => {
        if(this.props?.checkHasApplied) return;
        if (name === "mile") {
            // this.props.OpenPopup("ReviewMilestoneJob");
            this.setState({ showSubmitProposal: true })
            // this.props.closePopup('IncomingBriefReviewJob')
        } else if (name === "coll") {
            this.props.handleJobData(this.props?.collateral_name)
            this.props.OpenPopup('CollateralJobPop')

            // this.props.OpenPopup('CollateralJob')
            // this.props.closePopup()

        }
    }

    render() {
        
        const { collateral_name, project_name, project_description, minBudget, maxBudget, deadline, style, type,
            company_name, project_indust, reference_files, reference_links, project_question,
            match, ProjectType, sub_type,dash ,deadline_date} = this.props;

        const { redirectPop,clientData,checkFavIcon,company_add_link,isIndividual } = this.state;

        const isInChat = match.path === "/chat/:projectId/:projectName"
        const isAdminProject = isInChat && ProjectType === "post_project_admin"
        const data = { project_data: dash }
        // console.log("work_type = ",this.props.work_type)
        return (
            <>
                <div className="modal fade custom_form custom_Modal_Box show" id="project-details" style={{ paddingRight: "0px", display: "block" }} role="dialog" aria-labelledby="myModalLabel">
                    <div type="button" className="close_icon cross_black" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            {<img onClick={(e) => {
                                this.props.closePopup()
                                this.props.history.push(`/dashboard/jobs`);
                            }
                            } alt="" src={require("../../../../../Assets/images/close.png")} className="img-fliud" width="35" />}
                        </span>
                    </div>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content border-0">
                            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
                                <h5 className="modal-title main_modal_title text-capitalize" id="exampleModalLabel">
                                    {project_name}
                                </h5>

                                {sub_type && sub_type === 'retainer' ?
                                    <div className="tag_btn_green_common">
                                        <p className="mb-0">
                                            <img src={PercentIconGreen} alt="" className="icon_img mr-1" />
                                            Retainer
                                        </p>
                                    </div>
                                    :
                                    <div className="tag_btn_common">
                                        <p className="mb-0">
                                            <img src={PercentIcon} alt="" className="icon_img mr-1" />
                                            Fixed Scope
                                        </p>
                                    </div>
                                }

                            </div>
                            <div className="modal-body common_overflow_height">
                                <div className="project-deatils">
                                    {
                                        !this.state.clientData && this.props.location.state !== 'success' ?
                                            <div style={{ minHeight: '400px' }}>
                                                <BootstrapLoader height={'20vh'} />
                                            </div>
                                            :

                                            <div className="row m-0">

                                                <div className="col-sm-4 col-md-4 col-lg-4 bg-light-sky px-40 py-20 ipad-normal-p-15 mob-border-radius border-bottom-left-radius border-top-left-radius overflow-content inComingBrief chatting-box-overflow mobile-hide">
                                                    <div className="project-name mb-3">
                                                        <span className="sub-design font-14 font-weight-600 text-uppercase text-color-purple">{project_name}</span>
                                                    </div>
                                                    <div className="project-name mb-3 mt-3">
                                                        <span className="sub-design font-14 font-weight-bold text-uppercase text-dark-gray">{collateral_name}</span>
                                                    </div>
                                                    {/* {!isAdminProject ?
                                                        <div className="row my-3 mx-0 ">
                                                            <div className="col-lg-12">
                                                                <div class="card view_proposal_card h-100">
                                                                    <div class="card-body px-4 py-4">
                                                                        <div className="card_body_inner d-flex">
                                                                            <div className="inner_item mr-4">
                                                                                <div className="card_icon d-flex">
                                                                                    <img src={MoneyIcon} className="icon_img" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="inner_item">
                                                                                <p className="mb-0 text_gray">Budget</p>
                                                                                <p className="mb-2 text_black_md">
                                                                                    ₹{" "}
                                                                                    {separateNumber(minBudget, {isWords: true})} - ₹{" "}{separateNumber(maxBudget, {isWords: true})}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.number_month ?
                                                                <div className="col-lg-12">
                                                                    <div class="card view_proposal_card h-100">
                                                                        <div class="card-body px-4 py-4">
                                                                            <div className="card_body_inner d-flex">
                                                                                <div className="inner_item mr-4">
                                                                                    <div className="card_icon d-flex">
                                                                                        <img src={MenuBoardIcon} className="icon_img" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="inner_item">
                                                                                    <p className="mb-0 text_gray">Project Duration</p>
                                                                                    <p className="mb-2 text_black_md">
                                                                                        {this.props.number_month > 1 ? this.props.number_month + " months" : this.props.number_month + " month"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <></>}
                                                            {this.props.number_month ?
                                                                <div className="col-lg-12">
                                                                    <div class="card view_proposal_card h-100">
                                                                        <div class="card-body px-4 py-4">
                                                                            <div className="card_body_inner d-flex">
                                                                                <div className="inner_item mr-4">
                                                                                    <div className="card_icon d-flex">
                                                                                        <img src={MenuBoardIcon} className="icon_img" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="inner_item">
                                                                                    <p className="mb-0 text_gray">Weekly Commitment</p>
                                                                                    <p className="mb-2 text_black_md">
                                                                                        {this.props.number_hour > 1 ? this.props.number_hour + " hours" : this.props.number_hour + " hour"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <></>}
                                                        </div> : null} */}
                                                    {deadline > 0 && <div className="Project-duration mb-2">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Project duration</span>
                                                        <h2 className="mb-0 font-18 mob-font-16 font-weight-600">{deadline > 0 ? `${deadline} Days` : 'No Deadline'}</h2>
                                                    </div>}
                                                    {sub_type && sub_type === 'fixed' && deadline_date ? <div className="Project-duration mb-2">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Deadline</span>
                                                        <h2 className="mb-0 font-18 mob-font-16 font-weight-600">{getDateFormated(deadline_date)}</h2>
                                                    </div> : null}

                                                    {!isAdminProject ? <div className="Project-budget mb-3">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Budget</span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"><i className="fas fa-rupee-sign rupees font-15"></i>{separateNumber(minBudget)} - <i className="fas fa-rupee-sign rupees font-15"></i>{separateNumber(maxBudget)}</h2>
                                                        {this.props.number_month ? <div className="Project-budget mb-4">
                                                            <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Project Duration </span>
                                                            <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_month > 1 ? this.props.number_month + " months" : this.props.number_month + " month"}</h2>
                                                        </div> : <></>}
                                                        {this.props.number_month ? <div className="Project-budget mb-4">
                                                            <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Weekly Commitment </span>
                                                            <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_hour > 1 ? this.props.number_hour + " hours" : this.props.number_hour + " hour"}</h2>
                                                        </div> : <></>}
                                                    </div> : null}
                                                    <div className="card-wrapper mt-5">
                                                        <div className="card p-2">
                                                            <div className="card-body">
                                                                <div className="w-100 d-flex align-items-center">
                                                                    <div className="">
                                                                        <p className="mb-0 model-profile jobs"><img src={isIndividual ? BaseImageUrl + `${clientData?.profile_image ? clientData?.profile_image : `profile_image.png`}` : checkFavIcon ? `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${company_add_link}&size=64` : BaseImageUrl + `${clientData?.profile_image ? clientData?.profile_image : `profile_image.png`}`} alt="profile" title="" width="80" /></p>
                                                                    </div>
                                                                    <div className="w-65 overflow-hidden pl-2">
                                                                        <h2 className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16">{isIndividual ? "Individual" : company_name}</h2>
                                                                        <span className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16 text-dark-gray">{limitString(project_indust, 20)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="col-sm-8 col-md-8 col-lg-8 p-0">
                                                    
                                                    <div className={`project-description px-4 ipad-normal-p-15 ${match.path === '/chat/:projectId/:projectName' ? 'hide-buttons' : ''} mt-2`}>
                                                        <div className="row px-0 pb-4 deliverables mx-0 ">
                                                            <div className="col-lg-12 title_commn">
                                                                <p className="mb-0">Project Details</p>
                                                            </div>
                                                            <div className="col-lg-12 px-2 py-4">
                                                                {project_question.length ? (
                                                                    <CompanyAndProjectQuestions questions={project_question} /> 
                                                                ) : null}
                                                                {style.length ? (
                                                                    <StyleAndTypeQuestions ques={style} quesType={'style'} /> 
                                                                ) : null}
                                                                {type.length ? (
                                                                    <StyleAndTypeQuestions ques={type} quesType={'type'} />
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        {project_description.length ? 
                                                        <div className="row px-0 pb-4 deliverables mx-0">
                                                            <div className="col-lg-12 title_commn">
                                                                <p className="mb-0">Project Description</p>
                                                            </div>
                                                            <div className='col-lg-12 project_description px-2 py-4'>
                                                                <p className="description break-word" dangerouslySetInnerHTML={{ __html:sanitiseSummernoteText(project_description?.replace(/\n/g,'<br/>')) }}></p>
                                                            </div>
                                                        </div> : null}






                                                        {/*Add References */}
                                                        {reference_files?.length > 0 || reference_files?.length > 0 ? (
                                                            <div className="row px-4 pb-4 add_reference  mx-0">
                                                            <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                                                                <p className="mb-0">References</p>
                                                            </div>
                                                            <div className="col-lg-12 pt-2 px-0">
                                                                <nav className="mb-3">
                                                                <div className="nav nav-tabs cstm_tab" id="nav-tab" role="tablist">
                                                                    {reference_files?.length > 0 ? (
                                                                    <a
                                                                        className={`nav-item nav-link cstm_tab_item ${reference_files?.length ? "refrence_tab_active  active" : ""} d-flex align-items-center`}
                                                                        id="nav-attachment-tab"
                                                                        data-toggle="tab"
                                                                        href="#nav-attachment"
                                                                        role="tab"
                                                                        aria-controls="nav-attachment"
                                                                        aria-selected="true"
                                                                        onClick={() => this.setState({...this.state,toggle:1})}
                                                                    >
                                                                        <p className="mb-0">{reference_files?.length > 1 ? "Attachments" : "Attachment"}</p>
                                                                        <div className={`ml-3 mob-ml-3 ${this.state.toggle == 1 ? 'tag_btn_tab_active':"tag_btn_tab"}`}>
                                                                            <p className="mb-0"> {reference_files?.length > 0 ? reference_files.length : 0}</p>
                                                                        </div>
                                                                        {/* <div className={`tag_btn_tab ml-3`}>
                                                                        </div> */}
                                                                    </a>
                                                                    ) : null}

                                                                    {reference_links?.length > 0 ? (
                                                                    <a
                                                                        className={`nav-item nav-link cstm_tab_item ${!reference_files?.length && reference_links?.length ? "active" : ""} d-flex align-items-center`}
                                                                        id="nav-contact-tab"
                                                                        data-toggle="tab"
                                                                        href="#nav-contact"
                                                                        role="tab"
                                                                        aria-controls="nav-contact"
                                                                        aria-selected="false"
                                                                        onClick={() => this.setState({...this.state,toggle:2})}
                                                                    >
                                                                        <p className="mb-0"> {reference_links?.length > 1 ? "URLs" : "URL"}</p>
                                                                        <div className={`ml-3 mob-ml-3 ${this.state.toggle == 2 ? 'tag_btn_tab_active':"tag_btn_tab"}`}>
                                                                        <p className="mb-0"> {reference_links?.length > 0 ? reference_links.length : 0}</p>
                                                                        </div>
                                                                        {/* <div className={`tag_btn_tab ml-3`}>
                                                                        <p className="mb-0"> {reference_links?.length > 0 ? reference_links.length : 0}</p>
                                                                        </div> */}
                                                                    </a>
                                                                    ) : null}
                                                                </div>
                                                                </nav>

                                                                {reference_files?.length > 0 || reference_links?.length > 0 ? (
                                                                <div className="tab-content" id="nav-tabContent">
                                                                    {/* tab1 */}
                                                                    {reference_files?.length > 0 ? (
                                                                    <div className={`tab-pane fade ${reference_files?.length > 0 ? "show active" : ""}`}  id="nav-attachment" role="tabpanel" aria-labelledby="nav-attachment-tab">
                                                                        <div className="row">
                                                                        {reference_files?.length > 0 ? (
                                                                            reference_files?.map((item) => {
                                                                            return (
                                                                                <div className="col-lg-3 text-center mb-3">
                                                                                <div className="import">
                                                                                    <p className="mb-0 text_black_xs_w_500">
                                                                                    <span style={{ overflowWrap: "break-word" }}>{handleFileName(item?.name, 12)}</span>
                                                                                    <a
                                                                                        href={CollateralBaseUrl + item?.name}
                                                                                        target="_blank"
                                                                                        onClick={() => {
                                                                                        downloadFilesFun(CollateralBaseUrl, item?.name);
                                                                                        }}
                                                                                    >
                                                                                        <img src={ImportIcon} alt="" className="icon_img ml-3" />
                                                                                    </a>
                                                                                    </p>
                                                                                </div>
                                                                                </div>
                                                                            );
                                                                            })
                                                                        ) : (
                                                                            <div className="col-sm-12">
                                                                            <div style={{ padding: "1rem", textAlign: "center" }}>
                                                                                <span style={{ color: "#6f7d91" }}>No Attachments found ...</span>
                                                                            </div>
                                                                            </div>
                                                                        )}
                                                                        </div>
                                                                        {/* end cards */}
                                                                    </div>
                                                                    ) : null}
                                                                    {/* tab3 */}
                                                                    {reference_links?.length > 0 ? (
                                                                    <div className={`tab-pane fade ${!reference_files?.length && reference_links?.length ? "show active" : ""}`}  id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                                        <div className="row px-0 mx-0">
                                                                        { reference_links?.length > 0 ? (
                                                                            reference_links?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                {/* <div className="col-lg-12 px-0 py-3">
                                                                                <p className="url_input mb-0">
                                                                                    <a className="url_input mb-0" href={`http://${removeHttps(item)}`} target="_blank">
                                                                                    <img className="img-fluid mr-3" src={LinkIcon} />
                                                                                    {item}
                                                                                    </a>
                                                                                </p>
                                                                                </div> */}
                                                                                <>
                                                                                    <div className='col-lg-11 col-10 px-0 mb-2 cursor-pointer' key={index} onClick={() => window.open(`http://${removeHttps(item)}`, "_blank", 'noopener,noreferrer')}>
                                                                                    <input className="form-control input_md url_input cursor-pointer" type="text" value={item} />
                                                                                    <div className='input_content d-flex justify-content-between align-items-center cursor-pointer px-3'>
                                                                                        <img className='img-fluid' alt="" src={LinkIcon} />
                                                                                    </div>
                                                                                    </div>
                                                                                </>
                                                                                </>
                                                                            );
                                                                            })
                                                                        ) : (
                                                                            <div className="col-sm-12">
                                                                            <div style={{ padding: "1rem", textAlign: "center" }}>
                                                                                <span style={{ color: "#6f7d91" }}>No urls found ...</span>
                                                                            </div>
                                                                            </div>
                                                                        )}
                                                                        </div>
                                                                    </div>
                                                                    ) : null}
                                                                </div>
                                                                ) : null}
                                                            </div>
                                                            </div>
                                                        ) : null}
                                                        {/* end Add References */}

                                                        {/* {reference_files.length ? <div className="Project-duration mb-4">
                                                            <span className="sub-design d-block font-16 font-weight-600 text-dark-gray">References Files</span>

                                                            <div className="onboarding_style_questions">
                                                                <ul className="content p-0 mb-3">

                                                                    {reference_files.map((file, i) => {
                                                                        return (
                                                                            <Fragment key={i}>
                                                                                <li>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        href={CollateralBaseUrl + file.name}>
                                                                                        <div className={`deadline position-relative`} style={{ backgroundSize: 'cover', backgroundImage: `url("${isImage(file.name) ? CollateralBaseUrl + file.name : renderDifferentFilesThumbnail(file.name)}")` }}>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>


                                                        </div> : null} */}
                                                        {/* {reference_links.length ? <div className="Project-duration mb-4">
                                                            <span className="sub-design d-block font-16 font-weight-600 text-dark-gray">References Links</span>
                                                            {reference_links.map((link, i) => {
                                                                return (

                                                                    <a key={i} target="_blank" rel="noopener noreferrer" className="text-dark-black" href={"http://" + removeHttps(link)}><button type="button" className=" text-black main-btn font-12 font-weight-600 bg-extra-light mt-1 mr-2 cursor-pointer px-4">Link {i + 1}</button></a>
                                                                )
                                                            }
                                                            )}

                                                        </div> : null} */}
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className='modal-footer'>
                            {redirectPop?.length ?
                                     <div className="row m-0 align-items-center px-3 py-2 mob-pt-py-15">
                                     {redirectPop === "coll" ?
                                         <div className="col-12 col-sm-12 dflex mob-p-0 mob-mt-10">
                                             <div className="mob-w-50">
                                                 * You need to add <span style={{ color: "#10c89b" }}>{collateral_name}</span>  collateral first.
                                             </div>
                                             <button type="button" className="main-btn mob-font-16 mob-font-12 fw_600 ml-2" disabled={collateral_name ? false : true} onClick={() => this.handleRedirect(redirectPop)}>
                                                 Add Collateral
                                             </button>
                                         </div>
                                         :
                                         <div className={`col-12 col-sm-12 ${this.props?.checkHasApplied ? "dflex" : "text-right"} mob-p-0 mob-mt-10`}>
                                             {this.props?.checkHasApplied ?
                                                 <div className="mob-w-50">
                                                     * You have already submitted a proposal for this brief.
                                                 </div>
                                                 : ""
                                             }
                                             <button type="button" className={`main-btn mob-font-16 mob-font-12 fw_600 ml-2 ${this.props?.checkHasApplied?'cursor-default':''}`} disabled={collateral_name ? false : true} onClick={() => this.handleRedirect(redirectPop)}>
                                                 {/* {this.props?.checkHasApplied ? "View/Edit Proposal" : "Submit Proposal"} */}
                                                 {this.props?.checkHasApplied ? "Proposal Sent" : "Submit Proposal"}
                                             </button>
                                         </div>
                                     }
                                 </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
                <SubmitProp
                    changeShowSubmitProposal={() => this.changeShowSubmitProposal()}
                    id={this.props?.post_project_id}
                    {...this.props}
                    showSubmitProposal={this.state.showSubmitProposal}
                    data={data}
                />
            </>
        )
    }
}

const mapState = (store) => {
    const postProjectData = store.postProject;
    const globalReducer = store.globalReducer;
    const dashboard = store.dashboard;
    const chat = store.chat;
    return {
        dash: dashboard,
        sub_type: dashboard.sub_type,
        number_hour: dashboard.number_hour,
        number_month: dashboard.number_month,
        closeModal: postProjectData.closeModal,
        project_name: dashboard.project_name,
        client_role: dashboard.client_role,
        company_name: dashboard.company_name,
        about: dashboard.about,
        industry: dashboard.industry,
        website: dashboard.website,
        work_type: dashboard.work_type,
        minBudget: dashboard.minBudget,
        maxBudget: dashboard.maxBudget,
        deadline: dashboard.deadline,
        deadline_date: dashboard.deadline_date,
        project_description: dashboard.project_description,
        client: dashboard.client,
        collateral_name: dashboard.collateral_name,
        collateral_id: dashboard.collateral_id,
        style: dashboard.style,
        type: dashboard.type,
        reference_files: dashboard.reference_files,
        reference_links: dashboard.reference_link,
        company_question: dashboard.company_question,
        project_question: dashboard.project_question,
        post_project_id: dashboard.post_project_id,
        project_slug: dashboard.project_slug,
        project_indust: dashboard.project_indust,
        ProjectType: chat.ProjectType,
        CurrentChat: chat.CurrentChat,
        manager: dashboard.manager,
        Me: globalReducer.Me,
        deadline_date: dashboard.deadline_date
    };
}

const mapDispatch = dispatchEvent => ({
    OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    closePopup: () => dispatchEvent({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    rejectIncomingBrief: (id) => dispatchEvent({ type: 'REJECT_INCOMING_BRIEF', payload: id }),
    acceptIncomingBrief: (id, history, project_name) => dispatchEvent({ type: 'ACCEPT_INCOMING_BRIEF', payload: { id, history, project_name } }),
    loadProjectStatus: (projectId) => dispatchEvent({ type: 'LOAD_PROJECT_STATUS', payload: { projectId } }),

});

export default withRouter(connect(mapState, mapDispatch)(IncomingBriefReviewJob));

  
