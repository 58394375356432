export const createArtistNecessaryFields = (obj, proposal) => {
    if(obj.chat_type === 'group'){
      obj.first_name = "Group"
      obj.last_name = ""
      obj.profile_image = ""
    } else if(obj.chat_type === 'representative'){
      obj.first_name = obj.member1.first_name
      obj.last_name = obj.member1.last_name
      obj.profile_image = obj.member1.profile_image
      obj.user_id = obj.member1._id
      obj.username = obj.member1.username
    }

    obj.proposal_id = proposal._id

  
    return obj
  
  }