import React from "react";
// import RightIcon from "../../../../Assets/upgrade-images/Sucess/Right_Icon.png";
import SucessIcon from "../../../../Assets/upgrade-images/Sucess/Sucess.svg";

export default function SuccessProp({
  showSuccessProp,
  setShowSuccessProp,
  handleSubmitApi, ...props
}) {

  const { id, type } = props.match.params;

  return (
    <section className="sucess">
      <div
        className={`modal fade modal_fade_bg ${showSuccessProp ? "show" : ""}`}
        id="sucess"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showSuccessProp ? "block" : "" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal_content_box">
            <div className="modal_top_header d-flex align-items-center  justify-content-between px-4"></div>

            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="sucess_content col-lg-12 text-center">
                  <div className="sucess_img mb-4">
                    <img src={SucessIcon} className="img-fluid w-25" />
                    {/* <img src={RightIcon} className="img-fluid"/> */}
                  </div>
                  <h4 className="text_gray_lg_w_800 sucess_title">
                    Proposal Submitted
                  </h4>
                  <p className="mx-auto text_light_gray_md sucess_msg w-80">
                    Well done! We have sent your proposal for review. You will hear back from us shortly with an update.
                  </p>
                  <div className="add_project">
                    <button
                      type="button"
                      class="btn btn-common btn_common_sm btn_sucess"
                      onClick={() => {
                        setShowSuccessProp(false)
                        if(props.location.pathname.includes('jobs')){
                          props.history.push({pathname:`/dashboard/jobs`,state:'success'})
                        }else{

                          props.history.push(`/projects/${id}/overview`)
                        }
                      }}
                    >
                      Got It
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer py-3 border-0 justify-content-between align-items-center"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
