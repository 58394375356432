export const ADD_DATA = 'ADD_DATA';
export const ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS';
// export const ADD_DATA_FAILURE = ADD_DATA_FAILURE

export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const APPROVE_USER_STATUS = 'APPROVE_USER_STATUS';

// Upload
export const UPLOAD_RESUME = 'UPLOAD_RESUME';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPDATE_REVIEW_STATUS = 'UPDATE_REVIEW_STATUS';

export const UPDATE_UPLOADING = 'UPDATE_UPLOADING';
export const UPDATE_SAVING = 'UPDATE_SAVING';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_SUBMITTING = 'UPDATE_SUBMITTING';
export const UPDATE_FORMOPEN = 'UPDATE_FORMOPEN';

// close Popup.
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CLOSE_POPUP_SAGA = 'CLOSE_POPUP_SAGA';

export const OPEN_POPUP = 'OPEN_POPUP';
export const OPEN_POPUP_SAGA = 'OPEN_POPUP_SAGA';
export const ON_FIELDCHANGE = 'ON_FIELDCHANGE';
export const ON_FIELDCHANGE_SAGA = 'ON_FIELDCHANGE_SAGA';
export const ON_STATEUPDATE_SAGA = 'ON_STATEUPDATE_SAGA';
export const ON_ACTION_SAGA = 'ON_ACTION_SAGA';
export const OPEN_SPECIALIZATION = 'OPEN_SPECIALIZATION';

//open upload project Popup.
export const OPEN_PROJECT_POPUP = 'OPEN_PROJECT_POPUP';
export const SUBMIT_PROJECT_STATUS = 'SUBMIT_PROJECT_STATUS';
export const SUBMIT_PROJECT = 'SUBMIT_PROJECT';
export const UPDATE_PROJECT_IMAGE = 'UPDATE_PROJECT_IMAGE';
export const UPDATE_MILESTONE_IMAGE = "UPDATE_MILESTONE_IMAGE"; 
export const UPDATE_IS_IMAGE_UPLOADING   = 'UPDATE_IS_IMAGE_UPLOADING';


export const CROP_IMAGE = "CROP_IMAGE";
export const SET_CROP_IMAGE = "SET_CROP_IMAGE";


// Upload project (amit)
export const SET_IMAGE_FOR_CROP = "SET_IMAGE_FOR_CROP";
export const SET_IMAGES_OR_LINKS = "SET_IMAGES_OR_LINKS";
export const SET_PREVIEW_IMAGE = "SET_PREVIEW_IMAGE";
export const GET_PROJECT_DATA_1 = "GET_PROJECT_DATA_1";
export const EDIT_USER_PROJECT_1 = "EDIT_USER_PROJECT_1";
export const SET_USER_PROJECT_DATA = "SET_USER_PROJECT_DATA";
export const RESET_USER_PROJECT_DATA = "RESET_USER_PROJECT_DATA";
export const UPDATE_USER_PROJECT_DATA = "UPDATE_USER_PROJECT_DATA";
export const UPDATE_STYLE_AND_TYPE_QUESTIONS_IN_PROJECT = "UPDATE_STYLE_AND_TYPE_QUESTIONS_IN_PROJECT";
export const UPDATE_STYLE_QUESTION_WITH_ANSWER = "UPDATE_STYLE_QUESTION_WITH_ANSWER";
export const UPDATE_TYPE_QUESTION_WITH_ANSWER = "UPDATE_TYPE_QUESTION_WITH_ANSWER";





export const UPDATE_CROP_LIST = "UPDATE_CROP_LIST";
export const SET_CROP_IMAGE_LIST = "SET_CROP_IMAGE_LIST";

// Current User
export const UPDATE_LOAD_LOGGEDIN_USER = 'UPDATE_LOAD_LOGGEDIN_USER';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';


// Creator Profile.
export const UPLOAD_USERIMAGE = 'UPLOAD_USERIMAGE';
export const LOAD_CREATOR = 'LOAD_CREATOR';
export const LOAD_COLLATERAL_QUESTIONS_IN_CREATOR_PROFILE =
  'LOAD_COLLATERAL_QUESTIONS_IN_CREATOR_PROFILE';

export const LOAD_CREATOR_SPECIALIZATION_SAGA =
  'LOAD_CREATOR_SPECIALIZATION_SAGA';
export const LOAD_CREATOR_LOCATION_SAGA = 'LOAD_CREATOR_LOCATION_SAGA';
export const LOAD_CREATOR_SOFTWARE_SAGA = 'LOAD_CREATOR_SOFTWARE_SAGA';
export const LOAD_CREATOR_INDUSTORY_SAGA = 'LOAD_CREATOR_INDUSTORY_SAGA';
export const LOAD_CREATOR_COMPANY_SAGA = 'LOAD_CREATOR_COMPANY_SAGA';

export const LOAD_CREATOR_PUBLIC = 'LOAD_CREATOR_PUBLIC';
export const UPDATE_USER = 'UPDATE_USER';
export const LOAD_SPECIALIZATION = 'LOAD_SPECIALIZATION';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_CREATOR_ALLTOOLS = 'LOAD_CREATOR_ALLTOOLS';

// update user experience years
export const UPDATE_EXPERIENCE_YEAR = 'UPDATE_EXPERIENCE_YEAR'

// Api
export const USERADD_COLLATEROL = 'USERADD_COLLATEROL';
export const UPDATE_SAGA_CURRENTWORKSTATUS = 'UPDATE_SAGA_CURRENTWORKSTATUS';
export const UPDATE_CURRENTWORKSTATUS = 'UPDATE_CURRENTWORKSTATUS';
export const UPDATE_WebLink_Api = 'UPDATE_WebLink_Api';

export const LOAD_CREATOR_INDUSTRIES = 'LOAD_CREATOR_INDUSTRIES';
export const UPDATE_CREATOR_INDUSTRIES = 'UPDATE_CREATOR_INDUSTRIES';

export const UPDATE_CREATOR_PUBLIC = 'UPDATE_CREATOR_PUBLIC';
export const UPDATE_CREATOR_DATA_FETCHING = 'UPDATE_CREATOR_DATA_FETCHING';

export const LOAD_SOCIALPROFILE = 'LOAD_SOCIALPROFILE';

export const UPDATE_CREATORBASIC = 'UPDATE_CREATORBASIC';
export const UPDATE_CREATOR = 'UPDATE_CREATOR';
export const UPDATE_PROFILE_LOADED = 'UPDATE_PROFILE_LOADED';
export const UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA = 'UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA';
export const UPDATE_MESSAGE_NOTIFICATION_COUNT = 'UPDATE_MESSAGE_NOTIFICATION_COUNT';
export const CLEAR_MESSAGE_NOTIFICATION_SAGA = 'CLEAR_MESSAGE_NOTIFICATION_SAGA';

// deprecate
// reset notificaation from dashboard and chat
// export const RESET_NOTIFICATION_SAGA = 'RESET_NOTIFICATION_SAGA';

export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA = 'UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA';
export const UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA = 'UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA';
export const UPDATE_DASHBOARD_NOTIFICATION_COUNT = 'UPDATE_DASHBOARD_NOTIFICATION_COUNT';
export const UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT = 'UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT';
export const CLEAR_DASHBOARD_NOTIFICATION_SAGA = 'CLEAR_DASHBOARD_NOTIFICATION_SAGA';
export const CLEAR_COMPLETED_PROJECT_NOTIFICATION_SAGA = 'CLEAR_COMPLETED_PROJECT_NOTIFICATION_SAGA';

export const LOAD_CURRENTWORKSTATUS = 'LOAD_CURRENTWORKSTATUS';

export const UPDATE_FIRSTNAME = 'UPDATE_FIRSTNAME';
export const UPDATE_LASTNAME = 'UPDATE_LASTNAME';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const UPDATE_EDUCATION = 'UPDATE_EDUCATION';

export const UPDATE_SOFTWARE = 'UPDATE_SOFTWARE';
export const UPDATE_SOFTWARE_UPDATE = 'UPDATE_SOFTWARE_UPDATE';
export const UPDATE_SOFTWARE_SAGA = 'UPDATE_SOFTWARE_SAGA';
export const UPDATE_SOFTWARE_UPDATE_ERROR = 'UPDATE_SOFTWARE_UPDATE_ERROR';

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_UPDATE = 'UPDATE_LANGUAGE_UPDATE';
export const UPDATE_LANGUAGE_SAGA = 'UPDATE_LANGUAGE_SAGA';

export const UPDATE_AWARD = 'UPDATE_AWARD';
export const UPDATE_AWARD_UPDATE = 'UPDATE_AWARD_UPDATE';
export const UPDATE_AWARD_DELETE = 'UPDATE_AWARD_DELETE';
// export const UPDATE_ADD_AWARD = 'UPDATE_ADD_AWARD';
export const UPDATE_AWARD_SAGA = 'UPDATE_AWARD_SAGA';
export const DELETE_AWARD_SAGA = 'DELETE_AWARD_SAGA';
export const ADD_AWARD_SAGA = 'ADD_AWARD_SAGA';
export const ADD_AWARD = 'ADD_AWARD';

export const UPDATE_SPECIALIZATION_UPDATE = 'UPDATE_SPECIALIZATION_UPDATE';
export const UPDATE_SPECIALIZATION_UPDATE_ERROR =
  'UPDATE_SPECIALIZATION_UPDATE_ERROR';
export const UPDATE_SPECIALIZATION_SAGA = 'UPDATE_SPECIALIZATION_SAGA';

export const UPDATE_SPECIALIZATION = 'UPDATE_SPECIALIZATION';
export const UPDATE_COLLATERAL = 'UPDATE_COLLATERAL';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_OCCUPATION = 'UPDATE_OCCUPATION';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_PRIMARYFIELDWORK = 'UPDATE_PRIMARYFIELDWORK';

export const UPDATE_PRIMARYSPECIALIZATION_EXP = 'UPDATE_PRIMARYSPECIALIZATION_EXP';

export const UPDATE_CREATOR_WORK = 'UPDATE_CREATOR_WORK';
export const DELETE_CREATOR_WORK = 'DELETE_CREATOR_WORK';

export const UPDATE_CREATOR_PROJECT = 'UPDATE_CREATOR_PROJECT';

export const UPDATE_CREATOR_REVIEWS = 'UPDATE_CREATOR_REVIEWS';

export const UPDATE_BASIC_ERROR = 'UPDATE_BASIC_ERROR';

export const UPDATE_BASIC_FIRSTNAME = 'UPDATE_BASIC_FIRSTNAME';
export const UPDATE_BASIC_LASTNAME = 'UPDATE_BASIC_LASTNAME';
export const UPDATE_BASIC_ABOUT = 'UPDATE_BASIC_ABOUT';
export const UPDATE_BASIC_LOCATION = 'UPDATE_BASIC_LOCATION';
export const UPDATE_BASIC_AVATAR = 'UPDATE_BASIC_AVATAR';
export const UPDATE_BASIC_PRIMARYFIELDWORK = 'UPDATE_BASIC_PRIMARYFIELDWORK';

export const UPDATE_BASIC_FIRSTNAME_ERROR = 'UPDATE_BASIC_FIRSTNAME_ERROR';
export const UPDATE_BASIC_LASTNAME_ERROR = 'UPDATE_BASIC_LASTNAME_ERROR';
export const UPDATE_BASIC_ABOUT_ERROR = 'UPDATE_BASIC_ABOUT_ERROR';
export const UPDATE_BASIC_LOCATION_ERROR = 'UPDATE_BASIC_LOCATION_ERROR';
export const UPDATE_BASIC_AVATAR_ERROR = 'UPDATE_BASIC_AVATAR_ERROR';
export const UPDATE_BASIC_PRIMARYFIELDWORK_ERROR =
  'UPDATE_BASIC_PRIMARYFIELDWORK_ERROR';

export const LOAD_SOCIALPROFILEPOPUP = 'LOAD_SOCIALPROFILEPOPUP';

export const UPDATE_SOCIALPROFILE_RESUME = 'UPDATE_SOCIALPROFILE_RESUME';
export const UPDATE_SOCIALPROFILE_indiefolio_url =
  'UPDATE_SOCIALPROFILE_indiefolio_url';
export const UPDATE_SOCIALPROFILE_behance_url =
  'UPDATE_SOCIALPROFILE_behance_url';
export const UPDATE_SOCIALPROFILE_dribbble_url =
  'UPDATE_SOCIALPROFILE_dribbble_url';
export const UPDATE_SOCIALPROFILE_instagram_url =
  'UPDATE_SOCIALPROFILE_instagram_url';
export const UPDATE_SOCIALPROFILE_vimeo_url = 'UPDATE_SOCIALPROFILE_vimeo_url';
export const UPDATE_SOCIALPROFILE_personal_url =
  'UPDATE_SOCIALPROFILE_personal_url';
export const UPDATE_SOCIALPROFILE_custom_url =
  'UPDATE_SOCIALPROFILE_custom_url';
export const UPDATE_SOCIALPROFILE_custom_url1 =
  'UPDATE_SOCIALPROFILE_custom_url1';
export const UPDATE_SOCIALPROFILE_custom_url2 =
  'UPDATE_SOCIALPROFILE_custom_url2';

export const UPDATE_SOCIALPROFILE_indiefolio_url_ERROR =
  'UPDATE_SOCIALPROFILE_indiefolio_url_ERROR';
export const UPDATE_SOCIALPROFILE_behance_url_ERROR =
  'UPDATE_SOCIALPROFILE_behance_url_ERROR';
export const UPDATE_SOCIALPROFILE_dribbble_url_ERROR =
  'UPDATE_SOCIALPROFILE_dribbble_url_ERROR';
export const UPDATE_SOCIALPROFILE_instagram_url_ERROR =
  'UPDATE_SOCIALPROFILE_instagram_url_ERROR';
export const UPDATE_SOCIALPROFILE_vimeo_url_ERROR =
  'UPDATE_SOCIALPROFILE_vimeo_url_ERROR';
export const UPDATE_SOCIALPROFILE_personal_url_ERROR =
  'UPDATE_SOCIALPROFILE_personal_url_ERROR';
export const UPDATE_SOCIALPROFILE_custom_url_ERROR =
  'UPDATE_SOCIALPROFILE_custom_url_ERROR';
export const UPDATE_SOCIALPROFILE_custom_url1_ERROR =
  'UPDATE_SOCIALPROFILE_custom_url1_ERROR';
export const UPDATE_SOCIALPROFILE_custom_url2_ERROR =
  'UPDATE_SOCIALPROFILE_custom_url2_ERROR';

export const UPDATE_COLLATERAL_NAME = 'UPDATE_COLLATERAL_NAME';
export const UPDATE_COLLATERAL_DURATION = 'UPDATE_COLLATERAL_DURATION';
export const UPDATE_COLLATERAL_ENDPRICE = 'UPDATE_COLLATERAL_ENDPRICE';
export const UPDATE_COLLATERAL_STARTPRICE = 'UPDATE_COLLATERAL_STARTPRICE';
export const UPDATE_COLLATERAL_AVGTIME = 'UPDATE_COLLATERAL_AVGTIME';
export const UPDATE_COLLATERAL_EXPERIENCE = 'UPDATE_COLLATERAL_EXPERIENCE';

// global.
export const FIELD_CHANGE = 'FIELD_CHANGE';

// load Projects Api'
export const LOAD_PROJECTAPI = 'LOAD_PROJECTAPI';
export const GET_LOAD_COLLATERAL = 'GET_LOAD_COLLATERAL';
export const LOAD_COLLATERAL = 'LOAD_COLLATERAL';
export const ADD_ABOUTPROJECT = 'ADD_ABOUTPROJECT';
export const ADD_COLLABORATION = 'ADD_COLLABORATION';
export const ADD_COLLATERAL = 'ADD_COLLATERAL';
export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPLETEDINDIEFOLIO = 'ADD_COMPLETEDINDIEFOLIO';
export const ADD_CREATEDFOR = 'ADD_CREATEDFOR';
export const ADD_DESCRIBEROLE = 'ADD_DESCRIBEROLE';
export const ADD_KEYWORDS = 'ADD_KEYWORDS';
export const ADD_MEDIA = 'ADD_MEDIA';
export const ADD_STARTDATE = 'ADD_STARTDATE';
export const ADD_STYLETAG = 'ADD_STYLETAG';
export const ADD_TITLE = 'ADD_TITLE';
export const SELECTED_COLLATERAL = 'SELECTED_COLLATERAL';
export const GET_SELECTED_COLLATERAL = 'GET_SELECTED_COLLATERAL';
export const SELECT_COLLATERAL_ANSWER = 'SELECT_COLLATERAL_ANSWER';
export const SET_STYLE_TYPE_QUESTIONS = 'SET_STYLE_TYPE_QUESTIONS';

export const CHOOSE_COLLATERAL_ANSWER = 'CHOOSE_COLLATERAL_ANSWER';
export const SELECT_STYLE_TYPE_QUESTIONS = 'SELECT_STYLE_TYPE_QUESTIONS';

// Upload Project for Client
export const UPDATE_LISTCOLLATERAL = 'UPDATE_LISTCOLLATERAL';
export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_ABOUTPROJECT = 'UPDATE_ABOUTPROJECT';
export const UPDATE_TOOLSUSED = 'UPDATE_TOOLSUSED';
export const UPDATE_STYLETAG = 'UPDATE_STYLETAG';
export const UPDATE_CREATEDFOR = 'UPDATE_CREATEDFOR';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_STARTDATE = 'UPDATE_STARTDATE';
export const UPDATE_COLLABORATION = 'UPDATE_COLLABORATION';
export const UPDATE_DESCRIBEROLE = 'UPDATE_DESCRIBEROLE';
export const UPDATE_KEYWORDS = 'UPDATE_KEYWORDS';
export const UPDATE_COMPLETEDINDIEFOLIO = 'UPDATE_COMPLETEDINDIEFOLIO';

// UserWork
export const ADD_USERWORK_SAGA = 'ADD_USERWORK_SAGA';
export const LOAD_USERWORK = 'LOAD_USERWORK';

export const DELETE_USERWORK_SAGA = 'DELETE_USERWORK_SAGA';
export const DELETE_USERWORK = 'DELETE_USERWORK';

export const UPDATE_USERWORK_EDIT_SAGA = 'UPDATE_USERWORK_EDIT_SAGA';
export const UPDATE_USERWORK_EDITWORK = 'UPDATE_USERWORK_EDITWORK';

export const UPDATE_USERWORK = 'UPDATE_USERWORK';
export const UPDATE_USERWORK_RESET = 'UPDATE_USERWORK_RESET';

export const UPDATE_USERWORK_ALLCOMPANY = 'UPDATE_USERWORK_ALLCOMPANY';
export const UPDATE_USERWORK_ALLLOCATION = 'UPDATE_USERWORK_ALLLOCATION';
export const UPDATE_USERWORK_ALLINDUSTRY = 'UPDATE_USERWORK_ALLINDUSTRY';

export const UPDATE_USERWORK_EDIT = 'UPDATE_USERWORK_EDIT';

export const UPDATE_USERWORK_ERROR = 'UPDATE_USERWORK_ERROR';

export const UPDATE_USERWORK_COMPANY = 'UPDATE_USERWORK_COMPANY';
export const UPDATE_USERWORK_LOCATION = 'UPDATE_USERWORK_LOCATION';
export const UPDATE_USERWORK_DESIGNATION = 'UPDATE_USERWORK_DESIGNATION';
export const UPDATE_USERWORK_STARTMONTH = 'UPDATE_USERWORK_STARTMONTH';
export const UPDATE_USERWORK_ENDMONTH = 'UPDATE_USERWORK_ENDMONTH';
export const UPDATE_USERWORK_STARTYEAR = 'UPDATE_USERWORK_STARTYEAR';
export const UPDATE_USERWORK_ENDYEAR = 'UPDATE_USERWORK_ENDYEAR';
export const UPDATE_USERWORK_WEBSITE = 'UPDATE_USERWORK_WEBSITE';
export const UPDATE_USERWORK_INDUSTRY = 'UPDATE_USERWORK_INDUSTRY';
export const UPDATE_USERWORK_CURRENTROLE = 'UPDATE_USERWORK_CURRENTROLE';
export const UPDATE_USERWORK_ID = 'UPDATE_USERWORK_ID';

export const UPDATE_USERWORK_TYPE = 'UPDATE_USERWORK_TYPE';
export const UPDATE_USERWORK_TYPE_ERROR = 'UPDATE_USERWORK_TYPE_ERROR';

export const UPDATE_USERWORK_COMPANY_ERROR = 'UPDATE_USERWORK_COMPANY_ERROR';
export const UPDATE_USERWORK_LOCATION_ERROR = 'UPDATE_USERWORK_LOCATION_ERROR';
export const UPDATE_USERWORK_DESIGNATION_ERROR =
  'UPDATE_USERWORK_DESIGNATION_ERROR';
export const UPDATE_USERWORK_STARTMONTH_ERROR =
  'UPDATE_USERWORK_STARTMONTH_ERROR';
export const UPDATE_USERWORK_ENDMONTH_ERROR = 'UPDATE_USERWORK_ENDMONTH_ERROR';
export const UPDATE_USERWORK_STARTYEAR_ERROR =
  'UPDATE_USERWORK_STARTYEAR_ERROR';
export const UPDATE_USERWORK_ENDYEAR_ERROR = 'UPDATE_USERWORK_ENDYEAR_ERROR';
export const UPDATE_USERWORK_WEBSITE_ERROR = 'UPDATE_USERWORK_WEBSITE_ERROR';
export const UPDATE_USERWORK_INDUSTRY_ERROR = 'UPDATE_USERWORK_INDUSTRY_ERROR';

// UserEducation
export const LOAD_USEREDUCATION = 'LOAD_USEREDUCATION';
export const ADD_USEREDUCATION_SAGA = 'ADD_USEREDUCATION_SAGA';

export const UPDATE_USEREDUCATION_EDIT = 'UPDATE_USEREDUCATION_EDIT';

export const DELETE_USEREDUCATION_SAGA = 'DELETE_USEREDUCATION_SAGA';
export const DELETE_USEREDUCATION = 'DELETE_USEREDUCATION';
export const ADD_USEREDUCATION = 'ADD_USEREDUCATION';
export const UPDATE_USEREDUCATION_EDITUPDATE =
  'UPDATE_USEREDUCATION_EDITUPDATE';

export const UPDATE_USEREDUCATION_ERROR = 'UPDATE_USEREDUCATION_ERROR';
export const UPDATE_USEREDUCATION = 'UPDATE_USEREDUCATION';
export const UPDATE_USEREDUCATION_RESET = 'UPDATE_USEREDUCATION_RESET';

export const UPDATE_USEREDUCATION_SCHOOL = 'UPDATE_USEREDUCATION_SCHOOL';
export const UPDATE_USEREDUCATION_COURSE = 'UPDATE_USEREDUCATION_COURSE';
export const UPDATE_USEREDUCATION_STARTMONTH =
  'UPDATE_USEREDUCATION_STARTMONTH';
export const UPDATE_USEREDUCATION_ENDMONTH = 'UPDATE_USEREDUCATION_ENDMONTH';
export const UPDATE_USEREDUCATION_STARTYEAR = 'UPDATE_USEREDUCATION_STARTYEAR';
export const UPDATE_USEREDUCATION_ENDYEAR = 'UPDATE_USEREDUCATION_ENDYEAR';

export const UPDATE_USEREDUCATION_SCHOOL_ERROR =
  'UPDATE_USEREDUCATION_SCHOOL_ERROR';
export const UPDATE_USEREDUCATION_COURSE_ERROR =
  'UPDATE_USEREDUCATION_COURSE_ERROR';
export const UPDATE_USEREDUCATION_STARTMONTH_ERROR =
  'UPDATE_USEREDUCATION_STARTMONTH_ERROR';
export const UPDATE_USEREDUCATION_ENDMONTH_ERROR =
  'UPDATE_USEREDUCATION_ENDMONTH_ERROR';
export const UPDATE_USEREDUCATION_STARTYEAR_ERROR =
  'UPDATE_USEREDUCATION_STARTYEAR_ERROR';
export const UPDATE_USEREDUCATION_ENDYEAR_ERROR =
  'UPDATE_USEREDUCATION_ENDYEAR_ERROR';

  export const LOAD_AWARDS = 'LOAD_AWARDS';


// ViewProject
export const UPDATE_PROJECT_VIEW = 'UPDATE_PROJECT_VIEW';


// admin chat
export const LOAD_ADMIN_PROJECT_CHAT = 'LOAD_ADMIN_PROJECT_CHAT';
export const UPDATE_LOAD_ADMIN_CHAT = 'UPDATE_LOAD_ADMIN_CHAT';
export const GET_ADMIN_PROJECT_CHAT = 'GET_ADMIN_PROJECT_CHAT';
export const UPDATE_ADMIN_CHAT_USERS = 'UPDATE_ADMIN_CHAT_USERS';
export const UPDATE_CHANGE_ADMIN_CHAT = 'UPDATE_CHANGE_ADMIN_CHAT';
export const UPDATE_CHANGE_ADMIN_CHATMESSAGE = 'UPDATE_CHANGE_ADMIN_CHATMESSAGE';
export const UPDATE_SEND_ADMIN_MESSAGE = 'UPDATE_SEND_ADMIN_MESSAGE';
export const UPDATE_LOAD_ADMIN_CHATMESSAGES = 'UPDATE_LOAD_ADMIN_CHATMESSAGES';
export const LOAD_ARTIST_AND_CLIENT = 'LOAD_ARTIST_AND_CLIENT';
export const UPDATE_ARTIST_AND_CLIENT = 'UPDATE_ARTIST_AND_CLIENT';

// style question in createProfile -> collateralFrom
export const LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL =
  'LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL';


//Get Project Review from Client
export const UPDATE_REVIEW_PROJECT_ID = 'UPDATE_REVIEW_PROJECT_ID';
export const LOAD_COLLATERAL_QUESTIONS_IN_USER_COLLATERAL_SAGA =
  'LOAD_COLLATERAL_QUESTIONS_IN_USER_COLLATERAL_SAGA';

// update clients
export const UPDATE_CREATOR_CLIENTS = 'UPDATE_CREATOR_CLIENTS';

//client dashboard
export const DELETE_INCOMPLETE_BRIEF = 'DELETE_INCOMPLETE_BRIEF';


// creator dashboard
export const REJECT_INCOMING_BRIEF = 'REJECT_INCOMING_BRIEF';
export const ACCEPT_INCOMING_BRIEF = 'ACCEPT_INCOMING_BRIEF';

// project review
export const PROJECT_REVIEW_SAGA = 'PROJECT_REVIEW_SAGA';
export const UPDATE_PROJECT_RATING_STATUS = 'UPDATE_PROJECT_RATING_STATUS';
export const UPDATE_POST_PROJECT_DETAILS_FOR_REVIEW = 'UPDATE_POST_PROJECT_DETAILS_FOR_REVIEW';

// error handling saga
export const NOT_FOUND_SAGA = 'NOT_FOUND_SAGA';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_LOGGED_IN_STATE = 'RESET_LOGGED_IN_STATE';



// for new changes Indiefolio************************************************

export const RELEVENT_PROJECTS = "RELEVENT_PROJECTS";
export const RELEVENT_PROJECTS_SUCCESS = "RELEVENT_PROJECTS_SUCCESS";
export const RELEVENT_PROJECTS_ERR = "RELEVENT_PROJECTS_ERR"

export const PROJECT_BY_COLLATERAL = "PROJECT_BY_COLLATERAL";
export const PROJECT_BY_COLLATERAL_SUCCESS = "PROJECT_BY_COLLATERAL_SUCCESS"

export const PROJECT_IDS = "PROJECT_IDS";

export const PROJECT_ATTACHMENTS = "PROJECT_ATTACHMENTS";

export const PROJECT_URLS = "PROJECT_URLS"


export const CLIENT_ACTIVE_PROPOSAL = "CLIENT_ACTIVE_PROPOSAL";
export const CLIENT_ACTIVE_PROPOSAL_SUCCESS = "CLIENT_ACTIVE_PROPOSAL_SUCCESS";
export const CLIENT_ACTIVE_PROPOSAL_ERR = "CLIENT_ACTIVE_PROPOSAL_ERR";

export const APPROVE_PROPOSAL_AM = "APPROVE_PROPOSAL_AM";
export const APPROVE_PROPOSAL_AM_SUCCESS = "APPROVE_PROPOSAL_AM_SUCCESS";
export const APPROVE_PROPOSAL_AM_ERR = "APPROVE_PROPOSAL_AM_ERR"
