import React, { Component } from 'react'
import Select from 'react-select';

export default class SimpleReactSelect extends Component {

  selectValueHandler = (val) => {
    // console.log(val)
    this.props.selectHandler(val)
  }

  // selectHandleChange = (val) => {
    // console.log('adad', val)
    // const {searchable} = this.props
    // if(searchable){
    //   this.props.removeError()
    // }
    // console.log({searchable, val})
  // }

  componentDidMount(){
    // console.log(this.props.options)
  }
  
  render() {

  const {error, errorText, placeholder, value, options, searchable, className, disabled} = this.props
  const style = {
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: error ? "0 0 0 1px red !important" : "none",
      borderColor: error ? "rgb(214 52 71) !important" : 'none',
    }),
  };
  

    return (
      <>
        {/* {title ? <label class="label font-weight-600" for="primary">{title}</label> : null} */}
        <Select
              getOptionLabel={option => option.name }
              getOptionValue={option => option.name }
              isClearable={false}
              // onFocus={() => console.log('focus')}
              isSearchable={searchable ? true : false}
              isDisabled={disabled}
              value={value}
              className={`basic-single simple ${className ? className : ''}`}
              classNamePrefix="select"
              // isMulti={isMulti}
              defaultOptions
              placeholder={placeholder}
              onChange={(e) => this.selectValueHandler(e) }
              // onInputChange={this.selectHandleChange}
              options={options}
              components={{ IndicatorSeparator:() => null }}
              name="color"
              styles={style}
              // menuIsOpen={true}
              // onFocus={}
          />
        {error && errorText ? <span className="text-danger font-weight-600 font-12 py-1">{errorText}</span> : null}
    </>
    )
  }
}