import React, { useEffect, useState } from "react";
import FeaturedToggleImage from "../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import PercentIcon from "../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import { separateNumber } from "../../../ProfileCreator/TimeRetainer";
import Loader from "../../../Common/Common/Loader";
import { getImageSizeFromUrl } from "../../../../../utils/Helperfunctions";
import RenderImageCommonComp from "./RenderImageCommonComp";

const ProjectDetail = (props) => {
  const [imageLogo, setImageLogo] = useState("");
  const project_data = props?.data?.project_data;
  const sub_type = project_data?.sub_type;

  const isIndividual =
    props?.data?.project_data?.company_work_type?.toLowerCase() === "company" ||
    props?.data?.project_data?.company_work_type?.toLowerCase() === "agency"
      ? false
      : true;

  const getImageSize = async (url) => {
    const imgLogo = await getImageSizeFromUrl(url);
    if (imgLogo) {
      setImageLogo(imgLogo);
    }
  };

  useEffect(() => {
    if (!project_data?.company_logo && project_data?.company_add_link) {
      getImageSize(project_data?.company_add_link);
    }
  }, [project_data?.company_logo]);

  const { type, jobs } = props?.match?.params;

  return (
    <div className="page_card d-none d-md-block mb-4">
      <div className="proposal_header br_btm d-flex justify-content-between">
        <div className="prposal_heading d-flex align-items-center">
          <p className="mb-0 text_black_700_w_600">Project Details</p>
        </div>
        <div className="edit_proposal d-flex align-items-center">
          <img
            src={FeaturedToggleImage}
            className="icon_img mr-1 cursor-pointer"
            data-toggle="collapse"
            href={`#collapseExample`}
            role="button"
            aria-expanded="false"
            aria-controls={`collapseExample`}
          />
        </div>
      </div>

      <div
        className="total_project_price row mx-0 px-4 collapse show "
        id={`collapseExample`}
      >
        {project_data?.project_name ? (
          <>
            {props?.data?.collateral?.name?.length > 0 && (
              <div className="col-lg-12 mb-2 px-0 pt-3 d-flex">
                <div className="tag_btn_gray_common">
                  <p className="mb-0">{props.data.collateral.name}</p>
                </div>
              </div>
            )}

            <div className="col-lg-12 mb-2 px-0">
              <p className="mb-0 big_text w-80">
                {project_data?.project_name?.length > 25
                  ? project_data?.project_name?.slice(0, 25) + "..."
                  : project_data?.project_name}
              </p>
            </div>

            {props?.proposalSubmit ? null : isIndividual ? (
              <p className="mb-0 text_light_gray_xs ml-2 mb-2">Individual</p>
            ) : (
              <div className="col-lg-12 mb-2 px-0 d-flex mb-3 align-items-center">
                <div className="avatar user_company_profile_image rounded-circle">
                  <RenderImageCommonComp
                    companyLogo={project_data?.company_logo}
                    companyUrl={project_data?.company_add_link}
                  />
                </div>
                <p className="mb-0 text_light_gray_xs ml-2">
                  {project_data?.company_name}
                </p>
              </div>
            )}

            <div className="col-lg-12 pb-4 px-0 br_btm mb-4 d-flex">
              {sub_type &&
                (sub_type == "fixed" ? (
                  <div className="tag_btn_common">
                    <p className="mb-0">
                      <img src={PercentIcon} alt='' className="icon_img mr-1" />
                      Fixed Scope
                    </p>
                  </div>
                ) : sub_type == "retainer" ? (
                  <div className="tag_btn_green_common">
                    <p className="mb-0">
                      <img
                        src={PercentIconGreen}
                        alt=""
                        className="icon_img mr-1"
                      />
                      Retainer
                    </p>
                  </div>
                ) : (
                  <div className="tag_btn_dark_green_common">
                    <p className="mb-0">
                      <img
                        src={PercentIconDarkGreen}
                        alt=""
                        className="icon_img mr-1"
                      />
                      On-Actuals
                    </p>
                  </div>
                ))}
            </div>

            <div className="col-lg-12 mb-4 px-0 ">
              <div className="project_price_title d-flex justify-content-between">
                {project_data?.were_admin_project && props?.CurrentRole == 2 ? null : 
                  <div className="budget">
                    <p className="mb-0 text_gray_1100_w_600_sm">Budget</p>
                    <p className="font_semi_bold">
                      ₹ {separateNumber(project_data?.min_budget, "")} - ₹{" "}
                      {separateNumber(project_data?.max_budget, "")}
                    </p>
                  </div>
                }
                {/* {props?.isClient ? (
                  <div className="duration">
                    <p className="mb-0 text_highlight text_gray_sm">Deadline</p>
                    <p className="font_semi_bold">25 Dec</p>
                  </div>
                ) : (
                  <div className="duration">
                    <p className="mb-0 text_highlight text_gray_1100_w_600_sm">Duration</p>
                    <p className="font_semi_bold">30-45 Days</p>
                  </div>
                )} */}

                {sub_type == "retainer" ? (
                  <div className="duration">
                    <p className="mb-0 text_highlight text_gray_1100_w_600_sm">
                      Duration
                    </p>
                    <p className="font_semi_bold">
                      {project_data?.number_month}{" "}
                      {project_data?.number_month > 1 ? " Months" : " Month"}
                    </p>
                    {/* <p className="mb-0 text_highlight text_gray_1100_w_600_sm">Weekly Commitment</p>
                    <p className="font_semi_bold">{project_data?.number_hour } {project_data?.number_hour > 1 ? ' Hours/Week' : ' Hour/Week'}</p> */}
                  </div>
                ) : null}
              </div>
              <div className="col-lg-12 px-0 pt-3 text-center ">
                {type && jobs ? (
                  jobs == "dashboard" ? (
                    <a
                      className="mb-0 anchor_primary_text"
                      href={
                        props?.data?.creator_status_brief != 1 &&
                        props?.data?.creator_status_brief != 2 &&
                        props?.CurrentRole == 2
                          ? `/projects/${project_data?._id}/overview`
                          : props?.CurrentRole == 2 ||
                            !props?.isClient ||
                            project_data?.status >= 6
                          ? `/projects/${project_data?._id}/proposal_details/brief`
                          : `/projects/${project_data?._id}/proposal_details`
                      }
                      target="_blank"
                    >
                      View Brief
                    </a>
                  ) : (
                    <></>
                  )
                ) : (
                  <p
                    className="mb-0 anchor_primary_text"
                    onClick={() => {
                      let redirect =
                        props?.data?.creator_status_brief != 1 &&
                        props?.data?.creator_status_brief != 2 &&
                        props?.CurrentRole == 2
                          ? `/projects/${project_data?._id}/overview`
                          : props?.CurrentRole == 2 ||
                            !props?.isClient ||
                            project_data?.status >= 6
                          ? `/projects/${project_data?._id}/proposal_details/brief`
                          : `/projects/${project_data?._id}/proposal_details`;
                      props.history.push(redirect);
                    }}
                  >
                    View Brief
                  </p>
                )}
              </div>
            </div>
          </>
        ) : (
          <Loader
            isCreateModal={true}
            style={{ margin: "auto", marginBottom: "3rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetail;
