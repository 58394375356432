import { 
    RELEVENT_PROJECTS, 
    RELEVENT_PROJECTS_SUCCESS, 
    RELEVENT_PROJECTS_ERR,
    PROJECT_BY_COLLATERAL,
    PROJECT_BY_COLLATERAL_SUCCESS,
    PROJECT_IDS,
    PROJECT_ATTACHMENTS,
    PROJECT_URLS,
    CLIENT_ACTIVE_PROPOSAL,
    CLIENT_ACTIVE_PROPOSAL_SUCCESS,
    APPROVE_PROPOSAL_AM,
    APPROVE_PROPOSAL_AM_SUCCESS,
 } from "../type.action";


// For get Relevent Projects COLLATERAL **********************************

export const releventProjects = (values) => ({
    type: RELEVENT_PROJECTS,
    values
});
export const releventProjectSuccess = (values) => ({
    type: RELEVENT_PROJECTS_SUCCESS,
    values
});

export const releventProjectsErr = (error) => ({
    type : RELEVENT_PROJECTS_ERR,
    error
})


// GET RELEVENT Project by collateral ***************************************************

export const projectbyCollateral = (values) => ({
    type : PROJECT_BY_COLLATERAL,
    values
})

export const projectByCollateralSuccess = (values) => ({
    type : PROJECT_BY_COLLATERAL_SUCCESS,
    values
})


// relevent projects ***********************************************

export const relevent_projects = (values) => ({
    type : PROJECT_IDS,
    values
})

// attachments ****************************************************

export const projectAttachments = (values) => ({
    type : PROJECT_ATTACHMENTS,
    values
})

// url *********************************************************

export const projectUrls = (values) => ({
    type : PROJECT_URLS,
    values
})


// active project for client ************************************

export const activeProposalforClient = (values) => ({
    type : CLIENT_ACTIVE_PROPOSAL,
    values
})

export const activeProposalforclient = (values) => ({
    type : CLIENT_ACTIVE_PROPOSAL_SUCCESS,
    values
})

export const approveProposalFromAm = (values) =>({
    type:  APPROVE_PROPOSAL_AM,
    values
})

export const approveProposalFromAmSucess = (values) =>({
    type:APPROVE_PROPOSAL_AM_SUCCESS,
    values
})