//------------------------------------for-production-----------------------------------*/

export const Baseurl = 'https://api.getcreator.in';
export const emailVerification = 'https://api.getcreator.in';
export const BaseImageUrl = 'https://ifn-images.s3.ap-south-1.amazonaws.com/profile_images/';
export const SocialImageUrl = 'https://ifn-images.s3.ap-south-1.amazonaws.com/assets/';
export const ChatBaseUrl = 'https://api.getcreator.in/';
export const ChatImageBaseUrl = 'https://ifn-images.s3.ap-south-1.amazonaws.com/upload-files/';
export const CollateralBaseUrl  = 'https://ifn-images.s3.ap-south-1.amazonaws.com/collateral/';
export const RazorpayKey = 'rzp_live_u9voXPZhnGJk40'; 
export const prefill = {};
export const redirectURL = "https://home.indiefolio.com"

// google calendar
export const calendarClientID = '675149623541-fvr2ij9bbjhn4tud3qtnpot7fovcg8q3.apps.googleusercontent.com'
export const calendarClientSecret = 'G8gL39Arn92ZMsf51rHt9Z2u'
export const calendarApiKey = 'AIzaSyBAh7hCD18-5FWMUmHeOhKFf0mQ_oLWvr4'
export const WebsiteUrl = "https://www.indiefolio.com/"