import React, { useEffect, useState } from "react";
import CreatorVersionHistory from "../../../Dashboard/VersionHistory/CreatorVersionHistory";
import DetailSideBar from "../../CommonComp/DetailSideBar";
import ViewProp from "../../CommonComp/ViewProp";
import ViewBrief from "../../ViewBrief";
import VisionIcon from "../../../../../../Assets/upgrade-images/view_proposal_fc/vision_historyicon.png";

export default function ProjectDetails(props) {
  const [showTabs, setShowTabs] = useState("");
  const [showHistoryTab, setShowHistoryTab] = useState(false);
  const [propsNum, setPropsNum] = useState("");

  // useEffect(() => {
  //   if (props?.CurrentRole) {
  //     setShowTabs(props?.CurrentRole == 1 || props?.CurrentRole == 3 ? "Brief" : "Proposal");
  //   }
  // }, []);

  const handleSetShowHistoryTab = (value) => {
    setShowHistoryTab(value);
  };

  const projectData = props?.projectDataStates?.data?.project_data;
  const { projectId, connectionId } = props?.match?.params;

  return (
    <div className="row">
      <DetailSideBar {...props} showProjDetail="no" />
      {showHistoryTab ? (
        <CreatorVersionHistory id={props?.id} historyTab={true} handleSetShowHistoryTab={handleSetShowHistoryTab} {...props} />
      ) : (
        <div className="col-lg-9 Project_detail_tab">
          <div className="page_card">
            <div className="row mx-0">
              <div className="col-lg-12 px-0">
                <nav className="mb-0">
                  <div
                    className="nav nav-tabs cstm_tab proposal_header py-0 px-2 d-flex justify-content-between"
                    id="nav-tab"
                    role="tablist"
                  >
                    <div className="d-flex">
                      {(props?.CurrentRole && props?.CurrentRole == 2) || projectData?.status >= 6 ? (
                        <p
                          className={`nav-item nav-link cstm_tab_item refrence_tab_active d-flex align-items-center ${
                            !connectionId ? "active" : ""
                          }`}
                          // onClick={() => setShowTabs("Proposal")}
                          onClick={() => props.history.push(`/projects/${projectId}/proposal_details`)}
                        >
                          <p className="mb-0">{projectData?.status >= 6 ? "Final Proposal" : "Proposal"} </p>
                        </p>
                      ) : null}
                      <p
                        className={`nav-item nav-link cstm_tab_item  d-flex align-items-center ${
                          props?.CurrentRole != 2 && projectData?.status < 6
                            ? "active"
                            : connectionId && connectionId === "brief"
                            ? "active"
                            : ""
                        }`}
                        // onClick={() => setShowTabs("Brief")}
                        onClick={() => {
                          let redirect =
                            props?.CurrentRole == 2 || projectData?.status >= 6
                              ? `/projects/${projectId}/proposal_details/brief`
                              : `/projects/${projectId}/proposal_details`;
                          props.history.push(redirect);
                        }}
                      >
                        <p className="mb-0">Brief</p>
                      </p>
                    </div>
                    {props?.CurrentRole == 2 && propsNum > 1 && projectData?.status == 5 ? (
                      <p
                        className="blue_text_md_w_600 mb-0 d-flex align-items-center mr-5 cursor-pointer"
                        onClick={(e) => {
                          handleSetShowHistoryTab(true);
                        }}
                      >
                        <img src={VisionIcon} className="img-fluid mr-1" />
                        Show version history
                      </p>
                    ) : null}
                  </div>
                </nav>
              </div>
            </div>

            <div className="row mx-0 pb-0 projectDetail">
              {props?.CurrentRole ? (
                props?.CurrentRole != 2 ? (
                  <>
                    {!connectionId && projectData?.status >= 6 ? (
                      <ViewProp id={props?.id} userId="" role={1} {...props} col={12} isClient={true} />
                    ) : null}
                    {(projectData?.status >= 6  && connectionId && connectionId === "brief")|| (projectData?.status < 6 ) ? (
                      <ViewBrief
                        id={props?.id}
                        {...props}
                        col={12}
                        isClient={true}
                        showCloseProj={projectData?.status < 6 ? true : false}
                        isTab={true}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {!connectionId ? (
                      <ViewProp
                        id={props?.id}
                        userId=""
                        role={2}
                        {...props}
                        col={12}
                        isClient={false}
                        showChanges={true}
                        isTab={true}
                        historyTab={true}
                        handleSetShowHistoryTab={handleSetShowHistoryTab}
                        setPropsNum={setPropsNum}
                      />
                    ) : null}
                    {connectionId && connectionId === "brief" ? (
                      <ViewBrief
                        id={props?.id}
                        {...props}
                        col={12}
                        showReject={projectData?.status < 6 ? true : false}
                        showQuestion={props?.projectDataStates?.data?.creator_status_brief == 0 ? true : false}
                        isTab={true}
                      />
                    ) : null}
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
