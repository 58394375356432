import { put, call } from 'redux-saga/effects';
import { fireApi } from '../../../utils/fireApiPrecheck';

export function* loadUserWork() {
  try {
    yield call(loadLocation);
    yield call(loadCompany);
    yield call(loadIndustry);
  } catch (error) {
    throw error;
  }
}

export function* AddExperienceYears() {
  try {
    const userData =  yield fireApi('GetUserData')
    yield put({type: 'UPDATE_EXPERIENCE_YEAR', values: userData.data.user_id.total_experience})
  }  catch (error) {
    throw error;
  }
}

export function* AddWork(data = '') {
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Action = 'AddUserWorkSecond';
    if (data.payload.is_current === true) {
      Action = 'AddUserWork';
    }
    let Work = yield fireApi(Action, data.payload);
    // console.log(Work);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Work.error && Work.error === true) {
      yield put({ type: 'UPDATE_USERWORK_ERROR', values: Work });
    } else {
      if (parseInt(Work.status) === 1) {
        
        yield put({
          type: 'UPDATE_CREATOR_WORK',
          values: {
            company_name: data.payload.name,
            designation: data.payload.designation,
            start_month: data.payload.start_month,
            start_year: data.payload.start_year,
            end_month: data.payload.end_month,
            end_year: data.payload.end_year,
            location: data.payload.location,
            industry: data.payload.industry,
            website_url: data.payload.website_url,
            is_current: data.payload.is_current,
            job_type: data.payload.job_type,
            _id: Work.user_work._id,
            company_image: Work.company_name,
          },
        });
        yield put({ type: 'UPDATE_FORMOPEN', values: false });
        yield put({ type: 'UPDATE_USERWORK_RESET', values: { edit: -1 } });
        yield call(AddExperienceYears)
      }
    }
  } catch (error) {
    throw error;
  }
}

export function* UpdateWorkExperience(data = '') {
  try {
    // console.log('UpdateWorkExperience ', data.payload);
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Action = 'UpdateUserWorkSecond';
    if (data.payload.is_current === true) {
      Action = 'UpdateUserWork';
    }
    let Work = yield fireApi(Action, data.payload);
    // console.log('%c update exp', 'font-size: 22px; color:orange');
    // console.log(Work)
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Work.error && Work.error === true) {
      yield put({ type: 'UPDATE_USERWORK_ERROR', values: Work });
    } else {
      if (parseInt(Work.status) === 1) {
        yield put({
          type: 'UPDATE_USERWORK_EDITWORK',
          values: {
            company_name: data.payload.name,
            designation: data.payload.designation,
            start_month: data.payload.start_month,
            start_year: data.payload.start_year,
            end_month: data.payload.end_month,
            end_year: data.payload.end_year,
            location: data.payload.location,
            industry: data.payload.industry,
            website_url: data.payload.website_url,
            is_current: data.payload.is_current,
            job_type: data.payload.job_type,
            _id: data.payload._id,
            company_image: Work.company_name,
          },
        });
        yield put({ type: 'UPDATE_FORMOPEN', values: false });
        yield put({ type: 'UPDATE_USERWORK_RESET', values: { edit: -1 } });
        yield call(AddExperienceYears)
        return true;
      }
    }
  } catch (error) {
    throw error;
  }
}

export function* deleteWork(data = '') {
  try {
    let Work = yield fireApi('DeleteUserWork', { id: data.payload });
    if (parseInt(Work.status) === 1) {
      yield put({
        type: 'DELETE_CREATOR_WORK',
        values: data.payload,
      });
      yield call(AddExperienceYears)
    }
  } catch (error) {
    throw error;
  }
}

export function* loadLocation(data = '') {
  try {
    let AllLocations = yield fireApi('GetLocation', { name: data });
    yield put({
      type: 'UPDATE_USERWORK_ALLLOCATION',
      values: AllLocations.data,
    });
  } catch (error) {
    throw error;
  }
}

export function* loadCompany(data = '') {
  try {
    let AllLocations = yield fireApi('GetCompany', { name: data });
    yield put({
      type: 'UPDATE_USERWORK_ALLCOMPANY',
      values: AllLocations.data,
    });
  } catch (error) {
    throw error;
  }
}

export function* loadIndustry(data = '') {
  try {
    let AllLocations = yield fireApi('GetIndustry', { name: data });
    yield put({
      type: 'UPDATE_USERWORK_ALLINDUSTRY',
      values: AllLocations.data,
    });
  } catch (error) {
    throw error;
  }
}
