import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../../utils/API";
import ViewProposalModal from "../../Dashboard/VersionHistory/ViewProposalModal";
import { useDispatch } from "react-redux";
import { relevent_projects, projectAttachments, projectUrls } from "../../../../../store/action/proposal.action";

export default function ClientCreatorProposalPopup(props) {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [propError, setPropError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.id || props.user_id) {
      handleApi(props.id, props.user_id);
    }
  }, []);

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: current_ids ? current_ids : [],
      releventProject: data?.allProjectData ? data.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.link_image ? data.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.urls ? data.urls : []));
  };

  const handleApi = async (id, user_id) => {
    setIsLoading(true);
    let res = "";
    try {
      if (props?.unAuthorized) {
        res = await getAPI(`/proposal/get-latest-proposal-data-public?user_id=${user_id}&id=${id}`);
      } else if (props?.CurrentRole == 1 || props?.CurrentRole == 3) {
        res = await getAPI(`/proposal/get-latest-proposal-data?user_id=${user_id}&id=${id}`);
      } else {
        res = await getAPI(`/proposal/proposal-payment?id=${id}`);
      }
    } catch (err) {
      console.log("error");
    }
    if (res) {
      let data = res?.data?.AllProposal;
      setData(data);
      getReferenceData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setPropError(true);
    }
  };

  return (
    <>
      <ViewProposalModal
        {...props}
        userId={props?.user_id ? props?.user_id: props?.userId}
        isMakeChanges={true}
        isLoading={isLoading}
        propError={propError}
        data={data}
      />
    </>
  );
}
