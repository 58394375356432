import React from 'react';
import ProgressBar from './Progressbar';
import { connect } from 'react-redux';
import { putAPI, postAPI } from '../../../utils/API';
import { CollateralBaseUrl } from '../../../utils/BaseUrl';
import { Fragment } from 'react';

class Style extends React.Component {

	state = {
		showDropDownList: false,
		checkBoxImages: [],
		answer: [],
		styleQuestions: [],
		styleError: false,
		isLoading: false,
	}
	componentDidMount = () => {
		const { styleQuestions } = this.props;
		this.setState({
			styleQuestions: styleQuestions,
		})
	}
	handleContinue = (e,skip) => {
		const { selected_collateral, projectId, styleQuestions, post_steps, total_steps } = this.props;
		if(skip) {
			if(this.props.userName){

				putAPI('/project/post-project-skip-style-type',{_id:projectId,type:'style'}).then(res=>{
					this.setState({isLoading: false})
					this.props.history.push('/hire/projectName');	
				}).catch(err=>{
					console.log(err)
					alert("something went wrong")
					return ;
				})

			}else{
				localStorage.setItem("StyleSkip",'yes');

			this.props.history.push('/hire/projectName');


			}
			
	return 	;	
			
		}
		this.setState({isLoading: true})
		if(styleQuestions.length <= 0) {
			this.setState({
				styleError: true,
				isLoading: false,
			})
            //alert("Please select your style");
            return;
        }
		const style_data = {
			collateral_id: selected_collateral,
			_id: projectId,
			question_answer: styleQuestions,
		}
		if (this.props.userName) {
			putAPI('/project/post-project-style-question-answer', style_data).then((res) => {
				if (res.data.status === "1") {
					const status_data = {
						post_project_id: this.props.projectId,
						status: 1,
					}
						this.props.history.push('/hire/projectName')
					this.setState({isLoading: false})
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("StyleQuestionData",JSON.stringify(style_data));
			this.props.history.push('/hire/projectName');
			this.setState({isLoading: false})
		}
	}

	handleDropDownList = () => {
		const { showDropDownList } = this.state;
		this.setState({
			showDropDownList: !showDropDownList,
		})
	}


	SelectAnswer = (item) => {
        let styleQuestions = (this.state.styleQuestions) ? [...this.state.styleQuestions] : [];
		const questionAlreadySelected = styleQuestions.some(el => el._id === item.id);
		// console.log("Question Already Selected====>",questionAlreadySelected);
        if(!questionAlreadySelected) {
            let answers  = [item.answer];
            styleQuestions.push({
                _id: item.id,
				type: item.type,
				question:item.question,
				option: item.option,
				subtext: item.subtext,
                answer: answers,
            })
            this.setState({
                styleQuestions:styleQuestions,
                typeAnswers:answers,
            }, () => this.props.ChooseAnswer(this.state.styleQuestions));
        } else {
            const elIndex = styleQuestions.findIndex(element => element._id === item.id)
            let answers   = styleQuestions[elIndex].answer;
            if(item.type === "radio_button_with_images_with_text" || item.type === "dropdown"){
                answers.splice(0, 1, item.answer);
            }
            else {
                let hasOther = answers.some(el => el.item === "other");
                if(item.answer.item !== "other" && hasOther === false) {
                    answers.indexOf(item.answer) === -1 ? answers.push(item.answer) : (answers = answers.filter(el => el !== item.answer));
                } else if(hasOther === true && item.answer.item !== "other") {
                    answers.splice(0,answers.length,item.answer)
                } else {
                    answers.splice(0,answers.length,item.answer)
                }
            }
            const elementsIndex = styleQuestions.findIndex(element => element._id === item.id)
            let newArray = [...styleQuestions];
            newArray[elementsIndex] = {...newArray[elementsIndex], answer: answers}
            this.setState({
                styleQuestions:newArray,
                typeAnswers: answers,
            }, () => this.props.ChooseAnswer(this.state.styleQuestions));
		}
		this.setState({
			styleError: false,
		})
    }

	render() {

		const { StyleQuestions} = this.props;
		const { showDropDownList, styleQuestions,styleError} = this.state;

		// const Background_1 = require("../../../Assets/images/s-1.jpg");
		// const Background_2 = require("../../../Assets/images/s-2.jpg");
		return (
			<>
				<section>
					<div className="card-wrapper mob-mb-70">
						<div className="card mob-border-radius-0">
							<div className="card-body">
								<div className="row m-0 px-4 pb-4 pt-2 mob-p-10 ipad-only-p-10 ipad-pro-only-p-10 overflow-content project-height">
									<div className="col-12 col-sm-12 mob-px-0 ">
										<div className="row m-0">
											<div className="col-sm-12 mob-px-5 p-0">
												<div className="onboarding_style_questions">
													{
														StyleQuestions.map((obj, index) => {
															if (obj.type === "checkbox_with_images_with_text") {
																return <Fragment key={index}>
																	<div  className="pt-5 mob-pt-20">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "")?
																			<p  className="label font-weight-500 mob-font-13 text-third">{obj.subtext}</p>
																		:<></>} 
																	</div>
																	<ul className="content">
																		{
																			obj.option.map((options, index) => (
																				<li key={index} className="position-relative">
																					<div className={`deadline position-relative 
																						${
																							styleQuestions && styleQuestions.some(el => (el._id === obj._id && el?.answer?.some(item => item.item === options.item))) ? "active" : ""}`
																						}
																						value={options.item} style={{ backgroundImage: `url('${CollateralBaseUrl+options.image}')`,backgroundSize:"cover" }} 
																						onClick={(e) => this.SelectAnswer({
																							id:obj._id,
																							type: obj.type,
																							question: obj.question,
																							subtext: obj.subtext,
																							option: obj.option, 
																							answer:options
																						})} >
																					</div>
																					<p className="style_name">{options.item.length > 35 ? options.item.substring(0,35) + '...' : options.item}</p>
																				</li>
																			))
																		}
																	</ul>
																	{(styleError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your style.</p> : ""}
																</Fragment>
															}
															if (obj.type === "radio_button_with_images_with_text") {
																return <>
																	<div  className="pt-5 mob-pt-20">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "")?
																			<p  className="label font-weight-500 mob-font-13 text-third">{obj.subtext !== "" ? obj.subject : ''}</p>
																		:<></>} 
																	</div>
																	<ul className="content">
																		{obj.option.map((options, index) => (
																			<li key={index} className="position-relative">
																				<div className={`deadline position-relative 
																					${
																						styleQuestions && styleQuestions.some(el => (el._id === obj._id && el?.answer?.some(item => item.item === options.item))) ? "active" : ""}`
																					}
																					onClick={(e) => this.SelectAnswer({
																						id:obj._id,
																						type: obj.type,
																						question: obj.question,
																						subtext: obj.subtext,
																						option: obj.option, 
																						answer:options
																					})} 
																					value={options.item} 
																					style={{ backgroundImage: `url('${CollateralBaseUrl+options.image}')`,backgroundSize:"cover" }} >
																				</div>
																				<p className="style_name">{options.item.length > 35 ? options.item.substring(0,35) + '...' : options.item}</p>
																			</li>
																		))
																		}
																	</ul>
																	{(styleError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your style.</p> : ""}
																</>
															}

															if (obj.type === "dropdown") {
																return <>
																	<div  className="pt-5 mob-pt-20">
																		<h2 className={`font-22 mob-font-16 font-weight-600 ${obj.subtext === "" ? 'mb-4' : 'mb-2'}`}>{obj.question}</h2>
																		{(obj.subtext !== "")?
																			<p  className="label font-weight-500 mob-font-13 text-third">{obj.subtext}</p>
																		:<></>} 
																	</div>
																	<div className="filter-dropdown wrapper-dropdown w-48 mob-w-100 position-relative">
																		<div className="select">
																			<div className="select">
																				<div 
																					className={`select-styled text-dark-black`}
																					onClick={this.handleDropDownList}>
																					{styleQuestions && styleQuestions.some(el => el._id === obj._id) ? styleQuestions.filter(el => el._id === obj._id).map(filteredObj => filteredObj.answer?.[0].item) : <span className="text-secondary font-14">Choose your answer</span>}
																				</div>
																				{obj.option.length > 0 && showDropDownList === true ?

																					<ul className="select-options hideList" style={{ display: "block" }} >
																						<li rel="hide">2020</li>
																						{
																							obj.option.map((options, index) => (
																								<li key={index} onClick={(e) => 
																									this.SelectAnswer({
																										id:obj._id,
																										type: obj.type,
																										question: obj.question,
																										subtext: obj.subtext,
																										option: obj.option, 
																										answer:options
																									})}>{options.item}</li>
																							))
																						}
																					</ul> : ""
																				}
																			</div>
																		</div>
																		<i className="fas fa-chevron-down select-arrow" style={{ top: "20px", right: "20px" }}></i>
																	</div>
																	{(styleError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your style.</p> : ""}
																</>
															} else return null
														})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<ProgressBar skip={this.props.isHireflow?true:false} {...this.state} handleContinue={this.handleContinue} />
						</div>
					</div>
				</section>
			</>
		)
	}
}

const mapState = (store) => {
	let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
	return {
		selected_collateral: postProjectData.selected_collateral,
		style_answers: postProjectData.style_answers,
		projectId: postProjectData.projectId,
		post_steps: postProjectData.post_steps,
		total_steps: postProjectData.total_steps,
		hireAgainCreatorId:  postProjectData.hireAgainCreatorId,
    	isHireAgain:  postProjectData.isHireAgain,


		StyleQuestions: (postProjectData?.styleQuestions?.length > 0) ?  (postProjectData.styleQuestions) : postProjectData?.CollateralQuestions?.Collateral_style_question,
		styleQuestions: (postProjectData?.styleQuestions?.length > 0) ? (postProjectData.styleQuestions) : [],

		
		alreadyCreatedProject: postProjectData.alreadyCreatedProject,
		userName: globalReducer.Me.Username,
	};
}

const mapDispatch = dispatchEvent => ({
	OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
	ChooseAnswer: (answer) => dispatchEvent({ type: "CHOOSE_STYLE_ANSWER", payload: answer }),
	PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
	OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state}),
	changeHireAgainStatus: (val) => dispatchEvent({ type: 'UPDATE_HIRE_AGAIN_STATUS', values: {creatorId: '', isHireAgain: val} }),
});

export default connect(mapState, mapDispatch)(Style);