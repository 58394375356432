import React from 'react';
import axios from 'axios';
import { Baseurl } from '../../../utils/BaseUrl';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
const IMAGE_BASE_URL = 'https://ifn-images.s3.ap-south-1.amazonaws.com/project_images/';

class TestDiv extends React.Component {
    state = {
        src: null,
        crop: {
          unit: '%',
          width: 30,
          aspect: 4 / 3,
        },
        isLoading: false,
      };

    componentDidMount(){
      this.setState({
        isLoading: true,
      })

      // this.setState({
      //     src: this.props.imageForCrop.image_original,
      //     isLoading:false,
      //   })
        const that = this;
        let url = this.props.preview_image.image_original ? this.props.preview_image.image_original : this.props.preview_image.value ;
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          // let blob = xhr.response;
            var reader = new FileReader();
            reader.onloadend = function() {
              // console.log(reader.result)
                that.setState({ 
                  src: reader.result,
                  isLoading:false,
                })
              }
              reader.readAsDataURL(xhr.response);
            };
        xhr.open('GET', url);
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.send();
      }
    
      onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
          );
          reader.readAsDataURL(e.target.files[0]);
        }
      };
    
      // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
    
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ 
            croppedImageUrl,
          });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.ceil(crop.width*scaleX);
        canvas.height = Math.ceil(crop.height*scaleY);
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width*scaleX,
          crop.height*scaleY,
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              return;
            }
            this.setState({
              fileBlob: blob,
            })
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg',1);
        });
      }
    
      uploadCroppedImage = (action) => {
        let croppedImageArray = this.props.croppedImagesArray;
        // let croppedImageIndex = this.props.croppedImageIndex;
        var token = localStorage.getItem('SignUpidToken');
        let url =  this.state.fileBlob;
        let fileName = new File([url], 'cropped_image.jpeg');

        // close popup
        if(action === "cancel") {
          this.setState({
            src: null,
          })
          this.props.CropImage("", croppedImageArray, this.props.croppedImageIndex);
          this.props.closePopup();
          return;
        }

        const formData = new FormData();
        formData.append('projectImages', fileName);
        const config = {
          headers: {
              'content-type': 'multipart/form-data',
              'Authorization': 'Bearer ' + token,
          }
      };
        axios.post(Baseurl + `/project/upload-project-images`, formData, config)
          .then((res) => {
            if (res.data.status === "1") {
              const { images_or_links, preview_image } = this.props;

              const newArray = images_or_links.map(el => { 
                if(el.id === preview_image.id){
                  el.value = IMAGE_BASE_URL + res.data.project_images[0].name;
                  return el;
                } else {
                  return el;
                }
              })

              // update images_or_link array and preview image
              this.props.setImagesOrLinks(newArray)
              this.props.setPreviewImage(newArray.filter(el => el.id === preview_image.id)[0])
              this.props.closePopup();

            //     let array = [];
            //     croppedImageArray.map((item,i) => {
            //     if(i === this.props.croppedImageIndex) {
            //         array.push({...item,value:IMAGE_BASE_URL+res.data.project_images[0].name, is_cropped:true});
            //         this.setState({
            //           cropSrc:IMAGE_BASE_URL+res.data.project_images[0].name,
            //         });
            //     } else {
            //       array.push({...item});
            //     }  
            // })
                // console.log("Cropped Image",array);
                // croppedImageArray.splice(0,croppedImageArray.length, ...array);
                // this.props.changeField(croppedImageArray[0].value,croppedImageArray)
                // this.props.CropImage("", croppedImageArray, this.props.croppedImageIndex);
                // this.props.handleThumbnail(croppedImageIndex,"image",this.state.cropSrc,true,this.state.src);
                // this.props. closePopup();
                // console.log("Cropped Image array ===",array)
            }
          }).catch((error) => {
            alert("Error uploading image, Please try again");
            console.log("Project image api error---->", error)
          })
      }
      render() {
        const { crop, src,isLoading } = this.state;
        return ( 
          <>
          <div class="mob-text-left text-right p-3">
            <button className="main-btn mob-font-13 font-12 py-10 pr-40"
              onClick={(e) => this.uploadCroppedImage("crop")}
            >
              Crop & Save Image
            </button>
            <button className="main-btn mob-font-13 ml-2 font-12 py-10 pr-40"
              onClick={(e)=>this.uploadCroppedImage("cancel")}
            >
              Cancel Crop
            </button>
          </div>
          <div className={`card-wrapper mb-0 overflow-content text-center ${isLoading ? 'crop-image' : ''}`}> 
            {/* <div>
              <input type="file" accept="image/*" onChange={this.onSelectFile} />
            </div> */}
            {(src && !isLoading) ? (
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            ) :
            <div className="spinner-wrapper">
              <div className="spinner-border"></div>
            </div>
            }
            {/* {croppedImageUrl && (
              <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
            )} */} 
          </div>
          </>
        );
      }
}

const mapStateToProps = (state) => {
  const {
      project_image,
      cropImageSrc,
      croppedImagesArray,
      croppedImageIndex,
      imageForCrop,
      images_or_links,
      preview_image
  } = state.project;
  return {
      project_image,
      cropImageSrc,
      croppedImagesArray,
      croppedImageIndex,
      imageForCrop,
      images_or_links,
      preview_image
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    CropImage: (image,array,active_thumbnail)  => dispatch({ type: 'CROP_IMAGE', payload: {image:image,images_or_links:array,index:active_thumbnail}}),
    changeField: (image,array) => dispatch({ type: 'FIELD_CHANGE', payload: { type: "PROJECT_IMAGE", payload: {image:image,images_or_links:array} } }),
    setImagesOrLinks: (array) => dispatch({ type: 'SET_IMAGES_OR_LINKS', values: array}),
    setPreviewImage: (imageObj) => dispatch({ type: 'SET_PREVIEW_IMAGE', values: imageObj}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestDiv)