import React, { useEffect } from "react";
import TrashImage from "../../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import { handleSelectedNames, onkeyCode } from "../../CommonFunctionsNew/CommonFunctionsNew";
import PriceImage from "../../../../../../Assets/upgrade-images/submit_proposal/price.svg";
import PaymentListItem from "./PaymentListItem";

const PaymentTerms = ({ states, setStates, validationPayment, scroll }) => {
  useEffect(() => {
    handleSetFields();
  }, [states.deliverable]);
  const handleSetFields = () => {
    let arr = [];
    let final = [];
    let copyArr = [];

    states?.deliverable &&
      states.deliverable.length > 0 &&
      states.deliverable.forEach((item) => {
        if (item.name.length > 0) arr.push(item);
      });
    if (states.payments?.length > 0 && states.payments?.[0].names?.length > 0) {
      states.payments.forEach((items) => {
        let arrName = [];
        arr.forEach((obj, i) => {
          let fill = obj?.name == items?.names?.[i]?.name;
          fill ? arrName.push(items?.names?.[i]) : arrName.push(obj);
        });
        final.push({ ...items, names: arrName });
      });

      copyArr =
        final?.length > 0
          ? final?.[0]?.names?.map((item) => {
              return { ...item, isDisable: false };
            })
          : JSON.parse(JSON.stringify(arr));
    } else {
      copyArr = JSON.parse(JSON.stringify(arr));
      final = [
        {
          names: arr,
          breakup: "",
          toggle: false,
          validation: { name: false, price: false },
        },
      ];
    }

    // console.log("123 === ",arr)
    setStates({
      ...states,
      payments: final,
      copyDeliverable: copyArr,
    });
  };

  const handleChangeBreakup = (e, i) => {
    const { value } = e.target;
    states.payments[i].breakup = value;
    states.payments[i].validation.price = value ? false : true;
    setStates({
      ...states,
      payments: [...states.payments],
    });
  };

  const handleAppend = () => {
    let valid = validationPayment();
    // console.log("states.copyDeliverable = = ", states.copyDeliverable);
    if (valid) {
      let final = [];
      states.copyDeliverable.forEach((ele) => {
        final.push({ ...ele, isDisable: ele.check ? true : false });
      });
      setStates({
        ...states,
        payments: [
          ...states.payments,
          {
            names: final,
            breakup: "",
            toggle: false,
            validation: { name: false, price: false },
            _id: "",
            restrict: false,
          },
        ],
      });
    }
  };

  const handleToggleAdvanceFees = (e) => {
    const { checked } = e.target;
    setStates({
      ...states,
      advanceFees: {
        name: "Advance Fees",
        breakup: "",
        show: checked,
        status: 0,
      },
    });
  };

  const handleDelete = (array, i) => {
    let arr = [];
    let match = [];
    array.forEach((elem, i) => {
      if (elem.check && !elem.isDisable) {
        states.copyDeliverable[i] = { ...states.copyDeliverable[i], check: false };
        match.push({ name: elem.name, index: i });
      }
    });

    const checkInclude = (matchArr, name, i) => {
      let isMatch = false;
      matchArr.forEach((el) => {
        if (el.name == name && i == el.index) isMatch = true;
      });
      return isMatch;
    };
    states.payments.forEach((item, index) => {
      let nameArr = [];
      if (index != i) {
        item.names.forEach((ele, x) => {
          if (checkInclude(match, ele.name, x)) {
            nameArr.push({ ...ele, check: false, isDisable: false });
          } else {
            nameArr.push({ ...ele });
          }
        });
        arr.push({ ...item, names: nameArr });
      }
    });

    setStates({
      ...states,
      payments: [...arr],
    });
  };

  const handleCheckBox = (e, i, ind) => {
    // const { checked } = e.target;
    // console.log("handleCheckBox = ", checked, i, ind);
    let arr = [];

    states.payments.forEach((item, index) => {
      let nameArr = [];
      if (index != i) {
        item.names.forEach((ele, x) => {
          if (x == ind) {
            nameArr.push({ ...ele, check: !ele.check, isDisable: !ele.check });
          } else {
            nameArr.push({ ...ele });
          }
        });
        let valid = nameArr.filter((item) => item.check).length == 0;
        item.validation.name = valid;
        arr.push({ ...item, names: nameArr });
      } else {
        item.names.forEach((elem, ix) => {
          if (ix == ind) {
            nameArr.push({ ...elem, check: !elem.check, isDisable: false });
          } else {
            nameArr.push({ ...elem });
          }
        });
        let valid1 = nameArr.filter((item) => item.check).length == 0;
        item.validation.name = valid1;
        arr.push({ ...item, names: nameArr, _id: "" });
      }
    });

    states.copyDeliverable[ind].check = !states.copyDeliverable[ind].check;
    setStates({
      ...states,
      payments: [...arr],
      copyDeliverable: [...states.copyDeliverable],
    });
  };

  const handleToggleSelect = (i) => {
    let pay = states.payments.map((item, index) => {
      if (index == i) return { ...item, toggle: !item.toggle };
      else return { ...item, toggle: false };
    });
    setStates({
      ...states,
      payments: [...pay],
    });
  };

  const handleAdvance = (e) => {
    const { value } = e.target;
    states.advanceFees.breakup = value;
    states.validate.advanceFees = value ? false : true;
    setStates({
      ...states,
      advanceFees: { ...states.advanceFees },
    });
  };

  let validPayTerm =
    !states.checkPayError || states.payments?.[0]?.names?.filter((item) => !item.check).length == 0 ? "" : scroll("center");

  return (
    <React.Fragment>
      <div className="row p-4 payment_terms mx-0  scroll_bar_body">
        <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
          <p className="mb-0 text_black_md">Payment Terms</p>
          <div className="d-block d-lg-none">
            <img
              src={FeaturedToggleImage}
              className="icon_img mr-1 cursor-pointer"
              data-toggle="collapse"
              href={`#collapsePaymentTerms`}
              role="button"
              aria-expanded="false"
              aria-controls={`collapsePaymentTerms`}
            />
          </div>
          <div className="advance_toggle_btn d-none d-lg-block d-lg-flex align-items-center justify-content-center">
            <div className="toggle_btn pr-3">
              <label className="switch mb-0">
                <input
                  type="checkbox"
                  disabled={states?.advanceFees?.status == 2 ? true : false}
                  checked={states?.advanceFees?.show}
                  onChange={handleToggleAdvanceFees}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <p className="mb-0"> Advance</p>
          </div>
        </div>
        <div className="row mx-0 collapse show " id={`collapsePaymentTerms`}>
          <div className="col-lg-12 px-0 py-3">
            <div className="advance_toggle_btn d-block d-lg-none mb-4">
              <div className="toggle_btn d-flex align-items-center">
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    disabled={states?.advanceFees?.status == 2 ? true : false}
                    checked={states?.advanceFees?.show}
                    onChange={handleToggleAdvanceFees}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="mb-0 pl-3"> Advance</span>
              </div>
            </div>
            <p className="description">
              Use this section to match each payment milestone to one or more deliverables. If you'd like for the client to pay an
              advance, kindly switch the toggle above.{" "}
            </p>
          </div>

          <div className="custom_table col-lg-12 px-0 d-none d-lg-block">
            <div class="row m-0 pb-3 br_btm text_text-gray-1400">
              <div class="col-xxl-8 col-lg-7 d-flex justify-content-start align-items-center">
                <span class="mr-2 w-5">#</span>
                <span>Payment Milestones</span>
              </div>
              <div class="col-lg-2 col-2">
                <span>Breakup</span>
              </div>
              <div class="col-lg-2 col-2">
                <span></span>
              </div>
            </div>

            {states?.advanceFees?.show ? (
              <div class="row mx-0 mb-0 py-3 align-items-center row-border creator-proposal">
                <div class="col-xxl-8 col-lg-7">
                  <div className="d-flex justify-content-start align-items-center">
                    <span class="mr-2 w-5">1</span>
                    <input type="text" className="form-control input_md" value={states?.advanceFees?.name} disabled={true} />
                  </div>
                </div>
                <div class="col-xxl-2 col-lg-3 pr-0">
                  <div className="price_input">
                    <input
                      type="number"
                      disabled={states?.advanceFees?.status == 2 ? true : false}
                      className={`form-control input_md ${states.validate.advanceFees ? "border-danger" : ""}`}
                      value={states?.advanceFees?.breakup}
                      placeholder="Price"
                      onChange={handleAdvance}
                      onKeyDown={(evt) =>
                        (evt.key === "e" ||
                          evt.key === "=" ||
                          evt.key === "-" ||
                          evt.key === "+" ||
                          evt.key === "_" ||
                          evt.key === ".") &&
                        evt.preventDefault()
                      }
                    />
                    <img src={PriceImage} className="price_icon img-fluid"></img>
                  </div>
                  {states.validate.advanceFees ? <p className="error_message">field required</p> : ""}
                </div>
                {states?.advanceFees?.status == 2 ? null : (
                  <div class="col-xxl-2 col-lg-2 d-flex justify-content-end pr-0">
                    <img
                      src={TrashImage}
                      className="trash_icon img-fluid cursor-pointer"
                      onClick={() => {
                        setStates({
                          ...states,
                          advanceFees: {
                            name: "Advance Fees",
                            breakup: "",
                            show: false,
                            status: 1,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {states?.payments?.map((item, i) => {
              return (
                <>
                  <div class="row mx-0 mb-0 py-3 align-items-center row-border creator-proposal">
                    <div className="col-xxl-8 col-lg-7  d-flex justify-content-start align-items-center">
                      <span class="mr-2 w-5">{states?.advanceFees?.show ? i + 2 : i + 1}</span>
                      <PaymentListItem
                        key={"qqq" + i}
                        handleToggleSelect={handleToggleSelect}
                        i={i}
                        setStates={setStates}
                        handleSelectedNames={handleSelectedNames}
                        handleCheckBox={handleCheckBox}
                        item={item}
                      />
                    </div>

                    <div class="col-xxl-2 col-lg-3 pr-0">
                      <div className="price_input">
                        <input
                          type="number"
                          className={`form-control input_md ${item.validation.price ? "border-danger" : ""}`}
                          value={item.breakup}
                          placeholder="Price"
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "=" ||
                              evt.key === "-" ||
                              evt.key === "+" ||
                              evt.key === "_" ||
                              evt.key === ".") &&
                            evt.preventDefault()
                          }
                          // disabled={item?.status == 2 ? true : false}
                          disabled={item?.restrict ? true : false}
                          onChange={(e) => handleChangeBreakup(e, i)}
                        />
                        <img src={PriceImage} className="price_icon img-fluid"></img>
                      </div>
                      {item.validation.price ? <p className="error_message">field required</p> : ""}
                    </div>

                    {item?.restrict ? null : (
                      <div class="col-xxl-2 col-lg-2 d-flex justify-content-end pr-0">
                        <img
                          src={TrashImage}
                          className="trash_icon img-fluid cursor-pointer"
                          onClick={() => handleDelete(item.names, i)}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}

            {states.payments?.length > 0 && states.payments?.[0]?.names?.filter((item) => !item.check).length == 0 ? (
              ""
            ) : (
              <div className="row mt-3 mx-0">
                <p className="add_on_milestone cursor-pointer" onClick={handleAppend}>
                  <span className="add_on_milstone_icon">+</span>Add Payment Milestone
                </p>
              </div>
            )}
            {!states.checkPayError || states.payments?.[0]?.names?.filter((item) => !item.check).length == 0 ? (
              ""
            ) : (
              <span style={{ color: "red" }}>You didn't fill all the milestone</span>
            )}
          </div>

          {/* mobile view */}

          <div className="custom_table p-0 col-lg-12 d-block d-lg-none">
            {states?.advanceFees?.show ? (
              <div class="row border-top pt-2 mx-0 mb-0 py-3 align-items-center row-border creator-proposal">
                {states?.advanceFees?.status == 2 ? null : (
                  <div class="col-12 d-flex justify-content-between">
                    <span className="text_black_md">Payment Milestones</span>
                    <img
                      src={TrashImage}
                      className="trash_icon img-fluid cursor-pointer"
                      onClick={() => {
                        setStates({
                          ...states,
                          advanceFees: {
                            name: "Advance Fees",
                            breakup: "",
                            show: false,
                            status: 1,
                          },
                        });
                      }}
                    />
                  </div>
                )}
                <div class="col-12 mb-2">
                  <label className="sub_title_commn">Select Deliverables</label>
                  <input type="text" className="form-control input_md" value={states?.advanceFees?.name} disabled={true} />
                </div>
                <div class="col-12 mb-2">
                  <label className="sub_title_commn">Price Breakup</label>
                  <div className="price_input">
                    <input
                      type="number"
                      disabled={states?.advanceFees?.status == 2 ? true : false}
                      className={`form-control input_md ${states.validate.advanceFees ? "border-danger" : ""}`}
                      value={states?.advanceFees?.breakup}
                      placeholder="Price"
                      onChange={handleAdvance}
                      onKeyDown={(evt) =>
                        (evt.key === "e" ||
                          evt.key === "=" ||
                          evt.key === "-" ||
                          evt.key === "+" ||
                          evt.key === "_" ||
                          evt.key === ".") &&
                        evt.preventDefault()
                      }
                    />
                    <img src={PriceImage} className="price_icon img-fluid"></img>
                  </div>
                  {states.validate.advanceFees ? <p className="error_message">field required</p> : ""}
                </div>
              </div>
            ) : (
              ""
            )}

            {states?.payments?.map((item, i) => {
              return (
                <>
                  <div className="row mx-0 br_btm mb-0 py-3 align-items-center row-border creator-proposal">
                    <div className="col-12 d-flex justify-content-between">
                      <span className="text_black_md">Payment Milestone {states?.advanceFees?.show ? i + 2 : i + 1}</span>
                      {item?.restrict ? (
                        null
                      ) : (
                        <img
                          src={TrashImage}
                          className="trash_icon img-fluid cursor-pointer pr-0"
                          onClick={() => handleDelete(item.names, i)}
                        />
                      )}
                    </div>
                    <div className="col-12 mb-2">
                      <label className="sub_title_commn">Select Deliverables</label>
                      <PaymentListItem
                        key={"qqq" + i}
                        handleToggleSelect={handleToggleSelect}
                        i={i}
                        setStates={setStates}
                        handleSelectedNames={handleSelectedNames}
                        handleCheckBox={handleCheckBox}
                        item={item}
                      />
                    </div>

                    <div class="col-12">
                      <label className="sub_title_commn">Price Breakup</label>
                      <div className="price_input">
                        <input
                          type="number"
                          className={`form-control input_md ${item.validation.price ? "border-danger" : ""}`}
                          value={item.breakup}
                          placeholder="Price"
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "=" ||
                              evt.key === "-" ||
                              evt.key === "+" ||
                              evt.key === "_" ||
                              evt.key === ".") &&
                            evt.preventDefault()
                          }
                          // disabled={item?.status == 2 ? true : false}
                          disabled={item?.restrict ? true : false}
                          onChange={(e) => handleChangeBreakup(e, i)}
                        />
                        <img src={PriceImage} className="price_icon img-fluid"></img>
                      </div>
                      {item.validation.price ? <p className="error_message">field required</p> : ""}
                    </div>
                  </div>
                </>
              );
            })}

            {states.payments?.length > 0 && states.payments?.[0]?.names?.filter((item) => !item.check).length == 0 ? (
              ""
            ) : (
              <div className="row mt-3 mx-0">
                <p className="add_on_milestone cursor-pointer" onClick={handleAppend}>
                  <span className="add_on_milstone_icon">+</span>Add Payment Milestone
                </p>
              </div>
            )}
            {!states.checkPayError || states.payments?.[0]?.names?.filter((item) => !item.check).length == 0 ? (
              ""
            ) : (
              <span style={{ color: "red" }}>You didn't fill all the milestone</span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentTerms;
