import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { clickSignUp } from '../../../../store/action/helmet.action';
import {connect} from 'react-redux'
class Header extends Component{

    openModel=(e)=>{
        this.props.onClickNote();
        this.props.setHomeState({emailModal: true})
    }
    
    render(){

        const {isArtistPage, hideLoginAndHireBtn, service, userProfile} = this.props;
        const clientName = userProfile ? userProfile.first_name + " " + userProfile.last_name : '';
        const clientEmail = userProfile ? userProfile.email : '';
        const clientPhone = userProfile ? userProfile.phone_no : '';
        const collateral = service && service.collateralName ? service.collateralName : '';
        // console.log(clientName, clientEmail, clientPhone, collateral, this.props);
        return(
            <React.Fragment>
                <section className="main-header bg-white sticky-top box-shadow-light">
                    <div className={`container-fluid main-header main-header-landing max-w-1440 ${isArtistPage ? 'max-w-1440' : ''}`}>
                        <div className="mobile-ipad-show ipad-pt-10 mob-py-10 ipad-py-10">
                            <div className="d-flex align-items-center justify-content-between"> 
                                <div className="nav-item text-right">
                                    <a className="navbar-brand mobile-ipad-show" href="/"> 
                                        <img src={require("../../../../Assets/images/landing/IndieFolio_logo_2022.png")} width="130" className="img-fliud" alt="Indiefolio"/>
                                    </a>
                                </div>
                                {/* <div className="nav-item text-right">
                                    <a className="nav-link font-13 font-weight-600 text-white mob-p-0" href="#">All Services</a>
                                </div>
                                <div className="nav-item text-right px-4">
                                    <a className="nav-link font-13 font-weight-600 text-white mob-p-0" href="#">Hire An Expert</a>
                                </div> */} 
                                { service && service.button.length > 0 ? 
                                 <li className="nav-item justify-content-end">
                                    {/* <a className="nav-link main-btn py-8 text-white" target="_blank" href={service.buttonURL}> */}
                                    <a className="nav-link main-btn py-8 text-white" target="_blank" rel="noopener noreferrer" href={`/hire/schedule-call-service-pages?name=${clientName}&email=${clientEmail}&num=${clientPhone}&collateral=${collateral}`}>
                                        START HIRING
                                    </a>
                                </li>
                                :
                                <div className="nav-item text-right">
                                    <a className="nav-link main-btn py-8" href="/" onClick={e=>{e.preventDefault();this.openModel();}}>Login </a>
                                </div>
                                }
                            </div> 
                        </div>
                        <div className="mobile-ipad-none px-70">
                            <nav className={`navbar navbar-expand-lg navbar-light ipad-normal-p-0 ${isArtistPage ? 'px-0' : ''}`}>
                                <a className="navbar-brand mobile-ipad-none" href="/"> 
                                    <img src={require("../../../../Assets/images/landing/IndieFolio_logo_2022.png")} className="img-fliud" alt="Indiefolio"/>
                                </a>
                                <a className="navbar-brand hidden" href="/"> 
                                    <img src={require("../../../../Assets/images/landing/IndieFolio_logo_2022.png")} className="img-fliud" alt="Indiefolio"/>
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav d-flex align-items-center pl-3">
                                    <li className="nav-item active d-none">
                                        <a className="nav-link post_project position-relative" href="/" onClick={e=>e.preventDefault()}>Post a Project <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item d-none">
                                        <a className="nav-link" href="/" onClick={e=>e.preventDefault()}>I'm a Freelancer</a>
                                    </li>
                                    </ul>
                                </div>
                                {!hideLoginAndHireBtn ? <div>
                                    <ul className="navbar-nav d-flex align-items-center">
                                        {/* <li className="nav-item justify-content-end"> 
                                            <NavLink className="nav-link font-weight-600 pr-0" to="/Allservices"> All Services</NavLink>
                                        </li>*/} 
                                        { service && service.button.length > 0 ? 
                                            null : 
                                            <li className="nav-item justify-content-end">
                                                <Link className="nav-link text-dark-black outline-btn cursor-pointer" onClick={() => localStorage.setItem('prevPath',window.location.pathname)}
                                                    to={{
                                                    pathname: `/hire`,
                                                    state: { prevPath : window.location.pathname },
                                                }}
                                                >
                                                    Hire An Expert 
                                                </Link>
                                            </li>  
                                        }
                                        {service && service.button.length > 0 ?
                                        <li className="nav-item justify-content-end">
                                            {/* <Link to={`/services/hire?collateral=${service.collateralName}&previousUrl=${window.location.href}`} className="nav-link font-weight-800 text-white main-btn" href={() => {}}>START HIRING </Link> */}
                                            <Link to={`/hire/schedule-call-service-pages?name=${clientName}&email=${clientEmail}&num=${clientPhone}&collateral=${collateral}`} 
                                                className="nav-link font-weight-800 text-white main-btn" href={() => {}}>START HIRING </Link>
                                        </li> 
                                        :
                                        <li className="nav-item justify-content-end">
                                            <a className="nav-link font-weight-800 text-white main-btn" href="/"  
                                            onClick={e=>{e.preventDefault();this.openModel();}}>Login / Signup</a>
                                        </li> 
                                        }
                                        
                                    </ul> 
                                </div> : null}
                            </nav>
                        </div> 
                    </div> 
                </section>
            </React.Fragment>
        )
    }
}
const mapStateToProps =(state)=>{

    return {
        signUp: state.loginReducer.signup 
    }
}

const mapDispatchToProps=(dispatch)=>{

    return {
        onClickNote : ()=>dispatch(clickSignUp())
    }
 
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header))