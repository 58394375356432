import React,{Component} from 'react';
import firebaseIs from '../../../../utils/fire';
import {Link, NavLink} from 'react-router-dom';
import { ChatBaseUrl } from '../../../../utils/BaseUrl';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client'
import closeIcon from './../../../../Assets/images/close.png';
import { logoutUserRedirect } from '../../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';

class DashboardHeader extends Component{

    state={
        profileImg :'',
        dashboardMessageCount: 0
    }

    componentDidMount() {
        const socket = socketIOClient(ChatBaseUrl, 
            // { transports: ['websocket'] }
            );
            this.setState({socket}, () => {
                this.joinSocket()
                this.listenSocket()
            })
        
    }

    joinSocket = () => {
        this.state.socket.emit('join_msg_user', {_id: this.props.Id})
    }

    resetDashboardNotification = () => {
        const {dashboardNotificationCount, clearDashboardNotification,  } = this.props
        if(dashboardNotificationCount){
            clearDashboardNotification()
            this.loadDashboardData()
        }
    }

    resetMessageNotification = () => {
        const {messageNotificationCount, clearMessageNotification  } = this.props
        if(messageNotificationCount){
              clearMessageNotification()
        }
    }

    listenSocket = () => {
        this.state.socket.on(this.props.Id, resp => {
            const {CurrentRole, Id} = this.props
            // console.log('socket response 1', resp)
            const countMsg = (CurrentRole === 1 && Id === resp._id) ? resp.client_message_count : resp.creator_message_count
            this.props.updateMessageNotificationCount(countMsg)

            const count = (CurrentRole === 1 && Id === resp._id) ? resp.client_dashboard : resp.creator_dashboard
            this.props.updateDashboardNotificationCount(count)

            const completedCount = (CurrentRole === 1 && Id === resp._id) ? resp.client_dashboard_completed : resp.creator_dashboard_completed
            this.props.updateCompletedProjectNotificationCount(completedCount)
        })
    }
    
      
    loadDashboardData = () => {
        if(this.props.CurrentRole === 1){
          this.props.LoadInCompleteBrief();
        } else {
          this.props.LoadInComingBrief()
        }
    }

    showCloseIconRoutes = ['/uploadproject/choose_collateral',
     '/uploadprojects/project_details', '/self-service-hire',
      '/profile_selection', '/project_details/project', '/self-service-hire/budget', '/self-service-hire/unit_selection', '/self-service-hire/deadline', '/self-service-hire/type', '/self-service-hire/style', '/contactus']

    Logout=()=>{
        const that = this;
        localStorage.removeItem("SignUpidToken")
        firebaseIs.auth().signOut().then(function() {
            // alert("signOut successfully")
            that.props.setIndexRoutingState({authIs: false})
            that.props.loadMe({
                name: 'UPDATE_LOAD_LOGGEDIN_USER',
                value: [],
            });
            // that.props.history.push('/')
            logoutUserRedirect()
        }).catch(function(error) {
            console.log("logout error", error);
        });
    }

    render(){
        const {userProfile} = this.props;
        const {current_set_role, review_status} = userProfile;
        const {location, CurrentRole, ApprovedStatus,  isAdmin, messageNotificationCount, dashboardNotificationCount } = this.props;
        const {ProfileImage} = this.props.Me
        
        const isWiderHeader = location.pathname === '/dashboard' || location.pathname === '/dashboard/completed-projects' || location.pathname === '/dashboard/jobs' || location.pathname.includes('chat') || location.pathname.includes('/adminChat');
        const onServicePage = location.pathname.includes('/services');
        let [,collateral] = location.pathname.split('/services');
     
        
        collateral = collateral ? collateral.substring(1).split('-').join(' ') : '';
        
        collateral = collateral.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      

        const clientName = userProfile ? userProfile.first_name + " " + userProfile.last_name : '';
        const clientEmail = userProfile ? userProfile.email : '';
        const clientPhone = userProfile ? userProfile.phone_no : '';
        
        const {first_name, email} = this.props.userProfile;
        
        return(
            <React.Fragment>
                <section className="main-header bg-custom">
                    <div className={`container-fluid main-header position-relative ${ isWiderHeader ? '' : 'max-w-1440'}`}>
                        <nav className="navbar navbar-expand-lg navbar-light pt-0 pb-0">
                            <Link to="/dashboard" className="navbar-brand mx-auto mob-pl-15" ><img src={require("../../../../Assets/images/landing/IndieFolio_logo_white.png")} className="img-fluid" alt="Indiefolio" title="IndieFolio"/></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon " data-toggle="collapse"  data-target="#navbarNav"></span>
                            </button> 
                            <div className="collapse navbar-collapse justify-content-end" id="navbarNav"> 
                                <ul className="navbar-nav d-flex align-items-center"> 
                                {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
                                <> </>
                                : null}
                                <li className="nav-item">
                                    <NavLink onClick={this.resetDashboardNotification} className={`nav-link $`} to="/dashboard">Dashboard
                                    {dashboardNotificationCount ? <span className="dashboard_new_box font-family-sans text-white bg-primary font-weight-400 border-radius-36">New</span> : null}
                                    </NavLink>
                                    
                                </li>
                                
                                {CurrentRole === 1 || ApprovedStatus === 1 ? <li className="nav-item">
                                {/* { Projects.length > 0 ?  */}
                                    <NavLink onClick={this.resetMessageNotification} to="/chat" className={`cursor-pointer nav-link`}>Messages 
                                    {messageNotificationCount > 0 ? <span className="font-family-sans text-white bg-primary font-11 font-weight-400 py-2 px-2 border-radius-36 ml-2">{messageNotificationCount}</span> : null}
                                    </NavLink>
                                
                                </li> : null}
                                {/* test for messages socket */}
                                {/* <li className="text-white">{messageNotificationCount}</li> */}

                                {/* testing for admin chat control */}
                                {isAdmin ? <a className={`nav-link ${location.pathname.includes('/adminChat') ? 'active' : ''}`} href="/" onClick={e => {this.props.history.push('/adminChat');e.preventDefault();}}>Chats</a> : null}
                                
                                <li className="nav-item profile-header profile-inner dropdown">
                                <a className="nav-link pr-4 dropdown-toggle" href='/' onClick={(e) => {e.preventDefault()}} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {/* <a class="nav-link dropdown-toggle" href="#" >&nbsp;SMS&nbsp;&nbsp;</a> */}
                                        <div className="user-profile-blank">
                                            {
                                                ProfileImage ?
                                                <img src={`https://ifn-images.s3.ap-south-1.amazonaws.com/profile_images/`+ ProfileImage} alt="profile" title=""/>
                                                :
                                                <img src={require("../../../../Assets/images/user-default.png")} alt="profile" title=""/>                                                                                        
                                            }
                            
                                        </div>
                                    </a>
                                    {/* <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div> */}
                                    <div className="dropdown-menu outer-nav-wrap bg-shadow" aria-labelledby="navbarDropdown" data-target="#navbarDropdown">
                                        <span className="dropdown-menu-arrow nav-arrow"></span>
                                        {current_set_role === 2 &&  ApprovedStatus === 1 && <Link className="dropdown-item font-weight-600" to="/CreatorProfile" >Profile</Link>}
                                        <Link 
                                            className="dropdown-item font-weight-600" 
                                            to={current_set_role === 2 ? "/Profile/accountSecurity" : "/Profile/MyAccount"}>
                                            Account
                                        </Link> 
                                        <a className="dropdown-item font-weight-600 text-danger cursor-pointer" href="/" onClick={this.Logout}>Logout</a> 
                                    </div> 
                                </li>

                                {current_set_role && 
                                    <li className="nav-item">
                                        {
                                            (current_set_role === 2) ?
                                            <Link className="nav-link main-btn pt-2 pb-2 text-white" 
                                                to="/uploadproject/choose_collateral" onClick={e => review_status !== 2 && e.preventDefault()}>Upload your work</Link>
                                            :
                                            // <Link className="nav-link main-btn pt-2 pb-2 text-white" to={`/hire?previousUrl=${window.location.href}`}>Hire An Expert</Link>
                                            onServicePage 
                                                        ? <Link className="nav-link main-btn pt-2 pb-2 text-white" onClick={e => {
                                                            this.props.resetPostProjectData()
                                                            localStorage.setItem('prevPath',window.location.pathname)
                                                        }
                                                        }  to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                                                    >Hire An Expert</Link>
                                                        : <Link className="nav-link main-btn pt-2 pb-2 text-white" onClick={e => {
                                                            this.props.resetPostProjectData()
                                                            localStorage.setItem('prevPath',window.location.pathname)
                                                        }
                                                        } to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}>Hire An Expert</Link>
                                                                                     
                                        }
                                        
                                    </li>
                                } 
                                </ul>
                            </div>
                        </nav>
                        {(this.showCloseIconRoutes.includes(this.props.location.pathname) || window.location.pathname.split('/')[1] === 'talentconnect') && 
                        <div className="closeIcon" onClick={() => this.props.history.push('/dashboard')}>
                            <img src={closeIcon} alt="Close-icon" width="35"/>
                        </div>}
                    </div> 
                </section> 
            </React.Fragment>
        )
    }
}

const mapState = (store) => {
        const { Me, loading } = store.globalReducer;
        const { CurrentMilestone } = store.postProject
        const { ProjectName, CurrentChat } = store.chat

        return {
            CurrentRole: Me.CurrentRole,
            Id: Me.Id,
            messageNotificationCount: Me.messageNotificationCount,
            dashboardNotificationCount: Me.dashboardNotificationCount,
            ReviewStatus: Me.ReviewStatus,
            ApprovedStatus: Me.ApprovedStatus,
            isAdmin: Me.isAdmin,
            Projects: store.chat.Projects,
            CurrentMilestone: CurrentMilestone,
            ProjectName: ProjectName,
            chat_connection_id: CurrentChat && CurrentChat.chat_connection_id,
            loading:loading,
        }
}

const mapDispatch = dispatch => ({
    loadProjectChat: (obj) => dispatch({ type: 'LOAD_PROJECT_CHAT', payload: obj }),
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    clearCreatorDastboard: () => dispatch({ type: 'CLEAR_DASHBOARD_DATA', values: null  }),
    updateMessageNotificationCount: (count) => dispatch({ type: 'UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA', payload: count  }),
    updateDashboardNotificationCount: (count) => dispatch({ type: 'UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA', payload: count  }),
    updateCompletedProjectNotificationCount: (count) => dispatch({ type: 'UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA', payload: count  }),
    clearMessageNotification: () => dispatch({type: 'CLEAR_MESSAGE_NOTIFICATION_SAGA', payload: null}),
    clearDashboardNotification: () => dispatch({type: 'CLEAR_DASHBOARD_NOTIFICATION_SAGA', payload: null}),
    // resetNotification: (chat_connection_id) => dispatch({type: 'RESET_NOTIFICATION_SAGA', payload:chat_connection_id })
    // loadProjectChat: (projectId, projectName,projectStatus) => dispatch({ type: 'LOAD_PROJECT_CHAT', payload: {projectId, projectName, projectStatus} }),
    LoadInComingBrief: (id) => dispatch({ type: 'LOAD_INCOMING_BRIEF', payload: { search: id } }),
    LoadInCompleteBrief: (id) => dispatch({ type: 'LOAD_INCOMPLETE_BRIEF', payload: {search:id} }),
    resetPostProjectData: (id)   => dispatch({type:"RESET_ALL_DATA",payload:id}),
    activateAdminChat: () => dispatch({type: 'ACTIVATE_ADMIN_CHAT_SAGA', payload: null})
  });
  export default connect(mapState, mapDispatch)(DashboardHeader);
  


