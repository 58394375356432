import { put } from 'redux-saga/effects';
import { fireApi } from '../../../utils/fireApiPrecheck';

export function* AddUserAwards(data = '') {
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let userAwards = yield fireApi('AddUserAwards', data.payload);
    yield put({ type: 'UPDATE_SAVING', values: false });

    // console.log(userAwards.data);
    yield put({ type: 'ADD_AWARD', values: userAwards.data });
  } catch (error) {
    throw error;
  }
}

export function* UpdateUserAwards(data) {
  let values = data.payload;
  // console.log(values);
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let r = yield fireApi('UpdateUserAwards', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    // console.log(r);
    if (parseInt(r.status) === 1) {
      yield put({ type: 'UPDATE_AWARD_UPDATE', values: r.data });
    }
  } catch (error) {
    console.log(error);
  }
}


export function* DeleteUserAwards({payload}) {
  try{
    // console.log('delete ', payload)
    let response = yield fireApi('DeleteUserAwards', {_id: payload});
    if(parseInt(response.status) === 1){
      yield put({type: 'UPDATE_AWARD_DELETE', values: payload})
    }
    // console.log(response)
  } catch(error){
    console.log(error)
  }
}