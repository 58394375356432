import React from 'react';
import { connect } from 'react-redux';

class CurrentWorkStatus extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.UserAddCollateral({});
  }

  OnChange = (e) => {
    this.props.onFieldChange({
      name: '', value: e.target.value,
    });
  }

  render() {
    let { CurrentWorkStatus } = this.props;
    return (
      <>
        <div className="modal custom_form verify_modal edit-basic-profile show" data-backdrop="static" style={{ paddingRight: "0px", display: "block" }} id="EditCurrentWorkStatus" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">Edit Profile</p>
                        <h2 className="mt-0 font-weight-500">Current Status</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <form method="post" action="">
                        <div className="form-group mb-4 w-100">
                          <label className="label font-weight-600 font-14 mob-font-13" htmlFor="first">Current Status</label>
                          <div className="wrapper-form py-3">
                            <div className="form-group custom_checkbox py-3 col-sm-12 small-pl-5">
                              <input type="checkbox" id="post"
                                checked={CurrentWorkStatus === 'Full-Time Freelancer'}
                                value="Full-Time Freelancer"
                                onChange={this.OnChange}
                              />
                              <label htmlFor="post" className={`m-0 font-weight-500 mob-font-10 small-font-8 mob-font-weight-700 text-secondary ${CurrentWorkStatus === 'Full-Time Freelancer' ? 'active': ''}`}>Full-Time Freelancer</label>
                            </div>
                            <div className="form-group custom_checkbox py-3 col-sm-12 small-p-0 mob-pl-0">
                              <input type="checkbox" id="freelancer"
                                checked={CurrentWorkStatus === 'Part-Time Freelancer'}
                                value="Part-Time Freelancer" onChange={this.OnChange}
                              />
                              <label htmlFor="freelancer" className={`m-0 font-weight-500 mob-font-10 small-font-8 mob-font-weight-700 text-secondary ${CurrentWorkStatus === 'Part-Time Freelancer' ? 'active': ''}`}>Part-Time Freelancer</label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="row mt-2 bg-shadow-top mobile-edit-collateral">
                      <div className="col-sm-12 p-0 text-right pr-3 py-3">
                        <button type="button" onClick={(e) => this.props.closePopup()} className="main-btn bg-light-gray font-weight-600 cursor-pointer" data-dismiss="modal">Close</button>
                        {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3">
                      <button type="button" onClick={(e) => this.props.closePopup()} className="main-btn bg-light-gray font-weight-600 cursor-pointer" data-dismiss="modal">Close</button>
                      {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;

  return {
    closeModal: creatorProfile.closeModal,
    CurrentWorkStatus: creatorProfile.CurrentWorkStatus,
  };
};

const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) => dispatch({ type: 'UPDATE_SAGA_CURRENTWORKSTATUS', payload: { name: e.name, value: e.value } }),
});

export default connect(mapState, mapDispatch)(CurrentWorkStatus);
