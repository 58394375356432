import React from 'react';
import { connect } from 'react-redux';
// import { BaseImageUrl } from '../../../../utils/BaseUrl';
// import LocationDP from '../LocationDP';
// import SingleSelectDP from '../../Common/Common/SingleSelectDP';
import { postAPI } from '../../../../utils/API';
import { Baseurl } from '../../../../utils/BaseUrl';
import axios from 'axios'; 
import ErrorSpan from '../../Common/Common/ErrorSpan';
import Loader from '../../Common/Common/Loader';
// import BtnLoader from '../../Common/Common/BtnLoader';
import BtnLoader from './../../../unauthorized/Common/Loader/Loader';
import uploadImage from './../../../../Assets/images/upload-icon.png';
const IMAGE_BASE_URL =
  'https://ifn-images.s3.ap-south-1.amazonaws.com/profile_images/';

class Clients extends React.Component {
  state = {
    image1: null,
    image2: null,
    image3: null,
    name1: '',
    name2: '',
    name3: '',
    // commonError: false,
    name1Error: false,
    name2Error: false,
    name3Error: false,
    image1Error: false,
    image2Error: false,
    image3Error: false,
    image1Loading: false,
    image2Loading: false,
    image3Loading: false,
    
  };

  async componentDidMount() {
    this.props.creatorClients.forEach((obj, index) => {
      if (obj.client_image && obj.client_name) {
        this.setState({
          [`name${index + 1}`]: obj.client_name,
          [`image${index + 1}`]: obj.client_image,
        });
      }
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    // console.log('submit form handler');
    // console.log('state ', this.state);

    // validation
    const { name1, name2, name3, image1, image2, image3 } = this.state;
     
      if (name1 !== '' && !image1) {
        this.setState({
          image1Error: true,
          imageErrorMessage: 'upload image',
        });
        return;
      }
      else if (name2 !== '' && !image2) {
        this.setState({
          image2Error: true,
          imageErrorMessage: 'upload image',
        });
        return;
      }
     else if (name3 !== '' && !image3) {
        this.setState({
          image3Error: true,
          imageErrorMessage: 'upload image',
        });
        return;
      }
     else if (image1 && name1.trim() === '') {
        this.setState({ name1Error: true });
        return;
      }
      else if (image2 && name2.trim() === '') {
        this.setState({ name2Error: true });
        return;
      }
      else if (image3 && name3.trim() === '') {
        this.setState({ name3Error: true });
        return;
      }
      // validation end
    

    const obj1 = {};
    const obj2 = {};
    const obj3 = {};
    if (name1 !== '' && image1) {
      obj1.client_name = name1;
      obj1.client_image = image1;
    }
    if (name2 !== '' && image2) {
      obj2.client_name = name2;
      obj2.client_image = image2;
    }
    if (name3 !== '' && image3) {
      obj3.client_name = name3;
      obj3.client_image = image3;
    }

    const fullObj = { client: { obj1, obj2, obj3 } };
    // console.log('state ', this.state);
    // console.log('fullObj ', fullObj);

    try {
      this.props.loadSaving();
      const res = await postAPI('/users/add-creator-client', fullObj);
      this.props.loadSavingDone();
      this.props.closePopup();
      // console.log('RES ', res.data);

      if (res.data.status === '1') {
        this.props.UpdateClients(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }

  };

  closePopup = () => {
    this.props.onDPChange({
      name: 'CREATORBASIC',
      value: {
        About: this.props.ResetData.About,
        Location: this.props.ResetData.Location,
        Avatar: this.props.ResetData.Avatar,
        FirstName: this.props.ResetData.FirstName,
        LastName: this.props.ResetData.LastName,
        PrimaryFieldWork: this.props.ResetData.PrimaryFieldWork,
      },
    });
    this.props.closePopup();
  };

  reloadSpecilization = (value) => {
    this.props.loadSpecialization(value);
  };

  reloadLocation = (value) => {
    this.props.loadLocation(value);
  };

  handleImage = async (e) => {
    // console.log('state ', this.state);
    // console.log('image name ', e.target.name);
    const fileSize = e.target.files[0].size 
    if(fileSize > 1000 * 1000 * 2){
      alert('File size exceeds 2 MB');
      return;
    }

    const name = e.target.name;
    this.setState({[`${name}Loading`]: true})
    const formData = new FormData();
    formData.append('userImages', e.target.files[0]);

    const token = localStorage.getItem('SignUpidToken');

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    };

    const res = await axios.post(
      Baseurl + `/users/client-image`,
      formData,
      config
    );
    // console.log(res.data);
    this.setState({
      [name]: res.data.client_image,
      [`${name}Error`]: false,
      imageErrorMessage: null,
      [`${name}Loading`]: false,
    });

    // console.log(e.target.name);
    // console.log(e.target.files[0]);
    // this.setState({ [e.target.name]: e.target.files[0] });
  };

  nameChangeHandler = (e) => {
    // console.log(`${e.target.name}Error`);

    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: false,
    });
  };

  deleteHandler = (name, image) => {
    this.setState({ [name]: '', [image]: null });
  };

  render() {
    // console.log('state ', this.state);

    let {
      // FirstNameError,
      // LastNameError,
      // AboutError,
      // LocationError,
      // LocationErrorMessage,
      // PrimarySpecializationError,
      // PrimarySpecializationErrorMessage,
      saving,
      // uploading,
      // Avatar,
    } = this.props;
    const {
      name1,
      name2,
      name3,
      image1,
      image2,
      image3,
      image1Error,
      image2Error,
      image3Error,
      imageErrorMessage,
      name1Error,
      name2Error,
      name3Error,
      image1Loading,
      image2Loading,
      image3Loading,
    } = this.state;
    return (
      <>
        <div
          className="modal fade custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditProfileModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Clients</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 pl-2 overflow-content edit-collateral-height mid_height">
                    <div className="custom_form">
                      <form onSubmit={this.onSubmit} className="pt-4"> 
                        <div className="row m-0">
                          <div className="col-2 col-sm-2">
                            <div className="clients_popup cursor-pointer">
                              <input
                                type="file"
                                className="client-image cursor-pointer"
                                name="image1"
                                onChange={this.handleImage}
                              />
                              {!image1Loading ? <img
                                src={
                                  image1 ? IMAGE_BASE_URL + image1 : uploadImage
                                }
                                alt="profile"
                                title=""
                                className={`${image1 ? '' : 'h-50'}`}
                              /> : <Loader />} 
                            </div>
                            {image1Error ? (
                            <p className="font-12 font-weight-600 text-danger mb-0">{imageErrorMessage}</p>
                          ) : null}
                          </div>
                          <div className="form-group col-10 col-sm-10 pl-0 position-relative client_div mob-pl-50">
                            <label
                              className="label font-14 font-weight-600 text-dark-gray"
                              htmlFor="first"
                            >
                              Client name
                            </label>
                            <input
                              type="text"
                              // name="BASIC_FIRSTNAME"
                              // onChange={(e) => this.props.onFieldChange(e)}
                              name="name1"
                              className={`form-control email ${
                                name1Error ? 'border-error' : ''
                              }`}
                              value={name1}
                              placeholder="Name"
                              onChange={this.nameChangeHandler}
                            />
                            {
                              image1 || name1 ? <p
                              className="client_remove text-danger"
                              onClick={() =>
                                this.deleteHandler('name1', 'image1')
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </p> : null
                            }
                            <ErrorSpan
                              error={name1Error}
                              message={'please input client name'}
                            />
                          </div> 
                        </div>
                        <div className="row m-0">
                          <div className="col-2 col-sm-2">
                            <div className="clients_popup cursor-pointer">
                              <input
                                type="file"
                                className="client-image cursor-pointer"
                                name="image2"
                                onChange={this.handleImage}
                              />
                             {!image2Loading ? <img
                                src={
                                  image2 ? IMAGE_BASE_URL + image2 : uploadImage
                                }
                                alt="profile"
                                title=""
                                className={`${image2 ? '' : 'h-50'}`}
                              /> : <Loader />} 
                            </div>
                            {image2Error ? (
                            <p className="font-12 font-weight-600 text-danger mb-0">{imageErrorMessage}</p>
                          ) : null}
                          </div>
                          <div className="form-group col-10 col-sm-10 pl-0 position-relative client_div mob-pl-50">
                            <label
                              className="label font-14 font-weight-600 text-dark-gray"
                              htmlFor="first"
                            >
                              Client name
                            </label>
                            <input
                              type="text"
                              // name="BASIC_FIRSTNAME"
                              // onChange={(e) => this.props.onFieldChange(e)}
                              name="name2"
                              className={`form-control email ${
                                name2Error ? 'border-error' : ''
                              }`}
                              value={name2}
                              placeholder="Name"
                              onChange={this.nameChangeHandler}
                            />
                             {
                              image2 || name2 ? <p
                              className="client_remove text-danger"
                              onClick={() =>
                                this.deleteHandler('name2', 'image2')
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </p> : null
                            }
                            <ErrorSpan
                              error={name2Error}
                              message={'please input client name'}
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-2 col-sm-2">
                            <div className="clients_popup cursor-pointer">
                              <input
                                type="file"
                                className="client-image cursor-pointer"
                                name="image3"
                                onChange={this.handleImage}
                              />
                              {!image3Loading ? <img
                                src={
                                  image3 ? IMAGE_BASE_URL + image3 : uploadImage
                                }
                                alt="profile"
                                title=""
                                className={`${image3 ? '' : 'h-50'}`}
                              /> : <Loader />} 
                            </div>
                            {image3Error ? (
                            <p className="font-12 font-weight-600 text-danger mb-0">{imageErrorMessage}</p>
                          ) : null}
                          </div>
                          <div className="form-group col-10 col-sm-10 pl-0 position-relative client_div mob-pl-50">
                            <label
                              className="label font-14 font-weight-600 text-dark-gray"
                              htmlFor="first"
                            >
                              Client name
                            </label>
                            <input
                              type="text"
                              // name="BASIC_FIRSTNAME"
                              // onChange={(e) => this.props.onFieldChange(e)}
                              name="name3"
                              className={`form-control email ${
                                name3Error ? 'border-error' : ''
                              }`}
                              value={name3}
                              placeholder="Name"
                              onChange={this.nameChangeHandler}
                            />
                            {
                              image3 || name3 ? <p
                              className="client_remove text-danger"
                              onClick={() =>
                                this.deleteHandler('name3', 'image3')
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </p> : null
                            }
                           
                            <ErrorSpan
                              error={name3Error}
                              message={'please input client name'}
                            />
                          </div>
                        </div> 
                      </form>
                    </div> 
                  </div> 
                  <div className="col-sm-12 p-0 text-right pr-3 py-3">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                      >
                        Close
                      </button>
                      {saving === true ? (
                        <button
                          type="submit"
                          className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
                          disabled
                        >
                          <BtnLoader profileCreator="true"/>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={this.onSubmit}
                          className="main-btn px-4 ml-2"
                        >
                          Save
                        </button>
                      )}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let BasicDetails = creatorProfile.BasicDetails;

  return {
    saving: globalReducer.saving,
    uploading: globalReducer.uploading,
    FirstName: BasicDetails.FirstName,
    LastName: BasicDetails.LastName,
    Avatar: BasicDetails.Avatar,
    About: BasicDetails.About,
    Location: BasicDetails.Location,
    PrimaryFieldWork: BasicDetails.PrimaryFieldWork,
    creatorClients: creatorProfile.creatorClients,

    FirstNameError: BasicDetails.FirstNameError,
    FirstNameErrorMessage: BasicDetails.FirstNameErrorMessage,
    LastNameError: BasicDetails.LastNameError,
    LastNameErrorMessage: BasicDetails.LastNameErrorMessage,
    AvatarError: BasicDetails.AvatarError,
    AvatarErrorMessage: BasicDetails.AvatarErrorMessage,
    AboutError: BasicDetails.AboutError,
    AboutErrorMessage: BasicDetails.AboutErrorMessage,
    LocationError: BasicDetails.LocationError,
    LocationErrorMessage: BasicDetails.LocationErrorMessage,
    PrimarySpecializationError: BasicDetails.PrimarySpecializationError,
    PrimarySpecializationErrorMessage:
      BasicDetails.PrimarySpecializationErrorMessage,

    Specialization: creatorProfile.Specialization,
    Locations: creatorProfile.Locations,
    closeModal: creatorProfile.closeModal,
    ResetData: {
      FirstName: creatorProfile.FirstName,
      LastName: creatorProfile.LastName,
      Avatar: creatorProfile.Avatar,
      Location: creatorProfile.Location,
      About: creatorProfile.About,
      PrimaryFieldWork: creatorProfile.PrimaryFieldWork,
    },
  };
};
const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.target.name, value: e.target.value },
    }),
  onDPChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  uploadUserImage: (e) =>
    dispatch({ type: 'UPLOAD_USERIMAGE', payload: { file: e } }),
  UpdateUser: (e) => dispatch({ type: 'UPDATE_USER', payload: e }),
  UpdateClients: (e) => dispatch({ type: 'UPDATE_CREATOR_CLIENTS', values: e }),
  loadSpecialization: (e) =>
    dispatch({ type: 'LOAD_CREATOR_SPECIALIZATION_SAGA', payload: e }),
  loadLocation: (e) =>
    dispatch({ type: 'LOAD_CREATOR_LOCATION_SAGA', payload: e }),
  loadSaving: () => dispatch({ type: 'UPDATE_SAVING', values: true }),
  loadSavingDone: () => dispatch({ type: 'UPDATE_SAVING', values: false }),
});
export default connect(mapState, mapDispatch)(Clients);
