import React from 'react';
import { connect } from 'react-redux';
import { postAPI } from '../../../../utils/API';
import ModalBody from '../../Common/FeedbackModal/ModalBody';

class EndProjectReason extends React.Component {

  state = {
    clientCheckboxListNegotiation: [
      {text: "I am not satisfied with any of the proposals"},
      {text: "I am not satisfied with the creators' responses"},
      {text: "I am not satisfied with the quality of the creators' work"},
      {text: "I have found another creator outside IndieFolio"},
      {text: "I have decided to delay the project"},
      {text: "I have decided to cancel the project"},
      {text: "Other"},
    ],
    clientCheckboxListActive: [
      {text: "I am not satisfied with the creator's work"},
      {text: "The creator is not responsive"},
      {text: "The creator missed a deadline"},
      {text: "I have decided to cancel the project"},
      {text: "Other"},
    ],
    creatorCheckboxListActive: [
      {text: "Personal reasons"},
      {text: "I am too busy to complete this project"},
      {text: "Client is unresponsive"},
      {text: "Client is refusing to cooperate or make my payments"},
      {text: "Client wants me to end the project"},
      {text: "Other"},
    ],
    clientCheckboxListActiveBrief: [
      {text: "I have decided to cancel the project"},
      {text: "Other"},
    ],
  }

  

  handleSubmit = async (data) => {
    const { ProjectId,ProjectIdd, CurrentRole, Me } = this.props;
    this.setState({isSubmitting: true});

      const obj = {
        post_project_id: ProjectId || ProjectIdd,
        description: data.description,
        feedback: data.reason,
        user_type: CurrentRole === 1 ? 'client' : 'creator',
      }

      // console.log('final obj ', obj)

      const response = await postAPI('/project/add-post-project-close-reviews', obj);
      this.setState({isSubmitting: false});
      if(parseInt(response.data.status) === 1){
        alert(`Thank you, ${Me.FirstName} ${Me.LastName}. We have received your request and will be in touch with you shortly`)
        // console.log(data)
        this.props.loadLatestProposals(ProjectId || ProjectIdd);
        this.props.closePopup()
      }
  }

  getCheckboxList = () => {
    const { ProjectStatus, CurrentRole, hideActions } = this.props;
    const { clientCheckboxListNegotiation, clientCheckboxListActive, creatorCheckboxListActive, clientCheckboxListActiveBrief } = this.state
    if(ProjectStatus === 5){
      return CurrentRole === 1 ? clientCheckboxListNegotiation : []
    } 
    else if (ProjectStatus === 6) {
      return CurrentRole === 1 ? clientCheckboxListActive : creatorCheckboxListActive
    }else if(hideActions){
      return clientCheckboxListActiveBrief
    }
  }

  render() {
    const creatorHeadertextActiveProject = "Request to end active project";
    const { ProjectStatus, CurrentRole } = this.props;


    return (
      <>
        <div className="modal fade custom_form verify_modal edit-basic-profile show" style={{
          paddingRight: "0px !important",
          display:'block'
        }} id="EndProjectReason" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <ModalBody 
            headerText={ProjectStatus === 6 && CurrentRole === 2 ? creatorHeadertextActiveProject : "Request to end the project"}
            buttonText='Submit Request'
            checkboxList={this.getCheckboxList()} 
            submitHandler={this.handleSubmit}
            isSubmitting={this.state.isSubmitting}
          />
          {/* <div className="modal-dialog modal-dialog-centered ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0 mobile-hide">
                    <div className="profile-creator ml-05 mob-m-0 text-center">
                      <div className="py-30">
                        <h2 className="mt-0 font-weight-500 mb-1 pt-1 font-18 mob-font-14">Tell us what you didn't like</h2>
                        <p className="mb-0 text-third font-weight-500 font-12 mob-font-12">You'll receive a call from us once you've submitted the feeback.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mobile-show bg-shadow">
                    <div className="mob-m-0 text-left">
                      <div className="py-15">
                        <h2 className="mt-0 font-weight-600 mb-1 pt-1 pl-3 font-18 small-font-14">Tell us why you're rejecting Karan</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project reject-profile">
                    <div className="custom_form px-2 pb-4 pt-4 mob-px-0">
                      <form method="post" action="">
                        <div className="row m-0">
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox1" id="requirements" checked={checkbox1} onChange={this.onChangeHandler} />
                            <label htmlFor="requirements" className="font-weight-600 small-font-12">Portfolio work didn't match my requirements</label>
                          </div>
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox2" id="want" checked={checkbox2} onChange={this.onChangeHandler} />
                            <label htmlFor="want" className="font-weight-600 small-font-12">Want more experienced creators</label>
                          </div>
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox3" id="match" checked={checkbox3} onChange={this.onChangeHandler} />
                            <label htmlFor="match" className="font-weight-600 small-font-12">Portfolio work didn't match my requirements</label>
                          </div>
                          <div className="form-group col-sm-12">
                            <textarea className="form-control email min-h-100 mb-1" name="description" value={description} onChange={this.onChangeHandler} placeholder="Describe further (optional)" id="comment"></textarea>
                            <span className="font-weight-500 text-secondary">{description.length}/300</span>
                          </div>
                        </div>
                        <div className="form-group text-left pl-3 pt-2 mob-px-15 mob-mb-30">
                          <button type="button" className="main-btn font-weight-500 py-12 mob-w-100" onClick={this.submitHandler} >Confirm <i className="fas fa-arrow-right font-12 font-weight-500 ml-1"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  const creatorProfile = store.creatorProfile;
  const chat = store.chat
  const {Me} = store.globalReducer
  return {
    closeModal: creatorProfile.closeModal,
    ProjectId: chat.ProjectId,
    ProjectStatus: chat.ProjectStatus,
    CurrentRole: Me.CurrentRole,
    Me: Me,
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  loadLatestProposals: (projectId) => dispatch({ type: 'LOAD_LATEST_PROPOSAL', payload: projectId }),
});

export default connect(mapState, mapDispatch)(EndProjectReason);
