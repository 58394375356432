export const UPDATE_CHAT_USERS = 'UPDATE_CHAT_USERS';
export const UPDATE_MULTIPLE_PROPOSAL_STATUS = 'UPDATE_MULTIPLE_PROPOSAL_STATUS';
export const UPDATE_CHANGE_CHAT = 'UPDATE_CHANGE_CHAT';
export const UPDATE_CHANGE_CHATMESSAGE = 'UPDATE_CHANGE_CHATMESSAGE';
export const UPDATE_SEND_MESSAGE = 'UPDATE_SEND_MESSAGE';
export const GET_PROJECT_CHAT = 'GET_PROJECT_CHAT';
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS';
export const UPDATE_UNREAD_MESSAGE_IN_CREATOR_OBJ = 'UPDATE_UNREAD_MESSAGE_IN_CREATOR_OBJ';
export const UPDATE_LAST_MILESTONE_PAYMENT_DONE = 'UPDATE_LAST_MILESTONE_PAYMENT_DONE';
export const UPDATE_RESET_CHAT_DATA = 'UPDATE_RESET_CHAT_DATA';

export const RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ = 'RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ';
export const UPDATE_LOAD_CHAT = 'UPDATE_LOAD_CHAT';
export const LOAD_CHAT_AND_LATEST_PROPOSAL = 'LOAD_CHAT_AND_LATEST_PROPOSAL';
export const UPDATE_LOAD_CHATMESSAGES = 'UPDATE_LOAD_CHATMESSAGES';
export const LOAD_PROJECT_STATUS = 'LOAD_PROJECT_STATUS';
export const LOAD_PROJECT_CHAT = 'LOAD_PROJECT_CHAT';
export const ACTIVATE_ADMIN_CHAT_SAGA = 'ACTIVATE_ADMIN_CHAT_SAGA';
export const ACTIVATE_ADMIN_CHAT = 'ACTIVATE_ADMIN_CHAT';
export const LOAD_LAST_MILESTONE_PAYMENT_DONE = 'LOAD_LAST_MILESTONE_PAYMENT_DONE';
export const RESET_CHAT_DATA = 'RESET_CHAT_DATA';

export const ATTACHMENT_BUTTON_TOGGLE = 'ATTACHMENT_BUTTON_TOGGLE';
export const UPDATE_ATTACHMENT_BUTTON_CLICKED_STATUS = 'UPDATE_ATTACHMENT_BUTTON_CLICKED_STATUS';

export const REMOVE_NOTIFICATION_FORM_PROJECT_LIST = 'REMOVE_NOTIFICATION_FORM_PROJECT_LIST';
export const UPDATE_PROJECT_LIST_NOTIFICATION = 'UPDATE_PROJECT_LIST_NOTIFICATION';

export const UPDATE_CURRENT_CHAT_USER_DATA_SAGA = 'UPDATE_CURRENT_CHAT_USER_DATA_SAGA';
export const UPDATE_CURRENT_CHAT_USER_DATA = 'UPDATE_CURRENT_CHAT_USER_DATA';

export const UPDATE_ARTIST_MSG_COUNT = 'UPDATE_ARTIST_MSG_COUNT';

export const SET_CHAT_FILES = 'SET_CHAT_FILES';