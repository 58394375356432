import globalReducer from "./global.reducer";
import addData from "./add.reducer";
import getStatus from "./userStatus.reducer";
import creatorProfile from "./creatorProfile.reducer";
import project from "./add.Project";
import userWork from "./userWork.reducer";
import userEducation from "./userEducation.reducer";
import addCollateral from "./userAddCollateral.reducer";
import creatorSocial from "./creatorSocial.reducer";
import postProject from "./postProject.reducer";
import projectView from "./projectView.reducer";
import chat from "./chat.reducer";
import adminChat from "./adminChat.reducer";
import dashboard from "./dashboard.reducer";
import loginReducer from "./helmet.reducer";
import releventProjects from "./proposal.reducer";

// import loginReducer from '../../Components/unauthorized/LandingPage/Header/clickloginreducer';

export default {
  globalReducer,
  addData,
  getStatus,
  creatorProfile,
  project,
  userWork,
  userEducation,
  addCollateral,
  creatorSocial,
  postProject,
  projectView,
  chat,
  dashboard,
  adminChat,
  loginReducer,
  releventProjects,
};
