import React, { useEffect, useState } from "react";
import Star from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/Star.svg";
import GrayStar from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/gray_star.svg";
import Right from "../../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/right.svg";

export const ReviewMoalBody = ({ reviewState, setReviewState, setCheckboxList, arrClientGoodRating, arrClient, ...props }) => {
  const handleRating = (isText, text, num) => {
    return (
      <div
        className="d-flex flex-column text-center cursor-pointer align-items-center" style={{minWidth: "62px"}}
        onClick={() => {
          if (!props.showFeedback) {
            if (num == 4 || num == 5) {
              setCheckboxList(arrClientGoodRating);
            } else {
              setCheckboxList(arrClient);
            }
          }
          reviewState.reason = props.showFeedback ? reviewState.reason : [];
          setReviewState({ ...reviewState, rating: num });
        }}
      >
        <div className="star mb-3">
          <img src={reviewState.rating >= num ? Star : GrayStar} />
        </div>
        {isText ? <span className="text_gray_1100_w_600">{text}</span> : null}
      </div>
    );
  };

  const onChangeHandler = (e, obj) => {
    const isAlreadyChecked = reviewState.reason.find((el) => el.text === obj.text);

    if (isAlreadyChecked) {
      setReviewState({
        ...reviewState,
        reason: [...reviewState.reason.filter((el) => el.text !== obj.text)],
      });
    } else {
      setReviewState({ ...reviewState, reason: [...reviewState.reason, { text: obj.text }] });
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (!reviewState.rating) {
      isValid = false;
      alert("Please give rating.");
    } else if (!reviewState.reason.length && (props.CurrentRole != 2 || props.showFeedback)) {
      isValid = false;
      alert("Please select at least one reason.");
    }
    return isValid;
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setShowFinalReview(false);
    }
  };

  //   console.log("reviewState====== ", props.showFeedback, reviewState);

  const proposal_data = props.state?.proposalData;

  return (
    <div className="modal-content">
      <div className="br_btm d-flex align-items-center justify-content-between jainul-ui">
        <div className="header">
          <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
            We’d love to hear your feedback
          </h5>
        </div>

        <button type="button" className="close close_btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="close_icon modalclose-btnicon" onClick={() => props.setShowFinalReview(false)}>
            ×
          </span>
        </button>
      </div>

      <div className="modal-body p-0">
        <div className="row feedback_header br_btm mx-0 px-4 ">
          <div className={`col-lg-5 feedback_header_inner pl-2 ${props.showFeedback ? "completed" : "active"}`}>
            <p className="mb-0">
              {props.showFeedback ? (
                <span className="mr-3 ">
                  <img src={Right} />
                </span>
              ) : (
                <span className="mr-3 ">1.</span>
              )}
              {props.CurrentRole === 1 ? "Creator" : "Client"} Feedback
            </p>
          </div>
          <div className={`col-lg-7 feedback_header_inner ${props.showFeedback ? "active" : ""}`}>
            <p className="mb-0">
              <span className="mr-3">2.</span>Platform Feedback
            </p>
          </div>
        </div>
        <div className="row mx-0 jainul-ui">
          <div className="col-lg-12 mb-4 pl-0">
            <p className="mb-0 font_semi_bold_w_600">
              {props.showFeedback ? (
                <span>
                  <span style={{ color: "red" }}>* </span>
                  {`On a scale of 1 to 10, how likely are you to recommend our business to a friend or colleague?`}
                </span>
              ) : (
                <span>
                  <span style={{ color: "red" }}>* </span>
                  {`How was your experience working withs ${
                    proposal_data.userData.first_name + " " + proposal_data.userData.last_name
                  }?`}
                </span>
              )}
            </p>
          </div>
          <div className="col-lg-12 d-flex column_gap_10 pl-0">
            {props.showFeedback ? (
              <>
                {handleRating(true, "Not At All Likely", 1)}
                {handleRating(false, "", 2)}
                {handleRating(false, "", 3)}
                {handleRating(false, "", 4)}
                {handleRating(true, "", 5)}
                {handleRating(true, "", 6)}
                {handleRating(false, "", 7)}
                {handleRating(false, "", 8)}
                {handleRating(false, "", 9)}
                {handleRating(true, "Extremely Likely", 10)}
              </>
            ) : (
              <>
                {handleRating(true, "Very Bad", 1)}
                {handleRating(false, "", 2)}
                {handleRating(false, "", 3)}
                {handleRating(false, "", 4)}
                {handleRating(true, "Very Good", 5)}
              </>
            )}
          </div>
        </div>

        {props.CurrentRole != 2 || props.showFeedback ? (
          <div className="row mx-0 jainul-ui">
            <div className="col-lg-12 px-0">
              <p className="mb-0 font_semi_bold_w_600">
                {props.showFeedback ? (
                  <span>
                    <span style={{ color: "red" }}>* </span>
                    {`How would you feel if you could no longer use IndieFolio?`}
                  </span>
                ) : (
                  <span>
                    <span style={{ color: "red" }}>* </span>
                    {`What could have been better?`}
                  </span>
                )}
              </p>
            </div>
          </div>
        ) : null}

        {props.CurrentRole != 2 || props.showFeedback
          ? props.checkboxData?.length
            ? props.checkboxData.map((obj, index) => (
                <div className="row mx-0 px-4 ml-3 mb-3 d-flex justify-content-between">
                  <p className="mb-0 text_gray_1100_w_600">{obj.text}</p>
                  <div className="check_box mr-2">
                    <div class="cstm_checkbox">
                      <input
                        type="checkbox"
                        id={index}
                        checked={reviewState.reason.find((data) => data.text === obj.text) ? true : false}
                        onChange={(e) => onChangeHandler(e, obj)}
                        name={`checkbox${index}`}
                      />
                      <label for={index}></label>
                    </div>
                  </div>
                </div>
              ))
            : null
          : null}

        <div className="row mx-0 jainul-ui">
          <div className="col-lg-12 px-0 mb-3 ">
            <p className="mb-0 font_semi_bold_w_600">Anything that can be improved?</p>
          </div>
          <div className="col-lg-12 px-0">
            <textarea
              class="form-control upload_notes p-3"
              rows="5"
              value={reviewState.description}
              onChange={(e) =>
                setReviewState({
                  ...reviewState,
                  description: e.target.value,
                })
              }
              placeholder="Your Feedback (optional)"
              spellcheck="false"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="modal-footer justify-content-end align-items-center py-4">
        <div className="col-lg-12 text-right">
          {/* <button type="button" class="btn btn_outline_md fw_700 mr-4">Cancel</button>  */}
          <button
            type="button"
            class="btn btn_common_md fw_700"
            onClick={() => {
              if (formValidation()) {
                props.handleSubmit(reviewState);
              }
            }}
          >
            {props.showFeedback ? "Submit" : "Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
};
