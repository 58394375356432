import React, { useEffect, useState } from "react";
import { putAPI } from "../../../../../utils/API";

export const ProjectEndPopup = (props) => {
  const [load, setLoad] = useState(false);

  const handleProceed = async () => {
    setLoad(true);
    let data = {
      _id: props.proposalId,
    };
    try{
      const res = await putAPI("/proposal/complete-project", data);
      if (res) {
        setLoad(false);
        props.setShowProjEndPop(false)
        props.setjustStaticEndProjClient(true);
        props.callApiAgain()
        // props.setShowFinalReview(true);
      }
    }catch(err){
      alert("Something went wrong, Please try again later.");
      setLoad(false);
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setShowProjEndPop(false);
    }
  };

  return (
    <>
      <section className="projectendpopup">
        <div
          className={`modal fade modal_fade_bg ${props.showProjEndPop ? "show" : ""}`}
          id="endprojectpopup"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props.showProjEndPop ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm ">
            <div className="modal-content">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h5 className="modal-title main_modal_title mb-3" id="exampleModalLabel">
                  Would you like to mark the project as completed?
                  </h5>
                </div>
              </div>

              <div className="modal-body project_end_body">
                <p className="text_gray_w_600">
                On confirmation, the project will be marked as completed and final confirmations will be shared with all parties.
                </p>
              </div>
              <div className="modal-footer justify-content-end align-items-center py-4">
                <div className="col-lg-12 text-right">
                  <button type="button" class="btn btn_outline_sm fw_700 mr-4" onClick={() => props.setShowProjEndPop(false)}>
                    Cancel
                  </button>
                  <button type="button" class="btn btn_common_sm fw_700" onClick={handleProceed}>
                    {load ? "Proceeding..." : "Proceed"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
