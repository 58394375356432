
import DotImage from "../../../../../../Assets/images/vdot.svg";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import React, { useEffect, useState, useRef } from "react";
import HeaderIcon from "../../../../../../Assets/upgrade-images/submit_proposal/header_icon.svg";
import EditIcon from "../../../../../../Assets/upgrade-images/submit_proposal/edit.svg";
import PercentIcon from "../../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import DateIcon from "../../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import ReviewProposal from "../ReviewProposal";
import SuccessProp from "../../SuccessProp";
import ActualDeliverable from "./ActualDeliverable";
import { getAPI, postAPI } from "../../../../../../utils/API";
import DatePicker from "react-date-picker";
import Select from "react-select";
import ProjectDetail from "../../CommonComp/ProjectDetail";
import { useDispatch, useSelector } from "react-redux";
import Reference from "../Reference/Reference";
import {
  relevent_projects,
  projectAttachments,
  projectUrls,
} from "../../../../../../store/action/proposal.action";
import Loader from "../../../../../authorized/Common/Common/Loader";
import SubmitProp from "../../PopopCreator/SubmitProp";
import { addHrMinDate, convertToPlain, setTimeToZero } from "../../CommonFunctionsNew/CommonFunctionsNew";

// import OnActualsReviewConfirm from "../../../../OnActuals-ReviewConfirm/OnActualsReviewConfirm";

export default function Onactual(props) {
  const [states, setStates] = useState({
    deliverable: [
      {
        name: "",
        iterations: 1,
        breakup: "",
        toggle: false,
        iteration_charge: "",
        validation: { name: false, breakup: false, iteration_charge: false },
        status: 0,
        milestonecomplete: [],
        paid_status: 0,
      },
    ],
    propDuration: {
      prop_duration: { label: "Months", value: "Months" },
      prop_dur_num: 1,
    },
    prefBillingCycle: { label: "Cycle End", value: "Monthly" },
    note: "",
    proposed_start_date: setTimeToZero(new Date()),
    validation: { prop_dur_num: false },
    validNote: false,
    completed_milestone_no: 0,
  });
  const _ProjectsIs = useSelector((state) => state.releventProjects);
  const [loading, setLoading] = useState(true);
  const [showReviewProp, setShowReviewProp] = useState(false);
  const [showSuccessProp, setShowSuccessProp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const DeliverableRef = useRef(null)
  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: data?.proposal_type === "actual" ? current_ids : [],
      releventProject: data?.proposal_type === "actual" ? data?.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.proposal_type === "actual" ? data?.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.proposal_type === "actual" ? data?.urls : []));
  };

  // useEffect(() => {
  //   setProjectIds()
  //   setProjectAttachments()
  //   setAllUrls()
  // },[])

  // for redux state blank project reference ***********************

  useEffect(() => {
    setId(props?.id);
    if (props?.data?.project_data) handleGetProposal(props?.id);
  }, [props?.data]);

  const handleGetProposal = async (id) => {
    try {
      let res = "";
      let projectStatus = props?.data?.project_data?.status;
      if (projectStatus == 6 || projectStatus == 7) {
        res = await getAPI(`/proposal/proposal-latest-approved-data?id=${id}`);
      } else {
        res = await getAPI(`/proposal/proposal-payment?id=${id}`);
      }
      if (
        res &&
        res?.data &&
        res?.data?.AllProposal?.proposal_type !== "fixed" &&
        res?.data?.AllProposal?.proposal_type !== "retainer"
        ) {
        let data = res?.data?.AllProposal;
        // console.log("1===== ", data);
        getReferenceData(data);
        var tempNote = data?.note ? convertToPlain(data.note)  : "" 

        let arrDel = [];
        data?.deliverables?.length > 0 &&
          data.deliverables.forEach((item) => {
            if (
              (item?.name != "advance_payment" || item?.type != "token") &&
              data?.proposal_type !== "fixed" &&
              data?.proposal_type !== "retainer"
            ) {
              arrDel.push({
                name: item?.name,
                iterations: item?.iteration,
                toggle: item?.iteration_charge ? true : false,
                iteration_charge: item?.iteration_charge ? item.iteration_charge : "",
                breakup: item?.price,
                validation: {
                  name: false,
                  breakup: false,
                  iteration_charge: false,
                },
                status: item?.status,
                milestonecomplete: item?.milestonecomplete?.length > 0 ? item.milestonecomplete : [],
                paid_status: item?.paid_status ? item.paid_status : 0,
              });
            }
          });

        const arrayDel = props?.data?.project_data?.status == 6 ? (arrDel.length > 0 ? arrDel : []) : arrDel;

        states.deliverable =
          arrayDel.length > 0
            ? arrayDel
            : [
              {
                name: "",
                iterations: 1,
                breakup: "",
                toggle: false,
                iteration_charge: "",
                validation: {
                  name: false,
                  breakup: false,
                  iteration_charge: false,
                },
              },
            ];
        states.propDuration.prop_duration =
          data?.proposed_duration_type?.length > 0
            ? {
              label: data.proposed_duration_type,
              value: data.proposed_duration_type,
            }
            : { label: "Months", value: "Months" };
        states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
        states.prefBillingCycle =
          data?.preferred_billing_cycle?.length > 0
            ? {
              label: data.preferred_billing_cycle == "Monthly" ? "Cycle End" : data.preferred_billing_cycle,
              value: data.preferred_billing_cycle,
            }
            : { label: "Cycle End", value: "Monthly" };
        states.note = tempNote;
        states.proposed_start_date = data?.proposed_start_date ? setTimeToZero(data.proposed_start_date) : setTimeToZero(new Date());
        states.completed_milestone_no = data?.completed_milestone_no ? data.completed_milestone_no : 0;

        setStates({ ...states });
        setLoading(false);
      } else {
        setLoading(false);
        getReferenceData([]);
      }
    } catch (err) {
      console.log("error");
      setLoading(false);
      getReferenceData([]);
    }
  };

  const onClickBack = () =>{
    const {location,history,id,data} = props;
    if(history && data.project_data.project_slug){
      if(location.pathname.includes('jobs')){
        dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: 'IncomingBriefReviewJob' } })
        history.push(`/dashboard/jobs/:${id}/${data.project_data.project_slug}`)
      }else{
        history.push(`/projects/${props?.match?.params?.id}/overview`)
      }
    }
  }

  const scroll = () => {
    return DeliverableRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start"
    })
  }

  const validationDeliverable = () => {
    let valid = true;
    if (states?.propDuration?.prop_dur_num < 1) valid = false;
    states.deliverable.forEach((item) => {
      if (!item.name.length > 0 || item.breakup < 1 || (!item.iteration_charge && item.toggle)) valid = false;
      item.validation.name = item.name.length > 0 ? false : true;
      item.validation.breakup = item.breakup < 1 ? true : false;
      if (item.toggle) {
        item.validation.iteration_charge = item.iteration_charge ? false : true;
      }
    });
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    if (states.propDuration.prop_dur_num <= states.completed_milestone_no) {
      valid = false;
      alert(`Proposed duration count can't be less than or equal to already paid cycles that is ${states?.completed_milestone_no}.`);
    }
    if (!valid) { scroll() }
    setStates({ ...states });
    return valid;
  };

  const handleChangeNote = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length <= 1000) {
      states.validNote = value.length > 0 ? false : true;
      setStates({
        ...states,
        note: value,
      });
    }
  };

  const handleNumbers = (action, e) => {
    let numbers = states.propDuration.prop_dur_num;
    if (action === "plus") {
      numbers == 0 ? (states.propDuration.prop_dur_num = 1) : (states.propDuration.prop_dur_num += 1);
    } else if (action === "change") {
      states.propDuration.prop_dur_num = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (states.propDuration.prop_dur_num > 1) states.propDuration.prop_dur_num -= 1;
    }
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    setStates({ ...states, propDuration: { ...states.propDuration } });
  };

  const handleChangeDate = (e) => {
    states.proposed_start_date = new Date(e);
    setStates({ ...states });
  };

  const handleTypeChange = () => {
    props.changeShowSubmitProposal(true);
  };

  const handleSelect = (selected, which) => {
    if (which === "duration")
      setStates({
        ...states,
        propDuration: { ...states.propDuration, prop_duration: selected },
      });
    else
      setStates({
        ...states,
        prefBillingCycle: selected,
      });
  };

  const handleSubmitApi = async () => {
    setLoader(true);
    let arrDel = [
      {
        iteration: "0",
        iteration_charge: 0,
        name: "advance_payment",
        type: "token",
        price: 0,
      },
    ];
    states.deliverable.map((item) => {
      arrDel.push({
        iteration: item?.toggle ? item.iterations : 0,
        price: item.breakup,
        iteration_charge: item?.toggle ? item.iteration_charge : 0,
        name: item.name,
        type: "milestone",
      });
    });
    
    let data = {
      post_project_id: id,
      proposal_type: "actual",
      payment_terms: [],
      deliverables: arrDel,
      project_ids: _ProjectsIs?.project_ids,
      link_image: _ProjectsIs?.link_image,
      urls: _ProjectsIs?.urls,
      working_days_in_week: [],
      preferred_billing_cycle: states.prefBillingCycle.label,
      note: states.note,
      proposed_start_date: addHrMinDate(states.proposed_start_date,5,30),
      proposed_duration_type: states.propDuration.prop_duration.label,
      proposed_duration: states.propDuration.prop_dur_num,
      total_cost: gst_total_price,
      sub_total_cost: total_price,
      tax: parseInt((total_price * 0.18).toFixed(2)),
      total_earning: totalEarning,
      advance_amount: 0,
    };

    // console.log("data = ", data)

    try {
      let path=props.location.pathname.includes('jobs')?"/milestone/add-outside-proposals":"/proposal/proposal-submit"
      const res = await postAPI(path, data);
      if (res?.data) {
        setLoader(false);
        setShowReviewProp(false);
        setShowSuccessProp(true);
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };

  let total_price = 0;
  states.deliverable.forEach((item) => {
    total_price += Number(item?.breakup);
  });
  let gst_total_price = 0;
  gst_total_price = Number(total_price + total_price * 0.18);

  let totalEarning = 0;
  totalEarning = Number(total_price * 0.85);

  const dateimg = <img src={DateIcon}/>;
  return (
    <>
      <section className="submit_proposal_on_actuals main_page_container">
        <div className="row submit_proposal_on_actuals_container mx-0 mob-p-0">
          {/* top_heading */}
          <div className="col-lg-12 py-4">
            <div className="top_header d-flex align-items-center mob-justify-content-between">
              <div className="heading_icon">
                <img
                  src={HeaderIcon}
                  className="img-fluid cursor-pointer pr-4"
                  // onClick={() => (props?.history?.goBack() ? props.history.goBack() : "")}
                  onClick={() => props.data?.project_data?.project_slug? onClickBack():""}
                />
                <span className="submit_proposal_heading mb-0">Submit Proposal</span>
              </div>
              <div class="dropdown dropleft d-block d-lg-none">
                <img src={DotImage} className='dropdown-toggle' data-toggle="dropdown" />
                <div class="dropdown-menu">
                  <a class="dropdown-item font-12 font-weight-600" href="#">View Brief</a>
                  <a class="dropdown-item font-12 font-weight-600" href="#">Learn Best Practices</a>
                </div>
              </div>

            </div>
          </div>
          {/* left_side */}
          <div className="col-lg-9 mb-4">
            {/* page_card */}
            <div className="page_card">
              <div className="proposal_header br_btm mb-4 d-flex justify-content-between">
                <div className="prposal_heading d-flex align-items-center">
                  <p className="mb-0 mr-3 text_black_lg_600">Your Proposal</p>
                  <div className="tag_btn_dark_green_common">
                    <p className="mb-0">
                      <img src={PercentIcon} className="icon_img mr-1" />
                      On-Actuals
                    </p>
                  </div>
                </div>

                {loading || props?.data?.project_data?.status == 6 ? null : (
                  <div className="edit_proposal d-flex align-items-center" onClick={handleTypeChange}>
                    <p className="mb-0 mr-3 edit_proposal_text anchor_text_sm_w_700 d-none d-lg-block">Edit Proposal Type</p>
                    <img src={EditIcon} className="icon_img mr-1" />
                  </div>
                )}
              </div>

              {loading ? (
                <Loader isCreateModal={true} style={{ paddingTop: "15rem", paddingBottom: "15rem" }} />
              ) : (
                <>
                  <div className="proposal_date px-4 pb-4 d-block d-md-flex justify-content-between align-items-center">
                    <div className="proposal_date_title">
                      <p className="mb-0 font_semi_bold_w_600">Proposed start date</p>
                      <p className="proposal_date_sub_title">Estimated Beginning Date</p>
                    </div>
                    <div className="date_input mob-pt-3">
                      <DatePicker
                        value={states.proposed_start_date}
                        className="form-control text-center input_sm"
                        clearIcon={null}
                        name="startDate"
                        format="dd/MM/yyyy"
                        calendarIcon={dateimg}
                        calendarClassName="apniClass"
                        showLeadingZeros={true}
                        onChange={handleChangeDate}
                        minDate={setTimeToZero(new Date())}
                        disabled={(props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator  > 1) ? true : false}
                      />
                    </div>
                  </div>

                  {/* time_commitment */}
                  <div className="time_commitment px-4 mb-4">
                    <div className="row deliverables mx-0">
                      <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                        <p className="mb-0 text_black_md">Terms</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            href={`#collapseTerms`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseTerms`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0 collapse show " id={`collapseTerms`}>
                      <div className="col-lg-12 px-0 py-3">
                        <div className="row pb-4 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7">
                            <p className="font_semi_bold_w_600 mb-0">Proposed Duration</p>
                            <p className="text_light_gray_xs mb-0">Indicate how long you will be able to work on this project. </p>
                          </div>
                          <div className="duration_fields d-flex col-xl-5 justify-content-end mob-justify-content-unset mob-pt-3">
                            <div className="btn_field number_btn">
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("minus")}>
                                  -
                                </button>
                                <input
                                  type="number"
                                  class="form-control text-center input_sm"
                                  value={states.propDuration.prop_dur_num}
                                  onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
                                  onChange={(e) => handleNumbers("change", e)}
                                />
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("plus")}>
                                  +
                                </button>
                              </div>
                              {states.validation.prop_dur_num ? <p className="error_message">field required</p> : ""}
                            </div>

                            <div
                              className="dropedown_field select_dropdown d-flex align-items-center ml-4"
                              style={{ minWidth: "3rem" }}
                            >
                              {/* <input class="form-control input_md" placeholder="Week" />
                          <img className="down_icon" src={DownIcon}></img>
                          <Select
                            options={[
                              // { label: "Week", value: "Week" },
                              { label: "Month", value: "Month" },
                            ]}
                            value={states.propDuration.prop_duration}
                            onChange={(e) => handleSelect(e, "duration")}
                          /> */}
                              <p className="text_light_gray_md_w_700 mb-0">{states.propDuration.prop_dur_num > 1 ? states.propDuration.prop_duration.value : "Month"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div className="row br_tp py-4 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7">
                            <p className="font_semi_bold_w_600 mb-0">Perferred Billing Cycle</p>
                            <p className="text_light_gray_xs mb-0">Select when would you like to get paid</p>
                          </div>
                          <div className="duration_fields col-xl-5 d-flex justify-content-end mob-justify-content-unset mob-pt-3">
                            <div className="dropedown_field select_dropdown">
                              <Select
                                options={[
                                  { label: "Cycle End", value: "Monthly" },
                                  { label: "Month End", value: "Month End" },
                                ]}
                                isDisabled = {props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator  > 1}
                                value={states.prefBillingCycle}
                                onChange={(e) => handleSelect(e, "Billing")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Deliverables_section */}
                  <ActualDeliverable states={states} setStates={setStates} validationDeliverable={validationDeliverable} />
                  {/* end_deliverables_section */}
                  <div ref={DeliverableRef}></div>
                  {/*Add References */}

                  <Reference />

                  {/* end Add References */}

                  {/* add notes */}
                  <div className="row  px-4 pb-4 add_notes mx-0">
                    <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                      <p className="mb-0">Additional Notes</p>
                      <div className="d-block d-lg-none">
                        <img
                          src={FeaturedToggleImage}
                          className="icon_img mr-1 cursor-pointer"
                          data-toggle="collapse"
                          href={`#collapseNotes`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`collapseNotes`}
                        />
                      </div>
                    </div>
                    {/* <div className='col-lg-12 px-0 py-4'>
                  <div className='upload_notes'>
                 
                    <p className='upload_sub_title'>Please add any additional notes, points or disclaimers here</p>
                  </div>
                 

                </div> */}
                    <div className="col-sm-12 px-0">
                      <div className="row mx-0 collapse show " id={`collapseNotes`}>
                        <div className="col-lg-12 px-0 py-4">
                          {/* <input class="form-control input_md upload_notes upload_sub_title" type="text" placeholder="Please add any additional notes, points or disclaimers here"/> */}
                          <textarea
                            class={`form-control upload_notes`}
                            id="exampleFormControlTextarea1"
                            rows="5"
                            placeholder="Please add any additional notes, comments, or explanations here. Please note that any proposals with email IDs or phone numbers will be rejected."
                            value={states.note}
                            onChange={handleChangeNote}
                          />
                          {/* {states.validNote ? <p className="error_message">field required</p> : ""} */}
                          <span className="font-weight-500 text-secondary">{states?.note?.length}/1000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end add notes */}
                </>
              )}
            </div>
          </div>
          {/* right_side */}
          <div className="col-lg-3">
            <div className="sticky-top">
              {/* card1 */}
              <div className="page_card">
                <div className="proposal_header mb-4 br_btm d-none d-lg-block">
                  <div className="prposal_heading d-flex align-items-center">
                    <p className="mb-0 text_black_700_w_600">Payment Summary</p>
                  </div>
                </div>
                <a className="edit_proposal d-block p-3 d-lg-none text-center" data-toggle="collapse" href={`#collapsePayment`} role="button" aria-expanded="false" aria-controls={`collapsePayment`}>
                  <span className=" mb-0 sub_title_commn mr-2">Payment Summary</span>
                  <img
                    src={FeaturedToggleImage}
                    className="icon_img mr-1 cursor-pointer"
                  />
                </a>
                <div className="row mx-0 collapse show " id={`collapsePayment`}>
                  <div className="total_project_price mb-4 col-lg-12">
                    <div className="col-lg-12 mb-4 px-0">
                      <div className="project_price_title d-flex justify-content-between align-items-center line_height_18 mb-3">
                        <p className="mb-0 font_semi_bold_w_600"> The amount you’ll receive depends on items approved by client.</p>
                        <p className="mb-0 d-flex align-items-center">
                          {/* <div
                            class="position-relative"
                            data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable 
                            taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                          >
                            <img src={ExclamationIcon} className="img-fluid  ml-3" />
                          </div> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pb-4">
                  <button
                    type="button"
                    class="btn btn-common w-100"
                    data-toggle="modal"
                    // data-target="#OnActualsReviewConfirm"
                    onClick={() => {
                      let result = validationDeliverable() ? setShowReviewProp(true) : "";
                    }}
                  >
                    Review & Confirm
                  </button>
                </div>

              </div>
              {/* card2 */}

              <ProjectDetail data={props?.data} id={props?.id} {...props} proposalSubmit={true}/>

              {/* bottom link */}
              <div className="col-lg-12 d-none d-lg-block">
                {/* <div className="d-flex mt-4 justify-content-around align-items-center ">
                  <p className=" mb-0">
                    <a className="anchor_text">Learn Best Practices for Proposals </a>
                  </p>
                  <img src={ArrowIcon} className="icon_img mr-1" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <SubmitProp
        changeShowSubmitProposal={props?.changeShowSubmitProposal}
        id={props?.id}
        {...props}
        showSubmitProposal={props?.showSubmitProposal}
        data={props?.data}
      />
      <ReviewProposal
        showReviewProp={showReviewProp}
        setShowReviewProp={setShowReviewProp}
        handleSubmitApi={handleSubmitApi}
        states={states}
        propType="actual"
        loader={loader}
      />
      <SuccessProp showSuccessProp={showSuccessProp} setShowSuccessProp={setShowSuccessProp} propType="actual" {...props} />

      {/* <OnActualsReviewConfirm /> */}
      {/* <Sucess/> */}
    </>
  );
}
