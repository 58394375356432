
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAPI, postAPI } from '../../../../utils/API';
import { BaseImageUrl, RazorpayKey, prefill, ChatBaseUrl } from '../../../../utils/BaseUrl';
import { getDateTime } from '../../../../utils/DateMethod';
import { getFileName, getMsgTime, isViewableFile, renderDifferentFilesThumbnail } from '../../Common/Common/FileRenderMessage';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import defaultThumbnail from '../../../../Assets/images/video-thumbnail-default.png';
import { getThumbnailImage, isRandomUrl, sanitiseSummernoteText } from '../../../../utils/Helperfunctions'
import CustomSummernote from '../CustomSummernote';
import RenderHtml from '../../Common/RenderHtml/RenderHtml';
import RandomUrlPreview from '../../Common/RandomUrlPreview/RandomUrlPreview';
import socketIOClient from 'socket.io-client';
import AllFileViewer from '../../Common/AllFileViewer/AllFileViewer';
import { limitString } from '../../../../utils/Helperfunctions'; 

class ReviewDeliverable extends React.Component {
    state = {
        user: '',
        previewSrc: '',
        completed_Milestone: '',
        completed_Milestone_versions: [],
        completed_Milestone_comment: [],
        deliverable_feedback:'',
        offline_payment: null,
        isLoading: false
    }

    feedbackRef = React.createRef()

    getMilestoneData = () => {
        getAPI('/milestone/complete-milestone?milestone_complete_id=' + this.props.CurrentMilestoneId)
        .then((res) => { 
            this.handleState(res.data)
        }).catch((error) => { 
            this.setState({ isLoading: false })
        })
    }

    handleState = (data) => {
        // console.log(data)
        this.props.SetCurrentMileStoneID(data.completed_Milestone._id)
        this.setState({
            offline_payment:data.offline_payment,
            user: data.user,
            milestone: data.milestone,
            milestone_id: data.milestone._id,
            completed_Milestone: data.completed_Milestone,
            completed_Milestone_comment: data.completed_Milestone_comment,
            previewSrc: data.completed_Milestone.files.length ? data.completed_Milestone.files[0].name : data.completed_Milestone.links[0].link,
            isUrl: data.completed_Milestone.files.length ? false : true,
            thumbnailIndex: 0,
            isLast: this.props.milestones[this.props.milestones.length - 1]._id === data.milestone._id  ? true : false
        })
    }

    getLatestMilestone = async (id) => {
        try{
            const res = await getAPI(`/milestone/latest-completed-milestone?milestone_id=${id}`)
            this.handleState(res.data)
        } catch(error){
            console.log(error)
        }
    }

    componentDidMount = () => {
        // whenever new msg comes reload milestone data
        const socket = socketIOClient(ChatBaseUrl, 
            // { transports: ['websocket'] }
            );
        this.setState({socket: socket})
        socket.on(this.props.UserToken, (resp) => {
            this.getMilestoneData()
            this.feedbackRef.current.scrollTop = 0;
        });

        // const script = document.createElement('script');
        // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        // script.async = true;
        // document.body.appendChild(script);
        // isLoadLatestMilestone
        //   latestMilestoneId
        const {isLoadLatestMilestone, latestMilestoneId} = this.props;
        if(isLoadLatestMilestone){
            this.getLatestMilestone(latestMilestoneId)
            this.props.resetLatestMilestoneId()
        } else {
            this.getMilestoneData()
        }
    }

    componentWillUnmount(){
        this.state.socket.disconnect()
    }

    getVersionsList = (screen) => {
        getAPI('/milestone/completed-milestone?milestone_id=' + this.state.milestone_id).then((res) => {
          
            this.setState({
                completed_Milestone_versions: res.data.data,
                show_version_list: true,
            })
            if(screen === 'mobile'){
                this.props.setVersionHistoryData(res.data.data)
                this.props.OpenPopup('MobileVersionHistory')
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ isLoading: false })
        })
    }
    showFeedback = () => {
        this.setState({
            show_version_list: false,
        })
    }

    showVersionList = () => {
        this.setState({
            show_version_list: true,
            show_current_version: false,
        })
    }

    showCurrentVersionDetails = (version, comments) => {
 
        this.setState({
            show_current_version: true,
            show_version_list: false,
            current_version_id: version._id,
            current_version_number: version.number,
            current_version_files: version.files,
            current_version_links: version.links,
            current_version_comments: comments,
            current_version_date: version.createdAt,
        })
    }

    setPreview = (previewSrc, index,isUrl) => {
        this.setState({
            previewSrc,
            thumbnailIndex: index,
            isUrl: isUrl,
        })
    }
    approveDeliverable = () => {
        let milestone_id = this.state.completed_Milestone.milestone_id;
        const milestone_details = {
            milestone_id: milestone_id,
        }
        postAPI('/milestone/approve-milestone', milestone_details).then((res) => {
     
            if (res.data.status === 1) {
                alert("Approved!!")
                this.props.loadChatMessages(this.props.CurrentChat);
                this.props.closePopup();
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    reloadChat = () => {
        getAPI('/users/user-profile').then(res => 
            this.props.loadMe({
            name: 'UPDATE_LOAD_LOGGEDIN_USER',
            value: res.data.data,
          }))
        this.props.loadLatestProposals(this.props.ProjectId)
        this.props.loadChatMessages(this.props.CurrentChat);
        this.props.closePopup();
    }

    
  approveWithoutPayment = async () => {
    try{ 
        const milestoneId     = this.state.completed_Milestone.milestone_id;
        const proposalId = this.props.proposal._id;

        const obj = {
            milestone_id: milestoneId,
            proposal_id: proposalId,
            type: "milestone"
        }
        
        const res = await postAPI('/payment/direct-payment', obj);
        if(parseInt(res.data.status) === 1){
            this.reloadChat()
        }

        } catch(error){
            this.setState({isLoading: false});
            console.log(error)
        }
  }


    approvePayHandler = () => {
        let milestone_amount = this.state.milestone.price;
        // console.log(milestone_amount, this.state.offline_payment)
        if(milestone_amount === 0 || this.state.offline_payment === 1){
            const isConfirm = window.confirm('Do you want to approve deliverable?');
            if(isConfirm){
                this.setState({isLoading: true});
                this.approveWithoutPayment()
            } 
        } else {
            this.setState({isLoading: true});
            this.approvePay()
        }
    }

    approvePay = () => {
        let milestone_id     = this.state.completed_Milestone.milestone_id;
        let milestone_amount = this.state.milestone.price; 
        const payment_details = {
            amount: (milestone_amount + (milestone_amount * 0.18)) * 100,
            currency: "INR",
            receipt: "#",
            //proposal_id: proposal_data.proposal._id,
            milestone_id:milestone_id,
            type: "milestone"
        }
        postAPI('/payment/create-order', payment_details).then((res) => {
            // console.log(res)
            if (res.data.status === "1") { 
                this.setState({
                    order_id: res.data.data.order_id,
                    milestone_id: res.data.data.milestone_id,
                    payment_type: res.data.data.type,
                },(e) => this.paymentHandler());
            }
        }).catch((error) => {
            this.setState({isLoading: false});
            console.log(error.response);
        })
    }

    paymentHandler = async () => {
        const options = {
            key: RazorpayKey,
            name: "IndieFolio",
            description: "Milestone payment",
            order_id: this.state.order_id,
            
        
            handler: async (response) => {
                // console.log(response)
                try {
                    const paymentId = response.razorpay_payment_id;
                    const orderId = response.razorpay_order_id;
                    const signature = response.razorpay_signature;
                    const milestone_id = this.state.milestone_id;
                    const type = this.state.payment_type;

                    let payment_details = {
                        payment_id: paymentId,
                        order_id: orderId,
                        signature: signature,
                        milestone_id: milestone_id,
                        type: type
                    }

                    postAPI('/payment/verify-signeture', payment_details).then((res) => {
                     
                        if (parseInt(res.data.status) === 1) {
                      
                            this.reloadChat()
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                    //  const url = `${API_URL}capture/${paymentId}`;
                    //  const captureResponse = await Axios.post(url, {})

                } catch (err) {
                    console.log(err);
                }
            },
            theme: {
                color: "#686CFD",
            },
        };
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            options.prefill = prefill
        }

        
        
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        this.setState({isLoading: false});
    };

    handleFeedback = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        })
    }
    handleFeedbackNew = (value) => {
        // const { name, value } = event.target;
        this.setState({
            deliverable_feedback: value,
        })
    }

    sendFeedbackOnClick = (event) => {
        if (event.charCode === 13) {
            this.sendFeedback();
        }
    }
    sendFeedback = () => {
        const {deliverable_feedback, user} = this.state;
        const {ProjectType} = this.props;
        const isAdminProject = ProjectType === 'post_project_admin'

        if(!deliverable_feedback) return
        let milestone_id = this.state.completed_Milestone._id;
        const milestone_details = {
            milestonecompletes_id: milestone_id,
            comment: sanitiseSummernoteText(deliverable_feedback),
        }
        postAPI('/milestone/milestone-comment', milestone_details).then((res) => {
            if (res.data.status === 1) {
                alert(`Thank you for reviewing ${user.first_name}'s work. Your review has been successfully recorded`);
                this.getMilestoneData()
                this.setState({deliverable_feedback: ''})
                this.props.loadChatMessages(this.props.CurrentChat);
                if(isAdminProject){
                    this.props.loadAdminProjectProposal()
                } else {
                    this.props.loadLatestProposals(this.props.ProjectId)
                }

                // Reset milestone feedback height
                document.querySelector('.chat-modal-section .chatting-box-overflow').style.height = 'calc(100vh - 200px)'
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    viewFullScreen = () => {
        const {completed_Milestone, milestone, user} = this.state
        completed_Milestone.serial_no = milestone.serial_no
        completed_Milestone.user = user
        this.props.setCompletedMilestone(completed_Milestone)
        this.props.OpenPopup('ViewModalFullScreen')
    }

    render() {
        const { ProjectName } = this.props;
        const { user, completed_Milestone, previewSrc,isUrl, thumbnailIndex, completed_Milestone_versions, 
            show_version_list, completed_Milestone_comment, show_current_version, current_version_number, 
            current_version_files, current_version_links, current_version_comments, current_version_date, 
            milestone, isLast, isLoading } = this.state;
        const filesAndLinks = completed_Milestone ? completed_Milestone.files.concat(completed_Milestone.links).sort((a,b) => a.index -b.index) : []


        const options = {
            loop:false,
            margin:10,
            responsiveClass:true,
            responsive:{
                  0:{
                      items:1,
                      nav:true
                  },
      
                  700: {
                      items: 2,
                      nav: true,
                    //   loop: true
                  },
                  1030: {
                      items: 2,
                      nav: true,
                    //   loop:false
                  },
      
                  1366:{
                      items:2,
                      nav:true,
                    //   loop:true
                  },
      
              }
          }; 
        return (
            <>
                <div className="modal fade custom_form user_form_section custom_Modal_Box show" style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fluid" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/*-- Mobile Version Headline --*/}
                                <div className="mobile-show">
                                    <div className="row m-0 pt-4 pb-3 mob-pb-15 mob-pt-10 algin-items-center mob-bg-shadow justify-content-between">
                                        <div className="col-12 col-sm-9 mob-pl-0 d-flex align-items-center">
                                            {/* Mobile Icon Show */}
                                            <div className="px-3 mobile-show">
                                                <div className=''>
                                                    <i className="far fa-calendar-alt font-20 text-dark-black" />
                                                </div>
                                            </div>
                                            <div>
                                                <span className="font-14 text-dark-black line-height-normal letter-spacing-023 font-weight-500 d-block">{ProjectName}</span>
                                                <h2 className="mb-0 mt-1 font-20 text-dark-black font-wight-bold letter-spacing-01 mob-font-16">Milestone {milestone?.serial_no} Deliverables <span className="line-height-normal mob-d-block font-weight-500 font-12 letter-spacing-02 text-sub-5 ipad-d-block">shared by {user.first_name} {user.last_name}</span></h2>
                                            </div>
                                        </div>
                                        {this.props.CurrentRole === 1 && this.props.CurrentMilestoneStatus !== 3 && milestone?.status === 1 ?
                                            <div className="col-sm-3 p-0 mobile-hide">
                                                <button type="button" 
                                                        disabled={isLoading}
                                                        className="main-btn font-12 mt-1 font-weight-600 cursor-pointer" 
                                                        onClick={this.approvePayHandler}>Approve deliverables</button>
                                            </div>
                                            : <></>}
                                        <div className="col-sm-12 mobile-hide">
                                            <div className="mb-0 font-weight-500 font-12 pt-1 text-dark-black line-height-17 letter-spacing-01">
                                                <RenderHtml data={completed_Milestone.description} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className={`col-sm-8 ipad-normal-col-60 mob-w-col-100 bg-white border-top-left-radius border-bottom-left-radius milestone-approve-section mob-p-0 overflow-content mob-milestone-modal ${this.props.CurrentRole === 2 || !(this.props.CurrentRole === 1 && this.props.CurrentMilestoneStatus !== 3 && milestone?.status === 1) ? 'creator_review_milestone' : '' }`}> 
                                        <div className="mobile-ipad-none">
                                            <div className="row m-0 pt-4 pb-3 mob-pb-15 mob-pt-10 algin-items-center mob-bg-shadow justify-content-between">
                                                <div className="col-12 col-sm-9 mob-pl-0 d-flex align-items-center">
                                                    {/* Mobile Icon Show */}
                                                    <div className="px-3 mobile-show">
                                                        <div className=''>
                                                            <i className="far fa-calendar-alt font-20 text-dark-black" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="font-14 text-dark-black line-height-normal letter-spacing-023 font-weight-500 d-block">
                                                            {/* {ProjectName} */}
                                                            {ProjectName ? limitString(ProjectName, 50) : ProjectName }
                                                        </span>
                                                        <h2 className="mb-0 mt-1 font-20 text-dark-black font-wight-bold letter-spacing-01 mob-font-16">Milestone {milestone?.serial_no} Deliverables <span className="line-height-normal mob-d-block font-weight-500 font-12 letter-spacing-02 text-sub-5 ipad-d-block">shared by {user.first_name} {user.last_name}</span></h2>
                                                    </div>
                                                </div>
                                                {this.props.CurrentRole === 1 && this.props.CurrentMilestoneStatus !== 3 && milestone?.status === 1 ?
                                                    <div className="col-sm-3 p-0 mobile-hide">
                                                        <button type="button" 
                                                                disabled={isLoading}
                                                                className="main-btn font-12 mt-1 font-weight-600 cursor-pointer" 
                                                                onClick={this.approvePayHandler}>Approve deliverables</button>
                                                    </div>
                                                    : <></>}
                                                <div className="col-sm-12 mobile-hide">
                                                    <div className="mb-0 font-weight-500 font-12 pt-1 text-dark-black line-height-17 letter-spacing-01">
                                                        {/* {completed_Milestone.description} */}
                                                        <RenderHtml data={completed_Milestone.description} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Mobile Content text */}
                                        {completed_Milestone.description ? <div className="mobile-show">
                                            <div className="row m-0 py-3">
                                                <div className="col-sm-12 d-flex align-items-center">
                                                    <div className=''>
                                                        <p className="mb-0 creator-profile">
                                                            <img src={BaseImageUrl + user.profile_image} alt="profile" width={35} />
                                                        </p>
                                                    </div>
                                                    <div className="pl-2">
                                                        <h2 className="chat-username text-dark-black m-0 font-14 font-weight-500 line-height-16">
                                                            {completed_Milestone.description}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                        <div className="view-milestone-images mob-px-10">
                                            <div className={`user-work-section position-relative overflow-hidden pt-0 ${completed_Milestone.description ? 'mt-0' : ''}`}>
                                                {isUrl ? 
                                                    isRandomUrl(previewSrc) ?
                                                        <RandomUrlPreview url={previewSrc} /> :
                                                        <iframe title="custom-url" className="submit-milestone-iframe" src={previewSrc}></iframe> :
                                                    isViewableFile(previewSrc) ? 
                                                        <AllFileViewer link={previewSrc} />
                                                            :
                                                        <div className="project_big_image" style={{backgroundImage: `url("${renderDifferentFilesThumbnail(previewSrc)}")`}}></div>
                                                }
                                                     {/* <img src={renderDifferentFilesThumbnail(previewSrc)} alt="work" className="img-fluid" /> */}
                                                    {/* <div className="project_big_image" style={{ backgroundImage: `url("${previewSrc}")` }}></div> */}
                                                    {/* <RenderImage3 image={previewSrc} /> */}
                                                
                                                <div className="milestone-image-details">
                                                    <div className="dflex">
                                                        <div className="text-left">
                                                            <h2 className="mb-0 font-18 text-white font-weight-600">{getFileName(previewSrc)}</h2>
                                                            <span className="text-white font-weight-500 font-11">Received on {getDateTime(completed_Milestone.createdAt, "dateMonth")}</span>
                                                        </div>
                                                        <div className="milestone-received">
                                                            <span><a href={previewSrc} download={getFileName(previewSrc)} target="_blank" rel="noopener noreferrer" ><i className="fas fa-download" /></a></span>
                                                            <span onClick={() => this.viewFullScreen()}><i className="fas fa-arrows-alt" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-ipad-none">
                                                <div className="user-work-thumbnail">
                                                {completed_Milestone && filesAndLinks.map((item, idx) => {
                                                    if(item.hasOwnProperty('link')){
                                                        // {/* const idx = completed_Milestone.files.length + k */}
                                                        return(
                                                            <span key={idx} className={`thumb-img mb-2 ${thumbnailIndex === idx ? 'active' : ''}`} onClick={(e) => this.setPreview(item.link, idx,true)}>
                                                            {/* <img src={defaultThumbnail} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} /> */}
                                                            <RenderImage image={item.link} thumbnailIndex={thumbnailIndex} k={idx} />
                                                            </span>
                                                        )
                                                    } else {
                                                        return(
                                                            <span key={idx} className={`thumb-img mb-2 ${thumbnailIndex === idx ? 'active' : ''}`} onClick={(e) => this.setPreview(item.name, idx,false)}>
                                                            {/* <img src={renderDifferentFilesThumbnail(files.name)} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} /> */}
                                                            <span className="upload_image_thumbnail" style={{backgroundImage:`url("${renderDifferentFilesThumbnail(item.name)}")`}}></span>
                                                        </span>
                                                        )
                                                    }
                                                })}
                                                    {/* {completed_Milestone && completed_Milestone.files.map((files, k) => (
                                                        <span key={k} className={`thumb-img mb-2 ${thumbnailIndex === k ? 'active' : ''}`} onClick={(e) => this.setPreview(files.name, k,false)}>
                                                            <span className="upload_image_thumbnail" style={{backgroundImage:`url("${renderDifferentFilesThumbnail(files.name)}")`}}></span>
                                                        </span>
                                                    ))
                                                    } */}
                                                    {/* {completed_Milestone && completed_Milestone.links.map((links, k) => 
                                                    {
                                                        const idx = completed_Milestone.files.length + k
                                                        return (
                                                        <span key={idx} className={`thumb-img mb-2 ${thumbnailIndex === idx ? 'active' : ''}`} onClick={(e) => this.setPreview(links.link, idx,true)}>
                                                            <RenderImage image={links.link} thumbnailIndex={thumbnailIndex} k={idx} />
                                                        </span>
                                                    )})
                                                    } */}
                                                </div>
                                            </div>
                                            <div className="mobile-ipad-show">
                                                <div className="user-work-thumbnail">
                                                    {completed_Milestone && completed_Milestone.files.map((files, k) => {
                                                        if (k < 3) {
                                                            return (
                                                                <span key={k} className={`thumb-img mb-2 ${thumbnailIndex === k ? 'active' : ''}`} onClick={(e) => this.setPreview(files.name, k, false)}>
                                                                    <img src={renderDifferentFilesThumbnail(files.name)} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} />
                                                                </span>
                                                            )
                                                        } else return null
                                                    })
                                                    }
                                                    {completed_Milestone && completed_Milestone.links.map((links, k) => 
                                                    {
                                                        const idx = completed_Milestone.files.length + k
                                                        if(idx < 3){
                                                            return (
                                                                <span key={idx} className={`thumb-img mb-2 ${thumbnailIndex === idx ? 'active' : ''}`} onClick={(e) => this.setPreview(links.link, idx,true)}>
                                                                    {/* <img src={defaultThumbnail} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} /> */}
                                                                    <RenderImage image={links.link} thumbnailIndex={thumbnailIndex} k={idx} />
                                                                </span>
                                                            )
                                                        } else return null
                                                    })
                                                    }
                                                    {completed_Milestone && (completed_Milestone.files.length + completed_Milestone.links.length > 3) ?
                                                        <div className="more_thumb">
                                                            +
                                                        {completed_Milestone.files.length + completed_Milestone.links.length - 3}
                                                    </div>: null}
                                                </div>
                                            </div>
                                        </div>
                                        {isLast ? <div className="card-wrapper mb-3">
                                            <div className="row m-0">
                                                <div className="col-sm-12">
                                                    <div className="border-yellow border-radius-8 font-14 text-center py-2 font-weight-400 text-dark-black line-height-normal mob-text-left mob-px-10 ipad-only-px-10">
                                                        Note: The link to the open files will be shared once you make the payment
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null} 
                                    </div>
                                    {/* Mobile Version History */}
                                    <div className="mobile-show mob-px-15 py-3 mob-w-100">
                                        <button onClick={() => this.getVersionsList('mobile')} type="button" className="main-btn my-2 font-14 py-12 mob-w-100 bg-light-gray font-weight-600 ">Version History</button>
                                        {this.props.CurrentRole === 1 && this.props.CurrentMilestoneStatus !== 3 && milestone?.status === 1 
                                            ? <button onClick={this.approvePayHandler} disabled={isLoading} type="button" className="main-btn my-2 font-14 py-12 mob-w-100 font-weight-600 ">Approve Deliverables</button> 
                                            : null }
                                    </div>

                                    <div className="col-sm-4 ipad-normal-col-40 mob-w-col-100 chat-box-section chat-modal-section bg-light-sky mob-bg-white p-0 mob-border-left-right mobile-hide">
                                        <div className="chat-header">
                                            <div className="row m-0">
                                                {!show_version_list && !show_current_version ?
                                                    <div className="col-sm-12 p-0">
                                                        <div className="dflex">
                                                            <div>
                                                                <h2 className="chat-username text-dark-black text-capitalize m-0 font-18 font-weight-600 mob-font-16">Feedback</h2>
                                                            </div>
                                                            <div>
                                                                <button type="button" className="main-btn font-12 font-weight-600 bg-gray-mode text-four mob-w-100 cursor-pointer " onClick={this.getVersionsList}>View version history</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    show_version_list ?
                                                        <div className="col-sm-12 p-0 py-2">
                                                            <h2 className="chat-username text-dark-black text-capitalize mb-0 font-18 font-weight-600 mob-font-16 cursor-pointer"><i className="fas fa-arrow-left font-16 mr-1 text-light-gray" onClick={this.showFeedback} /> Version history</h2>
                                                        </div> : <></>
                                                }

                                                {/* Inside Version Headline */}
                                                {show_current_version ?
                                                    <div className="col-sm-12 p-0 py-2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="cursor-pointer">
                                                                <i className="fas fa-chevron-left mr-1 font-18 text-light-gray" onClick={this.showVersionList} />
                                                            </div>
                                                            <div className="pl-1">
                                                                <h2 className="chat-username text-dark-black text-capitalize mb-1 font-18 font-weight-600 mob-font-12">Version {current_version_number}</h2>
                                                                <p className="mb-0 text-third font-10 letter-spacing-02 line-height-17 font-weight-500">
                                                                    Submitted on {getDateTime(current_version_date, 'dateMonth')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <></>}
                                            </div>
                                        </div>
                                        {/* Inside Chat Content */}
                                        <div className="card-wrapper mb-0">
                                            <div ref={this.feedbackRef} className="overflow-content chat-milestone-modal-height chatting-box-overflow full-height pt-4">
                                                {/* Version history */}
                                                <div className="version-history">
                                                    {show_version_list ?
                                                        completed_Milestone_versions && completed_Milestone_versions.map((versions, k) => 
                                                        { 
                                                            const firstImageData = [...versions.completed_Milestone.files, ...versions.completed_Milestone.links][0]
                                                            const commentLength = versions.completed_Milestone_comment.length
                                                            return(
                                                                <div key={k} className="card-wrapper mb-3">
                                                                <div className="card-body px-4 position-relative">
                                                                    <div className="custom_form">
                                                                        <div className="card-wrapper mb-10">
                                                                            <div className="row m-0 d-flex">
                                                                                <div className="col-sm-12 p-0">
                                                                                    <div className="d-flex">
                                                                                        <div className="w-30 mob-w-auto">
                                                                                            {/* <p className="mb-0 version-profile" style={{ backgroundImage: `url(${firstImage})` }}>
                                                                                            </p> */}
                                                                                            {firstImageData.name ? 
                                                                                                <p className="mb-0 version-profile" style={{ backgroundImage: `url("${renderDifferentFilesThumbnail(firstImageData.name)}")` }}> </p>
                                                                                                : <RenderImage2 image={firstImageData}  />    
                                                                                            }
                                                                                        </div>
                                                                                        <div className="w-70 mob-w-auto pl-3">
                                                                                            <div className="w-100 cursor-pointer" onClick={(e) => this.showCurrentVersionDetails(versions.completed_Milestone, versions.completed_Milestone_comment)}>
                                                                                                <h2 className="chat-username text-capitalize mb-1 font-14 font-weight-600 mob-font-14">Version {versions.completed_Milestone.number}
                                                                                                    <i className="fas fa-chevron-right ml-1 font-12" />
                                                                                                </h2>
                                                                                            </div>
                                                                                            <div className="w-100">
                                                                                                <p className="mb-0 text-third font-12 mob-font-12 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                    Submitted on {getDateTime(versions.completed_Milestone.createdAt, "dateMonth")}
                                                                                                </p>
                                                                                                <p className="mb-0 text-third font-12 mob-font-11 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                    {commentLength} comment{commentLength > 1 ? 's' : ''}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                            }) :
                                                        !show_version_list && !show_current_version && completed_Milestone_comment.map((comments, index) => (
                                                            <div key={index} className="card-wrapper mb-3">
                                                                <div className="card-body pl-4 pr-2 position-relative">
                                                                    <div className="custom_form">
                                                                        <div className="card-wrapper mb-10">
                                                                            <div className="row m-0 d-flex">
                                                                                <div className="col-sm-12 p-0">
                                                                                    <div className="d-flex">
                                                                                        <div className=''>
                                                                                            <p className="mb-0 creator-profile">
                                                                                                <img src={BaseImageUrl + comments.user_id.profile_image} alt="profile" width={20} />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="w-100 pl-2">
                                                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">
                                                                                                    {/* {comments.user_id.first_name} */} 
                                                                                                    {comments.user_id.first_name ? limitString(comments.user_id.first_name, 10) : comments.user_id.first_name}
                                                                                                    </h2>
                                                                                                </div>
                                                                                                <div className="pl-2">
                                                                                                    <span className="font-12 text-sub font-weight-600">{getMsgTime(comments.createdAt, "time")}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-100">
                                                                                                <p className="mb-0 text-black font-13 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                    <RenderHtml data={comments.comment} />
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {/* Slider With Chat Screen */}
                                                {show_current_version ?
                                                    <div className="version-chat">
                                                        <div className="card-wrapper mb-3">
                                                            <div className="row m-0">
                                                                <div className="col-sm-12 pb-3 px-4" id="creatorSliderImage">
                                                                    <OwlCarousel
                                                                        {...options}
                                                                        className="owl-theme"
                                                                    >
                                                                        {[...current_version_files, ...current_version_links].map((obj, index) => (
                                                                            <div key={index} className="item">
                                                                                <div className="card-wrapper box-content box-shadow-box border-radius-8">
                                                                                    <div className="card">
                                                                                        <div className="card-body p-0">
                                                                                            {obj.name ? <div className="version_image" style={{ backgroundImage: `url("${renderDifferentFilesThumbnail(obj.name)}")` }}>
                                                                                            </div> : 
                                                                                            <RenderImage2 image={obj} isDivTag={true} />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        }
                                                                    </OwlCarousel>
                                                                    {/* <div id="creatorSliderImage" className="carousel slide pb-3" data-ride="carousel">
                                                                        <ol className="carousel-indicators">
                                                                            {
                                                                                current_version_files.map((files, index) => (
                                                                                    <li data-target={`#creatorSliderImage_${index}`} data-slide-to={index} className={`${(index === 0) ? "active" : ""}`} />
                                                                                ))
                                                                            }
                                                                        </ol>
                                                                        <div className="carousel-inner">
                                                                            {current_version_files.map((files, index) => (
                                                                                <div className={`carousel-item ${(index === 0) ? "active" : ""}`}>
                                                                                    <img className="d-block w-100" src={renderDifferentFilesThumbnail(files.name)} alt="First slide" />
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                        <a className="carousel-control-prev" href="#creatorSliderImage" role="button" data-slide="prev">
                                                                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                                                                            <span className="sr-only">Previous</span>
                                                                        </a>
                                                                        <a className="carousel-control-next" href="#creatorSliderImage" role="button" data-slide="next">
                                                                            <span className="carousel-control-next-icon" aria-hidden="true" />
                                                                            <span className="sr-only">Next</span>
                                                                        </a>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-wrapper mb-3">
                                                            <div className="card-body px-4 position-relative">
                                                                <div className="custom_form">
                                                                    <div className="card-wrapper mb-10">
                                                                        <div className="row m-0 d-flex">
                                                                            {
                                                                                current_version_comments.map((comments, index) => (
                                                                                    <div key={index} className="col-sm-12 p-0 mb-4">
                                                                                        <div className="d-flex">
                                                                                            <div className=''>
                                                                                                <p className="mb-0 creator-profile">
                                                                                                    <img src={BaseImageUrl + comments.user_id.profile_image} alt="profile" width={20} />
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="w-100 pl-2">
                                                                                                <div className="d-flex align-items-center mb-1">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">{comments.user_id.first_name}</h2>
                                                                                                    </div>
                                                                                                    <div className="pl-2">
                                                                                                        <span className="font-12 text-sub font-weight-600">{getDateTime(comments.createdAt, "time")}</span>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="w-100">
                                                                                                    <p className="mb-0 text-black font-13 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                        <RenderHtml data={comments.comment} />
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <></>}
                                            </div>
                                        </div>
                                        {this.props.CurrentMilestoneStatus !== 3 ?
                                        <div className="input-chat py-20 px-20 mob-bg-white mobile-hide">
                                            <div className="custom_form">
                                                <form onSubmit={(e) => (e.preventDefault())}>
                                                    {/* <div className="form-group">
                                                        <input 
                                                            onChange={this.handleFeedback} 
                                                            onKeyPress={this.sendFeedbackOnClick} 
                                                            name="deliverable_feedback" type="text" className="form-control email input-chat-field bg-white" id="exampleInputEmail1" placeholder="Type your message here..." >
                                                        </input>
                                                        <span onClick={this.sendFeedback}><i className="fas fa-location-arrow input-chat-location d-block" /></span>
                                                    </div> */}
                                                    {/* replace it by summernote */}
                                                    {/* <div className="input-group mb-3">
                                                        <textarea  
                                                        value={this.state.deliverable_feedback}
                                                        onChange={this.handleFeedback}
                                                        // onKeyPress={this.sendFeedbackOnClick}  
                                                        type="text" 
                                                        className="form-control email input-chat-field bg-white" placeholder="Type your message here..." name="deliverable_feedback" />
                                                        <div className="input-group-append">
                                                            <span onClick={this.sendFeedback} className="input-group-text feedback_input" id="basic-addon2"><i className={`fas fa-location-arrow d-block ${this.state.deliverable_feedback.length ? 'text-success' : ''}`} /></span>
                                                        </div>
                                                    </div> */}
                                                    <div className="chat-feedback">
                                                        <div className="position-relative">
                                                            <div className="submit_btn mobile-ipad-none">
                                                                <span onClick={this.sendFeedback}>
                                                                    <i className={`cursor-pointer fas fa-location-arrow ${this.state.deliverable_feedback.replace(/<\/?[^>]+(>|$)/g, "").length ? 'text-success' : 'text-light-gray'}`} />
                                                                </span>
                                                            </div>
                                                            <CustomSummernote 
                                                                placeholder="Type your message here..."
                                                                value={this.state.deliverable_feedback} 
                                                                onChange={this.handleFeedbackNew} 
                                                                sendMsg={this.sendFeedback} 
                                                                // minHeight="0"
                                                                inMilestone
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        :<></>}
                                    </div>
                                    {/* Mobile Footer Bottom */}
                                    {/* {this.props.CurrentRole === 1 ?
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer mobile-show mob-p-20">
                                            <p className="mb-0">
                                                <button type="button" className="main-btn font-14 py-12 mob-w-100 cursor-pointer" onClick={this.approvePayHandler}>Approve and pay</button>
                                            </p>
                                        </div> : <></>
                                    } */}
                                </div>
                            </div>
                        
                            {show_version_list ?<div className="modal-body hidden">
                                <div className="mobile-show">
                                    <div className="d-flex align-items-center mob-bg-shadow mob-p-15">
                                        <div>
                                            <h2><i className="fas fa-arrow-left mr-2 font-18" /></h2>
                                        </div>
                                        <div className="pl-2">
                                            <h2 className="text-third font-weight-400 line-height-normal">
                                            <span className="d-block font-weight-600 text-dark-black line-height-normal font-18">Version History</span>
                                            </h2>
                                        </div>
                                    </div> 
                                </div>
                                <div className="overflow-content mob-milestone-modal">
                                    <div className="version-history">
                                        {show_version_list ?
                                            completed_Milestone_versions && completed_Milestone_versions.map((versions, k) => 
                                            { 
                                                const firstImageData = [...versions.completed_Milestone.files, ...versions.completed_Milestone.links][0]
                                                const commentLength = versions.completed_Milestone_comment.length
                                                return(
                                                    <div key={k} className="card-wrapper mb-3">
                                                    <div className="card-body px-4 position-relative">
                                                        <div className="custom_form">
                                                            <div className="card-wrapper mb-10">
                                                                <div className="row m-0 d-flex">
                                                                    <div className="col-sm-12 p-0">
                                                                        <div className="d-flex">
                                                                            <div className="w-30 mob-w-auto">
                                                                                {/* <p className="mb-0 version-profile" style={{ backgroundImage: `url(${firstImage})` }}>
                                                                                </p> */}
                                                                                {firstImageData.name ? 
                                                                                    <p className="mb-0 version-profile" style={{ backgroundImage: `url("${renderDifferentFilesThumbnail(firstImageData.name)}")` }}> </p>
                                                                                    : <RenderImage2 image={firstImageData}  />    
                                                                                }
                                                                            </div>
                                                                            <div className="w-70 mob-w-auto pl-3">
                                                                                <div className="w-100 cursor-pointer" onClick={(e) => this.showCurrentVersionDetails(versions.completed_Milestone, versions.completed_Milestone_comment)}>
                                                                                    <h2 className="chat-username text-capitalize mb-1 font-14 font-weight-600 mob-font-14">Version {versions.completed_Milestone.number}
                                                                                        <i className="fas fa-chevron-right ml-1 font-12" />
                                                                                    </h2>
                                                                                </div>
                                                                                <div className="w-100">
                                                                                    <p className="mb-0 text-third font-12 mob-font-12 letter-spacing-02 line-height-17 font-weight-500">
                                                                                        Submitted on {getDateTime(versions.completed_Milestone.createdAt, "dateMonth")}
                                                                                    </p>
                                                                                    <p className="mb-0 text-third font-12 mob-font-11 letter-spacing-02 line-height-17 font-weight-500">
                                                                                        {commentLength} comment{commentLength > 1 ? 's' : ''}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                                }) :
                                            !show_version_list && !show_current_version && completed_Milestone_comment.map((comments, index) => (
                                                <div key={index} className="card-wrapper mb-3">
                                                    <div className="card-body px-4 position-relative">
                                                        <div className="custom_form">
                                                            <div className="card-wrapper mb-10">
                                                                <div className="row m-0 d-flex">
                                                                    <div className="col-sm-12 p-0">
                                                                        <div className="d-flex">
                                                                            <div className=''>
                                                                                <p className="mb-0 creator-profile">
                                                                                    <img src={BaseImageUrl + comments.user_id.profile_image} alt="profile" width={20} />
                                                                                </p>
                                                                            </div>
                                                                            <div className="w-100 pl-2">
                                                                                <div className="d-flex align-items-center mb-1">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">{comments.user_id.first_name}</h2>
                                                                                    </div>
                                                                                    <div className="pl-2">
                                                                                        <span className="font-12 text-sub font-weight-600">{getMsgTime(comments.createdAt, "time")}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-100">
                                                                                    <p className="mb-0 text-black font-13 letter-spacing-02 line-height-17 font-weight-500">
                                                                                        <RenderHtml data={comments.comment} />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                                
                                </div>
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
            </>
        )
    }
}

const mapState = (store) => {
    let chat = store.chat;
    let postProjectData = store.postProject;
    let globalReducer = store.globalReducer; 
    return {
        closeModal: postProjectData.closeModal,
        milestones: postProjectData.milestones,
        proposal: postProjectData.proposal,
        ProjectName: chat.ProjectName,
        ProjectId: chat.ProjectId,
        ProjectType: chat.ProjectType,
        Deliverables: chat.CurrentMilestoneDeliverables,
        CurrentMilestoneId: chat.CurrentMilestoneId,
        CurrentRole: globalReducer.Me.CurrentRole,
        UserId: chat.UserId,
        UserToken: chat.UserToken,
        CurrentChat: chat.CurrentChat,
        CurrentMilestoneStatus: chat.CurrentMilestoneStatus,
        isLoadLatestMilestone: postProjectData.isLoadLatestMilestone,
        latestMilestoneId: postProjectData.latestMilestoneId,
    };
}

const mapDispatch = dispatch => ({
    SetCurrentMileStoneID: (milestone_id) => dispatch({ type: 'SET_CURRENT_MILESTONE_ID', payload: {milestone_id}}),    
    loadChatMessages: (connection_id) => dispatch({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
    SetCurrentDeliverables: (milestone_id) => dispatch({ type: 'SET_CURRENT_DELIVERABLE', payload: { milestone_id } }),
    closePopup: () => dispatch({ type: 'CLOSE_POST_PROJECT_POPUP', payload: {} }),
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    loadMe: (text) => dispatch({ type: 'ON_STATEUPDATE_SAGA', payload: text }),
    loadLatestProposals: (projectId) => dispatch({ type: 'LOAD_LATEST_PROPOSAL', payload: projectId }),
    loadAdminProjectProposal: () => dispatch({ type: 'LOAD_ADMIN_PROJECT_PROPOSAL', payload: null}),
    resetLatestMilestoneId: () => dispatch({type: 'RESET_LATEST_MILESTONE_ID', payload: null}),
    setVersionHistoryData: (data) => dispatch({type: 'SET_VERSION_HISTORY_DATA', payload: data}),
    setCompletedMilestone: (data) => dispatch({type: 'SET_COMPLETED_MILESTONE_DATA', payload: data}),
});

export default connect(mapState, mapDispatch)(ReviewDeliverable);



const RenderImage = ({image, thumbnailIndex, k}) => {
    const [thumbnail, setThumbnail] = useState(null)
  
    useEffect(() => {
      const getThumbnailImageFunc = async (u) => {
        const url = await getThumbnailImage(u);
        setThumbnail(url)
      }
        getThumbnailImageFunc(image)
    }, [image])
  
    return (
        // <img src={thumbnail} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} />
        <span className={`upload_image_thumbnail ${thumbnailIndex === k ? 'active' : ''}`} style={{backgroundImage:`url("${thumbnail}")`}}></span>
    )
  }

const RenderImage2 = ({image, isDivTag}) => {
    const [thumbnail, setThumbnail] = useState(null)
  
    useEffect(() => {
      const getThumbnailImageFunc = async (u) => {
        const url = await getThumbnailImage(u, true);
        setThumbnail(url)
      }
      if(image.link){
        getThumbnailImageFunc(image.link)
      } else if(image.name){
        getThumbnailImageFunc(image.name)
      }
    }, [image])
  
    if(isDivTag){
        return (<div className="version_image" style={{ backgroundImage: `url("${thumbnail}")` }}>
        </div>)
    }
    return (
         <p className="mb-0 version-profile" style={{ backgroundImage: `url("${thumbnail}")` }}> </p>
        )
    }

    