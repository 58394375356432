import React from 'react';
import { connect } from 'react-redux';
import BasicDetails from './Popup/BasicDetails';
import Collateral from './Popup/Collateral';
import ViewModal from './Popup/ViewModal';
import ViewModalFullScreen from './Popup/ViewModalFullScreen';
import ReviewModal from './Popup/ReviewModal';
import Specialization from './Popup/Specialization';
import Clients from './Popup/Clients';
import WorkExperience from './Popup/WorkExperience';
import Education from './Popup/Education';
import WebLinks from './Popup/WebLinks';
import CurrentWorkStatus from './Popup/CurrentWorkStatus';
import SubmitProject from '../UploadProject/Popup/SubmitProject';
import CropImages from '../UploadProject/Popup/CropImages';
import Tools from './Popup/Tools';
import Languages from './Popup/Languages';
import Awards from './Popup/Awards';
import MobileDropDown from '../Chat/Popup/MobileDropDown';
import KickStartProject from '../Chat/Popup/KickStartProject';
import ReviewMilestone from '../Chat/Popup/ReviewMilestone';
import ReviewUpdatedProposal from '../Chat/Popup/ReviewUpdatedProposal';
import ProposalVersionModal from '../Chat/Popup/ProposalVersionModal';
import ReviewProposal from '../Chat/Popup/ReviewProposal';
import ReviewDeliverable from '../Chat/Popup/ReviewDeliverables';
import CreatorRejectsBrief from '../Chat/Popup/CreatorRejectsBrief';
import EndProject from '../Chat/Popup/EndProject';
// import CreatorAndCompanyFeedback from '../Chat/Popup/CreatorAndCompanyFeedback';
import InComingCall from '../Chat/Popup/InComingCall';
import KickOffProject from '../Chat/Popup/KickOffProject';
import MilestoneComplete from '../Chat/Popup/MilestoneComplete';
import ProjectComplete from '../Chat/Popup/ProjectComplete';
import UserSurvey from './Popup/UserSurvey';
import ShareTalent from '../Home/Dashboard/Popup/ShareCandidate';
import CollateralJob from '../Home/Dashboard/Popup/CollateralJob';
import TimeBasedPricing from './Popup/TimeBasedPricing';
import Availability from './Popup/Availability';
import Industries from './Popup/Industries';

let AllPopup = {
  BasicDetails,
  Collateral,
  ViewModal,
  ViewModalFullScreen,
  ReviewModal,
  Specialization,
  Clients,
  WorkExperience,
  Education,
  WebLinks,
  CurrentWorkStatus,
  SubmitProject,
  CropImages,
  Tools,
  Languages,
  Awards,
  MobileDropDown,
  KickStartProject,
  ReviewMilestone,
  ReviewUpdatedProposal,
// ReviewProposal,
  ReviewDeliverable,
  ProposalVersionModal,
  CreatorRejectsBrief,
  EndProject,
  // CreatorAndCompanyFeedback,
  InComingCall,
  KickOffProject,
  MilestoneComplete,
  ProjectComplete,
  UserSurvey,
  ShareTalent,
  TimeBasedPricing,
  CollateralJob,
  Availability,
  Industries,
}

class Popup extends React.Component {
  render() {
    // console.log("coponent",this.props.Component)
    let Component = AllPopup[this.props.Component];
    return (
      this.props.Component !== '' && Component ?
        <Component {...this.props} />
        :
        <></>
    )
  }
}

const mapState = (store) => {
  // console.log("store",store)
  let creatorProfile = store.creatorProfile;
  return {
    Component: creatorProfile.Component,
  };
};
const mapDispatch = dispatchEvent => ({
});
export default connect(mapState, mapDispatch)(Popup);
