import React, { useState } from "react";
import { connect } from "react-redux";
// import { getAPI } from '../../../../utils/API';
import ChatViewProposal from "../../Home/DashboardNew/CommonpPopup/ChatViewProposal";
import ArrowGray from "../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/arrow_gray.png";
import MeetingRightIcon from '../../../../Assets/upgrade-images/creator_active_brief_chat_project_room/meeting_right_icon.svg';
import Calendar from '../../../../Assets/upgrade-images/client_active_brief_chat/document-text.svg';
import { getMsgTime } from "../../Common/Common/FileRenderMessage";
import getUserName from "../utils/getUserName";
import { separateNumber } from "../../../../utils/Helperfunctions";
import moment from "moment";
import { BaseImageUrl } from "../../../../utils/BaseUrl";

function RenderMilestoneReview(props) {

  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [chatPropId, setChatPropId] = useState(false);

  let data = props.props.Data;
  let Me = props.props.Me;

  const proposal_id = data.proposal_id;
  const reviewStatus = data.status;
  const project_id = data.post_project_id;
  const project_name = data.project_name;

  const proposalData = data?.proposalData

  // const ProjectStatus = props.ProjectStatus;
  const userType = Me.CurrentRole;
  // console.log(data.files);

  // console.log(props.props.CurrentRole);

  const isActual = proposalData?.proposal_type == "actual"
  const isFixed = proposalData?.proposal_type == "fixed"
  const isCostChange = (proposalData?.sub_total_cost != proposalData?.sub_total_cost_new) && proposalData?.sub_total_cost_new
  const isDelDateChange = (proposalData?.last_milestone_due_date_new != proposalData?.last_milestone_due_date) && proposalData?.last_milestone_due_date_new
  const isStartDateChange = (proposalData?.proposed_start_date != proposalData?.proposed_start_date_new) && proposalData?.proposed_start_date_new

  const handlePathChange = () => {
    const { projectId, connectionId } = props.props?.match?.params;
    let redirect = "";
    if (connectionId) {
      if (connectionId != "proposal" && connectionId != "meetings") redirect = `${connectionId}/proposal`;
      else {
        redirect = `proposal`;
      }
    } else {
      redirect = `proposal`;
    }
    return props.props.history.push(`/projects/${projectId}/project_room/${redirect}`);
  };

  const handleDateFormat = (date) => {
    return date ? moment(date).format("MMM D, YYYY") : ""
  }


  return (
    <>
      {/* <div className="w-100 review-milestones mt-2 border-1 border-radius-10">
        <div className="profile-creator">
          <div className="d-flex align-center-items py-3 px-4">
            <div className="pt-15">
              <i className="far fa-calendar-alt font-20 text-white"></i>
            </div>
            <div className="pl-3">
              <span className="text-white font-14 mob-font-14 font-weight-500">{project_name}</span>
              <h2 className="font-weight-500 m-0 font-16 mob-font-14 mob-only-text-left line-height-14">{data.sub_text}</h2>
            </div>
          </div>
        </div>
        <div className="p-3">

          <div className={`files_wrapper d-inline-block ${data.files && data.files.length > 0 ? "" : "pb-40"}`}>
            {data.files && data.files.length > 0 && (
              <div className="d-flex align-content-center flex-wrap render_milestone_review">
                <span className="upload-files mob-w-100 mob-ml-0 mx-1 mb-2 border-1 border-radius-8">
                  <a
                    href="https://ifn-images.s3.ap-south-1.amazonaws.com/upload-files/1629987339188chat_filefixed.png"
                    target="_blank"
                    rel="noopener noreferrer "
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <img src={Attachement} alt="fng" className="border-radius-4" />
                    </div>
                  </a>
                </span>
                <span className="milestone-extra-images">
                  {data.files.length} Attachment{data.files.length > 1 ? "s" : ""}
                </span>
              </div>
            )}
          </div>

          {userType === 1 ? (
            reviewStatus === 1 || reviewStatus === 0 ? (
              <button
                type="button"
                className="main-btn d-inline-block float-right mob-float-unset font-12 mob-w-100 ml-1 cursor-pointer"
                onClick={(e) => {
                  // props.props.CurrentRole == 2
                  //   ? props.props.history.push(`/projects/${project_id}/proposal_details`)
                  //   : handlePathChange();

                  setChatPropId(props?.props?.Data?.proposal_id)
                  setShowVersionHistory(true)
                }}
              >
                {props.type === "milestones_review" ? "Review" : "Review Proposal"}
              </button>
            ) : reviewStatus === 4 ? (
              <button
                type="button"
                className="main-btn bg-light-gray float-right font-13 cursor-pointer ml-1 font-weight-600 disabled"
              >
                Expired
              </button>
            ) : (
              <button
                type="button"
                className="main-btn bg-light-gray font-13 cursor-pointer ml-1 font-weight-600"
                onClick={(e) => {
                  setChatPropId(props?.props?.Data?.proposal_id)
                  setShowVersionHistory(true)
                }}
              >
                <i className="fas fa-check-circle text-success font-16 mr-1"></i>
                Review Complete
              </button>
            )
          ) : reviewStatus === 4 ? (
            <div className="w-100 d-flex align-items-center justify-content-end">
              <a href="#/" className="font-13 mob-font-12 font-weight-bold letter-spacing-02 text-center disabled">
                Expired
              </a>
            </div>
          ) : (
            <div className="d-inline-block float-right pt-2">
              <p
                className="font-13 mob-font-12 font-weight-bold letter-spacing-02 text-center cursor pointer"
                style={{ color: "#007bff" }}
                onClick={(e) => {
                  setChatPropId(props?.props?.Data?.proposal_id)
                  setShowVersionHistory(true)
                }}
              >
                View
                <i className="fas fa-arrow-right font-14 ml-1"></i>
              </p>
            </div>
          )}
        </div>
      </div> */}

      <>
        <div className='row mx-0 mb-4'>
          <div className='col-lg-12 d-flex px-0'>
            <div className='company_profile milestone-chat mr-3'>
              <div className='icon_profile_image rounded-circle'>
                <img src={Calendar} className='img-fluid' />
              </div>
            </div>
            <div className="pl-2 user_massage">
              <div className='row mx-0 justify-content-between align-items-baseline mb-3'>
                <ul className="client_details d-flex align-items-center">
                  <li className="mr-3 text_light_gray font-14 fw_600">{proposalData?.number > 1 ? "Proposal Revised" : "New Proposal Submitted"} by <span className="text-black-700 fw_600">{getUserName(props.props)}</span></li>
                  <li className="ml-2 text_light_gray fw_400">{getMsgTime(data?.time)}</li>
                </ul>
              </div>
              <div className="small_title_commn px-0 mb-3">
                <div className='row mx-0 px-3 mob-px-0 align-items-end'>
{/* {console.log("proposalData === ",proposalData)} */}
                  {proposalData?.number > 1 ?
                    <div className="col-sm-9 col-xl-9">
                      <div className="d-flex">
                        <div className="user_img">
                          <img src={BaseImageUrl+ proposalData?.profile_image} className="img-fluid" />
                        </div>
                        <div className="user_quote">
                          <div className="info_box_item_outer d-flex">
                            <div className="info_box_item">
                              <p className="text_gray_1100_w_700 font-12 mb-1">Quote</p>
                              {isActual ?
                                <p className="font_semi_bold mb-0">
                                  On-Actual
                                </p>
                                : isCostChange ?
                                  <p className="font_semi_bold mb-0">
                                    <span className='strike text-gray-500 fw_500'><i className="fas fa-rupee-sign fs-14"></i>{separateNumber(proposalData?.sub_total_cost_new, "")}</span>
                                    <span className="fw_500 mx-2"><img src={ArrowGray} className="img-fluid" /></span>
                                    <i className="fas fa-rupee-sign fs-14"></i>{" "}{separateNumber(proposalData?.sub_total_cost, "")} <span className="gst">+ GST</span>
                                  </p> :
                                  <p className="font_semi_bold mb-0">
                                    <i className="fas fa-rupee-sign fs-14"></i>{" "}{separateNumber(proposalData?.sub_total_cost, "")} <span className="gst">+ GST</span>
                                  </p>
                              }
                            </div>
                            <div className="info_box_item">
                              <p className="text_gray_1100_w_700 font-12 mb-1 mob-m-0">{isFixed ? "Delivery " : "Start Date"}</p>
                              {isFixed ?
                                isDelDateChange ?
                                  <p className="font_semi_bold mb-0">
                                    <span className='strike text-gray-500 fw_500'>{handleDateFormat(proposalData?.last_milestone_due_date_new)}</span>
                                    <span className="fw_500 mx-2"><img src={ArrowGray} className="img-fluid" /></span>
                                    {handleDateFormat(proposalData?.last_milestone_due_date)}
                                  </p> :
                                  <p className="font_semi_bold mb-0">
                                    {handleDateFormat(proposalData?.last_milestone_due_date)}
                                  </p>
                                :
                                isStartDateChange ?
                                  <p className="font_semi_bold mb-0">
                                    <span className='strike text-gray-500 fw_500'>{handleDateFormat(proposalData?.proposed_start_date_new)}</span>
                                    <span className="fw_500 mx-2"><img src={ArrowGray} className="img-fluid" /></span>
                                    {handleDateFormat(proposalData?.proposed_start_date)}
                                  </p> :
                                  <p className="font_semi_bold mb-0">
                                    {handleDateFormat(proposalData?.proposed_start_date)}
                                  </p>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="col-sm-9 col-xl-9 mb-0 mob-px-0">
                      <div className="row mx-0">
                        <div className='col-sm-4 col-6 pl-lg-0'>
                          <p className='text_gray_1100_w_600_sm mb-0'>Project Quote</p>
                          <p className='font_semi_bold mb-0'><i className="fas fa-rupee-sign fs-14"></i>{isActual ? "On-Actual" : separateNumber(proposalData?.sub_total_cost, "")}</p>
                        </div>
                        <div className='col-sm-4 col-6'>
                          <p className='text_gray_1100_w_600_sm mb-0'>
                            {isFixed ? "Delivery Date" : "Start Date"}
                          </p>
                          <p className='font_semi_bold_w_600 mb-0'>{isFixed ? handleDateFormat(proposalData?.last_milestone_due_date) : handleDateFormat(proposalData?.proposed_start_date)}</p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="col-sm-3 col-xl-3 text-right">
                    <span className="blue_text_md_w_600 font-12 mb-0 cursor-pointer"
                      onClick={(e) => {
                        setChatPropId(props?.props?.Data?.proposal_id)
                        setShowVersionHistory(true)
                      }}
                    >View Proposal <img src={MeetingRightIcon} className="img-fluid" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {showVersionHistory && (
        <ChatViewProposal
          chatPropId={chatPropId}
          setShowVersionHistory={setShowVersionHistory}
          showVersionHistory={showVersionHistory}
          // user_id={props.props?.CurrentRole === 1 ? props.props?.Data?.user_id : ""}
          {...props.props}
        />
      )}
    </>
  );
}

const mapState = (store) => {
  let chat = store.chat;
  return {
    ProjectStatus: chat.ProjectStatus,
  };
};

const mapDispatch = (dispatch) => ({
  loadChatAndLatestProposals: (projectId, projectName, projectStatus) =>
    dispatch({
      type: "LOAD_CHAT_AND_LATEST_PROPOSAL",
      payload: { id: projectId, name: projectName, project_status: projectStatus },
    }),
  loadLatestProposals: (projectId) => dispatch({ type: "LOAD_LATEST_PROPOSAL", payload: projectId }),
});

export default connect(mapState, mapDispatch)(RenderMilestoneReview);
