import React, { useEffect, useState } from "react";
// import Select from 'react-select';
// import { BiRupee } from 'react-icons/bi';
import { getAPI } from "../../../utils/API";
import SimpleReactSelect from "../Common/SimpleReactSelect/SimpleReactSelect";
import Tag from "../../../Assets/upgrade-images/account/tagblue.svg";
import TagYellow from "../../../Assets/upgrade-images/account/tag_warning.svg";
import PriceImage from "../../../Assets/upgrade-images/submit_proposal/price.svg";
export const separateNumber = (number, obj) => {

  if (obj && obj.isWords && number >= 1000) {
      let val = Math.abs(number)

      if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + 'Cr';
      } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + 'L';
      } else if (val >= 1000) {
          val = (val / 1000).toFixed(2) + 'K';
      }
      return val;
  }

  const num = parseInt(number, 10)
  let output = num.toLocaleString('en-IN');
  return output;
}
const TimeRetainer = (props) => {
  const { submitPricing } = props;
  const [perCharge, setPerCharge] = useState("");
  const [perChargeError, setPerChargeError] = useState(false);
  // const [perTimeError, setPerTimeError] = useState(false);
  const [weeklyPrice, setWeeklyPrice] = useState(0);
  const [monthlyPrice, setmonthlyPrice] = useState(0);
  const [time, setTime] = useState({ value: "hour", name: "per hour" });
  const [load, setLoad] = useState(false);
  // getAPI('/users/user-profile')

  const fetchTimeData = () => {
    getAPI("/users/user-profile")
      .then((res) => {
        if (res.data.status === "1") {
          // console.log(res.data.data)
          const { time_base_duration, time_base_amount } = res.data.data;

          setPerCharge(time_base_amount);

          if (time_base_duration === "hour")
            setTime({ value: time_base_duration, name: "Per hour" });
          if (time_base_duration === "day")
            setTime({ value: time_base_duration, name: "Per day" });
          if (time_base_duration === "week")
            setTime({ value: time_base_duration, name: "Per week" });
          if (time_base_duration === "month")
            setTime({ value: time_base_duration, name: "Per month" });

          setLoad(true);

          // alert("Changes submitted successfully");
          //  this.props.closePopup();
        }
      })
      .catch((error) => {
        alert("Something went wrong, Please try again later.");
        //  this.setState({submitting: false});
        // console.log("user-company api error------->", error)
      });
  };

  
  const handleFunc = (e, obj) => {
    e.preventDefault();
    setTime(obj);
  };

  const options = [
    { value: "hour", name: "per hour" },
    { value: "day", name: "per day" },
    { value: "week", name: "per week" },
    { value: "month", name: "per month" },
  ];

  useEffect(() => {
    fetchTimeData();
  }, []);

  const onChangePerCharge = (e) => {
    if (e.target.value <= 0) {
      setPerCharge("");
      setPerChargeError(true);
    } else {
      setPerCharge(e.target.value);
      setPerChargeError(false);
    }
  };
  useEffect(() => {
    function handleCalcPricing() {
      let calcValue = time.value;
      let price = Number(perCharge);
  
      // console.log(calcValue,price);
  
      switch (calcValue) {
        case "hour":
          setWeeklyPrice(price * 25);
          setmonthlyPrice(price * 25 * 4);
          submitPricing("hour", price);
          break;
        case "day":
          setWeeklyPrice(price * 5);
          setmonthlyPrice(price * 5 * 4);
          submitPricing("day", price);
  
          break;
        case "week":
          setWeeklyPrice(price * 1);
          setmonthlyPrice(price * 1 * 4);
          submitPricing("week", price);
  
          break;
        case "month":
          setWeeklyPrice(price / 4);
          setmonthlyPrice(price * 1);
          submitPricing("month", price);
  
          break;
        default:
          setWeeklyPrice(price * 0);
          setmonthlyPrice(price * 0);
          submitPricing("", 0);
  
          break;
      }
        }
    handleCalcPricing();
  }, [perCharge, time,submitPricing]);

  return (
    <form>
      <div className="form-group mb-4 col-sm-12 pl-0 mob-p-0 m-md-3">
        <label
          className="mb-0 label text-gray font-14 mob-font-13 position-relative"
          htmlFor="perChaarge"
        >
          How much would you a charge for a time-based retainer project?
        </label>
        <div className="row work-experience onboarding_dropdown_wrapper my-3">
          <div className="col-sm-12 position-relative d-flex align-items-center position-relative">
            <div className="price_input w-100">
            <input
              type="text"
              className={`form-control pl-40 email price_input  mob-w-100 ${perChargeError ? "border-error" : ""
                }`}
              name="perCharge"
              value={perCharge}
              onChange={onChangePerCharge}
              autoComplete="off"
              placeholder="Price"
            />   
            <img src={PriceImage} className='price_icon img-fluid'/>
            </div>
            {/* Dropdown */}
            <div className="position-relative onboarding_dropdown_option"> 
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                  {time.name}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {
                    options.map((dropdownValue,index) => {
                      return <a className="dropdown-item" href="/" key={index} onClick={e => handleFunc(e, dropdownValue) }>
                        {dropdownValue.name}
                      </a>
                    })
                  } 
                </div>
              </div>
            </div> 
          </div>
          {perChargeError && (
              <span className="col-12 text-danger font-weight-600 font-12 py-1">
                Add charge to continue
              </span>
            )}  
          {/* <div className="col-sm-6 creator_profile_time_price">
            {load && <div className="filter-dropdown wrapper-dropdown position-relative"> 
            <SimpleReactSelect
              options={options} 
              value={{ name: time.name }}
              selectHandler={e => handleFunc(e, 'time') }
              placeholder="Time Period"
            />
            </div>} 
          </div> */}
        </div>
        <div className="row mt-4 mb-80">
          <div className="col-sm-12 mb-3">
            <p className="mb-3 label font-14 text-gray mob-font-13 position-relative">Estimating a 5 day work-week and 20 day work-month, we expect your weekly and monthly retainer charges as below:</p>
          </div>
          <div className="col-lg-6">
              <span className="mb-0 text-blue font-weight-500 font-14 mob-font-13 mb-2 position-relative"><img src={Tag}/> Weekly pricing :</span>
              <span className="pl-1 text-dark font-weight-600 ml-lg-1">
                <i className="fas fa-rupee-sign price_icon font-12"></i> {separateNumber(weeklyPrice)}
              </span>
          </div>
          <div className="col-lg-6">
              <span className="mb-0 text-yellow font-weight-500 font-14 mob-font-13 mb-2 position-relative"><img src={TagYellow}/> Monthly pricing :</span>
              <span className="pl-1 text-dark font-weight-600 ml-lg-1">
                <i className="fas fa-rupee-sign price_icon font-12"></i> {separateNumber(monthlyPrice)}
              </span>
          </div> 
        </div>
      </div>
    </form>
  );
};

export default TimeRetainer;
