import { put, call, select } from "redux-saga/effects";
import { fireApi } from "../../../utils/fireApiPrecheck";
import {creatorProfile, globalReducer} from '../workingSagas/selectors/index.select';
export function* loadIncompleteBriefs(value) {
    try {
        value = (value && value.payload) ? value.payload : '';
        yield put({type: 'DASHBOARD_DATA_LOADING', values: true})

        let globalReducerData = yield select(globalReducer);

        let IncompleteBriefs = ""
        if(globalReducerData?.Me?.CurrentRole == 3) {
            IncompleteBriefs = yield fireApi('GetIncompleteBriefsAdmin', { search: value });
        }
        else {
            IncompleteBriefs = yield fireApi('GetIncompleteBriefs', { search: value });
        }

        // console.log("Clients Projects List=======>",IncompleteBriefs);
        yield put({ type: "GET_INCOMPLETE_BRIEFS", values: IncompleteBriefs.in_complete_project});
        yield put({ type: "GET_DRAFT_PROJECTS", values: IncompleteBriefs.draft_project});
        yield put({ type: "GET_ONGOING_PROJECTS", values: IncompleteBriefs.ongoing_project});
        yield put({ type: "GET_CLIENT_COMPLETED_PROJECT", values: IncompleteBriefs.completed_project});
        yield put({ type: "GET_CLIENT_CLOSED_PROJECT", values: IncompleteBriefs.closed_project});

        yield put({ type: "UNREAD_MSG_DASHBOARD", values: {active_unread_msg:IncompleteBriefs.active_unread_msg , brief_unread_msg:IncompleteBriefs.brief_unread_msg}});

        yield put({type: 'DASHBOARD_DATA_LOADING', values: false})

    } catch (error) {
        console.log(error);
    }
}

export function* loadIncomingBriefs(value) {
    try {
        value = (value && value.payload) ? value.payload : '';
        yield put({type: 'DASHBOARD_DATA_LOADING', values: true})

        let IncomingBriefs = yield fireApi('GetIncomingBriefs', { search: value });

        // console.log("Creator Projects List=======>",IncomingBriefs);
        yield put({ type: "GET_INCOMING_BRIEFS", values: IncomingBriefs.in_coming_brief_project});
        yield put({ type: "GET_PROPOSAL_PROJECT", values: IncomingBriefs.proposal_project});
        yield put({ type: "GET_CREATOR_ONGOING_PROJECT", values: IncomingBriefs.ongoing_project});
        yield put({ type: "GET_CREATOR_COMPLETED_PROJECT", values: IncomingBriefs.completed_project});
        yield put({ type: "GET_CREATOR_CLOSED_PROJECT", values: IncomingBriefs.closed_project});

        yield put({ type: "UNREAD_MSG_DASHBOARD", values: {active_unread_msg:IncomingBriefs.active_unread_msg , brief_unread_msg:IncomingBriefs.brief_unread_msg}});

        yield put({type: 'DASHBOARD_DATA_LOADING', values: false})

    } catch (error) {
        console.log(error);
    }
}

export function* loadUpcomingMilestones() {
    try {
        let Milestones = yield fireApi('GetUserMilestones');
        yield put({ type: "GET_USER_MILESTONES", values: Milestones});
        // console.log(Milestones.data);
    } catch (error) {
        console.log(error);
    }
}

export function* loadJobs(value) {
    try {
        value = value.payload;
        let JobsData = yield fireApi('GetJobs',{id:value});
        yield put({ type: "GET_JOBS_DONE", values: JobsData});
        // console.log(Milestones.data);
    } catch (error) {
        console.log(error);
    }
}


export function* loadBriefData(value) {
    try {
        value = value.payload;
        let IncomingBriefData = yield fireApi('GetBriefData', {id: value.project_id});
        yield put({ type: "GET_BRIEF_DATA", values: IncomingBriefData});
    } catch (error) {
        console.log(error);
    }
}

export function* viewIncomingBrief(value) {
    try {
        value = value.payload;
        let res = yield fireApi('ViewIncomingBrief', {id: value.project_id});
        if(parseInt(res.status) === 1){
            yield put({ type: "UPDATE_VIEW_INCOMING_BRIEF", values: {id: value.project_id}});
        }
    } catch (error) {
        console.log(error);
    }
}

export function* loadCollateralBasedProjects(value) {
    
    try {
        let fromServicesOffered = value.origin === 'ServicesOffered';
        
        value = value.payload;
        let creatorProfileData = yield select(creatorProfile);
        let publicView     = creatorProfileData.public;
        if(publicView === true || fromServicesOffered) {
            let userName = creatorProfileData?.BasicDetails?.Username;
            let Projects = yield fireApi('GetCollateralBasedProjectsPublic', {username:userName,collateral_id:value});
            yield put({ type: "GET_COLLATERAL_BASED_PROJECTS", values: Projects});
        } else {           
            let Projects       = yield fireApi('GetCollateralBasedProjects', {id: value});// collatral
            yield put({ type: "GET_COLLATERAL_BASED_PROJECTS", values: Projects});           
        }
    } catch (error) {
        console.log(error);
    }
}

// export function* loadAllProjects(value) {
//     try {
//         value = value.payload;
//         let creatorProfileData = yield select(creatorProfile);
//         let publicView     = creatorProfileData.public;
//         if(publicView === true) {
//             let userName = creatorProfileData?.BasicDetails?.Username;
//             let Projects = yield fireApi('GetCollateralBasedProjectsPublic', {username:userName,collateral_id:value});
//             yield put({ type: "GET_COLLATERAL_BASED_PROJECTS", values: Projects});
//         } else {
//             let Projects       = yield fireApi('GetCollateralBasedProjects', {id: value});
//             yield put({ type: "GET_COLLATERAL_BASED_PROJECTS", values: Projects});
//         }
//     } catch (error) {
//         console.log(error);
//     }
// }

export function* rejectIncomingBrief(datavalue) {

    try{
        let value = datavalue;
        // delete value.payload.history 
        value = value.payload;
        let globalReducerData = yield select(globalReducer);
        let rejectIncomingBrief = yield fireApi('AcceptAndRejectIncomingBrief', {post_project_id: value.post_project_id, status: 2})
        let rejectIncomingBriefReason = yield fireApi('RejectIncomingBriefResason', value)
        // console.log(rejectIncomingBrief,'rejectIncomingBrief',rejectIncomingBriefReason,'rejectIncomingBriefReason')
        if(rejectIncomingBrief.status && parseInt(rejectIncomingBrief.status) === 1 && rejectIncomingBriefReason.status && parseInt(rejectIncomingBriefReason.status) === 1 ){
            yield put({type: 'UPDATE_INCOMING_BRIEF', values: value.post_project_id  })
            yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
            if(window.location.pathname === '/dashboard' || window.location.pathname.startsWith("/chat")){
                alert(`Thank you, ${globalReducerData.Me.FirstName} ${globalReducerData.Me.LastName} . We have rejected the brief on your behalf and recorded your feedback.`);
                datavalue.payload.history.push("/dashboard")
                console.log('here')
            } else {
                alert(`Sorry but the client has decided to opt for another creator for this project. Your next project is right on it's way. So hold tight. In case of any queries, reach out to us on team@indiefolio.com`);
            }
        }
    }
    catch (error) {
        console.log(error)
    }
}
export function* acceptIncomingBrief(value){
    try{
        const { id }  = value.payload;
        let acceptedIncomingBrief = yield fireApi('AcceptAndRejectIncomingBrief', {post_project_id: id, status: 1})
        if(acceptedIncomingBrief.status && parseInt(acceptedIncomingBrief.status) === 1){
            yield call(loadIncomingBriefs)
            yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
        }
    }
    catch (error) {
        console.log(error)
    }
}

export function* deleteIncompleteBrief(value) {
    try{
        value = value.payload;
        let deleteIncompleteBrief = yield fireApi('DeleteIncompleteBrief', {id: value})
        if(deleteIncompleteBrief.status && parseInt(deleteIncompleteBrief.status) === 1){
            yield put({type: 'UPDATE_DELETE_INCOMPLETE_BRIEF', values: value  })
        }
    }
    catch (error) {
        console.log(error)
    }
}
export function* deleteCreatorProject(value) {
    try{
        value = value.payload;
        const deleteProject = yield fireApi('DeleteUserProject', {_id: value.id})
        // console.log(deleteProject)
        if(deleteProject.status && parseInt(deleteProject.status) === 1){
            if(value.isFromAllProjects){
                // const AllProjects = yield fireApi('GetUserAllProjects');
                 yield put({ type: 'UPDATE_DELETE_CREATOR_PROJECT_FROM_ALL_PROJECTS', values: {id: value.id}});
            } else {
                yield put({type: 'UPDATE_DELETE_CREATOR_PROJECT', values: {id: value.id}  })
            }
        }
        // if(deleteIncompleteBrief.status && deleteIncompleteBrief.status == 1){
        //     // yield put({type: 'UPDATE_DELETE_INCOMPLETE_BRIEF', values: value  })
        // }
    }
    catch (error) {
        console.log(error)
    }
}

