import React from 'react';
import { ChatImageBaseUrl } from '../../../../utils/BaseUrl';

export const getFileType = (file) => {
  let i = file.lastIndexOf('.');
  return file.substring(i + 1, file.length);
}

export const isViewableFile = (file) => {
  const acceptableFileType = ['pdf', 'docx', 'doc', 'xlsx', 'mp3' ]
  const fileType =  getFileType(file).toLowerCase()

  return acceptableFileType.includes(fileType)
}

export const getFileName = (file, maxLength) => {
  let name = (file.indexOf("chat_file"));
  name = file.substring(name + 9, file.length)
  if(maxLength){
    name = name.substring(0, maxLength - 3) + '...'
  }
  return name;
}

export const getMsgTime = (msgTime) => {
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  msgTime      = (msgTime === undefined||msgTime === "") ? new Date() : msgTime;
  let hour   = new Date(msgTime).getHours();
  let minute = new Date(msgTime).getMinutes();
  let second = new Date(msgTime).getSeconds();
  let day = new Date(msgTime).getDate();
  let month = new Date(msgTime).getMonth();
  let year = new Date(msgTime).getFullYear();
  let ap = "AM";
  if (hour   > 11) { ap = "PM";             }
  if (hour   > 12) { hour = hour - 12;      }
  if (hour   === 0) { hour = 12;             }
  if (hour   < 10) { hour   = "0" + hour;   }
  if (minute < 10) { minute = "0" + minute; }
  if (second < 10) { second = "0" + second; }
  // let timeString = hour + ':' + minute + " " + ap + ", " + day + "th " + months[month] + " " + year;
  let timeString = day + " " + months[month] + " " + year + ", " +  hour + ':' + minute + " " + ap 

  return timeString;
}


export const renderDifferentFilesThumbnail = (file) => {
  let key = getFileType(file)
  let thumbnailImage;
  switch (key) {
  case 'pdf':
      thumbnailImage = require("../../../../Assets/images/big-pdf.jpg");
      return thumbnailImage;
  case 'ai':
      thumbnailImage = require("../../../../Assets/images/big-ai.jpg");
      return thumbnailImage;
  case 'mp4':
      thumbnailImage = require("../../../../Assets/images/big-mp4.jpg");
      return thumbnailImage;
  case 'zip':
      thumbnailImage = require("../../../../Assets/images/big-zip.jpg");
      return thumbnailImage;
  case 'psd':
      thumbnailImage = require("../../../../Assets/images/big-psd.jpg");
      return thumbnailImage;
  case 'doc':
  case 'docx':
      thumbnailImage = require("../../../../Assets/images/big-doc.jpg");
      return thumbnailImage;
  case 'eps':
      thumbnailImage = require("../../../../Assets/images/big-eps.jpg");
      return thumbnailImage;
  case 'ppt':
  case 'pptx':
      thumbnailImage = require("../../../../Assets/images/big-ppt.jpg");
      return thumbnailImage;
  case 'xlsx':
      thumbnailImage = require("../../../../Assets/images/big-xlsx.jpg");
      return thumbnailImage;
  case 'mp3':
      thumbnailImage = require("../../../../Assets/images/big-mp3.jpg");
      return thumbnailImage;
  default:
      thumbnailImage = require("../../../../Assets/file-img/file.png");
      return thumbnailImage;
  }
}





export const renderDifferentFiles = (file,inChat) => {
  let key = getFileType(file)
  switch (key) {
    case 'pdf':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-pdf.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'ai':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-ai.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'mp4':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-mp4.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'zip':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-zip.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'psd':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-psd.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'docx':
    case 'doc':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-doc.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'eps':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-eps.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
      case 'ppt':
      case 'pptx':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-ppt.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    case 'xlsx':
    case 'xlsm':
        return (
          <div className={inChat ? "" : `"pl-2"`}>
            <img src={require("../../../../Assets/images/big-xlsx.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
          </div>
        )
    case 'mp3':
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/images/big-mp3.jpg")} alt="eps" width="50" height="55" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
    default:
      return (
        <div className={inChat ? "" : `"pl-2"`}>
          <img src={require("../../../../Assets/file-img/file.png")} alt={'fng'} width="50" height="50" className={inChat ? "img-fluid" : `border-radius-4`} style={{objectFit:'cover'}} />
        </div>
      )
  }
}
