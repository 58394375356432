import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../../utils/API";
import VersionHistoryData from "./VersionHistoryData";
import { useDispatch } from "react-redux";
import {  relevent_projects, projectAttachments, projectUrls } from "../../../../../store/action/proposal.action";

export default function CreatorVersionHistory(props) {
  const [state, setState] = useState({
    AllProposal: [],
    projectData: { project_name: "", company_name: "", min_budget: "", max_budget: "" },
    collateral_name: "",
    creatorData:{}
  });
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.id) {
      handleApi(props?.id);
    } else {
      const id = props.match.params?.id;
      handleApi(id);
    }
  }, []);

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: current_ids ? current_ids : [],
      releventProject: data?.allProjectData ? data.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.link_image ? data.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.urls ? data.urls : []));
  };

  const handleApi = (id) => {
    setIsLoading(true);
    try {
      getAPI("/proposal/proposal-version-history-for-creator?id=" + id).then((res) => {
        setState(res.data);
        getReferenceData(res.data.AllProposal);
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <VersionHistoryData state={state} id={props.match.params?.id} isClient={false}  isLoading={isLoading} {...props} />
    </>
  );
}
