import React, { useEffect, useState } from "react";
import UploadMilestone from "../PopopCreator/UploadMilestone";
import ViewUploadDel from "../PopupAll/ViewUploadDel";
import ClientPayNow from "../PopupAll/ClientPayNow";
// import { useDispatch, useSelector } from "react-redux";
import FixedOverviewProj from "./AllActiveProjOverview/FixedOverviewProj";
import ActualOverviewProj from "./AllActiveProjOverview/ActualOverviewProj";
import { ProjectEndPopup } from "../PopupAll/ProjectEndPopup";
import { FinalReviewPop } from "../PopupAll/FinalReviewPop";
import { EndProjSuccessPop } from "../PopupAll/EndProjSuccessPop";
import RetainerOverviewProj from "./AllActiveProjOverview/RetainerOverviewProj";
import RetHourlyRequest from "../PopupAll/RetHourlyRequest";
import { getAPI, postAPI, putAPI } from "../../../../../utils/API";
import { TerminateProjProcess } from "../CommonpPopup/TerminateProjProcess";
import CommonApproveAndReject from "../CommonpPopup/CommonApproveAndReject";
import TerminationPay from "../PopupAll/TerminationPay";
// import { CommonRejectionPopup } from "../CommonpPopup/CommonRejectionPopup";

// const checkboxData = [
//   { text: "Project quote too high" },
//   { text: "Start date too late" },
//   { text: "Deliverables not covering requirements" },
//   { text: "Other" },
// ];

const MilestonePayCreator = ({ state, ...props }) => {
  const [mileData, setMileData] = useState([]);
  const [mileDataActual, setMileDataActual] = useState([]);
  const [mileDataRetainer, setMileDataRetainer] = useState([]);
  const [showUploadMilestone, setShowUploadMilestone] = useState(false);
  const [milestone_id, set_milestone_id] = useState("");
  const [viewUploadDel, setViewUploadDel] = useState(false);
  const [showClientPayNow, setShowClientPayNow] = useState(false);
  const [viewAttachments, setViewAttachments] = useState([]);
  // const [viewDocument, setViewDocument] = useState(false);
  const [viewVersionHistory, setViewVersionHistory] = useState(false);
  const [mileObj, setMileObj] = useState("");
  const [index, setIndex] = useState("");
  const [fixedLastSubm, setFixedLastSubm] = useState(false);
  const [showProjEndPop, setShowProjEndPop] = useState(false);
  const [showFinalReview, setShowFinalReview] = useState(false);
  const [showEndProjSuccess, setShowEndProjSuccess] = useState(false);
  const [justStaticEndProjClient, setjustStaticEndProjClient] = useState(false);
  const [retHourlyReq, setRetHourlyReq] = useState(false);
  const [retHourlyReqTYpe, setRetHourlyReqType] = useState("");
  const [uploadMileEdit, setUploadMileEdit] = useState(false);
  const [showTerminatePopup, setShowTerminatePopup] = useState(false);
  // const [showCommonReject, setShowCommonReject] = useState(false);
  // const [rejectStates, setRejectStates] = useState({
  //   reason: [],
  //   description: "",
  // });
  // const [rejectLoader, setRejectLoader] = useState(false);
  const [showApproveRejectPop, setShowApproveRejectPop] = useState(false);
  const [showTerminationPay, setShowTerminationPay] = useState(false);
  const [reworkId, setReworkId] = useState("");
  const [termLoad, setTermLoad] = useState(false);
  const [terminationData, setTerminationData] = useState("");
  const [popupType, setPopupType] = useState(2);
  const [allData, setAllData] = useState([]);

  const [paymentStatus, setPaymentStatus] = useState("");
  const [dummyMutualAccState, setDummyMutualAccState] = useState(true);

  // const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      if (state?.type && state.type.toLowerCase() === "fixed") {
        handleDataFunction(state);
      } else if (state?.type && state.type.toLowerCase() === "actual") {
        handleDataFunActual(state);
      } else if (state?.type && state.type.toLowerCase() === "retainer") {
        handleDataFunRetainer(state);
      }
      // let projectData = props?.projectDataStates?.data?.project_data;
      // dispatch({
      //   type: "LOAD_PROJECT_CHAT",
      //   payload: { projectId: projectData?._id, projectName: projectData?.project_name, projectStatus: projectData?.status },
      // });
    }
  }, []);

  useEffect(() => {
    handleTerminationData();
  }, []);

  useEffect(() => {
    let project_data = props?.projectDataStates?.data?.project_data;
    if (project_data?.isProjCompletedPopupClient && props?.role === 1) {
      setShowEndProjSuccess(true);
    } else if (project_data?.isProjCompletedPopupCreator && props.role === 2) {
      setShowEndProjSuccess(true);
    }
  }, [props?.projectDataStates?.data?.project_data]);

  const handleTerminationData = async () => {
    setTermLoad(true);
    try {
      const res = await getAPI(`/proposal/project-termination?_id=${props.projectDataStates.id}`);
      let data = res.data.data;
      if (data) {
        setTerminationData(data);
      }
      setTermLoad(false);
    } catch (err) {
      alert("Something went wrong please try again later");
      setTermLoad(false);
    }
  };

  const changeMutualAcceptStatus = async () => {
    let body = {
      post_project_id: props.projectDataStates.id,
      role: props.CurrentRole,
    };
    try {
      const res = await putAPI(`/postProject/change-mutual-accept-status`, body);
      setDummyMutualAccState(false)
    } catch (err) {
      console.log("Something went wrong please try again later");
      setDummyMutualAccState(false)
    }
  };

  const handleDataFunction = (state) => {
    const delivArr = state?.payments.map((item) => {
      return { ...item, names: item?.names.filter((obj) => obj.check && !obj.isDisable), show: item.status == 2 ? false : true };
    });
    setMileData(delivArr ? delivArr : []);
  };

  const changeTerminatePopup = (value) => {
    setShowTerminatePopup(value);
  };

  const handleDataFunActual = (state) => {
    const actualArr = state?.proposalData?.milestoneActual.map((item) => {
      return { ...item, show: item.status == 2 ? false : true };
    });
    setMileDataActual(actualArr ? actualArr : []);
  };

  const handleDataFunRetainer = (state) => {
    let arr = [];
    let data = state?.proposalData?.milestoneRetainer ? state.proposalData.milestoneRetainer : [];
    data.forEach((item) => {
      if (item.type !== "token") {
        arr.push({ ...item, show: item.status == 2 ? false : true });
      }
    });
    setMileDataRetainer([...arr]);
  };

  return (
    <>
      {state?.type && state.type.toLowerCase() == "fixed" ? (
        <FixedOverviewProj
          {...props}
          state={state}
          mileData={mileData}
          setMileData={setMileData}
          set_milestone_id={set_milestone_id}
          setMileObj={setMileObj}
          setIndex={setIndex}
          setShowClientPayNow={setShowClientPayNow}
          setViewUploadDel={setViewUploadDel}
          setViewAttachments={setViewAttachments}
          setShowUploadMilestone={setShowUploadMilestone}
          setFixedLastSubm={setFixedLastSubm}
          showProjEndPop={showProjEndPop}
          setShowProjEndPop={setShowProjEndPop}
          showFinalReview={showFinalReview}
          setShowFinalReview={setShowFinalReview}
          justStaticEndProjClient={justStaticEndProjClient}
          setjustStaticEndProjClient={setjustStaticEndProjClient}
          termLoad={termLoad}
          terminationData={terminationData}
          changeTerminatePopup={changeTerminatePopup}
          handleTerminationData={handleTerminationData}
          setShowApproveRejectPop={setShowApproveRejectPop}
          setPopupType={setPopupType}
          setShowTerminationPay={setShowTerminationPay}
          dummyMutualAccState={dummyMutualAccState}
          changeMutualAcceptStatus={changeMutualAcceptStatus}
        />
      ) : (
        ""
      )}
      {state?.type && state.type.toLowerCase() == "actual" ? (
        <ActualOverviewProj
          {...props}
          state={state}
          mileDataActual={mileDataActual}
          setMileDataActual={setMileDataActual}
          set_milestone_id={set_milestone_id}
          setMileObj={setMileObj}
          setIndex={setIndex}
          setShowClientPayNow={setShowClientPayNow}
          setViewUploadDel={setViewUploadDel}
          setViewAttachments={setViewAttachments}
          setShowUploadMilestone={setShowUploadMilestone}
          showProjEndPop={showProjEndPop}
          setShowProjEndPop={setShowProjEndPop}
          showFinalReview={showFinalReview}
          setShowFinalReview={setShowFinalReview}
          justStaticEndProjClient={justStaticEndProjClient}
          setjustStaticEndProjClient={setjustStaticEndProjClient}
          termLoad={termLoad}
          terminationData={terminationData}
          changeTerminatePopup={changeTerminatePopup}
          handleTerminationData={handleTerminationData}
          setShowApproveRejectPop={setShowApproveRejectPop}
          setPopupType={setPopupType}
          setShowTerminationPay={setShowTerminationPay}
          setPaymentStatus={setPaymentStatus}
          dummyMutualAccState={dummyMutualAccState}
          changeMutualAcceptStatus={changeMutualAcceptStatus}
        />
      ) : (
        ""
      )}

      {state?.type && state.type.toLowerCase() == "retainer" ? (
        <RetainerOverviewProj
          {...props}
          state={state}
          mileDataRetainer={mileDataRetainer}
          setMileDataRetainer={setMileDataRetainer}
          set_milestone_id={set_milestone_id}
          setMileObj={setMileObj}
          setIndex={setIndex}
          setShowClientPayNow={setShowClientPayNow}
          setViewUploadDel={setViewUploadDel}
          setViewAttachments={setViewAttachments}
          setShowUploadMilestone={setShowUploadMilestone}
          showProjEndPop={showProjEndPop}
          setShowProjEndPop={setShowProjEndPop}
          showFinalReview={showFinalReview}
          setShowFinalReview={setShowFinalReview}
          justStaticEndProjClient={justStaticEndProjClient}
          setjustStaticEndProjClient={setjustStaticEndProjClient}
          termLoad={termLoad}
          terminationData={terminationData}
          changeTerminatePopup={changeTerminatePopup}
          handleTerminationData={handleTerminationData}
          setShowApproveRejectPop={setShowApproveRejectPop}
          setPopupType={setPopupType}
          setShowTerminationPay={setShowTerminationPay}
          dummyMutualAccState={dummyMutualAccState}
          changeMutualAcceptStatus={changeMutualAcceptStatus}
        />
      ) : (
        ""
      )}

      {/* ========================modal start=============================== */}

      {showUploadMilestone && (
        <UploadMilestone
          setShowUploadMilestone={setShowUploadMilestone}
          milestone_id={milestone_id}
          showUploadMilestone={showUploadMilestone}
          // // viewDocument={viewDocument}
          viewAttachments={viewAttachments}
          viewLatestAttachments={viewAttachments?.milestonecomplete?.length ? viewAttachments?.milestonecomplete[0] : ""}
          mileObj={mileObj}
          // // setViewDocument={setViewDocument}
          viewVersionHistory={viewVersionHistory}
          setViewVersionHistory={setViewVersionHistory}
          proposalId={props?.proposalId}
          handleCallApiAgain={props.handleCallApiAgain}
          proposal_type={state?.type}
          setFixedLastSubm={setFixedLastSubm}
          fixedLastSubm={fixedLastSubm}
          uploadMileEdit={uploadMileEdit}
          setUploadMileEdit={setUploadMileEdit}
          setViewAttachments={setViewAttachments}
          {...props}
        />
      )}
      {viewUploadDel && (
        <ViewUploadDel
          setViewUploadDel={setViewUploadDel}
          viewAttachments={viewAttachments}
          viewLatestAttachments={
            fixedLastSubm
              ? viewAttachments
              : viewAttachments?.milestonecomplete?.length
              ? viewAttachments?.milestonecomplete[0]
              : {}
          }
          milestone_id={milestone_id}
          viewUploadDel={viewUploadDel}
          // // viewDocument={viewDocument}
          // // setViewDocument={setViewDocument}
          viewVersionHistory={viewVersionHistory}
          setViewVersionHistory={setViewVersionHistory}
          role={props?.CurrentRole}
          mileObj={mileObj}
          proposalId={props?.proposalId}
          setShowUploadMilestone={setShowUploadMilestone}
          handleCallApiAgain={props.handleCallApiAgain}
          proposal_type={state?.type}
          state={state}
          setFixedLastSubm={setFixedLastSubm}
          fixedLastSubm={fixedLastSubm}
          setRetHourlyReqType={setRetHourlyReqType}
          setRetHourlyReq={setRetHourlyReq}
          uploadMileEdit={uploadMileEdit}
          setUploadMileEdit={setUploadMileEdit}
          setReworkId={setReworkId}
          allData={allData}
          setAllData={setAllData}
          paymentStatus={paymentStatus}
          {...props}
        />
      )}
      {showClientPayNow && (
        <ClientPayNow
          proposalId={props?.proposalId}
          setShowClientPayNow={setShowClientPayNow}
          showClientPayNow={showClientPayNow}
          milestone_id={milestone_id}
          mileObj={mileObj}
          index={index}
          handleCallApiAgain={props.handleCallApiAgain}
          projectDataStates={props?.projectDataStates}
          proposal_type={state?.type}
          state={state}
          {...props}
        />
      )}

      {showTerminationPay && (
        <TerminationPay
          proposalId={props?.proposalId}
          setShowTerminationPay={setShowTerminationPay}
          showTerminationPay={showTerminationPay}
          handleCallApiAgain={props.handleCallApiAgain}
          projectDataStates={props?.projectDataStates}
          proposal_type={state?.type}
          terminationData={terminationData}
          {...props}
        />
      )}

      {showProjEndPop && (
        <ProjectEndPopup
          showProjEndPop={showProjEndPop}
          setShowProjEndPop={setShowProjEndPop}
          // setShowFinalReview={setShowFinalReview}
          setjustStaticEndProjClient={setjustStaticEndProjClient}
          {...props}
        />
      )}
      {showFinalReview && (
        <FinalReviewPop state={state} showFinalReview={showFinalReview} setShowFinalReview={setShowFinalReview} {...props} />
      )}
      {showEndProjSuccess && (
        <EndProjSuccessPop
          id={props?.projectDataStates?.id}
          projectData={props?.projectDataStates?.data?.project_data}
          showEndProjSuccess={showEndProjSuccess}
          CurrentRole={props?.CurrentRole}
          proposal_data={state?.proposalData}
          setjustStaticEndProjClient={setjustStaticEndProjClient}
          setShowFinalReview={setShowFinalReview}
          setShowEndProjSuccess={setShowEndProjSuccess}
        />
      )}

      {retHourlyReq && (
        <RetHourlyRequest
          userData={state.proposalData.userData}
          work_log_id={viewAttachments?.milestonecomplete[0]?._id}
          retHourlyReqTYpe={retHourlyReqTYpe}
          retHourlyReq={retHourlyReq}
          setRetHourlyReq={setRetHourlyReq}
          milestone_id={milestone_id}
          reworkId={reworkId}
          setViewUploadDel={setViewUploadDel}
          handleCallApiAgain={props.handleCallApiAgain}
        />
      )}

      {showTerminatePopup ? (
        <TerminateProjProcess
          showTerminatePopup={showTerminatePopup}
          changeTerminatePopup={changeTerminatePopup}
          isEdit={true}
          id={props?.projectDataStates?.id}
          terminalData={terminationData}
          handleTerminationData={handleTerminationData}
          retainer_fees_type={state?.proposalData?.retainer_fees_type}
          {...props}
        />
      ) : (
        ""
      )}

      {/* {showApproveRejectPop ? (
        <CommonApproveAndReject
          heading={"Undo Termination"}
          text={`undo this project termination`}
          buttonName={"Undo Termination"}
          msg={"Undo termination successfully"}
          apiPath={`/proposal/undo_termination`}
          popupType={popupType}
          id={props?.projectDataStates?.id}
          showApproveRejectPop={showApproveRejectPop}
          closeAppRejPopup={setShowApproveRejectPop}
        />
      ) : null} */}

      {showApproveRejectPop ? (
        <CommonApproveAndReject
          heading={popupType === 1 ? "Approve Termination" : "Undo Termination"}
          text={popupType === 1 ? ` approve this project termination` : ` undo this project termination`}
          buttonName={popupType === 1 ? "Approve" : "Undo Termination"}
          msg={popupType === 1 ? "Approve termination successfully" : "Undo termination successfully"}
          apiPath={popupType === 1 ? `/proposal/project-termination-accepted` : `/proposal/undo_termination`}
          popupType={popupType}
          id={props?.projectDataStates?.id}
          showApproveRejectPop={showApproveRejectPop}
          closeAppRejPopup={setShowApproveRejectPop}
          callApiAgain={props.callApiAgain}
        />
      ) : null}
    </>
  );
};

export default MilestonePayCreator;
