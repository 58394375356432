import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeaturedGalleryIconImage from '../../../../../../Assets/upgrade-images/submit_proposal/gallery.svg'
import TrashImage from '../../../../../../Assets/upgrade-images/submit_proposal/trash.svg';
import { fireApi } from '../../../../../../utils/fireApiPrecheck';
import { ChatImageBaseUrl } from "../../../../../../utils/BaseUrl"
import Loader from '../../../../../authorized/Common/Common/Loader';
import { projectAttachments } from "../../../../../../store/action/proposal.action";
import { handleFileName, handleFileType } from "../../CommonFunctionsNew/CommonFunctionsNew";

const Attachments = () => {
  const [attachments, setAttachments] = useState([])
  const [filesUploading, setfilesUploading] = useState(false)
  const _relevent_projects = useSelector((state) => state.releventProjects)

  const attachmentsIs = _relevent_projects?.link_image ? _relevent_projects.link_image : []

  const dispatch = useDispatch()

  const setProjectAttachments = (obj) => {
    dispatch(projectAttachments(obj))
  }

  useEffect(() => {
    setfilesUploading(false)
    setAttachments(attachmentsIs)
  }, [])

  const setImageRes = (value) => {
    const formData = new FormData();
    let validSize = false
    let invalid_images = [];
    let otherFormat = [];
    let valid_images = []
    // const allValidTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'ppt']
    let count = attachments.length >= value?.length ? attachments.length >= value?.length : 5 - attachments.length
    for (var i = 0; ((i < value?.length) && (i < count)); i++) {
      // const validFormatIs = allValidTypes.includes(value[i]?.name?.split(".")[value[i]?.name?.split(".")?.length - 1])
      // if (validFormatIs) {
        if (value[i]?.size > 10 * 1024 * 1024) {
          invalid_images.push(value[i]);
        } else {
          valid_images.push(value[i])
          // setAttachments(attachments?.concat({ name : ""  }))
          validSize = true
          formData.append('chatFiles', value[i]);
        }
      // } else {
      //   otherFormat.push(value[i])
      // }
    }
    if (otherFormat?.length > 0) {
      let alertMsg = (otherFormat.length > 1) ? otherFormat?.[0]?.name + "and " + (otherFormat.length - 1) + " other files are not uploaded, Please upload files of valid formats." : otherFormat?.[0].name + " is not uploaded, Please upload file of valid format";
      alert(alertMsg);
      setfilesUploading(false)
      return
    }
    if (invalid_images?.length > 0) {
      let alertMsg = (invalid_images.length > 1) ? invalid_images?.[0].name + "and " + (invalid_images.length - 1) + " other files are not uploaded, Please upload files of size less than 10 Mb." : invalid_images?.[0].name + " is not uploaded, Please upload file of size less than 10 Mb";
      alert(alertMsg);
      setfilesUploading(false)
    }
    if (valid_images?.length > 0) {
      fireApi('UploadChatFiles', formData)
        .then((res) => {
          const valueIs = res.chat_files
          if (valueIs?.length > 0) {
            let allImages = attachmentsIs
            const data = valueIs?.forEach((obj) => {
              allImages.push(obj)
            })
            setAttachments(allImages)
            setfilesUploading(false)
            setProjectAttachments(allImages)
          } else {
            setAttachments(attachments)
            // setfilesUploading(false)
            setProjectAttachments()
          }

        }).catch(err => {
          setfilesUploading(false)
        })
    } else {
      setfilesUploading(false)
    }
  }

  const handleOnImage = (e) => {
    const valueIs = e.target?.files
    if (valueIs?.length > 0) {
      if (valueIs?.length > 5) {
        alert("Max limit is 5")
      }
      setfilesUploading(true)
      setImageRes(valueIs)
    } else {
      alert("Please choose attachments")
    }
  }

  const handleRemove = (index) => {
    const data = attachments?.filter((_, i) => i !== index)
    setAttachments(data)
    setProjectAttachments(data)
  }

  return (
    <>
      {/* upload_media */}
      <div className='col-lg-12 px-0 py-4 mob-m-0'>
        <div className='upload_sub_title d-block d-md-flex justify-content-between align-items-center'>
          <p className='text_light_gray_xs'>Supports JPG, JPEG, PNG, GIF, DOC, PDF, XLSX, DOCX, PPT, ZIP </p>
          <p className='text_light_gray_xs'>Maximum 5 files can be uploaded</p>
        </div>
        {attachmentsIs?.length === 0 ?
          <div className='upload_box position-relative cursor_pointer'>
            <div className='upload_icon d-flex cursor_pointer'>
              <img src={FeaturedGalleryIconImage} className='gallery_icon img-fluid mb-2' />
              <input className="file_input" type="file" name="myfile"
                accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx,.xlsx,.ppt,.zip, image/*'
                multiple onChange={(e) => handleOnImage(e)} />
              <p className='mb-0 text_black_xs_w_600'>Upload Media</p>
            </div>
          </div> : ""}
      </div>
      {/* uploaded image */}
      <div className='col-lg-12 px-0 mb-4'>
        <div className='upload_image_box row'>
          {filesUploading ?
            <Loader style={{ margin: "0 auto" }} /> :
            attachmentsIs?.length ?
              attachmentsIs?.map((obj, index) => {
                const data = ['gif', 'jpg', 'jpeg', 'png']
                return (
                  <>
                  <div className="col-lg-3 mb-4">
                    <div className='upload_image upload_image_delete border' key={index}>
                      <img className=' image' src={handleFileType(obj?.name, ChatImageBaseUrl)}
                      />
                      <div className='delete_active'>
                        <div className="image_title">
                          <p className="mb-0 text_black_xs_w_600">{handleFileName(obj?.name, 12)}</p>
                        </div>
                        <div className='trash_box_trasparent' onClick={() => handleRemove(index)}>
                          <img className='upload_trash_icon' src={TrashImage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
 
                )
              }) : ""
          }
           {(attachmentsIs?.length !== 0) && (attachmentsIs?.length < 5) ?
          <div className="col-lg-3 mb-4">
            <div className='upload_box position-relative h-100 cursor_pointer'>
                <div className='d-flex cursor_pointer text-blue-800'>
                   <p className='mb-0 plus_icon font-30'>+</p>
                  <input className="file_input" type="file" name="myfile"
                    // accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx, image/*'
                    accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,.doc, .docx,.xlsx,.ppt,.zip, image/*'
                    multiple onChange={(e) => handleOnImage(e)} />
                </div>
            </div>
          </div>: ""}
        </div>
      </div>
    </>
  )
}

export default Attachments