import React, { useState } from "react";
import TrashImage from "../../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import PriceImage from "../../../../../../Assets/upgrade-images/submit_proposal/price.svg";
import DateIcon from "../../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import DatePicker from "react-date-picker";
import { onkeyCode } from "../../CommonFunctionsNew/CommonFunctionsNew";

const Deliverables = ({ states, setStates, validationDeliverable, addDays }) => {
  const handleChangeDeliverables = (e, i, action) => {
    if (action === "name") {
      const { value } = e.target;
      if (value.length < 100) {
        states.deliverable[i].name = value;
        states.deliverable[i].validation.name = value.length > 0 ? false : true;
      }
    } else if (action === "charge") {
      const { value } = e.target;
      states.deliverable[i].iteration_charge = value;
      states.deliverable[i].validation.iteration_charge = value.length > 0 ? false : true;
    } else {
      states.deliverable[i].dueDate = new Date(e);
      states.deliverable[i].validation.dueDate = e ? false : true;
    }
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleToggleIteration = (e, i) => {
    const { checked } = e.target;
    states.deliverable[i].toggle = checked;
    states.deliverable[i].iteration_charge = "";
    states.deliverable[i].iterations = 1;
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleAppend = () => {
    let valid = validationDeliverable();
    if (valid) {
      let length = states.deliverable.length;
      // new Date(addDays(states.deliverable[length - 1].dueDate, 1))
      let date = length > 0 ? "" : "";
      setStates({
        ...states,
        deliverable: [
          ...states.deliverable,
          {
            name: "",
            dueDate: date,
            iterations: "",
            toggle: false,
            check: false,
            isDisable: false,
            iteration_charge: "",
            validation: {
              name: false,
              dueDate: false,
              iteration_charge: false,
            },
            status: 0,
            milestonecomplete: [],
            paid_status: 0,
            id: "",
            restrict: false,
          },
        ],
      });
    }
  };

  const handleIteration = (i, action) => {
    if (states.deliverable[i].iterations >= 1) {
      if (action === "plus") states.deliverable[i].iterations += 1;
      else {
        if (states.deliverable[i].iterations > 1) states.deliverable[i].iterations -= 1;
      }
      setStates({
        ...states,
        deliverable: [...states.deliverable],
      });
    }
  };

  const dateimg = <img src={DateIcon} />;
  const handleDelete = (index) => {
    states.deliverable.splice(index, 1);
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };
  return (
    <React.Fragment>
      <div className="row p-4 deliverables mx-0  scroll_bar_body">
        <div className="col-lg-12 title_commn d-flex justify-content-between">
          <p className="mb-0 text_black_md">Deliverables</p>
          <div className="d-block d-lg-none">
            <img
              src={FeaturedToggleImage}
              className="icon_img mr-1 cursor-pointer"
              data-toggle="collapse"
              href={`#collapseDeliverables`}
              role="button"
              aria-expanded="false"
              aria-controls={`collapseDeliverables`}
            />
          </div>
        </div>
        <div className="col-lg-12 px-0 py-3">
          <div className="row mx-0 collapse show " id={`collapseDeliverables`}>
            {/* <div className="col-lg-12 px-0 py-3">
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit laoreet est augue faucibus ac. Est morbi cras nisl,
            non quis massa mattis sit arcu. Facilisis purus vitae volutpat tincidunt consectetur pulvinar convallis sed. Nulla
            risus sit in enim nam aliquam sapien phasellus rhoncus.
          </p>
        </div> */}

            <div className="custom_table col-lg-12 px-0 d-none d-lg-block">
              <div class="row m-0 pb-3 br_btm text_text-gray-1400">
                <div class="col-xxl-8 col-lg-7 d-flex justify-content-start align-items-center">
                  <span class="mr-2 w-5">#</span>
                  <span class="">Name</span>
                </div>
                <div class="col-xxl-2 col-lg-3">
                  <span class="">Due Date</span>
                </div>
                <div class="col-xxl-2 col-lg-2">
                  <span class="">Iterations</span>
                </div>
              </div>
              {states.deliverable?.map((item, i) => {
                return (
                  <>
                    <div class="row-border creator-proposal" key={i}>
                      <div class="row mx-0 mb-3 py-3 align-items-center ">
                        <div class="col-lg-7">
                          <div className="d-flex justify-content-start align-items-center">
                            <span class="mr-2 w-5">{onkeyCode(i + 65)}</span>
                            <input
                              className={`form-control input_md ${item.validation.name ? "border-danger" : ""}`}
                              type="text"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleAppend();
                              }}
                              // disabled={item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? true : false}
                              disabled={item?.restrict ? true : false}
                              value={item.name}
                              onChange={(e) => handleChangeDeliverables(e, i, "name")}
                              placeholder="Deliverable Name"
                            />
                          </div>

                          {item.validation.name ? <p className="error_message">field required</p> : ""}
                        </div>
                        <div class="col-lg-3 pr-0">
                          <DatePicker
                            onChange={(e) => handleChangeDeliverables(e, i, "date")}
                            // disabled={item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? true : false}
                            disabled={item?.restrict ? true : false}
                            value={item.dueDate}
                            className={`form-control text-center input_sm ${item.validation.dueDate ? "border-error" : ""}`}
                            dayPlaceholder={"dd"}
                            monthPlaceholder={"mm"}
                            yearPlaceholder={"yyyy"}
                            calendarIcon={dateimg}
                            clearIcon={null}
                            name="date"
                            format="dd/MM/yyyy"
                            calendarClassName="apniClass"
                            showLeadingZeros={true}
                            minDate={
                              states?.deliverable?.[i - 1]
                                ? new Date(addDays(states.deliverable[i - 1].dueDate, 1))
                                : new Date(states.proposed_start_date)
                            }
                            maxDate={states?.deliverable?.[i + 1] ? new Date(addDays(states.deliverable[i + 1].dueDate, -1)) : ""}
                          />
                          {item.validation.dueDate ? <p className="error_message">field required</p> : ""}
                        </div>
                        <div class="col-xxl-2 col-lg-2 pr-0">
                          <div className="toggle_btn d-flex align-items-center justify-content-between">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={item.toggle}
                                // disabled={
                                //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                //     ? true
                                //     : false
                                // }
                                disabled={item?.restrict ? true : false}
                                onChange={(e) => handleToggleIteration(e, i)}
                              />
                              <span className="slider round"></span>
                            </label>
                            {/* {item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? ( */}
                            {item?.restrict ? null : (
                              <img
                                src={TrashImage}
                                className="trash_icon img-fluid cursor-pointer"
                                onClick={() => handleDelete(i)}
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* table toggle */}

                      {item.toggle ? (
                        <div className="row  m-0 mb-3 justify-content-end">
                          <div className="col-lg-11 pr-0">
                            <div className="row table_toggle mx-0">
                              <div className="col-lg-5 col-5  d-flex justify-content-start align-items-center">
                                <span className="title">Number of iterations </span>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                  <button
                                    type="button"
                                    className="btn"
                                    // disabled={
                                    //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={item?.restrict ? true : false}
                                    onClick={() => handleIteration(i, "minus")}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control text-center"
                                    name="iteration"
                                    value={item.iterations}
                                    disabled={true}
                                  />
                                  <button
                                    type="button"
                                    // disabled={
                                    //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={item?.restrict ? true : false}
                                    className="btn "
                                    onClick={() => handleIteration(i, "plus")}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>

                              <div
                                className="col-lg-7 col-7  d-flex justify-content-start align-items-center"
                                style={{ backgroundColor: "#F5F7FD" }}
                              >
                                <span className="title">
                                  Price Per iteration after <b>{item.iterations} Iterations</b>
                                </span>
                                <div className="price_input">
                                  <input
                                    type="number"
                                    className={`form-control input_sm ${item.validation.iteration_charge ? "border-danger" : ""}`}
                                    placeholder="Price"
                                    value={item.iteration_charge}
                                    onKeyDown={(evt) =>
                                      (evt.key === "e" ||
                                        evt.key === "=" ||
                                        evt.key === "-" ||
                                        evt.key === "+" ||
                                        evt.key === "_" ||
                                        evt.key === ".") &&
                                      evt.preventDefault()
                                    }
                                    // disabled={
                                    //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={item?.restrict ? true : false}
                                    onChange={(e) => handleChangeDeliverables(e, i, "charge")}
                                  />
                                  <img src={PriceImage} className="price_icon img-fluid"></img>
                                </div>
                                {item.validation.iteration_charge ? <p className="error_message pl-3">field required</p> : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                );
              })}

              <div className="row">
                <div className="col-lg-12 mt-3">
                  <p className="add_on_milestone ">
                    <span className="cursor-pointer" onClick={handleAppend}>
                      {" "}
                      <span className="add_on_milstone_icon ">+</span>Add Deliverables{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* mobile view */}

            <div className="custom_table col-lg-12 p-0 d-lg-none d-block">
              {states.deliverable?.map((item, i) => {
                return (
                  <>
                    <div class="border-top pt-3 row m-0 pb-3 br_btm text_text-gray-1400" key={i}>
                      <div className="col-sm-12 d-flex mob-justify-content-between">
                        <div className="text_black_md">Deliverable {onkeyCode(i + 65)}</div>
                        <div>
                          {/* {item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? ( */}
                          {item?.restrict ? null : (
                            <img
                              src={TrashImage}
                              className="trash_icon img-fluid cursor-pointer"
                              onClick={() => handleDelete(i)}
                            ></img>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 mb-2">
                        <label>Name</label>
                        <input
                          className={`form-control input_md ${item.validation.name ? "border-danger" : ""}`}
                          type="text"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleAppend();
                          }}
                          // disabled={
                          //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? true : false
                          // }
                          disabled={item?.restrict ? true : false}
                          value={item.name}
                          onChange={(e) => handleChangeDeliverables(e, i, "name")}
                          placeholder="Deliverable Name"
                        />
                        {item.validation.name ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div className="col-sm-12 mb-2">
                        <label>Due Date</label>
                        <DatePicker
                          onChange={(e) => handleChangeDeliverables(e, i, "date")}
                          // disabled={
                          //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? true : false
                          // }
                          disabled={item?.restrict ? true : false}
                          value={item.dueDate}
                          className={`form-control text-center input_sm ${item.validation.dueDate ? "border-error" : ""}`}
                          // className="form-control text-center input_sm"
                          clearIcon={null}
                          name="date"
                          format="dd/MM/yyyy"
                          calendarClassName="apniClass"
                          showLeadingZeros={true}
                          minDate={
                            states?.deliverable?.[i - 1]
                              ? new Date(addDays(states.deliverable[i - 1].dueDate, 1))
                              : new Date(states.proposed_start_date)
                          }
                          maxDate={states?.deliverable?.[i + 1] ? new Date(addDays(states.deliverable[i + 1].dueDate, -1)) : ""}
                        />
                        {item.validation.dueDate ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div className="col-sm-12 mb-2">
                        <label>Iterations</label>
                        <div className="toggle_btn">
                          <label className="switch mb-0">
                            <input
                              type="checkbox"
                              checked={item.toggle}
                              // disabled={
                              //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0 ? true : false
                              // }
                              disabled={item?.restrict ? true : false}
                              onChange={(e) => handleToggleIteration(e, i)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      {/* table toggle */}

                      {item.toggle ? (
                        <div className="row m-0 mb-3 justify-content-end">
                          <div className="table_toggle mx-0">
                            <div className="border-bottom mb-2 pb-2 d-flex justify-content-between align-items-center">
                              <span className="title p-0">Number of iterations </span>
                              <div className="btn-group" role="group" aria-label="Basic example">
                                <button
                                  type="button"
                                  className="btn"
                                  // disabled={
                                  //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={item?.restrict ? true : false}
                                  onClick={() => handleIteration(i, "minus")}
                                >
                                  -
                                </button>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name="iteration"
                                  value={item.iterations}
                                  disabled={true}
                                />
                                <button
                                  type="button"
                                  // disabled={
                                  //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={item?.restrict ? true : false}
                                  className="btn "
                                  onClick={() => handleIteration(i, "plus")}
                                >
                                  +
                                </button>
                              </div>
                            </div>

                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{ backgroundColor: "#F5F7FD" }}
                            >
                              <span className="title p-0">
                                Price Per iteration after <b>{item.iterations} Iterations</b>
                              </span>
                              <div className="price_input">
                                <input
                                  type="number"
                                  className={`form-control input_sm ${item.validation.iteration_charge ? "border-danger" : ""}`}
                                  placeholder="Price"
                                  value={item.iteration_charge}
                                  onKeyDown={(evt) =>
                                    (evt.key === "e" ||
                                      evt.key === "=" ||
                                      evt.key === "-" ||
                                      evt.key === "+" ||
                                      evt.key === "_" ||
                                      evt.key === ".") &&
                                    evt.preventDefault()
                                  }
                                  // disabled={
                                  //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={item?.restrict ? true : false}
                                  onChange={(e) => handleChangeDeliverables(e, i, "charge")}
                                />
                                <img src={PriceImage} className="price_icon img-fluid"></img>
                              </div>
                              {item.validation.iteration_charge ? <p className="error_message pl-3">field required</p> : ""}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Deliverables;
