import React, { Component } from 'react'
import { withRouter } from 'react-router'

class RedirectToHire extends Component {

    componentDidMount(){
        const {history, match} = this.props;
        history.push(`/self-service-hire/${match.params?.collateral}`)
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default withRouter(RedirectToHire)
