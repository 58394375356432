import React, { useEffect, useState } from "react";
import LinkIcon from "../../../../../Assets/upgrade-images/submit_proposal/url_link.svg";
import { projectUrls } from "../../../../../store/action/proposal.action";
import { useDispatch, useSelector } from "react-redux";
import { removeHttps } from "../../../../../utils/Helperfunctions";

const UrlsForClient = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [urls, setUrls] = useState([]);
  const [addUrl, setAddUrl] = useState(true);
  const [errorIs, setError] = useState("");
  const dispatch = useDispatch();

  const _relevent_projects = useSelector((state) => state.releventProjects);
  const urlsIs = _relevent_projects?.urls;

  const handleChange = (e) => {
    setCurrentUrl(e.target.value);
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data));
  };

  // useEffect(()=>{
  //     setAllUrls()
  //     console.log("chla")
  // },[urls])

  const handleAppend = (e) => {
    e.preventDefault();
    const validUrl = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
      currentUrl
    );
    if (validUrl) {
      const currentUrlIs = currentUrl?.split("/").slice(0)[0];
      let currentData = null;
      if (currentUrlIs === "http:" || currentUrlIs === "https:" || currentUrlIs === "www:") {
        currentData = { name: currentUrl };
      } else {
        currentData = { name: "http://" + currentUrl };
      }
      const data = urlsIs.concat(currentData);
      setUrls(data);
      setAddUrl(false);
      setAllUrls(data);
    } else {
      setError("Invalid url");
    }
  };

  return (
    <>
      <div className="row px-0 mx-0">
        {urlsIs?.length ? (
          urlsIs.map((obj, index) => {
            return (
              <>
                <div className="col-lg-11 px-0 mb-2" key={index}>
                  <input class="form-control input_md url_input" disabled type="text" value={obj?.name} />

                  <div
                    className="input_content d-flex justify-content-between align-items-center w-100 px-3 cursor-pointer"
                    onClick={() => window.open(`http://${removeHttps(obj?.name)}`, "_blank", "noopener,noreferrer")}
                  >
                    <img className="img-fluid" src={LinkIcon} />
                    {/* <p className='mb-0 blue_text_sm'>Add URL</p> */}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="col-sm-12 text-center">
            <span className="discription_common pl-3">No URLs Found...</span>
          </div>
        )}
      </div>
    </>
  );
};

export default UrlsForClient;
