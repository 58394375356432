import React, {useState} from 'react'
import { BaseImageUrl } from '../../../../../../utils/BaseUrl'

const ClientCard = ({data}) => {
    const [isViewMore, setIsViewMore] = useState(false)
    return (
        <div className="card-wrapper mt-5">
            <div className="card p-2">
                <div className="card-body">
                    <div className="w-100 d-flex align-items-center">
                        <div className="">
                            <p className="mb-0 model-profile"><img src={BaseImageUrl + data.profile_image} alt="profile" title="" width="80" /></p>
                        </div>
                        <div className="w-65 overflow-hidden pl-2">
                            <span className="font-12 text-dark-gray font-weight-600">THE CLIENT</span>
                            <h2 className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16">{data.first_name} {data.last_name} </h2>
                            {!data.isIndividual && <span className="profile-subTitle font-14 font-weight-600 text-dark-gray line-height-14"> {data.role} at {data.company_name}</span>}
                        </div>
                    </div>
                    {!data.isIndividual && isViewMore && <div className="more-view-details">
                        <div className="row m-0  px-3">
                            {data.about ? 
                                <>
                                    <div className="col-sm-12 dflex p-0">
                                        <h2 className="text-capitalize text-dark-gray font-14 font-weight-600 mb-2">About</h2>  
                                    </div>
                                    <div className="col-sm-12 px-0 mb-2 font-weight-500 text-dark-black">
                                        <p className="mb-0 text-dark black font-13 font-weight-600">
                                        {data.about}
                                        </p>
                                    </div>
                                </>
                             : null}
                            {data.website ? <div className="Project-duration mb-2 col-12 px-0">
                                <span className="sub-design font-14 font-weight-600 text-dark-gray">Website</span>
                                <p className="mb-0 font-13 text-dark-black font-weight-600">
                                    {data.website}
                                </p>
                            </div> : null }
                            {data.industry ? <div className="Project-duration mb-2 col-12 px-0">
                                <span className="sub-design font-14 font-weight-600 text-dark-gray">Industry</span>
                                <p className="mb-0 font-13 text-dark-black font-weight-600">
                                    {data.industry}
                                </p>
                            </div> : null} 
                        </div>
                    </div>}
                    {!data.isIndividual && 
                    <button onClick={() => setIsViewMore(!isViewMore)} type="button" className="main-btn w-100 mt-2 bg-light-sky font-16 text-dark-gray font-weight-500" id="more-detais" >View Profile <i className={`fas font-14 mob-font-14 ml-0 collacteral pointer position-relative ${isViewMore ? 'fa-chevron-up': 'fa-chevron-down'}`}></i></button>} 
                </div>
            </div>
    </div>
    )
}

export default ClientCard