import { Helmet } from 'react-helmet';
import React from 'react';
import { useSelector } from 'react-redux';

const HelmetComp =({location})=>{
// console.log(location);
const {pathname} = location;
const loginInfo = useSelector(state=>state.loginReducer);

// console.log(loginInfo);   

// if(pathname.startsWith("/chat")){
//     // console.log("hello");
//     let path = pathname;
//    var [,,projectId,projectName] = path.split('/');
  
// }
if(pathname.startsWith("/uploadproject")){
    // console.log("hello");
   
  return  <Helmet><title>Upload Work || IndieFolio</title></Helmet>
}


if(pathname.startsWith("/adminChat/")){
    // console.log("hello");
    let path = pathname;
   var [,,adminChatProjectId,adminChatProjectName] = path.split('/');
//    console.log("ad",adminChatProjectId);
  
}
const helmetArray = [
    {pathname:'/',title:'IndieFolio || Hire The Best Freelancers For Your Creative Needs'},
    {pathname:'/profile_selection',title:'Profile Selection || IndieFolio'},
    {pathname:'/hire',title:"Hire A Creative Expert || IndieFolio"},
    {pathname:'/project_details/project',title:'Brief Details || IndieFolio'},
    {pathname:'/project_details/company',title:'Brief Details || IndieFolio'},
    { pathname: '/dashboard', title: 'Dashboard || IndieFolio' },
    { pathname: '/dashboard', title: 'Dashboard || IndieFolio' },
    { pathname: '/dashboard/jobs', title: 'Jobs || IndieFolio' },
    { pathname: '/dashboard/completed-projects', title: 'Completed Projects || IndieFolio' },
    { pathname: '/active-briefs', title: 'Active Briefs || IndieFolio' },
    // {pathname:`/chat/${projectId}/${projectName}`,title:`${projectName} Messages || IndieFolio`},
    {pathname:'/Profile/MyAccount',title:'Account Settings || IndieFolio'},
    {pathname:'/Profile/accountSecurity',title:'Account Settings || IndieFolio'},
    {pathname:'/Profile/notification',title:'Account Settings || IndieFolio'},
    {pathname:'/Profile/billing',title:'Account Settings || IndieFolio'},
    {pathname:'/contactus',title:'Contact Us || IndieFolio'},
    {pathname:'/terms',title:'Terms of Use || IndieFolio'},
    {pathname:'/privacypolicy',title:'Privacy Policy || IndieFolio'},
    {pathname:'/uploadproject',title:'Upload Work || IndieFolio'},
    {pathname:'/onboarding',title:'Onboarding || IndieFolio'},
    {pathname:'/creatoragreement',title:'Creator Agreement || IndieFolio'},
    {pathname:'/services',title:'All Services || Hire A Creative Freelancer on IndieFolio'},
    // {pathname:`/creator/${userName}`,title:`${userName} || Hire The Best Freelancers For Your Creative Needs`},
    {pathname:'/submitMilestone',title:'Submit Milestone || IndieFolio'},
    {pathname:'/rawfiles',title:'Submit Raw Files || IndieFolio'},
    {pathname:'/payment',title:'Payment|| IndieFolio'},
    {pathname:'/adminchat',title:'Hire A Creative Expert || IndieFolio'},
    {pathname:`/adminChat/${adminChatProjectId}/${adminChatProjectName}`,title:`${adminChatProjectName} || IndieFolio`},
    {pathname:'/hire/schedule-call',title:'Hire A Creative Expert || IndieFolio'},
    {pathname:'/CreatorProfile',title:'Creator Profile || IndieFolio'}

    // {pathname:'/hire/schedule-call',title:'Hire A Creative Expert || IndieFolio'},
    // {pathname:'/hire/schedule-call',title:'Hire A Creative Expert || IndieFolio'},

  ]
  if(loginInfo.login ===true){
   return <Helmet><title>Log In || IndieFolio</title></Helmet>
}
if(loginInfo.signup ===true){
    return <Helmet><title>Log In / Sign UP || IndieFolio</title></Helmet>
 }

return helmetArray.map((el,key)=>{
  
    if(pathname===el.pathname) return <Helmet key={key}><title>{el.title}</title></Helmet>
    return <></>
})
  
    // if(pathname=='/'){
    //  return <Helmet>
    //       <title>login</title>
    //   </Helmet>
    // }
    // if(pathname=='/dashboard'){
    //    return <Helmet>
    //         <title>Dashboard</title>
    //     </Helmet>
    //   }
   
    // return <div></div>
}

export default HelmetComp;