import React from "react";
import Proposal from "../../../../Assets/upgrade-images/submit_proposal/document-like.svg";

const NotFound = (props) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-rejected" role="tabpanel" aria-labelledby="nav-rejected-tab">
            <div className="row rejected_body  body_scroll_bar d-flex justify-content-center align-items-center">
              <div className="col-lg-3 text-center">
                <img src={Proposal} className="img-fluid mb-3" />
                {props?.isReject ? (
                  <p className="text_light_gray_lg_w_600">
                    No proposal is being
                    <br /> rejected yet
                  </p>
                ) : (
                  <p className="text_light_gray_lg_w_600">
                    Creators will share
                    <br /> proposals with you here
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
