import React from "react";
import { connect } from 'react-redux';
import ErrorSpan from './ErrorSpan';

class TextInputBox extends React.Component {
  render() {
    return (
      <>
        <input
          type="text"
          className={`form-control email pl-3 ${this.props.className} ${this.props.error === true ? 'border-error' : ''}`}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={(e) => { this.props.onDPChange({
            name: e.target.name, value: e.target.value, onblur: this.props.onblur
          }) }}
          onBlur={(e) => { this.props.onDPChange({
            name: e.target.name, value: e.target.value
          }) }}
          value={this.props.value}
          autoComplete="off"
        />
        <ErrorSpan error={this.props.error} message={this.props.message} />
      </>
    )
  }
}

const mapDispatch = dispatchEvent => ({
  onDPChange: (e) => dispatchEvent({ type: 'ON_FIELDCHANGE_SAGA', payload: { name: e.name, value: e.value, onblur: e.onblur } }),
});
export default connect(null, mapDispatch)(TextInputBox);
