import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { getAPI } from '../../../utils/API';
import { ChatImageBaseUrl } from "../../../utils/BaseUrl";
import {
  renderDifferentFiles,
  getFileType,
  getFileName,
  getMsgTime,
  renderDifferentFilesThumbnail,
} from "../Common/Common/FileRenderMessage";
import RenderMilestoneReview from "./Common/RenderMilestoneReview";
// import RenderAvatar from './Common/RenderAvatar';
// import RenderUser from './Common/RenderUser';
import { getThumbnailImage, removeHttps, sanitiseSummernoteText } from "../../../utils/Helperfunctions";
import RenderHtml from "../Common/RenderHtml/RenderHtml";
import { withRouter } from "react-router";
import getUserImage from "./utils/getUserImage";
import getUserName from "./utils/getUserName";
import { limitString } from "../../../utils/Helperfunctions";
import NotedIcon from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/noted.svg";
import MeetingRightIcon from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/meeting_right_icon.svg";
import { getDateTimeFormated } from "../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew";
import ParentChatViewUp from "../../authorized/Home/DashboardNew/PopupAll/ChatViewMilestone/ParentChatViewUp"

// =======chat new ui ==========

function RenderAvatar(props) {
  const image = getUserImage(props.props);

  return (
    <div className="">
      <p className="mb-0 creator-profile mr-3">
        <img src={image} alt="profile" title="" width="35" />
      </p>
    </div>
  );
}

function RenderUser(props) {
  props = props.props;
  const name = getUserName(props);
  return (
    <div className="d-flex align-items-center mb-1">
      <div className="d-flex align-items-center">
        <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">{name}</h2>
      </div>
      <div className="pl-2">
        <span className="font-12 text-sub fw_400">{getDateTimeFormated(props.Data.time)}</span>
        {/* getMsgTime(props.Data.time) */}
      </div>
    </div>
  );
}

class MessageTemplate extends React.Component {
  state = {
    payment_reference_id: "",
    milestone_id: "",
    milestone_complete_id: "",
    proposal_id: "",
    viewUploadDel: false,
  };

  setViewUploadDel = (value) => {
    this.setState({ ...this.state, viewUploadDel: value });
  };

  renderFiles = (files) => {
    // console.log(files);
    const openChatFiles = (idx) => {
      this.props.setChatFiles(this.props.Data, idx);
      this.props.OpenPopup("ChatFiles");
    };

    if (files && files.length < 1) return <></>;
    return (
      files &&
      files.length > 0 &&
      files.map((f, k) => {
        return (
          <span
            key={k}
            onClick={() => openChatFiles(k)}
            className="upload-files mob-w-100 border-1 m-2 border-radius-8">
            <div className="d-flex align-items-center">
              {renderDifferentFiles(f.name)}
              <div className="pl-2">
                <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-12 line-height-normal">
                  {getFileName(f.name)}
                  {/* {f.name} */}
                </h2>
              </div>
            </div>
          </span>
        );
      })
    );
  };

  openMilestone = (data) => {
    this.props.OpenPopup("ReviewDeliverable");
    this.props.SetCurrentMileStoneID(data.milestone_complete_id, data.status);
  };

  openProposal = (data) => {
    const { proposal_id, post_project_id, status } = data;
    this.props.OpenPopup("ReviewProposal");
    this.props.SetCurrentProposal(proposal_id, post_project_id, status);
  };

  openMilestoneOrProposal = (data, event) => {
    event.preventDefault();
    if (data.sub_type === "milestone_feedback" && data.milestone_complete_id) {
      this.openMilestone(data);
    } else if (data.sub_type === "request_change") {
      this.openProposal(data);
    }
  };

  renderMessages = (props) => {
    const isRepresentative = props.CurrentChat.user_role === "representative";
    // {
    //   console.log("messageList = ", props?.Data);
    // }
    const { projectId } = props?.match?.params;
    switch (props.Data.type) {
      case "milestone_payment":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100 review-milestones px-3 py-2 mt-2 border-1 border-radius-8">
                  <div className="p-1">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <i className="fas fa-rupee-sign mr-1 download_invoice mob-font-12 "></i>
                      </div>
                      <div className="pl-2">
                        <span className="font-14 font-weight-600 text-dark-black">{props.Data.text}</span>
                        <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-14 line-height-14">
                          {props.Me.CurrentRole === 2 || isRepresentative ? props.Data.creator_text : props.Data.client_text}
                        </h2>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="main-btn font-12 font-weight-600 bg-extra-light text-dark-black mt-3 mob-w-100 cursor-pointer hidden"
                    >
                      Download invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "proposal_accepted":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="p-4 mt-2 border-1 border-radius-8 mb-2 mob-p-10">
                  <h2 className="font-weight-600 font-16 mb-1 text-capitalize">
                    {props.Data.text} {props.Me.FirstName} {props.Me.LastName}!{" "}
                    <span>
                      <img
                        src={require("../../../Assets/images/party.png")}
                        width="20"
                        className="img-fluid"
                        alt="Congratulations"
                      />
                    </span>
                  </h2>
                  <p className="mb-3 font-12 fw_600 text-dark-black">
                    {props.Me.CurrentRole === 2 || isRepresentative ? props.Data.creator_text : props.Data.client_text}
                  </p>
                  {props.Me.CurrentRole === 1 || props.Me.CurrentRole === 3 ? (
                    <div className="position-relative d-inline info_voice">
                      <span className="d-block">
                        <button
                          type="button"
                          className=" main-btn font-12 font-weight-600 bg-extra-light text-dark-black mob-w-100 cursor-pointer disabled"
                        >
                          {" "}
                          Download invoice
                        </button>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      case "final_project":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100">
                  <p className="mb-0 font-14 text-sub font-weight-500">shared the link to open files of the project</p>
                </div>
                <div className="w-100 review-milestones mt-2 border-1 border-radius-10">
                  <div className="profile-creator">
                    <div className="d-flex align-center-items py-3 px-4">
                      <div className="pt-15">
                        <span className="complete-check">
                          <i className="fas fa-check mr-2 font-12 text-white"></i>
                        </span>
                      </div>
                      <div className="pl-3">
                        <span className="text-white font-14 mob-font-14 font-weight-500">{props.Data.project_name}</span>
                        <h2 className="font-weight-500 m-0 font-16 mob-font-14 mob-only-text-left line-height-14">
                          Project completed
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 p-4">
                    <h2 className="font-weight-600 font-16 mb-0">
                      {props.Data.text}
                      <span>
                        <img
                          src={require("../../../Assets/images/party.png")}
                          width="20"
                          className="img-fluid"
                          alt="Congratulations"
                        />
                      </span>
                    </h2>
                    <p className="mb-0 font-12 fw_400 text-dark-black py-1">
                      {props.Me.CurrentRole === 2 || isRepresentative ? props.Data.creator_text : props.Data.client_text}
                    </p>

                    {/* {props.Me.CurrentRole === 1 || props.Me.CurrentRole === 3 || isRepresentative ? (
                      <a
                        target="_blank"
                        href={"http://" + removeHttps(props.Data.final_link)}
                        rel="noopener noreferrer"
                        className="text-break  font-weight-600 font-13 "
                      >
                        <button className="main-btn font-12 font-weight-600 bg-extra-light text-dark-black  mob-ml-0 mob-mt-8 mob-w-100 cursor-pointer mt-1 ">
                          {" "}
                          Link to Open File{" "}
                        </button>
                      </a>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "milestone_delivered":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <div className="company_profile mr-3">
                <div className="icon_profile_image rounded-circle">
                  <img src={NotedIcon} className="img-fluid" />
                </div>
              </div>

              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="px-0 user_massage">
                  <div className="small_title_commn px-0 mb-3">
                    <div className="row mx-0 px-3 align-items-end">
                      {/* meeting_link */}
                      <div className="col-sm-9 col-xl-9 meeting_link">
                        <p className="mb-0 font-12 fw_400">{props.Data.text}</p>
                        {/* <p className="mb-0 text_gray_1100_w_600_xsm">{props.Data.sub_text}</p> */}
                        <div
                          className="mb-0 text_gray_1100_w_600_xsm"
                          style={{ maxHeight: "70vh", overflowY: "auto" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitiseSummernoteText(props?.Data?.sub_text?.replace(/\n/g,'<br/>')),
                          }}
                        />
                      </div>

                      <div className="col-sm-3 col-xl-3 text-right">
                        <span
                          className="blue_text_md_w_600 font-12 fw_400 mb-0 cursor-pointer"
                          // onClick={(e) => {
                          //   props.history.push(
                          //     `/projects/${projectId}/overview`
                          //   );
                          // }}
                          onClick={(e) => {
                            // props.history.push(
                            //   `/projects/${projectId}/overview?id1=${props.Data.payment_reference_id}&id2=${props.Data.milestone_id}&id3=${props.Data.milestone_complete_id}`
                            // );
                            this.setState({
                              viewUploadDel: true,
                              payment_reference_id: props.Data.payment_reference_id,
                              milestone_id: props.Data.milestone_id,
                              milestone_complete_id: props.Data.milestone_complete_id,
                              proposal_id: props.Data.proposal_id,
                            });
                          }}
                        >
                          View Deliverable <img src={MeetingRightIcon} className="img-fluid" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="w-100">
                  <p className="mb-0 font-14 text-dark-black font-weight-600">
                    {props.Data.text} <br /> {props.Data.sub_text}
                  </p>
                </div> */}
                {/* <div
                  className="w-100 review-milestones p-2 mt-2 border-1 border-radius-8"
                  onClick={(e) => {
                    this.props.SetCurrentMileStoneID(props.Data.milestone_complete_id, props.Data.status);
                  }}
                >
                  <div className="dflex pt-2">
                    <div className="d-flex align-items-center">
                      <div className="pl-2">
                        <i className="far fa-calendar-alt text-success font-20"></i>
                      </div>
                      <div className="pl-3">
                        <span className="font-13 mob-font-12 font-weight-500 text-sub">
                   
                          {props.Data.project_name ? limitString(props.Data.project_name, 15) : props.Data.project_name}
                        </span>
                        <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-12 line-height-14">
                          {props.Me.CurrentRole === 2 || isRepresentative ? props.Data.creator_text : props.Data.client_text}
                        </h2>
                      </div>
                    </div>
                    {this.props.CurrentRole === 1 || props.Me.CurrentRole === 3 ? (
                      <div className="">
                        <button
                          type="button"
                          className="main-btn font-12 mob-font-11 mob-w-100 cursor-pointer mobile-hide"
                          data-toggle="modal"
                          data-target="#"
                          onClick={(e) => {
                    
                            this.props.SetCurrentMileStoneID(props.Data.milestone_complete_id, props.Data.status);
                          }}
                        >
                          Review deliverables
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {this.props.CurrentRole === 1 || props.Me.CurrentRole === 3 ? (
                    <button
                      type="button"
                      className="main-btn font-12 mob-font-11 mt-2 mob-w-100 cursor-pointer mobile-show"
                      data-toggle="modal"
                      data-target="#"
                      onClick={(e) => {
                        this.props.SetCurrentMileStoneID(props.Data.milestone_id, props.Data.status);
                      }}
                    >
                      Review deliverables
                    </button>
                  ) : (
                    <></>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        );
      case "cycle_delivered":
        // console.log("98888 === ", props?.mach?.params);
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100 mid-laptop-w-70">
            <div className="d-flex">
              <div className="company_profile mr-3">
                <div className="icon_profile_image rounded-circle">
                  <img src={NotedIcon} className="img-fluid" />
                </div>
              </div>

              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="px-0 user_massage">
                  <div className="small_title_commn px-0 mb-3">
                    <div className="row mx-0 px-3 align-items-end">
                      {/* meeting_link */}
                      <div className="col-sm-9 col-xl-9 meeting_link">
                        <p className="mb-0 font-12 fw_400">{props.Data.text}</p>
                        {/* <p className="mb-0 text_gray_1100_w_600_xsm">{props.Data.sub_text}</p> */}

                        <div
                          className="mb-0 text_gray_1100_w_600_xsm"
                          style={{ maxHeight: "70vh", overflowY: "auto" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitiseSummernoteText(props?.Data?.sub_text?.replace(/\n/g,'<br/>')),
                          }}
                        />
                      </div>

                      <div className="col-sm-3 col-xl-3 text-right">
                        <span
                          className="blue_text_md_w_600 font-12 mb-0 cursor-pointer"
                          // onClick={(e) => {
                          //   props.history.push(
                          //     `/projects/${projectId}/overview`
                          //   );
                          // }}
                          onClick={(e) => {
                            // props.history.push(
                            //   `/projects/${projectId}/overview?id1=${props.Data.payment_reference_id}&id2=${props.Data.milestone_id}&id3=${props.Data.milestone_complete_id}`
                            // );
                            this.setState({
                              viewUploadDel: true,
                              payment_reference_id: props.Data.payment_reference_id,
                              milestone_id: props.Data.milestone_id,
                              milestone_complete_id: props.Data.milestone_complete_id,
                              proposal_id: props.Data.proposal_id,
                            });
                          }}
                        >
                          View Worklog <img src={MeetingRightIcon} className="img-fluid" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "reply":
      case "Reply":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100">
                  <p className="mb-1 font-12 text-sub">
                    <span className="text-sub-2 font-weight-500">replied to: </span>
                    {/* <a href="#/" onClick={this.openMilestoneOrProposal.bind(this, props.Data)} className="font-weight-600">
                      {props.Data.text}
                    </a> */}
                    <a href="#/" onClick={() => {}} className="font-weight-600">
                      {props.Data.text}
                    </a>
                  </p>
                  <div className="mb-0 font-12 fw_500 text-dark-black">
                    <RenderHtml data={props.Data.sub_text} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "proposal_review":
      case "milestones_review":
        return (
          <>
            {/* <div className="col-sm-12 p-0 small-laptop-w-100">
              <div className="d-flex">
                <RenderAvatar props={props} />
                <div className="w-100 pl-2">
                  <RenderUser props={props} />
                  <div className="w-100">
                    <p className="mb-0 font-14 text-sub font-weight-500">{props.Data.text}</p>
                    {props.Data.note ? <div className="mb-0 font-weight-500 line-height-20"><RenderHtml data={props.Data.note} /></div> : null}
                  </div>
                  
                </div>
              </div>
            </div> */}
            <div className="col-sm-12 px-0">
              {props?.Data?.proposalData && <RenderMilestoneReview props={props} type={props.Data.type} />}
            </div>
          </>
        );
      case "StartProject":
        return (
          <div className="col-sm-5 p-0 mid-laptop-w-50 small-laptop-w-70 ipad-normal-col-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100 review-milestones py-2 px-3 mt-2 border-1 border-radius-8">
                  <div className="p-1">
                    <div className="d-flex align-items-center">
                      <div>
                        <span className="font-14 font-weight-600 text-dark-black">
                          Accept to kick start you project with {props.CurrentChat.first_name}.
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-3 mob-d-block">
                      <button
                        type="button"
                        className="main-btn font-12 font-weight-600 mob-w-100 cursor-pointer"
                        // data-toggle="modal"
                        // data-target="#creatorConfirmation"
                        onClick={(e) => {
                          this.props.OpenPopup("KickStartProject");
                        }}
                      >
                        Accept
                      </button>
                      <button
                        type="button"
                        className="main-btn font-12 font-weight-600 bg-extra-light text-dark-black ml-3 mob-ml-0 mob-mt-8 mob-w-100 cursor-pointer"
                        data-toggle="modal"
                        data-target="#finalMilestoneReview"
                      >
                        Edit quote and milestones
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "File":
        return (
          <div className="col-sm-12 p-0">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100">
                  <div className="mb-0 font-12 text-dark-black fw_400">
                    <RenderHtml data={props.Data.text} />
                  </div>
                </div>
                <div className="d-flex align-content-center flex-wrap">{this.renderFiles(props.Data.files)}</div>
              </div>
            </div>
          </div>
        );
      case "approved_updated_proposal_view":
        // console.log(props)
        return (
          <>
            <div className="col-sm-12 p-0 small-laptop-w-100">
              <div className="d-flex">
                <RenderAvatar props={props} />
                <div className="w-100 pl-2">
                  <RenderUser props={props} />
                  <div className="p-4 mt-2 border-1 border-radius-8 mb-2">
                    <p className="mb-3 font-12 fw_600 text-dark-black">{props.Data.text}</p>
                    <div className="position-relative d-inline info_voice">
                      <span className="d-block">
                        <button
                          type="button"
                          onClick={() => {
                            props.OpenPopup("ReviewProposal");
                            props.SetCurrentProposal(props.Data.proposal_id, props.Data.post_project_id, props.Data.status);
                          }}
                          className=" main-btn font-12 font-weight-600 bg-extra-light text-dark-black mob-w-100 cursor-pointer "
                        >
                          View Proposal
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "reminder_milestone":
        return props.Me.CurrentRole === 2 ? (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <div className="company_profile mr-3">
                <RenderAvatar props={props} />
              </div>
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="px-0  user_massage">
                  <div className="small_title_commn px-0 mb-3">
                    <div className="row mx-0 px-3 align-items-end">
                      {/* meeting_link */}
                      <div className="col-sm-9 col-xl-9 meeting_link">
                        <p className="mb-0 font_semi_bold_w_600">{props.Data.text}</p>
                        {/* <p className="mb-0 text_gray_1100_w_600_xsm">{props.Data.sub_text}</p> */}
                        <div
                          className="mb-0 text_gray_1100_w_600_xsm"
                          style={{ maxHeight: "70vh", overflowY: "auto" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitiseSummernoteText(props?.Data?.sub_text?.replace(/\n/g,'<br/>')),
                          }}
                        />
                      </div>

                      {/* <div className="col-sm-3 col-xl-3 text-right">
                        <span
                          className="blue_text_md_w_600 font-12 mb-0 cursor-pointer"
                          // onClick={(e) => {
                          //   props.history.push(
                          //     `/projects/${projectId}/overview`
                          //   );
                          // }}
                          onClick={(e) => {
                            // props.history.push(
                            //   `/projects/${projectId}/overview?id1=${props.Data.payment_reference_id}&id2=${props.Data.milestone_id}&id3=${props.Data.milestone_complete_id}`
                            // );
                            this.setState({
                              viewUploadDel: true,
                              payment_reference_id: props.Data.payment_reference_id,
                              milestone_id: props.Data.milestone_id,
                              milestone_complete_id: props.Data.milestone_complete_id,
                              proposal_id: props.Data.proposal_id,
                            });
                          }}
                        >
                          View Milestone <img src={MeetingRightIcon} className="img-fluid" />
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        );

      case "project_completion":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="p-4 mt-2 border-1 border-radius-8 mb-2">
                  <h2 className="font-weight-600 font-16 mb-1 text-capitalize">
                    {props.Data.text}{" "}
                    <span>
                      <img
                        src={require("../../../Assets/images/party.png")}
                        width="20"
                        className="img-fluid"
                        alt="Congratulations"
                      />
                    </span>
                  </h2>
                  <p className="mb-3 font-14 font-weight-600 text-dark-black">
                    {props.Me.CurrentRole === 2 || isRepresentative ? props.Data.creator_text : props.Data.client_text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      case "Question":
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100">
                  <div className="mb-0 font-12 fw_400 text-dark-black">
                    <p className="mb-0">Questions:</p>
                    {props?.Data?.messageArray.map((item, i) => {
                      return (
                        <div className="d-flex justify-content-start">
                          <span className="mr-1">{i + 1}.</span>
                          <p className="mb-0">{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="col-sm-12 p-0 small-laptop-w-100">
            <div className="d-flex">
              <RenderAvatar props={props} />
              <div className="w-100 pl-2">
                <RenderUser props={props} />
                <div className="w-100">
                  <div className="mb-0 font-12 fw_400 text-dark-black">
                    {/* {props.Data.text}  */}
                    <RenderHtml data={props.Data.text} />
                    {/* { console.log("props.Data _id",props.msgId,this.el)} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };
  render() {
    const isAdminAndInAdminChat = this.props.Me.isAdmin && this.props.location.pathname.includes("/adminChat");
    if (
      this.props.CurrentChat?.chat_connection_id === this.props?.Data?.chat_connection_id ||
      this.props?.UserToken?.includes(this.props?.Data?.sender_id) ||
      isAdminAndInAdminChat
    ) {
      // console.log(this.props)
      return (
        <>
          {this.renderMessages(this.props)}
          {this.state.viewUploadDel && (
            <ParentChatViewUp
              setViewUploadDel={this.setViewUploadDel}
              viewUploadDel={this.state.viewUploadDel}
              payment_reference_id={this.state.payment_reference_id}
              id_milestone={this.state.milestone_id}
              milestone_complete_id={this.state.milestone_complete_id}
              proposal_id={this.state.proposal_id}
              CurrentRole={this.props.CurrentRole}
              projectDataStates={this.props.projectDataStates}
              CurrentChat = {this.props.CurrentChat}
            />
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
}

const mapState = (store) => {
  let postProjectData = store.postProject;
  let chat = store.chat;
  let globalReducer = store.globalReducer;
  return {
    current_proposal_id: postProjectData.current_proposal_id,
    ProjectName: chat.ProjectName,
    ProjectType: chat.ProjectType,
    CurrentRole: globalReducer.Me.CurrentRole,
  };
};

const mapDispatch = (dispatch) => ({
  SetCurrentProposal: (proposal_id, project_id, reviewStatus) =>
    dispatch({ type: "SET_CURRENT_PROPOSAL", payload: { proposal_id, project_id, reviewStatus } }),
  SetCurrentMileStoneID: (milestone_id, milestone_status) =>
    dispatch({ type: "SET_CURRENT_MILESTONE_ID", payload: { milestone_id, milestone_status } }),
  OpenPopup: (text) => dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  setChatFiles: (filesData, currentFileIndex) => dispatch({ type: "SET_CHAT_FILES", values: { filesData, currentFileIndex } }),
});
export default withRouter(connect(mapState, mapDispatch)(MessageTemplate));

const RenderImage = ({ image }) => {
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    let mount = true;
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      if (mount) {
        setThumbnail(url);
      }
    };
    getThumbnailImageFunc(image);
    return () => {
      mount = false;
      // setThumbnail(null);
    };
    // projects.images_or_links[0].type === 'url' ? getThumbnailImage(projects.images_or_links[0].value) : projects.images_or_links[0].value
  }, [image]);

  return <div className="send_deliverable" style={{ backgroundImage: `url("${thumbnail}")` }}></div>;
};
