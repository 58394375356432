import {
  UPDATE_TITLE,
  UPDATE_COLLATERAL,
  UPDATE_ABOUTPROJECT,
  UPDATE_TOOLSUSED,
  UPDATE_STYLETAG,
  UPDATE_CREATEDFOR,
  UPDATE_COMPANY,
  UPDATE_STARTDATE,
  UPDATE_COLLABORATION,
  UPDATE_DESCRIBEROLE,
  UPDATE_KEYWORDS,
  UPDATE_COMPLETEDINDIEFOLIO,
  UPDATE_LISTCOLLATERAL,
  GET_LOAD_COLLATERAL,
  GET_SELECTED_COLLATERAL,
  // OPEN_POPUP,
  SUBMIT_PROJECT,
  UPDATE_PROJECT_IMAGE,
  UPDATE_MILESTONE_IMAGE,
  SELECT_COLLATERAL_ANSWER,
  SET_STYLE_TYPE_QUESTIONS,
  CLOSE_POPUP,
  SET_CROP_IMAGE,
  SET_CROP_IMAGE_LIST,
  UPDATE_IS_IMAGE_UPLOADING,
  SET_IMAGE_FOR_CROP,
  SET_IMAGES_OR_LINKS,
  SET_PREVIEW_IMAGE,
  RESET_USER_PROJECT_DATA,
  SET_USER_PROJECT_DATA,
  UPDATE_STYLE_AND_TYPE_QUESTIONS_IN_PROJECT,
  UPDATE_STYLE_QUESTION_WITH_ANSWER,
  UPDATE_TYPE_QUESTION_WITH_ANSWER,
} from "../type.action";

const initState = {
  listCollateral: [
  ],
  media: [],
  images_or_links:[
    { value: "", type: "", isThumbnail:0, id: 'image-0'},
    { value: "", type: "", isThumbnail:0, id: 'image-1' },
    { value: "", type: "", isThumbnail:0, id: 'image-2' },
    { value: "", type: "", isThumbnail:0, id: 'image-3' },
    { value: "", type: "", isThumbnail:0, id: 'image-4' },
    { value: "", type: "", isThumbnail:0, id: 'image-5' },
    { value: "", type: "", isThumbnail:0, id: 'image-6' },
    { value: "", type: "", isThumbnail:0, id: 'image-7' },
    { value: "", type: "" , isThumbnail:0, id: 'image-8'},
    { value: "", type: "", isThumbnail:0, id: 'image-9' }
  ],
  preview_image: { value: "", type: "", isThumbnail:0, id: 'image-0'},
  collateral: [],
  selectedCollateral: [],
  title: '',
  about: '',
  style: '',
  tools: '',
  createdfor: '',
  role: '',
  company: '',
  startdate: '',
  collaboration: '',
  keywords: [],
  wascompleted: false,
  project_submitted : false,
  edit: -1,
  _id: '',
  endMonth: '',
  endYear: '',
  userData: '',
  styleQuestions: [],
  typeQuestions: [],
  createdType: '',
  companyName: ''
};
// describerole: '',

const project = (state = initState, { type, values, error }) => {
  switch (type) {
    case UPDATE_COLLATERAL:
      return Object.assign({}, state, {
        collateral: values
      });

    case UPDATE_PROJECT_IMAGE:
      return {
        ...state,
        project_image: values.image,
        images_or_links: values.images_or_links,
      }
    case UPDATE_MILESTONE_IMAGE:
        return {
          ...state,
          milestone_description: values.milestone_description,
          milestone_image: values.images_or_links,
        }
    case UPDATE_IS_IMAGE_UPLOADING: 
      // console.log("Valuess------------>!!!!!!",values.isUploading);
      return {
        ...state,
        isImageUploading: values.isUploading,
      }
    case UPDATE_TITLE:
      return Object.assign({}, state, {
        title: values
      });
    case UPDATE_LISTCOLLATERAL:
      return {
        ...state,
        listCollateral: values
      };
    case UPDATE_ABOUTPROJECT:
      return {
        ...state,
        about: values
      };
    case UPDATE_TOOLSUSED:
      return {
        ...state,
        tools: values
      };
    case UPDATE_STYLETAG:
      return {
        ...state,
        style: values
      };
    case UPDATE_CREATEDFOR:
      return {
        ...state,
        createdfor: values
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        company: values
      };
    case UPDATE_COLLABORATION:
      return {
        ...state,
        collaboration: values
      };
    case UPDATE_STARTDATE:
      return {
        ...state,
        startdate: values
      };
    case UPDATE_DESCRIBEROLE:
      return {
        ...state,
        describerole: values
      };
    case UPDATE_KEYWORDS:
      return {
        ...state,
        keywords: values
      };
    case GET_LOAD_COLLATERAL :
      return {
        ...state,
        listCollateral: values
      };
    case UPDATE_COMPLETEDINDIEFOLIO:
      return {
        ...state,
        wascompleted: !state.wascompleted
      };
    case GET_SELECTED_COLLATERAL:
      return {
        ...state,
        selectedCollateral:values
      };
      case SUBMIT_PROJECT: 
        return {
          ...state,
          project_submitted: values.status,
          project_submitted_id: values.id,
        }
      case SELECT_COLLATERAL_ANSWER: 
        return {
          ...state,
          collateral_answers: values,
        }
      case SET_STYLE_TYPE_QUESTIONS: 
        return {
          ...state,
          styleTypeQuestions: values,
        }
      // case OPEN_POPUP:
      //     return {
      //       ...state,
      //       closeModal: true,
      //       Component: values,
      //   }
    
      case SET_CROP_IMAGE:
        return {
          ...state,
          cropImageSrc: values.image,
          croppedImagesArray: values.images_or_links,
          croppedImageIndex:values.index,
      }
      case SET_CROP_IMAGE_LIST: 
      return {
        ...state,
        croppedImagesArray: values.cropImageList,
      }
      case CLOSE_POPUP:
        return {
          ...state,
          closeModal: false,
          Component: '',
      };
     
      case SET_IMAGE_FOR_CROP:
        return {
          ...state,
          imageForCrop: {...values}
      };

      case SET_IMAGES_OR_LINKS:
        return {
          ...state,
          images_or_links: [...values]
      };

      case SET_PREVIEW_IMAGE:
        return {
          ...state,
          preview_image: {...values}
      };

      case RESET_USER_PROJECT_DATA:
        return {
          ...state,
          preview_image: { value: "", type: "", isThumbnail:0, id: 'image-0'},
          images_or_links: [
            { value: "", type: "", isThumbnail:0, id: 'image-0'},
            { value: "", type: "", isThumbnail:0, id: 'image-1' },
            { value: "", type: "", isThumbnail:0, id: 'image-2' },
            { value: "", type: "", isThumbnail:0, id: 'image-3' },
            { value: "", type: "", isThumbnail:0, id: 'image-4' },
            { value: "", type: "", isThumbnail:0, id: 'image-5' },
            { value: "", type: "", isThumbnail:0, id: 'image-6' },
            { value: "", type: "", isThumbnail:0, id: 'image-7' },
            { value: "", type: "" , isThumbnail:0, id: 'image-8'},
            { value: "", type: "", isThumbnail:0, id: 'image-9' }
          ],
          selectedCollateral: [],
          collateral: [],
          edit: -1,
          _id: '',
          about: '',
          title: '',
          collaboration: '',
          role: '',
          endMonth: '',
          endYear: '',
          keywords: '',
          tools: '',
          industry: '',
          languages: '',
          userData: '',
          styleQuestions: [],
          typeQuestions: [],
          createdType: '',
          companyName: '',
      };

      // case EDIT_USER_PROJECT_1:
      //   return {
      //     ...state,
      //     edit: 1,
      //     projectId: values
      //   }

      // case UPDATE_USER_PROJECT_DATA:
      //   return {
      //     ...state,
      //     images_or_links: values.project.images_or_links,
      //     preview_image: values.project.images_or_links[0],
      //   }

      case SET_USER_PROJECT_DATA:
        return {
          ...state,
          edit: 1,
          images_or_links: values.project.images_or_links,
          preview_image: values.project.images_or_links[0],
          _id:values.project._id,
          about: values.project.about,
          title: values.project.title,
          collaboration: values.project.collaboration,
          role: values.project.role,
          endMonth: values.project.end_month,
          endYear: values.project.end_year,
          keywords: values.project.keywords,
          tools: values.project.tools,
          industry: values.project.industry,
          languages: values.project.languages,
          userData: values.project_user_data,
          collateral: values.projectCollateral,
          selectedCollateral: values.projectCollateral,
          styleQuestions: values.questionStyle,
          typeQuestions: values.questionType,
          createdType: values.project.created_type,
          companyName: values.project.company_name,
          // dataReview:values.dataReview,
        }
      
        case UPDATE_STYLE_AND_TYPE_QUESTIONS_IN_PROJECT:
          return {
            ...state,
            styleQuestions: values.collateral_style_question,
            typeQuestions: values.collateral_type_question,
          }

        case UPDATE_TYPE_QUESTION_WITH_ANSWER:
          return {
            ...state,
            typeQuestions: [...values],
          }

        case UPDATE_STYLE_QUESTION_WITH_ANSWER:
          return {
            ...state,
            styleQuestions: [...values],
          }

    default:
      return state;
  }
};

export default project;
