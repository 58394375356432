//Post Project
export const ON_FIELD_CHANGE_POST_PROJECT = "ON_FIELD_CHANGE_POST_PROJECT";
export const UPDATE_MINIMUM_BUDGET       = "UPDATE_MINIMUM_BUDGET";
export const UPDATE_MAXIMUM_BUDGET       = "UPDATE_MAXIMUM_BUDGET";
export const BUDGET_CHANGED       = "BUDGET_CHANGED";
export const UPDATE_UNIT                 = "UPDATE_UNIT";
//export const UPDATE_MAXIMUM_UNIT         = "UPDATE_MAXIMUM_UNIT";


//Post Project Company Details
export const ON_FIELD_CHANGE_PROJECT_DETAILS = "ON_FIELD_CHANGE_PROJECT_DETAILS";
export const ADD_org_type         = "ADD_org_type";
export const ADD_org_name         = "ADD_org_name";
export const ADD_role_in_org      = "ADD_role_in_org";
export const ADD_org_website      = "ADD_org_website";
export const ADD_org_location     = "ADD_org_location";
export const ADD_is_org_location_from_list     = "ADD_is_org_location_from_list";
export const ADD_company_individual_location = "ADD_company_individual_location";
export const ADD_is_company_individual_location_from_list = "ADD_is_company_individual_location_from_list";
export const ADD_org_industry     = "ADD_org_industry";
export const ADD_is_org_industry_from_list     = "ADD_is_org_industry_from_list";
export const ADD_is_org_name_from_list     = "ADD_is_org_name_from_list";
export const ADD_org_employees    = "ADD_org_employees";
export const ADD_org_description  = "ADD_org_description"
export const ADD_project_name     = "ADD_project_name";
export const ADD_project_brief     = "ADD_project_brief";
export const ADD_project_reference     = "ADD_project_reference";
export const ADD_project_reference_text     = "ADD_project_reference_text";
export const ADD_project_reference_files = "ADD_project_reference_files";
export const CHOOSE_PROJECT_DETAIL_ANSWER = "CHOOSE_PROJECT_DETAIL_ANSWER";
export const SELECT_PROJECT_DETAIL_ANSWER        = "SELECT_PROJECT_DETAIL_ANSWER";
export const CHOOSE_PROJECT_DETAIL_PROJECT_ANSWER = "CHOOSE_PROJECT_DETAIL_PROJECT_ANSWER";
export const SELECT_PROJECT_DETAIL_PROJECT_ANSWER = "SELECT_PROJECT_DETAIL_PROJECT_ANSWER";

export const SET_PROJECT_QUESTION_WITH_ANSWER = "SET_PROJECT_QUESTION_WITH_ANSWER";
export const SET_COMPANY_QUESTION_WITH_ANSWER = "SET_COMPANY_QUESTION_WITH_ANSWER";
export const ADD_BUDGET_SCOPE = "ADD_BUDGET_SCOPE";
export const ADD_PROJECT_MONTH = "ADD_PROJECT_MONTH";
export const ADD_PROJECT_HOUR= "ADD_PROJECT_HOUR";


export const SET_SKIP_STYLE = "SET_SKIP_STYLE"
export const SET_SKIP_TYPE = "SET_SKIP_TYPE"
export const SET_PROJECT_NAME_ERROR = "SET_PROJECT_NAME_ERROR"
export const   SET_PROJECT_NAME = "SET_PROJECT_NAME"



//Load All Services 
export const GET_ALL_SERVICES  = "GET_ALL_SERVICES";
export const LOAD_ALL_SERVICES = "LOAD_ALL_SERVICES";


//Select service

export const  SELECT_SERVICE  = "SELECT_SERVICE";

export const  CHOOSE_SERVICE  = "CHOOSE_SERVICE";
export const  SELECTED_SERVICE = "SELECTED_SERVICE";
export const  SET_SELECTED_COLLATERAL = "SET_SELECTED_COLLATERAL";

//Choose Style

export const LOAD_COLLATERAL_QUESTIONS = "LOAD_COLLATERAL_QUESTIONS";
export const LOAD_COLLATERAL_QUESTIONS_GLOBAL = "LOAD_COLLATERAL_QUESTIONS_GLOBAL";
export const GET_QUESTIONS  = "GET_QUESTIONS";

export const CHOOSE_STYLE_ANSWER        = "CHOOSE_STYLE_ANSWER";
export const SELECT_STYLE_ANSWER        = "SELECT_STYLE_ANSWER";
export const SELECT_TYPE_ANSWER         = "SELECT_TYPE_ANSWER";
export const CHOOSE_TYPE_ANSWER         = "CHOOSE_TYPE_ANSWER";



//Select DeadLine
export const CHOOSE_DEADLINE = "CHOOSE_DEADLINE";
export const SELECT_DEADLINE = "SELECT_DEADLINE";
export const SELECT_SUBTYPE = "SELECT_SUBTYPE";


//Post project Steps

export const POST_PROJECT_STEPS = "POST_PROJECT_STEPS";
export const GET_PROJECT_STEPS  = "GET_PROJECT_STEPS";


//Profile Selection Page

export const LOAD_ARTISTS = "LOAD_ARTISTS";
export const GET_ARTISTS  = "GET_ARTISTS";

export const CHOOSE_ARTIST = "CHOOSE_ARTIST";
export const SELECT_ARTIST = "SELECT_ARTIST";


//Open Popups

export const OPEN_POST_PROJECT_POPUP = "OPEN_POST_PROJECT_POPUP";


//Close Popups

export const CLOSE_POST_PROJECT_POPUP = "CLOSE_POST_PROJECT_POPUP";
export const CLOSE_POPUP              = "CLOSE_POPUP";

//Get Post Project Data
export const LOAD_USER_COMPANY_DETAIL="LOAD_USER_COMPANY_DETAIL"
export const LOAD_POST_PROJECT_DATA = "LOAD_POST_PROJECT_DATA";
export const UPDATE_INVITE_MORE_STATUS_SAGA = "UPDATE_INVITE_MORE_STATUS_SAGA";
export const UPDATE_INVITE_MORE_STATUS = "UPDATE_INVITE_MORE_STATUS";
export const GET_POST_PROJECT_DATA  = "GET_POST_PROJECT_DATA";
export const UPDATE_POST_PROJECT_DATA = "UPDATE_POST_PROJECT_DATA";
export const UPDATE_COMPANY_DETAIL = "UPDATE_COMPANY_DETAIL";

export const GET_CREATOR_LIST         = "GET_CREATOR_LIST";
export const LOAD_CREATOR_LIST        = "LOAD_CREATOR_LIST";

export const RESET_PROJECT_DATA = "RESET_PROJECT_DATA";
export const UPDATE_RESET_PROJECT_DATA = "UPDATE_RESET_PROJECT_DATA";

export const RESET_ALL_DATA = "RESET_ALL_DATA";
export const UPDATE_RESET_ALL_DATA = "UPDATE_RESET_ALL_DATA";

export const RESET_SERVICE  = "RESET_SERVICE";
export const UPDATE_RESET_SERVICE = "UPDATE_RESET_SERVICE";


export const OPEN_SIGNUP_LOGIN = "OPEN_SIGNUP_LOGIN";
export const SCOPE_OF_WORK_COMPLETED = "SCOPE_OF_WORK_COMPLETED";

export const UPDATE_PORTFOLIO_PROJECTS = "UPDATE_PORTFOLIO_PROJECTS";
export const SET_PORTFOLIO_PROJECTS    = "SET_PORTFOLIO_PROJECTS";

export const LOAD_REVIEW_PROPOSAL_DATA = "LOAD_REVIEW_PROPOSAL_DATA";
export const LOAD_LATEST_PROPOSAL = "LOAD_LATEST_PROPOSAL";
export const LOAD_ADMIN_PROJECT_PROPOSAL = "LOAD_ADMIN_PROJECT_PROPOSAL";
export const GET_REVIEW_PROPOSAL_DATA  = "GET_REVIEW_PROPOSAL_DATA";
export const UPDATE_LOAD_PROPOSAL_DATA  = "UPDATE_LOAD_PROPOSAL_DATA";

export const SET_CURRENT_PROPOSAL      = "SET_CURRENT_PROPOSAL";
export const GET_CURRENT_PROPOSAL_ID   = "GET_CURRENT_PROPOSAL_ID";

export const LOAD_LATEST_MILESTONE      = "LOAD_LATEST_MILESTONE";
export const RESET_LATEST_MILESTONE_ID      = "RESET_LATEST_MILESTONE_ID";
export const UPDATE_LOAD_LATEST_MILESTONE_STATUS      = "UPDATE_LOAD_LATEST_MILESTONE_STATUS";

export const SET_VERSION_HISTORY_DATA      = "SET_VERSION_HISTORY_DATA";
export const UPDATE_VERSION_HISTORY      = "UPDATE_VERSION_HISTORY";

export const SET_COMPLETED_MILESTONE_DATA      = "SET_COMPLETED_MILESTONE_DATA";
export const UPDATE_COMPLETED_MILESTONE      = "UPDATE_COMPLETED_MILESTONE";
export const RESET_COMPLETED_MILESTONE_DATA      = "RESET_COMPLETED_MILESTONE_DATA";

export const SET_CURRENT_MILESTONE_ID = "SET_CURRENT_MILESTONE_ID";
export const GET_CURRENT_MILESTONE_ID = "GET_CURRENT_MILESTONE_ID";

export const LOAD_SOCKET = "LOAD_SOCKET";
export const UPDATE_RESET_SOCKET = "UPDATE_RESET_SOCKET";


export const UPDATE_HIRE_AGAIN_STATUS = "UPDATE_HIRE_AGAIN_STATUS";
export const GET_POST_PROJECT_CLIENT_BRIEF = "GET_POST_PROJECT_CLIENT_BRIEF";
export const GET_POST_PROJECT_CLIENT_BRIEF_PUBLIC = "GET_POST_PROJECT_CLIENT_BRIEF_PUBLIC";
export const GET_CREATOR_PROPOSAL_PROFILE = "GET_CREATOR_PROPOSAL_PROFILE";
export const ADD_FIXED_PROJECT_DEADLINE = "ADD_FIXED_PROJECT_DEADLINE";