import React, { useEffect, useState } from "react";
import { postAPI } from "../../../../../utils/API";
import { ReviewMoalBody } from "./ReviewMoalBody";

const arrClient = [
  { text: "I expected better quality" },
  { text: "The creator did not meet the deadlines" },
  { text: "The creator was slow in responding" },
  { text: "Other" },
];

const arrClientGoodRating = [
  { text: "The quality of the deliverable was as per expectations" },
  { text: "All deliverables were submitted on time" },
  { text: "The creator was very professional & responsive" },
  { text: "Other" },
];

export const FinalReviewPop = (props) => {
  const [checkboxList, setCheckboxList] = useState(arrClient);
  const [checkboxListPlatform, setCheckboxListPlatform] = useState([
    { text: "Very disappointed" },
    { text: "Somewhat disappointed" },
    { text: "Not disappointed" },
  ]);
  const [load, setLoad] = useState(false);

  const [showFeedback, setShowFeedback] = useState(false);
  const [data1, setData1] = useState("");

  const [reviewState, setReviewState] = useState({
    rating: 0,
    reason: [],
    description: "",
  });

  const submit = async () => {
    // setLoad(true);
    const { projectId } = props?.match?.params;

    const obj = {
      post_project_id: projectId,
      rating: data1.rating,
      feedback: data1.reason,
      type: props.CurrentRole === 2 ? "client_feedback" : "creator_feedback",
      description: data1.description,
      user_type: props.CurrentRole === 1 ? "client" : "creator",
    };

    const companyObj = {
      post_project_id: projectId,
      rating: reviewState.rating,
      feedback: reviewState.reason,
      type: "platform_feedback",
      description: reviewState.description,
      user_type: props.CurrentRole === 1 ? "client" : "creator",
    };

    // console.log("------ ",obj,companyObj)

    try {
      const resObj = await postAPI("/project/add-post-project-rating", obj);
      const resCompany = await postAPI("/project/add-post-project-rating", companyObj);
      if (resObj && resCompany) {
        props.setShowFinalReview(false);
        props.callApiAgain();
      } else {
        alert("Something went wrong, Please try again later.");
        setLoad(false);
      }
    } catch (err) {
      alert("Something went wrong, Please try again later.");
      setLoad(false);
    }
  };

  const handleSubmit1 = (data) => {
    setData1(data);
    setTimeout(() => {
      setReviewState({ rating: 0, reason: [], description: "" });
      setShowFeedback(true);
    }, 100);
  };

  const handleSubmit2 = (data) => {
    submit();
  };

  return (
    <>
      <section className="finalreviewrapper ">
        <div
          className={`modal fade modal_fade_bg ${props.showFinalReview ? "show" : ""}`}
          style={{ display: props.showFinalReview ? "block" : "" }}
          id="finalreviewpopup"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_md m-auto">
            {showFeedback ? (
              <ReviewMoalBody
                checkboxData={checkboxListPlatform}
                {...props}
                setShowFeedback={setShowFeedback}
                showFeedback={showFeedback}
                handleSubmit={handleSubmit2}
                reviewState={reviewState}
                setReviewState={setReviewState}
                arrClient={arrClient}
                arrClientGoodRating={arrClientGoodRating}
                setCheckboxList={setCheckboxList}
              />
            ) : (
              <ReviewMoalBody
                handleSubmit={handleSubmit1}
                setShowFeedback={setShowFeedback}
                setCheckboxList={setCheckboxList}
                checkboxData={checkboxList}
                showFeedback={showFeedback}
                reviewState={reviewState}
                setReviewState={setReviewState}
                arrClient={arrClient}
                arrClientGoodRating={arrClientGoodRating}
                {...props}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
