import { GET_STATUS, GET_STATUS_SUCCESS } from "../type.action";

export const getStatus = values => ({
  type: GET_STATUS,
  values,
});

export const getStatusSuccess = values => ({
    type: GET_STATUS_SUCCESS,
    values,
}
);

