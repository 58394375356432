import {
    UPDATE_ADMIN_CHAT_USERS,
    UPDATE_CHANGE_ADMIN_CHAT,
    UPDATE_CHANGE_ADMIN_CHATMESSAGE,
    UPDATE_SEND_ADMIN_MESSAGE,
    GET_ADMIN_PROJECT_CHAT,
    UPDATE_ARTIST_AND_CLIENT,
  } from "../type.action";
  
  const initState = {
    Projects: [],
    About: '',
    Artist: [],
    Client: null, 
    Project: '',
    UserToken: '',
    ProjectToken: '',
    UserId: '',
    ProjectId: '',
    CurrentChat: {},
    Messages: [
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'Plain',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'Files',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'Milestone',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'Reply',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'ReviewDeliverables',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'DownloadInvoice',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'ProjectComplete',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'StartProject',
        body: {}
      },
      {
        text: 'The quote and milestones seem way off from what we originally discussed. I request you to please review them again and revert back at the earliest?',
        type: 'ProjectStarted',
        body: {}
      },
      {
        text: 'Uploaded files',
        type: 'File',
        files: [
          { name: '1593113513295download.png' },
          { name: '1593113513295download.png' },
          { name: 'hello.pdf' },
          { name: 'hello.doc' },
          { name: 'hello.docx' },
        ]
      }
    ],
  };
  
  const reducers = (state = initState, { type, values, error }) => {
    let newState = {};
    let obj = [];
  
    switch (type) {
      case UPDATE_ADMIN_CHAT_USERS:
        newState = Object.assign({}, state, values);
        return newState;

      case UPDATE_CHANGE_ADMIN_CHAT:
        // console.log(`%c UserToken Changed ${values.UserToken}`, 'font-size: 22px; color:orange');
        newState = Object.assign({}, state, values);
        return newState;

      case UPDATE_CHANGE_ADMIN_CHATMESSAGE:
        newState = Object.assign({}, state, {
          Messages: values
        });
        return newState;

      case UPDATE_SEND_ADMIN_MESSAGE:
        obj = Object.assign([], state.Messages);
        obj.push(values)
        // console.log('%c ADMIN MSG', 'font-size: 22px; color:orange');
        // console.log(obj);
        
        newState = Object.assign({}, state, {
          Messages: obj
        });
        return newState;

      case GET_ADMIN_PROJECT_CHAT:
          // console.log('%c GET_ADMIN_PROJECT_CHAT', 'font-size: 22px; color:orange');
          // console.log(values);     
        return {
          ...state,
          Projects:values,
        }

      case UPDATE_ARTIST_AND_CLIENT:
          // console.log('%c UPDATE_ARTIST_AND_CLIENT', 'font-size: 22px; color:orange');
          // console.log(values);
        return {
          ...state,
          Artist: values.Artist,
          Client: values.Client,
          ProjectName: values.ProjectName,
          Messages: [],
          UserToken: '',
          ProjectId: '',
          CurrentChat: {},
        }
        
      default:
        return state;
    }
  };
  
  export default reducers;