import { all, put, call, select } from 'redux-saga/effects';
import { fireApi } from '../../../utils/fireApiPrecheck';
import ValidateAction from '../../../utils/fieldValidation';
import { projectData, creatorProfile, globalReducer } from './selectors/index.select';
import { push } from 'connected-react-router'

export function* closePopupCreator(data) {
  try {
    yield put({ type: 'CLOSE_POPUP', values: '' });
    yield put({ type: 'UPDATE_UPLOADING', values: false });
    yield put({ type: 'UPDATE_SAVING', values: false });
  } catch (error) {
    console.log(error);
  }
}

export function* openPopupCreator(data) {
  let values = data.payload;
  try {
    yield put({ type: 'OPEN_POPUP', values: values.Component });
  } catch (error) {
    console.log(error);
  }
}


export function* loadCreatorDataPublic(values) {
  try {
    yield put({ type: 'UPDATE_CREATOR_DATA_FETCHING', values: true });

    let userName = values.payload.user_name;
    let AllProjects = yield fireApi('GetUserAllProjectsPublic', {username: userName})
    let UserData = yield fireApi('GetUserDataPublic', {username:userName});
    // console.log("User_data====>", UserData);
    let Software = yield fireApi('GetUserSoftwareSpecializationPublic',{username:userName});
    let Education = yield fireApi('GetUserEducationPublic',{username:userName});
    let UserWork = yield fireApi('GetUserWorkPublic',{username:userName});


    let UserProjects = yield fireApi('GetUserProjectsPublic',{username:userName});
    let UserReviews = yield fireApi('GetUserReviewsPublic',{username:userName});

    UserData = UserData.data;
    // check is missing.
    // let Collateral = yield fireApi('GetUserCollateral');
    yield call(fetchCollateralApproved,userName);
    yield call(loadSpecialization);
    // yield call(loadUserIndustries)

    yield put({
      type: 'UPDATE_CREATOR_PROJECT',
      values: UserProjects.data,
    });

    yield put({ type: 'UPDATE_CREATOR_REVIEWS', values: UserReviews });

    let WebLinks = yield fireApi('GetWebLinksPublic',{username:userName});
    // console.log('WebLinks load ', WebLinks);
    yield put({
      type: 'LOAD_SOCIALPROFILE',
      values: WebLinks.data,
    });

    // yield put({ type: "LOAD_CURRENTWORKSTATUS", values: UserWork.current_work_status })

    yield put({
      type: 'LOAD_CURRENTWORKSTATUS',
      values: {
        CurrentWorkStatus: UserWork.current_work_status,
        Experience: UserWork.data,
      },
    });

    yield put({
      type: 'UPDATE_SPECIALIZATION',
      values: Software.specialization.specialization,
    });
    yield put({
      type: 'UPDATE_SPECIALIZATION_UPDATE',
      values: Software.specialization.specialization,
    });

    yield put({ type: 'UPDATE_EDUCATION', values: Education.data });

    yield put({ type: 'UPDATE_SOFTWARE', values: Software.software.software });
    yield put({
      type: 'UPDATE_SOFTWARE_UPDATE',
      values: Software.software.software,
    });
    // yield put({ type: "UPDATE_COLLATERAL", values: Collateral.data })
    // User Details
    yield put({
      type: 'UPDATE_CREATOR',
      values: {
        UserDataPublic:UserData.user_id,
        Username:UserData.user_id.username,
        CreatorId: UserData.user_id._id,
        FirstName: UserData.user_id.first_name,
        LastName: UserData.user_id.last_name,
        isAvailable: UserData.user_id.available,
        availableDate: UserData.user_id.available_date,
        Avatar: UserData.user_id.profile_image,
        About: UserData.user_id.bio,
        Location: UserData.user_id.location,
        PrimaryFieldWork: UserData.primary_specialization,
        ExperienceYear: UserData.user_id.total_experience,
        Languages: UserData.user_id.languages,
        UpdateLanguages:
          UserData.user_id.languages !== '' && UserData.user_id.languages !== null
            ? UserData.user_id.languages
            : [],
        Awards: UserData.user_id.awards,
        UpdateAwards:
          UserData.user_id.awards !== '' && UserData.user_id.awards !== null
            ? UserData.user_id.awards
            : [],
        PrimaryFieldWorkExperience:Software.specialization.primary_specialization_experience,
      },
    });

    yield put({type: 'UPDATE_PROFILE_LOADED', values: true})
    // console.log('public')

    yield put({
      type: 'UPDATE_CREATORBASIC',
      values: {
        UserDataPublic:UserData.user_id,
        Username:UserData.user_id.username,
        FirstName: UserData.user_id.first_name,
        LastName: UserData.user_id.last_name,
        Avatar: UserData.user_id.profile_image,
        About: UserData.user_id.bio,
        Location: UserData.user_id.location,
        PrimaryFieldWork: UserData.primary_specialization,
      },
    });

    yield put({ type: 'LOAD_ALL_PROJECTS', values: AllProjects.project});

    yield all([
      call(loadAwardsPublic,userName),
      call(loadCreatorClientsPublic,userName),
    ]);

    // const creatorProfileData = yield select(creatorProfile);
    // const firstCollateral    = creatorProfileData.CollateralListing.filter(item => item.status === 1);            
    // let value = firstCollateral?.[0]?.collateral_id;

    yield put({ type: 'UPDATE_CREATOR_DATA_FETCHING', values: false });

    // let Projects = yield fireApi('GetCollateralBasedProjectsPublic', {username:userName,collateral_id:value});
    // yield put({ type: "GET_DEFAULT_COLLATERAL_BASED_PROJECTS", values: {Projects:Projects,collateralId:value}});

    
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.status);
    if(error.response.status){
      yield put(push('/notFound'))
    }

  }
}


export function* loadAllProjects(requsername){
  try{
    const creatorProfileData = yield select(creatorProfile);

    let AllProjects = []
    if(creatorProfileData.public){
      // console.log(requsername,'requsername')
      let userName = creatorProfileData?.BasicDetails?.Username;
      AllProjects = yield fireApi('GetUserAllProjectsPublic', {username: userName || requsername.values})
    } else {
      AllProjects = yield fireApi('GetUserAllProjects')
    }
    yield put({ type: 'LOAD_ALL_PROJECTS', values: AllProjects.project});


  } catch(error){
    console.log(error)
  }
}

export function* loadCreatorData() {
  try {
    // console.log('private')
    yield put({ type: 'UPDATE_CREATOR_DATA_FETCHING', values: true });
    yield put({ type: 'UPDATE_CREATOR_PUBLIC', values: false });

    let [UserData, Software, Education, UserWork] = yield all([
      fireApi('GetUserData'),
      fireApi('GetUserSoftwareSpecialization'),
      fireApi('GetUserEducation'),
      fireApi('GetUserWork'),
      // fireApi('GetUserAllProjects'),

    ]);
    UserData = UserData.data;
    yield all([
      put({
        type: 'UPDATE_CREATOR',
        values: {
          FirstName: UserData?.user_id.first_name,
          LastName: UserData?.user_id.last_name,
          // isAvailable: UserData?.user_id.available,
          Avatar: UserData?.user_id.profile_image,
          About: UserData?.user_id.bio,
          Location: UserData?.user_id.location,
          PrimaryFieldWork: UserData?.primary_specialization,
          ExperienceYear: UserData?.user_id.total_experience,
          Languages: UserData?.user_id.languages,
          UpdateLanguages:
            UserData?.user_id.languages !== '' &&
            UserData?.user_id.languages !== null
              ? UserData?.user_id.languages
              : [],
          Awards: UserData?.user_id.awards,
          UpdateAwards:
            UserData?.user_id.awards !== '' && UserData?.user_id.awards !== null
              ? UserData?.user_id.awards
              : [],
          primary_specialization_exp:Software.specialization.primary_specialization_experience,
        },
      }),
      put({
        type: 'UPDATE_CREATORBASIC',
        values: {
          FirstName: UserData?.user_id.first_name,
          LastName: UserData?.user_id.last_name,
          Avatar: UserData?.user_id.profile_image,
          About: UserData?.user_id.bio,
          Location: UserData?.user_id.location,
          PrimaryFieldWork: UserData?.primary_specialization,
        },
      }),
      put({ type: 'UPDATE_SOFTWARE', values: Software?.software?.software }),
      put({
        type: 'UPDATE_SOFTWARE_UPDATE',
        values: Software?.software?.software,
      }),
      put({
        type: 'UPDATE_SPECIALIZATION',
        values: Software?.specialization?.specialization,
      }),
      put({
        type: 'UPDATE_SPECIALIZATION_UPDATE',
        values: Software?.specialization?.specialization,
      }),
      put({
        type:'UPDATE_PRIMARYSPECIALIZATION_EXP',
        values:Software.specialization.primary_specialization_experience,
      }),
      put({ type: 'UPDATE_EDUCATION', values: Education.data }),
      put({
        type: 'LOAD_CURRENTWORKSTATUS',
        values: UserWork.current_work_status,
      }),
      put({
        type: 'LOAD_CURRENTWORKSTATUS',
        values: {
          CurrentWorkStatus: UserWork.current_work_status,
          Experience: UserWork.data,
        },
      }),
      // put({
      //   type: 'LOAD_ALL_PROJECTS',
      //   values: AllProjects.project,
      // }),
    ]);

    let UserProjects = yield fireApi('GetUserProjects');
    let UserReviews = yield fireApi('GetUserReviews');
    
    yield all([
      call(fetchCollateral),
      call(loadSpecialization),
      call(loadAllCollateral),
      call(loadSoftware),
      call(loadLocation),
      call(loadSocial),
      call(loadAwards),
      call(loadCreatorClients),
      call(loadAllProjects),
      call(loadIndustry),
      call(loadUserIndustries),
    ]);
    yield put({ type: 'UPDATE_CREATOR_PROJECT', values: UserProjects.data });
    yield put({ type: 'UPDATE_CREATOR_REVIEWS', values: UserReviews });

    // const creatorProfileData = yield select(creatorProfile);
    // const firstCollateral    = creatorProfileData.CollateralListing.filter(item => item.status === 1);            
    // let value = firstCollateral?.[0]?.collateral_id;

    yield put({ type: 'UPDATE_CREATOR_DATA_FETCHING', values: false });

    // let Projects = yield fireApi('GetCollateralBasedProjects', {id: value});
    // yield put({ type: "GET_DEFAULT_COLLATERAL_BASED_PROJECTS", values: {Projects:Projects,collateralId:value}});
  } catch (error) {
    console.log(error);
    yield put({ type: 'UPDATE_CREATOR_DATA_FETCHING', values: false });
  }
}

export function* uploadUserImage(data) {
  let values = data.payload;
  try {
    if (values.file.target.files[0]) {
      const formData = new FormData();
      yield put({ type: 'UPDATE_UPLOADING', values: true });
      formData.append('userImages', values.file.target.files[0]);
      let UploadImage = yield fireApi('UploadUserImage', formData);
      let image = UploadImage.name;
      yield put({ type: 'UPDATE_UPLOADING', values: false });
      yield put({ type: 'UPDATE_AVATAR', values: image });
      yield put({ type: 'UPDATE_BASIC_AVATAR', values: image });
      yield put({ type: 'UPDATE_PROFILE_IMAGE', values: image });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadResume(data) {
  let values = data.payload;
  try {
    if (values.file.target.files[0]) {
      yield put({ type: 'UPDATE_UPLOADING', values: true });
      const formData = new FormData();
      formData.append('userResume', values.file.target.files[0]);
      let UploadResume = yield fireApi('UploadUserResume', formData);
      let Resume = UploadResume.name;
      yield put({ type: 'UPDATE_SOCIALPROFILE_RESUME', values: Resume });
      yield put({ type: 'UPDATE_UPLOADING', values: false });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateUser(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let Basic = yield fireApi('UpdateUserBasic', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (Basic.error && Basic.error === true) {
      yield put({ type: 'UPDATE_BASIC_ERROR', values: Basic });
      // console.log('error');
    } else {
      // console.log('no error');
      
      yield all([
        put({
          type: 'UPDATE_CREATOR',
          values: {
            FirstName: values.first_name,
            LastName: values.last_name,
            Avatar: values.profile_image,
            About: values.bio,
            Location: values.location,
            PrimaryFieldWork: values.primary_specialization,
          },
        }),
        put({
          type: 'UPDATE_CREATORBASIC',
          values: {
            FirstName: values.first_name,
            LastName: values.last_name,
            Avatar: values.profile_image,
            About: values.bio,
            Location: values.location,
            PrimaryFieldWork: values.primary_specialization,
          },
        }),
        put({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
      ]);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* onSTATEUPDATE(data) {
  let payload = data.payload;
  console.log(payload)
  try {
    yield put({ type: payload.name, values: payload.value });
    // payload.value.project_rating_status && payload.value.post_project_id && payload.value.current_set_role === 1
//     if(payload.value.project_rating_status === 0  && payload.value.post_project_id){
//       let completedProject = yield fireApi('PostProjectDetailsForReview', { id: payload.value.post_project_id } )
      
//       if(completedProject.status === '1'){
//         delete completedProject.status;
//         delete completedProject.message;
//         yield put({type: 'UPDATE_POST_PROJECT_DETAILS_FOR_REVIEW', values: completedProject})
//       }
//       if (payload.value.current_set_role === 1){
//         yield put({ type: 'OPEN_POPUP_SAGA', payload: { Component: 'CreatorAndCompanyFeedback' } })
//       } else {
//         yield put({ type: 'OPEN_POPUP_SAGA', payload: { Component: 'ClientAndCompanyFeedback' } })
//       }
// }
if(payload.value?.current_set_role === 2&& payload.value?.review_status==2){
  if((!payload.value.time_base_amount) && (!payload.value.time_base_date)){
    yield put({ type: 'OPEN_POPUP_SAGA', payload: { Component: 'TimeBasedPricing' } })
  }else if(!payload.value.time_base_amount&&payload.value.time_base_date){
    const date1=new Date(payload.value.time_base_date);
    const date2=new Date();
      if(date1.getTime()<date2.getTime()){
        yield put({ type: 'OPEN_POPUP_SAGA', payload: { Component: 'TimeBasedPricing' } })
      }
  }
}
  } catch (error) {
    console.log(error);
  }
}

export function* updateMessageNotificationCount({payload}){
  try{
    yield put({type: 'UPDATE_MESSAGE_NOTIFICATION_COUNT', values: payload})
  } catch(error){
    console.log(error)
  }
}

export function* resetNotification({payload}){
  try{
    const globalReducerData = yield select(globalReducer)
    const data = yield fireApi('ResetNotification', {id: payload, user_type: globalReducerData.Me.CurrentRole === 1 ? 'client' : 'creator' })
    if(parseInt(data.status) === 1){
      yield put({type: 'UPDATE_MESSAGE_NOTIFICATION_COUNT', values: payload})
    }
  } catch(error){
    console.log(error)
  }
}


export function* clearMessageNotification(){
  try{
    const globalReducerData = yield select(globalReducer)
    const res = yield fireApi('ClearMessageNotification', {type: globalReducerData.Me.CurrentRole === 1 ? 'client' : 'creator' })
    if(parseInt(res.status) === 1){
      yield put({type: 'UPDATE_MESSAGE_NOTIFICATION_COUNT', values: 0})
    }
  } catch(error){
    console.log(error)
  }
}


export function* updateDashboardNotificationCount({payload}){
  try{
    yield put({type: 'UPDATE_DASHBOARD_NOTIFICATION_COUNT', values: payload})
  } catch(error){
    console.log(error)
  }
}

export function* updateCompletedProjectNotificationCount({payload}){
  try{
    yield put({type: 'UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT', values: payload})
  } catch(error){
    console.log(error)
  }
}

export function* clearDashboardNotification(){
  try{
    const globalReducerData = yield select(globalReducer)
    const user = globalReducerData.Me.CurrentRole === 1 ? 'client' : 'creator'
    const res = yield fireApi('ClearDashboardNotification', {user_type: user })
    // console.log(res.data[`${user}_dashboard`])
    if(parseInt(res.status) === 1){
      yield put({type: 'UPDATE_DASHBOARD_NOTIFICATION_COUNT', values: res.data[`${user}_dashboard`]})
    }
  } catch(error){
    console.log(error)
  }
}

export function* clearCompletedProjectNotification(){
  try{
    const globalReducerData = yield select(globalReducer)
    const user = globalReducerData.Me.CurrentRole === 1 ? 'client' : 'creator'
    const res = yield fireApi('ClearCompletedProjectNotification', {user_type: user })
    // console.log(res.data[`${user}_dashboard`])
    if(parseInt(res.status) === 1){
      yield put({type: 'UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT', values: res.data[`${user}_dashboard_completed`]})
    }
  } catch(error){
    console.log(error)
  }
}

export function* onFieldChangeCreator(data) {
  let values = data.payload;
  try {
    let r = false;
    let onBlur = values.onblur;
    if (!onBlur || onBlur === false) {
      r = ValidateAction(values.name, values.value);
    }
    // console.log('%c Name', 'font-size: 22px; color:orange');
    // console.log(values.name);
    if (r === false) {
      yield put({ type: 'UPDATE_' + values.name, values: values.value });
      yield put({
        type: 'UPDATE_' + values.name + '_ERROR',
        values: {
          error: false,
          message: '',
        },
      });
    }

    if (r === true) {
      yield put({
        type: 'UPDATE_' + values.name + '_ERROR',
        values: {
          error: true,
          message: '',
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* callAction(data) {
  let values = data.payload;
  try {
    yield put({ type: values.name, values: values.value });
  } catch (error) {
    console.log(error);
  }
}

function* fetchCollateralApproved(userName) {
  let Collateral2 = yield fireApi('GetUserCollateral2Public',{username:userName});
  yield put({ type: 'UPDATE_COLLATERAL', values: Collateral2.data });
}

function* fetchCollateral() {
  let Collateral2 = yield fireApi('GetUserCollateral');
  yield put({ type: 'UPDATE_COLLATERAL', values: Collateral2.data });
}

export function* loadSpecialization(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let Specialization = yield fireApi('GetSpecialization', { name: value });
    yield put({ type: 'LOAD_SPECIALIZATION', values: Specialization.data });
  } catch (error) {
    console.log(error);
  }
}

export function* loadLocation(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let Locations = yield fireApi('GetLocation', { name: value });
    yield put({ type: 'LOAD_LOCATIONS', values: Locations.data });
  } catch (error) {
    console.log(error);
  }
}

export function* loadSoftware(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let AllSoftware = yield fireApi('GetSoftware', { name: value });
    yield put({ type: 'LOAD_CREATOR_ALLTOOLS', values: AllSoftware.data });
  } catch (error) {
    console.log(error);
  }
}

export function* loadSocial() {
  try {
    let WebLinks = yield fireApi('GetWebLinks');
    // console.log('WebLinks in creatorsaga ', WebLinks);
    const withResume = { ...WebLinks.data, resume: WebLinks.resume };
    yield put({ type: 'LOAD_SOCIALPROFILE', values: withResume });
    yield put({ type: 'LOAD_SOCIALPROFILEPOPUP', values: withResume });
  } catch (error) {
    console.log(error);
  }
}

export function* loadIndustry(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let Industry = yield fireApi('GetIndustry', { name: value });
    yield put({ type: 'UPDATE_USERWORK_ALLINDUSTRY', values: Industry.data });
  } catch (error) {
    console.log(error);
  }
}

export function* loadCompany(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let Industry = yield fireApi('GetCompany', { name: value });
    yield put({ type: 'UPDATE_USERWORK_ALLCOMPANY', values: Industry.data });
  } catch (error) {
    console.log(error);
  }
}

export function* loadUserIndustries(value){
  try{
    value = value && value.payload ? value.payload :'';
    let UserIndustries = yield fireApi('GetUserIndustries',{});
    yield put({ type: 'UPDATE_CREATOR_INDUSTRIES', values: UserIndustries.data });
  }catch(err){
    console.log(err)
  }
}

export function* loadCreatorClients() {
  try {
    let creatorClients = yield fireApi('GetCreatorClients');
    yield put({
      type: 'UPDATE_CREATOR_CLIENTS',
      values: creatorClients.data,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* loadCreatorClientsPublic(userName) {
  try {
    let creatorClients = yield fireApi('GetCreatorClientsPublic',{username:userName});
    yield put({
      type: 'UPDATE_CREATOR_CLIENTS',
      values: creatorClients.data,
    });
  } catch (error) {
    console.log(error);
  }
}


export function* loadAllCollateral(value) {
  try {
    value = value && value.payload ? value.payload : '';
    let Industry = yield fireApi('GetCollaterals', { name: value });
    const project = yield select(projectData);
    let userCollateral = project.listCollateral;
    let myNewArray = Industry.data.filter(
      ({_id}) =>
        !userCollateral.some(
          ({ collateral_id }) => _id === collateral_id
        )
    );
    yield put({ type: 'LOAD_ADDCOLLATERALS', values: myNewArray });
    // console.log('collateral list ', myNewArray);
  } catch (error) {
    console.log(error);
  }
}

export function* loadCollateralDetail(value){
  try{
    value = value && value.payload? value.payload:'';
    let colateralDetail = yield fireApi('CollateralDetail',{_id:value});
    yield put({ type: 'LOAD_COLLATERALSDATA', values: colateralDetail });
    //  console.log('collateral data ', colateralDetail);
  }catch (error){
    console.log(error)
  }
}
export function* loadAwards(value) {
  try {
    let Awards = yield fireApi('GetAwards');
    yield put({ type: 'LOAD_AWARDS', values: Awards.data });
    // console.log('awards ', Awards);
  } catch (error) {
    console.log(error);
  }
}

export function* loadAwardsPublic(userName) {
  try {
    let Awards = yield fireApi('GetAwardsPublic',{username:userName});
    yield put({ type: 'LOAD_AWARDS', values: Awards.data });
    // console.log('awards ', Awards);
  } catch (error) {
    console.log(error);
  }
}

export function* onWorkStatusChange(data) {
  let values = data.payload;
  try {
    yield fireApi('UpdateCurrentWorkStatus', {
      current_work_status: values.value,
    });
    yield put({ type: 'UPDATE_CURRENTWORKSTATUS', values: values.value });
  } catch (error) {
    console.log(error);
  }
}

export function* updateWebLink(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let r = yield fireApi('UpdateWebLinks', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (r.error && r.error === true) {
      yield put({ type: 'LOAD_SOCIALPROFILEPOPUP', values: r });
    } else {
      if (r.status && parseInt(r.status) === 1) {
        yield put({ type: 'LOAD_SOCIALPROFILE', values: values });
        yield put({ type: 'LOAD_SOCIALPROFILEPOPUP', values: values });
        yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* UpdateUserSoftware(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let r = yield fireApi('UpdateUserSoftware', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (r.error && r.error === true) {
      yield put({
        type: 'UPDATE_CREATOR',
        values: {
          UpdateToolsError: r.UpdateToolsError,
        },
      });
    } else {
      if (parseInt(r.status) === 1) {
        yield put({ type: 'UPDATE_SOFTWARE', values: r.data.software });
        yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* UpdateUserSpecialization(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let r = yield fireApi('UpdateUserSpecialization', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (r.error && r.error === true) {
      yield put({
        type: 'UPDATE_BASIC_PRIMARYFIELDWORK_ERROR',
        values: {
          error: r.PrimarySpecializationError,
        },
      });
      yield put({
        type: 'UPDATE_CREATOR',
        values: {
          UpdateSpecializationError: r.UpdateSpecializationError,
        },
      });
    } else {
      if (parseInt(r.status) === 1) {
        yield put({
          type: 'UPDATE_BASIC_PRIMARYFIELDWORK_ERROR',
          values: { error: false },
        });
        yield put({
          type: 'UPDATE_SPECIALIZATION',
          values: r.data.specialization,
        });
        yield put({
          type: 'UPDATE_BASIC_PRIMARYFIELDWORK',
          values: r.data.primary_specialization,
        });
        yield put({
          type: 'UPDATE_CREATOR',
          values: {
            PrimaryFieldWork: r.data.primary_specialization,
            UpdateSpecializationError: false,
          },
        });
        yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* UpdateUserLanguage(data) {
  let values = data.payload;
  try {
    yield put({ type: 'UPDATE_SAVING', values: true });
    let r = yield fireApi('UpdateUserLanguage', values);
    yield put({ type: 'UPDATE_SAVING', values: false });
    if (parseInt(r.status) === 1) {
      yield put({ type: 'UPDATE_LANGUAGE', values: r.data.languages });
      yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* reviewProject({payload}) {
  try{
    const { company, user } = payload;
    
    yield put({ type: 'UPDATE_SUBMITTING', values: true });
    let res1 = yield fireApi('ProjectReview', user )
    let res2 = yield fireApi('PlateformReviewToCompany', company )
    yield put({ type: 'UPDATE_SUBMITTING', values: false });

    if(res1.status === "1" && res2.status === "1"){
      yield put({type: 'UPDATE_PROJECT_RATING_STATUS', values: 1})
      yield put({ type: 'CLOSE_POPUP_SAGA', payload: {} })
    }
    
  } catch(error) {
    console.log(error)
  }
}

// export function* UpdateUserAward(data) {
//   let values = data.payload;
//   console.log(values);
//   try {
//     yield put({ type: 'UPDATE_SAVING', values: true });
//     let r = yield fireApi('UpdateUserAwards', values);
//     yield put({ type: 'UPDATE_SAVING', values: false });
//     if (r.status == 1) {
//       yield put({ type: 'UPDATE_AWARD', values: r.data.awards });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }
