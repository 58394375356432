import React from "react";
import PreIcon from "../../../../../Assets/upgrade-images/view_deliverables_history/arrow-pre.svg";
import EyeIcon from "../../../../../Assets/upgrade-images/version_history/eye.svg";
import moment from "moment";

const UploadDocumentHistory = ({ handleData, viewAttachments, setIsHistoryVersion, viewLatestAttachments,isFixed, ...props }) => {

  return (
    <div className="modal-body">
      <div className="row" style={{ minHeight: "500px" }}>
        <div className="col-12 ">
          <div className="page_card">
            <div className="proposal_header br_btm d-flex justify-content-between px-4">
              <div className="prposal_heading d-flex align-items-center">
                <p
                  className="mb-0 mr-3 cursor-pointer"
                  onClick={() => {
                    handleData(viewLatestAttachments);
                    setIsHistoryVersion(false);
                    props.setViewVersionHistory(false);
                  }}
                >
                  <img src={PreIcon} className="img-fluid mr-3" /> Version History
                </p>
              </div>
            </div>
            <div className="history_list mx-4">
              {viewAttachments?.milestonecomplete?.length > 0 ? (
                viewAttachments.milestonecomplete.map((item) => (
                  <div
                    className="row p-3 list_item br_tp align-items-center cursor-pointer"
                    onClick={() => {
                      handleData(item);
                      setIsHistoryVersion(true);
                      props.setViewVersionHistory(false);
                    }}
                  >
                    <div className="col-11">
                      <div className="d-flex align-items-center">
                        <span>Version {isFixed ? item?.iteration_no + "." + item?.sub_iteration_no  : item?.serial_no}</span>
                        {props.fixedLastSubm ? null : (
                          <>
                            {/* <span className="pills_tag_btn_gray ml-3">Iteration</span> */}
                            {item?.contested_status == "1" && viewAttachments?.status != 2 ? (
                              <span className="mb-0 text-red-500_md_w_600 ml-3">Rework</span>
                            ) : null}
                          </>
                        )}
                      </div>
                        <span className="text_gray font-12 fw_500">Submitted on {moment(item?.updatedAt).format("DD MMM, h:mm a")}</span>
                    </div>
                    <div className="col-1">
                      {" "}
                      <img src={EyeIcon} className="img-fluid" />
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center" style={{ padding: "10rem" }}>
                  <h2>No History Found ...</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentHistory;
