import React,{Component, Suspense} from 'react';
import RootRouter from './RootRouter/Router/indexRouting';
import '../src/Assets/css/theme.css';
import "../src/Assets/css/dashboard-profile.css";
import "../src/Assets/css/theme-responsive.css";
import './Assets/css-shilpi/style.css';
import './Assets/css/custom.css';
import './Assets/css/Responsive.css';
import './Assets/css/custom-responsive.css';
import './Assets/css/slider.css';
// import "./Assets/css/bootstrap.min.css";
import "../src/Assets/js/main.js";
import {Route} from 'react-router-dom';
import configureStore from './store/index.store';
import {Provider} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import {history} from './store/index.store'
import BootstrapLoader from './Components/authorized/Common/BootstrapLoader/BootstrapLoader';

import "./Assets/upgrade-scss/style.scss";


export const store = configureStore()
 class App extends Component{

  render(){
    
    return(
       <Provider store={store}>   
          <ConnectedRouter  history={history}>
            <Suspense fallback={<BootstrapLoader />} >
              <Route render={(props)=> <RootRouter {...props}/>}/>
            </Suspense>
         </ConnectedRouter>
       </Provider>
    )
  }
}
export default App;

export {history}