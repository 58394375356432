import React, { useEffect, useRef, useState } from "react";
import DownArrow from "../../../../../Assets/upgrade-images/work_log_actual_upload_filled/down_arrow.svg";
import { limitString } from "../../../../../utils/Helperfunctions";
import { getDateFormated, getRealFileName, handleFileName } from "../CommonFunctionsNew/CommonFunctionsNew";

const ActualIteartionSelect = ({ index, object, iterationFiles, setIterChangeFun }) => {
  const reff = useRef(null);
  const [toggle, setToggle] = useState(false);
  const handleDropDownClose = (ee) => {
    if (!reff.current?.contains(ee.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleDropDownClose);
    return () => {
      window.removeEventListener("click", handleDropDownClose);
    };
  }, []);

  const selectedName = (names, name) => {
    let finalName = "";
    names.forEach((item, i) => {
      if (name == item.isCheckedBy) {
        finalName = item.type == "file" ? handleFileName(getRealFileName(item?.name),40): limitString(item?.name,60);
      }
    });
    return finalName;
  };
  return (
    <div className="collapse multi-collapse" id={"multiCollapseExample" + index}>
      <div className="show_iteration d-flex align-items-center py-3 " key={"p" + index}>
        <div className="col-6 col-sm-8">
          <p className="mb-0 text_light_gray_md">Select file to link the iteration with</p>
        </div>
        <div className="col-6 col-sm-4 dropedown_input scroll_bar_body" ref={reff}>
          <div onClick={() => setToggle(!toggle)}>
            <input
              className="form-control input_md"
              type="text"
              placeholder="Select "
              // onClick={() => setToggle(!toggle)}
              disabled={true}
              value={selectedName(iterationFiles, object.name)}
            />
            <img
              src={DownArrow}
              onClick={() => setToggle(!toggle)}
              className={`img-fluid dropedown_icon cursor-pointer error_icon`}
            />
          </div>

          {toggle ? (
            <div className="dropdown_menu ">
              <div className="dropdown_inner">
                {iterationFiles?.length ? iterationFiles.map((obj, i) => {
                  return (
                    <div className="dropdown_item d-flex">
                      <div class={`cstm_checkbox ${obj.isCheckedBy.length && object.name != obj.isCheckedBy ? "disabled" : ""}`}>
                        <input
                          type="checkbox"
                          id={`check${i + index}`}
                          checked={obj.isCheckedBy}
                          onClick={() => setIterChangeFun(object.name, i)}
                          disabled={obj.isCheckedBy.length && object.name != obj.isCheckedBy}
                        />
                        <label for={`check${i + index}`}></label>
                      </div>
                      <div className="uploaded_file w-100">
                        <p className="mb-0 font_semi_bold_w_600">
                          {obj.type == "file" ? handleFileName(getRealFileName(obj?.name),40) : limitString(obj?.name,60)}{" "}
                          <span className="d-block text_light_gray_xs">Submitted on : {obj?.updatedAt ? getDateFormated(obj.updatedAt) : "-"}</span>
                        </p>
                      </div>
                    </div>
                  );
                }) : "No data found ..."}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ActualIteartionSelect;
