import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StopIcon from "../../../../../Assets/images/stop-icon.jpg";
// import { getThumbnailImage } from "../../../../../utils/Helperfunctions";
// import { CollateralBaseUrl } from "../../../../../utils/BaseUrl";

export default class Reviews extends Component {

  reviewCard = {
    loop: false,
    margin: 15,
    responsiveClass: true,
    dots: true,
    nav: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      1366: {
        items: 1,
        nav: true,
      },
    },
  };

  getThumbnailObj = (images_or_links) => {
    const thumbnailObj = images_or_links.find((el) => el.isThumbnail);
    const secondsteptogetimage = images_or_links.filter(
      (el) => el.image_original
    );
    const thirdsteptogetimage = images_or_links.filter((el) => el.value);
    return thumbnailObj
      ? thumbnailObj
      : secondsteptogetimage.length
      ? secondsteptogetimage[0]
      : thirdsteptogetimage[0];
  };

  renderOwlCarousel = (reviewList) => {
    const ratingHTML = ["*", "*", "*", "*", "*"];
    
    return reviewList.map((el, index) => {
      let /*image */  title, rating, feedback, reviewer, reviewerDesignation, reviewerCompany;
      const isTypeProject = el.type === 'upload_project';
      if(isTypeProject){                  
        // image = this.getThumbnailObj(el.project_data?.images_or_links);
        // projectId = r?.project_data._id;
        title = el?.project_data?.title;
        rating = el?.data_review?.review;
        feedback = el?.data_review?.feedback;
        reviewer = el?.data_review?.name;
        reviewerDesignation = el?.data_review?.designation;
        reviewerCompany = el?.data_review?.company_name;                    
      } else {
        // image = {value: CollateralBaseUrl + el?.Collateral_image}
        // projectId = el?.review?.post_project_id;
        title = el?.project_name;
        rating = el?.review?.rating;
        feedback = el?.review?.description;
        reviewer = el?.Client_name;
        reviewerDesignation = el?.designation;
        reviewerCompany = el?.company_name;
      }
      return (
        <div className="item mb-3" key={index}>
          {/* <div className="review_details">
                <div className="stars">
                    <i class="fa fa-star text-yellow font-18" aria-hidden="true"></i>
                    <span className="font-18 font-weight-600 text-dark-black ml-1">{el.data_review.review}</span>
                </div>
                <p className="my-2 font-14 font-weight-600">{el.data_review.feedback}</p>
                <span className="profile-subTitle text-capitalize font-12">{el.data_review.name}, {el.data_review.designation}, {el.data_review.company_name}</span>
            </div> */}

          <div className="row m-0 align-items-center">
            <div className="col-sm-2 col-md-2 col-lg-2 px-0">
              <div className="recommendation review_box d-block text-center">
                <div className="">
                  {/* <RenderImage
                    image={image}
                  /> */}
                  <div className="font-22">
                    <span className="font-weight-600">{rating}</span><span className="font-12 font-weight-600">/5</span>
                  </div>
                  <div className="d-inline-block">
                    {ratingHTML.map((value, index) => {
                      return ( 
                        <span key={index}>
                          <i
                            className={`fas fa-star font-12 mr-1 ${
                              index < rating
                                ? `text_light_green`
                                : ``
                            }`}
                          ></i>
                        </span> 
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10 mob-pt-15">
              <div className="w-100 rating">
                <h2 className="text-capitalize font-14 font-weight-500 mob-font-14">
                  {title}
                  <span className="text-success"> </span>
                  {!isTypeProject && <p className='d-inline-block bg-purple font-13 text-white border-radius-10 py-8 px-15 mob-font-12 ml-2'>{"Completed on IndieFolio"}</p>}
                </h2>
                <p>
                  {feedback && 
                  <q className="quotes text-dark-black font-18 font-weight-700 mob-font-12">
                    {feedback}
                  </q>}
                </p>
              </div>
              <div className="w-100 d-flex align-items-center">
                <div>
                  <h2 className="text-capitalize m-0 font-13 mob-font-12">
                    {reviewer}{" "}
                  </h2>
                  {reviewerDesignation && reviewerCompany &&
                    <span className="profile-subTitle font-12 mob-font-11 text-capitalize">
                      {reviewerDesignation}, {reviewerCompany}
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  }

  render() {
    const {reviews} = this.props;
    const hasReviews = reviews?.length;
        
    return (
      <React.Fragment>
        <div className="review_wrapper p-4 bg-white border-radius-8">
          <div className="dflex">
            <h2 className="font-16 font-weight-800 pb-15 mb-0">Reviews</h2>
            
            {/* <div className="dflex justify-content-unset">
              <div className="availability-toggle">
                <div className={`client font-weight-600 ${isProjectReviews ? 'active text-dark-black' : ''}  `}>Project</div>
                <label className="switch toggle-user">
                  <input
                    type="checkbox"
                    checked={!isProjectReviews}
                    onChange={this.handleSelectChange}
                  />
                  <span className={`slider round `}></span>
                </label>
                <div className={`creator font-weight-600 ${!isProjectReviews ? 'active text-dark-black' : ''} `}>Transaction</div>
              </div>
            </div> */}
          </div>
          
          {hasReviews             
            ?               
              <OwlCarousel {...this.reviewCard} className="owl-theme">
                {this.renderOwlCarousel(reviews)}
              </OwlCarousel>
              
           : (
            <>
              <div className="stop_icon review_stop_icon">
                <img src={StopIcon} alt="Indiefolio" className="img-fluid" />
                <div className="pt-3 text-sub-7 font-weight-600 font-14 text-center">
                  There are no reviews for this profile
                </div>
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

// const RenderImage = ({ image }) => {
//   const [thumbnail, setThumbnail] = useState(null);
//   useEffect(() => {
//     const getThumbnailImageFunc = async (u) => {
//       const url = await getThumbnailImage(u);
//       setThumbnail(url);
//     };
//     if (image.type === "url") {
//       getThumbnailImageFunc(image.value);
//     } else {
//       setThumbnail(image.value);
//     }
//   }, [image]);

//   return <img alt="" className="img-fluid" src={thumbnail} />;
// };
