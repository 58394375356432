import React, { Component } from "react";
import closeIcon from "../../../../../Assets/images/close.png";
import { postAPI } from "../../../../../utils/API";
import { validateEmail } from "../../../../../utils/formValidator";

export default class ShareCandidate extends Component {
  state = {
    isLoading: false,
    email: '',
    emailError: false
  }

  closePopup = () => {
    this.setState({ show: false }, () => {
      setTimeout(() => {
        this.props.closePopup();
      }, 300);
    });
  };

  handleSubmit = () => {
    const url = window.location.href;
    if(validateEmail(this.state.email)){
      this.setState({isLoading: true})
      const data = {email: this.state.email, url, post_project_id: url.split('/').pop()}
      console.log(data)
      postAPI('/project/send-url-to-user', data).then(() => {
        this.closePopup();
        alert('Email with link sent successfully.');
      }).catch(err => {
        console.log(err)
        this.setState({isLoading: false})
      })
    } else {
      this.setState({emailError: true})
    }
  };

  copyToClipboard = (url) => {
    let textField = document.createElement('textarea')
    textField.innerText = url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert('URL copied to clipboard.')
  };

  render() {
    const url = window.location.href;
    const {emailError, isLoading} = this.state;
    return (
      <>
        <div
          className="modal custom_form shareModal fade show"
          data-backdrop="static"
          style={{ paddingRight: "0px", display: "block" }}
          id="shareModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={closeIcon}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body p-4 p-sm-5 text-center">
                <div className="row">
                  <div className="col-sm-12">
                    <h2 className="font-weight-600 font-24 mob-font-18 mb-4">
                      Share Talent With Your Team
                    </h2>
                  </div>
                  <div className="col-sm-12">
                    <div className="share_url">
                      <p className="mb-0 font-weight-600 text-sub font-15 mob-font-16">
                        Share private link with your team <span className="text-dark-black ml-3 cursor-pointer" onClick={() => this.copyToClipboard(url)}><i className="far fa-copy mob-font-24"></i></span> 
                        {/* <span className="ml-2 text-success" >Copied.</span> */}
                      </p>
                      <a
                        href="/"
                        onClick={e => e.preventDefault()}
                        className="mb-0 font-weight-500 text-dark-black font-15 text-break"
                      >
                        {url}
                        {/* <span className="text-dark-black ml-3" onClick={() => this.copyToClipboard(url)}><i className="far fa-copy"></i></span> */}
                      </a>
                    </div>
                    <div className="share_for mt-4">
                      <div className="form-group">
                        <input
                          type="email"
                          className={`form-control email ${emailError ? 'border-error' : ''}`}
                          placeholder="Email"
                          onChange={e => this.setState({email: e.target.value, emailError: false})}
                          value={this.state.email}
                        />
                        {emailError && <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please enter valid email.</p>}
                      </div>
                      <button
                        type="button"
                        className="main-btn text-white py-15 btn w-100"
                        disabled={isLoading}
                        onClick={this.handleSubmit}
                      >
                        {isLoading ? 'Sending. Please wait...' : 'Share candidate'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? "back-modal" : ""}
        ></div>
      </>
    );
  }
}
