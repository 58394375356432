import React, { useEffect, useState } from "react";
import FeaturedToggleImage from "../../../../Assets/upgrade-images/view_proposal_fc/toggle_btn.png";
import HeaderIcon from "../../../../Assets/upgrade-images/view_proposal_fc/header_icon.png";
import PercentIcon from "../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import VisionIcon from "../../../../Assets/upgrade-images/view_proposal_fc/vision_historyicon.png";
import MoneyIcon from "../../../../Assets/upgrade-images/view_proposal_fc/money.png";
import MenuBoardIcon from "../../../../Assets/upgrade-images/view_proposal_fc/menu-board.png";
import { getAPI } from "../../../../utils/API";
import {
  GetpaymenTermsData,
  handleDelivaryDate,
  handleSelectedNames,
  onkeyCode,
  retainerTableValues,
  ViewDeliverableData,
  workDays,
} from "./CommonFunctionsNew/CommonFunctionsNew";
import Loader from "../../Common/Common/Loader";
import { separateNumber } from "../../ProfileCreator/TimeRetainer";
import moment from "moment";
import { values } from "lodash";
import ClientLeftComp from "./ClientLeftComp";
import RejectBrief from "./PopopCreator/RejectBrief";
import RequestChange from "./PopupClient/RequestChange";
import ReferenceForClient from "./RefrenceForClient/RefrenceForClient";
import { useDispatch, useSelector } from "react-redux";
import { releventProjects, relevent_projects, projectAttachments, projectUrls } from "../../../../store/action/proposal.action";
import ApproveAndPay from "./CommonpPopup/ApproveAndPay";

export default function ClientViewBrief(props) {
  const [states, setStates] = useState({
    deliverable: [],
    payments: [],
    advanceFees: {},
    note: "",
    proposed_start_date: "",
    type: "",
    propDuration: {
      prop_duration: "",
      prop_dur_num: 1,
    },
    numOfHours: {
      day_week: "",
      num_hour: 1,
    },
    fees: {
      retainerFees: "",
      advanceFees: "",
    },
    workingDaysInWeek: workDays,
    propDuration: {
      prop_duration: { label: "Week", value: "Week" },
      prop_dur_num: 1,
    },
    prefBillingCycle: { label: "Monthly", value: "Monthly" },
    proposalData: [],
    sub_total_cost: 0,
    retainer_fees_type: ""
  });
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("");
  const [loader, setLoader] = useState("");
  const [showRequestChange, setShowRequestChange] = useState(false);
  const [showRejectBrief, setShowRejectBrief] = useState(false);
  const [proposalId, setProposalId] = useState("");
  const [showApproveAndPay, setShowApproveAndPay] = useState(false);
  const [userData, setUserData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const { id, userId } = props.match.params;
    setId(id);
    setUserId(userId);
    handleGetProposal(id, userId);
  }, []);

  const changeShowRequestChange = (value) => {
    setShowRejectBrief(false);
    setShowRequestChange(value);
  };

  const callApiAgain = () => {
    handleGetProposal(id, userId);
  };

  const changeShowApproveAndPay = (value) => {
    setShowApproveAndPay(value);
  };

  const changeShowRejectBrief = (value) => {
    setShowRequestChange(false);
    setShowRejectBrief(value);
  };

  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: current_ids ? current_ids : [],
      releventProject: data?.allProjectData ? data.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.link_image ? data.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.urls ? data.urls : []));
  };

  // useEffect(() => {
  //   setProjectIds()
  //   setProjectAttachments()
  //   setAllUrls()
  // },[])

  // for redux state blank project reference ***********************

  const handleGetProposal = async (id, user_id) => {
    setLoader(true);
    const res = await getAPI(`/proposal/get-latest-proposal-data?user_id=${user_id}&id=${id}`);
    try {
      if (res) {
        let data = res?.data?.AllProposal;
        getReferenceData(data);
        setProposalId(data?._id);
        // fixed values
        let arrDel = ViewDeliverableData(data);
        let obj = GetpaymenTermsData(data?.payment_terms, arrDel);
        let arrPay = obj?.arrPay;
        let advanceFees = obj?.advanceFees;
        // retainer values
        states.propDuration.prop_duration =
          data?.proposed_duration_type?.length > 0
            ? {
              label: data.proposed_duration_type,
              value: data.proposed_duration_type,
            }
            : { label: "Week", value: "Week" };
        states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
        states.numOfHours.day_week =
          data?.number_of_hours_type?.length > 0
            ? {
              label: data.number_of_hours_type,
              value: data.number_of_hours_type,
            }
            : { label: "Day", value: "Day" };
        states.numOfHours.num_hour = data?.number_of_hours ? data.number_of_hours : 1;
        states.workingDaysInWeek = data?.working_days_in_week?.length === 7 ? data.working_days_in_week : workDays;
        states.fees.retainerFees = data?.retainer_fees ? data.retainer_fees : "";
        states.retainer_fees_type = data?.retainer_fees_type;
        states.fees.advanceFees = data?.advance_amount ? data.advance_amount : "";
        // actual values
        states.deliverable = arrDel.length > 0 ? arrDel : [];
        states.propDuration.prop_duration =
          data?.proposed_duration_type?.length > 0
            ? {
              label: data.proposed_duration_type,
              value: data.proposed_duration_type,
            }
            : { label: "Week", value: "Week" };
        states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
        states.prefBillingCycle =
          data?.preferred_billing_cycle?.length > 0
            ? {
              label: data.preferred_billing_cycle,
              value: data.preferred_billing_cycle,
            }
            : { label: "Monthly", value: "Monthly" };
        //
        states.proposalData = data;
        setStates({
          ...states,
          deliverable: arrDel?.length > 0 ? arrDel : [],
          payments: arrPay?.length > 0 ? arrPay : [],
          proposed_start_date: data?.proposed_start_date ? new Date(data?.proposed_start_date) : new Date(),
          note: data?.note ? data.note : "",
          advanceFees,
          type: data?.proposal_type,
          sub_total_cost: data?.sub_total_cost,
        });
        setLoader(false);
      }
    } catch (err) {
      console.log("error");
    }
  };

  const handleChooseDays = (name) => {
    let arr = [];
    states.workingDaysInWeek.forEach((item) => {
      if (item.name === name) arr.push({ ...item, isActive: !item.isActive });
      else arr.push({ ...item });
    });
    let filter = arr?.filter((obj) => obj?.isActive);
    states.validation.workingDaysInWeek = filter.length > 0 ? false : true;
    setStates({
      ...states,
      workingDaysInWeek: [...arr],
    });
  };

  const getUserData = (value) => {
    setUserData(value);
  };

  const handleTimeCommitment = (action) => {
    let name = "";
    let result = "";
    let filter = [];
    if (action == 3) {
      name = "Working days in a week";
      filter = states.workingDaysInWeek?.length > 0 ? states.workingDaysInWeek.filter((item) => item.isActive) : [];
    } else {
      let obj = retainerTableValues(action, states);
      name = obj?.name;
      result = obj?.result;
    }

    return (
      <>
        <div className="duration_title col-xl-12 ">
          <div className="d-flex justify-content-between">
            <h4 className="font_semi_bold_w_600 p-3">{name}</h4>
            {action == 3 ? (
              <div className="duration_fields d-flex col-lg-4 justify-content-end">
                {filter?.length > 0
                  ? filter.map((item, i) => {
                    return (
                      <div className="circle_btn_field d-flex mr-2 cursor-pointer" onClick={() => handleChooseDays(item.name)}>
                        <div className={`round_btn_blue_common ${item.isActive ? "active" : ""}`}>
                          <p className="mb-0">{item.label}</p>
                        </div>
                      </div>
                    );
                  })
                  : "N/A"}
              </div>
            ) : (
              <h4 className="font_semi_bold_w_600 p-3">{result}</h4>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <section className="view_proposal_fc main_page_container">
        <div className="row view_proposal_fc_container mx-0">
          {/* top_heading */}
          <div className="col-lg-12 pb-4">
            <div className="top_header d-flex align-items-center">
              <div className="heading_icon pr-4">
                <img
                  src={HeaderIcon}
                  className="img-fluid cursor-pointer"
                  // onClick={() => (props?.history?.goBack() ? props.history.goBack() : "")}
                  onClick={() => props?.history?.push(`/projects/${props.match.params?.id}/overview`)}
                />
              </div>
              <p className="proposal_heading mb-0">View Brief</p>
            </div>
          </div>
          {/* left_side */}

          <ClientLeftComp states={states} id={id} {...props} isViewBrief={true} getUserData={getUserData} />

          {/* right_side */}
          <div className="col-lg-9">
            {/* page_card */}
            <div className="page_card">
              {/* header */}
              <div className="proposal_header br_btm mb-4 d-flex justify-content-between">
                <div className="prposal_heading d-flex align-items-center">
                  <p className="mb-0 mr-3">Proposal</p>
                  {states?.type &&
                    (states?.type == "fixed" ? (
                      <div className="tag_btn_common">
                        <p className="mb-0">
                          <img src={PercentIcon} className="icon_img mr-1" />
                          Fixed Scope
                        </p>
                      </div>
                    ) : states?.type == "retainer" ? (
                      <div className="tag_btn_green_common">
                        <p className="mb-0">
                          <img src={PercentIconGreen} className="icon_img mr-1" />
                          Retainer
                        </p>
                      </div>
                    ) : (
                      <div className="tag_btn_dark_green_common">
                        <p className="mb-0">
                          <img src={PercentIconDarkGreen} className="icon_img mr-1" />
                          On-Actuals
                        </p>
                      </div>
                    ))}
                </div>

                <div className="edit_proposal d-flex align-items-center">
                  <img src={VisionIcon} className="icon_img mr-1" />
                  <p
                    className="mb-0 mr-3 edit_proposal_text anchor_text"
                    onClick={() => props?.history?.push(`/version-history/${id}/${userId}`)}
                  >
                    Show version history
                  </p>
                </div>
              </div>

              {/* cards */}
              <div className="row mx-0 px-2 mb-4">
                <div className="col-lg-6">
                  <div class="card view_proposal_card">
                    <div class="card-body px-4 py-4">
                      <div className="card_body_inner d-flex">
                        <div className="inner_item mr-4">
                          <div className="card_icon d-flex">
                            <img src={MoneyIcon} className="icon_img" />
                          </div>
                        </div>
                        <div className="inner_item">
                          <p className="mb-0 text_gray">Project Quote</p>
                          <p className="mb-2 text_black_md">
                            ₹{" "}
                            {states?.type == "actual"
                              ? separateNumber(states?.propDuration?.prop_dur_num * states?.sub_total_cost)
                              : states?.type == "fixed" ? separateNumber(states?.sub_total_cost) : `${separateNumber(states?.fees?.retainerFees)}/ ${states?.retainer_fees_type == "Hourly" ? "Hour" : "Month"}`}
                            <span className="text_light_gray_xs">+ Applicable Taxes</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {userData?.status > 5 ? (
                  <div className="col-lg-6">
                    <div class="card view_proposal_card">
                      <div class="card-body px-4 py-4">
                        <div className="card_body_inner d-flex">
                          <div className="inner_item mr-4">
                            <div className="card_icon d-flex">
                              <img src={MenuBoardIcon} className="icon_img" />
                            </div>
                          </div>
                          <div className="inner_item">
                            <p className="mb-0 text_gray">{states?.type == "fixed" ? "Delivery Date" : "Start Date"}</p>
                            <p className="mb-2 text_black_md">
                              {states?.type == "fixed"
                                ? handleDelivaryDate(states, states?.deliverable, states?.type)
                                : moment(states?.proposed_start_date).format("MMM D, YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {loader ? (
                <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
              ) : (
                <>
                  {/* Fixed Section */}
                  {states?.type == "fixed" ? (
                    <>
                      <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                        <div className="col-lg-12 title_commn">
                          <p className="mb-0">Deliverables</p>
                        </div>
                        {/* table */}
                        <div className="col-lg-12 px-0">
                          <div className="table-responsive table_deliverables">
                            <table className="table mb-4">
                              <thead>
                                <tr className="table_br_btm">
                                  <th scope="col" className="font_th">
                                    #
                                  </th>
                                  <th scope="col" className="font_th">
                                    Milestone
                                  </th>
                                  <th scope="col" className="font_th">
                                    Due Date
                                  </th>
                                  <th scope="col" className="font_th">
                                    Iterations
                                  </th>
                                  <th scope="col" className="font_th">
                                    Price post iteration limit
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="table_br_btm">
                                {states.deliverable.map((item, i) => {
                                  return (
                                    <tr className="table_br_btm td_font">
                                      <th scope="row">{onkeyCode(i + 65)}</th>
                                      <td>
                                        <p className="mb-0">{item?.name}</p>
                                      </td>
                                      <td>
                                        <p className="mb-0">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                                      </td>
                                      <td>
                                        <p className="mb-0">{item?.iterations ? item?.iterations : "-"}</p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                        </p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Payment_Terms */}
                      <div className="row px-4 pb-4 payment_terms mx-0  scroll_bar_body">
                        {/* header */}
                        <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                          <p className="mb-0">Payment Terms</p>
                        </div>
                        {/* description */}
                        <div className="col-lg-12 px-0 py-3">
                          <p className="discription_common">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit laoreet est augue faucibus ac. Est
                            morbi cras nisl, non quis massa mattis sit arcu. Facilisis purus vitae volutpat tincidunt consectetur
                            pulvinar convallis sed. Nulla risus sit in enim nam aliquam sapien phasellus rhoncus.
                          </p>
                        </div>
                        {/* table */}
                        <div className="col-lg-12 ">
                          <div className="table-responsive payment_terms_table">
                            <table className="table mb-4">
                              <thead>
                                <tr className="table_br_btm">
                                  <th scope="col" className="font_th">
                                    #
                                  </th>
                                  <th scope="col" className="font_th">
                                    Payment Milestones
                                  </th>
                                  <th scope="col" className="font_th">
                                    Breakup
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=" scroll_bar_body">
                                {states?.advanceFees?.breakup ? (
                                  <tr className="table_br_btm td_font">
                                    <th scope="row">1</th>
                                    <td>
                                      <p>Advance Fees</p>
                                    </td>
                                    <td>
                                      <p>₹ {separateNumber(states.advanceFees.breakup, "")}</p>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {states.payments.map((item, i) => {
                                  return (
                                    <tr className=" td_font">
                                      <th scope="row"> {states?.advanceFees?.breakup ? i + 2 : i + 1}</th>
                                      <td>
                                        <p> {handleSelectedNames(item.names)}</p>
                                      </td>
                                      <td className="price_input">
                                        <p>₹ {separateNumber(item?.breakup, "")}</p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : states?.type == "retainer" ? (
                    <>
                      <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                        <div className="col-lg-12 title_commn">
                          <p className="mb-0">Time Commitment</p>
                        </div>
                        {/* table */}
                        {handleTimeCommitment(1)}
                        {handleTimeCommitment(2)}
                        {handleTimeCommitment(3)}
                      </div>

                      <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                        <div className="col-lg-12 title_commn">
                          <p className="mb-0">Payment Terms</p>
                        </div>
                        {/* table */}
                        {handleTimeCommitment(6)}
                        {handleTimeCommitment(4)}
                        {handleTimeCommitment(5)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                        <div className="col-lg-12 title_commn">
                          <p className="mb-0">Terms</p>
                        </div>
                        {/* table */}
                        {handleTimeCommitment(1)}
                        {handleTimeCommitment(6)}
                      </div>

                      <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                        <div className="col-lg-12 title_commn">
                          <p className="mb-0">Deliverables</p>
                        </div>
                        {/* table */}
                        <div className="col-lg-12 px-0">
                          <div className="table-responsive table_deliverables">
                            <table className="table mb-4">
                              <thead>
                                <tr className="table_br_btm">
                                  <th scope="col" className="font_th">
                                    #
                                  </th>
                                  <th scope="col" className="font_th">
                                    Milestone
                                  </th>
                                  <th scope="col" className="font_th">
                                    Pricing
                                  </th>
                                  <th scope="col" className="font_th">
                                    Iterations
                                  </th>
                                  <th scope="col" className="font_th">
                                    Price per iteration after 3
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="table_br_btm">
                                {loader ? (
                                  <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
                                ) : (
                                  states.deliverable.map((item, i) => {
                                    return (
                                      <tr className="table_br_btm td_font">
                                        <th scope="row">{states?.type == "fixed" ? onkeyCode(i + 65) : i + 1}</th>
                                        <td>
                                          <p className="mb-0">{item?.name}</p>
                                        </td>
                                        <td>
                                          <p> {item?.breakup > 0 ? `₹ ${separateNumber(item.breakup, "")}` : `₹ 0`}</p>
                                        </td>
                                        <td>
                                          <p>{item?.iterations}</p>
                                        </td>
                                        <td>
                                          <p>
                                            {item?.iteration_charge > 0
                                              ? `₹ ${separateNumber(item.iteration_charge, "")}`
                                              : `₹ 0`}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {/*Add References */}

              <ReferenceForClient creatorUsername = {states?.proposalData?.creatorUsername}/>

              {/* end Add References */}

              {/* add notes */}
              <div className="row  px-4 pb-4 add_notes mx-0">
                <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                  <p className="mb-0">Notes & Comments for the Creator</p>
                  <p className=" mb-0 sub_title_commn">Optional</p>
                </div>
                <div className="col-lg-12 px-0 py-4">
                  <textarea
                    class="form-control upload_notes"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    value={states?.note}
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing urna aliquet dictum proin. Ultricies egestas duis hac semper placerat. Vitae fermentum, nibh ut a, lectus vitae egestas ultrices. Lectus rhoncus vel sodales risus a aliquam."
                  />
                </div>
              </div>

              {/* page_bottom_btn */}
              {/* <div className="row mx-0 br_tp">
                <div className="col-lg-12 py-4 ">
                  <button type="button" class="btn btn-common btn_common_lg float-right">
                    Make Changes
                  </button>
                </div>
              </div> */}

              <div className="row mx-0 br_tp">
                <div className="col-12 m-4">
                  <div className="d-flex flex-row justify-content-end p-4">
                    <button
                      style={{
                        border: 0,
                        color: "red",
                        backgroundColor: "#fff",
                      }}
                      className="font-14 font-weight-400 cursor-pointer mr-5"
                      onClick={() => changeShowRejectBrief(true)}
                    >
                      Reject Project
                    </button>
                    <button
                      className="btn font-12 btn-outline-secondary font-weight-600 mob-w-100 cursor-pointer mr-4"
                      onClick={() => changeShowRequestChange(true)}
                    >
                      Request Changes
                    </button>
                    {userData && states?.proposalData?.status == 0 ? (
                      <button
                        className="main-btn font-12 font-weight-600 mob-w-100 cursor-pointer mr-4"
                        onClick={() => changeShowApproveAndPay(true)}
                      >
                        Approve and Pay
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showRejectBrief ? (
        <RejectBrief
          changeShowRejectBrief={changeShowRejectBrief}
          showRejectBrief={showRejectBrief}
          id={id}
          {...props}
          isClient={true}
        />
      ) : (
        ""
      )}

      {showRequestChange ? (
        <RequestChange
          changeShowRequestChange={changeShowRequestChange}
          showRequestChange={showRequestChange}
          id={id}
          {...props}
          isClient={true}
          stateData={states}
          proposalId={proposalId}
        />
      ) : (
        ""
      )}

      {showApproveAndPay ? (
        <ApproveAndPay
          showApproveAndPay={showApproveAndPay}
          changeShowApproveAndPay={changeShowApproveAndPay}
          id={id}
          {...props}
          stateData={states}
          isClientViewBrief={true}
          userData={userData}
          callApiAgain={callApiAgain}
        />
      ) : (
        ""
      )}
    </>
  );
}
