
import { put, select } from "redux-saga/effects";
import { fireApi } from '../../../utils/fireApiPrecheck';
import { postProjectData, globalReducer, chat } from './selectors/index.select';



export function* onFieldChangePostProject(data) {
    let values = data.payload; 
    try {
        // const postProject = yield select(postProjectData);
        yield put({ type: "UPDATE_" + values.name, values: {budget:values.value}});
    } catch (error) {
        console.log(error);
    }
}

export function* onFieldChangeProjectDetails(data) {
    let values = data.payload;
    try {
        yield put({ type: "ADD_" + values.name, values: values.value});
    } catch (error) {
        console.log(error);
    }
}

export function* loadAllServices(value) {
    try {
        value = (value && value.payload) ? value.payload : '';
        let Services = yield fireApi('GetCollateralsVisible', { name: value });
        yield put({ type: "LOAD_ALL_SERVICES", values: Services.data});
    } catch (error) {
        console.log(error);
    }
}

export function* selectService(values) {
    try{
        // console.log(values.payload.id);
        let value = values.payload;
        yield put({ type: "CHOOSE_SERVICE", values: {name:value.name,id:value.id,selected:value.selected}});
    } catch (error) {
        console.log(error);
    }
}

export function* selectedCollateral(values) {
    try {
        let value = values.payload;
        yield put({type: "SET_SELECTED_COLLATERAL", values:{id:value}});
    } catch(error) {
        console.log(error);
    }
}


export function* loadCollateralQuestions(value) {
    try {
        value = value.payload;
        // console.log("Collateral ID",value);
        let Questions = yield fireApi('GetQuestions',  {id: value});
        // console.log("Collateral Questions",Questions);
        yield put({ type: "GET_QUESTIONS", values: Questions});
    } catch (error) {
        console.log(error);
    }
}

export function* loadCollateralQuestionsGlobal(value) {
    try {
        var localStorageData = localStorage.getItem('CollateralQuestions');
        localStorageData = JSON.parse(localStorageData);
        let Questions = localStorageData;
        yield put({ type: "GET_QUESTIONS", values: Questions});
    } catch (error) {
        console.log(error);
    }
}


export function* selectDeadline(value) {
    try {
        value = value.payload;
        // console.log(value);
        yield put({ type: "SELECT_DEADLINE", values: value});
    } catch (error) {
        console.log(error);
    }
}
export function* selectSubType(value){
    try {
        value = value.payload;
        // console.log(value);
        yield put({ type: "SELECT_SUBTYPE", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* selectTypeAnswer(value) {
    try {
        value = value.payload;
        yield put({ type: "SELECT_TYPE_ANSWER", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* selectStyleAnswer(value) {
    try {
        value = value.payload;
        // console.log("Style answers====",value);
        yield put({ type: "SELECT_STYLE_ANSWER", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* selectProjectDetailAnswer(value) {
    try {
        value = value.payload;
        yield put({ type: "SELECT_PROJECT_DETAIL_ANSWER", values: value});
    } catch (error) {
        console.log(error);
    }
}
export function* selectProjectDetailProjectAnswer(value) {
    try {
        value = value.payload;
        yield put({ type: "SELECT_PROJECT_DETAIL_PROJECT_ANSWER", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* getSteps(value) {
    try {
        value = value.payload;
        yield put({ type: "GET_PROJECT_STEPS", values: value});
    } catch (error) {
        console.log(error);
    }
}


export function* getArtists(value) {
    try {
        value = value.payload;
        yield put({type: 'UPDATE_LOADING', values: true})

        let ARTISTS = yield fireApi('GetArtists',  {'id': value});
        yield put({ type: "GET_ARTISTS", values: ARTISTS});
        
        yield put({type: 'UPDATE_LOADING', values: false})
    } catch (error) {
        console.log(error);
    }
}


export function* selectArtist(value) {
    try {
        value = value.payload;
        yield put({ type: "SELECT_ARTIST", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* updatePortfolioProjects(value) {
    try {
        value = value.payload;
        yield put({ type: "SET_PORTFOLIO_PROJECTS", values: value});
    } catch (error) {
        console.log(error);
    }
}

export function* openPostProjectPopup(data) {
    let values = data.payload;
    try {
        yield put({ type: "OPEN_POPUP", values: values.Component })
    } catch (error) {
        throw error;
    }
}

export function* closePostProjectPopup(data) {
    try {
      yield put({ type: "CLOSE_POPUP", values: '' })
    } catch (error) {
      console.log(error);
    }
  }

  export function* loadPostProjectData(value) {
    try {
        value = value.payload;
        let PostProjectData = yield fireApi('GetPostProjectData',  {id: value});
        // console.log("Post project Data -------->>");
        yield put({ type: "UPDATE_POST_PROJECT_DATA", values: PostProjectData});
    } catch (error) {
        console.log(error);
    }
}
export function* loadCompanyDetails(value){

    try {
        value = value.payload;
        let userCompanyDetail = yield fireApi('GetUserCompanyDetail',  {id: value});
        yield put({ type: "UPDATE_COMPANY_DETAIL", values: userCompanyDetail});
    } catch (error) {
        console.log(error);
    }
}

export function* updateInviteMoreStatus({payload}) {
    try {
        yield put({type: 'UPDATE_INVITE_MORE_STATUS', values: payload})
    } catch(error){
        console.log(error)
    }
}

export function* loadCreatorList(value) {
    try {
        value = value.payload;
        const postProject = yield select(postProjectData);
        const ArtistCount = postProject.ArtistsCount;
        if(ArtistCount > 0) {
            let creatorList = yield fireApi('GetInviteMoreCreatorList',value);
            yield put({ type: "LOAD_CREATOR_LIST", values: creatorList});
        } else {
            let creatorList = yield fireApi('GetCreatorList',  {id: value});
            yield put({ type: "LOAD_CREATOR_LIST", values: creatorList});
        }
    } catch (error) {
        console.log(error);
    }
}

export function* resetProjectData(value) {
    try {
        value = value.payload;
        yield put({ type: "UPDATE_RESET_PROJECT_DATA", values: ""});
    } catch (error) {
        console.log(error);
    } 
}

export function* resetAllData(value) {
    try {
        value = value.payload;
        yield put({ type: "UPDATE_RESET_ALL_DATA", values: ""});
    } catch (error) {
        console.log(error);
    } 
}

export function* resetService(value) {
    try {
        value = value.payload;
        yield put({ type: "UPDATE_RESET_SERVICE", values: value});
    } catch (error) {
        console.log(error);
    } 
}

export function* openSignupLogin(value) {
    try {
        value = value.payload;
        yield put({ type: "SCOPE_OF_WORK_COMPLETED", values: value});
    } catch (error) {
        console.log(error);
    } 
}

export function* loadReviewProposalData(value) {
    try {
        value = value.payload;
        yield put({ type: "GET_REVIEW_PROPOSAL_DATA", values: value});
    } catch (error) {
        console.log(error);
    } 
}

export function* loadLatestProposal(value) {
    try {
        const projectId = value.payload
        const chatData = yield select(chat)
        const ProjectStatus = chatData.ProjectStatus
        // let api_path = (this.props.CurrentRole === 2) ? '/milestone/latest-proposals?post_project_id=' : '/milestone/ongoing-proposals?post_project_id='
        const globalReducerData = yield select(globalReducer);
        const CurrentRole = globalReducerData.Me.CurrentRole
        let proposalData;
        if(CurrentRole === 2){
            proposalData = yield fireApi('getProposalDataCreator', {id: projectId})
        } else {
            proposalData = yield fireApi('getProposalDataClient', {id: projectId})
        }
        yield put({ type: "GET_REVIEW_PROPOSAL_DATA", values: proposalData});

        let active_milestones, values;
        // console.log(proposalData)
      if(proposalData.milestone.length) {
        if(ProjectStatus === 5) {
          active_milestones = proposalData.milestone.find((element) => { return element.status === 0});
        } else {
          active_milestones = proposalData.milestone.find((element) => { return element.status === 1});
        }
        let milestone_id = active_milestones ? active_milestones._id : null;
        // console.log(proposalData);
        values = {
            milestones: proposalData.milestone,
            proposal:proposalData.proposal,
            proposalStatus: proposalData.proposal.status,
            active_milestones:milestone_id,
            delivered_status: proposalData.delivered_status,
            user_data:proposalData.user,
        }
        values.CurrentMilestone = {...proposalData.CurrentMilestone, completed_milestone_id: proposalData.mlstnCompt._id}
      } else {
          values = {
            milestones: [],
            proposal:[],
            active_milestones:'',
            delivered_status: null,
            user_data: null,
            CurrentMilestone: null,
            proposalStatus: null,
          }
      }

      yield put({type: 'UPDATE_LOAD_PROPOSAL_DATA', values: values})

    } catch (error) {
        console.log(error);
    } 
}


export function* loadAdminProjectProposal({payload}){
    let data;
    if(!payload){
      const {ProjectId} = yield select(chat)
      data = yield fireApi('GetChatUserListAdminProject',{id: ProjectId});
    } else {
      data = payload
    }
  
    const active_milestones = data.milestone.find((element) => { return element.status === 1});
    let milestone_id = active_milestones ? active_milestones._id : null;
    const values = {
        milestones: data.milestone,
        proposal:data.proposal,
        proposalStatus: data.proposal.status,
        active_milestones:milestone_id,
        delivered_status: null, // we are not showing current progress card
        user_data:data.user,
        CurrentMilestone: active_milestones,
    }
    yield put({ type: "GET_REVIEW_PROPOSAL_DATA", values: {proposal: data.proposal, milestone: data.milestone}});
    yield put({type: 'UPDATE_LOAD_PROPOSAL_DATA', values: values})
  }

export function* setCurrentProposal(value) {
    try {
        value = value.payload;
        yield put({ type: "GET_CURRENT_PROPOSAL_ID", values: value});
    } catch (error) {
        console.log(error);
    } 
}
export function* loadLatestMilestone({payload}) {
    try {
        yield put({ type: "UPDATE_LOAD_LATEST_MILESTONE_STATUS", values: {id: payload}});
    } catch (error) {
        console.log(error);
    } 
}

export function* resetLatestMilestoneId() {
    try {
        yield put({ type: "UPDATE_LOAD_LATEST_MILESTONE_STATUS", values: {id: null}});
    } catch (error) {
        console.log(error);
    } 
}

export function* updateVersionHistory({payload}) {
    try {
        yield put({ type: "UPDATE_VERSION_HISTORY", values: payload});
    } catch (error) {
        console.log(error);
    } 
}

export function* setCompletedMilestone({payload}) {
    try {
        yield put({ type: "UPDATE_COMPLETED_MILESTONE", values: payload});
    } catch (error) {
        console.log(error);
    } 
}

export function* resetCompletedMilestone() {
    try {
        yield put({ type: "UPDATE_COMPLETED_MILESTONE", values: null});
    } catch (error) {
        console.log(error);
    } 
}


export function* getPostProjectClientBrief(value) {
    try {
        value = value.payload;
      if(value){
        let creatorList = yield fireApi('GetPostProjectClientBriefRequest',  {post_project_id: value});
        yield put({ type: "GET_POST_PROJECT_CLIENT_BRIEF", values: creatorList});
      }
    } catch (error) {
        console.log(error);
    } 
}

export function* getPostProjectClientBriefPublic(value) {
    try {
        value = value.payload;
        yield put({type: 'UPDATE_LOADING', values: true})
      if(value){
        let creatorList = yield fireApi('GetPostProjectClientBriefPublicRequest',  {post_project_id: value});
        
        yield put({ type: "GET_POST_PROJECT_CLIENT_BRIEF", values: creatorList});
        yield put({type: 'UPDATE_LOADING', values: false})
      }
    } catch (error) {
        yield put({ type: "GET_POST_PROJECT_CLIENT_BRIEF", values: {status: 0}});
        yield put({type: 'UPDATE_LOADING', values: false})
        console.log(error);
    } 
}

export function* getCreatorProposalProfile(value) {
    try {
        value = value.payload;
      if(value){
        let creatorProposalProfileData = yield fireApi('GetCreatorProposalProfile',  value);
        yield put({ type: "GET_CREATOR_PROPOSAL_PROFILE", values: creatorProposalProfileData});
      }
    } catch (error) {
        console.log(error);
    } 
}