import React, { Component } from 'react';
import  {Switch, Route, Link} from 'react-router-dom';
import PostProjectHeader from '../PostProject/PostProjectHeader';
import Services from './Services';
import Budget from '../PostProject/Budget';
import Deadline from '../PostProject/Deadline';
import Style from '../PostProject/Style';
import { connect } from 'react-redux';
import Unit from './Unit';
import Type from './Type';
import Header from './Header';
import ProjectType from './ProjectType';
import ProjectName from './ProjectName';
import Contactus from '../../unauthorized/Common/Footer/contactus';

class PostProject2 extends Component {
    state = {
        authIs: false,
        isHireflow: true,
        posting_from: ""
    }

    componentDidMount =() => {
        // console.log(this.props)
        if(this.props.userName) {
            const projectId = this.props?.location?.state?.project_id;
            const newProject = this.props?.location?.state?.newProject;
            if(newProject === true) {
                this.props.resetData();
            } else {
                if(projectId){
                    this.props.LoadPostProjectData(projectId);
                }
                if(projectId === undefined) { 
                }
            }
        } else {
            let element = document.getElementById("postProjectQuestions");
            let post_steps = this.props.post_steps;
            let total_steps = element.children.length;
            this.props.PostProjectSteps(post_steps,total_steps);
        }
        // this.setState({posting_from : this.props?.location?.state?.prevPath})
    }

    componentDidUpdate = (prevProps) => {
        const newProject = this.props?.location?.state?.newProject;
        if(newProject === true && !this.props.selected_collateral) {
            this.props.resetData();
        }
        if(this.props.userName) {
            const newProject = this.props?.location?.state?.newProject;
            const completeBrief =  this.props?.location?.state?.completeBrief;
            if(this.props.selected_collateral !== prevProps.selected_collateral) {
                // console.log('%c SELECTED COLLATERAL CHANGED', 'font-size: 22px; color:orange');
                if(this.props.selected_collateral){
                    this.props.LoadCollateralQuestions(this.props.selected_collateral);
                }
            }
            let element = document.getElementById("postProjectQuestions");
            let total_steps = element.children.length;
            // console.log(this.props.budgetChanged,this.props.unitChanged, this.props.pricingUnit,this.props.deadline,this.props.typeQuestions,this.props.typeAnswers,this.props.StyleQuestions,this.props.styleAnswers,newProject);
            if(this.props.CollateralQuestions !== prevProps.CollateralQuestions && newProject !== true && completeBrief === true) {
        
                if(!this.props.subType){
                    let child  = document.getElementById('projectType_tab');
                    let step   = Array.from(child.parentNode.children).indexOf(child);
                    this.props.PostProjectSteps(step,total_steps);
                    this.props.history.push('/hire/projectType');
                    return
                }
                if(this.props.budgetChanged === false) {
                        let child  = document.getElementById('budget_tab');
                        let step   = Array.from(child.parentNode.children).indexOf(child);
                        this.props.PostProjectSteps(step,total_steps);
                        this.props.history.push('/hire/budget');
                        return;
                }
                if(this.props.unitChanged === false && this.props.pricingUnit === "per_unit"&&this.props.subType==='fixed') {
                    let child  = document.getElementById('unit_tab');
                    let step   = Array.from(child.parentNode.children).indexOf(child);
                    this.props.PostProjectSteps(step,total_steps);
                    this.props.history.push('/hire/unit_selection');
                    return;
                }

                if(this.props.typeQuestions?.length > 0 && (this.props.skipType===''||this.props.skipType==='no')) {
                    let child  = document.getElementById('type_tab');
                    let step   = Array.from(child.parentNode.children).indexOf(child);
                    this.props.PostProjectSteps(step,total_steps);
                    this.props.history.push('/hire/type');
                    return;
                }
                if(this.props.StyleQuestions?.length > 0 && (this.props.skipStyle===''||this.props.skipType==='no')) {
                    var child  = document.getElementById('style_tab');
                    let step   = Array.from(child.parentNode.children).indexOf(child);
                    this.props.PostProjectSteps(step,total_steps);
                    this.props.history.push('/hire/style');
                    return;
                }
                if(!this.props.projectname) {
                    var child  = document.getElementById('project_name_tab');
                    let step   = Array.from(child.parentNode.children).indexOf(child);
                    this.props.PostProjectSteps(step,total_steps);
                    this.props.history.push('/hire/projectName');
                    return;
                }
            }
        }
    }

    disableTabs = (path) => {
        let disabled_routes = ["services",'projectType',"budget","unit_selection","deadline","type","style","projectname"];
        const{location}    = this.props;
        const current_route = location.pathname.split('/').pop().toLowerCase();
        if(current_route === 'services') {
            disabled_routes = ["projecttype","budget","unit_selection","deadline","type","style","projectname"];
        }
        if(current_route==='projecttype'){            
            disabled_routes = ["budget","unit_selection","deadline","type","style","projectname"];   
        }
        if(current_route === 'budget') {
            disabled_routes = ["unit_selection","deadline","type","style","projectname"];
        }
        if(current_route === 'unit_selection') {
            disabled_routes = ["type","style","projectname"];
        }
       
        if(current_route === 'type') {
            disabled_routes = ["style","projectname"];
        }
        if(current_route === 'style') {
            disabled_routes = ["projectname"];
        }
        if(current_route === 'projectname') {
            disabled_routes = [];
        }
        return disabled_routes.includes(path);
    }

    render() {
        const{location, authIs} = this.props;
        const active_tab = (path) => {
            return location.pathname.split('/').pop().toLowerCase() === path
        }

        const {selected_collateral,selected_collateral_name,minBudget,maxBudget,unit,pricingUnit,StyleQuestions,typeQuestions,unitChanged,budgetChanged,subType,projectname} = this.props;
        // let typeAnswer  = typeAnswers;
        // let styleAnswer = styleAnswers;
        // console.log(styleAnswer);
        return (
            <>
            {
            (this.props.userName) ?
               <></>
                :
                <Header title=" Post a project" />
            }
            <section className="main_page_container">
             <PostProjectHeader />
            <React.Fragment>
                
                    <div className={`container-fluid p-4 mob-p-0 ipad-p-0 post-project-height ${!authIs ? "not-login" : "" }`}>
                        <div className="row m-0">
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 profile-inner overflow-content project-category mid-laptop-w-20 mob_display_none" id="postProjectQuestions">
                                <div className="card-wrapper mb-15" id="services_tab">
                                    <div className="card bg-shadow">
                                        <div className="card-body">
                                            <div className="dashboard-content">
                                                <Link to="/hire">
                                                    <div className="dflex dashboard-welcome p-3">
                                                        <div className="w-20 text-center font-20">
                                                            {/* <span><i className={`fas fa-user fa-icon ${active_tab('services') ? "active" : ""}`}></i></span> */}
                                                            <img src={require("../../../Assets/images/specializaton.png")} className={`img-fliud ${active_tab('services') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                        </div>
                                                        <div className="w-75">
                                                            <h2 className={`text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0 ${active_tab('services') ? " active" : ""}`}>Service</h2>
                                                            <p className="sub-text font-13 font-weight-600 mb-0">{selected_collateral_name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                selected_collateral.length > 0 ?
                                <>
                                    <div className="card-wrapper mb-15" id="projectType_tab">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className={`dashboard-content  ${this.disableTabs('projectType') ? 'disabled post_a_project_tabs' : ''} `}>
                                                    <Link to="/hire/projectType">
                                                        <div className="dflex dashboard-welcome p-3">
                                                            <div className="w-20 text-center font-20">
                                                                {/* <span><i className={`fas fa-pencil-ruler fa-icon ${active_tab('budget') ? "active" : ""}`}></i></span> */}
                                                                <img src={require("../../../Assets/images/budget.png")} className={`img-fliud ${active_tab('projectTy') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                            </div>
                                                            <div className="w-75">
                                                                <h2 className="text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0">Project Type</h2>
                                                                <p className="sub-text font-13 font-weight-600 mb-0">{(subType) ? `${subType.charAt(0).toUpperCase()+subType.substring(1)  }` : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-wrapper mb-15" id="budget_tab">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className={`dashboard-content  ${this.disableTabs('budget') ? 'disabled post_a_project_tabs' : ''} `}>
                                                    <Link to="/hire/budget">
                                                        <div className="dflex dashboard-welcome p-3">
                                                            <div className="w-20 text-center font-20">
                                                                {/* <span><i className={`fas fa-pencil-ruler fa-icon ${active_tab('budget') ? "active" : ""}`}></i></span> */}
                                                                <img src={require("../../../Assets/images/budget.png")} className={`img-fliud ${active_tab('budget') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                            </div>
                                                            <div className="w-75">
                                                                <h2 className="text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0">Budget</h2>
                                                                <p className="sub-text font-13 font-weight-600 mb-0">{(budgetChanged === true) ? `${minBudget/1000}K - ${maxBudget/1000}K` : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                    (pricingUnit === "per_unit"&&subType==='fixed') ?
                                    <div className="card-wrapper mb-15" id="unit_tab">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className={`dashboard-content  ${this.disableTabs('unit_selection') ? 'disabled post_a_project_tabs' : ''}`}>
                                                    <Link to="/hire/unit_selection">
                                                        <div className="dflex dashboard-welcome p-3">
                                                            <div className="w-20 text-center font-20">
                                                                {/* <span><i className={`fas fa-pencil-ruler fa-icon ${active_tab('unit_selection') ? "active" : ""}`}></i></span> */}
                                                                <img src={require("../../../Assets/images/budget.png")} className={`img-fliud ${active_tab('unit_selection') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                            </div>
                                                            <div className="w-75">
                                                                <h2 className="text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0">Unit</h2>
                                                                <p className="sub-text font-13 font-weight-600 mb-0">{(unitChanged === true) ? unit : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                    }
                                    {
                                    typeQuestions && typeQuestions.length > 0 ?
                                        <div className="card-wrapper mb-15" id="type_tab">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className={`dashboard-content  ${this.disableTabs('type') ? 'disabled post_a_project_tabs' : ''}`}>
                                                        <Link to="/hire/type">
                                                            <div className="dflex dashboard-welcome p-3">
                                                                <div className="w-20 text-center font-20">
                                                                    {/* <span><i className={`fab fa-windows fa-icon ${active_tab('type') ? "active" : ""}`}></i></span> */}
                                                                    <img src={require("../../../Assets/images/web-links.png")} className={`img-fliud ${active_tab('type') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                                </div>
                                                                <div className="w-75">
                                                                    <h2 className={`text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0 ${active_tab('type') ? " active" : ""}`}>Type</h2>
                                                                    <p className="sub-text font-13 font-weight-600 mb-0">{(typeQuestions?.[0]?.answer?.[0]?.item) ? typeQuestions?.[0]?.answer?.[0]?.item : "-"}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :<></>
                                    }
                                    {
                                    StyleQuestions && StyleQuestions.length > 0 ?
                                        <div className="card-wrapper mb-15" id="style_tab">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div  className={`dashboard-content  ${this.disableTabs('style') ? 'disabled post_a_project_tabs' : ''}`}>
                                                        <Link to="/hire/style">
                                                            <div className="dflex dashboard-welcome p-3">
                                                                <div className="w-20 text-center font-20">
                                                                    {/* <span><i className={`fab fa-windows fa-icon ${active_tab('style') ? "active" : ""}`}></i></span> */}
                                                                    <img src={require("../../../Assets/images/web-links.png")} className={`img-fliud ${active_tab('style') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                                </div>
                                                                <div className="w-75">
                                                                    <h2 className={`text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0 ${active_tab('style') ? " active" : ""}`}>Style</h2>
                                                                    <p className="sub-text font-13 font-weight-600 mb-0">{(StyleQuestions?.[0]?.answer?.[0]?.item) ? StyleQuestions?.[0]?.answer?.[0]?.item : "-"}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :<></>
                                    }
                                    {/* <div className="card-wrapper mb-15">
                                        <span className="sub-text font-12 font-weight-800 mb-0"><i className="far fa-save font-18 mr-1"></i> Save project draft</span>
                                    </div> */}
                                </>
                                :
                                <></>
                                }
                                             {
                                        true?
                                        <div className="card-wrapper mb-15" id="project_name_tab">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className={`dashboard-content  ${this.disableTabs('projectname') ? 'disabled post_a_project_tabs' : ''}`}>
                                                        <Link to="/hire/type">
                                                            <div className="dflex dashboard-welcome p-3">
                                                                <div className="w-20 text-center font-20">
                                                                    {/* <span><i className={`fab fa-windows fa-icon ${active_tab('type') ? "active" : ""}`}></i></span> */}
                                                                    <img src={require("../../../Assets/images/web-links.png")} className={`img-fliud ${active_tab('projectname') ? "active" : ""}`} width="30" alt="Indiefolio"/>
                                                                </div>
                                                                <div className="w-75">
                                                                    <h2 className={`text-light-color font-13 font-weight-600 text-uppercase text-underline mb-0 ${active_tab('type') ? " active" : ""}`}>Project Name</h2>
                                                                    <p className="sub-text font-13 font-weight-600 mb-0">{projectname ? projectname : "-"}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :<></>
                                    }
                            </div>
                            <div className="col-12 col-sm-10 col-md-10 col-lg-10 profile-inner mob-p-0 mob-col-100 mid-laptop-w-80 ipad-p-0">
                                {selected_collateral.length > 0 ?
                                <Switch>
                                    <Route path="/hire/budget"    render={(props)=> <Budget {...props} {...this.state} />} />
                                    <Route path="/hire/projectType"    render={(props)=> <ProjectType {...props} {...this.state} />} />
                                    <Route path="/hire/projectName"    render={(props)=> <ProjectName userProfile={this.props.userProfile} {...props} {...this.state} />} />
                                    <Route path="/hire/unit_selection"    render={(props)=> <Unit {...props} {...this.state} />} />
                                    <Route path="/hire/deadline"  render={(props)=> <Deadline {...props} {...this.state} />} />
                                    <Route path="/hire/type"     render={(props)=> <Type {...props} {...this.state} />} />
                                    <Route path="/hire/style"     render={(props)=> <Style {...props} {...this.state} />} /> 


                                    <Route exact path="/hire"  render={(props)=> <Services {...props} {...this.state} />} /> 
                                </Switch> :
                                <Switch> 
                                    <Route path="/hire/:collateralName"  render={(props)=> <Services {...props} {...this.state} />} />
                                    <Route exact path="/hire"  render={(props)=> <Services {...props} {...this.state} />} />
                                    <Route exact path="/hire"  render={(props)=> <Services {...props} {...this.state} />} /> 

                                </Switch>
                                }
                            </div>
                        </div>
                    </div>
               
            </React.Fragment>
            </section>
            </>
           

        )
    }
}


const mapState = (store) => { 
    let postProjectData = store.postProject;
    let globalReducer = store.globalReducer;
	return {
        skipType:postProjectData.skip_type_question,
        skipStyle:postProjectData.skip_style_question,
        subType:postProjectData.subType,
        projectname:postProjectData.project_name,

        projectId:  postProjectData.projectId,
		minBudget:  postProjectData.minBudget,
        maxBudget:  postProjectData.maxBudget,
        unit:       postProjectData.unit,
        budgetChanged: postProjectData.budgetChanged,
        unitChanged:   postProjectData.unitChanged,
        deadline:      postProjectData.deadline,
        selected_collateral: postProjectData.selected_collateral,
        selected_collateral_name: postProjectData.selected_collateral_name,

        typeQuestions: (postProjectData?.typeQuestions?.length > 0) ?  (postProjectData.typeQuestions) : postProjectData?.CollateralQuestions?.Collateral_type_question,
        StyleQuestions:(postProjectData?.styleQuestions?.length > 0) ? (postProjectData.styleQuestions):  postProjectData.CollateralQuestions.Collateral_style_question,

        typeAnswers:    (postProjectData?.typeQuestions?.length > 0) ?  (postProjectData.typeAnswers?.[0].item) : [],
        styleAnswers:    (postProjectData?.StyleQuestions?.length > 0) ?  (postProjectData.styleAnswers?.[0].item) : [],

        pricingUnit: (postProjectData?.pricingUnit !== undefined) ? postProjectData.pricingUnit : postProjectData?.CollateralQuestions?.collateral?.pricing_unit,
        
        total_steps: postProjectData.total_steps,
        post_steps: postProjectData.post_steps,
        CollateralQuestions: postProjectData?.CollateralQuestions,
        userName: globalReducer.Me.Username,
	}; 
}

const mapDispatch = dispatchEvent => ({
    
    OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state}),
    LoadPostProjectData:(id) => dispatchEvent({ type: 'LOAD_POST_PROJECT_DATA', payload: id }),
    LoadCollateralQuestions:(id) => dispatchEvent({ type: 'LOAD_COLLATERAL_QUESTIONS', payload: id }),
    resetData: (id)   => dispatchEvent({type:"RESET_ALL_DATA",payload:id}),
    PostProjectSteps : (step,total_step) => dispatchEvent({type:"POST_PROJECT_STEPS",payload:{step:step,total_steps:total_step}}),
    resetProject:(values) => dispatchEvent({ type: 'RESET_PROJECT_DATA', payload: values }),
});

export default connect(mapState,mapDispatch)(PostProject2);