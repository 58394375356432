import React, { Component } from 'react'
import { withRouter } from 'react-router'

class RedirectToPublicProfile extends Component {

    componentDidMount(){
        const {history, match} = this.props;
        history.push(`/creator/${match.params?.user_name}`)
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default withRouter(RedirectToPublicProfile)
