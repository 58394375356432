import React, { Component } from 'react';

class SingleSelectDP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enable: false,
      List: props.List,
      Value: '',
    };
  }

  showListOnClick = () => {
    this.setState({ Enable: !this.state.Enable });
  };

  handleOnclick = (obj) => {
    this.callDispatch(obj.name);
    this.showListOnClick();
  };

  onChange = (e) => {
    this.setState({ Value: e.target.value, Enable: true });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e.target.value);
    }
  };

  callDispatch = (value) => {
    this.setState({ Value: '' });
    if (typeof this.props.onChange === 'function') {
      this.props.onSelect(value);
    }
  };

  deleteItems = () => {
    if (typeof this.props.onRemove === 'function') {
      this.props.onRemove();
    }
  };

  notList = (not, item) => {
    let r = false;
    if (!not?.length && not?.length < 1) return r;
    not && not.forEach((element) => {
      if (element.name === item) r = true;
    });
    return r;
  };

  render() {
    let { Enable, Value } = this.state;
    let { List, error, infoText, className, notListed, Selected, width, hideList } = this.props;

    return (
      <>
        <div
          className={`select-tags bg-white d-flex align-items-center flex-wrap ${
            error && error === true ? 'border-error' : ''
          }`}
        >
          <div>
            {Selected && Selected !== '' ? (
              <span className="selected-tags common-tags py-10 px-15" key={0}>
                {Selected}{' '}
                <i
                  className="fas fa-times ml-1 font-13"
                  onClick={() => this.deleteItems(Selected)}
                ></i>
              </span>
            ) : (
              ''
            )}
            {Selected && Selected !== '' ? (
              <></>
            ) : (
              <span style={{ display: 'inline-block' }}>
                <input
                  type="text"
                  className={`w-270 form-control email pl-3 tags-input py-3`}
                  placeholder={infoText}
                  autoComplete="off"
                  name="secondarySection"
                  value={Value}
                  onChange={this.onChange}
                  onClick={this.showListOnClick}
                />
              </span>
            )}
          </div>
          {List.length > 0 && Enable === true ? (
            <ul
              className={`select-options  hideList${hideList ? hideList : ''} select-top100 ${width ? width : 'w-100'} ` + className}
              style={{ display: 'block' }}
            >
              <li rel="hide">Choose here</li>
              {List &&
                List.map((obj, index) => {
                  return !this.notList(notListed, obj.name) ? (
                    <li key={index} onClick={() => this.handleOnclick(obj)}>
                      {obj.name}
                    </li>
                  ) : (
                    <></>
                  );
                })}
            </ul>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default SingleSelectDP;
