import React from "react";
import UserIcon from "../../../../../Assets/upgrade-images/view_brief/user.svg";
import CarIcon from "../../../../../Assets/upgrade-images/view_brief/car.svg";
import { limitString } from "../../../../../utils/Helperfunctions";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import Loader from "../../../Common/Common/Loader";
const MobileClientDetailsPopup = (props) => {
  const data = props?.projectDataStates?.data?.project_data;
  const loader = props?.projectDataStates?.projectDataloader;

  const isIndividual =
    data?.company_work_type?.toLowerCase() === "company" || data?.company_work_type?.toLowerCase() === "agency" ? false : true;
    return (
        <>
            <section className="MobileClientDetailsPopup">
                <div className="modal" id="MobileClientDetailsPopup">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                               <h4 className="mb-0 modal-title">{props?.CurrentRole == 1 ? "Your Details" : "Client Details"}</h4>
                                <button
                                    type="button"
                                    className="close close_btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" className="modalclose-btnicon">
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <div className="page_card">
                            {loader ? (
                            <Loader isCreateModal={true} style={{ padding: "4rem" }} />
                            ) : isIndividual ? (
                                    <div className="client_card row mx-0 mb-4 ">
                                        <div className="col-lg-12 d-flex py-2 px-0 br_btm mb-4 ">
                                            <div className="profile_name">
                                                <p className="mb-0 text-capitalize text_gray mb-2"> Individual</p>
                                                {/* <p className="mb-0 text_gray_1100_w_600_sm">{moment(data?.createdAt).format("MMM D, YYYY")}</p> */}
                                                <p className="mb-0 text_gray_1100_w_600_sm">{data?.company_individual_location}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="client_card row mx-0 px-4 mb-4 ">
                                            <div className="col-lg-12 d-flex py-4 px-0 br_btm mb-4 ">
                                                <div className="profile_icon mr-3">
                                                <img
                                                    src={
                                                    data?.company_logo
                                                        ? BaseImageUrl + data?.company_logo
                                                        : `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data?.company_add_link}&size=64`
                                                    }
                                                    className="img-fluid"
                                                />
                                                </div>
                                                <div className="profile_name">
                                                    <p className="mb-0 text_black_md"> {limitString(data?.company_name, 30)}</p>
                                                    <p className="anchor_blue_600_underline mb-0">
                                                        {" "}
                                                        {data?.company_add_link?.length > 0 ? (
                                                            <a
                                                                href={data.company_add_link?.includes("http") ? data.company_add_link : "http://" + data.company_add_link}
                                                                target="_blank"
                                                            >
                                                                {data?.company_add_link}
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-2 px-0">
                                                <div className="client_details">
                                                    <p className="mb-0 text_gray_1100_w_600_sm">Industry Type</p>
                                                    <p className="font_semi_bold_w_600">
                                                        <img src={CarIcon} className="img-fluid mr-2" />
                                                        {data?.company_industry}
                                                    </p>
                                                </div>
                                                <div className="client_details">
                                                    <p className="mb-0 text_gray_1100_w_600_sm">PoC Location</p>
                                                    <p className="font_semi_bold_w_600">
                                                        <img src={UserIcon} className="img-fluid mr-2" /> {data?.company_employees}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default MobileClientDetailsPopup;