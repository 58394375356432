import React from "react";
import { connect } from "react-redux";
import TextInputBox from "../../Common/Common/TextInputBox";
import ErrorSpan from "../../Common/Common/ErrorSpan";
import Behance from "../../../../Assets/upgrade-images/account/behance.svg";
import Instagram from "../../../../Assets/upgrade-images/account/instagram.svg";
import Dribble from "../../../../Assets/upgrade-images/account/dribbble.svg";
import Vimeo from "../../../../Assets/upgrade-images/account/vimeo.svg";
import Indifolio from "../../../../Assets/upgrade-images/account/indiefolio.svg";
import Youtube from "../../../../Assets/upgrade-images/account/YouTube.svg";
import Custom from "../../../../Assets/upgrade-images/account/custon_link.svg";
import FeaturedGalleryIconImage from '../../../../Assets/upgrade-images/submit_proposal/gallery.svg'
class WebLinks extends React.Component {
  state = {
    custom_url_length: 1,
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (
      this.props.SocialProfile.resume === "" &&
      this.props.SocialProfile.behance_url === "" &&
      this.props.SocialProfile.custom_url === "" &&
      this.props.SocialProfile.dribbble_url === "" &&
      this.props.SocialProfile.custom_url1 === "" &&
      this.props.SocialProfile.dribbble_url === "" &&
      this.props.SocialProfile.custom_url2 === "" &&
      this.props.SocialProfile.dribbble_url === "" &&
      this.props.SocialProfile.indiefolio_url === "" &&
      this.props.SocialProfile.instagram_url === "" &&
      this.props.SocialProfile.personal_url === "" &&
      this.props.SocialProfile.vimeo_url === ""
    ) {
      alert("Please Fill atleast 1 Url");
      return;
    }

    this.props.UpdateWebLink({
      resume: this.props.SocialProfile.resume,
      behance_url: this.props.SocialProfile.behance_url,
      custom_url: this.props.SocialProfile.custom_url,
      custom_url1: this.props.SocialProfile.custom_url1,
      custom_url2: this.props.SocialProfile.custom_url2,
      dribbble_url: this.props.SocialProfile.dribbble_url,
      indiefolio_url: this.props.SocialProfile.indiefolio_url,
      instagram_url: this.props.SocialProfile.instagram_url,
      personal_url: this.props.SocialProfile.personal_url,
      vimeo_url: this.props.SocialProfile.vimeo_url,
    });
    // close pop disable. Will be called after update
    // this.closePopup();
  };

  UrlUpdate = (e) => {
    this.props.updateUrl({
      name: e.target.name,
      value: e.target.value,
    });
  };

  closePopup = () => {
    this.props.ResetWebLink({
      name: "LOAD_SOCIALPROFILEPOPUP",
      value: {
        resume: "",
        behance_url: this.props.SocialBase.behance_url,
        custom_url: this.props.SocialBase.custom_url,
        custom_url1: this.props.SocialBase.custom_url1,
        custom_url2: this.props.SocialBase.custom_url2,
        dribbble_url: this.props.SocialBase.dribbble_url,
        indiefolio_url: this.props.SocialBase.indiefolio_url,
        instagram_url: this.props.SocialBase.instagram_url,
        personal_url: this.props.SocialBase.personal_url,
        vimeo_url: this.props.SocialBase.vimeo_url,
        behance_urlError: false,
        instagram_urlError: false,
        indiefolio_urlError: false,
        dribbble_urlError: false,
        createdAtError: false,
        vimeo_urlError: false,
        custom_urlError: false,
        personal_urlError: false,
      },
    });
    this.props.closePopup();
  };

  componentDidMount = () => {
    let custom_url_length = 1;
    const { custom_url1, custom_url2 } = this.props.SocialProfile;
    // let customUrls = [custom_url1, custom_url2];
    // custom_url_length = custom_url1 != '' ? 1 : custom_url2 != '' ? 2 : 0;
    if (custom_url1) custom_url_length = 1;
    if (custom_url2) custom_url_length = 2;
    this.setState({
      custom_url_length: custom_url_length,
    });
  };

  addCustomUrl = () => {
    const { custom_url_length } = this.state;
    if (custom_url_length <= 3) {
      this.setState({
        custom_url_length: custom_url_length + 1,
      });
    }
  };

  uploadImg = (e) => {
    if (e.target.files[0].size > 5 * 1000 * 1000) {
      alert("file size exeeds 5MB");
    } else {
      this.props.UploadResume(e);
    }
  };

  render() {
    let { SocialProfile, uploading } = this.props;
    let { custom_url_length } = this.state;
    // console.log('socialprofile ', SocialProfile);
    return (
      <>
        <div
          className="modal fade custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: "0px", display: "block" }}
          id="EditWebLinkModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.props.closePopup()}
              alt=""
              src={require("../../../../Assets/images/close.png")}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">
                        Social Handles
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height awards">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <form onSubmit={this.onSubmit} id="weblinks">
                        <div className="row m-0">
                          <div className="form-group mb-2 col-sm-12 p-0">
                            <nav className="cstm_nav px-4">
                              <div
                                class="nav br_btm_border-gray-300 cstm_tab row"
                                id="nav-tab"
                                role="tablist"
                              >
                                <a
                                  class="nav-item nav-link cstm_tab_item refrence_tab_active  active  d-flex align-items-center"
                                  id="social-account-tab"
                                  data-toggle="tab"
                                  href="#nav-home"
                                  role="tab"
                                  aria-controls="nav-home"
                                  aria-selected="true"
                                >
                                  <p className="mb-0">Social Accounts</p>
                                </a>
                                <a
                                  class="nav-item nav-link cstm_tab_item  d-flex align-items-center"
                                  id="nav-attatchment-tab"
                                  data-toggle="tab"
                                  href="#nav-profile"
                                  role="tab"
                                  aria-controls="nav-profile"
                                  aria-selected="false"
                                >
                                  <p className="mb-0">Resume</p>
                                </a>
                              </div>
                            </nav>
                          </div>

                          <div class="tab-content col-sm-12" id="nav-tabContent">
                            <div
                              class="tab-pane fade show active"
                              id="nav-home"
                              role="tabpanel"
                              aria-labelledby="social-account-tab"
                            >
                               <label className="label py-3 font-16 mob-font-13" htmlFor="first">
                              Link the accounts showcasing your work</label>

                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Indifolio} className="mr-1" />{" "}
                                    Indifolio
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_indiefolio_url"
                                    value={SocialProfile.indiefolio_url}
                                    error={SocialProfile.indiefolio_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.indiefolio_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Behance} className="mr-1" />{" "}
                                    Behance
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_behance_url"
                                    value={SocialProfile.behance_url}
                                    error={SocialProfile.behance_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.behance_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Dribble} className="mr-1" />{" "}
                                    Dribble
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_dribbble_url"
                                    value={SocialProfile.dribbble_url}
                                    error={SocialProfile.dribbble_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.dribbble_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Instagram} className="mr-1" />{" "}
                                    Instagram
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_instagram_url"
                                    value={SocialProfile.instagram_url}
                                    error={SocialProfile.instagram_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.instagram_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Vimeo} className="mr-1" /> Vimeo
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_vimeo_url"
                                    value={SocialProfile.vimeo_url}
                                    error={SocialProfile.vimeo_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.vimeo_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50 link">
                                    <img src={Youtube} className="mr-1" />{" "}
                                    YouTube
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_personal_url"
                                    value={
                                      SocialProfile.personal_url
                                        ? SocialProfile.personal_url
                                        : ""
                                    }
                                    error={SocialProfile.personal_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.personal_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <div className="col-sm-12 p-0 d-flex align-items-center">
                                  <span className="w-20 mob-w-50">
                                    {" "}
                                    <img src={Custom} className="mr-1" />
                                    Custom Url
                                  </span>
                                  <TextInputBox
                                    placeholder="Enter URL here"
                                    className="w-98 ml-3"
                                    name="SOCIALPROFILE_custom_url"
                                    value={SocialProfile.custom_url}
                                    error={SocialProfile.custom_urlError}
                                    message={""}
                                    onblur={true}
                                  />
                                </div>
                                <ErrorSpan
                                  isWebLinkModal={true}
                                  error={SocialProfile.custom_urlError}
                                  message="please enter a valid url."
                                />
                              </div>
                              {custom_url_length > 0 ||
                              SocialProfile.custom_url1 !== "" ? (
                                <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                  <div className="col-sm-12 p-0 d-flex align-items-center">
                                    <span className="w-20 mob-w-50">
                                      <img src={Custom} className="mr-1" />
                                      Custom Url
                                    </span>
                                    <TextInputBox
                                      placeholder="Enter URL here"
                                      className="w-98 ml-3"
                                      name="SOCIALPROFILE_custom_url1"
                                      value={SocialProfile.custom_url1}
                                      error={SocialProfile.custom_url1Error}
                                      message={""}
                                      onblur={true}
                                    />
                                  </div>
                                  <ErrorSpan
                                    isWebLinkModal={true}
                                    error={SocialProfile.custom_url1Error}
                                    message="please enter a valid url."
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              {custom_url_length > 1 ||
                              SocialProfile.custom_url2 !== "" ? (
                                <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                  <div className="col-sm-12 p-0 d-flex align-items-center">
                                    <span className="w-20 mob-w-50">
                                      <img src={Custom} />
                                      Custom Url
                                    </span>
                                    <TextInputBox
                                      placeholder="Enter URL here"
                                      className="w-98 ml-3"
                                      name="SOCIALPROFILE_custom_url2"
                                      value={SocialProfile.custom_url2}
                                      error={SocialProfile.custom_url2Error}
                                      message={""}
                                      onblur={true}
                                    />
                                  </div>
                                  <ErrorSpan
                                    isWebLinkModal={true}
                                    error={SocialProfile.custom_url2Error}
                                    message="please enter a valid url."
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              {custom_url_length < 2 &&
                              (SocialProfile.custom_url1 === "" ||
                                SocialProfile.custom_url2 === "") ? (
                                <div className="w-100 py-4 pr-3 custom_url ipad-pro-px-0">
                                  <a
                                    href="#/"
                                    className="font-14 mob-font-12 font-weight-600 text-center"
                                    onClick={this.addCustomUrl}
                                  >
                                    <i className="fas fa-plus mr-1"></i> Add
                                    Custom URL
                                  </a>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div
                              class="tab-pane fade"
                              id="nav-profile"
                              role="tabpanel"
                              aria-labelledby="nav-attatchment-tab"
                            >
                              <div className="form-group mb-3 col-sm-12 p-0 mob-p-0 ipad-pr-0">
                                <label
                                  className="label py-3 font-16 mob-font-14 w-100 pt-3 pb-2"
                                  htmlFor="first"
                                >
                                  Upload your resume here
                                </label>
                                <div className="upload-btn-wrapper w-100">
                                  <button className="btn upload_box w-100 font-13 font-weight-500 text-secondary d-block">
                                    <p className="py-3 mt-4 font-14">Add Content</p>
                                    <span className="font-18 mob-font-13 d-block text-success">
                                      {SocialProfile.resume && !uploading ? (
                                        <span className="complete-check text-center d-block mb-1">
                                          <i className="fas fa-check bg-success text-white"></i>
                                        </span>
                                      ) : uploading ? (
                                        <div className="btn-loader-wrapper">
                                          <div className="btn-loader resume_upload"></div>
                                        </div>
                                      ) : (
                                        <div className='upload_icon d-flex cursor_pointer'>
                                            <img src={FeaturedGalleryIconImage} className='gallery_icon img-fluid mb-2' />
                                        <p className='mb-0 text_black_xs_w_600'>Upload Media</p>
                                      </div>
                                      )}
                                      {uploading === true
                                        ? "Uploading resume..."
                                        : SocialProfile.resume &&
                                          SocialProfile.resume.indexOf("*") !==
                                            -1
                                        ? SocialProfile.resume.split("*")[1]
                                        : "Browse files here"}
                                    </span>
                                    <div className="py-3 mt-4 mb-4">Supports JPG, PNG, PDF AND DOC.</div>
                                  </button>
                                  <input
                                    type="file"
                                    name="myfile"
                                    // accept=".jpg, .png, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    accept=".jpg, .png, .pdf, .doc,.docx"
                                    onChange={(e) => this.uploadImg(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top">
                    <div className="form-group text-right pr-3 pt-3">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-danger col-sm-2 col-5 mb-2 mx-2 font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        onClick={this.onSubmit}
                        type="submit"
                        data-dismiss="modal"
                        className="main-btn col-sm-3 col-6 mb-2 mx-2">
                        Save Changes
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-sm-12 p-0 text-right pr-3 py-3 bg-shadow-top mobile-edit-collateral">
                    <button
                      type="button"
                      onClick={(e) => this.closePopup()}
                      className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={this.onSubmit}
                      type="submit"
                      className="main-btn px-4 ml-2"
                    >
                      Save Changes
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? "back-modal" : ""}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let SocialProfile = store.creatorSocial;

  return {
    saving: globalReducer.saving,
    uploading: globalReducer.uploading,
    closeModal: creatorProfile.closeModal,
    SocialBase: creatorProfile.SocialProfile,
    SocialProfile: SocialProfile,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: "ON_FIELDCHANGE_SAGA",
      payload: { name: e.target.name, value: e.target.value },
    }),
  updateUrl: (e) => dispatch({ type: "ON_FIELDCHANGE_SAGA", payload: e }),
  UpdateWebLink: (e) => dispatch({ type: "UPDATE_WebLink_Api", payload: e }),
  UploadResume: (e) =>
    dispatch({ type: "UPLOAD_RESUME", payload: { file: e } }),
  ResetWebLink: (e) => dispatch({ type: "ON_ACTION_SAGA", payload: e }),
});

export default connect(mapState, mapDispatch)(WebLinks);
