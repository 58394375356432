import { put, call } from "redux-saga/effects";
import { getAPI, postAPI } from "../../utils/API"
import { releventProjectSuccess, releventProjectsErr, projectByCollateralSuccess, activeProposalforclient, approveProposalFromAmSucess } from "../action/proposal.action";
import { fireApi } from '../../utils/fireApiPrecheck';


export function* onReleventProjects(data) {
  let values = data.values;
  try {
    var dataIs = yield call(getAPI,`/proposal/active-user-collaterals?username=${values?.userNameIs}&search=${values.search}`); 
    // var dataIs = yield fireApi('GetUserCollateral2Public',{username: values?.userNameIs}); 
    if (dataIs?.status == 200) {      
        yield put(releventProjectSuccess(dataIs?.data?.data));
    }
  } catch (error) {
        yield put(releventProjectsErr(dataIs?.data));
  }
}

// get projects by collateral ************************

export function* onProjectsByCollateral(data) {
  let values = data.values;
  if(values){
    try {
      var dataIs = yield call(getAPI,`/proposal/active-user-collaterals-project?username=${values?.userName}&collateral_id=${values?.collateral_id}&search=${values.search}`); 
      // let dataIs = yield fireApi('GetCollateralBasedProjectsPublic', { username: values?.userName, collateral_id: values?.collateral_id }); 
      if (dataIs?.status == 200) {      
          yield put(projectByCollateralSuccess(dataIs?.data));
      }
    } catch (error) {
          // yield put(releventProjectsErr(dataIs?.data));
    }
  }  
}

//  get active proposal for client **********************

export function* onClientProposal(data) {
  let values = data.values;
  if(values){
    try {
      var dataIs = yield call(getAPI,`/project/post-project-client-breif-public?post_project_id=${values?.id}`);  
      if (dataIs?.status == 200) {      
          yield put(activeProposalforclient(dataIs?.data));
      }
    } catch (error) {
          // yield put(releventProjectsErr(dataIs?.data));
    }
  }  
}

// approve proposal by account manager  ****************
export function* onApproveProposalAM(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/milestone/admin-approved-proposal`,
        values
      );
      if (data.status === 200) {
        console.log(data.data,'00000000000000')
        yield put(approveProposalFromAmSucess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}