import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import companyImg from '../../../../../Assets/images/company.png';
import StopIcon from '../../../../../Assets/images/stop-icon.jpg';
export default class WorkExperience extends Component {
    workCard = {
        loop: false,
        margin: 15,
        responsiveClass: true,
        dots: true,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            1366: {
                items: 1,
                nav: true,
            },

        }
    };
    handleEndOfExperience = (month, year)=>{
        if(month && year){
            return `${month}  ${year}`
        }else{
            return 'Present'
        }
    }
    render() {
        const WorkExperience = this.props.Experience; 
        const prefixImageUrl = "https://indiefolio-images.s3.ap-south-1.amazonaws.com/company/profile/"
        return (
            <React.Fragment>
                <div className="review_wrapper common_height bg-white p-4 border-radius-8">
                    <h2 className="font-16 font-weight-800 pb-15 mb-0">Work Experience</h2>
                    {WorkExperience?.length ? <OwlCarousel {...this.workCard} className="owl-theme">
                        {WorkExperience.map(el => {
                            return (
                                <div className="item" key={el._id}>
                                    <div className="review_details">
                                        <div className="stars mb-2">
                                            {/* <i class="fa fa-star text-yellow font-18" aria-hidden="true"></i> */}
                                            <img src={el?.company_image ? prefixImageUrl + el.company_image : companyImg} alt="company" className="img-fluid" />
                                        </div>
                                        <h2 className="mb-0 font-13 font-weight-700">{el.designation}</h2>
                                        <p className="my-1 font-12 font-weight-500 text-success">{el.company_name}</p>
                                        <span className="profile-subTitle text-capitalize font-12 d-block">{el.start_month + ' ' + el.start_year} - {this.handleEndOfExperience(el.end_month, el.end_year)}</span>
                                    </div>
                                </div>
                            )
                        })}

                    </OwlCarousel> : 
                    <>
                        <div className="stop_icon">
                            <img src={StopIcon} alt="Indiefolio" className="img-fluid"/>
                            <div className="pt-3 text-sub-7 font-weight-600 font-14 text-center">
                                There are no work experience for this profile
                            </div>
                        </div>
                    </> 
                }
                </div>
            </React.Fragment>
        )
    }
}
