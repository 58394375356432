import React, { Component } from "react";
import { connect } from "react-redux";
// import socketIOClient from 'socket.io-client';
// import { ChatBaseUrl } from '../../../utils/BaseUrl';
import { fireApi } from "../../../utils/fireApiPrecheck";
import { renderDifferentFiles, getFileName } from "../Common/Common/FileRenderMessage";
// import ReactSummernote from 'react-summernote';
import "react-summernote/dist/react-summernote.css"; // import styles
// import 'react-summernote/lang/summernote-ru-RU';
// import $ from 'jquery'
import CustomSummernote from "./CustomSummernote";
import { sanitiseSummernoteText } from "../../../utils/Helperfunctions";
import BootstrapLoader from "../Common/BootstrapLoader/BootstrapLoader";
import MultipleCardLoader from "../Common/MiltipleCardLoader.js/MultipleCardLoader";
import SendArrow from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/send.svg";
import SendArrowDisabled from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/sendDisabled.svg";
import Microphone from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/microphone.png";
import Attachment from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/attachment.svg";
import Media from "../../../Assets/upgrade-images/creator_active_brief_chat_project_room/gallery.svg";
import FadeSendArrow from "../../../Assets/upgrade-images/creator_active_brief_chat_room_empty/send.svg";
class ChatInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "<p><p>",
      type: "Plain",
      filePreview: [],
      filesUploading: false,
      filesCount: 0,
      connection: 0,
      fileType:null,
    };
    this.hiddenFileInput = React.createRef();
  }
  // registerSocket = (e) => {
  //   if(this.state.connection === 0) {
  //     const { socket } = this.props
  //     // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
  //     socket.on(e, (resp) => {
  //       this.setState({connection: 1})
  //       this.addMessagetoBoard(resp);
  //     });
  //   }
  // };

  // componentDidMount() {
  //   console.log('chat interface')

  //   const { socket, UserToken } = this.props
  //   if(socket){
  //     socket.emit('join_user', {
  //       user_token: UserToken
  //     });
  //     this.registerSocket(UserToken);
  //     console.log('emit join_user and ' + UserToken)
  //   }
  // }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.updateLastSeen({
      user_token: this.props.UserToken,
    });
  };

  onClick = (e) => {
    this.updateLastSeen({
      user_token: this.props.UserToken,
    });
  };

  // componentDidUpdate(prevProps) {
  //   const { socket, UserToken } = this.props;
  //   if (UserToken !== prevProps.UserToken) {
  //     this.registerSocket(UserToken);
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isAttachmentButtonClicked !== this.props.isAttachmentButtonClicked &&
      this.props.isAttachmentButtonClicked === true
    ) {
      this.hiddenFileInput.current.click();
      this.props.resetAttachmentButtonStatus();
      this.props.closePopup();
    }
  }

  onSmsSend = (e) => {
    // e.preventDefault();
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = this.state.value;

    const text = this.state.value.replace(/<\/?[^>]+(>|$)/g, "");
    const textWithUrl = sanitiseSummernoteText(this.state.value);
    let type = this.state.filePreview && this.state.filePreview.length > 0 ? "File" : this.state.type;
    if ( (htmlObject?.innerText?.trim().length <= 0 && this.state.filePreview.length <= 0 )) {
      return false;
    }

    // old
    //  let reg = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    // let  data = this.state.value.split(" ")
    const reg = /(?:ht|f)tps?:\/\/[-a-zA-Z0-9.]+\.[a-zA-Z]{2,3}(?:\/(?:[^"<=]|=)*)?/g;
    let data = textWithUrl && textWithUrl.match(reg);
    let links = [];
    data &&
      data.forEach((e, i) => {
        // remove http and create arr of objects
        links.push({ link: e.replace(/(^\w+:|^)\/\//, "") });
      });
    this.sockerMessagePush({
      type: type,
      user_token: this.props.UserToken,
      files: this.state.filePreview,
      // text: this.state.value,
      text: text ? textWithUrl : "",
      sender_id: this.props.UserId,
      links: links,
      user_type: this.getUserType(),
    });
    // Reset chat window height
    // document.querySelector('.overflow-content.chat-height.chatting-box-overflow').style.height = 'calc(100vh - 185px)'
  };

  getUserType = () => {
    const { isAdmin, ProjectType, CurrentChat, location ,CurrentRole } = this.props;

    const inAdminChat = location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && inAdminChat;
    return isAdminAndInAdminChat ? "admin" : ProjectType === "post_project_admin" ? CurrentChat.chat_type :(CurrentChat.connection_type=='am_client'||CurrentChat.connection_type=='am_creator')?'account_manager': CurrentChat.connection_type=='client_creator'&&CurrentRole==3?"admin_group":"user";
  };

  updateLastSeen = (e) => {
    // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
    // const { socket } = this.props
    // socket.emit('LastseenMsg', e);
    // console.log("Last Seen Updated====>");
  };

  sockerMessagePush = (e) => {
    // const socket = socketIOClient(ChatBaseUrl, { transports: ['websocket'] });
    const { socket } = this.props;
    socket.emit("MsgIn", e);
    this.addMessagetoBoard(e);
    this.setState({ value: "", type: "Plain", filePreview: [] });
  };

  addMessagetoBoard = (values) => {
    // if(values.type === "proposal_accepted" || values.type == "milestone_payment") {
    //   let projectId   = values.post_project_id;
    //   let projectName =  values.project_name;
    //   let projectStatus = 6;
    //   this.props.loadChat(projectId,projectName,projectStatus);
    // }
    // console.log(values,'this is the value')
    this.props.onChange({
      name: "SEND_MESSAGE",
      value: values,
    });
  };

  handleClick = (event) => {
    this.props.OpenPopup("MobilePlusButtonDropdown");

    // console.log('file attatched click')
    // console.log(this.handleFileInput)
    this.hiddenFileInput.current.click();
  };

  handleClickDesktop = () => {
    this.hiddenFileInput.current.click();
  };


  handleFileOnChange = (e) => {
    let files = e.target.files;
    if (!files.length) return;
    let totalFiles = files.length + this.state.filePreview.length;
    if (totalFiles > 10) {
      alert("Only Allow 10 files Upload");
    } else {
      this.setState({ filesUploading: true, filesCount: files.length });
      const formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append("chatFiles", files[i]);
      }
      fireApi("UploadChatFiles", formData).then((r) => {
        if (r.status && r.status === "1") {
          let fil = [];
          r.chat_files.forEach((ff) => {
            fil.push({ name: ff.name });
          });
          this.setState({ filePreview: [...this.state.filePreview, ...fil], filesUploading: false, filesCount: 0 });
          this.hiddenFileInput.current.value=null
        }
      });
    }
  };

  removeFile = (k, f) => {
    let files = this.state.filePreview;
    if (files.length < 1) return "";
    files.splice(k, 1);
    this.setState({ filePreview: files });
  };
  handleSubmitMilestone = () => {
    this.props.history.push("/submitMilestone");
  };

  render() {
    const { filesUploading, filesCount, filePreview } = this.state;
    const { ProjectType, CurrentChat, milestones, isAdmin, location, chatMessages } = this.props;
    const secondLastMilestone = milestones.length && milestones[milestones.length - 2];
    const isOnlyLastMilestonePending = secondLastMilestone ? secondLastMilestone.status === 2 : false;
    // console.log(this.state.filePreview);
    const inAdminChat = location.pathname.includes("/adminChat");
    const isAdminAndInAdminChat = isAdmin && inAdminChat;

    const isAdminProject = ProjectType === "post_project_admin";
    const isGroupChat = CurrentChat.chat_type === "group";
    // const isDisabledChat = ProjectType === "post_project_client" && !chatMessages?.length;
    let isDisabledChat = false;


    const LoadingCard = (
      <span className="upload-files mob-w-100 mob-ml-0 mx-1 py-2 pl-2 pr-3 mt-2 border-1 border-radius-8">
        <div className="d-flex align-items-center">
          <BootstrapLoader smallSize height={"0vh"} />
        </div>
      </span>
    );
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = this.state.value;
    let isInput = (htmlObject.innerText.trim().length === 0 && this.state.filePreview.length === 0 ) ? false : true
    return (
      <>
        <div className="row  mx-0 outer_textarea px-0">
          <div className="col-lg-12 px-2">
            <div className={`${this.props.CurrentRole === 2&&isDisabledChat?"empty_chat_upload_notes":"upload_notes"}`}>
              <div className={this.props.CurrentRole === 2&&isDisabledChat ? "tooltip_custom" : "position-relative"}
                style={isDisabledChat ? { cursor: "not-allowed",paddingRight:"7rem" } : {paddingRight:"7rem"}}
                data-point={
                  isDisabledChat&&this.props.CurrentRole === 2
                    ? this.props.CurrentRole === 2
                      ? "Waiting for client to review your proposal."
                      : "Waiting for creator to share a proposal"
                    : ""
                }
              >
                {/*--- Desktop Version ---*/}
                {/* <div className="submit_btn mobile-ipad-none"> */}

                <div className="">
                  {/* {this.props.proposalStatus === 2 ? null : (
                    <>
                      {this.props.ProjectStatus === 5 && this.props.CurrentRole === 2 && !isAdminAndInAdminChat ? (
                        <span
                          style={{ opacity: isDisabledChat ? 0.5 : 1 }}
                          onClick={(e) => {
                            !isDisabledChat && this.props.OpenPopup("ReviewMilestone");
                          }}
                          className="submit-proposal font-13 font-weight-600 text-blue border-radius-8 p-2"
                        >
                          <i className="fas fa-plus font-12 mr-1" />
                          Submit Proposal
                        </span>
                      ) : (this.props.ProjectStatus === 6 && this.props.CurrentRole === 2 && !isAdminProject) ||
                        (isAdminProject && isGroupChat && CurrentChat.user_role === "creator") ? (
                        <span
                          className="submit-proposal font-13 font-weight-600 text-blue border-radius-8 p-2"
                          onClick={this.handleSubmitMilestone}
                        >
                          <i className="fas fa-plus font-12 mr-1"></i>
                          {isOnlyLastMilestonePending ? "Submit Final Milestone & Deliverable" : "Submit Milestone"}
                        </span>
                      ) : null}
                    </>
                  )} */}
                  <div className="send_icon"> 
                    <img onClick={() => {this.setState({fileType:1},() => this.handleClickDesktop())}} src={Attachment} className="img-fluid mr-4 cursor-pointer" />
                     {/* <img onClick={this.handleClickDesktop} src={Attachment} className="img-fluid mr-4 cursor-pointer" /> */}
                    {/*<img onClick={this.handleClickDesktop} src={Media} className="img-fluid  mr-4 cursor-pointer" />  */}
                    <img src={isInput ? SendArrow : SendArrowDisabled} className={`img-fluid mr-4 ${!isInput ? '': 'cursor-pointer'}`} onClick={this.onSmsSend}/>
                  </div>


                </div>

                {/*-- Mobile Version --*/}
                {/* <div className="mobile-ipad-show">
                  <span onClick={this.handleClick} className="mob-add-attached">
                    <i className="fas fa-plus text-primary font-20" />
                  </span>
                  <div onClick={this.onSmsSend} className="mob-enter-btn">
                    <i className="fas fa-location-arrow text-white" />
                  </div>
                </div> */}
                <CustomSummernote
                  placeholder="Type your message here..."
                  value={this.state.value}
                  onChange={(val) => this.setState({ value: val })}
                  sendMsg={this.onSmsSend}
                  disabled={isDisabledChat}
                  inChat
                  sendMsgOnEnter
                />
                <input
                  type="file"
                  name="images"
                  className="user-uploading-input"
                  accept={this.state.fileType === 2 ? `image/*,video/*`:'*'}
                  multiple
                  disabled={isDisabledChat}
                  onChange={this.handleFileOnChange}
                  ref={this.hiddenFileInput}
                  style={{ display: "none" }}
                />
              </div>
             {/* <div className="d-flex justify-content-between align-items-center mb-3">
             <div className="upload_media_icons d-flex">
                <img onClick={() => {this.setState({fileType:1},() => this.handleClickDesktop())}} src={Attachment} className="img-fluid mr-4 cursor-pointer" />
                <img onClick={() => {this.setState({fileType:1},() => this.handleClickDesktop())}} src={Microphone} className="img-fluid mr-4 cursor-pointer" />
                <img onClick={() => {this.setState({fileType:2},() => this.handleClickDesktop())}} src={Media} className="img-fluid mr-4 cursor-pointer" />
              </div>
              <div className="upload_buttons">
                <button className="btn btn-sm approve_btn">Approve Proposal</button>
              </div> 
             </div> */}

              {/*=== Attachment File ====*/}
              {filePreview.length || filesUploading ? (
                <div className="preview-fileupload " style={{ display: (filePreview.length || filesUploading) && "block" }}>
                  <div className="d-flex align-content-center flex-wrap inner-preview-fileupload">
                    {filePreview.map((fp, k) => {
                      // console.log(fp)
                      return (
                        <span
                          key={k}
                          className="upload-files mob-w-100 mob-ml-0 mx-1 py-2 pl-2 pr-3 mt-2 border-1 border-radius-8"
                        >
                          <div className="d-flex align-items-center">
                            {renderDifferentFiles(fp.name)}
                            {/* <img src={renderDifferentFilesThumbnail(fp.name)} alt="eps" width="30" height="35" className="border-radius-4" /> */}
                            <div className="pl-3">
                              <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-12 line-height-normal">
                                {getFileName(fp.name)}
                              </h2>
                            </div>
                          </div>
                          <div
                            className="remove-file"
                            onClick={(e) => {
                              this.removeFile(k, fp);
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </div>
                        </span>
                      );
                    })}
                    {filesUploading ? <MultipleCardLoader loadingCount={filesCount}>{LoadingCard}</MultipleCardLoader> : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (store) => {
  const chat = store.chat;
  const globalReducer = store.globalReducer;
  const postProject = store.postProject;
  return {
    UserToken: chat.UserToken,
    UserId: chat.UserId,
    ProjectStatus: chat.ProjectStatus,
    proposalStatus: postProject.proposalStatus,
    CurrentRole: globalReducer.Me.CurrentRole,
    isAdmin: globalReducer.Me.isAdmin,
    role: globalReducer.Me.role,
    CurrentChat: chat.CurrentChat,
    ProjectType: chat.ProjectType,
    isAttachmentButtonClicked: chat.isAttachmentButtonClicked,
    milestones: postProject.milestones,
    chatMessages: chat.Messages,
  };
};
const mapDispatch = (dispatch) => ({
  loadChat: (projectId, projectName, projectStatus) =>
    dispatch({ type: "UPDATE_LOAD_CHAT", payload: { id: projectId, name: projectName, project_status: projectStatus } }),
  OpenPopup: (text) => dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  onChange: (e) => dispatch({ type: "ON_FIELDCHANGE_SAGA", payload: { name: e.name, value: e.value } }),
  resetAttachmentButtonStatus: (e) => dispatch({ type: "ATTACHMENT_BUTTON_TOGGLE", payload: false }),
  closePopup: () => dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} }),
});
export default connect(mapState, mapDispatch)(ChatInterface);
