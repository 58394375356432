import React, { Component } from 'react';
import { connect } from 'react-redux'
import milestone_complete from './../../../../Assets/images/1b.jpg';
import wallet from './../../../../Assets/images/wallet.png';

class ProjectComplete extends Component {
    handleClose = () => {
        this.props.closePopup()
     }
     render() { 
        return (
             <React.Fragment>
                  <div className="modal custom_form kick_off_project" id="VerifyModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                    <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("./../../../../Assets/images/close.png")} className="img-fliud" width="35" alt='' /></span>  
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content kick_off_star"> 
                        <div className="modal-body">
                            <div className="row m-0">
                                <div className="col-sm-12 p-4"> 
                                    <div className="calling_wrapper text-center"> 
                                        <div className="calling-image">
                                            <div className="project_completed mb-2"> 
                                                <div class="project_completed_img" style={{backgroundImage:`url("${milestone_complete}")`}}></div>
                                            </div>
                                        </div> 
                                        <div className="pb-3">
                                            <h2 className="font-20 font-weight-700 line-height-normal">Congratulations!</h2> 
                                            <h4 className="font-16 font-weight-700 line-height-normal">You have successfully completed the project</h4> 
                                            <span className="text-sub font-13 font-weight-500 line-height-normal">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'.</span> 
                                        </div> 
                                    </div>  
                                    <div className="wrapper-form p-3 mt-3 mb-1 milestone_project">
                                        <div className="dflex">
                                            <div class="w-75 mob-w-65">
                                                <span className="text-sub font-14 font-weight-600 line-height-normal">Money added in <a href="/" onClick={e=>e.preventDefault()} className="font-weight-600">Wallet</a></span>
                                                <div className="font-22 font-weight-600 line-height-normal">
                                                    <span className="cash_amount py-2 d-line"><i class="fas fa-rupee-sign font-20 mob-font-16 mr-1"></i>8,000</span>
                                                    <div class="ongoing mb-2"><span class="milestone bg-orange">Milestone 3</span><span class="due-on-date">Final set of concepts delivered..</span></div>
                                                </div>
                                            </div>
                                            <div className="w-25 mob-w-35">
                                                <div className="cash_icon">
                                                    <div className="profile-user-image">
                                                        <img src={wallet} alt="wallet"></img> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                  
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-backdrop show" style={{display: 'block'}}></div>
             </React.Fragment>
        )
    }
}

const mapDispatch =  dispatch => ({
        closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    }) 

export default connect(null, mapDispatch)(ProjectComplete)