import React from 'react';
import { connect } from 'react-redux';
import ProgressBar from './Progressbar';
import { postAPI, putAPI } from '../../../utils/API';
// import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import InputRange from 'react-input-range';
// budget_subtext
class ProjectName extends React.Component {



	state = {

	}

	componentDidMount() {


		// to show budget at left panel

	}

	handleContinue = () => {// const { value } = this.state;
		const { projectId, styleQuestions, post_steps, total_steps, project_name,userProfile } = this.props;
		const name = userProfile ? userProfile.first_name + " " + userProfile.last_name : '';
		const email = userProfile ? userProfile.email : '';
		this.setState({ isLoading: true })
		if(project_name.trim().length < 5) {
            this.props.setProjectNameError()
		this.setState({ isLoading: false })

			return 
		}
		const project_name_data = {
			project_name: project_name,
			_id: projectId,

		}
		if (this.props.userName) {
			
			putAPI('/project/post-project-name', project_name_data).then((res) => {
				if (res.data.status === "1") {
					const status_data = {
						post_project_id: this.props.projectId,
						status: 1,
					}
					putAPI('/project/post-project-status', status_data).then((res) => {
						if (res.data.status === "1") {
							const { hireAgainCreatorId, isHireAgain,prevPostProjectId } = this.props;
							if (isHireAgain) {
								console.log(hireAgainCreatorId)
								const brief_details = {
									post_project_id: this.props.projectId,
									creator_id:this.props.hireAgainCreatorId,
									posting_from:localStorage.getItem('prevPath'),
									// user_ids: [{ user_id: hireAgainCreatorId }],
									budget_scope:this.props.budget_scope,
									
								}

								putAPI('/project/add-post-project-creator-company', brief_details)
									.then((res) => {
										if (res.data.status === "1") {
											this.props.history.push('/project_details/project');
											this.props.changeHireAgainStatus(false)
										}
									})
									.catch(err => console.log(err))
							} else {
								this.props.PostProjectSteps(post_steps, total_steps);
									if(this.props.budget_scope&&this.props.budget_scope==='high'){

										
        							localStorage.setItem('postProjectId',projectId)
        							localStorage.setItem('postProjectName',project_name)
										this.props.history.replace(`/hire/schedule-call?name=${name}&email=${email}`)
									}else{
										// this.props.history.replace('/profile_selection');
										this.props.history.replace({
											pathname: '/profile_selection',
											state: { project_name: project_name }
										});

									}
							}
						}
					}).catch((error) => {
						console.log(error);
					})
					this.setState({ isLoading: false })
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("ProjectName", JSON.stringify(project_name));
			this.props.OpenSignupLogin(true);
			this.props.history.push('/login');
			this.setState({ isLoading: false })
		}
	}



	handleChange(e) {
		this.props.projectNameEvent(e.target.value)
	}

	render() {
		let { budget_subtext, projectname, } = this.props;

		return (
			<>
				<section>
					<div className="card-wrapper mob-mb-70">
						<div className="card mob-border-radius-0">
							<div className="card-body">
								<div className="row mx-0 mt-1 overflow-content project-height">
									<div className="col-sm-12 mob-col-100 px-md-0">
										<h2 className="pt-5 pl-md-4 pr-4 mob-pl-0 mob-pr-15 mob-pt-18 font-22 mob-font-16 font-weight-600">Name your project
											<span className="text-danger">*</span>
										</h2>
										<div className="custom_form px-4 pb-4 mob-p-0 mob-mb-45">
											<div className="form-group">
												<p className="mb-3 label font-weight-500 mob-font-13 text-third"></p>
												<input maxLength={100} type="text" className={`form-control email ${this.props.project_name_error ? 'border-error' : ''} `} placeholder={this.props.project_name_placeholder} name="project_name" value={this.props.project_name}
													onChange={
												(e) => {
													// newHandleChange(e)
													this.props.OnFieldChange(e.target)
												}} 
												autoComplete="off"
												/>
												{
													this.props.project_name_error && this.props.project_name.trim().length === 0 && <p className="mb-0 text-danger font-weight-600 font-12 py-1">Add project name to continue.</p>
												}
												{
													this.props.project_name.trim().length !== 0 && this.props.project_name.trim().length < 5 && this.props.project_name_error && <p className="mb-0 text-danger font-weight-600 font-12 py-1">Project name must be greater than 5 characters.</p>
												}
											</div>
										</div> 
									</div> 
								</div>
								<div className="row m-0">
									<ProgressBar {...this.state} handleContinue={this.handleContinue} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}

const mapState = (store) => {
    let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
    return {
		budget_scope:postProjectData.budget_scope,
		projectId: postProjectData?.projectId,
        project_name_heading: postProjectData.project_name_heading,
        project_name_subtext: postProjectData.project_name_subtext,
        project_name_placeholder: postProjectData.project_name_placeholder,
        project_name: postProjectData.project_name,
        project_name_error: postProjectData.project_name_error,
		userName: globalReducer.Me.Username,
		isHireAgain:  postProjectData.isHireAgain,
		hireAgainCreatorId:postProjectData.hireAgainCreatorId,
		prevPostProjectId:postProjectData.prevPostProjectId
		
		
    }
}

const mapDispatch = (dispatchEvent) => ({
	OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state }),
	resetProject:(values) => dispatchEvent({ type: 'RESET_PROJECT_DATA', payload: values }),
	changeHireAgainStatus: (val) => dispatchEvent({ type: 'UPDATE_HIRE_AGAIN_STATUS', values: {creatorId: '', isHireAgain: val} }),

    OnFieldChange: (e) => dispatchEvent({ type: 'ON_FIELD_CHANGE_PROJECT_DETAILS', payload: { name: e.name, value: e.value } }),
    setProjectNameError : () => dispatchEvent({type: 'SET_PROJECT_NAME_ERROR', values: true}),
	PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),


})
export default connect(mapState, mapDispatch)(ProjectName);