import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../../utils/API";
import NotFound from "../../../Not-Found/NotFound";
import FixedProposal from "./FixedProposal/FixedProposal";
import Onactual from "./Onactuals/Onactual";
import RetainerProposal from "./RetainerProposal/RetainerProposal";

const Proposal = (props) => {
  const { id, type } = props.match.params;
  const [data, setData] = useState({});
  const [showSubmitProposal, setShowSubmitProposal] = useState(false);

  const changeShowSubmitProposal = (value) => {
    setShowSubmitProposal(value);
  };

  useEffect(() => {
    handleGetApi(id);
  }, []);

  const handleGetApi = async (id) => {
    try {
      const res = await getAPI(`/project/get-post-project-details-brief?post_project_id=${id}`);
      if (res) {
        let data = res?.data;
        setData(data);
      }
    } catch (err) {
      console.log("client brief error");
    }
  };

  const newProps = {
    showSubmitProposal,
    setShowSubmitProposal,
    changeShowSubmitProposal,
  };

  return (props.CurrentRole && props.CurrentRole == 2 ?
    <>
      {type.toLowerCase() === "fixed" ? (
        <FixedProposal id={id} data={data} {...props} {...newProps} />
      ) : type.toLowerCase() === "retainer" ? (
        <RetainerProposal id={id} data={data} {...props} {...newProps} />
      ) : type.toLowerCase() === "actual" ? (
        <Onactual id={id} data={data} {...props} {...newProps} />
      ) : (
        ""
      )}
    </> : <NotFound />
  );
};

export default Proposal;
