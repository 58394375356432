import React from 'react';
import { connect } from 'react-redux';
import MonthYearDP from '../../Common/Common/MonthYearDP';
import { allPreviousYear, plus6years } from '../../../../utils/PreviousYear';
import { ExperienceMonth } from '../../../../utils/PreviousYear';
import ErrorSpan from '../../Common/Common/ErrorSpan';
import {getAPI} from '../../../../utils/API'

class EducationForm extends React.Component {

  state={
    schoolList: [],
    showSchoolList: false,
  }

  componentDidMount() {
    if(this.props.StartYear){
      this.setState({next6Years: plus6years(parseInt(this.props.StartYear,10)) })
    }
  }

  onSubmit = (e) => {
    // e.preventDefault();
    if (this.onStartEndDate({})) {
      return '';
    }
    console.log(
      'InstituteImage in AdddUpdateEducation ',
      this.props.InstituteImage
    );

    this.props.AddUpdateEducation({
      name: this.props.School,
      Institute_image: this.props.InstituteImage,
      course: this.props.Course,
      start_month: this.props.StartMonth,
      start_year: this.props.StartYear,
      end_month: this.props.EndMonth,
      end_year: this.props.EndYear,
      _id: this.props.ID,
    });
  };

  onStartEndDate = (e) => {
    let Months = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    this.props.onDPChange({ name: e.name, value: e.value });

    let StartMonth =
      e.name === 'USEREDUCATION_STARTMONTH' ? e.value : this.props.StartMonth;
    let StartYear =
      e.name === 'USEREDUCATION_STARTYEAR' ? e.value : this.props.StartYear;
    let EndMonth =
      e.name === 'USEREDUCATION_ENDMONTH' ? e.value : this.props.EndMonth;
    let EndYear =
      e.name === 'USEREDUCATION_ENDYEAR' ? e.value : this.props.EndYear;
    let r = false;

    if (
      EndYear !== '' &&
      StartYear !== '' &&
      StartMonth !== '' &&
      EndMonth !== ''
    ) {
      let StartDate = StartYear + Months[StartMonth];
      let EndDate = EndYear + Months[EndMonth];
      if (StartDate > EndDate) {
        r = true;
        this.dispatchAction(true);
      }
    }

    if (!r) {
      this.dispatchAction(false);
    }
    return r;
  };

  dispatchAction = (e) => {
    this.props.onDPChange({
      name: 'USEREDUCATION_ENDMONTH_ERROR',
      value: { error: e },
    });
    this.props.onDPChange({
      name: 'USEREDUCATION_ENDYEAR_ERROR',
      value: { error: e },
    });
  };

    getSchoolList = (value) => {
      getAPI(`/institute?name=${value}`).then((res) => {
        // console.log('%c SCHOOL LIST', 'font-size: 22px; color:orange');
        // console.log('Response is ', res.data.data);
        this.setState({ schoolList: res.data.data });
      });

    };

  onChangeHandler = (e) => {
    this.props.onDPChange({
      name: 'USEREDUCATION_SCHOOL',
      value: e.target.value,
    })
    // getSchoolList
    this.getSchoolList(e.target.value)
    this.setState({showSchoolList: true})
  }

  onClickHandler = () => {
    // getSchoolList
    this.getSchoolList('')
    this.setState({showSchoolList: !this.state.showSchoolList})
  }

  setSchoolNameHAndler = (obj) => {
    this.props.onDPChange({
      name: 'USEREDUCATION_SCHOOL',
      value: obj.name,
    })
  }

  onChangeStartYear = (e) => {
    this.onStartEndDate({
      name: 'USEREDUCATION_STARTYEAR',
      value: e,
    });
    // console.log('%c plus6years', 'font-size: 22px; color:orange');
    // console.log(e)
    const next6Years= plus6years(parseInt(e,10))
    this.setState({next6Years: next6Years})
    // console.log(next6Years);
    
  }

  render() {
    let {
      School,
      Course,
      StartMonth,
      StartYear,
      EndMonth,
      EndYear,
      edit,
      SchoolError,
      CourseError,
      StartMonthError,
      EndMonthError,
      StartYearError,
      EndYearError,
      SchoolErrorMessage,
      CourseErrorMessage,
      StartMonthErrorMessage,
      EndMonthErrorMessage,
      StartYearErrorMessage,
      EndYearErrorMessage,
      // saving,
    } = this.props;

    const {schoolList, showSchoolList} = this.state;

    return (
      <div
        className={`wrapper-form p-4 mb-4 mob-p-15 ${
          edit !== -1 ? 'border-top-0-10 border-top-none' : ''
        }`}
      >
        <form
          onSubmit={(e) => {
            this.onSubmit(e);
          }}
        >
          <div className="row m-0">
            <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
              <label className="label font-weight-600" htmlFor="first">
                Name of Educational Institution
              </label>
              <input
                type="text"
                onChange={(e) => this.onChangeHandler(e) }
                value={School}
                className={`form-control email pl-3 ${
                  SchoolError === true ? 'border-error' : ''
                }`}
                placeholder="Eg: National Institute of Design"
                onClick={this.onClickHandler}
              />
              <ul
                  className="select-options select-position w-130 mt-15px hideList4"
                  style={{
                    display: `${
                      showSchoolList && schoolList.length ? 'block' : 'none'
                    }`,
                  }}
                >
                  <li rel="hide">Choose here</li>
                  {schoolList.map((obj, index) => (
                    <li
                      key={index}
                      onClick={() => this.setSchoolNameHAndler(obj)}
                    >
                      {obj.name}
                    </li>
                  ))}
                </ul>
              <ErrorSpan error={SchoolError} message={SchoolErrorMessage} />
            </div>
            <div className="form-group position-relative mb-4 col-sm-6 mob-p-0">
              <label className="label font-weight-600" htmlFor="primary">
                Name of Course
              </label>
              <input
                type="text"
                onChange={(e) =>
                  this.props.onDPChange({
                    name: 'USEREDUCATION_COURSE',
                    value: e.target.value,
                  })
                }
                value={Course}
                className={`form-control email pl-3 ${
                  CourseError === true ? 'border-error' : ''
                }`}
                placeholder="Eg: Bachelor of Design"
              />
              <ErrorSpan error={CourseError} message={CourseErrorMessage} />
            </div>
            <div className="form-group position-relative mb-4 col-sm-6 pl-0 mob-p-0">
              <label className="label font-weight-600" htmlFor="primary">
                Start Date
              </label>
              <div className="d-flex align-items-center">
                <div className="filter-dropdown wrapper-dropdown w-48 position-relative">
                  <div className="select">
                    <div>
                      <MonthYearDP
                        Title={StartMonth}
                        List={ExperienceMonth}
                        Key={'name'}
                        onChange={(e) => {
                          this.onStartEndDate({
                            name: 'USEREDUCATION_STARTMONTH',
                            value: e.name,
                          });
                        }}
                        error={StartMonthError}
                      />
                    </div>
                  </div>
                  <ErrorSpan
                    error={StartMonthError}
                    message={StartMonthErrorMessage}
                  />
                  <i
                    className="fas fa-chevron-down select-arrow"
                    style={{ top: '20px', right: '20px' }}
                  ></i>
                </div>
                <div className="filter-dropdown wrapper-dropdown w-48 ml-3 position-relative">
                  <div className="select">
                    <div className="select">
                      <MonthYearDP
                        Title={StartYear}
                        List={allPreviousYear}
                        Key={''}
                        onChange={(e) => this.onChangeStartYear(e)}
                        error={StartYearError}
                      />
                    </div>
                  </div>
                  <ErrorSpan
                    error={StartYearError}
                    message={StartYearErrorMessage}
                  />
                  <i
                    className="fas fa-chevron-down select-arrow"
                    style={{ top: '20px', right: '20px' }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="form-group position-relative mb-4 col-sm-6 mob-p-0">
              <label className="label font-weight-600" htmlFor="primary">
              End Date (or Expected)
              </label>
              <div className="d-flex align-items-center">
                <div className="filter-dropdown wrapper-dropdown w-48 position-relative">
                  <div className="select">
                    <div className="select">
                      <MonthYearDP
                        Title={EndMonth}
                        List={ExperienceMonth}
                        Key={'name'}
                        onChange={(e) => {
                          this.onStartEndDate({
                            name: 'USEREDUCATION_ENDMONTH',
                            value: e.name,
                          });
                        }}
                        error={EndMonthError}
                      />
                    </div>
                  </div>
                  <ErrorSpan
                    error={EndMonthError}
                    message={EndMonthErrorMessage}
                  />
                  <i
                    className="fas fa-chevron-down select-arrow"
                    style={{ top: '20px', right: '20px' }}
                  ></i>
                </div>
                <div className="filter-dropdown wrapper-dropdown w-48 ml-3 position-relative">
                  <div className="select">
                    <div className="select">
                      <MonthYearDP
                        Title={EndYear}
                        List={this.state.next6Years}
                        Key={''}
                        onChange={(e) => {
                          this.onStartEndDate({
                            name: 'USEREDUCATION_ENDYEAR',
                            value: e,
                          });
                        }}
                        error={EndYearError}
                      />
                    </div>
                  </div>
                  <ErrorSpan
                    error={EndYearError}
                    message={EndYearErrorMessage}
                  />
                  <i
                    className="fas fa-chevron-down select-arrow"
                    style={{ top: '20px', right: '20px' }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="form-group text-right pr-3 pt-3 mob-p-0">
            {edit !== -1 ?
              <button type="submit" className="main-btn px-4"
                disabled={saving}
              >{saving ? "Updating..." : "Update"}</button> :
              <button type="submit" className="main-btn px-4"
                disabled={saving}
              >{saving ? "Saving..." : "Save"}</button>
            }
          </div> */}
        </form>
      </div>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let Education = store.userEducation;

  return {
    saving: globalReducer.saving,
    courseList: Education.courseList,
    School: Education.School,
    Course: Education.Course,
    StartMonth: Education.StartMonth,
    StartYear: Education.StartYear,
    EndMonth: Education.EndMonth,
    EndYear: Education.EndYear,
    ID: Education.ID,
    InstituteImage: Education.InstituteImage,

    SchoolError: Education.SchoolError,
    CourseError: Education.CourseError,
    StartMonthError: Education.StartMonthError,
    EndMonthError: Education.EndMonthError,
    StartYearError: Education.StartYearError,
    EndYearError: Education.EndYearError,
    SchoolErrorMessage: Education.SchoolErrorMessage,
    CourseErrorMessage: Education.CourseErrorMessage,
    StartMonthErrorMessage: Education.StartMonthErrorMessage,
    EndMonthErrorMessage: Education.EndMonthErrorMessage,
    StartYearErrorMessage: Education.StartYearErrorMessage,
    EndYearErrorMessage: Education.EndYearErrorMessage,

    edit: Education.edit,
  };
};

const mapDispatch = (dispatchEvent) => ({
  onDPChange: (e) =>
    dispatchEvent({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  AddUpdateEducation: (e) =>
    dispatchEvent({ type: 'ADD_USEREDUCATION_SAGA', payload: e }),
});

export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  EducationForm
);
