import React from 'react';
import { connect } from 'react-redux';
import { getAPI, postAPI } from '../../../../utils/API';
// import BtnLoader from '../../Common/Common/BtnLoader';
import Loader from '../../../unauthorized/Common/Loader/Loader';

class Industries extends React.Component {
  state = {
    enable: false,
    secondarySection: '',
    updatedIndustry:[],
    updateToolsError:false,
    loader:false,
  };

  updateIndustries = async() =>{
    try{
      this.setState({loader:true})
      const response = await postAPI('/project/user-industry',{industries:this.state.updatedIndustry})
      if(response.status===200){
        this.props.updateIndustryValue(this.state.updatedIndustry);
        this.setState({loader:false})
        this.closePopup();
        // this.props.loadIndustry();
        // this.props.updateCreatorIndustriesProfile(this.state.updatedIndustry)
      }
      else{
        this.setState({loader:false})
        alert('Something went Wrong')
      }
    }catch(err){
      this.setState({loader:false})
      console.log(err)
    }
  }

  validate = () =>{
    let {updatedIndustry} = this.state;
    if(updatedIndustry.length===0){
      this.setState({updateToolsError:true})
      return false
    }
    else if(updatedIndustry.length>0){
      this.setState({updateToolsError:false})
      return true
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if(this.validate()){
      this.updateIndustries();
      
    }
    // this.props.UpdateUserSoftware({
    //   software: this.props.UpdateTools,
    // });
  };

  ShowList = () => {
    this.setState({ enable: !this.state.enable });
  };

  updateCreatorIndustries = (value) => {
    let tempIndustryArr = [...this.state.updatedIndustry];
    tempIndustryArr.push(value);
    this.setState({updatedIndustry:tempIndustryArr})
  }

  onSelect = (obj) => {
    
    let Tools = [...this.state.updatedIndustry];
    
    let i = -1;
    Tools.forEach((s, k) => {
      if (s.name === obj.name) {
        i = k;
      }
    });
    
    if (i === -1) {
      this.updateCreatorIndustries(obj)
      this.setState({updateToolsError:false})
      Tools.push(obj)
      
    }
    else {
      alert(`${obj.name} is already selected.`)
    }

    // this.props.onFieldChange({
    //   name: 'SOFTWARE_UPDATE',
    //   value: Tools,
    // });
    this.setState({
      enable: false,
      secondarySection: '',
    });
    // this.props.loadIndustry('');
  };

  deleteItems = (obj) => {
    let Tools = [...this.state.updatedIndustry];

    let tempArr = Tools.filter(el=>el._id!==obj._id)
    this.setState({updatedIndustry:tempArr})
    // let i = -1;

    // Tools.forEach((s, k) => {
    //   if (s.name === obj.name) {
    //     i = k;
    //   }
    // });

    // if (1 !== -1) {
    //   Tools.splice(i, 1);
      
    // };


    // this.props.onFieldChange({
    //   name: 'SOFTWARE_UPDATE',
    //   value: Tools,
    // });

    this.setState({
      enable: false,
      secondarySection: '',
    });
    // this.props.loadIndustry('');
  };

  handleOnChange = (e) => {

    this.setState({
      secondarySection: e.target.value,
      enable: true
    });
    // this.props.loadIndustry(e.target.value);
  };

  closePopup = () => {
    // this.props.onFieldChange({
    //   name: 'SOFTWARE_UPDATE',
    //   value: [...this.props.Tools],
    // });
    this.props.closePopup();
    this.props.openedFrom=='dashboard' && this.props.showPopupForDetails('Availability')
  };

componentDidMount(){
  this.setState({updatedIndustry:this.props.updatedIndustry?this.props.updatedIndustry:this.state.updatedIndustry})
}

  render() {
    const { updateToolsError } = this.state;
    const {saving} = this.props;
    const toolsHelp = <a className='font-12 d-block py-1' target="_blank" rel="noopener noreferrer" style={{color: '#0056b3'}} href="https://indiefolio.typeform.com/to/woeBhaXO">Did not find the softwares that you use? Let us know</a>
    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditToolsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Industries</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <form onSubmit={(e) => this.onSubmit(e)}>
                        <div className="row m-0">
                          <div className="form-group w-98 mb-4 col-sm-12 p-0 mob-p-0 mob-w-100">
                            <label
                              className="label font-weight-600 font-14 mob-font-13"
                              htmlFor="last"
                            >
                              Select Industries you work with
                            </label>
                            <div
                              className={`select-tags d-flex align-items-center flex-wrap ${
                                updateToolsError === true ? 'border-error' : ''
                              }`}
                            >
                              <div>
                                {this.state?.updatedIndustry?.map((obj, index) => (
                                  <span className="selected-tags common-tags py-10 px-15" key={index}>
                                    {obj.name} <i className="fas fa-times ml-1 font-13" onClick={() => this.deleteItems(obj)}></i>
                                  </span>
                                ))}
                                {this.state?.updatedIndustry?.length < 10 && 
                                  <span style={{ display: 'inline-block' }}>
                                    <input
                                      type="text"
                                      className="w-230 form-control email pl-3 tags-input py-3"
                                      placeholder="Eg: Photoshop, Illustrator"
                                      autoComplete="off"
                                      name="specilization"
                                      value={this.state.secondarySection}
                                      onChange={this.handleOnChange}
                                      onClick={this.ShowList}
                                    />
                                  </span>
                                 } 
                              </div>
                              {this.props.AllTools.length > 0 &&
                              this.state.enable === true ? (
                                <ul
                                  className="select-options select-top100 hideList"
                                  style={{ display: 'block' }}
                                >
                                  <li rel="hide">Choose here</li>
                                  {this.props.AllTools &&
                                    this.props.AllTools.map((obj, index) => (
                                      <li
                                        key={index}
                                        onClick={() => this.onSelect(obj)}
                                      >
                                        {obj.name}
                                      </li>
                                    ))}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </div>
                            {updateToolsError &&
                              <span className="text-danger font-weight-600 font-12 py-1">Add Industries to continue </span> 
                            }
                          </div>
                        </div>
                        {/* <div className="form-group text-right pt-3 mob-pr-0 mob-mb-30 mob-pt-0">
                          <button type="submit" className="main-btn px-4" disabled={saving}>
                            {saving === true
                              ?
                              "Saving..."
                              :
                              "Save"
                            }
                          </button>
                        </div> */}
                      </form>
                    </div> 
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-danger col-sm-2 col-5 mb-2 mx-2 font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className={`main-btn col-sm-3 col-6 mb-2 mx-2 ${saving ? 'p-0' : ''}`}
                        onClick={this.onSubmit}
                        data-dismiss="modal"
                        disabled={saving}
                      >
                        {saving === true ? <Loader profileCreator="true"/> : 'Save'}
                      </button>
                      {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  return {
    saving: globalReducer.saving,
    closeModal: creatorProfile.closeModal,
    UpdateTools: creatorProfile.UpdateTools,
    AllTools: store.userWork.AllIndustry,
    Tools: creatorProfile.UserIndustries,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UpdateUserSoftware: (e) =>
    dispatch({ type: 'UPDATE_SOFTWARE_SAGA', payload: e }),
  loadIndustry: (e) =>
    dispatch({ type: 'LOAD_CREATOR_INDUSTORY_SAGA', payload: e }),
  updateIndustryValue:(e)=>{dispatch({type:'UPDATE_CREATOR_INDUSTRIES',values: e})}
});

export default connect(mapState, mapDispatch)(Industries);
