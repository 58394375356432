import React, { Component } from 'react';
import { connect } from 'react-redux'
import dummy from './../../../../Assets/images/account-manager.png';
class InComingCall extends Component {
    handleClose = () => {
        this.props.closePopup()
     }
     render() { 
        return (
             <React.Fragment>
                  <div className="modal custom_form calling_modal" id="VerifyModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                    <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("./../../../../Assets/images/close.png")} className="img-fliud" width="35" alt='' /></span>  
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content"> 
                        <div className="modal-body">
                            <div className="row m-0">
                                <div className="col-sm-12 text-center p-4"> 
                                    <div className="calling_wrapper">
                                        <span className="text-sub font-14 font-weight-500 line-height-normal">Incoming call from...</span>
                                        <h2 className="font-18 font-weight-600 line-height-normal">Karan Chaudhary</h2> 
                                        <div className="calling-image">
                                            <div className="profile-user-image">
                                                <img src={dummy} alt="Calling-user"></img>
                                            </div>
                                        </div> 
                                        <div className="calling_buttons">
                                            <span className=""><i class="fas fa-phone"></i></span>
                                            <span className=""><i class="fas fa-phone"></i></span>
                                        </div>
                                    </div>                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-backdrop show" style={{display: 'block'}}></div>
             </React.Fragment>
        )
    }
}

const mapDispatch =  dispatch => ({
        closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    }) 

export default connect(null, mapDispatch)(InComingCall)