import React, { Component } from 'react';
import firebaseIs from '../../../utils/fire';
import axios from 'axios';
import { postPublicAPI, postAPI } from '../../../utils/API'
import { Baseurl } from '../../../utils/BaseUrl';
import Loader from './../Common/Loader/Loader';
import { limitString } from '../../../utils/Helperfunctions';
import { logoutUserRedirect } from '../../authorized/Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';


export default class Password extends Component {

  state = {
    password: ''
  }

  handleOnchange = (event) => {
    this.setState({[event.target.name]: event.target.value, passError : false})
  }

  handleHidePassOnClick = () => {
    // this.setState({password: ''})
    // this.props.setHomeState({passwordModal: false, userEmail: ''})

    logoutUserRedirect()

  }

  onEmailClick = (e) => {
    e.preventDefault();
    this.props.setHomeState({passwordModal: false, emailModal: true})
  }

  adminLogin = (e) => {
    const {userEmail} = this.props;
    const { password } = this.state;

    this.setState({isLoading: true})

    const data = {email: userEmail, password: password}
    postPublicAPI('/users/admin-login', data)
      .then(res => {
        // console.log('%c API RESPONSE', 'font-size: 22px; color:orange');
        // console.log(res.data)
        let userToken = res.data.token;
        localStorage.setItem("SignUpidToken", userToken);
        this.props.reload();
        this.setState({isLoading: false});
      }).catch(err => {
        console.log(err);
        alert("Invalid Password. Please try again.")
        this.setState({isLoading: false});
      })
  }
  managerLogin = (e) => {
    const {userEmail} = this.props;
    const { password } = this.state;

    this.setState({isLoading: true})

    const data = {email: userEmail, password: password}
    postPublicAPI('/users/manager-login', data)
      .then(res => {
        // console.log('%c API RESPONSE', 'font-size: 22px; color:orange');
        // console.log(res.data)
        let userToken = res.data.token;
        localStorage.setItem("SignUpidToken", userToken);
        this.props.reload();
        this.setState({isLoading: false});
      }).catch(err => {
        console.log(err);
        alert("Invalid Password. Please try again.")
        this.setState({isLoading: false});
      })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {isAdminWantsLogin,userData} = this.props;
    if(isAdminWantsLogin ){
      this.adminLogin(e)
    } else if(userData?.data?.current_set_role == 3){
      this.managerLogin(e);
    }else {
      this.password(e)
    }
  }

  password = (e) => {
    const that = this;

    this.setState({isLoading: true})
    const { password } = this.state;
    const {userEmail, loginTypeIs, firebaseOnlyUser} = this.props;
    // console.log(password, userEmail, loginTypeIs, firebaseOnlyUser)
    
      if(password.length){
        firebaseIs.auth().signInWithEmailAndPassword(userEmail, password)
        .then(result => {
          if(firebaseOnlyUser){
            that.props.setHomeState({createNewAccountModal: true, passwordModal: false})
          } else {
            // Get firebase token
            firebaseIs.auth().currentUser.getIdToken().then(function (idToken) {
              const loginTokenIs = { token: idToken, login_type: loginTypeIs }
              axios({
                method: 'post',
                url: Baseurl + '/users/login', 
                headers: { 'content-type': 'application/json' },
                data: loginTokenIs,
          
              }).then((res => {
                let userToken = res.data.token
                localStorage.setItem("SignUpidToken", userToken)
                // that.props.setHomeState({passwordModal: false})

                //  check if user fill post a project before login
                that.props.reload()
              //   if(that.props.isLoginOrSignupAsClient){
                  
              //     postAPI('/users/user-role-change', { current_set_role: 1 }).then((res) => {
              //         that.props.reload()
              //       }).catch((error) => {
              //         console.log("change-rol-api error----->", error)
              //       })
              // }else{
              //     that.props.reload();
              // }

              })).catch((error) => {
                console.log("login-api-error------>", error);
                that.setState({isLoading: false})
              })
            }).catch(function (error) {
              console.log("firebase login error", error)
              that.setState({isLoading: false})
            });
          }
        })
        .catch(error => {
          console.log("---////error >", error);
          this.setState({isLoading: false})
          alert("Username or password did not match. Please try again.")
        })    
      }else{
        this.setState({passError: true, isLoading: false}) 
      }
      
  }

  handleForgetPassword = (e) => {
    e.preventDefault();
    this.props.setHomeState({forgetPasswordModal: true, passwordModal: false})
  }
  
  render() {
    const { userData, userEmail } = this.props;
    const name = userData.data ? userData.data.first_name : ''
    // console.log(this.props) 
    console.log("activePath for = ",this.props?.activePath)

    return (
      <React.Fragment>
          <div className="modal custom_form custom_Modal_Box" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                  <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleHidePassOnClick}><img alt="" src={require("../../../Assets/images/close.png")} className="img-fliud" width="35" /></span>
                  <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                    <div className="modal-content user_bg">
                      <div className="modal-body">
                        <div className="row m-0">
                          <div className="col-sm-7 mob_background_image">
                            <div className="mob-login-background"></div>
                          </div>
                          <div className="col-sm-5 overflow-content mob-overflow-content user_modal">
                            <div className="login welcome_login zoomin">
                              <div className="user">
                                <img alt="" src={require("../../../Assets/images/user.svg")} className="img-fliud" style={{ "width": "85px" }} />
                                <h2>Welcome, <br/> 
                                {/* {name} */}
                                {name ? limitString(name, 15) : name }
                                </h2>
                              </div>
                              <form onSubmit={(e) => this.handleSubmit(e)}>
                                <div className="form-group user_details">
                                  <p>Enter password for</p>
                                  <a href="/" onClick={this.onEmailClick}>{userEmail}</a>
                                </div>
                                <div className="form-group">
                                  <input type="password" className={`form-control email ${this.state.passError ? 'border-error' : ''}`} placeholder="Password" name='password' autoFocus
                                    onChange={this.handleOnchange}
                                  />
                                  {this.state.passError && <span className="text-danger font-weight-600 font-12 py-1">Please enter your password to continue</span>}
                                </div>
                                
                                <div className="dflex">
                                  <div className="form-group custom_checkbox">
                                    <input type="checkbox" id="signed"
                                      onChange={this.handleCheckedOnChange}
                                    />
                                    <label htmlFor="signed" className="password_screen">Stay signed in</label>
                                  </div>
                                  <div className="form-group">
                                    <p className="sub_title mb-2">
                                      <a href="/" onClick={this.handleForgetPassword}>
                                        Forgot password?
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="form-group">
                                  {
                                    <button type="submit" className="main-btn mob-w-100 font-16 mob-font-14" disabled={this.state.isLoading}
                                      onClick={this.handleSubmit}
                                    >{this.state.isLoading ? <Loader isPasswordModal={true}/> : 'Continue'} 
                                    {!this.state.isLoading && <i className="fas fa-arrow-right"></i>}</button>
                                  }
                                </div>
                                {/* <div className="reCaptcha mt-5">
                                  <p className="pl-0">This Site is protected by reCAPTCHA and the <br /> Google Privacy Policy and Teams of Service Apply.</p>
                                </div> */}
                              </form>
                            </div>
                          </div>
                          <div className="col-sm-7 remove-pd overflow-hidden">
                            <div className="login-background slideinleft"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="back-modal"></div>
      </React.Fragment>
    )
  }
}