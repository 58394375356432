import _ from 'lodash';
import validator from 'validator';

export default function ValidateAction(Action, Data) {
  let error = {
    error: false,
    errorMessage: '',
  };
  let Definition = Action;
  if (_.size(Definition) < 1)
    return {
      error: false,
      errorMessage: 'Validation Missing',
    };

  let ValidClass = new Validate(Action);
  _.forEach(Definition, (v, k) => {
    let r = ValidClass.verify(k, v, Data[k]);
    error[v[4] + 'ErrorMessage'] = r;
    error[v[4] + 'Error'] = false;
    if (r !== '') {
      error.error = true;
      error[v[4] + 'Error'] = true;
    }
  });

  return error;
}

class Validate {
  constructor(Action) {
    this.Action = Action;
  }

  verify(key, defi, value) {
    let error = '';

    error = this.ifRequired(defi[0], value, defi[1], defi[3]);
    if (error !== '')
      return error;

    error = this.dataTypeCheck(key, value, defi[1], defi[0]);
    if (error !== '')
      return defi[4] + ' ' + error;

    error = this.lengthRegexCheck(defi[0], value, defi[1]);
    if (error !== '')
      return defi[4] + error;
    return '';
  }

  ifRequired(required, data, dataType, message) {
    if (required === 0)
      return '';
    // _.size(data) < 1 || 
    if (!data || data === '' || data === null) {
      if (message) {
        return message;
      }
      return ' Can Not Be Blank';
    }
    return '';
  }

  lengthRegexCheck() {
    return '';
  }

  // possible infinite loop case.
  dataTypeCheck(DType, value, SecondType, required) {
    let resp = '';
    value = (typeof value === 'string') ? validator.trim(value) : value;

    switch (DType) {
      case 'Number':
        if ((!value || value === '') && required === 0) {
        } else {
          if (value === null || isNaN(value)) {
            resp = " Can Only be Number. Given:" + value;
          }

          if (value < 0) {
            resp = " Number Can't Be Lower then 0. Given:" + value;
          }
        }
        break;
      case 'Bool':
        if ((!value || value === '') && required === 0) {
        } else {
          if (typeof value !== 'boolean') {
            resp = " Can Only Be Boolean. Given:" + value;
          }
        }
        break;
      case 'Date':
        if ((!value || value === '') && required === 0) {
        } else {
          if (value === '') {
            resp = " is Not a Valid Date. Given:" + value;
          } else {
            let newDd = new Date(value);
            if (newDd instanceof Date === false) {
              resp = " is Not a Valid Date. Given:" + value;
            }
          }
        }
        break;
      case 'Email':
        if ((!value || value === '') && required === 0) {
        } else {
          if (!validator.isEmail(value)) {
            resp = " is Not a Valid Email. Given:" + value;
          }
        }
        break;
      case 'File':
        if ((!value || value === '') && required === 0) {
        } else {
          if (!value.name || typeof value.name !== 'string') {
            resp = " is Not a Valid File.";
          }
        }
        break;
      case 'DOB':
        let newD = new Date(value);
        if (!newD instanceof Date) {
          resp = " is Not a Valid Date. Given:" + value;
        }
        let currDate = new Date()
        if (newD > currDate) {
          resp = " is Not a Valid. Given:" + value;
        }
        // Check DOB for Age and DateType
        break;
      case 'FirstName':
        if ((!value || value === '') && required === 0) {
        } else {
          if (!validator.isAlpha(value, 'en-US')) {
            resp = " Can Not Contain Number. Given:" + value;
          }
        }
        break;
      case 'Name':
        if ((!value || value === '') && required === 0) {
        } else {
          if (!validator.isAlpha(value, 'en-US')) {
            resp = " Can Not Contain Number. Given:" + value;
          }
        }
        break;
      case 'LastName':
        if ((!value || value === '') && required === 0) {
        } else {
          let reg = /[^A-Z a-z]/;
          // !validator.isAlpha(value, 'en-US')
          if (reg.test(value)) {
            resp = " is Not Valid. Given:" + value;
          }
        }
        break;
      case 'Mobile':
        // eslint-disable-next-line
        if ((!value || value === '') && required === 0) {
        } else {
          //eslint-disable-next-line
          let reg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
          if (reg.test(value) !== true || value.toString().length !== 10) {
            resp = value + " is Not a Valid Number. Given:" + value;
          }
        }
        break;
      case 'Obj':
        if ((!value || value === '') && required === 0) {
        } else {
          if (typeof value !== 'object' || value.length < 1) {
            resp = " Can't be Blank. Given:" + value;
          }
        }
        break;
      case 'PinCode':
        if ((!value || value === '') && required === 0) {
        } else {
          if (isNaN(value)) {
            resp = " Can Only be Number. Given:" + value;
          }
          // eslint-disable-next-line
          if (value.length != 5) {
            resp = " Can Only be of 5 Digit. Given:" + value;
          }
        }
        break;
      case 'GST':
        // eslint-disable-next-line
        if ((!value || value === '') && required === 0) {
        } else {
          if (value == null) {
            resp = " GST Cant be blank. Given:" + value;
            return
          }
          if (!verifyGST(value)) {
            resp = value + " is not a Valid GST. Given:" + value;
          }

          // eslint-disable-next-line
          if (value.length != 15) {
            resp = " Can Only be of 15 Digit. Given:" + value;
          }
        }
        break;
      case 'String':
        if ((!value || value === '') && required === 0) {
        } else {
          if (typeof value !== 'string') {
            resp = " Can Only be String. Given:" + value;
          }
        }
        break;
      case 'Array':
        if ((!value || value === '') && required === 0) {
        } else {
          if (!value.length || value.length < 1) {
            resp = " Can not be Blank:" + value;
          }
        }
        break;
      case 'Url':
        if ((!value || value === '') && required === 0) {
        } else {
          //eslint-disable-next-line
          let reg = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
          if (!reg.test(value)) {
            resp = " is not a Valid Url:" + value;
          }
        }
        break;

      default:
        resp = this.dataTypeCheck(SecondType, value, '', required);
    }
    return resp;
  }
}

function verifyGST(g) {
  //eslint-disable-next-line
  let reg = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
  let r = reg.test(g);

  if (g.length !== 15) {
    return {
      Status: 'Not Valid',
      State: '00'
    }
  } else {
    return {
      Status: (r) ? 'Valid' : 'Not Valid',
      State: g.substr(0, 2)
    }
  }
}
