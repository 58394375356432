import React, { useEffect, useState } from "react";
import UserIcon from "../../../../../Assets/upgrade-images/view_brief/user.svg";
import CarIcon from "../../../../../Assets/upgrade-images/view_brief/car.svg";
import Loader from "../../../Common/Common/Loader";
import moment from "moment";
import { limitString } from "../../../../../utils/Helperfunctions";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import companyLogo from '../../../../../Assets/upgrade-images/dashboard/dummy_company.svg';
import DummyUserImg from '../../../../../Assets/upgrade-images/dashboard/dummy_user2.svg'
import RenderImageCommonComp from "./RenderImageCommonComp";

const ClientDetails = (props) => {
  const data = props?.projectDataStates?.data?.project_data;
  const loader = props?.projectDataStates?.projectDataloader;
  // const [imageLogo, setImageLogo] = useState("")

  const isIndividual =
    data?.company_work_type?.toLowerCase() === "company" || data?.company_work_type?.toLowerCase() === "agency" ? false : true;
                    

  return (
    <>
      <div className="page_card d-none d-lg-block mb-4">
        <div className="proposal_header  br_btm d-flex justify-content-between">
          <div className="prposal_heading d-flex align-items-center">
            <p className="mb-0">{props?.CurrentRole == 1 ? "Your Details" : "Client Details"}</p>
          </div>
        </div>

        {loader ? (
          <Loader isCreateModal={true} style={{ padding: "4rem" }} />
        ) : isIndividual ? (
          <div className="client_card row mx-0 px-4 mb-4">
            <div className="col-lg-12 d-flex py-4 px-0 mb-4 ">
            <div className="user_company_profile_image rounded-circle mr-3">
                  <img
                    src={DummyUserImg}
                    className="img-fluid"
                  />
                </div>
              <div className="profile_name">
                <p className="mb-0 text-capitalize text_gray mb-2"> Individual</p>
                {/* <p className="mb-0 text_gray_1100_w_600_sm">{moment(data?.createdAt).format("MMM D, YYYY")}</p> */}
                <p className="mb-0 text_gray_1100_w_600_sm">{data?.company_individual_location}</p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="client_card row mx-0 px-4 mb-4 ">
              <div className="col-lg-12 d-flex align-items-center py-4 px-0 br_btm mb-4 ">
                <div className="user_company_profile_image rounded-circle mr-3">
                  {/* <img
                    src={
                      data?.company_logo
                        ? BaseImageUrl + data?.company_logo
                        :imageLogo ? imageLogo : companyLogo
                    }
                    className="img-fluid"
                  /> */}
                  <RenderImageCommonComp companyLogo={data?.company_logo} companyUrl ={data?.company_add_link} />
                </div>
                <div className="profile_name">
                  <p className="mb-0 text_black_md"> {limitString(data?.company_name, 30)}</p>
                  <p className="anchor_blue_600_underline mb-0">
                    {" "}
                    {data?.company_add_link?.length > 0 ? (
                      <a
                        href={data.company_add_link?.includes("http") ? data.company_add_link : "http://" + data.company_add_link}
                        target="_blank"
                      >
                        {/* {data?.company_add_link} */}
                        {data?.company_add_link?.length > 40
                          ? data?.company_add_link?.slice(0, 40) + "..."
                          : data?.company_add_link}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>

              <div className="col-lg-12 mb-2 px-0">
                <div className="client_details">
                  <p className="mb-0 text_gray_1100_w_600_sm">Industry Type</p>
                  <p className="font_semi_bold_w_600">
                    <img src={CarIcon} className="img-fluid mr-2" />
                    {data?.company_industry}
                  </p>
                </div>
                {/* <div className="client_details">
                  <p className="mb-0 text_gray_1100_w_600_sm">PoC Location</p>
                  <p className="font_semi_bold_w_600">
                    <img src={UserIcon} className="img-fluid mr-2" /> {data?.company_employees}
                  </p>
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ClientDetails;

