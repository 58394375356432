import axios from 'axios';
import {store} from '../App'

// Add a response interceptor
axios.interceptors.response.use((response) => {
    console.log('testing')
    if(response.status === 200){
        // console.log({store})
    }
    return response
}, function (error) {

   if (error.response?.status === 401) {
        // history.push('/notFound1');
        // if(process.env.NODE_ENV !== 'production'){
            store.dispatch({type: 'LOGOUT_USER', value: null})
        // }
        return Promise.reject(error);
   }
   if (error.response?.status === 404) {
        // history.push('/notFound1');
        if(process.env.NODE_ENV !== 'production'){
            // store.dispatch({type: 'NOT_FOUND_SAGA', value: null})
        }
        return Promise.reject(error);
   }

   return Promise.reject(error);
});

export default axios