import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../unauthorized/Common/Loader/Loader';

class ProgressBar extends Component {

    

    render() {
        const {handleContinue,post_steps,total_steps, isLoading} = this.props;
        let progress_precent = (post_steps/total_steps)*100;
        
        return (
            <React.Fragment>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top mobile-hide">
                    <div className="card-wrapper mb-0">
                        <div className="row m-0 align-items-center p-3 mob-pt-py-15">
                            <div className="col-6 col-sm-8 mob-p-0">
                                <div className="d-flex align-items-center mob-d-block">
                                    <p className="mb-0 font-weight-500 font-14 small-font-12 font-weight-600 mob-pb-5">Project brief completion</p>
                                    <div className="progress w-30 mob-w-100 h-8 ml-3 mob-ml-0">
                                        <div className={`progress-bar bg-success`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{width:`${progress_precent}%`}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 text-right mob-p-0 mob-mt-10">
                               {this.props.skip&& <button disabled={isLoading} type="button" className="main-btn bg-secondary mob-font-14 mr-3" onClick={(e)=>handleContinue(e,true)}>
                                { <>Skip <i className="fas fa-arrow-right ml-1 mob-m-0"></i></>}
                                </button>}
                                <button disabled={isLoading} type="button" className="main-btn mob-font-14" onClick={handleContinue}>
                                {isLoading === true ?  <Loader isCreateModal={true} /> : <>Proceed <i className="fas fa-arrow-right ml-1 mob-m-0"></i></>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 mt-4 bg-shadow-top bg-white footer_fixed ">
                    <div className="card-wrapper mb-0">
                        <div className="row m-0 align-items-center p-4 mob-pt-py-15">
                            <div className="col-6 col-sm-8 mob-p-0">
                                <div className="d-flex align-items-center mob-d-block">
                                    <p className="mb-0 font-weight-500 font-14 small-font-12 font-weight-600 mob-pb-5">Project brief completion</p>
                                    <div className="progress w-30 mob-w-100 h-8 ml-3 mob-ml-0">
                                        <div className={`progress-bar bg-success`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{width:`${progress_precent}%`}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 text-right mob-p-0 mob-mt-10">
                            {this.props.skip&& <button disabled={isLoading} type="button" className="main-btn bg-secondary mob-font-10 mr-2" onClick={(e)=>handleContinue(e,true)}>
                                { <>Skip <i className="fas fa-arrow-right ml-1 mob-m-0"></i></>}
                                </button>}
                            <button disabled={isLoading} type="button" className={`main-btn ${this.props.skip ? 'mob-font-10' : 'mob-font-14'}`} onClick={handleContinue}> 
                                {isLoading === true ?  <Loader isCreateModal={true}/> : <>Proceed <i className="fas fa-arrow-right ml-1 mob-m-0"></i></>}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapState = (store) => { 
    let postProjectData = store.postProject;
	return {
        post_steps: postProjectData.post_steps,
        total_steps: postProjectData.total_steps,
	}; 
}

const mapDispatch = dispatchEvent => ({ 
});

export default connect(mapState,mapDispatch)(ProgressBar);