import DotImage from "../../../../../../Assets/images/vdot.svg";
import React, { useEffect, useRef, useState } from "react";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import HeaderIcon from "../../../../../../Assets/upgrade-images/submit_proposal/header_icon.svg";
import EditIcon from "../../../../../../Assets/upgrade-images/submit_proposal/edit.svg";
import ExclamationIcon from "../../../../../../Assets/upgrade-images/submit_proposal/info-circle.svg";
import DateIcon from "../../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import PercentIcon from "../../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PriceIcon from "../../../../../../Assets/upgrade-images/retainer_open/price_icon.png";
import SuccessProp from "../../SuccessProp";
import Select from "react-select";
import ReviewProposal from "../ReviewProposal";
import DatePicker from "react-date-picker";
import { getAPI, postAPI } from "../../../../../../utils/API";
import { separateNumber } from "../../../../ProfileCreator/TimeRetainer";
import ProjectDetail from "../../CommonComp/ProjectDetail";
import Reference from "../Reference/Reference";
import { addHrMinDate, convertToPlain, setTimeToZero, workDays } from "../../CommonFunctionsNew/CommonFunctionsNew";
import { useDispatch, useSelector } from "react-redux";
import {
  releventProjects,
  relevent_projects,
  projectAttachments,
  projectUrls,
} from "../../../../../../store/action/proposal.action";
import Loader from "../../../../../authorized/Common/Common/Loader";
import SubmitProp from "../../PopopCreator/SubmitProp";

export default function RetainerProposal(props) {
  const [states, setStates] = useState({
    propDuration: {
      prop_duration: { label: "Months", value: "Months" },
      prop_dur_num: 1,
    },
    numOfHours: {
      day_week: { label: "Day", value: "Day" },
      num_hour: 1,
    },
    retainer_fees_type: { label: "Monthly", value: "Monthly" },
    toggle: false,
    note: "",
    proposed_start_date: setTimeToZero(new Date()),
    fees: {
      retainerFees: "",
      advanceFees: "",
    },
    workingDaysInWeek: workDays,
    prefBillingCycle: { label: "Cycle End", value: "Monthly" },
    validation: {
      note: false,
      workingDaysInWeek: false,
      retainerFees: false,
      advanceFees: false,
      proposed_start_date: false,
      prop_dur_num: false,
      num_hour: false,
    },
    completed_milestone_no: 0,
  });
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const _ProjectsIs = useSelector((state) => state.releventProjects);
  const [showReviewProp, setShowReviewProp] = useState(false);
  const [showSuccessProp, setShowSuccessProp] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const retainerFeesRef = useRef(null);
  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: data?.proposal_type === "retainer" ? current_ids : [],
      releventProject: data?.proposal_type === "retainer" ? data?.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.proposal_type === "retainer" ? data?.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.proposal_type === "retainer" ? data?.urls : []));
  };

  // useEffect(() => {
  //   setProjectIds()
  //   setProjectAttachments()
  //   setAllUrls()
  // },[])

  // for redux state blank project reference ***********************

  useEffect(() => {
    setId(props?.id);
    if (props?.data?.project_data) handleGetProposal(props?.id);
  }, [props?.data]);

  const handleGetProposal = async (id) => {
    try {
      let res = "";
      let projectStatus = props?.data?.project_data?.status;
      if (projectStatus == 6 || projectStatus == 7) {
        res = await getAPI(`/proposal/proposal-latest-approved-data?id=${id}`);
      } else {
        res = await getAPI(`/proposal/proposal-payment?id=${id}`);
      }
      if (res && res?.data && res?.data?.AllProposal?.proposal_type === "retainer") {
        let data = res?.data?.AllProposal;
        // console.log("1===== ", data);
        getReferenceData(data);
        // states.propDuration.prop_duration =
        //   data?.proposed_duration_type?.length > 0
        //     ? {
        //         label: data.proposed_duration_type,
        //         value: data.proposed_duration_type,
        //       }
        //     : { label: "Months", value: "Months" };

        var tempNote = data?.note ? convertToPlain(data.note)  : "" 

        states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
        states.numOfHours.day_week =
          data?.number_of_hours_type?.length > 0
            ? {
                label: data.number_of_hours_type,
                value: data.number_of_hours_type,
              }
            : { label: "Day", value: "Day" };
        states.prefBillingCycle =
          data?.preferred_billing_cycle?.length > 0
            ? {
                label: data.preferred_billing_cycle == "Monthly" ? "Cycle End" : data.preferred_billing_cycle,
                value: data.preferred_billing_cycle,
              }
            : { label: "Cycle End", value: "Monthly" };
        states.retainer_fees_type =
          data?.retainer_fees_type?.length > 0
            ? {
                label: data.retainer_fees_type,
                value: data.retainer_fees_type,
              }
            : { label: "Monthly", value: "Monthly" };
        states.numOfHours.num_hour = data?.number_of_hours ? data.number_of_hours : 1;
        states.workingDaysInWeek = data?.working_days_in_week?.length === 7 ? data.working_days_in_week : workDays;
        states.fees.retainerFees = data?.retainer_fees ? data.retainer_fees : "";
        states.fees.advanceFees = data?.advance_amount ? data.advance_amount : "";
        states.toggle = data?.advance_amount ? true : false;
        states.note = tempNote;
        states.proposed_start_date = data?.proposed_start_date ? setTimeToZero(data.proposed_start_date) : setTimeToZero(new Date());
        states.completed_milestone_no = data?.completed_milestone_no ? data.completed_milestone_no : 0;

        setStates({ ...states });
        setLoading(false);
      } else {
        setLoading(false);
        getReferenceData([]);
      }
    } catch (err) {
      console.log("error");
      setLoading(false);
      getReferenceData([]);
    }
  };

  const handleNumbers = (action, which, e) => {
    let numbers = "";
    let dayHourCal = states.numOfHours.day_week.label === "Day";
    if (which === "duration") numbers = states.propDuration.prop_dur_num;
    else numbers = states.numOfHours.num_hour;
    if (action === "plus") {
      if (which === "duration") numbers == 0 ? (states.propDuration.prop_dur_num = 1) : (states.propDuration.prop_dur_num += 1);
      else numbers == 0 ? (states.numOfHours.num_hour = 1) : (states.numOfHours.num_hour += 1);
    } else if (action === "change") {
      if (which === "duration") states.propDuration.prop_dur_num = e.target.value > 0 ? Number(e.target.value) : e.target.value;
      else states.numOfHours.num_hour = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (which === "duration") {
        if (states.propDuration.prop_dur_num > 1) states.propDuration.prop_dur_num -= 1;
      } else {
        if (states.numOfHours.num_hour > 1) states.numOfHours.num_hour -= 1;
      }
    }
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    states.validation.num_hour =
      states.numOfHours.num_hour < 1 ||
      (states?.numOfHours?.num_hour > 24 && dayHourCal) ||
      (states?.numOfHours?.num_hour > 168 && !dayHourCal)
        ? true
        : false;
    if (which === "duration") setStates({ ...states, propDuration: { ...states.propDuration } });
    else setStates({ ...states, numOfHours: { ...states.numOfHours } });
  };

  const handleSelect = (selected, which) => {
    if (which === "Billing")
      // setStates({
      //   ...states,
      //   propDuration: { ...states.propDuration, prop_duration: selected },
      // });
      setStates({
        ...states,
        prefBillingCycle: selected,
      });
    else if (which === "retainer_fees_type") {
      setStates({
        ...states,
        retainer_fees_type: selected,
      });
    } else {
      states.validation.num_hour = false;
      setStates({
        ...states,
        numOfHours: { ...states.numOfHours, day_week: selected },
      });
    }
  };

  const scroll = () => {
    return retainerFeesRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const handleChangeFees = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // if (name == "advanceFees" && states.toggle) states.validation.advanceFees = parseInt(value) > 0 && (parseInt(value) < parseInt(states.fees.retainerFees) || states.fees.retainerFees == "") ? false : true;
    if (name == "advanceFees" && states.toggle) states.validation.advanceFees = parseInt(value) > 0 ? false : true;
    else states.validation.retainerFees = parseInt(value) > 0 ? false : true;
    setStates({
      ...states,
      fees: { ...states.fees, [name]: value },
    });
  };

  const handleChooseDays = (name) => {
    let arr = [];
    states.workingDaysInWeek.forEach((item) => {
      if (item.name === name) arr.push({ ...item, isActive: !item.isActive });
      else arr.push({ ...item });
    });
    let filter = arr?.filter((obj) => obj?.isActive);
    states.validation.workingDaysInWeek = filter.length > 0 ? false : true;
    setStates({
      ...states,
      workingDaysInWeek: [...arr],
    });
  };

  const handleChangeNote = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length <= 1000) {
      setStates({
        ...states,
        note: value,
      });
    }
  };

  const handleTypeChange = () => {
    props.changeShowSubmitProposal(true);
  };

  const handleValidation = () => {
    let valid = true;
    let dayHourCal = states.numOfHours.day_week.label === "Day";
    let filter = states?.workingDaysInWeek?.filter((item) => item.isActive)?.length;
    if (
      !filter > 0 ||
      ((!parseInt(states?.fees?.advanceFees) ||
        parseInt(states?.fees?.advanceFees) < 1 ||
        parseInt(states?.fees?.advanceFees) >= parseInt(states.fees.retainerFees)) &&
        states.toggle) ||
      states?.fees?.retainerFees < 1 ||
      states?.propDuration?.prop_dur_num < 1 ||
      states?.numOfHours?.num_hour < 1 ||
      (states?.numOfHours?.num_hour > 24 && dayHourCal) ||
      (states?.numOfHours?.num_hour > 168 && !dayHourCal)
    )
      valid = false;
    // if (states.toggle) states.validation.advanceFees = (!parseInt(states?.fees?.advanceFees) || parseInt(states?.fees?.advanceFees) < 1) || parseInt(states?.fees?.advanceFees) >= parseInt(states.fees.retainerFees )? true : false;
    if (states.toggle)
      states.validation.advanceFees =
        !parseInt(states?.fees?.advanceFees) || parseInt(states?.fees?.advanceFees) < 1 ? true : false;
    states.validation.workingDaysInWeek = !filter > 0 ? true : false;
    states.validation.retainerFees = states?.fees?.retainerFees < 1 ? true : false;
    states.validation.prop_dur_num = states.propDuration.prop_dur_num < 1 ? true : false;
    states.validation.num_hour =
      states?.numOfHours?.num_hour < 1 ||
      (states?.numOfHours?.num_hour > 24 && dayHourCal) ||
      (states?.numOfHours?.num_hour > 168 && !dayHourCal)
        ? true
        : false;
    if (states.propDuration.prop_dur_num <= states.completed_milestone_no) {
      valid = false;
      alert(
        `Proposed duration count can't be less than or equal to already paid cycles that is ${states?.completed_milestone_no}.`
      );
    }
    if (states.toggle && parseInt(states.fees.advanceFees) >= parseInt(states.fees.retainerFees)) {
      valid = false;
      alert(`Advance fees should be less than retainer fees`);
    }
    if ((states.validation.retainerFees || states.validation.advanceFees) && !states.validation.workingDaysInWeek) {
      scroll();
    }
    setStates({ ...states });
    return valid;
  };

  const handleChangeDate = (e) => {
    states.proposed_start_date = new Date(e);
    states.validation.proposed_start_date = e ? false : true;
    setStates({ ...states });
  };

  const onClickBack = () => {
    const { location, history, id, data } = props;
    if (history && data.project_data.project_slug) {
      if (location.pathname.includes("jobs")) {
        dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "IncomingBriefReviewJob" } });
        history.push(`/dashboard/jobs/:${id}/${data.project_data.project_slug}`);
      } else {
        history.push(`/projects/${props?.match?.params?.id}/overview`);
      }
    }
  };

  const handleSubmitApi = async () => {
    setLoader(true);
    let data = {
      post_project_id: id,
      proposal_type: "retainer",
      payment_terms: [],
      deliverables: [],
      project_ids: _ProjectsIs?.project_ids,
      link_image: _ProjectsIs?.link_image,
      urls: _ProjectsIs?.urls,
      note: states.note,
      working_days_in_week: states.workingDaysInWeek,
      proposed_start_date: addHrMinDate(states.proposed_start_date,5,30),
      proposed_duration_type: states.propDuration.prop_duration.label,
      proposed_duration: states.propDuration.prop_dur_num,
      number_of_hours_type: states.numOfHours.day_week.label,
      number_of_hours: states.numOfHours.num_hour,
      retainer_fees: states.fees.retainerFees,
      retainer_fees_creator: states.fees.retainerFees * 0.85,
      total_cost: gst_total_price,
      sub_total_cost: total_price,
      total_earning: totalEarning,
      advance_amount: states.fees?.advanceFees ? states.fees?.advanceFees : 0,
      retainer_fees_type: states.retainer_fees_type.value,
      preferred_billing_cycle: states.prefBillingCycle.value,
    };

    // console.log("data = ", data)

    try {
      let path = props.location.pathname.includes("jobs") ? "/milestone/add-outside-proposals" : "/proposal/proposal-submit";
      const res = await postAPI(path, data);
      if (res?.data) {
        setLoader(false);
        setShowReviewProp(false);
        setShowSuccessProp(true);
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };
  const dateimg = <img src={DateIcon} />;
  let total_price = Number(states?.fees?.retainerFees ? states.fees.retainerFees : 0);
  // Number(states?.fees?.advanceFees ? states.fees.advanceFees : 0);
  let gst_total_price = 0;
  gst_total_price = Number(total_price + total_price * 0.18);
  let totalEarning = 0;
  totalEarning = Number(total_price * 0.85);

  return (
    <>
      <section className="retainer_open main_page_container">
        <div className="row retainer_open_container mx-0">
          {/* top_heading */}
          <div className="col-lg-12 py-4">
            <div className="top_header d-flex align-items-center mob-justify-content-between">
              <div className="heading_icon">
                <img
                  src={HeaderIcon}
                  className="img-fluid cursor-pointer pr-4"
                  // onClick={() => (props?.history?.goBack() ? props.history.goBack() : "")}
                  onClick={() => (props.data?.project_data?.project_slug ? onClickBack() : "")}
                />
                <span className="submit_proposal_heading mb-0">Submit Proposal</span>
              </div>
              <div class="dropdown dropleft d-block d-lg-none">
                <img src={DotImage} className="dropdown-toggle" data-toggle="dropdown" />
                <div class="dropdown-menu">
                  <a class="dropdown-item font-12 font-weight-600" href="#">
                    View Brief
                  </a>
                  <a class="dropdown-item font-12 font-weight-600" href="#">
                    Learn Best Practices
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* left_side */}
          <div className="col-lg-9">
            {/* page_card */}
            <div className="page_card">
              <div className="proposal_header br_btm mb-4 d-flex justify-content-between">
                <div className="prposal_heading d-flex align-items-center">
                  <p className="mb-0 mr-3 text_black_lg_600">Your Proposal</p>
                  <div className="tag_btn_green_common">
                    <p className="mb-0">
                      <img src={PercentIcon} className="icon_img mr-1" />
                      Retainer
                    </p>
                  </div>
                </div>

                {loading || props?.data?.project_data?.status == 6 ? null : (
                  <div className="edit_proposal d-flex align-items-center" onClick={handleTypeChange}>
                    <p className="mb-0 mr-3 edit_proposal_text anchor_text_sm_w_700 d-none d-lg-block">Edit Proposal Type</p>
                    <img src={EditIcon} className="icon_img mr-1" />
                  </div>
                )}
              </div>

              {loading ? (
                <Loader isCreateModal={true} style={{ paddingTop: "15rem", paddingBottom: "15rem" }} />
              ) : (
                <>
                  {/* proposal_date */}
                  <div className="proposal_date pb-0 px-4 d-block d-md-flex justify-content-between align-items-center">
                    <div className="proposal_date_title">
                      <p className="mb-0 font_semi_bold_w_600">Proposed start date</p>
                      <p className="proposal_date_sub_title">
                        {" "}
                        Kindly indicate by when you will be able to start working on this project.
                      </p>
                    </div>
                    <div className="date_input mob-pt-3">
                      {/* <input
                    class="form-control datepicker input_md"
                    data-date-format="mm/dd/yyyy"
                    placeholder="dd/mm/yy"
                  />
                  <img className="date_icon" src={DateImage}></img> */}
                      <DatePicker
                        value={states.proposed_start_date}
                        className="form-control text-center input_sm"
                        clearIcon={null}
                        name="startDate"
                        calendarIcon={dateimg}
                        format="dd/MM/yyyy"
                        calendarClassName="apniClass"
                        showLeadingZeros={true}
                        onChange={handleChangeDate}
                        minDate={setTimeToZero(new Date())}
                        disabled={
                          props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator > 1 ? true : false
                        }
                      />
                    </div>
                  </div>
                  {/* time_commitment */}
                  <div className="time_commitment p-4">
                    <div className="row deliverables mx-0">
                      <div className="col-lg-12 title_commn d-flex justify-content-between">
                        <p className="mb-0 text_black_md">Time Commitment</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            href={`#collapseCommitment`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseCommitment`}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0 collapse show " id={`collapseCommitment`}>
                      {/* <div className="col-lg-12 px-0 py-3">
                        <p className="discription_common">
                         
                        </p>
                      </div> */}
                      <div className="col-lg-12 px-0 py-3">
                        <div className="row pb-4 mx-0 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7 px-0 mob-mb-2">
                            <p className="font_semi_bold_w_600 mb-0">Proposed Duration</p>
                            <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p>
                          </div>
                          <div className="duration_fields d-flex col-xl-5 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
                            <div className="btn_field number_btn">
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "duration")}>
                                  -
                                </button>
                                <input
                                  type="number"
                                  class="form-control text-center input_sm"
                                  value={states.propDuration.prop_dur_num}
                                  onKeyDown={(evt) =>
                                    (evt.key === "e" ||
                                      evt.key === "=" ||
                                      evt.key === "-" ||
                                      evt.key === "+" ||
                                      evt.key === "_" ||
                                      evt.key === ".") &&
                                    evt.preventDefault()
                                  }
                                  onChange={(e) => handleNumbers("change", "duration", e)}
                                />
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "duration")}>
                                  +
                                </button>
                              </div>
                              {states.validation.prop_dur_num ? <p className="error_message">Field Required</p> : ""}
                            </div>

                            <div
                              className="dropedown_field select_dropdown d-flex align-items-center ml-4"
                              style={{ minWidth: "3rem" }}
                            >
                              {/* <input class="form-control input_md" placeholder="Week" />
                          <img className="down_icon" src={DownIcon}></img>
                          <Select
                            options={[
                              // { label: "Week", value: "Week" },
                              { label: "Month", value: "Month" },
                            ]}
                            value={states.propDuration.prop_duration}
                            onChange={(e) => handleSelect(e, "duration")}
                          /> */}
                              <p className="text_light_gray_md_w_700 mb-0">
                                {states.propDuration.prop_dur_num == 1 ? "Month" : states.propDuration.prop_duration.value}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div className="row br_tp py-4 mx-0 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7 px-0">
                            <p className="font_semi_bold_w_600 mb-0">Number of Hours</p>
                            <p className="text_light_gray_xs mb-0">Select the hours you are willing to commit per day/week </p>
                          </div>
                          <div className="duration_fields col-xl-5 d-flex px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
                            <div className="dropedown_field select_dropdown mr-4">
                              {/* <input class="form-control input_md" placeholder="Day" />
                          <img className="down_icon" src={DownIcon}></img> */}
                              <Select
                                options={[
                                  { label: "Day", value: "Day" },
                                  { label: "Week", value: "week" },
                                ]}
                                isDisabled={
                                  props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator > 1
                                }
                                value={states.numOfHours.day_week}
                                onChange={(e) => handleSelect(e, "numHours")}
                              />
                            </div>
                            <div className="btn_field number_btn">
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "numHours")}>
                                  -
                                </button>
                                <input
                                  type="number"
                                  class="form-control text-center input_sm"
                                  onChange={(e) => handleNumbers("change", "numHours", e)}
                                  onKeyDown={(evt) =>
                                    (evt.key === "e" ||
                                      evt.key === "=" ||
                                      evt.key === "-" ||
                                      evt.key === "+" ||
                                      evt.key === "_" ||
                                      evt.key === ".") &&
                                    evt.preventDefault()
                                  }
                                  value={states.numOfHours.num_hour}
                                />
                                <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "numHours")}>
                                  +
                                </button>
                              </div>
                              {states.validation.num_hour ? (
                                <p className="error_message position-absolute" style={{ right: 0 }}>
                                  {states?.numOfHours?.num_hour > 24 && states.numOfHours.day_week.label === "Day"
                                    ? "Hours Should Be Less Than 25"
                                    : states?.numOfHours?.num_hour > 168 && states.numOfHours.day_week.label === "Week"
                                    ? "Hours Should Be Less Than 169"
                                    : "Field Required"}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div className="row br_tp py-4  mx-0 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7 px-0">
                            <p className="font_semi_bold_w_600 mb-0">Working Days in a Week</p>
                            <p className="text_light_gray_xs mb-0">Select the days you will be working </p>
                          </div>
                          <div className="duration_fields d-flex col-lg-4 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
                            {/* {states.validation.workingDaysInWeek ? <p className="error_message pr-3">Field Required</p> : ""} */}
                            {states.workingDaysInWeek.map((item, i) => {
                              return (
                                <div
                                  className="circle_btn_field d-flex mr-2 cursor-pointer"
                                  onClick={() => handleChooseDays(item.name)}
                                  key={i}
                                >
                                  <div
                                    className={`round_btn_blue_common ${item.isActive ? "active" : ""} ${
                                      states.validation.workingDaysInWeek ? "border-danger" : ""
                                    }`}
                                  >
                                    <p className="mb-0">{item.label}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*payment_terms  */}
                  <div className="payment_terms px-4 mb-4">
                    <div className="row mx-0">
                      <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                        <p className="mb-0 text_black_md">Payment Terms</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            href={`#collapsePayment`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapsePayment`}
                          />
                        </div>
                        <div className="d-none d-lg-block">
                          <div className="advance_toggle_btn d-flex align-items-center justify-content-center">
                            <div className="toggle_btn pr-3">
                              <label className="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={states.toggle}
                                  disabled={props?.data?.project_data?.status == 6 ? true : false}
                                  onChange={() => {
                                    states.validation.advanceFees = false;
                                    states.fees.advanceFees = "";
                                    setStates({
                                      ...states,
                                      toggle: !states.toggle,
                                    });
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <p className="mb-0"> Advance</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0 collapse show " id={`collapsePayment`}>
                      <div className="col-lg-12 px-0 py-3">
                        <div className="d-block d-lg-none mb-2">
                          <div className="advance_toggle_btn d-flex align-items-center justify-content-start">
                            <div className="toggle_btn pr-3">
                              <label className="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={states.toggle}
                                  disabled={props?.data?.project_data?.status == 6 ? true : false}
                                  onChange={() => {
                                    states.validation.advanceFees = false;
                                    setStates({
                                      ...states,
                                      toggle: !states.toggle,
                                    });
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <p className="mb-0"> Advance</p>
                          </div>
                        </div>
                        <p className="discription_common">
                          Use this section to indicate your payment terms. If you'd like for the client to pay an advance, kindly
                          switch the toggle above.
                        </p>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div className="row mb-4  align-items-center">
                          <div className="duration_title col-xl-8">
                            <p className="font_semi_bold_w_600 mb-0">Retainer Fees</p>
                            <p className="text_light_gray_xs mb-0">Enter the amount you will be charging per week/per month </p>
                          </div>
                          <div className="col-xl-4">
                            <div className="row">
                              <div className="duration_fields col-xl-6 col-6">
                                <input
                                  type="number"
                                  class={`form-control input_md input_field ${
                                    states.validation.retainerFees ? "border-danger" : ""
                                  }`}
                                  placeholder="Price"
                                  value={states.fees.retainerFees}
                                  onKeyDown={(evt) =>
                                    (evt.key === "e" ||
                                      evt.key === "=" ||
                                      evt.key === "-" ||
                                      evt.key === "+" ||
                                      evt.key === "_" ||
                                      evt.key === ".") &&
                                    evt.preventDefault()
                                  }
                                  name="retainerFees"
                                  onChange={handleChangeFees}
                                />
                                <img className="price_icon" src={PriceIcon}></img>
                              </div>
                              <div className="text_field col-xl-6 px-0 w-5 col-6">
                                {/* <p className="text_light_gray_md_w_700 mb-0">/{states.propDuration.prop_duration.value}</p> */}
                                <div className="dropedown_field select_dropdown mr-4">
                                  <Select
                                    options={[
                                      { label: "Monthly", value: "Monthly" },
                                      { label: "Hourly", value: "Hourly" },
                                    ]}
                                    isDisabled={
                                      props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator > 1
                                    }
                                    value={states.retainer_fees_type}
                                    onChange={(e) => handleSelect(e, "retainer_fees_type")}
                                  />
                                </div>
                              </div>
                              {states.validation.retainerFees ? <p className="error_message pl-3">Field Required</p> : ""}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div ref={retainerFeesRef}></div>

                      {states.toggle ? (
                        <div className="col-lg-12 px-0">
                          <div className="row mx-0 mb-5 align-items-center title_commn py-3">
                            <div className="duration_title col-xl-10">
                              <p className="mb-0 text_light_gray_md">Project Advance - Fees</p>
                              <p className="mb-0 text_light_gray_xs">
                                Advance will only be applicable for month 1. Retainer fees mentioned above should be inclusive of
                                the advance.
                              </p>
                            </div>
                            <div className={`duration_fields col-xl-2`}>
                              <input
                                type="number"
                                class={`form-control input_md input_field ${
                                  states.validation.advanceFees ? "border-danger" : ""
                                }`}
                                placeholder="Price"
                                name="advanceFees"
                                value={states.fees.advanceFees}
                                onChange={handleChangeFees}
                                onKeyDown={(evt) =>
                                  (evt.key === "e" ||
                                    evt.key === "=" ||
                                    evt.key === "-" ||
                                    evt.key === "+" ||
                                    evt.key === "_" ||
                                    evt.key === ".") &&
                                  evt.preventDefault()
                                }
                                disabled={props?.data?.project_data?.status == 6 ? true : false}
                              />
                              <img
                                className={`price_icon ${states.validation.advanceFees ? "error_price_icon" : ""}`}
                                src={PriceIcon}
                              ></img>
                              {/* {states.validation.advanceFees ? <p className="error_message position-absolute">{parseInt(states.fees.advanceFees) >= parseInt(states.fees.retainerFees) ? "Advance fees should be less than retainer fees" : "Field Required"}</p> : ""} */}
                              {states.validation.advanceFees ? (
                                <p className="error_message position-absolute">Field Required</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12 px-0">
                        <div className="row br_tp py-4 justify-content-between align-items-center">
                          <div className="duration_title col-xl-7">
                            <p className="font_semi_bold_w_600 mb-0">Perferred Billing Cycle</p>
                            <p className="text_light_gray_xs mb-0">
                              If you'd like for the payment to be cleared at the end of a calendar month, kindly choose "Month
                              End". For example, your project starts off on June 15 and you'd like to be paid for June and then
                              start a new billing cycle, choose "Month End". If you'd like to be paid after 1 month gets over,
                              i.e., June 15 to July 14, choose "Cycle End"
                            </p>
                          </div>
                          <div className="duration_fields col-xl-5 mob-pt-3 d-flex justify-content-end mob-justify-content-unset">
                            <div className="dropedown_field select_dropdown">
                              <Select
                                options={[
                                  { label: "Cycle End", value: "Monthly" },
                                  { label: "Month End", value: "Month End" },
                                ]}
                                isDisabled={
                                  props?.data?.project_data?.status > 5 && props?.data?.project_data?.visible_creator > 1
                                }
                                value={states.prefBillingCycle}
                                onChange={(e) => handleSelect(e, "Billing")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* add refrence */}

                  <Reference />

                  {/* add notes */}
                  <div className="row  px-4 pb-4 add_notes mx-0 mb-4">
                    <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                      <p className="mb-0 text_black_md">Additional Notes</p>
                      <div className="d-block d-lg-none">
                        <img
                          src={FeaturedToggleImage}
                          className="icon_img mr-1 cursor-pointer"
                          data-toggle="collapse"
                          href={`#collapseNotes`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`collapseNotes`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 px-0">
                      <div className="row mx-0 collapse show " id={`collapseNotes`}>
                        <div className="col-lg-12 px-0 py-4">
                          <textarea
                            class={`form-control upload_notes `}
                            rows="5"
                            placeholder="Please add any additional notes, comments, or explanations here. Please note that any proposals with email IDs or phone numbers will be rejected."
                            value={states.note}
                            onChange={handleChangeNote}
                          />
                          {/* {states.validation.note ? <p className="error_message">field required</p> : ""} */}
                          <span className="font-weight-500 text-secondary">{states?.note?.length}/1000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="sticky-top">
              <div className="page_card mb-3">
                <div className="proposal_header br_btm d-none d-lg-block">
                  <div className="prposal_heading d-flex align-items-center">
                    <p className="mb-0 text_black_700_w_600">Payment Summary</p>
                  </div>
                </div>
                <a className="edit_proposal d-block p-3 d-lg-none text-center" data-toggle="collapse" href={`#collapsePayment`} role="button" aria-expanded="false" aria-controls={`collapsePayment`}>
                  <span className=" mb-0 sub_title_commn mr-2">Payment Summary</span>
                  <img
                    src={FeaturedToggleImage}
                    className="icon_img mr-1 cursor-pointer"
                  />
                </a>
                <div className="col-lg-12 collapse show " id={`collapsePayment`}>
                  <div className="total_project_price row mx-0 px-4 my-4">
                    <div className="col-lg-12 px-0 br_btm mb-4">
                      <div className="project_price_title d-flex justify-content-between pb-3">
                        <p className="mb-0 font_semi_bold_w_600">Retainer Fee</p>
                        <p className="mb-0 text_gray text-right">
                          {total_price ? `₹ ${separateNumber(total_price, "")}` : "-"}
                          <span className="d-block text-right text_light_gray_xxs_w_600">+ applicable taxes</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-4 px-0">
                      <div className="project_price_title d-flex justify-content-between align-items-center line_height_12 mb-3">
                        <p className="mb-0 font_semi_bold_w_600 d-flex align-items-center">
                          Your Earnings
                          <div
                            class="position-relative tool"
                            data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable 
                      taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                          >
                            <img src={ExclamationIcon} className="img-fluid  ml-3" />
                          </div>{" "}
                        </p>
                        <p className="mb-0 text_highlight">
                          {totalEarning ? `₹ ${separateNumber(totalEarning, "")}/Month` : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pb-4">
                  <button
                    type="button"
                    class="btn btn-common w-100"
                    data-toggle="modal"
                    onClick={() => {
                      let result = handleValidation() ? setShowReviewProp(true) : "";
                    }}
                  >
                    Review & Confirm
                  </button>
                </div>
              </div>
              <ProjectDetail data={props?.data} id={props?.id} {...props} proposalSubmit={true} />
              {/* <div className="d-none d-lg-block">
                <div className="col-lg-12 d-flex mt-4 justify-content-around align-items-center">
                  <p className=" mb-0">
                    <a className="anchor_text">Learn Best Practices for Proposals </a>
                  </p>
                  <img src={ArrowIcon} className="icon_img mr-1" />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <SubmitProp
          changeShowSubmitProposal={props?.changeShowSubmitProposal}
          id={props?.id}
          {...props}
          showSubmitProposal={props?.showSubmitProposal}
          data={props?.data}
        />
        <ReviewProposal
          showReviewProp={showReviewProp}
          setShowReviewProp={setShowReviewProp}
          handleSubmitApi={handleSubmitApi}
          states={states}
          propType="retainer"
          gst_total_price={gst_total_price}
          totalEarning={totalEarning}
          total_price={total_price}
          loader={loader}
        />
        <SuccessProp showSuccessProp={showSuccessProp} setShowSuccessProp={setShowSuccessProp} propType="retainer" {...props} />
      </section>
    </>
  );
}
