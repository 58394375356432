import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
// import lightboxProfileImage from '../../../../Assets/images/account-manager.png';
// import dummyImage from '../../../../Assets/images/aa1.jpg';
import { ChatImageBaseUrl } from '../../../../utils/BaseUrl'
import AllFileViewer from '../../Common/AllFileViewer/AllFileViewer';
import { getFileName, getMsgTime, isViewableFile } from '../../Common/Common/FileRenderMessage';
import getUserImage from '../utils/getUserImage';
import getUserName from '../utils/getUserName';
import { renderDifferentFilesThumbnail } from '../../Common/Common/FileRenderMessage';
import Download from "../../../../Assets/upgrade-images/client_active_brief_chat/download.svg";

function ChatFiles(props) {

    const [currentFileIndex, setCurrentFileIndex] = useState(0)
    const propsCurrentFileIndex=props.currentFileIndex;

    const handleClose = () => {
        props.closePopup()
        props.resetChatFiles()
    }

    const handleFileIndex = (slideTo) => {
        const filesLength = props.chatFilesData.files.length
        if(slideTo === 'next') {
            if(currentFileIndex === filesLength - 1) setCurrentFileIndex(0)
            else setCurrentFileIndex(currentFileIndex+1)
        } else {
            if(currentFileIndex === 0) setCurrentFileIndex(filesLength - 1)
            else setCurrentFileIndex(currentFileIndex - 1)
        }
    }

    useEffect(() => {
        setCurrentFileIndex(propsCurrentFileIndex)
    }, [propsCurrentFileIndex])

    const currentFile = props.chatFilesData.files[currentFileIndex].name
    const data = {
        Data: props.chatFilesData,
        CurrentChat: props.CurrentChat,
        Me: props.Me,
        UserId: props.UserId,
        ProjectType: props.ProjectType,
        location: props.location,
        projectDataStates:props?.projectDataStates
    }

    const userImage = getUserImage(data)
    const username = getUserName(data)

    return (
        <>
            <div className="modal fade custom_form lightbox-modal show" style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <span className="close_icon" data-dismiss="modal" aria-label="Close">
                    <img onClick={handleClose} alt="" src={require("../../../../Assets/images/close.png")} className="img-fluid" width="35" />
                </span>
                {/* <!-- Next & Prev Button --> */}
                <div onClick={() => handleFileIndex('prev')} className="lightbox_prev"><i class="fas fa-arrow-left"></i></div>
                <div onClick={() => handleFileIndex('next')} className="lightbox_next"><i class="fas fa-arrow-right"></i></div>
                <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-header">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="lightbox-profile-image">
                                        {/* TODO get user image, name and msg sent date */}
                                            <img src={userImage} alt="Indiefolio" className="img-fluid"/>
                                        </div>
                                        <div className="lightbox-profile-headline">
                                            <h2 className="font-16 font-weight-600 text-dark-black mb-0">{currentFile.length>50 ? getFileName(currentFile,50):getFileName(currentFile)}</h2>
                                            <p className="text-sub-7 font-14 font-weight-500 mb-0">
                                            <span className="text-capitalize">{username}</span> 
                                            , {getMsgTime(props.chatFilesData.time)}</p>
                                        </div>
                                    </div>
                                    <div className="lightbox-download-icon">
                                    <a href={ChatImageBaseUrl + currentFile} download target="_blank" rel="noopener noreferrer" >
                                        <span><img src={Download} width="20"/></span>
                                    </a>
                                    </div>
                                </div> 
                            </div>
                             <div className="row m-0">
                                 <div className="col-12 overflow-content overflow-lightbox">
                                     <div className="lightbox-big-preview">
                                     {props.chatFilesData.files.length ? 
                                         <RenderFile file={ChatImageBaseUrl + currentFile} />
                                     : null}
                                     </div>
                                 </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.closeModal === true ? "back-modal" : ""}></div>
        </>
    )
}

const mapState = state => {
    const {closeModal} = state.postProject;
    const {Me} = state.globalReducer;
    const {chatFilesData, currentFileIndex, CurrentChat, UserId, ProjectType} = state.chat;
    return {
        closeModal,
        chatFilesData,
        currentFileIndex,
        CurrentChat,
        UserId, 
        ProjectType,
        Me,
    }
}

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    closePopup: () => dispatch({ type: 'CLOSE_POST_PROJECT_POPUP', payload: {} }),
    resetChatFiles: () => dispatch({ type: 'SET_CHAT_FILES', values: { chatFilesData: null, currentIndex: 0} }),

})

export default connect(mapState, mapDispatch)(ChatFiles)

const RenderFile = ({file}) => {

    if(isViewableFile(file)) return <AllFileViewer link={file} />
    else return <div className="lightbox-big-image" style={{backgroundImage:`url("${renderDifferentFilesThumbnail(file)}")`}}></div>
}