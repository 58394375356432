import React, { useState, useEffect } from 'react'
import DatePicker from 'react-date-picker';
import SimpleReactSelect from '../Common/SimpleReactSelect/SimpleReactSelect';
import { getListOfTimeIntervals } from '../../../utils/Helperfunctions';
import ErrorSpan from '../Common/Common/ErrorSpan';
// import AttendeeList from './AttendeeList';

const CalendarForm = (props) => {
  const {isEdit,eventObj}=props
  
  
    const initObj = {value: '', error: false}
    const [startTime, setStartTime] = useState(initObj)
    const [endTime, setEndTime] = useState(initObj)
    const [day, setDay] = useState(initObj)
  
    const getTime = (date) => {
      const dateObj = new Date(date)
  
      let hrs = dateObj.getHours()
      hrs = hrs > 12 ? hrs - 12 : hrs
  
      let minutes = dateObj.getMinutes();
      minutes = minutes === 0 ? '00' : minutes
  
      const AMorPM = hrs > 12 ? "PM" : "AM"
  
      return `${hrs}:${minutes}${AMorPM}`
    }
  
    useEffect(() => {

      // if event is exists and state is empty
      if(isEdit && !day.value){
          // console.log('editable ', eventObj)
          const {start, end} = eventObj
          setDay({value: new Date(start.dateTime).toDateString(), error: false})
          setStartTime({value: getTime(start.dateTime), error: false})
          setEndTime({value: getTime(end.dateTime), error: false})
      }
  
      // if(!props.isLoading && (!props.isEdit && !day.value)){
      //   resetForm()
      // }
    
  
    }, [ isEdit,eventObj,day.value])
  
    // const validateForm = () => {
    //   let isValid = true
    //   if(!day.value){
    //     setDay({...day, error: true})
    //     isValid = false
    //   }
    //   if(!startTime.value){
    //     setStartTime({...startTime, error: true})
    //     isValid = false
    //   }
    //   if(!endTime.value){
    //     setEndTime({...endTime, error: true})
    //     isValid = false
    //   } else if(getFinalTime('endTime') < getFinalTime('startTime')){
    //     setEndTime({...endTime, error: true})
    //     isValid = false
    //   }
    //   return isValid
    // }
  
    // const getFinalTime = (t) => {
    //   const timeObj = {startTime, endTime}
      
    //   // add 12 to make 24 hours
    //   const time = timeObj[t].value
  
    //   // let hrs = time.substring(0, time.length-2) 
    //   const AMorPM = time.substring(time.length-2, time.length) 
      
    //   const [hrs, minutes] = time.substring(0, time.length - 2).split(':')
    //   const addFactor = (AMorPM === "AM" && hrs === "12") || (AMorPM === "PM" && hrs !== "12") ? 12 : 0
    //   const finalTime = new Date(day.value + " " + (parseInt(hrs) + addFactor) + ":" + minutes + ":00")
    //   return finalTime
    // }
  
    // const onSubmit = () => {
    //   if (!validateForm()) return
  
    //     const finalObj = {startTime: getFinalTime('startTime'), endTime: getFinalTime('endTime')}
    //     console.log(finalObj)
    //     // if(props.isEdit){
    //     //   props.onEdit(finalObj)
    //     // } else {
    //     //   props.onSubmit(finalObj)
    //     // }
      
    //   console.log('startDateWithTime ', getFinalTime('startTime'))
    //   console.log('startDateWithTime ', getFinalTime('endTime'))
    // }
  
    // const resetForm = () => {
    //   const emptyObj = {value: '', error: false}
    //   setStartTime(emptyObj)
    //   setEndTime(emptyObj)
    //   setDay(emptyObj)
    // }
  
    const startTimeList = () => {
      const today = new Date().toDateString();
      const start = (!day.value || today === day.value) ? new Date() : new Date(day.value);
      const end = new Date().setHours(23, 59, 59, 999);
      const startList = getListOfTimeIntervals(start, end, 15).map(el => ({name: el}))
      return startList
    }

  
    const getEndTimeList = () => {
      const idx = startTimeList().findIndex(el => el.name === startTime.value)
      return [...startTimeList()].splice(idx+1, startTimeList().length)
    }

    const setStateAndPassToParent = (state, data) => {
      if(state === 'setDay'){
        setDay({value: data.toDateString(), error: false});
        props.onChange({date: data.toDateString(), startTime: startTime.value, endTime: endTime.value})
      } else if (state === 'setStartTime'){
        setStartTime({value: data.name, error: false})
        props.onChange({date: day.value, startTime: data.name, endTime: endTime.value})
      } else if(state === 'setEndTime'){
        setEndTime({value: data.name, error: false});
        props.onChange({date: day.value, startTime: startTime.value, endTime: data.name});
      }
    }
  
  
    return (
      <>
        {/* <div class="google-input-time"> */}
        <div className="d-flex align-items-center justify-content-between px-4 mob-px-5">
          <div className="w-50 google-custom-calendar mob-w-40">
            <DatePicker
              onChange={e => setStateAndPassToParent('setDay', e)}
              value={(day.value) ? new Date(day.value) : ''}
              className={`form-control email ${day.error ? 'border-error' : ''}`}
              clearIcon={null}
              format="dd-MM-yyyy"
              calendarClassName="apniClass"
              showLeadingZeros={true}
              // defaultActiveStartDate={minDate}
              minDate={new Date()}
            /> 
            <ErrorSpan error={day.error} message={'please add day to continue'} />
          </div>
          <div className="w-50 google-input-time  mob-w-60">
            <SimpleReactSelect
                className="google-calendar google-time-1"
                options={startTimeList()}
                error={startTime.error}
                errorText='please add start time'
                value={startTime.value ? {name: startTime.value} : null}
                selectHandler={obj => setStateAndPassToParent('setStartTime', obj)}
                placeholder="00-00"
            />
            <span className="font-22 font-weight-500 text-dark-black">-</span>
            <SimpleReactSelect
                className="google-calendar google-time-2"
                options={getEndTimeList(startTime.value)}
                error={endTime.error}
                errorText={!endTime.value ? 'Please provide end time' : 'End time must be greater than start time'}
                value={endTime.value ? {name: endTime.value} : null}
                selectHandler={obj => setStateAndPassToParent('setEndTime', obj) }
                placeholder="00-00"
            />
          </div>
        </div>
      </>
    )
}

export default CalendarForm