import React, { useEffect, useState } from "react";
import ProfileIcon from "../../../../Assets/upgrade-images/view_proposal_fc/profile.png";
import { getAPI } from "../../../../utils/API";
import ProjectDetail from "./CommonComp/ProjectDetail";
import { BaseImageUrl } from "../../../../utils/BaseUrl";
import DetailSideBar from "./CommonComp/DetailSideBar";

const ClientLeftComp = (props) => {
  const [clientLeftStates, setClientLeftStates] = useState({
    data: [],
  });

  useEffect(() => {
    if (props?.id) {
      handleGetApi();
    }
  }, [props?.id]);

  const handleGetApi = async () => {
    try {
      const res = await getAPI(`/project/get-post-project-details-brief?post_project_id=${props?.id}`);
      if (res) {
        let data = res?.data;
        setClientLeftStates({ data });
        props?.isViewBrief && props.getUserData(data?.project_data)
      }
    } catch (err) {
      console.log("client brief error");
    }
  };

  return <DetailSideBar clientLeftStates={clientLeftStates} isLeftClient={true} {...props} showProjDetail="yes" />;
};

export default ClientLeftComp;
