import React, { useEffect, useState } from "react";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import DummyCompany from '../../../../../Assets/upgrade-images/dashboard/dummy_company.svg'
import { getImageSize } from "react-image-size";

export default function RenderImageCommonComp({ companyLogo,companyUrl }) {
    const [thumbnail,setThumbnail] = useState(DummyCompany)
    useEffect(() => {
              const isImageFetchedFromUrl = async(newUrl) =>{
              try {
                const {width, height} = await getImageSize(`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${newUrl}&size=256`);     
                if(width > 16){
                  setThumbnail(`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${newUrl}&size=256`)
                }else 
                setThumbnail(DummyCompany)
                } catch(err) {
                console.error(err)
              }
            }
            if(!companyLogo && companyUrl){
              let newUrl = companyUrl
              if(!companyUrl.includes('http'))
                newUrl = 'http://'+companyUrl
              isImageFetchedFromUrl(newUrl)
            }
  }, [companyUrl]);
  
    return (
      <img
        className="img-fluid rounded-circle"
        src = {companyLogo? BaseImageUrl+companyLogo:thumbnail}
      />
    );
  };