export const allWeekdays = [
    {short: 'Sun', full: 'Sunday'},
    {short: 'Mon', full: 'Monday'},
    {short: 'Tue', full: 'Tuesday'},
    {short: 'Wed', full: 'Wednesday'},
    {short: 'Thu', full: 'Thursday'},
    {short: 'Fri', full: 'Friday'},
    {short: 'Sat', full: 'Saturday'},
]

export const allMonths = [
    { short: 'Jan', full: "January"},
    { short: 'Feb', full: "February"},
    { short: 'Mar', full: "March"},
    { short: 'Apr', full: "April"},
    { short: 'May', full: "May"},
    { short: 'Jun', full: "June"},
    { short: 'Jul', full: "July"},
    { short: 'Aug', full: "August"},
    { short: 'Sep', full: "September"},
    { short: 'Oct', full: "October"},
    { short: 'Nov', full: "November"},
    { short: 'Dec', full: "December"}
]