import React from 'react';
import { connect } from 'react-redux';
import { putAPI } from '../../../../../utils/API';
import { BaseImageUrl } from '../../../../../utils/BaseUrl';
// import Popup from '../../../ProfileCreator/Popup';
import { CollateralBaseUrl } from '../../../../../utils/BaseUrl';
import StyleAndTypeQuestions from './StyleAndTypeQuestions';
import CompanyAndProjectQuestions from './CompanyAndProjectQuestions';
import { withRouter } from 'react-router';
import { separateNumber, isImage, sanitiseSummernoteText } from '../../../../../utils/Helperfunctions'
import { renderDifferentFilesThumbnail } from '../../../Common/Common/FileRenderMessage'
import { Fragment } from 'react';
import ClientCard from '../Components/IncomingBriefReview/ClientCard';
import ShowUsersCardInAdminProject from '../Components/IncomingBriefReview/ShowUsersCardInAdminProject';
import CreatorDetailsCard from '../Components/IncomingBriefReview/CreatorDetailsCard';
import { removeHttps } from '../../../../../utils/Helperfunctions';

class IncomingBriefReview extends React.Component {

    state = {
        user_list: [],
        viewMore: false,
    }


    // handleContinue = () => {
    //     return;
    //     this.props.OpenPopup("ReviewMilestone");
    // }

    rejectHandler = () => {
        this.props.OpenPopup("CreatorRejectsBrief");
        // this.props.rejectIncomingBrief(this.props.post_project_id)
    }
    acceptHandler = () => {
        this.props.acceptIncomingBrief(this.props.post_project_id, this.props.history, this.props.project_name)
    }

    askQuestionHandler = () => {
        let askQuestion_data = {
            post_project_id: this.props.post_project_id,
            status: 3,
        }
        putAPI('/project/post-project-artist-approved', askQuestion_data).then((res) => {
            if (res.data.status === "1") {
                this.props.loadProjectStatus(this.props.post_project_id)
                this.props.closePopup();
                this.props.history.push({ pathname: `/chat/${this.props.post_project_id}/${this.props.project_slug}` })

                // this.props.history.push({pathname:'/chat',state : { 
                //     post_project_id: this.props.post_project_id,
                //     project_name: this.props.project_name,
                //     project_status: 5
                //   }})
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    componentDidUpdate() {
        if (this.props.IsBriefAccepted) {
            // console.log('%c Accepted', 'font-size: 22px; color:orange');
            this.props.history.push({ pathname: '/chat', state: { post_project_id: this.props.post_project_id } })
        }
    }

    isImage = (name) => {
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name)
    }

    submitProposal = () => {
        this.props.loadProjectStatus(this.props.post_project_id)
        this.props.OpenPopup("ReviewMilestone");
    }
    render() {
        const { client, collateral_name, project_name, client_role,
            project_description, minBudget, maxBudget, deadline, style, type,
            work_type, about, website, industry, company_name,
            reference_files, reference_links, project_question, match, ProjectType, Me, CurrentChat, hideActions,sub_type, manager } = this.props;
        // const { viewMore } = this.state;

        const isIndividual = work_type === 'company' || work_type === 'agency' ? false : true

        const clientData = { ...client, website, about, industry, isIndividual, company_name, role: client_role, }

        // chat
        const isInChat = match.path === "/chat/:projectId/:projectName"
        const isAdminProject = isInChat && ProjectType === "post_project_admin"
        // const isRepresentative = isInChat && CurrentChat.user_role === "representative"

        // Admin Chat creator name and image
        
        return (
            <>
                <div className="modal fade custom_form custom_Modal_Box show" id="project-details" style={{ paddingRight: "0px", display: "block" }} role="dialog" aria-labelledby="myModalLabel">
                    <div type="button" className="close_icon cross_black" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            {<img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../../Assets/images/close.png")} className="img-fliud" width="35" />}
                        </span>
                    </div>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content border-0">
                            <div className="modal-body">
                                <div className="project-deatils">
                                    <div className="row m-0">
                                        <div className="col-sm-4 col-md-4  col-lg-4 bg-light-sky px-40 py-20 ipad-normal-p-15 mob-border-radius border-bottom-left-radius border-top-left-radius overflow-content inComingBrief chatting-box-overflow mobile-hide">
                                            <div className="project-name mb-3 mt-3">
                                                <span className="sub-design font-14 font-weight-bold text-uppercase text-color-purple">{collateral_name}</span>
                                                <h2 className="mb-0 font-18 mob-font-16 font-weight-600 ">{project_name}</h2>
                                            </div>
                                            {deadline>0&&<div className="Project-duration mb-2">
                                                <span className="sub-design font-14 font-weight-600 text-dark-gray">Project duration</span>
                                                <h2 className="mb-0 font-18 mob-font-16 font-weight-600">{deadline > 0 ? `${deadline} Days` : 'No Deadline'}</h2>
                                            </div>}
                                            {/* {sub_type&&sub_type==='retainer'?<div>retainer</div>:<div>fixed</div>} */}
                                            <div className="mb-3"><span className="main-btn bg-success d-inline font-11 mr-2 py-1 px-2">{sub_type && sub_type==='retainer'?'Retainer':'Fixed'}</span></div>

                                            {!isAdminProject ? <div className="Project-budget mb-3">
                                                <span className="sub-design font-14 font-weight-600 text-dark-gray">Budget</span>
                                                <h2 className="font-18 mob-font-16 font-weight-600"><i className="fas fa-rupee-sign rupees font-15"></i>{separateNumber(minBudget)} - <i className="fas fa-rupee-sign rupees font-15"></i>{separateNumber(maxBudget)}</h2>
                                                {this.props.number_month? <div className="Project-budget mb-4">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Project Duration </span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_month>1?this.props.number_month+" months":this.props.number_month+" month"}</h2>
                                                    </div>:<></>}
                                                   { this.props.number_month?<div className="Project-budget mb-4">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Weekly Commitment </span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_hour>1?this.props.number_hour+" hours":this.props.number_hour+" hour"}</h2>
                                                    </div>:<></>}
                                            </div> : null}
                                            
                                            {!isAdminProject ?
                                                <>
                                                    {/* clientData will only come when client is not viewing brief in normal brief */}
                                                    {client && <ClientCard data={clientData} />}
                                                    {/* {Me.CurrentRole === 1 && !hideActions ? <CreatorDetailsCard data={{ profile_image: CurrentChat.profile_image, first_name: CurrentChat.first_name, last_name: CurrentChat.last_name }} /> :
                                                        <>
                                                            {this.props.postProjectClientBreif?.creator.map(cr => {
                                                                return (
                                                                    <CreatorDetailsCard data={{ profile_image: cr.creator_id.profile_image, first_name: cr.creator_id.first_name, last_name: cr.creator_id.last_name }} />
                                                                )
                                                            })}
                                                        </>
                                                    } */}
                                                </>
                                                : <ShowUsersCardInAdminProject />}

                                            {/* Talent manager ******************** */}

                                            <div className="card-wrapper mt-5">
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="w-100 d-flex align-items-center">
                                                            <div className="">
                                                                <p className="mb-0 model-profile"><img src={BaseImageUrl + `${manager?.manager_image ? manager?.manager_image : `profile_image.png`} `} alt="profile" title="" width="80" /></p>
                                                            </div>
                                                            <div className="w-65 overflow-hidden pl-2">
                                                                <span className="font-12 text-dark-gray font-weight-600">Talent Manager</span>
                                                                <h2 className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16">{manager?.manager_name}</h2>
                                                               <p>{manager?.manager_email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>        
                                            
                                            
                                        </div>
                                        <div className="col-sm-8 col-md-8 col-lg-8 bg-white p-0 border-left-radius mob-border-radius">
                                            <div className={`project-description px-40 py-30 ipad-normal-p-15 overflow-content incoming-max-height ${match.path === '/chat/:projectId/:projectName' ? 'hide-buttons' : ''} mt-2`}>
                                                <div className="mobile-show">
                                                    <div className="project-name mb-3">
                                                        <span className="sub-design font-14 font-weight-600 text-uppercase text-color-purple">{collateral_name}</span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600 ">{project_name}</h2>
                                                    </div>
                                                    {deadline>0?<div className="Project-duration mb-3">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Project duration</span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600">{deadline > 0 ? `${deadline} Days` : 'No Deadline'}</h2>
                                                    </div>:''}
                                                    {/* {sub_type&&sub_type==='retainer'?<div>retainer</div>:<div>fixed</div>} */}
                                                    <div className="mb-3"><span className="main-btn bg-success d-inline font-11 mr-2 py-1 px-2">{sub_type&&sub_type==='retainer'?'Retainer':'Fixed'}</span></div>

                                                    <div className="Project-budget mb-3">
                                                        <span className="sub-design font-14 font-weight-600 text-uppercase text-dark-gray">Budget</span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"><i className="fas fa-rupee-sign rupees"></i> {separateNumber(minBudget)} - <i className="fas fa-rupee-sign rupees"></i> {separateNumber( maxBudget)}</h2>
                                                        {this.props.number_month? <div className="Project-budget mb-4">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Project Duration </span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_month>1?this.props.number_month+" months":this.props.number_month+" month"}</h2>
                                                    </div>:<></>}
                                                   { this.props.number_month?<div className="Project-budget mb-4">
                                                        <span className="sub-design font-14 font-weight-600 text-dark-gray">Total Weekly Commitment </span>
                                                        <h2 className="font-18 mob-font-16 font-weight-600"> {this.props.number_hour>1?this.props.number_hour+" hours":this.props.number_hour+" hour"}</h2>
                                                    </div>:<></>}
                                                    </div>
                                                    {!isAdminProject ?
                                                        <>
                                                            {/* clientData will only come when client is not viewing brief in normal brief */}
                                                            {client && <ClientCard data={clientData} />}
                                                            {Me.CurrentRole === 1 && <CreatorDetailsCard data={{ profile_image: CurrentChat.profile_image, first_name: CurrentChat.first_name, last_name: CurrentChat.last_name }} />}
                                                        </>
                                                        : <ShowUsersCardInAdminProject />}

                                                    <div className="card-wrapper mt-5">
                                                        <div className="card p-2">
                                                            <div className="card-body">
                                                                <div className="w-100 d-flex align-items-center">
                                                                    <div className="">
                                                                        <p className="mb-0 model-profile"><img src={BaseImageUrl + `${manager?.manager_image ? manager?.manager_image : `profile_image.png`} `} alt="profile" title="" width="80" /></p>
                                                                    </div>
                                                                    <div className="w-65 overflow-hidden pl-2">
                                                                        <span className="font-12 text-dark-gray font-weight-600">Talent Manager</span>
                                                                        <h2 className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16">{manager?.manager_name}</h2>
                                                                    <p>{manager?.manager_email}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>     
                                                    
                                                </div>

                                                {project_description?.length ? <div className="Project-duration  pb-1">
                                                    <span className="sub-design font-14 font-weight-600 text-dark-gray">Project descriptioncsacas</span>
                                                        <p className="font-16 text-dark-black font-weight-bold" dangerouslySetInnerHTML={{__html:sanitiseSummernoteText(project_description?.replace(/\n/g,'<br/>'))}}>
                                                    </p>
                                                </div> : null}
                                                
                                                {style.length ? <StyleAndTypeQuestions ques={style} quesType={'style'} /> : null}
                                                {type.length ? <StyleAndTypeQuestions ques={type} quesType={'type'} /> : null}
                                                
                                                {reference_files?.length ? <div className="Project-duration mb-4">
                                                    <span className="sub-design d-block font-16 font-weight-600 text-dark-gray">References Files</span>

                                                    <div className="onboarding_style_questions">
                                                        <ul className="content p-0 mb-3">

                                                            {reference_files.map((file, i) => {
                                                                return (
                                                                    <Fragment key={i}>
                                                                        <li>
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={CollateralBaseUrl + file.name}>
                                                                                <div className={`deadline position-relative`} style={{ backgroundSize: 'cover', backgroundImage: `url("${isImage(file.name) ? CollateralBaseUrl + file.name : renderDifferentFilesThumbnail(file.name)}")` }}>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </Fragment>
                                                                )
                                                            }
                                                            )}
                                                            {/* <img src={CollateralBaseUrl + file.name} alt='' className="img-size" /> */}
                                                        </ul>
                                                    </div>

                                                    
                                                </div> : null}
                                                {reference_links?.length ? <div className="Project-duration mb-4">
                                                    <span className="sub-design d-block font-16 font-weight-600 text-dark-gray">References Links</span>
                                                    {reference_links.map((link, i) => {
                                                        return (
                                                            
                                                            <a key={i} target="_blank" rel="noopener noreferrer" className="text-dark-black" href={"http://" + removeHttps(link)}><button type="button" className=" text-black main-btn font-12 font-weight-600 bg-extra-light mt-1 mr-2 cursor-pointer px-4">Link {i + 1}</button></a>
                                                        )
                                                    }
                                                    )}

                                                </div> : null}
                                                {project_question.length ? <CompanyAndProjectQuestions questions={project_question} /> : null}
                                            </div>
                                            {match.path.includes('/chat/:projectId/:projectName') || hideActions ?
                                                null :
                                                <div className="row m-0">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0  bg-shadow-top mobile_modal_footer_fixed">
                                                        <div className="card-wrapper mb-0">
                                                            <div className="row m-0 align-items-center px-3 py-4 mob-pt-py-15">
                                                                <div className="col-12 col-sm-12 text-right mob-p-0 mob-mt-10">
                                                                    <button type="button" className="main-btn mob-font-16 mob-font-12 font-weight-600 bg-light-sky text-dark-gray" onClick={this.rejectHandler} >Reject</button>
                                                                    
                                                                    <button type="button" className="main-btn mob-font-16 mob-font-12 font-weight-600 ml-2" onClick={this.submitProposal}>Submit Proposal</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>

            </>
        )
    }
}

const mapState = (store) => {
    const postProjectData = store.postProject;
    const globalReducer = store.globalReducer;
    const dashboard = store.dashboard;
    const chat = store.chat

    return {
        sub_type:dashboard.sub_type,
        number_hour:dashboard.number_hour,
        number_month:dashboard.number_month,
        closeModal: postProjectData.closeModal,
        project_name: dashboard.project_name,
        client_role: dashboard.client_role,
        company_name: dashboard.company_name,
        about: dashboard.about,
        industry: dashboard.industry,
        website: dashboard.website,
        work_type: dashboard.work_type,
        minBudget: dashboard.minBudget,
        maxBudget: dashboard.maxBudget,
        deadline: dashboard.deadline,
        project_description: dashboard.project_description,
        client: dashboard.client,
        collateral_name: dashboard.collateral_name,
        style: dashboard.style,
        type: dashboard.type,
        reference_files: dashboard.reference_files,
        reference_links: dashboard.reference_link,
        company_question: dashboard.company_question,
        project_question: dashboard.project_question,
        post_project_id: dashboard.post_project_id,
        project_slug: dashboard.project_slug,

        ProjectType: chat.ProjectType,
        CurrentChat: chat.CurrentChat,

        Me: globalReducer.Me,
        manager : dashboard.manager
    };
}

const mapDispatch = dispatchEvent => ({
    OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    closePopup: () => dispatchEvent({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    rejectIncomingBrief: (id) => dispatchEvent({ type: 'REJECT_INCOMING_BRIEF', payload: id }),
    acceptIncomingBrief: (id, history, project_name) => dispatchEvent({ type: 'ACCEPT_INCOMING_BRIEF', payload: { id, history, project_name } }),
    loadProjectStatus: (projectId) => dispatchEvent({ type: 'LOAD_PROJECT_STATUS', payload: { projectId } }),

});

export default withRouter(connect(mapState, mapDispatch)(IncomingBriefReview));


