import React, { useState } from "react";
import { postAPI, putAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

// for understanding popupType in this function =>
// popupType is 1 => approve
// popupType is 2 => reject
// and after that as per your requirements.

export default function CommonApproveAndReject({
  heading,
  text,
  buttonName,
  msg,
  apiPath,
  popupType,
  id,
  showApproveRejectPop,
  closeAppRejPopup,
  callApiAgain
}) {
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    try {
      let obj = {
        post_project_id: id,
      };
      let res = await putAPI(apiPath, obj);
      //   console.log("res === ", res);
      if (res) {
        setLoader(false);
        closeAppRejPopup(false);
        callApiAgain()
        setTimeout(() => {
          alert(msg);
        }, 500);
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong, please try again");
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      closeAppRejPopup(false);
    }
  };

  return (
    <section className="ask_question">
      <div
        className={`modal fade modal_fade_bg ${showApproveRejectPop ? "show" : ""}`}
        id="reviewConfirm"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showApproveRejectPop ? "block" : "" }}
      >
        <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm">
          <div className="modal-content">
            <div className="modal-header br_btm d-block">
              <div className="row header_title mb-3">
                <div className="col-lg-12 d-flex justify-content-center align-items-center">
                  <h5 className="modal-title modal_title" id="exampleModalLabel">
                    {heading}
                  </h5>
                  <button type="button" className="close close_btn" onClick={() => closeAppRejPopup(false)}>
                    <span aria-hidden="true" className="close_icon modalclose-btnicon">
                      ×
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="modal-body py-4">
              <p className="fw_600 fs_14 text-gray-1200">Are you sure you want to {text}</p>
            </div>
            <div className="modal-footer br_tp ">
              <button type="button" className="btn btn_outline_sm  btn_submit mr-4" onClick={() => closeAppRejPopup(false)}>
                Cancel
              </button>
              {popupType ? (
                <button
                  type="button"
                  className={`btn ${popupType === 2 ? "btn_common_md_danger" : "btn_common_sm"}`}
                  onClick={handleSubmit}
                >
                  {loader ? <Loader isCreateModal={true} /> : buttonName}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
