
import React from 'react';
import { connect } from 'react-redux';
import { getAPI, postAPI, putAPI } from '../../../../utils/API';
import { BaseImageUrl, ChatImageBaseUrl } from '../../../../utils/BaseUrl';
import { getDateTime } from '../../../../utils/DateMethod';
import { sanitiseSummernoteText, separateNumber } from '../../../../utils/Helperfunctions';
import { getFileName, renderDifferentFiles } from '../../Common/Common/FileRenderMessage';
import RenderHtml from '../../Common/RenderHtml/RenderHtml';
import CustomSummernote from '../CustomSummernote';
import LinkImage from '../../../../Assets/images/link-file.jpg';
import PdfImage from "../../../../Assets/images/big-pdf.jpg";
import DocImage from "../../../../Assets/images/big-doc.jpg";
import ZipImage from "../../../../Assets/images/big-zip.jpg";
import PPtImage from "../../../../Assets/images/big-ppt.jpg";
import { handleFileType } from '../../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';
class ReviewProposal extends React.Component {
    scrollRef = React.createRef()
    state = {
        showMsgBox: false,
        milestone: [],
        projectname: '',
        proposal: '',
        user: '',
        hideVersionHistoryButton: true,
        files: [],
        images_links:[]
    }

    componentDidMount = () => {
        let proposal_id = this.props.current_proposal_id;
        const {authIs} = this.props
        getAPI('/milestone/get-proposals?proposal_id=' + proposal_id).then((res) => {
          
            
            authIs && this.loadAllProposal(res.data.proposal.user_id);
            this.props.LoadReviewProposalData(res.data);
            const {proposal} = res.data;
           
            const {files, files_new} = proposal;
          
            this.setState({
                milestone: res.data.milestone,
                projectname: res.data.projectname,
                proposal,
                user: res.data.user,
                files: (proposal.update_status === 0)
                    ? files_new ? files_new : []
                    : files ? files : [],
                images_links : res?.data?.proposal?.link_image ? res?.data?.proposal?.link_image : []
            })
        }).catch((error) => {
            console.log(error)
        });
    }

    loadAllProposal = (userId) => {
        let api_path = '/milestone/proposals-creator?post_project_id=' + this.props.current_project_id + '&user_id=' + userId
        getAPI(api_path).then((res) => {
            
            if (res.data.status === "1") {
                this.setState({
                    hideVersionHistoryButton: res.data.data.length > 1 ? false : true
                })
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ isLoading: false })
        });
    }

    approveUpdatedProposal = (proposal_id) => {
        getAPI('/milestone/approved-update-proposals?proposal_id=' + proposal_id).then((res) => {
            this.props.closePopup();
            let projectId = this.props.ProjectId;
            // let projectName = this.props.ProjectName;
            // let projectStatus = this.props.ProjectStatus;
            this.props.loadChatMessages(this.props.CurrentChat);
            this.props.loadLatestProposals(projectId)
            // this.props.loadChatAndLatestProposals(projectId, projectName, projectStatus)
            // this.props.loadChat(projectId, projectName, projectStatus);
            alert("Proposal Approved");
        }).catch((error) => {
            console.log(error)
        })
    }
    rejectUpdateProposal=(proposal_id)=>{
      
        const obj={proposal_id:proposal_id}
        putAPI('/milestone/reject-proposal',obj).then((res)=>{
         
            this.props.closePopup();
            let projectId = this.props.ProjectId;
            // let projectName = this.props.ProjectName;
            // let projectStatus = this.props.ProjectStatus;
            this.props.loadChatMessages(this.props.CurrentChat);
            this.props.loadLatestProposals(projectId)
            // this.props.loadChatAndLatestProposals(projectId, projectName, projectStatus)
            // this.props.loadChat(projectId, projectName, projectStatus);
            alert("Sucessfully Rejected");
        }).catch((error) => {
            
         
        })
    }
    getPercent = (amount) => {
        const sub_total_cost = (this.state.proposal.update_status === 0) ? this.state.proposal.sub_total_cost_new : this.state.proposal.sub_total_cost;
        let percent = parseInt((amount * 100) / sub_total_cost);
        return percent;
    }

    getPercentForMilestone = (item, milestone) => {
        const price = item.update_status === 0 ? item.price_new : item.price
        const totalPrice = milestone.reduce((acc, obj) => acc + (obj.update_status === 0 ? obj.price_new : obj.price), 0)
        return parseInt((price / totalPrice) * 100)
    }

    requestChanges = () => {
        const { showMsgBox } = this.state;
        // window.scrollTo(0,900)
        this.setState({
            showMsgBox: !showMsgBox,
        }, () => {
            if (this.state.showMsgBox) {
                this.scrollRef.current.scrollIntoView()
            }
        })
    }
    requestMessage = (value) => {
        // let msg = event.target.value;
        this.setState({
            changeMsg: value,
            msgError: false,
        })
    }

    sendRequestChanges = () => {
        const { Artist, ProjectType } = this.props

        let msg = this.state.changeMsg;
        const isAdminProject = ProjectType === 'post_project_admin'

        if (!msg) {
            this.setState({
                msgError: true,
            })
            alert("Please write your msg");
            return;
        }
        const { proposal } = this.state
        const proposal_details = {
            proposal_id: proposal._id,
            text: sanitiseSummernoteText(msg),
        }
        postAPI('/milestone/chat-messages', proposal_details).then((res) => {
            if (res.data.status === 1) {
                alert("Your request is sent!")
                if (isAdminProject) {
                    this.props.loadChatMessages(Artist[1]);
                } else {
                    this.props.loadChatMessages(this.props.CurrentChat);
                }
                this.props.closePopup();
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    viewProposalVersions = () => {
        this.props.OpenPopup('ProposalVersionModal');
    }


    render() {

        const onTalentShare = window.location.pathname.includes('talentconnect');
        const { milestone, projectname, proposal, user, hideVersionHistoryButton, files, images_links } = this.state;
        const { showMsgBox, msgError } = this.state;
        const { current_project_status, CurrentRole, ProjectType, CurrentChat, postProjectClientBreif, userProfile } = this.props;
        const isAdminProject = ProjectType === 'post_project_admin'
        const isCreator = CurrentChat?.user_role === 'creator'
        const isCreatorInAdminProject = isAdminProject && isCreator
        const isRepresentative = CurrentChat?.user_role === 'representative';

        const client = postProjectClientBreif?.client;
        const isClientAndProjectBelongsToHim = client && client._id === userProfile._id;

        const showButtons = !onTalentShare || isClientAndProjectBelongsToHim;

        const Proj_delivery_date_filter = milestone.filter(item => item?.type !== "token")?.sort((a,b)=> a?.serial_no-b?.serial_no)?.pop()
        // console.log("==== ",Proj_delivery_date_filter)
        let count = 0;
        
        return (
            <>
                <div className={`modal fade custom_form show ${!isAdminProject ? 'custom_review_proposal' : ''}`} style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon recommendation_modal_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fluid" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650 mob-modal-xs" role="document">
                        <div className="modal-content border-0">
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-sm-12 p-0">
                                        <div className="profile-creator dflex p-4 mob-p-15">
                                            <div>
                                                <div className="d-flex w-100">
                                                    <div className="pt-15">
                                                        <i className="far fa-calendar-alt font-20 text-white"></i>
                                                    </div>
                                                    <div className="pl-3">
                                                        <span className="text-white font-13 mob-font-12 font-weight-500">{projectname}</span>
                                                        <h2 className="font-weight-500 m-0 font-18 mob-font-16 mob-only-text-left line-height-14">Project Proposal</h2>
                                                        <span className="font-13 text-sub-3">shared by {user.first_name} {user.last_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {showButtons && <div className="mobile-hide">
                                                {current_project_status !== 3 && CurrentRole === 1 && (proposal.status === 0 || proposal.update_status === 0) ? <button type="button" className="main-btn font-12 mob-w-100 cursor-pointer amit-test"
                                                    onClick={(proposal.update_status === 0) ? (e) => { this.approveUpdatedProposal(proposal._id) } : (e) => { this.props.OpenPopup('KickStartProject') }}> {proposal.update_status === 0 ? 'Approve proposal' : 'Approve and pay'}</button> : <></>}
                                                <button type="button" className="main-btn bg-light-gray font-12 mob-w-100 cursor-pointer hidden"> View previous version</button>
                                            </div>
                                            
                                                
                                            }
                                              {proposal.update_status===3?showButtons && <div className="mobile-hide">
                                                {current_project_status !== 3 && CurrentRole === 1 && proposal.update_status === 3 ? <button type="button" className="main-btn font-14 mob-w-100 cursor-pointer amit-test px-25"
                                                    onClick={ (e) => { this.rejectUpdateProposal(proposal._id) }}> Reject</button> : <></>}
                                                {/* <button type="button" className="main-btn bg-light-gray font-12 mob-w-100 cursor-pointer hidden"> View previous version</button> */}
                                            </div>
                                            
                                                
                                           :<></> }
                                            
                                        </div>
                                    </div>
                                    <div className={`col-sm-12 p-0 overflow-content review_proposal ${CurrentRole === 2 ? 'mobile_creator_proposal' : ''}`}>
                                        <div className="card-wrapper mb-0 mobile-hide w-100">
                                        <div className="custom_form p-3">
                                            <div className="row m-0">
                                                <div className={`col-6 col-sm-8 col-xl-7 dflex ${CurrentRole === 2 ? '' : 'justify-content-start'}`}>
                                                    <div className="row m-0 w-100">
                                                        {!isCreatorInAdminProject ? 
                                                            <>
                                                                <div className={`${CurrentRole === 2 ? 'col-sm-4' : 'col-sm-6'}`}>
                                                                    <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">Project quote</span>
                                                                    <span className="font-20 mob-font-18 text-dark-black font-weight-600"><span className="project-date-star position-relative"><i className="fas fa-rupee-sign font-18"></i>{(proposal.update_status === 0) ? separateNumber(proposal.sub_total_cost_new) : separateNumber(proposal.sub_total_cost)}</span></span>
                                                                    <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span>
                                                                </div>
                                                                {CurrentRole === 2 ? <CreatorEarningText data={{cost: (proposal.update_status === 0) ? separateNumber(proposal.total_earning_new) : separateNumber(proposal.total_earning), title: 'Your Earning', className: 'col-sm-4 project-delivery-date position-relative'}} /> : null}
                                                            </>
                                                            : 
                                                            <CreatorEarningText data={{cost: (proposal.update_status === 0) ? separateNumber(proposal.total_earning_new) : separateNumber(proposal.total_earning), title: 'Your Earning', className: 'col-sm-6'}} />
                                                        }
                                                        <div className={`${(!isCreatorInAdminProject && CurrentRole === 2) ? 'col-sm-4' : 'col-sm-6'} project-delivery-date position-relative`}>
                                                            <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">Project delivery date</span>
                                                                <span className="font-20 mob-font-18 text-dark-black font-weight-600 text-capitalize">
                                                                    {getDateTime( Proj_delivery_date_filter?.update_status === 0 ? Proj_delivery_date_filter?.due_date_new : Proj_delivery_date_filter?.due_date, "dateMonth")}
                                                                    {/* {getDateTime(milestone[milestone.length - 1]?.due_date, "dateMonth")} */}
                                                                </span>
                                                        </div>
                                                    </div> 
                                                </div> 
                                                <div className="col-6 col-sm-4 col-xl-5">
                                                    {showButtons && <div className="text-right">
                                                        {CurrentRole === 2 && proposal.update_status === 1 && !isAdminProject 
                                                            ? <button type="button" className="main-btn font-13 cursor-pointer font-weight-600" onClick={() => this.props.OpenPopup('ReviewMilestone')}>Make changes</button>
                                                            : <></>
                                                        }

                                                        {current_project_status !== 3 && CurrentRole === 1 ? <button type="button" className="main-btn bg-light-gray font-13 cursor-pointer font-weight-600" onClick={this.requestChanges}>Request changes</button> : <></>}

                                                        {!hideVersionHistoryButton && CurrentRole === 1 && !this.props?.history?.location?.pathname?.startsWith("/talentconnect") ? <button type="button" className="main-btn bg-light-gray font-13 cursor-pointer ml-3 font-weight-600" onClick={this.viewProposalVersions}>View version history</button> : null}
                                                    
                                                        {!hideVersionHistoryButton && CurrentRole === 2 ? <button type="button" className="main-btn bg-light-gray font-13 cursor-pointer ml-3 font-weight-600" onClick={this.viewProposalVersions}>View version history</button> : null}

                                                    </div>}
                                                </div>
                                            </div>
                                                <div className="custom_table pt-3">
                                                    <div className="row-border py-3">
                                                        <div className="row m-0">
                                                            <div className="col-sm-1 text-center">
                                                                <span className="text-sub font-weight-500">#</span>
                                                            </div>
                                                            <div className={`col-sm-${isAdminProject ? 5 : 4}`}>
                                                                <span className="text-sub font-weight-500">Deliverable</span>
                                                            </div>
                                                            {!isAdminProject ? <div className="col-sm-3">
                                                                <span className="text-sub font-weight-500">Due Amount</span>
                                                            </div> : null}
                                                            <div className={`col-sm-${isAdminProject ? 3 : 2}`}>
                                                                <span className="text-sub font-weight-500">Due Date</span>
                                                            </div>
                                                            <div className={`col-sm-${isAdminProject ? 3 : 2}`}>
                                                                <span className="text-sub font-weight-500">Iterations</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        milestone && milestone.map((item, index) => (
                                                            (item.type === "token") &&
                                                                <div key={index} className="wrapper-form py-3">
                                                                    <div className="row m-0">
                                                                        <div className="col-sm-1 text-center">
                                                                            <span className="text-sub-8"><i className="fas fa-info advance_info"></i></span>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div>
                                                                                <h3 className="text-third mb-0 font-14 font-weight-500">Project - Advance Fees</h3>
                                                                                {/* <p className="mb-0 text-third">
                                                                                <sup><i className="fas fa-star-of-life font-5"></i></sup>
                                                                                <span className="font-11 font-weight-500">Recommendation: 10-20% of total project value</span>
                                                                            </p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <div>
                                                                                <span className="text-dark-black mb-0 font-15 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{separateNumber(item.price)}</span>
                                                                                <button type="button" className="main-btn non-btn bg-tranparent text-third font-10 cursor-default font-weight-500 ml-1">{this.getPercent(item.price)} % of total payment</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                        ))
                                                    }
                                                    {milestone && milestone.map((item, index) => (
                                                        (item.type !== "token") &&
                                                            <div key={index} className="row-border py-3">
                                                                <div className="row m-0">
                                                                    <div className="col-sm-1 text-center">
                                                                        <span className="text-sub font-weight-500">{isAdminProject ? index + 1 : index}</span>
                                                                    </div>
                                                                    <div className={`col-sm-${isAdminProject ? 5 : 4}`}>
                                                                        <div>
                                                                            <h3 className="text-dark-black mb-0 font-14 font-weight-600">{item.update_status === 0 ? item.name_new : item.name}</h3>
                                                                        </div>
                                                                    </div>
                                                                    {!isAdminProject ? <div className="col-sm-3">
                                                                        <div>
                                                                            <span className="text-dark-black mb-0 font-15 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{item.update_status === 0 ? separateNumber(item.price_new) : separateNumber(item.price)}</span>
                                                                            <button type="button" className="main-btn bg-light-gray font-10 cursor-default py-1 px-2 font-weight-500 ml-1">{this.getPercentForMilestone(item, milestone)} % of total payment</button>
                                                                        </div>
                                                                    </div> : null}
                                                                    <div className={`col-sm-${isAdminProject ? 3 : 2}`}>
                                                                        <span className="font-14 mob-font-13 text-dark-black font-weight-600 text-capitalize">{getDateTime(item.update_status === 0 ? item.due_date_new : item.due_date, "dateMonth")}</span>
                                                                    </div>
                                                                    <div className={`col-sm-${isAdminProject ? 3 : 2}`}>
                                                                        <span className="font-14 mob-font-13 text-dark-black font-weight-600 text-capitalize pl-3">{item.update_status === 0 ? item.iteration_new : item.iteration}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                    <div className="row m-0">
                                                        <div className="col-sm-12">
                                                            <div className="pt-3 pb-4 dlfex">
                                                                {proposal && !isCreatorInAdminProject ?
                                                                    <div className="row-border row m-0 py-3">
                                                                        <div className="col-sm-8 offset-sm-4">
                                                                            <div className="card-wrapper mb-0">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="w-60 text-right">
                                                                                        <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                                                            Sub Total
                                                                            </h2>
                                                                                    </div>
                                                                                    <div className="w-40 pl-5">
                                                                                        <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(proposal.update_status === 0 ? proposal.sub_total_cost_new : proposal.sub_total_cost)}</h2>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-wrapper mb-0">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="w-60 text-right">
                                                                                        <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                                                            G.S.T @ 18%
                                                                            </h2>
                                                                                    </div>
                                                                        <div className="w-40 pl-5">
                                                                                        <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(proposal.update_status === 0 ? proposal.tax_new : proposal.tax)}</h2>
                                                                                    </div>
                                                                    </div>
                                                                            </div>
                                                                            <div className="card-wrapper mb-0">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="w-60 text-right">
                                                                                        <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                                                                            Total Project Cost for Client
                                                                        </h2>
                                                                                    </div>
                                                                                    <div className="w-40 pl-5">
                                                                                        <h2 className="font-16 font-weight-700 text-dark-black"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(proposal.update_status === 0 ? proposal.total_cost_new : proposal.total_cost)}</h2>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        
                                                                            {isRepresentative ? <div className="card-wrapper mb-0">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="w-60 text-right">
                                                                                        <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                                                                            Creator Earning
                                                                                    </h2>
                                                                                    </div>
                                                                                    <div className="w-40 pl-5">
                                                                                        <h2 className="font-16 font-weight-700 text-dark-black"><i className="fas fa-rupee-sign"></i>&nbsp;{separateNumber(proposal.update_status === 0 ? proposal.total_earning_new : proposal.total_earning)}</h2>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null}
                                                                        </div>
                                                                    </div> : null}
                                                                    {(proposal.note !== "" || proposal.note_new !== "") && 
                                                                        <div className="d-flex align-items-center pt-3 pb-4">
                                                                            <div className="">
                                                                                <p className="mb-0 creator-profile"><img src={BaseImageUrl + user.profile_image} alt="profile" title="" width="35" /></p>
                                                                            </div>
                                                                            <div className="pl-3">
                                                                                <span className="font-weight-500 text-third font-14 mob-font-12">Notes from {user.first_name} </span>
                                                                                <h2 className="chat-username m-0 text-dark-black font-14 font-weight-600 mob-font-12">
                                                                                    <RenderHtml data={proposal.update_status === 0 ? proposal.note_new : proposal.note} />
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    } 
                                                                    {files && files.length > 0 &&
                                                                        <div className="client_view_attachment">
                                                                            <div className="d-flex align-content-center flex-wrap inner-preview-fileupload">
                                                                                {files.map((file, idx) => {
                                                                                    return (
                                                                                        <span key={idx} className="upload-files mob-w-100 mob-ml-0 mx-1 py-2 pl-2 pr-3 mb-2 border-1 border-radius-8">
                                                                                            <a href={ChatImageBaseUrl + file.name} rel="noopener noreferrer" target="_blank">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <div className="pl-2">
                                                                                                        {renderDifferentFiles(file.name)}
                                                                                                    </div>
                                                                                                    <div className="pl-3">
                                                                                                        <h2 className="font-weight-600 text-dark-black m-0 font-14 mob-font-12 line-height-normal">
                                                                                                            {getFileName(file.name)}
                                                                                                        </h2>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="remove-file">
                                                                                                    <i className="fas fa-times" />
                                                                                                </div>
                                                                                            </a>
                                                                                        </span>
                                                                                    )
                                                                                })

                                                                                }
                                                                                
                                                                            </div> 
                                                                        </div>
                                                                    }

                                                                  {images_links.length>0 && <div className="row m-0 add_image_url_wrapper">
                                                                        {/* Add image or url */}
                                                                        <div className="col-sm-12 pt-4 pb-2">
                                                                            <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Project References</span>
                                                                        </div> 
                                                                        {
                                                                            images_links?.length > 0 && 
                                                                            <div className="col-sm-12 uploading-image review_milestone_uploading">
                                                                                <div className="upload-thumbnail mb-2">
                                                                                {
                                                                                    images_links?.length > 0 && images_links?.map((obj, index) => {
                                                                                        const data = ['gif', 'jpg', 'jpeg', 'png']
                                                                                        if(obj.type === "files"){
                                                                                        return(                                
                                                                                            <span
                                                                                            className="upload-thumb"
                                                                                            draggable="true"
                                                                                            style={{ opacity: 1 }}
                                                                                            >
                                                                                            {/* <div className="upload-delete">
                                                                                                <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                                                                            </div> */}
                                                                                                <a href={ChatImageBaseUrl + obj.name } target="_blank" key={index} style={{display:"contents"}}>
                                                                                            <span
                                                                                                className="upload_image_thumbnail"
                                                                                                style={{backgroundImage:`url("${
                                                                                                    handleFileType(obj?.name, ChatImageBaseUrl)
                                                                                                        // data?.includes(obj?.name?.split('.')[1])  ? 
                                                                                                        // ChatImageBaseUrl + obj.name :
                                                                                                        // obj?.name?.includes(".pdf") ?
                                                                                                        // PdfImage : 
                                                                                                        // obj?.name?.includes(".zip")?
                                                                                                        // ZipImage :
                                                                                                        // obj?.name?.includes(".ppt")?
                                                                                                        // PPtImage:
                                                                                                        // DocImage
                                                                                                    }")
                                                                                                    `}}
                                                                                                    />
                                                                                                    </a>
                                                                                            </span>                                 
                                                                                        )
                                                                                        }  else{
                                                                                        count++
                                                                                            return(                                
                                                                                            <span
                                                                                                className="upload-thumb"
                                                                                                draggable="true"
                                                                                                style={{ opacity: 1 }}
                                                                                            >
                                                                                                {/* <div className="upload-delete">
                                                                                                    <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                                                                                </div> */}
                                                                                                <a href={obj?.name} target="_blank" key={index} style={{display:"contents"}}>
                                                                                                <span
                                                                                                    className="upload_image_thumbnail"
                                                                                                    style={{backgroundImage:`url(${LinkImage})`}}
                                                                                                />
                                                                                                </a>
                                                                                                
                                                                                            </span>                      
                                                                                            )
                                                                                        }                              
                                                                                    })
                                                                                }
                                                                                </div>
                                                                            </div> 
                                                                            }

                                                                    </div>}
                                                            </div>                                                         
                                                        </div>
                                                        
                                                        {
                                                            (showMsgBox) ?
                                                                <>
                                                                    <div ref={this.scrollRef} className="col-sm-12 d-flex align-items-center">
                                                                        <div className={`submit-milestone-summernote w-90 ${msgError ? 'error' : ''}`}>
                                                                            <CustomSummernote
                                                                                placeholder="Please enter your requests & changes for the creator here"
                                                                                value={this.state.changeMsg}
                                                                                onChange={this.requestMessage}
                                                                                sendMsg={this.sendRequestChanges}
                                                                            />
                                                                        </div>
                                                                        <button type="button" className="main-btn cursor-pointer font-weight-500 ml-3" onClick={this.sendRequestChanges} >Send</button>
                                                                    </div>
                                                                    {msgError ? <p className="col-sm-12 mb-0 text-danger font-weight-600 font-12 py-1">Please input your message.</p> : ""}
                                                                </>
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {Mobile View Starts Here} */}
                                        <div className="card-wrapper mb-0 mobile-show">
                                            <div className="custom_form">
                                                <div className="row m-0 bor-bottom pb-4">
                                                    <div className="col-12 col-sm-12 d-flex align-items-center pt-4">
                                                        <div className="w-40">
                                                            <span className="d-block font-12 text-sub font-weight-500">Project quote</span>
                                                            <span className="font-18 text-dark-black font-weight-600"><span className="project-date-star position-relative"><i className="fas fa-rupee-sign font-16"></i>{(proposal.update_status === 0) ? `${separateNumber(proposal.sub_total_cost_new)}` : `${separateNumber(proposal.sub_total_cost)}`}</span></span>
                                                            <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span>
                                                        </div>
                                                        <div className="w-50 project-delivery-date position-relative">
                                                            <span className="d-block font-12 text-sub font-weight-500">Project delivery date</span>
                                                            <span className="font-18 text-dark-black font-weight-600 text-capitalize">{getDateTime(milestone[milestone.length - 1]?.due_date, "dateMonth")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom_table">
                                                {milestone && milestone.map((item, index) => (
                                                        (item.type === "token") &&
                                                            <div key={index} className="wrapper-form py-3 mob-border-radius-0">
                                                                <div className="row m-0">
                                                                    <div className="col-9">
                                                                        <div>
                                                                            <h3 className="text-third mb-2 font-15 font-weight-500 line-height-16">Advance one-time non-<span className="d-block">refundable fee <span className="text-sub-8"><i className="fas fa-info-circle"></i></span></span></h3>
                                                                            <button type="button" className="main-btn non-btn bg-tranparent text-third font-12 cursor-pointer font-weight-500">{this.getPercent(item.price)} % of total payment</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 small-p-0 small-mid-p-0">
                                                                        <div>
                                                                            <span className="text-dark-black mb-0 font-18 small-font-16 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>{separateNumber(item.price)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                    {milestone && milestone.map((item, index) => (
                                                        (item.type !== "token") &&
                                                            <div key={index} className="row-border py-3">
                                                                <div className="row m-0">
                                                                    <div className="col-9">
                                                                        <div>
                                                                            <span className="text-sub mb-1 font-14 d-block font-weight-500">Milestones {index}</span>
                                                                            <h3 className="text-dark-black mb-1 font-15 font-weight-600">{item.name}</h3>
                                                                            <span className="font-13 text-dark-black d-block font-weight-600 text-capitalize">Due Date: {getDateTime(item.due_date, "dateMonth")}</span>
                                                                        </div>
                                                                    </div>
                                                                    {!isAdminProject ? <div className="col-3 small-p-0 small-mid-p-0">
                                                                        <div>
                                                                            <span className="text-sub font-10 mb-1 d-block font-weight-500">Due Amount</span>
                                                                            <span className="text-dark-black mb-0 font-18 small-font-16 font-weight-600"><i className="fas fa-rupee-sign font-13"></i>
                                                                            {separateNumber(item.price)}
                                                                            </span>
                                                                        </div>
                                                                    </div> : null}
                                                                </div>
                                                            </div>
                                                    ))} 
                                                    <div className="row m-0">
                                                        <div className="col-sm-12">
                                                            <div className="d-flex pt-3 pb-4">
                                                                {(proposal.note !== "") ?
                                                                    <div className="d-flex">
                                                                        <div className="">
                                                                            <p className="mb-0 creator-profile"><img src={BaseImageUrl + user.profile_image} alt="profile" title="" width="35" /></p>
                                                                        </div>
                                                                        <div className="pl-3">
                                                                            <span className="font-weight-500 text-third font-13 mob-font-12">{user.first_name} also mentioned</span>
                                                                            <h2 className="chat-username text-capitalize m-0 text-black font-13 font-weight-600 line-height-16"><RenderHtml data={proposal.update_status === 0 ? proposal.note_new : proposal.note} /></h2>
                                                                        </div>
                                                                    </div> : <></>}
                                                            </div>
                                                        </div>
                                                        {images_links.length>0 && <div className="row m-0 add_image_url_wrapper">
                                                                        {/* Add image or url */}
                                                                        <div className="col-sm-12 pb-2">
                                                                            <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Project References</span>
                                                                        </div> 
                                                                        {
                                                                            images_links?.length > 0 && 
                                                                            <div className="col-sm-12 uploading-image review_milestone_uploading">
                                                                                <div className="upload-thumbnail mb-2">
                                                                                {
                                                                                    images_links?.length > 0 && images_links?.map((obj, index) => {
                                                                                        const data = ['gif', 'jpg', 'jpeg', 'png']
                                                                                        if(obj.type === "files"){
                                                                                        return(                                
                                                                                            <span
                                                                                            className="upload-thumb"
                                                                                            draggable="true"
                                                                                            style={{ opacity: 1 }}
                                                                                            >
                                                                                            {/* <div className="upload-delete">
                                                                                                <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                                                                            </div> */}
                                                                                                <a href={ChatImageBaseUrl + obj.name } target="_blank" key={index} style={{display:"contents"}}>
                                                                                            <span
                                                                                                className="upload_image_thumbnail"
                                                                                                style={{backgroundImage:`url("${
                                                                                                    handleFileType(obj?.name, ChatImageBaseUrl)
                                                                                                        // data?.includes(obj?.name?.split('.')[1])  ? 
                                                                                                        // ChatImageBaseUrl + obj.name :
                                                                                                        // obj?.name?.includes(".pdf") ?
                                                                                                        // PdfImage : 
                                                                                                        // obj?.name?.includes(".zip")?
                                                                                                        // ZipImage :
                                                                                                        // obj?.name?.includes(".ppt")?
                                                                                                        // PPtImage:
                                                                                                        // DocImage
                                                                                                    }")
                                                                                                    `}}
                                                                                                    />
                                                                                                    </a>
                                                                                            </span>                                 
                                                                                        )
                                                                                        }  else{
                                                                                        count++
                                                                                            return(                                
                                                                                            <span
                                                                                                className="upload-thumb"
                                                                                                draggable="true"
                                                                                                style={{ opacity: 1 }}
                                                                                            >
                                                                                                {/* <div className="upload-delete">
                                                                                                    <i className="far fa fa-times" onClick={()=>this.handleRemove(index)}/>
                                                                                                </div> */}
                                                                                                <a href={obj?.name} target="_blank" key={index} style={{display:"contents"}}>
                                                                                                <span
                                                                                                    className="upload_image_thumbnail"
                                                                                                    style={{backgroundImage:`url(${LinkImage})`}}
                                                                                                />
                                                                                                </a>
                                                                                                
                                                                                            </span>                      
                                                                                            )
                                                                                        }                              
                                                                                    })
                                                                                }
                                                                                </div>
                                                                            </div> 
                                                                            }

                                                                    </div>}
                                                        <div className="col-12 col-sm-12 mob-pl-18 mb-3 ">
                                                            {showButtons && <div className={`dflex text-center ${current_project_status !== 3 && CurrentRole === 1 && proposal.update_status === 3 ? 'flex-wrap' : 'flex-wrap'}`}>
                                                                {current_project_status !== 3 && CurrentRole === 1 ?
                                                                    <button type="button" className="w-48 main-btn bg-light-gray font-12 small-font-10 py-12 cursor-pointer font-weight-600 " onClick={this.requestChanges}>Request changes</button> : null 
                                                                } 
                                                                
                                                                {/* {current_project_status !== 3 && CurrentRole === 1 && proposal.update_status === 3 ?
                                                                    <button type="button" className="w-48 main-btn  font-12 small-font-10 py-12 cursor-pointer font-weight-600"
                                                                    onClick={ (e) => { this.rejectUpdateProposal(proposal._id) }}> Reject</button>
                                                                    :<></>} */}


                                                                {CurrentRole === 2 && proposal.update_status === 1 && !isAdminProject ? 
                                                                    <button type="button" className="w-48 main-btn font-12 small-font-10 py-12 cursor-pointer font-weight-600 " onClick={() => this.props.OpenPopup('ReviewMilestone')}>Make Changes</button> : null
                                                                }
                                                                {!hideVersionHistoryButton && CurrentRole === 2 ? <button type="button" className="w-48 main-btn bg-light-gray font-12 small-font-10 py-12 cursor-pointer font-weight-600 ml-2" onClick={this.viewProposalVersions}>View version history</button> : null}
                                                                {!hideVersionHistoryButton && CurrentRole === 1 && !this.props?.history?.location?.pathname?.startsWith("/talentconnect") ? <button type="button" className="w-48 main-btn bg-light-gray font-12 small-font-10 py-12 cursor-pointer font-weight-600 ml-2" onClick={this.viewProposalVersions}>View version history</button> : null}
 
                                                                {current_project_status !== 3 && CurrentRole === 1 && proposal.update_status === 3 ?
                                                                    <button type="button" className="w-100 main-btn mt-2 font-12 small-font-10 py-12 cursor-pointer font-weight-600"
                                                                    onClick={ (e) => { this.rejectUpdateProposal(proposal._id) }}> Reject</button>
                                                                    :<></>}
                                                            </div>}
                                                        </div>
                                                        {
                                                            (showMsgBox) ?
                                                                <div className="col-sm-12">
                                                                    <span className="text-sub mb-1 font-12 d-block font-weight-500">Request changes</span>
                                                                    <textarea onChange={this.requestMessage} className={`form-control w-100 email min-h-120 ${msgError ? 'border-error' : ''}`} id="comment"></textarea>
                                                                    {msgError ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please input your message.</p> : ""}
                                                                    <button onClick={this.sendRequestChanges} type="button" className="main-btn py-12 mt-4 mob-w-100 cursor-pointer font-weight-500">Send to {user.first_name}</button>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { CurrentRole === 1 
                                        ? showButtons &&
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer client-cost mobile-ipad-show mob-p-20">
                                            <div className="text-center">
                                               
                                            {current_project_status !== 3 && (proposal.status === 0 || proposal.update_status === 0) &&   <button onClick={(proposal.update_status === 0) ? (e) => { this.approveUpdatedProposal(proposal._id) } : (e) => { this.props.OpenPopup('KickStartProject') }} type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer small-font-12">
                                                    Approve and Pay
                                                </button>}
                                               
                                                
                                                
                                            </div>
                                        </div> 
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
            </>
        )
    }
}

const mapState = (store) => {
    let chat = store.chat;
    let postProjectData = store.postProject;
    let globalReducer = store.globalReducer;
    return {
        collateral_name: postProjectData.selected_collateral_name,
        review_proposal_data: postProjectData.review_proposal_data,
        project_name: postProjectData?.project_name,
        projectId: postProjectData?.projectId,
        user_list: postProjectData.creatorList,
        closeModal: postProjectData.closeModal,
        current_proposal_id: postProjectData.current_proposal_id,
        current_project_status: postProjectData.current_project_status,
        CurrentChat: chat.CurrentChat,
        Artist: chat.Artist,
        ProjectName: chat.ProjectName,
        ProjectType: chat.ProjectType,
        ProjectId: chat.ProjectId,
        ProjectStatus: chat.ProjectStatus,

        current_project_id: postProjectData.current_project_id,
        UserId: chat.UserId,
        CurrentRole: globalReducer.Me.CurrentRole,
    };
}

const mapDispatch = dispatchEvent => ({
    loadChat: (projectId, projectName, projectStatus) => dispatchEvent({ type: 'UPDATE_LOAD_CHAT', payload: { id: projectId, name: projectName, project_status: projectStatus } }),
    loadChatMessages: (connection_id) => dispatchEvent({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
    LoadReviewProposalData: (values) => dispatchEvent({ type: 'LOAD_REVIEW_PROPOSAL_DATA', payload: values }),
    closePopup: () => dispatchEvent({ type: 'CLOSE_POST_PROJECT_POPUP', payload: {} }),
    OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    loadLatestProposals: (projectId) => dispatchEvent({ type: 'LOAD_LATEST_PROPOSAL', payload: projectId }),
    loadChatAndLatestProposals: (projectId, projectName, projectStatus) => dispatchEvent({ type: 'LOAD_CHAT_AND_LATEST_PROPOSAL', payload: { id: projectId, name: projectName, project_status: projectStatus } }),

});

export default connect(mapState, mapDispatch)(ReviewProposal);

const CreatorEarningText = ({data}) => {
    const {cost, title='', className=''} = data
    return (
        <div className={`${className}`}>
            <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">{title}</span>
            <span className="font-20 mob-font-18 text-dark-black font-weight-600"><span className="project-date-star position-relative"><i className="fas fa-rupee-sign font-18"></i>{cost}</span></span>
            {/* <span className="d-block font-11 text-sub font-weight-500">Excl. of GST</span> */}
        </div>
    )
}


