import React, { useEffect, useState } from "react";
import PercentIcon from "../../../../../Assets/upgrade-images/retainer_open/percentageBlue.svg";
import PercentIconGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageGreen.svg";
import PercentIconDarkGreen from "../../../../../Assets/upgrade-images/retainer_open/percentageDarkGreen.svg";
import MoneyIcon from "../../../../../Assets/upgrade-images/view_brief/money.svg";
import MenuBoardIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/menu-board.svg";
import InfoIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/info-circle.svg";
import WalletIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/wallet.svg";
import Loader from "../../../Common/Common/Loader";
import FeaturedToggleImage from "../../../../../Assets/upgrade-images/submit_proposal_fixed_cost/toggle_btn.png";
import { separateNumber } from "../../../ProfileCreator/TimeRetainer";
import moment from "moment";
import {
  convertToPlain,
  handleDelivaryDate,
  handleSelectedNames,
  onkeyCode,
  retainerTableValues,
} from "../CommonFunctionsNew/CommonFunctionsNew";
import ReferenceForClient from "../RefrenceForClient/RefrenceForClient";
import ProjectsFooter from "./ProjectsFooter";
import ExclamationIcon from "../../../../../Assets/upgrade-images/client_view_fixed_proposal/exclamation.png";
import { limitString, sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";
import { postAPI } from "../../../../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../../../Assets/upgrade-images/submit_proposal/alert.svg";
import Popup from "../../../Chat/Popup";
import ViewModalPopup from "../../../ProfileCreator/Popup.js"

export default function ViewPropShow({ state, setState, loader, ...props }) {
  const [selectedCreator,setSelectedCreator] = useState('')
  const [openedFrom,setOpenedFrom] = useState('')
  const [selectedCreatorType,setSelectedCreatorType] = useState('')
  const [selectedCreatorData,setSelectedCreatorData] = useState({})

  const projectData = props?.projectDataStates?.data?.project_data;
  const _chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  // console.log(_chat.acceptedIndividual);
  const handleChooseDays = (name) => {
    let arr = [];
    state.workingDaysInWeek.forEach((item) => {
      if (item.name === name) arr.push({ ...item, isActive: !item.isActive });
      else arr.push({ ...item });
    });
    let filter = arr?.filter((obj) => obj?.isActive);
    state.validation.workingDaysInWeek = filter.length > 0 ? false : true;
    setState({
      ...state,
      workingDaysInWeek: [...arr],
    });
  };

  const handleActualQuote = () => {
    let data = state.deliverable.map((item) => {
      return { amount: item?.breakup, name: item?.name };
    });
    return data;
  };

  useEffect(() => {
    if (props?.approveProposal && state?.proposalData?.is_incoming_proposal) {
      props.ApiCall(2);
    }
  }, []);

  const handleTimeCommitment = (action) => {
    let name = "";
    let result = "";
    let filter = [];
    if (action == 3) {
      name = "Working days in a week";
      filter = state.workingDaysInWeek?.length > 0 ? state.workingDaysInWeek.filter((item) => item.isActive) : [];
    } else {
      let obj = retainerTableValues(action, state);
      name = obj?.name;
      result = obj?.result;
    }

    return (
      <>
        <div className="duration_title col-xl-12 px-0">
          <div className="d-block d-md-flex justify-content-between p-3">
            <h4 className="font_semi_bold_w_600">{name}</h4>
            {action == 3 ? (
              <div className="duration_fields d-flex col-lg-4 justify-content-end mob-justify-content-unset">
                {filter?.length > 0
                  ? filter.map((item, i) => {
                      return (
                        <div
                          className="circle_btn_field d-flex mr-2 cursor-pointer"
                          onClick={() => handleChooseDays(item.name)}
                        >
                          <div className={`round_btn_blue_common ${item.isActive ? "active" : ""}`}>
                            <p className="mb-0">{item.label}</p>
                          </div>
                        </div>
                      );
                    })
                  : "N/A"}
              </div>
            ) : (
              <h4 className="font_semi_bold_w_600">{result}</h4>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleViewProfile = (data) => {
    // console.log(index, data);
    const proposalid = data.creator_id._id;
    const username = data.creator_id.username;
    const userType = data.user_type;
    // const {authIs} = this.props;
    setSelectedCreatorType(userType);
    setSelectedCreator(proposalid);
    setSelectedCreatorData(data);
    // props.OpenPopup('SliderModalBrief')
    // props.loadPublicCreatorData(username)
    dispatch({
      type: "OPEN_POPUP_SAGA",
      payload: { Component: "SliderModalBrief" },
    });
    dispatch({ type: "LOAD_CREATOR_PUBLIC", payload: { user_name: username } });
  };

  const onClickViewProjects = (ele) => {
    if (!ele.project_data) {
      alert("No Projects for the Selected Collateral");
      return;
    }
    if (ele?.review_status) {
      setOpenedFrom("slidermodalbrief");
    } else {
      setOpenedFrom("overview");
    }
    // this.props.LoadProjectData(ele.project_data._id);
    // this.props.closePopup();
    //   this.props.OpenPopupPoject('ViewModal');
    dispatch({ type: "UPDATE_CREATOR_PUBLIC", values: true });
    // dispatch({ type: "LOAD_ALL_PROJECTS_SAGA", values: ele.username })
    dispatch({
      type: "LOAD_PROJECT_DATA",
      payload: { id: ele.project_data._id },
    });
    dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} });
    dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "ViewModal" } });
  };

  const handleApproveProposalAM = async (e) => {
    e.preventDefault();
    let totalAcceptedIndividual = _chat.acceptedIndividual;
    let data = [];
    let payload = {
      post_project_id: props.ProjectId,
      user_id: props.userId,
      proposal_id: props.proposalId,
      note: state.proposalData.note,
    };
    data.push(payload);

    try {
      if (data) {
        const res = await postAPI(`/milestone/admin-approved-proposal`, { data: data });
        if (res.status === 200) {
          props.handleGetProposal(props.ProjectId, props?.userId);
          dispatch({ type: "UPDATE_CHAT_USERS", values: { acceptedIndividual: totalAcceptedIndividual + 1 } });
        }
      }
    } catch (error) {
      // if (error.response.status === 401) {
      // }
    }
  };

  const showRequestChange = () => {
    return (
      <div className="page_card mb-3">
        <div className="proposal_header br_btm d-flex align-items-center justify-content-start">
          <div className="prposal_heading d-flex align-items-center">
            <div className="heading_logo">
              <img src={Alert} className="mr-3" />
            </div>
            <div className="heading_info">
              <p className="mb-0">Client has requested changes in the proposal</p>
              {/* <p className="text_light_gray_xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </div>
          </div>
        </div>
        <div className="row  br_btm mx-0">
          <div className="col-lg-12 p-4">
            {/* <p className="text_light_gray_md mb-0">{state?.proposalData?.request_change_text}</p> */}
            {state?.proposalData?.request_change_text && (
              <div
                className="text_light_gray_md mb-0"
                dangerouslySetInnerHTML={{ __html: state.proposalData.request_change_text }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  // const tempNote = state?.note?.length ? convertToPlain(state.note)  : ""
  const notes = state?.note?.length ? sanitiseSummernoteText(state?.note.replace(/\n/g, "<br/>")) : "";

  return (
    <div className={`col-xxl-9 col-lg-${props?.col} px-0`}>
      {state?.proposalData?.isRequestChange ? showRequestChange() : null}

      <div className="page_card">
        {/* header */}
        <div className={`${props?.match?.params?.subroute === "overview" ? "" : "content_scrollbar scroll_bar_body"}`}>
          <div className={`proposal_header ${props?.isTab ? "" : "br_btm mb-4"} d-flex justify-content-between`}>
            <div className="prposal_heading d-flex align-items-center">
              <p className="mb-0 mr-3">{props?.approveProposal ? "Client Approved Proposal" : "Proposal Type"}</p>
            </div>
            <div className="edit_proposal d-flex align-items-center">
              {state?.type &&
                (state?.type == "fixed" ? (
                  <div className="tag_btn_common">
                    <p className="mb-0">
                      <img src={PercentIcon} className="icon_img mr-1" />
                      Fixed Scope
                    </p>
                  </div>
                ) : state?.type == "retainer" ? (
                  <div className="tag_btn_green_common">
                    <p className="mb-0">
                      <img src={PercentIconGreen} className="icon_img mr-1" />
                      Retainer
                    </p>
                  </div>
                ) : (
                  <div className="tag_btn_dark_green_common">
                    <p className="mb-0">
                      <img src={PercentIconDarkGreen} className="icon_img mr-1" />
                      On-Actuals
                    </p>
                  </div>
                ))}
            </div>
          </div>

          {projectData?.sub_type != state?.type && projectData?.status <= 5 && props?.CurrentRole == 1 ? (
            <div className="row br_btm mx-0 mb-5 px-4">
              <div
                className="col-lg-12 p-3"
                style={{ border: "1px solid #E8ECF4", borderRadius: "10px", borderLeft: "9px solid #1464DC" }}
              >
                <div className="alert_container">
                  <p>
                    <img src={ExclamationIcon} alt="" className="icon_img mr-3" />
                    Creator has proposed a different proposal type
                  </p>

                  <p className="text_light_gray_xs w-60 mob-w-100">
                    {props.CurrentChat.first_name + " " + props.CurrentChat.last_name} has sent{" "}
                    {props.CurrentChat.proposal_data.proposal_type.toLowerCase() == "actual"
                      ? "an On-Actuals"
                      : "a " + props.CurrentChat.proposal_data.proposal_type}{" "}
                    proposal in response to your {props.clientBriefDetails.project_data.sub_type} brief. They believe
                    this work arrangement will work better for your requirement.
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit laoreet est augue faucibus ac. Est morbi cras
                    nisl, non quis massa mattis sit arcu. */}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {/* cards */}
          <div
            className={`${props?.match?.params?.subroute === "overview" ? "content_scrollbar scroll_bar_body" : ""}`}
          >
            <div className="row mx-0 px-0 mb-2">
              {projectData?.were_admin_project ? null : (
                <div className={`col-lg-${props?.CurrentRole != 2 && state?.type !== "fixed" ? "6" : "4"} mb-2`}>
                  <div class="card view_proposal_card">
                    <div class="card-body px-4 py-4">
                      <div className="card_body_inner d-flex">
                        <div className="inner_item mr-4">
                          <div className="card_icon d-flex">
                            <img src={MoneyIcon} alt="" className="icon_img" />
                          </div>
                        </div>
                        <div className="inner_item">
                          <p className="mb-0 text_gray">
                            {state?.type == "retainer" ? "Retainer Fees" : "Project Quote"}
                          </p>
                          <p className="mb-2 text_black_md">
                            ₹{" "}
                            {state?.type == "actual"
                              ? handleActualQuote().length > 1
                                ? `${separateNumber(handleActualQuote()[0]?.amount)}/${limitString(
                                    handleActualQuote()[0]?.name,
                                    15
                                  )} +${handleActualQuote().length - 1}`
                                : `${separateNumber(handleActualQuote()[0]?.amount)}/${limitString(
                                    handleActualQuote()[0]?.name,
                                    15
                                  )}`
                              : state?.type == "fixed"
                              ? separateNumber(state?.sub_total_cost)
                              : `${separateNumber(state?.fees?.retainerFees)}/${
                                  state?.retainer_fees_type == "Hourly" ? " Hour" : " Month"
                                }`}
                          </p>
                          {state?.type == "actual" ? (
                            props?.CurrentRole == 1 ? (
                              <p className="text_light_gray_xs">Cost subjected to what you approve</p>
                            ) : null
                          ) : (
                            <p className="text_light_gray_xs">+ Applicable Taxes</p>
                          )}
                        </div>
                        {state?.type == "actual" ? null : (
                          <div className="tag_btn_gray card_tag text_gray_xxs_w_600">
                            Learn More
                            <div
                              className="position-relative tool"
                              data-tip="This is the total project cost being sent to the client. Taxes will be borne basis legal entity of the client and will not affect creator earnings."
                            >
                              <img src={InfoIcon} alt="" className="icon_img ml-2" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {props?.CurrentRole == 1 || props?.CurrentRole == 3 || state?.type == "actual" ? null : (
                <div className={`col-lg-${props?.CurrentRole != 2 && state?.type !== "fixed" ? "6" : "4"} mb-2`}>
                  <div class="card view_proposal_card h-100">
                    <div class="card-body px-4 py-4">
                      <div className="card_body_inner d-flex">
                        <div className="inner_item mr-4">
                          <div className="card_icon d-flex">
                            <img src={WalletIcon} alt="" className="icon_img" />
                          </div>
                        </div>
                        <div className="inner_item">
                          <p className="mb-0 text_gray">
                            {projectData?.were_admin_project ? "Your Earnings" : "You'll Receive"}
                          </p>
                          <p className="mb-2 text_black_md">
                            ₹{" "}
                            {state?.type == "fixed"
                              ? separateNumber(state?.total_earning)
                              : `${separateNumber(
                                  projectData?.were_admin_project && props?.CurrentRole == 2
                                    ? state?.fees?.retainerFees
                                    : state?.fees?.retainerFees * 0.85
                                )}/ ${state?.retainer_fees_type}`}
                          </p>
                        </div>
                        <div className="tag_btn_gray card_tag text_gray_xxs_w_600">
                          Learn More
                          <div
                            className="position-relative tool"
                            data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                          >
                            <img src={InfoIcon} alt="" className="icon_img ml-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {projectData?.status > 5 ? ( */}
              {props?.CurrentRole === 2 ? (
                <div className={`col-lg-${props?.CurrentRole != 2 && state?.type !== "fixed" ? "6" : "4"} mb-2`}>
                  <div class="card view_proposal_card h-100">
                    <div class="card-body px-4 py-4">
                      <div className="card_body_inner d-flex">
                        <div className="inner_item mr-4">
                          <div className="card_icon d-flex">
                            <img src={MenuBoardIcon} alt="" className="icon_img" />
                          </div>
                        </div>
                        <div className="inner_item">
                          <p className="mb-0 text_gray">
                            {state?.type == "fixed"
                              ? "Delivery Date"
                              : projectData?.status > 5
                              ? "Start Date"
                              : "Proposed Start Date"}
                          </p>
                          <p className="mb-2 text_black_md">
                            {state?.type == "fixed"
                              ? handleDelivaryDate(state, state?.deliverable, state?.type)
                              : moment(state?.proposed_start_date).format("MMM D, YYYY")}
                          </p>
                        </div>
                        {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={`col-lg-${props?.CurrentRole != 2 && state?.type !== "fixed" ? "6" : "4"} mb-2`}>
                    <div class="card view_proposal_card h-100">
                      <div class="card-body px-4 py-4">
                        <div className="card_body_inner d-flex">
                          <div className="inner_item mr-4">
                            <div className="card_icon d-flex">
                              <img src={MenuBoardIcon} alt="" className="icon_img" />
                            </div>
                          </div>
                          <div className="inner_item">
                            <p className="mb-0 text_gray">
                              {projectData?.status > 5 ? "Start Date" : "Proposed Start Date"}
                            </p>
                            <p className="mb-2 text_black_md">
                              {moment(state?.proposed_start_date).format("MMM D, YYYY")}
                            </p>
                          </div>
                          {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {props?.CurrentRole != 2 && state?.type === "fixed" ? (
                    <div className={`col-lg-${props?.CurrentRole != 2 && state?.type !== "fixed" ? "6" : "4"} mb-2`}>
                      <div class="card view_proposal_card h-100">
                        <div class="card-body px-4 py-4">
                          <div className="card_body_inner d-flex">
                            <div className="inner_item mr-4">
                              <div className="card_icon d-flex">
                                <img src={MenuBoardIcon} alt="" className="icon_img" />
                              </div>
                            </div>
                            <div className="inner_item">
                              <p className="mb-0 text_gray">Delivery Date</p>
                              <p className="mb-2 text_black_md">
                                {handleDelivaryDate(state, state?.deliverable, state?.type)}
                              </p>
                            </div>
                            {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              {/* ) : null} */}
            </div>

            {loader ? (
              <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
            ) : (
              <>
                {/* Fixed Section */}
                {state?.type == "fixed" ? (
                  <>
                    <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                      <div className="col-lg-12 title_commn">
                        <p className="mb-0">Deliverables</p>
                      </div>
                      {/* table  Desktop*/}
                      <div className="col-lg-12 custom_table px-0 d-none d-lg-block">
                        <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                          <div class="col-lg-1 col-1 text-left">
                            <span class="">#</span>
                          </div>
                          <div class="col-lg-5 col-5">
                            <span class="">Milestone</span>
                          </div>
                          <div class="col-lg-2 col-2">
                            <span class="">Due Date</span>
                          </div>
                          <div class="col-lg-2 col-2">
                            <span class="">Iterations</span>
                          </div>
                          <div class="col-lg-2 col-2">
                            <span class="">Price post iteration limit</span>
                          </div>
                        </div>

                        {state.deliverable.map((item, i) => {
                          return (
                            <div class="row-border creator-proposal">
                              <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                <div class="col-lg-1 col-1 text-left">{onkeyCode(i + 65)}</div>
                                <div class="col-lg-5 col-lg-5">
                                  <p className="mb-0">{item?.name}</p>
                                </div>
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                                </div>
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">{item?.iterations ? item?.iterations : ` - `}</p>
                                </div>
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">
                                    {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : ` - `}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* Mobile view*/}
                      <div className="col-lg-12 custom_table px-0 d-block d-lg-none">
                        {state.deliverable.map((item, i) => {
                          return (
                            <div class="row-border creator-proposal">
                              <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                <div class="col-lg-12 mb-2">
                                  <span className="font_semi_bold">
                                    {onkeyCode(i + 65)} {item?.name}
                                  </span>
                                </div>
                                <div class="col-lg-12 mb-2">
                                  <p className="text_text-gray-1400">Due Date</p>
                                  <p className="font_semi_bold">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                                </div>
                                <div class="col-lg-12 mb-2">
                                  <p className="text_text-gray-1400">Iterations</p>
                                  <p className="font_semi_bold">{item?.iterations ? item?.iterations : "-"}</p>
                                </div>
                                <div class="col-lg-12 mb-2">
                                  <p className="text_text-gray-1400">Price post iteration limit</p>
                                  <p className="font_semi_bold">
                                    {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* Payment_Terms */}
                    <div className="row px-4 pb-4 payment_terms mx-0  scroll_bar_body">
                      {/* header */}
                      <div className="col-lg-12 pb-3 title_commn d-flex justify-content-between align-items-center">
                        <p className="mb-0">Payment Terms</p>
                      </div>
                      {/* description */}
                      {/* <div className="col-lg-12 px-0 py-3">
                      <p className="discription_common">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit laoreet est augue faucibus ac. Est morbi
                        cras nisl, non quis massa mattis sit arcu. Facilisis purus vitae volutpat tincidunt consectetur pulvinar
                        convallis sed. Nulla risus sit in enim nam aliquam sapien phasellus rhoncus.
                      </p>
                    </div> */}
                      {/* table  Desktop*/}

                      <div className="custom_table col-lg-12 px-0 d-none d-lg-block">
                        <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                          <div class="col-lg-1 col-1 text-left">
                            <span class="">#</span>
                          </div>
                          <div
                            class={`col-lg-${props?.CurrentRole == 2 ? "7" : "9"} col-${
                              props?.CurrentRole == 2 ? "7" : "9"
                            }`}
                          >
                            <span class="">Payment Milestones</span>
                          </div>
                          {projectData?.were_admin_project && props?.CurrentRole == 2 ? null : (
                            <div class={`col-lg-2 col-2`}>
                              <span class="">Breakup</span>
                            </div>
                          )}
                          {props?.CurrentRole == 2 ? (
                            <div class="col-lg-2 col-2">
                              <span class="">
                                {projectData?.were_admin_project ? "Your Earnings" : "You'll Receive"}
                              </span>
                            </div>
                          ) : null}
                        </div>

                        {state?.advanceFees?.breakup ? (
                          <div class="row-border creator-proposal">
                            <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                              <div class="col-lg-1 col-1 text-left">1</div>
                              <div
                                class={`col-lg-${props?.CurrentRole == 2 ? "7" : "9"} col-${
                                  props?.CurrentRole == 2 ? "7" : "9"
                                }`}
                              >
                                <p className="mb-0">Advance Fees</p>
                              </div>
                              <div class={`col-lg-2 col-2 pr-0`}>
                                <p className="mb-0">₹ {separateNumber(state.advanceFees.breakup, "")}</p>
                              </div>
                              {props?.CurrentRole == 2 ? (
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">₹ {separateNumber(state.advanceFees.breakup * 0.85, "")}</p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {state.payments.map((item, i) => {
                          return (
                            <div class="row mx-0 mb-0 py-3 row-border align-items-center font_semi_bold_w_600">
                              <div class="col-lg-1 col-1 text-left">{state?.advanceFees?.breakup ? i + 2 : i + 1}</div>
                              <div
                                class={`col-lg-${props?.CurrentRole == 2 ? "7" : "9"} col-${
                                  props?.CurrentRole == 2 ? "7" : "9"
                                }`}
                              >
                                <p className="mb-0">{handleSelectedNames(item.names)}</p>
                              </div>
                              {projectData?.were_admin_project && props?.CurrentRole == 2 ? null : (
                                <div class={`col-lg-2 col-2 pr-0`}>
                                  <p className="mb-0">₹ {separateNumber(item?.breakup, "")}</p>
                                </div>
                              )}
                              {props?.CurrentRole == 2 ? (
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">
                                    ₹{" "}
                                    {projectData?.were_admin_project
                                      ? separateNumber(item?.total_earning, "")
                                      : separateNumber(item?.breakup * 0.85, "")}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>

                      {/* Mobile view */}
                      <div className="custom_table col-lg-12 px-0 d-block d-lg-none">
                        {state?.advanceFees?.breakup ? (
                          <div class="row-border creator-proposal">
                            <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                              <div class="col-lg-12 mb-3 font-weight-bold">1. Advance Fees</div>
                              <div class="col-lg-12">
                                <p className="text_text-gray-1400">Price Breakup</p>
                                <p className="font_semi_bold"> ₹ {separateNumber(state.advanceFees.breakup, "")}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {state.payments.map((item, i) => {
                          return (
                            <div class="row mx-0 mb-0 py-3 row-border align-items-center font_semi_bold_w_600">
                              <div class="col-lg-12 mb-3 font-weight-bold">
                                {state?.advanceFees?.breakup ? i + 2 : i + 1}. {handleSelectedNames(item.names)}
                              </div>
                              <div class="col-lg-12">
                                <p className="text_text-gray-1400 font-14">Price Breakup</p>
                                <p className="font_semi_bold"> ₹ {separateNumber(item?.breakup, "")}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : state?.type == "retainer" ? (
                  <>
                    <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                      <div className="col-lg-12 title_commn d-flex justify-content-between">
                        <p className="mb-0">Time Commitment</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            alt=""
                            data-toggle="collapse"
                            href={`#collapseCommitment`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseCommitment`}
                          />
                        </div>
                      </div>
                      {/* table */}
                      <div className="col-lg-12 px-0">
                        <div className="row mx-0 collapse show " id={`collapseCommitment`}>
                          {handleTimeCommitment(1)}
                          {handleTimeCommitment(2)}
                          {handleTimeCommitment(3)}
                        </div>
                      </div>
                    </div>

                    <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                      <div className="col-lg-12 title_commn d-flex justify-content-between">
                        <p className="mb-0">Payment Terms</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            href={`#collapsePayment`}
                            role="button"
                            alt=""
                            aria-expanded="false"
                            aria-controls={`collapsePayment`}
                          />
                        </div>
                      </div>
                      {/* table */}
                      <div className="col-lg-12 px-0">
                        <div className="row mx-0 collapse show " id={`collapsePayment`}>
                          {handleTimeCommitment(6)}
                          {handleTimeCommitment(4)}
                          {projectData?.were_admin_project ? null : handleTimeCommitment(5)}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                      <div className="col-lg-12 title_commn d-flex justify-content-between">
                        <p className="mb-0">Terms</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            href={`#collapseTerms`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseTerms`}
                          />
                        </div>
                      </div>
                      {/* table */}
                      <div className="col-lg-12 px-0">
                        <div className="row mx-0 collapse show " id={`collapseTerms`}>
                          {handleTimeCommitment(1)}
                          {handleTimeCommitment(6)}
                        </div>
                      </div>
                    </div>

                    <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                      <div className="col-lg-12 title_commn d-flex justify-content-between">
                        <p className="mb-0">Deliverables</p>
                        <div className="d-block d-lg-none">
                          <img
                            src={FeaturedToggleImage}
                            className="icon_img mr-1 cursor-pointer"
                            data-toggle="collapse"
                            alt=""
                            href={`#collapseDeliverab`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapseDeliverab`}
                          />
                        </div>
                      </div>
                      {/* table  Desktop View*/}
                      <div className="col-lg-12 custom_table px-0 d-none d-lg-block">
                        <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                          <div class="col-lg-1 col-1 text-left">
                            <span class="">#</span>
                          </div>
                          <div class="col-lg-5 col-5">
                            <span class="">Items</span>
                          </div>
                          <div class="col-lg-2 col-2">
                            <span class="">Pricing</span>
                          </div>
                          {!projectData?.were_admin_project && props?.CurrentRole == 2 ? (
                            <div class="col-lg-2 col-2">
                              <span class="">Your Earnings</span>
                            </div>
                          ) : null}
                          <div class="col-lg-2 col-2">
                            <span class="">Iterations</span>
                          </div>
                          {props?.CurrentRole != 2 || projectData?.were_admin_project ? (
                            <div class="col-lg-2 col-2">
                              <span class="">Price post iteration limit</span>
                            </div>
                          ) : null}
                        </div>

                        {state.deliverable.map((item, i) => {
                          return (
                            <div class="row-border creator-proposal">
                              <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                <div class="col-lg-1 col-1 text-left">
                                  {state?.type == "fixed" ? onkeyCode(i + 65) : i + 1}
                                </div>
                                <div class="col-lg-5 col-lg-5">
                                  <p className="mb-0">{item?.name}</p>
                                </div>
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">
                                    {" "}
                                    {item?.breakup ? `₹ ${separateNumber(item.breakup, "")}` : `-`}
                                  </p>
                                </div>
                                {!projectData?.were_admin_project && props?.CurrentRole == 2 ? (
                                  <div class="col-lg-2 col-2 pr-0">
                                    <p className="mb-0">
                                      {" "}
                                      {item?.breakup ? `₹ ${separateNumber(item.breakup * 0.85, "")}` : `-`}
                                    </p>
                                  </div>
                                ) : null}
                                <div class="col-lg-2 col-2 pr-0">
                                  <p className="mb-0">{item?.iterations ? item?.iterations : "-"}</p>
                                </div>
                                {props?.CurrentRole != 2 || projectData?.were_admin_project ? (
                                  <div class="col-lg-2 col-2 pr-0">
                                    <p className="mb-0">
                                      {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* table  Mobile View*/}
                      <div className="col-lg-12 custom_table px-0 d-block d-lg-none">
                        <div className="row mx-0 collapse show " id={`collapseDeliverab`}>
                          {state.deliverable.map((item, i) => {
                            return (
                              <div class="row-border creator-proposal">
                                <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                  <div class="col-12 mb-2 font-weight-600">
                                    {state?.type == "fixed" ? onkeyCode(i + 65) : i + 1}. {item?.name}
                                  </div>
                                  <div class="col-12 mb-2">
                                    <span class="text_text-gray-1400">Pricing</span>
                                    <p className="mb-0">
                                      {" "}
                                      {item?.breakup ? `₹ ${separateNumber(item.breakup, "")}` : `-`}
                                    </p>
                                  </div>
                                  {!projectData?.were_admin_project && props?.CurrentRole == 2 ? (
                                    <div class="col-12 pr-0">
                                      <span class="text_text-gray-1400">Your Earnings</span>
                                      <p className="mb-0">
                                        {" "}
                                        {item?.breakup ? `₹ ${separateNumber(item.breakup * 0.85, "")}` : `-`}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div class="col-12 mb-2">
                                    <span class="text_text-gray-1400">Iterations</span>
                                    <p className="mb-0">{item?.iterations ? item?.iterations : "-"}</p>
                                  </div>
                                  {props?.CurrentRole != 2 || projectData?.were_admin_project ? (
                                    <div class="col-12">
                                      <span class="text_text-gray-1400">Price post iteration limit</span>
                                      <p className="mb-0">
                                        {item?.iteration_charge
                                          ? `₹ ${separateNumber(item.iteration_charge, "")}`
                                          : `-`}
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <ReferenceForClient {...props} creatorUsername={state?.proposalData?.creatorUsername} />
              </>
            )}

            {/* add notes */}
            {state?.note?.trim()?.length ? (
              <div className="row px-4 pb-4 add_notes mx-0">
                <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                  <p className="mb-0">Additional Notes</p>
                  <div className="d-block d-lg-none">
                    <img
                      src={FeaturedToggleImage}
                      className="icon_img mr-1 cursor-pointer"
                      data-toggle="collapse"
                      alt=""
                      href={`#collapseNote`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={`collapseNote`}
                    />
                  </div>
                </div>
                <div className="col-lg-12 px-0">
                  <div className="row mx-0 collapse show " id={`collapseNote`}>
                    <div className="col-lg-12 px-0 py-4">
                      <div className="text_gray_xs_w_600" dangerouslySetInnerHTML={{ __html: notes }} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {props?.CurrentRole == 3 ? (
          props?.ProjectStatus == 5 && state?.proposalData?.status === 4 && _chat.acceptedIndividual < 3 ? (
            <div className="row mx-0 br_tp">
              <ProjectsFooter
                id={props?.id}
                showHistory={projectData?.status <= 5 && props?.CurrentRole == 1 ? true : false}
                showAppPay={state?.proposalData?.status == 0 && props?.CurrentRole == 1 ? true : false}
                isOffLine={parseInt(state?.proposalData?.advance_amount) == 0 ? true : false}
                {...props}
                type={state.type}
                stateData={state}
                proposalId={props?.proposalId}
                showTerminateProj={false}
                handleApproveProposalAM={handleApproveProposalAM}
              />
            </div>
          ) : null
        ) : projectData?.status == 7 ||
          projectData?.terminate_request == 1 ||
          projectData?.terminate_request == 2 ? null : (
          <div className="row mx-0 br_tp">
            <ProjectsFooter
              id={props?.id}
              showHistory={projectData?.status <= 5 && props?.CurrentRole == 1 ? true : false}
              showAppPay={state?.proposalData?.status == 0 && props?.CurrentRole == 1 ? true : false}
              isOffLine={parseInt(state?.proposalData?.advance_amount) == 0 ? true : false}
              {...props}
              type={state.type}
              stateData={state}
              proposalId={props?.proposalId}
              showTerminateProj={projectData?.status == 6 ? true : false}
              handleApproveProposalAM={handleApproveProposalAM}
            />
          </div>
        )}

        {selectedCreator && (
          <Popup
            {...props}
            selectedCreator={selectedCreator}
            hideActions={true}
            projectId={props?.match.params.projectId}
            // openProposal={this.openProposal}
            // openBrief={this.openBrief}
            // creatorUnderReview={creatorUnderReview}
            handleCreatorProfileByIndex={handleViewProfile}
            onClickViewProjects={onClickViewProjects}
            selectedCreatorType={selectedCreatorType}
            selectedCreatorData={selectedCreatorData}
            // Below props needed to showcase common slider
            // creatorRejected={artistArrayForSlider}
            // allCreators={artistArrayForSlider}
          />
        )}
        <ViewModalPopup
          {...props}
          hideActions={true}
          selectedCreator={selectedCreator}
          projectId={props?.match.params.projectId}
          // openProposal={this.openProposal}
          // openBrief={this.openBrief}
          // creatorUnderReview={creatorUnderReview}
          handleCreatorProfileByIndex={handleViewProfile}
          onClickViewProjects={onClickViewProjects}
          selectedCreatorType={selectedCreatorType}
          selectedCreatorData={selectedCreatorData}
          openedFrom={openedFrom}
          // creatorRejected={artistArrayForSlider}
        />
      </div>
    </div>
  );
}
