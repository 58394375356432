import React from 'react'
import { connect } from 'react-redux';
import CurrentProgress from './../../../Assets/images/current_progress.png';

function CurrentProgressCard(props) {
    const {CurrentChat, delivered_status, proposal, CurrentMilestone, proposalStatus} = props

    let text = null;
    // The project has been successfully completed
    if(proposalStatus === 2 ){
      text = <p className="mb-0 font-13 text-sub-4 font-weight-500">The project has been successfully completed
      </p>
    } else if(delivered_status === 1){
        text = <p className="mb-0 font-13 text-sub-4 font-weight-500">{CurrentChat && CurrentChat.first_name} is currently working on   Milestone  {CurrentMilestone.serial_no}
        <a href="/" className="d-block text-primary font-weight-600 mt-1" onClick={(e)=>{e.preventDefault();
        props.OpenPopup('ReviewProposal');
        props.SetCurrentProposal(proposal._id,proposal.post_project_id);
        }}> 
            View Timeline 
        <i className="fas fa-arrow-right ml-1"></i>
        </a>
        </p>
    } else if (delivered_status === 2){
        text = <p className="mb-0 font-12 text-sub-4 font-weight-500">{CurrentChat && CurrentChat.first_name} has submitted deliverables for milestone {CurrentMilestone.serial_no}. 
        <a href="/" className="d-block text-primary font-weight-600 mt-1" onClick={(e) => {e.preventDefault();
          props.OpenPopup('ReviewDeliverable');
          props.SetCurrentMileStoneID(CurrentMilestone.completed_milestone_id , 1);
        }}> 
          Review Submission<i className="fas fa-arrow-right"></i>
        </a>
      </p>
    } else if(delivered_status === 3){
        text = <p className="mb-0 font-12 text-sub-4 font-weight-500">{CurrentChat && CurrentChat.first_name} is reviewing your feedback on Milestone {CurrentMilestone.serial_no}.
        <a href="/" className="d-block text-primary font-weight-600 mt-1" onClick={(e)=>{e.preventDefault();
        props.OpenPopup('ReviewProposal');
        props.SetCurrentProposal(proposal._id,proposal.post_project_id);
        }}> 
            View Timeline 
        <i className="fas fa-arrow-right ml-1"></i>
        </a>
        </p>
    } else if(delivered_status === 4){
        text = <p className="mb-0 font-12 text-sub-4 font-weight-500">We are currently review your request to close this project and will be in touch with you.
        </p>
    }

    return (
        text ? <div className="card-wrapper mb-10">
        <div className="card">
          <div className="card-body px-2 py-3">
            <div className="row m-0">
              <div className="col-sm-12 mb-2">
                <h2 className="chat-username text-dark-black text-capitalize m-0 font-16 font-weight-600 mob-font-12">Current progress</h2>
              </div>
            </div>
            <div className="row m-0 d-flex">
              <div className="col-2 col-sm-2">
                <div className="text-center">
                  {/* <span><i className="fas fa-search font-15 text-primary"></i></span> */}
                  <img src={CurrentProgress} className="img-fliud" width="40" alt="Indiefolio" />
                </div>
              </div>
              <div className="col-10 col-sm-10 pl-2">
                <div className="">
                  {text}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : null
    )
}

const mapState = (state) => {
  const {proposalStatus} = state.postProject;

  return {
    proposalStatus,

  }
}

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    SetCurrentProposal: (proposal_id,project_id,reviewStatus) => dispatch({ type: 'SET_CURRENT_PROPOSAL', payload: {proposal_id,project_id,reviewStatus}}),
    SetCurrentMileStoneID: (milestone_id) => dispatch({ type: 'SET_CURRENT_MILESTONE_ID', payload: {milestone_id}}),
});

export default connect(mapState, mapDispatch)(CurrentProgressCard)
