import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Baseurl } from '../../../utils/BaseUrl';
import firebaseIs from '../../../utils/fire';
import firebase from 'firebase';
import { validateEmail } from '../../../utils/formValidator';
// import BtnLoader from '../../authorized/Common/Common/BtnLoader';
import Loader from './../Common/Loader/Loader';
import { postAPI } from '../../../utils/API';
// import $ from "jquery";
import {connect} from 'react-redux';
import {closeLoginPopUp,closeSignUp} from '../../../store/action/helmet.action'
import { logoutUserRedirect } from '../../authorized/Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew';


class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            statusResponseIs: '',
            socialPopup: false,
            showEmailModal: true,
            isLoading: false
        }
    }

    tempFunc = (e) => {
        e.preventDefault();
        this.setState({fadeOut: true})
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true})
        const userEmail = this.state.userEmail.toLowerCase();


        if (!validateEmail(userEmail)) {
            this.setState({isLoading: false, emailError: true})
        } else {
            axios({
                method: 'get',
                url: Baseurl + '/users/user-exist?email=' + userEmail,
            }).then(res => {
                const userData = res.data;
                
                const userStatus = userData.status;
                this.setState({fadeOut: true})
                const isPasswordResetFromBackend = userData.data && userData.data.password && userData.data.password !== ""
                
                setTimeout(() => {
                    this.setState({isLoading: false})
                    if(userStatus === '0'){
                        this.props.setHomeState({
                            emailModal: false, loginTypeIs: 'manual_login', userEmail, userData,
                            createNewAccountModal: true
                        })
                        
                    } else if (userStatus === '1'){
                        this.props.setHomeState({
                            emailModal: false, loginTypeIs: 'manual_login', userEmail, userData,
                            passwordModal: true, isAdminWantsLogin: isPasswordResetFromBackend                    
                        })
                        
                    } else if (userStatus === '2'){
                        this.props.setHomeState({
                            emailModal: false, loginTypeIs: 'manual_login', userEmail,
                            passwordModal: true, firebaseOnlyUser: true                
                        })
                        
                    } else if (userStatus === '3'){
                        // if password is reset by admin then show password modal
                        if(isPasswordResetFromBackend){
                            this.props.setHomeState({
                                emailModal: false, loginTypeIs: 'manual_login', userEmail, userData,
                                passwordModal: true, isAdminWantsLogin: isPasswordResetFromBackend                  
                            })
                        } else {
                            this.props.setHomeState({
                                emailModal: false, loginTypeIs: 'manual_login', userEmail, userData,
                                loginOptionsModal: true,               
                            })
                        }
                        
                    } else if (userStatus === '4'){
                        alert(userData.message)
                    }
                }, 200)
                    
            }).catch(error => {
                console.log("status-api-review-->", error)
            })
        }
    }

    EmailUser = (event) => {
        this.setState({
            userEmail: event.target.value, emailError: false
        })
    }

    handleSocialLogin = () => {
        this.setState({socialPopup: true})
        // const {userEmail,statusResponseIs} = this.state;
        const that = this;

        var provider = new firebase.auth.GoogleAuthProvider();

        firebaseIs.auth().signInWithPopup(provider).then(function (result) {
            let userEmail = result.user.email.toLowerCase();
            // console.log("firebase social login successful", userEmail);

            /*********api call ********/

            axios({
                method: 'get',
                url: Baseurl + '/users/user-exist?email=' + userEmail
            }).then(res => {
                const userStatus = res.data.status;

                if (userStatus === '2'){
                    that.props.setHomeState({
                        emailModal: false, loginTypeIs: 'social_login', userEmail,
                        createNewAccountModal: true, firebaseOnlyUser: true              
                    })
                    // console.log("Condition 2", res.data, userStatus);
                } else if (userStatus === '3' || userStatus === '1'){
                    // console.log("Condition 3", res.data, userStatus);
                    firebaseIs.auth().currentUser.getIdToken().then(function (idToken) {
                        const loginTokenIs = { token: idToken, login_type: 'social_login' }
                        axios({
                          method: 'post',
                          url: Baseurl + '/users/login', 
                          headers: { 'content-type': 'application/json' },
                          data: loginTokenIs,
                    
                        }).then((res => {
                          let userToken = res.data.token
                          localStorage.setItem("SignUpidToken", userToken);
                              that.props.reload();

                          // that.props.setHomeState({passwordModal: false})
                        //   if(that.props.isLoginOrSignupAsClient){
                        //     postAPI('/users/user-role-change', { current_set_role: 1 }).then((res) => {
                        //         that.props.reload()
                        //       }).catch((error) => {
                        //         console.log("change-rol-api error----->", error)
                        //       })
                        // }else{
                        // }
            
                        })).catch((error) => {
                          console.log("login-api-error------>", error);
                          that.setState({socialPopup: false})
                        })
                    }).catch(function (error) {
                        console.log("firebase login error", error)
                        that.setState({socialPopup: false})
                    });
                } else {
                    that.setState({ socialPopup: false })
                    alert('Something went wrong. Please try again.')
                }
            }).catch(error => {
                that.setState({ socialPopup: false })
                console.log("status-api-review-->", error)
            })

            /*********api call ********/

        }).catch(function (error) {
            that.setState({ socialPopup: false })
            console.log("firebase social login error--->", error);

        });

    }

    closePopup = () => {
        // this.props.onClickNote();
        // this.props.onClickNoteSignUp();

        // this.props.setHomeState({emailModal: false})
        logoutUserRedirect()        
    }

    render() {
        const { socialPopup, isLoading, emailError, userEmail } = this.state;
        // console.log('coming here: ', userEmail)
        // console.log("activePath in = ",this.props?.activePath)
        return (
                <React.Fragment>
                    <div className="modal fade custom_form custom_Modal_Box show" style={{display: 'block'}} data-backdrop="static" id="LoginModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.closePopup}><img src={require("../../../Assets/images/close.png")} className="img-fliud" width="35" alt=""/></span>
                        <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                            <div className="modal-content user_bg">
                                <div className="modal-body">
                                    <div className="row m-0">
                                        <div className="col-sm-7 mob_background_image">
                                            <div className="mob-login-background"></div>
                                        </div>
                                        <div className="col-sm-5 overflow-content mob-overflow-content user_modal">
                                        <div className={`login login_account ${this.state.fadeOut ? 'zoomOut' : 'zoomin'}`}>
                                            {this.props?.activePath == "/active-briefs" ? <h2>Log in to your account to view the brief</h2>
                                                :
                                                <h2>{this.props?.location?.state?.text?.length ? this.props.location.state.text : "Login / Signup" }</h2>
                                            }
                                                <form >
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            disabled={socialPopup}
                                                            value={userEmail}
                                                            className={`form-control email ${emailError ? 'border-error' : 's'}`}
                                                            // id="exampleInputEmail1"
                                                            placeholder="Email Address"
                                                            onChange={this.EmailUser}
                                                            autoFocus
                                                        />
                                                        {
                                                            emailError && <span className="text-danger font-weight-600 font-12 py-1">Add email to continue</span>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="main-btn mob-w-100 font-16 mob-font-14"
                                                            onClick={this.handleSubmit} disabled={isLoading || socialPopup}
                                                        >{isLoading ? <Loader /> :'Get started'} 
                                                            {!isLoading && <i className="fas fa-arrow-right"></i>}
                                                        </button>
                                                    </div>
                                                    <div className="social_icon">
                                                        <p>
                                                            <span className="mr-2 d-inline mob_width">Or log in with</span>
                                                            <span className="mob_d_flex">
                                                                <span onClick={this.handleSocialLogin} className="social_platform mr-15" >
                                                                    <img alt="" src={require("../../../Assets/images/google.png")} className="img-fliud google_icon mob_google_icon" width="45"
                                                                        
                                                                    />
                                                                    <span className="social_desktop_none" >Google</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {/* <div className="reCaptcha">
                                                        <p>This Site is protected by reCAPTCHA and the <br /> Google Privacy Policy and Teams of Service Apply.</p>
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 remove-pd overflow-hidden">
                                            <div className={`login-background ${this.state.fadeOut ? 'slideinright' : 'slideinleft'}`}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={this.closePopup} className="back-modal">
                    </div>
                </React.Fragment>
        )
    }
};
const mapStateToProps =(state)=>{

    return {
        login: state.loginReducer.login,
        signup: state.loginReducer.signup
    }
}

const mapDispatchToProps=(dispatch)=>{

    return {
        onClickNote : ()=>dispatch(closeLoginPopUp()),
        onClickNoteSignUp : ()=>dispatch(closeSignUp())
    }
 
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Modal));