import React, { useEffect, useState } from "react";
import { postAPI } from "../../../../../utils/API";
import { fireApi } from "../../../../../utils/fireApiPrecheck";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import { sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";

const RequestChange = (props) => {
  const [request, setRequest] = useState("");
  const [loader, setLoader] = useState(false);
  const [requestValid, setRequestValid] = useState(false);

  const formValidation = () => {
    let isValid = true;
    if (!request.length) {
      setRequestValid(true);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (formValidation()) {
      handleSubmitRequestChange();
    }
  };

  const handleSubmitRequestChange = async () => {
    if (request?.trim()?.length > 0) {
      const { projectId, connectionId } = props?.match?.params;

      setLoader(true);
      const proposal_details = {
        proposal_id: props?.proposalId,
        text: sanitiseSummernoteText(request.replace(/\n/g,'<br/>')),
      };
      try {
        let res = await postAPI("/milestone/chat-messages", proposal_details);
        if (res) {
          setLoader(false);
          props.changeShowRequestChange(false);
          if (connectionId) {
            props.history.push(`/projects/${projectId}/project_room/${connectionId}`);
          } else {
            props.history.push(`/projects/${projectId}/project_room`);
          }
          setTimeout(() => {
            alert("Request change successfully");
          }, 300);
        } else {
          setLoader(false);
          alert("Something went wrong please try again later");
        }
      } catch (err) {
        setLoader(false);
        alert("Something went wrong please try again later");
      }
    } else {
      setRequestValid(true);
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeShowRequestChange(false);
    }
  };

  const user = props?.stateData?.proposalData?.userData;
  const name = user?.first_name + " " + user?.last_name;
  return (
    <React.Fragment>
      <section className="ask_question">
        <div
          className={`modal fade modal_fade_bg ${props?.showRequestChange ? "show" : ""}`}
          id="reviewConfirm"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.showRequestChange ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm">
            <div className="modal-content">
              <div className="modal-header  d-block">
                <div className="row header_title">
                  <div className="col-lg-12">
                    <h5 className="modal-title modal_title mb-3" id="exampleModalLabel">
                      Request Changes To Proposal
                    </h5>
                    <p className="text_gray_1100_w_600 mb-0">
                      To: <span className="font_semi_bold_w_600">{name}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-body py-4">
                <div className="row">
                  <div className="form-group col-sm-12">
                    <p className="text_gray_1100_w_600 mb-2">Your Request</p>
                    <textarea
                      className="form-control  request_textarea modal_scroll mb-1 mt-1"
                      value={request}
                      onChange={(e) => {
                        setRequest(e.target.value.substring(0, 300));
                        setRequestValid(e.target.value?.length > 0 ? false : true);
                      }}
                      // placeholder="reason ..."
                      id="comment"
                    ></textarea>
                    <span className="font-weight-500 text-secondary">{request?.length}/300</span>
                    {requestValid ? (
                      <p className="text-center" style={{ color: "red" }}>
                        Field Required
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer br_tp">
                <button type="button" className="btn btn_outline_md" onClick={() => props.changeShowRequestChange(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn_common_md" onClick={handleSubmit}>
                  {loader ? <Loader isCreateModal={true} /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RequestChange;
