import React from "react";
import { separateNumber } from "../../../ProfileCreator/TimeRetainer";
import PdfImage from "../../../../../Assets/file-img/pdf1.png";
import ZipImage from "../../../../../Assets/file-img/zip.png";
import PPtImage from "../../../../../Assets/file-img/ppt.png";
import Exe from "../../../../../Assets/file-img/exe.png";
import Docx from "../../../../../Assets/file-img/docx.png";
import Mp4 from "../../../../../Assets/file-img/mp4.png";
import Xlsx from "../../../../../Assets/file-img/xlsx.png";
import Csv from "../../../../../Assets/file-img/csv.png";
import Wmv from "../../../../../Assets/file-img/wmv.png";
import Doc from "../../../../../Assets/file-img/doc.png";
import EmptyImg from "../../../../../Assets/file-img/images.jpeg";
import { getAPI } from "../../../../../utils/API";
import moment from "moment";

export const handleSelectedNames = (names) => {
  let finalName = "";
  let arr = [];

  names.forEach((item, i) => {
    if (item.check && !item.isDisable) {
      arr.push({ name: item.name, index: i });
    }
  });

  let header = "";
  arr.length > 0 ? (header = "Completion of ") : (header = "");

  arr.forEach((ele, i) => {
    if (arr.length == 1) finalName += onkeyCode(ele.index + 65);
    else finalName += (i == 0 ? "" : ", ") + onkeyCode(ele.index + 65);
  });

  return header + finalName;
};

export const onkeyCode = (keyCode) => {
  let chrCode = keyCode - 48 * Math.floor(keyCode / 48);
  let chr = String.fromCharCode(96 <= keyCode ? chrCode : keyCode);
  return chr;
};

export const GetpaymenTermsData = (data, arrDel) => {
  let arrPay = [];
  let advanceFees = { name: "Advance Fees", breakup: "", show: true };
  data &&
    data.forEach((item) => {
      let filter = item?.milestone_reference?.filter((obj) => obj?.label == "Advance Fees")?.length > 0;
      if (filter) {
        advanceFees = {
          name: "Advance Fees",
          breakup: item?.price_without_tax ? item.price_without_tax : "",
          show: item?.price_without_tax ? true : false,
          status: item?.status,
          price_without_tax: item?.price_without_tax,
          price: item?.price,
          tax: item?.tax,
          total_earning: item?.total_earning,
        };
      } else {
        let arrName = [];
        let compare = item?.milestone_reference.map((items) => {
          return items?.label;
        });
        arrDel.forEach((object, i) => {
          let inc = compare.includes(onkeyCode(i + 65));
          arrName.push({
            ...object,
            check: true,
            isDisable: inc ? false : true,
          });
        });
        arrPay.push({
          names: arrName,
          breakup: item?.price_without_tax,
          toggle: false,
          validation: { name: false, price: false },
          status: item.status,
          payment_date: item.payment_date,
          admin_approved: item.admin_approved,
          admin_approved_client: item.admin_approved_client,
          payment_type: item.payment_type,
          _id: item._id,
          price_without_tax: item?.price_without_tax,
          price: item?.price,
          tax: item?.tax,
          total_earning: item?.total_earning,
          restrict: item?.restrict,
        });
      }
    });

  return { advanceFees, arrPay };
};

export const retainerTableValues = (action, states, propType) => {
  let name = "";
  let result = "";
  if (action === 1) {
    name = "Proposed Duration";
    result =
      states?.propDuration?.prop_dur_num +
      " " +
      (states?.propDuration?.prop_dur_num == 1 ? "Month" : states?.propDuration?.prop_duration?.label);
  } else if (action === 3) {
    name = "Working days";
    let str = "";
    let filter = states.workingDaysInWeek.filter((obj) => obj?.isActive);
    filter.forEach((item, i) => {
      str += item.sort + (filter.length - 1 == i ? "" : ", ");
    });
    result = str;
  } else if (action === 2) {
    let check = states?.numOfHours?.day_week?.value;
    name = "Number of hours";
    result =
      states?.numOfHours?.num_hour > 1
        ? states.numOfHours.num_hour + " Hours/" + check
        : states.numOfHours.num_hour + " Hour/" + check;
  } else if (action === 4) {
    name = "Retainer Fees";
    result =
      "₹ " +
      separateNumber(states?.fees?.retainerFees, "") +
      `/${
        states?.retainer_fees_type?.value
          ? states.retainer_fees_type.value == "Monthly"
            ? "Month"
            : "Hour"
          : states?.retainer_fees_type == "Monthly"
          ? "Month"
          : "Hour"
      }`;
  } else if (action === 5) {
    // name = "Project Advance - Fees" + (propType === "retainer" ? " (inclusive retainer fees)" : "");
    name = "Advance Fees";
    result = states?.fees?.advanceFees ? "₹ " + separateNumber(states.fees.advanceFees, "") : "-";
  } else if (action === 6) {
    name = "Preferred Billing Cycle";
    result = states?.prefBillingCycle?.label;
  }

  return { name, result };
};

export const workDays = [
  { name: "sunday", label: "S", isActive: false, index: 1, sort: "Sun" },
  { name: "monday", label: "M", isActive: false, index: 2, sort: "Mon" },
  { name: "tuesday", label: "T", isActive: false, index: 3, sort: "Tue" },
  { name: "wednesday", label: "W", isActive: false, index: 4, sort: "Wed" },
  { name: "thursday", label: "T", isActive: false, index: 5, sort: "Thu" },
  { name: "friday", label: "F", isActive: false, index: 6, sort: "Fri" },
  { name: "saturday", label: "S", isActive: false, index: 7, sort: "Sat" },
];

export const ViewDeliverableData = (data) => {
  let arrDel = [];
  data?.deliverables &&
    data.deliverables.forEach((item) => {
      if ((item?.name != "advance_payment" || item?.type != "token") && data?.proposal_type === "fixed") {
        arrDel.push({
          name: item?.name,
          dueDate: new Date(item?.due_date),
          iterations: item?.iteration,
          toggle: item?.iteration_charge ? true : false,
          check: false,
          isDisable: false,
          iteration_charge: item?.iteration_charge ? item.iteration_charge : "",
          ...item,
        });
      }
      if (
        (item?.name != "advance_payment" || item?.type != "token") &&
        data?.proposal_type !== "fixed" &&
        data?.proposal_type !== "retainer"
      ) {
        arrDel.push({
          name: item?.name,
          iterations: item?.iteration,
          toggle: item?.iteration_charge ? true : false,
          iteration_charge: item?.iteration_charge ? item.iteration_charge : "",
          breakup: item?.price,
          ...item,
        });
      }
    });

  return arrDel;
};

export const downloadFilesFun = (baseurl, name) => {
  const url = baseurl + name;
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = name;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

// export const handleFileName = (name,length) => {
//   let arr = name.split(".");
//   const filename = arr.slice(0, -1).join(".");
//   const extension = arr.slice(-1).join("");
//   let result = filename && extension ? filename.substring(0, length) + "." + extension : "";
//   return result;
// };

export const handleFileName = (name, length) => {
  let arr = name?.split(".");
  const filename = arr?.slice(0, -1)?.join(".");
  const extension = arr?.slice(-1)?.join("");
  let result = filename && extension ? limitStringFile(filename, length) + "." + extension : "";
  return result;
};

export const limitStringFile = (text, limit) => {
  if (!text || !limit) return "";

  if (text.length < limit) return text;

  const txt = text.substring(0, limit - 3) + "...";
  return txt;
};

export const handleFileType = (name, baseurl) => {
  return name
    ? name?.includes(".pdf") ||
      name?.includes(".zip") ||
      name?.includes(".ppt") ||
      name?.includes(".exe") ||
      name?.includes(".docx") ||
      name?.includes(".mp4") ||
      name?.includes(".xlsx") ||
      name?.includes(".csv") ||
      name?.includes(".wmv") ||
      name?.includes(".doc")
      ? name?.includes(".pdf")
        ? PdfImage
        : name?.includes(".zip")
        ? ZipImage
        : name?.includes(".ppt")
        ? PPtImage
        : name?.includes(".exe")
        ? Exe
        : name?.includes(".docx")
        ? Docx
        : name?.includes(".mp4")
        ? Mp4
        : name?.includes(".xlsx")
        ? Xlsx
        : name?.includes(".csv")
        ? Csv
        : name?.includes(".wmv")
        ? Wmv
        : name?.includes(".doc")
        ? Doc
        : EmptyImg
      : baseurl + name
    : EmptyImg;
};

export const handleProposalData = (Propstates, data) => {
  let arrDel = ViewDeliverableData(data);
  let obj = GetpaymenTermsData(data?.payment_terms, arrDel);
  let arrPay = obj?.arrPay;
  let advanceFees = obj?.advanceFees;
  Propstates.propDuration.prop_duration =
    data?.proposed_duration_type?.length > 0
      ? {
          label: data.proposed_duration_type,
          value: data.proposed_duration_type,
        }
      : { label: "Week", value: "Week" };
  Propstates.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
  Propstates.numOfHours.day_week =
    data?.number_of_hours_type?.length > 0
      ? {
          label: data.number_of_hours_type,
          value: data.number_of_hours_type,
        }
      : { label: "Day", value: "Day" };
  Propstates.numOfHours.num_hour = data?.number_of_hours ? data.number_of_hours : 1;
  Propstates.workingDaysInWeek = data?.working_days_in_week?.length === 7 ? data.working_days_in_week : workDays;
  Propstates.fees.retainerFees = data?.retainer_fees ? data.retainer_fees : "";
  Propstates.retainer_fees_type = data?.retainer_fees_type;
  Propstates.fees.advanceFees = data?.advance_amount ? data.advance_amount : "";
  // actual values
  Propstates.deliverable = arrDel.length > 0 ? arrDel : [];
  Propstates.propDuration.prop_duration =
    data?.proposed_duration_type?.length > 0
      ? {
          label: data.proposed_duration_type,
          value: data.proposed_duration_type,
        }
      : { label: "Week", value: "Week" };
  Propstates.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
  Propstates.prefBillingCycle =
    data?.preferred_billing_cycle?.length > 0
      ? {
          label: data.preferred_billing_cycle == "Monthly" ? "Cycle End" : data.preferred_billing_cycle,
          value: data.preferred_billing_cycle,
        }
      : { label: "Cycle End", value: "Monthly" };

  Propstates.deliverable = arrDel?.length > 0 ? arrDel : [];
  Propstates.payments = arrPay?.length > 0 ? arrPay : [];
  Propstates.proposed_start_date = data?.proposed_start_date ? new Date(data?.proposed_start_date) : new Date();
  Propstates.note = data?.note ? data.note : "";
  Propstates.advanceFees = advanceFees;
  Propstates.type = data?.proposal_type;
  Propstates.total_earning = data?.total_earning;
  Propstates.sub_total_cost = data?.sub_total_cost;
  Propstates.proposalData = data;
  Propstates.number = data?.number;

  return Propstates;
};

export const handleStatusDel = (object, direct) => {
  let obj = direct ? object : object?.milestonecomplete?.length ? object.milestonecomplete[0] : "";
  return obj ? (
    obj?.status == 0 ? (
      <p className="mb-0 text_yellow_md_500">In Review</p>
    ) : obj?.status == 1 ? (
      <p className="mb-0 text_primary_100_w_600">Approved</p>
    ) : (
      <p className="mb-0 text-red-500_md_w_600">Rejected</p>
    )
  ) : (
    <p className="mb-0 text-red-500_md_w_600">Pending</p>
  );
};

export const initialActualExtState = () => {
  let obj = {
    deliverable: [
      {
        name: "",
        iterations: 0,
        breakup: "",
        toggle: false,
        iteration_charge: 0,
        validation: { name: false, breakup: false, iteration_charge: false },
        status: 1,
        milestonecomplete: [],
        paid_status: 0,
      },
    ],
    propDuration: {
      prop_duration: { label: "Month", value: "Month" },
      prop_dur_num: 1,
    },
    prefBillingCycle: { label: "Month", value: "Month" },
    proposed_start_date: setTimeToZero(new Date()),
    note: "",
    validNote: false,
    allData: "",
    noAlreadyData: true,
  };
  return obj;
};

export const initialActualExtApi = async (id) => {
  let object = initialActualExtState();
  const res = await getAPI(`/proposal/get-extend-proposal?proposal_id=${id}`);
  if (Object.keys(res?.data?.AllProposal).length) {
    let data = res?.data?.AllProposal;
    let arrDel = [];
    data?.deliverables?.length > 0 &&
      data.deliverables.forEach((item) => {
        if (
          (item?.name != "advance_payment" || item?.type != "token") &&
          data?.proposal_type !== "fixed" &&
          data?.proposal_type !== "retainer" &&
          item?.name
        ) {
          arrDel.push({
            name: item?.name,
            iterations: item?.iteration ? item.iteration : 0,
            toggle: item?.iteration_charge ? true : false,
            iteration_charge: item?.iteration_charge ? item.iteration_charge : "",
            breakup: item?.price,
            validation: {
              name: false,
              breakup: false,
              iteration_charge: false,
            },
            status: item?.status,
            milestonecomplete: item?.milestonecomplete?.length > 0 ? item.milestonecomplete : [],
            paid_status: item?.paid_status ? item.paid_status : 0,
            price_without_tax: item?.price_without_tax,
            price: item?.price,
            tax: item?.tax,
          });
        }
      });

    object.deliverable = arrDel;
    object.propDuration.prop_duration = {
      label: data.proposed_duration_type,
      value: data.proposed_duration_type,
    };
    object.propDuration.prop_dur_num = data.proposed_duration;
    object.prefBillingCycle = {
      label: data.preferred_billing_cycle,
      value: data.preferred_billing_cycle,
    };
    object.note = data?.note.length > 0 ? data.note : "";
    object.proposed_start_date = setTimeToZero(data.proposed_start_date ? data.proposed_start_date : new Date());
    object.allData = data;
    object.noAlreadyData = false;
  }
  return object;
};

export const initialRetainerExtState = () => {
  let obj = {
    proposed_start_date: setTimeToZero(new Date()),
    propDuration: {
      prop_duration: { label: "/Month", value: "Month" },
      prop_dur_num: 1,
    },
    numOfHours: {
      day_week: { label: "/Week", value: "Week" },
      num_hour: 1,
    },
    note: "",
    fees: {
      retainerFees: "",
    },
    validation: {
      retainerFees: false,
      prop_dur_num: false,
      num_hour: false,
    },
    allData: "",
    noAlreadyData: true,
  };

  return obj;
};

export const initialRetainerExtApi = async (id) => {
  let object = initialRetainerExtState();
  const res = await getAPI(`/proposal/get-extend-proposal-retainer?proposal_id=${id}`);
  if (Object.keys(res?.data?.AllProposal).length) {
    let data = res?.data?.AllProposal;
    object.propDuration.prop_duration =
      data?.proposed_duration_type?.length > 0
        ? {
            label: data.proposed_duration_type,
            value: data.proposed_duration_type,
          }
        : { label: "Month", value: "Month" };
    object.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
    object.numOfHours.day_week =
      data?.number_of_hours_type?.length > 0
        ? {
            label: data.number_of_hours_type,
            value: data.number_of_hours_type,
          }
        : { label: "/Week", value: "Week" };
    object.numOfHours.num_hour = data?.number_of_hours ? data.number_of_hours : 1;
    object.fees.retainerFees = data?.retainer_fees ? data.retainer_fees : "";
    object.note = data?.note?.length > 0 ? data.note : "";
    object.allData = data;
    object.proposed_start_date = setTimeToZero(data.proposed_start_date ? data.proposed_start_date : new Date());
    object.noAlreadyData = false;
  }

  return object;
};

export const handleDelivaryDate = (states, array, type) => {
  let result = "";
  if (type == "actual") {
    let value = array?.length ? new Date(array[0]?.createdAt) : "";
    var newDate = value ? new Date(value.setMonth(value.getMonth() + states?.propDuration?.prop_dur_num)) : "";
    result = newDate ? moment(newDate).format("MMM D, YYYY") : "";
  } else {
    let length = array?.length;
    let value = length ? array[length - 1]?.dueDate : "";
    result = value ? moment(value).format("MMM D, YYYY") : "";
  }
  return result;
};

export const getRealFileName = (name, length) => {
  let result = "";
  if (length) {
    result = name.substring(length);
  } else {
    result = name.substring(22);
  }
  return result;
};

export const getDateFormated = (date) => {
  let result = date ? moment(date).format("MMM D, YYYY") : "-";
  return result;
};

export const getDateTimeFormated = (date) => {
  let result = date ? moment(date).format("MMM D, h:mm a") : "";
  return result;
};

export const getQueryParams = (value) => {
  const searchParams = new URLSearchParams(window.location.search.split("?")[1]);
  return searchParams.get(value);
};

export const checkOneOrMany = (value, singular, mult) => {
  return value + " " + (value > 1 ? mult : singular);
};

export const addHrMinDate = (value, hr, min) => {
  let date = new Date(value);
  date.setHours(date.getHours() + hr);
  date.setMinutes(date.getMinutes() + min);
  return date;
};

export const setTimeToZero = (value) => {
  var time = moment(value).toDate();
  time.setHours(0);
  time.setMinutes(0);
  time.setSeconds(0);
  time.setMilliseconds(0);

  return time;
};


export const convertToPlain = (html) => {

  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;

  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export const checkUrlValidAndUpdate = (name) => {
  let result=""
  if((name.includes('www') && !name.includes('http'))){
   result = `http://${name}`
  }else if((!name.includes('www') && !name.includes('http'))){
    result = `http://www.${name}`
  }else{
    result = name
  }
  return result
}

export const certificationOptions = [
  {value:"Diploma",label:"Diploma"},
  {value:"Certification",label:"Certification"},
  {value:"Bachelor's Degree",label:"Bachelor's Degree"},
  {value:"Master's Degree",label:"Master's Degree"},
  {value:"PhD",label:"PhD"},
]
export const certificationOptionsArr = [{number:1,name:"Diploma"},{number:2,name:"Certification"},{number:3,name:"Bachelor's Degree"},{numer:4,name:"Master's Degree"},{number:5,name:"PhD"}];

export const certificationOptionsOnboarding = [
  {name:"Diploma"},
  {name:"Certification"},
  {name:"Bachelor's Degree"},
  {name:"Master's Degree"},
  {name:"PhD"},
]

export const logoutUserRedirect = () => {
  window.location.href = 'https://home.indiefolio.com'
}