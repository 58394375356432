import React from 'react';
import { connect } from 'react-redux';
import EducationForm from './EducationForm';
// import BtnLoader from '../../Common/Common/BtnLoader';
import Loader from './../../../unauthorized/Common/Loader/Loader';
import Delete from '../../../../Assets/upgrade-images/submit_proposal/trash.svg';
import Edit from '../../../../Assets/upgrade-images/account/edit.svg';
class Education extends React.Component {
  child = React.createRef();
  enableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: true,
    });
  };

  disableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: false,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.UserAddCollateral({});
  };

  onDelete = (id) => {
    const isConfirm = window.confirm('Do you want to delete the education?');
    if(!isConfirm) return;
    this.props.onDelete(id);
    // alert('Education has been deleted.')
  };

  onEditEnable = (e, k) => {
    // console.log('onEDitenabe ', e);

    let obj = {
      edit: k,
      School: e.Institute_name,
      InstituteImage: e.Institute_image,
      Course: e.course,
      StartMonth: e.start_month,
      EndMonth: e.end_month,
      StartYear: e.start_year,
      EndYear: e.end_year,
      ID: e._id,
      SchoolError: false,
      CourseError: false,
      StartMonthError: false,
      EndMonthError: false,
      StartYearError: false,
      EndYearError: false,
      SchoolErrorMessage: '',
      CourseErrorMessage: '',
      StartMonthErrorMessage: '',
      EndMonthErrorMessage: '',
      StartYearErrorMessage: '',
      EndYearErrorMessage: '',
    };
    if (this.props.edit === k) {
      obj = {
        edit: -1,
        School: '',
        Course: '',
        StartMonth: '',
        EndMonth: '',
        StartYear: '',
        EndYear: '',
        SchoolError: false,
        CourseError: false,
        StartMonthError: false,
        EndMonthError: false,
        StartYearError: false,
        EndYearError: false,
        SchoolErrorMessage: '',
        CourseErrorMessage: '',
        StartMonthErrorMessage: '',
        EndMonthErrorMessage: '',
        StartYearErrorMessage: '',
        EndYearErrorMessage: '',
        ID: '',
      };
    }

    this.props.onChange({
      name: 'USEREDUCATION',
      value: obj,
    });
    this.disableForm();
  };

  closePopup = () => {
    this.props.onChange({ name: 'USEREDUCATION_RESET', value: {} });
    this.props.onChange({ name: 'FORMOPEN', value: false });
    this.props.closePopup();
  };

  handleSubmit = () => {
    this.child.current.onSubmit();
  };

  render() {
    let { Educations, edit, formOpen, saving } = this.props;
    const submitBtns =
      formOpen || edit !== -1 ? (
        edit !== -1 ? (
          <button
            type="submit"
            className={`main-btn col-sm-3 col-6 mb-2 mx-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Update'}
          </button>
        ) : (
          <button
            type="submit"
            className={`main-btn col-sm-3 col-6 mb-2 mx-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Save Changes'}
          </button>
        )
      ) : null;

    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditEducationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Education</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height awards">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      {Educations && Educations.length > 0 ? (
                        Educations.map((e, k) => {
                          return (
                            <React.Fragment key={k}>
                              <div className="mb-4">
                                <div
                                  className={`card wrapper-form ${
                                    edit === k ? 'border-bottom-0-10 border-none box-shadow-none' : ''
                                  }`}
                                >
                                  <div className="card-body">
                                    <div className="row m-0 align-items-center py-3 px-2 mob-pr-0 mob-pl-0 mob-justify-content-between">
                                      <div className="col-7 col-sm-10">
                                        <h2 className="text-capitalize serv-title m-0 mob-font-14">
                                          {e.course}
                                        </h2>
                                        <div className="form-group mb-0 pt-1 mob-hidden education_modal">
                                          <span className="sub_title font-weight-500 text-capitalize">
                                            {e.Institute_name}
                                          </span>
                                          <span className="sub_title font-weight-500 px-4 text-capitalize">
                                            {e.start_month} {e.start_year} -{' '}
                                            {e.end_month} {e.end_year}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-5 col-sm-2 modal-tab tabs-icon">
                                        <div className="text-right">
                                          <span>
                                          <img src={Edit} className='pr-1' onClick={() =>
                                                 this.onEditEnable(e, k) }/>
                                            {/* <i
                                              className={`fas font-20 mob-font-14 pl-3 collacteral pointer position-relative ${
                                                edit !== undefined && edit === k
                                                  ? 'fa-chevron-up'
                                                  : 'fa-chevron-down'
                                              }`}
                                              onClick={() =>
                                                this.onEditEnable(e, k)
                                              }
                                            ></i> */}
                                          </span>
                                          <span className='cursor-pointer ml-3' onClick={() => this.onDelete(e._id)}>
                                            <img src={Delete}/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {edit === k ? (
                                  <EducationForm ref={this.child} />
                                ) : (
                                  <></>
                                )}
                              </div>
                              {/* <div className="border-bottom-2 my-30 mob-my-20"></div> */}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {edit === -1 ? (
                        formOpen || Educations.length < 1 ? (
                          <EducationForm ref={this.child} />
                        ) : (
                          <div className="w-100 py-4 dflex">
                            <a
                              href={() => {}}
                              className="font-13 mob-font-12 font-weight-600 text-primary text-center cursor-pointer"
                              onClick={this.enableForm}
                            >
                              <i className="fas fa-plus mr-1"></i> Add Education
                            </a>
                            {/* <span className="add-collateral-underline"></span> */}
                          </div>
                        )
                      ) : (
                        <></>
                      )} 
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top">
                    <div className="form-group text-right pr-3 pt-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-danger col-sm-2 col-5 mb-2 mx-2 font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {submitBtns}
                      {Educations.length === 0 ? (
                        <button
                          type="submit"
                          className={`main-btn col-sm-3 col-6 mb-2 mx-2 ${saving ? 'p-0' : ''}`}
                          disabled={saving}
                          onClick={this.handleSubmit}
                        >
                          {saving ? <Loader profileCreator="true"/> : 'Save Changes'}
                        </button>
                      ) : null}
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let Education = store.userEducation;

  return {
    saving: globalReducer.saving,
    formOpen: globalReducer.formOpen,
    closeModal: creatorProfile.closeModal,
    Educations: creatorProfile.Education,
    edit: Education.edit,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.target.name, value: e.target.value },
    }),
  onChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  onDelete: (e) => dispatch({ type: 'DELETE_USEREDUCATION_SAGA', payload: e }),
});

export default connect(mapState, mapDispatch)(Education);
