import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { fireApi } from '../../../../utils/fireApiPrecheck';

class ReviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: '',
      Email: '',
      NameError: false,
      EmailError: false,
      loading: false,
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    const obj = {
      email: this.state.Email,
      name: this.state.Name,
      project_id: this.props.projectId,
    }
    fireApi('ProjectRequestReview', obj).then(rm => {
      this.setState({ loading: false })
      if (rm.error && rm.error === true) {
        // console.log(rm);
        this.setState({
          NameError: rm.NameError,
          EmailError: rm.EmailError,
        })
      } else {
        this.reset();
        $("body").removeClass("modal-open");
        this.props.closePopup();
        alert("Review Request Sent");
      }
    });
  }

  onFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [e.target.name + 'Error']: false });
  }

  reset = () => {
    this.setState({
      Name: '',
      Email: '',
      NameError: false,
      EmailError: false
    })
  }

  render() {
    const { Name, Email, NameError, EmailError } = this.state;
    return (
      <>
        <div className="modal  custom_form verify_modal edit-basic-profile show"  style={{ paddingRight: "0px", display:'block' }} id="RequestReviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator ml-05 mob-m-0">
                      <div className="p-4">
                        <h2 className="mt-0 font-weight-500 m-0 pt-1 font-18 mob-font-14">Request a testimonial</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 overflow-content basic-height">
                    <div className="custom_form py-3">
                      <form onSubmit={this.onSubmit}>
                        <div className="row m-0">
                          <div className="form-group mb-4 col-sm-12">
                            <label className="label font-weight-600 mb-3" htmlFor="first">Who is eligible to provide a testimonial for your project?</label>
                            <div className="font-weight-600 font-12 text-dark-black d-flex mb-3">
                              <div><i className="fas fa-arrow-right mr-2 text-secondary"></i></div>
                              <div>The client for whom the project was created, or</div>
                            </div>
                            <div className="mb-0 font-weight-600 font-12 text-dark-black d-flex mb-3">
                              <div><i className="fas fa-arrow-right mr-2 text-secondary"></i></div>
                              <div>Employer for whom you were working with/for while creating this project, or</div>
                            </div>
                            <div className="mb-0 font-weight-600 font-12 text-dark-black d-flex">
                              <div><i className="fas fa-arrow-right mr-2 text-secondary"></i></div>
                              <div>College professor who sanctioned this project as classwork</div>
                            </div>
                          </div>
                          <div className="form-group mb-4 col-sm-12">
                            <label className="label font-weight-600" htmlFor="first">Name</label>
                            <input
                              type="text"
                              name="Name"
                              value={Name}
                              onChange={(e) => this.onFieldChange(e)}
                              className={`form-control email ${NameError === true ? "border-error" : ""}`}
                              placeholder="Enter name here"
                            />
                          </div>
                          <div className="form-group mb-3 col-sm-12">
                            <label className="label font-weight-600" htmlFor="last">Email</label>
                            <input
                              type="email"
                              name="Email"
                              value={Email}
                              onChange={(e) => this.onFieldChange(e)}
                              className={`form-control email ${EmailError === true ? "border-error" : ""}`}
                              placeholder="Enter email here"
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-12 text-right pr-3 pt-3">
                          <button type="submit" className="main-btn w-100 px-4"
                            disabled={this.state.loading}>
                            {this.state.loading === true
                              ?
                              'Sending...'
                              :
                              'Send request'
                            }
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  // console.log(creatorProfile);
  return {
    closeModal: creatorProfile.closeModal,
    projectId: creatorProfile.UpdateReviewProjectId,
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  // onFieldChange: (e) => dispatch({ type: 'ON_FIELDCHANGE_SAGA', payload: { name: e.target.name, value: e.target.value } }),
});
export default connect(mapState, mapDispatch)(ReviewModal);
