import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import { capitalizeWordFirstLetter, getThumbnailImage, isRandomUrl, modifyURLByPlatform } from '../../../../utils/Helperfunctions'
import { BaseImageUrl } from '../../../../utils/BaseUrl';
// import project from '../../../../store/reducer/add.Project';
import RandomUrlPreview from '../../Common/RandomUrlPreview/RandomUrlPreview';
import  {isViewableFile, renderDifferentFilesThumbnail} from '../../Common/Common/FileRenderMessage'
import AllFileViewer from '../../Common/AllFileViewer/AllFileViewer';
import { Helmet } from 'react-helmet';

class ViewModal extends React.Component {
  state = {
    preview_src: '',
  }
  editProject = (projectId) => {
    const { history } = this.props;
    this.props.editUserProject(projectId,history);
  }

  handleThumbnail = (index, type, src, original_image) => {
    if (src !== "") {
        this.setState({
            preview_src: src,
            preview_type: (type === "url") ? "url" : "image",
            active_thumbnail: index,
            original_image: original_image,
        })
    } 
  }
  historyUrl=(id,title)=>{
    let username =  this.props.location.pathname.split('/')[2]
    return this.props.history.push(`/creator/${username}/${id}/${title}`)
     

  }

  slideProjects = (slideTo) => {
    const { AllProjects } = this.props;

    let Projects = this.props.Projects;

    if(Projects.length<=1 && AllProjects.length<=1){
      return;
    }

      if(Projects.length > 0 || AllProjects.length > 0) {
  

        if(!Projects.length){
          Projects = AllProjects
        }
        let currentProject = this.props.projectId;
        let currentProjectIndex  = Projects.findIndex((item) => item.project_data._id === currentProject);
        if(slideTo === "next") {
          if(currentProjectIndex === Projects.length - 1){
            // bring the first project again
            this.props.LoadProjectData(Projects?.[0]?.project_data._id);
            if(this.props.match.path.includes(`/creator`))
            this.historyUrl(Projects?.[0]?.project_data._id,Projects?.[0]?.project_data.title)
          } else {
            let nextProjectIndex  = currentProjectIndex+1;
            nextProjectIndex      = (nextProjectIndex > Projects.length) ? 0 : nextProjectIndex;
            this.props.LoadProjectData(Projects?.[nextProjectIndex]?.project_data._id);
            if(this.props.match.path.includes(`/creator`))
            this.historyUrl(Projects?.[nextProjectIndex]?.project_data._id,Projects?.[nextProjectIndex]?.project_data.title)
            
          }
          // let nextProjectIndex  = currentProjectIndex+1;
          // nextProjectIndex      = (nextProjectIndex > Projects.length) ? 0 : nextProjectIndex;
          // this.props.LoadProjectData(Projects?.[nextProjectIndex]?.project_data._id);
        } else {
          let prevProjectIndex   = currentProjectIndex-1;
          prevProjectIndex      = (prevProjectIndex < 0) ? Projects.length-1 : prevProjectIndex;
          this.props.LoadProjectData(Projects?.[prevProjectIndex]?.project_data._id);
          if(this.props.match.path.includes(`/creator`))
          this.historyUrl(Projects?.[prevProjectIndex]?.project_data._id,Projects?.[prevProjectIndex]?.project_data.title)
          
        }
    } else {
      let Projects = this.props.userPortfolioProjects; 
      let currentProject = this.props.projectId;
      let currentProjectIndex  = Projects.findIndex((item) => item._id === currentProject);
      if(slideTo === "next") {
        if(currentProjectIndex === Projects.length - 1){
          // bring the first project again
          this.props.LoadProjectData(Projects?.[0]?._id);
          if(this.props.match.path.includes(`/creator`))
          this.props.history.push(`/creator/${Projects?.[0]?._id}/${Projects?.[0]?.title}`)

          
        } else {
          let nextProjectIndex  = currentProjectIndex+1;
          nextProjectIndex      = (nextProjectIndex > Projects.length) ? 0 : nextProjectIndex;
          this.props.LoadProjectData(Projects?.[nextProjectIndex]?._id);
          if(this.props.match.path.includes(`/creator`))
          this.props.history.push(`/creator/${Projects?.[nextProjectIndex]?._id}/${Projects?.[nextProjectIndex]?.title}`)

        }
      } else {
        let prevProjectIndex   = currentProjectIndex-1;
        prevProjectIndex      = (prevProjectIndex < 0) ? Projects.length-1 : prevProjectIndex;
        this.props.LoadProjectData(Projects?.[prevProjectIndex]?._id);
        if(this.props.match.path.includes(`/creator`))
        this.props.history.push(`/creator/${Projects?.[prevProjectIndex]?._id}/${Projects?.[prevProjectIndex]?.title}`)

      }
    }
  }

  slideImages = (slideTo) => {
    const {active_thumbnail} = this.state;
    const {Images} = this.props;
    let Image = Images.filter((item) => item.value !== "");
    let index = active_thumbnail;
    if(slideTo === "next") {
      index     = active_thumbnail+1;
      index     = (index >  Image.length-1) ? 0 : index;
    } else {
      index     = active_thumbnail-1;
      index     = (index < 0) ? Image.length-1 : index;
    }
    let preview_type = Image && Image?.[index]?.type;
    let preview_src = Image && Image?.[index]?.value;
    let original_image = Image && Image?.[index]?.image_original;
    this.handleThumbnail(index,preview_type,preview_src,original_image)
  }

  showFullScreen = (url) => { 
    this.props.closePopup();
    this.props.OpenPopup("ViewModalFullScreen");
    //$("body").removeClass("modal-open");
    // window.open(url, "_blank");
  }

  componentDidUpdate = (prevProps) => {
    const arraypathname=this.props.history.location.pathname.split('/');
    if(arraypathname.length>=4)
    {
      if(this.props.project_error){
        console.log('hit')

        this.props.closePopup()
        this.props.history.push(`/creator/${arraypathname[2]}`)
        return 
      }
    }
      const {Images} = this.props;
      if(this.props.Images !== prevProps.Images ) {
        let preview_type = Images && Images?.[0]?.type;
        let preview_src = Images && Images?.[0]?.value;
        this.handleThumbnail(0,preview_type,preview_src);
    }
  }
  
  componentDidMount = () => {
    const arraypathname=this.props.history.location.pathname.split('/');
    if(arraypathname.length>=4)
    {
      if(this.props.project_error){
        console.log('hit')

        this.props.closePopup()
        this.props.history.push(`/creator/${arraypathname[2]}`)
        return 
      }
    }

    const {Images} = this.props;
    let preview_type = Images && Images?.[0]?.type;
    let preview_src = Images && Images?.[0]?.value;
    let original_image = Images && Images?.[0]?.image_original;
    this.handleThumbnail(0,preview_type,preview_src,original_image);
    document.addEventListener("keydown", this.keyPress, false)
  }

  // componentDidCatch(){
  //   document.removeEventListener("keydown", this.keyPress, false);
  // }

  handleClose = (e) => {
    this.props.closePopup();
    this.props.resetProjectData();
    if(this.props.match.path.includes('/creator')){
      const username = this.props.location.pathname.split('/')

    
      return   this.props.history.push(`/creator/${username[2]}`);
      
  
    }

    if(this.props.match.path === "/talentconnect/:projectId" || this.props.match.path.includes("/profile_selection") || (this.props.match.path.includes("/projects") && this.props?.openedFrom=='slidermodalbrief')){
      // to open profile preview popup back if this modal is called from talentconnect page
      this.props.OpenPopup('SliderModalBrief')
    } 
    document.removeEventListener("keydown", this.keyPress, false);
  }

  getReview = (projectId) => {
    this.props.onFieldChange({
      name: 'REVIEW_PROJECT_ID',
      value: projectId,
    });
    this.props.OpenPopup('ReviewModal')
  }

  keyPress = (e) => {
    if(e.key === 'Escape'){
      this.handleClose()
    }
  }
 
  render() {
    let {projectId,Title,About,Collateral, Tools,Role, Keywords,Images,EndMonth,EndYear,publicView,userData,companyName,createdFor,dataReview} = this.props;
    const {preview_src,active_thumbnail,original_image} = this.state;
    Images = Images.filter((item) => item.value !== "");
    const image_user = require("../../../../Assets/images/user.png");

    let titleName = `${Title} || ${userData?.first_name} ${userData?.last_name}`
    titleName = capitalizeWordFirstLetter(titleName);
    const location = this.props.location.pathname.split('/')
    const isTalentConnect=location[1]&&location[1]==='talentconnect';
    return (
      <>
        {
          Title && 
            <Helmet>
              <title >{`${titleName} || IndieFolio`}</title>
            </Helmet>
        }
        <div className='position-relative custom-overlay'>
        <div className="modal fade custom_form user_form_section custom_Modal_Box show" style={{ paddingRight: "0px", display: "block",zIndex:"999999" }}  data-backdrop="static" id="editWorkSection" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="project_prev" onClick={(e)=>this.slideProjects("prev")}><i className="fas fa-arrow-left"></i></div>
        <div className="project_next" onClick={(e) =>this.slideProjects("next")}><i className="fas fa-arrow-right"></i></div>
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img alt="" onClick={this.handleClose} onKeyPress={this.keyPress}
                  src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
            <div className="modal-content bg-light-sky">
              <div className="modal-body">
                <div className="row m-0 align-items-center">
                  <div className="w-100 mobile-only-show bg-white border-top-left-radius border-top-right-radius">
                    <div className="row m-0 py-4 px-3 border-bottom-1 mob-p-15">
                      <div className="col-10 col-sm-10 mob-pl-0">
                        <div className="w-100">
                          <h2 className="font-18 font-weight-600 mob-font-14">{Title}</h2>
                        </div>
                        <div className="w-100 d-flex align-items-center">
                          <div>
                            <p className="mb-0 review"><img src={(userData?.profile_image !== "") ? BaseImageUrl+userData?.profile_image :image_user} alt="profile" title="" width="25" /></p>
                          </div>
                          {isTalentConnect?<div className="pl-2">
                            <span className="profile-subTitle font-weight-500 font-15 text-sub mob-font-12 text-capitalize">By {userData?.first_name} {userData?.last_name}</span>
                          </div>:<></>}
                        </div>
                      </div>
                      {!publicView ? <div className="col-2 col-sm-2 profile-inner">
                        <div className="user-work-edit dropdown-toggle" d="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fas fa-ellipsis-h"></i>
                        </div>
                        <div className="dropdown_btn">
                          <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                            <Link to="#" className="dropdown-item font-weight-600" onClick={(e) => {
                                this.props.closePopup()
                                this.editProject(projectId)
                              }}>Edit</Link>
                            <a href="/" className={`dropdown-item font-weight-600
                              ${
                                dataReview === null
                                  ? ''
                                  : 'post_a_project_tabs disabled'
                              }`} 
                            
                              onClick={(e) => this.getReview(projectId) }
                              >
                              {dataReview === null
                              ? 'Request Testimonial'
                              : dataReview?.status === 0
                              ? 'Review Pending'
                              : 'Reviewed'}
                              </a>
                            {/* <a className="dropdown-item font-weight-600 text-danger" href="/#">Delete</a> */}
                          </div>
                        </div>
                      </div> : null}
                    </div>
                  </div>
                  <div className="col-sm-8 border-top-left-radius border-bottom-left-radius mob-p-10"> 
                    <div className={`user-work-section position-relative ${preview_src && isViewableFile(preview_src) ? 'min-h-100-percentage' : ''}`}>
                      {/* <button onClick={(e) =>{this.showFullScreen(original_image)}}>Show Full Screen</button> */}
                      <div className="fullscreen cursor-pointer" onClick={(e) =>{this.showFullScreen(original_image)}}> 
                        <span className="font-weight-600"><i className="fas fa-arrows-alt mr-1"></i>Full Screen</span>
                      </div>
                      {this.state.preview_type === "image" ?
                        // <img src={preview_src} alt="work" className="img-fluid" />
                        preview_src && isViewableFile(preview_src) ? 
                          <AllFileViewer link={preview_src} /> :
                          <div className="project_big_image" style={{backgroundImage: `url("${ preview_src}")` }}></div>
                        :
                        isRandomUrl(preview_src) ?
                        <RandomUrlPreview url={preview_src} /> :
                        <iframe title="custom-url" className="video_iframe border-0 border-radius-8" src={modifyURLByPlatform(preview_src, {isForPreview: true})}></iframe>
                      }
                      
                      {Images && Images.length>1 && <><div className="next-prev left" onClick={(e)=>{this.slideImages("prev")}}><i className="fas fa-angle-left"></i></div>
                      <div className="next-prev right" onClick={(e) => {this.slideImages("next")}}><i className="fas fa-angle-right"></i></div></>}
                    </div>
                    <div className="mobile-ipad-none">
                      <div className="user-work-thumbnail">
                          {
                            Images && Images.length > 0 ?
                            Images.map((list,index) => {
                              
                              return <RenderImage key={index} index={index} image={Images[index]} active_thumbnail={active_thumbnail} handleThumbnail={this.handleThumbnail} />

                            })
                            :
                            <></>
                          }
                      </div>
                    </div> 
                    <div className="mobile-ipad-show">
                      <div className="user-work-thumbnail ">
                          {
                            Images && Images.length > 0 ?
                            Images.map((list,index) => {
                              /* let imgSrc = Images[index].value;
                              let type = Images[index].type;
                              let original_image = Images[index].image_original;
                              let thumbnail = (type === "url") ? require('../../../../Assets/images/video-thumbnail-default.png') : imgSrc;
                              if(list.value !== "" && index < 3) {
                                return <span className={`thumb-img mb-2  ${active_thumbnail == index ? "active" : ""}`} onClick={() => this.handleThumbnail(index, type, imgSrc,original_image)}>
                                  <img src={thumbnail} alt="work" className={`img-fluid  ${active_thumbnail == index ? "active" : ""}`} />
                                </span>
                              } */
                              return index < 3 ? <RenderImage key={index} index={index} image={Images[index]} active_thumbnail={active_thumbnail} handleThumbnail={this.handleThumbnail} /> : null
                            })
                            :
                            <></>
                          }
                          {
                            Images.length > 3 && 
                              <div className="more_thumb">
                              +
                              {
                                Images.length - 3
                              }  
                            </div>
                          } 
                      </div>
                    </div> 
                  </div>
                  <div className="col-sm-4 overflow-content user-work-content p-0">
                    <div className="desktop-ipad-show">
                      <div className="row m-0 py-4 px-3 border-bottom-1">
                        <div className="col-10 col-sm-9">
                          <div className="w-100">
                            <h2 className="font-18 font-weight-600 mob-font-14 text-capitalize">{Title}</h2>
                          </div>
                          <div className="w-100 d-flex align-items-center">
                            <div>
                              <p className="mb-0 review"><img src={(userData?.profile_image !== "") ? BaseImageUrl+userData?.profile_image :image_user} alt="profile" title="" width="25" /></p>
                            </div>
                            <div className="pl-2">
                              
                                {isTalentConnect?<span className="profile-subTitle font-weight-500 font-15 text-sub mob-font-12 text-capitalize">By
                                  { this.props.SelectedArtistData 
                                    ? <a target="_blank" rel="noopener noreferrer" href={`/creator/${userData?.username || this.props.SelectedArtistData?.userData?.username}`}> {userData?.first_name} {userData?.last_name}</a>
                                    : ` ${userData?.first_name} ${userData?.last_name}`
                                  }
                                </span>:<></>}
                              
                            </div>
                          </div>
                        </div>
                        {publicView !== true ?
                          <div className={`col-2 col-sm-3 profile-inner pr-0 text-center ${(this.props.CurrentRole === 2 ) ? "" : "hidden" } ${this.props.CurrentRole} `}>
                            <div className="user-work-edit dropdown-toggle" d="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fas fa-ellipsis-h"></i>
                            </div>
                            <div className="dropdown_btn text-center">
                              <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item font-weight-600" onClick={(e) => {
                                  this.props.closePopup()
                                  this.editProject(projectId)
                                }}>Edit</Link>
                                <a onClick={(e) => this.getReview(projectId) } className={`dropdown-item font-weight-600
                                  ${
                                    dataReview === null
                                      ? ''
                                      : 'post_a_project_tabs disabled'
                                  }`} 
                                  href="/#" data-toggle="modal" data-target="#RequestReviewModal">
                                  {(dataReview === null)
                                ? 'Request Testimonial'
                                : dataReview?.status === 0
                                ? 'Review Pending'
                                : 'Reviewed'}
                                </a>
                                {/* <a className="dropdown-item font-weight-600 text-danger" href="/#">Delete</a> */}
                              </div>
                            </div>
                          </div>
                          : <></>
                        }
                      </div>
                    </div>
                    <div className="row m-0 p-3 mob-py-15-px-0">
                      <div className="col-sm-12 pb-3">
                        <h2 className="text-third text-capitalize font-13 mb-1 font-weight-600 mob-font-13">Project Description</h2>
                        <p className="font-weight-500 mb-1 text-black mob-font-13">
                          {About}
                        </p>
                        {/* <div className="d-flex align-items-center flex-wrap">
                          {
                            Collateral && Collateral.length > 0 ?
                            Collateral.map((cl,index) =>(
                              <div key={index} className="tags text-capitalize" id={cl.collateral_id._id}>{cl.collateral_id.name}</div>
                            ))
                            :
                            <></>
                          }
                        </div> */}
                      </div>
                      <div className="col-sm-12 pb-3">
                        <h2 className="text-third text-capitalize font-13 mb-1 font-weight-600 mob-font-13">Collateral Type</h2> 
                        <div className="d-flex align-items-center flex-wrap">
                          {
                            Collateral && Collateral.length > 0 ?
                            Collateral.map((cl,index) =>(
                              <div key={index} className="tags text-capitalize" id={cl.collateral_id._id}>{cl.collateral_id.name}</div>
                            ))
                            :
                            <></>
                          }
                        </div>
                      </div>
                      <div className="col-sm-12 pb-3">
                        <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-2">Software/Tools Used</h2>
                        <div className="d-flex align-items-center flex-wrap">
                          {Tools.length > 0
                            ?
                            Tools.map((v, k) => {
                              return (
                                  <div key={k} className="text-capitalize tags">{v.name}</div>
                              )
                            })
                            :
                            <></>
                          }
                        </div>
                      </div>
                      {
                        (createdFor === "company") ?
                          <div className="col-sm-12 pb-3">
                            <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-1">Client</h2>
                            <p className="w-60 mob-w-100 font-weight-500 mb-1 text-black mob-font-13">
                              {companyName}
                            </p>
                          </div>
                          : <></>
                      }
                      {
                        (Role !== "") ?
                          <div className="col-sm-12 pb-3">
                            <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-1">{userData?.first_name}'s Role</h2>
                            <p className="font-weight-500 mb-1 text-black mob-font-13">
                              {Role}
                            </p>
                          </div>
                        :<></>
                      } 
                      {Keywords && Keywords.length > 0 ?
                      <div className="col-sm-12 pb-3">
                        <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-1">Keywords</h2>
                        <div className="d-flex align-items-center flex-wrap">
                          {Keywords.length > 0 &&
                            Keywords.map((v, k) => {
                              return ( <div className="tags" key={k}>{v[0]}</div> )
                            })
                          }
                        </div>
                      </div> :<></>
                      }
                      {
                        (dataReview?.status === 1) ?
                          <div className="col-sm-12 pb-3">
                            <div className="d-flex align-items-center">
                              <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-1">Client Rating</h2>
                              <div className="d-flex align-items-center flex-wrap mb-1 ml-2">
                                <div className="rating d-inline-block">
                                  <span><i className={`fas fa-star mx-1 font-12  ${dataReview?.review >= 1 ? 'text-success' : ''} `}></i></span>
                                  <span><i className={`fas fa-star mx-1 font-12  ${dataReview?.review >= 2 ? 'text-success' : ''} `}></i></span>
                                  <span><i className={`fas fa-star mx-1 font-12  ${dataReview?.review >= 3 ? 'text-success' : ''} `}></i></span>
                                  <span><i className={`fas fa-star mx-1 font-12  ${dataReview?.review >= 4 ? 'text-success' : ''} `}></i></span>
                                  <span><i className={`fas fa-star mx-1 font-12  ${dataReview?.review === 5 ? 'text-success' : ''} `}></i></span>
                                </div>
                              </div>
                            </div>
                            <p className="mb-2 text-dark-black font-weight-500">
                              <q>{dataReview.feedback}</q>
                            </p> 
                            <p className="d-block mt-1 mb-0 font-weight-500 mb-1 text-capitalize text-dark-black font-13">
                                {dataReview.name}
                            </p>
                            <p className="profile-subTitle font-13 font-weight-500 text-capitalize text-third d-block mb-1">
                                {dataReview.designation}{dataReview.designation ? ',' : ''} {dataReview.company_name}
                            </p>
                          
                        </div> : <></>
                      }
                      <div className="col-sm-12">
                        <h2 className="text-third font-13 font-weight-600 mob-font-13 mb-1">Completed On: {EndMonth} {EndYear}</h2>
                        
                        <p className="mb-1 font-weight-500 font-16 mob-font-14">
                          <img src={require("../../../../Assets/images/cc.png")} className="img-fluid" alt="uploading" width="50" />
                        </p>
                        <p className="mb-0 font-weight-500 font-12 font-weight-500 text-third desktop-ipad-show">This work is licensed under a <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank" rel="noopener noreferrer">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  let projectView = store.projectView;
  let globalReducer  = store.globalReducer;
  let dashBoard = store.dashboard;
  let postProjectData = store.postProject;
  return {
    project_error:projectView.Project_data_error,
    publicView: creatorProfile.public,
    closeModal: creatorProfile.closeModal,
    projectId: projectView._id,
    About: projectView.About,
    Title: projectView.Title,
    Role: projectView.Role,
    EndMonth: projectView.EndMonth,
    EndYear: projectView.EndYear,
    Keywords: projectView.Keywords,
    Tools: projectView.Tools,
    Images: projectView.Images,
    Collateral: projectView.Collateral,
    userData:projectView.userData,
    createdFor: projectView.created_type,
    companyName: projectView.company_name,
    dataReview: projectView.dataReview,
    CurrentRole: globalReducer.Me.CurrentRole,
    Projects: dashBoard.Projects,
    AllProjects: dashBoard.AllProjects,
    userPortfolioProjects: postProjectData.userPortfolioProjects,
    
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  LoadProjectData: (id) => dispatch({ type: 'LOAD_PROJECT_DATA', payload: { id } }),
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  //editUserProject: (e) => dispatch({ type: 'EDIT_PROJECT', payload: { id: e}})
  editUserProject: (id, history) => dispatch({ type: 'EDIT_PROJECT', payload: { id, history } }),
  resetProjectData: () => dispatch({ type: 'RESET_SINGLE_PROJECT_DATA', values: null }),
  onFieldChange: (e) =>dispatch({type: 'ON_FIELDCHANGE_SAGA', payload: { name: e.name, value: e.value },}),
});
export default connect(mapState, mapDispatch)(ViewModal);


const RenderImage = ({index, image, active_thumbnail, handleThumbnail}) => {
  let imgSrc = image.value;
  let type = image.type;
  let original_image = image.image_original;
  // let thumbnail = (type === "url") ? (async () => await getThumbnailImage(imgSrc))() : imgSrc;

  const [thumbnail, setThumbnail] = useState(null)

  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url)
    }
    if(type === "url" ){
      getThumbnailImageFunc(imgSrc)
    } else {
      setThumbnail(imgSrc)
    }
  }, [imgSrc,type])

    return (
      <span className={`thumb-img mb-2  ${active_thumbnail === index ? "active" : ""}`} onClick={() => handleThumbnail(index, type, imgSrc, original_image)}>
        {/* <img src={thumbnail} alt="project-work" className={`img-fluid  ${active_thumbnail == index ? "active" : ""}`} /> */}
        <span className={`upload_image_thumbnail`} style={{backgroundImage:`url("${isViewableFile(image.value) ?  renderDifferentFilesThumbnail(image.value) : image.type === "image" ? image.value : image.type === "url" ? thumbnail : ''}")`}}></span>
      </span>
    )
}
