import React, { useEffect, useState } from "react";
import { putAPI } from "../../../../../utils/API";
import { fireApi } from "../../../../../utils/fireApiPrecheck";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

const creatorCheckboxList = [
  { text: "Unable to agree terms with client." },
  { text: "I do not want to extend this project further." },
  { text: "I am already booked with another work commitment." },
  { text: "I am taking a break." },
  { text: "Other" },
];
const clientCheckboxList = [
  { text: "Unable to agree terms with creator." },
  { text: "Creator is unavailable." },
  { text: "Extension is no longer required." },
  { text: "We are going ahead with another resource." },
  { text: "Other" },
];

const CloseExtPopup = (props) => {
  // const checkboxList = props.role === 2 ? creatorCheckboxList : clientCheckboxList
  const [states, setStates] = useState({
    reason: [],
    checkboxList:props.role === 2 ? creatorCheckboxList : clientCheckboxList,
    description: "",
  });

  const [loader, setLoader] = useState(false);

  const onChangeHandler = (e, obj) => {
    const isAlreadyChecked = states.reason.find((el) => el.text === obj.text);

    if (isAlreadyChecked) {
      setStates({
        ...states,
        reason: [...states.reason.filter((el) => el.text !== obj.text)],
      });
    } else {
      setStates({ ...states, reason: [...states.reason, { text: obj.text }] });
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (!states.reason.length) {
      alert("Please select at least one reason.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    const { reason, description } = states;
    if (formValidation()) {
      handleSubmitReject({ reason, description });
    }
  };

  const handleSubmitReject = async (data) => {
    setLoader(true);
    const obj = {
      rejected_reason: data.reason,
      rejected_note: data.description,
    };
    // console.log("obj === ", obj);
    props.handleCloseAppExt(2, props.extCloseId, obj);
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setExtClose(false);
    }
  };

  return (
    <React.Fragment>
      <section className="ask_question">
        <div
          className={`modal fade modal_fade_bg show`}
          id="reviewConfirm"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_xsm">
            <div className="modal-content">
              {/* <div className="modal-header  d-block">
                <div className="row header_title mb-2">
                  <div className="col-lg-12">
                    <h5 className="modal-title modal_title mb-0" id="exampleModalLabel">
                      Tell us why you're closing this extension
                    </h5>
                  </div>
                </div>
              </div> */}
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between ps_1 pe_1">
                <h5 className="line_height_20 fw_700 text-black-700 mb-0" id="exampleModalLabel">
                  Tell us why you're closing this extension
                </h5>
                <button type="button" className="close close_btn" data-dismiss="modal" aria-label="Close" onClick={() => props.setExtClose(false)}>
                  <span aria-hidden="true" className="modalclose-btnicon">
                    ×
                  </span>
                </button>
              </div>
              {/* <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
                <h5 className="line_height_20 fw_700 text-black-700" id="exampleModalLabel">
                Updated by<span><img src={GrayUserIcon} className='mx-2'/></span>Jinal on {moment(data?.updateAt).format("MMMM DD YYYY, h:mm a")}
                </h5>
                <button
                  type="button"
                  className="close close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowVersionHistory(false)}
                >
                  <span aria-hidden="true" className="modalclose-btnicon">
                    ×
                  </span>
                </button>
              </div> */}
              <div className="modal-body py-4 ">
                <div className="row mb-4">
                  <div className="col-lg-12">
                    <p className="text_gray_w_600 mb-0">Please share with us why you’re want to close the project</p>
                  </div>
                </div>

                {states.checkboxList?.length
                  ? states.checkboxList.map((obj, index) => (
                      <div className="row mb-3">
                        <div key={index} className=" col-lg-auto pr-0">
                          <div className="cstm_checkbox">
                            <input
                              type="checkbox"
                              id={index}
                              checked={states.reason.find((data) => data.text === obj.text) ? true : false}
                              onChange={(e) => onChangeHandler(e, obj)}
                              name={`checkbox${index}`}
                            />
                            <label htmlFor={index}></label>
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <p className="mb-0 text-black-700 fw_600 fs_14">{obj.text}</p>
                        </div>
                      </div>
                    ))
                  : null}

                {/* checkbox */}
                {/* {checkboxList?.length
                    ? checkboxList.map((obj, index) => (
                        <div  key={index} className="col-sm-12 cstm_checkbox">
                          <input type="checkbox"  id={index}  checked={states.reason.find((data) => data.text === obj.text) ? true : false}
                            onChange={(e) => onChangeHandler(e, obj)}
                            name={`checkbox${index}`}/>
                          <label htmlFor={index}>{obj.text}</label>
                        </div>
                               
                      ))
                      : null} */}
                <div className="row">
                  <div className="form-group col-sm-12 mt-2">
                    <textarea
                      className="form-control email min-h-100 mb-1"
                      name="description"
                      value={states.description}
                      onChange={(e) =>
                        setStates({
                          ...states,
                          description: e.target.value,
                          // description: e.target.value.substring(0, 300),
                        })
                      }
                      placeholder="Describe further (optional)"
                      id="comment"
                    ></textarea>
                    {/* <span className="font-weight-500 text-secondary">{states.description?.length}/300</span> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn_outline_sm" onClick={() => props.setExtClose(false)}>
                  cancel
                </button>
                <button type="button" className="btn btn-common-danger ml-3" onClick={handleSubmit}>
                  {loader ? <Loader isCreateModal={true} /> : "Reject Extension"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CloseExtPopup;
