import React from 'react';
import { connect } from 'react-redux';
// import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import TestDiv from '../TestDiv';

class CropImages extends React.Component {
    render() {
        return (
            <>
                <div className="modal fade custom_form edit-basic-profile show" data-backdrop="static" style={{ paddingRight: "0px", display: "block" }} id="projectSubmittedModal"
                    tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content small-mt-60 overflow-hidden mob-mt-50">
                            <div className="modal-body">
                                <TestDiv {...this.props}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
            </>
        )
    }
}

const mapState = (store) => {
    let project = store.project;
    let creatorProfile = store.creatorProfile;
    // console.log("Project=====>", project);
    return {
        cropImageSrc: project.cropImageSrc,
        projectImage: project.project_image,
        images_or_links: project.images_or_links,
        projectId: project.project_submitted_id,
        closeModal: creatorProfile.closeModal,
        imageForCrop: project.imageForCrop
    };
};

const mapDispatch = dispatch => ({
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
});

export default connect(mapState, mapDispatch)(CropImages);