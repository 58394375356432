import React from 'react'
// import FileViewer from 'react-file-viewer'
import { getFileType } from '../Common/FileRenderMessage'
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import Loader from '../../../unauthorized/Common/Loader/Loader';
// import Viewer from 'react-viewer';

export default function AllFileViewer({link}) {
    // const [file, setFile] = useState(null)
    
    // useEffect(() => {
    //     const setFileFunc = async () => {
    //         await setFile(null)
    //         await setFile(link)
    //     }
    //     setFileFunc()
    // }, [link])

    // if(!file) return null
    const fileType = getFileType(link)
    console.log("fileType == ",fileType,fileType === 'pdf')
    return (
        // <FileViewer
        //     fileType={getFileType(file)}
        //     filePath={file}
        // />
        // <DocViewer 
        // style={{width: '100%'}}
        // documents={[{uri: link}]}
        // pluginRenderers={DocViewerRenderers}
        //  />
        // <iframe width="100%" height="100%" frameborder="0" src={`https://docs.google.com/gview?embedded=true&url=${link} `}></iframe>
        // <iframe className={getFileType(link)} width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${link}&embedded=true`}></iframe>
        fileType === 'mp3' ? 
        <audio controls>
            <source src={link}type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio> :
        fileType === 'pdf' ?
        <iframe title="pdf" className={getFileType(link)} width="100%" height="115%" frameBorder="0" src={link}></iframe> :
        <iframe title="files" src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`} width='100%' height='115%' frameborder='0'></iframe>
    )
}
