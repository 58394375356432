import React, { useEffect, useState } from "react";
import ChatViewUploadDel from "./ChatViewUploadDel";
import { getAPI } from "../../../../../../utils/API";
import RetHourlyRequest from "../../PopupAll/RetHourlyRequest";

const ParentChatViewUp = ({
  viewUploadDel,
  setViewUploadDel,
  CurrentRole,
  payment_reference_id,
  id_milestone,
  milestone_complete_id,
  proposal_id,
  projectDataStates,
  CurrentChat,
}) => {
  const [milestone_id, set_milestone_id] = useState("");
  const [loader, setLoader] = useState(true);
  const [viewAttachments, setViewAttachments] = useState([]);
  const [viewVersionHistory, setViewVersionHistory] = useState(false);
  const [mileObj, setMileObj] = useState("");
  const [fixedLastSubm, setFixedLastSubm] = useState(false);
  const [uploadMileEdit, setUploadMileEdit] = useState(false);
  const [allData, setAllData] = useState([]);
  const [showProjEndPop, setShowProjEndPop] = useState(false);
  const [showFinalReview, setShowFinalReview] = useState(false);
  const [showEndProjSuccess, setShowEndProjSuccess] = useState(false);
  const [justStaticEndProjClient, setjustStaticEndProjClient] = useState(false);
  const [retHourlyReq, setRetHourlyReq] = useState(false);
  const [retHourlyReqTYpe, setRetHourlyReqType] = useState("");
  const [reworkId, setReworkId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [dataAll, setDataAll] = useState("");


  useEffect(() => {
    handleChatGetProposal();
  }, []);

  const handleChatGetProposal = async () => {
    try {
      const res =
        await getAPI(`/milestone/get-milestone-detail-chat?payment_deliverable_id=${payment_reference_id}&milestone_id=${id_milestone}&milestone_complete_id=${milestone_complete_id}&proposal_id=${proposal_id}
      `);
      let data = res.data.AllProposal;
      // console.log("res ======== ", data);
      if (data) {
        setDataAll(data)
        set_milestone_id(id_milestone);
        setViewAttachments(
          data?.proposal_type?.toLowerCase() == "fixed"
            ? data.milestoneFixed[0].deliverables[0]
            : data?.proposal_type?.toLowerCase() == "actual"
            ? data.milestoneActual[0].deliverable[0]
            : data.milestoneRetainer[0].deliverable[0]
        );
        setMileObj(
          data?.proposal_type?.toLowerCase() == "fixed"
            ? data.milestoneFixed[0]
            : data?.proposal_type?.toLowerCase() == "actual"
            ? data.milestoneActual[0]
            : data.milestoneRetainer[0]
        );
        setPaymentStatus(
          data?.proposal_type?.toLowerCase() == "fixed"
            ? data.milestoneFixed[0]?.status
            : data?.proposal_type?.toLowerCase() == "actual"
            ? data.milestoneActual[0]?.status
            : data.milestoneRetainer[0]?.status
        );
      }

      setLoader(false);
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };

  const handleCallApiAgain = () => {
    return;
  };

  return (
    <>
      {viewUploadDel && (
        <ChatViewUploadDel
          setViewUploadDel={setViewUploadDel}
          viewAttachments={viewAttachments}
          viewLatestAttachments={
            fixedLastSubm
              ? viewAttachments
              : viewAttachments?.milestonecomplete?.length
              ? viewAttachments?.milestonecomplete[0]
              : {}
          }
          milestone_id={milestone_id}
          viewUploadDel={viewUploadDel}
          projectDataStates={projectDataStates}
          viewVersionHistory={viewVersionHistory}
          setViewVersionHistory={setViewVersionHistory}
          role={CurrentRole}
          mileObj={mileObj}
          proposalId={dataAll?._id}
          handleCallApiAgain={handleCallApiAgain}
          proposal_type={dataAll?.proposal_type}
          setFixedLastSubm={setFixedLastSubm}
          fixedLastSubm={fixedLastSubm}
          setRetHourlyReqType={setRetHourlyReqType}
          setRetHourlyReq={setRetHourlyReq}
          uploadMileEdit={uploadMileEdit}
          setUploadMileEdit={setUploadMileEdit}
          setReworkId={setReworkId}
          allData={allData}
          loader={loader}
          setAllData={setAllData}
          paymentStatus={paymentStatus}
          state={dataAll}
        />
      )}

      {retHourlyReq && (
        <RetHourlyRequest
          userData={CurrentChat}
          work_log_id={viewAttachments?.milestonecomplete[0]?._id}
          retHourlyReqTYpe={retHourlyReqTYpe}
          retHourlyReq={retHourlyReq}
          setRetHourlyReq={setRetHourlyReq}
          milestone_id={milestone_id}
          reworkId={reworkId}
          handleCallApiAgain={handleCallApiAgain}
          setViewUploadDel={setViewUploadDel}
        />
      )}
    </>
  );
};

export default ParentChatViewUp;
