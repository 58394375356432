import React, { Component } from 'react'

class ModalBody extends Component {

    state = {
        reason:[],
        checkboxList:[],
        description: ''
    }


    onChangeHandler = (e, obj) => {
        // const { name, checked } = e.target;
        // console.log({name, checked, obj})
        const isAlreadyChecked = this.state.reason.find(el => el.text === obj.text);

        if(isAlreadyChecked){
            this.setState({reason: [...this.state.reason.filter(el => el.text !== obj.text)]})
        } else {
            this.setState({reason: [...this.state.reason, {text: obj.text}]})
        }
    }

    formValidation = () => {
      let isValid = true;

      if(!this.state.reason.length){
        alert('Please select at least one reason.')
        isValid = false
      }
      return isValid
    }

    handleSubmit = () => {
      const { reason, description } = this.state
      if(this.formValidation()){
        this.props.submitHandler({reason, description})
      }
    }

    

    render() {
        const { checkboxList, headerText, buttonText, isSubmitting } = this.props
        const { reason, description } = this.state
        return (
            <div className="modal-dialog modal-dialog-centered ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0 mobile-hide">
                    <div className="profile-creator ml-05 mob-m-0 text-center">
                      <div className="py-30">
                        <h2 className="mt-0 font-weight-500 mb-1 pt-1 font-18 mob-font-14">{headerText}</h2>
                        {/* <p className="mb-0 text-third font-weight-500 font-12 mob-font-12">You'll receive a call from us once you've submitted the feeback.</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mobile-show bg-shadow">
                    <div className="mob-m-0 text-left">
                      <div className="py-15">
                        <h2 className="mt-0 font-weight-600 mb-1 pt-1 pl-3 font-18 small-font-14">Tell us why you're rejecting Karan</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project reject-profile">
                    <div className="custom_form px-2 pb-4 pt-4 mob-px-0">
                      <form method="post" action="">
                        <div className="row m-0">
                          {checkboxList?.length ? checkboxList.map((obj,index) => (
                            <div key={index} className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" 
                                name={`checkbox${index}`}
                                id={index}
                                checked={reason.find(data => data.text === obj.text) ? true : false} 
                                onChange={(e) => this.onChangeHandler(e, obj) } 
                            />
                            <label htmlFor={index} className="font-weight-600 small-font-12">{obj.text}</label>
                          </div>
                          )) : null}
                          
                          <div className="form-group col-sm-12">
                            <textarea className="form-control email min-h-100 mb-1" name="description" value={description} onChange={(e) => this.setState({description: e.target.value.substring(0,300)})} placeholder="Describe further (optional)" id="comment"></textarea>
                            <span className="font-weight-500 text-secondary">{description.length}/300</span>
                          </div>

                        </div>
                        <div className="form-group text-left pl-3 pt-2 mob-px-15 mob-mb-30">
                          <button 
                            type="button" 
                            className="main-btn font-weight-500 py-12 mob-w-100"
                            disabled={isSubmitting}
                            onClick={this.handleSubmit} >{buttonText ? buttonText : 'Confirm'} <i className="fas fa-arrow-right font-12 font-weight-500 ml-1"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default ModalBody
