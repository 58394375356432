import React, { Component } from 'react';
import axios from 'axios';
import { Baseurl } from '../../../utils/BaseUrl';
import firebaseIs from '../../../utils/fire';
import firebase from 'firebase';
import {getPublicAPI} from '../../../utils/API'

export default class SocialLogin extends Component {
    state = {
        isLoading: false
    }

    handleSocialLogin = () => {
        this.setState({isLoading: true, loggingIn: true})
        const that = this;

        var provider = new firebase.auth.GoogleAuthProvider();
        firebaseIs.auth().signInWithPopup(provider).then(function (result) {
            
            // let userEmail = result.user.email;
            // console.log("firebase social login successful", userEmail);
            firebaseIs.auth().currentUser.getIdToken().then(function (idToken) {
                axios({
                    method: 'post',
                    url: Baseurl + '/users/login',
                    headers: { 'content-type': 'application/json' },
                    data: {
                        token: idToken,
                        login_type: "social_login"
                    }
                }).then((res => {
                    console.log("login-api-response----->", res);
                    let userToken = res.data.token
                    localStorage.setItem("SignUpidToken", userToken)
                    that.props.reload();
                })).catch((error) => {
                    console.log("login-api-error------>", error);
                    that.setState({isLoading: false})
                    // alert("Internal Server error. Please try again.")
                })
            });
        }).catch(function (error) {

            console.log("firebase social login error--->", error);
            that.setState({isLoading: false, loggingIn: false})
            // alert(errorMessage)
        });

    }

    handleLoginOTP = () => {
        alert('This feature is in progress...')
    }

    send_Link = () => {
        this.setState({resetting: true, isLoading: true})
        const that = this;
        const { userEmail } = this.props;
        getPublicAPI('/users/forgot-password-link?email='+userEmail).then((res)=>{
            // console.log('forgot password link api review--->',res)
            if(res.data.status === "1"){
                alert("A link to reset password has been sent to your email.")
                this.props.setHomeState({loginOptionsModal: false})
            } else {
                that.setState({resetting: false, isLoading: false})
                alert("Something went wrong. Please try again.")
            }
        }).catch((error)=>{
            console.log("forgot password link api error----->", error.response);
            that.setState({resetting: false, isLoading: false})
        })
        // firebaseIs.auth().sendPasswordResetEmail(userEmail)
        //     .then(function (user) {
                // that.setState({resetting: false, isLoading: false})
        //         alert('Email sent with reset link. Please check your email...')
        //     }).catch(function (e) {
        //         console.log(e)
        //         that.setState({resetting: false, isLoading: false})
        //         alert('Something went wrong. Please try again.')
        //     })
    }

    handleClose = () => {
        this.props.setHomeState({
            loginOptionsModal: false,
        });
    }

    render() {
        const {isLoading, resetting, loggingIn} = this.state;
        const { userData } = this.props;
        const name = userData.data ? userData.data.first_name : ''

        return (
            <React.Fragment>
                <div className="modal custom_form custom_Modal_Box" id="1LoginModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                    <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img alt="" src={require("../../../Assets/images/close.png")} className="img-fliud" width="35" /></span>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content user_bg">
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-sm-7 mob_background_image">
                                        <div className="mob-login-background"></div>
                                    </div>
                                    <div className="col-sm-5 overflow-content mob-overflow-content user_modal">
                                        <div className="login signIn_google">
                                            <div className="user">
                                                <img alt="" src={require("../../../Assets/images/user.svg")} className="img-fliud" style={{ width: "85px" }} />
                                                <h2 className="ipad-py-24">Welcome, <br />
                                                    {name}
                                                </h2>
                                            </div>
                                            <form action="" method="post">
                                                <div className="form-group user_details">
                                                    <p>Looks like you had logged in through a social account last time. How would you like to log in today?</p>
                                                </div>
                                                <div className="form-group">
                                                    <button disabled={isLoading} type="button" className="main-btn w-100 mb-3" onClick={this.handleSocialLogin}>{loggingIn ? 'Logging...' : 'Log in with Google'}</button>
                                                    <button disabled={isLoading} type="button" className="main-btn w-100 mb-3 bg-secondary" onClick={this.handleLoginOTP}>Log in with OTP</button>
                                                    <button disabled={isLoading} type="button" className="main-btn w-100 mb-3 bg-secondary" onClick={this.send_Link}>{resetting ? 'Sending email...' : 'Reset password'}</button>
                                                </div>
                                                {/* <div className="reCaptcha mt-4">
                                                    <p className="pl-0">This Site is protected by reCAPTCHA and the <br /> Google Privacy Policy and Teams of Service Apply.</p>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-7 remove-pd">
                                        <div className="login-background"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-modal"></div>


                {/* <div id="exampleModal" className="modaldemo" role="dialog" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                          <h5>Welcome user</h5>
                        </div>
                        <div className="modal-body">
                                <div className="form-group col-md-12 mx-center">
                                    <button className="btn btn-success" onClick={this.socialLogin}>Sign in through Google</button>
                                </div>
                                <div className="form-group col-md-12 mx-center">
                                    <button className="btn btn-secondary">Sign in with otp</button>
                                </div>
                                <div className="form-group col-md-12 mx-center">
                                    <button className="btn btn-secondary" onClick={this.send_Link}>Reset Password</button>
                                </div>                           
                        </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
} 