import React from 'react'
import {BaseImageUrl} from '../../../../utils/BaseUrl'
export default function ClientCardInLeftPanel({Client}) {
    return (
        <div className="card-wrapper mb-10 chat-box-status active mb-4">
        <div className="card">
          <div className="card-body px-2 py-3">
            <div className="row m-0 d-flex">
              <div className="col-2 pl-2 mob-pl-6 mob-pr-0">
                <div>
                  <p className="mb-0 creator-profile">
                    <img
                      src={BaseImageUrl + Client.profile_image}
                      alt="profile"
                      title=""
                      width={35}
                    />
                  </p>
                </div>
              </div>
              <div className="col-10 pl-2">
                <div className="dflex mb-1">
                  <div className="d-flex align-items-center">
                    <h2 className=" chat-username  text-dark-black text-capitalize m-0 font-16 font-weight-600 mob-font-12">
                      {Client.first_name + ' ' + Client.last_name}
                    </h2>
                  </div>
                </div>
                <p>Client</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
