import React from 'react';
import ProgressBar from './Progressbar';
import { connect } from 'react-redux';
import { putAPI } from '../../../utils/API';
class Deadline extends React.Component {
    state = {
		deadLineError: false,
		isLoading:false,
	} 
	handleContinue= () => {
		this.setState({isLoading: true})
		if(!this.props.deadline) {
			this.setState({
				deadLineError: true,
				isLoading: false,
			})
            //alert("Please select your deadline to continue");
            return;
        }
		const deadline_data = {
			_id: this.props.projectId,
			dealline: this.props.deadline,
        }
		let post_steps = this.props.post_steps;
		let total_steps = this.props.total_steps;
		let typeQuestions = this.props.typeQuestions;
		let StyleQuestions = this.props.StyleQuestions;
		let {isHireflow}=this.props
		if(this.props.userName) {
			putAPI('/project/post-project-dealline', deadline_data).then((res) => {
				if(res.data.status === "1") {
					this.props.PostProjectSteps(post_steps,total_steps);
					if(typeQuestions && typeQuestions.length > 0) {
						this.props.history.push(`${isHireflow?'/hire/':'/self-service-hire/'}type`);
					} else if(StyleQuestions && StyleQuestions.length > 0) {
						this.props.history.push(`${isHireflow?'/hire/':'/self-service-hire/'}style`);
					} else {
						const status_data = {
							post_project_id:this.props.projectId,
							status: 1,
						}
						putAPI('/project/post-project-status', status_data).then((res) => {
							if(res.data.status === "1") {
							this.props.history.push('/profile_selection');
						}
							}).catch((error) => {
							console.log(error);
						})
						
					}
					this.setState({isLoading: false})
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("DeadlineData",JSON.stringify(deadline_data));
			if(typeQuestions?.length > 0) {
				this.props.history.push(`${isHireflow?'/hire/':'/self-service-hire/'}type`);
			} else if(StyleQuestions && StyleQuestions?.length > 0) {
				this.props.history.push(`${isHireflow?'/hire/':'/self-service-hire/'}style`);
			} else {
				this.props.OpenSignupLogin(true);
				this.props.history.push('/');
			}
			this.setState({isLoading: false})
		}
	}

    render() {
		const Background_1 = require("../../../Assets/images/Deadline-1.svg");
		const Background_2 = require("../../../Assets/images/Deadline-2.svg");
		const Background_3 = require("../../../Assets/images/Deadline-3.svg");
		const Background_4 = require("../../../Assets/images/Deadline-4.svg");

		const {deadline_heading,deadline ,deadline_subtext} = this.props;
		const {deadLineError} = this.state;
		// console.log(deadline);

		return (
            <>
                <section>
                <div  className="card-wrapper mob-mb-70"> 
					<div  className="card mob-border-radius-0">
						<div  className="card-body"> 
							<div  className="row m-0 px-2 pb-4 mob-p-0 mob-m-0 overflow-content budget-deadline"> 
								<div  className="col-12 col-sm-12 mob-p-0"> 
									<div  className="px-3 pt-5 pb-3 mob-pt-py">
										<h2  className="font-22 mob-font-18 font-weight-600 mb-1">{deadline_heading}</h2>
										{(deadline_subtext !== "") ?
											<p  className="label font-weight-500 text-third">{deadline_subtext}</p>
										:<></>}
									</div> 
									<div  className="row m-0">
										<div  className="col-sm-11">
											<div  className="deadline-project">
												<ul  className="content">
													<li  className="mb-4">
														<div  className={`deadline fadeout position-relative ${(deadline === 7) ? "active" : ""}`} style={{backgroundImage: `url("${Background_1}")`}} onClick={(e) => {this.props.ChooseDeadLine(7)}}>
														</div> 
													</li>
													<li  className="mb-4">
														<div  className={`deadline fadeout position-relative ${(deadline === 15) ? "active" : ""}`} style={{backgroundImage: `url("${Background_2}")`}} onClick={(e) => {this.props.ChooseDeadLine(15)}}>
														</div> 
													</li> 
													<li  className="mb-4">
														<div  className={`deadline fadeout position-relative ${(deadline === 30) ? "active" : ""}`} style={{backgroundImage: `url("${Background_3}")`}} onClick={(e) => {this.props.ChooseDeadLine(30)}}>
														</div> 
													</li> 
													<li  className="mb-4">
														<div className={`deadline fadeout position-relative ${(deadline === -1 || deadline === "0") ? "active" : ""}`} style={{backgroundImage: `url("${Background_4}")`}} onClick={(e) => {this.props.ChooseDeadLine("0")}}>
														</div> 
													</li>  	
												</ul>  
											</div>
											{(deadLineError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your deadline.</p> : ""}	
										</div> 
									</div> 
								</div>
							</div> 
							<div class="row m-0">
								<ProgressBar {...this.state} handleContinue={this.handleContinue} />
							</div>
						</div>
					</div>
				</div>
                </section>
            </>
        )
    }
}

const mapState = (store) => { 
	let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
	return {
		deadline: postProjectData.deadline,
		deadline_heading:  (postProjectData?.deadline_heading !== undefined) ? postProjectData.deadline_heading : postProjectData?.CollateralQuestions?.collateral?.deadline_heading,
		deadline_subtext:  (postProjectData?.deadline_subtext !== undefined) ? postProjectData.deadline_subtext : postProjectData?.CollateralQuestions?.collateral?.deadline_subtext,
		
		projectId: postProjectData.projectId,
		post_steps:postProjectData.post_steps,
		total_steps: postProjectData.total_steps,
		typeQuestions: postProjectData.CollateralQuestions.Collateral_type_question,
		StyleQuestions: postProjectData.CollateralQuestions.Collateral_style_question,
		alreadyCreatedProject: postProjectData.alreadyCreatedProject,
		userName: globalReducer.Me.Username,
	}; 
}

const mapDispatch = dispatchEvent => ({ 
	OpenSignupLogin: (state) => dispatchEvent({ type: "OPEN_SIGNUP_LOGIN", payload: state}),
	ChooseDeadLine: (days) => dispatchEvent({ type: 'CHOOSE_DEADLINE',   payload: days }),
	PostProjectSteps : (step,total_step) => dispatchEvent({type:"POST_PROJECT_STEPS",payload:{step:step,total_steps:total_step}}),
});


export default connect(mapState,mapDispatch)(Deadline);