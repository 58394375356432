import {
  UPDATE_USERWORK_ALLCOMPANY,
  UPDATE_USERWORK_ALLLOCATION,
  UPDATE_USERWORK_ALLINDUSTRY,
  UPDATE_USERWORK,
  UPDATE_USERWORK_RESET,
  UPDATE_USERWORK_COMPANY,
  UPDATE_USERWORK_LOCATION,
  UPDATE_USERWORK_DESIGNATION,
  UPDATE_USERWORK_STARTMONTH,
  UPDATE_USERWORK_ENDMONTH,
  UPDATE_USERWORK_STARTYEAR,
  UPDATE_USERWORK_ENDYEAR,
  UPDATE_USERWORK_WEBSITE,
  UPDATE_USERWORK_ID,
  UPDATE_USERWORK_INDUSTRY,
  UPDATE_USERWORK_EDIT,
  UPDATE_USERWORK_CURRENTROLE,
  UPDATE_USERWORK_ERROR,
  UPDATE_USERWORK_COMPANY_ERROR,
  UPDATE_USERWORK_LOCATION_ERROR,
  UPDATE_USERWORK_DESIGNATION_ERROR,
  UPDATE_USERWORK_STARTMONTH_ERROR,
  UPDATE_USERWORK_ENDMONTH_ERROR,
  UPDATE_USERWORK_STARTYEAR_ERROR,
  UPDATE_USERWORK_ENDYEAR_ERROR,
  UPDATE_USERWORK_WEBSITE_ERROR,
  UPDATE_USERWORK_INDUSTRY_ERROR,
  UPDATE_USERWORK_TYPE,
  UPDATE_USERWORK_TYPE_ERROR,
} from '../type.action';

const initState = {
  AllCompany: [],
  AllLocations: [],
  AllIndustry: [],

  edit: -1,
  CurrentRole: false,
  Industry: '',
  Company: '',
  CompanyImage: '',
  Location: '',
  Designation: '',
  StartMonth: '',
  Type: '',
  EndMonth: '',
  StartYear: '',
  EndYear: '',
  WebSite: '',
  IndustryError: false,
  TypeError: false,
  CompanyError: false,
  LocationError: false,
  InsurationError: false,
  DesignationError: false,
  StartMonthError: false,
  EndMonthError: false,
  StartYearError: false,
  EndYearError: false,
  WebSiteError: false,
  IndustryErrorMessage: '',
  TypeErrorMessage: '',
  CompanyErrorMessage: '',
  LocationErrorMessage: '',
  InsurationErrorMessage: '',
  DesignationErrorMessage: '',
  StartMonthErrorMessage: '',
  EndMonthErrorMessage: '',
  StartYearErrorMessage: '',
  EndYearErrorMessage: '',
  WebSiteErrorMessage: '',
  ID: '',
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};

  switch (type) {
    case UPDATE_USERWORK:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_USERWORK_CURRENTROLE:
      newState = Object.assign({}, state, {
        CurrentRole: values,
      });
      return newState;

    case UPDATE_USERWORK_EDIT:
      newState = Object.assign({}, state, {
        edit: values,
      });
      return newState;

    case UPDATE_USERWORK_ALLCOMPANY:
      newState = Object.assign({}, state, {
        AllCompany: values,
      });
      return newState;
    case UPDATE_USERWORK_ALLLOCATION:
      newState = Object.assign({}, state, {
        AllLocations: values,
      });
      return newState;
    case UPDATE_USERWORK_ALLINDUSTRY:
      newState = Object.assign({}, state, {
        AllIndustry: values,
      });
      return newState;

    case UPDATE_USERWORK_COMPANY:
      newState = Object.assign({}, state, {
        Company: values,
      });
      return newState;
    case UPDATE_USERWORK_LOCATION:
      newState = Object.assign({}, state, {
        Location: values,
      });
      return newState;
    case UPDATE_USERWORK_DESIGNATION:
      newState = Object.assign({}, state, {
        Designation: values,
      });
      return newState;
    case UPDATE_USERWORK_STARTMONTH:
      newState = Object.assign({}, state, {
        StartMonth: values,
      });
      return newState;
    case UPDATE_USERWORK_ENDMONTH:
      newState = Object.assign({}, state, {
        EndMonth: values,
      });
      return newState;
    case UPDATE_USERWORK_STARTYEAR:
      newState = Object.assign({}, state, {
        StartYear: values,
      });
      return newState;
    case UPDATE_USERWORK_ENDYEAR:
      newState = Object.assign({}, state, {
        EndYear: values,
      });
      return newState;
    case UPDATE_USERWORK_WEBSITE:
      newState = Object.assign({}, state, {
        WebSite: values,
      });
      return newState;
    case UPDATE_USERWORK_INDUSTRY:
      newState = Object.assign({}, state, {
        Industry: values,
      });
      return newState;

    case UPDATE_USERWORK_TYPE:
      // console.log('reducer ', values);
      newState = Object.assign({}, state, {
        Type: values,
      });
      return newState;

    case UPDATE_USERWORK_ID:
      newState = Object.assign({}, state, {
        ID: values,
      });
      return newState;

    case UPDATE_USERWORK_ERROR:
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_USERWORK_COMPANY_ERROR:
      newState = Object.assign({}, state, {
        CompanyError: values.error,
        CompanyErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_LOCATION_ERROR:
      newState = Object.assign({}, state, {
        LocationError: values.error,
        LocationErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_DESIGNATION_ERROR:
      newState = Object.assign({}, state, {
        DesignationError: values.error,
        DesignationErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_STARTMONTH_ERROR:
      newState = Object.assign({}, state, {
        StartMonthError: values.error,
        StartMonthErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_ENDMONTH_ERROR:
      newState = Object.assign({}, state, {
        EndMonthError: values.error,
        EndMonthErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_STARTYEAR_ERROR:
      newState = Object.assign({}, state, {
        StartYearError: values.error,
        StartYearErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_ENDYEAR_ERROR:
      newState = Object.assign({}, state, {
        EndYearError: values.error,
        EndYearErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_WEBSITE_ERROR:
      newState = Object.assign({}, state, {
        WebSiteError: values.error,
        WebSiteErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_INDUSTRY_ERROR:
      newState = Object.assign({}, state, {
        IndustryError: values.error,
        IndustryErrorMessage: values.message,
      });
      return newState;

    case UPDATE_USERWORK_TYPE_ERROR:
      newState = Object.assign({}, state, {
        TypeError: values.error,
        TypeErrorMessage: values.message,
      });
      return newState;
    case UPDATE_USERWORK_RESET:
      return initState;
    default:
      return state;
  }
};

export default reducers;
