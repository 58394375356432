import React, { Component } from "react";
import { connect } from "react-redux";
import { getAPI } from "../../../utils/API";
import { slugify } from "../../../utils/Helperfunctions";
import BootstrapLoader from "../Common/BootstrapLoader/BootstrapLoader";
import ChatComponent from "./ChatComponent";

let firstMess = {mes:0,ID:0}

class ChatFromRoute extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      projectId: "",
      projectName: "",
      projectStatus: "",
    };
     this.getProjectData();
  }

  getProjectData = async () => {
    const { match } = this.props;
    const { projectId, connectionId } = match.params;
    this.setState({ isLoading: true });

    try {
      const response = await getAPI(`/project/get-post-project?_id=${projectId}`);
      const data = response.data;
      // console.log("data === ",data)
      if (parseInt(data.status) === 1) {
        const projectName = data.project_data.project_name;
        const projectStatus = data.project_data.status;
        let projectSlug = data.project_data.project_slug;
        if (!projectSlug.length) projectSlug = slugify(data.project_data.project_name);

        // TODO will change it after enterprise done
        const projectType = data.project_data.type ? data.project_data.type : "post_project_client";
        const obj = {
          projectId,
          projectName,
          projectStatus,
          projectType,
          projectSlug,
          // added this just so that redux does not re-route if connectionId is found.
          connectionId,
        };
        this.props.loadProjectChat(obj);
        this.props.getPostProjectClientBrief(projectId);
        this.setState({ isLoading: false, clientBriefDetails: data });
      }
    } catch (error) {
      console.log("Error: ", error.response);
      if (error.response.status === 404) {
        this.props.history.push("/notFound");
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.projectId !== this.props.match.params.projectId) {
      this.getProjectData();
    }
  }

  render() {
    // const {isLoading, projectId, projectName, projectStatus} = this.state;
    const { loading } = this.props;
    let arr = this.props?.Artist?.length ? this.props.Artist.filter(element => element.chat_connection_id === this.props?.CurrentChat?.chat_connection_id) : []
    
    if(arr?.length && arr[0]?.unread_msg > 0){
      firstMess= {mes:arr[0]?.unread_msg,ID:arr[0]?.chat_connection_id}
    }

    return (loading || this.state.isLoading )? (
      <BootstrapLoader />
    ) : (
      <ChatComponent
        firstMess={firstMess}
        authIs={this.props.authIs}
        clientBriefDetails={this.state.clientBriefDetails}
        {...this.props}
        {...this.state}
        handleSetUserId={this.handleSetUserId}
      />
    );
  }
}

const mapDispatch = (dispatch) => ({
  getPostProjectClientBrief: (data) => dispatch({ type: "GET_POST_PROJECT_CLIENT_BRIEF", payload: data }),
  loadChat: (projectId, projectName, projectStatus) =>
    dispatch({ type: "UPDATE_LOAD_CHAT", payload: { id: projectId, name: projectName, project_status: projectStatus } }),
  loadProjectChat: (obj) => dispatch({ type: "LOAD_PROJECT_CHAT", payload: obj }),
});

const mapState = (state) => {
  const { loading } = state.globalReducer;
  const {Artist,CurrentChat} = state.chat;
  return {
    loading,
    Artist,
    CurrentChat
  };
};

export default connect(mapState, mapDispatch)(ChatFromRoute);
