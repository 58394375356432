import React, { useState } from "react";
import TrashImage from "../../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";
import PriceImage from "../../../../../../Assets/upgrade-images/submit_proposal/price.svg";
import DatePicker from "react-date-picker";

const ActualDeliverable = ({ states, setStates, validationDeliverable }) => {
  const handleChangeDeliverables = (e, i) => {
    const { name, value } = e.target;
    states.deliverable[i][name] = value;
    states.deliverable[i].validation[name] = value.length > 0 ? false : true;

    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleToggleIteration = (e, i) => {
    const { checked } = e.target;
    states.deliverable[i].toggle = checked;
    states.deliverable[i].iteration_charge = "";
    states.deliverable[i].iterations = 1;
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleAppend = () => {
    let valid = validationDeliverable();
    if (valid) {
      setStates({
        ...states,
        deliverable: [
          ...states.deliverable,
          {
            name: "",
            iterations: 1,
            breakup: "",
            toggle: false,
            iteration_charge: "",
            validation: {
              name: false,
              breakup: false,
              iteration_charge: false,
            },
            status:0,
            milestonecomplete:[],
            paid_status:0,
          },
        ],
      });
    }
  };

  const handleIteration = (i, action) => {
    if (states.deliverable[i].iterations >= 1) {
      if (action === "plus") states.deliverable[i].iterations += 1;
      else {
        if (states.deliverable[i].iterations > 1) states.deliverable[i].iterations -= 1;
      }
      setStates({
        ...states,
        deliverable: [...states.deliverable],
      });
    }
  };

  const handleDelete = (index) => {
    states.deliverable.splice(index, 1);
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  return (
    <React.Fragment>
      <div className="row px-4 pb-4 deliverables mx-0 scroll_bar_body">
        <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
          <p className="mb-0">Deliverables</p>
          
          <img
              src={FeaturedToggleImage}
              className="icon_img mr-1 cursor-pointer d-block d-lg-none"
              data-toggle="collapse"
              href={`#collapseDeliv`}
              role="button"
              aria-expanded="false"
              aria-controls={`collapseDeliv`}
            />
        </div>
        <div className="col-lg-12 px-0 py-3">
        <div className="row mx-0 mb-4 collapse show " id={`collapseDeliv`}>
        {/* table Desktop */}
        
        <div className="custom_table col-lg-12 px-0 mob-p-0 d-none d-lg-block">
          <div className="row m-0 pb-3 br_btm text_text-gray-1400">
          <div className="col-lg-6 d-flex justify-content-start align-items-center">
              <span className="mr-2 w-5">#</span>
              <span className="">Name</span>
            </div>
            <div className="col-lg-2 col-2">
              <span className="">Pricing</span>
            </div>
            <div className="col-lg-2 col-2">
              <span className="">Your Earnings</span>
            </div>
            <div className="col-lg-2 col-2">
              <span className="">Iterations</span>
            </div>
          </div>
          {states.deliverable?.map((item, i) => {
            return (
              <>
                <div className="row-border creator-proposal" key={i}>
                  <div className="row mx-0 mb-3 py-3 align-items-center ">
                    <div className="col-lg-6">
                      <div className=" d-flex justify-content-start align-items-center">
                      <span className="mr-2 w-5">{i + 1}</span>
                      <input
                        className={`form-control input_md ${item?.validation?.name ? "border-danger" : ""}`}
                        type="text"
                        value={item?.name}
                        name="name"
                        onChange={(e) => handleChangeDeliverables(e, i)}
                        placeholder="Deliverable item"
                      />
                      </div>
                      {item?.validation?.name ? <p className="error_message ml-5">field required</p> : ""}
                    </div>
                    <div className="col-lg-2 col-2 pr-0">
                      <div className={`price_input ${item?.validation?.breakup ? "error_price_icon" : ""}`}>
                        <input
                          type="number"
                          className={`form-control input_sm ${item?.validation?.breakup ? "border-danger" : ""}`}
                          placeholder="Price"
                          value={item?.breakup}
                          name="breakup"
                          onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault() }
                          onChange={(e) => handleChangeDeliverables(e, i)}
                        />
                        <img
                          src={PriceImage}
                          className={`price_icon img-fluid ${item?.validation?.breakup ? "error_price_icon" : ""}`}
                        ></img>
                        {item?.validation?.breakup ? <p className="error_message">field required</p> : ""}
                      </div>
                    </div>
                    <div className="col-lg-2 col-2 pr-0">
                      <p className={`price_input`}>
                         ₹ {item?.breakup ? Math.floor(item?.breakup * .85) : 0}
                      </p>
                    </div>
                    <div className="col-lg-2 col-2 pr-0">
                      <div className="toggle_btn d-flex align-items-center justify-content-between">
                        <label className="switch mb-0">
                          <input type="checkbox" checked={item?.toggle} onChange={(e) => handleToggleIteration(e, i)} />
                          <span className="slider round"></span>
                        </label>
                        {states?.deliverable?.length==1?null: <img src={TrashImage} className="icon_img cursor-pointer" onClick={() => handleDelete(i)}></img>}
                      </div>
                    </div>
                  </div>

                  {/* table toggle */}

                  {item.toggle ? (
                    <div className="row m-0 mb-3 justify-content-end">
                      <div className="col-lg-11 mob-p-0 pr-0">
                        <div className="row table_toggle mx-0">
                          <div className="col-lg-5 mob-p-0 d-flex justify-content-start mob-justify-content-between align-items-center">
                            <span className="title mob-p-0">Number of iterations </span>
                            <div className="btn-group w-25" role="group" aria-label="Basic example">
                              <button type="button" className="btn" onClick={() => handleIteration(i, "minus")}>
                                -
                              </button>
                              <input
                                type="text"
                                className="form-control text-center"
                                name="iteration"
                                value={item.iterations}
                                disabled={true}
                              />
                              <button type="button" className="btn " onClick={() => handleIteration(i, "plus")}>
                                +
                              </button>
                            </div>
                          </div>

                          <div
                            className="col-lg-7 mob-p-0 d-flex justify-content-start mob-justify-content-between align-items-center"
                            style={{ backgroundColor: "#F5F7FD" }}
                          >
                            <span className="title mob-p-0">
                              Price Per iteration after <b>{item.iterations} Iterations</b>
                            </span>
                            <div className="price_input">
                              <input
                                type="number"
                                className={`form-control input_sm ${item?.validation?.iteration_charge ? "border-danger" : ""}`}
                                placeholder="Price"
                                value={item?.iteration_charge}
                                name="iteration_charge"
                                onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault() }
                                onChange={(e) => handleChangeDeliverables(e, i)}
                              />
                              <img src={PriceImage} className="price_icon img-fluid"></img>
                            </div>
                            {item.validation.iteration_charge ? <p className="error_message pl-3">field required</p> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          })}
          <div className="row">
            <div className="col-lg-12 mt-3">
              <p className="add_on_milestone mb-4">
              <span className="cursor-pointer" onClick={handleAppend}>  <span className="add_on_milstone_icon">+</span>Add Items </span>
              </p>
            </div>
          </div>
        </div>

        {/* table Mobile view */}
        <div className="custom_table col-lg-12 mob-p-0 d-block d-lg-none">
          {states.deliverable?.map((item, i) => {
            return (
              <>
                <div className="row-border creator-proposal" key={i}>
                  <div className="row mx-0 mb-3 py-3 align-items-center ">
                    <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                      <span>Item {i + 1}</span>
                      <img src={TrashImage} className="icon_img cursor-pointer" onClick={() => handleDelete(i)}></img>
                    </div>
                    <div className="col-12 mb-2">
                      <label className="mb-0 sub_title_commn">Name</label>
                      <input
                        className={`form-control input_md ${item?.validation?.name ? "border-danger" : ""}`}
                        type="text"
                        value={item?.name}
                        name="name"
                        onChange={(e) => handleChangeDeliverables(e, i)}
                        placeholder="Deliverable item"
                      />
                      {item?.validation?.name ? <p className="error_message">field required</p> : ""}
                    </div>
                    <div className="col-12 mb-2">
                    <label className=" mb-0 sub_title_commn">Pricing</label>
                      <div className={`price_input ${item?.validation?.breakup ? "error_price_icon" : ""}`}>
                        <input
                          type="number"
                          className={`form-control input_sm ${item?.validation?.breakup ? "border-danger" : ""}`}
                          placeholder="Price"
                          value={item?.breakup}
                          name="breakup"
                          onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault() }
                          onChange={(e) => handleChangeDeliverables(e, i)}
                        />
                        <img
                          src={PriceImage}
                          className={`price_icon img-fluid ${item?.validation?.breakup ? "error_price_icon" : ""}`}
                        ></img>
                        {item?.validation?.breakup ? <p className="error_message">field required</p> : ""}
                      </div>
                    </div>

                    <div className="col-12 mb-2">
                    <label className=" mb-0 sub_title_commn">Your Earning</label>
                      <p className={`form-control input_sm`}> ₹ {item?.breakup ? Math.floor(item?.breakup * .85) : 0} </p>
                    </div>

                    <div className="col-lg-2 col-2 pr-0">
                    <label className=" mb-0 sub_title_commn">Iterations</label>
                      <div className="toggle_btn d-flex align-items-center justify-content-between">
                        <label className="switch mb-0">
                          <input type="checkbox" checked={item?.toggle} onChange={(e) => handleToggleIteration(e, i)} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* table toggle */}

                  {item.toggle ? (
                    <div className="row m-0 mb-3 justify-content-end">
                      <div className="col-lg-11 mob-p-0 pr-0">
                        <div className="row table_toggle mx-0">
                          <div className="col-lg-6 br_btm mob-p-0 d-flex justify-content-start mob-justify-content-between align-items-center">
                            <span className="title mob-p-0">Number of iterations </span>
                            <div className="btn-group mb-2" role="group" aria-label="Basic example">
                              <button type="button" className="btn" onClick={() => handleIteration(i, "minus")}>
                                -
                              </button>
                              <input
                                type="text"
                                className="form-control text-center"
                                name="iteration"
                                value={item.iterations}
                                disabled={true}
                              />
                              <button type="button" className="btn " onClick={() => handleIteration(i, "plus")}>
                                +
                              </button>
                            </div>
                          </div>
                          <div
                            className="col-lg-6 mt-2 mob-p-0 d-flex justify-content-start mob-justify-content-between align-items-center"
                            style={{ backgroundColor: "#F5F7FD" }}
                          >
                            <span className="title mob-p-0">
                              Price Per iteration after <b>{item.iterations} Iterations</b>
                            </span>
                            <div className="price_input">
                              <input
                                type="number"
                                className={`form-control input_sm ${item?.validation?.iteration_charge ? "border-danger" : ""}`}
                                placeholder="Price"
                                value={item?.iteration_charge}
                                name="iteration_charge"
                                onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault() }
                                onChange={(e) => handleChangeDeliverables(e, i)}
                              />
                              <img src={PriceImage} className="price_icon img-fluid"></img>
                            </div>
                            {item.validation.iteration_charge ? <p className="error_message pl-3">field required</p> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          })}
          <div className="row">
            <div className="col-lg-12 mt-3">
              <p className="add_on_milestone mb-4">
              <span className="cursor-pointer" onClick={handleAppend}>  <span className="add_on_milstone_icon">+</span>Add Items </span>
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
};

export default ActualDeliverable;
