    import React from 'react';
import { connect } from 'react-redux';
import TimeRetainer from '../TimeRetainer';
import { putAPI } from '../../../../utils/API';

class TimeBasedPricing extends React.Component {
  state = {
    submitData: { time_base_duration: '', time_base_amount: 0 },
    submitting: false,
    error: false,
  };
  UserSubmitPricing = (duration, amount) => {
    this.setState({
      submitData: { time_base_duration: duration, time_base_amount: amount },
    });
  };

  onSubmit = (e) => {
    if (this.state.submitData.time_base_amount <= 0) {
      return;
    }

    // console.log(this.state.submitData)

    putAPI('/users/creator-update-time-base-price', this.state.submitData)
      .then((res) => {
        if (res.data.status === '1') {
          alert('Changes submitted successfully');
          this.props.closePopup();
        }
      })
      .catch((error) => {
        alert('Something went wrong, Please try again later.');
        this.setState({ submitting: false });
        console.log('user-company api error------->', error);
      });
  };

  OnChange = (e) => {
    this.props.onFieldChange({
      name: '',
      value: e.target.value,
    });
  };
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
 closeTimeBasedModal(e){
  // const {time_base_amount}=this.state.submitData
  // let todayDate = new Date();
  // let numberOfDaysToAdd = 15;
  // let date = todayDate.setDate(todayDate.getDate() + numberOfDaysToAdd);
  // const updatedate= this.formatDate(date);
  // console.log(updatedate)
  // const payload={date:updatedate}

  // if(!time_base_amount){
  //   try{

  //     putAPI('/users/update-time-base-date',payload).then(res=>{

  //       this.props.closePopup()
  //     })
  //   }catch(ex){
  //     this.props.closePopup()
  //   }

  // }else {
    this.props.closePopup()
  // }
 }
  render() {
    // let { CurrentWorkStatus } = this.props;
    // console.log(this.state.submitData)

    return (
      <>
        <div
          className='modal custom_form verify_modal edit-basic-profile show'
          data-backdrop='static'
          style={{ paddingRight: '0px', display: 'block' }}
          id='EditCurrentWorkStatus'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >

          <span className='close_icon' data-dismiss='modal' aria-label='Close'>
            <img
              onClick={(e) => this.closeTimeBasedModal()}
              alt=''
              src={require('../../../../Assets/images/close.png')}
              className='img-fliud'
              width='35'
            />
          </span>
          <div
            className='modal-dialog modal-dialog-centered modal-lg ipad-screen-650'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='row m-0'>
                  <div className='col-sm-12 p-0'>
                    <div className='profile-creator'>
                      <div className='px-4 py-3'>
                        <p className='text-white mb-1 font-weight-500'>
                          Edit Time Based pricing
                        </p>
                        <h2 className='mt-0 font-weight-500'>
                          Time Based Pricing
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row m-0'>
                  <div className='col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height'>
                    <div className='custom_form py-4 px-2 mob-p-0'>
                      <TimeRetainer submitPricing={this.UserSubmitPricing} />
                    </div>
                    {/* <div className='row mt-2 bg-shadow-top mobile-edit-collateral'>
                      <div className='col-sm-12 p-0 text-right pr-3 py-3'>
                        <button
                          type='button'
                          onClick={(e) => this.closeTimeBasedModal()}
                          className='main-btn bg-light-gray font-weight-600 cursor-pointer'
                          data-dismiss='modal'
                        >
                          Close
                        </button> 
                      </div>
                    </div> */}
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top d-flex justify-content-end desktop-ipad-show'>
                    <div className='form-group text-right pr-3 pt-3'>
                      <button
                        type='button'
                        onClick={(e) => this.onSubmit()}
                        className='main-btn font-weight-600 cursor-pointer'
                        data-dismiss='modal'
                      >
                        Save Changes
                      </button>
                      {/* <button type='button' className='main-btn px-4 ml-2'>Save</button> */}
                    </div>
                    <div className='form-group text-right pr-3 pt-3'>
                      <button
                        type='button'
                        onClick={(e) => this.closeTimeBasedModal()}
                        className='main-btn bg-light-gray font-weight-600 cursor-pointer'
                        data-dismiss='modal'
                      >
                        Close
                      </button>
                      {/* <button type='button' className='main-btn px-4 ml-2'>Save</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;

  return {
    closeModal: creatorProfile.closeModal,
    CurrentWorkStatus: creatorProfile.CurrentWorkStatus,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'UPDATE_SAGA_CURRENTWORKSTATUS',
      payload: { name: e.name, value: e.value },
    }),
});

export default connect(mapState, mapDispatch)(TimeBasedPricing);
