
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { getAPI, postAPI } from '../../../../utils/API';
import { BaseImageUrl,} from '../../../../utils/BaseUrl';
import { getDateTime } from '../../../../utils/DateMethod';
import { renderDifferentFilesThumbnail } from '../../Common/Common/FileRenderMessage';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import defaultThumbnail from '../../../../Assets/images/video-thumbnail-default.png';
import { getThumbnailImage } from '../../../../utils/Helperfunctions'

class ReviewDeliverable extends React.Component {
    state = {
        // user: '',
        // previewSrc: '',
        // completed_Milestone: '',
        // completed_Milestone_versions: [],
        // completed_Milestone_comment: [],
        // deliverable_feedback:'',
        show_version_list: true,
        show_current_version: false,
    }

    showCurrentVersionDetails = (version, comments) => {
        // console.log(comments);
        this.setState({
            show_current_version: true,
            show_version_list: false,
            current_version_id: version._id,
            current_version_number: version.number,
            current_version_files: version.files,
            current_version_links: version.links,
            current_version_comments: comments,
            current_version_date: version.createdAt,
        })
    }

    
    goToBackHandler = () => {
        if(this.state.show_current_version){
            this.setState({
                show_current_version: false,
                show_version_list: true,
            })
        } else if (this.state.show_version_list){
            this.props.OpenPopup('ReviewDeliverable');
        }
    }


    render() {
        // const { ProjectName } = this.props;
        const { show_version_list, show_current_version, current_version_files, current_version_links, current_version_comments } = this.state;
        const {versionHistory} = this.props
        const options = {
            loop:false,
            margin:10,
            responsiveClass:true,
            responsive:{
                  0:{
                      items:2,
                      nav:true
                  },
      
                  700: {
                      items: 2,
                      nav: true,
                    //   loop: true
                  },
                  1030: {
                      items: 2,
                      nav: true,
                    //   loop:false
                  },
      
                  1366:{
                      items:2,
                      nav:true,
                    //   loop:true
                  },
      
              }
          };
        return (
            <>
                <div className="modal fade custom_form user_form_section custom_Modal_Box show" style={{ paddingRight: "0px !imortant", display: "block" }} id="mobileVersionHistory" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fluid" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/*-- Mobile Version Headline --*/}
                                <div className="mobile-show">
                                    <div className="row m-0 py-3 algin-items-center mob-bg-shadow justify-content-between">
                                        <div className="col-12 col-sm-9 d-flex align-items-center">
                                            {/* Mobile Icon Show */}
                                            <div className="pr-2">
                                                <div onClick={this.goToBackHandler} className>
                                                    <i class="fas fa-arrow-left text-third font-16 pt-1"></i>
                                                </div>
                                            </div>
                                            <div> 
                                                <h2 className="mb-0 font-18 text-dark-black font-weight-600 letter-spacing-01">Version history</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="row m-0">
                                    <div className="col-12 pr-0 py-3 chat-modal-section">
                                        <div className="overflow-content mobile_version_history_height">
                                        <div className="version-history">
                                                    {versionHistory && show_version_list ?
                                                        versionHistory && versionHistory.map((versions, k) => 
                                                        { 
                                                            const firstImageData = [...versions.completed_Milestone.files, ...versions.completed_Milestone.links][0]
                                                            const commentLength = versions.completed_Milestone_comment.length
                                                            return(
                                                                <div key={k} className="card-wrapper mb-3">
                                                                <div className="card-body px-4 position-relative">
                                                                    <div className="custom_form">
                                                                        <div className="card-wrapper mb-10">
                                                                            <div className="row m-0 d-flex">
                                                                                <div className="col-sm-12 p-0">
                                                                                    <div className="d-flex">
                                                                                        <div className="w-30">
                                                                                            {/* <p className="mb-0 version-profile" style={{ backgroundImage: `url(${firstImage})` }}>
                                                                                            </p> */}
                                                                                            {firstImageData.name ? 
                                                                                                <p className="mb-0 version-profile" style={{ backgroundImage: `url("${renderDifferentFilesThumbnail(firstImageData.name)}")` }}> </p>
                                                                                                : <RenderImage2 image={firstImageData}  />    
                                                                                            }
                                                                                        </div>
                                                                                        <div className="w-70 pl-3">
                                                                                            <div className="w-100 cursor-pointer" onClick={(e) => this.showCurrentVersionDetails(versions.completed_Milestone, versions.completed_Milestone_comment)}>
                                                                                                <h2 className="chat-username text-capitalize mb-1 font-14 font-weight-600 mob-font-14">Version {versions.completed_Milestone.number}
                                                                                                    <i className="fas fa-chevron-right ml-1 font-12" />
                                                                                                </h2>
                                                                                            </div>
                                                                                            <div className="w-100">
                                                                                                <p className="mb-0 text-third font-12 mob-font-12 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                    Submitted on {getDateTime(versions.completed_Milestone.createdAt, "dateMonth")}
                                                                                                </p>
                                                                                                <p className="mb-0 text-third font-12 mob-font-11 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                    {commentLength} comment{commentLength > 1 ? 's' : ''}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                            }) : 
                                                        show_current_version ?
                                                            <div className="version-chat chatting-box-overflow">
                                                                <div className="card-wrapper mb-3">
                                                                    <div className="row m-0">
                                                                        <div className="col-sm-12 pb-3 px-4" id="creatorSliderImage">
                                                                            {(current_version_files.length + current_version_links.length) ? 
                                                                            <OwlCarousel
                                                                                {...options}
                                                                                className="owl-theme"
                                                                            >
                                                                                {[...current_version_files, ...current_version_links].map((obj, index) => (
                                                                                    <div key={index} className="item">
                                                                                        <div className="card-wrapper box-content box-shadow-box border-radius-8">
                                                                                            <div className="card">
                                                                                                <div className="card-body p-0">
                                                                                                    {obj.name ? <div className="version_image" style={{ backgroundImage: `url("${renderDifferentFilesThumbnail(obj.name)}")` }}>
                                                                                                    </div> : 
                                                                                                    <RenderImage2 image={obj} isDivTag={true} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                }
                                                                            </OwlCarousel> : null}
                                                                            {/* <div id="creatorSliderImage" className="carousel slide pb-3" data-ride="carousel">
                                                                                <ol className="carousel-indicators">
                                                                                    {
                                                                                        current_version_files.map((files, index) => (
                                                                                            <li data-target={`#creatorSliderImage_${index}`} data-slide-to={index} className={`${(index === 0) ? "active" : ""}`} />
                                                                                        ))
                                                                                    }
                                                                                </ol>
                                                                                <div className="carousel-inner">
                                                                                    {current_version_files.map((files, index) => (
                                                                                        <div className={`carousel-item ${(index === 0) ? "active" : ""}`}>
                                                                                            <img className="d-block w-100" src={renderDifferentFilesThumbnail(files.name)} alt="First slide" />
                                                                                        </div>
                                                                                    ))
                                                                                    }
                                                                                </div>
                                                                                <a className="carousel-control-prev" href="#creatorSliderImage" role="button" data-slide="prev">
                                                                                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                                                                                    <span className="sr-only">Previous</span>
                                                                                </a>
                                                                                <a className="carousel-control-next" href="#creatorSliderImage" role="button" data-slide="next">
                                                                                    <span className="carousel-control-next-icon" aria-hidden="true" />
                                                                                    <span className="sr-only">Next</span>
                                                                                </a>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-wrapper mb-3 hidden">
                                                                    <div className="card-body px-4 position-relative">
                                                                        <div className="custom_form">
                                                                            <div className="card-wrapper mb-10">
                                                                                <div className="row m-0 d-flex">
                                                                                    {
                                                                                        current_version_comments.map((comments, index) => (
                                                                                            <div key={index} className="col-sm-12 p-0 mb-4">
                                                                                                <div className="d-flex">
                                                                                                    <div className>
                                                                                                        <p className="mb-0 creator-profile">
                                                                                                            <img src={BaseImageUrl + comments.user_id.profile_image} alt="profile" title width={20} />
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="w-100 pl-2">
                                                                                                        <div className="d-flex align-items-center mb-1">
                                                                                                            <div className="d-flex align-items-center">
                                                                                                                <h2 className="chat-username text-capitalize m-0 font-14 font-weight-600 mob-font-14">{comments.user_id.first_name}</h2>
                                                                                                            </div>
                                                                                                            <div className="pl-2">
                                                                                                                <span className="font-12 text-sub font-weight-600">{getDateTime(comments.createdAt, "time")}</span>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="w-100">
                                                                                                            <p className="mb-0 text-black font-13 letter-spacing-02 line-height-17 font-weight-500">
                                                                                                                {comments.comment}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
            </>
        )
    }
}

const mapState = (store) => {

    let postProjectData = store.postProject;
    // let globalReducer = store.globalReducer;

    return {
        closeModal: postProjectData.closeModal,
        // milestones: postProjectData.milestones,
        // ProjectName: chat.ProjectName,
        // ProjectId: chat.ProjectId,
        // Deliverables: chat.CurrentMilestoneDeliverables,
        // CurrentMilestoneId: chat.CurrentMilestoneId,
        // CurrentRole: globalReducer.Me.CurrentRole,
        // UserId: chat.UserId,
        // CurrentChat: chat.CurrentChat,
        // CurrentMilestoneStatus: chat.CurrentMilestoneStatus,
        // isLoadLatestMilestone: postProjectData.isLoadLatestMilestone,
        // latestMilestoneId: postProjectData.latestMilestoneId,

        versionHistory: postProjectData.versionHistory,
    };
}

const mapDispatch = dispatch => ({
    closePopup: () => dispatch({ type: 'CLOSE_POST_PROJECT_POPUP', payload: {} }),
    OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
    SetCurrentMileStoneID: (milestone_id,milestone_status) => dispatch({ type: 'SET_CURRENT_MILESTONE_ID', payload: {milestone_id,milestone_status}}),
    // loadChatMessages: (connection_id) => dispatch({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
    // SetCurrentDeliverables: (milestone_id) => dispatch({ type: 'SET_CURRENT_DELIVERABLE', payload: { milestone_id } }),
    // loadMe: (text) => dispatch({ type: 'ON_STATEUPDATE_SAGA', payload: text }),
    // loadLatestProposals: (projectId) => dispatch({ type: 'LOAD_LATEST_PROPOSAL', payload: projectId }),
    // resetLatestMilestoneId: () => dispatch({type: 'RESET_LATEST_MILESTONE_ID', payload: null})
});

export default connect(mapState, mapDispatch)(ReviewDeliverable);



// const RenderImage = ({image, thumbnailIndex, k}) => {
//     const [thumbnail, setThumbnail] = useState(null)
  
//     useEffect(() => {
//       const getThumbnailImageFunc = async (u) => {
//         const url = await getThumbnailImage(u);
//         setThumbnail(url)
//       }
//         getThumbnailImageFunc(image)
//     }, [image])
  
//     return (
//         <img src={thumbnail} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} />
//     )
//   }

const RenderImage2 = ({image, isDivTag}) => {
    const [thumbnail, setThumbnail] = useState(null)
  
    useEffect(() => {
      const getThumbnailImageFunc = async (u) => {
        const url = await getThumbnailImage(u, true);
        setThumbnail(url)
      }
      if(image.link){
        getThumbnailImageFunc(image.link)
      } else if(image.name){
        getThumbnailImageFunc(image.name)
      }
    }, [image])
  
    if(isDivTag){
        return (<div className="version_image" style={{ backgroundImage: `url("${thumbnail}")` }}>
        </div>)
    }
    return (
         <p className="mb-0 version-profile" style={{ backgroundImage: `url("${thumbnail}")` }}> </p>
        )
    }

    