import React, { Component } from 'react';
// import profileImage from '../../../../../Assets/images/account-manager.png';
import { BaseImageUrl } from '../../../../../utils/BaseUrl';
import StopIcon from '../../../../../Assets/images/stop-icon.jpg';
import { limitString } from '../../../../../utils/Helperfunctions';

export default class Clients extends Component {
    render() {
        const creatorClients = this.props?.data?.selectedCreatorData; 
        return (
            <React.Fragment>
                <div className="client_wrapper bg-white border-radius-8 ipad-mb-15">
                    <h2 className="font-16 font-weight-800 pb-15 mb-0">Clients</h2>
                    {creatorClients?.length > 0 ? creatorClients.map((el, idx) => {
                        return (
                          <React.Fragment key={idx}>
                               {el.client_image || el.client_name ? <div className="clients_details" key={el._id}>
                                <div className="d-flex align-items-center">
                                  {el.client_image &&  <div className="client_img">
                                        <img src={BaseImageUrl + el.client_image} alt="" />
                                    </div>}
                                    <div>
                                      {el.client_name &&  <span className="font-12 font-weight-600 text-dark-black line-height-normal">
                                      {el.client_name ? limitString(el.client_name, 20) : el.client_name}</span>}
                                    </div>
                                </div>
                            </div> : null}
                          </React.Fragment>
                        )
                    })
                    :
                    <div className="stop_icon">
                        <img src={StopIcon} alt="Indiefolio" className="img-fluid"/>
                        <div className="pt-3 text-sub-7 font-weight-600 font-14 text-center">
                            There are no clients on this profile
                        </div>
                    </div>} 

                </div>
            </React.Fragment>
        )
    }
}
