import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { getAPI, postAPI, putAPI } from '../../../../utils/API';
import { getDateTime } from '../../../../utils/DateMethod';

class ReviewUpdatedProposal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ItemMilestone: [{
                name: '',
                due_date: '',
                price: '',
                milestoneNameError: '',
                dateError: '',
                priceError: '',
                iteration: 1,
                iterationError: ''
            }
            ],
            advancePrice: '',
            advanceDate: '',
            notes: '',
            price: [],
            taxes: 0,
            convenienceCharge: 0,
            AmountPayable: 0,
            finalPrice: 0,
            tempPrice: 0,
            advancePriceError: ''
        }
    }
    closePopup = () => {
        this.props.closePopup()
    }

    componentDidMount = () => {
        let current_proposal_id = this.props.current_proposal_id;
        this.loadLatestProposals(current_proposal_id);
    }
    loadLatestProposals = (current_proposal_id) => {
        getAPI('/milestone/get-proposals?proposal_id=' + current_proposal_id).then((res) => {
            console.log("Updated Proposal data=======>",res.data);
          
            this.setState({
                ItemMilestone: res.data.milestone,
                ItemMilestoneCopy: res.data.milestone,
                advancePrice: res.data.milestone?.[0].price,
                notes: (res.data.proposal.update_status === 0) ? res.data.proposal.notes_new :res.data.proposal.notes,
                finalPrice: (res.data.proposal.update_status === 0) ? res.data.proposal.total_earning_new:  res.data.proposal.total_earning,
                convenienceCharge: (res.data.proposal.update_status === 0) ? res.data.proposal.charges_new : res.data.proposal.charges,
                taxes: (res.data.proposal.update_status === 0) ? res.data.proposal.tax_new :res.data.proposal.tax,
                AmountPayable: (res.data.proposal.update_status === 0) ? res.data.proposal.total_cost_new : res.data.proposal.total_cost,
                tempPrice: (res.data.proposal.update_status === 0) ? res.data.proposal.total_earning_new :  res.data.proposal.total_earning,
                proposal_status: res.data.proposal.status,
                proposal_id: res.data.proposal._id,
            })
        }).catch((error) => {
            console.log(error)
        });
    }

    chargesAndTaxes = () => {
        let finalPrice = parseInt(this.state.finalPrice);
        let convenienceCharge = parseInt((10 / 100) * finalPrice);
        let taxes = parseInt((18 / 100) * finalPrice);
        let AmountPayable = parseInt(finalPrice + convenienceCharge + taxes);
        this.setState({
            convenienceCharge,
            taxes,
            AmountPayable,
        })
    }

    handleDateOnChange = (date, k) => {
        let isOlderThanPrevious = false;
        let isNewerThanNext = false;
        let ItemMilestone = [...this.state.ItemMilestone];
        ItemMilestone.forEach((elements, index) => {
                if (index <= k && elements.type !== "token") {
                    if (new Date(elements.due_date).getTime() > date.getTime()) {
                        isOlderThanPrevious = true;
                        return isOlderThanPrevious;
                    }
                } else if (index >= k) {
                    if (new Date(elements.due_date).getTime() < date.getTime()) {
                        isNewerThanNext = true;
                        return isNewerThanNext;
                    }
                } else return null
        })
        if( ItemMilestone[k]['update_status'] === 0) {
            ItemMilestone[k]['due_date_new'] = (isOlderThanPrevious || isNewerThanNext) ? ItemMilestone[k]['due_date_new'] : new Date(date);
        } else {
            ItemMilestone[k]['due_date'] = (isOlderThanPrevious || isNewerThanNext) ? ItemMilestone[k]['due_date'] : new Date(date);
        }
        ItemMilestone[k][`dateError`] = (isOlderThanPrevious) ? "Please enter a date greater than previous milestones" : (isNewerThanNext) ? "Please enter a date smaller than next milestones" : ""; 
        this.setState({ ItemMilestone: ItemMilestone });
    }

    handleOnChange = (event, k) => {
        let ItemMilestone = [...this.state.ItemMilestone]
        let { value, name } = event.target;
        if(ItemMilestone[k]['update_status'] === 0) {
            ItemMilestone[k][`${name}_new`] = value;
        } else {
            ItemMilestone[k][name] = value
        }
        if (name === "iteration") {
            if(ItemMilestone[k]['update_status'] === 0) {
                ItemMilestone[k][`${name}_new`] = parseInt(value)
            } else {
                ItemMilestone[k][name] = parseInt(value)
            }
            
        }
        ItemMilestone[k][`${name}Error`] = "";
        ItemMilestone[k]['isChanged'] = true;
        this.setState({ ItemMilestone: ItemMilestone })
        if (name === "price") {
            let total = 0
            let tempPrice = 0
            this.state.ItemMilestone.forEach((obj) => {
                if(obj.update_status === 0) {
                    if (obj.price_new !== '') {
                        total = total + parseInt(obj.price_new);
                        tempPrice = tempPrice + parseInt(obj.price_new);
                    }
                } else {
                    if (obj.price !== '') {
                        total = total + parseInt(obj.price);
                        tempPrice = tempPrice + parseInt(obj.price);
                    }
                }
                
            })
            if (this.state.advancePrice !== '') {
                total = parseInt(total) + parseInt(this.state.advancePrice)
            }
            this.setState({ tempPrice: tempPrice })
            this.setState({
                finalPrice: total
            }, (e) => { this.chargesAndTaxes() });
        }
    }

    handleCalculation = () => {
        let total = 0
            let tempPrice = 0
            this.state.ItemMilestone.forEach((obj) => {
                if(obj.update_status === 0) {
                    if (obj.price_new !== '') {
                        total = total + parseInt(obj.price_new);
                        tempPrice = tempPrice + parseInt(obj.price_new);
                    }
                } else {
                    if (obj.price !== '') {
                        total = total + parseInt(obj.price);
                        tempPrice = tempPrice + parseInt(obj.price);
                    }
                }
                
            })
            if (this.state.advancePrice !== '') {
                total = parseInt(total) + parseInt(this.state.advancePrice)
            }
            this.setState({ tempPrice: tempPrice })
            this.setState({
                finalPrice: total
            }, (e) => { this.chargesAndTaxes() });
      }

    onBlur = (event, k) => {
        let ItemMilestone = [...this.state.ItemMilestone]
        let { value, name } = event.target
        ItemMilestone[k][name] = value
        if (Number(event.target.value) < event.target.min || Number(event.target.value) > event.target.max) {
            ItemMilestone[k][name] = event.target.min
            this.setState({ ItemMilestone: ItemMilestone })
        }
    }

    handleChange = (event) => {
        let { value, name } = event.target
        this.setState({ [name]: value, [`${name}Error`]: "" })
        if (name === "advancePrice" && value !== '') {
            if (this.state.tempPrice !== '') {
                let total = parseInt(this.state.tempPrice) + parseInt(value)
                this.setState({ finalPrice: total }, (e) => { this.chargesAndTaxes() })
            }
        }
        else if (name === "advancePrice" && value === '') {
            this.setState({
                finalPrice: parseInt(this.state.tempPrice)
            }, (e) => { this.chargesAndTaxes() })
        }
    }

    AddMilestone = (e) => {
        e.preventDefault();
        let ItemMilestone = [...this.state.ItemMilestone, {
            name: '',
            due_date: '',
            price: '',
            milestoneNameError: '',
            dateError: '',
            priceError: '',
            iteration: 1
        }]
        this.setState({ ItemMilestone })
    }

    removeMilestone = (event, k) => {
        let ItemMilestone = [...this.state.ItemMilestone]
        ItemMilestone.splice(k, 1);
        this.setState({ ItemMilestone }, () => this.handleCalculation())
    }

    IncrementItem = (event, k) => {
        let ItemMilestone = [...this.state.ItemMilestone]
        if (this.state.ItemMilestone[k]['iteration'] < 100) {
            if(ItemMilestone[k]['update_status'] === 0) {
                ItemMilestone[k].iteration_new = parseInt(this.state.ItemMilestone[k].iteration_new) + 1;
                this.setState({ ItemMilestone })
            } else {
                ItemMilestone[k].iteration = parseInt(this.state.ItemMilestone[k].iteration) + 1;
                this.setState({ ItemMilestone })
            }
        }
    }

    DecreaseItem = (event, k) => {
        let ItemMilestone = [...this.state.ItemMilestone]
        if (this.state.ItemMilestone[k].iteration > 1) {
            if(ItemMilestone[k]['update_status'] === 0) {
                ItemMilestone[k].iteration_new = parseInt(this.state.ItemMilestone[k].iteration_new) - 1;
                this.setState({ ItemMilestone })
            } else {
                ItemMilestone[k].iteration = parseInt(this.state.ItemMilestone[k].iteration) - 1;
                this.setState({ ItemMilestone })
            }
        }
    }

    handleValidation() {
        let formError = []
        let formIsnotValid = false
        let ItemMilestone = [...this.state.ItemMilestone]
        if (ItemMilestone.length === 0) {
            alert("Please enter atleast one milestone")
            return;
        } 
        ItemMilestone.forEach((e, i) => {
            if ((e.due_date === "" || e.due_date === undefined) && e.type !== "token") {
                e.dateError = "Please enter a date"
                formError.push(true)
            }
            if (e.name === "" && e.type !== "token") {
                e.milestoneNameError = "Please enter a name"
                formError.push(true)
            }
            if (e.price === "") {
                e.priceError = "Please enter  Price"
                formError.push(true)
            }
            if (e.iteration === "" || isNaN(e.iteration)) {
                e.iterationError = "Please select one iteration"
                formError.push(true)
            }
        })
        if (this.state.advancePrice === '') {
            this.setState({ advancePriceError: "Please enter  Price" })
            formError.push(true)
        }
        this.setState({ ItemMilestone })
        formIsnotValid = formError.includes(true)
        return formIsnotValid
    }

    
  handleSubmit = () => {
    const { advancePrice, taxes, convenienceCharge, AmountPayable, finalPrice, notes, ItemMilestone,ItemMilestoneCopy, proposal_status, proposal_id } = this.state;
    let milestoneData = [];
    // if(!ItemMilestone.some(milestone => milestone.type === 'token' )){
      // console.log('IT HAS NO TOKEN AMOUNT')
      milestoneData = [{
        name: "",
        price: advancePrice,
        due_date: "",
        iteration: 0,
        type: "token"
      }];
    // }
    
    ItemMilestone.forEach((data, key) => {
      if(!data.type){
          milestoneData.push({
            name: data.name,
            price: data.price,
            due_date: data.due_date,
            iteration: data.iteration,
            type: "milestone",
          })
      } else if(data.type !== 'token'){
        milestoneData.push({
          name: data.name,
          price: data.price,
          due_date: data.due_date,
          iteration: data.iteration,
          type: "milestone",
        })
      }
    }) 
    // return

    // console.log(proposal_status);
    // console.log(milestoneData);
    // return false;
    const formNotValid = this.handleValidation()
    let current_location = window.location.pathname.split('/').pop().toLowerCase();
    if (!formNotValid) {
      if (proposal_status === 1) {
        let newMilestoneData = []
        let newMilestoneList = ItemMilestone.filter(item => !item._id);
        let oldMilestoneList = ItemMilestone.filter(item => item.isChanged && item._id);
        let deletedMilestone = ItemMilestoneCopy.filter(({ _id: id1 }) => !ItemMilestone.some(({ _id: id2 }) => id2 === id1));
        newMilestoneList.forEach((data, key) => {
          newMilestoneData.push({
            name: data.name,
            price: data.price,
            due_date: data.due_date,
            iteration: data.iteration,
            type: "milestone",
          })
        })
        let updateProposalData = {
          proposal_id: proposal_id,
          tax: taxes,
          charges: convenienceCharge,
          total_cost: AmountPayable,
          total_earning: finalPrice,
          note: notes,
          milestone_existing:(oldMilestoneList.length > 0) ? oldMilestoneList : "",
          milestone_new: (newMilestoneData.length > 0) ? newMilestoneData : "",
          milestone_delete: (deletedMilestone.length > 0) ?  deletedMilestone : "",
        }
        putAPI('/milestone/update-milestone', updateProposalData).then((res) => {
          if (res.data.status === "1") {
            let projectId = this.props.ProjectId;
            let projectName = this.props.ProjectName;
            let ProjectStatus = this.props.ProjectStatus;
            // this.props.loadChat(projectId, projectName,projectStatus);
            this.props.loadChatAndLatestProposals(projectId,projectName,ProjectStatus)

            //this.props.loadChatMessages(this.props.CurrentChat);
            alert("Changes submitted successfully");
            this.props.closePopup();
          }
        }).catch((error) => {
          console.log("user-company api error------->", error)
        })
      } else {
        
        let submitProposalData = {
          post_project_id: this.props.ProjectId ,
          tax: taxes,
          charges: convenienceCharge,
          total_cost: AmountPayable,
          total_earning: finalPrice,
          note: notes,
          milestone: milestoneData,
        }
 
        // return
        postAPI('/milestone/add-proposal', submitProposalData).then((res) => {
          if (res.data.status === "1") {
            if(current_location === 'chat'){
              this.props.loadChatMessages(this.props.CurrentChat);
              const { projectName } = this.state
              const { ProjectStatus } = this.props;
              const projectId = (current_location === "chat") ? this.props.ProjectId : this.props.post_project_id;

              this.props.loadChatAndLatestProposals(projectId,projectName,ProjectStatus)
              alert("Changes submitted successfully");
              this.props.closePopup();
            } else {
              this.props.history.push({
                pathname: '/chat', state: {
                  post_project_id: this.props.post_project_id,
                  project_name: this.props.project_name,
                  project_status: this.props.ProjectStatus,
                }
              })
              this.props.closePopup()
            }
          }
        }).catch((error) => {
          console.log("user-company api error------->", error)
        })
        
      }
    }
    else {
      alert("Please fill all the required fields")
    }
  }
    render() {
        // let current_location = window.location.pathname.split('/').pop().toLowerCase();
        const { ItemMilestone, advancePrice, notes, finalPrice, advancePriceError, convenienceCharge, taxes, AmountPayable, } = this.state
        let ProjectName = this.props.ProjectName ;
        const active_milestones = ItemMilestone.find((element) => { return element.status === 1})?._id;

        return (
            <>
                <div className="modal fade custom_form review_milestone_modal show" style={{ paddingRight: "0px !imortant", display: "block" }} id="viewProposalModal" tabIndex="-1" role="dialog" ariaLabelledBy="exampleModalCenterTitle" aria-hidden="true">
                    <span className="close_icon recommendation_modal_icon" data-dismiss="modal" aria-label="Close">
                        <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
                    </span>
                    <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs ipad-screen-650" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row m-0">
                                    <div className="col-sm-12 p-0">
                                        <div className="profile-creator dflex p-4 mob-p-15">
                                            <div>
                                                <div className="d-flex w-100">
                                                    <div className="pt-15">
                                                        <i className="far fa-calendar-alt font-20 text-white"></i>
                                                    </div>
                                                    <div className="pl-3">
                                                        <span className="text-white font-13 mob-font-12 font-weight-500">{ProjectName}</span>
                                                        <h2 className="font-weight-500 m-0 font-18 mob-font-16 mob-only-text-left line-height-14">Project Proposal</h2>
                                                        {/* <span className="font-13 text-sub-3">shared by karan chowdhary</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-hide hidden">
                                                <button type="button" className="main-btn font-12 mob-w-100 cursor-pointer">Approve and pay</button>
                                                <button type="button" className="main-btn bg-light-gray font-12 mob-w-100 cursor-pointer hidden"> View previous version</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- proposal version history --> */}
                                </div>
                                <div className="row m-0">
                                    <div className="col-sm-12 col-md-12 col-lg-12 p-0 dont-like overflow-content chat-modal-height">
                                        {/* <!-- Creator Section --> */}
                                        <div className="card-wrapper mb-0 mobile-hide">
                                            <div className="custom_form p-3">
                                                <div className="custom_table">
                                                    <div className="row-border pb-3">
                                                        <div className="row m-0">
                                                            <div className="col-sm-1 text-center">
                                                                <span className="text-sub font-weight-500">#</span>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <span className="text-sub font-weight-500">Milestone</span>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <span className="text-sub font-weight-500">Due Date</span>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <span className="text-sub font-weight-500">Pricing</span>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <span className="text-sub font-weight-500">No. of Iterations</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`${ItemMilestone.length ? 'row-border' : ''} py-3 creator-proposal`}>
                                                        <div className="row m-0 align-items-center disabled">
                                                            <div className="col-sm-1 text-center">
                                                                <span className="text-sub-3"><i className="fas fa-info advance_info"></i></span>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <div>
                                                                    <h3 className="text-dark-black mb-0 font-14 font-weight-600">Project - Advance Fees</h3>
                                                                    <p className="mb-0 text-third">
                                                                        <span className="font-10 font-weight-600">Recommendation: 10-20% of total project value</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2 pr-0">
                                                                <div className="custom_form">
                                                                    <input type="text" className="form-control email input-chat-field" disabled id="exampleInputImmediately" placeholder="Immediately" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2 pr-0">
                                                                <div className="custom_form dflex">
                                                                    <input type="number" value={advancePrice} name="advancePrice" onChange={this.handleChange} className={`form-control w-100 email input-chat-field ${advancePriceError ? 'border-error' : ''}`} id="exampleInputPrice" placeholder="Price" disabled/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2 position-relative iteration_div">
                                                                <div className="input-group active">
                                                                    <div className="input-group-prepend" disabled >
                                                                        <span className="input-group-text input-text-left font-12">
                                                                            <i className="fas fa-minus"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type="number" name='iteration' min="1" max="100"
                                                                        disabled
                                                                        className={`form-control email pl-2 text-center`}
                                                                    />
                                                                    <div className="input-group-append" disabled >
                                                                        <span className="input-group-text input-text-right font-12">
                                                                            <i className="fas fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                    {advancePriceError && <span className="text-danger font-weight-600 font-12 py-1">{advancePriceError}</span>}
                                                                    {/* <span className="remove-icon"><i className="fas fa-times font-16 text-danger"></i></span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {ItemMilestone.map((data, k) => {
                                                        let previousMilestoneDate = k > 0 && ItemMilestone[k-1].due_date
                                                        let minDate = k > 1 
                                                          ? previousMilestoneDate 
                                                            ? new Date(previousMilestoneDate) 
                                                            : new Date()
                                                          : new Date()
                                                        minDate.setDate(minDate.getDate() + 1);
                                                        // k >= 1 ? new Date(ItemMilestone[k-1].due_date) : new Date()

                                                        return (
                                                        (data.type !== "token") ?
                                                            <div key={k} className={`${k === ItemMilestone.length - 1 ? '' : 'row-border'} py-3 creator-proposal`}>
                                                                <div className={`row m-0 align-items-center ${data.status === 2 ? 'disabled' : ''}`}>
                                                                    <div className="col-sm-1 text-center">
                                                                        <span className="text-sub-7 font-weight-500">{k + 1}</span>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <div className="custom_form">
                                                                            <input type="text" name="name" value={data.update_status === 0 ? data.name_new : data.name} onChange={(e) => this.handleOnChange(e, k)} className={`form-control email input-chat-field ${data.milestoneNameError ? 'border-error' : ''}`} id="exampleInputEmailk" placeholder="Milestone Name Eg: Rough Sketches" disabled={data.status === 2 ? true : false} />
                                                                            {data.milestoneNameError && <span className="text-danger font-weight-600 font-12 py-1">{data.milestoneNameError}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2 pr-0">
                                                                        <div className="custom_form position-relative">
                                                                            <DatePicker
                                                                                onChange={
                                                                                    (e) => { this.handleDateOnChange(e, k) }
                                                                                }
                                                                                value={(data.due_date || data.due_date_new) ? new Date(data.update_status === 0 && data.due_date_new ? data.due_date_new : data.due_date) : ''}
                                                                                className={`form-control email ${data.dateError ? 'border-error' : ''}`}
                                                                                clearIcon={null}
                                                                                format="dd-MM-yy"
                                                                                calendarClassName="apniClass"
                                                                                showLeadingZeros={true}
                                                                                disabled={data.status === 2 ? true : false}
                                                                                minDate={minDate}
                                                                                
                                                                            />
                                                                            
                                                                            {data.dateError && <span className="text-danger font-weight-600 font-12 py-1">{data.dateError}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2 pr-0">
                                                                        <div className="custom_form position-relative">
                                                                            <input type="number" name="price" value={data.update_status === 0 ? data.price_new : data.price} onChange={(e) => this.handleOnChange(e, k)} className={`form-control w-100 email input-chat-field ${data.priceError ? 'border-error' : ''}`} id="exampleInputEmail1" placeholder="Price" disabled={data.status === 2 ? true : false}/>
                                                                            {data.priceError && <span className="text-danger font-weight-600 font-12 py-1">{data.priceError}</span>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-2 position-relative iteration_div">
                                                                        <div className={`input-group ${data.status === 2 ? "active" : ""}`}>
                                                                            <div className="input-group-prepend" onClick={(e) => this.DecreaseItem(e, k)}  disabled={data.status === 2 ? true : false}>
                                                                                <span className="input-group-text input-text-left font-12">
                                                                                    <i className="fas fa-minus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input
                                                                                type="number" name='iteration' min="1" max="100"
                                                                                placeholder="3"
                                                                                className={`form-control email pl-2 text-center ${data.iterationError ? 'border-error' : ''}`}
                                                                                disabled={data.status === 2 ? true : false}
                                                                                value={data.update_status === 0 ? data.iteration_new : data.iteration} onChange={(e) => this.handleOnChange(e, k)} onBlur={(e) => this.onBlur(e, k)}
                                                                            />
                                                                            <div className="input-group-append" onClick={(e) => this.IncrementItem(e, k)}  disabled={data.status === 2 ? true : false}>
                                                                                <span className="input-group-text input-text-right font-12">
                                                                                    <i className="fas fa-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <span className={`remove-icon ${data.status === 2 ||  data._id === active_milestones ? 'hidden' : ''} `} onClick={(e) => this.removeMilestone(e, k)}><i className="fas fa-times font-16 text-danger"></i></span>
                                                                            {data.iterationError && <span className="text-danger font-weight-600 font-12 py-1">{data.iterationError}</span>}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div> : <></>
                                                    )})}
                                                    
                                                    <div className="w-100 dflex add_milestone">
                                                        <a href="/" className="font-13 mob-font-12 font-weight-bold letter-spacing-02 text-center" onClick={this.AddMilestone}><i className="fas fa-plus mr-1"></i> Add milestone</a>
                                                        <span className="add-collateral-underline"></span>
                                                     
                                                    </div>
                                                    <div className="row-border row m-0 py-3">
                                                        <div className="col-sm-8 offset-sm-4">
                                                            <div className="card-wrapper mb-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="w-60 text-right">
                                                                        <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                                        Platform & Payment Gateway Charges
													          </h2>
                                                                    </div>
                                                                    <div className="w-40 pl-5">
                                                                        <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>{!isNaN(convenienceCharge) || convenienceCharge !== undefined ? convenienceCharge : 0}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-wrapper mb-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="w-60 text-right">
                                                                        <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                                                        G.S.T @ 18%
													          </h2>
                                                                    </div>
                                                                    <div className="w-40 pl-5">
                                                                        <h2 className="font-13 font-weight-500 text-third"><i className="fas fa-rupee-sign"></i>{!isNaN(taxes) || taxes !== undefined ? taxes : 0}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-wrapper mb-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="w-60 text-right">
                                                                        <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                                                        Total Project Cost for Client
													          </h2>
                                                                    </div>
                                                                    <div className="w-40 pl-5">
                                                                        <h2 className="font-16 font-weight-700 text-dark-black"><i className="fas fa-rupee-sign"></i>{AmountPayable}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row m-0">
                                                        <div className="col-sm-12 pt-2">
                                                            <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block mob-font-12">Additional Notes for Client</span>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="d-flex align-items-center">
                                                                <textarea value={notes} name="notes" onChange={this.handleChange} className="form-control w-100 email" id="comment" placeholder="Please add any additional notes, points or disclaimers here"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0 mobile-show">
                                            {/* New proposal and old version mobile */}
                                            <div className="col-sm-12 message-folder mobile-tabs-milestone py-3">
                                                <div className="wrapper-form p-1">
                                                    <div className="row m-0 align-items-center">
                                                        <div className="col-6 col-sm-6 p-0"><a href="/" onClick={e => e.preventDefault()} className="tabs-folder py-8 font-weight-500 active">New Proposal</a></div>
                                                        <div className="col-6 col-sm-6 p-0"><a href="/" onClick={e => e.preventDefault()} className="tabs-folder py-8 font-weight-500">Older versions</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12 dont-like overflow-content chat-modal-height new-proposal-height">
                                                <p className="mb-0 py-3 font-16 small-font-14 font-weight-600">
                                                    <i className="fas fa-info-circle text-dark-black font-16 small-font-14" /> Advance one-time non-refundable fee
                        </p>
                                                <div className="wrapper-form p-4 mb-4 mob-p-15">
                                                    <form>
                                                        <div className="row m-0">
                                                            <div className="form-group mb-3 col-12 p-0">
                                                                <label className="label font-weight-600" htmlFor="first">Due date</label>
                                                                <input type="text" className="form-control email pl-3 bg-tranparent" placeholder="Immediately" />
                                                                <span className="pick-a-date set-quote"><i className="far fa-calendar-alt font-20 text-third" /></span>
                                                            </div>
                                                            <div className="form-group mb-3 col-12 p-0">
                                                                <label className="label font-weight-600" htmlFor="first">Cost</label>
                                                                <input type="text" className="form-control email pl-3" placeholder="Enter cost here" value={advancePrice} name="advancePrice" onChange={this.handleChange} />
                                                                <span className="text-third font-13 pt-2 d-block line-height-14 font-weight-500">Recommended to maintain a minimum of 10% of total project size.</span>
                                                            </div>
                                                        </div>
                                                        <button type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer">Save</button>
                                                    </form>
                                                </div>
                                                <div className="border-bottom-2 my-30 mob-my-20" />
                                                {ItemMilestone.map((data, k) => (
                                                    (data.type === "token") ?
                                                        <div key={k} className="form-group mb-4 col-12 p-0 disabled">
                                                            <label className="label font-weight-600 font-14 text-dark-black" htmlFor="first">Advance one-time non-refundable fee</label>
                                                            <div className="card wrapper-form">
                                                                <div className="card-body">
                                                                    <div className="row m-0 py-3 px-2 mob-pr-0 mob-pl-0">
                                                                        <div className="col-9">
                                                                            {/* <h2 className=" text-capitalize text-dark-black font-16 mb-1 font-weight-600">Logo design iterations</h2> */}
                                                                            <div className="form-group mb-0 pt-1">
                                                                                <span className="d-block mb-1 text-third font-weight-500 font-14"><i className="far fa-calendar-alt font-16 text-third mr-1" /> Immediately</span>
                                                                                <span className="d-block text-third font-weight-500 font-14"><i className="far fa-closed-captioning font-16 mr-1" /> <i className="fas fa-rupee-sign font-12" />{data.price}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div key={k} className="form-group mb-4 col-12 p-0">
                                                            <label className="label font-weight-600 font-14 text-dark-black" htmlFor="first">Milestone {data.serial_no}</label>
                                                            <div className="card wrapper-form">
                                                                <div className="card-body">
                                                                    <div className="row m-0 py-3 px-2 mob-pr-0 mob-pl-0">
                                                                        <div className="col-9">
                                                                            <h2 className=" text-capitalize text-dark-black font-16 mb-1 font-weight-600">{data.name}</h2>
                                                                            <div className="form-group mb-0 pt-1">
                                                                                <span className="d-block mb-1 text-third font-weight-500 font-14"><i className="far fa-calendar-alt font-16 text-third mr-1" /> {getDateTime(data.due_date, "dateMonth")}</span>
                                                                                <span className="d-block text-third font-weight-500 font-14"><i className="far fa-closed-captioning font-16 mr-1" /> <i className="fas fa-rupee-sign font-12" />{data.price}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3 tabs-icon milestone-icon">
                                                                            <div className="text-right">
                                                                                <span className="d-block"><i className="fas fa-times text-light-gray font-20 mob-font-14 pl-3 pb-30" /></span>
                                                                                <span className="d-block"><i className="fas fa-chevron-down text-light-gray font-20 mob-font-14 pl-3 collacteral position-relative" /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                ))}
                                                <div className="border-bottom-2 my-30 mob-my-20" />
                                                <div className="wrapper-form p-4 mb-4 mob-p-15">
                                                    <form method="post" action>
                                                        <div className="row m-0">
                                                            <div className="form-group mb-3 col-12 p-0">
                                                                <label className="label font-weight-600" htmlFor="first">Name milestones</label>
                                                                <input type="text" className="form-control email pl-3" placeholder="Enter details here" />
                                                            </div>
                                                            <div className="form-group mb-3 col-12 p-0">
                                                                <label className="label font-weight-600" htmlFor="first">Due date</label>
                                                                <input type="text" className="form-control email pl-3" placeholder="Pick a date" />
                                                                <span className="pick-a-date set-quote"><i className="far fa-calendar-alt font-20 text-third" /></span>
                                                            </div>
                                                            <div className="form-group mb-3 col-12 p-0">
                                                                <label className="label font-weight-600" htmlFor="first">Cost</label>
                                                                <input type="text" className="form-control email pl-3" placeholder="Enter cost here" />
                                                            </div>
                                                            <button type="button" className="main-btn mob-w-100 font-14 py-12 cursor-pointer">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="w-100 dflex add_milestone pl-0 mb-4">
                                                    <a href="/" onClick={e => e.preventDefault()} className="mob-font-13 small-font-11 font-weight-bold letter-spacing-02 text-center"><i className="fas fa-plus mr-1" /> Add milestone</a>
                                                    <span className="add-collateral-underline" />
                                                </div>
                                                <div className="row m-0">
                                                    <div className="col-sm-12 p-0">
                                                        <span className="font-weight-bold letter-spacing-046 text-sub-6 font-14 pb-1 d-block">Add notes for the client</span>
                                                    </div>
                                                    <div className="col-sm-12 p-0 mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <textarea className="form-control w-100 email" id="comment" value={notes} name="notes" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* mobile Section */}
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer client-cost mobile-ipad-show mob-p-20">
                                                <div className="row m-0 mb-0 align-items-center">
                                                    <div className="col-8 d-flex align-items-center p-0">
                                                        <div className="row w-100">
                                                            <div className="col-6 pr-0">
                                                                <span className="d-block font-12 text-sub font-weight-500">Client's cost</span>
                                                                <span className="font-18 mob-font-14 text-dark-black font-weight-600">
                                                                    <span className="position-relative">
                                                                        <i className="fas fa-rupee-sign font-16 mob-font-13" />130,725
                                    <i className="fas fa-info-circle text-sub-8 font-16 mob-font-12 small-font-10" data-toggle="modal" data-target="#milestonesBreakdown" />
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="col-6 project-delivery-date position-relative pr-0">
                                                                <span className="d-block font-12 text-sub font-weight-500">Your earnings</span>
                                                                <span className="font-18 mob-font-14 text-dark-black font-weight-600 text-upparcase"><i className="fas fa-rupee-sign font-16 mob-font-13" />100,725</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-right p-0">
                                                        <button type="button" className="main-btn font-14 py-12 cursor-pointer small-font-12" onClick={this.handleSubmit}>Submit <i className="fas fa-arrow-right" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--desktop Section --> */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer mobile-hide">
                                        <p className="mb-0 text-sub-3 font-11 hidden">
                                            <i className="fas fa-info-circle"></i>
                                            <span className="text-secondary font-weight-500">IndieFolio charges an additional non-refundable one time payment of 10% of the total project cost.<sup><i className="fas fa-star-of-life font-5"></i></sup>Project quote is inclusive of all taxes and charges. Milestone payment will only be payable when you approve the milestone deliverables.</span>
                                        </p>
                                        <p className="mb-0 text-right d-flex align-items-center justify-content-end">
                                            <span className="earning_info position-relative pr-3 line-height-normal font-16 font-weight-700 text-dark-black letter-spacing-023">Total earnings for you
                                                <span className="tool" data-tip="These are your total earnings for the project after deducting convenience & connection charges. Do note that any applicable taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."><i className="fas fa-info-circle font-13 ml-1"></i></span>
  				                                <span className="d-block line-height-normal text-capitalize font-24 font-weight-700 text-dark-black letter-spacing-05"><i className="fas fa-rupee-sign font-20"></i>{!isNaN(finalPrice) || finalPrice !== undefined ? finalPrice : 0}</span>
                                            </span>
                                            <button type="button" className="main-btn font-14 py-3 mob-w-100 cursor-pointer" onClick={this.handleSubmit}>Submit proposal <i className="fas fa-arrow-right pl-5"></i></button>
                                        </p>
                                    </div>
                                    {/* <!-- mobile Section --> */}
                                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 view-proposal-footer mobile-show mob-p-20">
                    <p className="mb-0">
                      <button type="button" onClick={this.handleSubmit} className="main-btn font-14 py-12 mob-w-100 cursor-pointer" >Approve and pay</button>
                    </p>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.closeModal === true ? "back-modal" : ""}>
                </div>
            </>
        )
    }
}

const mapState = (store) => {
    let creatorProfile = store.creatorProfile;
    let dashboard = store.dashboard;
    let chat = store.chat;
    let postProjectData = store.postProject;
    return {
        closeModal: creatorProfile.closeModal,
        project_name: dashboard.project_name,
        post_project_id: dashboard.post_project_id,
        ProjectName: chat.ProjectName,
        ProjectId: chat.ProjectId,
        ProjectStatus: chat.ProjectStatus,
        CurrentChat: chat.CurrentChat,
        current_proposal_id:postProjectData.current_proposal_id,
    };
};
const mapDispatch = dispatch => ({
    loadChatMessages: (connection_id) => dispatch({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
    closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
});

export default connect(mapState, mapDispatch)(ReviewUpdatedProposal);
