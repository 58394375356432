import React from 'react';
import { connect } from 'react-redux';
import { getAPI, postAPI } from '../../../utils/API';
import ProgressBar from './Progressbar';
// import axios from 'axios';
import { CollateralBaseUrl } from '../../../utils/BaseUrl';
class Services extends React.Component {
    constructor(props) {
        super(props);
        this.props.GetAllServices();
        this.state = {
            Services: [],
            FeaturedServices: [],
            featuredFilteredServices: [],
            post_project_step: "",
            showLength: 6,
            featuredShowLength: 3,
            searchServices: false,
            serviceError: false,
            isLoading: false,
            inActive: "",
            searchStringLength:0,
            currentSearches : []
        }
    }

    componentDidMount = async () => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const collateralName = url.searchParams.get('collateralName');
        const { isHireAgain, hireAgainCreatorId, match, history } = this.props
        
        const { selected } = this.props
        if (!match.params.collateralName||!collateralName) {
            this.props.resetService(!selected);
        }
        if (this.props.projectId) {
            this.props.LoadPostProjectData(this.props.projectId);
        }
        const { showLength } = this.props;
        this.setState({
            showLength: (showLength !== undefined) ? showLength : 6,
        })
        if (!this.props.userName) {
            let keysToRemove = ["CollateralQuestions", "BudgetData", "StyleQuestionData", "DeadlineData", "TypeQuestionData", "UnitData","ProjectName","ProjectType","StyleSkip","TypeSkip"];
            keysToRemove.forEach(
                k => localStorage.removeItem(k)
            )
        }
        // console.log(this.props.hireAgainCreatorId, this.props.isHireAgain) // these data are coming

        // const { location: { state } }  = this.props;
        // const collateralId = state && state.collateralId
        // const collateralName = state && state.collateralName
        // if(collateralId && collateralName){
        //     // this.props.ChooseService(collateralId,collateralName)
        //     // this.props.LoadCollateralQuestions(collateralId);
        //     this.selectService(collateralId,collateralName)

        // }
     await getAPI('/collateral-feature?name=').then((res) => {
            // temp1.filter(obj1 => temp2.some(obj2 => obj2.collateral_id === obj1._id))
            const FeaturedServices = res.data.data;
            if (isHireAgain) {
                getAPI(`/project/get-user-collateral-creator?_id=${hireAgainCreatorId}`)
                .then(res2 => {

                    const creatorCollateralList = res2.data.data;
                    const filteredCollateralList = FeaturedServices.filter(obj1 => creatorCollateralList.some(obj2 => obj2.collateral_id === obj1._id))
                    this.setState({ FeaturedServices:filteredCollateralList, featuredFilteredServices: filteredCollateralList });                })
            }else{

            this.setState({ FeaturedServices, featuredFilteredServices: res.data.data });
            }

        }).catch(error => {
            console.log(error)
        })

        getAPI('/collateral-not-feature?name=').then((res) => {
            // temp1.filter(obj1 => temp2.some(obj2 => obj2.collateral_id === obj1._id))
            const allCollateralList = res.data.data;
            if (isHireAgain) {
                getAPI(`/project/get-user-collateral-creator?_id=${hireAgainCreatorId}`)
                    .then(res2 => {

                        const creatorCollateralList = res2.data.data;
                        const filteredCollateralList = allCollateralList.filter(obj1 => creatorCollateralList.some(obj2 => obj2.collateral_id === obj1._id))
                        this.setState({ Services: filteredCollateralList })
                    })
            } else {
   
                if (match.params.collateralName||collateralName) {
                    let collateralNameFromUrl 
                    if(collateralName){
                        collateralNameFromUrl =collateralName.toLowerCase()
                    }
                    else collateralNameFromUrl = match.params.collateralName.replace(/-/g, ' ').replace(/_/g, '/').toLowerCase()

                    let collateralObj = allCollateralList.find(el => el.name.toLowerCase() === collateralNameFromUrl)
                    if(!collateralObj){
                     collateralObj = this.state.FeaturedServices.find(el => el.name.toLowerCase() === collateralNameFromUrl)

                    }
                    // console.log(collateralName,collateralNameFromUrl,collateralObj,allCollateralList,this.state.FeaturedServices)
                    if (collateralObj) {
                        const selected_services = {
                            collateral_id: collateralObj._id,
                        }
                        this.addPostProject(selected_services)
                        this.props.ChooseService(collateralObj._id, collateralObj.name)
                        this.loadQuestions(collateralObj._id)
                        // this.setInActive()
                        // this.handleContinue()
                    } else {
                        history.push(`${this.props.isHireflow?'/hire':'/self-service-hire'}`)
                    }
                }
                this.setState({ Services: res.data.data, filteredServices: res.data.data })
                // console.log(res.data.data)
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selected !== prevProps.selected || this.props.selected_collateral !== prevProps.selected_collateral) {
            const { AllServices } = this.props;
            const Services = this.props.isHireAgain ? this.state.Services : AllServices
            const { selected_collateral } = this.props;
            let serviceIndex = Services.findIndex(item => item._id === selected_collateral);
            serviceIndex = (serviceIndex % 6 === 0) ? (serviceIndex / 6 + 1) : (serviceIndex / 6);
            let length = Math.ceil(serviceIndex) * 6;
            this.setState({
                showLength: (length < 6) ? 6 : length,
            })
        }
    }

    loadQuestions = (id) => {
        if (this.props.userName) {
            this.props.LoadCollateralQuestions(id);
        } else {
            getAPI('/project/get-collateral-global?_id=' + id).then((res) => {
                if (res.data.status === "1") {
                    localStorage.setItem("CollateralQuestions", JSON.stringify(res.data));
                    var localStorageData = localStorage.getItem('CollateralQuestions');
                    localStorageData = JSON.parse(localStorageData);
                    let id = localStorageData.collateral._id;
                    this.props.LoadCollateralQuestionsGlobal(id);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    setInActive = () => {
        this.setState({
            serviceError: false,
            inActive: 'inActive',
        })
    }


    selectService = (id, name) => {
        const projectId = this.props.projectId;
        var localStorageData = localStorage.getItem('CollateralQuestions');
        localStorageData = JSON.parse(localStorageData);
        let local_id = localStorageData?.collateral._id;
        if (local_id !== id) {
            this.props.resetProject();
        }
        if (id !== this.props.selected_collateral && projectId !== undefined) {
            // this.updateCollateral(id,projectId);
            this.props.resetProject();
        }
        this.props.ChooseService(id, name)
        this.loadQuestions(id)
        this.setInActive()


    }
    isServiceNamethere=(value,array)=>{
        const regex = new RegExp(`${value}*`,'i');
        // console.log('hit',value,array)
        let ismatched= false;
        array.map(item=> {
            const istrue= regex.test(item.name)
              if(istrue){
                ismatched=true
            }
      
           let check = regex.test(item.name) ? "1" : "2"
        //    console.log("check = ",check)
        })
        // console.log(ismatched)
        return ismatched

    }

    searchServices = (event) => {
        // const {AllServices}= this.props;
        const { Services, FeaturedServices } = this.state
        const { value } = event.target;
        // const{showLength} = this.state;

        const search= this.isServiceNamethere
        let featuredServicesList = FeaturedServices.filter(function (items) { return (items.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 ||search(event.target.value,items.alternative_name))});
        let servicesList = Services.filter(function (items) { return (items.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1||search(event.target.value,items.alternative_name))});
        
        var currentSearches = []
        if(value?.length > 0 && !featuredServicesList.length&&!servicesList.length){
          currentSearches.push(value)
          
        }
        
        // console.log("/??????????? search ", FeaturedServices, Services, currentSearches)

        if(event.target.value.length>=this.state.searchStringLength&&!featuredServicesList.length&&!servicesList.length){
            postAPI('/users/create-user-search',{key:event.target.value })
        }
        this.setState({
            featuredFilteredServices: featuredServicesList,
            filteredServices: servicesList,
            searchStringLength:event.target.value.length,
            searchServices: (event.target.value.length > 0) ? true : false,
        });
    }


    updateCollateral = (collateral_id, projectId) => {
        // const update_data = {
        //     collateral_id: collateral_id,
        //     _id: projectId,
        // }
        // console.log(update_data);
        // putAPI('/project/update-post-project',update_data).then((res) => {
        //     // console.log(res);
        // }).catch((error) => {
        //     console.log(error);
        // })
    }

    handleContinue = () => {
        this.setState({ isLoading: true })
        if (!this.props.selected_collateral) {
            this.setState({
                serviceError: true,
                isLoading: false,
            })
            alert("Please select a collateral to continue");
            return;
        }
        const selected_services = {
            collateral_id: this.props.selected_collateral,
            posting_from: localStorage.getItem('prevPath')
        }
        this.addPostProject(selected_services)

    }

    addPostProject = (obj) => {
        if (this.props.alreadyCreatedProject !== true && this.props.userName) {
            postAPI('/project/add-post-project', obj).then((res) => {
                if (res.data.status === "1") {
                    let _id = res.data.data._id;
                    this.props.SelectedService(_id);
                    let element = document.getElementById("postProjectQuestions");
                    let post_steps = 0;
                    let total_steps = element.children.length;
                    this.props.PostProjectSteps(post_steps, total_steps);
                    this.props.LoadPostProjectData(_id);
                    this.props.history.push(`${this.props.isHireflow?'/hire/projectType':'self-service-hire/budget'}`);
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error);
            })
        } else {
            let element = document.getElementById("postProjectQuestions");
            let post_steps = 0;
            let total_steps = element.children.length;
            this.props.PostProjectSteps(post_steps, total_steps);
            this.props.history.push(`${this.props.isHireflow?'/hire/projectType':'self-service-hire/budget'}`);
            this.setState({ isLoading: false })
        }

    }

    handleServicesList = (type) => {
        const { Services, showLength } = this.state;
        if (type === "next") {
            this.setState({
                showLength: (showLength >= Services.length) ? showLength : showLength + 6,
            })
        } else {
            this.setState({
                showLength: (showLength <= 6) ? 6 : showLength - 6,
            })
        }
    }

    handleFeaturedServicesList = (type) => {
        const { FeaturedServices, featuredShowLength } = this.state;
        if (type === "next") {
            this.setState({
                featuredShowLength: (featuredShowLength >= FeaturedServices.length) ? featuredShowLength : featuredShowLength + 3,
            })
        } else {
            this.setState({
                featuredShowLength: (featuredShowLength <= 3) ? 3 : featuredShowLength - 3,
            })
        }
    }

    render() {
        const { Services, showLength, searchServices, serviceError, inActive, filteredServices, FeaturedServices, featuredFilteredServices, featuredShowLength } = this.state;
        const { selected_collateral, isHireAgain } = this.props;
        return (
            <>
                <section>
                    <div className="card-wrapper">
                        <div className="card mob-border-radius-0">
                            <div className="card-body">
                                <div className="row mx-0 mt-1 overflow-content project-height">
                                    <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project">
                                        <h2 className="pt-5 px-4 mob-pt-py font-22 mob-font-16 font-weight-600 ">Select a service</h2>
                                        <div className="custom_form px-4 pb-4 pt-2 mob-p-0 mob-mb-45">
                                            <form method="post" action="" onSubmit={e=> e.preventDefault()}>
                                                <div className="row m-0 align-items-center">
                                                    {!isHireAgain ? <div className="form-group mb-4 col-8 col-sm-4 pl-0 mob-p-0 position-relative">
                                                        <input type="text" id="searchInput" className="form-control email pl-40" placeholder="Search for a service" onChange={this.searchServices} />
                                                        <i className="fas fa-search post-search"></i>
                                                    </div> : null}
                                                    <div className="form-group mb-4 col-4 col-sm-8 text-right pl-0 mob-p-0 position-relative">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            {
                                                                Services && Services.length > 0 ? <div className="service-icon-prev" onClick={(e) => this.handleServicesList("prev")}>
                                                                    <i className="fas fa-chevron-left"></i>
                                                                </div> : <></>
                                                            }
                                                            {Services && Services.length > 0 ?
                                                                <div className="service-icon-next ml-2" onClick={(e) => this.handleServicesList("next")}>
                                                                    <i className="fas fa-chevron-right"></i>
                                                                </div> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* featured collatrol */}

                                                {featuredFilteredServices && featuredFilteredServices.length ? 
                                                <div className="row m-0 position-relative">
                                                    <div className="col-sm-12 p-0">
                                                        <div className="choose-title text-left mb-2 mt-1">
                                                            <h2 className="font-16 font-weight-600 text-dark-gray">Featured</h2>
                                                        </div>
                                                        <div className="row">
                                                            {FeaturedServices && FeaturedServices.length > 0 ?
                                                                <div className="service_desktop_prev" onClick={(e) => this.handleFeaturedServicesList("prev")}>
                                                                    <i className="fas fa-chevron-left"></i>
                                                                </div> : <></>
                                                            }
                                                            {(searchServices === true) ?
                                                                featuredFilteredServices && featuredFilteredServices.length > 0 ?
                                                                    featuredFilteredServices.map((obj, index) => (
                                                                        (index <= 2) ?
                                                                            <div key={index} id={obj._id} className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">
                                                                                <div className="card service-ratio">
                                                                                    <div className={`user-upload-content-work position-relative in_active ${(selected_collateral === obj._id) ? "active" : `${inActive}`}`} style={{ backgroundImage: `url('${CollateralBaseUrl + obj.image_second}')`, backgroundSize: "cover" }} onClick={(e) => { this.selectService(obj._id, obj.name) }} >
                                                                                    <p className="user-upload-content-text text-white font-18 mob-font-14 font-weight-600 mb-2 px-15">{obj.name}</p>
                                                                                    </div>
                                                                                  
                                                                                </div>
                                                                                {/* <ul className="packages-discription px-15 mb-3">
                                                                                    <li>&gt; {obj.about}</li>
                                                                                </ul> */}


                                                                            </div> : <></>
                                                                    )) : <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">
                                                                        <div className="post-project-enquire">
                                                                            <h1>Cannot find what you want?</h1>
                                                                            <a
                                                                                href="https://indiefolio.typeform.com/to/TsIms3YG" target="_blank" rel="noopener noreferrer"><button type="button" className="main-btn mob-font-14">Enquire</button></a>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                FeaturedServices.slice(featuredShowLength - 3, featuredShowLength).map((obj, index) => (
                                                                    <div key={index} id={obj._id}
                                                                        className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">
                                                                        <div className="card service-ratio">
                                                                            <div
                                                                                className={`user-upload-content-work position-relative in_active ${(selected_collateral === obj._id) ? "active" : `${inActive}`}`} style={{ backgroundImage: `url('${CollateralBaseUrl + obj.image_second}')`, backgroundSize: "cover" }}
                                                                                onClick={(e) => { this.selectService(obj._id, obj.name) }} >
                                                                                <p className="user-upload-content-text text-white font-18 mob-font-14 font-weight-600 mb-2 px-15">{obj.name}</p>
                                                                            </div>
                                                                      
                                                                            {/* <ul className="packages-discription px-15 mb-3">
                                                                                <li>&gt; {obj.about}</li>

                                                                            </ul> */}
                                                                           
                                                                        </div>

                                                                    </div>
                                                                ))

                                                            }
                                                            {FeaturedServices && FeaturedServices.length > 0 ?
                                                                <div className="service_desktop_next" onClick={(e) => this.handleFeaturedServicesList("next")}>
                                                                    <i className="fas fa-chevron-right"></i>
                                                                </div> : <></>
                                                            }
                                                        </div>
                                                        {/* {
                                                            (serviceError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select a service.</p> : ""
                                                        } */}
                                                    </div>
                                                </div> : ""}
                                                {/* ********* */}
                                                <div className="row m-0 position-relative">
                                                    <div className="col-sm-12 p-0">
                                                        <div className="choose-title text-left mb-2 mt-1">
                                                            <h2 className="font-16 font-weight-600 text-dark-gray">Popular services</h2>
                                                        </div>
                                                        <div className="row">
                                                            {Services && Services.length > 0 ?
                                                                <div className="service_desktop_prev" onClick={(e) => this.handleServicesList("prev")}>
                                                                    <i className="fas fa-chevron-left"></i>
                                                                </div> : <></>
                                                            }
                                                            {
                                                                (searchServices === true) ?
                                                                    filteredServices && filteredServices.length > 0 ?
                                                                        filteredServices.map((obj, index) => (
                                                                            (index <= 5) ?
                                                                                <div key={index} id={obj._id} className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">

                                                                                    <div className={`user-upload-content-work position-relative in_active ${(selected_collateral === obj._id) ? "active" : `${inActive}`}`} style={{ backgroundImage: `url('${CollateralBaseUrl + obj.image_second}')`, backgroundSize: "cover" }} onClick={(e) => { this.selectService(obj._id, obj.name) }} >
                                                                                        <p className="user-upload-content-text text-white font-18 mob-font-14 font-weight-600 mb-2 px-15">{obj.name}</p>
                                                                                    </div>

                                                                                </div> : <></>
                                                                        )) : <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">
                                                                            <div className="post-project-enquire">
                                                                                <h1>Cannot find what you want?</h1>
                                                                                <a
                                                                                    href="https://indiefolio.typeform.com/to/TsIms3YG" target="_blank" rel="noopener noreferrer"><button type="button" className="main-btn mob-font-14">Enquire</button></a>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    Services.slice(showLength - 6, showLength).map((obj, index) => (
                                                                        <div key={index} id={obj._id} className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4">
                                                                            <div className={`user-upload-content-work position-relative in_active ${(selected_collateral === obj._id) ? "active" : `${inActive}`}`} style={{ backgroundImage: `url('${CollateralBaseUrl + obj.image_second}')`, backgroundSize: "cover" }} onClick={(e) => { this.selectService(obj._id, obj.name) }} >
                                                                                <p className="user-upload-content-text text-white font-18 mob-font-14 font-weight-600 mb-2 px-15">{obj.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))

                                                            }
                                                            {Services && Services.length > 0 ?
                                                                <div className="service_desktop_next" onClick={(e) => this.handleServicesList("next")}>
                                                                    <i className="fas fa-chevron-right"></i>
                                                                </div> : <></>
                                                            }
                                                        </div>
                                                        {
                                                            (serviceError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select a service.</p> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <ProgressBar {...this.props} {...this.state} handleContinue={this.handleContinue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


const mapState = (store) => {
    let postProjectData = store.postProject;
    let globalReducer = store.globalReducer;
    return {
        AllServices: postProjectData.Services,
        selected_collateral: postProjectData.selected_collateral,
        showLength: postProjectData.show_length,
        CollateralQuestions: postProjectData.CollateralQuestions,
        post_steps: postProjectData.post_steps,
        projectId: postProjectData.projectId,
        alreadyCreatedProject: postProjectData.alreadyCreatedProject,
        selected: postProjectData.selected,
        isHireAgain: postProjectData.isHireAgain,
        hireAgainCreatorId: postProjectData.hireAgainCreatorId,

        userName: globalReducer.Me.Username,
    };
}

const mapDispatch = dispatchEvent => ({
    LoadCollateralQuestions: (id) => dispatchEvent({ type: 'LOAD_COLLATERAL_QUESTIONS', payload: id }),
    LoadCollateralQuestionsGlobal: (id) => dispatchEvent({ type: 'LOAD_COLLATERAL_QUESTIONS_GLOBAL', payload: id }),
    GetAllServices: (e) => dispatchEvent({ type: 'GET_ALL_SERVICES', payload: {} }),
    ChooseService: (id, name) => dispatchEvent({ type: 'SELECT_SERVICE', payload: { name: name, id: id, selected: true } }),
    SelectedService: (id) => dispatchEvent({ type: "SELECTED_SERVICE", payload: id }),
    PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
    resetService: (id) => dispatchEvent({ type: "RESET_SERVICE", payload: id }),
    LoadPostProjectData: (id) => dispatchEvent({ type: 'LOAD_POST_PROJECT_DATA', payload: id }),
    resetProject: (values) => dispatchEvent({ type: 'RESET_PROJECT_DATA', payload: values }),
});

export default connect(mapState, mapDispatch)(Services);