import React from 'react';
import { connect } from 'react-redux';
import { BaseImageUrl } from '../../../../utils/BaseUrl';
// import LocationDP from '../LocationDP';
import SingleSelectDP from '../../Common/Common/SingleSelectDP';
// import BtnLoader from '../../Common/Common/BtnLoader'
import Loader from './../../../unauthorized/Common/Loader/Loader';
class BasicDetails extends React.Component {
  onSubmit = (e) => {
    if(this.props.AboutError) {
      return
    }
    // e.preventDefault();
    this.props.UpdateUser({
      bio: this.props.About,
      location: this.props.Location,
      profile_image: this.props.Avatar,
      first_name: this.props.FirstName,
      last_name: this.props.LastName,
      primary_specialization: this.props.PrimaryFieldWork,
    });
    // console.log('close popup');
    

    // close popup after saving
    // if (!this.props.saving) {
    //   this.closePopup();
    // }
  };

  closePopup = () => {
    this.props.onDPChange({
      name: 'CREATORBASIC',
      value: {
        About: this.props.ResetData.About,
        Location: this.props.ResetData.Location,
        Avatar: this.props.ResetData.Avatar,
        FirstName: this.props.ResetData.FirstName,
        LastName: this.props.ResetData.LastName,
        PrimaryFieldWork: this.props.ResetData.PrimaryFieldWork,
      },
    });
    this.props.closePopup();
  };

  reloadSpecilization = (value) => {
    this.props.loadSpecialization(value);
  };

  reloadLocation = (value) => {
    this.props.loadLocation(value);
  };

  changeImageHandler = (e) => {
    const fileSize = e.target.files[0].size;
    if(fileSize > 1000 * 1000 * 2){
      alert('File size exceeds 2 MB')
      return;
    }
    this.props.uploadUserImage(e)
  }

  render() {
    let {
      FirstNameError,
      // FirstNameErrorMessage,
      LastNameError,
      // LastNameErrorMessage,
      // AvatarError,
      // AvatarErrorMessage,
      AboutError,
      AboutErrorMessage,
      LocationError,
      // LocationErrorMessage,
      PrimarySpecializationError,
      // PrimarySpecializationErrorMessage,
      saving,
      uploading,
      Avatar,
    } = this.props;

   

    return (
      <>
        <div
          className="modal fade custom_form verify_modal edit-basic-profile  show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditProfileModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Basic Details</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 overflow-content basic-height mid_height">
                    <div className="profile-user mob-profile-user pX_pY_30 d-flex align-items-center">
                      <div className="basic_profile">
                        {!Avatar || Avatar === '' ? (
                          <img
                            src={require('../../../../Assets/images/user-default.png')}
                            alt="profile"
                            title=""
                          />
                        ) : (
                          <img
                            src={BaseImageUrl + this.props.Avatar}
                            alt="profile"
                            title=""
                          />
                        )}
                      </div>
                      <div className="pl-3">
                        <input
                          type="file"
                          ref={(ref) => (this.upload = ref)}
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => this.changeImageHandler(e)}
                          style={{ display: 'none' }}
                        />
                        <button
                          onClick={() => {
                            this.upload.click();
                          }}
                          type="button"
                          className="main-btn bg-light-gray mob-font-13 font-weight-600"
                          disabled={uploading}
                        >
                          {uploading === true
                            ? 'Uploading...'
                            : 'Update profile picture'}
                        </button>
                      </div>
                    </div>
                    <div className="custom_form">
                      <form onSubmit={this.onSubmit}>
                        <div className="row m-0">
                          <div className="form-group mb-4 col-sm-6">
                            <label
                              className="label"
                              htmlFor="first"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="BASIC_FIRSTNAME"
                              onChange={(e) => this.props.onFieldChange(e)}
                              className={`form-control email ${
                                FirstNameError === true ? 'border-error' : ''
                              }`}
                              value={this.props.FirstName}
                              placeholder="Bojan"
                            />
                            {FirstNameError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add first name to continue
                              </span>
                            )}
                            {FirstNameError === true ? (
                              <></> //<span className="font-11 font-weight-400 text-danger">{FirstNameErrorMessage}</span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="form-group w-98 mb-4 col-sm-6">
                            <label
                              className="label"
                              htmlFor="last"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="BASIC_LASTNAME"
                              onChange={(e) => this.props.onFieldChange(e)}
                              className={`form-control email ${
                                LastNameError === true ? 'border-error' : ''
                              }`}
                              value={this.props.LastName}
                              placeholder="Joncic"
                            />
                            {LastNameError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add last name to continue
                              </span>
                            )}
                            {LastNameError === true ? (
                              <></> //<span className="font-11 font-weight-400 text-danger">{LastNameErrorMessage}</span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="form-group position-relative mb-4 col-sm-6">
                            <div className="col-sm-12 p-0 mob-p-0 mob-w-100">
                              <label
                                className="label"
                                htmlFor="last"
                              >
                                What's your primary field of work?
                              </label>{' '}
                              <br />
                              <SingleSelectDP
                                Selected={this.props.PrimaryFieldWork}
                                List={this.props.Specialization}
                                onChange={(e) => {
                                  this.reloadSpecilization(e);
                                }}
                                onRemove={(e) => {
                                  this.props.onDPChange({
                                    name: 'BASIC_PRIMARYFIELDWORK',
                                    value: '',
                                  });
                                }}
                                infoText="Select atleast 1 specilization"
                                onSelect={(e) => {
                                  this.props.onDPChange({
                                    name: 'BASIC_PRIMARYFIELDWORK',
                                    value: e,
                                  });
                                }}
                                notListed={this.props.PrimaryFieldWork}
                                error={PrimarySpecializationError}
                              />
                              {PrimarySpecializationError && (
                                <span className="text-danger font-weight-600 font-12 py-1">
                                  Add primary field to continue
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group position-relative mb-4 col-sm-6 edit-location">
                            <label
                              className="label"
                              htmlFor="primary"
                            >
                              Location
                            </label>
                            <SingleSelectDP
                              width={'w-93'}
                              Selected={this.props.Location}
                              List={this.props.Locations}
                              onChange={(e) => {
                                this.reloadLocation(e);
                              }}
                              onRemove={(e) => {
                                this.props.onDPChange({
                                  name: 'BASIC_LOCATION',
                                  value: '',
                                });
                              }}
                              infoText="Select upto 1 location"
                              onSelect={(e) => {
                                this.props.onDPChange({
                                  name: 'BASIC_LOCATION',
                                  value: e,
                                });
                              }}
                              notListed={this.props.Location}
                              error={LocationError}
                            />
                            {LocationError && (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add location to continue
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-4 col-sm-12">
                            <label
                              className="label"
                              htmlFor="first"
                            >
                              About
                            </label>
                            <textarea
                              name="BASIC_ABOUT"
                              onChange={(e) => this.props.onAboutChange(e)}
                              className={`form-control email min-h-60 ${
                                AboutError === true ? 'border-error' : ''
                              }`}
                              value={this.props.About}
                              id="comment"
                            ></textarea>
                            {AboutError === true ? (
                              <span className="font-11 font-weight-400 text-danger">{AboutErrorMessage}</span>
                            ) : (
                              <></>
                            )}
                            {/* {(AboutError) && 
                              <span className="text-danger font-weight-600 font-12 py-1">
                                Add about to continue
                              </span>
                            } */}
                            {/* {(AboutError && this.props.About.length >= 300) && 
                              <span className="text-danger font-weight-600 font-12 py-1">
                                About must be in 300 characters
                              </span>
                            } */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group text-right pr-3 pt-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        data-dismiss="modal"
                        aria-label="Close"
                        className={`main-btn bg-danger col-sm-2 col-5 mx-2 mb-2 font-weight-600 cursor-pointer`}
                      >
                        Close
                      </button>
                      {saving === true ? (
                        <button
                          type="submit"
                          className={`main-btn col-sm-3 col-6 mx-2 mb-2 ${saving ? 'p-0' : ''}`}
                          disabled
                        >
                        <Loader profileCreator="true"/>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          // data-dismiss="modal"
                          onClick={() => this.onSubmit()}
                          className="main-btn col-sm-3 col-6 mx-2 mb-2"
                        >
                          Save Changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let BasicDetails = creatorProfile.BasicDetails;

  return {
    saving: globalReducer.saving,
    uploading: globalReducer.uploading,
    FirstName: BasicDetails.FirstName,
    LastName: BasicDetails.LastName,
    Avatar: BasicDetails.Avatar,
    About: BasicDetails.About,
    Location: BasicDetails.Location,
    PrimaryFieldWork: BasicDetails.PrimaryFieldWork,

    FirstNameError: BasicDetails.FirstNameError,
    FirstNameErrorMessage: BasicDetails.FirstNameErrorMessage,
    LastNameError: BasicDetails.LastNameError,
    LastNameErrorMessage: BasicDetails.LastNameErrorMessage,
    AvatarError: BasicDetails.AvatarError,
    AvatarErrorMessage: BasicDetails.AvatarErrorMessage,
    AboutError: BasicDetails.AboutError,
    AboutErrorMessage: BasicDetails.AboutErrorMessage,
    LocationError: BasicDetails.LocationError,
    LocationErrorMessage: BasicDetails.LocationErrorMessage,
    PrimarySpecializationError: BasicDetails.PrimarySpecializationError,
    PrimarySpecializationErrorMessage:
      BasicDetails.PrimarySpecializationErrorMessage,

    Specialization: creatorProfile.Specialization,
    Locations: creatorProfile.Locations,
    closeModal: creatorProfile.closeModal,
    ResetData: {
      FirstName: creatorProfile.FirstName,
      LastName: creatorProfile.LastName,
      Avatar: creatorProfile.Avatar,
      Location: creatorProfile.Location,
      About: creatorProfile.About,
      PrimaryFieldWork: creatorProfile.PrimaryFieldWork,
    },
  };
};
const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.target.name, value: e.target.value },
    }),
  onAboutChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.target.name, value: e.target.value.substring(0,500) },
    }),
  onDPChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  uploadUserImage: (e) =>
    dispatch({ type: 'UPLOAD_USERIMAGE', payload: { file: e } }),
  UpdateUser: (e) => dispatch({ type: 'UPDATE_USER', payload: e }),
  loadSpecialization: (e) =>
    dispatch({ type: 'LOAD_CREATOR_SPECIALIZATION_SAGA', payload: e }),
  loadLocation: (e) =>
    dispatch({ type: 'LOAD_CREATOR_LOCATION_SAGA', payload: e }),
});
export default connect(mapState, mapDispatch)(BasicDetails);
