import {
  GET_PROJECT_DATA,
  EDIT_USER_PROJECT,
  RESET_SINGLE_PROJECT_DATA,
} from "../action/projectView.action";

const initState = {
  About: '',
  Title: '',
  Role: '',
  EndMonth: '',
  EndYear: '',
  Tools: [],
  Images: [],
  Keywords: [],
  Project_data_error:false
};

const reducers = (state = initState, { type, values, error }) => {
  // let newState = {};

  switch (type) {
    case GET_PROJECT_DATA:
      let blank_images        = values.project.images_or_links.filter((item) => item.value === "");
      let Images              = values.project.images_or_links.filter((item) => item.value !== "");
      Images                  = (blank_images) ? Images.concat(blank_images) : Images;

      return {
        ...state,
        Project_data_error:false,
        _id:values.project._id,
        About: values.project.about,
        Title: values.project.title,
        collaboration: values.project.collaboration,
        Role: values.project.role,
        EndMonth: values.project.end_month,
        EndYear: values.project.end_year,
        Keywords: values.project.keywords,
        Tools: values.project.tools,
        Images: Images,
        userData: values.project_user_data,
        Collateral: values.projectCollateral,
        StyleQuestions: values.questionStyle,
        TypeQuestions: values.questionType,
        created_type: values.project.created_type,
        company_name: values.project.company_name,
        dataReview:values.dataReview,
    }
    case 'PROJECT_DATA_ERROR':
      return {
        ...state,
        Project_data_error:values
      }
    case EDIT_USER_PROJECT: 
      return {
        ...state,
        projectId:values,
        edit:1,
      }

    case RESET_SINGLE_PROJECT_DATA:
      return {
        About: '',
        Title: '',
        Role: '',
        EndMonth: '',
        EndYear: '',
        Tools: [],
        Images: [],
        Keywords: [],
      }

    default:
      return state;
  }
};

export default reducers;