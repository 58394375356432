import React from 'react'
import { BaseImageUrl } from '../../../../../../utils/BaseUrl'

export default function CreatorDetailsCard({data}) {
    return (
        <div className="card-wrapper mt-5">
            <div className="card p-2">
                <div className="card-body">
                <div className="w-100 d-flex align-items-center">
                   {data.profile_image && <div>
                    <p className="mb-0 model-profile">
                        <img src={BaseImageUrl + data.profile_image} alt="profile" title="true" width={80} />
                    </p>
                    </div>}
                    <div className="w-65 overflow-hidden pl-2">
                    <span className="font-12 text-dark-gray font-weight-600">THE CREATOR</span>
                    <h2 className="text-capitalize mb-1 font-16 font-weight-600 mob-font-16">
                        {data.first_name} {data.last_name}
                    </h2>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
