import React, { Component } from 'react';
import { CollateralBaseUrl } from '../../../../../utils/BaseUrl';

export default class StyleAndTypeQuestions extends Component {
  render() {
    const {ques, quesType} = this.props
    return (
        <>
        {ques.length > 0 && <div className="Project-duration border-bottom mb-2">
                <span className="sub-design text_light_gray_md">{quesType === 'style' ? 'Style' : 'Type'}</span>
                {ques.map((item,index) => {
                    if(item.type === 'checkbox_with_images_with_text' || item.type === 'radio_button_with_images_with_text'){ 
                        return (
                            <div key={index} className="onboarding_style_questions">
                                <ul className="content p-0">
                                    {item.answer.map((ans, i) => 
                                    <li key={i}>
                                        <div className={`deadline position-relative cursor-default`} style={{backgroundSize:'cover', backgroundImage:`url("${CollateralBaseUrl + ans.image}")`}}>
                                            {/* <img src={CollateralBaseUrl + ans.image} alt='' className="img-size" /> */} 
                                        </div>
                                        <p className="style_name">{ans.item.length > 25 ? ans.item.substring(0,25) + '...' : ans.item}</p>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        )
                    } if(item.type === 'dropdown'){
                        return(
                            <ul key={index} className="dflex justify-content-start mb-3">
                                {item.answer.map((ans, i) => 
                                    <p key={i} className="font-16 mob-font-18 font-weight-bold mb-0">{ans.item}</p>
                                )}

                            </ul>
                        )
                    } else return null
                })}
                
            </div>}
        </>
    );
  }
}
