import {
  UPDATE_SOCIALPROFILE_indiefolio_url,
  UPDATE_SOCIALPROFILE_behance_url,
  UPDATE_SOCIALPROFILE_dribbble_url,
  UPDATE_SOCIALPROFILE_instagram_url,
  UPDATE_SOCIALPROFILE_vimeo_url,
  UPDATE_SOCIALPROFILE_personal_url,
  UPDATE_SOCIALPROFILE_custom_url,
  UPDATE_SOCIALPROFILE_custom_url1,
  UPDATE_SOCIALPROFILE_custom_url2,
  UPDATE_SOCIALPROFILE_RESUME,
  LOAD_SOCIALPROFILEPOPUP,
  UPDATE_SOCIALPROFILE_indiefolio_url_ERROR,
  UPDATE_SOCIALPROFILE_behance_url_ERROR,
  UPDATE_SOCIALPROFILE_dribbble_url_ERROR,
  UPDATE_SOCIALPROFILE_instagram_url_ERROR,
  UPDATE_SOCIALPROFILE_vimeo_url_ERROR,
  UPDATE_SOCIALPROFILE_personal_url_ERROR,
  UPDATE_SOCIALPROFILE_custom_url_ERROR,
  UPDATE_SOCIALPROFILE_custom_url1_ERROR,
  UPDATE_SOCIALPROFILE_custom_url2_ERROR,
} from '../type.action';

const initState = {
  resume: '',
  behance_url: '',
  createdAt: '',
  custom_url: '',
  dribbble_url: '',
  indiefolio_url: '',
  instagram_url: '',
  personal_url: '',
  vimeo_url: '',
  behance_urlError: false,
  instagram_urlError: false,
  indiefolio_urlError: false,
  dribbble_urlError: false,
  createdAtError: false,
  vimeo_urlError: false,
  custom_urlError: false,
  custom_url1Error: false,
  custom_url2Error: false,
  personal_urlError: false,
};

const reducers = (state = initState, { type, values, error }) => {
  let newState = {};

  switch (type) {
    case UPDATE_SOCIALPROFILE_RESUME:
      // console.log(values);
      newState = Object.assign({}, state, {
        resume: values,
      });
      return newState;
    case LOAD_SOCIALPROFILEPOPUP:
      // console.log(values);
      newState = Object.assign({}, state, values);
      return newState;

    case UPDATE_SOCIALPROFILE_indiefolio_url:
      newState = Object.assign({}, state, {
        indiefolio_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_behance_url:
      newState = Object.assign({}, state, {
        behance_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_dribbble_url:
      newState = Object.assign({}, state, {
        dribbble_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_instagram_url:
      newState = Object.assign({}, state, {
        instagram_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_vimeo_url:
      newState = Object.assign({}, state, {
        vimeo_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_personal_url:
      newState = Object.assign({}, state, {
        personal_url: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_custom_url:
      newState = Object.assign({}, state, {
        custom_url: values,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_custom_url1:
      newState = Object.assign({}, state, {
        custom_url1: values,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_custom_url2:
      newState = Object.assign({}, state, {
        custom_url2: values,
      });
      return newState;

    case UPDATE_SOCIALPROFILE_indiefolio_url_ERROR:
      newState = Object.assign({}, state, {
        indiefolio_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_behance_url_ERROR:
      newState = Object.assign({}, state, {
        behance_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_dribbble_url_ERROR:
      newState = Object.assign({}, state, {
        dribbble_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_instagram_url_ERROR:
      newState = Object.assign({}, state, {
        instagram_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_vimeo_url_ERROR:
      newState = Object.assign({}, state, {
        vimeo_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_personal_url_ERROR:
      newState = Object.assign({}, state, {
        personal_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_custom_url_ERROR:
      // console.log('%c 0', 'font-size: 22px; color:orange');
      newState = Object.assign({}, state, {
        custom_urlError: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_custom_url1_ERROR:
      // console.log('%c 1', 'font-size: 22px; color:orange');
      newState = Object.assign({}, state, {
        custom_url1Error: values.error,
      });
      return newState;
    case UPDATE_SOCIALPROFILE_custom_url2_ERROR:
      // console.log('%c 2', 'font-size: 22px; color:orange');
      newState = Object.assign({}, state, {
        custom_url2Error: values.error,
      });
      return newState;
    default:
      return state;
  }
};

export default reducers;
