import React from 'react';

export default function (props) {
  const {isWebLink, isWebLinkModal} = props;
  return props.error === true ? (
    // <></>
    <span className={`font-12 font-weight-600 text-danger ${isWebLink ? 'web-links' : ''} ${isWebLinkModal ? 'web-links-modal' : ''}`}>{props.message}</span>
  ) : (
    <></>
  );
}
// text-danger font-weight-600 font-12 py-1
