import React, { useEffect, useRef, useState } from "react";
import ArrowImage from "../../../../../../Assets/upgrade-images/submit_proposal/caret-down.svg";
import { onkeyCode } from "../../CommonFunctionsNew/CommonFunctionsNew";

const PaymentListItem = ({ i, handleSelectedNames, item, handleCheckBox }) => {
  const reff = useRef(null);
  const [toggle, setToggle] = useState(false);
  const handleDropDownClose = (ee) => {
    if (!reff.current?.contains(ee.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleDropDownClose);
    return () => {
      window.removeEventListener("click", handleDropDownClose);
    };
  }, []);

  return (
    <div class="dropedown_input w-100" ref={reff} key={"sss" + i}>
      <div onClick={() => (item?.restrict ? "" : setToggle(!toggle))}>
        <input
          className={`form-control input_md cursor-pointer ${item.validation.name ? "border-danger" : ""}`}
          type="text"
          placeholder="Select "
          // disabled={item?.status == 2 ? true : false}
          disabled={item?.restrict ? true : false}
          value={handleSelectedNames(item.names)}
        />
        <img src={ArrowImage} className={`img-fluid dropedown_icon cursor-pointer`} />
      </div>
      {toggle ? (
        <div className="dropdown_menu ml-1">
          <div className="dropdown_inner">
            {item.names.length > 0 ? (
              item.names.map((ele, ind) => {
                return (
                  <div className="dropdown_item d-flex">
                    <input
                      disabled={true}
                      className="form-control dropedown_input rounded-0 cursor-pointer"
                      type="text"
                      placeholder="Default input"
                      value={onkeyCode(ind + 65) + ". " + ele.name}
                    />
                    <div className={`cstm_checkbox ${ele.isDisable || item?.restrict ? "disabled" : ""}`}>
                      <input
                        type="checkbox"
                        id={`check${i + ind}`}
                        checked={ele.check}
                        onChange={(e) => handleCheckBox(e, i, ind)}
                        disabled={ele.isDisable || item?.restrict}
                      />
                      <label for={`check${i + ind}`}></label>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="dropdown_item d-flex">
                <input className="form-control rounded-0 " type="text" placeholder="Default input" value="No Options ..." />
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {item.validation.name ? <p className="error_message">field required</p> : ""}
    </div>
  );
};
export default PaymentListItem;
