import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putAPI, postAPI } from '../../../../utils/API';
import ValidateAction from '../../../../utils/fieldValidation';

class AwardsForm extends Component {
  state = {
    awards: [],
    awardsType: 'awards',
    awardName: '',
    showAwardDropdown: false,
    title: '',
    publication: '',
    url: '',
    awarder: '',

    id: null,
    edit: this.props.edit,
    awardData: this.props.award,

    titleError: false,
    publicationError: false,
    urlError: false,
    invalidUrl: false,
    awardsTypeError: false,
    awarderError: false,
    awarderNameError: false,
    isFormTouched: false,

    awardNameLengthError: false,
    awarderLengthError: false,
    titleLengthError: false,
    publicationLengthError: false,
    urlLengthError: false,

    isTouched: {
      title: false,
      publication: false,
      url: false,
      // awardsType: false,
      awardName: false,
      awarder: false,
    },
  };

  componentDidMount() {
    const { edit, award } = this.props;

    if (edit !== -1 && award) {
      this.setState({
        id: award._id,
        awardsType: award.type,
        url: award.url ? award.url : '',
        isFormTouched: true,
      });
      if (award.type === 'awards') {
        this.setState({ awardName: award.award_name, awarder: award.awarder });
      } else {
        this.setState({ title: award.title, publication: award.publication });
      }
      // console.log('set award data in form');
    }
    // console.log('compdidmount');
    // console.log(edit, award);
  }

  handleShowType = () => {
    this.setState((prevState) => ({
      showAwardDropdown: !prevState.showAwardDropdown,
    }));
  };

  handleOnChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    if(name === 'url'){
      if(ValidateAction('USERWORK_WEBSITE', value)){
        this.setState({invalidUrl: true});
      } else {
        this.setState({invalidUrl: false});
      }
    }


    if (value.trim() === '') {
      this.setState({
        [name]: value,
        [`${name}Error`]: true,
        isFormTouched: true,
      });
    }
    else if(value.trim().length > 100){
      this.setState({[name]: value, [`${name}LengthError`]: true});
    }
     else {
      this.setState({
        [name]: value,
        [`${name}Error`]: false,
        [`${name}LengthError`]: false,
        isFormTouched: true,
      });
    }
  };

  handleAwardsTypeOnClick = (type) => {
    this.setState({
      ...this.state,
      awardsType: type,
      showAwardDropdown: false,
      awardsTypeError: false,
      isFormTouched: true,
      isTouched: { ...this.state.isTouched, awardsType: true },
    });
  };

  handleBlur = (e) => {
    // console.log(e.target.name, e.target.value);
    const { isTouched } = this.state;
    this.setState({
      ...this.state,
      isTouched: { ...isTouched, [e.target.name]: true },
    });
  };

  validateForm = () => {
    const {
      // titleError,
      // publicationError,
      // urlError,
      // awardsTypeError,
      title,
      publication,
      awardName,
      awarder,
      url,
      awardsType,
      // isTouched,
      // awardNameError,
      // awarderError,
    } = this.state;

    const isInvalidUrl = ValidateAction('USERWORK_WEBSITE', url)

    if (awardsType === 'awards') {
      if (awardName.trim() === '') this.setState({ awardNameError: true });
      if (awardName.trim().length > 100) this.setState({ awardNameLengthError: true });
      if (awarder.trim() === '') this.setState({ awarderError: true });
      if (awarder.trim().length > 100) this.setState({ awarderLengthError: true });
    } else {
      if (title.trim() === '') this.setState({ titleError: true });
      if (title.trim().length > 100) this.setState({ titleLengthError: true });
      if (publication.trim() === '') this.setState({ publicationError: true });
      if (publication.trim().length > 100) this.setState({ publicationLengthError: true });
      if (url.trim() === '') this.setState({ urlError: true });
      // else if(url.trim().length > 100) this.setState({ urlLengthError: 100})
      else if (isInvalidUrl) this.setState({invalidUrl: true});
    }

    // console.log(this.state);

    if (
      awardsType === 'awards' &&
      awardName.trim() !== '' &&
      awarder.trim() !== '' &&
      awardName.trim().length < 100 &&
      awarder.trim().length < 100
      // url.trim() !== '' && !isInvalidUrl   // remove url validation from awards
      // url.trim().length < 100
    ) {
      if(url.length && isInvalidUrl) return false
      // console.log('%c Valid ', 'font-size: 20px; color: white');
      return true;
    } else if (
      awardsType === 'mentions' &&
      title.trim() !== '' &&
      publication.trim() !== '' &&
      title.trim().length < 100 &&
      publication.trim().length < 100 &&
      url.trim() !== '' && !isInvalidUrl
      // url.trim().length < 100
    ) {
      // console.log('%c Valid ', 'font-size: 20px; color: white');
      return true;
    } else {
      // console.log('%c not Valid ', 'font-size: 20px; color: white');
      return false;
    }
  };

  onSubmit = async (e) => {
    const {
      // type,
      title,
      awardsType,
      awardName,
      awarder,
      publication,
      url,
    } = this.state;
    // e.preventDefault();
    if (!this.validateForm()) return;
    const obj = {
      type: awardsType,
      url: url,
    };
    if (awardsType === 'awards') {
      obj.award_name = awardName;
      obj.awarder = awarder;
    } else {
      obj.title = title;
      obj.publication = publication;
    }

    if (this.props.edit !== -1 && this.state.id) {
      // console.log('in update awards');
      obj._id = this.state.id;

      this.props.savingFrom(true);
      const updatedAward = await putAPI('/users/update-user-award', obj);
      this.props.savingFrom(false);
      // console.log(updatedAward.data);
      if (parseInt(updatedAward.data.status) === 1) {
        this.props.UpdateUserAwards(updatedAward.data.data);
        this.props.closeUpdateForm();
      }
    } else {
      this.props.savingFrom(true);
      const AddAward = await postAPI('/users/add-user-award', obj);
      this.props.savingFrom(false);

      if (parseInt(AddAward.data.status) === 1) {
        this.props.AddUserAwards(AddAward.data.data);
        this.props.closeUpdateForm();
      }
    }
  };

  render() {
    // const { UpdateAwards, saving } = this.props;
    const {
      // award,
      awardName,
      showAwardDropdown,
      title,
      publication,
      url,
      awardsType,
      awardNameError,
      titleError,
      awarder,
      publicationError,
      urlError,
      awardsTypeError,
      awarderError,
      // isFormTouched,
      invalidUrl,
      awardNameLengthError,
      awarderLengthError,
      titleLengthError,
      publicationLengthError,
      // urlLengthError,
    } = this.state;
    const awardsTypeOptions = [
      { name: 'Awards', type: 'awards' },
      { name: 'Articles & Mentions', type: 'mentions' },
    ];
    return (
      <div
        className={`custom_form wrapper-form remove p-4 mb-4 mob-p-15 ${
          this.props.edit !== -1 ? 'border-top-0-10' : ''
        }`}
      >
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className="row m-0">
            <div className="form-group w-98 mb-4 col-sm-6 pl-0 mob-p-0 mob-w-100">
              <label
                className="label font-weight-600 font-14 mob-font-13"
                htmlFor="last"
              >
                Type
              </label>
              <div className="filter-dropdown">
                <div className="select">
                  <div className="select">
                    <div
                      className={`select-styled bg-white${
                        awardsTypeError ? 'border-error' : ''
                      }`}
                      onBlur={this.handleBlur}
                      onClick={this.handleShowType}
                    >
                      {
                        awardsTypeOptions.filter(
                          (el) => el.type === awardsType
                        )[0].name
                      }
                    </div>

                    {showAwardDropdown && (
                      <ul
                        className="select-options hideList"
                        style={{ display: 'block' }}
                      >
                        {/* <li rel="Choose here">Choose here</li> */}
                        {awardsTypeOptions &&
                          awardsTypeOptions.map((obj, index) => (
                            <li
                              key={index}
                              rel={obj.type}
                              onClick={() =>
                                this.handleAwardsTypeOnClick(
                                  obj.type.toLocaleLowerCase()
                                )
                              }
                            >
                              {obj.name}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </div>
                <i className="fas fa-chevron-down select-arrow"></i>
              </div>
            </div>
            {awardsType === 'awards' ? (
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label font-weight-600" htmlFor="first">
                  Award Name
                </label>
                <input
                  type="text"
                  placeholder="Award Name"
                  className={`form-control email pl-3 ${
                    awardNameError ? 'border-error' : ''
                  }`}
                  name="awardName"
                  value={awardName}
                  onChange={this.handleOnChange}
                  onBlur={this.handleBlur}
                />
                {awardNameError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add award name to continue
                  </span>
                )}
                {awardNameLengthError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Award name must be less than 100 characters
                  </span>
                )}
              </div>
            ) : (
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label font-weight-600" htmlFor="first">
                  Title
                </label>
                <input
                  type="text"
                  placeholder="Title"
                  className={`form-control email pl-3 ${
                    titleError ? 'border-error' : ''
                  }`}
                  name="title"
                  value={title}
                  onChange={this.handleOnChange}
                  onBlur={this.handleBlur}
                />
                {titleError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add title to continue
                  </span>
                )}
                {titleLengthError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Title must be less than 100 characters
                  </span>
                )}
              </div>
            )}
            {awardsType === 'awards' ? (
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label font-weight-600" htmlFor="first">
                  Awarder
                </label>
                <input
                  type="text"
                  placeholder="Awarder"
                  className={`form-control email pl-3 ${
                    awarderError ? 'border-error' : ''
                  }`}
                  name="awarder"
                  value={awarder}
                  onChange={this.handleOnChange}
                  onBlur={this.handleBlur}
                />
                {awarderError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add awarder to continue
                  </span>
                )}
                {awarderLengthError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Awarder must be less than 100 characters
                  </span>
                )}
              </div>
            ) : (
              <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
                <label className="label font-weight-600" htmlFor="first">
                  Publication
                </label>
                <input
                  type="text"
                  placeholder="Publication"
                  className={`form-control email pl-3 ${
                    publicationError ? 'border-error' : ''
                  }`}
                  name="publication"
                  value={publication}
                  onChange={this.handleOnChange}
                  onBlur={this.handleBlur}
                />
                {publicationError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Add publication to continue
                  </span>
                )}
                {publicationLengthError && (
                  <span className="text-danger font-weight-600 font-12 py-1">
                    Publication must be less than 100 characters
                  </span>
                )}
              </div>
            )}
            <div className="form-group mb-4 col-sm-6 pl-0 mob-p-0">
              <label className="label font-weight-600" htmlFor="first">
                URL
              </label>
              <input
                type="text"
                placeholder="URL"
                className={`form-control email pl-3 ${
                  urlError ? 'border-error' : ''
                }`}
                name="url"
                value={url}
                onChange={this.handleOnChange}
                onBlur={this.handleBlur}
              />
              {/* {urlError && (
                <span className="text-danger font-weight-600 font-12 py-1">
                  Add url to continue
                </span>
              )}
              {urlLengthError && (
                <span className="text-danger font-weight-600 font-12 py-1">
                  Url must be less than 100 characters
                </span>
              )} */}
              {invalidUrl && (
                <span className="text-danger font-weight-600 font-12 py-1">
                  Add valid url to continue
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  let globalReducer = store.globalReducer;
  return {
    saving: globalReducer.saving,
    closeModal: creatorProfile.closeModal,
    UpdateAwards: creatorProfile.UpdateAwards,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UpdateUserAwards: (e) => dispatch({ type: 'UPDATE_AWARD_UPDATE', values: e }),
  AddUserAwards: (e) => dispatch({ type: 'ADD_AWARD', values: e }),
  savingFrom: (e) => dispatch({ type: 'UPDATE_SAVING', values: e }),
});

export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  AwardsForm
);
