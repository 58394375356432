import firebase from 'firebase';

/*--------------------------------for production-configration------------------------------------------*/

const firebaseConfig = {
  apiKey: "AIzaSyA9QkFR0lx5DqkFl8DsvCAD4TFKA5RkNdw",
  authDomain: "indiefolio-v3-test.firebaseapp.com",
  databaseURL: "https://indiefolio-v3-test.firebaseio.com",
  projectId: "indiefolio-v3-test",
  storageBucket: "indiefolio-v3-test.appspot.com",
  messagingSenderId: "192967860233",
  appId: "1:192967860233:web:d322749b48e3402999d8d8"
};

/*--------------------------------for staging-configration------------------------------------------*/

// const firebaseConfig = {
//     apiKey: "AIzaSyD-K4pLxCfY5cnOYqIXf_mdpTlHgxeNyvE",
//     authDomain: "testif-df134.firebaseapp.com",
//     databaseURL: "https://testif-df134.firebaseio.com",
//     projectId: "testif-df134",
//     storageBucket: "testif-df134.appspot.com",
//     messagingSenderId: "818769410389",
//     appId: "1:818769410389:web:1812ba426aacf4118057dc"
//   };  
  
const firebaseIs = firebase.initializeApp(firebaseConfig);

export default firebaseIs;