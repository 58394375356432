import React from "react";
import SucessIcon from "../../../../../Assets/upgrade-images/Sucess/Sucess.svg";
import { putAPI } from "../../../../../utils/API";

export const EndProjSuccessPop = ({
  id,
  projectData,
  showEndProjSuccess,
  CurrentRole,
  proposal_data,
  setjustStaticEndProjClient,
  setShowFinalReview,
  setShowEndProjSuccess,
}) => {
  const callReviewAndClosePopup = async () => {
    let data = {
      role: CurrentRole,
      status: false,
      post_project_id: id,
    };

    try {
      const res = await putAPI("/postProject/change-project-complete-status", data);
      if ((projectData?.rating_by_client == 1 && CurrentRole != 2) || (projectData?.rating_by_creator == 1 && CurrentRole == 2)) {
        setShowEndProjSuccess(false);
      } else {
        setShowEndProjSuccess(false);
        setjustStaticEndProjClient(true);
        setShowFinalReview(true);
      }
    } catch (err) {
      console.log("Something went wrong, Please try again later.");
    }
  };

  return (
    <>
      <section className="projectcomplete_wrapper">
        <div
          className={`modal fade modal_fade_bg ${showEndProjSuccess ? "show" : ""}`}
          id="projectcomplete"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: showEndProjSuccess ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_md m-auto">
            <div className="modal-content br_6">
              <div className="modal_top_header d-flex align-items-center  justify-content-between px-4"></div>

              <div className="modal-body">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-8 text-center">
                    <div className="sucess_logo mb-4">
                      <img src={SucessIcon} className="img-fluid" />
                    </div>
                    <h4 className="text_gray_lg_w_800 mb-4">Congratulations, the project is completed!</h4>
                    <p className="mx-auto text_light_gray_md sucess_msg mb-4">
                      {CurrentRole == 2
                        ? `${
                            projectData?.project_name
                          } is now officially completed. Kindly review your experience of working with ${
                            proposal_data.userData.first_name + " " + proposal_data.userData.last_name
                          } from the overview section itself. This was a great effort by you and we wish you success and luck for your next set of projects on IndieFolio`
                        : `${
                            projectData?.project_name
                          } is now officially completed. You can fetch the final files of the project from the platform itself. You can also review your experience of working with ${
                            proposal_data.userData.first_name + " " + proposal_data.userData.last_name
                          } from the overview section itself. Hope you had a great experience using IndieFolio.`}
                    </p>

                    <div className="add_project py-3">
                      <button type="button" class="btn btn_common_md" onClick={callReviewAndClosePopup}>
                        Review Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer py-3 border-0 justify-content-between align-items-center"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
