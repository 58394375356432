import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import { BaseImageUrl } from '../../../../utils/BaseUrl';
import { getThumbnailImage, isRandomUrl, modifyURLByPlatform } from '../../../../utils/Helperfunctions'
import logo from '../../../../Assets/images/landing/IndieFolio_logo_2022.png';
import $ from 'jquery';
import { getFileType, renderDifferentFilesThumbnail, isViewableFile } from '../../Common/Common/FileRenderMessage';
// import { times } from 'lodash';
import RandomUrlPreview from '../../Common/RandomUrlPreview/RandomUrlPreview';
import AllFileViewer from '../../Common/AllFileViewer/AllFileViewer';

class ViewModalFullScreen extends React.Component {
  state = {
    // preview_src: '',
    previewImage: null,
    Images: [],
  }


//   handleThumbnail = (index, type, src, original_image) => {
//     if (src !== "") {
//         this.setState({
//             preview_src: src,
//             preview_type: (type == "url") ? "url" : "image",
//             active_thumbnail: index,
//             original_image: original_image,
//         })
//     } 
// }

  handleThumbnail = (image) => {
    this.setState({previewImage: image})
  }




componentDidUpdate = (prevProps) => {
    const {Images} = this.props;
    if(this.props.Images !== prevProps.Images ) {
        // let preview_type = Images && Images?.[0]?.type;
        // let preview_src = Images && Images?.[0]?.value;
        // this.handleThumbnail(0,preview_type,preview_src);
    this.setState({Images: Images, previewImage: Images?.[0]})

  }
}
componentDidMount = () => {
  const {Images, completedMilestone} = this.props;
  if(completedMilestone){
    this.setState({isMilestone: true, milestone: completedMilestone, previewImage: completedMilestone.files.concat(completedMilestone.links)[0]})
  } else {
    this.setState({Images: Images, previewImage: Images?.[0]})
  }
  // let preview_src = Images && Images?.[0]?.value;
  // let preview_type = Images && Images?.[0]?.type;
  // let original_image = Images && Images?.[0]?.image_original;
  // this.handleThumbnail(0,preview_type,preview_src,original_image);
}

closeModal = () => {
  this.props.closePopup();
  $("body").removeClass("modal-open");
  if(this.state.isMilestone){
    this.props.resetCompletedMilestone()
  } else {
    this.props.resetProjectData()
  }
}


  render() {
    const { previewImage, Images, isMilestone, milestone} = this.state;
    const {Title,userData} = this.props;
    const image_user = require("../../../../Assets/images/user.png");
    const images_length = Images.length && Images.filter(el => el.value !== "").length;
    
    const filesAndLinks = isMilestone ? milestone.files.concat(milestone.links).sort((a,b) => a.index -b.index) : []
    return (
      <>
        
        <div className="modal fade custom_form fullscreen_modal show" id="modal-subscribe" tabIndex={-1} style={{ paddingRight: "0px", display: "block" }} role="dialog" aria-labelledby="myModalLabel">
          <div type="button" className="close_icon" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
              <img onClick={this.closeModal} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          </div>
          <div className="modal-dialog modal-dialog-centered modal-lg mob-modal-xs" role="document">
              <div className="modal-content border-0">
                  <div className="modal-body">
                  <div className="row m-0 fullscrreen_wrapper"> 
                      <div className="mob-col-100 d-flex ipad-pro-pt-15 fullscreen_header">
                        <div className="pl-3 pr-5 mob-hidden">
                          <img src={logo} alt="Get-Creator" width="150"/>
                        </div>
                        <div className="">
                            <div className="w-100">
                                <h2 className="font-18 font-weight-600 mob-font-14 text-capitalize mb-1">{isMilestone ? `Milestone ${milestone.serial_no}` : Title}</h2>
                            </div>
                            {isMilestone ? 
                              <div className="w-100 d-flex align-items-center">
                              <div>
                                  <p className="mb-0 review"><img src={BaseImageUrl + milestone.user.profile_image} alt="profile" title="" width="25" /></p>
                              </div>
                              {/* <div className="pl-2">
                                  <span className="profile-subTitle font-weight-500 font-14 text-sub mob-font-12 text-capitalize">By {milestone.user.first_name} {milestone.user.last_name}</span>
                              </div> */}
                            </div> :
                            <div className="w-100 d-flex align-items-center">
                              <div>
                                  <p className="mb-0 review"><img src={(userData?.profile_image !== "") ? BaseImageUrl+userData?.profile_image :image_user} alt="profile" title="" width="25" /></p>
                              </div>
                              {/* <div className="pl-2">
                                  <span className="profile-subTitle font-weight-500 font-14 text-sub mob-font-12 text-capitalize">By {userData?.first_name} {userData?.last_name}</span>
                              </div> */}
                            </div>}
                          </div>
                      </div>
                      <div className="mob-col-100 fullscreen-scroll fullscreen_thumb mob-text-center ipad-pro-text-center">
                          <div className={`user-work-thumbnail ${images_length > 5 ? 'full-w-100' : ''}`}>
                                  {/* <img src={renderDifferentFilesThumbnail(files.name)} alt="work" className={`img-fluid ${thumbnailIndex === k ? 'active' : ''}`} />   */}
                              {isMilestone ? 
                              <>
                                {filesAndLinks.map((item, idx) => {
                                  if(item.hasOwnProperty('link')){
                                    return(
                                      <span key={idx} className={`thumb-img mb-2  ${previewImage.link === item.link ? "active" : ""}`} onClick={() => this.handleThumbnail(item)}>
                                       <RenderMilestoneImage image={item} />
                                      </span>
                                    )
                                  } else {
                                    return (
                                      <span className={`thumb-img mb-2  ${previewImage.name === item.name ? "active" : ""}`} onClick={() => this.handleThumbnail(item)}>
                                          <span className={`upload_image_thumbnail ${previewImage.name === item.name ? "active" : ""}`} style={{backgroundImage:`url("${renderDifferentFilesThumbnail(item.name)}")`}}></span>
                                      </span>
                                    )
                                  }
                                })}
                                
                                {/* {milestone.files.length ? milestone.files.map((el,idx) => 
                                  <span className={`thumb-img mb-2  ${previewImage.name == el.name ? "active" : ""}`} onClick={() => this.handleThumbnail(el)}>
                                      <span className={`upload_image_thumbnail ${previewImage.name == el.name ? "active" : ""}`} style={{backgroundImage:`url("${renderDifferentFilesThumbnail(el.name)}")`}}></span>
                                  </span>
                                ) : null}
                                {milestone.links.length ? milestone.links.map((el, idx) =>
                                  <span className={`thumb-img mb-2  ${previewImage.link == el.link ? "active" : ""}`} onClick={() => this.handleThumbnail(el)}>
                                  <RenderMilestoneImage image={el} />
                                  </span>
                                )
                                :null} */}
                              </>
                                :
                              Images && Images.length > 0 ?
                              Images.map((list,index) => {
                                  return list.value !== '' ? 
                                  <RenderImage previewImage={previewImage} key={index} image={list} handleThumbnail={this.handleThumbnail} /> : null
                              })
                              :
                              <></>
                              }
                          </div>
                      </div>
                  </div>
                  <div className="row m-0">
                      <div className="col-12 overflow-content full_screen_view">
                          {previewImage ?
                            isMilestone ? 
                              <RenderMilestonePreview image={previewImage} /> : 
                              previewImage.type === "image" ?
                              previewImage.image_original && isViewableFile(previewImage.image_original) ? 
                                  <AllFileViewer link={previewImage.image_original} /> :
                                <img src={(previewImage.image_original) ? previewImage.image_original : previewImage.value} alt="work" className="img-fluid" />
                                :
                                  isRandomUrl(previewImage.value) ? <RandomUrlPreview url={previewImage.value} /> :
                                    <iframe title="custom-url" className="fullscreen_video border-0 border-radius-8" src={modifyURLByPlatform(previewImage.value,  {isForPreview: true})}></iframe>
                            :null}
                              {/* <ReactPlayer height="406px" width="540px" url={(original_image) ? original_image : preview_src} /> */}
                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
      <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
      </>
    )
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  let projectView = store.projectView;
  let globalReducer  = store.globalReducer;
  let dashBoard = store.dashboard;
  let postProjectData = store.postProject;
  return {
    publicView: creatorProfile.public,
    closeModal: creatorProfile.closeModal,
    projectId: projectView._id,
    About: projectView.About,
    Title: projectView.Title,
    Role: projectView.Role,
    EndMonth: projectView.EndMonth,
    EndYear: projectView.EndYear,
    Keywords: projectView.Keywords,
    Tools: projectView.Tools,
    Images: projectView.Images,
    Collateral: projectView.Collateral,
    userData:projectView.userData,
    createdFor: projectView.created_type,
    companyName: projectView.company_name,
    dataReview: projectView.dataReview,
    CurrentRole: globalReducer.Me.CurrentRole,
    Projects: dashBoard.Projects,
    userPortfolioProjects: postProjectData.userPortfolioProjects,
    completedMilestone: postProjectData.completedMilestone,
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  OpenPopup: (text) => dispatch({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  resetProjectData: () => dispatch({ type: 'RESET_SINGLE_PROJECT_DATA', values: null }),
  resetCompletedMilestone: () => dispatch({ type: 'RESET_COMPLETED_MILESTONE_DATA', values: null }),

});
export default connect(mapState, mapDispatch)(ViewModalFullScreen);

const RenderMilestoneImage = ({image}) => {
  const [img, setImg] = useState('');
  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setImg(url)
    }

    getThumbnailImageFunc(image.link)

  }, [image,image.type])

  return (
    // <img src={img} alt="work" className={`img-fluid  ${image.link == img ? "active" : ""}`} />
    <span className={`upload_image_thumbnail ${image.link === img ? 'active' : ''}`} style={{backgroundImage:`url("${img}")`}}></span>
  )

}

const RenderMilestonePreview = ({image}) => {

  const [img, setImg] = useState('');

  useEffect(() => {
    // const getThumbnailImageFunc = async (u) => {
    //   const url = await getThumbnailImage(u, true);
    //   setImg(url)
    // }

    if(image.name){
      setImg(renderDifferentFilesThumbnail(image.name))
    } else {
      setImg(image.link)
    }

  }, [image])

  const isFile = (filename) => {
    const fileType = getFileType(filename)
    const fileExt = ['pdf','ai','mp4','zip', 'pdf', 'psd', 'doc', 'docx', 'eps', 'ppt', 'pptx', 'xlsx', 'mp3']
    return fileExt.includes(fileType)
  }

  return(
    image.name ? 
      <>
      {isViewableFile(image.name) ? 
        <AllFileViewer link={image.name} />:
      <div class="d-block">
        <div>
          <img src={img} alt="work" className="img-fluid" /> 
        </div>
        <div className="pt-4">
          {isFile(image.name) ? <a href={image.name} target="_blank" rel="noopener noreferrer" className="main-btn text-white" download >View File</a> : null}
        </div>
      </div>  }     
      </>:
      isRandomUrl(img) ? <RandomUrlPreview url={img} /> :
        <iframe title="custom-url" className="fullscreen_video border-0 border-radius-8" src={img}></iframe>
  )
}

const RenderImage = ({image, handleThumbnail, previewImage}) => {

  const [thumbnail, setThumbnail] = useState(null)


  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url)
    }
    if(image.type === 'url'){
      getThumbnailImageFunc(image.value)
    } else if(isViewableFile(image.value)) {
      setThumbnail(renderDifferentFilesThumbnail(image.value))
    }else {
      setThumbnail(image.value)
    }
  },[image.value,image.type])

  return(
    <span className={`thumb-img mb-2  ${previewImage.value === image.value ? "active" : ""}`} onClick={() => handleThumbnail(image)}>
        {/* <img src={ thumbnail} alt="work" className={`img-fluid  ${previewImage.value == image.value ? "active" : ""}`} /> */}
        <span className={`upload_image_thumbnail ${previewImage.value === image.value ? "active" : ""}`} style={{backgroundImage:`url("${thumbnail}")`}}></span>
    </span>
  )
}
