import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { activeProposalforClient } from "../../../../../../store/action/proposal.action";
import ActiveOverview from "./ActiveOverview";
import RejectedOverview from "./RejectedOverview";
import DetailSideBar from "../../CommonComp/DetailSideBar";
import ViewProp from "../../CommonComp/ViewProp";
import Loader from "../../../../Common/Common/Loader";

const Overview = (props) => {
  const data = useSelector((state) => state.releventProjects);
  // const proposalsIs = data?.allProposal;
  // const managerData = data?.allProposal?.postprojectdata;

  const dispatch = useDispatch();
  const { id } = props.projectDataStates;

  const getAllProposal = () => {
    const data = { id };
    dispatch(activeProposalforClient(data));
  };

  useEffect(() => {
    if (id) getAllProposal();
  }, [id]);

  let isVisibleActProj =
    props?.projectDataStates?.data &&
    props?.projectDataStates?.data?.project_data?.status;

  return (
    <>
      <div className="row">
        {!props?.projectDataStates?.projectDataloader ? (
          <>
            {isVisibleActProj == 6 || isVisibleActProj == 7 ? (
              <ViewProp
                id={props?.id}
                userId=""
                role={1}
                isOverview={true}
                {...props}
                col={9}
              />
            ) : (
              <div className="col-lg-9 overview_tab">
                <div className="page_card mb-4">
                  <div>
                  <nav className="cstm_nav px-4">
                    <div className="nav br_btm_border-gray-300 cstm_tab row" id="nav-tab" role="tablist">
                      {props.authIs ? (
                        <>
                          <a
                            className="nav-item nav-link cstm_tab_item refrence_tab_active  active  d-flex align-items-center"
                            id="nav-revelent-project-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            <p className="mb-0">Active Proposals</p>
                          </a>
                          <a
                            className="nav-item nav-link cstm_tab_item  d-flex align-items-center"
                            id="nav-attatchment-tab"
                            data-toggle="tab"
                            href="#nav-profile"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            <p className="mb-0">Rejected</p>
                          </a>
                          {/* <a
                            className="nav-item nav-link cstm_tab_item  d-flex align-items-center"
                            id="nav-attatchment-tab"
                            data-toggle="tab"
                            href="#nav-brief"
                            role="tab"
                            aria-controls="nav-brief"
                            aria-selected="false"
                          >
                            <p className="mb-0">View Brief</p>
                          </a> */}

                            <span
                              className="font-14 anchor_text_sm_w_700"
                              style={{ marginLeft: "auto", padding: "1.3rem" }}
                              onClick={() =>
                                props?.history?.push(
                                  `/projects/${id}/proposal_details`
                                )
                              }
                            >
                              View Brief
                            </span>
                          </>
                        ) : (
                          <a
                            class="nav-item nav-link cstm_tab_item refrence_tab_active  active  d-flex align-items-center"
                            id="nav-revelent-project-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            <p className="mb-0">Active Proposals</p>
                          </a>
                        )}
                      </div>
                    </nav>
                  </div>

                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-revelent-project-tab"
                    >
                      <ActiveOverview {...props} />
                    </div>

                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-attatchment-tab">
                      <RejectedOverview {...props} />
                    </div>
                    {/* <div className="tab-pane fade" id="nav-brief" role="tabpanel" aria-labelledby="nav-attatchment-tab">
                      <ViewBrief id={props?.id} {...props} col={12} showReject={false} showQuestion={false} isTab={true} />
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            <DetailSideBar {...props} showProjDetail="yes"/>
          </>
        ) : (
          <Loader
            isCreateModal={true}
            style={{
              marginTop: "20rem",
              marginLeft: "45rem",
              marginBottom: "20rem",
            }}
          />
        )}
      </div>
    </>
  );
};

export default Overview;
