import React from "react";
import Select from "react-select";
import { handleStatusDel, onkeyCode } from "../CommonFunctionsNew/CommonFunctionsNew";
import PriceImage from "../../../../../Assets/upgrade-images/submit_proposal_fixed_cost/price.png";
import { separateNumber, getProjectQuote } from "../../../../../utils/Helperfunctions";
import DatePicker from "react-date-picker";
import Edit from "../../../../../Assets/upgrade-images/Project_Termination_EndDate/edit.png";
import DateIcon from "../../../../../Assets/upgrade-images/submit_proposal/date.svg";
import RotateRight from "../../../../../Assets/upgrade-images/Project_Termination_EndDate/rotate_right.svg";

const TerminateProjectMilestone = (props) => {
  const handleSelect = (selected, i, amount) => {
    props.mileData[i].completion_status = selected;
    if (selected.value == "Fully Done") {
      props.mileData[i].payable = parseInt(amount);
      props.mileData[i].payable_tax = parseInt(amount) * 0.18;
      props.mileData[i].validPayable = false;
    } else {
      props.mileData[i].payable = "";
      props.mileData[i].payable_tax = 0;
      props.mileData[i].validPayable = false;
    }
    props.setMileData([...props.mileData]);
  };

  const handlePayable = (e, i) => {
    e.preventDefault();
    props.mileData[i].payable = e.target.value > 0 ? parseInt(e.target.value) : e.target.value;
    props.mileData[i].payable_tax = e.target.value > 0 ? parseInt(e.target.value) * 0.18 : e.target.value * 0.18;
    props.mileData[i].validPayable = e.target.value ? false : true;
    props.setMileData([...props.mileData]);
  };

  const handleNotesField = () => {
    return (
      <div className={`row ${props.type == "fixed" ? "mx-4 mb-3 py-3 align-items-center" : "py-3 mx-0"}`}>
        <div className="col-lg-12 px-0">
          <p className="fs_14 text-black-700 fw_600 mb-2 ">
            Additional Notes <span className="fs_14 text-gray-1300 fw_600"> (Optional)</span>
          </p>
        </div>

        <div className="col-lg-12 px-0">
          <textarea
            className="form-control upload_notes"
            name="description"
            value={props.note}
            onChange={(e) => props.setNote(e.target.value.substring(0, 300))}
            placeholder="Notes ..."
            id="comment"
            rows="5"
          />
          <span className="font-weight-500 text-secondary">{props.note?.length}/300</span>
        </div>
      </div>
    );
  };

  const handleEditTotalAmount = () => {
    props.setEditTotalPay(!props.editTotalPay);
    if (!props.editTotalPay) {
      props.setActualRetainerData({ ...props.actualRetainerData, amount: "", validAmount: false });
    } else {
      props.setActualRetainerData({
        ...props.actualRetainerData,
        amount: props.actualRetainerData.copyAmount,
        validAmount: false,
      });
    }
  };

  const handleTotalPay = (e) => {
    e.preventDefault();
    props.setActualRetainerData({
      ...props.actualRetainerData,
      amount: e.target.value,
      validAmount: e.target.value < 1 ? true : false,
    });
  };

  const getProjectQuoteTerm = (quote, type, retainerFeesType) => {
    if (type === "retainer") {
      let billing = retainerFeesType?.split("l")?.[0];
      return "₹ " + separateNumber(quote) + "/" + billing;
    } else {
      return "₹ " + separateNumber(quote);
    }
  };

  const dateimg = <img src={DateIcon}/>;
  const handleShowAmount = (price) => {
    return (
      <div className={`total_details_card ${props.type === "fixed" ? "px-4 br_6" : "ps_5 pe_5"}`}>
        <div className="row d-flex align-items-center justify-content-between mx-0 px-0 py-3 amount_tag ">
          <div className="title">
            <p className="mb-0 font_semi_bold">
              {props.type === "fixed" ? "Total Amount Payable By Client" : "Total Amount Payable"}
              {props.type === "fixed" ? null : (
                <span className="ml-2 text-blue-800 cursor-pointer" onClick={() => handleEditTotalAmount()}>
                  <img src={props.editTotalPay ? RotateRight : Edit} className="mr-1" />
                  {props.editTotalPay ? "Reset to Default" : "Edit"}
                </span>
              )}
            </p>
            {props.type === "fixed" ? null : <p className="mb-0 fs_10 fw_600 text-gray-1300">Auto calculated as per end date</p>}
          </div>

          <div className="total_amount">
            {props.editTotalPay ? (
              <>
                <div className="price_input">
                  <input
                    type="number"
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                    className="form-control input_md "
                    placeholder="Enter Amount + GST"
                    aria-label="Amount"
                    aria-describedby="basic-addon1"
                    onChange={(e) => handleTotalPay(e)}
                    value={props.actualRetainerData.amount}
                  ></input>
                  <img src={PriceImage} className="price_icon img-fluid"></img>
                </div>
                {props.actualRetainerData.validAmount ? <p className="error_message">field required</p> : ""}
              </>
            ) : (
              <p className="mb-0 text_gray_md_w_700">
                {getProjectQuoteTerm(price,props?.type,props?.retainer_fees_type)}
                {/* ₹ {separateNumber(price, "")}{" "} */}
                {/* {props.type === "retainer" ? (props?.retainer_fees_type === "Hourly" ? "/Hour" : "/Month") : ""} */}
                {/* <span className="d-block text_light_gray_xs text-right">+18% GST </span> */}
                <span className="d-block text_light_gray_xs text-right">{(props?.type=='fixed' || props?.type=='retainer')?'+ Applicable taxes':' Pricing provided per item'}</span>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleNumbers = (action, which, e) => {
    let numbers = "";
    numbers = props.actualRetainerData.num_hour;
    if (action === "plus") {
      if (numbers == 0) {
        props.actualRetainerData.num_hour = 1;
        if (!props.editTotalPay) props.actualRetainerData.amount = props.actualRetainerData.copyAmount;
      } else {
        props.actualRetainerData.num_hour += 1;
        if (!props.editTotalPay)
          props.actualRetainerData.amount = props.actualRetainerData.copyAmount * props.actualRetainerData.num_hour;
      }
    } else if (action === "change") {
      props.actualRetainerData.num_hour = e.target.value > 0 ? Number(e.target.value) : e.target.value;
      if (!props.editTotalPay) props.actualRetainerData.amount = props.actualRetainerData.copyAmount * Number(e.target.value);
    } else {
      if (props.actualRetainerData.num_hour > 1) {
        props.actualRetainerData.num_hour -= 1;
        if (!props.editTotalPay)
          props.actualRetainerData.amount = props.actualRetainerData.copyAmount * props.actualRetainerData.num_hour;
      }
    }
    props.actualRetainerData.validHours = props.actualRetainerData.num_hour < 1 ? true : false;
    props.setActualRetainerData({
      ...props.actualRetainerData,
    });
  };

  const handleChangeDate = (e) => {
    props.setProjectEndDate(new Date(e));
  };

  let totalPayableFixed = 0;
  props.mileData.map((item) => {
    return (totalPayableFixed += ~~item.payable);
  });

  return (
    <>
      {props.type == "fixed" ? (
        <>
          {props.mileData.map((item, i) => {
            return (
              <div className="milestone_wrapper mx-4 py-4 br_btm" key={i}>
                <div className="row mx-0">
                  <div className="col-lg-12 px-0">
                    <p className="fs_14 fw_700 text-black-700">Milestone {item.index + 1}</p>
                  </div>
                  <div className=" col-lg-12 px-0 mb-4">
                    <ul className="paying_cycle_list text_gray_xs_w_600 ">
                      {item?.names.map((obj, index) => {
                        return (
                          <li className="mb-2" key={index}>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0">
                                {onkeyCode(index + 65)}. {obj?.name} {obj?.iteration > 1 ? `(${obj?.iteration} Iterations)` : ""}
                              </p>
                              {handleStatusDel(obj)}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="total_details_card px-4 br_6">
                  <div className="row d-flex align-items-center justify-content-between br_btm mx-0 px-0 py-3 amount_tag ">
                    <div className="title">
                      <p className="mb-0 font_semi_bold">Completion Status</p>
                    </div>

                    <div className="duration_fields">
                      <div className="dropedown_field select_dropdown">
                        <Select
                          options={[
                            { label: "Fully Done", value: "Fully Done" },
                            { label: "Partially Done", value: "Partially Done" },
                          ]}
                          className="hhhh"
                          value={item.completion_status}
                          onChange={(e) => handleSelect(e, i, item.payable_amount)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center justify-content-between br_btm mx-0 px-0 py-3 amount_tag ">
                    <div className="title">
                      <p className="mb-0 font_semi_bold">Payable</p>
                    </div>
                    <div className="total_amount">
                      {item.completion_status.value === "Fully Done" ? (
                        <p className="mb-0 text_gray_md_w_700">₹ {separateNumber(item.payable, "")}</p>
                      ) : (
                        <>
                          <div className="price_input">
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                                evt.preventDefault()
                              }
                              className="form-control input_sm"
                              placeholder="Enter Amount"
                              aria-label="Amount"
                              aria-describedby="basic-addon1"
                              onChange={(e) => handlePayable(e, i)}
                              value={item.payable}
                            />
                            <img src={PriceImage} className="price_icon img-fluid"></img>
                          </div>
                          {item.validPayable ? <p className="error_message">field required</p> : ""}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {handleNotesField()}
          {handleShowAmount(totalPayableFixed)}
        </>
      ) : (
        <>
          <div className="pe_3 ps_3 pt-4">
            <div className="row br_btm mx-0 py-3">
              <div className="col-lg-7 pl-0">
                <p className="fs_14 text-black-700 fw_600 mb-0">Select end date</p>
                <p className="fs_12 text-gray-1300 fw_600">This date will be used to calculate the pending payments</p>
              </div>
              <div className="col-lg-5 text-right pr-0">
                <div className="date_input">
                  <DatePicker
                    value={props.project_end_date}
                    className="form-control text-center input_sm"
                    clearIcon={null}
                    name="startDate"
                    dayPlaceholder={'dd'}
                    monthPlaceholder={'mm'}
                    yearPlaceholder={'yyyy'}
                    calendarIcon={dateimg}
                    format="dd/MM/yyyy"
                    calendarClassName="apniClass"
                    showLeadingZeros={true}
                    onChange={handleChangeDate}
                    minDate={new Date()>=props.ref_project_end_date ? new Date() : props.ref_project_end_date}
                    maxDate={new Date(props.checkDate.maxDate)}
                  />
                </div>
              </div>
            </div>
            {props?.retainer_fees_type === "Hourly" ? (
              <div className="row br_btm mx-0 py-3">
                <div className="col-lg-7 pl-0">
                  <p className="fs_14 text-black-700 fw_600 mb-0">Number of hours</p>
                  <p className="fs_12 text-gray-1300 fw_600">Select the hours the creator has worked for since the last cycle</p>
                </div>
                <div className="col-lg-5 text-right pr-0">
                  <div class="btn_field number_btn">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button border-0" class="btn" onClick={() => handleNumbers("minus", "numHours")}>
                        -
                      </button>
                      <input
                        type="number"
                        class="form-control text-center input_sm"
                        onChange={(e) => handleNumbers("change", "numHours", e)}
                        onKeyDown={(evt) =>
                          (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                          evt.preventDefault()
                        }
                        value={props.actualRetainerData.num_hour}
                      />
                      <button type="button border-0" class="btn" onClick={() => handleNumbers("plus", "numHours")}>
                        +
                      </button>
                    </div>
                    {props.actualRetainerData.validHours ? <p className="error_message">field required</p> : ""}
                  </div>
                </div>
              </div>
            ) : null}
            {handleNotesField()}
          </div>
          {handleShowAmount(props.actualRetainerData.amount)}
        </>
      )}
    </>
  );
};

export default TerminateProjectMilestone;
