import React, { Component, useState } from "react";
import firebaseIs from "../../../../utils/fire";
// import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import { ChatBaseUrl, BaseImageUrl } from "../../../../utils/BaseUrl";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import HeaderLogoText from "../../../../Assets/upgrade-images/sidebar/logo_1.svg";
import HeaderNewLogo from "../../../../Assets/upgrade-images/sidebar/logo_3.svg";
import HireExpert from "../../../../Assets/images/svg/hire.svg";
import UploadIcon from "../../../../Assets/upgrade-images/sidebar/document-upload.svg";
import DashboardIcon from "../../../../Assets/upgrade-images/sidebar_icons/dashboard.svg";
import FolderOpenIcon from "../../../../Assets/upgrade-images/sidebar_icons/projects.svg";
import ProfileIcon from "../../../../Assets/upgrade-images/sidebar_icons/clients.svg";
import ReceiptIcon from "../../../../Assets/upgrade-images/sidebar_icons/earning.svg";
import FolderIcon from "../../../../Assets/upgrade-images/sidebar_icons/file_browser.svg";
import SettingIcon from "../../../../Assets/upgrade-images/sidebar_icons/setting.svg";
import messageIcon from "../../../../Assets/upgrade-images/sidebar_icons/message-2.svg";
import LogoutIcon from "../../../../Assets/upgrade-images/sidebar_icons/logout.svg";
import SidebarCloseIcon from "../../../../Assets/upgrade-images/sidebar_icons/close.png";
import { limitString } from "../../../../utils/Helperfunctions";
import { logoutUserRedirect } from "../../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew";

class NewLeftSideBar extends Component {


  state = {
    profileImg: "",
    dashboardMessageCount: 0,
    miniSidebar: true,
    sidebarToggle: false
  };
  handleClick = this.handleClick.bind(this);

  handleClick() {
    this.setState((prevState) => ({
      sidebarToggle: !prevState.sidebarToggle,
    }));
  }

  handleToggle = () => {
    const { sidebarToggle } = this.state;
    this.setState({ sidebarToggle: !sidebarToggle })
  }

  componentDidMount() {
    const socket = socketIOClient(
      ChatBaseUrl
      // { transports: ['websocket'] }
    );
    this.setState({ socket }, () => {
      this.joinSocket();
      this.listenSocket();
    });
  }

  joinSocket = () => {
    this.state.socket.emit("join_msg_user", { _id: this.props.Id });
  };

  resetDashboardNotification = () => {
    const { dashboardNotificationCount, clearDashboardNotification } = this.props;
    if (dashboardNotificationCount) {
      clearDashboardNotification();
      this.loadDashboardData();
    }
    this.handleClick();
  };

  resetMessageNotification = () => {
    const { messageNotificationCount, clearMessageNotification } = this.props;
    if (messageNotificationCount) {
      clearMessageNotification();
    }
  };

  listenSocket = () => {
    this.state.socket.on(this.props.Id, (resp) => {
      const { CurrentRole, Id } = this.props;
      // console.log('socket response 1', resp)
      const countMsg = CurrentRole === 1 && Id === resp?._id ? resp.client_message_count : resp?.creator_message_count;
      this.props.updateMessageNotificationCount(countMsg);

      const count = CurrentRole === 1 && Id === resp?._id ? resp?.client_dashboard : resp?.creator_dashboard;
      this.props.updateDashboardNotificationCount(count);

      const completedCount =
        CurrentRole === 1 && Id === resp?._id ? resp?.client_dashboard_completed : resp?.creator_dashboard_completed;
      this.props.updateCompletedProjectNotificationCount(completedCount);
    });
  };

  loadDashboardData = () => {
    if (this.props.CurrentRole === 1) {
      this.props.LoadInCompleteBrief();
    } else {
      this.props.LoadInComingBrief();
    }
  };

  showCloseIconRoutes = [
    "/uploadproject/choose_collateral",
    "/uploadprojects/project_details",
    "/self-service-hire",
    "/profile_selection",
    "/project_details/project",
    "/self-service-hire/budget",
    "/self-service-hire/unit_selection",
    "/self-service-hire/deadline",
    "/self-service-hire/type",
    "/self-service-hire/style",
    "/contactus",
  ];

  Logout = () => {
    const that = this;
    localStorage.removeItem("SignUpidToken");
    firebaseIs
      .auth()
      .signOut()
      .then(function () {
        // alert("signOut successfully")
        that.props.setIndexRoutingState({ authIs: false });
        that.props.loadMe({
          name: "UPDATE_LOAD_LOGGEDIN_USER",
          value: [],
        });
        // that.props.history.push("/");
        logoutUserRedirect()
      })
      .catch(function (error) {
        console.log("logout error", error);
      });
  };

  render() {
    const { userProfile } = this.props;
    const { current_set_role, review_status } = userProfile;
    const { location, CurrentRole, ApprovedStatus, isAdmin, messageNotificationCount, dashboardNotificationCount } = this.props;
    const { ProfileImage } = this.props.Me;

    const onServicePage = location.pathname.includes("/services");

    let [, collateral] = location.pathname.split("/services");

    collateral = collateral ? collateral.substring(1).split("-").join(" ") : "";

    collateral = collateral
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

    const { sidebarToggle } = this.state;
    return (
      <React.Fragment>
        {/* <section className={`dasboard_sidebar ${this.state.miniSidebar == false ? "" : "mini_sidebar"}`}>
          <div className="menu_icon" onClick={this.handleClick}>
            <img src={SidebarMenuIcon} className="img-fluid" />
          </div>
          <div className="close_icon" onClick={this.handleClick}>
            <img src={SidebarCloseIcon} className="img-fluid" />
          </div>

          <nav className="navbar side_bar_nav navbar-expand-lg p-0">
            <a className="navbar-brand side_bar_nav_brandname" href="#">
              <img src={HeaderIcon} className="expand_logo" />
              <img src={LogoG} className="mini_logo" />
            </a>
            <div className="side_nav_content" id="navbarSupportedContent">
              <ul className="navbar-nav side_bar_nav_list mr-auto">
                <li className="nav-item side_bar_nav_item side_bar_nav_active">
                  <div className="side_bar_icon">
                    <img src={DashboardIcon} />
                  </div>
                  <Link
                    className="nav-link side_bar_link side_bar_active_link"
                    to="/dashboard"
                    onClick={this.resetDashboardNotification}
                  >
                    Dashboard
                    {dashboardNotificationCount ? (
                      <span className="dashboard_new_box font-family-sans text-white bg-primary font-weight-400 border-radius-36">
                        New
                      </span>
                    ) : null}
                  </Link>
                </li>

                {current_set_role === 2 && ApprovedStatus === 1 && (
                  <li className="nav-item side_bar_nav_item ">
                    <div className="side_bar_icon">
                      <img src={ProfileIcon} />
                    </div>
                    <Link className="nav-link side_bar_link" to="/CreatorProfile">
                      Profile
                    </Link>
                  </li>
                )}

                {current_set_role == 3 ? "" :
                  <li className="nav-item side_bar_nav_item ">
                    <div className="side_bar_icon">
                      <img src={FolderOpenIcon} />
                    </div>
                    <Link
                      className="nav-link side_bar_link"
                      to={current_set_role === 2 ? "/Profile/accountSecurity" : "/Profile/MyAccount"}
                    >
                      Account
                    </Link>
                  </li>
                }

                {current_set_role && current_set_role != 3 && (
                  <li className="nav-item side_bar_nav_item active">
                    {current_set_role === 2 ? (
                      <>
                        <div className="side_bar_icon">
                          <img src={UploadIcon} />
                        </div>
                        <Link
                          className="nav-link side_bar_link"
                          to="/uploadproject/choose_collateral"
                          onClick={(e) => review_status !== 2 && e.preventDefault()}
                        >
                          Upload your work
                        </Link>
                      </>
                    ) : onServicePage ? (
                      <>
                        <div className="side_bar_icon">
                          <img src={ProfileIcon} />
                        </div>
                        <Link
                          className="nav-link side_bar_link"
                          onClick={(e) => {
                            this.props.resetPostProjectData();
                            localStorage.setItem("prevPath", window.location.pathname);
                          }}
                          to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                        >
                          Hire An Expert
                        </Link>
                      </>
                    ) : (
                      <>
                        <div className="side_bar_icon">
                          <img src={ProfileIcon} />
                        </div>
                        <Link
                          className="nav-link side_bar_link"
                          onClick={(e) => {
                            this.props.resetPostProjectData();
                            localStorage.setItem("prevPath", window.location.pathname);
                          }}
                          to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                        >
                          Hire An Expert
                        </Link>
                      </>
                    )}
                  </li>
                )}

                <li className="nav-item side_bar_nav_item active">
                  <div className="side_bar_icon">
                    <img src={MessageIcon} />
                  </div>
                  <a className="nav-link side_bar_link" href="/" onClick={this.Logout}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className="login_user d-flex align-items-center mr-auto">
            <div className="login_user_profile_image mr-2">
              <img
                src={this.props?.userProfile?.profile_image ? BaseImageUrl + this.props.userProfile.profile_image : ""}
                className="icon_img mr-1"
              />
            </div>

            <div className="login_user_name">
              <p className="mb-0 text_light_gray_md">
                {this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name}
              </p>
            </div>
          </div>
        </section> */}
        <header className="app-header fixed-top">
          <div className="app-header-inner d-md-none d-flex align-items-center">
            <div className="container-fluid py-2">
              <div className="app-header-content">
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto">
                    <a id="sidepanel-toggler" className="sidepanel-toggler d-inline-block d-xl-none" href="#">
                      <i className="fa fa-bars" onClick={this.handleToggle} />
                    </a>
                  </div>
                  <div className="app-utilities col-6  col-sm-4">
                    <div className="app-branding-mobile">
                      <a className="app-logo" href="#">
                        <img className="logo-icon me-2" src={HeaderNewLogo} alt="logo" />
                        <img className="logo_text me-2" src={HeaderLogoText} alt="logo" />
                      </a>
                    </div>
                  </div>
                  {this.props?.authIs ?
                    <div className="col-auto">
                      <div className="dropdown">
                        <a href="#" className="dropdown-toggle" id="sidebarAvatar" role="button" data-toggle="dropdown">
                          <div className="avatar-parent-child">
                            <img src={this.props?.userProfile?.profile_image ? BaseImageUrl + this.props.userProfile.profile_image : ""} className="avatar rounded-circle" />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </a>
                        <ul className="dropdown-menu p-2 py-3" aria-labelledby="sidebarAvatar">
                          <li>
                            <NavLink to={current_set_role === 2 ? `/creatorProfile` : `/dashboard`} className="d-flex justify-content-start mb-3 br-btm"
                              onClick={() => {
                                this.handleClick();
                              }}
                            >
                              <div className="icon_list mr-2">
                                <img src={ProfileIcon} className="img-fluid" />
                              </div>
                              <span className="links_name text-success">{this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name}</span>
                              <span className="tooltip">{this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name}</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="" className="d-flex justify-content-start"
                              onClick={() => {
                                // this.props.history.push("/");
                                this.Logout();
                                // this.handleClick();
                              }}
                            >
                              <div className="icon_list mr-2">
                                <img src={LogoutIcon} className="img-fluid" />
                              </div>
                              <span className="links_name text-success">Logout</span>

                              <span className="tooltip">Logout</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div> : null}

                </div>
              </div>
            </div>
          </div>{/*//app-header-inner*/}

          <div className={`sidebar mini_active ${sidebarToggle ? "show-sidebar" : "hide-sidebar"}`} id="app-sidepanel">
            <div className="menu_icons d-block d-lg-none">
              {this.state.sidebarToggle == true ? (
                <>
                  <img src={SidebarCloseIcon} className="" id="btn" onClick={this.handleClick} />
                </>
              ) : (
                <>
                </>
              )}
            </div>
            <div className="logo_content d-none d-md-block">
              <div
                className="logo"
                onClick={() => {
                  this.props.history.push("/");
                  this.handleClick();
                }}
              >
                <div className="logo_icon">
                  <img src={HeaderNewLogo} className="img-fluid" />
                </div>
                <div className="logo_text">
                  <img src={HeaderLogoText} className="img-fluid links_name" />
                </div>
                {/* <img src={HeaderIcon} className="img-fluid expand_logo" />
              <img src={LogoG} className="img-fluid mini_logo" /> */}
              </div>
            </div>
            {this.props?.authIs ?
              <>
                <ul className="nav_list mob-mt-40">

                    {
                      (current_set_role !== 2 ) &&
                      <>
                        <li className="user-role">
                                         <NavLink
                          onClick={(e) => {
                            this.props.resetPostProjectData();
                            localStorage.setItem("prevPath", window.location.pathname);
                            this.handleClick();
                          }}
                          to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                          className="bg-success"
                        >
                          <div className="icon_list">
                            <img src={HireExpert} className="img-fluid" />
                          </div>
                          <span className="links_name">Hire An Expert</span>
                          <span className="tooltip">Hire An Expert</span>
                        </NavLink>
                    </li>
                      </>

                    }

                  <li>
                    <NavLink to="/dashboard" className="" onClick={this.resetDashboardNotification}>
                      <div className="icon_list">
                        <img src={DashboardIcon} className="img-fluid" />
                      </div>
                      <span className="links_name">Dashboard</span>
                      <span className="tooltip">Dashboard</span>
                    </NavLink>
                  </li>
                  {current_set_role === 2 && ApprovedStatus == 1 && (
                    <li>
                      <NavLink to="/CreatorProfile" onClick={this.handleClick} className="">
                        <div className="icon_list">
                          <img src={ProfileIcon} className="img-fluid" />
                        </div>
                        <span className="links_name">Profile</span>
                        <span className="tooltip">Profile</span>
                      </NavLink>
                    </li>
                  )}

                  {current_set_role && current_set_role != 3 && (
                                            <>
                      {current_set_role === 2 && (

                        <li className="user-role 1111">
                        <NavLink
                          onClick={(e) => {
                            review_status !== 2 && e.preventDefault();
                            this.handleClick();
                          }}
                          to="/uploadproject/choose_collateral"
                          className="text-success"
                        >
                          <div className="icon_list">
                            <img src={UploadIcon} className="img-fluid" />
                          </div>
                          <span className="links_name">Upload a Project</span>
                          <span className="tooltip">Upload a Project</span>
                        </NavLink>
                        </li>
                        
                      ) 
                     
                      // : onServicePage ? (
                      //   <NavLink
                      //     onClick={(e) => {
                      //       this.props.resetPostProjectData();
                      //       localStorage.setItem("prevPath", window.location.pathname);
                      //       this.handleClick();
                      //     }}
                      //     to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                      //     className="bg-success"
                      //   >
                      //     <div className="icon_list">
                      //       <img src={ProfileIcon} className="img-fluid" />
                      //     </div>
                      //     <span className="links_name">Hire An Expert</span>
                      //     <span className="tooltip">Hire An Expert</span>
                      //   </NavLink>
                      // ) : (
                      //   <NavLink
                      //     onClick={(e) => {
                      //       this.props.resetPostProjectData();
                      //       localStorage.setItem("prevPath", window.location.pathname);
                      //       this.handleClick();
                      //     }}
                      //     to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                      //     className="bg-success"
                      //   >
                      //     <div className="icon_list">
                      //       <img src={HireExpert} className="img-fluid" />
                      //     </div>
                      //     <span className="links_name">Hire An Expert</span>
                      //     <span className="tooltip">Hire An Expert</span>
                      //   </NavLink>
                      // )
                      }
                    </>
                    
                  )}

                  {current_set_role == 3 ? (
                    ""
                  ) : (
                    <li>
                      <NavLink to={current_set_role === 2 ? "/Profile/accountSecurity" : "/Profile/MyAccount"} onClick={this.handleClick} className="">
                        <div className="icon_list">
                          <img src={current_set_role === 2 ? SettingIcon : FolderOpenIcon} className="img-fluid" />
                        </div>
                        <span className="links_name">{current_set_role === 2 ? "Settings" : "Settings"}</span>
                        <span className="tooltip">{current_set_role === 2 ? "Settings" : "Settings"}</span>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to={"/contactus"} onClick={this.handleClick} className="">
                      <div className="icon_list">
                        <img src={messageIcon} className="img-fluid" />
                      </div>
                      <span className="links_name">Contact Us</span>
                    </NavLink>
                  </li>


                  {/* <li>
              <Link
              to=""> 
                <div className="icon_list">
                  <img src={FolderOpenIcon} className="img-fluid" />
                </div>
                <span className="links_name">Projects</span> 
                <span className="tooltip">Projects</span>
              </Link>
              
            </li>
            <li>
              <Link 
              to=""
              > 
                <div className="icon_list">
                  <img src={ProfileIcon} className="img-fluid" />
                </div>
                <span className="links_name">Clients</span> 
                <span className="tooltip">Clients</span>
              </Link>
              
            </li>
            <li>
              <Link
              to=""
              > 
                  <div className="icon_list">
                    <img src={ReceiptIcon} className="img-fluid" />
                  </div>
                  <span className="links_name">Earnings</span> 
                  <span className="tooltip">Earnings</span>
              </Link>
              
            </li>
            <li>
              <Link
              to=""
              > 
                <div className="icon_list">
                  <img src={FolderIcon} className="img-fluid" />
                </div>
                <span className="links_name">File Browser</span> 
                <span className="tooltip">File Browser</span>
              </Link>
              
            </li>  
            <li>
              <Link
              to=""
              > 
                <div className="icon_list">
                  <img src={SettingIcon} className="img-fluid" />
                </div>
                <span className="links_name">Setting</span> 
                <span className="tooltip">Setting</span>
              </Link>
              
            </li>
            <li>
              <Link> 
                <div className="icon_list">
                  <img src={ContactIcon} className="img-fluid" />
                </div>
                <span className="links_name">Contact Us</span> 
                <span className="tooltip">Contact Us</span>
              </Link>
              
            </li> */}
                </ul>
                <div className="sidebar_bottom d-none d-lg-block">
                  {/* <div className="user_img">
              <div
                className="user_details"
                onClick={() => {
                  this.props.history.push("/creatorProfile");
                  this.handleClick();
                }}
              >
                <img
                  src={this.props?.userProfile?.profile_image ? BaseImageUrl + this.props.userProfile.profile_image : ""}
                  alt=""
                />
                <div className="name_job cursor-pointer">
                  <div className="name">{this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name}</div>
                </div>
              </div> 
              <span
                id="log_out"
                onClick={() => {
                  this.Logout();
                  this.props.history.push("/");
                  this.handleClick();
                }}
              >
                <img src={LogoutIcon} className="img-fluid" />
              </span>
            </div> */}
                  <ul className="user_profile_wrapper mt-0">
                    <li>
                      <NavLink
                        //  to={current_set_role === 2 ? `/creatorProfile` : `/dashboard`}
                        // && this.props.Me.update_status == 2
                        to={current_set_role === 2 ? this.props.Me.ReviewStatus == 2 ? `/creatorProfile` : "/dashboard" : "/Profile/MyAccount"}
                        className=""
                        onClick={() => {
                          this.handleClick();
                        }}
                      >
                        <div className="icon_list">
                          <img src={this.props?.userProfile?.profile_image ? BaseImageUrl + this.props.userProfile.profile_image : ""} className="img-fluid rounded-circle" />
                        </div>
                        <span className="links_name">{limitString(this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name,18)}</span>

                        <span className="tooltip">{this.props?.userProfile?.first_name + " " + this.props?.userProfile?.last_name}</span>
                      </NavLink>
                    </li>
                    <li className="user_profile_border_bottom"></li>
                    <li>
                      <NavLink to="" className=""
                        onClick={() => {
                          this.Logout();
                        }}
                      >
                        <div className="icon_list">
                          <img src={LogoutIcon} className="img-fluid" />
                        </div>
                        <span className="links_name">Logout</span>

                        <span className="tooltip">Logout</span>
                      </NavLink>
                    </li>
                  </ul>

                </div>
              </> : 
                   <ul className="nav_list mob-mt-40">
                   {
                     (current_set_role !== 2 ) &&
                     <>
                       <li className="user-role">
                        <NavLink
                         onClick={(e) => {
                           this.props.resetPostProjectData();
                           localStorage.setItem("prevPath", window.location.pathname);
                           this.handleClick();
                         }}
                         to={{ pathname: `/hire`, state: { prevPath: window.location.pathname } }}
                         className="bg-success"
                       >
                         <div className="icon_list">
                           <img src={HireExpert} className="img-fluid" />
                         </div>
                         <span className="links_name">Hire An Expert</span>
                         <span className="tooltip">Hire An Expert</span>
                       </NavLink>
                   </li>
                     </>

                   }
                    <li>
                      <NavLink to="" className=""
                        onClick={() => {
                          this.props.history.push("/");
                          this.handleClick();
                        }}
                      >
                        <div className="icon_list">
                          <img src={LogoutIcon} className="img-fluid" />
                        </div>
                        <span className="links_name">Login</span>

                        <span className="tooltip">LOgin</span>
                      </NavLink>
                    </li>
               </ul>
              }
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapState = (store) => {
  const { Me, loading } = store.globalReducer;
  const { CurrentMilestone } = store.postProject;
  const { ProjectName, CurrentChat } = store.chat;

  return {
    CurrentRole: Me.CurrentRole,
    Id: Me.Id,
    messageNotificationCount: Me.messageNotificationCount,
    dashboardNotificationCount: Me.dashboardNotificationCount,
    ReviewStatus: Me.ReviewStatus,
    ApprovedStatus: Me.ApprovedStatus,
    isAdmin: Me.isAdmin,
    Projects: store.chat.Projects,
    CurrentMilestone: CurrentMilestone,
    ProjectName: ProjectName,
    chat_connection_id: CurrentChat && CurrentChat.chat_connection_id,
    loading: loading,
  };
};

const mapDispatch = (dispatch) => ({
  loadProjectChat: (obj) => dispatch({ type: "LOAD_PROJECT_CHAT", payload: obj }),
  OpenPopup: (text) => dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  clearCreatorDastboard: () => dispatch({ type: "CLEAR_DASHBOARD_DATA", values: null }),
  updateMessageNotificationCount: (count) => dispatch({ type: "UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA", payload: count }),
  updateDashboardNotificationCount: (count) => dispatch({ type: "UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA", payload: count }),
  updateCompletedProjectNotificationCount: (count) =>
    dispatch({ type: "UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA", payload: count }),
  clearMessageNotification: () => dispatch({ type: "CLEAR_MESSAGE_NOTIFICATION_SAGA", payload: null }),
  clearDashboardNotification: () => dispatch({ type: "CLEAR_DASHBOARD_NOTIFICATION_SAGA", payload: null }),
  // resetNotification: (chat_connection_id) => dispatch({type: 'RESET_NOTIFICATION_SAGA', payload:chat_connection_id })
  // loadProjectChat: (projectId, projectName,projectStatus) => dispatch({ type: 'LOAD_PROJECT_CHAT', payload: {projectId, projectName, projectStatus} }),
  LoadInComingBrief: (id) => dispatch({ type: "LOAD_INCOMING_BRIEF", payload: { search: id } }),
  LoadInCompleteBrief: (id) => dispatch({ type: "LOAD_INCOMPLETE_BRIEF", payload: { search: id } }),
  resetPostProjectData: (id) => dispatch({ type: "RESET_ALL_DATA", payload: id }),
  activateAdminChat: () => dispatch({ type: "ACTIVATE_ADMIN_CHAT_SAGA", payload: null }),
});
export default connect(mapState, mapDispatch)(NewLeftSideBar);
