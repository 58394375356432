import React, { useEffect, useState } from "react"
import { releventProjects, relevent_projects } from "../../../../../../store/action/proposal.action";
import { useDispatch, useSelector } from "react-redux";
import UploadPlusIcon from "../../../../../../Assets/upgrade-images/submit_proposal/upload_media_plus.svg";
import ReleventProjectsModal from "../../PopopCreator/ReleventProjects";
import { getAPI } from "../../../../../../utils/API";
import { getThumbnailImage } from "../../../../../../utils/Helperfunctions";
import { isViewableFile, renderDifferentFilesThumbnail } from "../../../../Common/Common/FileRenderMessage";
import TrashImage from '../../../../../../Assets/upgrade-images/submit_proposal/trash.svg';

const ReleventProjects = (props) => {
    const [ userName, setUserName ] = useState('')
    const [ search , setSearch ] = useState('')
    const _ProjectsIs = useSelector((state) => state.releventProjects)
    const creatorFavProIs = _ProjectsIs?.releventProjectIs
    const project_ids = _ProjectsIs?.project_ids
    const dispatch = useDispatch() 
    
    const getcollateralList = async() => {
        const res = await getAPI('/users/user-profile');
        const userNameIs = res?.data?.data?.username
        const data = { userNameIs, search }
        setUserName(userNameIs)
        dispatch(releventProjects(data))
    }

  useEffect(() => {
    getcollateralList();
  }, []);

  useEffect(() => {
    getcollateralList();
  }, [search]);

    const handleRemove = (index , obj) => {
      const releventProject = creatorFavProIs?.filter((_, i) => i !== index)
      const project_idIs = project_ids?.filter(el => el._id !== obj?._id)
      setProjectIds(releventProject , project_idIs)
    }

    const setProjectIds = (releventProject , project_ids) => {
      const data = { project_ids , releventProject}
      dispatch(relevent_projects(data))
    }
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {/* description */}
      <div className="col-lg-12 px-0 py-3">
        {/* <p className="discription_common">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
          laoreet est augue faucibus ac. Est morbi cras nisl, non quis massa
          mattis sit arcu. Facilisis purus vitae volutpat tincidunt consectetur
          pulvinar convallis sed. Nulla risus sit in enim nam aliquam sapien
          phasellus rhoncus.
        </p> */}
      </div>
      {/* upload_media */}
      {creatorFavProIs?.length == 3 ? null :
      <div className="col-lg-12 px-0 mb-4">
        <div
          className="upload_box"
          data-toggle="modal"
          data-target="#releventproject"
        >
          <div className="upload_icon d-flex cursor_pointer">
            <img src={UploadPlusIcon} className="gallery_icon img-fluid mb-2" />
            <p className="mb-0 text_black_xs_w_600">Add Projects</p>
          </div>
        </div>
      </div>
      }
      {/* end upload media */}

            {/* cards */}
            <div className='col-lg-12 px-0'>
            <div className='row mt-5'>
                {/* cards */}
                {creatorFavProIs?.length ?
                 creatorFavProIs?.map((obj, index)=>{
                    return(
                    <div className='col-lg-4 mb-4 mb-lg-0 featured_project_card' key={index}>
                        <div class="card featured_card">
                            <RenderImage
                              images={obj.images_or_links}
                            />
                            <div class="card-body featured_card_body">
                                <div className='row m-auto featured_card_body_text p-3'>
                                <p class="card_font mb-0">{obj?.title}</p> 
                                </div>                           
                            </div>
                            <div className='trash_box' onClick={()=>handleRemove(index , obj)}>
                                <img className='upload_trash_icon' src={TrashImage}/>
                            </div>
                        </div>
                        
                    </div> )
                 })
                : ''}
            </div>  
            </div>
            {/* end cards */}
            {/* <div  className='edit_proposal d-flex align-items-center col-lg-12 px-0 py-4'>
              <img src={EditIcon} className="icon_img mr-1"/> 
              <p className='mb-0 mr-3 edit_proposal_text anchor_text'>Edit Proposal Type</p>
            </div> */}
 
      {/* Modal */}
      <ReleventProjectsModal
        {...props}
        projects={_ProjectsIs}
        userName={userName}
        handleSearch={handleSearch}
        search={search}
      />
    </>
  );
};


const RenderImage = ({ images }) => {
  let thumbnailObj;
  const thumbnailIs = images?.find((el) => el?.isThumbnail)
  if(thumbnailIs){
    thumbnailObj = thumbnailIs
  }else{
    thumbnailObj = images[0]
  }
  // const {type}=image;
  // const {value}=image;
  const type = thumbnailObj?.type
  const value = thumbnailObj?.value
  const [thumbnail, setThumbnail] = useState(null);
  useEffect(() => {
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url);
    };
    if (type === "url") {
      getThumbnailImageFunc(value);
    } else {
      setThumbnail(value);
    }
  }, [type,value]);

  return (
    <div
      className="project-view"
      style={{
        backgroundImage: `url("${
          isViewableFile(thumbnailObj.value)
            ? renderDifferentFilesThumbnail(thumbnailObj.value)
            : thumbnail
        }")`,
      }}
    ></div>
  );
};

export default ReleventProjects;
