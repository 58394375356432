import React from 'react';
import { connect } from 'react-redux';
import AwardsForm from './AwardsForm';
// import BtnLoader from '../../Common/Common/BtnLoader';
import Loader from './../../../unauthorized/Common/Loader/Loader';
import Delete from '../../../../Assets/upgrade-images/submit_proposal/trash.svg';
import Edit from '../../../../Assets/upgrade-images/account/edit.svg';

class Awards extends React.Component {
  child = React.createRef();
  state = {
    edit: -1,
    id: null,
    singleAwardData: null,
  };

  enableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: true,
    });
  };

  disableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: false,
    });
  };

  onEditEnable = async (e, k) => {


    if (this.state.id === k) {
      this.setState({ edit: -1, singleAwardData: null, id: null });
      this.disableForm();
    } else {
      this.setState({ edit: -1, singleAwardData: null, id: null }, () => {
        const award = this.props.Awards.filter((el) => el._id === e._id)[0];
        this.setState({ edit: k, singleAwardData: award, id: k });
        // console.log('get local ', award);
      });
    }
  };

  closePopup = () => {
    this.props.closePopup();
  };

  handleAwardOnChange = (event) => {
    let award = event.target.value;
    this.setState({
      award: award,
    });
  };

  handleAwards = (event) => {
    if (event.charCode === 13) {
      let awards = this.props.UpdateAwards;
      let award = this.state.award;
      if (awards.length <= 4) {
        awards.push({ name: award });
      }
      this.setState(
        {
          awards,
          award: '',
        },
        (e) =>
          this.props.onFieldChange({
            name: 'AWARD_UPDATE',
            value: awards,
          })
      );
    }
  };

  deleteItems = (obj, type) => {
    if (type === 'award') {
      let myNewArray = this.props.UpdateAwards.filter(function (items) {
        return items.name !== obj.name;
      });
      this.setState(
        {
          awards: myNewArray,
        },
        (e) =>
          this.props.onFieldChange({
            name: 'AWARD_UPDATE',
            value: myNewArray,
          })
      );
    }
  };

  closeUpdateForm = () => {
    this.setState({ edit: -1, singleAwardData: null, id: null });
    this.disableForm();
  };

  handleSubmit = () => {
    this.child.current.onSubmit();
  };

  deleteAwards = (obj) => {
    const isConfirm = window.confirm('Do you want to delete the award?')
    if(isConfirm)
      this.props.DeleteUserAward(obj._id)
  }

  render() {
    // console.log('render');
    // console.log('state award.js ', this.state);
    // console.log('props award.js ', this.props);
    let { Awards, formOpen, saving } = this.props;
    const { edit, singleAwardData, id } = this.state;
    const ID = id === null ? id : id.toString;
    let submitBtns =
      formOpen || ID ? (
        edit !== -1 ? (
          <button
            type="submit"
            className={`main-btn col-sm-3 col-6 mx-2 mb-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Update'}
          </button>
        ) : (
          <button
            type="submit"
            className={`main-btn col-sm-3 col-6 mx-2 mb-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Save Changes'}
          </button>
        )
      ) : null;
    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditLanguagesModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">
                          Awards & Mentions
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100 overflow-content edit-work-height awards">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <div className="form-group">
                        {/* <label
                          className="label font-weight-600 font-14 mob-font-13"
                          htmlFor="first"
                        >
                          Awards & Mentions
                        </label> */}
                        {Awards.length > 0 ? (
                          Awards.map((m, k) => {
                            return (
                              <React.Fragment key={k}>
                                <div className='mb-3'>
                                <div
                                  className={`card wrapper-form shadow-none ${
                                    edit === k ? 'border-bottom-0-10' : ''
                                  }`}
                                >
                                  <div className="card-body">
                                    <div className="row m-0 align-items-center py-3 px-2 mob-pr-0 mob-pl-0 mob-justify-content-between">
                                      <div className="col-7 col-sm-10">
                                        <h2 className="text-capitalize serv-title m-0 mob-font-14">
                                          {m.type}
                                        </h2>
                                        <div className="form-group mb-0 pt-1 mob-hidden">
                                          <span className="sub_title font-weight-500 text-capitalize">
                                            {m.type === 'awards' ? m.award_name : m.title}
                                          </span>
                                          <span className={`sub_title font-weight-500 px-4 text-capitalize ${!m.url ? 'is-not-active' : ''}`}>
                                          {m.type === 'awards' ? m.awarder : m.publication}
                                          </span>
                                          {m.url ? <span className="sub_title font-weight-500  text-capitalize">
                                          {m.url}
                                          </span> : null}
                                        </div>
                                      </div>
                                      <div className="col-5 col-sm-2 modal-tab tabs-icon">
                                        <div className="text-right">
                                          <span>
                                          <img src={Edit} className='pr-1' onClick={() =>
                                                this.onEditEnable(m, k) }/>
                                          </span>
                                          <span className='cursor-pointer ml-3' onClick={() => this.deleteAwards(m)}><img src={Delete}/></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {edit === k ? (
                                  <AwardsForm
                                    ref={this.child}
                                    award={singleAwardData}
                                    edit={edit}
                                    closeUpdateForm={this.closeUpdateForm}
                                  />
                                ) : (
                                  <></>
                                )}
                                </div>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      {edit === -1 ? (
                        formOpen || Awards.length < 1 ? (
                          <AwardsForm
                            ref={this.child}
                            award={singleAwardData}
                            edit={edit}
                            closeUpdateForm={this.closeUpdateForm}
                          />
                        ) : (
                          <div className="w-100 py-4 dflex">
                            <a
                              href="#/"
                              className="font-13 mob-font-12 font-weight-600 text-center"
                              onClick={this.enableForm}
                            >
                              <i className="fas fa-plus mr-1"></i> Add award/Mentions
                            </a>
                          </div>
                        )
                      ) : null}
                    </div> 
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top">
                    <div className="form-group text-right pr-3 pt-3">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-danger col-sm-2 col-5 mx-2 mb-2 font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {submitBtns}
                      {Awards.length === 0 ? (
                        <button
                          type="submit"
                          className={`main-btn col-sm-3 col-6 mx-2 mb-2 ${saving ? 'p-0' : ''}`}
                          disabled={saving}
                          onClick={this.handleSubmit}
                        >
                          {saving ? <Loader profileCreator="true"/> : 'Save Changes'}
                        </button>
                      ) : null}
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  let globalReducer = store.globalReducer;

  return {
    saving: globalReducer.saving,
    formOpen: globalReducer.formOpen,
    closeModal: creatorProfile.closeModal,
    Awards: creatorProfile.Awards,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  // onFieldChange: (e) =>
  //   dispatch({
  //     type: 'ON_FIELDCHANGE_SAGA',
  //     payload: { name: e.name, value: e.value },
  //   }),
  onChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UpdateUserAward: (e) => dispatch({ type: 'UPDATE_AWARD_SAGA', payload: e }),
  DeleteUserAward: (id) => dispatch({ type: 'DELETE_AWARD_SAGA', payload: id }),
  AddUserAwards: (e) => dispatch({ type: 'ADD_AWARD_SAGA', payload: e }),
});

export default connect(mapState, mapDispatch)(Awards);
