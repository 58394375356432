import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../../../../utils/API";
import Loader from "../../../../../Common/Common/Loader";
import ViewBrief from "../../../ViewBrief";
import ViewProp from "../../../CommonComp/ViewProp";
import DetailSideBar from "../../../CommonComp/DetailSideBar";

const OverviewCreator = (props) => {
  const [check, setCheck] = useState("");
  const getIsSummitProp = async (id, user_id) => {
    try {
      const res = await getAPI(`/postProject/get-users-proposals?user_id=${user_id}&post_project_id=${id}&role=2`);
      if (res) {
        let check = res?.data?.proposals?.length > 0 ? "yes" : "no";
        setCheck(check);
      }
    } catch (err) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (props?.id) {
      getIsSummitProp(props?.id, "");
    }
  }, []);

  return (
    <>
      {check?.length > 0 ? (
        check === "yes" ? (
          <>
            <div className="row">
              <ViewProp
                id={props?.id}
                userId=""
                role={2}
                isOverview={true}
                {...props}
                col={9}
                isClient={false}
                showHistory={true}
                showChanges={true}
              />
              <DetailSideBar {...props} showProjDetail="yes" />
            </div>
          </>
        ) : (
          <div className="row">
            <ViewBrief
              id={props?.id}
              {...props}
              col={9}
              showReject={true}
              questionStatus={props?.projectDataStates?.data?.creator_status_brief}
              showQuestion={props?.projectDataStates?.data?.creator_status_brief == 0 ? true : false}
              showSubmit={true}
            />
            <DetailSideBar {...props} showProjDetail="no" />
          </div>
        )
      ) : (
        <Loader isCreateModal={true} style={{ marginTop: "18rem" }} />
      )}
    </>
  );
};

export default OverviewCreator;
