import React, { Component, Fragment } from 'react';
import { CollateralBaseUrl } from '../../../../../utils/BaseUrl';


export default class CompanyAndProjectQuestions extends Component {
    isImage = (name) => {
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name)
    }
  render() {
      const {questions} = this.props
    return ( 
        <Fragment>
        {questions.map((ques,index) => {
            if(ques.type === 'dropdown' && ques?.answer){
                return(
                    <Fragment key={index}> 
                    <div className="Project-duration border-bottom pb-3 mb-4">
                        <span className="sub-design text_light_gray_md">{ques.question}</span>
                        <p  className="font_semi_bold_w_600 mb-0">
                            {ques.answer.map((ans, i) => <Fragment key={i}>{ans.item}</Fragment> )}
                        </p>
                    </div>
                    </Fragment>
                )
            } else if((ques.type === "checkbox_with_images_with_text" || ques.type === "radio_button_with_images_with_text") && ques?.answer) {
                // console.log(ques);
                return (
                    <Fragment key={index}>
                    <div className="Project-duration border-bottom mb-2">
                        <span  className="sub-design text_light_gray_md">{ques.question}</span> 
                        
                        <div className="onboarding_style_questions">
                            <ul key={index} className="content p-0">
                                {ques.answer.map((ans, i) => 
                                <li key={i}>
                                    <div className={`deadline position-relative cursor-default`} style={{backgroundSize:'cover', backgroundImage:`url("${CollateralBaseUrl + ans.image}")`}}>
                                        {/* <img src={CollateralBaseUrl + ans.image} alt='' className="img-size" /> */} 
                                    </div>
                                    <p className="style_name">{ans.item.length > 15 ? ans.item.substring(0,15) + '...' : ans.item}</p>
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    </Fragment>
                )
            }  else if((ques.type === "long_answer" && ques?.answer) || (ques.type === 'short_answer' && ques?.answer)) {
                return(
                    <Fragment key={index}>
                        <div className="Project-duration mb-4 border-bottom">
                            <span className="sub-design text_light_gray_md">{ques.question}</span>
                            <p key={index} className="font_semi_bold_w_600 mb-0">
                                {ques.answer.map((ans,i) => <Fragment key={i}> {ans.item} </Fragment>)}
                            </p>
                        </div>
                    </Fragment>
                )
            }  
            else if(ques.type === "scale_range" && ques?.answer){
                return(
                    <Fragment key={index}>
                    <div className="Project-duration mb-4 border-bottom">
                        <span className="sub-design text_light_gray_md">{ques.question}</span>
                        <p key={index} className="  font_semi_bold_w_600 mb-0">
                            {ques.answer.map((ans,i) => <Fragment key={i}>{ans.start_range}-{ans.end_range}</Fragment>)}
                        </p>
                    </div>
                    </Fragment>
                )   
            }
            else if(ques.type === "date_picker" && ques?.answer){
                return(
                    <Fragment key={index}>
                    <div className="Project-duration border-bottom mb-4">
                        <span className="sub-design text_light_gray_md">{ques.question}</span>
                        <p key={index} className="font_semi_bold_w_600 mb-0">
                            {ques.answer.map((ans,i) => <Fragment key={i}>{new Date(ans.item).toDateString()}</Fragment>)}
                        </p>
                    </div>
                    </Fragment>
                )   
            }
            else if(ques.type === "file_upload" && ques?.answer){
                return(
                    <Fragment key={index}>
                    <div className="Project-duration border-bottom">
                        <div className="">
                            <span className="sub-design text_light_gray_md">{ques.question}</span>
                            <p key={index} className="font_semi_bold_w_600 mb-0"> 
                            {ques?.answer?.map((ans,i) => 
                                <Fragment key={i}>
                                    {this.isImage(ans.item) ?
                                    <div className="onboarding_style_questions">
                                        <ul className="content p-0">
                                            <li key={i}>
                                                <div className={`deadline position-relative cursor-default`} style={{backgroundSize:'cover', backgroundImage:`url("${CollateralBaseUrl + ans.item}")`}}> 
                                                </div>
                                                <p className="style_name">{ans.item.length > 15 ? ans.item.substring(0,15) + '...' : ans.item}</p>
                                                {/* <img src={CollateralBaseUrl + ans.item} alt='' className="img-size" /> */}
                                                {/* <p className="style_name">{ans.item}</p> */}
                                            </li>
                                        </ul>
                                    </div>
                                            
                                        :
                                        // <a target="_blank" href={CollateralBaseUrl + ans.item} download={ans.name}>Download</a>
                                        <button type="button" className="main-btn font-12 mb-3 font-weight-600 bg-extra-light mt-1 mob-w-100 cursor-pointer"><a target="_blank" rel="noopener noreferrer" className="text-dark-black" href={CollateralBaseUrl + ans.item} download={ans.name}>Download</a></button>   
                                    }
                                </Fragment>
                            )} 
                        
                        </p>
                        </div>
                    </div>
                    </Fragment>
                )   
            } else return null
        })}    
    </Fragment>
    )
  }
}
