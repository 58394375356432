import { put } from "redux-saga/effects";

import axios from "../../api-interface";
import { getStatusSuccess } from "../../action/userStatus.action";

export function* onGetStatus(data) {
  let values = data.values;
  try {
    const data = yield axios.get("users/user-exist?email=" + values);
    yield put(getStatusSuccess(data));
  } catch (error) {
    throw error;
  }
}