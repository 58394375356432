import React from 'react';
import { connect } from 'react-redux';
// import BtnLoader from '../../Common/Common/BtnLoader'
import Loader from '../../../unauthorized/Common/Loader/Loader';
import { withRouter } from 'react-router';
import CollateralFormJobPop from './CollateralFormJobPop';
import { getAPI } from '../../../../utils/API';

class CollateralJobPop extends React.Component {

  textInput = React.createRef();

  child = React.createRef();
  enableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: true,
    });
  };

  disableForm = () => {
    this.props.onChange({
      name: 'FORMOPEN',
      value: false,
    });
  };

  onDelete = (id) => {
    const isConfirm = window.confirm('Do you want to delete the collateral?');
    if(!isConfirm) return;
    alert('Your collateral will be delete after reviewing.')
    // add new api to review the the delete collateral request
    this.props.onDelete(id);
    this.reset();
    this.props.resetCollateralQnA();
  };

  scrollToBottom = () => {
    console.log("2")
    if (this.textInput.current) {
       this.textInput.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  editEnable = (e, k) => {
    if (this.props.edit === -1 || this.props.edit !== k) {
      // console.log('collateral obj ', e);
      this.props.resetCollateralQnA();
      this.props.LoadCollateralQnA(e.collateral_id, e._id);
    } else {
      this.props.resetCollateralQnA();
    }

    const avgTimeType = ['Hours', 'Days', 'Weeks', 'Months'];
    let obj = {
      edit: k,
      Name: e.collateral_name,
      Type: e.type,
      EndPrice: e.end_price,
      StartPrice: e.start_price,
      Time: e.time,
      Experience: e.year,
      ExperienceMonth: e.month,
      ID: e._id,
      CollateralError: false,
      EndPriceError: false,
      StartPriceError: false,
      ExperienceError: false,
      TimeError: false,
      TypeError: false,
      CollateralErrorMessage: '',
      EndPriceErrorMessage: '',
      StartPriceErrorMessage: '',
      ExperienceErrorMessage: '',
      TimeErrorMessage: '',
      TypeErrorMessage: '',
      TypeIndex: avgTimeType.findIndex(t => t === e.type),
      avgTimeType: avgTimeType,
    };

    if (this.props.edit === k) {
      obj = {
        edit: -1,
        Name: '',
        Type: 'Hours',
        EndPrice: '',
        StartPrice: '',
        Time: 0,
        Experience: 0,
        ExperienceMonth: 0,
        ID: '',
        CollateralError: false,
        EndPriceError: false,
        StartPriceError: false,
        ExperienceError: false,
        TimeError: false,
        TypeError: false,
        CollateralErrorMessage: '',
        EndPriceErrorMessage: '',
        StartPriceErrorMessage: '',
        ExperienceErrorMessage: '',
        TimeErrorMessage: '',
        TypeErrorMessage: '',
        TypeIndex: 0,
        avgTimeType: ['Hours', 'Days', 'Weeks', 'Months'],
        collateralDesc: '',
        collateralPricing: '',
        collateralUnit: '',
      };
    }

    this.props.onChange({
      name: 'ALLCOLLATERAL',
      value: obj,
    });
    this.disableForm();
  };

  closePopupCall = () => {
    this.reset();
    this.props.closePopup();
    if(this.props.location.pathname === "/uploadproject/choose_collateral"){
      this.props.loadCollateral()
    }
  };

  reset = () => {
    this.props.onChange({
      name: 'ALLCOLLATERAL_RESET',
      value: {
        Name: '',
        Type: '',
        EndPrice: '',
        StartPrice: '',
        Time: 0,
        Experience: 0,
      },
    });
    this.props.onChange({ name: 'FORMOPEN', value: false });
  };

  componentDidMount = () => {
    this.scrollToBottom() 
  }

  handleSubmit = () => {
    this.child.current.onSubmit();
  };

  render() {
    let { CollateralListing, edit, formOpen, saving } = this.props;
    // console.log("CollateralListing = ",CollateralListing)
    let submitBtns;
    submitBtns =
      formOpen || edit !== -1 ? (
        edit !== -1 ? (
          <button
            type="submit"
            className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Update'}
          </button>
        ) : (
          <button
            type="submit"
            className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
            disabled={saving}
            onClick={this.handleSubmit}
          >
            {saving ? <Loader profileCreator="true"/> : 'Save'}
          </button>
        )
      ) : null;

    return (
      <>
        <div
          className="modal fade custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditCollateralModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => {
                this.closePopupCall()
                this.props.history.push(`/dashboard/jobs`);
              }
              }
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator edit-collateral-modal">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 mb-1 font-weight-500">
                          Collateral offered
                        </h2>
                        <p className="font-14 mob-font-12 font-weight-400 text-white">Your costs are not shared publicly</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 overflow-content edit-collateral-height mid_height">
                    <div className="custom_form edit-collateral-form-modal pt-4 mb-4">
                      {CollateralListing && CollateralListing.length > 0 ? (
                        CollateralListing.map((c, k) => {
                          return (
                            <React.Fragment key={k}> 
                              <div className="mb-4">
                                <div
                                  className={`card wrapper-form ${
                                    edit !== undefined && edit === k
                                      ? 'active border-bottom-0-10 lookChange'
                                      : ''
                                  }`}
                                >
                                  <div className="card-body">
                                    <div className="row m-0 align-items-center py-3 px-2 mob-pr-0 mob-pl-0 mob-justify-content-between">
                                      <div className="col-7 col-sm-10">
                                        <h2 className="text-dark-black font-18 m-0 mob-font-14 font-weight-500">
                                          {c.collateral_name}
                                          {c.status === 4 ? <span class="tool collateral-tool" data-tip="We have received your request to remove this collateral from your profile. Our team is currently reviewing your request."><i class="fas fa-info-circle ml-1"></i></span> : null}
                                        </h2>
                                        <div className="form-group mb-0 pt-1 mob-hidden">
                                          <span className="sub_title text-capitalize">
                                            {c.year} years {c.month} month
                                            experience
                                          </span>
                                          <span className="sub_title px-4 text-capitalize">
                                            Average time taken {c.time} {c.type}
                                          </span>
                                          <span className="sub_title text-capitalize">
                                            <i className="fas fa-rupee-sign pr-1"></i>
                                            {c.start_price} -{' '}
                                            <i className="fas fa-rupee-sign pr-1"></i>
                                            {c.end_price}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-5 col-sm-2 modal-tab tabs-icon">
                                        <div className="text-right">
                                          <span>
                                            <i
                                              className={`fas font-20 mob-font-14 pointer pl-3 collacteral position-relative ${
                                                edit !== undefined && edit === k
                                                  ? 'fa-chevron-up'
                                                  : 'fa-chevron-down'
                                              }`}
                                              onClick={() =>
                                                this.editEnable(c, k)
                                              }
                                            ></i>
                                          </span>
                                          <span>
                                          {
                                            c.status === 4 ? null : 
                                            <i
                                              className="far fa-trash-alt font-20 mob-font-14 pointer pl-3"
                                              onClick={() => this.onDelete(c._id)}
                                            ></i>
                                          }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> 
                              {edit === k ? (
                                  <CollateralFormJobPop ref={this.child} paths={this.props?.paths} LoadBriefData={this.props.LoadBriefData} ViewIncomingBrief = {this.props.ViewIncomingBrief}
                                    redirect={this.props.history} pathSlug={this.props?.pathSlug} OpenPopup={this.props.OpenPopup}/>
                              ) : (
                                <></>
                              )}
                              {/* <div className="border-bottom-2 my-20 mob-my-20"></div> */}
                              </div>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      
                      {edit === -1 ? (
                          <CollateralFormJobPop ref={this.child} paths={this.props?.paths} checkMyColl={true} pathSlug={this.props?.pathSlug}
                            redirect={this.props.history} closePop={this.props.closePopup} LoadBriefData={this.props.LoadBriefData}
                            ViewIncomingBrief={this.props.ViewIncomingBrief} OpenPopup={this.props.OpenPopup}/>
                     
                      ) : (
                        <></>
                      )}

                      {/* {edit === -1 ? (
                        formOpen || CollateralListing.length < 1 ? (
                          <CollateralFormJobPop ref={this.child} />
                        ) : (
                          <div className="w-100 py-4 dflex">
                            <a
                              href="#/"
                              className="font-13 mob-font-12 font-weight-600 text-center"
                              onClick={this.enableForm}
                            >
                              <i className="fas fa-plus mr-1"></i> Add
                              collateral
                            </a>
                          </div>
                        )
                      ) : (
                        <></>
                      )} */}
                      <div ref={this.textInput} />
                    </div> 
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={(e) => {
                          this.closePopupCall()
                          this.props.history.push(`/dashboard/jobs`);
                        }
                        }
                        className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button type="Submit" className="main-btn px-4 ml-2">Save</button> */}
                      {submitBtns}
                      {/* {!CollateralListing || CollateralListing.length === 0 ? ( */}
                      {edit === -1 ?
                        <button
                          type="submit"
                          className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
                          disabled={saving}
                          onClick={this.handleSubmit}
                        // data-dismiss="modal"
                        >
                          {saving ? <Loader profileCreator="true" /> : 'Save'}
                        </button>
                        : ""
                      }
                      {/* ) : null} */}
                    </div>
                  </div> 
                  <div className="col-sm-12 p-0 text-right pr-3 py-3 bg-shadow-top mobile-edit-collateral">
                    <button
                      type="button"
                      onClick={(e) => this.closePopupCall()}
                      className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    {/* <button type="Submit" className="main-btn px-4 ml-2">Save</button> */}
                    {submitBtns}
                    {!CollateralListing ||
                    CollateralListing.length === 0 ? (
                      <button
                        type="submit"
                        className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
                        disabled={saving}
                        onClick={this.handleSubmit}
                      >
                        {saving ? <Loader profileCreator="true"/> : 'Save'}
                      </button>
                    ) : null}
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
        <style jsx="true">
          {`
            .lookChange {
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              box-shadow: none;
              border-bottom: none;
            }
          `}
        </style>
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  let AddCollateral = store.addCollateral;

  return {
    formOpen: globalReducer.formOpen,
    saving: globalReducer.saving,
    edit: AddCollateral.edit,
    CollateralListing: creatorProfile.CollateralListing,
    closeModal: creatorProfile.closeModal,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.target.name, value: e.target.value },
    }),
  onChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  onDelete: (e) =>
    dispatch({ type: 'UPDATE_DELETECOLLATERAL_SAGA', payload: e }),
  loadCollateral: () => dispatch({ type: 'LOAD_COLLATERAL', payload: {} }),
  LoadCollateralQnA: (ques_id, ans_id) =>
    dispatch({
      type: 'LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL',
      payload: { ques_id, ans_id },
    }),
  resetCollateralQnA: (id) =>
    dispatch({
      type: 'RESET_COLLATERAL_QNA',
      payload: id,
    }),
});

export default withRouter(connect(mapState, mapDispatch)(CollateralJobPop));
