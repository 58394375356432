import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ModalBody from '../../Common/FeedbackModal/ModalBody';

class CreatorRejectsBrief extends React.Component {
  closePopup = () => {
    this.props.closePopup()
  }

  state = {
    checkboxList: [
      {text: "Project budget doesn't match my fee rate"},
      {text: "Work not suitable for my portfolio objectives"},
      {text: "I am busy with other projects currently"},
      {text: "I am taking a break from work"},
      {text: "Other"},
    ]
  }

  // onChangeHandler = (e) => {
  //   const target = e.target;
  //   const name = target.name;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   if(name === 'description' && value.length > 300){
  //     this.setState({[name]: value.substring(0,300)})
  //   } else {
  //     this.setState({[name]: value})
  //   }
  // }

  // validateForm = () => {
  //   const {checkbox1, checkbox2, checkbox3, description} =  this.state
  //   if(!checkbox1 && !checkbox2 && !checkbox3){
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // submitHandler = () => {
  //   const {checkbox1, checkbox2, checkbox3, description} =  this.state;
  //   const checkbox1Text = "Portfolio work didn't match my requirements"
  //   const checkbox2Text = "Want more experienced creators"
  //   const checkbox3Text = "Portfolio work didn't match my requirements"
  //   if(this.validateForm()){
  //     const obj = {
  //       post_project_id: this.props.CurrentRole === 2 ? this.props.post_project_id : this.props.ProjectId,
  //       description: description,
  //       reason: [],
  //       user_type: this.props.CurrentRole === 1 ? 'client' : 'creator' 
  //     }

  //     if(checkbox1) obj.reason.push({text: checkbox1Text})
  //     if(checkbox2) obj.reason.push({text: checkbox2Text})
  //     if(checkbox3) obj.reason.push({text: checkbox3Text})

  //     this.props.rejectIncomingBrief(obj)
  //     console.log('valid');
  //   } else {
  //     alert('please select atleast 1 reason.')
  //   }
  // }

  handleSubmit = (data) => {
    const isChatRoute = this.props.location.pathname.includes('/chat') 

    const obj = {
      post_project_id: isChatRoute ? this.props.ProjectId : this.props.post_project_id ,
      user_type: this.props.CurrentRole === 1 ? 'client' : 'creator',
      reason: data.reason,
      description: data.description,
      history:this.props.history
    }
    // console.log("reject creator final data ", obj)
    this.props.rejectIncomingBrief(obj)
}

  render() {
    return (
      <>
        <div className="modal fade custom_form verify_modal edit-basic-profile show" style={{
          paddingRight: "0px !important",
          display:'block'
        }} id="CreatorRejectsBrief" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img onClick={(e) => this.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
          </span>
          <ModalBody 
            headerText="Reasons for rejecting the brief"
            buttonText='Confirm'
            checkboxList={this.state.checkboxList} 
            submitHandler={this.handleSubmit} 
          />
          {/* <div className="modal-dialog modal-dialog-centered ipad-screen-650" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0 mobile-hide">
                    <div className="profile-creator ml-05 mob-m-0 text-center">
                      <div className="py-30">
                        <h2 className="mt-0 font-weight-500 mb-1 pt-1 font-18 mob-font-14">Tell us what you didn't like</h2>
                        <p className="mb-0 text-third font-weight-500 font-12 mob-font-12">You'll receive a call from us once you've submitted the feeback.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mobile-show bg-shadow">
                    <div className="mob-m-0 text-left">
                      <div className="py-15">
                        <h2 className="mt-0 font-weight-600 mb-1 pt-1 pl-3 font-18 small-font-14">Tell us why you're rejecting Karan</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project reject-profile">
                    <div className="custom_form px-2 pb-4 pt-4 mob-px-0">
                      <form method="post" action="">
                        <div className="row m-0">
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox1" id="requirements" checked={checkbox1} onChange={this.onChangeHandler} />
                            <label htmlFor="requirements" className="font-weight-600 small-font-12">Portfolio work didn't match my requirements</label>
                          </div>
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox2" id="want" checked={checkbox2} onChange={this.onChangeHandler} />
                            <label htmlFor="want" className="font-weight-600 small-font-12">Want more experienced creators</label>
                          </div>
                          <div className="form-group col-sm-12 custom_checkbox">
                            <input type="checkbox" name="checkbox3" id="match" checked={checkbox3} onChange={this.onChangeHandler} />
                            <label htmlFor="match" className="font-weight-600 small-font-12">Portfolio work didn't match my requirements</label>
                          </div>
                          <div className="form-group col-sm-12">
                            <textarea className="form-control email min-h-100 mb-1" name="description" value={description} onChange={this.onChangeHandler} placeholder="Describe further (optional)" id="comment"></textarea>
                            <span className="font-weight-500 text-secondary">{description.length}/300</span>
                          </div>
                        </div>
                        <div className="form-group text-left pl-3 pt-2 mob-px-15 mob-mb-30">
                          <button type="button" className="main-btn font-weight-500 py-12 mob-w-100" onClick={this.submitHandler} >Confirm <i className="fas fa-arrow-right font-12 font-weight-500 ml-1"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}>
        </div>
      </>
    )
  }
}

const mapState = (store) => {
  const creatorProfile = store.creatorProfile;
  const dashboard = store.dashboard;
  const { Me } = store.globalReducer;
  const chat = store.chat
  return {
    closeModal: creatorProfile.closeModal,
    post_project_id: dashboard.post_project_id,
    CurrentRole: Me.CurrentRole,
    ProjectId: chat.ProjectId
  };
};
const mapDispatch = dispatch => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  rejectIncomingBrief: (obj) => dispatch({ type: 'REJECT_INCOMING_BRIEF', payload: obj }),
});

export default withRouter(connect(mapState, mapDispatch)(CreatorRejectsBrief));
