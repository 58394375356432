import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import {
  ADD_DATA,
  UPDATE_LANGUAGE_SAGA,
  UPDATE_AWARD_SAGA,
  DELETE_AWARD_SAGA,
  ADD_AWARD_SAGA,
  SELECT_STYLE_TYPE_QUESTIONS,
  CROP_IMAGE,
  LOAD_ADMIN_PROJECT_CHAT,
  UPDATE_LOAD_ADMIN_CHAT,
  LOAD_ARTIST_AND_CLIENT,
  PROJECT_REVIEW_SAGA,
  UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA,
  CLEAR_MESSAGE_NOTIFICATION_SAGA,
  // RESET_NOTIFICATION_SAGA,
  UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA,
  UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA,
  CLEAR_DASHBOARD_NOTIFICATION_SAGA,
  CLEAR_COMPLETED_PROJECT_NOTIFICATION_SAGA,
  NOT_FOUND_SAGA,
  LOGOUT_USER,
  APPROVE_PROPOSAL_AM,
} from '../type.action';
import {
  GET_STATUS,
  CLOSE_POPUP_SAGA,
  OPEN_POPUP_SAGA,
  OPEN_PROJECT_POPUP,
  ON_FIELDCHANGE_SAGA,
  LOAD_CREATOR,
  UPLOAD_USERIMAGE,
  UPDATE_USER,
  USERADD_COLLATEROL,
  // LOAD_PROJECTAPI,
  LOAD_COLLATERAL,
  FIELD_CHANGE,
  SUBMIT_PROJECT_STATUS,
  UPDATE_SAGA_CURRENTWORKSTATUS,
  UPDATE_WebLink_Api,
  LOAD_USERWORK,
  ADD_USERWORK_SAGA,
  ADD_USEREDUCATION_SAGA,
  DELETE_USERWORK_SAGA,
  DELETE_USEREDUCATION_SAGA,
  SELECTED_COLLATERAL,
  UPDATE_USERWORK_EDIT_SAGA,
  UPDATE_SOFTWARE_SAGA,
  UPDATE_SPECIALIZATION_SAGA,
  LOAD_CREATOR_PUBLIC,
  LOAD_CREATOR_SPECIALIZATION_SAGA,
  LOAD_CREATOR_LOCATION_SAGA,
  LOAD_CREATOR_SOFTWARE_SAGA,
  LOAD_CREATOR_INDUSTORY_SAGA,
  LOAD_CREATOR_COMPANY_SAGA,
  ON_ACTION_SAGA,
  UPLOAD_RESUME,
  UPDATE_LOAD_ADMIN_CHATMESSAGES,
  ON_STATEUPDATE_SAGA,
  CHOOSE_COLLATERAL_ANSWER,
  LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL,
  REJECT_INCOMING_BRIEF,
  ACCEPT_INCOMING_BRIEF,
  RELEVENT_PROJECTS,
  PROJECT_BY_COLLATERAL,
  CLIENT_ACTIVE_PROPOSAL,
  REHAUL_PROJECT_BRIEF_DETAILS
} from '../type.action';

import {
    LOAD_ADDCOLLATERALS_SAGA,
    LOAD_DETAILCOLLATERALS_SAGA,
    RESET_COLLATERAL_QNA,
    UPDATE_DELETECOLLATERAL_SAGA,
    UPDATE_STYLE_ANSWER_SAGA,
    UPDATE_TYPE_ANSWER_SAGA,
    UPDATE_UPDATECOLLATERAL_SAGA,
  } from '../action/userAddCollateral.action'

// Post Project Actions
import {
  ON_FIELD_CHANGE_POST_PROJECT,
  GET_ALL_SERVICES,
  SELECT_SERVICE,
  LOAD_COLLATERAL_QUESTIONS,
  CHOOSE_DEADLINE,
  CHOOSE_TYPE_ANSWER,
  POST_PROJECT_STEPS,
  SELECTED_SERVICE,
  LOAD_ARTISTS,
  CHOOSE_ARTIST,
  OPEN_POST_PROJECT_POPUP,
  ON_FIELD_CHANGE_PROJECT_DETAILS,
  CHOOSE_PROJECT_DETAIL_ANSWER,
  CHOOSE_PROJECT_DETAIL_PROJECT_ANSWER,
  CLOSE_POST_PROJECT_POPUP,
  LOAD_POST_PROJECT_DATA,
  UPDATE_INVITE_MORE_STATUS_SAGA,
  GET_CREATOR_LIST,
  RESET_PROJECT_DATA,
  RESET_ALL_DATA,
  RESET_SERVICE,
  LOAD_COLLATERAL_QUESTIONS_GLOBAL,
  CHOOSE_STYLE_ANSWER,
  OPEN_SIGNUP_LOGIN,
  UPDATE_PORTFOLIO_PROJECTS,
  LOAD_REVIEW_PROPOSAL_DATA, 
  SET_CURRENT_PROPOSAL,
  SET_CURRENT_MILESTONE_ID,
  LOAD_LATEST_PROPOSAL,
  LOAD_ADMIN_PROJECT_PROPOSAL,
  LOAD_LATEST_MILESTONE,
  RESET_LATEST_MILESTONE_ID,
  SET_VERSION_HISTORY_DATA,
  SET_COMPLETED_MILESTONE_DATA,
  RESET_COMPLETED_MILESTONE_DATA,
  GET_POST_PROJECT_CLIENT_BRIEF,
  GET_POST_PROJECT_CLIENT_BRIEF_PUBLIC,
  GET_CREATOR_PROPOSAL_PROFILE,
  LOAD_USER_COMPANY_DETAIL,

} from '../action/postProject.action';

import {
  RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ,
  UPDATE_LOAD_CHAT,
  LOAD_CHAT_AND_LATEST_PROPOSAL,
  UPDATE_LOAD_CHATMESSAGES,
  LOAD_PROJECT_STATUS,
  LOAD_PROJECT_CHAT,
  ACTIVATE_ADMIN_CHAT_SAGA,
  LOAD_LAST_MILESTONE_PAYMENT_DONE,
  RESET_CHAT_DATA,
  ATTACHMENT_BUTTON_TOGGLE,
  REMOVE_NOTIFICATION_FORM_PROJECT_LIST,
  UPDATE_CURRENT_CHAT_USER_DATA_SAGA,
} from '../action/chat.action'

import {
  ChatLoad,
  LoadChatMessages,
  loadProjectStatus,
  resetUnreadMsgInCreatorObj,
  loadProjectChat,
  activateAdminChat,
  setCurrentMilestoneId,
  // initializeSocket,
  loadLastMilestonePaymentDone,
  // submitMileStoneClicked,
  // resetSocket,
  loadChatAndLatestProposals,
  resetChatData,
  toggleAttachmentButton,
  removeNotificationFromProjectList,
  updateCurrentChatUserData,
} from './workingSagas/chat.saga';

import {
  // AdminChatLoad,
  LoadAdminChatMessages,
  loadAdminProjectChat,
  loadArtistAndClient,
} from './workingSagas/adminChat.saga';

import { onaddData } from './workingSagas/addData.saga';
import { onGetStatus } from './workingSagas/userStatus.saga';
import { reviewProject, loadAllProjects } from './workingSagas/creator.saga'

import {
  closePopupCreator,
  openPopupCreator,
  onFieldChangeCreator,
  loadCreatorData,
  uploadUserImage,
  updateUser,
  onWorkStatusChange,
  updateWebLink,
  UpdateUserSoftware,
  UpdateUserLanguage,
  UpdateUserSpecialization,
  loadCreatorDataPublic,
  loadSpecialization,
  loadLocation,
  loadSoftware,
  loadIndustry,
  loadCompany,
  loadAllCollateral,
  loadCollateralDetail,
  callAction,
  uploadResume,
  onSTATEUPDATE,
  updateMessageNotificationCount,
  clearMessageNotification,
  // resetNotification,
  updateDashboardNotificationCount,
  updateCompletedProjectNotificationCount,
  clearDashboardNotification,
  clearCompletedProjectNotification,
} from './workingSagas/creator.saga';
import {
  loadUserWork,
  AddWork,
  deleteWork,
  UpdateWorkExperience,
} from './workingSagas/userWorksaga';
import {
  UserAddCollateral,
  UpdateUserCollateral,
  DeleteUserCollateral,
  loadCollateralQnAInUserCollateral,
  // loadCollateralAnswersInUserCollateral,
  setStyleAnswer,
  setTypeAnswer,
  resetCollateralQnA,
} from './workingSagas/userCollateral.saga';
import {
  AddUserEducation,
  DeleteUserEducation,
} from './workingSagas/userEducation';
import {
  onFieldChange,
  // loadUploadProjectApi,
  onloadCollateral,
  loadSelectedCollateral,
  openProjectPopup,
  submitProject,
  selectCollateralAnswer,
  styleTypeQuestions,
  setCropImages,
  // loadUserProjectData,
} from './workingSagas/addProject.saga';

import {
  onFieldChangePostProject,
  loadAllServices,
  selectService,
  loadCollateralQuestions,
  loadCollateralQuestionsGlobal,
  selectDeadline,
  getSteps,
  selectedCollateral,
  getArtists,
  selectArtist,
  openPostProjectPopup,
  onFieldChangeProjectDetails,
  selectProjectDetailAnswer,
  closePostProjectPopup,
  selectProjectDetailProjectAnswer,
  loadPostProjectData,
  updateInviteMoreStatus,
  loadCreatorList,
  resetProjectData,
  resetService,
  selectTypeAnswer,
  selectStyleAnswer,
  openSignupLogin,
  resetAllData,
  updatePortfolioProjects,
  loadReviewProposalData, 
  setCurrentProposal,
  loadLatestMilestone,
  resetLatestMilestoneId,
  updateVersionHistory,
  loadLatestProposal,
  loadAdminProjectProposal,
  setCompletedMilestone,
  resetCompletedMilestone,
  getPostProjectClientBrief,
  getPostProjectClientBriefPublic,
  getCreatorProposalProfile,
  setSelectName,
  loadCompanyDetails
} from './workingSagas/postProject.saga';

import {
  loadIncompleteBriefs,
  loadIncomingBriefs,
  loadBriefData,
  viewIncomingBrief,
  loadCollateralBasedProjects,
  rejectIncomingBrief,
  acceptIncomingBrief,
  deleteIncompleteBrief,
  loadUpcomingMilestones,
  loadJobs,
  deleteCreatorProject,
} from './workingSagas/dashboard.saga';
import {
  LOAD_INCOMPLETE_BRIEF,
  LOAD_INCOMING_BRIEF,
  LOAD_BRIEF_DATA,
  VIEW_INCOMING_BRIEF_SAGA,
  LOAD_COLLATERAL_BASED_PROJECTS,
  DELETE_INCOMPLETE_BRIEF_SAGA,
  GET_UPCOMING_MILESTONES,
  DELETE_CREATOR_PROJECT,
  LOAD_ALL_PROJECTS_SAGA,
  GET_JOBS
} from '../action/dashboard.action';

import { LOAD_PROJECT_DATA, EDIT_PROJECT } from '../action/projectView.action';
import {
  loadProjectData,
  editUserProject,
} from './workingSagas/projectView.saga';

import {redirectToNotFoundPage, logoutUser} from './workingSagas/error.saga'

import {
  AddUserAwards,
  UpdateUserAwards,
  DeleteUserAwards,
} from './workingSagas/userAwards.saga';
import { onReleventProjects, onProjectsByCollateral, onClientProposal, onApproveProposalAM } from "./proposal.saga"

// import { initial } from 'lodash';

function* sagas() {
  yield all ([takeLatest(RELEVENT_PROJECTS, onReleventProjects)])
  yield all ([takeLatest(PROJECT_BY_COLLATERAL, onProjectsByCollateral)])
  yield all ([takeLatest(CLIENT_ACTIVE_PROPOSAL, onClientProposal)])
  yield all ([takeLatest(APPROVE_PROPOSAL_AM, onApproveProposalAM)])
  yield all([takeLatest(ADD_DATA, onaddData)]);
  yield all([takeLatest(GET_STATUS, onGetStatus)]);
  yield all([takeLatest(CLOSE_POPUP_SAGA, closePopupCreator)]);
  yield all([takeLatest(OPEN_POPUP_SAGA, openPopupCreator)]);
  yield all([takeLatest(OPEN_PROJECT_POPUP, openProjectPopup)]);
  yield all([
    takeLatest(ON_STATEUPDATE_SAGA, onSTATEUPDATE),
    takeLatest(ON_FIELDCHANGE_SAGA, onFieldChangeCreator),
    takeLatest(ON_ACTION_SAGA, callAction),
    takeLatest(UPLOAD_RESUME, uploadResume),
  ]);
  // takeLeading
  yield all([takeLatest(LOAD_CREATOR, loadCreatorData)]);
  yield all([takeLatest(LOAD_CREATOR_PUBLIC, loadCreatorDataPublic)]);

  yield all([takeLatest(USERADD_COLLATEROL, UserAddCollateral)]);

  yield all([takeLatest(UPLOAD_USERIMAGE, uploadUserImage)]);
  yield all([takeLatest(UPDATE_USER, updateUser)]);
  yield all([takeEvery(FIELD_CHANGE, onFieldChange)]);
  //yield all([takeLatest(LOAD_PROJECTAPI, loadUploadProjectApi)]);
  yield all([takeLatest(LOAD_COLLATERAL, onloadCollateral)]);

  yield all([takeLatest(UPDATE_SAGA_CURRENTWORKSTATUS, onWorkStatusChange)]);
  yield all([takeLatest(UPDATE_WebLink_Api, updateWebLink)]);
  yield all([takeLatest(LOAD_USERWORK, loadUserWork)]);

  // Update User Work
  // Add User Work
  yield all([
    takeLatest(ADD_USEREDUCATION_SAGA, AddUserEducation),
    takeLatest(DELETE_USERWORK_SAGA, deleteWork),
  ]);
  // yield all([])
  // yield all([
  // takeLatest(ADD_USERWORK_SAGA, AddWork),
  // yield all([takeLatest(ADD_USERWORK_SAGA, AddWork), takeLatest(DELETE_USEREDUCATION_SAGA, DeleteUserEducation)]);
  yield all([
    takeLatest(ADD_USERWORK_SAGA, AddWork),
    takeLatest(DELETE_USEREDUCATION_SAGA, DeleteUserEducation),
    takeLatest(UPDATE_USERWORK_EDIT_SAGA, UpdateWorkExperience),
  ]);

  yield all([takeLatest(LOAD_COLLATERAL, onloadCollateral)]);
  yield all([takeLatest(SELECTED_COLLATERAL, loadSelectedCollateral)]);

  //Submit Project
  yield all([takeLatest(SUBMIT_PROJECT_STATUS, submitProject)]);
  yield all([takeLatest(CHOOSE_COLLATERAL_ANSWER, selectCollateralAnswer)]);
  yield all([takeLatest(SELECT_STYLE_TYPE_QUESTIONS,styleTypeQuestions)]);
  yield all([takeLatest(SELECTED_COLLATERAL, loadSelectedCollateral)]);
  yield all([takeLatest(CROP_IMAGE, setCropImages)]);
  // yield all([takeLatest(LOAD_COLLATERAL, onloadCollateral)]);
  yield all([takeLatest(UPDATE_DELETECOLLATERAL_SAGA, DeleteUserCollateral)]);

  yield all([
    takeLatest(
      LOAD_COLLATERAL_QNA_IN_USER_COLLATERAL,
      loadCollateralQnAInUserCollateral
    ),
  ]);
  yield all([takeLatest(RESET_COLLATERAL_QNA, resetCollateralQnA)]);

  // style and type questions in creatorProfile -> collateralForm
  yield all([
    takeLatest(UPDATE_STYLE_ANSWER_SAGA, setStyleAnswer),
    takeLatest(UPDATE_TYPE_ANSWER_SAGA, setTypeAnswer),
  ]);

  yield all([
    takeLatest(LOAD_CREATOR_SPECIALIZATION_SAGA, loadSpecialization),
    takeLatest(LOAD_CREATOR_LOCATION_SAGA, loadLocation),
    takeLatest(LOAD_CREATOR_SOFTWARE_SAGA, loadSoftware),

    takeEvery(UPDATE_UPDATECOLLATERAL_SAGA, UpdateUserCollateral),
    takeLatest(UPDATE_SOFTWARE_SAGA, UpdateUserSoftware),
    takeLatest(LOAD_CREATOR_INDUSTORY_SAGA, loadIndustry),
    takeLatest(LOAD_CREATOR_COMPANY_SAGA, loadCompany),
    takeLatest(LOAD_ADDCOLLATERALS_SAGA, loadAllCollateral),
    takeLatest(LOAD_DETAILCOLLATERALS_SAGA, loadCollateralDetail),

    takeLatest(UPDATE_LANGUAGE_SAGA, UpdateUserLanguage),
    takeLatest(UPDATE_AWARD_SAGA, UpdateUserAwards),
    takeLatest(DELETE_AWARD_SAGA, DeleteUserAwards),
    takeLatest(ADD_AWARD_SAGA, AddUserAwards),

    takeLatest(UPDATE_SPECIALIZATION_SAGA, UpdateUserSpecialization),
  ]);
  //Edit Project

  yield all([
    takeLatest(LOAD_PROJECT_DATA, loadProjectData),
    takeLatest(EDIT_PROJECT, editUserProject),
  ]);

  //Post Project
  yield all([
    takeLatest(ON_FIELD_CHANGE_POST_PROJECT, onFieldChangePostProject),
    takeLatest(GET_ALL_SERVICES, loadAllServices),
    takeEvery(SELECT_SERVICE, selectService),

    
    takeLatest(LOAD_COLLATERAL_QUESTIONS, loadCollateralQuestions),
    takeLatest(LOAD_COLLATERAL_QUESTIONS_GLOBAL, loadCollateralQuestionsGlobal),
    takeLatest(CHOOSE_DEADLINE, selectDeadline),
    takeLatest(CHOOSE_TYPE_ANSWER, selectTypeAnswer),
    takeLatest(CHOOSE_STYLE_ANSWER, selectStyleAnswer),
    takeLatest(POST_PROJECT_STEPS, getSteps),
    takeLatest(SELECTED_SERVICE, selectedCollateral),
    takeLatest(LOAD_ARTISTS, getArtists),
    takeLatest(CHOOSE_ARTIST, selectArtist),
    takeLatest(UPDATE_PORTFOLIO_PROJECTS, updatePortfolioProjects),
    takeLatest(OPEN_POST_PROJECT_POPUP, openPostProjectPopup),
    takeLatest(CLOSE_POST_PROJECT_POPUP, closePostProjectPopup),
    takeLatest(ON_FIELD_CHANGE_PROJECT_DETAILS, onFieldChangeProjectDetails),
    takeLatest(CHOOSE_PROJECT_DETAIL_ANSWER, selectProjectDetailAnswer),
    takeLatest(
      CHOOSE_PROJECT_DETAIL_PROJECT_ANSWER,
      selectProjectDetailProjectAnswer
    ),
    takeLatest(LOAD_POST_PROJECT_DATA, loadPostProjectData),
    takeLatest(LOAD_USER_COMPANY_DETAIL, loadCompanyDetails),
    takeLatest(UPDATE_INVITE_MORE_STATUS_SAGA, updateInviteMoreStatus),
    takeLatest(GET_CREATOR_LIST, loadCreatorList),
    takeLatest(RESET_PROJECT_DATA, resetProjectData),
    takeLatest(RESET_ALL_DATA, resetAllData),
    takeLatest(RESET_SERVICE, resetService),
    takeLatest(OPEN_SIGNUP_LOGIN, openSignupLogin),
    takeLatest(LOAD_REVIEW_PROPOSAL_DATA, loadReviewProposalData),
    takeLatest(LOAD_LATEST_PROPOSAL, loadLatestProposal),
    takeLatest(LOAD_ADMIN_PROJECT_PROPOSAL, loadAdminProjectProposal),
    takeLatest(SET_CURRENT_PROPOSAL, setCurrentProposal),
    takeLatest(LOAD_LATEST_MILESTONE, loadLatestMilestone),
    takeLatest(RESET_LATEST_MILESTONE_ID, resetLatestMilestoneId ),
    takeLatest(SET_VERSION_HISTORY_DATA, updateVersionHistory ),
    takeLatest(SET_COMPLETED_MILESTONE_DATA, setCompletedMilestone ),
    takeLatest(RESET_COMPLETED_MILESTONE_DATA, resetCompletedMilestone ),
    takeLatest(SET_CURRENT_MILESTONE_ID,setCurrentMilestoneId),
    takeLatest(GET_POST_PROJECT_CLIENT_BRIEF ,getPostProjectClientBrief),
    takeLatest(GET_POST_PROJECT_CLIENT_BRIEF_PUBLIC ,getPostProjectClientBriefPublic),
    takeLatest(GET_CREATOR_PROPOSAL_PROFILE ,getCreatorProposalProfile)
  ]);

  //Chat
  yield all([
    takeLatest(UPDATE_LOAD_CHAT, ChatLoad),
    takeLatest(LOAD_CHAT_AND_LATEST_PROPOSAL, loadChatAndLatestProposals),
    takeLatest(RESET_CHAT_DATA, resetChatData),
    takeLatest(ATTACHMENT_BUTTON_TOGGLE, toggleAttachmentButton),
    takeLatest(REMOVE_NOTIFICATION_FORM_PROJECT_LIST, removeNotificationFromProjectList),
    takeLatest(UPDATE_LOAD_CHATMESSAGES, LoadChatMessages),
    takeLatest(LOAD_PROJECT_STATUS, loadProjectStatus),
    takeLatest(RESET_UNREAD_MESSAGE_IN_CREATOR_OBJ, resetUnreadMsgInCreatorObj),
    takeLatest(LOAD_PROJECT_CHAT, loadProjectChat),
    takeLatest(LOAD_LAST_MILESTONE_PAYMENT_DONE, loadLastMilestonePaymentDone),
    takeLatest(UPDATE_CURRENT_CHAT_USER_DATA_SAGA, updateCurrentChatUserData),
  ]);
  
  //ADMIN Chat
  yield all([
    takeLatest(ACTIVATE_ADMIN_CHAT_SAGA, activateAdminChat),
    takeLatest(UPDATE_LOAD_ADMIN_CHAT, ChatLoad),
    takeLatest(UPDATE_LOAD_ADMIN_CHATMESSAGES, LoadAdminChatMessages),
    takeLatest(LOAD_ADMIN_PROJECT_CHAT, loadAdminProjectChat),
    takeLatest(LOAD_ARTIST_AND_CLIENT, loadArtistAndClient),
  ]);

 
  //Client Dashboard
  yield all([
    takeLatest(LOAD_INCOMPLETE_BRIEF, loadIncompleteBriefs),
    takeLatest(LOAD_INCOMING_BRIEF, loadIncomingBriefs),
    takeLatest(DELETE_INCOMPLETE_BRIEF_SAGA, deleteIncompleteBrief),
  ]);

  //Creator Dashboard
  yield all([
    takeLatest(LOAD_BRIEF_DATA, loadBriefData),
    takeLatest(VIEW_INCOMING_BRIEF_SAGA, viewIncomingBrief),
    takeLatest(LOAD_COLLATERAL_BASED_PROJECTS, loadCollateralBasedProjects),
    takeLatest(REJECT_INCOMING_BRIEF, rejectIncomingBrief),
    takeLatest(ACCEPT_INCOMING_BRIEF, acceptIncomingBrief),
    takeLatest(GET_UPCOMING_MILESTONES, loadUpcomingMilestones),
    takeLatest(GET_JOBS,loadJobs),
    takeLatest(DELETE_CREATOR_PROJECT,deleteCreatorProject),
    takeLatest(LOAD_ALL_PROJECTS_SAGA, loadAllProjects),

  ]);
  
  // project review 
  yield all([
    takeLatest(PROJECT_REVIEW_SAGA, reviewProject),
  ]);
  
  // header msg count
    yield all([
      takeLatest(UPDATE_MESSAGE_NOTIFICATION_COUNT_SAGA, updateMessageNotificationCount),
      takeLatest(CLEAR_MESSAGE_NOTIFICATION_SAGA, clearMessageNotification),

      // takeLatest(RESET_NOTIFICATION_SAGA, resetNotification),

      takeLatest(UPDATE_DASHBOARD_NOTIFICATION_COUNT_SAGA, updateDashboardNotificationCount),
      takeLatest(UPDATE_COMPLETED_PROJECT_NOTIFICATION_COUNT_SAGA, updateCompletedProjectNotificationCount),
      takeLatest(CLEAR_DASHBOARD_NOTIFICATION_SAGA, clearDashboardNotification),
      takeLatest(CLEAR_COMPLETED_PROJECT_NOTIFICATION_SAGA, clearCompletedProjectNotification),
    ]);

    yield all([
      takeLatest(NOT_FOUND_SAGA, redirectToNotFoundPage),
      takeLatest(LOGOUT_USER, logoutUser),
    ])

}

export default sagas;
