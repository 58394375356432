import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import PriceImage from "../../../../../Assets/upgrade-images/submit_proposal/price.svg";
import TrashImage from "../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import { getAPI, postAPI, putAPI } from "../../../../../utils/API";
import Loader from "../../../Common/Common/Loader";
import { addHrMinDate, setTimeToZero } from "../CommonFunctionsNew/CommonFunctionsNew";

export const ActualProjectForm = (props) => {
  const [states, setStates] = useState(props.addExtData);
  const [minExtStartDate, setMinExtStartDate] = useState(setTimeToZero(new Date()));
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState({ prop_dur_num: false });
  const { projectId } = props?.match?.params;

  useEffect(() => {
    let endDate = props.array[props.array.length - 1].end_date;
    let minimumDate = new Date(endDate);
    minimumDate.setDate(minimumDate.getDate() + 1);
    if(states.noAlreadyData){
      states.proposed_start_date = setTimeToZero(minimumDate);
    }
    setMinExtStartDate(setTimeToZero(minimumDate))
    setStates({ ...states });
  },[])

  const validationDeliverable = () => {
    let valid = true;
    if (states?.propDuration?.prop_dur_num < 1) valid = false;
    states.deliverable.forEach((item) => {
      if (!item.name.length > 0 || !item.breakup) valid = false;
      item.validation.name = item.name.length > 0 ? false : true;
      item.validation.breakup = item.breakup ? false : true;
    });
    setValidation({
      prop_dur_num: states.propDuration.prop_dur_num < 1 ? true : false,
    });
    setStates({ ...states });
    return valid;
  };

  const handleChangeNote = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length < 300) {
      states.validNote = value.length > 0 ? false : true;
      setStates({
        ...states,
        note: value,
      });
    }
  };

  const handleNumbers = (action, e) => {
    let numbers = states.propDuration.prop_dur_num;
    if (action === "plus") {
      numbers == 0 ? (states.propDuration.prop_dur_num = 1) : (states.propDuration.prop_dur_num += 1);
    } else if (action === "change") {
      states.propDuration.prop_dur_num = e.target.value > 0 ? Number(e.target.value) : e.target.value;
    } else {
      if (states.propDuration.prop_dur_num > 1) states.propDuration.prop_dur_num -= 1;
    }
    setValidation({ prop_dur_num: states.propDuration.prop_dur_num < 1 ? true : false });
    setStates({ ...states, propDuration: { ...states.propDuration } });
  };

  const handleSubmitApi = async () => {
    setLoader(true);
    let arrDel = [];
    states.deliverable.map((item) => {
      arrDel.push({
        iteration: item?.iterations > 0 ? item.iterations : 0,
        price: item.breakup,
        iteration_charge: item?.iteration_charge > 0 ? item.iteration_charge : 0,
        name: item.name,
        type: "milestone",
      });
    });

    let data = {
      post_project_id: projectId,
      proposal_type: "actual",
      deliverables: arrDel,
      preferred_billing_cycle: props.state.prefBillingCycle.value,
      note: states.note,
      proposed_duration_type: states.propDuration.prop_duration.label,
      proposed_duration: states.propDuration.prop_dur_num,
      total_cost: gst_total_price,
      sub_total_cost: total_price,
      total_earning: totalEarning,
      proposed_start_date: addHrMinDate(states.proposed_start_date,5,30),
      proposal_id: props.state?.proposalData?._id,
      tax: parseInt((total_price * 0.18).toFixed(2)),
      access_key: props.CurrentRole,
    };

    // console.log("data = ", data);
    try {
      let res = "";
      if (props.isaddExt) {
        res = await postAPI("/proposal/extend-proposal", data);
      } else {
        data.approvedproposal_id = props.state?.proposalData?._id;
        data.proposal_id = states?.allData?._id;
        res = await putAPI("/proposal/put-exit-extend-proposal", data);
      }
      // console.log("res = ", res);
      if (res?.data) {
        setLoader(false);
        props.setActualPopupForm(false);
        props.handleGetAddExtData();
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoader(false);
    }
  };

  const handleChangeDeliverables = (e, i) => {
    const { name, value } = e.target;
    states.deliverable[i][name] = value;
    states.deliverable[i].validation[name] = value.length > 0 ? false : true;

    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleAppend = () => {
    let valid = validationDeliverable();
    if (valid) {
      setStates({
        ...states,
        deliverable: [
          ...states.deliverable,
          {
            name: "",
            iterations: 0,
            breakup: "",
            toggle: false,
            iteration_charge: 0,
            validation: {
              name: false,
              breakup: false,
              iteration_charge: false,
            },
            status: 1,
            milestonecomplete: [],
            paid_status: 0,
          },
        ],
      });
    }
  };

  const handleDelete = (index) => {
    states.deliverable.splice(index, 1);
    setStates({
      ...states,
      deliverable: [...states.deliverable],
    });
  };

  const handleChangeDate = (e) => {
    states.proposed_start_date = new Date(e);
    setStates({ ...states });
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.setActualPopupForm(false);
    }
  };

  let total_price = 0;
  states.deliverable.forEach((item) => {
    total_price += Number(item?.breakup);
  });
  let gst_total_price = 0;
  gst_total_price = Number(total_price + total_price * 0.18);

  let totalEarning = 0;
  totalEarning = Number(total_price * 0.85);

  return (
    <>
      <section className="worklogextensionrequestwrapper">
        <div
          className={`modal fade modal_fade_bg ${props.actualPopupForm ? "show" : ""}`}
          id="worklogextensionrequestpopup"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props.actualPopupForm ? "block" : "" }}
        >
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_md m-auto">
            <div className="modal-content">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h5 className="modal-title main_modal_title mb-3" id="exampleModalLabel">
                    Request Extension
                  </h5>
                </div>
                <button type="button" className="close close_btn" data-dismiss="modal" aria-label="Close">
                  <span
                    aria-hidden="true"
                    className="close_icon modalclose-btnicon"
                    onClick={() => props.setActualPopupForm(false)}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="br_btm duration px-0 mx-4 mb-3">
                  <div className="row proposal_date pb-4 mx-0">
                    <div className="proposal_date_title col-lg-12 mb-3 px-1">
                      <p className="mb-0 font_semi_bold_w_600">Duration</p>
                      <span className="proposal_date_sub_title">
                        Enter the start till which you would like to extend the project
                      </span>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center px-1 ">
                      <div className="date_input">
                        <DatePicker
                          value={states?.proposed_start_date?new Date(states.proposed_start_date) :""}
                          clearIcon={null}
                          name="startDate"
                          format="dd/MM/yyyy"
                          calendarClassName="apniClass"
                          showLeadingZeros={true}
                          onChange={handleChangeDate}
                          minDate={minExtStartDate}
                          className={`form-control text-center input_sm`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="br_btm duration px-3 mx-3">
                  <div className="row  pb-4 justify-content-between align-items-center">
                    <div className="duration_title col-xl-4 px-lg-0">
                      <p className="font_semi_bold_w_600 mb-0">Proposed duration</p>
                      <p className="text_light_gray_xs mb-0">Indicate how long you will be able to work on this project. </p>
                    </div>
                    <div className="duration_fields d-flex col-xl-8 justify-content-end px-lg-0">
                      <div className="btn_field number_btn">
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("minus")}>
                            -
                          </button>
                          <input
                            type="number"
                            class="form-control text-center input_sm"
                            onChange={(e) => handleNumbers("change", e)}
                            value={states.propDuration.prop_dur_num}
                          />
                          <button type="button border-0" class="btn" onClick={() => handleNumbers("plus")}>
                            +
                          </button>
                        </div>
                        {validation.prop_dur_num ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div
                        className="dropedown_field select_dropdown d-flex align-items-center ml-4"
                        style={{ minWidth: "3rem" }}
                      >
                        <p className="text_light_gray_md_w_700 mb-0">{states.propDuration.prop_duration.value}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row m-0 py-3">
                  <div className="col-lg-12">
                    <p className="mb-2 font_semi_bold_w_600">Confirm Deliverables</p>
                    <p className="mb-0 text_light_gray_xs">
                      Enter the start and end date till which you would like to extend the project
                    </p>
                  </div>
                </div>

                {states.deliverable?.map((item, i) => {
                  return (
                    <div class="row  mb-3 py-3 align-items-center ">
                      <div class="col-lg-1 col-1 text-center">{i + 1}</div>
                      <div class="col-lg-7 col-lg-7">
                        <input
                          class={`form-control input_md ${item?.validation?.name ? "border-danger" : ""}`}
                          type="text"
                          value={item?.name}
                          name="name"
                          onChange={(e) => handleChangeDeliverables(e, i)}
                          placeholder="Name"
                        />
                        {item?.validation?.name ? <p className="error_message">field required</p> : ""}
                      </div>
                      <div class="col-lg-3 col-3 pr-0">
                        <div className="price_input">
                          <input
                            type="number"
                            class={`form-control input_sm ${item?.validation?.breakup ? "border-danger" : ""}`}
                            placeholder="Price"
                            value={item?.breakup}
                            name="breakup"
                            onChange={(e) => handleChangeDeliverables(e, i)}
                          />
                          <img
                            src={PriceImage}
                            className={`price_icon img-fluid ${item?.validation?.breakup ? "error_price_icon" : ""}`}
                          ></img>
                          {item?.validation?.breakup ? <p className="error_message">field required</p> : ""}
                        </div>
                      </div>

                     {states.deliverable?.length==1?null : <div class="col-lg-1 col-1 pr-0">
                        <div className="toggle_btn d-flex align-items-center justify-content-between">
                          <img src={TrashImage} className="icon_img cursor-pointer" onClick={() => handleDelete(i)}></img>
                        </div>
                      </div>}
                    </div>
                  );
                })}

                <div className="row mx-0">
                  <div className="col-lg-12 mt-3">
                    <p className="add_on_milestone mb-4 cursor-pointer" onClick={handleAppend}>
                      <span className="add_on_milstone_icon">+</span>Add Items
                    </p>
                  </div>
                </div>

                {/* add notes */}
                <div className="row add_notes mx-0">
                  <div className="col-lg-12">
                    <p className="mb-0 font_semi_bold_w_600">
                      Additional Notes <span className="text_light_gray_md">(Optional)</span>
                    </p>
                  </div>
                  <div className="col-lg-12  py-4">
                    <textarea
                      class={`form-control upload_notes`}
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="Please add any additional notes, points or disclaimers here"
                      value={states.note}
                      onChange={handleChangeNote}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-end align-items-center py-4">
                <div className="col-lg-12 text-right">
                  <button type="button" class="btn btn_outline_md fw_700 mr-4" onClick={() => props.setActualPopupForm(false)}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn_common_md fw_700"
                    onClick={() => {
                      let result = validationDeliverable() ? handleSubmitApi() : "";
                    }}
                  >
                    {loader ? <Loader isCreateModal={true} /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
