// import CreatorChatIcon from '../../../../Assets/images/creator-chat.svg';
// import ClientChatIcon from '../../../../Assets/images/client-chat.svg';
import ClientChatIcon from "../../../../Assets/upgrade-images/dashboard/dummy_user2.svg";
import CreatorChatIcon from "../../../../Assets/upgrade-images/dashboard/dummy_user2.svg";


import logo from '../../../../Assets/upgrade-images/sidebar/logo_3.svg';
import { BaseImageUrl } from '../../../../utils/BaseUrl';

function getUserImage(data) {
    const getImage = () => {
        const isClient = data.Me.CurrentRole === 1
        if(data.UserId === data.Data.sender_id) {
          return data.Me.ProfileImage.includes('profile_image.png') ?  
            isClient ? ClientChatIcon : CreatorChatIcon : data.Me.ProfileImage ? BaseImageUrl + data.Me.ProfileImage : ClientChatIcon
        } else if(data.CurrentChat.user_id === data.Data.sender_id) {
          return data.CurrentChat.profile_image.includes('profile_image.png') ?  
            isClient ? CreatorChatIcon : ClientChatIcon : data.CurrentChat?.profile_image ? BaseImageUrl + data.CurrentChat?.profile_image : ClientChatIcon
        }else if(data.projectDataStates.data.project_data.manager_id ==data.Data.sender_id){
          // console.log(data.projectDataStates.data.project_data)
        return  data.projectDataStates.data.project_data.manager_image ? BaseImageUrl+data.projectDataStates.data.project_data.manager_image : ClientChatIcon
        }else{
          // console.log('seeeithere',data)
          return data.clientData?.profile_image ? BaseImageUrl+data.clientData?.profile_image : ClientChatIcon
        }
      }
      
    let image;

    if(data.Data.user_type === "admin"){
      image = logo;

    } else if(data.Data.user_type === "Indiefolio"||data.Data.user_type=="IndieFolio"){
      image = logo
    } else if(data.ProjectType === 'post_project_admin'){
        if (data.UserId === data.Data.sender_id){
          image = getImage()
        } else if (data.CurrentChat.member1._id === data.Data.sender_id) {
          image = BaseImageUrl + data.CurrentChat.member1.profile_image
        } else if (data.CurrentChat.chat_type === 'group' && data.CurrentChat.member2._id === data.Data.sender_id){
          image = BaseImageUrl + data.CurrentChat.member2.profile_image
        }
    }
      else {
      image = getImage();
    }


    // in adminChat
    if(data.Me.isAdmin && data.location.pathname.includes('/adminChat')){
        image = data.Data.sender_id && data.Data.user_type === "user"
        ? BaseImageUrl + data.Data.sender_id.profile_image
        : logo //admin image
        // : BaseImageUrl + 'profile_image.png' //admin image
    }

    return image
}


export default getUserImage
