import React from 'react';
import { connect } from 'react-redux';
class PostProjectHeader extends React.Component {


    render() {
        const {status} = this.props;
        const current = (path) => {
            return window.location.pathname.split('/').pop().toLowerCase() === path
        }
        return (
            <>
                <section className="bg-white">
                    <div className="container-fluid p-3 max-w-1440">
                        <div className="d-flex align-items-center justify-content-center mob-justify-content-unset">
                            <div className="choose-collateral active">
                                <p className="mb-0 font-weight-600 d-flex align-items-center">
                                <span className={`sticky-note text-dark   ${(status <= 0) ? "" : "hidden"} `}>
                                    <i className="far fa-sticky-note font-18 mr-1"></i>
                                </span>
                                <span className={`complete-check text-dark ${(status >= 1) ? ""   : "hidden"} `}><i className="fas fa-check mr-2 "></i></span>
                                <span className={` ${(status < 1) ? ""   : "desktop-ipad-ipad-pro-show"}`}>Scope of work</span>
                                </p>
                            </div>
                            <div className={`choose-underline ${(current("profile_selection") || current("project") ? 'active' : '')}`}></div>
                            <div className={`publish-project ${(current("profile_selection") ? 'active text-dark' : '')}`}>
                                <p className="mb-0 font-weight-600 d-flex align-items-center">
                                    <span><i className={`fas fa-users font-18 mr-2 ${(status <2 ) ? "" : "hidden"}`}></i></span>
                                    <span className={`complete-check text-dark ${(status >= 2) ? "" : "hidden"} `}><i className="fas fa-check mr-2"></i></span>
                                    <span className={`${(current("profile_selection")) ? '' : 'desktop-ipad-ipad-pro-show'} ${(status < 2 ?  '' : 'text-dark')}`}>{this.props.budget_scope==='high'?"Meeting Booked":`Profile selection`}</span>
                                </p>
                            </div>
                            <div className={`choose-underline ${((current("project") || status === 3||status===11) ? 'active' : '')}`}></div>
                            <div className={`choose-collateral  ${(current("project") || status === 3) ? "active text-dark": ""}`}>
                                <p className="mb-0 font-weight-600 d-flex align-items-center"> 
                                    <span className="sticky-note"><i className="far fa-sticky-note font-18 mr-1"></i></span>
                                    <span className={`${(current("project")) ? ""   : " desktop-ipad-ipad-pro-show"} ${(status < 3 ?  '' : 'text-dark')}`}>{this.props.budget_scope==='high'?"Project Launch":'Project details'}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


const mapState = (store) => { 
    let postProjectData = store.postProject;

	return {
        budget_scope:postProjectData.budget_scope,
		status: (postProjectData?.status !== undefined) ? postProjectData?.status :0,
	}; 
}

const mapDispatch = dispatchEvent => ({ 
});


export default connect(mapState,mapDispatch)(PostProjectHeader);