import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { postAPI } from '../../../../utils/API';
import { BaseImageUrl, RazorpayKey, prefill } from '../../../../utils/BaseUrl';

class KickStartProject extends React.Component {
  state= {
    isLoading: false
  }

  closePopup = () => {
    this.props.closePopup()
  }

  approveWithoutPayment = async () => {

    try{
      let proposal_data = this.props.proposal_data;
      const obj = {
        milestone_id: proposal_data.milestone.find(el => el.type === 'token' )._id,
        proposal_id: proposal_data.proposal._id,
        type: "proposal"
      }
      
      const res = await postAPI('/payment/direct-payment', obj);
      if(parseInt(res.data.status) === 1){
        const obj = {
          projectId : this.props.ProjectId,
          projectName : this.props.ProjectName,
          projectStatus : 6,
          projectType: this.props.ProjectType,
        }
        
        if(this.props?.history?.location?.pathname?.startsWith("/talentconnect")){
          const pdata = this.props?.postProjectClientBreif?.postprojectdata
          const objj = {
            projectId : pdata?._id,
            projectName : pdata?.project_name,
            projectStatus :pdata?.status,
            projectType : pdata?.type,
            callAllProposalApi: true
          }
          this.props.loadChat(objj);
          const slug = pdata?.project_slug; 
          this.props.history.push(`/chat/${objj.projectId}/${slug}`)
        } else {
          this.props.loadChat(obj);
        }
        this.props.closePopup();
      }

      } catch(error){
        this.setState({isLoading: false});
        console.log(error)
    }
  }

  handleContinue = () => {  
    this.setState({isLoading: true});
    let proposal_data = this.props.proposal_data;
    let tokenMoney = proposal_data.milestone.find((element) => { return element.type === "token" });
    // console.log(tokenMoney, proposal_data)
    if(parseInt(tokenMoney?.price) === 0 || proposal_data?.offline_payment === 1){
      this.approveWithoutPayment()
    } else {
      this.approvePay();
    }
  }

  componentDidMount() {
    // load razorpay script
    // const script = document.createElement('script');
    // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    // script.async = true;
    // document.body.appendChild(script);
  }

  approvePay = () => {
    let proposal_data = this.props.proposal_data;
    
    let tokenMoney = proposal_data.milestone.find((element) => { return element.type === "token" })
    const payment_details = {
      amount: (tokenMoney?.price + (tokenMoney?.price * 0.18)) * 100,
      currency: "INR",
      receipt: "#",
      proposal_id: proposal_data.proposal?._id,
      //milestone_id:"5f9684fe2bf16c0a9da5e590",
      type: "proposal"
    }
    postAPI('/payment/create-order', payment_details).then((res) => {
      if (res.data.status === "1") {
        // console.log("payment Details===>", res.data);
        this.setState({
          order_id: res.data.data.order_id,
          proposal_id: res.data.data.proposal_id,
          payment_type: res.data.data.type,
        }, (e) => this.paymentHandler());
      }
    }).catch((error) => {
      this.setState({isLoading: false});
      console.log(error);
    })
  }

  paymentHandler = async () => {
    const options = {
      key: RazorpayKey,
      name: "IndieFolio",
      description: "Kickstart Project payment",
      order_id: this.state.order_id, 
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;
          const proposal_id = this.state.proposal_id;
          const type = this.state.payment_type;

          let payment_details = {
            payment_id: paymentId,
            order_id: orderId,
            signature: signature,
            proposal_id: proposal_id,
            type: type
          }

          postAPI('/payment/verify-signeture', payment_details).then((res) => {
          //  console.log("Verification Details===>", res.data);
            if (parseInt(res.data.status) === 1) {
              // let projectId = this.props.ProjectId;
              // let projectName = this.props.ProjectName;
              // let projectStatus = 6;
              // let projectType = this.props.ProjectType
              const obj = {
                projectId : this.props.ProjectId,
                projectName : this.props.ProjectName,
                projectStatus : 6,
                projectType : this.props.ProjectType,
                callAllProposalApi: true
              }
              // let projectStatus = this.props.ProjectStatus;
            
              // this.props.loadChatAndLatestProposals(projectId,projectName,projectStatus)
              // this.props.loadChatMessages(this.props.CurrentChat);
            
              if(this.props?.history?.location?.pathname?.startsWith("/talentconnect")){
                const pdata = this.props?.postProjectClientBreif?.postprojectdata
                const objj = {
                  projectId : pdata?._id,
                  projectName : pdata?.project_name,
                  projectStatus :pdata?.status,
                  projectType : pdata?.type,
                  callAllProposalApi: true
                }
                this.props.loadChat(objj);
                const slug = pdata?.project_slug; 
                this.props.history.push(`/chat/${objj.projectId}/${slug}`)
              }else{
                this.props.loadChat(obj);
              }
              this.props.closePopup();
             // console.log("payment Details===>", res.data);
            }
          }).catch((error) => {
            console.log(error);
          })
          //  const url = `${API_URL}capture/${paymentId}`;
          //  const captureResponse = await Axios.post(url, {})

         // console.log("Payment Response===>", response);
        } catch (err) {
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      options.prefill = prefill
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    this.setState({isLoading: false});
  };

  render() {
    const { milestone, user } = this.props.proposal_data;
    const {isLoading} = this.state;
    
    let tokenMoney = milestone.find((element) => { return element.type === "token" })
   // console.log(this.props.proposal_data);
    return (
      <>
        <div className="modal fade custom_form show" style={{ paddingRight: "0px", display: "block" }} data-backdrop="static" id="editWorkSection" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div type="button" className="close_icon" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <img onClick={(e) => this.props.closePopup()} alt="" src={require("../../../../Assets/images/close.png")} className="img-fliud" width="35" />
            </span>
          </div>
          <div className="modal-dialog modal-dialog-centered ipad-screen-650 mob-modal-xs" role="document">
            <div className="modal-content height-auto position-bottom-modal breakdown-modal">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0 mobile-hide">
                    <div className="profile-creator ml-05 mob-m-0">
                      <div className="py-30 px-40">
                        <h2 className="mt-0 font-weight-400 mb-1 pt-1 font-16 line-height-14 mob-font-14">You're about to kick off your project <span className="d-inline font-weight-500 font-16">with <span className="mb-0 tools">
                          <img src={BaseImageUrl + user?.profile_image} alt="profile" title="" width="20" />
                        </span> <span className="text-transform">{user?.first_name} {user?.last_name}</span></span></h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mobile-show bg-shadow">
                    <div className="mob-m-0 text-left">
                      <div className="mob-p-15">
                        <h2 className="mt-0 font-weight-500 mb-1 pt-1 font-16 mob-font-18 line-height-14">You're about to kick off your project <span className="d-inline font-weight-600 font-16">with <span className="mb-0 tools">
                          <img src={BaseImageUrl + user?.profile_image} alt="profile" title="" width="20" />
                        </span> <span className="text-transform">{user?.first_name} {user?.last_name}</span></span></h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project">
                    <div className="custom_form px-2 pb-4 pt-4 mob-p-0">
                      <form method="post" action="">
                        <div className="form-group text-left pl-3 pt-3 mob-pr-0 mob-mb-30">
                          <p className="pb-3 font-14 mob-font-16 font-weight-500 line-height-16">
                          <span className="text-transform">{user?.first_name} {user?.last_name}</span> will start working on your project as soon as you make the first payment. Note that you'll not be able to chat with any other recommended creators once you make the initial payment.
								          </p>
                          <button type="button" className="main-btn font-weight-500 py-12 mob-w-100" 
                                  disabled={isLoading}
                                  onClick={this.handleContinue} >
                                    {isLoading ? 
                                      'Processing, please wait...' 
                                      : <> Confirm and pay <i className="fas fa-rupee-sign font-12"></i> {tokenMoney?.price} + GST <i className="fas fa-arrow-right font-12 font-weight-500 ml-1"></i></>}
                            </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""}></div>
      </>
    )
  }
}

const mapState = (store) => {
  let chat = store.chat;
  let creatorProfile = store.creatorProfile;
  let postProjectData = store.postProject;
  return {
    closeModal: creatorProfile.closeModal,
    current_proposal_id: postProjectData.current_proposal_id,
    proposal_data: postProjectData.review_proposal_data,
    CurrentChat: chat.CurrentChat,
    ProjectName: chat.ProjectName,
    ProjectStatus: chat.ProjectStatus,
    ProjectType: chat.ProjectType,
    ProjectId:chat.ProjectId,
  };
};
const mapDispatch = dispatch => ({
  loadChat: (obj) => dispatch({ type: 'UPDATE_LOAD_CHAT', payload: obj }),
  loadChatMessages: (connection_id) => dispatch({ type: 'UPDATE_LOAD_CHATMESSAGES', payload: connection_id }),
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  loadChatAndLatestProposals: (projectId, projectName, projectStatus) => dispatch({ type: 'LOAD_CHAT_AND_LATEST_PROPOSAL', payload: { id: projectId, name: projectName, project_status: projectStatus } }),
});
export default connect(mapState, mapDispatch)(KickStartProject);
