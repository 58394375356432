import {put} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import firebaseIs from '../../../utils/fire';



export function* redirectToNotFoundPage(){
    yield put(push('/not-found'))
}

export function* logoutUser() {

    try {
        localStorage.removeItem("SignUpidToken")
        yield firebaseIs.auth().signOut();
        yield put({type: 'RESET_LOGGED_IN_STATE', value: null})
        yield put(push('/'))
    } catch(error){
        console.log(error)
    }
}