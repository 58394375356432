import { 
    RELEVENT_PROJECTS , 
    RELEVENT_PROJECTS_SUCCESS,
    PROJECT_BY_COLLATERAL,
    PROJECT_BY_COLLATERAL_SUCCESS,
    PROJECT_IDS,
    PROJECT_ATTACHMENTS,
    PROJECT_URLS,
    CLIENT_ACTIVE_PROPOSAL,
    CLIENT_ACTIVE_PROPOSAL_SUCCESS,
    APPROVE_PROPOSAL_AM_SUCCESS,
} from "../type.action";

const initState = {
    data : "",
    loadingIs : false,
    ErrorIs : null,
    projects : null,
    projectLoading :false,
    project_ids : [],
    releventProjectIs : [],
    link_image : [],
    urls : [],
    allProposal : []
}

const reducers = (state = initState, { type, values, error }) => {
    switch (type) {
        case RELEVENT_PROJECTS:
            return{
                ...state,
                loadingIs : true
            }
        case RELEVENT_PROJECTS_SUCCESS:
            return {
                ...state,
                data : values,
                loadingIs : false
            };
        case PROJECT_BY_COLLATERAL:
            return{
                ...state,
                projectLoading : true,
            } 
        case PROJECT_BY_COLLATERAL_SUCCESS:
            return{
                ...state,
                projects : values,
                projectLoading : false
            }   
        case PROJECT_IDS:
            return{
                ...state,
                project_ids : values.project_ids,
                releventProjectIs : values.releventProject
            } 
        case PROJECT_ATTACHMENTS:
            return{
                ...state,
                link_image : values
            }  
        case PROJECT_URLS:
            return{
                ...state,
                urls : values  
            } 
        case CLIENT_ACTIVE_PROPOSAL:
            return{
                ...state,
                loadingIs : true,
                allProposal : null
            }    
        case CLIENT_ACTIVE_PROPOSAL_SUCCESS:
            return{
                ...state,
                loadingIs : false,
                allProposal : values
            }    
        case APPROVE_PROPOSAL_AM_SUCCESS:
            return {
                ...state,
                loadingIs:false,
                allProposal : values
            }   
        default:
        return state;
    }
};

export default reducers;