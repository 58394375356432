import React, { useState } from "react";
import Loader from "../../../../unauthorized/Common/Loader/Loader";
import TerminateProjectMilestone from "../CommonComp/TerminateProjectMilestone";

const TerminateProject = (props) => {
  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeTerminatePopup(false);
    }
  };
  return (
    <>
      <section className="client_appprovepay_wrapper project_termination_milestone_wrapper">
        <div
          className={`modal fade modal_fade_bg ${props?.showTerminatePopup ? "show" : ""}`}
          id="terminateProject"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.showTerminatePopup ? "block" : "" }}
        >
          {/* <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content br_6">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h3 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                    {props.type == "fixed" ? "Mark pending milestone completion" : "Mark end date & pending paytments"}
                  </h3>
                </div>
              </div>

              <div
                className="modal-body py-4 ps-5 pe-5 common_overflow_sm_height"
                style={{ maxHeight: "430px", minHeight: "430px" }}
              >
                <TerminateProjectMilestone {...props} />
              </div>

              <div className="modal-footer d-flex  justify-content-between align-items-center py-4  ps-5 pe-5 border-0">
                <button
                  type="button"
                  className="btn btn-outline-secondary fw_700 m-0"
                  onClick={() => props.changeTerminatePopup(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success fw_700 m-0"
                  onClick={() => {
                    let check = props.type == "fixed" ? props.validationPayable() : props.validationActualRetainer();
                    let go = check ? props.handleTerminalSubmit() : "";
                  }}
                >
                  {props.load ? <Loader isCreateModal={true} /> : "Request Termination"}
                </button>
              </div>
            </div>
          </div> */}

          {/* =====================new ========================= */}
          <div className="modal-dialog modal-dialog-centered popup_modal_dialog_md">
            <div className="modal-content br_6">
              <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between">
                <div className="header">
                  <h5 className="modal-title main_modal_title mb-0" id="exampleModalLabel">
                    {props.type == "fixed" ? "Mark pending milestone completion" : "Mark end date & pending payments"}
                  </h5>
                </div>
                <button
                  type="button"
                  className="close close_btn"
                  aria-label="Close"
                  onClick={() => props.changeTerminatePopup(false)}
                >
                  <span aria-hidden="true" className="close_icon modalclose-btnicon">
                    ×
                  </span>
                </button>
              </div>

              <div className={`modal-body p-0 ${props.type == "fixed" ? "common_overflow_height" : ""}`}>
                <TerminateProjectMilestone {...props} />
              </div>
              <div className="modal-footer justify-content-between align-items-center py-4 pe_3 ps_3">
                <button
                  type="button"
                  class="btn btn-link-text-primary fw_700 m-0"
                  onClick={() => props.changeTerminatePopup(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn_common_sm fw_700"
                  onClick={() => {
                    let check = props.type == "fixed" ? props.validationPayable() : props.validationActualRetainer();
                    let go = check ? props.handleTerminalSubmit() : "";
                  }}
                >
                  {props.load ? <Loader isCreateModal={true} /> : "Request Termination"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TerminateProject;
