import React, { Component } from 'react'

export default class Loader extends Component {
    render() {
        const {style} = this.props;
        const customStyle = style ? style : {};
        return (
            <div className="btn-loader-wrapper" style={customStyle}>
                <div className="btn-loader resume_upload"></div>
            </div>
        )
    }
}
