import React, { useState } from "react";
import { postAPI, putAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

export default function ApproveRejectDeliverable(props) {
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    try {
      let res = "";
      let obj = "";
      if (props.appRejType === "fixed") {
        obj = {
          milestone_id: props?.milestone_id,
          proposal_id: props?.proposalId,
          type: "milestone",
        };
        res = await postAPI("/payment/direct-payment", obj);
      } else {
        obj = {
          deliverable_file_id: props?.singleUploadDocId,
          status: props.appRejType === "approve" ? 1 : 2,
        };
        res = await putAPI("/proposal/status-change-creator-deliverable", obj);
      }
      let isapprove=1;
      // console.log("res === ", res);
      if (res) {
        setLoader(false);
        props.setShowApproveRejectDeliverable(false);
        if (props.appRejType === "fixed") {
          props.setViewUploadDel(false);
          props.handleCallApiAgain();
        } else {
          isapprove=props.appRejType === "approve" ? 1 : 2
          props.setActualAppRejState({
            active: true,
            typeStatus: props.appRejType === "approve" ? 1 : 2,
            id: props?.singleUploadDocId,
          });
          props.setViewDocument(false);
        }
        alert(isapprove === 1 ?`successfully approved`:`successfully rejected`)
      }
    } catch (error) {
      setLoader(false);
      alert("Something went wrong, please try again");
    }
  };

  return (
    <section className="ask_question">
      <div
        className={`modal fade modal_fade_bg ${props?.showApproveRejectDeliverable ? "show" : ""}`}
        id="reviewConfirm"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: props?.showApproveRejectDeliverable ? "block" : "" }}
      >
        <div className="modal-dialog modal-dialog-centered popup_modal_dialog_sm">
          <div className="modal-content">
            <div className="modal-header  d-block">
              <div className="row header_title">
                <div className="col-lg-12">
                  <h5 className="modal-title modal_title mb-3" id="exampleModalLabel">
                    {props.appRejType === "reject" ? "Reject" : "Approve"} Deliverable ?
                  </h5>
                </div>
              </div>
            </div>
            <div className="modal-body py-4">
              <p className="fw_600 fs_14 text-gray-1200">
                Are you sure to {props.appRejType === "approve" ? "approve" : props.appRejType === "reject" ? "reject" : ""} this
                deliverable.
              </p>
            </div>
            <div className="modal-footer br_tp ">
              <button
                type="button"
                className="btn btn_outline_sm  btn_submit mr-4"
                onClick={() => props.setShowApproveRejectDeliverable(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn ${props.appRejType === "reject" ? "btn_common_md_danger" : "btn_common_sm"}`}
                onClick={handleSubmit}
              >
                {loader ? <Loader isCreateModal={true} /> : props.appRejType === "reject" ? "Reject" : "Approve"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
