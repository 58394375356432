import React, {  useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import TrashImage from "../../../../../Assets/upgrade-images/submit_proposal/trash.svg";
import { postAPI } from "../../../../../utils/API";
import Loader from "../../../../unauthorized/Common/Loader/Loader";

const AskQuestion = (props) => {
  const [questions, setQuestions] = useState([{ question: "", edit: true }]);
  const [loader, setLoader] = useState(false);

  const handleQuestions = (e, i) => {
    e.preventDefault();
    const { value } = e.target;
    if (value.length <= 300) {
      questions[i].question = value;
      setQuestions([...questions]);
    }
  };

  const handleEditSave = (i, action) => {
    questions[i].edit = action;
    setQuestions([...questions]);
  };

  const handleAppend = () => {
    setQuestions([...questions, { question: "", edit: true }]);
  };

  const handleDelete = (index) => {
    questions.splice(index, 1);
    setQuestions([...questions]);
  };

  const formValidation = () => {
    let isValid = true;
    if (!questions?.length || (questions?.length > 0 && !questions.filter((item) => item?.question?.trim()?.length > 0).length > 0)) {
      alert("Please fill at least one question.");
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = () => {
    if (formValidation()) {
      onSubmitQuestion();
    }
  };

  const onSubmitQuestion = async () => {
    setLoader(true);
    let arr = [];
    questions.forEach((item) => {
      if (item?.question?.length > 0) {
        arr.push(item?.question?.trim());
      }
    });
    let data = {
      project_id: props?.id,
      questions: arr,
    };
    try {
      let res = await postAPI(`/proposal/ask-question`, data);
      if (res) {
        setLoader(false);
        props.changeShowAskQuestion(false);
        props.setShowAskButton(false);
        setTimeout(() => {
          alert("Questions submitted successfully");
        }, 500);
        props.callApiAgain()        
      } else {
        setLoader(false);
        alert("Something went wrong please try again later");
      }
    } catch (err) {
      setLoader(false);
      alert("Something went wrong please try again later");
    }
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      props.changeShowAskQuestion(false);
    }
  };

  return (
    <React.Fragment>
      <section className="ask_question">
        <div
          className={`modal fade modal_fade_bg ${props?.showAskQuestion ? "show" : ""}`}
          id="reviewConfirm"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: props?.showAskQuestion ? "block" : "" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header br_btm d-block">
                <div className="row header_title mb-3">
                  <div className="col-lg-12 d-flex justify-content-center align-items-center">
                    <h5 className="modal-title modal_title" id="exampleModalLabel">
                      Ask Questions
                    </h5>
                    <button type="button" className="close close_btn" onClick={() => props.changeShowAskQuestion(false)}>
                      <span aria-hidden="true" className="close_icon modalclose-btnicon">
                        ×
                      </span>
                    </button>
                  </div>
                </div>

                <div className="row header_subtitle">
                  <div className="col-lg-12">
                    <p>You can ask upto 3 questions from the client for further clarification</p>
                  </div>
                </div>
              </div>
              <div className="modal-body py-0">
                <div className="container">
                  <div className="row add_question ">
                    {questions?.map((item, i) => {
                      return (
                        <>
                          <div className="col-lg-12 question_box d-flex justify-content-between align-items-center border-bottom py-4 px-0">
                            <p className="mb-0 d-flex align-items-start w-100">
                              <span className="pr-4 pt-3">{i + 1}</span>
                              <span className="w-100">
                              <ReactTextareaAutosize 
                                style={{resize: 'none',overflow:'hidden', padding:'0.95rem'}}
                                id="formGroupExampleInput"
                                value={item.question}
                                onChange={(e) => handleQuestions(e, i)}
                                placeholder="Type your question"
                                className="form-control mb-2"/>
                                <span className="font-weight-500 text-secondary">{questions[i].question.length}/300</span>
                                </span>
                              <img src={TrashImage} onClick={() => handleDelete(i)} className="icon_img cursor-pointer ml-2 pt-3" />
                            </p>
                          </div>
                          {/* {item?.edit ? (
                            <div className="col-lg-12 question_box d-flex justify-content-between align-items-center border-bottom py-4 px-0">
                              <p className="mb-0 d-flex align-items-center w-100">
                                <sapn className="pr-4">{i + 1}</sapn>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="formGroupExampleInput"
                                  value={item.question}
                                  onChange={(e) => handleQuestions(e, i)}
                                  placeholder="Type your question"
                                />
                                <button
                                  onClick={() => handleEditSave(i, false)}
                                  type="button"
                                  className="main-btn ml-2 font-14 py-12 cursor-pointer"
                                >
                                  Save
                                </button>
                              </p>
                            </div>
                          ) : (
                            <div className="col-lg-12 question_box d-flex  justify-content-between align-items-center  border-bottom py-4 px-0">
                              <p className="mb-0">
                                <sapn className="pr-4">{i + 1}</sapn>
                                {item.question}
                              </p>
                              <p className="mb-0">
                                <img
                                  src={PencilImage}
                                  onClick={() => handleEditSave(i, true)}
                                  className="icon_img pr-3 cursor-pointer"
                                />
                                <img src={TrashImage} onClick={() => handleDelete(i)} className="icon_img cursor-pointer" />
                              </p>
                            </div>
                          )} */}
                        </>
                      );
                    })}

                    {questions.length < 3 ? (
                      <div className="col-lg-12 question_box d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 d-flex align-items-center w-100">
                          <span className="cursor-pointer text-blue-800" onClick={handleAppend}>
                            <span className="pr-2"><i class="fa fa-plus" aria-hidden="true"></i></span> Add Question
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer border-top">
                <button type="button" className="btn btn-common btn_submit" onClick={onSubmit}>
                  {loader ? <Loader isCreateModal={true} /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*mobile-view  */}
        <div class="container  d-md-none">
          <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-block">
                <div className="row header_title mb-3">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <h5 className="modal-title modal-title" id="exampleModalLabel">
                      Ask Questions
                    </h5>
                    <button type="button" className="close close_btn" onClick={() => props.changeShowAskQuestion(false)}>
                      <span aria-hidden="true" className="close_icon modalclose-btnicon">
                        ×
                      </span>
                    </button>
                  </div>
                </div>

                <div className="row header_subtitle">
                  <div className="col-9">
                    <p>You can ask upto 3 questions from the client for further clarification</p>
                  </div>
                </div>
              </div>
              <div className="modal-body py-0">
                <div className="container">
                  <div className="row add_question">
                    {questions?.map((item, i) => {
                      return (
                        <>
                          <div className="col-1 pl-0 question_box d-flex align-items-center border-bottom py-4">
                            <p>{i + 1}</p>
                          </div>
                          <div className="col-10 px-0 question_box d-flex align-items-center border-bottom py-4">
                            <input
                              type="text"
                              class="form-control"
                              value={item.question}
                              onChange={(e) => handleQuestions(e, i)}
                              id="formGroupExampleInput"
                              placeholder="Type your question"
                            />
                          </div>
                          <div className="col-1 pl-0 question_box d-flex align-items-center border-bottom py-4">
                            <p>
                              <img src={TrashImage} onClick={() => handleDelete(i)} className="icon_img cursor-pointer"></img>
                            </p>
                          </div>

                          {/* {item?.edit ? (
                            <>
                              <div className="col-1 pl-0 question_box d-flex align-items-center border-bottom py-4">
                                <p>{i + 1}</p>
                              </div>
                              <div className="col-9 px-0 question_box d-flex align-items-center border-bottom py-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={item.question}
                                  onChange={(e) => handleQuestions(e, i)}
                                  id="formGroupExampleInput"
                                  placeholder="Type your question"
                                />
                              </div>
                              <div className="col-2 pl-0 question_box d-flex align-items-center border-bottom py-4">
                                <button
                                  onClick={() => handleEditSave(i, false)}
                                  type="button"
                                  className="main-btn ml-2 font-14 py-12 cursor-pointer"
                                >
                                  Save
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-1 pl-0 question_box d-flex align-items-start border-bottom py-4">
                                <p>{i + 1}</p>
                              </div>
                              <div className="col-8 px-0 question_box d-flex align-items-center border-bottom py-4">
                                <p className="mb-0" style={{wordBreak:"break-all"}}>{item.question}</p>
                              </div>
                              <div className="col-2 question_box d-flex justify-content-end align-items-center border-bottom py-4">
                                <p>
                                  {" "}
                                  <img
                                    src={PencilImage}
                                    onClick={() => handleEditSave(i, true)}
                                    className="icon_img cursor-pointer"
                                  ></img>
                                </p>
                              </div>
                              <div className="col-1 pl-0 question_box d-flex align-items-center border-bottom py-4">
                                <p>
                                  {" "}
                                  <img
                                    src={TrashImage}
                                    onClick={() => handleDelete(i)}
                                    className="icon_img cursor-pointer"
                                  ></img>
                                </p>
                              </div>
                            </>
                          )} */}
                        </>
                      );
                    })}

                    {questions.length < 3 ? (
                      <div className="col-12 question_box d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 d-flex align-items-center w-100 ">
                          <span className="cursor-pointer" onClick={handleAppend}>
                            <span className="pr-4">+</span> Add Question
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer border-top">
                <button type="button" className="btn btn-common btn_submit w-100" onClick={onSubmit}>
                  {loader ? <Loader isCreateModal={true} /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AskQuestion;
