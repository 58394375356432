import React from "react";

const CloseAndDelete = (props) => {
  return (
    <section className="upload_milestone">
      <div
        className={`modal fade modal_fade_bg show`}
        id="CloseAndDelete"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg m-auto ">
          <div className="modal-content mt-5 mb-5 br_6 ">
            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
              <h5 className="modal-title main_modal_title" id="exampleModalLabel">
                Are you sure?
              </h5>
            </div>
            <div className="modal-body br_6 px-2">
              <div className="row mx-0">
                <div className="col-sm-12 pb-5">
                  <p>
                    It will delete all data and files that you have been uploaded.
                  </p>
                </div>
              </div>
            </div>
            <div className=" modal-footer px-4 py-3 d-flex justify-content-end align-items-center">
              <div className="">
                <button
                  type="button"
                  className="btn btn_outline_sm"
                  onClick={() => {
                    props.setCloseUploadMile(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-common-danger ml-3"
                  onClick={() => {
                    props.setFixedLastSubm(false);
                    props.setShowUploadMilestone(false);
                    props.setUploadMileEdit(false)
                    props.setViewAttachments([])
                  }}
                >
                  Close & Delete Files
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CloseAndDelete;
