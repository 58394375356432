export const UPDATE_UPDATECOLLATERAL_SAGA = 'UPDATE_UPDATECOLLATERAL_SAGA';
export const UPDATE_DELETECOLLATERAL_SAGA = 'UPDATE_DELETECOLLATERAL_SAGA';
// export const UPDATE_USERCOLLATERAL_EDIT_SAGA = "UPDATE_USERWORK_EDIT_SAGA";
export const LOAD_ADDCOLLATERALS_SAGA = 'LOAD_ADDCOLLATERALS_SAGA';
export const LOAD_DETAILCOLLATERALS_SAGA = 'LOAD_DETAILCOLLATERALS_SAGA'
export const LOAD_ADDCOLLATERALS = 'LOAD_ADDCOLLATERALS';
export const LOAD_COLLATERALSDATA='LOAD_COLLATERALSDATA';

export const UPDATE_UPDATECOLLATERAL = 'UPDATE_UPDATECOLLATERAL';
export const UPDATE_ADDCOLLATERAL = 'UPDATE_ADDCOLLATERAL';
export const UPDATE_DELETECOLLATERAL = 'UPDATE_DELETECOLLATERAL';

export const UPDATE_ALLCOLLATERAL_RESET = 'UPDATE_ALLCOLLATERAL_RESET';

export const UPDATE_ALLCOLLATERAL = 'UPDATE_ALLCOLLATERAL';
export const UPDATE_ALLCOLLATERAL_NAME = 'UPDATE_ALLCOLLATERAL_NAME';
export const UPDATE_ALLCOLLATERAL_DURATION = 'UPDATE_ALLCOLLATERAL_DURATION';
export const UPDATE_ALLCOLLATERAL_ENDPRICE = 'UPDATE_ALLCOLLATERAL_ENDPRICE';
export const UPDATE_ALLCOLLATERAL_STARTPRICE =
  'UPDATE_ALLCOLLATERAL_STARTPRICE';
export const UPDATE_ALLCOLLATERAL_AVGTIME = 'UPDATE_ALLCOLLATERAL_AVGTIME';
export const UPDATE_ALLCOLLATERAL_EXPERIENCE =
  'UPDATE_ALLCOLLATERAL_EXPERIENCE';
export const UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH =
  'UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH';
export const UPDATE_ALLCOLLATERAL_ID = 'UPDATE_ALLCOLLATERAL_ID';
export const UPDATE_ALLCOLLATERAL_TYPEINDEX = 'UPDATE_ALLCOLLATERAL_TYPEINDEX';

export const UPDATE_COLLATERAL_ERROR = 'UPDATE_COLLATERAL_ERROR';
export const UPDATE_ALLCOLLATERAL_NAME_ERROR =
  'UPDATE_ALLCOLLATERAL_NAME_ERROR';
export const UPDATE_ALLCOLLATERAL_DURATION_ERROR =
  'UPDATE_ALLCOLLATERAL_DURATION_ERROR';
export const UPDATE_ALLCOLLATERAL_ENDPRICE_ERROR =
  'UPDATE_ALLCOLLATERAL_ENDPRICE_ERROR';
export const UPDATE_ALLCOLLATERAL_STARTPRICE_ERROR =
  'UPDATE_ALLCOLLATERAL_STARTPRICE_ERROR';
export const UPDATE_ALLCOLLATERAL_AVGTIME_ERROR =
  'UPDATE_ALLCOLLATERAL_AVGTIME_ERROR';
export const UPDATE_ALLCOLLATERAL_EXPERIENCE_ERROR =
  'UPDATE_ALLCOLLATERAL_EXPERIENCE_ERROR';
export const UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH_ERROR =
  'UPDATE_ALLCOLLATERAL_EXPERIENCEMONTH_ERROR';
  
export const UPDATE_STYLE_ANSWER_SAGA = 'UPDATE_STYLE_ANSWER_SAGA';
export const UPDATE_TYPE_ANSWER_SAGA = 'UPDATE_TYPE_ANSWER_SAGA';

export const GET_COLLATERAL_INFO_TEXT = 'GET_COLLATERAL_INFO_TEXT';
export const UPDATE_STYLE_ANSWER = 'UPDATE_STYLE_ANSWER';
export const UPDATE_TYPE_ANSWER = 'UPDATE_TYPE_ANSWER';
  export const GET_QNA_IN_USER_COLLATERAL = 'GET_QNA_IN_USER_COLLATERAL';
export const RESET_COLLATERAL_QNA = 'RESET_COLLATERAL_QNA';
export const UPDATE_RESET_COLLATERAL_QNA = 'UPDATE_RESET_COLLATERAL_QNA';
export const RESET_QNA_IN_USER_COLLATERAL = 'RESET_QNA_IN_USER_COLLATERAL';

// TypeIndex

export const UPDATE_ALLCOLLATERAL_ENABLEEDIT =
  'UPDATE_ALLCOLLATERAL_ENABLEEDIT';