//Load Incomplete Briefs 
export const LOAD_INCOMPLETE_BRIEF = "LOAD_INCOMPLETE_BRIEF";
export const GET_INCOMPLETE_BRIEFS = "GET_INCOMPLETE_BRIEFS";
export const GET_DRAFT_PROJECTS    = "GET_DRAFT_PROJECTS";

export const GET_ONGOING_PROJECTS  = "GET_ONGOING_PROJECTS";
export const GET_CLIENT_COMPLETED_PROJECT = "GET_CLIENT_COMPLETED_PROJECT";
export const GET_CLIENT_CLOSED_PROJECT    = "GET_CLIENT_CLOSED_PROJECT";

//Load Incoming Briefs
export const LOAD_INCOMING_BRIEF = "LOAD_INCOMING_BRIEF";
export const GET_INCOMING_BRIEFS = "GET_INCOMING_BRIEFS";
export const DASHBOARD_DATA_LOADING = "DASHBOARD_DATA_LOADING";


export const LOAD_BRIEF_DATA = "LOAD_BRIEF_DATA";
export const VIEW_INCOMING_BRIEF_SAGA = "VIEW_INCOMING_BRIEF_SAGA";
export const GET_BRIEF_DATA  = "GET_BRIEF_DATA";
export const UPDATE_VIEW_INCOMING_BRIEF  = "UPDATE_VIEW_INCOMING_BRIEF";


export const LOAD_COLLATERAL_BASED_PROJECTS = "LOAD_COLLATERAL_BASED_PROJECTS";
export const GET_COLLATERAL_BASED_PROJECTS  = "GET_COLLATERAL_BASED_PROJECTS";

export const GET_DEFAULT_COLLATERAL_BASED_PROJECTS = "GET_DEFAULT_COLLATERAL_BASED_PROJECTS";

export const REJECT_INCOMING_BRIEF = "REJECT_INCOMING_BRIEF";
export const UPDATE_INCOMING_BRIEF = "UPDATE_INCOMING_BRIEF";

export const UPDATE_DELETE_INCOMPLETE_BRIEF = "UPDATE_DELETE_INCOMPLETE_BRIEF";
export const DELETE_INCOMPLETE_BRIEF_SAGA = "DELETE_INCOMPLETE_BRIEF_SAGA";
export const GET_PROPOSAL_PROJECT = "GET_PROPOSAL_PROJECT";

export const GET_CREATOR_ONGOING_PROJECT = "GET_CREATOR_ONGOING_PROJECT";
export const GET_CREATOR_COMPLETED_PROJECT = "GET_CREATOR_COMPLETED_PROJECT";
export const GET_CREATOR_CLOSED_PROJECT  = "GET_CREATOR_CLOSED_PROJECT";

export const UNREAD_MSG_DASHBOARD  = "UNREAD_MSG_DASHBOARD";


export const GET_UPCOMING_MILESTONES = "GET_UPCOMING_MILESTONES";
export const GET_USER_MILESTONES = "GET_USER_MILESTONES";

export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_DONE = "GET_JOBS_DONE";


// export const BRIEF_ACCEPT_SUCCESSED = "BRIEF_ACCEPT_SUCCESSED";
export const CHANGE_PROJECT_TYPE = "CHANGE_PROJECT_TYPE";

export const RESET_COLLATERAL_PROJECTS_DATA = "RESET_COLLATERAL_PROJECTS_DATA";
export const LOAD_ALL_PROJECTS = "LOAD_ALL_PROJECTS";
export const LOAD_ALL_PROJECTS_SAGA = "LOAD_ALL_PROJECTS_SAGA";
export const DELETE_CREATOR_PROJECT = "DELETE_CREATOR_PROJECT";
export const UPDATE_DELETE_CREATOR_PROJECT = "UPDATE_DELETE_CREATOR_PROJECT";
export const UPDATE_DELETE_CREATOR_PROJECT_FROM_ALL_PROJECTS = "UPDATE_DELETE_CREATOR_PROJECT_FROM_ALL_PROJECTS";

// test
export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";
