export const HandleScheduleDate = (date) => {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let smonth = date.toLocaleString('en-GB', { month: 'short' });
    let sday = weekday[date.getDay()]
    let sdate = date.getDate();

    return { date: `${sday}, ${smonth} ${sdate}`, timezone: date };
}


export const handleDateFormat = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const newDate = `${month}-${day}-${year}`;
    return newDate;
}

export const handleTimeFormat = (time) => {
    const hour = time.slice(0, 2);
    const min = time.slice(2, 4);
    const finalHours = time.slice(0, 2) > 12 ? time.slice(0, 2) - 12 : time.slice(0, 2);
    const ts = hour > 11 ? 'PM' : 'AM';
    const complete_time = `${String(finalHours).padStart(2, '0')}:${min} ${ts}`;
    return complete_time;
    
}

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
export const getDateTime = (date,format) => {
    date      = (date === undefined) ? new Date() : date;
    let day    = new Date(date).getDate();
    let month    = new Date(date).getMonth();
    let year    = new Date(date).getFullYear();
    let hour   = new Date(date).getHours();
    let minute = new Date(date).getMinutes();
    let second = new Date(date).getSeconds();
    let ap = "AM";
    if (hour   > 11) { ap = "PM";             }
    if (hour   > 12) { hour = hour - 12;      }
    if (hour   === 0) { hour = 12;             }
    if (hour   < 10) { hour   = "0" + hour;   }
    if (minute < 10) { minute = "0" + minute; }
    if (second < 10) { second = "0" + second; }
    let timeString = "";
    if(format === "date") {
        timeString = day+ '-' +month+ '-' + year
    } 
    if(format === "time") {
        timeString = hour + ':' + minute + " " + ap;
    }
    if(format === "dateTime") {
        timeString = day+ '-' +month+ '-' + year+ ' '+ hour + ':' + minute + " " + ap;
    }
    if(format === 'dateMonth'){
        timeString = day+ ' ' +months[month]+ ' ' + year
    }
    if(format === 'day'){
        timeString = day
    }
    if(format === 'month'){
        timeString = months[month]
    }
    return timeString;
}
