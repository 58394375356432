import React from 'react';
import { connect } from 'react-redux';
import { getAPI } from '../../../../utils/API';
// import BtnLoader from '../../Common/Common/BtnLoader';
import Loader from './../../../unauthorized/Common/Loader/Loader';

// const UpdateSpecialization = [];

class Languages extends React.Component {
  state = {
    languages: [],
    language: '',
    allLanguages: [],
    isListVisible: false,
    seletedLanguages: [],
    languageInput: '',
    error: false,
  };

  handleOnChange = async (e) => {
    try {
      const value = e.target.value;
      this.setState({languageInput: value})
      const allLanguages = await getAPI(`/Language?name=${value}`);
      this.setState({
        allLanguages: allLanguages.data.data,
        isListVisible: true,
      });
      // console.log('%c ALL Languages', 'font-size: 22px; color:orange');
      // console.log(allLanguages.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  showList = async () => {
    try {
        
      this.setState({isListVisible: !this.state.isListVisible,})
      const allLanguages = await getAPI(`/Language?name=`);
      const newLangs = allLanguages.data.data.filter(
        (el) => !this.state.seletedLanguages.includes(el.name)
      );
      this.setState({
        allLanguages: newLangs,
      });
      // console.log(allLanguages.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  onSelectHandler = (obj) => {
    // console.log(obj);
    const { seletedLanguages } = this.state;
    if (seletedLanguages.includes(obj.name)) {
      alert(`${obj.name} is already selected.`)
      return;
    }

    this.setState({
      seletedLanguages: [...seletedLanguages, obj.name],
      isListVisible: false,
      languageInput: '',
      error: false,
    });
  };

  componentDidMount() {
    if (this.props.Languages) {
      this.setState({ seletedLanguages: this.props.Languages.map(obj => obj.name ? obj.name : obj) });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const langs = this.state.seletedLanguages;
    if (langs.length === 0) {
      this.setState({ error: true });
      return;
    }

    this.props.UpdateUserLanguage({
      languages: langs,
    });
  };

  closePopup = () => {
    this.props.closePopup();
  };

  handleLanguageOnChange = (event) => {
    let language = event.target.value;
    this.setState({
      language: language,
    });
  };

  handleLanguages = (event) => {
    if (event.charCode === 13) {
      let languages = this.props.UpdateLanguages;
      let language = this.state.language;
      if (languages.length <= 4) {
        languages.push({ name: language });
      }
      this.setState(
        {
          languages,
          language: '',
        },
        (e) =>
          this.props.onFieldChange({
            name: 'LANGUAGE_UPDATE',
            value: languages,
          })
      );
      // console.log(languages);
    }
  };

  deleteItems = (name, type) => {
    const newArray = this.state.seletedLanguages.filter(
      (lang) => lang !== name
    );
    this.setState({ seletedLanguages: newArray });
    // if (type == 'language') {
    //   let myNewArray = this.props.UpdateLanguages.filter(function (items) {
    //     return items.name !== obj.name;
    //   });
    //   this.setState(
    //     {
    //       languages: myNewArray,
    //     },
    //     (e) =>
    //       this.props.onFieldChange({
    //         name: 'LANGUAGE_UPDATE',
    //         value: myNewArray,
    //       })
    //   );
    // }
  };

  render() {
    const { saving } = this.props;
    const { seletedLanguages, error } = this.state;

    return (
      <>
        <div
          className="modal custom_form verify_modal edit-basic-profile show"
          data-backdrop="static"
          style={{ paddingRight: '0px', display: 'block' }}
          id="EditLanguagesModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <span className="close_icon" data-dismiss="modal" aria-label="Close">
            <img
              onClick={(e) => this.closePopup()}
              alt=""
              src={require('../../../../Assets/images/close.png')}
              className="img-fliud"
              width="35"
            />
          </span>
          <div
            className="modal-dialog modal-dialog-centered modal-lg ipad-screen-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-sm-12 p-0">
                    <div className="profile-creator">
                      <div className="px-4 py-3">
                        <p className="text-white mb-1 font-weight-500">
                          Edit Profile
                        </p>
                        <h2 className="mt-0 font-weight-500">Languages</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 mob-pt-25 mob-col-100">
                    <div className="custom_form py-4 px-2 mob-p-0">
                      <form onSubmit={(e) => this.onSubmit(e)}>
                        <div className="row m-0">
                          <div className="form-group w-98 mb-4 col-sm-12 p-0 mob-p-0 mob-w-100">
                            <label
                              className="label font-weight-600 font-14 mob-font-13"
                              htmlFor="last"
                            >
                              Choose your Languages
                            </label>
                            <div
                              className={`select-tags d-flex align-items-center flex-wrap ${
                                error ? 'border-error' : ''
                              }`}
                            >
                              <div>
                                {seletedLanguages.map((name, index) => (
                                  <span
                                    className="selected-tags common-tags py-10 px-15"
                                    key={index}
                                  >
                                    {name}
                                    <i
                                      className="fas fa-times ml-1 font-13"
                                      onClick={() => this.deleteItems(name)}
                                    ></i>
                                  </span>
                                ))}
                                {seletedLanguages.length < 5 && 
                                  <span style={{ display: 'inline-block' }}>
                                    <input
                                      type="text"
                                      className="w-230 form-control email pl-3 tags-input py-3"
                                      placeholder="Choose your language"
                                      autoComplete="off"
                                      name="languages"
                                      value={this.state.languageInput}
                                      onChange={this.handleOnChange}
                                      onClick={this.showList}
                                      // onBlur={() =>
                                      //   this.setState({ isListVisible: false })
                                      // }
                                    />
                                  </span>
                                }
                              </div>

                              {/*
                               <div>
                                {UpdateLanguages?.length <= 5 &&
                                  UpdateLanguages.map((obj, index) => (
                                    <span
                                      className="selected-tags p-2 mb-2"
                                      key={index}
                                    >
                                      {obj.name}{' '}
                                      <i
                                        className="fas fa-times ml-1 font-13"
                                        onClick={() =>
                                          this.deleteItems(obj, 'language')
                                        }
                                      ></i>
                                    </span>
                                  ))}
                                <span style={{ display: 'inline-block' }}>
                                  <input
                                    type="text"
                                    className="w-230 form-control email tags-input"
                                    placeholder="Input your languages press enter"
                                    autoComplete="off"
                                    name="specilization"
                                    value={language}
                                    onChange={(e) =>
                                      this.handleLanguageOnChange(e)
                                    }
                                    onKeyPress={(e) => this.handleLanguages(e)}
                                  />
                                </span>
                              </div> 
                              */}
                            </div>
                            {error ? (
                              <span className="text-danger font-weight-600 font-12 py-1">
                                select atleast one language
                              </span>
                            ) : null}
                            {this.state.allLanguages.length > 0 &&
                            this.state.isListVisible === true ? (
                              <ul
                                className="select-options select-top100 hideList"
                                style={{ display: 'block' }}
                              >
                                <li rel="hide">Choose here</li>
                                {this.state.allLanguages &&
                                  this.state.allLanguages.map((obj, index) => {
                                    return (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          this.onSelectHandler(obj)
                                        }
                                      >
                                        {obj.name}
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {/* <div className="form-group text-right pt-3 mob-pr-0 mob-mb-30 mob-pt-0">
                          <button type="submit" className="main-btn px-4" disabled={saving}>
                            {saving === true
                              ?
                              "Saving..."
                              :
                              "Save"
                            }
                          </button>
                        </div> */}
                      </form>
                    </div>
                    <div className="row mt-2 bg-shadow-top mobile-edit-collateral">
                      <div className="col-sm-12 p-0 text-right pr-3 py-3">
                        <button
                          type="button"
                          onClick={(e) => this.closePopup()}
                          className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                        <button
                          type="submit"
                          className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
                          onClick={this.onSubmit}
                          disabled={saving}
                        >
                          {saving === true ? <Loader profileCreator="true"/> : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-shadow-top desktop-ipad-show">
                    <div className="form-group text-right pr-3 pt-3">
                      <button
                        type="button"
                        onClick={(e) => this.closePopup()}
                        className="main-btn bg-light-gray font-weight-600 cursor-pointer"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button type="button" className="main-btn px-4 ml-2">Save</button> */}
                      <button
                        type="submit"
                        className={`main-btn px-4 ml-2 ${saving ? 'p-0' : ''}`}
                        onClick={this.onSubmit}
                        data-dismiss="modal"
                        disabled={saving}
                      >
                        {saving === true ? <Loader profileCreator="true"/> : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.closeModal === true ? 'back-modal' : ''}
        ></div>
      </>
    );
  }
}

const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  let globalReducer = store.globalReducer;
  return {
    saving: globalReducer.saving,
    closeModal: creatorProfile.closeModal,
    UpdateLanguages: creatorProfile.UpdateLanguages,
    Languages: creatorProfile.Languages,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
  onFieldChange: (e) =>
    dispatch({
      type: 'ON_FIELDCHANGE_SAGA',
      payload: { name: e.name, value: e.value },
    }),
  UpdateUserLanguage: (e) =>
    dispatch({ type: 'UPDATE_LANGUAGE_SAGA', payload: e }),
});

export default connect(mapState, mapDispatch)(Languages);
