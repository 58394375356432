import React from 'react';
import { connect } from 'react-redux';
import ProgressBar from './Progressbar';
import { putAPI } from '../../../utils/API';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
class Unit extends React.Component {
	state = {
		unitError: false,
		isLoading: false,
	}

	handleContinue = () => {
		const { projectId, unit, post_steps, total_steps,unitChanged,isHireflow } = this.props;
		this.setState({isLoading: true})
		if(!unitChanged || unit <= 0) {
			this.setState({
				unitError: true,
				isLoading: false,
			})
            //alert("Please enter unit to continue");
            return;
        }
		const unit_data = {
			_id: projectId,
			unit: unit,
		}

		if (this.props.userName) {
			putAPI('/project/post-project-unit', unit_data).then((res) => {
				if (res.data.status === "1") {
					this.props.PostProjectSteps(post_steps, total_steps);
					if(this.props.typeQuestions.length>0){
					this.props.history.push(`${isHireflow?'/hire/type':'/self-service-hire/deadline'}`);
					}else if(this.props.StyleQuestions.length>0){
					this.props.history.push(`${isHireflow?'/hire/style':'/self-service-hire/deadline'}`);
						
					}else{

						this.props.history.push(`${isHireflow?'/hire/projectName':'/self-service-hire/deadline'}`);
					}
					// console.log("Unit Response", res.data);
					this.setState({isLoading: false})
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("UnitData",JSON.stringify(unit_data));
			this.props.PostProjectSteps(post_steps, total_steps);
			if(this.props.typeQuestions.length>0){
				this.props.history.push(`${isHireflow?'/hire/type':'/self-service-hire/deadline'}`);
				}else if(this.props.StyleQuestions.length>0){
				this.props.history.push(`${isHireflow?'/hire/style':'/self-service-hire/deadline'}`);
					
				}else{

					this.props.history.push(`${isHireflow?'/hire/projectName':'/self-service-hire/deadline'}`);
				}
			this.setState({isLoading: false})
		}
	}

	componentDidMount = () => {
		this.initializeSlider();
	}

	removeError = () => {
		this.setState({
			unitError:false,
		})
	}

	initializeSlider() {
		const that = this;
		let minUnit = that.props.minUnit;
        let maxUnit = that.props.maxUnit;
		$("#slider-range").slider({
			range: true,
			min: 5000,
			max: 70000,
			step: 50,
			values: [minUnit, maxUnit],
			change: function (e, ui) {
				$("#MINIMUM_UNIT").val(ui.values[0]);
				$("#MAXIMUM_UNIT").val(ui.values[1]);
				let name = (ui.handleIndex === 1) ? "MAXIMUM_UNIT" : "MINIMUM_UNIT";
				let value = (ui.value);
				// console.log(name, value);
				that.props.OnFieldChange(name, value)
			},
			create: function () {
				let values = $(this).slider("option", "values");
				$("#MINIMUM_UNIT").val(values[0]);
				$("#MAXIMUM_UNIT").val(values[1]);
			}
		})

		const toInt = input => {
			let val = Number(input);

			if (Number.isInteger(val)) {
				return Number(val);
			} else {
				return 0;
			}
		};

		$("#MINIMUM_UNIT").change(function () {
			let unit_min = toInt($(this).val());
			let unit_max = toInt($("#MAXIMUM_UNIT").val());

			if (unit_min >= unit_max) {
				let temp  = unit_min
				unit_min = unit_max;
				unit_max = temp;
			}
			$("#slider-range").slider("values", 0, unit_min);
			$("#slider-range").slider("values", 1, unit_max);
		});

		$("#MAXIMUM_UNIT").change(function () {
			let unit_max = toInt($(this).val());
			let unit_min = toInt($("#MINIMUM_UNIT").val());

			if (unit_max <= unit_min) {
				let temp = unit_max
				unit_max = unit_min;
				unit_min = temp;
			}
			$("#slider-range").slider("values", 0, unit_min);
			$("#slider-range").slider("values", 1, unit_max);
		});

		$('.ui-slider-handle').html("|||");
	}
// unit_question_subtext
	render() {
		let {unit,unit_question,unit_tag, unit_question_subtext} = this.props;
		let {unitError} = this.state;
		return (
			<>
				<section>
					<div className="card-wrapper">
						<div className="card mob-border-radius-0">
							<div className="card-body">
								<div className="row m-0">
									<div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project overflow-content project-height"> 
										<div className="custom_form px-4 pb-4 pt-2 mob-p-0">
											<div className="form-group mb-4 col-sm-12 pl-0 mob-p-0">
												<div  className="pt-5 pb-4 mob-pt-20">
													<h2 className="font-22 mob-font-16 font-weight-600 text-capitalize">{unit_question}</h2> 
													{(unit_question_subtext !== "") ?
													<p  className="mb-0 label font-weight-500 mob-font-12 text-third">{unit_question_subtext}</p>
													: <></>}
												</div>
												<div className="d-flex align-items-center flex-wrap">
												<input type="number" name="UNIT" className={`form-control email font-weight-500 w-24 mob-w-100 ${(unitError === true) ? 'border-error' :''}`} value={unit} 
													onChange={(e) => {
														this.props.OnFieldChange(e.target.name, e.target.value);
														this.removeError();
														}} /> 
														<p className="d-block ml-2 mt-2 mob-pt-5 text-sub font-weight-500">{unit_tag}</p>
												</div>
												{(unitError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please input units.</p> : ""}	
											</div> 
										</div>
									</div>
									<ProgressBar {...this.state} handleContinue={this.handleContinue} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}

const mapState = (store) => {
	let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
	return {
		unit: (postProjectData?.unit !== undefined) ? postProjectData.unit : postProjectData?.CollateralQuestions?.collateral?.unit,
		unit_question: (postProjectData?.unit_question !== undefined) ? postProjectData.unit_question : postProjectData?.CollateralQuestions?.collateral?.unit_question,
		unitChanged: postProjectData.unitChanged,
		unit_question_subtext: postProjectData.unit_question_subtext,
		unit_tag:postProjectData?.CollateralQuestions?.collateral?.unit,
        typeQuestions: (postProjectData?.typeQuestions?.length > 0) ?  (postProjectData.typeQuestions) : postProjectData?.CollateralQuestions?.Collateral_type_question,
        StyleQuestions:(postProjectData?.styleQuestions?.length > 0) ? (postProjectData.styleQuestions):  postProjectData.CollateralQuestions.Collateral_style_question,

		projectId: postProjectData.projectId,
		post_steps: postProjectData.post_steps,
		total_steps: postProjectData.total_steps,
		alreadyCreatedProject: postProjectData.alreadyCreatedProject,
		userName: globalReducer.Me.Username,
	};	
}

const mapDispatch = dispatchEvent => ({
	OnFieldChange: (name, value) => dispatchEvent({ type: 'ON_FIELD_CHANGE_POST_PROJECT', payload: { name: name, value: value } }),
	PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
});
export default connect(mapState, mapDispatch)(Unit);