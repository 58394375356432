import React, { useEffect, useState } from "react";
import ViewDocument from "../ViewDocument";
import moment from "moment";
import ApproveRejectDeliverable from "../ApproveRejectDeliverable";
import { getQueryParams, handleStatusDel } from "../../CommonFunctionsNew/CommonFunctionsNew";
import ViewUploadedDocuments from "../../CommonComp/ViewUploadedDocuments";
import Clock from "../../../../../../Assets/upgrade-images/view_deliverable_monthly/clock.png";
import InfoCircle from "../../../../../../Assets/upgrade-images/view_deliverable/info_circle.png";
import { getRealFileName, handleFileName } from "../../CommonFunctionsNew/CommonFunctionsNew";
import Loader from "../../../../Common/Common/Loader";
import { sanitiseSummernoteText } from "../../../../../../utils/Helperfunctions";

const ChatViewUploadDel = (props) => {
  const [documentDetail, setDocumentDetail] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [data, setData] = useState("");
  const [showApproveRejectDeliverable, setShowApproveRejectDeliverable] = useState(false);
  const [appRejType, setAppRejType] = useState("");
  const [singleUploadDocId, setSingleUploadDocId] = useState("");
  const [actualAppRejState, setActualAppRejState] = useState({
    active: false,
    typeStatus: 4,
    id: "",
  });
  const [viewDocument, setViewDocument] = useState(false);
  const [arrPending, setArrPending] = useState([]);
  const [arrApprove, setArrApprove] = useState([]);
  const [arrReject, setArrReject] = useState([]);
  const [arrAll, setArrAll] = useState([]);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    handleData(props?.viewLatestAttachments);
  }, [props?.viewLatestAttachments]);

  const handleData = (obj) => {
    if (Object.keys(obj).length) {
      setAttachments(props.fixedLastSubm ? [...obj.RawFile] : [...obj.allFiles]);
      setData(obj);
    }
  };

  const handleCancelViewUploadDel = () => {
    let id1 = getQueryParams("id1");
    let id2 = getQueryParams("id2");
    let id3 = getQueryParams("id3");
    if (props.proposal_type === "actual" && actualAppRejState.id) {
      props.handleCallApiAgain();
    }
    props.setFixedLastSubm(false);
    props.setViewUploadDel(false);
    if (id1?.length || id2?.length || id3?.length) {
      props.history.push(`/projects/${props?.projectDataStates?.id}/overview`);
    }
  };

  const handleSetArray = (data) => {
    let arrPen = [];
    let arrApp = [];
    let arrRej = [];
    let arrAl = [];
    data.forEach((element) => {
      arrPen.push({ ...element, allFiles: element.allFiles.filter((item) => item.status == 0) });
      arrApp.push({ ...element, allFiles: element.allFiles.filter((item) => item.status == 1) });
      arrRej.push({ ...element, allFiles: element.allFiles.filter((item) => item.status == 2) });
      arrAl.push({ ...element });
    });
    setArrPending(arrPen);
    setArrApprove(arrApp);
    setArrReject(arrRej);
    setArrAll(arrAl);
    props.setAllData([...data]);
  };

  useEffect(() => {
    if (actualAppRejState.active) {
      if (props.allData.length > 0) {
        setLoad(true);
        let passObjViewDoc = "";
        let arr = props.allData.length
          ? props.allData.map((item) => {
              let all_files = item.allFiles.map((obj) => {
                if (viewDocument && obj._id == actualAppRejState.id) {
                  passObjViewDoc = {
                    ...obj,
                    status: actualAppRejState.typeStatus,
                  };
                }
                return {
                  ...obj,
                  status: obj._id == actualAppRejState.id ? actualAppRejState.typeStatus : obj.status,
                };
              });
              return { ...item, allFiles: all_files };
            })
          : [];
        if (viewDocument) {
          setDocumentDetail(passObjViewDoc);
        }
        handleSetArray(arr);
        setLoad(false);
      }
    }
  }, [actualAppRejState]);

  const isFixed = props?.proposal_type && props.proposal_type.toLowerCase() == "fixed";
  const isActual = props?.proposal_type && props.proposal_type.toLowerCase() == "actual";
  const isRetainer = props?.proposal_type && props.proposal_type.toLowerCase() == "retainer";
  let dateDur = "";
  if (isRetainer) {
    dateDur =
      moment(props?.viewAttachments?.start_duration).format("D MMM") +
      " - " +
      moment(props?.viewAttachments?.end_duration).format("D MMM");
  }

  return (
    <section className="worklog_upload_deliverable_wrappper upload_milestone work_log_view_file_wrapper">
      <div
        className={`modal fade modal_fade_bg ${props?.viewUploadDel ? "show" : ""}`}
        id="ViewingProposalFC"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: props?.viewUploadDel ? "block" : "" }}
      >
        <div className="modal-dialog modal-dialog-centered popup_modal_dialog_lg">
          <div className="modal-content br_6 overflow_hidden ">
            {!props.loader ? (
              <>
                <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
                  <h5 className="modal-title main_modal_title" id="exampleModalLabel">
                    {props.fixedLastSubm ? "Final Submission" : isRetainer ? dateDur : props?.viewAttachments?.name}
                  </h5>
                  <button
                    type="button"
                    className="close close_btn"
                    onClick={() => (viewDocument ? setViewDocument(false) : handleCancelViewUploadDel())}
                  >
                    <span aria-hidden="true" className="modalclose-btnicon">
                      ×
                    </span>
                  </button>
                </div>
                {viewDocument ? (
                  <ViewDocument
                    documentDetail={documentDetail}
                    isActual={isActual}
                    isRetainer={isRetainer}
                    isFixed={isFixed}
                    setAppRejType={setAppRejType}
                    setSingleUploadDocId={setSingleUploadDocId}
                    setShowApproveRejectDeliverable={setShowApproveRejectDeliverable}
                    actualAppRejState={actualAppRejState}
                    setActualAppRejState={setActualAppRejState}
                    {...props}
                    viewDocument={viewDocument}
                    setViewDocument={setViewDocument}
                    showChatMess={true}
                  />
                ) : (
                  <div className="modal-body br_6 py-0 px-0 upload_milestone_bg">
                    <div className="row mx-0">
                      <div
                        className={`col-12 col-sm-${isActual ? "12" : "7"} col-md-${isActual ? "12" : "7"} col-lg-${
                          isActual ? "12" : "7"
                        } col-xl-${isActual ? "12" : "7"} ${isActual ? "p-0" : "p-4"}  body_scroll`}
                      >
                        <ViewUploadedDocuments
                          documentDetail={documentDetail}
                          setDocumentDetail={setDocumentDetail}
                          attachments={attachments}
                          isActual={isActual}
                          setAppRejType={setAppRejType}
                          setSingleUploadDocId={setSingleUploadDocId}
                          setShowApproveRejectDeliverable={setShowApproveRejectDeliverable}
                          actualAppRejState={actualAppRejState}
                          setActualAppRejState={setActualAppRejState}
                          viewDocument={viewDocument}
                          setViewDocument={setViewDocument}
                          arrPending={arrPending}
                          arrApprove={arrApprove}
                          arrReject={arrReject}
                          arrAll={arrAll}
                          load={load}
                          setLoad={setLoad}
                          handleSetArray={handleSetArray}
                          {...props}
                        />
                      </div>
                      {isActual ? (
                        ""
                      ) : isRetainer ? (
                        <RightViewUploadDelRetainer viewLatestAttach={props?.viewLatestAttachments} {...props} />
                      ) : (
                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 p-3 ipad-w-100 px-4 common_overflow_height white_bg">
                          <RightViewUploadDel {...props} />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* ========================footer================ */}

                {isFixed && !props?.viewLatestAttachments?.isExpired ? (
                  <div className=" modal-footer px-4 py-3 d-flex justify-content-between align-items-center">
                    <div className="btn_outer">
                      {props?.viewLatestAttachments?.status == 1 || props?.mileObj?.status == 2 || props?.role == 2 ||
                      props?.projectDataStates?.data?.project_data?.terminate_request == 1 || props?.projectDataStates?.data?.project_data?.terminate_request == 2
                      ? (
                        null
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn_common_gray_md mr-2"
                            onClick={() => {
                              props.setReworkId(props?.viewLatestAttachments?._id);
                              props.setRetHourlyReqType("rework");
                              props.setRetHourlyReq(true);
                            }}
                          >
                            Request Rework
                          </button>

                          <button
                            type="button"
                            className="btn btn_common_md"
                            onClick={() => {
                              setAppRejType("fixed");
                              setShowApproveRejectDeliverable(true);
                            }}
                          >
                            Approve
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <Loader isCreateModal={true} style={{ paddingTop: "20rem", paddingBottom: "20rem" }} />
            )}
          </div>
        </div>
      </div>
      {showApproveRejectDeliverable && (
        <ApproveRejectDeliverable
          showApproveRejectDeliverable={showApproveRejectDeliverable}
          setViewDocument={setViewDocument}
          setShowApproveRejectDeliverable={setShowApproveRejectDeliverable}
          appRejType={appRejType}
          singleUploadDocId={singleUploadDocId}
          actualAppRejState={actualAppRejState}
          setActualAppRejState={setActualAppRejState}
          {...props}
        />
      )}
    </section>
  );
};

export default ChatViewUploadDel;

export const RightViewUploadDel = (props) => {
  let obj = "";
  if (props.fixedLastSubm) {
    let arr = props.viewLatestAttachments.RawFile.filter((item) => item.type == "rowDescription");
    obj = arr[0];
  } else {
    obj = props?.viewLatestAttachments;
  }
  const allData = props?.viewAttachments;
  // let description = props.fixedLastSubm ? obj.name : obj.description;
  const description = props.fixedLastSubm ? sanitiseSummernoteText(obj?.name?.replace(/\n/g,'<br/>')) : sanitiseSummernoteText(obj?.description?.replace(/\n/g,'<br/>'));

  return (
    <>
      <div className="row mx-0 mb-4 pb-2">
        <div className="col-lg-12 px-0">
          <p className="text_black_700_w_600 mb-2">{props.fixedLastSubm ? "Details" : "Current Version Details"}</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-lg-12 px-0">
          <div className="row mx-0 justify-content-between">
            <div className="status">
              <p className="text_gray_xs_w_700 mb-0">Submitted On</p>
            </div>
            <div className="status_date">
              <p className="text_light_gray_xs  mb-0">{moment(obj?.createdAt).format("DD MMM YYYY")}</p>
            </div>
          </div>
        </div>
        {obj?.contested_status == "1" && props?.viewAttachments?.status != 2 ? (
          <div className="col-lg-12 mb-3 pb-4 mt-3 danger_container">
            <p className="mb-3 font_semi_bold_w_600">
              Rework was requested by {props?.role == "2" ? "Client" : "You"}
              <span>
                <img src={InfoCircle} className="ml-2" />
              </span>
            </p>
            <div
              className="mb-0 text_gray_w_500"
              style={{ maxHeight: "70vh", overflowY: "auto" }}
              dangerouslySetInnerHTML={{
                __html: sanitiseSummernoteText(obj?.contested_note?.replace(/\n/g,'<br/>')),
              }}
            />
          </div>
        ) : null}
      </div>
      {description ? (
        <div className="row mx-0 mb-4">
          <div className="col-lg-12 br_btm px-0 pb-4">
            <div
              className="mb-0 text_gray_w_500"
              style={{ maxHeight: "70vh", overflowY: "auto" }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {props.fixedLastSubm ? (
        ""
      ) : (
        <>
          <div className="row mx-0 mb-3">
            <div className="col-lg-12 px-0">
              <p className="mb-0 text_black_700_w_600">Deliverable Details</p>
            </div>
          </div>
          <div className="row mx-0 mb-4">
            <div className="col-lg-12 px-0 mb-2">
              <div className="row mx-0 justify-content-between">
                <div className="status">
                  <p className="text_gray_xs_w_700 mb-0">Due By</p>
                </div>
                <div className="status_date">
                  <p className="text_light_gray_xs mb-0">{moment(allData?.due_date).format("DD MMM YYYY")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 px-0 mb-2">
              <div className="row mx-0 justify-content-between">
                <div className="status">
                  <p className="text_gray_xs_w_700 mb-0">Total Iterations</p>
                </div>
                <div className="status_date">
                  <p className="text_light_gray_xs mb-0">1/{props?.viewAttachments?.milestonecomplete?.length}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 px-0 mb-2">
              <div className="row mx-0 justify-content-between">
                <div className="status">
                  <p className="text_gray_xs_w_700 mb-0">Status</p>
                </div>
                <div className="status_date">
                  <p className="text_yellow_sm_600 mb-0">{handleStatusDel(obj, true)}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const RightViewUploadDelHistory = (props) => {
  const obj = props.data;
  return (
    <>
      <div className="row mx-0 mb-4 pb-2">
        <div className="col-lg-12 px-0">
          <p className="text_black_700_w_600 mb-2">Version Details</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-lg-12 px-0">
          <div className="row mx-0 justify-content-between">
            <div className="status">
              <p className="text_gray_xs_w_700 mb-0">Submitted On</p>
            </div>
            <div className="status_date">
              <p className="text_gray_xs_w_700 mb-0">{moment(obj?.createdAt).format("DD MMM YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
      {obj?.description ? (
        <div className="row mx-0 mb-4">
          <div className="col-lg-12 br_btm px-0 pb-4">
            <div
              className="mb-0 text_gray_w_500"
              style={{ maxHeight: "70vh", overflowY: "auto" }}
              dangerouslySetInnerHTML={{
                __html: sanitiseSummernoteText(obj?.description?.replace(/\n/g,'<br/>')),
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const RightViewUploadDelRetainer = (props) => {
  const handleStatus = (status) => {
    return status == 0 ? (
      <p className="mb-0 text_yellow_md_500">In Review</p>
    ) : status == 1 ? (
      <p className="mb-0 text_primary_100_w_600">Approved</p>
    ) : (
      <p className="mb-0 text-red-500_md_w_600">Rejected</p>
    );
  };
  const isFixed = props?.proposal_type && props.proposal_type.toLowerCase() == "fixed";
  let obj = props?.viewLatestAttach;

  return (
    <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 p-3 ipad-w-100 px-4 common_overflow_height white_bg">
      <div className="row mx-0 mb-4 pb-4 br_btm">
        <div className="col-lg-12 px-0">
          <p className="text_black_700_w_600 mb-2">Task List</p>
        </div>
        <div className="col-lg-12  ">
          <ul className="font_semi_bold">
            {obj.task_list.map((item, i) => {
              return <li className="li_auto">{item.name}</li>;
            })}
          </ul>
        </div>
      </div>
      <div className="row mx-0 mb-4 pb-4 br_btm">
        <div className="col-lg-12 px-0 mb-3">
          <div className="row justify-content-between align-items-center mx-0">
            <p className="text_black_700_w_600 mb-2">Timelog</p>
            <p className="text_yellow_sm_600">{props?.state?.retainer_fees_type === 'Monthly' ? null : handleStatus(obj?.status)}</p>
          </div>
        </div>
        {obj?.contested_status == "1" && props?.viewAttachments?.latestWorkLog?.status != 1 && obj?.status != 1 ? (
          <div className="col-lg-12 mb-4 pb-4 danger_container">
            <p className="mb-3 font_semi_bold_w_600">
              Hours Contested by {props?.role == "2" ? "client" : "you"}
              <span>
                <img src={InfoCircle} className="ml-2" />
              </span>
            </p>
            <div
              className="mb-0 text_gray_w_500"
              style={{ maxHeight: "70vh", overflowY: "auto" }}
              dangerouslySetInnerHTML={{
                __html: sanitiseSummernoteText(obj?.contested_note?.replace(/\n/g,'<br/>')),
              }}
            />
          </div>
        ) : null}
        <div className="col-lg-8 px-0">
          <p className="font_semi_bold">
            <span>
              <img src={Clock} className="mr-2" />
            </span>
            {obj.number_of_hour_worked_on} Hours<span className="text_gray"> of work logged</span>
          </p>
        </div>
        {obj?.timesheet.length
          ? obj.timesheet.map((item, i) => {
              return (
                <div className="col-lg-12 mb-3 px-0">
                  <div className="d-flex tag_btn_common justify-content-between py-3">
                    <div className="uplod_doc font_semi_bold_w_600">{handleFileName(getRealFileName(item?.name), 40)}</div>
                  </div>
                </div>
              );
            })
          : null}
        {props.isHistoryVersion ? null : props?.viewAttachments?.latestWorkLog?.status == 1 ||
          props?.role == 2 ||
          props.projStatus == 7 ||
          (isFixed &&
            (props?.projectDataStates?.data?.project_data?.terminate_request == 1 ||
              props?.projectDataStates?.data?.project_data?.terminate_request == 2)) ||
              props?.state?.retainer_fees_type === 'Monthly' || props?.viewLatestAttachments?.isExpired ? null : (
          <div className="col-lg-12 pl-0">
            <div className="d-flex justify-content-start align-items-center">
              {obj?.contested_status == "1" || obj?.status == 1 ? null : (
                <button
                  type="button"
                  className="btn btn_outline_sm mr-4"
                  onClick={() => {
                    props.setRetHourlyReqType("contest");
                    props.setRetHourlyReq(true);
                  }}
                >
                  Contest
                </button>
              )}
              {obj?.status == 1 ? null : <button
                type="button"
                className="btn btn_common_sm "
                onClick={() => {
                  props.setRetHourlyReqType("approve");
                  props.setRetHourlyReq(true);
                }}
              >
                Approve Hours
              </button>
              }
            </div>
          </div>
        )}
      </div>
      <div className="row mx-0 mb-3">
        <div className="col-lg-12 px-0">
          <p className="mb-0 text_black_700_w_600">Details</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-lg-12 px-0 mb-2">
          <div className="row mx-0 justify-content-between">
            <div className="status">
              <p className="text_gray_xs_w_700 mb-0">Submitted On</p>
            </div>
            <div className="status_date">
              <p className="text_light_gray_xs mb-0">{moment(obj?.createdAt).format("DD MMM YYYY")}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 px-0 mb-2">
          <div className="row mx-0 justify-content-between">
            <div className="status">
              <p className="text_gray_xs_w_700 mb-0">Duration</p>
            </div>
            <div className="status_date">
              <p className="text_light_gray_xs mb-0">
                {moment(obj.start_duration).format("D MMM")} - {moment(obj.end_duration).format("D MMM")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
