import React, { useEffect, useState } from "react";
import LinkIcon from "../../../../../Assets/upgrade-images/submit_proposal/url_link.svg";
import { ChatImageBaseUrl, CollateralBaseUrl } from "../../../../../utils/BaseUrl";
import Eye from "../../../../../Assets/upgrade-images/version_history/eye.svg";
import moment from "moment";
import Loader from "../../../Common/Common/Loader";
import { checkUrlValidAndUpdate, getRealFileName, handleFileName } from "../CommonFunctionsNew/CommonFunctionsNew";
import { isImage, limitString } from "../../../../../utils/Helperfunctions";
import { renderDifferentFilesThumbnail } from "../../../Common/Common/FileRenderMessage";
import EmptyImg from "../../../../../Assets/upgrade-images/submit_proposal/Empty_img.svg";
import { Autoplay } from "swiper";

const ViewUploadedDocuments = (props) => {
  const [statusType, setStatusType] = useState(3);
  const isFixed = props?.proposal_type && props.proposal_type.toLowerCase() == "fixed";

  useEffect(() => {
    if ((props.isActual && !props?.allData?.length) || !props.actualAppRejState.active) {
      let arr = props?.viewAttachments?.milestonecomplete;
      props.setAllData(arr?.length ? arr : []);
      if (arr?.length) {
        props.handleSetArray(props.viewAttachments.milestonecomplete);
        props.setLoad(false);
      } else {
        props.setLoad(false);
      }
    } else {
      props.setLoad(false);
    }
  }, [props?.viewAttachments?.milestonecomplete]);

  const handleButtons = (obj) => {
    return (
      <>
        <img
          src={Eye}
          className="cursor_pointer d-inline"
          onClick={() => {
            props.setDocumentDetail(obj);
            props.setViewDocument(true);
          }}
        />

        {(isFixed &&
          (props?.projectDataStates?.data?.project_data?.terminate_request == 1 ||
            props?.projectDataStates?.data?.project_data?.terminate_request == 2)) ||
          props?.projectDataStates?.data?.project_data?.status == 7 ? null : props.isActual && props.paymentStatus !== 2 &&
            props.role == 1 &&
            obj.status == 0 ? (
          <>
            <span className="border_left">
              <span
                className="mb-0 cursor_pointer pl-3 fs_14 fw_500"
                onClick={() => {
                  props.setSingleUploadDocId(obj._id);
                  props.setAppRejType("reject");
                  props.setShowApproveRejectDeliverable(true);
                }}
                style={{ color: "#9393AB" }}
              >
                Reject
              </span>
              <span
                className="mb-0 cursor_pointer ml-4 fs_14 fw_500"
                onClick={() => {
                  props.setSingleUploadDocId(obj._id);
                  props.setAppRejType("approve");
                  props.setShowApproveRejectDeliverable(true);
                }}
                style={{ color: "#10C89B" }}
              >
                Approve
              </span>
            </span>
          </>
        ) : null}
      </>
    );
  };

  const handleStatus = (status) => {
    return status == 1 ? (
      <span className="pills_tag_btn_green ml-3">Approved</span>
    ) : status == 2 ? (
      <span className="pills_tag_btn_danger ml-3">Rejected</span>
    ) : (
      <span className="pills_tag_btn_yellow ml-3">In Review</span>
    );
  };

  const funRedirectUrl = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const handleAttList = (obj, index) => {
    return (
      <>
        {(obj.status == statusType || statusType === 3) && obj?.type != "rowDescription" ? (
          obj?.type === "file" ? (
            <>
              <div className="page_card mb-3" key={"i" + index}>
                <div className="p-3">
                  <div className="row align-items-center px-0 mx-0">
                    <div className="col-9 col-sm-10 ipad-w-100 px-0 d-flex align-items-center">
                      <div className="pr-3">
                        <img
                          className="small_image_preview cursor-pointer"
                          src={isImage(obj?.name) ? ChatImageBaseUrl + obj?.name : renderDifferentFilesThumbnail(obj?.name)}
                          // onClick= {() => funRedirectUrl(ChatImageBaseUrl + obj?.name)}
                          onClick={() => {
                            props.setDocumentDetail(obj);
                            props.setViewDocument(true);
                          }}
                        />
                      </div>
                      <p className="mb-0 text_black_xs_w_600 d-flex align-items-center cursor-pointer">
                        <span className="text-blue-800" 
                        // onClick= {() => funRedirectUrl(ChatImageBaseUrl + obj?.name)}
                        onClick={() => {
                          props.setDocumentDetail(obj);
                          props.setViewDocument(true);
                        }}
                        >{handleFileName(getRealFileName(obj?.name), 40)}</span>
                        {props.isActual ? (
                          <>
                            {obj.is_iteration == true ? <span className="pills_tag_btn_gray ml-3">Iteration</span> : <></>}
                            {handleStatus(obj?.status)}
                          </>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-3 col-sm-2 ipad-w-100 d-flex align-items-center justify-content-end">
                      {handleButtons(obj)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="page_card mb-3" key={"l" + index}>
                <div className="p-3">
                  <div className="row align-items-center px-0 mx-0">
                    <div className="col-9 col-sm-8 ipad-w-100 pl-0 d-flex align-items-center">
                      <div className="small_icon_preview mr-3" onClick= {() => funRedirectUrl(checkUrlValidAndUpdate(obj?.name))}>
                        <img className="img-fluid cursor_pointer" src={LinkIcon} />
                      </div>
                      <p className="mb-0 text_black_xs_w_600 font-14 d-flex align-items-center">
                        {/* <span className="text-blue-800" >{limitString(obj?.name, 60)}</span> */}
                        <a href={checkUrlValidAndUpdate(obj?.name)} target="_blank" style={{display:"contents"}}>{limitString(obj?.name, 60)}</a>
                        {props.isActual ? (
                          <>
                            {obj.is_iteration == true ? <span className="pills_tag_btn_gray ml-3">Iteration</span> : <></>}
                            {handleStatus(obj?.status)}{" "}
                          </>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-3 col-sm-4 ipad-w-100 d-flex align-items-center justify-content-end">
                      {/* <span className="msg_tag">
                        <img src={MessageWhiteIcon} className="img-fluid cursor-pointer" /> 3
                      </span> */}
                      <span className="pl-3">{handleButtons(obj)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const handleTabsData = (data) => {
    return (
      <>
        {data.length && data.filter((item) => item.allFiles.length).length ? (
          data.map((obj, index) => {
            return (
              <>
                {obj.allFiles.length ? (
                  <>
                    <div className="end_cycle_upload_inner px-4 pt-4">
                      <div className="text-gray-1300 fs_13 mb-3">Submitted On {moment(obj.createdAt).format("MMM D, YYYY")}</div>
                      {obj?.allFiles?.length ? (
                        <>
                          {obj.allFiles.map((obj, index) => {
                            return handleAttList(obj, index);
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })
        ) : (
          <div className="text-gray-1300 fw_300 text-center py-5">No Data Found ...</div>
        )}
      </>
    );
  };

  return (
    <>
      {props.isActual ? (
        props?.viewAttachments?.milestonecomplete?.length ? (
          <>
            <div className="row mx-0">
              <div className="col-lg-12 px-0">
                <nav className="mb-0 bg-white">
                  <div class="nav nav-tabs cstm_tab pt-0 pb-0 pl-0 px-2" id="nav-tab" role="tablist">
                    <p
                      className={`mb-0 ml-4 pl-0 pr-0 nav-item nav-link cstm_tab_item d-flex align-items-center cursor-pointer ${statusType === 3 ? "active" : ""
                        }`}
                      onClick={() => setStatusType(3)}
                    >
                      <p className="mb-0 fw_400 fs_14">All</p>
                    </p>
                    <p
                      className={`mb-0 ml-3 pl-0 pr-0 nav-item nav-link cstm_tab_item d-flex align-items-center cursor-pointer ${statusType === 0 ? "active" : ""
                        }
                      }`}
                      onClick={() => setStatusType(0)}
                    >
                      <p className="mb-0 fw_400 fs_14">Pending </p>
                    </p>
                    <p
                      className={`mb-0 ml-3 pl-0 pr-0 nav-item nav-link cstm_tab_item d-flex align-items-center cursor-pointer ${statusType === 1 ? "active" : ""
                        }`}
                      onClick={() => setStatusType(1)}
                    >
                      <p className="mb-0 fw_400 fs_14">Approved </p>
                    </p>
                    <p
                      className={`mb-0 ml-4 pl-0 pr-0 nav-item nav-link cstm_tab_item d-flex align-items-center cursor-pointer ${statusType === 2 ? "active" : ""
                        }`}
                      onClick={() => setStatusType(2)}
                    >
                      <p className="mb-0 fw_400 fs_14">Rejected</p>
                    </p>
                  </div>
                </nav>
              </div>
            </div>
            {props.load ? (
              <Loader isCreateModal={true} />
            ) : (
              <>
                <div className="end_cycle_modal_wrapper body_scroll_bar">
                  {statusType == 3 ? handleTabsData(props.arrAll) : ""}
                  {statusType == 0 ? handleTabsData(props.arrPending) : ""}
                  {statusType == 1 ? handleTabsData(props.arrApprove) : ""}
                  {statusType == 2 ? handleTabsData(props.arrReject) : ""}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center align-item-center" style={{ marginTop: "146px" }}>
            <img src={EmptyImg} />
             <span>There is no Image</span>
          </div>
        )
      ) : 
        <>
          {props?.isHistoryVersion ? null : (
            <div className="row">
              <div className="col-lg-12 mb-2">
                <p className="d-flex text_black_700_w_600">
                  Version {props.fixedLastSubm ? "" : isFixed ? props.viewLatestAttachments?.iteration_no + "." + props.viewLatestAttachments?.sub_iteration_no  : props.viewLatestAttachments?.serial_no }
                  {props.fixedLastSubm ? null : (
                    <>
                     {props?.viewAttachments?.milestonecomplete?.length > 1 ? <span className="pills_tag_btn_gray ml-2">Iteration</span> : null}
                      {props.viewLatestAttachments?.contested_status == "1" && props?.viewAttachments?.status != 2 ? (
                        <span className="pills_tag_btn_danger ml-2">Rework</span>
                      ) : null}
                      {/* <span className="pills_tag_btn_green ml-2">Approved</span> */}
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
          {props.attachments?.length ? props.attachments?.map((obj, index) => {
            return handleAttList(obj, index);
          })
          :
          <div className="d-flex justify-content-center align-item-center" style={{ marginTop: "20vh" }}>
            <div className="text-center">
            <img src={EmptyImg} />
            <p className="text_light_gray_md mt-3">There is no Image</p>
          </div>
        </div>
          }
        </>
      }
    </>
  );
};

export default ViewUploadedDocuments;
