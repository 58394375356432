import React, { Component } from 'react';
import { connect } from 'react-redux';
import Milestones from '../../Milestones';
import Proposals from '../../Proposals';

class ChatLeftPanelMobileView extends Component {

    render() {
        const {ProjectStatus, milestones} = this.props
        return (
            <div className="overflow-content mobile-milestone-overflow">
                {(ProjectStatus === 6 || ProjectStatus === 7) &&  milestones && milestones.length > 0  ?
                    <Milestones /> :
                (ProjectStatus === 5 && milestones && milestones.length > 0) ?
                    <Proposals /> : 
                    null}
            </div>
        )
    }
}

const mapState = (state) => {
    const postProject = state.postProject
    const chat = state.chat
    const {Me} = state.globalReducer

    return {
        milestones: postProject.milestones,
        proposal: postProject.proposal,
        proposalStatus: postProject.proposalStatus,
        CurrentRole: Me.CurrentRole,
        ProjectStatus: chat.ProjectStatus,
    }
}

export default connect(mapState)(ChatLeftPanelMobileView);



// const MobileProposal = () => {
//     return(
//         <div className="card-wrapper mb-10">
//                 <div className="card">
//                     <div className="card-body px-2 py-3 ipad-only-pb-0">
//                         <div className="row m-0 align-items-center border-bottom-1 pb-2">
//                             <div className="col-7 col-sm-7 pb-1">
//                                 <h2 className="text-dark-black font-14 font-weight-600 mb-0">Latest Proposal</h2>
//                                 <span className="text-third mobile-ipad-show font-weight-500 font-11">Sent on 23rd Feb 2020</span>
//                             </div>
//                             <div className="col-5 col-sm-5 text-right mobile-ipad-show">
//                                 <a href="#" className="text-primary font-12 small-font-10 font-weight-600 d-block">Make changes <i className="fas fa-arrow-right" /></a>
//                             </div>
//                         </div>
//                         <div className="row m-0">
//                             <div className="col-sm-12">
//                                 <div className="timeline-due-date page">
//                                     <div className="timeline">
//                                         <div className="timeline__group">
//                                             <div className="timeline__box mb-25 mob-pt-20">
//                                                 <div className="timeline__date bg-tranparent">
//                                                     <span><i className="fas fa-info-circle text-sub-8 font-30 pt-15 mob-pt-25" /></span>
//                                                 </div>
//                                                 <div className="timeline__post">
//                                                     <div className="timeline__content active">
//                                                         <p className="mb-0 font-12 font-weight-600">Advance one-time</p>
//                                                         <div className="dflex">
//                                                             <h2 className="milestone-headline mb-1 font-weight-600 font-12">non-refundable fee</h2>
//                                                             <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12" />2,000</span>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <span className="vertical-line advance-payment" />
//                                             </div>
//                                             <div className="timeline__box mb-30 mob-mb-35">
//                                                 <div className="timeline__date active">
//                                                     <span className="due-date active">DUE DATE</span>
//                                                     <span className="timeline__day">23</span>
//                                                     <span className="timeline__month">Mar</span>
//                                                 </div>
//                                                 <div className="timeline__post">
//                                                     <div className="timeline__content active">
//                                                         <div className="dflex">
//                                                             <h2 className="milestone-headline mb-1 font-weight-600 font-13">Milestone 1</h2>
//                                                             <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12" />10,000</span>
//                                                         </div>
//                                                         <p className="font-11 font-weight-600">First set of concepts delivery along with initial sketches</p>
//                                                     </div>
//                                                 </div>
//                                                 <span className="vertical-line" />
//                                             </div>
//                                             <div className="timeline__box">
//                                                 <div className="timeline__date">
//                                                     <span className="due-date">DUE DATE</span>
//                                                     <span className="timeline__day">23</span>
//                                                     <span className="timeline__month">Mar</span>
//                                                 </div>
//                                                 <div className="timeline__post">
//                                                     <div className="timeline__content">
//                                                         <div className="dflex">
//                                                             <h2 className="mb-1 font-weight-600 font-13">Milestone 1</h2>
//                                                             <span className="font-weight-600 font-12"><i className="fas fa-rupee-sign font-12" />10,000</span>
//                                                         </div>
//                                                         <p className="font-11 font-weight-600">First set of concepts delivery along with initial sketches</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
                                    
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row m-0 bor-bottom bor-top py-3 mt-3 ipad-border-bottom-none">
//                             <div className="col-sm-12 dflex creator-cost">
//                                 <div className="w-50">
//                                     <span className="d-block font-12 text-sub font-weight-500">Client's cost</span>
//                                     <span className="font-16 text-dark-black font-weight-600"><i className="fas fa-rupee-sign font-14" />13,725 <span><i className="fas fa-info-circle text-secondary font-12" /></span></span>
//                                 </div>
//                                 <div className="w-50 project-delivery-date position-relative pl-3">
//                                     <span className="d-block font-12 text-sub font-weight-500">Your earnings</span>
//                                     <span className="font-16 text-dark-black font-weight-600"><i className="fas fa-rupee-sign font-14" />10,725 </span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row m-0 mobile-ipad-none">
//                             <div className="col-sm-12 text-right">
//                                 <a href="#" className="text-primary font-12 font-weight-600 d-block pt-3">Make changes <i className="fas fa-arrow-right" /></a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//     )
// }