import React,{useState} from "react"
import ReleventProjects from "./ReleventProjects";
import Attachments from "./Attachments";
import Urls from "./Urls";
import { useDispatch, useSelector } from "react-redux";
import FeaturedToggleImage from "../../../../../../Assets/upgrade-images/submit_proposal/toggle_btn.svg";

const Reference = (props) => {
    const _relevent_projects = useSelector((state) => state.releventProjects)
    const creatorFavProIs = _relevent_projects?.releventProjectIs;
    const attachments = _relevent_projects?.link_image
    const urlsIs = _relevent_projects?.urls;
    const [toggle,setToggle]=useState(1)   
    return(        
        <div className='row px-4 pb-4 add_reference mx-0'>
            <div className='col-lg-12 title_commn d-flex justify-content-between align-items-center'>
                <p className='mb-0 text_black_md'>Add References</p>
                {/* <p className=' mb-0 sub_title_commn'>Optional</p> */}
                <div className="d-block d-lg-none">
                    <img
                    src={FeaturedToggleImage}
                    className="icon_img mr-1 cursor-pointer"
                    data-toggle="collapse"
                    href={`#collapseReferences`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={`collapseReferences`}
                    />
                </div>
            </div>
            <div className="col-lg-12 px-0">
            <div className="row mx-0 collapse show" id={`collapseReferences`}>
            <div className='col-lg-12 px-0 pt-2'>
                <nav className='mb-3'>
                    <div class="nav nav-tabs cstm_tab" id="nav-tab" role="tablist">
                    <a onClick={() => setToggle(1)} class="nav-item nav-link cstm_tab_item refrence_tab_active  active  d-flex align-items-center" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                        {/* <p className='mb-0' >Relevant Projects </p> */}
                        <p className='mb-0' >{creatorFavProIs?.length > 1 ? "Relevant Projects" : "Relevant Project"} </p>
                        <div className={`ml-3 mob-ml-3 ${toggle!== 2 && toggle!== 3 ? 'tag_btn_tab_active':"tag_btn_tab"}`}>
                        <p className={`mb-0`}>{creatorFavProIs?.length}</p>
                        </div>
                    </a>
                    <a onClick={() => setToggle(2)} class="nav-item nav-link cstm_tab_item  d-flex align-items-center" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                    <p className='mb-0' >{attachments?.length > 1 ? "Attachments" : "Attachment"}</p>
                    <div className={`ml-3 mob-ml-3 ${toggle!== 1 && toggle!== 3 ? 'tag_btn_tab_active':"tag_btn_tab"}`}>
                        <p className='mb-0 '>{attachments?.length}</p>
                    </div>
                    </a>
                    <a onClick={() => setToggle(3)} class="nav-item nav-link cstm_tab_item  d-flex align-items-center" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                    {/* <p className='mb-0'> URLs</p> */}
                    <p className='mb-0'> {urlsIs?.length > 1 ? "URLs" : "URL"}</p>
                    <div className={`ml-3 mob-ml-3 ${toggle!== 2 && toggle!== 1 ? 'tag_btn_tab_active':"tag_btn_tab"}`}>
                        <p className='mb-0 '>{urlsIs?.length}</p>
                    </div>
                    </a>
                    </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">
                    {/* tab1 */}
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                       <ReleventProjects {...props}/>
                    </div>
                    {/* tab2 */}
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                       <Attachments {...props}/>
                    </div>
                    {/* tab3 */}
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                       <Urls {...props}/>
                    </div>
                </div>
            </div>
           </div>
           </div>
        </div>
        
    )
}

export default Reference