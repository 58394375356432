import React, { useEffect, useState } from "react";
import HeaderIcon from "../../../../../Assets/upgrade-images/submit_proposal/header_icon.svg";
import PreIcon from "../../../../../Assets/upgrade-images/version_history/perview_arrow.svg";
import EyeIcon from "../../../../../Assets/upgrade-images/version_history/eye.svg";
import ClockIcon from "../../../../../Assets/upgrade-images/version_history/clock.svg";
import moment from "moment";
import ViewProposalModal from "./ViewProposalModal";
import ClientLeftComp from "../../DashboardNew/ClientLeftComp";
import Loader from "../../../../authorized/Common/Common/Loader";

export default function VersionHistoryData(props) {
  const { AllProposal, creatorData } = props?.state;
  const [data, setData] = useState({});
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [isLoadingVHistory,setIsLoading] = useState(true)

  useEffect(()=>{
    if(props?.isClient) setIsLoading(props?.isLoadingClientVersionHistory)
    else if (!props?.isClient) setIsLoading(props?.isLoading)
  },[props])

  return (
    <>
      {props?.historyTab ? (
        <VersionHistoryDiv
          AllProposal={AllProposal}
          showVersionHistory={showVersionHistory}
          setShowVersionHistory={setShowVersionHistory}
          data={data}
          setData={setData}
          creatorData={creatorData}
          isLoadingVHistory={isLoadingVHistory}
          {...props}
        />
      ) : (
        <section className="version_history main_page_container">
          <div className="row version_history_container mx-0">
            {/* top_heading */}
            <div className="col-lg-12 py-4">
              <div className="top_header d-flex align-items-center">
                <div className="heading_icon pr-4">
                  <img
                    src={HeaderIcon}
                    className="img-fluid cursor-pointer"
                    onClick={() => (props?.history?.goBack() ? props.history.goBack() : "")}
                  />
                </div>
                <p className="proposal_heading mb-0">Version History</p>
              </div>
            </div>
            {/* left_side */}
            <ClientLeftComp id={props?.id} {...props} />
            {/* right_side */}
            <VersionHistoryDiv
              AllProposal={AllProposal}
              showVersionHistory={showVersionHistory}
              setShowVersionHistory={setShowVersionHistory}
              data={data}
              setData={setData}
              creatorData={creatorData}
              isLoadingVHistory={isLoadingVHistory}
              {...props}
            />
          </div>
        </section>
      )}
      {showVersionHistory && (
        <ViewProposalModal
          setShowVersionHistory={setShowVersionHistory}
          showVersionHistory={showVersionHistory}
          data={data}
          creatorData={creatorData}
          isVersion={true}
          {...props}
        />
      )}
    </>
  );
}

export const VersionHistoryDiv = ({
  AllProposal,
  data,
  creatorData,
  setData,
  showVersionHistory,
  setShowVersionHistory,
  ...props
}) => {
  return (
    <div className="col-lg-9">
      <div className="page_card">
        <div className="proposal_header br_btm d-flex justify-content-between px-4">
          <div className="prposal_heading d-flex align-items-center">
            <p
              className="mb-0 mr-1 cursor-pointer"
              onClick={() =>
                props?.historyTab ? props.handleSetShowHistoryTab(false) : props?.history?.goBack() ? props.history.goBack() : ""
              }
            >
              <img src={PreIcon} className="img-fluid mr-2" /> Version History
            </p>
          </div>
        </div>
        <div className="history_list mx-4">

          {props?.isLoadingVHistory ? (
            <Loader isCreateModal={true} style={{ padding: "10rem" }} />
          ) : AllProposal?.length > 0 ? (
            AllProposal.map((proposal,index) => (
              <div className="row mx-0 p-4 list_item br_tp align-items-center" key={index}>
                <div className="col-auto px-0">
                  <img src={ClockIcon} className="img-fluid" />
                </div>
                {/* <div className="col">
                  <div
                    className="d-flex justify-content-between align-items-center text_gray cursor-pointer"
                    onClick={() => {
                      setData(proposal);
                      setShowVersionHistory(true);
                    }}
                  >
                    Edited on {moment(proposal.updateAt).format("MMMM DD YYYY, h:mm a")}{" "}
                    <img src={EyeIcon} className="img-fluid" />
                  </div>
                </div> */}
                <div className="col">
                  <div
                    className="d-flex justify-content-between align-items-center text_gray_w_600 cursor-pointer"
                    onClick={() => {
                      setData(proposal);
                      setShowVersionHistory(true);
                    }}
                  >
                    <p className="mb-0">
                      <sapn className="text_light_gray_md">Updated by </sapn>
                      {props?.isClient ? (
                        <>
                          {/* <img src={GrayUserIcon} className="mx-2" /> */}
                          {creatorData?.first_name + " " + creatorData?.last_name} on{" "}
                        </>
                      ) : (
                        "you on "
                      )}
                      {moment(proposal.createdAt).format("MMMM DD YYYY, h:mm a")}{" "}
                    </p>
                    <img src={EyeIcon} className="img-fluid" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center" style={{ padding: "10rem" }}>
              <h2>No Data Found ...</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
