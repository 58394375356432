import React, { useEffect, useState } from "react";
import HeaderIcon from "../../../../../Assets/upgrade-images/submit_proposal/header_icon.svg";
import HeaderTabs from "./HeaderTabs/HeaderTabs";
import ProjectRoom from "./ProjectRoom/ProjectRoom";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import RedirectOverview from "./Overview/RedirectOverview";
import { useSelector } from "react-redux";
import { getAPI } from "../../../../../utils/API";
import DotImage from "../../../../../Assets/images/vdot.svg";
import MobileClientDetailsPopup from "../PopupAll/MobileClientDetailPopup";
import MobileNotificationCenterPopup from "../PopupAll/MobileNotificationCenterPopup";
import NotFound from "../../../../../Components/authorized/Not-Found/NotFound";
import { NotResponsivePopUp } from "../PopupAll/NotResponsivePopUp";
import MobileAMpopup from "../PopupAll/MobileAMpopup";

export default function ProjectsView(props) {
  const [projectDataStates, setProjectDataStates] = useState({
    id: "",
    data: "",
    projectDataloader: true,
  });

  const [chatUnreadMes, setChatUnreadMes] = useState(0);
  const [userError, setUserError] = useState(false);

  const CurrentRole = useSelector((state) => state?.globalReducer?.Me?.CurrentRole);

  const path = props?.history?.location?.pathname?.split("/");
  const path1 = path?.[1];
  const { projectId, subroute } = props?.match?.params;

  useEffect(() => {
    if (projectId) handleGetUserApi(projectId);
  }, [props?.history?.location?.pathname]);

  const handleGetUserApi = async (id) => {
    setProjectDataStates({ ...projectDataStates, projectDataloader: true });
    try {
      const res = await getAPI(`/project/get-post-project-details-brief?post_project_id=${id}`);
      if (res) {
        let data = res?.data;
        setProjectDataStates({ projectDataloader: false, data, id });
        setChatUnreadMes(data?.unread_msg ? data.unread_msg : 0);
      }
    } catch (err) {
      console.log("error");
      setProjectDataStates({ ...projectDataStates, data: "", id });
    }
  };

  const callApiAgain = () => {
    handleGetUserApi(projectId);
  };

  return (
    <>
      {projectDataStates.data?.project_data?.status == 8 ||
      (projectDataStates.data?.creator_status_brief == 2 && CurrentRole == 2) ||
      (projectDataStates.data?.project_data?.status > 5 && !props.authIs) ||
      userError ? (
        <NotFound />
      ) : (
        <section className="creator_active_brief_chat_project_room  main_page_container">
          <div className="creator_active_brief_chat_project_room_container mx-0">
            <div className="row">
              <div className="col-lg-12 pb-4">
                <div className="top_header d-flex align-items-center mob-justify-content-between">
                  <div className="heading_icon d-flex align-items-center">
                    <img
                      src={HeaderIcon}
                      className="img-fluid cursor-pointer pr-4"
                      onClick={() => {
                        // (props?.history?.goBack() ? props.history.goBack() : "")
                        props.authIs ? props.history.push("/dashboard") : props.history.push("/");
                      }}
                    />
                    <span className="proposal_heading text-capitalize mb-0">
                      {projectDataStates.data ? projectDataStates.data?.project_data?.project_name : ""}
                    </span>
                  </div>
                  <div className="dropdown dropleft d-block d-lg-none">
                    <img src={DotImage} className="dropdown-toggle" data-toggle="dropdown" />
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item font-12 font-weight-600"
                        data-toggle="modal"
                        data-target="#MobileClientDetailsPopup"
                      >
                        Client Details
                      </span>
                      <span
                        className="dropdown-item font-12 font-weight-600"
                        data-toggle="modal"
                        data-target="#MobileNotificationCenterPopup"
                      >
                        Notifications
                      </span>
                      <span className="dropdown-item font-12 font-weight-600"
                      data-toggle="modal"
                      data-target="#MobileAMpopup">
                        Account Manager
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <HeaderTabs
                  projectDataStates={projectDataStates}
                  CurrentRole={CurrentRole}
                  path1={path1}
                  subroute={subroute}
                  id={projectId}
                  authIs={props.authIs}
                  chatUnreadMes={chatUnreadMes}
                  setChatUnreadMes={setChatUnreadMes}
                />
              </div>
            </div>

            <div className="tab-content">
              {path1 == "projects" ? (
                props.authIs ? (
                  <>
                    {subroute == "overview" && (
                      <RedirectOverview
                        callApiAgain={callApiAgain}
                        projectDataStates={projectDataStates}
                        {...props}
                        CurrentRole={CurrentRole}
                        id={projectId}
                        setUserError={setUserError}
                      />
                    )}
                    <React.Fragment className="d-none d-lg-block">
                      {subroute == "project_room" && (
                        <ProjectRoom
                          callApiAgain={callApiAgain}
                          projectDataStates={projectDataStates}
                          {...props}
                          CurrentRole={CurrentRole}
                          id={projectId}
                          setChatUnreadMes={setChatUnreadMes}
                          setUserError={setUserError}
                        />
                      )}
                      {subroute == "proposal_details" &&
                        (projectDataStates?.data?.creator_status_brief == 1 || CurrentRole == 1 || CurrentRole == 3) && (
                          <ProjectDetails
                            callApiAgain={callApiAgain}
                            projectDataStates={projectDataStates}
                            {...props}
                            CurrentRole={CurrentRole}
                            id={projectId}
                            setUserError={setUserError}
                          />
                        )}
                      {/* {subroute == "compliance" && (
                  <Compliance
                    callApiAgain={callApiAgain}
                    projectDataStates={projectDataStates}
                    {...props}
                    CurrentRole={CurrentRole}
                    id={projectId}
          
                        setUserError={setUserError}
                  />
                )} */}
                      {/* {subroute == "files" && (
                  <Files
                    callApiAgain={callApiAgain}
                    projectDataStates={projectDataStates}
                    {...props}
                    CurrentRole={CurrentRole}
                    id={projectId}
          
                        setUserError={setUserError}
                  />
                )} */}
                    </React.Fragment>
                    {subroute != "overview" ? (
                      <React.Fragment className=" d-block d-md-none">
                        <NotResponsivePopUp {...props} />
                      </React.Fragment>
                    ) : null}
                  </>
                ) : subroute == "overview" ? (
                  <RedirectOverview
                    callApiAgain={callApiAgain}
                    projectDataStates={projectDataStates}
                    {...props}
                    setUserError={setUserError}
                    id={projectId}
                  />
                ) : null
              ) : null}
              <MobileClientDetailsPopup />
              {/* <MobileNotificationCenterPopup/> */}
              <MobileAMpopup/>
            </div>
          </div>
          {CurrentRole != 2 ? (
            <React.Fragment className=" d-block d-md-none">
              <NotResponsivePopUp {...props} />
            </React.Fragment>
          ) : null}
        </section>
      )}
    </>
  );
}
