import React, { Component } from 'react';
import { connect } from 'react-redux'
import client from './../../../../Assets/images/account-manager.png';
import cash from './../../../../Assets/images/cash.png';
class KickOffProject extends Component {
    handleClose = () => {
        this.props.closePopup()
     }
     render() { 
        return (
             <React.Fragment>
                  <div className="modal custom_form kick_off_project" id="VerifyModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ "display": "block" }}>
                    <span className="close_icon" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><img src={require("./../../../../Assets/images/close.png")} className="img-fliud" width="35" alt="" /></span>  
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content"> 
                        <div className="modal-body kick_off_star">
                            <div className="row m-0">
                                <div className="col-sm-12 p-4"> 
                                    <div className="calling_wrapper text-center"> 
                                        <div className="calling-image kick_off_rotate">
                                            <div className="profile-user-image">
                                                <img src={client} alt="kickoffproject"></img> 
                                            </div>
                                            <div className="profile-user-image">
                                                <img src={client} alt="kickoffproject"></img> 
                                            </div>
                                        </div> 
                                        <div className="pb-3">
                                            <h2 className="font-20 font-weight-700 line-height-normal">Well done!</h2> 
                                            <h4 className="font-15 font-weight-600 line-height-normal">Anuj chose you for his project, Guappus branding</h4> 
                                            <span className="text-sub font-13 font-weight-500 line-height-normal">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'.</span> 
                                        </div> 
                                    </div>  
                                    <div className="wrapper-form p-3 mt-3 mb-1">
                                        <div className="dflex">
                                            <div class="w-75 mob-w-65">
                                                <span className="text-sub font-14 font-weight-600 line-height-normal">Paid Advance fee</span>
                                                <div className="font-22 font-weight-600 line-height-normal">
                                                    <span className="cash_amount py-2 d-line"><i class="fas fa-rupee-sign font-20 mob-font-16 mr-1"></i>8,000</span>
                                                    <h3 class="text-sub mt-1 mb-0 font-12 font-weight-600"><span class="text-sub"><i class="fas fa-info-circle font-12"></i></span> Advance one-time non-refundable fee</h3>
                                                </div>
                                            </div>
                                            <div className="w-25 mob-w-35">
                                                <div className="cash_icon">
                                                    <div className="profile-user-image">
                                                        <img src={cash} alt="cash"></img> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                  
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-backdrop show" style={{display: 'block'}}></div>
             </React.Fragment>
        )
    }
}

const mapDispatch =  dispatch => ({
        closePopup: () => dispatch({ type: 'CLOSE_POPUP_SAGA', payload: {} }),
    }) 

export default connect(null, mapDispatch)(KickOffProject)