import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class NotFound extends Component{

    render(){
        return(
            <React.Fragment>
                <section className="page_not_found">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                                <div className="page_inner">
                                    <h3>Page Not <span className="d-block">Found</span></h3>
                                    <p className="sub_text">The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
                                    <Link to="/" className="btn main-btn text-white">Go To Dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}