import React, { useEffect, useState } from "react";
import WalletIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/wallet.svg";
import MenuBoardIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/menu-board.svg";
import MoneyIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/money.svg";
import InfoIcon from "../../../../../Assets/upgrade-images/view_proposal_fc/info-circle.svg";
import Loader from "../../../../authorized/Common/Common/Loader";
import { separateNumber } from "../../../ProfileCreator/TimeRetainer";
import {
  GetpaymenTermsData,
  handleDelivaryDate,
  handleSelectedNames,
  onkeyCode,
  retainerTableValues,
  ViewDeliverableData,
  workDays,
} from "../../DashboardNew/CommonFunctionsNew/CommonFunctionsNew";
import {
  releventProjects,
  relevent_projects,
  projectAttachments,
  projectUrls,
} from "../../../../../store/action/proposal.action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReferenceForClient from "../../DashboardNew/RefrenceForClient/RefrenceForClient";
import { putAPI } from "../../../../../utils/API";
// import { data } from "jquery";
import ProjectsFooter from "../../DashboardNew/CommonComp/ProjectsFooter";
import { limitString, sanitiseSummernoteText } from "../../../../../utils/Helperfunctions";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import NotFoundDynamic from "../../DashboardNew/CommonComp/NotFoundDynamic";
import Popup from "../../../Chat/Popup";
import ViewModalPopup from "../../../ProfileCreator/Popup.js"

export default function ViewProposalModal({ setShowVersionHistory, showVersionHistory, data, ...props }) {
  const [states, setStates] = useState({
    deliverable: [],
    payments: [],
    advanceFees: {},
    note: "",
    proposed_start_date: "",
    type: "",
    propDuration: {
      prop_duration: "",
      prop_dur_num: 1,
    },
    numOfHours: {
      day_week: "",
      num_hour: 1,
    },
    fees: {
      retainerFees: "",
      advanceFees: "",
    },
    workingDaysInWeek: workDays,
    propDuration: {
      prop_duration: { label: "Week", value: "Week" },
      prop_dur_num: 1,
    },
    prefBillingCycle: { label: "Monthly", value: "Monthly" },
    total_earning: 0,
    sub_total_cost: 0,
    retainer_fees_type: "",
  });
  const [loader, setLoader] = useState("");
  const [load, setLoad] = useState(false);
  const [loadType, setLoadType] = useState("");
  const [selectedCreator,setSelectedCreator] = useState('')
  const [selectedCreatorType,setSelectedCreatorType] = useState('')
  const [selectedCreatorData,setSelectedCreatorData] = useState({})
  const [openedFrom,setOpenedFrom] = useState('')

  const dispatch = useDispatch();

  // for redux state blank project reference ***********************

  const getReferenceData = (data) => {
    setAllUrls(data);
    setProjectAttachments(data);
    setProjectIds(data);
  };

  const setProjectIds = (data) => {
    const current_ids = [];
    const ids = data?.allProjectIds?.forEach((obj) => {
      return current_ids.push({ _id: obj });
    });

    const dataIs = {
      project_ids: current_ids ? current_ids : [],
      releventProject: data?.allProjectData ? data.allProjectData : [],
    };
    dispatch(relevent_projects(dataIs));
  };

  const setProjectAttachments = (data) => {
    dispatch(projectAttachments(data?.link_image ? data.link_image : []));
  };

  const setAllUrls = (data) => {
    dispatch(projectUrls(data?.urls ? data.urls : []));
  };

  // useEffect(() => {
  //   setProjectIds()
  //   setProjectAttachments()
  //   setAllUrls()
  // },[])

  // for redux state blank project reference ***********************

  useEffect(() => {
    setLoader(true);
    if (data) {
      // fixed values
      let arrDel = ViewDeliverableData(data);
      let obj = GetpaymenTermsData(data?.payment_terms, arrDel);
      getReferenceData(data);
      let arrPay = obj?.arrPay;
      let advanceFees = obj?.advanceFees;
      // retainer values
      states.propDuration.prop_duration =
        data?.proposed_duration_type?.length > 0
          ? {
              label: data.proposed_duration_type,
              value: data.proposed_duration_type,
            }
          : { label: "Week", value: "Week" };
      states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
      states.numOfHours.day_week =
        data?.number_of_hours_type?.length > 0
          ? {
              label: data.number_of_hours_type,
              value: data.number_of_hours_type,
            }
          : { label: "Day", value: "Day" };
      states.numOfHours.num_hour = data?.number_of_hours ? data.number_of_hours : 1;
      states.workingDaysInWeek = data?.working_days_in_week?.length === 7 ? data.working_days_in_week : workDays;
      states.fees.retainerFees = data?.retainer_fees ? data.retainer_fees : "";
      states.retainer_fees_type = data?.retainer_fees_type;
      states.fees.advanceFees = data?.advance_amount ? data.advance_amount : "";
      // actual values
      states.deliverable = arrDel.length > 0 ? arrDel : [];
      states.propDuration.prop_duration =
        data?.proposed_duration_type?.length > 0
          ? {
              label: data.proposed_duration_type,
              value: data.proposed_duration_type,
            }
          : { label: "Week", value: "Week" };
      states.propDuration.prop_dur_num = data?.proposed_duration ? data.proposed_duration : 1;
      states.prefBillingCycle =
        data?.preferred_billing_cycle?.length > 0
          ? {
              label: data.preferred_billing_cycle,
              value: data.preferred_billing_cycle,
            }
          : { label: "Monthly", value: "Monthly" };
      //
      setStates({
        ...states,
        deliverable: arrDel?.length > 0 ? arrDel : [],
        payments: arrPay?.length > 0 ? arrPay : [],
        proposed_start_date: data?.proposed_start_date ? new Date(data?.proposed_start_date) : new Date(),
        note: data?.note ? data.note : "",
        advanceFees,
        type: data?.proposal_type,
        total_earning: data?.total_earning,
        sub_total_cost: data?.sub_total_cost,
        proposalData: data,
      });
      setLoader(false);
    }
  }, [data]);

  const handleChooseDays = (name) => {
    let arr = [];
    states.workingDaysInWeek.forEach((item) => {
      if (item.name === name) arr.push({ ...item, isActive: !item.isActive });
      else arr.push({ ...item });
    });
    let filter = arr?.filter((obj) => obj?.isActive);
    states.validation.workingDaysInWeek = filter.length > 0 ? false : true;
    setStates({
      ...states,
      workingDaysInWeek: [...arr],
    });
  };

  const handleApproveApi = async (type) => {
    setLoad(true);
    setLoadType(type);
    let obj = {
      _id: data._id,
      post_project_id: props.id,
    };
    try {
      let res = "";
      if (type === "approve") res = await putAPI("/proposal/approve-fixed-make-change", obj);
      else res = await putAPI("/proposal/reject-proposal", obj);
      if (res) {
        setLoad(false);
        setLoadType("");
        setShowVersionHistory(false);
        props?.isMakeChanges && props.handleCallApiAgain();
        setTimeout(() => {
          alert(`Proposal ${type} successfully`);
        }, 500);
      }
    } catch (err) {
      alert("Something went wrong please try again later");
      setLoad(false);
      setLoadType(type);
    }
  };

  const handleViewProfile = (data) => {
    // console.log(index, data);
    const proposalid = data.creator_id._id;
    const username = data.creator_id.username;
    const userType = data.user_type;
    // const {authIs} = this.props;
    setSelectedCreatorType(userType);
    setSelectedCreator(proposalid);
    setSelectedCreatorData(data);
    // props.OpenPopup('SliderModalBrief')
    // props.loadPublicCreatorData(username)
    dispatch({
      type: "OPEN_POPUP_SAGA",
      payload: { Component: "SliderModalBrief" },
    });
    dispatch({ type: "LOAD_CREATOR_PUBLIC", payload: { user_name: username } });
  };

  const onClickViewProjects = (ele) => {
    if (!ele.project_data) {
      alert("No Projects for the Selected Collateral");
      return;
    }
    if (ele?.review_status) {
      setOpenedFrom("slidermodalbrief");
    } else {
      setOpenedFrom("overview");
    }
    // this.props.LoadProjectData(ele.project_data._id);
    // this.props.closePopup();
    //   this.props.OpenPopupPoject('ViewModal');
    dispatch({ type: "UPDATE_CREATOR_PUBLIC", values: true });
    // dispatch({ type: "LOAD_ALL_PROJECTS_SAGA", values: ele.username })
    dispatch({
      type: "LOAD_PROJECT_DATA",
      payload: { id: ele.project_data._id },
    });
    dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} });
    dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: "ViewModal" } });
  };

  const handleActualQuote = () => {
    let data = states.deliverable.map((item) => {
      return { amount: item?.breakup, name: item?.name };
    });
    return data;
  };

  const handleTimeCommitment = (action) => {
    let name = "";
    let result = "";
    let filter = [];
    if (action == 3) {
      name = "Working days in a week";
      filter = states.workingDaysInWeek?.length > 0 ? states.workingDaysInWeek.filter((item) => item.isActive) : [];
    } else {
      let obj = retainerTableValues(action, states);
      name = obj?.name;
      result = obj?.result;
    }

    return (
      <>
        <div className="duration_title col-xl-12 ">
          <div className="d-flex justify-content-between">
            <h4 className="font_semi_bold_w_600 p-3">{name}</h4>
            {action == 3 ? (
              <div className="duration_fields d-flex col-lg-4 justify-content-end">
                {filter?.length > 0
                  ? filter.map((item, i) => {
                      return (
                        <div className="circle_btn_field d-flex mr-2 cursor-pointer" onClick={() => handleChooseDays(item.name)}>
                          <div className={`round_btn_blue_common ${item.isActive ? "active" : ""}`}>
                            <p className="mb-0">{item.label}</p>
                          </div>
                        </div>
                      );
                    })
                  : "N/A"}
              </div>
            ) : (
              <h4 className="font_semi_bold_w_600 p-3">{result}</h4>
            )}
          </div>
        </div>
      </>
    );
  };

  document.onkeyup = (e) => {
    if (e.key === "Escape") {
      setShowVersionHistory(false);
    }
  };

  const projectData = props?.projectDataStates?.data?.project_data;
  const noteText = data?.note ? sanitiseSummernoteText(data?.note.replace(/\n/g, "<br/>")) : "";

  // console.log("states === ", states);

  return (
    <section className="viewing_proposal_fc">
      <div
        className={`modal fade modal_fade_bg show`}
        id="ViewingProposalFC"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered  viewing_proposal_fc_modal_dialog ">
          <div className="modal-content modal_scroll  popup_modal_dialog_lg br_6 ">
            <div className="br_btm modal_top_header d-flex align-items-center  justify-content-between px-4">
              {props?.isLoading || props?.propError ? null : (
                <h5 className="line_height_20 fw_700 text-black-700" id="exampleModalLabel">
                  Updated by{" "}
                  {/* <span>
                  <img src={states?.proposalData?.userData?.profile_image?BaseImageUrl+states?.proposalData?.userData?.profile_image:GrayUserIcon} className="mx-2 rounded-circle" width={24}/>
                </span> */}
                  {props?.isLoading
                    ? " "
                    : props?.isVersion
                    ? props?.creatorData?.first_name + " " + props?.creatorData?.last_name
                    : states?.proposalData?.userData?.first_name + " " + states?.proposalData?.userData?.last_name}{" "}
                  on {moment(data?.createdAt).format("MMMM DD YYYY, h:mm a")}
                </h5>
              )}
              <button
                type="button"
                className="close close_btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowVersionHistory(false)}
              >
                <span aria-hidden="true" className="modalclose-btnicon">
                  ×
                </span>
              </button>
            </div>

            {props?.isLoading ? (
              <Loader isCreateModal={true} style={{ marginTop: "10rem", marginBottom: "10rem" }} />
            ) : props?.propError ? (
              <div className="modal-body px-2 ">
                  <NotFoundDynamic text={"The proposal you are looking for is not affiliated to your project."} />
              </div>
            ) : (
              <>
                <div className="modal-body px-2  common_overflow_height">
                  {/* cards */}
                  <div className="row my-3 mx-0 ">
                    <div className="col-lg-4">
                      <div class="card view_proposal_card h-100">
                        <div class="card-body px-4 py-4">
                          <div className="card_body_inner d-flex">
                            <div className="inner_item mr-4">
                              <div className="card_icon d-flex">
                                <img src={MoneyIcon} className="icon_img" />
                              </div>
                            </div>
                            <div className="inner_item">
                              <p className="mb-0 text_gray">Project Quote</p>
                              <p className="mb-2 text_black_md">
                                ₹{" "}
                                {/* {states?.type == "actual"
                                  ? separateNumber(states?.propDuration?.prop_dur_num * states?.sub_total_cost)
                                  : states?.type == "fixed"
                                  ? separateNumber(states?.sub_total_cost)
                                  : `${separateNumber(states?.fees?.retainerFees)}/ ${
                                      states?.retainer_fees_type == "Hourly" ? "Hour" : "Month"
                                    }`} */}
                                {states?.type == "actual"
                                  ? handleActualQuote().length > 1
                                    ? `${separateNumber(handleActualQuote()[0]?.amount)}/${limitString(
                                        handleActualQuote()[0]?.name,
                                        15
                                      )} +${handleActualQuote().length - 1}`
                                    : `${separateNumber(handleActualQuote()[0]?.amount)}/${limitString(
                                        handleActualQuote()[0]?.name,
                                        15
                                      )}`
                                  : states?.type == "fixed"
                                  ? separateNumber(states?.sub_total_cost)
                                  : `${separateNumber(states?.fees?.retainerFees)}/${
                                      states?.retainer_fees_type == "Hourly" ? "Hour" : "Month"
                                    }`}
                              </p>
                              <p className="text_light_gray_xs">+ Applicable Taxes</p>
                            </div>
                            <div className="tag_btn_gray card_tag text_gray_xxs_w_600">
                              Learn More
                              <div
                                className="position-relative tool"
                                data-tip="This is the total project cost being sent to the client. Taxes will be borne basis legal entity of the client and will not affect creator earnings."
                              >
                                <img src={InfoIcon} className="icon_img ml-2" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {props?.CurrentRole == 1 || props?.CurrentRole == 3 || states?.type == "actual" || !props.authIs ? null : (
                      <div className="col-lg-4">
                        <div class="card view_proposal_card h-100">
                          <div class="card-body px-4 py-4">
                            <div className="card_body_inner d-flex">
                              <div className="inner_item mr-4">
                                <div className="card_icon d-flex">
                                  <img src={WalletIcon} className="icon_img" />
                                </div>
                              </div>
                              <div className="inner_item">
                                <p className="mb-0 text_gray">
                                  {states?.type == "retainer" ? "Your Earnings" : "You'll Receive"}
                                </p>
                                <p className="mb-2 text_black_md">
                                  ₹{" "}
                                  {states?.type == "fixed"
                                    ? separateNumber(states?.total_earning)
                                    : `${separateNumber(states?.fees?.retainerFees * 0.85)}/${
                                        states?.retainer_fees_type == "Monthly" ? "Month" : "Hour"
                                      }`}
                                </p>
                              </div>
                              <div className="tag_btn_gray card_tag text_gray_xxs_w_600">
                                Learn More
                                <div
                                  className="position-relative tool"
                                  data-tip="These are your total earnings for the project after deducting platform & connection charges. Do note that any applicable taxes, including TDS, might be further deducted from this amount and necessary calculation & paperwork will be provided for the same."
                                >
                                  <img src={InfoIcon} className="icon_img ml-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {props.CurrentRole === 2 ? (
                      <div className="col-lg-4">
                        <div class="card view_proposal_card h-100">
                          <div class="card-body px-4 py-4">
                            <div className="card_body_inner d-flex">
                              <div className="inner_item mr-4">
                                <div className="card_icon d-flex">
                                  <img src={MenuBoardIcon} className="icon_img" />
                                </div>
                              </div>
                              <div className="inner_item">
                                <p className="mb-0 text_gray">{states?.type == "fixed" ? "Delivery Date" : "Start Date"}</p>
                                <p className="mb-2 text_black_md">
                                  {states?.type == "fixed"
                                    ? handleDelivaryDate(states, states?.deliverable, states?.type)
                                    : moment(states?.proposed_start_date).format("MMM D, YYYY")}
                                </p>
                              </div>
                              {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-lg-4">
                          <div class="card view_proposal_card h-100">
                            <div class="card-body px-4 py-4">
                              <div className="card_body_inner d-flex">
                                <div className="inner_item mr-4">
                                  <div className="card_icon d-flex">
                                    <img src={MenuBoardIcon} className="icon_img" />
                                  </div>
                                </div>
                                <div className="inner_item">
                                  <p className="mb-0 text_gray">Start Date</p>
                                  <p className="mb-2 text_black_md">
                                    {moment(states?.proposed_start_date).format("MMM D, YYYY")}
                                  </p>
                                </div>
                                {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {states.type === "fixed" ? (
                          <div className="col-lg-4">
                            <div class="card view_proposal_card h-100">
                              <div class="card-body px-4 py-4">
                                <div className="card_body_inner d-flex">
                                  <div className="inner_item mr-4">
                                    <div className="card_icon d-flex">
                                      <img src={MenuBoardIcon} className="icon_img" />
                                    </div>
                                  </div>
                                  <div className="inner_item">
                                    <p className="mb-0 text_gray">Delivery Date</p>
                                    <p className="mb-2 text_black_md">
                                      {handleDelivaryDate(states, states?.deliverable, states?.type)}
                                    </p>
                                  </div>
                                  {/* <div className="tag_btn_gray card_tag text_gray_xxs_w_600">Learn More</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>

                  {loader ? (
                    <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
                  ) : (
                    <>
                      <>
                        {/* Fixed Section */}
                        {states?.type == "fixed" ? (
                          <>
                            <div className="row px-4 pb-4 deliverables mx-0 scroll_bar_body">
                              <div className="col-lg-12 title_commn">
                                <p className="mb-0">Deliverables</p>
                              </div>
                              {/* table */}
                              <div className="col-lg-12 custom_table px-0 d-none d-lg-block">
                                <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                                  <div class="col-lg-1 col-1 text-left">
                                    <span class="">#</span>
                                  </div>
                                  <div class="col-lg-5 col-5">
                                    <span class="">Milestone</span>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                    <span class="">Due Date</span>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                    <span class="">Iterations</span>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                    <span class="">Price post iteration limit</span>
                                  </div>
                                </div>

                                {states.deliverable.map((item, i) => {
                                  return (
                                    <div class="row-border creator-proposal">
                                      <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                        <div class="col-lg-1 col-1 text-left">{onkeyCode(i + 65)}</div>
                                        <div class="col-lg-5 col-lg-5">
                                          <p className="mb-0">{item?.name}</p>
                                        </div>
                                        <div class="col-lg-2 col-2 pr-0">
                                          <p className="mb-0">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                                        </div>
                                        <div class="col-lg-2 col-2 pr-0">
                                          <p className="mb-0">{item?.iterations ? item?.iterations : "-"}</p>
                                        </div>
                                        <div class="col-lg-2 col-2 pr-0">
                                          <p className="mb-0">
                                            {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              {/*mobile view table */}
                              <div className="col-lg-12 custom_table px-0 d-lg-none d-block">
                                {states.deliverable.map((item, i) => {
                                  return (
                                    <div class="row-border creator-proposal">
                                      <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                      <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                          <div class="col-lg-12 mb-2">
                                            <span className="font_semi_bold font-16">
                                              {onkeyCode(i + 65)} {item?.name}
                                            </span>
                                          </div>
                                          <div class="col-lg-12 mb-2">
                                            <p className="text_text-gray-1400">Due Date</p>
                                            <p className="font_semi_bold">{moment(item?.dueDate).format("MMM D, YYYY")}</p>
                                          </div>
                                          <div class="col-lg-12 mb-2">
                                            <p className="text_text-gray-1400">Iterations</p>
                                            <p className="font_semi_bold">{item?.iterations ? item?.iterations : "-"}</p>
                                          </div>
                                          <div class="col-lg-12 mb-2">
                                            <p className="text_text-gray-1400">Price post iteration limit</p>
                                            <p className="font_semi_bold">
                                              {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            {/* Payment_Terms */}
                            <div className="row px-4 pb-4 payment_terms mx-0 scroll_bar_body">
                              {/* header */}
                              <div className="col-lg-12 title_commn d-flex justify-content-between align-items-center">
                                <p className="mb-0">Payment Terms</p>
                              </div>
                              {/* description */}
                              {/* <div className="col-lg-12 px-0 py-3">
                                <p className="discription_common">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit laoreet est augue faucibus ac.
                                  Est morbi cras nisl, non quis massa mattis sit arcu. Facilisis purus vitae volutpat tincidunt
                                  consectetur pulvinar convallis sed. Nulla risus sit in enim nam aliquam sapien phasellus rhoncus.
                                </p>
                              </div> */}
                              {/* table */}
                              <div className="custom_table col-lg-12 px-0 pt-3 d-none d-lg-block">
                                <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                                  <div class="col-lg-1 col-1 text-left">
                                    <span class="">#</span>
                                  </div>
                                  <div class="col-lg-9 col-5">
                                    <span class="">Payment Milestones</span>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                    <span class="">Breakup</span>
                                  </div>
                                </div>

                                {states?.advanceFees?.breakup ? (
                                  <div class="row-border creator-proposal">
                                    <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                      <div class="col-lg-1 col-1 text-left">1</div>
                                      <div class="col-lg-9 col-lg-5">
                                        <p className="mb-0">Advance Fees</p>
                                      </div>
                                      <div class="col-lg-2 col-2 pr-0">
                                        <p className="mb-0">₹ {separateNumber(states.advanceFees.breakup, "")}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {states.payments.map((item, i) => {
                                  return (
                                    <div class="row mx-0 mb-0 py-3 row-border align-items-center font_semi_bold_w_600">
                                      <div class="col-lg-1 col-1 text-left">{states?.advanceFees?.breakup ? i + 2 : i + 1}</div>
                                      <div class="col-lg-9 col-lg-5">
                                        <p className="mb-0">{handleSelectedNames(item.names)}</p>
                                      </div>
                                      <div class="col-lg-2 col-2 pr-0">
                                        <p className="mb-0">₹ {separateNumber(item?.breakup, "")}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              {/* Mobile view */}
                              <div className="custom_table col-lg-12 px-0 d-block d-lg-none">
                              {states?.advanceFees?.breakup ? (
                                  <div class="row-border creator-proposal">
                                    <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                      <div class="col-lg-12 mb-3 font-weight-bold">1. Advance Fees</div>
                                      <div class="col-lg-12">
                                        <p className="text_text-gray-1400">Price Breakup</p>
                                        <p className="font_semi_bold"> ₹ {separateNumber(states.advanceFees.breakup, "")}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {states.payments.map((item, i) => {
                                  return (
                                    <div class="row mx-0 mb-0 py-3 row-border align-items-center font_semi_bold_w_600">
                                      <div class="col-lg-12 mb-3 font-weight-bold">
                                      {states?.advanceFees?.breakup ? i + 2 : i + 1}. {handleSelectedNames(item.names)}
                                      </div>
                                      <div class="col-lg-12">
                                        <p className="text_text-gray-1400 font-14">Price Breakup</p>
                                        <p className="font_semi_bold"> ₹ {separateNumber(item?.breakup, "")}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : states?.type == "retainer" ? (
                          <>
                            <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                              <div className="col-lg-12 title_commn">
                                <p className="mb-0">Time Commitment</p>
                              </div>
                              {/* table */}
                              {handleTimeCommitment(1)}
                              {handleTimeCommitment(2)}
                              {handleTimeCommitment(3)}
                            </div>

                            <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                              <div className="col-lg-12 title_commn">
                                <p className="mb-0">Payment Terms</p>
                              </div>
                              {/* table */}
                              {handleTimeCommitment(6)}
                              {handleTimeCommitment(4)}
                              {projectData?.were_admin_project ? null : handleTimeCommitment(5)}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                              <div className="col-lg-12 title_commn">
                                <p className="mb-0">Terms</p>
                              </div>
                              {/* table */}
                              {handleTimeCommitment(1)}
                              {handleTimeCommitment(6)}
                            </div>

                            <div className="row px-4 pb-4 deliverables mx-0  scroll_bar_body">
                              <div className="col-lg-12 title_commn">
                                <p className="mb-0">Deliverables</p>
                              </div>
                              {/* table */}
                              <div className="col-lg-12 custom_table px-0">
                                <div class="row m-0 pb-0 py-3 br_btm text_text-gray-1400">
                                  <div class="col-lg-1 col-1 text-left">
                                    <span class="">#</span>
                                  </div>
                                  <div class="col-lg-5 col-5">
                                    <span class="">Items</span>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                    <span class="">Pricing</span>
                                  </div>
                                  {!projectData?.were_admin_project && props?.CurrentRole == 2 ? <div class="col-lg-2 col-2">
                                    <span class="">Your Earnings</span>
                                  </div>
                                  :null }
                                  <div class="col-lg-2 col-2">
                                    <span class="">Iterations</span>
                                  </div>
                                 {props?.CurrentRole != 2 || projectData?.were_admin_project ?  <div class="col-lg-2 col-2">
                                    <span class="">Price per iteration after 3</span>
                                  </div> : null }
                                </div>

                                {loader ? (
                                  <Loader isCreateModal={true} style={{ marginTop: "3rem", marginBottom: "3rem" }} />
                                ) : (
                                  states.deliverable.map((item, i) => {
                                    return (
                                      <div class="row-border creator-proposal">
                                        <div class="row mx-0 mb-0 py-3 align-items-center font_semi_bold_w_600">
                                          <div class="col-lg-1 col-1 text-left">
                                            {states?.type == "fixed" ? onkeyCode(i + 65) : i + 1}
                                          </div>
                                          <div class="col-lg-5 col-lg-5">
                                            <p className="mb-0">{item?.name}</p>
                                          </div>
                                          <div class="col-lg-2 col-2 pr-0">
                                            <p className="mb-0">
                                              {item?.breakup ? `₹ ${separateNumber(item.breakup, "")}` : `-`}
                                            </p>
                                          </div>
                                          {!projectData?.were_admin_project && props?.CurrentRole == 2 ? <div class="col-lg-2 col-2 pr-0">
                                            <p className="mb-0"> {item?.breakup ? `₹ ${separateNumber(item.breakup * .85, "")}` : `-`}</p>
                                          </div>: null }
                                          <div class="col-lg-2 col-2 pr-0">
                                            <p className="mb-0">{item?.iterations ? item?.iterations : "-"}</p>
                                          </div>
                                          {props?.CurrentRole != 2 || projectData?.were_admin_project ?<div class="col-lg-2 col-2 pr-0">
                                            <p className="mb-0">
                                              {item?.iteration_charge ? `₹ ${separateNumber(item.iteration_charge, "")}` : `-`}
                                            </p>
                                          </div>: null }
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </>
                  )}

                  {/*Add References */}

                  <ReferenceForClient {...props} creatorUsername={states?.proposalData?.creatorUsername} />

                  {/* add notes */}
                  {data?.note?.trim()?.length > 0 ? (
                    <div className="row  px-4 add_notes mx-0">
                      <div className="col-lg-12 title_commn d-flex mb-3 justify-content-between align-items-center">
                        <p className="mb-0">Additional Notes</p>
                      </div>
                      <div className="col-lg-12 px-0 mb-4">
                        <div className="text_gray_xs_w_600" dangerouslySetInnerHTML={{ __html: noteText }} />
                      </div>
                    </div>
                  ) : null}
                  {/* end add notes */}
                </div>
                <div className="modal-footer">
                  {props?.isLoading || props?.unAuthorized ? null : ((props?.isChat && !data?.isExpired) ||
                      props?.isMakeChanges) &&
                    props.CurrentRole !== 3 ? (
                    <>
                      {projectData?.status <= 5 ? (
                        <div className="row mx-0">
                          <ProjectsFooter
                            id={props?.match?.params?.projectId}
                            showAppPay={data?.status == 0 && props?.CurrentRole == 1 ? true : false}
                            isOffLine={parseInt(data?.advance_amount) == 0 ? true : false}
                            stateData={{ proposalData: data }}
                            showReject={true}
                            showRequest={props?.CurrentRole == 1 ? true : false}
                            showChanges={props?.CurrentRole == 2 ? true : false}
                            isClient={props?.CurrentRole == 1 ? true : false}
                            {...props}
                            type={data?.proposal_type}
                            proposalId={data?._id}
                          />
                        </div>
                      ) : null}

                      {projectData?.status == 6 ? (
                        <>
                          {props?.CurrentRole == 1 || props?.CurrentRole == 2 ? (
                            <div className="row mx-0">
                              <ProjectsFooter
                                id={props?.match?.params?.projectId}
                                {...props}
                                stateData={{ proposalData: data }}
                                showChanges={props?.CurrentRole == 2 ? true : false}
                                type={data?.proposal_type}
                                showRequest={props?.CurrentRole == 1 ? true : false}
                                proposalId={data?._id}
                                isAppRejProp={props?.CurrentRole == 1 ? true : false}
                                loader={load}
                                loadType={loadType}
                                handleApproveApi={handleApproveApi}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {selectedCreator && (
          <Popup
            {...props}
            selectedCreator={selectedCreator}
            hideActions={true}
            projectId={props?.match.params.projectId}
            // openProposal={this.openProposal}
            // openBrief={this.openBrief}
            // creatorUnderReview={creatorUnderReview}
            handleCreatorProfileByIndex={handleViewProfile}
            onClickViewProjects={onClickViewProjects}
            selectedCreatorType={selectedCreatorType}
            selectedCreatorData={selectedCreatorData}
            // Below props needed to showcase common slider
            // creatorRejected={artistArrayForSlider}
            // allCreators={artistArrayForSlider}
          />
        )}
        <ViewModalPopup
          {...props}
          hideActions={true}
          selectedCreator={selectedCreator}
          projectId={props?.match.params.projectId}
          // openProposal={this.openProposal}
          // openBrief={this.openBrief}
          // creatorUnderReview={creatorUnderReview}
          handleCreatorProfileByIndex={handleViewProfile}
          onClickViewProjects={onClickViewProjects}
          selectedCreatorType={selectedCreatorType}
          selectedCreatorData={selectedCreatorData}
          openedFrom={openedFrom}
          // creatorRejected={artistArrayForSlider}
        />
    </section>
  );
}
