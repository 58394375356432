import React from 'react';
import { connect } from 'react-redux';
import ProgressBar from './Progressbar';
import { putAPI } from '../../../utils/API';
// import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import InputRange from 'react-input-range';
import { separateNumber } from '../../../utils/Helperfunctions';
import DatePicker from "react-date-picker";
import DateIcon from "../../../Assets/upgrade-images/submit_proposal/date.svg";
import {addHrMinDate} from '../Home/DashboardNew/CommonFunctionsNew/CommonFunctionsNew'

// budget_subtext
import './plus.css'
class Budget extends React.Component {
	minInput = React.createRef();
	maxInput = React.createRef();


	state = {
		budgetError: false,
		isLoading: false,
		value: { min: 8000, max: 12000 },
		min: null,
		max: null,
		monthError: false,
		hourError: false,
        deadlineError: false, 
		maxShow:null,
	}

	componentDidMount() {
		this.setState({ value: { min: this.props.minBudget, max: this.props.maxBudget } })

		// to show budget at left panel
		this.props.budgetChangedEvent(true)
	}
	

	handleContinue = () => {
		const { projectId, minBudget, maxBudget, pricingUnit, post_steps, total_steps, isHireflow, subType, number_month, number_hour, fixedProjectDeadline } = this.props;
		// const { value } = this.state;
		this.setState({ isLoading: true })
		// if(!budgetChanged) {
		// 	this.setState({
		// 		budgetError: true,
		// 	})
		//     alert("Please enter your budget to continue");
		//     return;
		// }

		const budget_data = {
			_id: projectId,
			min_budget: minBudget,
			max_budget: maxBudget,
			budget_type: pricingUnit,
			budget_unit: "RS",
			number_month,
			number_hour,
			fixedProjectDeadline: addHrMinDate(fixedProjectDeadline,5,30),
		}

		// if (subType === 'retainer' && (number_month === '' || number_month === 0 || number_hour === '' || number_hour === 0)) {

		// 	alert("Enter Month field and Hour field")
		// 	this.setState({ isLoading: false })
		// 	return

		// }
		if (subType === 'retainer' && (number_month === '' || number_month < 1)) {
			if((number_hour === '' || number_hour < 1))
			this.setState({ hourError: true, isLoading: false })

				this.setState({ monthError: true, isLoading: false })
			
			return
		}
		if (subType === 'retainer' && (number_hour === '' || number_hour < 1)) {
			this.setState({ hourError: true, isLoading: false })
			return
		}
		if (this.props.userName) {
			putAPI('/project/post-project-budget', budget_data).then((res) => {
				if (res.data.status === "1") {
					this.props.PostProjectSteps(post_steps, total_steps);
					this.props.SetBudgetScope(res.data.project_data.budget_scope)
					if (pricingUnit ==='per_unit'  && subType === 'retainer' && !this.props.typeQuestions.length &&!this.props.StyleQuestions.length) {

						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}projectName`);
					}if (pricingUnit ==='per_unit'  && subType === 'retainer' && !this.props.typeQuestions?.length &&this.props.StyleQuestions.length!=0) {

						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
					}  
					else if (pricingUnit === "per_unit" && subType === 'retainer'&&this.props.typeQuestions.length != 0) {
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}type`)
					}
					else if (pricingUnit === "per_unit"&&subType!=='retainer') {
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}unit_selection`);
						
					}
					else if(this.props.typeQuestions.length>0){
						this.props.history.push(`${isHireflow ? '/hire/type' : '/self-service-hire/deadline'}`);

					}
					else if(this.props.StyleQuestions.length>0){
						this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`)

					}
					else {
						this.props.history.push(`${isHireflow ? '/hire/projectName' : '/self-service-hire/deadline'}`);
					}
					// console.log("BudgetResponse", res.data);
					this.setState({ isLoading: false })
				}
			}).catch((error) => {
				console.log(error);
			})
		} else {
			localStorage.setItem("BudgetData", JSON.stringify(budget_data));
			this.props.PostProjectSteps(post_steps, total_steps);
				if (pricingUnit ==='per_unit'  && subType === 'retainer' && !this.props.typeQuestions.length &&!this.props.StyleQuestions.length) {

					this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}projectName`);
				}if (pricingUnit ==='per_unit'  && subType === 'retainer' && !this.props.typeQuestions?.length &&this.props.StyleQuestions.length!=0) {

					this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`);
				}  
				else if (pricingUnit === "per_unit" && subType === 'retainer'&&this.props.typeQuestions.length != 0) {
					this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}type`)
				}
				else if (pricingUnit === "per_unit"&&subType!=='retainer') {
					this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}unit_selection`);
					
				}
				else if(this.props.typeQuestions.length>0){
					this.props.history.push(`${isHireflow ? '/hire/type' : '/self-service-hire/deadline'}`);

				}
				else if(this.props.StyleQuestions.length>0){
					this.props.history.push(`${isHireflow ? '/hire/' : '/self-service-hire/'}style`)

				}
				else {
					this.props.history.push(`${isHireflow ? '/hire/projectName' : '/self-service-hire/deadline'}`);
				}
			this.setState({ isLoading: false })
		}
		// this.props.OnFieldChange("MINIMUN_BUDGET", value.min);
		// this.props.OnFieldChange("MAXIMUM_BUDGET", value.max);
	}

	changeBudgetHandler = (value) => {
		this.minInput.current.value = separateNumber(value.min)
		this.maxInput.current.value = separateNumber(value.max)
		
		this.setState({maxShow:value.max}) ;
		// console.log("ss",this.maxInput.current.value);

		this.props.OnFieldChange("MINIMUM_BUDGET", value.min);
		this.props.OnFieldChange("MAXIMUM_BUDGET", value.max);
	}

	handleSliderInputBox = (e) => {
		const minBudget = 500
		const maxBudget = 500000

		const min = parseInt(this.minInput.current.value.replace(/,/g,''));
		const max = parseInt(this.maxInput.current.value.replace(/,/g,''));

		if (min < minBudget || max > maxBudget || min > maxBudget || max < minBudget) {
			if (min < minBudget || min > maxBudget) {
				this.minInput.current.value = minBudget
				this.props.OnFieldChange("MINIMUM_BUDGET", minBudget);
			}
				else if(max>maxBudget){
				this.props.OnFieldChange("MAXIMUM_BUDGET", max);
				this.setState({maxShow:max})
			}else if(max<maxBudget){
				this.props.OnFieldChange("MAXIMUM_BUDGET", max);
				this.setState({maxShow:max})
			

			}
			return
		} else if (min < max) {
			this.minInput.current.value = min
			this.maxInput.current.value = max
			this.props.OnFieldChange("MINIMUM_BUDGET", min);
			this.props.OnFieldChange("MAXIMUM_BUDGET", max);
		} else if (min > max) {
			this.minInput.current.value = max
			this.maxInput.current.value = min
			this.props.OnFieldChange("MINIMUM_BUDGET", max);
			this.props.OnFieldChange("MAXIMUM_BUDGET", min);
		}
		this.minInput.current.value = separateNumber(min)
		this.maxInput.current.value = separateNumber(max)
	}

	// handleSliderInputBox = (e,obj, type) => {
	//     const newValue = parseInt(e.target.value)
	//     if(!newValue) return
	//     console.log(obj.answer)
	//     console.log(this.state[`rangeInput_${obj._id}`])

	//     const prevMin = this.state[`rangeInput_${obj._id}`].min
	//     const prevMax = this.state[`rangeInput_${obj._id}`].max 

	//     if(newValue < obj.start_rang || newValue > obj.end_rang) {
	//         alert('invalid range input')
	//         return
	//     }

	//     if(type === 'min'){
	//         if(newValue > prevMax){
	//             this.setState({[`rangeInput_${obj._id}`]:{min: prevMax , max: newValue}}, () => this.newSelectedAnswer(obj,{start_range:prevMax , end_range: newValue}))
	//             // alert('Minimum value cannot be greater than the maximum value')
	//         }else{
	//             this.setState({[`rangeInput_${obj._id}`]:{min: newValue, max: prevMax}}, () => this.newSelectedAnswer(obj,{start_range:newValue, end_range: prevMax}))
	//         }
	//     } else {
	//         if(newValue < prevMin){ 
	//             // alert('Maximun value cannot be greater than the minimun value')
	//             this.setState({[`rangeInput_${obj._id}`]:{min: newValue , max: prevMin}}, () => this.newSelectedAnswer(obj,{start_range:newValue , end_range: prevMin}))
	//         } else {
	//             this.setState(prevState => ({[`rangeInput_${obj._id}`]:{min: prevMin, max: newValue}}), () => this.newSelectedAnswer(obj,{start_range:prevMin, end_range: newValue}))
	//         }
	//     }
	// }

	handleChangeDate = (e) => {
		this.props.AddFixedProjectDeadline(new Date(e))
	};

	render() {
		const collection = document.getElementsByClassName("input-range__label-container");
		const dateimg = <img src={DateIcon}/>;

		if (collection.length >= 4) {
			// collection[2].innerHTML=`${separateNumber(parseInt(collection[2].innerHTML))}`
			collection[3].innerHTML = `${separateNumber(parseInt(500000))}`
		}


		// collection[0].innerHTML = collection[0].innerHTML+"Hello World!";
		let { budget_heading, budget_subtext, minBudget, maxBudget, number_month, number_hour,subType  } = this.props;
		let { budgetError ,monthError, hourError, deadlineError} = this.state;
		return (
			<>
				<section>
					<div className="card-wrapper mob-mb-70">
						<div className="card mob-border-radius-0">
							<div className="card-body">
								<div className="row mx-0 mt-1 overflow-content project-height">
									<div className="col-sm-12 col-md-12 col-lg-12 mob-col-100 post_a_project">
										<div className="px-4 pt-5 pb-3 mob-px-0 mob-pt-20">
											<h2 className="mb-2 font-22 mob-font-18 font-weight-600">{subType==='retainer'?'What is your monthly budget for this resource?':budget_heading}</h2>
											{(budget_subtext !== ""&&subType!=='retainer') ?
												<p className="mb-0 label font-weight-500 mob-font-12 text-third">{budget_subtext}</p>
												: <></>}
										</div>
										<div className="custom_form px-4 pb-4 pt-2 mob-p-0">
											<form method="post" action="">
												<div className="row m-0">
													<div className="w-100 d-flex align-items-center">
														<div className="form-group w-10 mob-w-30 ipad-only-w-12 position-relative">
															<label className="label font-weight-600" htmlFor="first">Min. budget</label>
															<input onBlur={this.handleSliderInputBox} ref={this.minInput} defaultValue={separateNumber(minBudget)} id="MINIMUM_BUDGET" type="text" 
                               									onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}				
																className="form-control email mob-w-100 pl-4 " placeholder="5000" />
															<span className="font-14 text-light-gray font-weight-500 pt-2 d-block">
																<i className="fas fa-rupee-sign post-search rupees"></i>
															</span>
														</div>
														<span className="w-5 mob-w-10 text-center font-20">-</span>
														<div className="form-group w-10 mob-w-30 ipad-only-w-12 position-relative">
															<label className="label font-weight-600" htmlFor="first">Max. budget</label>
															<input onBlur={this.handleSliderInputBox} ref={this.maxInput} defaultValue={separateNumber(maxBudget)} id="MAXIMUM_BUDGET"
                                								onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
																type="text" className="form-control email mob-w-100 pl-4 " placeholder="12000" />
															<span className="font-14 text-light-gray font-weight-500 pt-2 d-block">
																<i className="fas fa-rupee-sign post-search right_icon rupees"></i>
															</span>
														</div>
													</div>
												</div>
												<div className="form-group mb-60 ">
													<div className="selector">
														{/* <div id="slider-range" className="range_slider"></div> */}
														<div className="selector mb-4">
															{/* <div id={"slider_range_"+`${index}`} className="range_slider"></div> */}
															{/* <CustomSlider range={[obj.start_rang, obj.end_rang]} /> */}
															<InputRange
																step={500}
																minValue={500}
																maxValue={500000}
																formatLabel={value => `${value}`}
																value={{ min: minBudget, max: maxBudget }}
																onChange={value => this.changeBudgetHandler(value)}
																onChangeComplete={value => this.changeBudgetHandler(value)} />

															{/* <div className="dflex mt-4">
																	<span className="label">{obj.start_rang}</span>
																	<span className="label">{obj.end_rang}</span>
																</div> */}  
															</div>
															<div className={this.state.maxShow>500000?`text-muted plus`:`d-none`} >+</div>
													</div>
													{/* <div  className="range_slider" id="slider-range">
													<div>
														<div  className="inverse-left"  style={{width:"70%"}}></div>
														<div  className="inverse-right" style={{width:"70%"}}></div>
														<div  className="range" style={{left:`${minPercent+"%"}`,right:`${(100-maxPercent)+"%"}`}}></div>
														<div  className="thumb" style={{left:`${minPercent+"%"}`}}>
															||| 
														</div>
														<div  className="thumb" style={{left:`${maxPercent+"%"}`}}> 
															|||
														</div> 
													</div>
													*/}
													{/* <input type="range" tabIndex="0" className="min" name="MINIMUM_BUDGET" value={minBudget} max="65500" min="5000" step="500"    onChange={(e) => this.props.OnFieldChange(e.target)} />
                                                    <input type="range" tabIndex="0" className="max" name="MAXIMUM_BUDGET" value={maxBudget} max="70000" min="5000" step="500"  onChange={(e) => this.props.OnFieldChange(e.target)}  /> */}
													{/* <div className="dflex mt-4">
														<span className="label"><i className="fas fa-rupee-sign"></i>5,000</span>
														<span className="label"><i className="fas fa-rupee-sign"></i>70,000</span>
													</div> */}
													{/* </div>   */}
													{(budgetError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please select your budget range.</p> : ""}
													
												</div>
												<div className='mb-20'>
												<label className="label font-14 font-weight-600" htmlFor="no">If you want to add a budget of more than INR 5 lakh, kindly use the text box.</label>
												</div>
												{
													subType === 'retainer'? 
													<div className="row">
														<div className="col-sm-6 col-lg-5 mb-4 mb-lg-0">
															<label className="label font-weight-600 text-dark font-14" htmlFor="first">How many months are you looking to hire the resource for?</label>
															<input type="number"
                                								onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
																className="form-control email mob-w-100" value={number_month===0?"":number_month} onChange={e => {
																this.setState({ monthError: false })
																this.props.AddProjectMonth(e.target.value)
															}} />
															{(monthError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">{number_month != "" && number_month < 1 ? "Months should be greator than equal to 1." : "Please enter months field."}</p> : ""}
														</div>
														<div className="col-sm-6 col-lg-5">
															<label className="label font-weight-600 text-dark font-14" htmlFor="first">How many hours do you expect the resource to work in a week?</label>
															<input type="number"
                                								onKeyDown={(evt) => (evt.key === 'e' || evt.key === '=' || evt.key === '-' || evt.key === '+' || evt.key === '_' || evt.key === ".") && evt.preventDefault()}
																className="form-control email mob-w-100" value={number_hour===0?"":number_hour} onChange={e => {
																this.setState({ hourError:false })
																this.props.AddProjectHour(e.target.value)
															}} />
															{(hourError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">{number_hour != "" && number_hour < 1 ? "hours should be greator than equal to 1." : "Please enter hours field."}</p> : ""} 
														</div>
														
													</div>
													:
													<div className="row">
														<div className="col-sm-6 col-lg-5 mb-4 mb-lg-0">
														<label className="label font-weight-600 text-dark font-14" htmlFor="first">Add a project deadline.</label>
															{/* <input type="date" className="form-control email mob-w-100" onChange={e => {
																this.setState({ deadlineError:false })
																this.props.AddFixedProjectDeadline(e.target.value)
															}} /> */}
															<DatePicker
																value={this.props?.fixedProjectDeadline}
																className="form-control text-center input_sm"
																clearIcon={null}
																name="startDate"
																calendarIcon={dateimg}
																format="dd/MM/yyyy"
																calendarClassName="apniClass"
																showLeadingZeros={true}
																onChange={this.handleChangeDate}
																minDate={new Date(new Date().getTime() + 86400000)}
															/>
															{/* {(deadlineError === true) ? <p className="mb-0 text-danger font-weight-600 font-12 py-1">Please enter deadline field.</p> : ""} */}
														</div>
													</div>
												}
													
												{/* <!-- Mobile Content --> */}
												{/* <div  className="row m-0">
												<div  className="col-12 mob-p-0">
													<p  className="text-third font-weight-500 font-14 mb-3">
														For the selected range you get
													</p>
												</div>
												<div  className="w-100 wrapper-form py-3 mobile-ipad-show"> 
													<div  className="row m-0 align-items-center"> 
														<div  className="col-4 p-0">
															<div  className="d-block text-center">
																<div  className="post-a-icon pb-1">
																	<span><i  className="fas fa-star"></i></span>
																</div>
																<div  className="">
																	<span  className="profile-subTitle font-12 text-capitalize mob-font-12 small-font-8">Creator Level</span>
																	<h2  className="text-capitalize m-0 font-weight-600 font-14 mob-font-12 small-font-10">Entry Level</h2> 
																</div>
															</div>
														</div>
														<div  className="col-4 p-0">
															<div  className="d-block text-center">
																<div  className="post-a-icon pb-1">
																	<span><i  className="fas fa-star"></i></span>
																</div>
																<div  className="">
																	<span  className="profile-subTitle font-12 text-capitalize mob-font-12 small-font-8">Project Budget</span>
																	<h2  className="text-capitalize m-0 font-weight-600 font-14 mob-font-12 small-font-10">
																		<span className="d-inline mr-1"><i  className="fas fa-rupee-sign font-12"></i>5,000</span>
																		- 
																		<span className="d-inline ml-1"><i  className="fas fa-rupee-sign font-12"></i>12,000</span></h2> 
																</div>
															</div>
														</div>
														<div  className="col-4 p-0">
															<div  className="d-block text-center">
																<div  className="post-a-icon pb-1">
																	<span><i  className="fas fa-star"></i></span>
																</div>
																<div  className="">
																	<span  className="profile-subTitle font-12 text-capitalize mob-font-12 small-font-8">Work Exp.</span>
																	<h2  className="text-capitalize m-0 font-weight-600 font-14 mob-font-12 small-font-10"> <span>&#8804;</span> 2 years</h2> 
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>  */}

												{/* <!-- Desktop Content --> */}
												{/* <div  className="wrapper-form p-4 mob_display_none_off"> 
												<div  className="row m-0 align-items-center">
													<div  className="col-sm-3">
														<p  className="text-third font-weight-500 font-14 m-0">
															For the selected range you get
														</p>
													</div>
													<div  className="col-sm-3">
														<div  className="d-flex align-items-center">
															<div  className="post-a-icon">
																<span><i  className="fas fa-star"></i></span>
															</div>
															<div  className="pl-3">
																<span  className="profile-subTitle font-12 text-capitalize ipad-pro-font-11">Creator Recommendations</span>
																<h2  className="text-capitalize m-0 font-weight-600 font-14">Entry Level</h2> 
															</div>
														</div>
													</div>
													<div  className="col-sm-3">
														<div  className="d-flex align-items-center">
															<div  className="post-a-icon">
																<span><i  className="fas fa-star"></i></span>
															</div>
															<div  className="pl-3">
																<span  className="profile-subTitle font-12 text-capitalize ipad-pro-font-11">Budget</span>
																<h2  className="text-capitalize m-0 font-weight-600 font-14">
																	<span className="d-inline mr-1"><i  className="fas fa-rupee-sign font-13"></i>5,000</span>
																	- 
																	<span className="d-inline ml-1"><i  className="fas fa-rupee-sign font-13"></i>12,000</span></h2> 
															</div>
														</div>
													</div>
													<div  className="col-sm-3">
														<div  className="d-flex align-items-center">
															<div  className="post-a-icon">
																<span><i  className="fas fa-star"></i></span>
															</div>
															<div  className="pl-3">
																<span  className="profile-subTitle font-12 text-capitalize ipad-pro-font-11">Work Experience</span>
																<h2  className="text-capitalize m-0 font-weight-600 font-14">2 years or lesser</h2> 
															</div>
														</div>
													</div>
												</div>
											</div>  */}

											</form>
										</div>
									</div>
								</div>
								<div className="row m-0">
									<ProgressBar {...this.state} handleContinue={this.handleContinue} />
								</div>
								{/* <div>+</div> */}
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}

const mapState = (store) => {
	let postProjectData = store.postProject;
	let globalReducer = store.globalReducer;
	return {
		number_hour: postProjectData.number_hour,
		number_month: postProjectData.number_month,
		budget_scope: postProjectData.budget_scope,
		subType: postProjectData.subType,
		projectId: postProjectData.projectId,
		minBudget: postProjectData.minBudget,
		maxBudget: postProjectData.maxBudget,
		budgetChanged: postProjectData.budgetChanged,
		budget_heading: (postProjectData?.budget_heading !== undefined) ? postProjectData.budget_heading : postProjectData?.CollateralQuestions?.collateral?.budget_heading,
		budget_subtext: (postProjectData?.budget_subtext !== undefined) ? postProjectData.budget_subtext : postProjectData?.CollateralQuestions?.collateral?.budget_subtext,
		pricingUnit: postProjectData?.CollateralQuestions?.collateral?.pricing_unit,
		typeQuestions: (postProjectData?.typeQuestions?.length > 0) ? (postProjectData.typeQuestions) : postProjectData?.CollateralQuestions?.Collateral_type_question,
		StyleQuestions:(postProjectData?.styleQuestions?.length > 0) ? (postProjectData.styleQuestions):  postProjectData.CollateralQuestions?.Collateral_style_question,
		post_steps: postProjectData.post_steps,
		total_steps: postProjectData.total_steps,
		alreadyCreatedProject: postProjectData.alreadyCreatedProject,
		userName: globalReducer.Me.Username,
		fixedProjectDeadline: postProjectData?.fixedProjectDeadline
	};
}

const mapDispatch = dispatchEvent => ({

	OnFieldChange: (name, value) => dispatchEvent({ type: 'ON_FIELD_CHANGE_POST_PROJECT', payload: { name: name, value: value } }),
	PostProjectSteps: (step, total_step) => dispatchEvent({ type: "POST_PROJECT_STEPS", payload: { step: step, total_steps: total_step } }),
	budgetChangedEvent: (val) => dispatchEvent({ type: "BUDGET_CHANGED", values: val }),
	SetBudgetScope: (val) => dispatchEvent({ type: "ADD_BUDGET_SCOPE", values: val }),
	AddProjectMonth: (val) => dispatchEvent({ type: "ADD_PROJECT_MONTH", values: val }),
	AddProjectHour: (val) => dispatchEvent({ type: "ADD_PROJECT_HOUR", values: val }),
	AddFixedProjectDeadline: (val) => dispatchEvent({ type: "ADD_FIXED_PROJECT_DEADLINE", values: val })
});
export default connect(mapState, mapDispatch)(Budget);