import React, { Component } from "react";
import closeIcon from "../../../../../Assets/images/close.png";
// import profile from "../../../../../Assets/images/account-manager.png";
import ServicesOffered from "../ActiveBriefClient/ServicesOffered";
import Reviews from "../ActiveBriefClient/Reviews";
// import Clients from "../ActiveBriefClient/Clients";
import AboutMe from "../ActiveBriefClient/AboutMe";
import WorkExperience from "../ActiveBriefClient/WorkExperience";
import Education from "../ActiveBriefClient/Education";
import AwardAndMention from "../ActiveBriefClient/AwardAndMention";
import { connect } from "react-redux";
import { separateNumber } from "../../../../../utils/Helperfunctions";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import { Link } from "react-router-dom";
import BootstrapLoader from "../../../Common/BootstrapLoader/BootstrapLoader";
import ScheduleCall from "../../../Chat/ScheduleCall";

class SliderModalBrief extends Component {
  state = {
    show: false,
    loading: true,
    showmorebio:false,
    showCalendar: false
  };
  componentDidMount() {
    // const allCreatorsData = this.props?.postProjectClientBreif?.creator;
    // const selectedCreatorId = this.props.selectedCreator;
    // const selectedCreatorData = allCreatorsData.find(el => el.creator_id._id === selectedCreatorId);
    // this.props.loadAllProjects(selectedCreatorData.creator_id.username)
    this.setState({ show: true });
  }
  closePopup = () => {
    this.setState({ show: false }, () => {
      setTimeout(() => {
        this.props.closePopup();
      }, 300);
    });
  };
  alreadyselected(user_id){
   const res= this.props.location.pathname.includes('overview') ?this.props.selectedCreator:this.props.SelectedArtists.find(m=>m.user_id===user_id)
   if(res){
     return true;
   }
   else return  false;

  }
  handleShowMoreBio=(event)=>{
   if(event === 'more'){
     this.setState({showmorebio:true})
   } else if(event ==='less'){
    this.setState({showmorebio:false})
   }
  }
  // componentDidMount() {
  //     const allCreatorsData = this.props?.postProjectClientBreif?.creator;
  //     const selectedCreatorId = this.props.selectedCreator;
  //     const selectedCreatorData = allCreatorsData.find(el => el.creator_id._id === selectedCreatorId);
  //     this.props.loadAllProjects(selectedCreatorData.creator_id.username)
  // }
  componentDidUpdate(prevProps) { 
    if (prevProps.selectedCreator !== this.props.selectedCreator) {
      // console.log(this.props?.postProjectClientBreif?.creator, this.props.allCreators);
      const allCreatorsData = this.props?.allCreators;
      const selectedCreatorId = this.props.selectedCreator;
      const selectedCreatorData = allCreatorsData.find(
        (el) => el.creator_id._id === selectedCreatorId
      );
      this.props.loadAllProjects(selectedCreatorData?.creator_id?.username);
    }
    // console.log('brief', this.props)
  }
  pagePush = (e, page, creatorData) => {
    // e.preventDefault();
    const url = page + '/' + creatorData?.creator_id?.username
    // console.log(creatorData, url)
    this.props.closePopup();
    setTimeout(() => {
      this.props.history.push(url);
    }, 500); 
  };

  handleNextPreviousProfileSwitch = (
    activeStatusNumberIndex,
    selectedCreatorType,
    creators,
    switc
  ) => {
    if (switc === "next") {
      if (activeStatusNumberIndex < creators?.length - 1) {
        this.setState({ loading: true }, () => {
          this.props.handleCreatorProfileByIndex(
            activeStatusNumberIndex + 1,
            selectedCreatorType,
            creators
          );
        });
      }
    } else if (switc === "prev") {
      if (activeStatusNumberIndex !== 0) {
        this.setState({ loading: true }, () => {
          this.props.handleCreatorProfileByIndex(
            activeStatusNumberIndex - 1,
            selectedCreatorType,
            creators
          );
        });
      }
    }
  };

  openCalendar = () =>{
    // console.log('on calendar click')
    this.setState({showCalendar:!this.state.showCalendar});
  }
  
  render() {
    const { show, showmorebio } = this.state;
    const {authIs, userProfile} = this.props;

    const location = this.props.location.pathname.split('/')
    const isTalentConnect=location[1]&&location[1]==='talentconnect';
    const isFetching = this.props?.isFetching;
    const selectedCreatorType = this.props.selectedCreatorType;
    const creatorUnderReview = this.props?.creatorUnderReview;
    const creatorRejected = this.props.creatorRejected;
    const postProjectClientBreif = this.props?.postProjectClientBreif;
    // console.log(this.props, selectedCreatorType);
    const creators =
      selectedCreatorType === "underReview"
        ? creatorUnderReview
        : creatorRejected;
    const projectStatus = postProjectClientBreif?.postprojectdata?.status;
    const selectedCreatorId = this.props.selectedCreator;
    
    const activeStatusNumberIndex = creators?.findIndex(
      (el) => el?.creator_id?._id === selectedCreatorId
    );

    const activeStatusNumber = activeStatusNumberIndex + 1;
    const selectedCreatorData = location.includes('overview')?this.props.selectedCreatorData : creators?.find((el) => el?.creator_id?._id === selectedCreatorId) 
    // console.log(creators, selectedCreatorId, selectedCreatorData, postProjectClientBreif)
    const client = postProjectClientBreif?.client;
    const isClientAndProjectBelongsToHim = authIs && client && client._id === userProfile._id;

    
    const sub_type=selectedCreatorData?.postPorjectData?.sub_type;
    const time_base_amount_month=selectedCreatorData?.userData?.time_base_amount_month;
    const start_price=location.includes('overview')?selectedCreatorData.experience.start_price:selectedCreatorData?.matchCollateral?.start_price
    const end_price=location.includes('overview')?selectedCreatorData.experience.end_price:selectedCreatorData?.matchCollateral?.end_price
    const budget_type=selectedCreatorData?.postPorjectData?.budget_type
    const unit_tag=selectedCreatorData?.postPorjectData?.unit_tag

    return (
      <React.Fragment>
        {/*=== Sidebar Modal ===*/}
        <div
          className={`modal activeBrief_Modal fade ${show ? "show" : ""}`}
          style={{
            paddingRight: "0px !important",
            display: "block",
          }}
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >

          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <div className={`modal-title ${location.includes('overview')?'invisible':''}`}>
                  <span
                    className="left_icon cursor-pointer"
                    style={{ opacity: activeStatusNumberIndex !== 0 ? 1 : 0.5 }}
                    onClick={() =>
                      this.handleNextPreviousProfileSwitch(
                        activeStatusNumberIndex,
                        selectedCreatorType,
                        creators,
                        "prev"
                      )
                    }
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </span>

                  <span className="Numbers">
                    {activeStatusNumber} of {creators?.length}
                  </span>

                  <span
                    className="right_icon cursor-pointer"
                    style={{
                      opacity:
                        activeStatusNumberIndex < creators?.length - 1
                          ? 1
                          : 0.5,
                    }}
                    onClick={() =>
                      this.handleNextPreviousProfileSwitch(
                        activeStatusNumberIndex,
                        selectedCreatorType,
                        creators,
                        "next"
                      )
                    }
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </div>
                <span
                  className="close_icon cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.closePopup()}
                  
                >
                  <img
                    alt=""
                    src={closeIcon}
                    className="img-fliud"
                    width="35"
                  />
                </span>
              </div>
              <div className="bg-white">
                
                
                <div className="max-w-1440 mx-auto px-40 mob-px-15">
                  <div className={`dflex pt-3 ${isTalentConnect?'align-items-start':'align-items-center'} flex-column flex-sm-row`}>
                    <div className={`d-flex flex-wrap flex-column ${isTalentConnect?'flex-lg-row':'flex-lg-row'} mob-only-justify-content-center w-75 ipad-w-70 mob-w-100`}>
                      <div className="profile mob-mb-10">
                        <img
                          src={
                            BaseImageUrl +
                            selectedCreatorData?.creator_id?.profile_image
                          }
                          alt={selectedCreatorData?selectedCreatorData?.creator_id?.first_name:'creatorName'}
                          className="img-fluid"
                        />
                      </div>
                      <div className="mobile-show">
                        <div className="active_profile_wrapper">
                          {isClientAndProjectBelongsToHim && <div className="dropdown m-show ml-3">
                            <a
                              href="#/"
                              className="dropdown-toggle" 
                              role="button"
                              // data-toggle="dropdown"
                              data-toggle="collapse"
                              data-target="#navbarDropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fas fa-ellipsis-h pt-5"></i>
                            </a>
                            <div
                              className="dropdown-menu bg-shadow"
                              aria-labelledby="navbarDropdown"
                              id="navbarDropdown"
                            >
                              <span className="dropdown-menu-arrow nav-arrow"></span>
                              <a
                                className="dropdown-item"
                                href={`/creator/${selectedCreatorData?.creator_id?.username}`}
                              >
                                View{" "}
                                {selectedCreatorData?.creator_id.first_name}'s
                                Profile
                              </a>
                              {selectedCreatorType === "underReview" &&
                                projectStatus === 5 && (
                                  <Link
                                    className="dropdown-item"
                                    to=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.OpenPopup(
                                        "ClientRejectsCreator"
                                      )}
                                    }
                                  >
                                    Reject creator
                                  </Link>
                                )}
                            </div>
                          </div>}
                        </div>
                      </div>
                      <div className={`mob-only-text-center ${isTalentConnect?'w-65':'w-85'} mob-w-100`}>
                        {location[1]==='talentconnect'&&<h1 className="mb-0 text-dark-black font-16 mob-font-14 font-weight-800">
                          
                          {selectedCreatorData?.creator_id.first_name +
                            " " +
                            selectedCreatorData?.creator_id.last_name}
                        </h1>}
                        <span className="profile-subTitle font-12">
                          {selectedCreatorData?.creator_id.location}{" "}
                          {/* {selectedCreatorData?.experience.year > 0 ? (
                            <span className="dot pl-3">
                              {selectedCreatorData?.experience.year}+{" "}
                              {selectedCreatorData?.experience.year > 1
                                ? "years"
                                : "year"}{" "}
                              of experience{" "}
                            </span>
                          ) : (
                            ""
                          )} */}
                           {location.includes('overview')?
                            <span className="dot pl-3">
                            {(selectedCreatorData.experience.year< 1 ? 1 : selectedCreatorData.experience.year) +
                              (selectedCreatorData.experience.year < 1 ? " year " : selectedCreatorData.experience.month ? "+ years " : selectedCreatorData.experience.year == 1 ? " year " : " years ")} of experience
                          </span>
                           : <span className="dot pl-3">
                              {(selectedCreatorData.matchCollateral.year< 1 ? 1 : selectedCreatorData.matchCollateral.year) +
                                (selectedCreatorData.matchCollateral.year < 1 ? " year " : selectedCreatorData.matchCollateral.month ? "+ years " : selectedCreatorData.matchCollateral.year == 1 ? " year " : " years ")} of experience
                            </span>}
                        </span>
                       {isTalentConnect?<></>: <div className="charges_wrapper">
                          {sub_type=='retainer'&&!time_base_amount_month ?<p className="mb-0 mt-2 font-12 text-dark font-weight-600"></p>:<p className="mb-0 mt-2 font-12 text-dark font-weight-600">Usually Charges</p>}
                          {sub_type=='retainer'&&!time_base_amount_month ?<div className="font-weight-800 font-15 pt-1">-</div>:<div className="font-weight-800 font-15 pt-1">
                            <i class="fas fa-rupee-sign font-13"></i>
                             {sub_type==='retainer'?`${separateNumber( time_base_amount_month)}`  :`${separateNumber(start_price,{ isWords: true })} - ${separateNumber(end_price,{ isWords: true })}`}
                          
                          </div>}
                          {sub_type=='retainer'&&time_base_amount_month ?   <a href="javascript:void"className="font-12 font-weight-500">per month</a>:''}

                {sub_type=='retainer'?<a href="javascript:void" className="font-12 font-weight-500"></a>: <a href="javascript:void"className="font-12 font-weight-500">{budget_type ===
               "per_unit"
                 ? `per ${unit_tag}`
                 : "per project"}</a>}
                          {/* <a href="javascript:void" className="font-12 font-weight-500">Per project</a> */}
                        </div> }
                       {isTalentConnect&& <p className="font-14 text-dark-black font-weight-500"> 
                          {selectedCreatorData?.creator_id.bio.length > 230
                            ? 
                            <>
                              {selectedCreatorData?.creator_id.bio.substring(
                                0,
                                showmorebio ? selectedCreatorData?.creator_id.bio.lenght : 230
                              )}
                             {showmorebio ?  <Link to="" onClick={(e)=>{e.preventDefault(); this.handleShowMoreBio('less')}} className="d-inline-block text-success font-weight-500 font-12 ml-2">View less <i className="fa fa-angle-up ml-1" aria-hidden="true"></i></Link> 
                              : <>... <Link to="" onClick={(e)=>{e.preventDefault(); this.handleShowMoreBio('more')}} className="d-inline-block text-success font-weight-500 font-12 ml-2">View more <i className="fa fa-angle-down ml-1" aria-hidden="true"></i></Link></>}
                            </>
                            : selectedCreatorData?.creator_id.bio}
                           
                        </p>}
                        {selectedCreatorType === "underReview" ? (
                          <div className="d-flex align-items-center pb-3 mob-d-block">
                            {selectedCreatorData?.Proposal ? (
                              <React.Fragment>
                                <button
                                  type="button"
                                  className="btn btn-brief mob-mx-auto"
                                >
                                  <i className="fas fa-rupee-sign font-14 mr-1"></i>{" "}
                                  {separateNumber(
                                    selectedCreatorData.Proposal?.sub_total_cost
                                  )}
                                  <span className="font-10 font-weight-800 pl-1">
                                    {" "}
                                    + GST
                                  </span>
                                </button>
                                <Link
                                  to={`/viewBrief/${selectedCreatorData?.Proposal?.post_project_id}/${selectedCreatorData?.creator_id?._id}`}
                                  // onClick={(e) =>
                                  //   {e.preventDefault(); this.props.openProposal(
                                  //     selectedCreatorData.Proposal._id
                                  //   )}
                                  // }
                                  className="font-14 font-weight-500 d-block ml-3 mob-mt-8"
                                >
                                  View full proposal{" "}
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                {isClientAndProjectBelongsToHim && <div className="mobile-show">
                                  <div className='mt-2'>
                                  {selectedCreatorType !== 'rejected' ? selectedCreatorData?.Proposal ? (
                                          <>
                                            <ScheduleCall onClick={this.openCalendar} 
                                                          showCalendar={this.state.showCalendar} 
                                                          fromTalentConnect 
                                                          creatorData={selectedCreatorData}
                                                          postProjectId={this.props.projectId}
                                              /> 
                                            <button                            
                                              onClick={(e) =>
                                                this.pagePush(e,
                                                  `/chat/${postProjectClientBreif?.postprojectdata?._id}/${postProjectClientBreif?.postprojectdata?.project_slug}`,
                                                  selectedCreatorData
                                                )
                                              }
                                              className="main-btn text-white px-4"
                                            >
                                              Message
                                            </button>
                                          </>
                                        ) : 
                                        // (
                                        //   <div
                                        //     className="tooltip_custom"
                                        //     data-point={
                                        //       "Waiting for creator to share a proposal"
                                        //     }
                                        //   >
                                        //     <span className="down_icon"></span>
                                        //     <a
                                        //       href="/"
                                        //       className="main-btn text-white px-4"
                                        //       style={{
                                        //         backgroundColor: "#808080",
                                        //         cursor: "not-allowed",
                                        //       }}
                                        //       onClick={e => e.preventDefault()}
                                        //     >
                                        //       Message
                                        //     </a>
                                        //   </div>
                                        // )
                                        '' : ''}
                                  </div>
                                </div>}
                              </React.Fragment>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-brief mob-mx-auto"
                              >
                                Waiting for proposal
                              </button>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {isClientAndProjectBelongsToHim && <div className="active_profile_wrapper mobile-hide w-25 ipad-w-30">
                      <div className={`w-100 dflex justify-content-end`}>
                        {selectedCreatorType !== 'rejected' ? selectedCreatorData?.Proposal ? (
                          <>
                            <ScheduleCall onClick={this.openCalendar} 
                                          showCalendar={this.state.showCalendar} 
                                          fromTalentConnect 
                                          creatorData={selectedCreatorData}
                                          postProjectId={this.props.projectId}
                              /> 
                            <button                            
                              onClick={(e) =>
                                this.pagePush(e,
                                  `/chat/${postProjectClientBreif?.postprojectdata?._id}/${postProjectClientBreif?.postprojectdata?.project_slug}`,
                                  selectedCreatorData
                                )
                              }
                              className="main-btn text-white px-4"
                            >
                              Message
                            </button>
                          </>
                        ) : (
                          <div
                            className="tooltip_custom"
                            data-point={
                              "Waiting for creator to share a proposal"
                            }
                          >
                            <span className="down_icon"></span>
                            <a
                              href="/"
                              className="main-btn text-white px-4"
                              style={{
                                backgroundColor: "#808080",
                                cursor: "not-allowed",
                              }}
                              onClick={e => e.preventDefault()}
                            >
                              Message
                            </a>
                          </div>
                        ): ''}
                        <div className="dropdown ml-3 inside_profile_dropdown">
                          <a
                            href="#/"
                            className="dropdown-toggle" 
                            role="button"
                            // data-toggle="dropdown"
                            data-toggle="collapse"
                            data-target="#navbarDropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-h pt-5"></i>
                          </a>
                          <div
                            className="dropdown-menu bg-shadow"
                            aria-labelledby="navbarDropdown"
                            id="navbarDropdown"
                          >
                            <span className="dropdown-menu-arrow nav-arrow"></span>
                            <Link
                              className="dropdown-item"
                              target="_blank"
                              to={`/creator/${selectedCreatorData?.creator_id?.username}`}
                            >
                              View {selectedCreatorData?.creator_id.first_name}'s
                              Profile
                            </Link>
                            {selectedCreatorType === "underReview" &&
                              projectStatus === 5 && (
                                <Link
                                  className="dropdown-item"
                                  to=""
                                  onClick={(e) =>
                                    {e.preventDefault(); this.props.OpenPopup("ClientRejectsCreator")}
                                  }
                                >
                                  Reject creator
                                </Link>
                              )}
                          </div>
                        </div>
                      </div> 
                    </div>} 

                    {isTalentConnect || location.includes('overview')?<></>:

                    this.alreadyselected(selectedCreatorData?.creator_id._id)?

                    <div className="shortlist_button py-3 py-sm-0">
                      <button className="btn" type="button" onClick={()=> this.props.chooseArtist(selectedCreatorData?.creator_id._id)}><i class="fas fa-check-circle mr-1"></i>Selected</button>
                    </div>
                    :
                   
                    <div className="shortlist_button py-3 py-sm-0 ">
                      <button className="btn" type="button" onClick={()=> this.props.chooseArtist(selectedCreatorData?.creator_id._id)}>Select</button>
                    </div>
                    }
                  </div>
                </div>
              </div>
              {isFetching ? (
                <div className="modal-body loader-position-cu01">
                  <BootstrapLoader />
                </div>
              ) : (
                <div className="modal-body">
                  {/*=== Services Section ===*/}
                  <div className="card-wrapper">
                    <ServicesOffered {...this.props} />
                  </div>

                  {/*=== Reviews & Clients Section ===*/}
                  <div className="card-wrapper">
                    <div className="row">
                      {this.props.AllReviews?.length ? (
                        <div className="col-sm-12">
                          <Reviews reviews={this.props.AllReviews}/>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/*=== About Section ===*/}
                  <div className="card-wrapper">
                    <AboutMe
                      {...this.props}
                      selectedCreatorData={(selectedCreatorData?.creatorClient)?selectedCreatorData.creatorClient:selectedCreatorData?.userClient}
                      bio={selectedCreatorData?.creator_id.bio}
                    />
                  </div>

                  {/*=== Work Education & Awards Section ===*/}
                  <div className="card-wrapper mb-0">
                    <div className="row">
                      <div className={`col-sm-6 col-lg-4 ipad-mb-15`}>
                        <WorkExperience Experience={this.props.Experience} />
                      </div>
                      <div className={`col-sm-6 col-lg-4 ipad-mb-15`}>
                        <Education Education={this.props.Education} />
                      </div>
                      <div className={`col-sm-6 col-lg-4 ipad-mb-15`}>
                        <AwardAndMention Awards={this.props.Awards} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={this.props.closeModal === true ? "back-modal" : ""} />
      </React.Fragment>
    );
  }
}
const mapState = (store) => {
  let creatorProfile = store.creatorProfile;
  return {
    isFetching:creatorProfile?.isFetching,
    PrimaryFieldWork: creatorProfile.PrimaryFieldWork,
    closeModal: store.creatorProfile.closeModal,
    creatorProposalProfile: store.postProject?.creatorProposalProfile,
    AllProjectsData: store.dashboard.AllProjects,
    ProjectsData: store.dashboard.Projects,
    AllReviews: creatorProfile.Reviews,
    Tools: creatorProfile.Tools,
    Experience: creatorProfile.Experience,
    Education: creatorProfile.Education,
    Languages: creatorProfile.Languages,
    Specilizations: creatorProfile.Specilizations,
    Awards: creatorProfile.Awards,
    Collateral: creatorProfile.CollateralListing,
  };
};
const mapDispatch = (dispatch) => ({
  OpenPopup: (text) =>
    dispatch({ type: "OPEN_POPUP_SAGA", payload: { Component: text } }),
  closePopup: () => dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} }),
  loadAllProjects: (e) =>
    dispatch({ type: "LOAD_ALL_PROJECTS_SAGA", values: e }),
  LoadCollateralBasedProjects: (e, origin) =>
    dispatch({ type: "LOAD_COLLATERAL_BASED_PROJECTS", payload: e, origin }),
  ResetCollateralBasedProjects: () =>
    dispatch({ type: "RESET_COLLATERAL_PROJECTS_DATA", payload: null }),
});

export default connect(mapState, mapDispatch)(SliderModalBrief);
