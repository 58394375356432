import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getThumbnailImage } from '../../../../../../utils/Helperfunctions'
import {
  isViewableFile,
  renderDifferentFilesThumbnail,
} from "../../../../Common/Common/FileRenderMessage";
class ProjectSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      slideLength: 1,

    };

  }

  componentDidUpdate(prevProps) {
    if (prevProps.SelectedArtistData !== this.props.SelectedArtistData) {
      this.setState({ slideLength: 1 })
    }
  }


  handleSlider = (type) => {
    const { SelectedArtistData } = this.props;
    const { slideLength } = this.state;

    const projectsLength = SelectedArtistData?.project?.length;
    if (type === "next") {
      this.setState({
        slideLength: (slideLength >= projectsLength) ? slideLength : slideLength + 1,
      })
    } else {
      this.setState({
        slideLength: (slideLength <= 1) ? slideLength : slideLength - 1,
      })
    }
  }



  getThumbnailObj = (images_or_links) => {
    // console.log("images_or_links = ",images_or_links)
    const thumbnailObj = images_or_links.find(el => el.isThumbnail)
    const thirdsteptogetimage = images_or_links.filter((el) => el.value);
    // console.log("thumbnailObj = ",thumbnailObj)
    return thumbnailObj ? thumbnailObj : thirdsteptogetimage?.[0]
  }

  render() {
    // let { projectId, SelectedArtistData, project_title, slideLength, activeProject } = this.state;
    let { SelectedArtistData,activeProject,handleClick } = this.props;
    let { slideLength,  } = this.state;
    return (
      // SelectedArtistData.userProject.length < 1
      //   ?
      //   <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 creator-recommended-slider laptop-w-61 small-laptop-w-58 mobile-ipad-none">
      //     <div className="card-wrapper">
      //       <div className="card overflow-content recommentation-overflow">
      //         <div className="card-body px-4 pb-4 position-relative">
      //           <div className="row m-0 py-3">
      //             <img src="https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg" alt=""></img>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   :
        <React.Fragment>
              {
                SelectedArtistData && SelectedArtistData?.project?.length > 1 && slideLength!==1?
                  <span className="new_profile_prev card_slider_nav_pre" onClick={(e) => { this.handleSlider("prev") }}>
                    <i className="fas fa-caret-left cursor-pointer"></i>
                  </span>
                  : <></>
              }
              {                
                SelectedArtistData && SelectedArtistData?.project?.length > 0 ? SelectedArtistData?.project?.slice(slideLength - 1, slideLength).map((projects, i) => ( 
                  <RenderImage  
                  // handleClick={handleClick} 
                  key={i} 
                  image={this.getThumbnailObj(projects.images_or_links)} 
                  index={i} 
                  SelectedArtistData={this.props.SelectedArtistData}
                  location={this.props.location}
                  loadAllProjects = {this.props.loadAllProjects}
                  onClickViewProjects={this.props.onClickViewProjects}
                  projects={projects}
                  /> 
                )):<img src="https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg" alt=""></img>
              }
              {
                SelectedArtistData && SelectedArtistData?.project?.length > 1 && SelectedArtistData?.project?.length!== slideLength ?
                  <span className="new_profile_next card_slider_nav_next" onClick={(e) => { this.handleSlider("next") }}>
                    <i className="fas fa-caret-right cursor-pointer"></i>
                  </span>
                  : <></>
              }
          {/* <Popup {...this.state} {...this.props} /> */}
        </React.Fragment>
    )
  }
}


const mapDispatch = dispatchEvent => ({
  UpdateProjects: (userProjects) => dispatchEvent({ type: "UPDATE_PORTFOLIO_PROJECTS", payload: userProjects }),
  OpenPopup: (text) => dispatchEvent({ type: 'OPEN_POPUP_SAGA', payload: { Component: text } }),
  loadAllProjects: (e) =>dispatchEvent({ type: "LOAD_ALL_PROJECTS_SAGA", values: e }),
});

export default connect(null, mapDispatch)(ProjectSlider);

const RenderImage = ({ image,handleClick,SelectedArtistData,location,loadAllProjects,onClickViewProjects,projects}) => {
  const [thumbnail, setThumbnail] = useState(null)
  const { value } = image
  const { type } = image
  const [loaded, setLoaded]=useState(false);
  useEffect(() => {
    setLoaded(false);
    const getThumbnailImageFunc = async (u) => {
      const url = await getThumbnailImage(u);
      setThumbnail(url)
    }
    if (type === "url") {
      getThumbnailImageFunc(value)
    } else {
      setThumbnail(value)
    }
    // projects.images_or_links[0].type === 'url' ? getThumbnailImage(projects.images_or_links[0].value) : projects.images_or_links[0].value
  }, [value, type])

  const handleClickOnThumbnail = ()=>{
    if(location.pathname.includes('overview')){
    loadAllProjects(SelectedArtistData.creator_id.username );
    onClickViewProjects({project_data:projects,username:SelectedArtistData.creator_id.username})
  }
}

  return (
    // <img  src={thumbnail} alt="uploading" className={`img-fluid ${(activeProject === index) ? 'active' : ""}`} />
    //<span className="upload_image_thumbnail" style={{ backgroundImage: `url("${thumbnail}")` }}></span>
    <div className='slider_image'>
    {loaded ? null :
       <div className="profile_selection_loader">
       {/* <BootstrapLoader /> */} 
          <div className={`spinner-border spinner-border-sm`} role="status">
              <span className="sr-only">Loading...</span>
          </div> 
     </div>
    }
    
    
    <img 
    // onClick={()=>handleClick()}
    onClick={()=>handleClickOnThumbnail()}
    style={loaded ? {} : {display: 'none'}}
    src={ isViewableFile(image.value)
            ? renderDifferentFilesThumbnail(image.value)
            : thumbnail}
    className="img-fliud"
    onLoad={() => setLoaded(true)}
  />
  </div>

  )
}

{/* <div>
        {this.state.loaded ? null :
          <div
            style={{
              background: 'red',
              height: '400px',
              width: '400px',
            }}
          />
        }
        <img
          style={this.state.loaded ? {} : {display: 'none'}}
          src={this.props.src}
          onLoad={() => this.setState({loaded: true})}
        />
      </div> */}

{/* <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 creator-recommended-slider laptop-w-61 small-laptop-w-58 mobile-ipad-none ">
  <div className="card-wrapper">
    <div className="card overflow-content recommentation-overflow">
      <div className="card-body px-4 pb-4 position-relative">

        <div className="row m-0 recommendation-bottom">
          <div className="col-sm-8 offset-sm-2">
            <div className={`upload-thumbnail mb-2 position-relative px-2  justify-content-center`}>
              <span className="d-block"></span>

              {
                SelectedArtistData && SelectedArtistData.userProject.length > 1 ?
                  <span className="" onClick={(e) => { this.handleSlider("prev") }}>
                    <i className="fas fa-angle-left font-22  cursor-pointer"></i>
                  </span>
                  : <></>
              }

              {
                SelectedArtistData && SelectedArtistData.userProject.slice(slideLength - 1, slideLength).map((projects, i) => (
                  <span key={i} className={`upload-thumb ${(activeProject === i) ? 'active' : ""}`} > 
                    <RenderImage key={i} image={this.getThumbnailObj(projects.images_or_links)} index={i} />
                  </span>
                ))
              }
              {
                SelectedArtistData && SelectedArtistData.userProject.length > 1  ?
                  <span className="profile_selection_next" onClick={(e) => { this.handleSlider("next") }}>
                    <i className="fas fa-angle-right font-22 pl-2 cursor-pointer"></i>
                  </span>
                  : <></>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}