import { GET_STATUS_SUCCESS } from "../type.action";

const initState = "status api";

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {    
    case GET_STATUS_SUCCESS:
      return values
    default:
      return state;
  }
};

export default reducers;