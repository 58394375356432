import React from "react";
import { BaseImageUrl } from "../../../../../utils/BaseUrl";
import DummyProfile from "../../../../../Assets/upgrade-images/client_active_brief_chat/dummy.png";
import DarkBG from "../../../../../Assets/upgrade-images/client_active_brief_chat/Vector.svg";
import { Link } from "react-router-dom";
const MobileAMpopup = (props) => {
  const data = props?.isLeftClient
    ? props?.clientLeftStates?.data
    : props?.projectDataStates?.data;
  return (
    <>
      <section className="MobileAMpopup">
        <div className="modal" id="MobileAMpopup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="mb-0 modal-title">Account Manager</h4>
                <button
                  type="button"
                  className="close close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="modalclose-btnicon">
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="dark_page_card"
                  style={{
                    backgroundImage: `url(${DarkBG})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "auto 75%",
                  }}
                >
                  <div className="row py-4 px-1 mx-0">
                    <div className="col-3 col-xl-3 col-md-5">
                      <div className="rounded-circle detail_sidebar_profile_image mx-auto">
                        <img
                          src={
                            data?.project_data?.manager_image
                              ? BaseImageUrl + data?.project_data?.manager_image
                              : DummyProfile
                          }
                          className=" rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="col-xl-9 col-sm-7 col-9  d-flex flex-column align-items-start">
                      <p className="text_light_gray_xs_w_400 mb-1">Account Manager</p>
                      <p className="font_lg_white mb-2">{data?.project_data?.manager_name ? data.project_data.manager_name : "AM assign shortly"}</p>
                      {props.authIs ? <Link to={`/projects/${data?.project_data?._id}/project_room/${data?.project_data?.manager_username}`}><div className="tag_btn_primary text_highlight_xs">Message</div></Link> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default MobileAMpopup;